import { Box, InputLabel } from "@mui/material";
import "react-quill/dist/quill.snow.css";
import { SxProps, Theme } from "@mui/material/styles";
import { mergeSx } from "src/utils/styles.ts";
import { getLargestActiveBreakpoint, useMediaQueryForAllBreakpointsUp } from "src/utils/breakpointUtils.ts";
import { Breakpoint } from "@mui/system";
import { filterNulls } from "src/utils/arrayUtils.ts";
import React, { Suspense } from "react";
import { PageLoading } from "src/components/common/PageLoading.tsx";
import { ReactQuillProps } from "react-quill";

export interface AavoRichTextEditorProps extends ReactQuillProps {
	label?: string;
	sx?: SxProps<Theme>;
}

const ReactQuill = React.lazy(() => import("react-quill"));

export const AavoRichTextEditor = ({ label, sx, ...other }: AavoRichTextEditorProps) => {
	const breakpoints = useMediaQueryForAllBreakpointsUp();
	const useIfBreakpoint = <T,>(breakpoint: Breakpoint, value: T[]): T[] | null => {
		return breakpoints[breakpoint] ? value : null;
	};

	const toolbarOptions = filterNulls([
		["bold", "italic", "underline", "strike", "blockquote"],

		useIfBreakpoint("xs", [{ header: 1 }, { header: 2 }, { list: "ordered" }, { list: "bullet" }]),
		useIfBreakpoint("md", ["link", "image"]),
		useIfBreakpoint("md", [{ script: "sub" }, { script: "super" }]),
		useIfBreakpoint("md", [{ indent: "-1" }, { indent: "+1" }]),

		useIfBreakpoint("md", [{ header: [1, 2, 3, 4, 5, 6, false] }]),

		useIfBreakpoint("sm", [{ color: [] }, { background: [] }]),
		useIfBreakpoint("md", [{ font: [] }]),
		useIfBreakpoint("sm", [{ align: [] }]),

		useIfBreakpoint("sm", ["clean"]),
	]);

	return (
		<Suspense fallback={<PageLoading />}>
			<Box
				sx={mergeSx(
					{
						display: "flex",
						flexDirection: "column",
						alignItems: "stretch",
						"& .quill": {
							display: "flex",
							flexDirection: "column",
						},
						"& .ql-editor": {
							overflow: "auto",
						},
						"& .ql-toolbar": {
							minHeight: "unset",
						},
					},
					sx,
				)}
			>
				{label && (
					<InputLabel
						sx={{
							fontSize: "0.75rem",
							margin: 0,
							marginTop: "-1rem",
						}}
					>
						{label}
					</InputLabel>
				)}
				<ReactQuill
					key={getLargestActiveBreakpoint(breakpoints)} // Rotating screen causes editor to crash without remount.
					theme="snow"
					style={{
						flex: 1,
						minHeight: 0,
					}}
					modules={{
						toolbar: toolbarOptions,
					}}
					{...other}
				/>
			</Box>
		</Suspense>
	);
};
