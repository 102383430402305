import {ReclamationView} from "src/api/generated/erp/db/types/tables/reclamationView.ts";
import {useRefreshRef} from "src/utils/useRefreshRef.ts";
import {ReclamationsView} from "./ReclamationsView.tsx";
import i18n from "i18next";
import {useState} from "react";
import {ReclamationsDataGridBase} from "./ReclamationsDataGridBase.tsx";
import {OpenReclamationsDataGrid} from "src/components/views/erp/reclamation/OpenReclamationsDataGrid.tsx";

export interface AllReclamationsPageProps {
	customerOrderId?: number;
}

export const AllReclamationsPage = ({ customerOrderId }: AllReclamationsPageProps) => {
	const openReclamationsRefreshRef = useRefreshRef();
	const closedReclamationsRefreshRef = useRefreshRef();
	const taskViewRefreshRef = useRefreshRef();
	const customerOrdersRefreshRef = useRefreshRef();

	const [selectedOpenReclamations, setSelectedOpenReclamations] = useState<ReclamationView[]>([]);
	const [selectedClosedReclamations, setSelectedClosedReclamations] = useState<ReclamationView[]>(
		[],
	);

	const refreshConnectedCustomerOrders = () => {
		customerOrdersRefreshRef.refresh();
	};

	return (
		<ReclamationsView
			selectedReclamations={selectedOpenReclamations.concat(selectedClosedReclamations)}
			taskViewRefreshRef={taskViewRefreshRef}
			customerOrdersRefreshRef={customerOrdersRefreshRef}
			upperContent={{
				type: "tabs",
				items: [
					{
						type: "panel",
						title: i18n.t("incomplete"),
						content: (
							<OpenReclamationsDataGrid
								refreshRef={openReclamationsRefreshRef}
								selectedReclamations={selectedOpenReclamations}
								onReclamationReleased={() => {
									taskViewRefreshRef.refresh();
								}}
								onReclamationClosed={() =>
									closedReclamationsRefreshRef.refresh()
								}
								onSelectionChanged={(newValue) => {
									setSelectedOpenReclamations(newValue);
									setSelectedClosedReclamations([]);
								}}
								onConnectedCustomerOrdersChanged={refreshConnectedCustomerOrders}
								customerOrderId={customerOrderId}
							/>
						),
					},
					{
						type: "panel",
						title: i18n.t("closed"),
						content: (
							<	ReclamationsDataGridBase
								states={["CLOSED"]}
								refreshRef={closedReclamationsRefreshRef}
								onReclamationCloseReverted={() =>
									openReclamationsRefreshRef.refresh()
								}
								selectedReclamations={selectedClosedReclamations}
								onSelectionChanged={(newValue) => {
									setSelectedClosedReclamations(newValue);
									setSelectedOpenReclamations([]);
								}}
								onConnectedCustomerOrdersChanged={refreshConnectedCustomerOrders}
								gridId={"12BE019F7067753D"}
								customerOrderId={customerOrderId}
							/>
						),
					},
				],
			}}
		/>
	);
};
