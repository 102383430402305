import {
	TransformedConfigurationComponent_Text,
} from "src/api/generated/erp/configurator/componentTransformation/model/transformedConfigurationComponent.ts";
import { RawHtmlContent } from "src/components/common/RawHtmlContent";

export interface ConfiguratorTextComponentProps {
	component: TransformedConfigurationComponent_Text;
}

export const ConfiguratorTextComponent = ({ component }: ConfiguratorTextComponentProps) => {
	return <RawHtmlContent html={component.content} />;
};
