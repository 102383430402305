import i18n from "i18next";
import { faPaperclip } from "@fortawesome/pro-regular-svg-icons";
import { AavoObjectTypeApi } from "src/api/generated/common/sourceType/aavoObjectTypeApi.ts";
import { DocumentsOfSingleObjectView } from "src/components/views/documents/objectDocuments/DocumentsOfSingleObjectView.tsx";
import { AsyncButton, AsyncButtonProps } from "../../../common/buttons/AsyncButton.tsx";
import { useGenericDialog } from "../../../common/dialogs/useGenericDialog.ts";
import { AavoObjectRef } from "src/api/generated/common/sourceType/aavoObjectRef.ts";
import { useTenantCustomizations } from "src/tenantCustomizations/useTenantCustomizations.tsx";
import { DocumentsViewEditable } from "src/components/views/documents/types.ts";
import React from "react";

export interface DocumentsOfObjectButtonProps extends Omit<AsyncButtonProps, "onClick"> {
	objectRef: AavoObjectRef | undefined;
	editable?: DocumentsViewEditable;
	dialogTitle?: (objectDescription: string) => string;
	dialogContent?: () => React.ReactNode;
}

export const DocumentsOfObjectButton = ({
	objectRef,
	label = "",
	icon = faPaperclip,
	tooltip = i18n.t("connected_documents"),
	editable,
	dialogTitle,
	dialogContent,
	...other
}: DocumentsOfObjectButtonProps) => {
	const { tenantConfig } = useTenantCustomizations();
	const { openDialog } = useGenericDialog();

	if (!tenantConfig.documentsEnabled) return;

	return (
		<AsyncButton
			label={label}
			icon={icon}
			disabled={!objectRef}
			tooltip={tooltip}
			color={"primary"}
			onClick={async () => {
				if (!objectRef) return;

				const description = await AavoObjectTypeApi.getAavoObjectDescription({
					objectRef: objectRef,
				});

				openDialog(() => ({
					title:
						dialogTitle ?
							dialogTitle(description)
						:	`${i18n.t("connected_documents")} (${description})`,
					content:
						dialogContent != null ? dialogContent() : (
							<DocumentsOfSingleObjectView objectRef={objectRef} editable={editable} />
						),
				}));
			}}
			{...other}
		/>
	);
};
