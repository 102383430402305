import { ObjectAttributeField } from "src/api/generated/erp/common/objectAttributes/objectAttributeField.ts";
import { FieldPath, FieldPathValue, useController, UseControllerProps } from "react-hook-form";
import { ObjectAttributeFieldComponent } from "./ObjectAttributeFieldComponent";
import { requireRule } from "src/components/common/forms/validation.ts";

export interface ObjectAttributeFieldFormComponentProps<
	TFieldValues extends object,
	TFieldName extends FieldPath<TFieldValues>,
> extends Pick<UseControllerProps<TFieldValues, TFieldName>, "control" | "name"> {
	fieldDefinition: ObjectAttributeField;
	disabled?: boolean;
}

export const ObjectAttributeFieldFormComponent = <
	TFieldValues extends object,
	TFieldName extends FieldPath<TFieldValues>,
>({
	fieldDefinition,
	control,
	name,
	disabled,
}: ObjectAttributeFieldFormComponentProps<TFieldValues, TFieldName>) => {
	const { field: formField } = useController({
		name,
		control,
		rules: fieldDefinition.required ? requireRule() : undefined,
		defaultValue: null as FieldPathValue<TFieldValues, TFieldName>,
	});

	return (
		<ObjectAttributeFieldComponent
			fieldDefinition={fieldDefinition}
			value={formField.value}
			onChange={formField.onChange}
			onBlur={formField.onBlur}
			inputRef={formField.ref}
			disabled={disabled || formField.disabled}
		/>
	);
};
