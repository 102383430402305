// This file is automatically generated. Do not edit manually.

import { t } from "i18next";

export const CompanyCurrencyValues = ["EUR"] as const;

export type CompanyCurrency = (typeof CompanyCurrencyValues)[number];

export const getCompanyCurrencyLabels = () => ({
	EUR: t("eur"),
});

export const getCompanyCurrencyLabel = (value: CompanyCurrency): string => {
	return getCompanyCurrencyLabels()[value];
};
