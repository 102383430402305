// This file is automatically generated. Do not edit manually.

import { PurchaseOrder } from "src/api/generated/erp/db/types/tables/purchaseOrder";
import { DeliveryMethod } from "src/api/generated/erp/db/types/tables/deliveryMethod";
import { DeliveryTerm } from "src/api/generated/erp/db/types/tables/deliveryTerm";
import { PaymentTerm } from "src/api/generated/erp/db/types/tables/paymentTerm";
import { Site } from "src/api/generated/erp/db/types/tables/site";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace PurchaseOrderEditApi {
	export async function getFormInitData(args: {
		purchaseOrderId: number | null | undefined;
	}): Promise<PurchaseOrderEditApi_FormInitData> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/purchase/purchaseOrder/getFormInitData",
			method: "POST",
			data: {
				purchaseOrderId: args.purchaseOrderId,
			},
		});
		return response as PurchaseOrderEditApi_FormInitData;
	}

	export async function insert(args: { purchaseOrder: PurchaseOrder }): Promise<number> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/purchase/purchaseOrder/insert",
			method: "POST",
			data: {
				purchaseOrder: args.purchaseOrder,
			},
		});
		return response as number;
	}

	export async function update(args: {
		purchaseOrder: PurchaseOrder;
		updatePlannedArrivalDateToLines: boolean;
	}): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/purchase/purchaseOrder/update",
			method: "POST",
			data: {
				purchaseOrder: args.purchaseOrder,
				updatePlannedArrivalDateToLines: args.updatePlannedArrivalDateToLines,
			},
		});
		return response as void;
	}

	export async function delete_(args: { purchaseOrderIds: Array<number> }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/purchase/purchaseOrder/delete",
			method: "POST",
			data: {
				purchaseOrderIds: args.purchaseOrderIds,
			},
		});
		return response as void;
	}
}

export interface PurchaseOrderEditApi_FormInitData {
	deliveryMethodOptions: Array<DeliveryMethod>;
	deliveryTermOptions: Array<DeliveryTerm>;
	paymentTermOptions: Array<PaymentTerm>;
	purchaseOrder: PurchaseOrder | null | undefined;
	siteOptions: Array<Site>;
}
