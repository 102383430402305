import React, { useCallback } from "react";
import { AsyncState, useAsyncState } from "src/utils/async/asyncState";
import { logError } from "src/errorHandling/errorLogging.ts";
import { useErrorDialog } from "src/components/common/dialogs/errorDialog/userErrorDialog.ts";

export interface ContextMenuState {
	position: ContextMenuPosition | undefined;
	contentAsync: AsyncState<ContextMenuContent> | undefined;
	closeContextMenu: () => void;
}

export interface ContextMenuPosition {
	x: number;
	y: number;
}

export type ContextMenuContentProvider =
	| ContextMenuContent
	| (() => ContextMenuContent | Promise<ContextMenuContent>);

export type ContextMenuContent = React.ReactNode[] | React.ReactNode;

export type UseContextMenuReturn = [OpenContextMenuFunc, ContextMenuState];

export type OpenContextMenuFunc = (options: OpenContextMenuOptions) => void;

export interface OpenContextMenuOptions {
	content: ContextMenuContentProvider;
	mouseEvent: React.MouseEvent | MouseEvent | { clientX: number; clientY: number };
}

export const useContextMenu = (): UseContextMenuReturn => {
	const [contextMenuContentAsync, setContextMenuContentAsync, setContextMenuContentImmediate] =
		useAsyncState<ContextMenuContent | undefined>({});
	const [position, setPosition] = React.useState<ContextMenuPosition | undefined>(undefined);
	const { logErrorAndShowOnDialog } = useErrorDialog();

	const closeContextMenu = useCallback(() => {
		setPosition(undefined);
		setContextMenuContentImmediate(() => undefined);
	}, [setContextMenuContentImmediate]);

	const resolveContent = useCallback(
		async (contentProvider: ContextMenuContentProvider): Promise<ContextMenuContent> => {
			if (typeof contentProvider !== "function") {
				return contentProvider;
			} else {
				try {
					return await contentProvider();
				} catch (error) {
					logErrorAndShowOnDialog(error);
				}
			}
		},
		[logErrorAndShowOnDialog],
	);

	const openContextMenu = useCallback(
		({ content: contentProvider, mouseEvent }: OpenContextMenuOptions) => {
			if("preventDefault" in mouseEvent) {
				mouseEvent.preventDefault();
			}
			if (contextMenuContentAsync.data !== undefined) setContextMenuContentImmediate(() => undefined);
			else {
				setContextMenuContentAsync(async () => await resolveContent(contentProvider)).catch(logError);
			}

			setPosition((prev) => {
				return prev === undefined ?
						{
							x: mouseEvent.clientX + 2,
							y: mouseEvent.clientY - 6,
						}
					:	undefined;
			});
		},
		[contextMenuContentAsync, resolveContent, setContextMenuContentAsync, setContextMenuContentImmediate],
	);

	return [
		openContextMenu,
		{
			position,
			contentAsync: contextMenuContentAsync,
			closeContextMenu,
		},
	];
};
