import { Routes, Route } from "react-router-dom";
import { absolute, join_paths, url_param } from "src/utils/paths.ts";
import {
	INSUFFICIENT_PERMISSIONS,
	LOGGED_OUT,
	OAUTH_CALLBACK,
	WOPI_FILE_PAGE,
	EMAIL_VERIFIED,
	SETTINGS,
} from "src/urls.ts";
import { FrontendViewsContextProvider } from "src/components/views/frontendViews/FrontendViewsContext.tsx";
import { GenericDialogProvider } from "src/components/common/dialogs/GenericDialogProvider.tsx";
import { ViewRouting } from "src/components/views/main/ViewRouting.tsx";
import OAuthCallbackView from "./components/views/oAuthCallback/OAuthCallbackView";
import WopiFilePage from "./components/views/documents/wopi/WopiFilePage";
import EmailVerifiedPage from "./components/views/emailVerified/EmailVerifiedPage";
import GlobalInitDataProvider from "src/contexts/globalInitDataContext.tsx";
import SettingsPage from "src/components/views/settings/SettingsPage.tsx";
import StartUpDataProvider from "src/contexts/startUpDataContext.tsx";
import { InsufficientPermissionsPage } from "./components/views/login/InsufficientPermissionsPage";
import { LoggedOutPage } from "./components/views/login/LoggedOutPage";

export const AavoRoutes = () => {
	return (
		<Routes>
			<Route path={absolute(LOGGED_OUT)} element={<LoggedOutPage />} />
			<Route
				path={absolute(INSUFFICIENT_PERMISSIONS)}
				element={<InsufficientPermissionsPage />}
			/>
			<Route path={absolute(OAUTH_CALLBACK)} element={<OAuthCallbackView />} />
			<Route
				path={join_paths([WOPI_FILE_PAGE, url_param("action"), url_param("fileId")])}
				element={<WopiFilePage />}
			/>
			<Route path={absolute(EMAIL_VERIFIED)} element={<EmailVerifiedPage />} />
			<Route
				path={"*"}
				element={
					<GlobalInitDataProvider>
						<Routes>
							<Route path={absolute(SETTINGS)} element={<SettingsPage />} />
							<Route
								path={"*"}
								element={
									<StartUpDataProvider>
										<FrontendViewsContextProvider>
											<GenericDialogProvider>
												<ViewRouting />
											</GenericDialogProvider>
										</FrontendViewsContextProvider>
									</StartUpDataProvider>
								}
							/>
						</Routes>
					</GlobalInitDataProvider>
				}
			/>
		</Routes>
	);
};
