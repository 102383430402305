import {html, LitElement} from 'lit';
import PDFObject from 'pdfobject';

class PdfObjectWebComponent extends LitElement {
    static get properties() {
        return {
            sourceUrl: {type: String}
        };
    }

    createRenderRoot() {
        return this;
    }

    constructor() {
        super();
        this.sourceUrl = null;
    }

    render() {
        return html`
            <div class="pdf-object-container"/> `;
    }

    firstUpdated(_changedProperties) {
        super.firstUpdated(_changedProperties);
        const container = this.querySelector('.pdf-object-container');
        PDFObject.embed(this.sourceUrl, container, {
            PDFJS_URL: '/static/pdfjs/web/viewer.html'
        });

        document.addEventListener('pdfJsViewerInitialized', (event) => {
            enablePinchZoom(event.detail.pdfViewer);
        });
    }
}

function enablePinchZoom(pdfViewer) {
    const viewer = pdfViewer.viewer;
    const container = pdfViewer.container;
    let startX = 0, startY = 0;
    let initialPinchDistance = 0;
    let pinchScale = 1;
    const reset = () => {
        startX = startY = initialPinchDistance = 0;
        pinchScale = 1;
    };
    container.addEventListener('touchmove', (e) => {
        if (e.touches.length > 1 && e.scale !== 1) {
            e.preventDefault();
        }
    }, {passive: false});

    container.addEventListener('touchstart', (e) => {
        if (e.touches.length > 1) {
            const touch1 = e.touches[0];
            const touch2 = e.touches[1];
            startX = (touch1.pageX + touch2.pageX) / 2;
            startY = (touch1.pageY + touch2.pageY) / 2;
            initialPinchDistance = calculatePinchDistance(e);
        } else {
            initialPinchDistance = 0;
        }
    });
    container.addEventListener('touchmove', (e) => {
        if (initialPinchDistance <= 0 || e.touches.length < 2) {
            return;
        }
        if (e.scale !== 1) {
            e.preventDefault();
        }
        const pinchDistance = calculatePinchDistance(e);
        const originX = startX + container.scrollLeft;
        const originY = startY + container.scrollTop;
        pinchScale = pinchDistance / initialPinchDistance;
        viewer.style.transform = `scale(${pinchScale})`;
        viewer.style.transformOrigin = `${originX}px ${originY}px`;
    }, {passive: false});
    container.addEventListener('touchend', (e) => {
        if (initialPinchDistance <= 0) {
            return;
        }
        viewer.style.transform = `none`;
        viewer.style.transformOrigin = `unset`;
        pdfViewer.currentScale *= pinchScale;
        const rect = container.getBoundingClientRect();
        const dx = startX - rect.left;
        const dy = startY - rect.top;
        container.scrollLeft += dx * (pinchScale - 1);
        container.scrollTop += dy * (pinchScale - 1);
        reset();
    });
}

function calculatePinchDistance(e) {
    return Math.hypot(e.touches[1].pageX - e.touches[0].pageX, e.touches[1].pageY - e.touches[0].pageY);
}

customElements.define('pdf-object', PdfObjectWebComponent);
