// This file is automatically generated. Do not edit manually.

import { IsoDateString } from "src/types/dateTime";
import { FrontendDownloadFileHandle } from "src/api/generated/common/frontendDownloadFileHandle";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace V10PurchaseOrderCommands {
	export async function downloadV10PurchaseOrderXml(args: {
		deliveryDate: IsoDateString;
		purchaseRequestIds: Array<number>;
	}): Promise<FrontendDownloadFileHandle> {
		const response = await makeAxiosRequest({
			url: "/api/ext/lehto/v10/downloadPurchaseOrderXml",
			method: "POST",
			data: {
				deliveryDate: args.deliveryDate,
				purchaseRequestIds: args.purchaseRequestIds,
			},
		});
		return response as FrontendDownloadFileHandle;
	}
}
