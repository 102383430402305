import { PartConfigurationAttributesApi } from "src/api/generated/erp/configurator/api/partConfigurationAttributesApi.ts";
import { AavoForm, AavoFormContentParams } from "src/components/common/forms/AavoForm";
import { ObjectAttributeFieldFormComponent } from "src/components/views/erp/objectAttributes/ObjectAttributeFieldFormComponent.tsx";
import { useFieldArray } from "react-hook-form";
import { AttributeFieldWithValue } from "src/components/views/erp/objectAttributes/AttributeFieldWithValue.tsx";
import { FormCommonProps } from "src/components/common/forms/types.ts";

export interface PartConfigurationAttributesFormProps extends FormCommonProps<void> {
	partConfigurationId: number;
	attributes: AttributeFieldWithValue[];
}

interface FormValues {
	attributes: AttributeFieldWithValue[];
}

export const PartConfigurationAttributesForm = ({
	partConfigurationId,
	attributes,
	onFormEdited,
	onCompleted,
}: PartConfigurationAttributesFormProps) => {
	return (
		<AavoForm
			defaultValues={{
				attributes: attributes,
			}}
			submit={submitForm}
			columns={2}
			onCompleted={onCompleted}
			onFormEdited={onFormEdited}
			render={(params) => <FormContent {...params} />}
		/>
	);

	async function submitForm(values: FormValues) {
		await PartConfigurationAttributesApi.saveAttributes({
			partConfigurationId: partConfigurationId,
			attributes: values.attributes,
		});
	}
};

const FormContent = ({ control }: AavoFormContentParams<FormValues>) => {
	const attributeFieldArray = useFieldArray({
		control,
		name: "attributes",
	});

	return attributeFieldArray.fields.map((field, index) => {
		return (
			<ObjectAttributeFieldFormComponent
				key={field.id}
				control={control}
				name={`attributes.${index}.attributeValue`}
				fieldDefinition={field}
			/>
		);
	});
};
