import { StackedIcon, StackedIconProps } from "src/components/common/icons/StackedIcon.tsx";
import { faLink } from "@fortawesome/pro-regular-svg-icons";
import {  faPlus } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export interface LinkWithPlusProps extends StackedIconProps {}

export const LinkWithPlusIcon = ({ ...other }: LinkWithPlusProps) => {
	return (
		<StackedIcon {...other}>
			<FontAwesomeIcon
				icon={faLink}
				transform={{
					size: 14,
					x: 1,
					y: 1,
				}}
			/>
			<FontAwesomeIcon
				icon={faPlus}
				transform={{
					size: 9,
					x: -5,
					y: -6,
				}}
			/>
		</StackedIcon>
	);
};
