import { SxProps, Theme } from "@mui/material/styles";

const textSize = "1rem";
const iconSize = "1.5rem";
const inputPaddingY = "0.375rem";

export const actionBarSxContentOnly: SxProps<Theme> = {
	"& > *": {
		maxHeight: "2.25rem",
	},
	"& .MuiInputBase-root": {
		fontSize: textSize,
		flex: 1,
		"& .MuiInputBase-input": {
			paddingY: inputPaddingY,
		},
	},
	"& .MuiInputLabel-root": {
		fontSize: textSize,
		"&:not(.MuiInputLabel-shrink)": {
			transform: `translate(14px, ${inputPaddingY}) scale(1)`,
		},
	},
	"& .MuiTextField-root": {
		maxWidth: 150,
	},
	"& .MuiAutocomplete-root": {
		display: "flex",
		"& > .MuiTextField-root": {
			flex: 1,
			maxWidth: "unset",
		},
		"& .MuiInputBase-root": {
			paddingY: 0,
			"& .MuiInputBase-input": {
				paddingY: inputPaddingY,
				maxWidth: "20ch",
			},
		},
		"& .MuiAutocomplete-clearIndicator": {
			backgroundColor: (theme: Theme) => theme.palette.background.paper,
			"&:hover": {
				backgroundColor: (theme: Theme) => theme.palette.grey[200],
			},
		},
		"&.MuiAutocomplete-hasClearIcon.MuiAutocomplete-hasPopupIcon": {
			"& .MuiInputBase-root": {
				paddingRight: "2rem",
			},
		},
		"& .MuiCircularProgress-root": {
			position: "absolute",
			right: "2.5rem",
		},
	},
	"& .AavoNativeSelectRoot": {
		marginTop: "0.25rem", // Leave space for the label
		maxHeight: "2rem",
	},
	"& .MuiButton-root": {
		paddingY: inputPaddingY,
		minHeight: "2rem",
		"& .MuiTypography-root": {
			fontSize: "0.875rem",
			lineHeight: textSize,
		},
		"& .MuiButton-startIcon": {
			"& > svg": {
				fontSize: "1.2rem",
			},
		},
	},
	"& .MuiIconButton-root": {
		fontSize: iconSize,
		paddingX: "0.375rem",
		paddingY: "0.25rem",
	},
};

export const actionBarSx: SxProps = {
	display: "flex",
	alignSelf: "flex-start",
	alignItems: "stretch",
	flexWrap: "wrap",
	paddingX: 0.5,
	paddingY: 1,
	gap: 0.5,
	...actionBarSxContentOnly,
};
