// This file is automatically generated. Do not edit manually.

import { t } from "i18next";

export const PurchaseOrderReceiptStateValues = ["INITIAL", "RECEIPTED"] as const;

export type PurchaseOrderReceiptState = (typeof PurchaseOrderReceiptStateValues)[number];

export const getPurchaseOrderReceiptStateLabels = () => ({
	INITIAL: t("initial"),
	RECEIPTED: t("receipted"),
});

export const getPurchaseOrderReceiptStateLabel = (value: PurchaseOrderReceiptState): string => {
	return getPurchaseOrderReceiptStateLabels()[value];
};
