import {useMemo} from "react";
import {AavoDialog, AavoDialogProps} from "../../common/dialogs/AavoDialog";
import {LegacyModalViewProps} from "./types";
import {LegacyAavoView} from "./LegacyAavoView";
import {resolveHandlebarsTemplate, getHandlebarsParams} from "src/utils/handlebars";
import {getModalViewSize} from "./utils";

export const LegacyModalView = ({
                                    startUpData,
                                    viewDefinition,
                                    modalViewDefinition,
                                    isUppermostView,
                                    ...other
                                }: LegacyModalViewProps & Omit<AavoDialogProps, "size" | "actions" | "title" | "content">) => {
    const title = useMemo(
        () =>
            resolveHandlebarsTemplate(
                viewDefinition.view.displayName,
                getHandlebarsParams(
                    startUpData,
                    viewDefinition.view.staticParams,
                    modalViewDefinition.viewParams,
                ),
            ),
        [viewDefinition, modalViewDefinition, startUpData]
    );
    return (
        <AavoDialog
            title={title}
            size={getModalViewSize(viewDefinition.view)}
            {...other}
        >
            <LegacyAavoView
                startUpData={startUpData}
                viewDefinition={viewDefinition}
                isOnForeground={true}
                isUppermostView={isUppermostView}
                modalViewDefinition={modalViewDefinition}
            />
        </AavoDialog>
    );
};
