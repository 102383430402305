import { KeyValuePair } from "src/utils/keyValuePair.ts";

export const OPEN_FLOATING_VIEW_ACTION_SPECIFIER = "VIEW_COMMON|OPEN_FLOATING_VIEW";
export const SHOW_TOAST_EVENT_ACTION_SPECIFIER = "GLOBAL|SHOW_TOAST";

export interface ViewActionEvent {
	action: any;
	senderViewId: string | null;
	senderSourceViewId: string | null;
	allParams: KeyValuePair[];

	[other: string]: any;
}

export interface VersionedViewActionEventList {
	version: number;
	events: ViewActionEvent[];
}

export interface IncomingViewActionEvents {
	detail: ViewActionEvent[];
}

export const isOpenFloatingViewEvent = (e: ViewActionEvent): boolean => {
	return e.action.actionSpecifier === OPEN_FLOATING_VIEW_ACTION_SPECIFIER;
};

export const isOpenDynamicViewEvent = (e: ViewActionEvent): boolean => {
	return e.action.actionSpecifier === "VIEW_COMMON|OPEN_DYNAMIC_VIEW";
};

export const isOpenToastEvent = (e: ViewActionEvent) => {
	return e.action.actionSpecifier === SHOW_TOAST_EVENT_ACTION_SPECIFIER;
};

export const isCloseUppermostFloatingViewEvent = (e: ViewActionEvent) => {
	return e.action.actionSpecifier === "GLOBAL|CLOSE_UPPERMOST_FLOATING_VIEW";
};

export const isCloseFloatingViewEvent = (e: ViewActionEvent) => {
	return e.action.actionSpecifier === "GLOBAL|CLOSE_FLOATING_VIEW";
};

export const isOpenFrontendModalViewEvent = (e: ViewActionEvent) => {
	return e.action.actionSpecifier === "GLOBAL|OPEN_FRONTEND_VIEW";
};

export const isRefreshSourceViewEvent = (e: ViewActionEvent) => {
	return e.action.actionSpecifier === "VIEW_COMMON|REFRESH" && e.action.target.type === "sourceView";
};
