import {
    MellanoTransactionStatusViewApi,
    MellanoTransactionStatusViewApi_ComponentBatch,
} from "../../../../api/generated/tenants/mellano/api/mellanoTransactionStatusViewApi.ts";
import {AavoDataGrid} from "../../../../components/common/dataGrid/AavoDataGrid.tsx";
import {textColumn} from "../../../../components/common/dataGrid/columns.tsx";
import {ClientSideDataGridModel} from "../../../../components/common/dataGrid/gridModel/ClientSideDataGridModel.tsx";

export interface ComponentBatchesDataGridProps {
	transactionId: number;
	setSelectedBatch: (batch: MellanoTransactionStatusViewApi_ComponentBatch | undefined) => void;
}

export const ComponentBatchesDataGrid = ({
	transactionId,
	setSelectedBatch,
}: ComponentBatchesDataGridProps) => {
	return (
		<ClientSideDataGridModel
			fetchData={() =>
				MellanoTransactionStatusViewApi.getComponentBatches({
					transactionId: transactionId,
				})
			}
			getRows={(data) => data}
			gridId={"86A6835C911D311D"}
			initialParams={{}}
			getRowId={(row) => row.workOrderCode}
			onSelectionChanged={(rows) => setSelectedBatch(rows[0])}
			render={({ dataGridProps }) => {
				return (
					<AavoDataGrid<MellanoTransactionStatusViewApi_ComponentBatch>
						disableMultipleRowSelection
						columns={[
							textColumn({
								field: "workOrderCode",
								headerName: "Koodi",
								width: 200,
							}),
							textColumn({
								field: "workFlowGroup",
								headerName: "Ryhmä",
								width: 250,
							}),
							textColumn({
								field: "firstComponentState",
								headerName: "Tila",
								width: 150,
							}),
						]}
						{...dataGridProps}
					/>
				);
			}}
		/>
	);
};
