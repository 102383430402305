// This file is automatically generated. Do not edit manually.

import { CustomerOrderOffer } from "src/api/generated/erp/db/types/tables/customerOrderOffer";
import { ContractualTerm } from "src/api/generated/erp/db/types/tables/contractualTerm";
import { Customer } from "src/api/generated/erp/db/types/tables/customer";
import { CustomerAddress } from "src/api/generated/erp/db/types/tables/customerAddress";
import { CustomerOrderOfferView } from "src/api/generated/erp/db/types/tables/customerOrderOfferView";
import { DeliveryMethod } from "src/api/generated/erp/db/types/tables/deliveryMethod";
import { DeliveryTerm } from "src/api/generated/erp/db/types/tables/deliveryTerm";
import { PaymentTerm } from "src/api/generated/erp/db/types/tables/paymentTerm";
import { SalespersonView } from "src/api/generated/erp/db/types/tables/salespersonView";
import { Site } from "src/api/generated/erp/db/types/tables/site";
import { VatCode } from "src/api/generated/erp/db/types/tables/vatCode";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace CustomerOrderOfferEditApi {
	export async function getFormInitData(args: {
		customerOrderOfferId: number | null | undefined;
	}): Promise<CustomerOrderOfferEditApi_FormInitData> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/customerOrderOffer/getFormInitData",
			method: "POST",
			data: {
				customerOrderOfferId: args.customerOrderOfferId,
			},
		});
		return response as CustomerOrderOfferEditApi_FormInitData;
	}

	export async function insert(args: { offer: CustomerOrderOffer }): Promise<number> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/customerOrderOffer/insert",
			method: "POST",
			data: {
				offer: args.offer,
			},
		});
		return response as number;
	}

	export async function update(args: {
		offer: CustomerOrderOffer;
		updatePlannedDateToLines: boolean;
		updatePlannedDateToTasks: boolean;
	}): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/customerOrderOffer/update",
			method: "POST",
			data: {
				offer: args.offer,
				updatePlannedDateToLines: args.updatePlannedDateToLines,
				updatePlannedDateToTasks: args.updatePlannedDateToTasks,
			},
		});
		return response as void;
	}

	export async function delete_(args: { customerOrderOfferIds: Array<number> }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/customerOrderOffer/delete",
			method: "POST",
			data: {
				customerOrderOfferIds: args.customerOrderOfferIds,
			},
		});
		return response as void;
	}
}

export interface CustomerOrderOfferEditApi_FormInitData {
	contractualTermOptions: Array<ContractualTerm>;
	customer: Customer | null | undefined;
	customerAddresses: Array<CustomerAddress> | null | undefined;
	customerOrderOffer: CustomerOrderOfferView | null | undefined;
	deliveryMethodOptions: Array<DeliveryMethod>;
	deliveryTermOptions: Array<DeliveryTerm>;
	offerHasAnyLines: boolean;
	paymentTermOptions: Array<PaymentTerm>;
	salespersonOptions: Array<SalespersonView>;
	siteOptions: Array<Site>;
	vatCodeOptions: Array<VatCode>;
}
