import { AsyncFetchRender } from "src/components/common/async/AsyncFetchRender.tsx";
import { ConfigurationComponentForm } from "src/components/views/erp/configurator/managing/productFamilyVersions/components/componentForm/ConfigurationComponentForm.tsx";
import { CenteredTypography } from "src/components/common/CenteredTypography.tsx";
import i18n from "i18next";
import { AavoMosaic } from "src/components/common/mosaic/AavoMosaic.tsx";
import { ConfiguratorManagingApi } from "src/api/generated/erp/configurator/management/api/configuratorManagingApi.ts";
import { useContextOrThrow } from "src/utils/useContextOrThrow.tsx";
import {
	ProductFamilyVersionManagingViewContext,
	ProductFamilyVersionManagingViewContextProvider,
} from "src/components/views/erp/configurator/managing/productFamilyVersions/ProductFamilyVersionManagingViewContext.tsx";
import { NewComponentPalette } from "src/components/views/erp/configurator/managing/productFamilyVersions/components/NewComponentPalette.tsx";
import { getOnlyMember } from "src/utils/arrayUtils.ts";
import { TabComponentTitle } from "src/components/views/erp/configurator/managing/productFamilyVersions/components/tabs/TabComponentTitle.tsx";
import { NewTabComponentButton } from "src/components/views/erp/configurator/managing/productFamilyVersions/components/tabs/NewTabComponentButton.tsx";
import { TabComponentContent } from "src/components/views/erp/configurator/managing/productFamilyVersions/components/tabs/TabComponentContent.tsx";
import { ConfiguratorPropertiesDataGrid } from "src/components/views/erp/configurator/managing/productFamilyVersions/properties/ConfiguratorPropertiesDataGrid.tsx";
import { ProductFamilyVersionTopLevelForm } from "src/components/views/erp/configurator/managing/productFamilyVersions/ProductFamilyVersionTopLevelForm.tsx";
import { DataDirtyStateChangeHandler } from "src/utils/dataDirtyStateChangeHandler.ts";
import { useConfirmDialog } from "src/components/common/dialogs/confirmDialog/useConfirmDialog.ts";
import { confirmUnsavedChangesWillBeLost } from "src/components/common/dialogs/confirmDialog/confirmDialogUtils.ts";
import { useAavoToast } from "src/utils/toast.ts";
import { ProductFamilyVersionManagingViewTestingTab } from "src/components/views/erp/configurator/managing/productFamilyVersions/ProductFamilyVersionManagingViewTestingTab.tsx";
import { ConfigurationProductFamilyVersion } from "src/api/generated/erp/db/types/tables/configurationProductFamilyVersion.ts";

export interface ProductFamilyVersionManagingViewProps {
	productFamilyVersion: ConfigurationProductFamilyVersion;
	onDirtyStateChanged: DataDirtyStateChangeHandler;
	disabled?: boolean;
}

export const ProductFamilyVersionManagingView = (props: ProductFamilyVersionManagingViewProps) => {
	const { productFamilyVersion, onDirtyStateChanged } = props;
	return (
		<AsyncFetchRender
			fetch={() =>
				ConfiguratorManagingApi.getProductFamilyVersionConfiguratorComponents({
					productFamilyVersionId: productFamilyVersion.configurationProductFamilyVersionId,
				})
			}
			content={(components, refresh) => (
				<ProductFamilyVersionManagingViewContextProvider
					productFamilyVersion={productFamilyVersion}
					onDirtyStateChanged={onDirtyStateChanged}
					initialComponents={components}
					refresh={refresh}
				>
					<ViewContent />
				</ProductFamilyVersionManagingViewContextProvider>
			)}
		/>
	);
};

const ViewContent = () => {
	const showConfirmDialog = useConfirmDialog();
	const showToast = useAavoToast();

	const { productFamilyVersionId, focusedDataIsDirty, setFocusedDataIsDirty, productFamilyVersionIsReadOnly } =
		useContextOrThrow(ProductFamilyVersionManagingViewContext);

	return (
		<AavoMosaic
			viewId={"2588E909D4CC18C7"}
			layout={{
				type: "tabs",
				confirmChangeActiveItem: async () => {
					if (focusedDataIsDirty) {
						const confirmed = await confirmUnsavedChangesWillBeLost(showConfirmDialog);
						if (!confirmed) return false;
						setFocusedDataIsDirty(false);
					}
					return true;
				},
				items: [
					{
						type: "panel",
						title: i18n.t("product_family_version"),
						content: (
							<ProductFamilyVersionTopLevelForm
								productFamilyVersionId={productFamilyVersionId}
								disabled={productFamilyVersionIsReadOnly}
								onFormEdited={() => setFocusedDataIsDirty(true)}
								onCompleted={(result) => {
									switch (result.type) {
										case "success": {
											setFocusedDataIsDirty(false);
											showToast(i18n.t("changes_saved"));
											break;
										}
									}
								}}
							/>
						),
					},
					{
						type: "row",
						title: i18n.t("components"),
						first: {
							type: "panel",
							title: i18n.t("preview"),
							content: <ComponentsManagingView />,
						},
						second: {
							type: "row",
							splitPercentage: 70,
							first: {
								type: "panel",
								title: i18n.t("component"),
								content: <SelectedComponentForm />,
							},
							second: {
								type: "panel",
								title: i18n.t("new_component"),
								hidden: productFamilyVersionIsReadOnly,
								content: <NewComponentPalette />,
							},
						},
					},
					{
						type: "panel",
						title: i18n.t("properties"),
						content: (
							<ConfiguratorPropertiesDataGrid
								productFamilyVersionId={productFamilyVersionId}
								editingDisabled={productFamilyVersionIsReadOnly}
							/>
						),
					},
					{
						type: "panel",
						title: i18n.t("testing"),
						content: (
							<ProductFamilyVersionManagingViewTestingTab
								productFamilyVersionId={productFamilyVersionId}
							/>
						),
					},
				],
			}}
		/>
	);
};

const ComponentsManagingView = () => {
	const { allComponents, unselectAllComponents } = useContextOrThrow(ProductFamilyVersionManagingViewContext);
	const tabsWithChildren = allComponents
		.filter((c) => c.componentType === "TAB")
		.toSorted((a, b) => a.orderNum - b.orderNum)
		.map((tab) => ({
			tab,
			children: allComponents.filter((c) => c.parentTabComponentId === tab.configurationComponentId),
		}));

	return (
		<AavoMosaic
			viewId={"B5AD0F149BF2933"}
			layout={{
				type: "tabs",
				confirmChangeActiveItem: async () => {
					const result = await unselectAllComponents();
					return result !== "cancelled";
				},
				items: tabsWithChildren.map(({ tab, children }, idx) => ({
					type: "panel",
					key: tab.configurationComponentId.toString(),
					title: <TabComponentTitle tabComponent={tab} tabIndex={idx} />,
					content: <TabComponentContent tabComponent={tab} tabChildren={children} />,
				})),
			}}
			toolbarExtraComponents={<NewTabComponentButton />}
		/>
	);
};

const SelectedComponentForm = () => {
	const { selectedComponents, updateComponent, setFocusedDataIsDirty, productFamilyVersionIsReadOnly } =
		useContextOrThrow(ProductFamilyVersionManagingViewContext);
	const onlySelectedComponent = getOnlyMember(selectedComponents);

	return onlySelectedComponent == null ?
			<CenteredTypography children={i18n.t("select_a_component")} />
		:	<ConfigurationComponentForm
				key={onlySelectedComponent.configurationComponentId}
				component={onlySelectedComponent}
				backingPropertyId={onlySelectedComponent.backingPropertyId ?? undefined}
				setIsDirty={setFocusedDataIsDirty}
				saveComponent={updateComponent}
				disabled={productFamilyVersionIsReadOnly}
			/>;
};
