import React from "react";
import {
	dataGridPersistentStateProps,
	DEFAULT_GRID_INITIAL_STATE,
} from "src/components/common/dataGrid/dataGridStateStorage.ts";
import { useForwardedRef } from "src/utils/useForwardedRef.ts";
import { GridRowId } from "@mui/x-data-grid-pro";
import { DataGridCsvExportButton } from "src/components/common/dataGrid/csvDownload/DataGridCsvExportButton.tsx";
import { AavoDataGridApi } from "src/components/common/dataGrid/AavoDataGridApi.ts";
import { AavoDataGridProps } from "src/components/common/dataGrid/AavoDataGrid.tsx";
import { getOnlyMember } from "src/utils/arrayUtils.ts";
import { useMaybeControlledState } from "src/utils/useMaybeControlledState.ts";

export interface UseStaticDataGridModelParams<RowData extends object> {
	rows: RowData[];
	getRowId: (row: RowData) => GridRowId;
	gridId: string;
	onSelectionChanged?: (selectedRows: RowData[]) => void;
	selectedRows?: RowData[];
	apiRef?: React.MutableRefObject<AavoDataGridApi<RowData> | null>;
}

export interface UseStaticDataGridModelReturn<RowData extends object> {
	getApi: () => AavoDataGridApi<RowData>;
	selectedRows: RowData[];
	onlySelectedRow: RowData | undefined;
	setSelectedRows: (rows: RowData[]) => void;
	dataGridProps: UseStaticDataGridModelGridProps<RowData>;
}

export type UseStaticDataGridModelGridProps<RowData extends object> = Required<
	Pick<
		AavoDataGridProps<RowData>,
		| "rows"
		| "initialState"
		| "onStateChange"
		| "selectedRows"
		| "onRowSelectionChanged"
		| "getRowId"
		| "actionBarMenuComponents2"
		| "apiRef"
	>
>;

export const useStaticDataGridModel = <RowData extends object>({
	rows,
	gridId,
	getRowId,
	onSelectionChanged: onSelectionChangedProp,
	selectedRows: selectedRowsProp,
	apiRef: apiRefProp,
}: UseStaticDataGridModelParams<RowData>): UseStaticDataGridModelReturn<RowData> => {
	const apiRef = useForwardedRef(apiRefProp);

	const { initialState: initialGridState, onStateChange: onGridStateChange } = dataGridPersistentStateProps(
		gridId,
		DEFAULT_GRID_INITIAL_STATE,
	);

	const [selectedRows, setSelectedRows] = useMaybeControlledState({
		controlledValue: selectedRowsProp,
		onChange: onSelectionChangedProp,
		defaultValue: [],
	});
	const onlySelectedRow = getOnlyMember(selectedRows);

	const onRowSelectionChanged = (selectedRowIds: GridRowId[]) => {
		const selectedRows = rows.filter((row) => selectedRowIds.includes(getRowId(row))) ?? [];
		setSelectedRows(selectedRows);
	};

	const getApi = () => {
		if (!apiRef.current) {
			throw new Error("DataGrid API ref is not set");
		}
		return apiRef.current;
	};

	return {
		dataGridProps: {
			rows: rows,
			apiRef: apiRef,
			initialState: initialGridState,
			onStateChange: onGridStateChange,
			selectedRows: selectedRows.map(getRowId),
			onRowSelectionChanged: onRowSelectionChanged,
			getRowId: getRowId,
			actionBarMenuComponents2: [
				<DataGridCsvExportButton
					key={"dataGridDefaultExportDataButton"}
					dataModel={{
						type: "clientSide",
						rows: rows,
					}}
					getApi={getApi}
				/>,
			],
		},
		selectedRows: selectedRows,
		setSelectedRows: setSelectedRows,
		onlySelectedRow: onlySelectedRow,
		getApi: getApi,
	};
};
