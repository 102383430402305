import { CustomerOrderSalesConditionDataGrid } from "src/components/views/erp/sales/customerOrder/CustomerOrderSalesConditionDataGrid.tsx";

export const AllIncompleteCustomerOrdersSalesConditionsPage = () => {
	return (
		<CustomerOrderSalesConditionDataGrid
			customerOrderId={null}
			onlyIncomplete={true}
			editable={false}
		/>
	);
};
