import i18n from "i18next";
import { openLegacyControlChartStatisticsView } from "src/components/views/legacy/legacyViewAdapters.ts";
import dayjs from "dayjs";
import { OpenInputDialogFunc } from "src/components/common/dialogs/input/useInputDialog.tsx";
import { OpenLegacyViewFunction } from "src/components/views/legacy/useOpenLegacyView.ts";
import { ControlChartSelectionApi } from "src/api/generated/spc/controlChart/api/controlChartSelectionApi.ts";
import { ControlChartActionApi } from "src/api/generated/spc/controlChart/api/controlChartActionApi";
import { concatWithPipe } from "src/utils/strings.tsx";

export async function askInputAndOpenControlChartCapabilityView({
	showInputDialog,
	openLegacyView,
	controlChartId,
	chartName,
	beforeDate,
}: {
	showInputDialog: OpenInputDialogFunc;
	openLegacyView: OpenLegacyViewFunction;
	controlChartId: number;
	chartName: string;
	beforeDate?: dayjs.Dayjs;
}) {
	const sampleSize = await showInputDialog({
		type: "integer",
		title: i18n.t("sample_size"),
		required: true,
		defaultValue: 200,
	});
	if (sampleSize === undefined) return;

	openLegacyControlChartStatisticsView({
		openLegacyView,
		viewTitle: chartName,
		controlChartId: controlChartId,
		sampleSize: sampleSize,
		beforeDate: beforeDate,
	});
}

export async function updateControlChartsChartGroup({
	showInputDialog,
	controlChartId,
}: {
	showInputDialog: OpenInputDialogFunc;
	controlChartId: number;
}) {
	const newChartGroupId = await showInputDialog({
		type: "singleNumberSelect",
		title: i18n.t("select_new_chart_group"),
		required: true,
		defaultValue: null,
		fieldProps: {
			selection: {
				options: async () => {
					const chartGroups = await ControlChartSelectionApi.getControlChartGroups({
						parentGroupId: null,
					});
					return chartGroups.map((chartGroup) => ({
						value: chartGroup.chartGroupId,
						label: concatWithPipe(chartGroup.parentGroupName, chartGroup.name),
					}));
				},
			},
		},
	});
	if (newChartGroupId === undefined) return;

	await ControlChartActionApi.setControlChartGroup({
		controlChartId: controlChartId,
		newControlChartGroupId: newChartGroupId,
	});
}
