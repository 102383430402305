// This file is automatically generated. Do not edit manually.

import { FrontendDownloadFileHandle } from "src/api/generated/common/frontendDownloadFileHandle";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace DeliveryActionApi {
	export async function setDeliveryToDelivered(args: { deliveryId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/delivery/setDeliveryToDelivered",
			method: "POST",
			data: {
				deliveryId: args.deliveryId,
			},
		});
		return response as void;
	}

	export async function revertDeliveryToInitial(args: { deliveryId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/delivery/revertDeliveryToInitial",
			method: "POST",
			data: {
				deliveryId: args.deliveryId,
			},
		});
		return response as void;
	}

	export async function printDeliveryConsignmentNote(args: {
		deliveryId: number;
	}): Promise<FrontendDownloadFileHandle> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/delivery/printDeliveryConsignmentNote",
			method: "POST",
			data: {
				deliveryId: args.deliveryId,
			},
		});
		return response as FrontendDownloadFileHandle;
	}

	export async function printDeliveryNote(args: { deliveryId: number }): Promise<FrontendDownloadFileHandle> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/delivery/printDeliveryNote",
			method: "POST",
			data: {
				deliveryId: args.deliveryId,
			},
		});
		return response as FrontendDownloadFileHandle;
	}
}
