import { AavoForm } from "src/components/common/forms/AavoForm.tsx";
import { FormTextField } from "src/components/common/forms/fields/FormTextField.tsx";
import { emailRule, requireRule } from "src/components/common/forms/validation.ts";
import i18n from "i18next";
import { DocumentBaseFields } from "src/components/views/documents/revisions/types.ts";
import { DocumentSendingApi } from "src/api/generated/documents/api/documentSendingApi.ts";
import { FormCheckbox } from "src/components/common/forms/fields/FormCheckbox.tsx";
import { FormRichTextEditor } from "src/components/common/forms/fields/FormRichTextEditor.tsx";
import { useOAuthAuthentication } from "src/utils/oAuth.ts";

export interface SendDocumentsViewProps {
	documents: DocumentBaseFields[];
	onCompletedSuccessfully: () => void;
}

interface SendDocumentsViewFormValues {
	receiverEmail: string;
	title: string;
	message: string;
	zipFiles: boolean;
}

export const SendDocumentsView = ({ documents, onCompletedSuccessfully }: SendDocumentsViewProps) => {
	const { checkAuthentication } = useOAuthAuthentication(
		DocumentSendingApi.ensureDocumentSendingAuthentication,
	);

	const submitForm = async (values: SendDocumentsViewFormValues) => {
		const authResult = await checkAuthentication();
		if (authResult === "requiresAuthentication") {
			return "interrupted";
		}

		await DocumentSendingApi.sendDocuments({
			mailReceiver: values.receiverEmail,
			title: values.title,
			message: values.message,
			sendAsZipFile: values.zipFiles,
			documentIds: documents.map((d) => d.documentId),
		});
	};

	return (
		<AavoForm
			defaultValues={{
				receiverEmail: "",
				title: "",
				message: "",
			}}
			submit={submitForm}
			submitLabel={i18n.t("send")}
			fitContent={true}
			columns={1}
			onCompleted={(result) => {
				if (result.type === "success") onCompletedSuccessfully();
			}}
			render={({ control }) => (
				<>
					<FormTextField
						control={control}
						name={"receiverEmail"}
						rules={{
							...requireRule(),
							...emailRule(),
						}}
						tooltip={i18n.t("you_can_use_semicolon_to_separate_multiple_email_addresses")}
						label={i18n.t("receiver_mail")}
					/>
					<FormTextField
						control={control}
						name={"title"}
						rules={requireRule()}
						label={i18n.t("title")}
					/>
					<FormRichTextEditor
						control={control}
						name={"message"}
						sx={{
							flex: 1,
							gridColumn: "1 / -1",
						}}
					/>
					<FormCheckbox control={control} name={"zipFiles"} label={i18n.t("send_as_zip_file")} />
				</>
			)}
		/>
	);
};
