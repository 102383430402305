import {ShopOrderLineView} from "src/api/generated/erp/db/types/tables/shopOrderLineView.ts";
import {
	WarehousePickingObjectReservationFormBase
} from "src/components/views/erp/warehouse/inventory/WarehousePickingObjectReservationFormBase.tsx";
import {FormCommonProps} from "src/components/common/forms/types.ts";
import i18n from "i18next";
import {
	ShopOrderBomReservationFormApi
} from "src/api/generated/erp/production/api/shopOrderPicking/shopOrderBomReservationFormApi.ts";

export interface ShopOrderReservationBaseFormContentProps extends FormCommonProps<void> {
	shopOrderBomLines: ShopOrderLineView[];
}

export const ShopOrderReservationBaseForm = ({
	shopOrderBomLines,
	onFormEdited,
	onCompleted,
}: ShopOrderReservationBaseFormContentProps) => {
	const warehousePickingObjects = shopOrderBomLines.filter(shopOrderLineIsReservable).map((line) => {
		const subTitleRefData =
			line.sourceRefData != "" ? `, ${i18n.t("reference")}: ${line.sourceRefData}` : "";
		return {
			pickingObjectId: line.shopOrderLineId,
			partId: line.partId,
			objectTotalValueInWarehouseUnit: line.quantity,
			objectReservedQuantityInWarehouseUnit: line.reservedQuantity,
			partDescription: line.configurationOrPartDescription,
			subTitle: `${i18n.t("order_id")}: ${line.shopOrderId}${subTitleRefData}`,
		};
	});

	return (
		<WarehousePickingObjectReservationFormBase
			warehousePickingObjects={warehousePickingObjects}
			onFormEdited={onFormEdited}
			submit={async (values) =>
				await ShopOrderBomReservationFormApi.createReservations({
					reservationRequests: values.pickingObjects.map((request) => ({
						shopOrderLineId: request.pickingObjectId,
						partWarehouseLocationId: request.partWarehouseLocationId,
						quantityInWarehouseUnit: request.quantityToReserveInWarehouseUnit,
					})),
				})
			}
			onCompleted={onCompleted}
		/>
	);
};

function shopOrderLineIsReservable(line: ShopOrderLineView): boolean {
	if (line.reservedQuantity >= line.quantity) return false;
	switch (line.acquisitionMethod) {
		case "NO_ACQUISITION":
			return true;
		case "WAREHOUSE":
			return true;
		case "MANUFACTURE":
			return line.acquisitionShopOrderState === "READY";
		case "PURCHASE_TRANSIT":
			return line.acquisitionPurchaseOrderLineState === "RECEIVED";
	}
}
