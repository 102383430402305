import { Box, BoxProps } from "@mui/material";
import Typography from "@mui/material/Typography";
import { mergeSx } from "src/utils/styles.ts";

export interface BoxWithTitleProps extends BoxProps {
	title: string;
}

export const BoxWithTitle = ({ title, children, sx, ...other }: BoxWithTitleProps) => {
	return (
		<Box
			sx={mergeSx(
				{
					position: "relative",
					border: "1px solid",
					borderColor: "grey.400",
					borderRadius: 1,
				},
				sx,
			)}
			{...other}
		>
			<Typography
				variant="caption"
				children={title}
				sx={{
					fontSize: "0.75rem",
					color: "grey.600",
					marginTop: `-1.1rem`,
					backgroundColor: "white",
					alignSelf: "flex-start",
					paddingX: 1,
				}}
			/>
			{children}
		</Box>
	);
};
