type NotFunction = object | string | number | boolean | null | undefined;

export type ValueOrProvider<T extends NotFunction> = T | (() => T);

export const resolveValueOrProvider = <T extends NotFunction>(valueProvider: ValueOrProvider<T>): T => {
	if (typeof valueProvider === "function") {
		return valueProvider();
	} else {
		return valueProvider;
	}
};
