// This file is automatically generated. Do not edit manually.

import { t } from "i18next";

export const PartTypeValues = ["PURCHASE", "MANUFACTURE"] as const;

export type PartType = (typeof PartTypeValues)[number];

export const getPartTypeLabels = () => ({
	PURCHASE: t("part_type_purchase"),
	MANUFACTURE: t("part_type_manufacture"),
});

export const getPartTypeLabel = (value: PartType): string => {
	return getPartTypeLabels()[value];
};
