import { ControlledAsyncCrudDataGrid } from "src/components/common/dataGrid/crud/ControlledAsyncCrudDataGrid.tsx";
import { coloredBooleanColumn, floatColumn, textColumn } from "src/components/common/dataGrid/columns.tsx";
import i18n from "i18next";
import { SalesPartView } from "src/api/generated/erp/db/types/tables/salesPartView.ts";
import { DocumentsOfObjectButton } from "src/components/views/documents/objectDocuments/DocumentsOfObjectButton.tsx";
import { nullableAavoObjectRef } from "src/utils/aavoObjectRefUtils.ts";
import { useState } from "react";
import { faCheck } from "@fortawesome/pro-regular-svg-icons";
import { useConfirmDialog } from "src/components/common/dialogs/confirmDialog/useConfirmDialog.ts";
import { OpenObjectChangeLogButton } from "src/components/views/changeLogging/OpenObjectChangeLogButton.tsx";
import { AavoTextField } from "src/components/common/inputFields/AavoTextField.tsx";
import { MenuCheckbox } from "src/components/common/contextMenu/MenuCheckbox.tsx";
import { SalesPartForm } from "src/components/views/erp/sales/basedata/salesParts/SalesPartForm.tsx";
import { SalesPartEditApi } from "src/api/generated/erp/sales/basedata/api/salesPartEditApi.ts";
import { RefreshableElementProps } from "src/utils/useRefreshRef.ts";
import { SalesPartApi } from "src/api/generated/erp/sales/basedata/api/salesPartApi.ts";
import { useServerSideDataGridModel } from "src/components/common/dataGrid/gridModel/useServerSideDataGridModel";
import { AsyncMenuButton } from "src/components/common/contextMenu/AsyncMenuButton.tsx";

export interface SalesPartsDataGridProps extends RefreshableElementProps {
	partId: number | null;
}

export const SalesPartsDataGrid = ({ partId, refreshRef }: SalesPartsDataGridProps) => {
	const [selectedSalesPart, setSelectedSalesPart] = useState<SalesPartView | undefined>(undefined);
	const confirm = useConfirmDialog();
	const { dataGridProps, refreshData, currentParams } = useServerSideDataGridModel({
		gridId: "9AF1255722C287D0",
		fetchData: SalesPartApi.searchSalesParts,
		refreshRef: refreshRef,
		getRowId: (row) => row.salesPartId,
		initialParams: {
			partId: partId,
			searchQuery: "",
			onlyDefaultSite: true,
			showInactiveSalesParts: false,
		},
		onSelectionChanged: (rows) => {
			setSelectedSalesPart(rows[0]);
		},
	});

	return (
		<ControlledAsyncCrudDataGrid<SalesPartView>
			disableMultipleRowSelection
			columns={[
				textColumn({
					field: "salesPartNo",
					headerName: i18n.t("sales_part_no"),
					width: 120,
				}),
				textColumn({
					field: "salesPartDescription",
					headerName: i18n.t("description"),
					width: 200,
				}),
				textColumn({
					field: "salesPartCategoryName",
					headerName: i18n.t("sales_part_category"),
					width: 130,
				}),
				textColumn({
					field: "priceGroupName",
					headerName: i18n.t("sales_price_group"),
					width: 120,
				}),
				floatColumn({
					field: "salesPrice",
					headerName: i18n.t("sales_price"),
				}),
				textColumn({
					field: "salesPriceUnit",
					headerName: i18n.t("sales_price_unit"),
				}),
				textColumn({
					field: "salesUnit",
					headerName: i18n.t("sales_unit"),
				}),
				floatColumn({
					field: "priceFactor",
					headerName: i18n.t("price_factor"),
				}),
				floatColumn({
					field: "salesFactor",
					headerName: i18n.t("sales_factor"),
				}),
				textColumn({
					field: "vatCodeName",
					headerName: i18n.t("vat_code"),
				}),
				coloredBooleanColumn({
					field: "isDefault",
					headerName: i18n.t("default"),
				}),
				coloredBooleanColumn({
					field: "active",
					headerName: i18n.t("active"),
				}),
			]}
			actionBarComponents={
				<>
					<DocumentsOfObjectButton
						objectRef={nullableAavoObjectRef("SALES_PART", selectedSalesPart?.salesPartId)}
					/>
					{partId == null && (
						<AavoTextField
							label={i18n.t("search")}
							onSubmit={async (value) => {
								await refreshData({ searchQuery: value });
							}}
						/>
					)}
				</>
			}
			actionBarMenuComponents={[
				partId == null && (
					<MenuCheckbox
						key={"onlyDefaultSite"}
						label={i18n.t("only_default_site")}
						checked={currentParams.onlyDefaultSite}
						onChange={async (checked) => {
							await refreshData({ onlyDefaultSite: checked });
						}}
					/>
				),
				<MenuCheckbox
					key={"onlyActiveCheckbox"}
					label={i18n.t("show_inactive_sales_parts")}
					checked={currentParams.showInactiveSalesParts}
					onChange={async (checked) => {
						await refreshData({ showInactiveSalesParts: checked });
					}}
				/>,
			]}
			rowContextMenuComponents={({ row, onlySingleRowSelected }) => [
				<OpenObjectChangeLogButton
					key={"changeLog"}
					objectRef={{
						objectType: "SALES_PART",
						objectId: row.salesPartId,
					}}
				/>,
				onlySingleRowSelected && row.active === true && !row.isDefault && (
					<AsyncMenuButton
						key={"setToDefault"}
						icon={faCheck}
						label={i18n.t("set_to_default")}
						onClick={async () => {
							const confirmed = await confirm({
								title: i18n.t("are_you_sure"),
								message: i18n.t("do_you_want_to_set_this_sales_part_as_default"),
							});
							if (!confirmed) return;
							await SalesPartEditApi.setToDefaultSalesPart({
								salesPartId: row.salesPartId,
							});
							await refreshData();
						}}
					/>
				),
			]}
			remove={{
				type: "enabled",
				action: async () => {
					if (selectedSalesPart == null) return;
					await SalesPartEditApi.delete_({
						salesPartId: selectedSalesPart.salesPartId,
					});
					await refreshData();
				},
			}}
			form={{
				dialogSize: "sm",
				dialogTitle: i18n.t("sales_part"),
				addRowEnabled: partId != null,
				editEnabled: true,
				component: ({ row, onCompleted, onFormEdited }) => {
					const partIdToUse = partId ?? row?.partId;
					if (partIdToUse == null) return;
					return (
						<SalesPartForm
							salesPartId={row?.salesPartId ?? null}
							partId={partIdToUse}
							onCompleted={onCompleted}
							onFormEdited={onFormEdited}
						/>
					);
				},
			}}
			{...dataGridProps}
		/>
	);
};
