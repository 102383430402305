import { singleSelectColumn, textColumn } from "../../../../common/dataGrid/columns.tsx";
import i18n from "i18next";
import { CostEventObjectTypeSettingsApi } from "src/api/generated/erp/common/costEvents/costEventObjectTypeSettingsApi.ts";
import { CostEventObjectTypeSettingsView } from "src/api/generated/erp/db/types/tables/costEventObjectTypeSettingsView.ts";
import { ClientSideDataGridModel } from "src/components/common/dataGrid/gridModel/ClientSideDataGridModel.tsx";
import { CrudDataGrid } from "src/components/common/dataGrid/crud/CrudDataGrid.tsx";
import { getCostEventObjectTypeLabel } from "src/api/generated/erp/db/types/enums/costEventObjectType.ts";

export const CostEventObjectTypeSettingsPage = () => {
	return (
		<ClientSideDataGridModel
			fetchData={CostEventObjectTypeSettingsApi.getAll}
			getRows={(data) => data.costEventObjectTypeSettings}
			getRowId={(row) => row.objectType}
			gridId={"A1451E5B97C37F41"}
			initialParams={{}}
			render={({ dataGridProps, data: { priceLists } }) => (
				<CrudDataGrid<CostEventObjectTypeSettingsView>
					columns={[
						textColumn({
							field: "objectType",
							headerName: i18n.t("object"),
							valueGetter: (_, row) => getCostEventObjectTypeLabel(row.objectType),
							width: 200,
						}),
						singleSelectColumn({
							field: "defaultPriceListId",
							headerName: i18n.t("default_price_list"),
							editable: true,
							valueOptions: priceLists.map((priceList) => ({
								value: priceList.costEventPriceListId,
								label: priceList.name,
							})),
							width: 200,
						}),
					]}
					editMode={"row"}
					save={{
						type: "enabled",
						action: async ({ items }) =>
							await CostEventObjectTypeSettingsApi.save({
								costEventObjectTypeSettings: items,
							}),
					}}
					{...dataGridProps}
				/>
			)}
		/>
	);
};
