// This file is automatically generated. Do not edit manually.

import { ProductionLineWorkcenter } from "src/api/generated/erp/db/types/tables/productionLineWorkcenter";
import { UpOrDown } from "src/api/generated/common/upOrDown";
import { ControlChartView } from "src/api/generated/postgres/db/types/public_/tables/controlChartView";
import { ProductionLineWorkcenterView } from "src/api/generated/erp/db/types/tables/productionLineWorkcenterView";
import { Workcenter } from "src/api/generated/erp/db/types/tables/workcenter";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace ProductionLineWorkCentersEditApi {
	export async function getFormInitData(args: {
		productionLineWorkCenterId: number | null | undefined;
		productionLineId: number;
	}): Promise<ProductionLineWorkCentersEditApi_FormInitData> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/productionLine/workCenters/getFormInitData",
			method: "POST",
			data: {
				productionLineWorkCenterId: args.productionLineWorkCenterId,
				productionLineId: args.productionLineId,
			},
		});
		return response as ProductionLineWorkCentersEditApi_FormInitData;
	}

	export async function insert(args: { productionLineWorkcenter: ProductionLineWorkcenter }): Promise<number> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/productionLine/workCenters/insert",
			method: "POST",
			data: {
				productionLineWorkcenter: args.productionLineWorkcenter,
			},
		});
		return response as number;
	}

	export async function update(args: { productionLineWorkcenter: ProductionLineWorkcenter }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/productionLine/workCenters/update",
			method: "POST",
			data: {
				productionLineWorkcenter: args.productionLineWorkcenter,
			},
		});
		return response as void;
	}

	export async function delete_(args: { productionLineWorkcenterIds: Array<number> }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/productionLine/workCenters/delete",
			method: "POST",
			data: {
				productionLineWorkcenterIds: args.productionLineWorkcenterIds,
			},
		});
		return response as void;
	}

	export async function movePosition(args: {
		productionLineWorkcenterId: number;
		direction: UpOrDown;
	}): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/productionLine/workCenters/movePosition",
			method: "POST",
			data: {
				productionLineWorkcenterId: args.productionLineWorkcenterId,
				direction: args.direction,
			},
		});
		return response as void;
	}
}

export interface ProductionLineWorkCentersEditApi_FormInitData {
	controlChartOptions: Array<ControlChartView>;
	productionLineWorkCenter: ProductionLineWorkcenterView | null | undefined;
	workCenterOptions: Array<Workcenter>;
}
