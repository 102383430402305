import { ControlChartParentGroup } from "src/api/generated/postgres/db/types/public_/tables/controlChartParentGroup.ts";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { ChartGroupView } from "src/api/generated/postgres/db/types/public_/tables/chartGroupView.ts";
import { faLayerGroup } from "@fortawesome/pro-regular-svg-icons";
import { FilterableAavoTreeViewItemModel } from "src/components/common/tree/filtering/treeItemFilter.tsx";

export type ChartGroupTreeItemModel = FilterableAavoTreeViewItemModel<
	ChartParentGroupModel | ChartGroupModel
>;

export interface ChartParentGroupModel extends ChartGroupTreeViewBaseProps {
	type: "chartParentGroup";
	parentGroupId: number;
}

export interface ChartGroupModel extends ChartGroupTreeViewBaseProps {
	type: "chartGroup";
	chartGroupId: number;
	parentGroupId: number;
}

interface ChartGroupTreeViewBaseProps {
	id: string;
	label: string;
	icon: IconDefinition;
}

export function mapChartGroupTreeData(
	chartParentGroups: ControlChartParentGroup[],
	chartGroups: ChartGroupView[],
): ChartGroupTreeItemModel[] {
	return chartParentGroups.map((chartParentGroup) => mapChartParentGroup(chartParentGroup));

	function mapChartParentGroup(chartParentGroup: ControlChartParentGroup): ChartGroupTreeItemModel {
		const children = chartGroups
			.filter((chartGroup) => chartGroup.superGroupId === chartParentGroup.id)
			.map((chartGroup) => mapChartGroup(chartGroup));
		return {
			id: `chartParentGroup-${chartParentGroup.id}`,
			label: chartParentGroup.name,
			icon: faLayerGroup,
			type: "chartParentGroup",
			children: children,
			parentGroupId: chartParentGroup.id,
			expand: false,
			directSearchMatch: false,
		};
	}

	function mapChartGroup(chartGroup: ChartGroupView): ChartGroupTreeItemModel {
		return {
			id: `chartGroup-${chartGroup.id}`,
			label: chartGroup.name,
			icon: faLayerGroup,
			type: "chartGroup",
			children: [],
			chartGroupId: chartGroup.id,
			parentGroupId: chartGroup.superGroupId,
			expand: false,
			directSearchMatch: false,
		};
	}
}
