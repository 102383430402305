// This file is automatically generated. Do not edit manually.

import { ConfigurationComponent } from "src/api/generated/erp/db/types/tables/configurationComponent";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace ConfiguratorComponentEditApi {
	export async function insertComponent(args: {
		component: ConfigurationComponent;
		createProperty?: boolean;
	}): Promise<ConfigurationComponent> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/configurator/managing/insertComponent",
			method: "POST",
			data: {
				component: args.component,
				createProperty: args.createProperty,
			},
		});
		return response as ConfigurationComponent;
	}

	export async function updateComponent(args: {
		component: ConfigurationComponent;
	}): Promise<ConfigurationComponent> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/configurator/managing/updateComponent",
			method: "POST",
			data: {
				component: args.component,
			},
		});
		return response as ConfigurationComponent;
	}

	export async function deleteComponent(args: { componentId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/configurator/managing/deleteComponent",
			method: "POST",
			data: {
				componentId: args.componentId,
			},
		});
		return response as void;
	}

	export async function moveComponentToPosition(args: { componentId: number; newPosition: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/configurator/managing/moveComponentToPosition",
			method: "POST",
			data: {
				componentId: args.componentId,
				newPosition: args.newPosition,
			},
		});
		return response as void;
	}

	export async function moveComponentsToTab(args: {
		componentIds: Array<number>;
		newParentTabId: number;
	}): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/configurator/managing/moveComponentsToTab",
			method: "POST",
			data: {
				componentIds: args.componentIds,
				newParentTabId: args.newParentTabId,
			},
		});
		return response as void;
	}
}
