// This file is automatically generated. Do not edit manually.

import { CompanyView } from "src/api/generated/erp/db/types/tables/companyView";
import { Company } from "src/api/generated/erp/db/types/tables/company";
import { EInvoiceOperator } from "src/api/generated/erp/db/types/tables/eInvoiceOperator";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace CompanyApi {
	export async function getFormData(args: {
		companyId: number | null | undefined;
	}): Promise<CompanyApi_CompanyInitData> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/common/companies/getFormData",
			method: "POST",
			data: {
				companyId: args.companyId,
			},
		});
		return response as CompanyApi_CompanyInitData;
	}

	export async function getAll(): Promise<Array<CompanyView>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/common/companies/getAll",
			method: "POST",
			data: {},
		});
		return response as Array<CompanyView>;
	}

	export async function save(args: { company: Company }): Promise<Company> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/common/companies/save",
			method: "POST",
			data: {
				company: args.company,
			},
		});
		return response as Company;
	}

	export async function delete_(args: { companies: Array<Company> }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/common/companies/delete",
			method: "POST",
			data: {
				companies: args.companies,
			},
		});
		return response as void;
	}
}

export interface CompanyApi_CompanyInitData {
	company: Company | null | undefined;
	eInvoiceOperatorOptions: Array<EInvoiceOperator>;
}
