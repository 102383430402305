// This file is automatically generated. Do not edit manually.

import { CatalogPartRevisionLineView } from "src/api/generated/erp/db/types/tables/catalogPartRevisionLineView";
import { CatalogPartRevisionLine } from "src/api/generated/erp/db/types/tables/catalogPartRevisionLine";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace CatalogPartRevisionBomDataGridApi {
	export async function getCatalogPartRevisionBomLines(args: {
		catalogPartRevisionId: number;
		searchQuery?: string;
	}): Promise<Array<CatalogPartRevisionLineView>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/productManagement/catalogPartRevisionBom/getCatalogPartRevisionBomLines",
			method: "POST",
			data: {
				catalogPartRevisionId: args.catalogPartRevisionId,
				searchQuery: args.searchQuery,
			},
		});
		return response as Array<CatalogPartRevisionLineView>;
	}

	export async function save(args: {
		revisionLines: Array<CatalogPartRevisionLine>;
	}): Promise<Array<CatalogPartRevisionLineView>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/productManagement/catalogPartRevisionBom/save",
			method: "POST",
			data: {
				revisionLines: args.revisionLines,
			},
		});
		return response as Array<CatalogPartRevisionLineView>;
	}

	export async function delete_(args: { revisionLines: Array<CatalogPartRevisionLine> }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/productManagement/catalogPartRevisionBom/delete",
			method: "POST",
			data: {
				revisionLines: args.revisionLines,
			},
		});
		return response as void;
	}
}
