import { Box, BoxProps } from "@mui/material";
import { actionBarSx } from "src/components/common/actionBar/actionBarStyles.ts";
import { mergeSx } from "src/utils/styles.ts";

export interface AavoActionBarProps extends BoxProps {}

export const AavoActionBar = ({ sx, children, ...other }: AavoActionBarProps) => {
	return (
		<Box sx={mergeSx(actionBarSx, sx)} {...other}>
			{children}
		</Box>
	);
};
