import { DeliveryPackagesDataGrid } from "../../delivery/deliveryPackage/DeliveryPackagesDataGrid.tsx";

export const AllProjectDeliveryPackagesPage = () => {
	return (
		<DeliveryPackagesDataGrid
			deliverySourceType={"PROJECT"}
			gridId={"DD5080D67212A7DC"}
			editable
			showSearchField
			showDeliveryStateSelection
		/>
	);
};
