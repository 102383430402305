// This file is automatically generated. Do not edit manually.

import { ProjectState } from "src/api/generated/erp/db/types/enums/projectState";
import { ServerSideDataModelRequest } from "src/api/generated/common/dataGrids/serverSideDataModelRequest";
import { ServerSideDataModelResult } from "src/api/generated/common/dataGrids/serverSideDataModelResult";
import { ProjectActivityCostEventView } from "src/api/generated/erp/db/types/tables/projectActivityCostEventView";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace ProjectCostEventsDataGridsApi {
	export async function getGridDataForMultipleProjects(args: {
		projectIds: Array<number>;
		projectStates: Array<ProjectState>;
		dataModelRequest: ServerSideDataModelRequest;
	}): Promise<ProjectCostEventsDataGridsApi_GridData> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/project/costEvents/getGridDataForMultipleProjects",
			method: "POST",
			data: {
				projectIds: args.projectIds,
				projectStates: args.projectStates,
				dataModelRequest: args.dataModelRequest,
			},
		});
		return response as ProjectCostEventsDataGridsApi_GridData;
	}

	export async function getGridDataForProject(args: {
		projectId: number;
		dataModelRequest: ServerSideDataModelRequest;
	}): Promise<ProjectCostEventsDataGridsApi_GridData> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/project/costEvents/getGridDataForProject",
			method: "POST",
			data: {
				projectId: args.projectId,
				dataModelRequest: args.dataModelRequest,
			},
		});
		return response as ProjectCostEventsDataGridsApi_GridData;
	}

	export async function getGridDataForSubProject(args: {
		subProjectId: number;
		dataModelRequest: ServerSideDataModelRequest;
	}): Promise<ProjectCostEventsDataGridsApi_GridData> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/project/costEvents/getGridDataForSubProject",
			method: "POST",
			data: {
				subProjectId: args.subProjectId,
				dataModelRequest: args.dataModelRequest,
			},
		});
		return response as ProjectCostEventsDataGridsApi_GridData;
	}
}

export interface ProjectCostEventsDataGridsApi_GridData {
	costEventsData: ServerSideDataModelResult<ProjectActivityCostEventView>;
	totalCosts: number;
	totalPrice: number;
}
