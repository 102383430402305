import { PartConfigurationView } from "src/api/generated/erp/db/types/tables/partConfigurationView.ts";
import { useClientSideDataGridModel } from "src/components/common/dataGrid/gridModel/useClientSideDataGridModel";
import { ControlledAsyncCrudDataGrid } from "src/components/common/dataGrid/crud/ControlledAsyncCrudDataGrid";
import { PartConfigurationOperationApi } from "src/api/generated/erp/configurator/api/partConfigurationOperationApi.ts";
import { floatColumn, integerColumn, textColumn } from "src/components/common/dataGrid/columns.tsx";
import i18n from "i18next";
import { PartConfigurationOperationView } from "src/api/generated/erp/db/types/tables/partConfigurationOperationView.ts";
import { PartConfigurationOperationForm } from "src/components/views/erp/configurator/inspecting/PartConfigurationOperationForm.tsx";

export interface PartConfigurationOperationsDataGridProps {
	partConfiguration: PartConfigurationView;
	editable: boolean;
}

export const PartConfigurationOperationsDataGrid = ({
	partConfiguration,
	editable,
}: PartConfigurationOperationsDataGridProps) => {
	const { dataGridProps } = useClientSideDataGridModel({
		fetchData: () =>
			PartConfigurationOperationApi.getPartConfigurationOperations({
				partConfigurationId: partConfiguration.partConfigurationId,
			}),
		getRowId: (row) => row.partConfigurationOperationId,
		gridId: "CBAF55A178B53A68",
		initialParams: {},
	});

	return (
		<ControlledAsyncCrudDataGrid<PartConfigurationOperationView>
			columns={[
				integerColumn({
					field: "position",
					headerName: i18n.t("number_shortened"),
					width: 40,
				}),
				textColumn({
					field: "operationLongName",
					headerName: i18n.t("operation"),
					width: 300,
				}),
				textColumn({
					field: "materialOperationLongName",
					headerName: i18n.t("material_operation"),
					width: 300,
				}),
				floatColumn({
					field: "setUpTime",
					headerName: i18n.t("set_up_time_hours"),
				}),
				floatColumn({
					field: "workingTime",
					headerName: i18n.t("working_time_hours"),
				}),
			]}
			form={{
				component: ({ row, onCompleted, onFormEdited }) => (
					<PartConfigurationOperationForm
						partConfiguration={partConfiguration}
						partConfigurationOperation={row}
						onCompleted={onCompleted}
						onFormEdited={onFormEdited}
					/>
				),
				editEnabled: editable,
				addRowEnabled: editable,
				dialogSize: "md",
				dialogTitle: i18n.t("operation"),
			}}
			remove={
				editable ?
					{
						type: "enabled",
						action: ({ items }) =>
							PartConfigurationOperationApi.deleteOperations({
								partConfigurationOperationIds: items.map(
									(item) => item.partConfigurationOperationId,
								),
							}),
					}
				:	{ type: "hidden" }
			}
			{...dataGridProps}
		/>
	);
};
