import { useClientSideDataGridModel } from "src/components/common/dataGrid/gridModel/useClientSideDataGridModel.tsx";
import { CustomerOrderTypeApi } from "src/api/generated/erp/sales/basedata/api/customerOrderTypeApi.ts";
import i18n from "i18next";
import { booleanColumn, textColumn } from "src/components/common/dataGrid/columns.tsx";
import { CustomerOrderType } from "src/api/generated/erp/db/types/tables/customerOrderType.ts";
import { ControlledAsyncCrudDataGrid } from "src/components/common/dataGrid/crud/ControlledAsyncCrudDataGrid.tsx";
import { CustomerOrderTypeEditApi } from "src/api/generated/erp/sales/basedata/api/customerOrderTypeEditApi.ts";
import { CustomerOrderTypeForm } from "src/components/views/erp/sales/basedata/customerOrderTypes/CustomerOrderTypeForm.tsx";

export const CustomerOrderTypesDataGrid = () => {
	const { dataGridProps } = useClientSideDataGridModel({
		fetchData: () => CustomerOrderTypeApi.getAll(),
		initialParams: {},
		getRowId: (row) => row.customerOrderTypeId,
		gridId: "10DF11DB14D19766",
	});

	return (
		<ControlledAsyncCrudDataGrid<CustomerOrderType>
			columns={[
				textColumn({
					field: "name",
					headerName: i18n.t("name"),
					width: 200,
				}),
				textColumn({
					field: "description",
					headerName: i18n.t("description"),
					width: 300,
				}),
				booleanColumn({
					field: "automaticReservation",
					headerName: i18n.t("automatic_reservation"),
					width: 300,
				}),
			]}
			form={{
				addRowEnabled: true,
				editEnabled: true,
				dialogSize: "sm",
				dialogTitle: i18n.t("customer_order_type"),
				component: ({ row, onCompleted, onFormEdited }) => (
					<CustomerOrderTypeForm
						customerOrderTypeId={row?.customerOrderTypeId}
						onCompleted={onCompleted}
						onFormEdited={onFormEdited}
					/>
				),
			}}
			remove={({ items }) =>
				CustomerOrderTypeEditApi.delete_({
					customerOrderTypeIds: items.map((item) => item.customerOrderTypeId),
				})
			}
			{...dataGridProps}
		/>
	);
};
