import { faArrowLeft } from "@fortawesome/pro-regular-svg-icons";
import {
	AavoIconButton,
	AavoIconButtonProps,
} from "../../common/buttons/AavoIconButton";
import { useNavigate } from "react-router-dom";

export const NavigateBackButton = (
	props: Omit<AavoIconButtonProps, "onClick" | "icon" | "color" | "border">
) => {
	const navigate = useNavigate();

	return (
		<AavoIconButton
			icon={faArrowLeft}
			color={"primary"}
			onClick={() => {
				navigate(-1);
			}}
			{...props}
		/>
	);
};
