// This file is automatically generated. Do not edit manually.

import { t } from "i18next";

export const PurchaseOrderStateValues = [
	"INITIAL",
	"RELEASED",
	"CONFIRMED",
	"PARTIALLY_RECEIVED",
	"RECEIVED",
	"CANCELLED",
] as const;

export type PurchaseOrderState = (typeof PurchaseOrderStateValues)[number];

export const getPurchaseOrderStateLabels = () => ({
	INITIAL: t("initial"),
	RELEASED: t("released"),
	CONFIRMED: t("confirmed"),
	PARTIALLY_RECEIVED: t("partially_received"),
	RECEIVED: t("received"),
	CANCELLED: t("cancelled"),
});

export const getPurchaseOrderStateLabel = (value: PurchaseOrderState): string => {
	return getPurchaseOrderStateLabels()[value];
};
