// This file is automatically generated. Do not edit manually.

import { t } from "i18next";

export const WarehouseTransferStateValues = [
	"INITIAL",
	"RELEASED",
	"RESERVED",
	"PICKED",
	"TRANSFERRED",
	"CANCELLED",
] as const;

export type WarehouseTransferState = (typeof WarehouseTransferStateValues)[number];

export const getWarehouseTransferStateLabels = () => ({
	INITIAL: t("initial"),
	RELEASED: t("released"),
	RESERVED: t("reserved"),
	PICKED: t("picked"),
	TRANSFERRED: t("transferred"),
	CANCELLED: t("cancelled"),
});

export const getWarehouseTransferStateLabel = (value: WarehouseTransferState): string => {
	return getWarehouseTransferStateLabels()[value];
};
