import { TasksView } from "src/components/views/tasks/TasksView.tsx";

export const AllTasksPage = () => {
	return (
		<TasksView
			taskQueryParams={{}}
			showCanBeStarted={false}
			showIsInSchedulePlanning={false}
			showTaskSourceInfo={true}
			showAssignedTo={true}
			showResponsiblePerson={true}
			showGanttButton={false}
		></TasksView>
	);
};
