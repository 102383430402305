import {ClientSideDataGridModel} from "src/components/common/dataGrid/gridModel/ClientSideDataGridModel.tsx";
import {
    ConfigurationProductFamilyCategoryApi
} from "src/api/generated/erp/configurator/management/productFamilies/api/configurationProductFamilyCategoryApi.ts";
import {CrudDataGrid} from "src/components/common/dataGrid/crud/CrudDataGrid.tsx";
import {textColumn} from "src/components/common/dataGrid/columns.tsx";
import i18n from "i18next";
import {
    ConfigurationProductFamilyCategory
} from "src/api/generated/erp/db/types/tables/configurationProductFamilyCategory.ts";
import {
    ConfigurationProductFamilyCategoryForm
} from "src/components/views/erp/configurator/managing/productFamilyCategories/ConfigurationProductFamilyCategoryForm.tsx";

export const ConfigurationProductFamilyCategoriesDataGrid = () => {
	return (
		<ClientSideDataGridModel
			gridId={"E6525F7356D8F99D"}
			fetchData={ConfigurationProductFamilyCategoryApi.getCategories}
			initialParams={{}}
			getRows={(data) => data}
			getRowId={(row) => row.configurationProductFamilyCategoryId}
			render={({ dataGridProps }) => (
				<CrudDataGrid<ConfigurationProductFamilyCategory>
					columns={[
						textColumn({
							field: "name",
							headerName: i18n.t("name"),
							width: 150,
						}),
					]}
					form={{
						addRowEnabled: true,
						editEnabled: true,
						dialogSize: "sm",
						dialogTitle: i18n.t("category"),
						component: ({ row, onCompleted, onFormEdited }) => (
							<ConfigurationProductFamilyCategoryForm
								productFamilyCategory={row}
								onCompleted={onCompleted}
								onFormEdited={onFormEdited}
							/>
						),
					}}
					remove={({ row }) =>
						ConfigurationProductFamilyCategoryApi.delete_({
							productFamilyCategoryId: row.configurationProductFamilyCategoryId,
						})
					}
					{...dataGridProps}
				/>
			)}
		/>
	);
};
