// This file is automatically generated. Do not edit manually.

import { IsoDateString } from "src/types/dateTime";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace ProjectActionsApi {
	export async function releaseProject(args: {
		projectId: number;
		releaseSubProjectsAndActivities: boolean;
		releaseTasks: boolean;
	}): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/project/releaseProject",
			method: "POST",
			data: {
				projectId: args.projectId,
				releaseSubProjectsAndActivities: args.releaseSubProjectsAndActivities,
				releaseTasks: args.releaseTasks,
			},
		});
		return response as void;
	}

	export async function revertCompleteProject(args: { projectId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/project/revertCompleteProject",
			method: "POST",
			data: {
				projectId: args.projectId,
			},
		});
		return response as void;
	}

	export async function completeProject(args: { projectId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/project/completeProject",
			method: "POST",
			data: {
				projectId: args.projectId,
			},
		});
		return response as void;
	}

	export async function cancelProject(args: { projectId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/project/cancelProject",
			method: "POST",
			data: {
				projectId: args.projectId,
			},
		});
		return response as void;
	}

	export async function cloneProject(args: {
		projectId: number;
		newProjectName: string;
		newProjectPlannedStartDate: IsoDateString;
	}): Promise<number> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/project/cloneProject",
			method: "POST",
			data: {
				projectId: args.projectId,
				newProjectName: args.newProjectName,
				newProjectPlannedStartDate: args.newProjectPlannedStartDate,
			},
		});
		return response as number;
	}
}
