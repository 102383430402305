import {PartialTenantCustomizations} from "src/tenantCustomizations/TenantCustomizations.ts";
import {
    getInventoryLevelEventQuantitySpcSamplingMethod
} from "src/components/views/erp/spcSamplingMethods/InventoryLevelEventQuantitySpcSamplingMethod.tsx";

export const necoCustomizations: PartialTenantCustomizations = {
	tenantConfig: {
		erp: {
			enabled: true,
			reclamationsEnabled: true,
			configuratorEnabled: true,
			defaultCustomerType: "COMPANY",
		},
		tasks: {
			enabled: true,
		},
		documentsEnabled: true,
	},
	spcSamplingMethods: () => [getInventoryLevelEventQuantitySpcSamplingMethod()],
};
