// This file is automatically generated. Do not edit manually.

import { FrontendUploadFileHandle } from "src/api/generated/common/frontendUploadFileHandle";
import { ImportDocumentsToCustomerOrderLinesResult } from "src/api/generated/erp/sales/customerOrder/service/importDocumentsToCustomerOrderLinesResult";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace CustomerOrderDocumentImportApi {
	export async function importDocumentsToCustomerOrderLines(args: {
		customerOrderId: number;
		categoryId: number;
		fileHandles: Array<FrontendUploadFileHandle>;
	}): Promise<ImportDocumentsToCustomerOrderLinesResult> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/customerOrder/importDocumentsToCustomerOrderLines",
			method: "POST",
			data: {
				customerOrderId: args.customerOrderId,
				categoryId: args.categoryId,
				fileHandles: args.fileHandles,
			},
		});
		return response as ImportDocumentsToCustomerOrderLinesResult;
	}
}
