import {SalesContract} from "src/api/generated/erp/db/types/tables/salesContract";
import {CustomerSalesContractEditApi} from "src/api/generated/erp/sales/pricing/api/customerSalesContractEditApi";
import {AavoForm} from "src/components/common/forms/AavoForm.tsx";
import {FormCommonProps} from "src/components/common/forms/types.ts";
import {FormTextField} from "src/components/common/forms/fields/FormTextField.tsx";
import {FormDateField} from "src/components/common/forms/fields/FormDateField.tsx";
import i18n from "i18next";
import {requireRule} from "src/components/common/forms/validation.ts";

export interface CustomerSalesContractFormProps extends FormCommonProps<number> {
	customerId: number;
	contract?: SalesContract;
}

export const CustomerSalesContractForm = ({
	customerId,
	contract,
	onCompleted,
	onFormEdited,
}: CustomerSalesContractFormProps) => {
	return (
		<AavoForm<SalesContract, number>
			onCompleted={onCompleted}
			onFormEdited={onFormEdited}
			defaultValues={{
				customerId: customerId,
				...contract
			}}
			submit={(values) => {
				if (contract == null) {
					return CustomerSalesContractEditApi.insert({
						contract: values,
					});
				} else {
					CustomerSalesContractEditApi.update({
						contract: values,
					});
					return contract.salesContractId;
				}
			}}
			render={({ control }) => (
				<>
					<FormTextField control={control} name="name" label={i18n.t("name")} rules={requireRule()} />
					<FormDateField
						control={control}
						name={"activeFrom"}
						label={i18n.t("active_from")}
						rules={requireRule()}
					/>
					<FormDateField
						control={control}
						name={"activeTo"}
						label={i18n.t("active_to")}
						rules={requireRule()}
					/>
				</>
			)}
		/>
	);
};
