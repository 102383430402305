import { Box, BoxProps } from "@mui/material";
import { forwardRef } from "react";
import { mergeSx } from "src/utils/styles.ts";

export const VerticalBox = forwardRef(({ sx, ...other }: BoxProps, ref) => {
	return (
		<Box
			ref={ref}
			sx={mergeSx(
				{
					display: "flex",
					flexDirection: "column",
					minWidth: 0,
					minHeight: 0,
				},
				sx,
			)}
			{...other}
		/>
	);
});
