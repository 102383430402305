import { ObjectAccountingDimensionValue } from "src/api/generated/erp/sales/accounting/objectAccountingDimensionValue";
import {
	ObjectAccountingFormDimensionValues,
	ObjectAccountingFormValues,
} from "src/components/views/erp/sales/accounting/ObjectAccountingForm.tsx";
import { DeepPartial } from "react-hook-form";
import { filterNulls } from "src/utils/arrayUtils.ts";

export function getObjectAccountingFormDefaultValues(
	dimensionValues: ObjectAccountingDimensionValue[],
): DeepPartial<ObjectAccountingFormValues> {
	return {
		accountingDimensionValues: dimensionValues.map((dimensionValue) => ({
			dimension: dimensionValue.dimension,
			accountingCodeId: dimensionValue.accountingCode?.accountingCodeId,
		})),
	};
}

export function getObjectAccountingCodeIdsFromFormValues(
	accountingDimensionValues: ObjectAccountingFormDimensionValues[],
): number[] {
	return filterNulls(accountingDimensionValues.map((dimensionValue) => dimensionValue.accountingCodeId));
}
