// This file is automatically generated. Do not edit manually.

import { IsoDateString } from "src/types/dateTime";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace ProjectSchedulingApi {
	export async function scheduleProject(args: {
		projectId: number;
		newPlannedStartDate: IsoDateString;
		newPlannedEndDate: IsoDateString | null | undefined;
		inheritToDescendants: boolean;
	}): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/projects/scheduling/scheduleProject",
			method: "POST",
			data: {
				projectId: args.projectId,
				newPlannedStartDate: args.newPlannedStartDate,
				newPlannedEndDate: args.newPlannedEndDate,
				inheritToDescendants: args.inheritToDescendants,
			},
		});
		return response as void;
	}

	export async function scheduleSubProject(args: {
		subProjectId: number;
		newPlannedStartDate: IsoDateString;
		newPlannedEndDate: IsoDateString | null | undefined;
		inheritToDescendants: boolean;
	}): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/projects/scheduling/scheduleSubProject",
			method: "POST",
			data: {
				subProjectId: args.subProjectId,
				newPlannedStartDate: args.newPlannedStartDate,
				newPlannedEndDate: args.newPlannedEndDate,
				inheritToDescendants: args.inheritToDescendants,
			},
		});
		return response as void;
	}

	export async function scheduleProjectActivity(args: {
		activityId: number;
		newPlannedStartDate: IsoDateString;
		newPlannedEndDate: IsoDateString | null | undefined;
		inheritToDescendants: boolean;
	}): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/projects/scheduling/scheduleProjectActivity",
			method: "POST",
			data: {
				activityId: args.activityId,
				newPlannedStartDate: args.newPlannedStartDate,
				newPlannedEndDate: args.newPlannedEndDate,
				inheritToDescendants: args.inheritToDescendants,
			},
		});
		return response as void;
	}
}
