// This file is automatically generated. Do not edit manually.

import { t } from "i18next";

export const WarehouseTypeValues = ["PICK", "SHIPMENT"] as const;

export type WarehouseType = (typeof WarehouseTypeValues)[number];

export const getWarehouseTypeLabels = () => ({
	PICK: t("pick"),
	SHIPMENT: t("shipment"),
});

export const getWarehouseTypeLabel = (value: WarehouseType): string => {
	return getWarehouseTypeLabels()[value];
};
