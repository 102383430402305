import { ConfiguratorTableRevision } from "src/api/generated/erp/db/types/tables/configuratorTableRevision.ts";
import { AsyncFetchRender } from "src/components/common/async/AsyncFetchRender.tsx";
import { AavoMosaic } from "src/components/common/mosaic/AavoMosaic.tsx";
import { ConfiguratorTableTabDataGridStateful } from "src/components/views/erp/configurator/tables/revisionContentView/tabDataGrid/ConfiguratorTableTabDataGridStateful.tsx";
import { ConfiguratorTableTab } from "src/api/generated/erp/db/types/tables/configuratorTableTab.ts";
import { ConfiguratorTableTabContentQueryApi } from "src/api/generated/erp/configurator/tables/api/configuratorTableTabContentQueryApi.ts";
import { NewConfiguratorTableTabButton } from "src/components/views/erp/configurator/tables/revisionContentView/NewConfiguratorTableTabButton.tsx";
import { ConfiguratorTableTabMosaicTitle } from "./ConfiguratorTableTabMosaicTitle";
import { ConfiguratorTableTabsApi } from "src/api/generated/erp/configurator/tables/api/configuratorTableTabsApi.ts";

export interface ConfiguratorTableRevisionContentViewProps {
	revision: ConfiguratorTableRevision;
}

export const ConfiguratorTableRevisionContentView = (props: ConfiguratorTableRevisionContentViewProps) => {
	const { revision } = props;
	return (
		<AsyncFetchRender
			fetch={() =>
				ConfiguratorTableTabsApi.getRevisionTabs({
					configuratorTableRevisionId: revision.configuratorTableRevisionId,
				})
			}
			content={(tabs, refresh) => (
				<AavoMosaic
					sx={{
						marginTop: 1,
					}}
					layout={{
						type: "tabs",
						items: tabs.map((tab) => ({
							type: "panel",
							key: tab.configuratorTableTabId.toString(),
							title: <ConfiguratorTableTabMosaicTitle tab={tab} refreshTabs={refresh} />,
							content: <ConfiguratorTableTabAsyncView tab={tab} />,
						})),
					}}
					toolbarExtraComponents={
						<NewConfiguratorTableTabButton
							configuratorTableRevisionId={revision.configuratorTableRevisionId}
							refreshTabs={refresh}
						/>
					}
				/>
			)}
		/>
	);
};

interface ConfiguratorTableTabAsyncViewProps {
	tab: ConfiguratorTableTab;
}

const ConfiguratorTableTabAsyncView = ({ tab }: ConfiguratorTableTabAsyncViewProps) => {
	return (
		<AsyncFetchRender
			fetch={() =>
				ConfiguratorTableTabContentQueryApi.getTabContent({
					configuratorTableTabId: tab.configuratorTableTabId,
				})
			}
			content={(tabContent, refresh) => (
				<ConfiguratorTableTabDataGridStateful tab={tab} tabContent={tabContent} refresh={refresh} />
			)}
		/>
	);
};
