import { AavoMenuItem } from "src/components/common/contextMenu/AavoMenuItem.tsx";
import {
	AavoCheckbox,
	AavoCheckboxProps,
} from "src/components/common/inputFields/AavoCheckbox.tsx";
import { mergeSx } from "src/utils/styles.ts";

export const MenuCheckbox = ({ sx, ...other }: AavoCheckboxProps) => {
	return (
		<AavoMenuItem>
			<AavoCheckbox
				{...other}
				sx={mergeSx(
					{
						paddingRight: 2,
					},
					sx,
				)}
			/>
		</AavoMenuItem>
	);
};
