import { PurchaseRequest } from "src/api/generated/erp/db/types/tables/purchaseRequest.ts";
import i18n from "i18next";
import { FormCommonProps } from "src/components/common/forms/types.ts";
import { FormDateField } from "src/components/common/forms/fields/FormDateField.tsx";
import {
	PurchaseRequestFormApi,
	PurchaseRequestFormApi_InitialData
} from "src/api/generated/erp/purchase/purchaseRequest/purchaseRequestFormApi.ts";
import { FormSelectField } from "src/components/common/forms/fields/FormSelectField.tsx";
import { FormTextField } from "src/components/common/forms/fields/FormTextField.tsx";
import { requiredNonNegativeFloatRule, requireRule } from "src/components/common/forms/validation.ts";
import { AsyncForm } from "src/components/common/forms/AsyncForm.tsx";

export interface PurchaseRequestFormProps extends FormCommonProps<number> {
	purchaseRequestId: number;
}

export const PurchaseRequestForm = ({
	purchaseRequestId,
	onFormEdited,
	onCompleted,
}: PurchaseRequestFormProps) => {
	return (
		<AsyncForm<PurchaseRequestFormApi_InitialData, PurchaseRequest, number>
			fetch={() => PurchaseRequestFormApi.getInitialData({ purchaseRequestId })}
			getDefaultValues={({ purchaseRequest }) => purchaseRequest}
			submit={submitForm}
			onCompleted={onCompleted}
			onFormEdited={onFormEdited}
			render={({ control, data: { supplierPartIdOptions } }) => (
				<>
					<FormDateField
						control={control}
						name={"demandDate"}
						label={i18n.t("demand_date")}
						rules={requireRule()}
					/>
					<FormSelectField
						control={control}
						name={"supplierPartId"}
						label={i18n.t("supplier")}
						disableClearable
						options={supplierPartIdOptions}
						getOptionKey={(o) => o.supplierPartId}
						getOptionLabel={(o) => o.supplierName}
						rules={requireRule()}
					/>
					<FormTextField
						control={control}
						name={"quantity"}
						label={i18n.t("quantity")}
						type={"number"}
						rules={requiredNonNegativeFloatRule()}
					/>
				</>
			)}
		/>
	);
};

const submitForm = async (purchaseRequest: PurchaseRequest): Promise<number> => {
	const result = await PurchaseRequestFormApi.update({ purchaseRequest });
	return result.purchaseRequestId;
};
