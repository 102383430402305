// This file is automatically generated. Do not edit manually.

import { ServerSideDataModelRequest } from "src/api/generated/common/dataGrids/serverSideDataModelRequest";
import { ServerSideDataModelResult } from "src/api/generated/common/dataGrids/serverSideDataModelResult";
import { SalesPriceListLineView } from "src/api/generated/erp/db/types/tables/salesPriceListLineView";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace SalesPriceListLinesDataGridApi {
	export async function getDataGridData(args: {
		salesPriceListId: number;
		siteIdFilter: number | null | undefined;
		onlyActiveLines: boolean;
		searchQuery: string;
		dataModelRequest: ServerSideDataModelRequest;
	}): Promise<ServerSideDataModelResult<SalesPriceListLineView>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/pricing/salesPriceLists/lines/dataGrid/getDataGridData",
			method: "POST",
			data: {
				salesPriceListId: args.salesPriceListId,
				siteIdFilter: args.siteIdFilter,
				onlyActiveLines: args.onlyActiveLines,
				searchQuery: args.searchQuery,
				dataModelRequest: args.dataModelRequest,
			},
		});
		return response as ServerSideDataModelResult<SalesPriceListLineView>;
	}
}
