// This file is automatically generated. Do not edit manually.

import { AavoObjectRef } from "src/api/generated/common/sourceType/aavoObjectRef";
import { NewDocumentFileCreationType } from "src/api/generated/documents/newDocumentFileCreationType";
import { FrontendUploadFileHandle } from "src/api/generated/common/frontendUploadFileHandle";
import { AavoObjectType } from "src/api/generated/common/sourceType/aavoObjectType";
import { DocumentCategory } from "src/api/generated/postgres/db/types/documents/tables/documentCategory";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace DocumentCreationApi {
	export async function createDocument(args: {
		objectRef: AavoObjectRef | null | undefined;
		description1: string;
		categoryId?: number | null | undefined;
		note?: string;
		fileCreationType: NewDocumentFileCreationType;
		uploadedFile: FrontendUploadFileHandle | null | undefined;
	}): Promise<number> {
		const response = await makeAxiosRequest({
			url: "/api/ext/documents/createDocument",
			method: "POST",
			data: {
				objectRef: args.objectRef,
				description1: args.description1,
				categoryId: args.categoryId,
				note: args.note,
				fileCreationType: args.fileCreationType,
				uploadedFile: args.uploadedFile,
			},
		});
		return response as number;
	}

	export async function getDefaultDocumentCategoryForNewDocument(args: {
		objectType: AavoObjectType | null | undefined;
	}): Promise<DocumentCategory> {
		const response = await makeAxiosRequest({
			url: "/api/ext/documents/getDefaultDocumentCategoryForNewDocument",
			method: "POST",
			data: {
				objectType: args.objectType,
			},
		});
		return response as DocumentCategory;
	}

	export async function getNewDocumentDescription2(args: {
		objectRef: AavoObjectRef | null | undefined;
	}): Promise<string> {
		const response = await makeAxiosRequest({
			url: "/api/ext/documents/getNewDocumentDescription2",
			method: "POST",
			data: {
				objectRef: args.objectRef,
			},
		});
		return response as string;
	}
}
