import { AavoToastContent } from "./types";
import React from "react";

export interface AavoToastContextState extends AavoToastContent {
	open: boolean;
}

const AAVO_TOAST_CONTEXT_DEFAULT: AavoToastContextState = {
	open: false,
	title: undefined,
	message: undefined
};

export type Action =
	| {
	type: "displayContent";
	payload: AavoToastContent;
}
	| {
	type: "openToast";
}
	| {
	type: "closeToast";
};

type Dispatch = (action: Action) => void;

export const AavoToastContext = React.createContext<
	{ state: AavoToastContextState; dispatch: Dispatch } | undefined
>(undefined);

export const AavoToastDispatchContext = React.createContext<Dispatch | undefined>(
	undefined
);

function aavoToastReducer(
	state: AavoToastContextState,
	action: Action
): AavoToastContextState {
	switch (action.type) {
		case "openToast":
			return {
				...state,
				open: true
			};
		case "closeToast":
			return {
				...state,
				open: false
			};
		case "displayContent":
			return {
				...state,
				title: action.payload.title,
				message: action.payload.message,
				open: true
			};
	}
}

type AavoToastProviderProps = {
	children: React.ReactNode;
};

export function AavoToastContextProvider({ children }: AavoToastProviderProps) {
	const [state, dispatch] = React.useReducer(
		aavoToastReducer,
		AAVO_TOAST_CONTEXT_DEFAULT
	);
	const value = { state, dispatch };
	return (
		<AavoToastContext.Provider value={value}>
			<AavoToastDispatchContext.Provider value={dispatch}>
				{children}
			</AavoToastDispatchContext.Provider>
		</AavoToastContext.Provider>
	);
}