// This file is automatically generated. Do not edit manually.

import { t } from "i18next";

export const VatHandlingValues = ["NORMAL_VAT", "REVERSE_CHARGE_VAT"] as const;

export type VatHandling = (typeof VatHandlingValues)[number];

export const getVatHandlingLabels = () => ({
	NORMAL_VAT: t("normal_vat"),
	REVERSE_CHARGE_VAT: t("reverse_charge_vat"),
});

export const getVatHandlingLabel = (value: VatHandling): string => {
	return getVatHandlingLabels()[value];
};
