// This file is automatically generated. Do not edit manually.

import { DeliveryPackageType } from "src/api/generated/erp/db/types/tables/deliveryPackageType";
import { WarehouseLocation } from "src/api/generated/erp/db/types/tables/warehouseLocation";
import { ShopOrderView } from "src/api/generated/erp/db/types/tables/shopOrderView";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace ShopOrderCompletionFormApi {
	export async function getFormInitData(args: {
		shopOrderId: number;
	}): Promise<ShopOrderCompletionFormApi_FormInitData> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/shopOrderPackagingForm/getFormInitData",
			method: "POST",
			data: {
				shopOrderId: args.shopOrderId,
			},
		});
		return response as ShopOrderCompletionFormApi_FormInitData;
	}

	export async function submit(args: {
		shopOrderId: number;
		quantityToCompleteInBaseUnit: number;
		closeShopOrder: boolean;
		shopOrderOperationIdToComplete: number | null | undefined;
		packageCustomerOrderLineRequest:
			| ShopOrderCompletionFormApi_CustomerOrderLinePackagingRequest
			| null
			| undefined;
	}): Promise<ShopOrderCompletionFormApi_SubmitResult> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/shopOrderPackagingForm/submit",
			method: "POST",
			data: {
				shopOrderId: args.shopOrderId,
				quantityToCompleteInBaseUnit: args.quantityToCompleteInBaseUnit,
				closeShopOrder: args.closeShopOrder,
				shopOrderOperationIdToComplete: args.shopOrderOperationIdToComplete,
				packageCustomerOrderLineRequest: args.packageCustomerOrderLineRequest,
			},
		});
		return response as ShopOrderCompletionFormApi_SubmitResult;
	}
}

export interface ShopOrderCompletionFormApi_FormInitData {
	defaultPackageDescription: string;
	packageTypeOptions: Array<DeliveryPackageType>;
	packageWarehouseLocationOptions: Array<WarehouseLocation>;
	shopOrder: ShopOrderView;
}

export interface ShopOrderCompletionFormApi_CustomerOrderLinePackagingRequest {
	customerOrderLineId: number;
	height: number | null | undefined;
	length: number | null | undefined;
	packageDescription: string;
	packageTypeId: number;
	packageWarehouseLocationId: number;
	weight: number | null | undefined;
	width: number | null | undefined;
}

export interface ShopOrderCompletionFormApi_SubmitResult {
	createdPackageId: number | null | undefined;
}
