import {CenteredTypography} from "../../../../common/CenteredTypography.tsx";
import {AsyncFetchRender} from "../../../../common/async/AsyncFetchRender.tsx";
import i18n from "i18next";
import {getCountryLabel} from "../../../../../api/generated/erp/db/types/enums/country.ts";
import {CustomerOrderQueryApi} from "../../../../../api/generated/erp/sales/customerOrder/api/customerOrderQueryApi.ts";

export interface CustomerOrderInfoViewProps {
	customerOrderId: number;
}

export const CustomerOrderContactInfoView = ({ customerOrderId }: CustomerOrderInfoViewProps) => {
	return (
		<AsyncFetchRender
			fetch={() => CustomerOrderQueryApi.getCustomerOrder({ customerOrderId: customerOrderId })}
			content={(customerOrder) => {
				const address =
					customerOrder.deliveryAddress_2 != null ?
						`${customerOrder.deliveryAddress_1}, ${customerOrder.deliveryAddress_2}`
					:	`${customerOrder.deliveryAddress_1}`;

				return (
					<CenteredTypography
						lineHeight={2}
					>
						<b> {`${i18n.t("contact_person")}: `}</b>
						{`${customerOrder.contactName}`} <br />
						<b> {`${i18n.t("email")}: `}</b>
						{`${customerOrder.contactEmail}`} <br />
						<b> {`${i18n.t("phone")}: `}</b>
						{`${customerOrder.contactPhone}`} <br />
						<b> {`${i18n.t("address")}: `}</b> <br/>
						{address} <br />
						{`${customerOrder.deliveryPostalCode} ${customerOrder.deliveryCity}`} <br />
						{`${getCountryLabel(customerOrder.deliveryCountry)}`} <br />
					</CenteredTypography>
				);
			}}
		/>
	);
};
