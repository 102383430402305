import {ClientSideDataGridModel} from "src/components/common/dataGrid/gridModel/ClientSideDataGridModel.tsx";
import {CustomerOrderLineView} from "src/api/generated/erp/db/types/tables/customerOrderLineView.ts";
import {enumColumn, floatColumn, integerColumn, textColumn,} from "src/components/common/dataGrid/columns.tsx";
import i18n from "i18next";
import {getCustomerOrderLineStateLabels} from "src/api/generated/erp/db/types/enums/customerOrderLineState.ts";
import {getSalesPartTypeLabels} from "src/api/generated/erp/db/types/enums/salesPartType.ts";
import {getAcquisitionMethodLabels} from "src/api/generated/erp/db/types/enums/acquisitionMethod.ts";
import {CrudDataGrid} from "src/components/common/dataGrid/crud/CrudDataGrid.tsx";
import {MenuCheckbox} from "src/components/common/contextMenu/MenuCheckbox.tsx";
import {
    ReclamationCustomerOrdersDataGridApi
} from "src/api/generated/erp/reclamations/api/reclamationCustomerOrdersDataGridApi.ts";
import {AsyncMenuButton} from "src/components/common/contextMenu/AsyncMenuButton.tsx";
import {useGenericDialog} from "src/components/common/dialogs/useGenericDialog.ts";
import {faShare} from "@fortawesome/pro-regular-svg-icons";

export interface ReclamationCustomerOrderLinesDataGridProps {
	customerOrderId: number;
	parentLineId: number | null;
}

export const ReclamationCustomerOrderLinesDataGrid = ({
	customerOrderId,
	parentLineId,
}: ReclamationCustomerOrderLinesDataGridProps) => {
	const { openDialog } = useGenericDialog();
	return (
		<ClientSideDataGridModel
			gridId={"2191916BD808E59F"}
			fetchData={() =>
				ReclamationCustomerOrdersDataGridApi.getReclamationCustomerOrderLines({
					customerOrderId: customerOrderId,
					parentLineId: parentLineId,
				})
			}
			getRows={(data) => data}
			getRowId={(row) => row.customerOrderLineId}
			initialParams={{
				searchQuery: "",
				showCancelledLines: false,
			}}
			render={({ refreshData, dataGridProps, currentParams, selectedRows }) => {
				const onlySingleRowSelected = selectedRows.length === 1;
				return (
					<CrudDataGrid<CustomerOrderLineView>
						columns={[
							integerColumn({
								field: "lineNumber",
								headerName: i18n.t("number_shortened"),
								width: 50,
							}),
							textColumn({
								field: "configurationOrPartDescription",
								headerName: i18n.t("part"),
								width: 250,
							}),
							floatColumn({
								field: "salesQuantityAsSalesUnits",
								headerName: i18n.t("ordered_quantity"),
							}),
							textColumn({
								field: "salesUnit",
								headerName: i18n.t("sales_unit"),
							}),
							textColumn({
								field: "mark",
								headerName: i18n.t("mark"),
								width: 200,
							}),
							enumColumn({
								field: "customerOrderLineState",
								headerName: i18n.t("state"),
								enumLabels: getCustomerOrderLineStateLabels(),
							}),
							enumColumn({
								field: "salesPartType",
								headerName: i18n.t("type"),
								enumLabels: getSalesPartTypeLabels(),
								width: 75,
							}),
							enumColumn({
								field: "acquisitionMethod",
								headerName: i18n.t("acquisition_method"),
								enumLabels: getAcquisitionMethodLabels(),
								width: 125,
							}),
							parentLineId != null &&
								textColumn({
									field: "packageNumber_1",
									headerName: i18n.t("package_number"),
								}),
							parentLineId != null &&
								textColumn({
									field: "packageNumber_2",
									headerName: i18n.t("sequence_number"),
								}),
						]}
						actionBarMenuComponents={[
							<MenuCheckbox
								key={"showCancelled"}
								label={i18n.t("show_cancelled_items")}
								checked={currentParams.showCancelledLines}
								onChange={(checked) => refreshData({ showCancelledLines: checked })}
							/>,
						]}
						rowContextMenuComponents={({row}) => [
							onlySingleRowSelected &&
								parentLineId == null &&
								row.salesPartType === "PACKAGE_" && (
									<AsyncMenuButton
										key={"childLines"}
										label={i18n.t("package_lines")}
										icon={faShare}
										onClick={async () => {
											openDialog(() => ({
												title: i18n.t("package_lines"),
												size: "xl",
												content: (
													<ReclamationCustomerOrderLinesDataGrid
														customerOrderId={customerOrderId}
														parentLineId={row.customerOrderLineId}
													/>
												),
											}));
										}}
									/>
								),
						]}
						{...dataGridProps}
					/>
				);
			}}
		/>
	);
};
