// This file is automatically generated. Do not edit manually.

import { FrontendDownloadFileHandle } from "src/api/generated/common/frontendDownloadFileHandle";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace SalesCommissionPaymentApi {
	export async function createPayments(args: { commissionLineIds: Array<number> }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/commissions/payments/createPayments",
			method: "POST",
			data: {
				commissionLineIds: args.commissionLineIds,
			},
		});
		return response as void;
	}

	export async function approvePayment(args: { salesCommissionPaymentId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/commissions/payments/approvePayment",
			method: "POST",
			data: {
				salesCommissionPaymentId: args.salesCommissionPaymentId,
			},
		});
		return response as void;
	}

	export async function addCommissionLinesToPayment(args: {
		salesCommissionPaymentId: number;
		commissionLineIds: Array<number>;
	}): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/commissions/payments/addCommissionLinesToPayment",
			method: "POST",
			data: {
				salesCommissionPaymentId: args.salesCommissionPaymentId,
				commissionLineIds: args.commissionLineIds,
			},
		});
		return response as void;
	}

	export async function removeCommissionLinesFromPayment(args: {
		salesCommissionPaymentId: number;
		commissionLineIds: Array<number>;
	}): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/commissions/payments/removeCommissionLinesFromPayment",
			method: "POST",
			data: {
				salesCommissionPaymentId: args.salesCommissionPaymentId,
				commissionLineIds: args.commissionLineIds,
			},
		});
		return response as void;
	}

	export async function printSalesCommissionPayment(args: {
		salesCommissionPaymentId: number;
	}): Promise<FrontendDownloadFileHandle> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/commissions/payments/printSalesCommissionPayment",
			method: "POST",
			data: {
				salesCommissionPaymentId: args.salesCommissionPaymentId,
			},
		});
		return response as FrontendDownloadFileHandle;
	}
}
