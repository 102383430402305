import { ProductionLineCapacityEditApi } from "src/api/generated/erp/production/basedata/productionLines/api/productionLineCapacityEditApi.ts";
import { IsoDateString } from "src/types/dateTime.ts";
import { currentLocalDateAsIsoString, dayJsToDateIsoString } from "src/utils/dayjsUtils.ts";
import { requireRule } from "src/components/common/forms/validation.ts";
import { FormNumberField } from "src/components/common/forms/fields/FormNumberField.tsx";
import { FormDateField } from "src/components/common/forms/fields/FormDateField.tsx";
import dayjs from "dayjs";
import { AsyncForm } from "src/components/common/forms/AsyncForm.tsx";
import i18n from "i18next";
import { FormCommonProps } from "src/components/common/forms/types.ts";
import { AavoFormContentParams } from "src/components/common/forms/AavoForm.tsx";
import { DeepPartial } from "react-hook-form";

export interface GenerateProductionLineCapacityFormProps extends FormCommonProps<void> {
	productionLineId: number;
}

interface FormValues {
	startDate: IsoDateString;
	dayCount: number;
	weekdayCapacity: number;
	saturdayCapacity: number;
	sundayCapacity: number;
}

export const GenerateProductionLineCapacityForm = (props: GenerateProductionLineCapacityFormProps) => {
	const { productionLineId, onCompleted, onFormEdited } = props;
	return (
		<AsyncForm
			fetch={() => ProductionLineCapacityEditApi.getProductionLineMaxCapacityDate({ productionLineId })}
			getDefaultValues={getDefaultValues}
			submit={submit}
			onCompleted={onCompleted}
			onFormEdited={onFormEdited}
			render={(contentParams) => <FormContent {...props} {...contentParams} />}
		/>
	);

	function getDefaultValues(currentMaxCapacityDate: IsoDateString | null): DeepPartial<FormValues> {
		return {
			startDate:
				currentMaxCapacityDate ?
					dayJsToDateIsoString(dayjs(currentMaxCapacityDate).add(1, "day"))
				:	currentLocalDateAsIsoString(),
		};
	}

	async function submit(values: FormValues) {
		return ProductionLineCapacityEditApi.generateCapacityDays({
			productionLineId,
			...values,
		});
	}
};

interface FormContentProps extends GenerateProductionLineCapacityFormProps, AavoFormContentParams<FormValues> {}

const FormContent = ({ control }: FormContentProps) => {
	return (
		<>
			<FormDateField control={control} name={"startDate"} label={i18n.t("start_date")} rules={requireRule()} />
			<FormNumberField
				control={control}
				name={"dayCount"}
				label={i18n.t("day_count")}
				rules={requireRule()}
				type={"integer"}
			/>
			<FormNumberField
				control={control}
				name={"weekdayCapacity"}
				label={i18n.t("weekday_capacity")}
				rules={requireRule()}
			/>
			<FormNumberField
				control={control}
				name={"saturdayCapacity"}
				label={i18n.t("saturday_capacity")}
				rules={requireRule()}
			/>
			<FormNumberField
				control={control}
				name={"sundayCapacity"}
				label={i18n.t("sunday_capacity")}
				rules={requireRule()}
			/>
		</>
	);
};
