import {
	SalesContractPriceGroupDiscountsApi,
	SalesContractPriceGroupDiscountsApi_FormInitData,
} from "src/api/generated/erp/sales/pricing/api/salesContractPriceGroupDiscountsApi.ts";
import { SalesContractPriceGroupDiscount } from "src/api/generated/erp/db/types/tables/salesContractPriceGroupDiscount.ts";
import { FormSelectField } from "src/components/common/forms/fields/FormSelectField.tsx";
import { FormNumberField } from "src/components/common/forms/fields/FormNumberField.tsx";
import { AsyncForm } from "src/components/common/forms/AsyncForm.tsx";
import i18n from "i18next";
import { requireRule } from "src/components/common/forms/validation.ts";
import { FormCommonProps } from "src/components/common/forms/types.ts";
import { DeepPartial } from "react-hook-form";

export interface SalesContractPriceGroupDiscountFormProps extends FormCommonProps<number> {
	salesContractId: number;
	discountId: number | undefined;
}

interface FormValues extends SalesContractPriceGroupDiscount {}

export const SalesContractPriceGroupDiscountForm = (props: SalesContractPriceGroupDiscountFormProps) => {
	const { salesContractId, discountId, onCompleted, onFormEdited } = props;
	return (
		<AsyncForm
			fetch={() =>
				SalesContractPriceGroupDiscountsApi.getFormInitData({
					salesContractId: salesContractId,
					salesContractPriceGroupDiscountId: discountId,
				})
			}
			getDefaultValues={getDefaultValues}
			submit={submit}
			onCompleted={onCompleted}
			onFormEdited={onFormEdited}
			render={({ control, data: { priceGroupOptions } }) => (
				<>
					<FormSelectField
						control={control}
						name={"salesPartPriceGroupId"}
						label={i18n.t("price_group")}
						options={priceGroupOptions}
						getOptionKey={(option) => option.salesPartPriceGroupId}
						getOptionLabel={(option) => option.name}
						rules={requireRule()}
					/>
					<FormNumberField
						control={control}
						name={"discountPercentage"}
						label={i18n.t("discount_percent")}
						rules={requireRule()}
					/>
				</>
			)}
		/>
	);

	function getDefaultValues({ discount }: SalesContractPriceGroupDiscountsApi_FormInitData): DeepPartial<FormValues> {
		return (
			discount ?? {
				salesContractId: salesContractId,
			}
		);
	}

	async function submit(values: FormValues) {
		if (discountId != null) {
			await SalesContractPriceGroupDiscountsApi.update({ discount: values });
			return discountId;
		} else {
			return await SalesContractPriceGroupDiscountsApi.insert({ discount: values });
		}
	}
};
