// This file is automatically generated. Do not edit manually.

import { SalesCommissionLineName } from "src/api/generated/erp/db/types/tables/salesCommissionLineName";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace SalesCommissionLineNamesApi {
	export async function getSalesCommissionLineNames(): Promise<Array<SalesCommissionLineName>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/commissions/lineNames/getSalesCommissionLineNames",
			method: "POST",
			data: {},
		});
		return response as Array<SalesCommissionLineName>;
	}

	export async function insert(args: { name: string }): Promise<number> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/commissions/lineNames/insert",
			method: "POST",
			data: {
				name: args.name,
			},
		});
		return response as number;
	}

	export async function update(args: { salesCommissionLineNameId: number; name: string }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/commissions/lineNames/update",
			method: "POST",
			data: {
				salesCommissionLineNameId: args.salesCommissionLineNameId,
				name: args.name,
			},
		});
		return response as void;
	}

	export async function delete_(args: { salesCommissionLineNameId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/commissions/lineNames/delete",
			method: "POST",
			data: {
				salesCommissionLineNameId: args.salesCommissionLineNameId,
			},
		});
		return response as void;
	}
}
