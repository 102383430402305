import { AutocompleteRenderInputParams } from "@mui/material";

export const encodeNativeSelectKey = <T>(key: T): string => {
	return JSON.stringify({ key: key });
};

export const decodeNativeSelectKey = (value: string): any => {
	const key = JSON.parse(value).key;
	if (key === "") return null;
	return key;
};

export const getSingleSelectAutocompleteInputWidthStyle = (
	label: string,
	renderInputParams: AutocompleteRenderInputParams,
) => {
	let textLength;
	const inputValue = renderInputParams.inputProps.value;
	if (typeof inputValue === "string" && inputValue !== "") {
		textLength = inputValue.length;
	} else {
		textLength = label.length;
	}
	return {
		minWidth: 0,
		width: `${textLength}ch`,
	};
};

export const getMultiSelectAutocompleteInputWidthStyle = (label: string, value: any[], ) => {
	let width;
	if (value.length > 0) {
		width = "2rem";
	} else {
		width = `${label.length}ch`;
	}
	return {
		minWidth: 0,
		width: width,
	};
};
