// This file is automatically generated. Do not edit manually.

import { SurveySubmissionObjectRef } from "src/api/generated/erp/surveys/model/surveySubmissionObjectRef";
import { SurveyFieldValue } from "src/api/generated/io/aavo/applications/db/erp/types/surveyFieldValue";
import { SurveyFieldSubmission } from "src/api/generated/erp/db/types/tables/surveyFieldSubmission";
import { SurveyFormField } from "src/api/generated/erp/db/types/tables/surveyFormField";
import { SurveyFormRevision } from "src/api/generated/erp/db/types/tables/surveyFormRevision";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace SurveySubmissionFormApi {
	export async function getFormInitData(args: {
		surveyFormId: number;
		surveyFormRevisionId: number | null | undefined;
		surveySubmissionId: number | null | undefined;
	}): Promise<SurveySubmissionFormApi_FormInitData> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/surveys/submission/surveySubmissionForm/getFormInitData",
			method: "POST",
			data: {
				surveyFormId: args.surveyFormId,
				surveyFormRevisionId: args.surveyFormRevisionId,
				surveySubmissionId: args.surveySubmissionId,
			},
		});
		return response as SurveySubmissionFormApi_FormInitData;
	}

	export async function submit(args: {
		surveyFormId: number;
		surveySubmissionId: number | null | undefined;
		objectRef: SurveySubmissionObjectRef | null | undefined;
		valuesByFieldIds: Record<number, SurveyFieldValue | null | undefined>;
	}): Promise<number> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/surveys/submission/surveySubmissionForm/submit",
			method: "POST",
			data: {
				surveyFormId: args.surveyFormId,
				surveySubmissionId: args.surveySubmissionId,
				objectRef: args.objectRef,
				valuesByFieldIds: args.valuesByFieldIds,
			},
		});
		return response as number;
	}
}

export interface SurveySubmissionFormApi_FormInitData {
	fieldSubmissions: Array<SurveyFieldSubmission>;
	fields: Array<SurveyFormField>;
	submitAllowed: boolean;
	surveyFormRevision: SurveyFormRevision;
}
