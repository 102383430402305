// This file is automatically generated. Do not edit manually.

import { ObjectPermission } from "src/api/generated/iam/model/objectPermission";
import { ObjectMemberType } from "src/api/generated/iam/model/objectMemberType";
import { AppRole } from "src/api/generated/postgres/db/types/role_management/tables/appRole";
import { AppUser } from "src/api/generated/postgres/db/types/role_management/tables/appUser";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace SurveyFormPermissionApi {
	export async function getSurveyFormPermissions(args: { surveyFormId: number }): Promise<Array<ObjectPermission>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/surveys/management/surveyFormPermissions/getSurveyFormPermissions",
			method: "POST",
			data: {
				surveyFormId: args.surveyFormId,
			},
		});
		return response as Array<ObjectPermission>;
	}

	export async function getFormInitData(): Promise<SurveyFormPermissionApi_FormInitData> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/surveys/management/surveyFormPermissions/getFormInitData",
			method: "POST",
			data: {},
		});
		return response as SurveyFormPermissionApi_FormInitData;
	}

	export async function savePermission(args: {
		surveyFormId: number;
		memberType: ObjectMemberType;
		memberId: number;
		allowChecking: boolean;
		allowApproving: boolean;
	}): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/surveys/management/surveyFormPermissions/savePermission",
			method: "POST",
			data: {
				surveyFormId: args.surveyFormId,
				memberType: args.memberType,
				memberId: args.memberId,
				allowChecking: args.allowChecking,
				allowApproving: args.allowApproving,
			},
		});
		return response as void;
	}

	export async function deletePermission(args: {
		surveyFormId: number;
		memberType: ObjectMemberType;
		memberId: number;
	}): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/surveys/management/surveyFormPermissions/deletePermission",
			method: "POST",
			data: {
				surveyFormId: args.surveyFormId,
				memberType: args.memberType,
				memberId: args.memberId,
			},
		});
		return response as void;
	}
}

export interface SurveyFormPermissionApi_FormInitData {
	roleOptions: Array<AppRole>;
	userOptions: Array<AppUser>;
}
