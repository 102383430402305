// This file is automatically generated. Do not edit manually.

import { t } from "i18next";

export const DayOfWeekValues = ["MONDAY", "TUESDAY", "WEDNESDAY", "THURSDAY", "FRIDAY", "SATURDAY", "SUNDAY"] as const;

export type DayOfWeek = (typeof DayOfWeekValues)[number];

export const getDayOfWeekLabels = () => ({
	MONDAY: t("monday_short"),
	TUESDAY: t("tuesday_short"),
	WEDNESDAY: t("wednesday_short"),
	THURSDAY: t("thursday_short"),
	FRIDAY: t("friday_short"),
	SATURDAY: t("saturday_short"),
	SUNDAY: t("sunday_short"),
});

export const getDayOfWeekLabel = (value: DayOfWeek): string => {
	return getDayOfWeekLabels()[value];
};
