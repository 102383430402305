import { FormCommonProps } from "src/components/common/forms/types.ts";
import { ProjectActivity } from "src/api/generated/erp/db/types/tables/projectActivity.ts";
import { AsyncForm, AsyncFormContentParams } from "src/components/common/forms/AsyncForm.tsx";
import { DeepPartial } from "react-hook-form";
import {
	ProjectActivityEditApi,
	ProjectActivityEditApi_FormInitData,
} from "src/api/generated/erp/project/projectActivity/api/projectActivityEditApi.ts";
import { FormAsyncUserSelectField } from "src/components/views/users/FormAsyncUserSelectField.tsx";
import { requireRule } from "src/components/common/forms/validation.ts";
import i18n from "i18next";
import { FormTextField } from "src/components/common/forms/fields/FormTextField.tsx";
import { FormSelectField } from "src/components/common/forms/fields/FormSelectField.tsx";
import { FormDateField } from "src/components/common/forms/fields/FormDateField.tsx";
import { FormCheckbox } from "src/components/common/forms/fields/FormCheckbox.tsx";

export interface ProjectActivityFormProps extends FormCommonProps<number> {
	subProjectId: number;
	activityId: number | null;
}

interface FormValues extends ProjectActivity {
	inheritScheduleChange: boolean;
}

export const ProjectActivityForm = (props: ProjectActivityFormProps) => {
	const { subProjectId, activityId, onCompleted, onFormEdited } = props;
	return (
		<AsyncForm
			fetch={() =>
				ProjectActivityEditApi.getFormInitData({
					subProjectId,
					activityId,
				})
			}
			getDefaultValues={getDefaultValues}
			submit={submit}
			onCompleted={onCompleted}
			onFormEdited={onFormEdited}
			columns={2}
			render={(contentParams) => <FormContent {...props} {...contentParams} />}
		/>
	);

	function getDefaultValues({
		subProject,
		activity,
	}: ProjectActivityEditApi_FormInitData): DeepPartial<FormValues> {
		if (activity != null)
			return {
				...activity,
				inheritScheduleChange: true,
			};
		else
			return {
				subProjectId: subProjectId,
				responsiblePersonId: subProject.responsiblePersonId,
				plannedStartDate: subProject.plannedStartDate,
				plannedEndDate: subProject.plannedEndDate,
				surveyTasksEnabled: false,
				scheduleOnChainedRelease: true,
			};
	}

	async function submit({ inheritScheduleChange, ...activity }: FormValues) {
		if (activityId != null) {
			await ProjectActivityEditApi.update({
				activity,
				inheritScheduleChange,
			});
			return activityId;
		} else
			return await ProjectActivityEditApi.insert({
				activity,
			});
	}
};

interface FormContentProps
	extends ProjectActivityFormProps,
		AsyncFormContentParams<ProjectActivityEditApi_FormInitData, FormValues> {}

const FormContent = ({
	activityId,
	control,
	watch,
	data: { activityTypeOptions, resourceOptions, subProjectOtherActivities },
	formState: { dirtyFields },
}: FormContentProps) => {
	const isExistingRecord = activityId != null;
	return (
		<>
			<FormTextField
				control={control}
				name={"activityName"}
				label={i18n.t("name")}
				rules={requireRule()}
			/>
			<FormAsyncUserSelectField
				control={control}
				name={"responsiblePersonId"}
				label={i18n.t("responsible_person")}
				rules={requireRule()}
			/>
			<FormSelectField
				control={control}
				name={"activityTypeId"}
				label={i18n.t("type")}
				options={activityTypeOptions}
				getOptionKey={(o) => o.activityTypeId}
				getOptionLabel={(o) => o.activityTypeName}
				rules={requireRule()}
			/>
			<FormSelectField
				control={control}
				name={"activityResourceId"}
				label={i18n.t("resource")}
				options={resourceOptions}
				getOptionKey={(o) => o.activityResourceId}
				getOptionLabel={(o) => o.resourceName}
			/>
			<FormDateField
				control={control}
				name={"plannedStartDate"}
				label={i18n.t("planned_start")}
				rules={requireRule()}
			/>
			<FormDateField
				control={control}
				name={"plannedEndDate"}
				label={i18n.t("planned_end")}
				rules={requireRule()}
			/>
			{isExistingRecord && dirtyFields.plannedStartDate && (
				<FormCheckbox
					control={control}
					name={"inheritScheduleChange"}
					label={i18n.t("inherit_schedule_change_to_lower_level_items")}
					spanGridColumns
				/>
			)}
			<FormTextField control={control} name={"note"} label={i18n.t("note")} multiline spanGridColumns />
			<FormSelectField
				control={control}
				name={"releaseAfterActivityCompletedId"}
				label={i18n.t("release_after_another_activity_completed")}
				options={subProjectOtherActivities}
				getOptionKey={(o) => o.activityId}
				getOptionLabel={(o) => o.activityName}
				startNewGridRow
			/>
			{watch("releaseAfterActivityCompletedId") != null && (
				<FormCheckbox
					control={control}
					name={"scheduleOnChainedRelease"}
					label={i18n.t("reschedule_after_automatic_release")}
				/>
			)}
		</>
	);
};
