// This file is automatically generated. Do not edit manually.

import { IsoDateString } from "src/types/dateTime";
import { IsoDateTimeString } from "src/types/dateTime";
import { SalesContractState } from "src/api/generated/erp/db/types/enums/salesContractState";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace CustomerSalesContractsDataGridApi {
	export async function getSalesContracts(args: {
		customerId: number;
	}): Promise<Array<CustomerSalesContractsDataGridApi_SalesContractDto>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/pricing/salesContracts/getSalesContracts",
			method: "POST",
			data: {
				customerId: args.customerId,
			},
		});
		return response as Array<CustomerSalesContractsDataGridApi_SalesContractDto>;
	}
}

export interface CustomerSalesContractsDataGridApi_SalesContractDto {
	isActive: boolean;
	activeFrom: IsoDateString;
	activeTo: IsoDateString;
	createdAt: IsoDateTimeString;
	createdByUserId: number;
	customerId: number;
	deactivatedAt: IsoDateTimeString | null | undefined;
	deactivatedByUserId: number | null | undefined;
	description: string;
	lastUpdated: IsoDateTimeString;
	name: string;
	releasedAt: IsoDateTimeString | null | undefined;
	releasedByUserId: number | null | undefined;
	salesContractId: number;
	state: SalesContractState;
}
