import { ProjectsDataGridApi } from "src/api/generated/erp/project/project/api/projectsDataGridApi";
import { ControlledAsyncCrudDataGrid } from "src/components/common/dataGrid/crud/ControlledAsyncCrudDataGrid";
import { useServerSideDataGridModel } from "src/components/common/dataGrid/gridModel/useServerSideDataGridModel";
import {
	booleanColumn,
	dateColumn,
	dateTimeColumn,
	enumColumn,
	integerColumn,
	textColumn,
} from "src/components/common/dataGrid/columns.tsx";
import i18n from "i18next";
import { getProjectStateLabels } from "src/api/generated/erp/db/types/enums/projectState.ts";
import { ProjectContextMenu } from "src/components/views/erp/project/project/ProjectContextMenu.tsx";
import { ProjectForm } from "src/components/views/erp/project/project/ProjectForm.tsx";
import { DocumentsOfObjectButton } from "src/components/views/documents/objectDocuments/DocumentsOfObjectButton.tsx";
import { nullableAavoObjectRef } from "src/utils/aavoObjectRefUtils.ts";
import { AavoTextField } from "src/components/common/inputFields/AavoTextField.tsx";
import { MenuCheckbox } from "src/components/common/contextMenu/MenuCheckbox.tsx";
import { ProjectView } from "src/api/generated/erp/db/types/tables/projectView.ts";
import { CreateNewProjectForm } from "src/components/views/erp/project/project/CreateNewProjectForm.tsx";

export interface ProjectsDataGridProps {
	onlyProjectId?: number;
	onSelectionChanged?: (project: ProjectView | undefined) => void;
}

export const ProjectsDataGrid = ({ onlyProjectId, onSelectionChanged }: ProjectsDataGridProps) => {
	const { dataGridProps, onlySelectedRow, currentParams, refreshData } = useServerSideDataGridModel({
		fetchData: (params) =>
			ProjectsDataGridApi.getDataGridData({
				onlyProjectId,
				...params,
			}),
		initialParams: {
			searchQuery: "",
			onlyDefaultSiteProjects: true,
			completedProjects: false,
			cancelledProjects: false,
		},
		getRowId: (row) => row.projectId,
		gridId: "8A2DF2DCAE543A01",
		onSelectionChanged: (rows) => onSelectionChanged?.(rows[0]),
		selectFirstRowOnLoad: onlyProjectId != null,
	});

	return (
		<ControlledAsyncCrudDataGrid<ProjectView>
			disableMultipleRowSelection
			columns={[
				integerColumn({
					field: "projectId",
					headerName: i18n.t("number_shortened"),
					width: 80,
				}),
				textColumn({
					field: "siteName",
					headerName: i18n.t("site"),
					width: 80,
				}),
				textColumn({
					field: "projectDescription",
					headerName: i18n.t("name"),
					width: 200,
				}),
				textColumn({
					field: "groupName",
					headerName: i18n.t("group"),
				}),
				textColumn({
					field: "projectType",
					headerName: i18n.t("type"),
				}),
				textColumn({
					field: "projectResponsiblePersonName",
					headerName: i18n.t("responsible_person"),
					width: 120,
				}),
				booleanColumn({
					field: "hasCustomerOrders",
					headerName: i18n.t("customer_order"),
				}),
				textColumn({
					field: "comment",
					headerName: i18n.t("comment"),
				}),
				dateColumn({
					field: "createdDate",
					headerName: i18n.t("created_at"),
				}),
				textColumn({
					field: "createdByUserName",
					headerName: i18n.t("created_by"),
				}),
				dateColumn({
					field: "plannedStartDate",
					headerName: i18n.t("planned_start"),
				}),
				dateColumn({
					field: "plannedEndDate",
					headerName: i18n.t("planned_end"),
				}),
				enumColumn({
					field: "projectState",
					headerName: i18n.t("state"),
					enumLabels: getProjectStateLabels(),
				}),
				dateTimeColumn({
					field: "releasedDate",
					headerName: i18n.t("released_at"),
					width: 150,
				}),
				dateTimeColumn({
					field: "completedDate",
					headerName: i18n.t("completed_at"),
					width: 150,
				}),
				dateTimeColumn({
					field: "cancelledDate",
					headerName: i18n.t("cancelled_at"),
					width: 150,
				}),
				textColumn({
					field: "addressName",
					headerName: i18n.t("address_name"),
					width: 120,
				}),
				textColumn({
					field: "address_1",
					headerName: i18n.t("address_1"),
					width: 120,
				}),
				textColumn({
					field: "address_2",
					headerName: i18n.t("address_2"),
					width: 120,
				}),
				textColumn({
					field: "postalCode",
					headerName: i18n.t("postal_code"),
				}),
				textColumn({
					field: "city",
					headerName: i18n.t("city"),
					width: 120,
				}),
				textColumn({
					field: "addressContact",
					headerName: i18n.t("address_contact"),
					width: 150,
				}),
			]}
			rowContextMenuComponents={({ row }) => (
				<ProjectContextMenu project={row} refreshData={refreshData} />
			)}
			actionBarComponents={
				<>
					<DocumentsOfObjectButton
						objectRef={nullableAavoObjectRef("PROJECT", onlySelectedRow?.projectId)}
					/>
					<AavoTextField
						label={i18n.t("search")}
						onSubmit={(searchQuery) => refreshData({ searchQuery })}
					/>
				</>
			}
			actionBarMenuComponents={[
				<MenuCheckbox
					key={"onlyDefaultSiteProjects"}
					label={i18n.t("only_default_site")}
					checked={currentParams.onlyDefaultSiteProjects}
					onChange={(checked) => refreshData({ onlyDefaultSiteProjects: checked })}
				/>,
				<MenuCheckbox
					key={"completedProjects"}
					label={i18n.t("show_completed")}
					checked={currentParams.completedProjects}
					onChange={(checked) => refreshData({ completedProjects: checked })}
				/>,
				<MenuCheckbox
					key={"cancelledProjects"}
					label={i18n.t("show_cancelled")}
					checked={currentParams.cancelledProjects}
					onChange={(checked) => refreshData({ cancelledProjects: checked })}
				/>,
			]}
			form={{
				addRowEnabled: true,
				editEnabled: true,
				dialogTitle: (row) => (row == null ? i18n.t("new_project") : i18n.t("project")),
				dialogSize: (row) => (row == null ? "sm" : "lg"),
				component: ({ row, closeDialog, ...other }) =>
					row == null ?
						<CreateNewProjectForm closeDialog={closeDialog} {...other} />
					:	<ProjectForm projectId={row?.projectId ?? null} {...other} />,
			}}
			{...dataGridProps}
		/>
	);
};
