export function getFilesFromDropEvent(event) {
	const files = [];
	const items = event.dataTransfer.items || [];
	for (let i = 0; i < items.length; i++) {
		const item = items[i];
		if (item.kind === "file") {
			files.push(item.getAsFile());
		}
	}
	return files;
}
