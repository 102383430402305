// This file is automatically generated. Do not edit manually.

import { CostEventPriceListLine } from "src/api/generated/erp/db/types/tables/costEventPriceListLine";
import { CostEventItem } from "src/api/generated/erp/db/types/tables/costEventItem";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace CostEventPriceListLineDataGridApi {
	export async function getLines(args: {
		costEventPriceListId: number;
	}): Promise<CostEventPriceListLineDataGridApi_CostEventPriceListLineData> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/costEvents/costEventPriceListLine/getLines",
			method: "POST",
			data: {
				costEventPriceListId: args.costEventPriceListId,
			},
		});
		return response as CostEventPriceListLineDataGridApi_CostEventPriceListLineData;
	}

	export async function save(args: {
		costEventPriceListLines: Array<CostEventPriceListLine>;
	}): Promise<Array<CostEventPriceListLine>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/costEvents/costEventPriceListLine/save",
			method: "POST",
			data: {
				costEventPriceListLines: args.costEventPriceListLines,
			},
		});
		return response as Array<CostEventPriceListLine>;
	}

	export async function delete_(args: { costEventPriceListLines: Array<CostEventPriceListLine> }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/costEvents/costEventPriceListLine/delete",
			method: "POST",
			data: {
				costEventPriceListLines: args.costEventPriceListLines,
			},
		});
		return response as void;
	}
}

export interface CostEventPriceListLineDataGridApi_CostEventPriceListLineData {
	costEventItems: Array<CostEventItem>;
	costEventPriceListLines: Array<CostEventPriceListLine>;
}
