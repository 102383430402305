import { AavoMosaic } from "src/components/common/mosaic/AavoMosaic.tsx";
import i18n from "i18next";
import { AsyncButton } from "src/components/common/buttons/AsyncButton.tsx";
import { DeliveryView } from "src/api/generated/erp/db/types/tables/deliveryView.ts";
import { useState } from "react";
import { DeliveryPackageView } from "src/api/generated/erp/db/types/tables/deliveryPackageView.ts";
import { CenteredTypography } from "src/components/common/CenteredTypography.tsx";
import { faMinus, faTruckLoading } from "@fortawesome/pro-regular-svg-icons";
import { DeliveryPackagesDataGrid } from "../deliveryPackage/DeliveryPackagesDataGrid.tsx";
import { useRefreshRef } from "src/utils/useRefreshRef.ts";
import { DeliveryPackageApi } from "src/api/generated/erp/delivery/api/deliveryPackageApi.ts";
import { DeliverySource } from "src/api/generated/erp/delivery/model/deliverySource.ts";
import { DeliveriesDataGrid } from "./DeliveriesDataGrid.tsx";

export interface DeliverySourceDeliveringViewProps {
	deliverySource: DeliverySource;
	onlyDeliveryId?: number;
}

export const DeliverySourceDeliveringView = ({ deliverySource, onlyDeliveryId }: DeliverySourceDeliveringViewProps) => {
	const [selectedDelivery, setSelectedDelivery] = useState<DeliveryView | undefined>(undefined);
	const [selectedUndeliveredPackages, setSelectedUndeliveredPackages] = useState<DeliveryPackageView[]>([]);
	const [selectedDeliveryPackages, setSelectedDeliveryPackages] = useState<DeliveryPackageView[]>([]);
	const deliveryPackagesRefreshRef = useRefreshRef();
	const undeliveredPackagesRefreshRef = useRefreshRef();
	return (
		<AavoMosaic
			viewId={"B898FA3F9CC228BF"}
			layout={{
				type: "column",
				first: {
					type: "panel",
					title: i18n.t("deliveries"),
					content: (
						<DeliveriesDataGrid
							gridId={"F71A4E4E5856D928"}
							deliverySource={deliverySource}
							showSearchField={false}
							showOnlyUndeliveredByDefault={false}
							onlyDeliveryId={onlyDeliveryId}
							onSelectionChanged={(selectedDelivery) => {
								setSelectedDelivery(selectedDelivery);
							}}
						/>
					),
				},
				second: {
					type: "row",
					first: {
						type: "panel",
						title: i18n.t("undelivered_packages"),
						content: (
							<DeliveryPackagesDataGrid
								key={selectedDelivery?.deliveryId ?? -1}
								gridId={"548BE3FC2D394C94"}
								deliverySource={deliverySource}
								onlyPackagesWithoutConnectedDelivery
								onSelectedPackageChanged={(selectedPackages) => {
									setSelectedUndeliveredPackages(selectedPackages);
								}}
								selectedRows={selectedUndeliveredPackages}
								showSourceDocumentsButton={false}
								showSearchField={false}
								showStateChangeActions={false}
								showCalculateWeightButton={false}
								showPrintPackageLabelButton={false}
								editable={false}
								addEnabled={false}
								refreshRef={undeliveredPackagesRefreshRef}
								actionBarExtraComponents={
									<AsyncButton
										icon={faTruckLoading}
										label={i18n.t("move_to_delivery")}
										variant="outlined"
										disabled={
											selectedDelivery == null ? i18n.t("select_delivery")
											: selectedDelivery.deliveryState !== "INITIAL" ?
												i18n.t("delivery_is_already_delivered")
											: selectedUndeliveredPackages.length === 0 ?
												i18n.t("select_packages")
											:	undefined
										}
										onClick={async () => {
											await DeliveryPackageApi.movePackagesToDelivery({
												deliveryId: selectedDelivery!.deliveryId,
												deliveryPackageIds: selectedUndeliveredPackages.map(
													(row) => row.deliveryPackageId,
												),
											});
											await Promise.all([
												deliveryPackagesRefreshRef.refresh(),
												undeliveredPackagesRefreshRef?.refresh(),
											]);
										}}
									/>
								}
							/>
						),
					},
					second: {
						type: "panel",
						title: i18n.t("delivery_packages"),
						content:
							selectedDelivery == null ?
								<CenteredTypography>{i18n.t("select_delivery")}</CenteredTypography>
							:	<DeliveryPackagesDataGrid
									key={selectedDelivery.deliveryId}
									gridId={"548BE3FC2D394C90"}
									selectedRows={selectedDeliveryPackages}
									onSelectedPackageChanged={(selectedPackages) =>
										setSelectedDeliveryPackages(selectedPackages)
									}
									deliveryId={selectedDelivery.deliveryId}
									showSearchField={false}
									refreshRef={deliveryPackagesRefreshRef}
									editable={false}
									showPrintPackageLabelButton={false}
									showCalculateWeightButton={false}
									actionBarExtraComponents={
										selectedDelivery?.deliveryState === "INITIAL" && (
											<AsyncButton
												label={i18n.t("remove_from_delivery")}
												icon={faMinus}
												variant="outlined"
												disabled={selectedDeliveryPackages.length === 0}
												onClick={async () => {
													await DeliveryPackageApi.deletePackageFromDelivery({
														deliveryPackageIds: selectedDeliveryPackages.map(
															(row) => row.deliveryPackageId,
														),
													});
													await deliveryPackagesRefreshRef.refresh();
													await undeliveredPackagesRefreshRef?.refresh();
												}}
											/>
										)
									}
								/>,
					},
				},
			}}
		/>
	);
};
