// This file is automatically generated. Do not edit manually.

import { t } from "i18next";

export const FileMirrorStrategyValues = ["ORIENT_AND_RESIZE_IMAGE"] as const;

export type FileMirrorStrategy = (typeof FileMirrorStrategyValues)[number];

export const getFileMirrorStrategyLabels = () => ({
	ORIENT_AND_RESIZE_IMAGE: t("orient_and_resize_image"),
});

export const getFileMirrorStrategyLabel = (value: FileMirrorStrategy): string => {
	return getFileMirrorStrategyLabels()[value];
};
