import { ObjectCommentObjectType } from "src/api/generated/erp/db/types/enums/objectCommentObjectType";
import { AsyncFetchRender } from "src/components/common/async/AsyncFetchRender";
import { ObjectCommentApi } from "src/api/generated/erp/common/objectComments/objectCommentApi.ts";
import { List, ListItem, ListItemText } from "@mui/material";
import { useState } from "react";
import { VerticalBox } from "src/components/common/box/VerticalBox";
import { HorizontalBox } from "src/components/common/box/HorizontalBox.tsx";
import { AavoTextField } from "src/components/common/inputFields/AavoTextField";
import { AsyncButton } from "src/components/common/buttons/AsyncButton.tsx";
import { faPaperPlaneTop } from "@fortawesome/pro-regular-svg-icons";
import i18n from "i18next";
import { useErrorDialog } from "src/components/common/dialogs/errorDialog/userErrorDialog.ts";
import { formatIsoString } from "src/utils/dayjsUtils.ts";
import { linebreaksToHtmlBreaks } from "src/utils/strings.tsx";

export interface ObjectCommentingViewProps {
	objectType: ObjectCommentObjectType;
	objectId: number;
}

export const ObjectCommentingView = ({ objectType, objectId }: ObjectCommentingViewProps) => {
	const { logErrorAndShowOnDialog } = useErrorDialog();

	const [newComment, setNewComment] = useState("");

	return (
		<AsyncFetchRender
			fetch={() => ObjectCommentApi.getObjectComments({ objectType, objectId })}
			content={(comments, reload) => (
				<VerticalBox alignItems={"stretch"} flex={1}>
					<List
						sx={{
							overflow: "auto",
							flex: 1,
							display: "flex",
							flexDirection: "column-reverse",
						}}
					>
						{comments.map((comment) => (
							<ListItem
								key={comment.objectCommentId}
								sx={{
									borderBottom: "1px solid",
									borderColor: "divider",
								}}
							>
								<ListItemText
									primary={linebreaksToHtmlBreaks(comment.comment)}
									secondary={
										<>
											{comment.createdByUserName}
											{" - "}
											{formatIsoString(comment.createdAt, "L LT")}
										</>
									}
								/>
							</ListItem>
						))}
					</List>
					<HorizontalBox
						sx={{
							margin: 1,
							gap: 1,
						}}
					>
						<AavoTextField
							size={"small"}
							value={newComment}
							onChange={setNewComment}
							multiline
							maxRows={5}
							variant={"outlined"}
							sx={{
								flex: 1,
							}}
							placeholder={i18n.t("new_comment")}
							onSubmit={() => sendMessage(reload)}
							submitOnBlur={false}
						/>
						<AsyncButton
							icon={faPaperPlaneTop}
							tooltip={i18n.t("send")}
							onClick={() => sendMessage(reload)}
							variant={"contained"}
							disabled={newComment === ""}
							sx={{
								alignSelf: "flex-end",
							}}
						/>
					</HorizontalBox>
				</VerticalBox>
			)}
		/>
	);

	async function sendMessage(reload: () => Promise<unknown>) {
		try {
			await ObjectCommentApi.addComment({
				objectType: objectType,
				objectId: objectId,
				content: newComment,
			});
			setNewComment("");
			await reload();
		} catch (e) {
			logErrorAndShowOnDialog(e);
		}
	}
};
