import {floatColumn, textColumn} from "src/components/common/dataGrid/columns.tsx";
import i18n from "i18next";
import {RefreshableElementRef} from "src/utils/useRefreshRef.ts";
import {
    ShopOrderBomPickingDataGridApi
} from "src/api/generated/erp/production/api/shopOrderPicking/shopOrderBomPickingDataGridApi.ts";
import {ClientSideDataGridModel} from "src/components/common/dataGrid/gridModel/ClientSideDataGridModel.tsx";
import {
    ShopOrderLinePickingObjectLocationView
} from "src/api/generated/erp/db/types/tables/shopOrderLinePickingObjectLocationView.ts";
import {CrudDataGrid} from "src/components/common/dataGrid/crud/CrudDataGrid.tsx";
import {SelectField} from "src/components/common/inputFields/SelectField.tsx";
import {AsyncButton} from "src/components/common/buttons/AsyncButton.tsx";
import {faUndo} from "@fortawesome/pro-regular-svg-icons";

export interface ShopOrderBomPickedDataGridProps {
    shopOrderId: number;
    shopOrderOperationId?: number | null;
    refreshRef?: RefreshableElementRef;
    reservableLinesRefreshRef: RefreshableElementRef;
    pickableLinesRefreshRef?: RefreshableElementRef;
    showFullOperationProductionBomOnSite: boolean;
}

export const ShopOrderBomPickedDataGrid = ({
                                               shopOrderId,
                                               shopOrderOperationId = null,
                                               refreshRef,
                                               reservableLinesRefreshRef,
                                               pickableLinesRefreshRef,
                                               showFullOperationProductionBomOnSite,
                                           }: ShopOrderBomPickedDataGridProps) => {
    return (
        <ClientSideDataGridModel
            refreshRef={refreshRef}
            gridId={"AF2A7A03C8221CB7"}
            fetchData={async (params) =>
                ShopOrderBomPickingDataGridApi.getShopOrderPickedBom({
                    shopOrderId: shopOrderId,
                    shopOrderOperationId: params.shopOrderOperationId,
                })
            }
            getRows={(data) => data.shopOrderLinePickingLocations}
            initialParams={{
                shopOrderOperationId: !showFullOperationProductionBomOnSite ? shopOrderOperationId : null,
            }}
            getRowId={(row) => row.objectId}
            render={({
                         data: {operationOptions},
                         dataGridProps,
                         refreshData,
                         currentParams,
                         selectedRows,
                     }) => {
                return (
                    <CrudDataGrid<ShopOrderLinePickingObjectLocationView>
                        checkboxSelection={true}
                        columns={[
                            textColumn({
                                field: "partLongName",
                                headerName: i18n.t("part"),
                                width: 300,
                            }),
                            floatColumn({
                                field: "locationPickedQuantity",
                                headerName: i18n.t("picked_quantity"),
                                width: 150,
                            }),
                            textColumn({
                                field: "partUnit",
                                headerName: i18n.t("unit"),
                            }),
                            textColumn({
                                field: "locationCode",
                                headerName: i18n.t("warehouse_location"),
                            }),
                        ]}
                        actionBarComponents={
                            <>
                                <SelectField
                                    label={i18n.t("operation")}
                                    options={operationOptions}
                                    getOptionKey={(option) => option.shopOrderOperationId}
                                    getOptionLabel={(option) =>
                                        `${option.operationNo} | ${option.operationDescription}`
                                    }
                                    value={currentParams.shopOrderOperationId}
                                    onChange={async (value) =>
                                        await refreshData({shopOrderOperationId: value})
                                    }
                                />
                                <AsyncButton
                                    icon={faUndo}
                                    label={i18n.t("revert_picking")}
                                    variant={"outlined"}
                                    disabled={selectedRows.length === 0}
                                    onClick={async () => {
                                        await ShopOrderBomPickingDataGridApi.revertPickShopOrderBom({
                                            pickingObjects: selectedRows,
                                        });
                                        await Promise.all([
                                            refreshData(),
                                            reservableLinesRefreshRef?.refresh(),
                                            pickableLinesRefreshRef?.refresh()
                                        ])
                                    }}
                                />
                            </>
                        }
                        {...dataGridProps}
                    />
                );
            }}
        />
    );
};
