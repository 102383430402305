import {
	CustomerOrderOfferLineEditApi,
	CustomerOrderOfferLineEditApi_FormInitData,
} from "src/api/generated/erp/sales/customerOrderOffer/api/customerOrderOfferLineEditApi";
import { AsyncForm, AsyncFormContentParams } from "src/components/common/forms/AsyncForm";
import { FormCommonProps } from "src/components/common/forms/types.ts";
import { CustomerOrderOfferLine } from "src/api/generated/erp/db/types/tables/customerOrderOfferLine.ts";
import { FormAsyncSelectField } from "src/components/common/forms/fields/FormAsyncSelectField.tsx";
import i18n from "i18next";
import { requireRule } from "src/components/common/forms/validation.ts";
import { SalesPartApi } from "src/api/generated/erp/sales/basedata/api/salesPartApi.ts";
import { SalesPartView } from "src/api/generated/erp/db/types/tables/salesPartView.ts";
import { FormTextField } from "src/components/common/forms/fields/FormTextField.tsx";
import { FormDateField } from "src/components/common/forms/fields/FormDateField.tsx";
import { FormCheckbox } from "src/components/common/forms/fields/FormCheckbox.tsx";
import { FormNumberField } from "src/components/common/forms/fields/FormNumberField.tsx";
import { FormSelectField } from "src/components/common/forms/fields/FormSelectField.tsx";
import { round } from "src/utils/numberUtils.ts";
import { useErrorDialog } from "src/components/common/dialogs/errorDialog/userErrorDialog.ts";
import { getDefaultVatCode } from "src/components/views/erp/sales/salesUtils.ts";
import { AavoButton } from "src/components/common/buttons/AavoButton.tsx";
import { faCubes } from "@fortawesome/pro-solid-svg-icons";
import { useGenericDialog } from "src/components/common/dialogs/useGenericDialog.ts";
import { CustomerOrderOfferLinesDataGrid } from "./CustomerOrderOfferLinesDataGrid";
import { DeepPartial } from "react-hook-form";

export interface CustomerOrderOfferLineFormProps extends FormCommonProps<number> {
	customerOrderOfferId: number;
	parentLineId: number | undefined;
	customerOrderOfferLineId: number | undefined;
}

interface FormValues extends Omit<CustomerOrderOfferLine, "salesPartId"> {
	salesPart: SalesPartView;
}

export const CustomerOrderOfferLineForm = (props: CustomerOrderOfferLineFormProps) => {
	const {
		customerOrderOfferId,
		parentLineId,
		customerOrderOfferLineId,
		onCompleted,
		onFormEdited,
	} = props;

	return (
		<AsyncForm
			fetch={() =>
				CustomerOrderOfferLineEditApi.getFormInitData({
					customerOrderOfferId,
					parentLineId,
					customerOrderOfferLineId,
				})
			}
			getDefaultValues={getDefaultValues}
			onCompleted={onCompleted}
			onFormEdited={onFormEdited}
			submit={submitForm}
			columns={2}
			render={(params) => <FormContent {...props} {...params} />}
		/>
	);

	function getDefaultValues({
		offer,
		offerLine,
		salesPart,
		parentLine,
		defaultLineNumber,
		vatCodeOptions,
	}: CustomerOrderOfferLineEditApi_FormInitData): DeepPartial<FormValues> {
		if (offerLine != null)
			return {
				...offerLine,
				salesPart: salesPart ?? undefined,
				unitPrice:
					offer.inputPricesWithVat ? offerLine.unitPriceWithVat : offerLine.unitPrice,
			};
		else
			return {
				customerOrderOfferId: customerOrderOfferId,
				parentLineId: parentLineId,
				lineNumber: defaultLineNumber,
				plannedDeliveryDate: offer.plannedDeliveryDate,
				salesQuantityAsSalesUnits: 1,
				discountPercentage: 0,
				vatCodeId: parentLine?.vatCodeId ?? getDefaultVatCode(vatCodeOptions)?.vatCodeId,
				priceLocked: false,
				costLocked: false,
			};
	}

	async function submitForm(values: FormValues) {
		const offerLine = {
			...values,
			salesPartId: values.salesPart.salesPartId,
		};
		if (customerOrderOfferLineId != null) {
			await CustomerOrderOfferLineEditApi.update({
				offerLine: offerLine,
			});
			return customerOrderOfferLineId;
		} else {
			return await CustomerOrderOfferLineEditApi.insert({
				offerLine: offerLine,
			});
		}
	}
};

interface FormContentProps
	extends CustomerOrderOfferLineFormProps,
		AsyncFormContentParams<CustomerOrderOfferLineEditApi_FormInitData, FormValues> {}

const FormContent = ({
	parentLineId,
	control,
	watch,
	setValue,
	data: { offerLine, offer, vatCodeOptions },
}: FormContentProps) => {
	const { logErrorAndShowOnDialog } = useErrorDialog();
	const { openDialog } = useGenericDialog();

	const isPackageChildLine = parentLineId != null;
	const isExistingRecord = offerLine != null;

	const salesPart = watch("salesPart");
	const salesQuantityAsSalesUnits = watch("salesQuantityAsSalesUnits");
	const acquisitionMethod = salesPart?.acquisitionMethod;

	return (
		<>
			<FormAsyncSelectField
				control={control}
				name={"salesPart"}
				formValueType="option"
				label={i18n.t("sales_part")}
				rules={requireRule()}
				fetchOptions={({ searchQuery, currentSelection }) =>
					SalesPartApi.getSalesPartOptions({
						siteId: offer.siteId,
						searchQuery: searchQuery,
						currentSelection: currentSelection,
						onlyIfCanBeUsedOnCustomerOrderLine: true,
					})
				}
				getOptionKey={(option: SalesPartView) => option.salesPartId}
				getOptionLabel={(option) =>
					`${option.salesPartNo} | ${option.salesPartDescription}`
				}
				onChange={onSalesPartChanged}
				disabled={isExistingRecord}
				spanGridColumns
			/>
			<FormTextField control={control} name={"mark"} label={i18n.t("mark")} spanGridColumns />
			{getSalesQuantityField()}
			{!isPackageChildLine && (
				<>
					<FormDateField
						control={control}
						name={"plannedDeliveryDate"}
						label={i18n.t("planned_delivery_date")}
					/>
					{getUnitPriceField()}
					<FormCheckbox
						control={control}
						name={"priceLocked"}
						label={i18n.t("price_locked")}
					/>
					<FormNumberField
						control={control}
						name={"discountPercentage"}
						label={i18n.t("discount_percentage")}
					/>
				</>
			)}
			{!isPackageChildLine && offer.vatHandling !== "REVERSE_CHARGE_VAT" && (
				<FormSelectField
					control={control}
					name={"vatCodeId"}
					label={i18n.t("vat_code")}
					options={vatCodeOptions}
					getOptionKey={(vatCode) => vatCode.vatCodeId}
					getOptionLabel={(vatCode) => vatCode.vatCodeName}
					rules={requireRule()}
					startNewGridRow
				/>
			)}
			{salesPart?.salesPartType !== "PACKAGE_" && (
				<>
					<FormNumberField
						control={control}
						name={"unitCost"}
						label={i18n.t("cost")}
						startNewGridRow
					/>
					<FormCheckbox
						control={control}
						name={"costLocked"}
						label={i18n.t("cost_locked")}
					/>
				</>
			)}
			{acquisitionMethod === "MANUFACTURE" && (
				<>
					<FormTextField
						control={control}
						name={"shopOrderBatchCode"}
						label={i18n.t("shop_order_batch")}
						sx={{
							visibility:
								salesPart?.acquisitionMethod === "MANUFACTURE" ?
									"visible"
								:	"hidden",
						}}
					/>
					<FormNumberField
						control={control}
						name={"capacityQuantity"}
						label={i18n.t("capacity_quantity")}
					/>
				</>
			)}
			<FormNumberField
				control={control}
				name={"lineNumber"}
				label={i18n.t("line_number")}
				type={"integer"}
				rules={requireRule()}
			/>
			{offerLine != null && offerLine.salesPartType === "PACKAGE_" && (
				<AavoButton
					label={i18n.t("package_lines")}
					icon={faCubes}
					onClick={() => {
						openDialog(() => ({
							title: i18n.t("package_lines"),
							size: "xl",
							content: (
								<CustomerOrderOfferLinesDataGrid
									customerOrderOfferId={offerLine.customerOrderOfferId}
									parentLineId={offerLine.customerOrderOfferLineId}
								/>
							),
						}));
					}}
				/>
			)}
		</>
	);

	function getSalesQuantityField() {
		const labelSalesUnitPart = salesPart?.salesUnit != null ? ` (${salesPart.salesUnit})` : "";
		const label = `${i18n.t("quantity")}${labelSalesUnitPart}`;

		return (
			<FormNumberField
				control={control}
				name={"salesQuantityAsSalesUnits"}
				label={label}
				rules={requireRule()}
				onSubmit={(value) => {
					setValue("capacityQuantity", getDefaultCapacityQuantity(salesPart, value));
				}}
			/>
		);
	}

	function getUnitPriceField() {
		const labelVatPart = offer.inputPricesWithVat ? i18n.t("with_vat") : i18n.t("without_vat");
		const labelSalesUnitPart = salesPart?.salesUnit != null ? ` (${salesPart.salesUnit})` : "";
		const label = `${i18n.t("unit_price")}${labelSalesUnitPart}, ${labelVatPart}`;
		return (
			<FormNumberField
				control={control}
				name={"unitPrice"}
				label={label}
				rules={requireRule()}
				startNewGridRow
			/>
		);
	}

	async function onSalesPartChanged(salesPart: SalesPartView | null) {
		try {
			const vatCodeId = getDefaultVatCodeId(salesPart);
			const defaultUnitPrice =
				salesPart == null ? 0 : (
					await SalesPartApi.getSalesPartDefaultPrice({
						salesPartId: salesPart.salesPartId,
						customerId: offer.customerId,
						withVat: offer.inputPricesWithVat,
						vatCodeId: vatCodeId,
					})
				);
			setValue("vatCodeId", vatCodeId);
			setValue("unitPrice", defaultUnitPrice);
			setValue(
				"capacityQuantity",
				getDefaultCapacityQuantity(salesPart, salesQuantityAsSalesUnits),
			);
			setValue(
				"unitCost",
				salesPart == null || salesPart.standardCost == null ?
					0
				:	round(salesPart.standardCost * salesPart.salesFactor, 2),
			);
		} catch (e) {
			logErrorAndShowOnDialog(e);
		}
	}

	function getDefaultVatCodeId(salesPart: SalesPartView | null): number {
		if (salesPart != null) return salesPart.vatCodeId;

		return getDefaultVatCode(vatCodeOptions).vatCodeId;
	}

	function getDefaultCapacityQuantity(
		salesPart: SalesPartView | null,
		salesQuantityAsSalesUnits: number | null,
	) {
		if (
			salesPart == null ||
			salesPart.capacityQuantity == null ||
			salesQuantityAsSalesUnits == null
		)
			return 0;

		return salesPart.capacityQuantity * salesPart.salesFactor * salesQuantityAsSalesUnits;
	}
};
