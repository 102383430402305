// This file is automatically generated. Do not edit manually.

import { ConfiguratorLibraryFunctionView } from "src/api/generated/erp/db/types/tables/configuratorLibraryFunctionView";
import { ConfiguratorLibraryFunction } from "src/api/generated/erp/db/types/tables/configuratorLibraryFunction";
import { ConfiguratorLibraryVersion } from "src/api/generated/erp/db/types/tables/configuratorLibraryVersion";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace ConfiguratorLibraryFunctionsApi {
	export async function getLibraryVersionFunctions(args: {
		configuratorLibraryVersionId: number;
	}): Promise<Array<ConfiguratorLibraryFunctionView>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/configurator/managing/libraryFunctions/getLibraryVersionFunctions",
			method: "POST",
			data: {
				configuratorLibraryVersionId: args.configuratorLibraryVersionId,
			},
		});
		return response as Array<ConfiguratorLibraryFunctionView>;
	}

	export async function getFormInitData(args: {
		configuratorLibraryVersionId: number;
		configuratorLibraryFunctionId: number | null | undefined;
	}): Promise<ConfiguratorLibraryFunctionsApi_FormInitData> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/configurator/managing/libraryFunctions/getFormInitData",
			method: "POST",
			data: {
				configuratorLibraryVersionId: args.configuratorLibraryVersionId,
				configuratorLibraryFunctionId: args.configuratorLibraryFunctionId,
			},
		});
		return response as ConfiguratorLibraryFunctionsApi_FormInitData;
	}

	export async function insert(args: {
		configuratorLibraryVersionId: number;
		functionName: string;
		description: string;
		functionBody: string;
	}): Promise<number> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/configurator/managing/libraryFunctions/insert",
			method: "POST",
			data: {
				configuratorLibraryVersionId: args.configuratorLibraryVersionId,
				functionName: args.functionName,
				description: args.description,
				functionBody: args.functionBody,
			},
		});
		return response as number;
	}

	export async function update(args: {
		configuratorLibraryFunctionId: number;
		functionName: string;
		description: string;
		functionBody: string;
	}): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/configurator/managing/libraryFunctions/update",
			method: "POST",
			data: {
				configuratorLibraryFunctionId: args.configuratorLibraryFunctionId,
				functionName: args.functionName,
				description: args.description,
				functionBody: args.functionBody,
			},
		});
		return response as void;
	}

	export async function delete_(args: { configuratorLibraryFunctionId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/configurator/managing/libraryFunctions/delete",
			method: "POST",
			data: {
				configuratorLibraryFunctionId: args.configuratorLibraryFunctionId,
			},
		});
		return response as void;
	}
}

export interface ConfiguratorLibraryFunctionsApi_FormInitData {
	libraryFunction: ConfiguratorLibraryFunction | null | undefined;
	libraryVersion: ConfiguratorLibraryVersion;
}
