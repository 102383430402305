// This file is automatically generated. Do not edit manually.

import { ReceivePurchaseOrderLineDto } from "src/api/generated/erp/purchase/purchaseOrder/api/receivePurchaseOrderLineDto";
import { PartWarehouseLocationView } from "src/api/generated/erp/db/types/tables/partWarehouseLocationView";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace ReceivePurchaseOrderLinesFormApi {
	export async function getFormInitData(args: {
		purchaseOrderLineIds: Array<number>;
	}): Promise<ReceivePurchaseOrderLinesFormApi_FormInitData> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/purchase/purchaseOrder/receivePurchaseOrderLinesForm/getFormInitData",
			method: "POST",
			data: {
				purchaseOrderLineIds: args.purchaseOrderLineIds,
			},
		});
		return response as ReceivePurchaseOrderLinesFormApi_FormInitData;
	}

	export async function submit(args: { lines: Array<ReceivePurchaseOrderLineDto> }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/purchase/purchaseOrder/receivePurchaseOrderLinesForm/submit",
			method: "POST",
			data: {
				lines: args.lines,
			},
		});
		return response as void;
	}
}

export interface ReceivePurchaseOrderLinesFormApi_FormInitData {
	partWarehouseLocationOptionsByPartId: Record<number, Array<PartWarehouseLocationView>>;
}
