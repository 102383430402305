import DOMPurify from "dompurify";
import { Box, BoxProps } from "@mui/material";
import { mergeSx } from "src/utils/styles.ts";

export interface RawHtmlContentProps extends BoxProps {
	html: string;
}

/**
 * Sanitizes HTML content and renders it
 */
export const RawHtmlContent = ({ html, sx, ...other }: RawHtmlContentProps) => {
	const sanitizedHtml = DOMPurify.sanitize(html, { USE_PROFILES: { html: true } });
	return (
		<Box
			sx={mergeSx(
				{
					"& p": {
						margin: 0,
					},
				},
				sx,
			)}
			dangerouslySetInnerHTML={{ __html: sanitizedHtml }}
			{...other}
		/>
	);
};
