// This file is automatically generated. Do not edit manually.

import { ProductionLineOperationShopOrdersView } from "src/api/generated/erp/db/types/tables/productionLineOperationShopOrdersView";
import { Workcenter } from "src/api/generated/erp/db/types/tables/workcenter";
import { WorkCenterSpcData } from "src/api/generated/erp/production/jobQueues/api/workCenterSpcData";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace ProductionLineJobQueueInProgressApi {
	export async function getInProgressOperations(): Promise<ProductionLineJobQueueInProgressApi_GetOperationsResponse> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/productionLineJobQueue/getInProgressOperations",
			method: "POST",
			data: {},
		});
		return response as ProductionLineJobQueueInProgressApi_GetOperationsResponse;
	}
}

export interface ProductionLineJobQueueInProgressApi_GetOperationsResponse {
	shopOrderOperations: Array<ProductionLineOperationShopOrdersView>;
	workCenter: Workcenter;
	workCenterSpcData: WorkCenterSpcData;
}
