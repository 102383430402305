import { GridRowId } from "@mui/x-data-grid-pro";
import React, { useCallback } from "react";

export interface DataGridValidationContext {
	setError(rowId: GridRowId, fieldName: string, error: string | undefined): void;
}

export const DataGridValidationContext = React.createContext<DataGridValidationContext | undefined>(undefined);

export interface DataGridValidationContextProviderProps {
	setErrors: React.Dispatch<React.SetStateAction<DataGridValidationErrors>>;
	children: React.ReactNode;
}

export type DataGridValidationErrors = {
	[rowId: string]: RowValidationErrors;
}

export type RowValidationErrors = {
	[fieldName: string]: string | undefined;
}

export const DataGridValidationContextProvider = ({
													  setErrors,
													  children
												  }: DataGridValidationContextProviderProps) => {
	const setError = useCallback(
		(rowId: GridRowId, fieldName: string, error: string | undefined) => {
			setErrors((errors) => ({
				...errors,
				[rowId]: {
					...errors[rowId],
					[fieldName]: error
				}
			}));
		}, [setErrors]);

	const contextValue = {
		setError: setError
	};

	return <DataGridValidationContext.Provider value={contextValue}>
		{children}
	</DataGridValidationContext.Provider>;
};

