// This file is automatically generated. Do not edit manually.

import { AppUser } from "src/api/generated/postgres/db/types/role_management/tables/appUser";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace UserApi {
	export async function getUserSelectionOptions(args: {
		currentSelectionUserId: number | null | undefined;
		searchQuery: string;
	}): Promise<Array<AppUser>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/user/getUserSelectionOptions",
			method: "POST",
			data: {
				currentSelectionUserId: args.currentSelectionUserId,
				searchQuery: args.searchQuery,
			},
		});
		return response as Array<AppUser>;
	}
}
