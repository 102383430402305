// This file is automatically generated. Do not edit manually.

import { IsoDateString } from "src/types/dateTime";
import { FrontendDownloadFileHandle } from "src/api/generated/common/frontendDownloadFileHandle";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace PurchaseRequestApi {
	export async function cancelSelectedPurchaseRequests(args: { purchaseRequestIds: Array<number> }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/purchase/purchaseRequests/cancel",
			method: "POST",
			data: {
				purchaseRequestIds: args.purchaseRequestIds,
			},
		});
		return response as void;
	}

	export async function markSelectedPurchaseRequestsOrdered(args: {
		purchaseRequestIds: Array<number>;
	}): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/purchase/purchaseRequests/markAsOrdered",
			method: "POST",
			data: {
				purchaseRequestIds: args.purchaseRequestIds,
			},
		});
		return response as void;
	}

	export async function createPurchaseOrderFromSelectedPurchaseRequests(args: {
		purchaseRequestIds: Array<number>;
		plannedArrivalDate: IsoDateString;
		sumSamePartRequests: boolean;
	}): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/purchase/purchaseRequests/createOrder",
			method: "POST",
			data: {
				purchaseRequestIds: args.purchaseRequestIds,
				plannedArrivalDate: args.plannedArrivalDate,
				sumSamePartRequests: args.sumSamePartRequests,
			},
		});
		return response as void;
	}

	export async function printPurchaseRequests(args: {
		purchaseRequestIds: Array<number>;
	}): Promise<FrontendDownloadFileHandle> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/purchase/purchaseRequests/print",
			method: "POST",
			data: {
				purchaseRequestIds: args.purchaseRequestIds,
			},
		});
		return response as FrontendDownloadFileHandle;
	}
}
