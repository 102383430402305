import { setLocalStorageItemManaged } from "src/storage/localStorageUtils.ts";

export const MAIN_MENU_FAVORITES_STORAGE_KEY = "mainMenuFavorites";

export function saveFavoriteViewKeys(keys: string[]) {
	setLocalStorageItemManaged(MAIN_MENU_FAVORITES_STORAGE_KEY, JSON.stringify(keys));
}

export function loadFavoriteViewKeys(): string[] {
	const serialized = localStorage.getItem(MAIN_MENU_FAVORITES_STORAGE_KEY);
	if (!serialized) return [];

	return JSON.parse(serialized);
}
