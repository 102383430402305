// This file is automatically generated. Do not edit manually.

import { ProductionLineGroupView } from "src/api/generated/erp/db/types/tables/productionLineGroupView";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace ProductionLineGroupApi {
	export async function getProductionLineGroups(args: {
		siteId: number | null | undefined;
	}): Promise<Array<ProductionLineGroupView>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/productionLineGroups/getProductionLineGroups",
			method: "POST",
			data: {
				siteId: args.siteId,
			},
		});
		return response as Array<ProductionLineGroupView>;
	}
}
