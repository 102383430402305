import { SurveyFormField } from "src/api/generated/erp/db/types/tables/surveyFormField.ts";
import { FormCommonProps } from "src/components/common/forms/types.ts";
import { DeepPartial, useController, useFieldArray } from "react-hook-form";
import { SurveyFormRevisionView } from "src/api/generated/erp/db/types/tables/surveyFormRevisionView.ts";
import {
	SurveyFormFieldApi,
	SurveyFormFieldApi_FormInitData,
} from "src/api/generated/erp/surveys/api/surveyFormFieldApi.ts";
import { FormEnumSelectField } from "src/components/common/forms/fields/FormEnumSelectField.tsx";
import { getSurveyFormFieldTypeLabels } from "src/api/generated/erp/db/types/enums/surveyFormFieldType.ts";
import { requireRule } from "src/components/common/forms/validation.ts";
import i18n from "i18next";
import { HorizontalBox } from "src/components/common/box/HorizontalBox.tsx";
import { FormTextField } from "src/components/common/forms/fields/FormTextField.tsx";
import { AavoButton } from "src/components/common/buttons/AavoButton.tsx";
import { faPlus, faTrash } from "@fortawesome/pro-regular-svg-icons";
import { BoxWithTitle } from "src/components/common/box/BoxWithTitle.tsx";
import { FormCheckbox } from "src/components/common/forms/fields/FormCheckbox.tsx";
import { surveyFieldValueToString } from "src/components/views/erp/surveys/surveyUtils.ts";
import { AavoTextField } from "src/components/common/inputFields/AavoTextField.tsx";
import { AavoDateTimePicker } from "src/components/common/inputFields/AavoDateTimePicker.tsx";
import { dayJsToDateIsoString, dayJsToLocalDateTimeIsoString } from "src/utils/dayjsUtils.ts";
import { AavoCheckbox } from "src/components/common/inputFields/AavoCheckbox.tsx";
import { AavoDatePicker } from "src/components/common/inputFields/AavoDatePicker.tsx";
import { LazySelectField } from "src/components/common/inputFields/LazySelectField.tsx";
import dayjs from "dayjs";
import { AsyncForm, AsyncFormContentParams } from "src/components/common/forms/AsyncForm.tsx";

export interface SurveyFormFieldFormProps extends FormCommonProps<number> {
	surveyFormRevision: SurveyFormRevisionView;
	surveyFormFieldId: number | undefined;
}

interface FormValues extends SurveyFormField {}

export const SurveyFormFieldForm = (props: SurveyFormFieldFormProps) => {
	const { surveyFormRevision, surveyFormFieldId, onCompleted, onFormEdited } = props;
	return (
		<AsyncForm
			fetch={() =>
				SurveyFormFieldApi.getFormInitData({
					surveyFormFieldId,
				})
			}
			getDefaultValues={getDefaultValues}
			submit={submit}
			onCompleted={onCompleted}
			onFormEdited={onFormEdited}
			getUseFormProps={() => ({ disabled: surveyFormRevision.state !== "INITIAL" })}
			render={(contentParams) => <FormContent {...props} {...contentParams} />}
		/>
	);

	function getDefaultValues({ surveyFormField }: SurveyFormFieldApi_FormInitData): DeepPartial<FormValues> {
		return (
			surveyFormField ?? {
				fieldType: "TEXT",
				surveyFormRevisionId: surveyFormRevision.surveyFormRevisionId,
				selectionFieldOptions: [],
			}
		);
	}

	async function submit(values: FormValues) {
		if (surveyFormFieldId == null) {
			return await SurveyFormFieldApi.insert({ surveyFormField: values });
		} else {
			await SurveyFormFieldApi.update({ surveyFormField: values });
			return surveyFormFieldId;
		}
	}
};

interface FormContentProps
	extends SurveyFormFieldFormProps,
		AsyncFormContentParams<SurveyFormFieldApi_FormInitData, FormValues> {}

const FormContent = (props: FormContentProps) => {
	const { control, watch } = props;

	const fieldType = watch("fieldType");

	return (
		<>
			<FormTextField control={control} name={"label"} label={i18n.t("name")} rules={requireRule()} />
			<FormTextField control={control} name={"instruction"} label={i18n.t("instruction_text")} />
			<FormEnumSelectField
				control={control}
				name={"fieldType"}
				label={i18n.t("type")}
				options={getSurveyFormFieldTypeLabels()}
				rules={requireRule()}
				disableClearable
			/>
			<SelectionOptionsForm {...props} />
			{fieldType !== "SECTION_BREAK" && (
				<FormCheckbox control={control} name={"required"} label={i18n.t("required")} />
			)}
			{fieldType !== "SECTION_BREAK" && (
				<FormCheckbox control={control} name={"spanAllColumns"} label={i18n.t("fill_whole_row")} />
			)}
			{fieldType === "DOCUMENT" && <DocumentFieldSpecificForm {...props} />}
			{fieldType !== "SECTION_BREAK" && !watch("spanAllColumns") && (
				<>
					<FormCheckbox control={control} name={"placeOnNewRow"} label={i18n.t("place_on_new_row")} />
				</>
			)}
			<DefaultValueForm {...props} />
		</>
	);
};

const SelectionOptionsForm = (props: FormContentProps) => {
	const { control, watch } = props;

	const fieldType = watch("fieldType");

	const optionsFieldArray = useFieldArray({
		control: control,
		name: "selectionFieldOptions",
	});

	if (fieldType !== "SELECTION") return null;

	return (
		<BoxWithTitle
			title={i18n.t("options")}
			sx={{
				display: "flex",
				flexDirection: "column",
				gap: 2,
				padding: 1,
			}}
		>
			{optionsFieldArray.fields.map((option, index) => (
				<SelectionOptionForm
					key={option.id}
					index={index}
					removeOption={() => optionsFieldArray.remove(index)}
					{...props}
				/>
			))}
			<AavoButton label={i18n.t("add")} icon={faPlus} onClick={() => optionsFieldArray.append({ key: "" })} />
		</BoxWithTitle>
	);
};

const DocumentFieldSpecificForm = ({ control }: FormContentProps) => {
	return (
		<>
			<FormCheckbox
				control={control}
				name={"documentFieldAllowMultiple"}
				label={i18n.t("allow_multiple_files")}
			/>
		</>
	);
};

interface SelectionOptionFormProps extends FormContentProps {
	index: number;
	removeOption: () => void;
}

const SelectionOptionForm = ({ index, removeOption, control }: SelectionOptionFormProps) => {
	return (
		<HorizontalBox gap={1}>
			<FormTextField
				control={control}
				name={`selectionFieldOptions.${index}.key`}
				label={i18n.t("option")}
				rules={{
					...requireRule(),
					validate: (value, values) => {
						if (values.selectionFieldOptions.some((option, i) => option.key === value && i !== index)) {
							return i18n.t("duplicate_key");
						}
					},
				}}
			/>
			<AavoButton icon={faTrash} onClick={() => removeOption()} />
		</HorizontalBox>
	);
};

const DefaultValueForm = (props: FormContentProps) => {
	const { control, watch, getValues } = props;

	const {
		field: { value, onChange },
	} = useController({
		control: control,
		name: "defaultValue",
		defaultValue: null,
	});

	const fieldType = watch("fieldType");

	const commonProps = {
		control: control,
		name: "defaultValue",
		label: i18n.t("default_value"),
	} as const;

	switch (fieldType) {
		case "TEXT":
		case "TEXTAREA":
			return (
				<AavoTextField
					{...commonProps}
					multiline={fieldType === "TEXTAREA"}
					value={surveyFieldValueToString(value)}
					onChange={(value) =>
						onChange({
							type: "string",
							value: value,
						})
					}
				/>
			);
		case "INTEGER":
		case "DECIMAL":
			return (
				<AavoTextField
					{...commonProps}
					value={surveyFieldValueToString(value)}
					type={"number"}
					onChange={(value) =>
						onChange({
							type: "number",
							value: value,
						})
					}
				/>
			);
		case "CHECKBOX":
			return (
				<AavoCheckbox
					{...commonProps}
					checked={value?.type === "boolean" && value.value}
					onChange={(checked) =>
						onChange({
							type: "boolean",
							value: checked,
						})
					}
				/>
			);

		case "DATE":
			return (
				<AavoDatePicker
					{...commonProps}
					value={value?.type === "date" ? dayjs(value.value) : null}
					onChange={(date) => {
						if (date === null) onChange(null);
						else
							onChange({
								type: "date",
								value: dayJsToDateIsoString(date),
							});
					}}
				/>
			);

		case "DATETIME":
			return (
				<AavoDateTimePicker
					{...commonProps}
					value={value?.type === "dateTime" ? dayjs(value.value) : null}
					onChange={(dateTime) => {
						if (dateTime === null) onChange(null);
						else
							onChange({
								type: "dateTime",
								value: dayJsToLocalDateTimeIsoString(dateTime),
							});
					}}
				/>
			);

		case "SELECTION":
			return (
				<LazySelectField
					{...commonProps}
					fetchOptions={async () => getValues("selectionFieldOptions")}
					getOptionKey={(option) => option.key}
					getOptionLabel={(option) => option.key}
					defaultValue={value?.type === "string" ? value.value : null}
					onChange={(key) => {
						if (key === null) onChange(null);
						else
							onChange({
								type: "string",
								value: key,
							});
					}}
				/>
			);
	}
};
