// This file is automatically generated. Do not edit manually.

import { TaskView } from "src/api/generated/postgres/db/types/tasks/tables/taskView";
import { TaskSourceRef } from "src/api/generated/tasks/taskSource/taskSourceRef";
import { TaskSource } from "src/api/generated/tasks/taskSource/taskSource";
import { TaskAndSource } from "src/api/generated/tasks/api/taskAndSource";
import { TaskState } from "src/api/generated/io/aavo/applications/db/postgres/enums/taskState";
import { ServerSideDataModelRequest } from "src/api/generated/common/dataGrids/serverSideDataModelRequest";
import { ServerSideDataModelResult } from "src/api/generated/common/dataGrids/serverSideDataModelResult";
import { TaskQueryDto } from "src/api/generated/tasks/api/taskQueryDto";
import { TaskSourceOption } from "src/api/generated/tasks/api/taskSourceOption";
import { AppUser } from "src/api/generated/postgres/db/types/role_management/tables/appUser";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace TaskQueryApi {
	export async function getSingleTask(args: { taskId: number }): Promise<TaskView> {
		const response = await makeAxiosRequest({
			url: "/api/ext/tasks/getSingleTask",
			method: "POST",
			data: {
				taskId: args.taskId,
			},
		});
		return response as TaskView;
	}

	export async function getTaskSource(args: { sourceRef: TaskSourceRef }): Promise<TaskSource> {
		const response = await makeAxiosRequest({
			url: "/api/ext/tasks/getTaskSource",
			method: "POST",
			data: {
				sourceRef: args.sourceRef,
			},
		});
		return response as TaskSource;
	}

	export async function getTaskAndSource(args: { taskId: number }): Promise<TaskAndSource> {
		const response = await makeAxiosRequest({
			url: "/api/ext/tasks/getTaskWithSource",
			method: "POST",
			data: {
				taskId: args.taskId,
			},
		});
		return response as TaskAndSource;
	}

	export async function queryTasks(args: {
		sources?: Array<TaskSourceRef> | null | undefined;
		states?: Array<TaskState>;
		responsiblePersonId?: number | null | undefined;
		assignedToUserId?: number | null | undefined;
		searchQuery?: string;
		dataModelRequest?: ServerSideDataModelRequest;
	}): Promise<ServerSideDataModelResult<TaskQueryDto>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/tasks/queryTasks",
			method: "POST",
			data: {
				sources: args.sources,
				states: args.states,
				responsiblePersonId: args.responsiblePersonId,
				assignedToUserId: args.assignedToUserId,
				searchQuery: args.searchQuery,
				dataModelRequest: args.dataModelRequest,
			},
		});
		return response as ServerSideDataModelResult<TaskQueryDto>;
	}

	export async function getTaskSourceOptions(args: {
		searchQuery: string;
		currentSelection: TaskSourceRef | null | undefined;
		states?: Array<TaskState>;
		responsiblePersonId?: number | null | undefined;
		assignedToUserId?: number | null | undefined;
	}): Promise<Array<TaskSourceOption>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/tasks/getTaskSourceOptions",
			method: "POST",
			data: {
				searchQuery: args.searchQuery,
				currentSelection: args.currentSelection,
				states: args.states,
				responsiblePersonId: args.responsiblePersonId,
				assignedToUserId: args.assignedToUserId,
			},
		});
		return response as Array<TaskSourceOption>;
	}

	export async function getTaskAssignedToUserOptions(args: {
		currentSelection: number | null | undefined;
		userNameSearchQuery: string;
		sources?: Array<TaskSourceRef> | null | undefined;
		states?: Array<TaskState>;
		responsiblePersonId?: number | null | undefined;
	}): Promise<Array<AppUser>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/tasks/getTaskAssignedToUserOptions",
			method: "POST",
			data: {
				currentSelection: args.currentSelection,
				userNameSearchQuery: args.userNameSearchQuery,
				sources: args.sources,
				states: args.states,
				responsiblePersonId: args.responsiblePersonId,
			},
		});
		return response as Array<AppUser>;
	}

	export async function getTaskResponsiblePersonOptions(args: {
		currentSelection: number | null | undefined;
		userNameSearchQuery: string;
		sources?: Array<TaskSourceRef> | null | undefined;
		states?: Array<TaskState>;
		assignedToUserId?: number | null | undefined;
	}): Promise<Array<AppUser>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/tasks/getResponsiblePersonOptions",
			method: "POST",
			data: {
				currentSelection: args.currentSelection,
				userNameSearchQuery: args.userNameSearchQuery,
				sources: args.sources,
				states: args.states,
				assignedToUserId: args.assignedToUserId,
			},
		});
		return response as Array<AppUser>;
	}
}
