import i18n from "i18next";
import {ErpUnit} from "src/api/generated/erp/db/types/tables/erpUnit";
import {AsyncCrudDataGrid} from "src/components/common/dataGrid/crud/AsyncCrudDataGrid";
import {dataGridPersistentStateProps} from "src/components/common/dataGrid/dataGridStateStorage";
import {textColumn} from "src/components/common/dataGrid/columns";
import {UnitsApi} from "src/api/generated/erp/warehouse/basedata/api/unitsApi.ts";

export const ErpUnitsDataGrid = () => {
	return (
		<AsyncCrudDataGrid<ErpUnit>
			fetchData={UnitsApi.getAll}
			save={{
				type: "enabled",
				action: UnitsApi.save,
			}}
			add={{
				type: "enabled",
				action: async () => {
					return {
						unitName: "",
						unitDescription: "",
						externalId: "",
						lastUpdated: new Date().toISOString(),
					};
				},
			}}
			remove={{
				type: "enabled",
				action: UnitsApi.delete_,
			}}
			columns={[
				textColumn({
					headerName: i18n.t("unit"),
					editable: true,
					field: "unitName",
					validate: "required",
				}),
				textColumn({
					headerName: i18n.t("description"),
					editable: true,
					field: "unitDescription",
				}),
				textColumn({
					headerName: i18n.t("external_id"),
					editable: true,
					field: "externalId",
					width: 130,
				}),
			]}
			getRowId={(row) => {
				return row.unitId;
			}}
			{...dataGridPersistentStateProps("38F52A11EA499F4C")}
		/>
	);
};
