import { ClientSideDataGridModel } from "src/components/common/dataGrid/gridModel/ClientSideDataGridModel.tsx";
import { CatalogPartRevisionBomDataGridApi } from "src/api/generated/erp/parts/catalogPart/api/catalogPartRevisionBomDataGridApi.ts";
import { CrudDataGrid, CrudDataGridApi } from "src/components/common/dataGrid/crud/CrudDataGrid.tsx";
import {
	asyncSingleSelectColumn,
	booleanColumn,
	enumColumn,
	floatColumn,
	integerColumn,
	textColumn,
} from "src/components/common/dataGrid/columns.tsx";
import i18n from "i18next";
import { CatalogPartApi } from "src/api/generated/erp/parts/catalogPart/api/catalogPartApi.ts";
import { concatWithPipe } from "src/utils/strings.tsx";
import { CatalogPartRevisionLineView } from "src/api/generated/erp/db/types/tables/catalogPartRevisionLineView.ts";
import { CatalogPart } from "src/api/generated/erp/db/types/tables/catalogPart.ts";
import { getPartRevisionLineAcquisitionMethodLabels } from "src/api/generated/erp/db/types/enums/partRevisionLineAcquisitionMethod.ts";
import { DocumentsOfObjectButton } from "src/components/views/documents/objectDocuments/DocumentsOfObjectButton.tsx";
import { nullableAavoObjectRef } from "src/utils/aavoObjectRefUtils.ts";
import { AavoButton } from "src/components/common/buttons/AavoButton.tsx";
import { faSitemap } from "@fortawesome/pro-regular-svg-icons";
import { useGenericDialog } from "src/components/common/dialogs/useGenericDialog.ts";
import { CatalogPartRevisionBomView } from "src/components/views/erp/parts/catalogPart/CatalogPartRevisionBomView.tsx";
import React from "react";
import { AavoDataGridApi } from "src/components/common/dataGrid/AavoDataGridApi.ts";
import { AavoTextField } from "src/components/common/inputFields/AavoTextField.tsx";
import { MenuCheckbox } from "src/components/common/contextMenu/MenuCheckbox.tsx";
import { DataDirtyStateChangeHandler } from "src/utils/dataDirtyStateChangeHandler.ts";
import { CatalogPartRevision } from "src/api/generated/erp/db/types/tables/catalogPartRevision.ts";

export interface CatalogPartRevisionBomDataGridProps {
	catalogPartRevision: CatalogPartRevision;
	onRowSelectionChanged?: (selectedRows: CatalogPartRevisionLineView[]) => void;
	selectedRow?: CatalogPartRevisionLineView | null;
	crudDataGridApiRef?: React.MutableRefObject<CrudDataGridApi | null>;
	gridApiRef?: React.MutableRefObject<AavoDataGridApi<CatalogPartRevisionLineView> | null>;
	dataDirtyStateChanged?: DataDirtyStateChangeHandler;
}

export const CatalogPartRevisionBomDataGrid = ({
	catalogPartRevision,
	onRowSelectionChanged,
	crudDataGridApiRef,
	gridApiRef,
	dataDirtyStateChanged,
}: CatalogPartRevisionBomDataGridProps) => {
	const { openDialog } = useGenericDialog();
	return (
		<ClientSideDataGridModel
			apiRef={gridApiRef}
			gridId={"98251C8B5141695F"}
			fetchData={(params) =>
				CatalogPartRevisionBomDataGridApi.getCatalogPartRevisionBomLines({
					catalogPartRevisionId: catalogPartRevision.catalogPartRevisionId,
					...params,
				})
			}
			initialParams={{
				searchQuery: "",
				showInDesignUnit: false,
			}}
			getRows={(data) => data}
			getRowId={(row) => row.catalogPartRevisionLineId}
			onSelectionChanged={onRowSelectionChanged}
			render={({ onlySelectedRow, currentParams: { showInDesignUnit }, dataGridProps, refreshData, getApi }) => {
				const editable = catalogPartRevision.state === "INITIAL";
				return (
					<CrudDataGrid<CatalogPartRevisionLineView>
						crudDataGridApiRef={crudDataGridApiRef}
						disableMultipleRowSelection
						dataDirtyStateChanged={dataDirtyStateChanged}
						columns={[
							integerColumn({
								field: "referenceNo",
								headerName: i18n.t("reference_no"),
								editable: true,
								validate: "required",
							}),
							getPartSelectionColumn(),
							showInDesignUnit && [
								floatColumn({
									field: "designUnitQuantity",
									headerName: i18n.t("quantity"),
									editable: true,
									validate: "required",
								}),
								textColumn({
									field: "linePartDesignUnitName",
									headerName: i18n.t("design_unit"),
								}),
							],
							!showInDesignUnit && [
								floatColumn({
									field: "quantity",
									headerName: i18n.t("quantity"),
									editable: true,
									validate: "required",
								}),
								textColumn({
									field: "linePartUnitName",
									headerName: i18n.t("unit"),
								}),
							],
							enumColumn({
								field: "acquisitionMethod",
								headerName: i18n.t("acquisition_method"),
								enumLabels: getPartRevisionLineAcquisitionMethodLabels(),
								editable: true,
								validate: "required",
								width: 150,
							}),
							booleanColumn({
								field: "isPhantom",
								headerName: i18n.t("phantom"),
								editable: true,
							}),
							textColumn({
								field: "note",
								headerName: i18n.t("note"),
								editable: true,
								width: 450,
							}),
						]}
						actionBarComponents={
							<>
								<DocumentsOfObjectButton
									objectRef={nullableAavoObjectRef(
										"CATALOG_PART_REVISION",
										onlySelectedRow?.linePartLatestRevisionId,
									)}
								/>
								<AavoButton
									icon={faSitemap}
									label={i18n.t("product_structure")}
									disabled={
										onlySelectedRow == null || onlySelectedRow.linePartLatestRevisionId == null
									}
									variant={"outlined"}
									onClick={() => {
										if (onlySelectedRow == null || onlySelectedRow.linePartLatestRevisionId == null)
											return;
										openDialog({
											title: `${i18n.t("product_structure")} 
											(${onlySelectedRow.linePartNo} 
											${onlySelectedRow.linePartDescription_1} 
											${onlySelectedRow.linePartLatestRevisionNumber})`,

											content: (
												<CatalogPartRevisionBomView
													catalogPartRevisionId={onlySelectedRow.linePartLatestRevisionId}
												/>
											),
										});
									}}
								/>
								<AavoTextField
									label={i18n.t("search")}
									onSubmit={async (value) => {
										await refreshData({ searchQuery: value });
									}}
								/>
							</>
						}
						actionBarMenuComponents={[
							<MenuCheckbox
								key={"showInDesignUnit"}
								label={i18n.t("show_in_design_unit")}
								defaultChecked={showInDesignUnit}
								onChange={async (value) => {
									await refreshData({ showInDesignUnit: value });
								}}
							/>,
						]}
						add={
							editable &&
							(() => ({
								referenceNo: getDefaultRefNo(getApi()),
								catalogPartRevisionId: catalogPartRevision.catalogPartRevisionId,
							}))
						}
						save={
							editable &&
							(({ items }) =>
								CatalogPartRevisionBomDataGridApi.save({
									revisionLines: items.map((item) => ({
										...item,
										quantity: getQuantityInBaseUnit(item),
									})),
								}))
						}
						remove={
							editable &&
							(async ({ items }) => {
								await CatalogPartRevisionBomDataGridApi.delete_({
									revisionLines: items,
								});
							})
						}
						{...dataGridProps}
					/>
				);

				function getQuantityInBaseUnit(revisionLine: CatalogPartRevisionLineView) {
					if (showInDesignUnit)
						return (revisionLine.designUnitQuantity ?? 0) * revisionLine.linePartDesignUnitFactor;
					else return revisionLine.quantity;
				}
			}}
		/>
	);

	function getPartSelectionColumn() {
		return asyncSingleSelectColumn<CatalogPartRevisionLineView, CatalogPart, number>({
			afterColumn: "referenceNo",
			disableClearable: true,
			field: "lineCatalogPartId",
			headerName: i18n.t("part"),
			getOptionKey: (o: CatalogPart) => o.catalogPartId,
			fetchOptions: CatalogPartApi.getCatalogPartOptions,
			getOptionLabel: (option) => concatWithPipe(option.partNo, option.description_1, option.description_2),
			getRowLabel: (row) => concatWithPipe(row.linePartNo, row.linePartDescription_1, row.linePartDescription_2),
			setRowLabel: (row, value) => {
				return {
					...row,
					linePartNo: value?.partNo ?? "",
					linePartDescription_1: value?.description_1 ?? "",
					linePartDescription_2: value?.description_2 ?? "",
				};
			},
			editable: true,
			validate: "required",
			width: 250,
		});
	}

	function getDefaultRefNo(api: AavoDataGridApi<CatalogPartRevisionLineView>): number {
		const currentReferenceNos = api.getRows().map((r) => r.referenceNo);
		if (currentReferenceNos.length === 0) return 1;
		return Math.max(...currentReferenceNos) + 1;
	}
};
