// This file is automatically generated. Do not edit manually.

import { CustomerOrderView } from "src/api/generated/erp/db/types/tables/customerOrderView";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace ProjectCustomerOrdersApi {
	export async function getProjectCustomerOrders(args: { projectId: number }): Promise<Array<CustomerOrderView>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/project/customerOrders/getProjectCustomerOrders",
			method: "POST",
			data: {
				projectId: args.projectId,
			},
		});
		return response as Array<CustomerOrderView>;
	}

	export async function unlinkCustomerOrderFromProject(args: { customerOrderId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/project/customerOrders/unlinkCustomerOrderFromProject",
			method: "POST",
			data: {
				customerOrderId: args.customerOrderId,
			},
		});
		return response as void;
	}
}
