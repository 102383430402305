export function selectWithShiftAndCtrl<TItem, TKey>({
	clickedItem,
	selectedItems,
	allItems,
	getItemKey,
	shiftKey,
	ctrlKey,
}: {
	clickedItem: TItem;
	selectedItems: TItem[];
	allItems: TItem[];
	getItemKey: (item: TItem) => TKey;
	shiftKey: boolean;
	ctrlKey: boolean;
}): TItem[] {
	if (ctrlKey) {
		const clickedItemKey = getItemKey(clickedItem);
		const isSelected = selectedItems.some((item) => getItemKey(item) === clickedItemKey);
		return isSelected ?
				selectedItems.filter((item) => getItemKey(item) !== clickedItemKey)
			:	[...selectedItems, clickedItem];
	} else if (shiftKey) {
		const clickedItemIndex = allItems.findIndex((item) => getItemKey(item) === getItemKey(clickedItem));

		const firstSelectedItem = selectedItems[0];
		if (!firstSelectedItem) return [clickedItem];
		const firstSelectedItemIndex = allItems.findIndex((item) => getItemKey(item) === getItemKey(firstSelectedItem));

		const lastSelectedItem = selectedItems[selectedItems.length - 1];
		if (!lastSelectedItem) return [clickedItem];
		const lastSelectedItemIndex = allItems.findIndex((item) => getItemKey(item) === getItemKey(lastSelectedItem));

		return allItems.slice(
			Math.min(firstSelectedItemIndex, clickedItemIndex),
			Math.max(lastSelectedItemIndex, clickedItemIndex) + 1,
		);
	} else {
		return [clickedItem];
	}
}
