import { ObjectAttributeField } from "src/api/generated/erp/common/objectAttributes/objectAttributeField.ts";
import { ObjectAttribute } from "src/api/generated/erp/db/types/tables/objectAttribute.ts";
import { AttributeObjectType } from "src/api/generated/erp/db/types/enums/attributeObjectType.ts";
import dayjs from "dayjs";

export interface AttributeFieldWithValue extends ObjectAttributeField, ObjectAttribute {}

export function createAttributeFieldWithValue({
	objectType,
	objectId,
	attributeField,
	attribute,
}: {
	objectType: AttributeObjectType;
	objectId: number;
	attributeField: ObjectAttributeField;
	attribute: ObjectAttribute | undefined;
}): AttributeFieldWithValue {
	return {
		...attributeField,
		objectType: objectType,
		objectId: objectId,
		attributeFieldId: attributeField.id,
		attributeValue: attribute?.attributeValue ?? null,
		objectAttributeId: -1,
		// backend will set rest for unsaved attribute
		valueModifiedAt: dayjs().toISOString(),
		valueModifiedByUserId: -1,
	};
}
