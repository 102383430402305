import { ConfiguratorLibraryFunction } from "src/api/generated/erp/db/types/tables/configuratorLibraryFunction.ts";
import {
	ConfiguratorLibraryFunctionsApi,
	ConfiguratorLibraryFunctionsApi_FormInitData,
} from "src/api/generated/erp/configurator/management/libraries/api/configuratorLibraryFunctionsApi.ts";
import { AsyncForm } from "src/components/common/forms/AsyncForm.tsx";
import { FormCommonProps } from "src/components/common/forms/types.ts";
import { FormTextField } from "src/components/common/forms/fields/FormTextField.tsx";
import { requireRule } from "src/components/common/forms/validation.ts";
import i18n from "i18next";
import { FormConfiguratorLuaEditor } from "src/components/views/erp/configurator/scripting/FormConfiguratorLuaEditor.tsx";
import { DeepPartial } from "react-hook-form";

export interface ConfiguratorLibraryFunctionFormProps extends FormCommonProps<number> {
	configuratorLibraryVersionId: number;
	configuratorLibraryFunctionId: number | undefined;
}

interface FormValues extends ConfiguratorLibraryFunction {}

export const ConfiguratorLibraryFunctionForm = (props: ConfiguratorLibraryFunctionFormProps) => {
	const { configuratorLibraryVersionId, configuratorLibraryFunctionId, onCompleted, onFormEdited } = props;
	return (
		<AsyncForm
			fetch={() =>
				ConfiguratorLibraryFunctionsApi.getFormInitData({
					configuratorLibraryVersionId,
					configuratorLibraryFunctionId,
				})
			}
			getDefaultValues={getDefaultValues}
			submit={submit}
			onCompleted={onCompleted}
			onFormEdited={onFormEdited}
			getUseFormProps={({ libraryVersion }) => ({
				disabled: libraryVersion.state !== "INITIAL",
			})}
			render={({ control }) => (
				<>
					<FormTextField
						control={control}
						name={"functionName"}
						label={i18n.t("name")}
						rules={requireRule()}
					/>
					<FormTextField
						control={control}
						name={"description"}
						label={i18n.t("description")}
						rules={requireRule()}
						multiline
					/>
					<FormConfiguratorLuaEditor
						control={control}
						name={"functionBody"}
						label={i18n.t("code")}
						productFamilyVersionId={null}
						catalogPartRevisionId={null}
						rules={requireRule()}
					/>
				</>
			)}
		/>
	);

	function getDefaultValues({
		libraryFunction,
	}: ConfiguratorLibraryFunctionsApi_FormInitData): DeepPartial<FormValues> {
		return (
			libraryFunction ?? {
				configuratorLibraryVersionId,
				functionBody: "return args.a + args.b"
			}
		);
	}

	async function submit(values: FormValues) {
		if (configuratorLibraryFunctionId != null) {
			await ConfiguratorLibraryFunctionsApi.update({
				configuratorLibraryFunctionId: configuratorLibraryFunctionId,
				functionName: values.functionName,
				description: values.description,
				functionBody: values.functionBody,
			});
			return configuratorLibraryFunctionId;
		} else {
			return await ConfiguratorLibraryFunctionsApi.insert({
				configuratorLibraryVersionId: configuratorLibraryVersionId,
				functionName: values.functionName,
				description: values.description,
				functionBody: values.functionBody,
			});
		}
	}
};
