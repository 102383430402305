import {
    SpcSamplingMethod
} from "src/components/views/spc/basedata/controlCharts/form/samplingMethod/SpcSamplingMethod.tsx";
import i18n from "i18next";

export const getKaskipuuAggregateRecordSpcSamplingMethod = (): SpcSamplingMethod => {
    return {
        key: "aggregate_record",
        label: i18n.t("completed_quantity"),
        renderParameterFields: () => <></>,
    };
};
