import { AsyncFetchRender } from "src/components/common/async/AsyncFetchRender.tsx";
import { FormCommonProps } from "src/components/common/forms/types.ts";
import { DeliveryPackage } from "src/api/generated/erp/db/types/tables/deliveryPackage.ts";
import { AavoForm } from "src/components/common/forms/AavoForm.tsx";
import { FormTextField } from "src/components/common/forms/fields/FormTextField.tsx";
import {
	nonNegativeFloatRule,
	requiredNonNegativeIntegerRule,
	requireRule,
} from "src/components/common/forms/validation.ts";
import i18n from "i18next";
import { FormSelectField } from "src/components/common/forms/fields/FormSelectField.tsx";
import {
	DeliveryPackageFormApi,
	DeliveryPackageFormApi_InitData,
} from "src/api/generated/erp/delivery/api/deliveryPackageFormApi.ts";
import { DeliverySource } from "src/api/generated/erp/delivery/model/deliverySource.ts";
import {
	deliverySourceToSourceId,
	deliverySourceToSourceType,
} from "src/components/views/erp/delivery/deliverySourceUtils.ts";
import { useStoredState } from "../../../../../utils/useStoredState.ts";
import { genericNullableValue } from "../../../../../utils/genericNullableValue.ts";
import { DeepPartial } from "react-hook-form";

export interface DeliveryPackageFormProps extends FormCommonProps<number> {
	deliverySource: DeliverySource;
	deliveryPackageId: number | null | undefined;
}

export const DeliveryPackageForm = (props: DeliveryPackageFormProps) => {
	const { deliverySource, deliveryPackageId } = props;
	return (
		<AsyncFetchRender
			fetch={() => DeliveryPackageFormApi.getInitData({ source: deliverySource, deliveryPackageId })}
			content={(initData) => <DeliveryPackageFormContent initData={initData} {...props} />}
		/>
	);
};

interface DeliveryPackageFormContentProps extends DeliveryPackageFormProps {
	initData: DeliveryPackageFormApi_InitData;
}

const DeliveryPackageFormContent = ({
	deliveryPackageId,
	initData,
	deliverySource,
	onFormEdited,
	onCompleted,
}: DeliveryPackageFormContentProps) => {
	const [storedDefaultValues, setStoredDefaultValues] = useStoredState({
		defaultValue: {
			packageTypeId: genericNullableValue<number>(),
			warehouseLocationId: genericNullableValue<number>(),
		},
		key: "6B932BA61962B645",
	});

	return (
		<AavoForm<DeliveryPackage, number>
			defaultValues={getDefaultValues()}
			submit={submit}
			columns={2}
			onFormEdited={onFormEdited}
			onCompleted={onCompleted}
			render={({ control }) => (
				<>
					<FormTextField
						control={control}
						name={"packageNo"}
						rules={requiredNonNegativeIntegerRule()}
						label={i18n.t("package_no")}
					/>
					<FormTextField control={control} name={"description"} label={i18n.t("description")} />
					<FormSelectField
						control={control}
						name={"packageTypeId"}
						label={i18n.t("type")}
						options={initData.packageTypeOptions}
						getOptionKey={(o) => o.deliveryPackageTypeId}
						getOptionLabel={(o) => o.name}
						rules={requireRule()}
					/>
					<FormSelectField
						control={control}
						name={"warehouseLocationId"}
						label={i18n.t("shipping_warehouse_location")}
						options={initData.warehouseLocationOptions}
						getOptionKey={(o) => o.warehouseLocationId}
						getOptionLabel={(o) => o.locationCode}
						rules={requireRule()}
					/>
					<FormTextField
						control={control}
						name={"weight"}
						label={i18n.t("weight")}
						rules={nonNegativeFloatRule()}
					/>

					<FormTextField
						control={control}
						name={"length"}
						label={i18n.t("length_cm")}
						rules={nonNegativeFloatRule()}
					/>
					<FormTextField
						control={control}
						name={"width"}
						label={i18n.t("width_cm")}
						rules={nonNegativeFloatRule()}
					/>
					<FormTextField
						control={control}
						name={"height"}
						label={i18n.t("height_cm")}
						rules={nonNegativeFloatRule()}
					/>
				</>
			)}
		/>
	);

	function getDefaultValues(): DeepPartial<DeliveryPackage> {
		return (
			initData.deliveryPackage ?? {
				packageNo: initData.nextPackageNumber,
				warehouseLocationId:
					storedDefaultValues.warehouseLocationId ??
					initData.warehouseLocationOptions[0]?.warehouseLocationId,
				packageTypeId: storedDefaultValues.packageTypeId ?? undefined,
				sourceType: deliverySourceToSourceType(deliverySource),
				sourceId: deliverySourceToSourceId(deliverySource),
			}
		);
	}

	async function submit(deliveryPackage: DeliveryPackage): Promise<number> {
		setStoredDefaultValues(deliveryPackage);

		if (deliveryPackageId == null) {
			return await DeliveryPackageFormApi.insert({
				deliveryPackage: deliveryPackage,
			});
		} else {
			await DeliveryPackageFormApi.update({
				deliveryPackage: deliveryPackage,
			});
			return deliveryPackageId;
		}
	}
};
