import { ControlledAsyncDataGrid } from "src/components/common/dataGrid/ControlledAsyncDataGrid";
import { useServerSideDataGridModel } from "src/components/common/dataGrid/gridModel/useServerSideDataGridModel";
import {
	DeliverableCustomerOrdersDataGridApi,
	DeliverableCustomerOrdersDataGridApi_Row,
} from "src/api/generated/erp/sales/delivery/api/deliverableCustomerOrdersDataGridApi.ts";
import { genericNullableValue } from "src/utils/genericNullableValue.ts";
import { dateColumn, integerColumn, textColumn } from "src/components/common/dataGrid/columns.tsx";
import i18n from "i18next";
import { formatDayJs } from "src/utils/dayjsUtils.ts";
import { AavoTextField } from "src/components/common/inputFields/AavoTextField.tsx";
import { LazySelectField } from "src/components/common/inputFields/LazySelectField.tsx";
import { LazyMultiSelectField } from "src/components/common/inputFields/LazyMultiSelectField.tsx";
import { CustomerOrderTypeApi } from "src/api/generated/erp/sales/basedata/api/customerOrderTypeApi.ts";
import { TransportRouteApi } from "src/api/generated/erp/sales/basedata/api/transportRouteApi.ts";
import { OpenCustomerOrderButton } from "src/components/views/erp/utilComponents/OpenCustomerOrderButton.tsx";
import { OpenCustomerOrderInfoButton } from "src/components/views/erp/utilComponents/OpenCustomerOrderInfoButton.tsx";
import { MenuCheckbox } from "src/components/common/contextMenu/MenuCheckbox.tsx";
import { useGenericDialog } from "src/components/common/dialogs/useGenericDialog.ts";
import { OpenDeliveryViewButton } from "src/components/views/erp/utilComponents/OpenDeliveryViewButton.tsx";
import {
	DeliverySourceDeliveringView
} from "src/components/views/erp/delivery/delivery/DeliverySourceDeliveringView.tsx";

export interface DeliverableCustomerOrdersDataGridProps {}

export const DeliverableCustomerOrdersDataGrid = ({}: DeliverableCustomerOrdersDataGridProps) => {
	const { openDialog } = useGenericDialog();

	const { dataGridProps, onlySelectedRow, refreshData, currentParams } = useServerSideDataGridModel({
		fetchData: (params) => DeliverableCustomerOrdersDataGridApi.getRows(params),
		initialParams: {
			onlyDefaultSiteOrders: true,
			customerOrderTypeIdFilter: Array<number>(),
			transportRouteId: genericNullableValue<number>(),
			searchQuery: "",
		},
		getRowId: (row) => row.customerOrderId,
		gridId: "1D40B9C2CF781BAC",
	});

	return (
		<ControlledAsyncDataGrid<DeliverableCustomerOrdersDataGridApi_Row>
			disableMultipleRowSelection
			columns={[
				integerColumn({
					field: "customerOrderId",
					headerName: i18n.t("number_shortened"),
				}),
				textColumn({
					field: "siteName",
					headerName: i18n.t("site"),
				}),
				textColumn({
					field: "customerName",
					headerName: i18n.t("customer"),
					width: 200,
				}),
				textColumn({
					field: "orderReference",
					headerName: i18n.t("reference"),
					width: 200,
				}),
				textColumn({
					field: "customerOrderTypeName",
					headerName: i18n.t("order_type"),
					width: 150,
				}),
				dateColumn({
					field: "nextDeliveryDate",
					headerName: i18n.t("next_shipping_date"),
					valueFormatter: (value) => `${formatDayJs(value)} (${i18n.t("week_abbr")} ${value.week()})`,
					width: 200,
				}),
				textColumn({
					field: "transportRouteName",
					headerName: i18n.t("transport_route"),
					width: 150,
				}),
			]}
			actionBarComponents={
				<>
					<AavoTextField
						label={i18n.t("search")}
						onSubmit={async (searchQuery) => {
							await refreshData({ searchQuery });
						}}
					/>
					<LazySelectField
						label={i18n.t("transport_route")}
						fetchOptions={TransportRouteApi.getAllTransportRoutes}
						getOptionKey={(o) => o.transportRouteId}
						getOptionLabel={(o) => o.name}
						onChange={async (value) => {
							await refreshData({ transportRouteId: value });
						}}
					/>
					<LazyMultiSelectField
						label={i18n.t("order_type")}
						fetchOptions={CustomerOrderTypeApi.getAll}
						getOptionKey={(option) => option.customerOrderTypeId}
						getOptionLabel={(option) => option.name}
						onChange={async (newValue) => {
							await refreshData({ customerOrderTypeIdFilter: newValue });
						}}
					/>
					<OpenDeliveryViewButton
						deliverySource={
							onlySelectedRow == null ? null : (
								{
									type: "customerOrder",
									customerOrderId: onlySelectedRow.customerOrderId,
								}
							)
						}
						variant={"outlined"}
					/>
				</>
			}
			rowContextMenuComponents={({ row }) => [
				<OpenCustomerOrderButton key={"openCustomerOrder"} customerOrderId={row.customerOrderId} />,
				<OpenCustomerOrderInfoButton key={"openCustomerOrderInfo"} customerOrderId={row.customerOrderId} />,
			]}
			actionBarMenuComponents={[
				<MenuCheckbox
					label={i18n.t("show_only_default_site_orders")}
					checked={currentParams.onlyDefaultSiteOrders}
					onChange={async (checked) => {
						await refreshData({ onlyDefaultSiteOrders: checked });
					}}
				/>,
			]}
			onRowDoubleClick={({ row }) => {
				openDialog({
					title: i18n.t("delivery"),
					content: (
						<DeliverySourceDeliveringView
							deliverySource={{
								type: "customerOrder",
								customerOrderId: row.customerOrderId,
							}}
						/>
					),
				});
			}}
			{...dataGridProps}
		/>
	);
};
