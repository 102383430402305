import {InventoryLevelSummary} from "src/api/generated/erp/warehouse/inventory/inventoryEvent/inventoryLevelSummary.ts";
import {HorizontalBox} from "src/components/common/box/HorizontalBox.tsx";
import i18n from "i18next";
import {Typography} from "@mui/material";

export const InventoryLevelSummaryText = ({ summary }: { summary: InventoryLevelSummary }) => {
	return (
		<HorizontalBox
			sx={{
				gap: 2,
				alignItems: "center",
			}}
		>
			<LabelValuePair label={i18n.t("total")} value={summary.stockQuantity} />
			<LabelValuePair label={i18n.t("reserved")} value={summary.reservedQuantity} />
			<LabelValuePair label={i18n.t("available")} value={summary.stockQuantity - summary.reservedQuantity} />
		</HorizontalBox>
	);
};

const LabelValuePair = ({ label, value }: { label: string; value: number }) => {
	return (
		<HorizontalBox
			sx={{
				gap: 0.5,
			}}
		>
			<Typography>{`${label}: `}</Typography>
			<Typography
				sx={{
					fontStyle: "italic",
				}}
			>
				{value}
			</Typography>
		</HorizontalBox>
	);
};
