// This file is automatically generated. Do not edit manually.

import { t } from "i18next";

export const ShopOrderStateValues = ["INITIAL", "PLANNED", "RELEASED", "STARTED", "READY", "CANCELLED"] as const;

export type ShopOrderState = (typeof ShopOrderStateValues)[number];

export const getShopOrderStateLabels = () => ({
	INITIAL: t("initial"),
	PLANNED: t("planned"),
	RELEASED: t("released"),
	STARTED: t("started"),
	READY: t("ready"),
	CANCELLED: t("cancelled"),
});

export const getShopOrderStateLabel = (value: ShopOrderState): string => {
	return getShopOrderStateLabels()[value];
};
