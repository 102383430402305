import {
    ControlledAsyncCrudDataGrid,
    ControlledAsyncCrudDataGridProps,
} from "../../../common/dataGrid/crud/ControlledAsyncCrudDataGrid.tsx";
import {ReclamationView} from "src/api/generated/erp/db/types/tables/reclamationView.ts";
import {dateColumn, dateTimeColumn, enumColumn, integerColumn, textColumn,} from "../../../common/dataGrid/columns.tsx";
import i18n from "i18next";
import {
    getReclamationStateLabel,
    getReclamationStateLabels,
    ReclamationState,
} from "src/api/generated/erp/db/types/enums/reclamationState.ts";
import {DocumentsOfObjectButton} from "../../documents/objectDocuments/DocumentsOfObjectButton.tsx";
import {nullableAavoObjectRef} from "src/utils/aavoObjectRefUtils.ts";
import {useServerSideDataGridModel} from "src/components/common/dataGrid/gridModel/useServerSideDataGridModel";
import {AavoTextField} from "src/components/common/inputFields/AavoTextField.tsx";
import {genericNullableValue} from "src/utils/genericNullableValue.ts";
import {LazySelectField} from "src/components/common/inputFields/LazySelectField.tsx";
import {RefreshableElementProps, setRefreshRefValue} from "src/utils/useRefreshRef.ts";
import {ReclamationForm} from "./ReclamationForm.tsx";
import {AsyncMenuButton} from "../../../common/contextMenu/AsyncMenuButton.tsx";
import {faCheck, faLink, faPrint, faShare, faUndo} from "@fortawesome/pro-regular-svg-icons";
import {ReclamationOperationApi} from "src/api/generated/erp/reclamations/api/reclamationOperationApi.ts";
import {ReclamationQueryApi} from "src/api/generated/erp/reclamations/api/reclamationQueryApi.ts";
import {SelectField} from "../../../common/inputFields/SelectField.tsx";
import {downloadFile} from "src/utils/fileDownloading.ts";
import {useInputDialog} from "src/components/common/dialogs/input/useInputDialog.tsx";
import {InputDialogType, InputDialogValueType, ShowInputDialogParams} from "../../../common/dialogs/input/types.ts";
import {OpenCustomerOrderButton} from "src/components/views/erp/utilComponents/OpenCustomerOrderButton.tsx";
import {LazyMultiSelectField} from "src/components/common/inputFields/LazyMultiSelectField.tsx";
import {CustomerOrderQueryApi} from "src/api/generated/erp/sales/customerOrder/api/customerOrderQueryApi.ts";
import React from "react";
import {AavoDataGridRowContextMenuParams} from "../../../common/dataGrid/AavoDataGrid.tsx";
import {useUserPermissions} from "src/components/views/erp/common/userPermissions.ts";
import {useGlobalInitData} from "src/contexts/useGlobalInitData.ts";
import {SelectSiteField} from "src/components/views/erp/common/sites/SelectSiteField.tsx";
import {ReclamationEditApi} from "src/api/generated/erp/reclamations/api/reclamationEditApi.ts";

export interface ReclamationsDataGridBaseProps
	extends RefreshableElementProps,
		Pick<ControlledAsyncCrudDataGridProps<ReclamationView>, "actionBarComponents"> {
	states?: ReclamationState[];
	selectedReclamations: ReclamationView[];
	onSelectionChanged: (selectedReclamations: ReclamationView[]) => void;
	onReclamationReleased?: (reclamation: ReclamationView) => void;
	onReclamationClosed?: (reclamation: ReclamationView) => void;
	onReclamationCloseReverted?: (reclamation: ReclamationView) => void;
	onConnectedCustomerOrdersChanged?: () => void;
	fetchOnlySingleReclamationId?: number;
	usePagination?: boolean;
	onDataLoaded?: (newRows: ReclamationView[]) => void;
	gridId: string;
	customerOrderId?: number;
	siteId?: number;
	rowContextMenuComponents?: (params: AavoDataGridRowContextMenuParams<ReclamationView>) => React.ReactNode[];
}

export const ReclamationsDataGridBase = ({
	refreshRef,
	actionBarComponents,
	rowContextMenuComponents,
	states = [],
	selectedReclamations,
	onReclamationReleased,
	onSelectionChanged,
	onReclamationClosed,
	onReclamationCloseReverted,
	onConnectedCustomerOrdersChanged,
	fetchOnlySingleReclamationId,
	usePagination,
	onDataLoaded,
	gridId,
	customerOrderId,
	siteId,
}: ReclamationsDataGridBaseProps) => {
	const showInputDialog = useInputDialog();
	const userPermissions = useUserPermissions();
	const { defaultSiteId } = useGlobalInitData();
	const { dataGridProps, currentParams, refreshData, onlySelectedRow } = useServerSideDataGridModel({
		gridId: gridId,
		fetchData: ReclamationQueryApi.getReclamations,
		initialParams: {
			siteId: genericNullableValue(siteId ?? defaultSiteId),
			reclamationId: fetchOnlySingleReclamationId,
			states: states,
			searchQuery: "",
			responsiblePersonId: genericNullableValue<number>(null),
			categoryIds: Array<number>(),
			customerOrderId: customerOrderId,
		},
		getRowId: (row) => row.reclamationId,
		usePagination: usePagination,
		afterRefresh: ({ newRows }) => {
			onDataLoaded?.(newRows);
		},
		selectedRows: selectedReclamations,
		onSelectionChanged: onSelectionChanged,
	});

	setRefreshRefValue(refreshRef, refreshData);

	return (
		<ControlledAsyncCrudDataGrid<ReclamationView>
			columns={[
				integerColumn({
					field: "reclamationId",
					headerName: i18n.t("number_shortened"),
					width: 65,
				}),
				states.length !== 1 &&
					enumColumn({
						field: "reclamationState",
						headerName: i18n.t("state"),
						enumLabels: getReclamationStateLabels(),
					}),
				textColumn({
					field: "title",
					headerName: i18n.t("title"),
					width: 150,
				}),
				textColumn({
					field: "subjectCode",
					headerName: i18n.t("subject_code"),
					width: 150,
				}),
				textColumn({
					field: "city" as any,
					headerName: i18n.t("city"),
					width: 150,
					valueGetter: (_, row) => row.address?.city ?? (row as any).city, // Secondary form is for CSV-export (address is embedded field)
				}),
				textColumn({
					field: "customerOrderLongName",
					headerName: i18n.t("customer_order"),
					width: 150,
				}),
				dateColumn({
					field: "plannedCompletedDate",
					headerName: i18n.t("planned_completed_date_short"),
					width: 110,
				}),
				textColumn({
					field: "reclamationCategoryName",
					headerName: i18n.t("category"),
				}),
				textColumn({
					field: "reclamationCauseName",
					headerName: i18n.t("cause"),
					width: 120,
				}),
				textColumn({
					field: "responsiblePersonUserName",
					headerName: i18n.t("responsible_person"),
					width: 130,
				}),
				textColumn({
					field: "siteName",
					headerName: i18n.t("site"),
					width: 100,
				}),
				dateTimeColumn({
					field: "releasedAt",
					headerName: i18n.t("released_at"),
					width: 140,
				}),
				dateTimeColumn({
					field: "closedAt",
					headerName: i18n.t("closed_at"),
					width: 140,
				}),
				textColumn({
					field: "createdByUserName",
					headerName: i18n.t("created_by"),
					width: 130,
				}),
				dateTimeColumn({
					field: "createdAt",
					headerName: i18n.t("created_at"),
					width: 130,
				}),
				textColumn({
					field: "complainerName",
					headerName: i18n.t("complainer"),
				}),
				textColumn({
					field: "complainerPhone",
					headerName: i18n.t("complainer_phone_short"),
					width: 110,
				}),
				textColumn({
					field: "complainerEmail",
					headerName: i18n.t("complainer_email"),
					width: 130,
				}),
			]}
			actionBarComponents={
				<>
					<DocumentsOfObjectButton
						objectRef={nullableAavoObjectRef("RECLAMATION_V2", onlySelectedRow?.reclamationId)}
					/>
					{siteId == null && (
						<SelectSiteField value={currentParams.siteId} onChange={(siteId) => refreshData({ siteId })} />
					)}
					<LazySelectField
						label={i18n.t("responsible_person")}
						fetchOptions={ReclamationQueryApi.getResponsiblePersonOptions}
						getOptionKey={(o) => o.appUserId}
						getOptionLabel={(o) => o.name}
						onChange={async (value) => {
							await refreshData({ responsiblePersonId: value });
						}}
					/>
					<AavoTextField
						label={i18n.t("search")}
						onSubmit={async (input) => {
							await refreshData({ searchQuery: input });
						}}
					/>
					<LazyMultiSelectField
						label={i18n.t("category")}
						value={currentParams.categoryIds}
						onChange={async (value) => {
							await refreshData({ categoryIds: value });
						}}
						fetchOptions={ReclamationQueryApi.getReclamationCategoryOptions}
						getOptionKey={(o) => o.reclamationCategoryId}
						getOptionLabel={(o) => o.name}
					/>
					{states.length > 1 && (
						<SelectField
							label={i18n.t("state")}
							options={states}
							getOptionKey={(o) => o}
							onChange={async (value) => {
								await refreshData({ states: value === null ? states : [value] });
							}}
							getOptionLabel={(o) => getReclamationStateLabel(o)}
						/>
					)}
					{actionBarComponents}
				</>
			}
			rowContextMenuComponents={(contextMenuParams) => {
				const { row, onlySingleRowSelected } = contextMenuParams;
				return [
					(row.reclamationState === "INITIAL" || row.reclamationState === "RELEASED") &&
						onlySingleRowSelected &&
						row.reclamationState == "INITIAL" && (
							<AsyncMenuButton
								key={"release"}
								label={i18n.t("release")}
								icon={faShare}
								onClick={async () => {
									const releaseTasks = await showInputDialog({
										type: "boolean",
										title: i18n.t("release_reclamation"),
										fieldLabel: i18n.t("release_tasks_too"),
										defaultValue: true,
									});
									if (releaseTasks === undefined) return;

									await ReclamationOperationApi.releaseReclamation({
										reclamationId: row.reclamationId,
										releaseTasks: releaseTasks,
									});
									await refreshData();
									onReclamationReleased?.(row);
								}}
							/>
						),
					(row.reclamationState === "INITIAL" || row.reclamationState === "RELEASED") &&
						onlySingleRowSelected &&
						row.reclamationState == "RELEASED" && (
							<AsyncMenuButton
								key={"close"}
								label={i18n.t("close")}
								icon={faCheck}
								onClick={async () => {
									await ReclamationOperationApi.closeReclamation({
										reclamationId: row.reclamationId,
									});
									await refreshData();
									onReclamationClosed?.(row);
								}}
							/>
						),
					row.reclamationState === "CLOSED" && onlySingleRowSelected && (
						<AsyncMenuButton
							key={"revert_close"}
							label={i18n.t("revert_close")}
							icon={faUndo}
							onClick={async () => {
								await ReclamationOperationApi.revertCloseReclamation({
									reclamationId: row.reclamationId,
								});
								await refreshData();
								onReclamationCloseReverted?.(row);
							}}
						/>
					),
					onlySingleRowSelected && (
						<AsyncMenuButton
							key={"print"}
							label={i18n.t("print")}
							icon={faPrint}
							onClick={async () => {
								const fileHandle = await ReclamationOperationApi.printReclamation({
									reclamationId: row.reclamationId,
								});
								downloadFile(fileHandle);
							}}
						/>
					),
					onlySingleRowSelected && (
						<AsyncMenuButton
							key={"connectToCustomerOrder"}
							label={i18n.t("connect_to_customer_order")}
							icon={faLink}
							onClick={async () => {
								const customerOrderId = await selectCustomerOrderToConnect(showInputDialog);
								if (customerOrderId == undefined) return;
								await ReclamationOperationApi.connectToCustomerOrder({
									reclamationId: row.reclamationId,
									customerOrderId: customerOrderId,
								});
								onConnectedCustomerOrdersChanged?.();
							}}
						/>
					),
					onlySingleRowSelected &&
						customerOrderId == null &&
						row.customerOrderId != null &&
						userPermissions.sales.readCustomerOrders && (
							<OpenCustomerOrderButton key={"openCustomerOrder"} customerOrderId={row.customerOrderId} />
						),
					...(rowContextMenuComponents?.(contextMenuParams) ?? []),
				];
			}}
			form={{
				dialogSize: "xl",
				dialogTitle: i18n.t("reclamation"),
				addRowEnabled: true,
				editEnabled: true,
				component: ({ row, onCompleted, onFormEdited }) => {
					return (
						<ReclamationForm
							reclamationId={row?.reclamationId}
							customerOrderId={customerOrderId}
							siteId={siteId}
							onFormEdited={onFormEdited}
							onCompleted={(result) => onCompleted(result)}
						/>
					);
				},
			}}
			remove={({ row }) => ReclamationEditApi.delete_({ reclamationId: row.reclamationId })}
			{...dataGridProps}
		/>
	);
};

function selectCustomerOrderToConnect(
	showInputDialog: <T extends InputDialogType>(
		params: ShowInputDialogParams<T>,
	) => Promise<InputDialogValueType<T> | undefined>,
) {
	return showInputDialog({
		defaultValue: null,
		type: "singleNumberSelect",
		title: i18n.t("connect_to_customer_order"),
		fieldLabel: i18n.t("customer_order"),
		fieldProps: {
			selection: {
				options: async ({ searchQuery }) => {
					const customerOrders = await CustomerOrderQueryApi.getCustomerOrderOptions({
						searchQuery: searchQuery,
					});
					return customerOrders.map((co) => ({
						value: co.customerOrderId,
						label: `${co.customerOrderId} | ${co.orderReference}`,
					}));
				},
			},
		},
	});
}
