import i18n from "i18next";
import { Box, Typography } from "@mui/material";
import { mergeSx } from "src/utils/styles";
import { alignCenter, columnFlex, flex1, gap1, justifyCenter } from "src/styles/sx";
import { useSearchParams } from "react-router-dom";
import { makeAxiosRequest } from "src/api/utils/apiClient";
import { AsyncFetchRender } from "src/components/common/async/AsyncFetchRender";

export const OAuthCallbackView = () => {
	const [searchParams] = useSearchParams();

	return (
		<AsyncFetchRender
			fetch={() => sendOAuthCallback(Object.fromEntries(searchParams.entries()))}
			content={() => {
				return (
					<Box sx={mergeSx(columnFlex, flex1, justifyCenter, alignCenter, gap1)}>
						<Typography color={"primary"} variant={"h5"}>
							{i18n.t("oauth_connected_succeeded_title")}
						</Typography>
						<Typography variant={"body1"}>
							{i18n.t("oauth_connected_succeeded_body")}
						</Typography>
					</Box>
				);
			}}
		/>
	);
};

const sendOAuthCallback = async (urlParams: Record<string, string>) => {
	return await makeAxiosRequest<any>({
		url: "/api/connection/oauth/callback",
		method: "POST",
		params: urlParams,
	});
};

export default OAuthCallbackView;
