// This file is automatically generated. Do not edit manually.

import { CapacityDay } from "src/api/generated/erp/db/types/tables/capacityDay";
import { IsoDateString } from "src/types/dateTime";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace ProductionLineCapacityEditApi {
	export async function insert(args: { capacityDay: CapacityDay }): Promise<number> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/productionLines/capacity/insert",
			method: "POST",
			data: {
				capacityDay: args.capacityDay,
			},
		});
		return response as number;
	}

	export async function update(args: { capacityDay: CapacityDay }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/productionLines/capacity/update",
			method: "POST",
			data: {
				capacityDay: args.capacityDay,
			},
		});
		return response as void;
	}

	export async function delete_(args: { capacityDayIds: Array<number> }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/productionLines/capacity/delete",
			method: "POST",
			data: {
				capacityDayIds: args.capacityDayIds,
			},
		});
		return response as void;
	}

	export async function getProductionLineMaxCapacityDate(args: {
		productionLineId: number;
	}): Promise<IsoDateString | null> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/productionLines/capacity/getProductionLineMaxCapacityDate",
			method: "POST",
			data: {
				productionLineId: args.productionLineId,
			},
		});
		return response === "" ? null : (response as IsoDateString | null);
	}

	export async function generateCapacityDays(args: {
		productionLineId: number;
		startDate: IsoDateString;
		dayCount: number;
		weekdayCapacity: number;
		saturdayCapacity: number;
		sundayCapacity: number;
	}): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/productionLines/capacity/generateCapacityDays",
			method: "POST",
			data: {
				productionLineId: args.productionLineId,
				startDate: args.startDate,
				dayCount: args.dayCount,
				weekdayCapacity: args.weekdayCapacity,
				saturdayCapacity: args.saturdayCapacity,
				sundayCapacity: args.sundayCapacity,
			},
		});
		return response as void;
	}
}
