import { AavoToastContextProvider } from "./context";
import { AavoToast } from "./AavoToast";

export const AavoToastProvider = ({ children }: React.PropsWithChildren) => {
	return (
		<AavoToastContextProvider>
			<AavoToast />
			{children}
		</AavoToastContextProvider>
	);
};
