import { AccountingCodesApi } from "src/api/generated/erp/sales/accounting/api/accountingCodesApi.ts";
import { booleanColumn, textColumn } from "src/components/common/dataGrid/columns.tsx";
import { ControlledAsyncCrudDataGrid } from "src/components/common/dataGrid/crud/ControlledAsyncCrudDataGrid.tsx";
import i18n from "i18next";
import { AccountingCode } from "src/api/generated/erp/db/types/tables/accountingCode.ts";
import { useClientSideDataGridModel } from "src/components/common/dataGrid/gridModel/useClientSideDataGridModel.tsx";
import { MenuCheckbox } from "src/components/common/contextMenu/MenuCheckbox.tsx";
import { AsyncMenuButton } from "src/components/common/contextMenu/AsyncMenuButton.tsx";
import { faBan, faUndo } from "@fortawesome/pro-regular-svg-icons";
import { useConfirmDialog } from "src/components/common/dialogs/confirmDialog/useConfirmDialog.ts";

export interface AccountingDimensionCodesDataGridProps {
	dimensionId: number;
}

export const AccountingDimensionCodesDataGrid = ({ dimensionId }: AccountingDimensionCodesDataGridProps) => {
	const showConfirmDialog = useConfirmDialog();

	const { dataGridProps, currentParams, refreshData } = useClientSideDataGridModel({
		fetchData: ({ includeInactiveCodes }) =>
			AccountingCodesApi.getAccountingCodesOfDimension({
				dimensionId,
				includeInactiveCodes,
			}),
		initialParams: {
			includeInactiveCodes: false,
		},
		getRowId: (row) => row.accountingCodeId,
		gridId: "2D5F11DB11D19766",
	});
	return (
		<ControlledAsyncCrudDataGrid<AccountingCode>
			columns={[
				textColumn({
					field: "code",
					headerName: i18n.t("code"),
					width: 120,
					editable: true,
					validate: "required",
				}),
				textColumn({
					field: "description",
					headerName: i18n.t("description"),
					width: 300,
					editable: true,
				}),
				booleanColumn({
					field: "isActive",
					headerName: i18n.t("active"),
				}),
			]}
			add={() => ({
				dimensionId: dimensionId,
				code: "",
				description: "",
			})}
			save={({ items }) => AccountingCodesApi.save({ accountingCodes: items })}
			remove={({ items }) =>
				AccountingCodesApi.delete_({
					accountingCodeIds: items.map((item) => item.accountingCodeId),
				})
			}
			actionBarMenuComponents={[
				<MenuCheckbox
					key={"includeInactiveCodes"}
					label={i18n.t("show_inactive")}
					checked={currentParams.includeInactiveCodes}
					onChange={(value) => refreshData({ includeInactiveCodes: value })}
				/>,
			]}
			rowContextMenuComponents={({ row, onlySingleRowSelected }) => [
				onlySingleRowSelected && (
					<AsyncMenuButton
						key={"setIsActive"}
						label={row.isActive ? i18n.t("deactivate") : i18n.t("activate")}
						icon={row.isActive ? faBan : faUndo}
						onClick={async () => {
							const confirmed = await showConfirmDialog();
							if (!confirmed) return;
							await AccountingCodesApi.setAccountingCodeIsActive({
								accountingCodeId: row.accountingCodeId,
								isActive: !row.isActive,
							});
							await refreshData();
						}}
					/>
				),
			]}
			{...dataGridProps}
		/>
	);
};
