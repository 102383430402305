import React from "react";
import { RefreshableElementRef } from "src/utils/useRefreshRef.ts";

export interface ProjectActivityJobQueueViewContextValue {
	initialActivitiesRefreshRef: RefreshableElementRef;
	inProgressActivitiesRefreshRef: RefreshableElementRef;
	completedActivitiesRefreshRef: RefreshableElementRef;
}

export const ProjectActivityJobQueueViewContext = React.createContext<
	ProjectActivityJobQueueViewContextValue | undefined
>(undefined);
