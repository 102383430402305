// This file is automatically generated. Do not edit manually.

import { ProductFamilyVersionConfiguratorSelectionOptions } from "src/api/generated/erp/configurator/management/catalogPart/api/productFamilyVersionConfiguratorSelectionOptions";
import { CatalogPartRevisionConfiguratorSelectionsOnProductFamilyVersion } from "src/api/generated/erp/configurator/management/service/catalogPartRevisionConfiguratorSelectionsOnProductFamilyVersion";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace CatalogPartRevisionConfiguratorSelectionsApi {
	export async function getProductFamilyVersionConfiguratorSelectionOptions(args: {
		productFamilyVersionId: number;
	}): Promise<ProductFamilyVersionConfiguratorSelectionOptions> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/configurator/managing/catalogPartRevision/getProductFamilyVersionConfiguratorSelectionOptions",
			method: "POST",
			data: {
				productFamilyVersionId: args.productFamilyVersionId,
			},
		});
		return response as ProductFamilyVersionConfiguratorSelectionOptions;
	}

	export async function getRevisionConfiguratorSelections(args: {
		catalogPartRevisionId: number;
		productFamilyVersionId: number;
	}): Promise<CatalogPartRevisionConfiguratorSelectionsOnProductFamilyVersion> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/configurator/managing/catalogPartRevision/getRevisionConfiguratorSelections",
			method: "POST",
			data: {
				catalogPartRevisionId: args.catalogPartRevisionId,
				productFamilyVersionId: args.productFamilyVersionId,
			},
		});
		return response as CatalogPartRevisionConfiguratorSelectionsOnProductFamilyVersion;
	}

	export async function convertRevisionActiveConfiguratorSelectionsForProductFamilyVersion(args: {
		catalogPartRevisionId: number;
		toProductFamilyVersionId: number;
	}): Promise<CatalogPartRevisionConfiguratorSelectionsOnProductFamilyVersion> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/configurator/managing/catalogPartRevision/convertRevisionActiveConfiguratorSelectionsForProductFamilyVersion",
			method: "POST",
			data: {
				catalogPartRevisionId: args.catalogPartRevisionId,
				toProductFamilyVersionId: args.toProductFamilyVersionId,
			},
		});
		return response as CatalogPartRevisionConfiguratorSelectionsOnProductFamilyVersion;
	}

	export async function setSelectedComponentsAndProps(args: {
		catalogPartRevisionId: number;
		productFamilyVersionId: number;
		selections: CatalogPartRevisionConfiguratorSelectionsOnProductFamilyVersion;
	}): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/configurator/managing/catalogPartRevision/setSelectedComponentsAndProps",
			method: "POST",
			data: {
				catalogPartRevisionId: args.catalogPartRevisionId,
				productFamilyVersionId: args.productFamilyVersionId,
				selections: args.selections,
			},
		});
		return response as void;
	}
}
