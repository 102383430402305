// This file is automatically generated. Do not edit manually.

import { PartConfigurationBomLineView } from "src/api/generated/erp/db/types/tables/partConfigurationBomLineView";
import { PartConfigurationBomLine } from "src/api/generated/erp/db/types/tables/partConfigurationBomLine";
import { PartConfigurationOperationView } from "src/api/generated/erp/db/types/tables/partConfigurationOperationView";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace PartConfigurationBomApi {
	export async function getPartConfigurationBomLines(args: {
		partConfigurationId: number;
	}): Promise<Array<PartConfigurationBomLineView>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/configurator/configuration/bom/getPartConfigurationBomLines",
			method: "POST",
			data: {
				partConfigurationId: args.partConfigurationId,
			},
		});
		return response as Array<PartConfigurationBomLineView>;
	}

	export async function getFormInitData(args: {
		partConfigurationId: number;
	}): Promise<PartConfigurationBomApi_FormInitData> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/configurator/configuration/bom/getFormInitData",
			method: "POST",
			data: {
				partConfigurationId: args.partConfigurationId,
			},
		});
		return response as PartConfigurationBomApi_FormInitData;
	}

	export async function saveBomLine(args: { partConfigurationBomLine: PartConfigurationBomLine }): Promise<number> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/configurator/configuration/bom/saveBomLine",
			method: "POST",
			data: {
				partConfigurationBomLine: args.partConfigurationBomLine,
			},
		});
		return response as number;
	}

	export async function deleteBomLines(args: { partConfigurationBomLineIds: Array<number> }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/configurator/configuration/bom/deleteBomLines",
			method: "POST",
			data: {
				partConfigurationBomLineIds: args.partConfigurationBomLineIds,
			},
		});
		return response as void;
	}
}

export interface PartConfigurationBomApi_FormInitData {
	defaultReferenceNo: number;
	operationOptions: Array<PartConfigurationOperationView>;
}
