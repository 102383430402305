import { Checkbox, CheckboxProps, FormControlLabel, Tooltip, Typography } from "@mui/material";
import { mergeSx } from "src/utils/styles.ts";
import React from "react";
import { VerticalBox } from "src/components/common/box/VerticalBox.tsx";

export interface AavoCheckboxProps extends Omit<CheckboxProps, "value" | "defaultValue" | "onChange" | "disabled"> {
	disabled?: boolean | string;
	required?: boolean;
	label?: React.ReactNode;
	onChange?: (value: boolean, event: React.SyntheticEvent) => void;
	tooltip?: string;
	error?: string;
}

export const AavoCheckbox = ({
	disabled: disabledProp,
	required,
	label,
	onChange: onChangeProp,
	sx,
	tooltip: tooltipProp,
	error,
	...other
}: AavoCheckboxProps) => {
	const onChangeSwapped = (event: React.SyntheticEvent, checked: boolean) => {
		onChangeProp?.(checked, event);
	};

	const disabled = typeof disabledProp === "string" ? disabledProp.length > 0 : disabledProp;
	const tooltip = typeof disabledProp === "string" ? disabledProp : tooltipProp;

	const color = error ? "error.main" : undefined;

	const checkbox =
		label != null ?
			<VerticalBox sx={mergeSx(sx)}>
				<FormControlLabel
					control={
						<Checkbox
							sx={{
								color: color,
							}}
							{...other}
						/>
					}
					required={required}
					disabled={disabled}
					label={label}
					onChange={onChangeSwapped}
					sx={{
						margin: 0,
						color: color,
					}}
				/>
				{error && (
					<Typography
						variant={"caption"}
						color={"error.main"}
						sx={{
							marginLeft: 2,
						}}
					>
						{error}
					</Typography>
				)}
			</VerticalBox>
		:	<Checkbox
				onChange={onChangeSwapped}
				sx={mergeSx(
					{
						color: color,
					},
					sx,
				)}
				{...other}
			/>;

	return (
		<Tooltip title={tooltip} arrow placement={"bottom-start"}>
			{checkbox}
		</Tooltip>
	);
};
