import { getOrInitAppStateStorage, setAppStateStorage } from "./appState";
import { AllViewStates, ViewState } from "./types";

export const saveViewState = (viewId: string, state: ViewState): ViewState => {
	const appState = getOrInitAppStateStorage();
	setAppStateStorage({
		...appState,
		viewStates: {
			...appState.viewStates,
			[viewId]: state,
		},
	});
	return state;
};

export const extractSpecificViewState = (
	allViewStates: AllViewStates,
	viewId: string
): ViewState => {
	return allViewStates[viewId] ?? null;
};
