import { OAuthAuthenticationRequest } from "src/api/generated/common/oauth/oAuthAuthenticationRequest.ts";
import { MessageDialogContext } from "src/components/common/dialogs/messageDialog/MessageDialogProvider.tsx";
import i18n from "i18next";
import { useContextOrThrow } from "src/utils/useContextOrThrow.tsx";

export interface UseOAuthAuthenticationResult {
	checkAuthentication: () => Promise<AuthenticationStatus>;
}

export type AuthenticationStatus =
	"alreadyAuthenticated" |
	"requiresAuthentication"

export const useOAuthAuthentication = (
	getAuthenticationRequest: () => Promise<OAuthAuthenticationRequest | null>
): UseOAuthAuthenticationResult => {
	const openMessageDialog = useContextOrThrow(MessageDialogContext);
	const checkAuthentication = async (): Promise<AuthenticationStatus> => {
		const authenticationRequest = await getAuthenticationRequest();
		if (authenticationRequest === null) {
			return "alreadyAuthenticated";
		}
		openMessageDialog({
			title: i18n.t("authentication_required"),
			content: i18n.t("oauth_authentication_required_message")
		});
		setTimeout(() => {
			window.open(authenticationRequest.authorizationUrl, "_blank");
		}, 1000);
		return "requiresAuthentication";
	};
	return {
		checkAuthentication
	};
};