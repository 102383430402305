import { Divider, DividerProps } from "@mui/material";
import { mergeSx } from "src/utils/styles.ts";

export const AavoFormDivider = ({ sx, textAlign = "left", ...other }: DividerProps) => (
	<Divider
		sx={mergeSx(
			{
				gridColumn: "1 / -1",
				color: "primary.main",
			},
			sx,
		)}
		textAlign={textAlign}
		{...other}
	/>
);
