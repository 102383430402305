import { FieldValues } from "react-hook-form";
import { FormCommonProps } from "src/components/common/forms/types.ts";
import { AavoForm } from "src/components/common/forms/AavoForm.tsx";
import { ShowFormInputDialogParams } from "src/components/common/dialogs/formInput/useFormInput.tsx";

export interface FormInputContentProps<TFieldValues extends FieldValues>
	extends ShowFormInputDialogParams<TFieldValues>,
		FormCommonProps<TFieldValues> {}

export const FormInputContent = <TFieldValues extends FieldValues>({
	onCompleted,
	defaultValues,
	content: contentCreator,
	submitLabel,
	onSubmit,
	onFormEdited
}: FormInputContentProps<TFieldValues>) => {
	return (
		<AavoForm<TFieldValues, TFieldValues>
			submit={async (values) => {
				if (onSubmit) {
					await onSubmit(values);
				}
				return values;
			}}
			columns={1}
			onCompleted={onCompleted}
			onFormEdited={onFormEdited}
			defaultValues={defaultValues}
			submitLabel={submitLabel}
			fitContent
			render={(useFormReturn) => contentCreator(useFormReturn)}
		/>
	);
};
