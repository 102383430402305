// This file is automatically generated. Do not edit manually.

import { WarehouseLocationView } from "src/api/generated/erp/db/types/tables/warehouseLocationView";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace WarehouseLocationsApi {
	export async function getWarehouseLocationOptions(args: {
		siteId: number | null | undefined;
		partId: number | null | undefined;
		currentSelection: number | null | undefined;
		searchQuery: string;
	}): Promise<Array<WarehouseLocationView>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/warehouse/warehouseLocations/getWarehouseLocationOptions",
			method: "POST",
			data: {
				siteId: args.siteId,
				partId: args.partId,
				currentSelection: args.currentSelection,
				searchQuery: args.searchQuery,
			},
		});
		return response as Array<WarehouseLocationView>;
	}
}
