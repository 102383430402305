import { DeepPartial } from "react-hook-form";
import { TenantMailSendingApi } from "src/api/generated/common/mail/tenantMailSendingApi.ts";
import { useOAuthAuthentication } from "src/utils/oAuth.ts";
import { AavoForm } from "src/components/common/forms/AavoForm.tsx";
import i18n from "i18next";
import { FormCommonProps } from "src/components/common/forms/types.ts";
import { FormTextField } from "src/components/common/forms/fields/FormTextField.tsx";
import { emailRule, requireRule } from "src/components/common/forms/validation.ts";
import { FormRichTextEditor } from "src/components/common/forms/fields/FormRichTextEditor.tsx";

export interface SendEmailFormProps extends FormCommonProps<void> {
	defaultValues?: DeepPartial<SendEmailFormValues>;
}

export interface SendEmailFormValues {
	receiverEmail: string;
	subject: string;
	body: string;
}

export const SendEmailForm = ({ defaultValues, onCompleted, onFormEdited }: SendEmailFormProps) => {
	const { checkAuthentication } = useOAuthAuthentication(TenantMailSendingApi.ensureMailSendingAuthentication);

	const submitForm = async (values: SendEmailFormValues) => {
		const authResult = await checkAuthentication();
		if (authResult === "requiresAuthentication") {
			return "interrupted";
		}

		await TenantMailSendingApi.sendMailAsCurrentUser({
			receiver: values.receiverEmail,
			subject: values.subject,
			body: values.body,
		});
	};

	return (
		<AavoForm
			defaultValues={defaultValues}
			submit={submitForm}
			submitLabel={i18n.t("send")}
			fitContent={true}
			columns={1}
			onCompleted={onCompleted}
			onFormEdited={onFormEdited}
			render={({ control }) => (
				<>
					<FormTextField
						control={control}
						name={"receiverEmail"}
						rules={{
							...requireRule(),
							...emailRule(),
						}}
						tooltip={i18n.t("you_can_use_semicolon_to_separate_multiple_email_addresses")}
						label={i18n.t("receiver_mail")}
					/>
					<FormTextField
						control={control}
						name={"subject"}
						rules={requireRule()}
						label={i18n.t("title")}
					/>
					<FormRichTextEditor
						control={control}
						name={"body"}
						sx={{
							flex: 1,
							gridColumn: "1 / -1",
						}}
					/>
				</>
			)}
		/>
	);
};
