import { ProjectTreeViewApi_TreeViewData } from "src/api/generated/erp/project/api/projectTreeViewApi.ts";
import { ProjectView } from "src/api/generated/erp/db/types/tables/projectView.ts";
import { SubProjectView } from "src/api/generated/erp/db/types/tables/subProjectView.ts";
import { ProjectActivityView } from "src/api/generated/erp/db/types/tables/projectActivityView.ts";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { faProjectDiagram, faSitemap, faTasks } from "@fortawesome/pro-regular-svg-icons";
import { concatWithPipe } from "src/utils/strings.tsx";
import { ProjectState } from "src/api/generated/erp/db/types/enums/projectState.ts";
import { SubProjectState } from "src/api/generated/erp/db/types/enums/subProjectState.ts";
import { ActivityState } from "src/api/generated/erp/db/types/enums/activityState.ts";
import { Theme } from "@mui/material/styles";
import { AavoTreeViewBaseItem } from "src/components/common/tree/AavoTreeView.tsx";

export type ProjectTreeItemModel = AavoTreeViewBaseItem<
	ProjectTreeViewBaseProps &
		(ProjectTreeProjectModel | ProjectTreeSubProjectModel | ProjectTreeActivityModel)
>;

type ProjectTreeViewBaseProps = {
	id: string;
	label: string;
	icon: IconDefinition;
	stateColor: string;
};

export interface ProjectTreeProjectModel extends ProjectView {
	type: "project";
}

export interface ProjectTreeSubProjectModel extends SubProjectView {
	type: "subProject";
}

export interface ProjectTreeActivityModel extends ProjectActivityView {
	type: "activity";
}

export function mapProjectTreeData(
	data: ProjectTreeViewApi_TreeViewData,
	{ palette }: Theme,
): ProjectTreeItemModel[] {
	return data.projects.map((project) => {
		return mapProject(project);
	});

	function mapProject(project: ProjectView): ProjectTreeItemModel {
		const subProjects = data.subProjects
			.filter((subProject) => subProject.projectId === project.projectId)
			.map((subProject) => {
				return mapSubProject(subProject);
			});
		return {
			...project,
			id: `project-${project.projectId}`,
			label: concatWithPipe(project.projectId, project.projectDescription),
			icon: faProjectDiagram,
			stateColor: getStateColor(project.projectState),
			type: "project",
			children: subProjects,
		};
	}

	function mapSubProject(subProject: SubProjectView): ProjectTreeItemModel {
		const activities = data.activities
			.filter((activity) => activity.subProjectId === subProject.subProjectId)
			.map(mapActivity);
		return {
			...subProject,
			id: `sub-project-${subProject.subProjectId}`,
			label: concatWithPipe(subProject.subProjectId, subProject.subProjectDescription),
			icon: faSitemap,
			stateColor: getStateColor(subProject.subProjectState),
			type: "subProject",
			children: activities,
		};
	}

	function mapActivity(activity: ProjectActivityView): ProjectTreeItemModel {
		return {
			...activity,
			id: `activity-${activity.activityId}`,
			label: concatWithPipe(activity.activityId, activity.activityName),
			icon: faTasks,
			stateColor: getStateColor(activity.activityState),
			type: "activity",
			children: [],
		};
	}

	function getStateColor(state: ProjectState | SubProjectState | ActivityState) {
		switch (state) {
			case "INITIAL":
				return palette.state.initial;
			case "RELEASED":
				return palette.state.released;
			case "STARTED":
				return palette.state.inProgress;
			case "READY":
				return palette.state.completed;
			case "CANCELLED":
				return palette.state.cancelled;
		}
	}
}
