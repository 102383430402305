import i18n from "i18next";
import { SalesCommissionLineView } from "src/api/generated/erp/db/types/tables/salesCommissionLineView.ts";
import { faCheck } from "@fortawesome/pro-regular-svg-icons";
import { SalesCommissionLineActionApi } from "src/api/generated/erp/sales/salesCommissions/api/salesCommissionLineActionApi.ts";
import { AsyncButton } from "src/components/common/buttons/AsyncButton.tsx";
import { useConfirmDialog } from "src/components/common/dialogs/confirmDialog/useConfirmDialog.ts";
import {
	SalesCommissionUnapprovedLinesDataGridApi,
	SalesCommissionUnapprovedLinesDataGridApi_DataGridData,
} from "src/api/generated/erp/sales/salesCommissions/api/salesCommissionUnapprovedLinesDataGridApi.ts";
import { AavoDataGrid } from "src/components/common/dataGrid/AavoDataGrid.tsx";
import { ClientSideDataGridModel } from "src/components/common/dataGrid/gridModel/ClientSideDataGridModel.tsx";
import { MultiSelectField } from "src/components/common/inputFields/MultiSelectField.tsx";
import { getSalesCommissionLinesDataGridColumns } from "src/components/views/erp/sales/salesCommissions/salesCommissionUtils.ts";
import { OpenCustomerOrderButton } from "src/components/views/erp/utilComponents/OpenCustomerOrderButton.tsx";

export const SalesCommissionUnapprovedLinesDataGrid = () => {
	const showConfirmDialog = useConfirmDialog();

	return (
		<ClientSideDataGridModel<
			SalesCommissionUnapprovedLinesDataGridApi_DataGridData,
			SalesCommissionLineView,
			{ salespersonIds: Array<number> }
		>
			fetchData={(params) => SalesCommissionUnapprovedLinesDataGridApi.getDataGridData(params)}
			initialParams={{ salespersonIds: [] }}
			getRowId={(row) => row.salesCommissionLineId}
			getRows={(data) => data.commissionLines}
			gridId={"629EF7E9A7B2F514"}
			render={({
				dataGridProps,
				selectedRows,
				refreshData,
				currentParams,
				data: { salespersonOptions },
			}) => (
				<AavoDataGrid<SalesCommissionLineView>
					columns={[
						getSalesCommissionLinesDataGridColumns({
							showState: false,
							showApprovalColumns: false,
							showPaymentColumns: false,
						}),
					]}
					actionBarComponents={
						<>
							<MultiSelectField
								label={i18n.t("salespersons")}
								options={salespersonOptions}
								getOptionKey={(option) => option.salespersonId}
								getOptionLabel={(option) => option.name}
								value={currentParams.salespersonIds}
								onChange={(salespersonIds) => refreshData({ salespersonIds })}
							/>
							<AsyncButton
								icon={faCheck}
								label={i18n.t("approve_selected")}
								disabled={selectedRows.length === 0}
								variant={"outlined"}
								onClick={async () => {
									const confirmed = await showConfirmDialog({
										message: i18n.t("confirm_approve_selected_commission_lines"),
									});
									if (!confirmed) return;
									await SalesCommissionLineActionApi.approveSalesCommissionLines({
										salesCommissionLineIds: selectedRows.map(
											(row) => row.salesCommissionLineId,
										),
									});
									await refreshData();
								}}
							/>
						</>
					}
					rowContextMenuComponents={({ row }) => [
						<OpenCustomerOrderButton key={"openCustomerOrder"} customerOrderId={row.customerOrderId} />,
					]}
					{...dataGridProps}
				/>
			)}
		/>
	);
};
