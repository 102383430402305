import { ManualInventoryEventFormBase } from "src/components/views/erp/warehouse/inventory/manualEventViews/ManualInventoryEventFormBase.tsx";
import { FormCommonProps } from "src/components/common/forms/types.ts";
import { ManualInventoryEventFormApi } from "src/api/generated/erp/warehouse/inventory/inventoryEvent/api/manualInventoryEventFormApi.ts";

export interface ScrappingFormProps extends FormCommonProps<void> {}

export const ScrappingForm = ({ onCompleted }: ScrappingFormProps) => {
	return (
		<ManualInventoryEventFormBase
			onCompleted={onCompleted}
			submitForm={async (formValues) => {
				await ManualInventoryEventFormApi.makeInventoryEvents({
					events: [
						{
							eventType: "SCRAPPING",
							eventQuantity: -formValues.quantity,
							partConfigurationId: formValues.partConfigurationId,
							partId: formValues.part.partId,
							warehouseLocationId: formValues.warehouseLocationId,
						},
					],
				});
			}}
		/>
	);
};
