import i18n from "i18next";
import { floatColumn, integerColumn, textColumn } from "src/components/common/dataGrid/columns.tsx";
import { AavoDataGrid } from "src/components/common/dataGrid/AavoDataGrid.tsx";
import { faHandPointRight } from "@fortawesome/pro-regular-svg-icons";
import { AavoButton } from "src/components/common/buttons/AavoButton.tsx";
import { useGenericDialog } from "src/components/common/dialogs/useGenericDialog.ts";
import { useContext } from "react";
import { DeliverySourcePickingViewContext } from "src/components/views/erp/delivery/deliveryPackage/picking/DeliverySourcePickingViewContext.tsx";
import { ClientSideDataGridModel } from "src/components/common/dataGrid/gridModel/ClientSideDataGridModel.tsx";
import { ReservableProjectActivityMaterialsDataGridApi } from "src/api/generated/erp/project/delivery/api/reservableProjectActivityMaterialsDataGridApi.ts";
import { ProjectActivityMaterialView } from "src/api/generated/erp/db/types/tables/projectActivityMaterialView.ts";
import { ProjectActivityMaterialReservationForm } from "src/components/views/erp/project/delivery/picking/ProjectActivityMaterialReservationForm.tsx";

export interface ReservableProjectActivityMaterialsDataGridProps {
	projectId: number;
}

export const ReservableProjectActivityMaterialsDataGrid = ({
	projectId,
}: ReservableProjectActivityMaterialsDataGridProps) => {
	const { reservableLinesViewRefreshRef, pickableLinesViewRefreshRef } =
		useContext(DeliverySourcePickingViewContext) ?? {};

	const { openDialog } = useGenericDialog();

	return (
		<ClientSideDataGridModel
			fetchData={(params) =>
				ReservableProjectActivityMaterialsDataGridApi.getRows({
					projectId,
					...params,
				})
			}
			initialParams={{}}
			getRows={(response) => response}
			getRowId={(row) => row.projectActivityMaterialId}
			refreshRef={reservableLinesViewRefreshRef}
			gridId={"C7059BEFBDB74F29"}
			render={({ dataGridProps, refreshData, data: rows }) => (
				<AavoDataGrid<ProjectActivityMaterialView>
					columns={[
						textColumn({
							field: "configurationOrPartDescription",
							headerName: i18n.t("part"),
							width: 250,
						}),
						floatColumn({
							field: "reservableQuantity" as any,
							valueGetter: (_, row) => row.quantityInBaseUnit - row.reservedQuantityInBaseUnit,
							headerName: i18n.t("reservable_quantity"),
							width: 120,
						}),
						textColumn({
							field: "partUnit",
							headerName: i18n.t("unit"),
						}),
						textColumn({
							field: "subProjectDescription",
							headerName: i18n.t("sub_project"),
							width: 250,
						}),
						textColumn({
							field: "activityName",
							headerName: i18n.t("activity"),
							width: 250,
						}),
						integerColumn({
							field: "lineNumber",
							headerName: i18n.t("line_number"),
						}),
						textColumn({
							field: "mark",
							headerName: i18n.t("mark"),
						}),
					]}
					actionBarComponents={
						<>
							<AavoButton
								label={i18n.t("reserve")}
								icon={faHandPointRight}
								variant={"outlined"}
								onClick={() => {
									openDialog(({ closeDialog }) => ({
										title: i18n.t("reserve"),
										size: "md",
										content: (
											<ProjectActivityMaterialReservationForm
												activityMaterials={rows}
												onCompleted={async (result) => {
													await closeDialog();
													if (result.type === "success") {
														await refreshData();
													}
													await pickableLinesViewRefreshRef?.refresh();
												}}
											/>
										),
									}));
								}}
							/>
						</>
					}
					{...dataGridProps}
				/>
			)}
		/>
	);
};
