import { useController, UseControllerProps } from "react-hook-form";
import {
	AavoAddressAutofill,
	AavoAddressAutofillProps,
} from "src/components/common/mapbox/AavoAddressAutofill.tsx";

export interface FormAddressAutofillProps<TFieldValues extends object>
	extends Omit<AavoAddressAutofillProps, "defaultValue" | "onSubmitFreeText" | "error">,
		Pick<UseControllerProps<TFieldValues>, "control" | "name" | "rules"> {}

export const FormAddressAutofill = <TFieldValues extends object>({
	control,
	name,
	rules,
	disabled: disabledProp,
	onSelectLocation,
	...other
}: FormAddressAutofillProps<TFieldValues>) => {
	const { field, fieldState } = useController({
		name,
		control,
		rules,
	});

	return (
		<AavoAddressAutofill
			defaultValue={field.value}
			onSelectLocation={(value) => {
				const stringValue = onSelectLocation(value);
				field.onChange(stringValue);
				return stringValue;
			}}
			onSubmitFreeText={(value) => {
				field.onChange(value);
			}}
			error={fieldState.error?.message}
			disabled={field.disabled || disabledProp}
			{...other}
		/>
	);
};
