import { DataGridProProps } from "@mui/x-data-grid-pro";
import { GridValidRowModel } from "@mui/x-data-grid/models/gridRows";
import { GridColumnsState } from "@mui/x-data-grid/hooks/features/columns/gridColumnsInterfaces";
import { GridColDef } from "@mui/x-data-grid/models/colDef/gridColDef";
import { GridInitialStatePro } from "@mui/x-data-grid-pro/models/gridStatePro";
import { GridEventListener } from "@mui/x-data-grid/models/events";
import { useState } from "react";

interface UseDataGridColumnStateSavingProps<TRow extends GridValidRowModel>
	extends Pick<
		DataGridProProps<TRow>,
		"columns" | "autosizeOptions" | "onColumnWidthChange" | "onColumnOrderChange"
	> {
	initialState: NonNullable<DataGridProProps<TRow>["initialState"]>;
	disableColumnOrderPersist: boolean;
}

interface UseDataGridColumnStateSavingReturn<TRow extends GridValidRowModel>
	extends Required<
		Pick<DataGridProProps<TRow>, "columns" | "autosizeOptions" | "onColumnWidthChange" | "onColumnOrderChange">
	> {}

interface ColumnState {
	index?: number;
	width?: number;
	hasBeenResized?: boolean;
}

/**
 * MUI DataGrid doesn't correctly save width and order of columns.
 * Partially the problem arises from the non-memoized columns prop.
 * This functions keeps track of column width and order maps columns-prop.
 */
export function useDataGridColumnStateSaving<TRow extends GridValidRowModel>({
	initialState,
	columns,
	disableColumnOrderPersist,
	autosizeOptions: autosizeOptionsProp,
	onColumnOrderChange: onColumnOrderChangeProp,
	onColumnWidthChange: onColumnWidthChangeProp,
}: UseDataGridColumnStateSavingProps<TRow>): UseDataGridColumnStateSavingReturn<TRow> {
	const [columnStates, setColumnStates] = useState<Record<string, ColumnState>>(
		initializeColumnStates(columns, initialState),
	);

	const updateColumnState = (field: string, state: Partial<ColumnState>) => {
		const columnCurrentState = columnStates[field] ?? {};
		setColumnStates({
			...columnStates,
			[field]: {
				...columnCurrentState,
				...state,
			},
		});
	};

	const onColumnWidthChange: GridEventListener<"columnWidthChange"> = (params, event, details) => {
		const { colDef, width } = params;
		updateColumnState(colDef.field, { width, hasBeenResized: true });
		onColumnWidthChangeProp?.(params, event, details);
	};

	const onColumnOrderChange: GridEventListener<"columnOrderChange"> = (params, event, details) => {
		if (!disableColumnOrderPersist) {
			const { api } = details;
			const orderedFields = api.state.columns.orderedFields;
			const newColumnStates = setColumnIndices(columnStates, orderedFields);
			setColumnStates(newColumnStates);
		}
		onColumnOrderChangeProp?.(params, event, details);
	};

	const columnsWithPersistedWidth = columns.map((column) => {
		const state = columnStates[column.field];
		return {
			...column,
			width: state?.width ?? column.width,
		};
	});

	const sortedColumns =
		disableColumnOrderPersist ? columnsWithPersistedWidth : (
			columnsWithPersistedWidth.toSorted((a, b) => {
				const aIndex = columnStates[a.field]?.index ?? -1;
				const bIndex = columnStates[b.field]?.index ?? -1;
				return aIndex - bIndex;
			})
		);

	// autosize only columns without saved width
	const autosizeOptions = {
		...autosizeOptionsProp,
		columns: columns
			.map((c) => c.field)
			.filter((field) => {
				const state = columnStates[field];
				return state?.hasBeenResized !== true;
			}),
	};

	return {
		columns: sortedColumns,
		autosizeOptions: autosizeOptions,
		onColumnWidthChange,
		onColumnOrderChange,
	};
}

function initializeColumnStates<TRow extends GridValidRowModel>(
	columns: readonly GridColDef<TRow>[],
	initialState: GridInitialStatePro,
): Record<string, ColumnState> {
	const columnsStates = initialState.columns as GridColumnsState | undefined;
	if (columnsStates?.lookup == undefined) return {};
	const ret = columns.reduce(
		(acc, column, index) => {
			const state = columnsStates.lookup[column.field];
			const width = state?.width ?? column.width;
			const hasBeenResized = state?.hasBeenResized ?? false;
			acc[column.field] = {
				index,
				width,
				hasBeenResized,
			};
			return acc;
		},
		{} as Record<string, ColumnState>,
	);

	return setColumnIndices(ret, columnsStates.orderedFields);
}

function setColumnIndices(
	columnStates: Record<string, ColumnState>,
	orderedFields: string[],
): Record<string, ColumnState> {
	const newColumnStates = { ...columnStates };
	orderedFields.forEach((field, index) => {
		const columnCurrentState = newColumnStates[field] ?? {};
		newColumnStates[field] = { ...columnCurrentState, index };
	});
	return newColumnStates;
}
