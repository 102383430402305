import { FormCommonProps } from "src/components/common/forms/types";
import { PartCategoryApi } from "src/api/generated/erp/parts/categories/api/partCategoryApi";
import { PartCategory } from "src/api/generated/erp/db/types/tables/partCategory";
import { useTranslation } from "react-i18next";
import { AavoForm } from "src/components/common/forms/AavoForm.tsx";
import { FormTextField } from "src/components/common/forms/fields/FormTextField";
import { AsyncFetchRender } from "src/components/common/async/AsyncFetchRender.tsx";
import { DeepPartial } from "react-hook-form";
import { requireRule } from "src/components/common/forms/validation";

export interface PartCategoryFormProps extends FormCommonProps<number> {
	partCategoryId?: number;
	parentId?: number | null;
}

export const PartCategoryForm = ({ partCategoryId, parentId, ...other }: PartCategoryFormProps) => {
	const fetchDefaultValues = async () => {
		return partCategoryId !== undefined ?
				await PartCategoryApi.getSingle({ partCategoryId })
			:	{
					parentId: parentId,
				};
	};
	return (
		<AsyncFetchRender
			fetch={fetchDefaultValues}
			content={(defaultValues) => {
				return <PartCategoryFormContent defaultValues={defaultValues} {...other} />;
			}}
		/>
	);
};

export interface PartCategoryFormContentProps extends FormCommonProps<number> {
	defaultValues: DeepPartial<PartCategory>;
}

export const PartCategoryFormContent = ({
	defaultValues,
	onFormEdited,
	onCompleted,
}: PartCategoryFormContentProps) => {
	const { t } = useTranslation();

	return (
		<AavoForm
			defaultValues={defaultValues}
			submit={submitForm}
			columns={1}
			onCompleted={onCompleted}
			onFormEdited={onFormEdited}
			render={({ control }) => (
				<>
					<FormTextField
						control={control}
						name={"categoryName"}
						label={t("part_category")}
						rules={requireRule()}
						autoFocus
					/>
				</>
			)}
		/>
	);
};

const submitForm = async (partCategory: PartCategory): Promise<number> => {
	if (partCategory.partCategoryId == null) {
		return await PartCategoryApi.insert({ partCategory });
	} else {
		await PartCategoryApi.update({ partCategory });
		return partCategory.partCategoryId;
	}
};
