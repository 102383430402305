// This file is automatically generated. Do not edit manually.

import { SalesPartCategory } from "src/api/generated/erp/db/types/tables/salesPartCategory";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace SalesPartCategoryApi {
	export async function getAllSalesPartCategories(): Promise<Array<SalesPartCategory>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/salesPartCategory/getAllSalesPartCategories",
			method: "POST",
			data: {},
		});
		return response as Array<SalesPartCategory>;
	}
}
