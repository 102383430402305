import {  MobileTimePicker, MobileTimePickerProps } from "@mui/x-date-pickers-pro";
import { Dayjs } from "dayjs";
import deepMerge from "src/utils/deepMerge.ts";

export interface AavoTimePickerProps extends MobileTimePickerProps<Dayjs> {
	errorMessage?: string;
}

export const AavoTimePicker = ({ errorMessage, slotProps, ...other }: AavoTimePickerProps) => {
	const slotPropsWrapped: MobileTimePickerProps<Dayjs>["slotProps"] = deepMerge(
		{
			textField: {
				error: errorMessage !== undefined,
				helperText: errorMessage,
			},
		},
		slotProps ?? {},
	);
	return <MobileTimePicker slotProps={slotPropsWrapped} {...other} />;
};
