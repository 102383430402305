import { AppUser } from "../../../api/generated/postgres/db/types/role_management/tables/appUser.ts";
import { UserApi } from "../../../api/generated/users/api/userApi.ts";
import { AsyncSelectField, AsyncSelectFieldProps } from "../../common/inputFields/AsyncSelectField.tsx";

export interface AsyncUserSelectFieldProps
	extends Omit<AsyncSelectFieldProps<AppUser, number>, "fetchOptions" | "getOptionKey" | "getOptionLabel"> {
	fetchOptions?: AsyncSelectFieldProps<AppUser, number>["fetchOptions"];
}

export const AsyncUserSelectField = ({
	label,
	onChange,
	fetchOptions,
	...other
}: AsyncUserSelectFieldProps) => {
	return (
		<AsyncSelectField<AppUser, number>
			label={label}
			fetchOptions={
				fetchOptions ??
				(({ searchQuery, currentSelection }) =>
					UserApi.getUserSelectionOptions({
						searchQuery: searchQuery,
						currentSelectionUserId: currentSelection,
					}))
			}
			getOptionKey={(option) => option.id}
			getOptionLabel={(option) => option.name}
			onChange={onChange}
			{...other}
		/>
	);
};
