// This file is automatically generated. Do not edit manually.

import { CatalogPartRevisionConfiguratorSelectionsOnProductFamilyVersion } from "src/api/generated/erp/configurator/management/service/catalogPartRevisionConfiguratorSelectionsOnProductFamilyVersion";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace CatalogPartRevisionProductFamilyVersionApi {
	export async function upgradeRevisionProductFamilyVersion(args: {
		catalogPartRevisionId: number;
		newProductFamilyVersionId: number;
	}): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/configurator/managing/catalogPartRevisionConfigurator/upgradeRevisionProductFamilyVersion",
			method: "POST",
			data: {
				catalogPartRevisionId: args.catalogPartRevisionId,
				newProductFamilyVersionId: args.newProductFamilyVersionId,
			},
		});
		return response as void;
	}

	export async function connectRevisionsToProductFamilyVersion(args: {
		productFamilyVersionId: number;
		catalogPartRevisions: Array<CatalogPartRevisionProductFamilyVersionApi_ConnectRevisionToProductFamilyVersionRequest>;
	}): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/configurator/managing/catalogPartRevisionConfigurator/connectRevisionsToProductFamilyVersion",
			method: "POST",
			data: {
				productFamilyVersionId: args.productFamilyVersionId,
				catalogPartRevisions: args.catalogPartRevisions,
			},
		});
		return response as void;
	}
}

export interface CatalogPartRevisionProductFamilyVersionApi_ConnectRevisionToProductFamilyVersionRequest {
	catalogPartRevisionId: number;
	selections: CatalogPartRevisionConfiguratorSelectionsOnProductFamilyVersion | null | undefined;
}
