import { TextField, TextFieldProps, useTheme } from "@mui/material";
import { TextFieldVariants } from "@mui/material/TextField/TextField";
import React, { ChangeEvent, ForwardedRef, forwardRef, useRef } from "react";
import { mergeSx } from "src/utils/styles";
import { resolveValueOrProvider, ValueOrProvider } from "src/utils/valueOrProvider.ts";
import { MaybeTooltip } from "src/components/common/tooltips/MaybeTooltip.tsx";

export type AavoTextFieldProps<Variant extends TextFieldVariants = TextFieldVariants> = Omit<
	TextFieldProps<Variant>,
	"onSubmit" | "onChange" | "error" | "value" | "disabled"
> & {
	inputRef?: ForwardedRef<HTMLInputElement>;
	onChange?: (value: string, event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
	onSubmit?: (value: string, source: "enterKey" | "blur") => void;
	error?: string | boolean;
	value?: string;
	disabled?: ValueOrProvider<boolean | string>;
	tooltip?: ValueOrProvider<React.ReactNode>;
	submitOnBlur?: boolean;
};

export const AavoTextField = forwardRef(
	(
		{
			onSubmit,
			onKeyDown,
			onBlur,
			onChange,
			onFocus,
			sx,
			multiline,
			minRows = multiline ? 2 : undefined,
			error: errorProp,
			helperText: helperTextProp,
			type,
			inputRef,
			tooltip: tooltipProvider,
			disabled: disabledProvider,
			inputProps,
			InputLabelProps,
			submitOnBlur: submitOnBlurProp = true,
			...other
		}: AavoTextFieldProps,
		ref: ForwardedRef<HTMLDivElement> | undefined,
	) => {
		const theme = useTheme();
		const placeholderOpacity = 0.5;
		const placeholderColor = theme.palette.text.primary;

		const error = typeof errorProp === "string" ? errorProp != "" : errorProp;
		const helperText = helperTextProp ?? (typeof errorProp === "string" ? errorProp : undefined);

		const shouldSubmitOnBlurRef = useRef(false);

		const disabled = resolveValueOrProvider(disabledProvider);
		const isDisabled = typeof disabled === "string" ? disabled.length > 0 : disabled;
		const tooltip = typeof disabled === "string" ? disabled : resolveValueOrProvider(tooltipProvider);

		return (
			<MaybeTooltip title={tooltip} arrow placement={"bottom-start"}>
				<TextField
					ref={ref}
					type={type}
					disabled={isDisabled}
					onKeyDown={async (e) => {
						onKeyDown?.(e);
						if (e.key === "Enter") {
							// Multiline text field can be submitted with Ctrl+Enter
							if (multiline && !e.ctrlKey) return;

							const input = (e as any).target.value;
							onSubmit?.(input, "enterKey");
							shouldSubmitOnBlurRef.current = false;
						}
					}}
					onBlur={async (e) => {
						onBlur?.(e);
						if (submitOnBlurProp && shouldSubmitOnBlurRef.current) {
							onSubmit?.(e.target.value, "blur");
							shouldSubmitOnBlurRef.current = false;
						}
					}}
					onChange={(e) => {
						onChange?.(e.target.value, e);
						shouldSubmitOnBlurRef.current = true;
					}}
					onFocus={(e) => {
						onFocus?.(e);
						// Move cursor to the end of the input field
						if (type !== "number" && e.currentTarget.value.length > 0)
							e.currentTarget.setSelectionRange(
								e.currentTarget.value.length,
								e.currentTarget.value.length,
							);
					}}
					error={error}
					helperText={helperText}
					multiline={multiline}
					minRows={minRows}
					inputProps={{
						ref: inputRef,
						...inputProps,
					}}
					InputLabelProps={{
						shrink: disabled ? true : undefined,
						...InputLabelProps,
					}}
					sx={mergeSx(
						{
							input: {
								color: placeholderColor,
								"&::placeholder": {
									opacity: placeholderOpacity,
								},
							},
							label: {
								color: placeholderColor,
								opacity: placeholderOpacity,
							},
						},
						sx,
					)}
					{...other}
				/>
			</MaybeTooltip>
		);
	},
);
