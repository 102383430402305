import {
	ConfiguratorLibraryVersionsApi,
	ConfiguratorLibraryVersionsApi_FormInitData,
} from "src/api/generated/erp/configurator/management/libraries/api/configuratorLibraryVersionsApi.ts";
import {ConfiguratorLibraryVersion} from "src/api/generated/erp/db/types/tables/configuratorLibraryVersion.ts";
import {requireRule} from "src/components/common/forms/validation.ts";
import {FormNumberField} from "src/components/common/forms/fields/FormNumberField.tsx";
import {AsyncForm} from "src/components/common/forms/AsyncForm.tsx";
import i18n from "i18next";
import {FormCommonProps} from "src/components/common/forms/types.ts";
import {DeepPartial} from "react-hook-form";
import {FormTextField} from "src/components/common/forms/fields/FormTextField.tsx";

export interface ConfiguratorLibraryVersionFormProps extends FormCommonProps<number> {
	configuratorLibraryId: number;
	configuratorLibraryVersionId: number | undefined;
}

interface FormValues extends ConfiguratorLibraryVersion {}

export const ConfiguratorLibraryVersionForm = (props: ConfiguratorLibraryVersionFormProps) => {
	const { onCompleted, onFormEdited, configuratorLibraryId, configuratorLibraryVersionId } = props;

	const isExistingVersion = configuratorLibraryVersionId != null;

	return (
		<AsyncForm
			fetch={() =>
				ConfiguratorLibraryVersionsApi.getFormInitData({
					libraryId: configuratorLibraryId,
					libraryVersionId: configuratorLibraryVersionId,
				})
			}
			getDefaultValues={getDefaultValues}
			submit={submit}
			onCompleted={onCompleted}
			onFormEdited={onFormEdited}
			render={({ control }) => (
				<>
					<FormNumberField
						control={control}
						name={"versionNumber"}
						label={i18n.t("version")}
						type={"integer"}
						rules={requireRule()}
						disabled={isExistingVersion}
					/>
					<FormTextField control={control} name={"note"} label={i18n.t("note")} multiline />
				</>
			)}
		/>
	);

	function getDefaultValues({
		configuratorLibraryVersion,
		defaultVersionNumber,
	}: ConfiguratorLibraryVersionsApi_FormInitData): DeepPartial<FormValues> {
		if (configuratorLibraryVersion != null) return configuratorLibraryVersion;
		else
			return {
				configuratorLibraryId: configuratorLibraryId,
				versionNumber: defaultVersionNumber,
			};
	}

	async function submit(values: FormValues) {
		if (configuratorLibraryVersionId == null)
			return await ConfiguratorLibraryVersionsApi.insert({
				configuratorLibraryId,
				versionNumber: values.versionNumber,
				note: values.note,
			});
		else {
			await ConfiguratorLibraryVersionsApi.update({
				configuratorLibraryVersionId: configuratorLibraryVersionId,
				note: values.note,
			});
			return configuratorLibraryVersionId;
		}
	}
};
