import { useState } from "react";
import {
	CatalogPartRevisionConfiguratorSelectionsViewContext,
	TabComponentWithChildren,
} from "src/components/views/erp/configurator/managing/catalogPart/configuratorSelections/catalogPartRevisionConfiguratorSelectionsViewContext.ts";
import { CatalogPartRevisionConfiguratorSelectionsStatelessView } from "src/components/views/erp/configurator/managing/catalogPart/configuratorSelections/CatalogPartRevisionConfiguratorSelectionsStatelessView.tsx";
import { CatalogPartRevisionConfiguratorSelectionsApi } from "src/api/generated/erp/configurator/management/catalogPart/api/catalogPartRevisionConfiguratorSelectionsApi.ts";
import { DataDirtyStateChangeHandler } from "src/utils/dataDirtyStateChangeHandler.ts";
import { CatalogPartRevisionConfiguratorSelectionsOnProductFamilyVersion } from "src/api/generated/erp/configurator/management/service/catalogPartRevisionConfiguratorSelectionsOnProductFamilyVersion.ts";
import { ProductFamilyVersionConfiguratorSelectionOptions } from "src/api/generated/erp/configurator/management/catalogPart/api/productFamilyVersionConfiguratorSelectionOptions.ts";

export interface CatalogPartRevisionConfiguratorSelectionsViewProps {
	catalogPartRevisionId: number;
	productFamilyVersionId: number;
	onSubmitted?: () => Promise<unknown>;
	onCancelled?: () => Promise<unknown>;
	onDataDirtyStateChanged: DataDirtyStateChangeHandler;
	productFamilySelectionOptions: ProductFamilyVersionConfiguratorSelectionOptions;
	currentSelections: CatalogPartRevisionConfiguratorSelectionsOnProductFamilyVersion;
	onSelectionsChanged?: (
		newSelections: CatalogPartRevisionConfiguratorSelectionsOnProductFamilyVersion,
	) => void;
	disableSubmit?: boolean;
	disableCancel?: boolean;
}

export const CatalogPartRevisionConfiguratorSelectionsView = ({
	catalogPartRevisionId,
	onSubmitted,
	onCancelled,
	onDataDirtyStateChanged,
	productFamilyVersionId,
	productFamilySelectionOptions: { components, computedProperties },
	currentSelections: { selectedComponentIds, selectedComputedPropertyIds },
	onSelectionsChanged,
	disableSubmit = false,
	disableCancel = false,
}: CatalogPartRevisionConfiguratorSelectionsViewProps) => {
	const [tabComponents, setTabComponents] = useState<TabComponentWithChildren[]>(createTabComponents());
	const [selectedPropertyIds, setSelectedPropertyIds] = useState<number[]>(selectedComputedPropertyIds);

	return (
		<CatalogPartRevisionConfiguratorSelectionsViewContext.Provider
			value={{
				catalogPartRevisionId,
				tabComponents,
				setComponentSelectionsOnTab: setSelectionsOnTab,
				submitAndClose: disableSubmit ? undefined : submitAndClose,
				cancelAndClose: disableCancel ? undefined : cancelAndClose,
				allComputedProperties: computedProperties,
				selectedPropertyIds,
				setSelectedPropertyIds: setSelectedPropertyIdsWrapped,
			}}
		>
			<CatalogPartRevisionConfiguratorSelectionsStatelessView />
		</CatalogPartRevisionConfiguratorSelectionsViewContext.Provider>
	);

	function createTabComponents(): TabComponentWithChildren[] {
		return components
			.filter((c) => c.componentType === "TAB")
			.map((tab) => {
				const childComponents = components.filter(
					(c) => c.parentTabComponentId === tab.configurationComponentId,
				);

				return {
					tabComponent: tab,
					childComponents: childComponents,
					selectedChildComponentsIds: childComponents
						.filter((c) => selectedComponentIds.includes(c.configurationComponentId))
						.map((c) => c.configurationComponentId),
				};
			});
	}

	function setSelectedPropertyIdsWrapped(newSelectedPropertyIds: number[]) {
		onDataDirtyStateChanged({ isDirty: true });
		setSelectedPropertyIds(newSelectedPropertyIds);
		onSelectionsChanged?.({
			selectedComponentIds: getComponentIdsToConnect(),
			selectedComputedPropertyIds: newSelectedPropertyIds,
		});
	}

	function setSelectionsOnTab(tabComponentId: number, selectedComponentIds: number[]) {
		onDataDirtyStateChanged({ isDirty: true });
		const newTabComponents = tabComponents.map((tab) =>
			tab.tabComponent.configurationComponentId === tabComponentId ?
				{ ...tab, selectedChildComponentsIds: selectedComponentIds }
			:	tab,
		);
		setTabComponents(newTabComponents);
		onSelectionsChanged?.({
			selectedComponentIds: getComponentIdsToConnect(newTabComponents),
			selectedComputedPropertyIds: selectedPropertyIds,
		});
	}

	async function submitAndClose() {
		const componentIdsToConnect = getComponentIdsToConnect();
		await CatalogPartRevisionConfiguratorSelectionsApi.setSelectedComponentsAndProps({
			catalogPartRevisionId,
			productFamilyVersionId,
			selections: {
				selectedComponentIds: componentIdsToConnect,
				selectedComputedPropertyIds: selectedPropertyIds,
			},
		});
		await onSubmitted?.();
	}

	function getComponentIdsToConnect(
		currentTabComponents: TabComponentWithChildren[] = tabComponents,
	): number[] {
		return currentTabComponents.flatMap(({ tabComponent, selectedChildComponentsIds }) => {
			if (selectedChildComponentsIds.length === 0) return [];
			return [...selectedChildComponentsIds, tabComponent.configurationComponentId];
		});
	}

	async function cancelAndClose() {
		await onCancelled?.();
	}
};
