// This file is automatically generated. Do not edit manually.

import { SalesCommissionLine } from "src/api/generated/erp/db/types/tables/salesCommissionLine";
import { SalesCommissionLineView } from "src/api/generated/erp/db/types/tables/salesCommissionLineView";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace SalesCommissionLineEditApi {
	export async function getFormInitData(args: {
		salesCommissionLineId: number | null | undefined;
	}): Promise<SalesCommissionLineEditApi_FormInitData> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/commissions/line/getFormInitData",
			method: "POST",
			data: {
				salesCommissionLineId: args.salesCommissionLineId,
			},
		});
		return response as SalesCommissionLineEditApi_FormInitData;
	}

	export async function insert(args: { line: SalesCommissionLine }): Promise<number> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/commissions/line/insert",
			method: "POST",
			data: {
				line: args.line,
			},
		});
		return response as number;
	}

	export async function update(args: { line: SalesCommissionLine }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/commissions/line/update",
			method: "POST",
			data: {
				line: args.line,
			},
		});
		return response as void;
	}

	export async function delete_(args: { salesCommissionLineIds: Array<number> }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/commissions/line/delete",
			method: "POST",
			data: {
				salesCommissionLineIds: args.salesCommissionLineIds,
			},
		});
		return response as void;
	}
}

export interface SalesCommissionLineEditApi_FormInitData {
	nameOptions: Array<string>;
	salesCommissionLine: SalesCommissionLineView | null | undefined;
}
