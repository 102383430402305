import { DocumentSearchDataGridBase } from "src/components/views/documents/search/DocumentSearchDataGridBase.tsx";
import { AavoObjectRef } from "src/api/generated/common/sourceType/aavoObjectRef.ts";
import {
	DocumentActiveRevisionView
} from "src/api/generated/postgres/db/types/documents/tables/documentActiveRevisionView.ts";
import { AsyncButton } from "src/components/common/buttons/AsyncButton.tsx";
import i18n from "i18next";
import { DocumentConnectionApi } from "src/api/generated/documents/api/documentConnectionApi.ts";
import { useState } from "react";
import { faLink } from "@fortawesome/pro-regular-svg-icons";

export interface ConnectDocumentsToObjectDataGridProps {
	objectRef: AavoObjectRef,
	onSelectedDocumentsChanged: (documents: DocumentActiveRevisionView[]) => void;
	onDocumentsConnected: () => void
}

export const ConnectDocumentsToObjectDataGrid = (
	{
		objectRef,
		onSelectedDocumentsChanged,
		onDocumentsConnected
	}: ConnectDocumentsToObjectDataGridProps) => {

	const [selectedRows, setSelectedRows] = useState<DocumentActiveRevisionView[]>([]);

	return <DocumentSearchDataGridBase
		filterDocumentsConnectedToObject={objectRef}
		onRowSelectionChanged={(rows) => {
			onSelectedDocumentsChanged?.(rows);
			setSelectedRows(rows);
		}}
		actionBarComponents={
			<AsyncButton
				icon={faLink}
				label={i18n.t("connect_selected")}
				variant={"contained"}
				disabled={selectedRows.length === 0 && i18n.t("select_documents_to_connect")}
				onClick={async () => {
					await DocumentConnectionApi.connectDocumentsToObject({
						objectRef: objectRef,
						documentIds: selectedRows.map(d => d.documentId)
					});
					onDocumentsConnected();
				}}
			/>
		}
	/>;
};