import {
	DatePicker,
	PickersActionBar,
	DatePickerProps,
	PickerChangeHandlerContext,
} from "@mui/x-date-pickers-pro";
import { Dayjs } from "dayjs";
import { BaseTextFieldProps } from "@mui/material/TextField/TextField";
import { ConditionalNullable } from "src/components/common/inputFields/types.ts";
import { PickersActionBarAction } from "@mui/x-date-pickers/PickersActionBar/PickersActionBar";
import deepMerge from "src/utils/deepMerge.ts";

export interface AavoDatePickerProps<DisableClearable extends boolean | undefined>
	extends Omit<DatePickerProps<Dayjs>, "onChange">,
		Pick<BaseTextFieldProps, "size"> {
	onChange?: (date: ConditionalNullable<Dayjs, DisableClearable>) => void;
	errorMessage?: string;
	disableClearable?: DisableClearable;
}

export const AavoDatePicker = <DisableClearable extends boolean | undefined>({
	slots,
	slotProps,
	size,
	onChange,
	errorMessage,
	disableClearable,
	displayWeekNumber = true,
	...other
}: AavoDatePickerProps<DisableClearable>) => {
	const slotPropsWrapped: DatePickerProps<Dayjs>["slotProps"] = deepMerge(
		{
			textField: {
				size: size,
				error: errorMessage !== undefined,
				helperText: errorMessage,
				InputProps: {
					sx: {
						minHeight: 0,
					},
				},
			},
			mobilePaper: {
				sx: {
					"& .MuiPickersLayout-root": {
						display: "flex",
						flexDirection: "column",
					},
					"& .MuiPickersLayout-contentWrapper": {
						flex: 1,
					},
				},
			},
		},
		slotProps ?? {},
	);

	const onChangeWrapped = (value: Dayjs | null, context: PickerChangeHandlerContext<any>) => {
		if (disableClearable === true && value === null) return;

		const actualValue = context.validationError == null ? value : null;
		onChange?.(actualValue as ConditionalNullable<Dayjs, DisableClearable>);
	};

	const actions: PickersActionBarAction[] =
		disableClearable === true ? ["today", "cancel", "accept"] : ["today", "clear", "cancel", "accept"];

	return (
		<DatePicker
			slotProps={slotPropsWrapped}
			onChange={onChangeWrapped}
			displayWeekNumber={displayWeekNumber}
			slots={{
				...slots,
				actionBar: ({ ...actionBarProps }) => {
					return <PickersActionBar {...actionBarProps} actions={actions} />;
				},
			}}
			{...other}
		/>
	);
};
