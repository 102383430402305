import { useServerSideDataGridModel } from "src/components/common/dataGrid/gridModel/useServerSideDataGridModel.tsx";
import { genericNullableValue } from "src/utils/genericNullableValue.ts";
import { SalesPriceListLineView } from "src/api/generated/erp/db/types/tables/salesPriceListLineView.ts";
import { SalesPriceListLinesDataGridApi } from "src/api/generated/erp/sales/pricing/api/salesPriceListLinesDataGridApi.ts";
import { ControlledAsyncCrudDataGrid } from "src/components/common/dataGrid/crud/ControlledAsyncCrudDataGrid.tsx";
import { dateColumn, floatColumn, textColumn } from "src/components/common/dataGrid/columns.tsx";
import i18n from "i18next";
import { calculateSalesMarginPercent } from "src/components/views/erp/sales/salesUtils.ts";
import { SalesPriceListLineEditApi } from "src/api/generated/erp/sales/pricing/api/salesPriceListLineEditApi.ts";
import { AsyncButton } from "src/components/common/buttons/AsyncButton.tsx";
import { faArrowsAltV, faLink, faPercent, faTrash, faTrashAlt } from "@fortawesome/pro-regular-svg-icons";
import { LazySelectField } from "src/components/common/inputFields/LazySelectField.tsx";
import { SitesApi } from "src/api/generated/erp/common/sites/sitesApi.ts";
import { AavoTextField } from "src/components/common/inputFields/AavoTextField.tsx";
import { MenuCheckbox } from "src/components/common/contextMenu/MenuCheckbox.tsx";
import { ConnectSalesPartsToPriceListView } from "src/components/views/erp/sales/pricing/salesPriceLists/ConnectSalesPartsToPriceListView.tsx";
import { useGenericDialog } from "src/components/common/dialogs/useGenericDialog.ts";
import { SiteView } from "src/api/generated/erp/db/types/tables/siteView.ts";
import { VerticalBox } from "src/components/common/box/VerticalBox.tsx";
import Typography from "@mui/material/Typography";
import { showAsyncDialog } from "src/components/common/dialogs/asyncDialog.ts";
import _ from "underscore";
import { AsyncMenuButton } from "src/components/common/contextMenu/AsyncMenuButton.tsx";
import { useFormInput } from "src/components/common/dialogs/formInput/useFormInput.tsx";
import { FormDateField } from "src/components/common/forms/fields/FormDateField.tsx";
import { floatRule, requireRule } from "src/components/common/forms/validation.ts";
import { FormNumberField } from "src/components/common/forms/fields/FormNumberField.tsx";
import { currentLocalDateAsIsoString } from "src/utils/dayjsUtils.ts";
import { IsoDateString } from "src/types/dateTime.ts";

export interface SalesPriceListLinesDataGridProps {
	salesPriceListId: number;
}

export const SalesPriceListLinesDataGrid = ({ salesPriceListId }: SalesPriceListLinesDataGridProps) => {
	const { openDialog } = useGenericDialog();
	const showFormInput = useFormInput();

	const { dataGridProps, refreshData, currentParams } = useServerSideDataGridModel({
		fetchData: (params) =>
			SalesPriceListLinesDataGridApi.getDataGridData({
				salesPriceListId: salesPriceListId,
				...params,
			}),
		initialParams: {
			siteIdFilter: genericNullableValue<number>(),
			onlyActiveLines: true,
			searchQuery: "",
		},
		getRowId: (row) => row.salesPriceListLineId,
		gridId: "1A6E850DF2A8DC2B",
	});

	return (
		<ControlledAsyncCrudDataGrid<SalesPriceListLineView>
			columns={[
				textColumn({
					field: "siteName",
					headerName: i18n.t("site"),
				}),
				textColumn({
					field: "salesPartNo",
					headerName: i18n.t("sales_part_no"),
					width: 130,
				}),
				textColumn({
					field: "partDescription_1",
					headerName: i18n.t("description_1"),
					width: 150,
				}),
				textColumn({
					field: "partDescription_2",
					headerName: i18n.t("description_2"),
					width: 150,
				}),
				dateColumn({
					field: "activeFrom",
					headerName: i18n.t("active_from"),
					width: 150,
					editable: true,
					validate: "required",
				}),
				floatColumn({
					field: "price",
					headerName: i18n.t("price"),
					width: 80,
					editable: true,
					validate: "required",
				}),
				floatColumn({
					field: "standardCostAsPriceUnits",
					headerName: i18n.t("cost"),
				}),
				floatColumn({
					field: "profit" as any,
					headerName: i18n.t("gross_margin") + " (%)",
					filterable: false,
					sortable: false,
					valueGetter: (_, row) =>
						calculateSalesMarginPercent({
							cost: row.standardCostAsPriceUnits ?? 0,
							salesPrice: row.price,
						}),
				}),
			]}
			save={async ({ items }) => {
				await SalesPriceListLineEditApi.update({
					salesPriceListLines: items,
				});
				return items;
			}}
			remove={{
				type: "enabled",
				action: ({ items }) => onDelete(items),
				confirm: false,
			}}
			actionBarComponents={
				<>
					<LazySelectField
						label={i18n.t("site")}
						fetchOptions={() => SitesApi.getAll()}
						getOptionKey={(option: SiteView) => option.siteId}
						getOptionLabel={(option) => option.siteName}
						onChange={(value) => refreshData({ siteIdFilter: value })}
					/>
					<AavoTextField
						label={i18n.t("search")}
						onSubmit={(value) => refreshData({ searchQuery: value })}
					/>
					<AsyncButton
						label={i18n.t("connect_parts")}
						icon={faLink}
						variant={"outlined"}
						onClick={() => {
							openDialog(({ closeDialog }) => ({
								title: i18n.t("connect_parts"),
								size: "xl",
								content: (
									<ConnectSalesPartsToPriceListView
										salesPriceListId={salesPriceListId}
										onCompleted={async () => {
											await closeDialog();
											await refreshData();
										}}
									/>
								),
							}));
						}}
					/>
				</>
			}
			actionBarMenuComponents={[
				<MenuCheckbox
					key={"onlyActiveLines"}
					label={i18n.t("only_active")}
					checked={currentParams.onlyActiveLines}
					onChange={(value) => refreshData({ onlyActiveLines: value })}
				/>,
				<AsyncMenuButton
					key={"setPricesForAllLinesByMargin"}
					label={i18n.t("set_price_for_all_lines_based_on_the_margin")}
					icon={faPercent}
					onClick={setPricesForAllLinesByMargin}
				/>,
				<AsyncMenuButton
					key={"changePriceForAllLinesByPercentage"}
					label={i18n.t("change_the_price_of_all_lines_by_a_percentage")}
					icon={faArrowsAltV}
					onClick={changePriceForAllLinesByPercentage}
				/>,
			]}
			{...dataGridProps}
		/>
	);

	async function onDelete(rows: SalesPriceListLineView[]) {
		const result = await showAsyncDialog(openDialog, ({ onCompleted }) => ({
			title: i18n.t("delete_lines"),
			size: "sm",
			content: (
				<DeleteRowsDialog
					salesPriceListId={salesPriceListId}
					rowsToDelete={rows}
					onCompleted={async () => {
						await onCompleted({ type: "success", value: "deleted" });
					}}
				/>
			),
		}));
		if (result === undefined) return "interrupt";
	}

	async function setPricesForAllLinesByMargin() {
		const input = await showFormInput<{ activeFrom: IsoDateString; margin: number }>({
			title: i18n.t("set_price_for_all_lines_based_on_the_margin"),
			defaultValues: {
				activeFrom: currentLocalDateAsIsoString(),
				margin: undefined,
			},
			content: ({ control }) => (
				<>
					<FormDateField
						control={control}
						name={"activeFrom"}
						label={i18n.t("active_from")}
						rules={requireRule()}
					/>
					<FormNumberField
						control={control}
						name={"margin"}
						label={i18n.t("margin.sales") + " (%)"}
						rules={{
							...requireRule(),
							...floatRule((value) => {
								if (value < -100 || value > 100) {
									return i18n.t("invalid_value");
								}
							}),
						}}
					/>
				</>
			),
		});
		if (input === undefined) return;

		await SalesPriceListLineEditApi.setMarginForAllPartsInPriceList({
			salesPriceListId,
			activeFrom: input.activeFrom,
			marginPercent: input.margin,
		});
		await refreshData();
	}

	async function changePriceForAllLinesByPercentage() {
		const input = await showFormInput<{ activeFrom: IsoDateString; percentage: number }>({
			title: i18n.t("change_the_price_of_all_lines_by_a_percentage"),
			defaultValues: {
				activeFrom: currentLocalDateAsIsoString(),
				percentage: undefined,
			},
			content: ({ control }) => (
				<>
					<FormDateField
						control={control}
						name={"activeFrom"}
						label={i18n.t("active_from")}
						rules={requireRule()}
					/>
					<FormNumberField
						control={control}
						name={"percentage"}
						label={i18n.t("change.noun") + " (%)"}
						rules={{
							...requireRule(),
							...floatRule((value) => {
								return value >= -100;
							}),
						}}
					/>
				</>
			),
		});
		if (input === undefined) return;

		await SalesPriceListLineEditApi.changePriceByFactorForAllPartsInPriceList({
			salesPriceListId,
			activeFrom: input.activeFrom,
			factor: 1 + input.percentage / 100,
		});
		await refreshData();
	}
};

interface DeleteRowsDialogProps {
	salesPriceListId: number;
	rowsToDelete: SalesPriceListLineView[];
	onCompleted: () => Promise<unknown>;
}

const DeleteRowsDialog = ({ rowsToDelete, onCompleted, salesPriceListId }: DeleteRowsDialogProps) => {
	return (
		<VerticalBox gap={1.5} margin={1.5}>
			<Typography>{i18n.t("what_would_you_like_to_do")}</Typography>
			<AsyncButton
				label={i18n.t("only_delete_selected_price_list_lines")}
				variant={"outlined"}
				icon={faTrash}
				onClick={async () => {
					await SalesPriceListLineEditApi.deletePriceLists({
						salesPriceListLineIds: rowsToDelete.map((row) => row.salesPriceListLineId),
					});
					await onCompleted();
				}}
			/>
			<AsyncButton
				label={i18n.t("completely_remove_parts_from_price_list")}
				variant={"outlined"}
				icon={faTrashAlt}
				onClick={async () => {
					await SalesPriceListLineEditApi.completelyRemoveSalesPartsFromPriceList({
						salesPriceListId,
						salesPartIds: _.unique(rowsToDelete.map((row) => row.salesPartId)),
					});
					await onCompleted();
				}}
			/>
		</VerticalBox>
	);
};
