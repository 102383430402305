// This file is automatically generated. Do not edit manually.

import { Operation } from "src/api/generated/erp/db/types/tables/operation";
import { ProductionLine } from "src/api/generated/erp/db/types/tables/productionLine";
import { ProductionWorkQueueView } from "src/api/generated/erp/db/types/tables/productionWorkQueueView";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace WorkCenterJobQueueInitialsApi {
	export async function getInitialOperations(args: {
		operationId: number | null | undefined;
		productionLineId: number | null | undefined;
	}): Promise<WorkCenterJobQueueInitialsApi_InitialJobsData> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/workCenterJobQueue/getInitialOperations",
			method: "POST",
			data: {
				operationId: args.operationId,
				productionLineId: args.productionLineId,
			},
		});
		return response as WorkCenterJobQueueInitialsApi_InitialJobsData;
	}
}

export interface WorkCenterJobQueueInitialsApi_InitialJobsData {
	operationOptions: Array<Operation>;
	productionLineOptions: Array<ProductionLine>;
	rows: Array<ProductionWorkQueueView>;
}
