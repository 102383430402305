// This file is automatically generated. Do not edit manually.

import { t } from "i18next";

export const SalesPartTypeValues = ["WAREHOUSE", "PACKAGE_", "SERVICE"] as const;

export type SalesPartType = (typeof SalesPartTypeValues)[number];

export const getSalesPartTypeLabels = () => ({
	WAREHOUSE: t("warehouse"),
	PACKAGE_: t("package_"),
	SERVICE: t("service"),
});

export const getSalesPartTypeLabel = (value: SalesPartType): string => {
	return getSalesPartTypeLabels()[value];
};
