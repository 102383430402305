import { VerticalBox } from "../../../common/box/VerticalBox.tsx";
import { Box, Typography, useTheme } from "@mui/material";
import i18n from "i18next";
import React, { Fragment, useState } from "react";
import { dayJsToDateIsoStringNullable, formatIsoString } from "src/utils/dayjsUtils.ts";
import { AavoDatePicker, AavoDatePickerProps } from "src/components/common/inputFields/AavoDatePicker.tsx";
import dayjs from "dayjs";
import { AavoTextField, AavoTextFieldProps } from "src/components/common/inputFields/AavoTextField.tsx";
import { ConditionalNullable } from "src/components/common/inputFields/types.ts";
import { getTaskStateLabel } from "src/api/generated/io/aavo/applications/db/postgres/enums/taskState.ts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/pro-solid-svg-icons";
import { AsyncSelectField } from "src/components/common/inputFields/AsyncSelectField.tsx";
import { UserApi } from "src/api/generated/users/api/userApi.ts";
import { AppUser } from "src/api/generated/postgres/db/types/role_management/tables/appUser.ts";
import { useDebounce } from "src/utils/useDebounce.ts";
import { TaskWithUserNames } from "../types.ts";

export interface TaskMobileFormProps {
	task: TaskWithUserNames;
	onTaskChanged: (newTask: TaskWithUserNames) => void;
	markTaskDirty: () => void;
}

export const TaskMobileForm = (props: TaskMobileFormProps) => {
	const { task } = props;
	const [fieldUnderEdit, setFieldUnderEdit] = useState<string | null>(null);

	const fieldCommonProps = {
		fieldUnderEdit,
		setFieldUnderEdit,
		...props,
	};

	return (
		<VerticalBox
			sx={{
				flex: 1,
				overflow: "auto",
			}}
		>
			<Box
				sx={{
					display: "grid",
					gridTemplateColumns: "auto 1fr",
					alignItems: "start",
					rowGap: 1.5,
					columnGap: 2,
					padding: 1,
					minHeight: "min-content",
					fontSize: "1.125rem",
				}}
			>
				<TaskField
					name={"title"}
					label={""}
					vertical
					renderEditField={(params) => <TextFieldEditor {...params} multiline />}
					{...fieldCommonProps}
				/>
				<TaskField
					name={"sourceDescription"}
					label={i18n.t("source")}
					vertical
					{...fieldCommonProps}
				/>
				<TaskField
					name={"taskState"}
					label={i18n.t("state")}
					format={(value) => getTaskStateLabel(value)}
					{...fieldCommonProps}
				/>
				<TaskField
					name={"createdAt"}
					label={i18n.t("created_at")}
					format={formatIsoString}
					{...fieldCommonProps}
				/>
				<TaskField
					name={"releasedDate"}
					label={i18n.t("released_at")}
					format={formatIsoString}
					hideIfNull
					{...fieldCommonProps}
				/>
				<TaskField
					name={"actualStartDate"}
					label={i18n.t("started_at")}
					format={formatIsoString}
					hideIfNull
					{...fieldCommonProps}
				/>
				<TaskField
					name={"completedDate"}
					label={i18n.t("completed_at")}
					format={formatIsoString}
					hideIfNull
					{...fieldCommonProps}
				/>
				<TaskField
					name={"cancelledDate"}
					label={i18n.t("cancelled_at")}
					format={formatIsoString}
					hideIfNull
					{...fieldCommonProps}
				/>
				<TaskField
					name={"assignedToUserId"}
					label={i18n.t("executor")}
					format={() => task.assignedToUserName ?? "-"}
					renderEditField={(params) => (
						<UserFieldEditor
							userLabelField={"assignedToUserName"}
							clearable={task.releasedDate == null}
							{...params}
						/>
					)}
					{...fieldCommonProps}
				/>
				<TaskField
					name={"responsiblePersonUserId"}
					label={i18n.t("responsible_person")}
					format={() => task.responsiblePersonUserName ?? "-"}
					renderEditField={({ onSubmit, ...other }) => (
						<UserFieldEditor
							clearable={false}
							userLabelField={"responsiblePersonUserName"}
							onSubmit={(newValue) => {
								if (newValue != null) {
									onSubmit(newValue);
								}
							}}
							{...other}
						/>
					)}
					{...fieldCommonProps}
				/>
				<TaskField
					name={"earliestStartDate"}
					label={i18n.t("earliest_start_date")}
					format={formatIsoString}
					renderEditField={({ value, ...other }) => (
						<DateFieldEditor value={value ?? null} {...other} />
					)}
					{...fieldCommonProps}
				/>
				<TaskField
					name={"deadlineDate"}
					label={i18n.t("deadline_date_shortened")}
					format={formatIsoString}
					renderEditField={(params) => <DateFieldEditor disableClearable {...params} />}
					{...fieldCommonProps}
				/>
				<TaskField
					name={"workloadEstimateHours"}
					label={i18n.t("workload_estimate")}
					format={(value) => `${value ?? "-"} h`}
					renderEditField={({ value, onSubmit, ...other }) => (
						<TextFieldEditor
							type={"number"}
							value={value?.toString() ?? ""}
							onSubmit={(newValue) => onSubmit(Number(newValue))}
							{...other}
						/>
					)}
					{...fieldCommonProps}
				/>
				<TaskField
					name={"taskDescription"}
					label={i18n.t("description")}
					vertical
					renderEditField={(params) => <TextFieldEditor {...params} multiline />}
					{...fieldCommonProps}
				/>
				<TaskField
					name={"note"}
					label={i18n.t("comment")}
					vertical
					renderEditField={(params) => <TextFieldEditor {...params} multiline />}
					{...fieldCommonProps}
				/>
			</Box>
		</VerticalBox>
	);
};

interface TaskFieldProps<TName extends keyof TaskWithUserNames> extends TaskMobileFormProps {
	name: TName;
	label: string;
	format?: (value: NonNullable<TaskWithUserNames[TName]>) => string;
	renderEditField?: (params: RenderFieldParams<TaskWithUserNames[TName]>) => React.ReactNode;
	hideIfNull?: boolean;
	vertical?: boolean;
	fieldUnderEdit: string | null;
	setFieldUnderEdit: React.Dispatch<React.SetStateAction<string | null>>;
}

interface RenderFieldParams<TValue> {
	value: Exclude<TValue, undefined>;
	onSubmit: (newValue: TValue, otherUpdates?: Partial<TaskWithUserNames>) => void;
	onStopEditing: () => void;
	markTaskDirty: () => void;
}

const TaskField = <TName extends keyof TaskWithUserNames>({
	task,
	onTaskChanged,
	name,
	label,
	renderEditField,
	hideIfNull,
	vertical,
	fieldUnderEdit,
	setFieldUnderEdit,
	markTaskDirty,
	format: formatProp,
}: TaskFieldProps<TName>) => {
	const { palette } = useTheme();
	const value = task[name];
	const isEditing = fieldUnderEdit === name;
	const isEditable = renderEditField !== undefined;

	const startEditing = () => {
		setFieldUnderEdit((curr) => {
			if (curr === name) return name; // Is already editing this
			if (curr !== null) return null; // Is editing something else, stop editing first.
			return name;
		});
	};

	const stopEditing = () => {
		setFieldUnderEdit(null);
	};

	const onSubmit = async (
		newValue: TaskWithUserNames[TName],
		otherUpdates?: Partial<TaskWithUserNames>,
	) => {
		if (newValue === value) return;
		onTaskChanged({
			...task,
			[name]: newValue,
			...otherUpdates,
		});
	};

	const format = (value: TaskWithUserNames[TName]) => {
		if (value === null || value === undefined || value === "") return "-";
		if (formatProp) return formatProp(value);

		return String(value);
	};

	if (hideIfNull && value === null) return null;

	const labelTypography = (
		<Typography
			sx={{
				color: "primary.main",
				fontWeight: "bold",
				fontSize: "inherit",
			}}
		>
			{label}
		</Typography>
	);

	const valueTypography = (
		<Typography
			sx={{
				placeSelf: "stretch",
				whiteSpace: "pre-line",
				fontSize: "inherit",
			}}
			onClick={() => {
				if (isEditable) {
					startEditing();
				}
			}}
		>
			{format(value)}
			{isEditable && (
				<FontAwesomeIcon
					icon={faPen}
					style={{
						marginLeft: "4px",
					}}
					transform={{
						size: 9,
						y: -6,
					}}
					color={palette.grey[600]}
				/>
			)}
		</Typography>
	);

	const editField = renderEditField?.({
		value: (value === undefined ? null : value) as Exclude<TaskWithUserNames[TName], undefined>,
		onSubmit: onSubmit,
		onStopEditing: () => {
			stopEditing();
		},
		markTaskDirty: markTaskDirty,
	});

	if (vertical)
		return (
			<VerticalBox
				sx={{
					gridColumn: "1 / -1",
				}}
			>
				{labelTypography}
				{isEditing ? editField : valueTypography}
			</VerticalBox>
		);
	else
		return (
			<Fragment>
				{labelTypography}
				{isEditing ? editField : valueTypography}
			</Fragment>
		);
};

interface TextFieldEditorProps
	extends Pick<AavoTextFieldProps, "type" | "multiline">,
		Omit<RenderFieldParams<string>, "value"> {
	value: string | null;
}

const TextFieldEditor = ({
	value,
	onSubmit,
	onStopEditing,
	markTaskDirty,
	...other
}: TextFieldEditorProps) => {
	const debounceOnChange = useDebounce();
	return (
		<AavoTextField
			defaultValue={value ?? ""}
			onSubmit={(newVal) => {
				onSubmit(newVal);
				onStopEditing();
			}}
			onChange={(newVal) => {
				markTaskDirty();
				debounceOnChange(1500, () => {
					onSubmit?.(newVal);
				});
			}}
			autoFocus
			variant="standard"
			InputProps={{
				sx: {
					padding: 0,
					fontSize: "inherit",
				},
			}}
			{...other}
		/>
	);
};

interface DateFieldEditorProps<DisableClearable extends boolean | undefined>
	extends Omit<AavoDatePickerProps<DisableClearable>, "value">,
		RenderFieldParams<ConditionalNullable<string, DisableClearable>> {}

const DateFieldEditor = <DisableClearable extends boolean | undefined>({
	value,
	onSubmit,
	onStopEditing,
	...other
}: DateFieldEditorProps<DisableClearable>) => {
	const [isOpen, setIsOpen] = useState(true);
	return (
		<AavoDatePicker
			open={isOpen}
			onOpen={() => setIsOpen(true)}
			onClose={() => {
				setIsOpen(false);
				onStopEditing();
			}}
			defaultValue={dayjs(value)}
			onChange={(date) => {
				onSubmit(dayJsToDateIsoStringNullable(date) as ConditionalNullable<string, DisableClearable>);
			}}
			slotProps={{
				textField: {
					variant: "standard",
					inputProps: {
						sx: {
							paddingY: 0,
						},
					},
				},
			}}
			{...other}
		/>
	);
};

interface UserFieldEditorProps extends RenderFieldParams<number | null> {
	clearable?: boolean;
	userLabelField: keyof TaskWithUserNames;
}

const UserFieldEditor = ({
	clearable = true,
	value,
	onSubmit,
	onStopEditing,
	userLabelField,
}: UserFieldEditorProps) => {
	return (
		<AsyncSelectField<AppUser, number>
			label={""}
			autoFocus
			openOnFocus={true}
			defaultValue={value}
			onClose={onStopEditing}
			showClearButton={clearable}
			onChange={(newUser) => {
				onSubmit(newUser?.id ?? null, { [userLabelField]: newUser?.name });
			}}
			fetchOptions={({ searchQuery, currentSelection }) =>
				UserApi.getUserSelectionOptions({
					searchQuery: searchQuery,
					currentSelectionUserId: currentSelection,
				})
			}
			getOptionKey={(option) => option.id}
			getOptionLabel={(option) => option.name}
			TextFieldProps={{
				variant: "standard",
				InputProps: {
					style: {
						fontSize: "inherit",
					},
				},
				inputProps: {
					style: {
						paddingTop: 0,
						paddingBottom: 0,
					},
				},
			}}
		/>
	);
};
