// This file is automatically generated. Do not edit manually.

import { ProductChangeRequestProjectActivityView } from "src/api/generated/erp/db/types/tables/productChangeRequestProjectActivityView";
import { ProductChangeRequest } from "src/api/generated/erp/db/types/tables/productChangeRequest";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace ProjectActivityProductChangeRequestsApi {
	export async function getActivityProductChangeRequests(args: {
		projectActivityId: number;
	}): Promise<Array<ProductChangeRequestProjectActivityView>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/project/activityProductChangeRequests/getActivityProductChangeRequests",
			method: "POST",
			data: {
				projectActivityId: args.projectActivityId,
			},
		});
		return response as Array<ProductChangeRequestProjectActivityView>;
	}

	export async function getChangeRequestToConnectOptions(args: {
		projectActivityId: number;
	}): Promise<Array<ProductChangeRequest>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/project/activityProductChangeRequests/getChangeRequestToConnectOptions",
			method: "POST",
			data: {
				projectActivityId: args.projectActivityId,
			},
		});
		return response as Array<ProductChangeRequest>;
	}

	export async function connectProductChangeRequests(args: {
		projectActivityId: number;
		productChangeRequestIds: Array<number>;
	}): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/project/activityProductChangeRequests/connectProductChangeRequests",
			method: "POST",
			data: {
				projectActivityId: args.projectActivityId,
				productChangeRequestIds: args.productChangeRequestIds,
			},
		});
		return response as void;
	}

	export async function deleteConnections(args: {
		projectActivityId: number;
		productChangeRequestIds: Array<number>;
	}): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/project/activityProductChangeRequests/deleteConnections",
			method: "POST",
			data: {
				projectActivityId: args.projectActivityId,
				productChangeRequestIds: args.productChangeRequestIds,
			},
		});
		return response as void;
	}
}
