import { ConfigurationProductFamilyCatalogPartsApi } from "src/api/generated/erp/configurator/management/productFamilies/api/configurationProductFamilyCatalogPartsApi.ts";
import { CatalogPartView } from "src/api/generated/erp/db/types/tables/catalogPartView.ts";
import { ClientSideDataGridModel } from "src/components/common/dataGrid/gridModel/ClientSideDataGridModel.tsx";
import { AavoDataGrid } from "src/components/common/dataGrid/AavoDataGrid.tsx";
import { textColumn } from "src/components/common/dataGrid/columns.tsx";
import i18n from "i18next";

export interface ConfigurationProductFamilyCatalogPartsDataGridProps {
	productFamilyId: number;
}

export const ConfigurationProductFamilyCatalogPartsDataGrid = ({
	productFamilyId,
}: ConfigurationProductFamilyCatalogPartsDataGridProps) => {
	return (
		<ClientSideDataGridModel
			fetchData={() =>
				ConfigurationProductFamilyCatalogPartsApi.getConfigurationProductFamilyCatalogParts({
					productFamilyId,
				})
			}
			initialParams={{}}
			getRows={(data) => data}
			getRowId={(row) => row.catalogPartId}
			gridId={"246531118EC72795"}
			render={({ dataGridProps }) => (
				<AavoDataGrid<CatalogPartView>
					columns={[
						textColumn({
							field: "partNo",
							headerName: i18n.t("part_no"),
							width: 150,
						}),
						textColumn({
							field: "description_1",
							headerName: i18n.t("description_1"),
							width: 250,
						}),
						textColumn({
							field: "description_2",
							headerName: i18n.t("description_2"),
							width: 250,
						}),
						textColumn({
							field: "categoryName",
							headerName: i18n.t("category"),
							width: 150,
						}),
					]}
					{...dataGridProps}
				/>
			)}
		/>
	);
};
