import { ServerSideDataGridModel } from "src/components/common/dataGrid/gridModel/ServerSideDataGridModel.tsx";
import { CrudDataGrid } from "src/components/common/dataGrid/crud/CrudDataGrid.tsx";
import { SurveyFormSubmissionsDataGridApi } from "src/api/generated/erp/surveys/api/surveyFormSubmissionsDataGridApi.ts";
import { SurveySubmissionView } from "src/api/generated/erp/db/types/tables/surveySubmissionView.ts";
import i18n from "i18next";
import { SurveySubmissionForm } from "src/components/views/erp/surveys/submissions/SurveySubmissionForm.tsx";
import { SurveySubmissionPrintingApi } from "src/api/generated/erp/surveys/api/surveySubmissionPrintingApi.ts";
import { downloadFile } from "src/utils/fileDownloading.ts";
import { AsyncMenuButton } from "src/components/common/contextMenu/AsyncMenuButton.tsx";
import { faPrint } from "@fortawesome/pro-regular-svg-icons";
import { getSurveySubmissionDataGridColumns } from "src/components/views/erp/surveys/surveyUtils.ts";

export interface SurveyFormSubmissionsDataGridProps {
	surveyFormId: number;
}

export const SurveyFormSubmissionsDataGrid = ({ surveyFormId }: SurveyFormSubmissionsDataGridProps) => {
	return (
		<ServerSideDataGridModel
			fetchData={(params) =>
				SurveyFormSubmissionsDataGridApi.getData({
					surveyFormId,
					...params,
				})
			}
			initialParams={{}}
			getDataModelResult={(response) => response}
			getRowId={(row) => row.surveySubmissionId}
			gridId={"F4C04491275C8C34"}
			render={({ dataGridProps }) => (
				<CrudDataGrid<SurveySubmissionView>
					columns={getSurveySubmissionDataGridColumns({
						showFormName: false,
						showRevision: true
					})}
					form={{
						addRowEnabled: true,
						editEnabled: true,
						dialogTitle: i18n.t("survey"),
						dialogSize: "lg",
						component: ({ row, onCompleted, onFormEdited }) => (
							<SurveySubmissionForm
								surveyFormId={surveyFormId}
								surveySubmissionId={row?.surveySubmissionId}
								onCompleted={onCompleted}
								onFormEdited={onFormEdited}
							/>
						),
					}}
					rowContextMenuComponents={({ allSelectedRows }) => [
						<AsyncMenuButton
							key={"print"}
							label={i18n.t("print")}
							icon={faPrint}
							onClick={async () => {
								const fileHandle = await SurveySubmissionPrintingApi.printSurveySubmissions({
									surveySubmissionIds: allSelectedRows.map((r) => r.surveySubmissionId),
								});
								downloadFile(fileHandle);
							}}
						/>,
					]}
					{...dataGridProps}
				/>
			)}
		/>
	);
};
