import {AavoMosaic} from "../../../common/mosaic/AavoMosaic.tsx";
import {ReclamationCustomerOrdersDataGrid} from "./ReclamationCustomerOrdersDataGrid.tsx";
import i18n from "i18next";
import {useState} from "react";
import {CustomerOrderReclamationView} from "src/api/generated/erp/db/types/tables/customerOrderReclamationView.ts";
import {CenteredTypography} from "../../../common/CenteredTypography.tsx";
import {RefreshableElementRef} from "src/utils/useRefreshRef.ts";
import {
    ReclamationCustomerOrderLinesDataGrid
} from "src/components/views/erp/reclamation/ReclamationCustomerOrderLinesDataGrid.tsx";

export interface ReclamationCustomerOrdersViewProps {
	reclamationId: number;
	customerOrdersRefreshRef?: RefreshableElementRef;
}

export const ReclamationCustomerOrdersView = ({
	reclamationId,
	customerOrdersRefreshRef,
}: ReclamationCustomerOrdersViewProps) => {
	const [selectedCustomerOrders, setSelectedCustomerOrders] = useState<
		CustomerOrderReclamationView[]
	>([]);
	const onlySelectedCustomerOrder =
		selectedCustomerOrders.length === 1 ? selectedCustomerOrders[0] : null;
	return (
		<AavoMosaic
			viewId={"26C9C601F2C79A1"}
			layout={{
				type: "row",
				first: {
					type: "panel",
					title: i18n.t("customer_orders"),
					content: (
						<ReclamationCustomerOrdersDataGrid
							refreshRef={customerOrdersRefreshRef}
							reclamationId={reclamationId}
							onSelectionChanged={setSelectedCustomerOrders}
						/>
					),
				},
				second: {
					type: "panel",
					title: i18n.t("customer_order_lines"),
					content:
						onlySelectedCustomerOrder == null ?
							<CenteredTypography>
								{i18n.t("select_single_customer_order")}
							</CenteredTypography>
						:	<ReclamationCustomerOrderLinesDataGrid
								key={onlySelectedCustomerOrder.customerOrderId}
								customerOrderId={onlySelectedCustomerOrder.customerOrderId}
								parentLineId={null}
							/>,
				},
			}}
		/>
	);
};
