import { CatalogPartView } from "src/api/generated/erp/db/types/tables/catalogPartView.ts";
import { FormCommonProps } from "src/components/common/forms/types.ts";
import { CopyCatalogPartToSitesFormApi } from "src/api/generated/erp/parts/catalogPart/api/copyCatalogPartToSitesFormApi.ts";
import i18n from "i18next";
import { CatalogPartToSiteParams } from "src/api/generated/erp/parts/catalogPart/service/catalogPartToSiteParams.ts";
import { Control } from "react-hook-form";
import { useAavoToast } from "src/utils/toast.ts";
import { getPartTypeLabels } from "src/api/generated/erp/db/types/enums/partType.ts";
import { FormEnumSelectField } from "src/components/common/forms/fields/FormEnumSelectField.tsx";
import { Site } from "src/api/generated/erp/db/types/tables/site.ts";
import { Divider, Typography } from "@mui/material";
import { requireRule } from "src/components/common/forms/validation.ts";
import { getAcquisitionMethodLabels } from "src/api/generated/erp/db/types/enums/acquisitionMethod.ts";
import { FormAsyncUserSelectField } from "src/components/views/users/FormAsyncUserSelectField.tsx";
import { FormNumberField } from "src/components/common/forms/fields/FormNumberField.tsx";
import { FormLazySelectField } from "src/components/common/forms/fields/FormLazySelectField.tsx";
import { AsyncForm } from "src/components/common/forms/AsyncForm.tsx";
import { UnitsApi } from "src/api/generated/erp/warehouse/basedata/api/unitsApi.ts";
import { ErpUnit } from "src/api/generated/erp/db/types/tables/erpUnit.ts";
import { VerticalBox } from "src/components/common/box/VerticalBox.tsx";
import { HorizontalBox } from "src/components/common/box/HorizontalBox.tsx";
import { Fragment } from "react";

export interface CopyCatalogPartToSitesFormProps extends FormCommonProps<number[]> {
	catalogParts: CatalogPartView[];
	sites: Site[];
}

export const CopyCatalogPartToSitesForm = ({
	catalogParts,
	sites,
	onFormEdited,
	onCompleted,
}: CopyCatalogPartToSitesFormProps) => {
	const showToast = useAavoToast();

	return (
		<AsyncForm<ErpUnit[], FormValues, number[]>
			fetch={() => UnitsApi.getAll()}
			onCompleted={onCompleted}
			onFormEdited={onFormEdited}
			getDefaultValues={() => {
				return {
					catalogParts: catalogParts.map((catalogPart) => ({
						catalogPart: catalogPart,
						sites: sites.map((site) => ({
							catalogPartId: catalogPart.catalogPartId,
							siteId: site.siteId,
							partType: "PURCHASE",
							acquisitionMethod: "WAREHOUSE",
							responsiblePersonId: catalogPart.responsiblePersonId,
							warehouseTransferUnitId: catalogPart.partUnitId,
							warehouseTransferFactor: 1,
						})),
					})),
				};
			}}
			render={({ control }) => getCatalogParts(control)}
			submit={async ({ catalogParts }) => {
				const result = await CopyCatalogPartToSitesFormApi.savePartToSites({
					parts: catalogParts.flatMap((part) => part.sites),
				});
				showToast("", i18n.t("parts_copied_to_sites"));
				return result;
			}}
		/>
	);

	function getCatalogParts(control: Control<FormValues>) {
		return catalogParts.map((catalogPart, catalogPartIndex) => (
			<Fragment key={catalogPart.catalogPartId}>
				<Divider
					sx={{
						gridColumn: "1 / -1",
						color: "primary.main",
					}}
					textAlign={"left"}
				>
					{`${catalogPart.partNo} ${catalogPart.description_1} ${catalogPart.description_2}`}
				</Divider>
				{getPartSites(catalogPart, catalogPartIndex, control)}
			</Fragment>
		));
	}

	function getPartSites(
		catalogPart: CatalogPartView,
		catalogPartIndex: number,
		control: Control<FormValues>,
	) {
		return sites.map((site, siteIndex) => (
			<SingleSitePartContent
				key={site.siteId}
				sitePartParams={{
					catalogPartId: catalogPart.catalogPartId,
					siteId: site.siteId,
					partType: "PURCHASE",
					acquisitionMethod: "WAREHOUSE",
					responsiblePersonId: catalogPart.responsiblePersonId,
					warehouseTransferUnitId: catalogPart.partUnitId,
					warehouseTransferFactor: 1,
				}}
				siteIndex={siteIndex}
				catalogPartIndex={catalogPartIndex}
				control={control}
				sites={sites}
			/>
		));
	}
};

interface SingleUnitContentProps {
	sitePartParams: CatalogPartToSiteParams;
	siteIndex: number;
	catalogPartIndex: number;
	control: Control<FormValues>;
	sites: Site[];
}

const SingleSitePartContent = ({
	sitePartParams,
	siteIndex,
	catalogPartIndex,
	control,
	sites,
}: SingleUnitContentProps) => {
	const site = sites.find((o) => o.siteId === sitePartParams.siteId);
	return (
		<VerticalBox gap={1}>
			<Typography
				sx={{
					color: "secondary.main",
				}}
			>{`${i18n.t("site")}: ${site?.siteName}`}</Typography>
			<HorizontalBox
				sx={{
					gridColumnGap: "0.25rem",
					"& > *": { flex: 1 },
				}}
			>
				<FormEnumSelectField
					control={control}
					name={`catalogParts.${catalogPartIndex}.sites.${siteIndex}.partType`}
					label={i18n.t("part_type")}
					options={getPartTypeLabels()}
					rules={requireRule()}
				/>
				<FormEnumSelectField
					options={getAcquisitionMethodLabels()}
					control={control}
					name={`catalogParts.${catalogPartIndex}.sites.${siteIndex}.acquisitionMethod`}
					label={i18n.t("acquisition_method")}
					rules={requireRule()}
				/>
				<FormAsyncUserSelectField
					label={i18n.t("responsible_person")}
					control={control}
					name={`catalogParts.${catalogPartIndex}.sites.${siteIndex}.responsiblePersonId`}
					rules={requireRule()}
				/>
				<FormLazySelectField
					control={control}
					name={`catalogParts.${catalogPartIndex}.sites.${siteIndex}.warehouseTransferUnitId`}
					label={i18n.t("warehouse_transfer_unit")}
					fetchOptions={async () => await UnitsApi.getAll()}
					getOptionKey={(option) => option.unitId}
					getOptionLabel={(option) => option.unitName}
					rules={requireRule()}
				/>
				<FormNumberField
					control={control}
					name={`catalogParts.${catalogPartIndex}.sites.${siteIndex}.warehouseTransferFactor`}
					label={i18n.t("warehouse_transfer_factor")}
					rules={requireRule()}
				/>
			</HorizontalBox>
		</VerticalBox>
	);
};

interface FormValues {
	catalogParts: CatalogPartFormValues[];
}

interface CatalogPartFormValues {
	catalogPart: CatalogPartView;
	sites: CatalogPartToSiteParams[];
}
