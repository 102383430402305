import i18n from "i18next";
import { AavoForm } from "src/components/common/forms/AavoForm.tsx";
import { PartCategoryApi } from "src/api/generated/erp/parts/categories/api/partCategoryApi";
import { ObjectAttributeField } from "src/api/generated/erp/common/objectAttributes/objectAttributeField";
import { FormCommonProps } from "src/components/common/forms/types";
import { FormMultiSelectField } from "src/components/common/forms/fields/FormMultiSelectField";
import { AsyncFetchRender } from "src/components/common/async/AsyncFetchRender.tsx";
import { requireRule } from "src/components/common/forms/validation";

export interface PartCategoryAttributesFormProps extends FormCommonProps<void> {
	partCategoryId: number;
}

export const PartCategoryAttributesForm = ({ ...other }: PartCategoryAttributesFormProps) => {
	return (
		<AsyncFetchRender
			fetch={PartCategoryApi.getAllPartAttributeFields}
			content={(allPartAttributeFields) => {
				return (
					<PartCategoryAttributesFormContent allAttributes={allPartAttributeFields} {...other} />
				);
			}}
		/>
	);
};

interface PartCategoryAttributesFormContentProps extends PartCategoryAttributesFormProps {
	allAttributes: ObjectAttributeField[];
}

interface PartCategoryAttributesFormFieldValues {
	selectedAttributeIds: number[];
}

export const PartCategoryAttributesFormContent = ({
	allAttributes,
	partCategoryId,
	onFormEdited,
	onCompleted,
}: PartCategoryAttributesFormContentProps) => {
	return (
		<AavoForm<PartCategoryAttributesFormFieldValues, void>
			defaultValues={{
				selectedAttributeIds: [],
			}}
			onFormEdited={onFormEdited}
			onCompleted={onCompleted}
			submit={(fieldValues) => submitForm(partCategoryId, fieldValues)}
			columns={2}
			render={({ control }) => (
				<>
					<FormMultiSelectField
						control={control}
						name={"selectedAttributeIds"}
						options={allAttributes.map((attr) => {
							return { key: attr.id, label: attr.label };
						})}
						label={i18n.t("attribute")}
						rules={requireRule()}
						autoFocus
					/>
				</>
			)}
		/>
	);
};

const submitForm = async (partCategoryId: number, fieldValues: PartCategoryAttributesFormFieldValues) => {
	return await PartCategoryApi.connectAttributesFields({
		partCategoryId,
		attributeFieldIds: fieldValues.selectedAttributeIds,
	});
};
