// This file is automatically generated. Do not edit manually.

import { ProjectActivityMaterialView } from "src/api/generated/erp/db/types/tables/projectActivityMaterialView";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace ProjectActivityMaterialApi {
	export async function getProjectActivityMaterial(args: {
		projectActivityMaterialId: number;
	}): Promise<ProjectActivityMaterialView> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/project/activityMaterials/getProjectActivityMaterial",
			method: "POST",
			data: {
				projectActivityMaterialId: args.projectActivityMaterialId,
			},
		});
		return response as ProjectActivityMaterialView;
	}
}
