// This file is automatically generated. Do not edit manually.

import { FrontendDownloadFileHandle } from "src/api/generated/common/frontendDownloadFileHandle";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace SurveySubmissionPrintingApi {
	export async function printSurveySubmissions(args: {
		surveySubmissionIds: Array<number>;
	}): Promise<FrontendDownloadFileHandle> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/surveys/submission/print/printSurveySubmissions",
			method: "POST",
			data: {
				surveySubmissionIds: args.surveySubmissionIds,
			},
		});
		return response as FrontendDownloadFileHandle;
	}
}
