import { Box, Menu } from "@mui/material";
import { ContextMenuState } from "src/components/common/contextMenu/useContextMenu.ts";
import { AavoLoading } from "src/components/common/AavoLoading.tsx";
import { AavoContextMenuClosingContext } from "./AavoContextMenuClosingContext";

export interface AavoContextMenuProps {
	state: ContextMenuState;
}

export const AavoContextMenu = ({ state }: AavoContextMenuProps) => {
	const { position, closeContextMenu, contentAsync } = state;
	const contextValue = {
		closeContextMenu: closeContextMenu,
	};

	const menuContent =
		contentAsync?.loading && position ?
			<Box
				sx={{
					position: "fixed",
					top: position.y,
					left: position.x,
				}}
			>
				<AavoLoading size={"2x"} />
			</Box>
		:	contentAsync?.data;

	return (
		<AavoContextMenuClosingContext.Provider value={contextValue}>
			<Menu
				open={position !== undefined}
				anchorReference="anchorPosition"
				anchorPosition={position !== undefined ? { top: position.y, left: position.x } : undefined}
				onContextMenu={(e) => {
					e.preventDefault();
					closeContextMenu();
				}}
				onClose={closeContextMenu}
				children={menuContent}
			/>
		</AavoContextMenuClosingContext.Provider>
	);
};
