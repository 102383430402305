import { InvoicesDataGridBase } from "src/components/views/erp/sales/invoicing/InvoicesDataGridBase.tsx";
import { RefreshableElementProps } from "src/utils/useRefreshRef.ts";

export interface CustomerOrderInvoicesDataGridProps extends RefreshableElementProps {
	customerOrderId: number;
	editable: boolean
}

export const CustomerOrderInvoicesDataGrid = ({
	customerOrderId,
	...other
}: CustomerOrderInvoicesDataGridProps) => {
	return (
		<InvoicesDataGridBase customerOrderId={customerOrderId} hideSearchField={true} {...other} />
	);
};
