import { html, LitElement } from "lit";
import { Calendar } from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction";
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
import bootstrapPlugin from "@fullcalendar/bootstrap";
import fiLocale from "@fullcalendar/core/locales/fi";
import { onAppContentAreaChanged } from "src/legacyViews/js/appContentAreaChangedEvent";

class FullCalendar extends LitElement {
	static get properties() {
		return {
			isResourceMode: { type: Boolean },
			editableEvents: { type: Boolean },
			calendarData: { type: Object },
			dataVersion: { type: Number },
			mode: { type: String }, // 'resource' | 'event'
		};
	}

	constructor() {
		super();
		this.resourceModeEnabled = false;
		this.editableEvents = false;
		this.calendarData = {
			events: [],
			resources: [],
		};
		this.dataVersion = -1;
		this._fetchEventsRequest = null;
		this._fetchResourcesRequest = null;
	}

	render() {
		return html` <div class="fullcalendar-container" />`;
	}

	createRenderRoot() {
		return this;
	}

	firstUpdated(_) {
		const options = {
			schedulerLicenseKey: "0372497864-fcs-1635232377",
			plugins: [
				dayGridPlugin,
				timeGridPlugin,
				listPlugin,
				interactionPlugin,
				bootstrapPlugin,
				resourceTimelinePlugin,
			],
			firstDay: 1,
			locale: fiLocale,
			lazyFetching: true,
			themeSystem: "bootstrap",
			initialView: this.resourceModeEnabled
				? "resourceTimelineSlidingMonth"
				: "dayGridMonth",
			editable: this.editableEvents,
			eventTextColor: "black",
			customButtons: {
				refresh: {
					bootstrapFontAwesome: "fa-sync",
					click: this._onRefreshButtonClicked.bind(this),
				},
			},
			headerToolbar: {
				left: "prev,next refresh today",
				center: "title",
				right: this.resourceModeEnabled
					? "resourceTimelineWeek,resourceTimelineSlidingMonth,resourceTimelineSlidingYear"
					: "dayGridMonth,timeGridWeek,listWeek",
			},
			events: this._onFetchEventsRequested.bind(this),
			eventClick: this._onEventClicked.bind(this),
			eventDidMount: this._onEventDidMount.bind(this),
			eventResize: this._onCalendarEventEdited.bind(this),
			eventDrop: this._onCalendarEventEdited.bind(this),

			views: {
				resourceTimelineSlidingMonth: {
					type: "resourceTimeline",
					nowIndicator: true,
					buttonText: "Kuukausi",
					duration: { weeks: 4 },
				},
				resourceTimelineSlidingYear: {
					type: "resourceTimeline",
					nowIndicator: true,
					buttonText: "Vuosi",
					duration: { months: 12 },
					slotDuration: { days: 12 },
					weekNumbers: true,
				},
			},
			displayEventTime: false,
			navLinks: true,
			resourcesInitiallyExpanded: false,
			resourceAreaWidth: "20%",
		};
		this._calendar = new Calendar(this._getContainer(), options);
		this._calendar.render();

		onAppContentAreaChanged(() => {
			this._calendar.render();
		});
	}

	updated(_changedProperties) {
		if (!this._calendar) return;

		if (_changedProperties.has("dataVersion")) {
			this._resetEvents();

			if (this.resourceModeEnabled) {
				this._resetResources();
			}
		}
	}

	_resetEvents() {
		if (!this._fetchEventsRequest) return;
		// Set events if requested From FullCalendar internal buttons (arrows etc.)
		this._fetchEventsRequest.successCallback(this.calendarData.events);
		// Set events if requested externally updating dataVersion and calendarEvents.
		this._calendar.batchRendering(() => {
			this._calendar.removeAllEvents();
			this._calendar.addEventSource(this.calendarData.events);
		});
	}

	_resetResources() {
		this._calendar.batchRendering(() => {
			this._calendar.getResources().forEach((res) => res.remove());
			this.calendarData.resources.forEach((res) =>
				this._calendar.addResource(res)
			);
		});
	}

	_getContainer() {
		return this.querySelector("div.fullcalendar-container");
	}

	_onRefreshButtonClicked() {
		this.dispatchEvent(new CustomEvent("refreshRequested"));
	}

	_onFetchEventsRequested(info, successCallback, failureCallback) {
		this._fetchEventsRequest = {
			info,
			successCallback,
			failureCallback,
		};
		this.dispatchEvent(
			new CustomEvent("fetchDataRequest", {
				detail: info,
			})
		);
	}

	_onEventClicked(info) {
		this._dispatchPointerEvent("eventClick", info.event, info.jsEvent);
	}

	_onEventDidMount({ event, el }) {
		// Setup right click handler.
		el.addEventListener("contextmenu", (jsEvent) => {
			jsEvent.preventDefault();
			this._dispatchPointerEvent("eventRightClick", event, jsEvent);
		});
	}

	_dispatchPointerEvent(eventName, calendarEvent, jsEvent) {
		this.dispatchEvent(
			new CustomEvent(eventName, {
				detail: {
					eventId: calendarEvent.id,
					mouseEvent: {
						event: jsEvent,
						documentRect:
							document.documentElement.getBoundingClientRect(),
					},
				},
			})
		);
	}

	_onCalendarEventEdited(info) {
		const event = info.event;
		const resourceId = info.newResource
			? info.newResource.id
			: event._def.resourceIds[0];
		this.dispatchEvent(
			new CustomEvent("eventEdited", {
				detail: {
					eventId: event.id,
					newStart: event.startStr,
					newEnd: event.endStr,
					newResourceId: resourceId,
				},
			})
		);
	}
}

customElements.define("fullcalendar-component", FullCalendar);
