// This file is automatically generated. Do not edit manually.

import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace ConfiguratorLuaEditorApi {
	export async function getLintingData(args: {
		productFamilyVersionId: number;
		catalogPartRevisionId: number | null | undefined;
	}): Promise<ConfiguratorLuaEditorApi_LintingData> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/configurator/luaEditor/getLintingData",
			method: "POST",
			data: {
				productFamilyVersionId: args.productFamilyVersionId,
				catalogPartRevisionId: args.catalogPartRevisionId,
			},
		});
		return response as ConfiguratorLuaEditorApi_LintingData;
	}
}

export interface ConfiguratorLuaEditorApi_LintingData {
	parentProperties: Array<ConfiguratorLuaEditorApi_LintingData_ParentProperty>;
	properties: Array<ConfiguratorLuaEditorApi_LintingData_Property>;
}

export interface ConfiguratorLuaEditorApi_LintingData_ParentProperty {
	parentProductFamilyName: string;
	propertyName: string;
}

export interface ConfiguratorLuaEditorApi_LintingData_Property {
	propertyName: string;
}
