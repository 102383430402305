import { Tooltip, TooltipProps } from "@mui/material";

export const MaybeTooltip = ({ children, title, ...other }: TooltipProps) => {
	if (title === undefined || title === null || title === false || title === "") {
		return children;
	}

	return (
		<Tooltip title={title} {...other}>
			{children}
		</Tooltip>
	);
};
