// This file is automatically generated. Do not edit manually.

import { t } from "i18next";

export const ControlChartTypeValues = ["I", "I_MR", "C", "XBAR_R", "XBAR_S"] as const;

export type ControlChartType = (typeof ControlChartTypeValues)[number];

export const getControlChartTypeLabels = () => ({
	I: t("i"),
	I_MR: t("i_mr"),
	C: t("c"),
	XBAR_R: t("xbar_r"),
	XBAR_S: t("xbar_s"),
});

export const getControlChartTypeLabel = (value: ControlChartType): string => {
	return getControlChartTypeLabels()[value];
};
