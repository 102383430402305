import { AavoMosaic } from "src/components/common/mosaic/AavoMosaic.tsx";
import i18n from "i18next";
import { CustomerOrderInvoicesDataGrid } from "src/components/views/erp/sales/invoicing/customerOrderInvoicing/CustomerOrderInvoicesDataGrid.tsx";
import { BillingPlanInvoicingView } from "src/components/views/erp/sales/invoicing/customerOrderInvoicing/BillingPlanInvoicingView.tsx";
import { CustomerOrderView } from "src/api/generated/erp/db/types/tables/customerOrderView.ts";
import { useRefreshRef } from "src/utils/useRefreshRef.ts";
import { CustomerOrderLineInvoicingView } from "src/components/views/erp/sales/invoicing/customerOrderInvoicing/CustomerOrderLineInvoicingView.tsx";
import { AavoMosaicNode } from "src/components/common/mosaic/types.ts";
import {
	DeliveryInvoicingView
} from "src/components/views/erp/sales/invoicing/customerOrderInvoicing/DeliveryInvoicingView.tsx";

export interface CustomerOrderInvoicingViewProps {
	customerOrder: CustomerOrderView;
}

export const CustomerOrderInvoicingView = ({
	customerOrder: { customerOrderId, billingPlanEnabled },
}: CustomerOrderInvoicingViewProps) => {
	const invoicesDataGridRefreshRef = useRefreshRef();

	const leftSide: AavoMosaicNode = billingPlanEnabled
		? {
				type: "panel",
				title: i18n.t("billing_plan"),
				content: (
					<BillingPlanInvoicingView
						customerOrderId={customerOrderId}
						onInvoicesModified={() => invoicesDataGridRefreshRef.refresh()}
					/>
				),
		  }
		: {
				type: "column",
				first: {
					type: "panel",
					title: i18n.t("deliveries"),
					content: (
						<DeliveryInvoicingView
							customerOrderId={customerOrderId}
							onInvoicesModified={() => invoicesDataGridRefreshRef.refresh()}
						/>
					),
				},
				second: {
					type: "panel",
					title: i18n.t("other_order_lines"),
					content: (
						<CustomerOrderLineInvoicingView
							customerOrderId={customerOrderId}
							onInvoicesModified={() => invoicesDataGridRefreshRef.refresh()}
						/>
					),
				},
		  };

	return (
		<AavoMosaic
			viewId={"AC4F654209A13A9B"}
			layout={{
				type: "row",
				first: leftSide,
				second: {
					type: "panel",
					title: i18n.t("invoices"),
					content: (
						<CustomerOrderInvoicesDataGrid
							customerOrderId={customerOrderId}
							refreshRef={invoicesDataGridRefreshRef}
							editable={true}
						/>
					),
				},
			}}
		/>
	);
};
