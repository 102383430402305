import { AsyncForm } from "src/components/common/forms/AsyncForm.tsx";
import {
	CustomerOrderBillingPlanLineEditApi,
	CustomerOrderBillingPlanLineEditApi_InitData,
} from "src/api/generated/erp/sales/billingPlan/customerOrder/api/customerOrderBillingPlanLineEditApi.ts";
import { CustomerOrderBillingPlanLine } from "src/api/generated/erp/db/types/tables/customerOrderBillingPlanLine.ts";
import { FormTextField } from "src/components/common/forms/fields/FormTextField.tsx";
import i18n from "i18next";
import {
	floatRule,
	requiredNonNegativeFloatRule,
	requireRule,
} from "src/components/common/forms/validation.ts";
import { FormDateField } from "src/components/common/forms/fields/FormDateField.tsx";
import { FormSelectField } from "src/components/common/forms/fields/FormSelectField.tsx";
import { FormCommonProps } from "src/components/common/forms/types.ts";
import { FormCheckbox } from "src/components/common/forms/fields/FormCheckbox.tsx";
import { FormNumberField } from "src/components/common/forms/fields/FormNumberField.tsx";
import { DefaultValues } from "react-hook-form";
import { FormEnumSelectField } from "src/components/common/forms/fields/FormEnumSelectField.tsx";
import { RefreshableElementRef } from "src/utils/useRefreshRef.ts";
import {
	ObjectAccountingForm,
	ObjectAccountingFormValues,
} from "src/components/views/erp/sales/accounting/ObjectAccountingForm.tsx";
import {
	getObjectAccountingCodeIdsFromFormValues,
	getObjectAccountingFormDefaultValues,
} from "src/components/views/erp/sales/accounting/ObjectAccountingForm.utils.ts";
import {
	BillingPlanLineSumInput
} from "src/api/generated/erp/sales/billingPlan/base/service/billingPlanLineSumInput.ts";

export interface CustomerOrderBillingPlanLineFormProps extends FormCommonProps<number> {
	customerOrderId: number;
	billingPlanLine: CustomerOrderBillingPlanLine | undefined;
	customerOrderDataRefreshRef?: RefreshableElementRef;
}

interface FormValues
	extends Pick<CustomerOrderBillingPlanLine, "description" | "estimatedApprovalDate" | "funded">,
		ObjectAccountingFormValues {
	sumInputType: SumInputType;
	sum: number;
	percent: number;
	vatCodeId: number;
}

type SumInputType = "sum" | "percent" | "rest";

export const CustomerOrderBillingPlanLineForm = ({
	customerOrderId,
	billingPlanLine,
	onCompleted,
	onFormEdited,
	customerOrderDataRefreshRef,
}: CustomerOrderBillingPlanLineFormProps) => {
	return (
		<AsyncForm
			fetch={() =>
				CustomerOrderBillingPlanLineEditApi.getFormInitData({
					customerOrderId: customerOrderId,
					billingPlanLineId: billingPlanLine?.customerOrderBillingPlanLineId,
				})
			}
			getDefaultValues={getDefaultValues}
			submit={submit}
			onCompleted={onCompleted}
			onFormEdited={onFormEdited}
			render={({ data: { inputPricesWithVat, vatCodeOptions }, control, watch }) => {
				const sumInputType = watch("sumInputType");
				return (
					<>
						<FormTextField
							control={control}
							label={i18n.t("description")}
							name={"description"}
							rules={requireRule()}
						/>
						<FormDateField
							control={control}
							label={i18n.t("estimated_approval_date")}
							name={"estimatedApprovalDate"}
							disabled={billingPlanLine != null && billingPlanLine.approvedAt !== null}
							rules={requireRule()}
						/>
						<FormEnumSelectField
							control={control}
							name={"sumInputType"}
							label={i18n.t("input_type")}
							options={{
								sum: i18n.t("sum"),
								percent: i18n.t("share_of"),
								rest: i18n.t("rest_of_order"),
							}}
						/>
						{sumInputType === "sum" && (
							<FormNumberField
								control={control}
								name={"sum"}
								rules={requiredNonNegativeFloatRule()}
								label={
									i18n.t("sum") +
									" " +
									(inputPricesWithVat ? i18n.t("with_vat") : i18n.t("without_vat"))
								}
							/>
						)}
						{sumInputType === "percent" && (
							<FormNumberField
								control={control}
								label={i18n.t("share_of") + " %"}
								name={"percent"}
								rules={floatRule((value) => {
									if (value < 0 || value > 100) {
										return i18n.t("value_must_be_between_0_and_100");
									}
								})}
							/>
						)}
						<FormSelectField
							control={control}
							name={"vatCodeId"}
							label={i18n.t("vat_code")}
							options={vatCodeOptions}
							getOptionKey={(o) => o.vatCodeId}
							getOptionLabel={(o) => o.vatCodeName}
							disabled={billingPlanLine != null}
						/>
						<ObjectAccountingForm control={control} />
						<FormCheckbox label={i18n.t("funded")} control={control} name={"funded"} />
					</>
				);
			}}
		/>
	);

	function getDefaultValues({
		inputPricesWithVat,
		customerOrderVatCodeId,
		accountingDimensions,
	}: CustomerOrderBillingPlanLineEditApi_InitData): DefaultValues<FormValues> {
		if (!billingPlanLine) {
			return {
				sumInputType: "sum",
				sum: 0,
				percent: 0,
				funded: false,
				vatCodeId: customerOrderVatCodeId,
				...getObjectAccountingFormDefaultValues(accountingDimensions),
			};
		} else {
			return {
				...billingPlanLine,
				sumInputType: "sum",
				sum:
					inputPricesWithVat ?
						billingPlanLine.billingPlanSumWithVat
					:	billingPlanLine.billingPlanSum,
				percent: 0,
				...getObjectAccountingFormDefaultValues(accountingDimensions),
			};
		}
	}

	async function submit(values: FormValues) {
		const sumInput: BillingPlanLineSumInput =
			values.sumInputType === "sum" ?
				{
					type: "sum",
					sum: values.sum,
				}
			: values.sumInputType === "percent" ?
				{
					type: "percent",
					percent: values.percent,
				}
			:	{
					type: "rest",
				};

		const result = await CustomerOrderBillingPlanLineEditApi.save({
			customerOrderId: customerOrderId,
			billingPlanLineId: billingPlanLine?.customerOrderBillingPlanLineId,
			description: values.description,
			estimatedApprovalDate: values.estimatedApprovalDate,
			funded: values.funded,
			sumInput: sumInput,
			vatCodeId: values.vatCodeId,
			accountingCodeIds: getObjectAccountingCodeIdsFromFormValues(values.accountingDimensionValues),
		});

		customerOrderDataRefreshRef?.refresh();
		return result.customerOrderBillingPlanLineId;
	}
};
