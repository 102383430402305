import { AsyncCrudDataGrid } from "../../../../common/dataGrid/crud/AsyncCrudDataGrid.tsx";
import { textColumn } from "../../../../common/dataGrid/columns.tsx";
import i18n from "i18next";
import { dataGridPersistentStateProps } from "../../../../common/dataGrid/dataGridStateStorage.ts";
import { CostEventItemForm } from "src/components/views/erp/common/costEvents/CostEventItemForm.tsx";
import { CostEventItemApi } from "src/api/generated/erp/common/costEvents/costEventItemApi.ts";
import { CostEventItemView } from "src/api/generated/erp/db/types/tables/costEventItemView.ts";

export const CostEventItemsDataGrid = () => {
	return (
		<AsyncCrudDataGrid<CostEventItemView>
			fetchData={CostEventItemApi.getAll}
			getRowId={(row) => row.costEventItemId}
			columns={[
				textColumn({
					headerName: i18n.t("number_shortened"),
					field: "costEventItemId",
					width: 100,
				}),
				textColumn({
					headerName: i18n.t("name"),
					field: "itemName",
					width: 400,
				}),
				textColumn({
					headerName: i18n.t("vat_code"),
					field: "vatCodeName",
				}),
			]}
			remove={{
				type: "enabled",
				action: async (params) =>
					await CostEventItemApi.delete_({ costEventItems: params.items }),
			}}
			form={{
				editEnabled: true,
				addRowEnabled: true,
				dialogSize: "sm",
				dialogTitle: i18n.t("item"),
				component: ({ row, ...other }) => (
					<CostEventItemForm costEventItemId={row?.costEventItemId ?? null} {...other} />
				),
			}}
			{...dataGridPersistentStateProps("FF68304AC0430930")}
		/>
	);
};
