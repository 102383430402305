import { Box, BoxProps } from "@mui/material";
import { mergeSx } from "src/utils/styles.ts";
import React from "react";

export const HorizontalBox = React.forwardRef(
	({ sx, ...other }: BoxProps, ref: React.ForwardedRef<HTMLDivElement | null>) => {
		return (
			<Box
				ref={ref}
				sx={mergeSx(
					{
						display: "flex",
						flexDirection: "row",
						minWidth: 0,
						minHeight: 0,
					},
					sx,
				)}
				{...other}
			/>
		);
	},
);
