import { ClientSideDataGridModel } from "../../../../components/common/dataGrid/gridModel/ClientSideDataGridModel.tsx";
import { MellanoTransactionStatusViewApi } from "../../../../api/generated/tenants/mellano/api/mellanoTransactionStatusViewApi.ts";
import { AavoDataGrid } from "../../../../components/common/dataGrid/AavoDataGrid.tsx";
import { MellanoTransaction } from "../../../../api/generated/tenants/mellano/models/mellanoTransaction.ts";
import {
	coloredBooleanColumn,
	dateTimeColumn,
	textColumn,
} from "../../../../components/common/dataGrid/columns.tsx";
import i18n from "i18next";
import { AavoTextField } from "../../../../components/common/inputFields/AavoTextField.tsx";
import { toIntOrNull } from "../../../../utils/strings.tsx";
import { AsyncMenuButton } from "../../../../components/common/contextMenu/AsyncMenuButton.tsx";
import { faFileBinary } from "@fortawesome/pro-solid-svg-icons";
import { useGenericDialog } from "../../../../components/common/dialogs/useGenericDialog.ts";
import { ComponentBatchesView } from "./ComponentBatchesView.tsx";

export interface TransactionStatusViewProps {
	setSelectedTransaction: (transaction: MellanoTransaction | undefined) => void;
}

export const TransactionStatusView = ({ setSelectedTransaction }: TransactionStatusViewProps) => {
	const { openDialog } = useGenericDialog();
	return (
		<ClientSideDataGridModel
			fetchData={async ({ transactionNumber }) => {
				const transactionId = toIntOrNull(transactionNumber);
				return transactionId == null ?
						[]
					:	await MellanoTransactionStatusViewApi.getTransactions({
							transactionNumber: transactionId,
						});
			}}
			getRows={(data) => data}
			gridId={"C774F6009C02E11D"}
			initialParams={{
				transactionNumber: "",
			}}
			getRowId={(row) => row.transactionNumber}
			onSelectionChanged={(rows) => setSelectedTransaction(rows[0])}
			render={({ dataGridProps, refreshData }) => {
				return (
					<AavoDataGrid<MellanoTransaction>
						disableMultipleRowSelection
						columns={[
							textColumn({
								field: "transactionNumber",
								headerName: i18n.t("order_number"),
							}),
							textColumn({
								field: "customerName",
								headerName: i18n.t("name"),
								width: 250,
							}),
							textColumn({
								field: "title",
								headerName: i18n.t("title"),
								width: 350,
							}),
							dateTimeColumn({
								field: "deliveryDate",
								headerName: i18n.t("delivery_date"),
								width: 130,
							}),
							coloredBooleanColumn({
								field: "isReclamation",
								headerName: i18n.t("reclamation"),
							}),
							textColumn({
								field: "state",
								headerName: i18n.t("state"),
								width: 150,
							}),
						]}
						actionBarComponents={
							<>
								<AavoTextField
									label={i18n.t("order_number")}
									name={"transactionNumber"}
									variant={"outlined"}
									onSubmit={async (value) => {
										await refreshData({ transactionNumber: value });
									}}
								/>
							</>
						}
						rowContextMenuComponents={({ row }) => [
							<AsyncMenuButton
								icon={faFileBinary}
								label={"Työmääräimet"}
								onClick={() => {
									if (row.transactionId != undefined)
										openDialog(() => ({
											title: "Työmääräimet",
											content: (
												<ComponentBatchesView transactionId={row.transactionId} />
											),
										}));
								}}
							/>,
						]}
						{...dataGridProps}
					/>
				);
			}}
		/>
	);
};
