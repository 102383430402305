import { AavoMenuButton } from "src/components/common/menu/AavoMenuButton";
import i18n from "i18next";
import { faGear, faRefresh } from "@fortawesome/pro-regular-svg-icons";
import { ErpAppbarSelections } from "src/components/views/appBar/ErpAppbarSelections.tsx";
import React from "react";
import { useTenantCustomizations } from "src/tenantCustomizations/useTenantCustomizations.tsx";
import { AsyncButton } from "src/components/common/buttons/AsyncButton.tsx";
import { unregisterServiceWorkers } from "src/utils/serviceWorkerUtils.ts";

export const AppBarDropdownMenuButton = React.memo(() => {
	const { tenantConfig } = useTenantCustomizations();

	return (
		<AavoMenuButton
			icon={faGear}
			variant={"contained"}
			tooltip={i18n.t("erp_options")}
			menuProps={{
				transformOrigin: {
					vertical: "bottom",
					horizontal: MENU_WIDTH,
				},
				PaperProps: {
					sx: {
						width: MENU_WIDTH,
					},
				},
			}}
			menuContent={(closeMenu) => [
				<AsyncButton
					key={"hardRefreshApp"}
					variant={"menu"}
					icon={faRefresh}
					label={i18n.t("update_app")}
					tooltip={i18n.t("update_app_tooltip")}
					onClick={async () => {
						await unregisterServiceWorkers();
						window.location.reload();
					}}
				/>,
				tenantConfig.erp.enabled && (
					<ErpAppbarSelections key={"erpSelections"} closeMenu={closeMenu} />
				),
			]}
		/>
	);
});

const MENU_WIDTH = 300; // Hard to set this dynamically due to async content.