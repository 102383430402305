// This file is automatically generated. Do not edit manually.

import { SystemDiagram } from "src/api/generated/postgres/db/types/public_/tables/systemDiagram";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace SystemDiagramCrudApi {
	export async function getSingle(args: { systemDiagramId: number }): Promise<SystemDiagram> {
		const response = await makeAxiosRequest({
			url: "/api/ext/common/systemDiagrams/manage/getSingle",
			method: "POST",
			data: {
				systemDiagramId: args.systemDiagramId,
			},
		});
		return response as SystemDiagram;
	}

	export async function save(args: { systemDiagram: SystemDiagram }): Promise<SystemDiagram> {
		const response = await makeAxiosRequest({
			url: "/api/ext/common/systemDiagrams/manage/save",
			method: "POST",
			data: {
				systemDiagram: args.systemDiagram,
			},
		});
		return response as SystemDiagram;
	}

	export async function delete_(args: { systemDiagrams: Array<SystemDiagram> }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/common/systemDiagrams/manage/delete",
			method: "POST",
			data: {
				systemDiagrams: args.systemDiagrams,
			},
		});
		return response as void;
	}
}
