import { useClientSideDataGridModel } from "src/components/common/dataGrid/gridModel/useClientSideDataGridModel";
import { PartConfigurationApi } from "src/api/generated/erp/configurator/api/partConfigurationApi.ts";
import { ControlledAsyncDataGrid } from "src/components/common/dataGrid/ControlledAsyncDataGrid.tsx";
import { PartConfigurationProperty } from "src/api/generated/erp/db/types/tables/partConfigurationProperty";
import i18n from "i18next";
import { isEmptyConfigurationPropertyValue } from "src/components/views/erp/configurator/configuratorUtils.ts";

export interface PartConfigurationPropertiesDataGridProps {
	partConfigurationId: number;
}

export const PartConfigurationPropertiesDataGrid = ({
	partConfigurationId,
}: PartConfigurationPropertiesDataGridProps) => {
	const { dataGridProps } = useClientSideDataGridModel({
		fetchData: async () => {
			const allProps = await PartConfigurationApi.getPartConfigurationProperties({
				partConfigurationId,
			});
			return allProps.filter(
				(prop) => !(prop.hideIfEmptyValue && isEmptyConfigurationPropertyValue(prop.propertyValue)),
			);
		},
		getRowId: (row) => row.partConfigurationPropertyId,
		gridId: "CBAF40A175B53A68",
		initialParams: {},
	});

	return (
		<ControlledAsyncDataGrid<PartConfigurationProperty>
			hideRefreshButton
			columns={[
				{
					type: "string",
					field: "propertyTitle",
					headerName: i18n.t("property"),
					width: 300,
				},
				{
					type: "string",
					field: "propertyValue",
					headerName: i18n.t("value"),
					valueGetter: (_, row) => row.propertyValue?.label ?? "-",
					width: 700,
				},
			]}
			{...dataGridProps}
		/>
	);
};
