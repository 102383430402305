import { GridValidRowModel } from "@mui/x-data-grid/models/gridRows";
import { GridRenderEditCellParams } from "@mui/x-data-grid-pro";
import { Dayjs } from "dayjs";
import React from "react";
import { AavoDateTimePicker } from "src/components/common/inputFields/AavoDateTimePicker";

export interface AavoDataGridDateTimeEditorProps<RowData extends GridValidRowModel>
	extends GridRenderEditCellParams<RowData, Dayjs> {}

export const AavoDataGridDateTimeEditor = <RowData extends GridValidRowModel>({
	api,
	id,
	value,
	field,
	hasFocus,
}: AavoDataGridDateTimeEditorProps<RowData>) => {
	const [isOpen, setIsOpen] = React.useState(hasFocus);

	return (
		<AavoDateTimePicker
			value={value}
			open={isOpen}
			onOpen={() => setIsOpen(true)}
			onClose={() => setIsOpen(false)}
			onChange={(newValue) => {
				api.setEditCellValue({ id, field, value: newValue });
			}}
		/>
	);
};
