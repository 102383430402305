import { CustomerOrderView } from "src/api/generated/erp/db/types/tables/customerOrderView.ts";
import { ControlledAsyncDataGrid } from "src/components/common/dataGrid/ControlledAsyncDataGrid.tsx";
import { enumColumn, integerColumn, textColumn } from "src/components/common/dataGrid/columns.tsx";
import { useServerSideDataGridModel } from "src/components/common/dataGrid/gridModel/useServerSideDataGridModel";
import i18n from "i18next";
import { getCustomerOrderStateLabels } from "src/api/generated/erp/db/types/enums/customerOrderState.ts";
import { AavoTextField } from "src/components/common/inputFields/AavoTextField.tsx";
import { AavoCheckbox } from "src/components/common/inputFields/AavoCheckbox.tsx";
import { AavoButton } from "src/components/common/buttons/AavoButton.tsx";
import { faReceipt } from "@fortawesome/pro-regular-svg-icons";
import { useGenericDialog } from "src/components/common/dialogs/useGenericDialog.ts";
import { CustomerOrderInvoicingView } from "src/components/views/erp/sales/invoicing/customerOrderInvoicing/CustomerOrderInvoicingView.tsx";
import { CustomerOrderInvoicingApi } from "src/api/generated/erp/sales/invoicing/api/customerOrderInvoicingApi.ts";
import { OpenCustomerOrderButton } from "src/components/views/erp/utilComponents/OpenCustomerOrderButton.tsx";

export const BillableCustomerOrdersDataGrid = () => {
	const { openDialog } = useGenericDialog();

	const { dataGridProps, refreshData, currentParams, onlySelectedRow } = useServerSideDataGridModel({
		gridId: "8A7B7FECD4C64A24",
		fetchData: CustomerOrderInvoicingApi.getBillableCustomerOrders,
		getRowId: (row) => row.customerOrderId,
		initialParams: {
			onlyDefaultSite: true,
			searchQuery: "",
		},
	});

	return (
		<ControlledAsyncDataGrid<CustomerOrderView>
			columns={[
				integerColumn({
					field: "customerOrderId",
					headerName: i18n.t("order_number"),
				}),
				enumColumn({
					field: "customerOrderState",
					headerName: i18n.t("state"),
					enumLabels: getCustomerOrderStateLabels(),
					width: 150,
				}),
				textColumn({
					field: "customerName",
					headerName: i18n.t("customer"),
					width: 180,
				}),
				textColumn({
					field: "billingPlanEnabled",
					headerName: i18n.t("billing_method"),
					width: 180,
					valueGetter: (_, row) =>
						row.billingPlanEnabled ? i18n.t("billing_plan") : i18n.t("delivery"),
				}),
				textColumn({
					field: "orderReference",
					headerName: i18n.t("reference"),
					width: 200,
				}),
				textColumn({
					field: "note",
					headerName: i18n.t("note"),
					width: 300,
				}),
			]}
			actionBarComponents={
				<>
					<AavoTextField
						label={i18n.t("search")}
						onSubmit={async (value) => {
							await refreshData({ searchQuery: value });
						}}
					/>
					<AavoButton
						label={i18n.t("invoicing")}
						icon={faReceipt}
						disabled={!onlySelectedRow}
						variant={"outlined"}
						onClick={() => {
							if (!onlySelectedRow) return;
							openDialog(() => ({
								title: `${i18n.t("invoicing")} 
								(${onlySelectedRow.customerOrderId}, ${onlySelectedRow.orderReference})`,

								content: <CustomerOrderInvoicingView customerOrder={onlySelectedRow} />,
							}));
						}}
					/>
					<AavoCheckbox
						label={i18n.t("only_default_site")}
						checked={currentParams.onlyDefaultSite}
						onChange={async (checked) => {
							await refreshData({ onlyDefaultSite: checked });
						}}
					/>
				</>
			}
			rowContextMenuComponents={({ row, multipleRowsSelected }) => [
				!multipleRowsSelected && row.customerOrderId != null && (
					<OpenCustomerOrderButton
						key={"open_customer_order"}
						customerOrderId={row.customerOrderId}
					/>
				),
			]}
			{...dataGridProps}
		/>
	);
};
