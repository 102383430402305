import {AavoObjectType} from "../api/generated/common/sourceType/aavoObjectType.ts";
import {AavoObjectRef} from "../api/generated/common/sourceType/aavoObjectRef.ts";

export const nullableAavoObjectRef = (
	objectType: AavoObjectType | undefined | null,
	objectId: number | undefined | null,
): AavoObjectRef | undefined => {
	if (objectId == undefined  || objectType == undefined) {
		return undefined;
	}
	return {
		objectType,
		objectId,
	};
};
