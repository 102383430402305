import { CustomerOrderTenantStateConfiguration } from "src/api/generated/postgres/db/types/salvos/tables/customerOrderTenantStateConfiguration.ts";
import { CustomerOrderView } from "src/api/generated/erp/db/types/tables/customerOrderView.ts";
import { useGenericDialog } from "src/components/common/dialogs/useGenericDialog.ts";
import { AsyncMenuButton } from "src/components/common/contextMenu/AsyncMenuButton.tsx";
import i18n from "i18next";
import { faEnvelope } from "@fortawesome/pro-regular-svg-icons";
import { SendEmailForm } from "src/components/views/email/SendEmailForm.tsx";

export interface SalvosSendEmailAboutCustomerOrderTenantStateButtonProps {
	tenantStateConfig: CustomerOrderTenantStateConfiguration;
	customerOrder: CustomerOrderView;
}

export const SalvosSendEmailAboutCustomerOrderTenantStateButton = ({
	tenantStateConfig,
	customerOrder,
}: SalvosSendEmailAboutCustomerOrderTenantStateButtonProps) => {
	const { openDialog } = useGenericDialog();
	return [
		<AsyncMenuButton
			key={"sendEmailAboutCustomerOrderTenantStateChange"}
			label={i18n.t("send_email_to_customer")}
			icon={faEnvelope}
			onClick={async () => {
				openDialog(({ closeDialog }) => ({
					title: i18n.t("send_email_to_customer"),
					size: "xl",
					content: (
						<SendEmailForm
							defaultValues={{
								receiverEmail: customerOrder.contactEmail,
								subject: tenantStateConfig.mailTaskSubject,
								body: tenantStateConfig.mailTaskBody,
							}}
							onCompleted={async () => {
								await closeDialog();
							}}
						/>
					),
				}));
			}}
		/>,
	];
};
