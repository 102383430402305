import {ConfiguratorLibrary} from "src/api/generated/erp/db/types/tables/configuratorLibrary.ts";
import {FormTextField} from "src/components/common/forms/fields/FormTextField.tsx";
import {requireRule} from "src/components/common/forms/validation.ts";
import {
	ConfiguratorLibrariesApi
} from "src/api/generated/erp/configurator/management/libraries/api/configuratorLibrariesApi.ts";
import {AavoForm} from "src/components/common/forms/AavoForm.tsx";
import i18n from "i18next";
import {FormCommonProps} from "src/components/common/forms/types.ts";
import {DeepPartial} from "react-hook-form";

export interface ConfiguratorLibraryFormProps extends FormCommonProps<number> {
	configuratorLibrary: ConfiguratorLibrary | undefined;
}

interface FormValues extends ConfiguratorLibrary {}

export const ConfiguratorLibraryForm = (props: ConfiguratorLibraryFormProps) => {
	const { onCompleted, onFormEdited, configuratorLibrary } = props;
	return (
		<AavoForm
			defaultValues={getDefaultValues()}
			submit={submit}
			onCompleted={onCompleted}
			onFormEdited={onFormEdited}
			render={({ control }) => (
				<>
					<FormTextField
						control={control}
						name={"name"}
						label={i18n.t("name")}
						rules={requireRule()}
					/>
				</>
			)}
		/>
	);

	function getDefaultValues(): DeepPartial<FormValues> {
		if (configuratorLibrary != null) return configuratorLibrary;
		else return {};
	}

	async function submit(values: FormValues) {
		if (configuratorLibrary != null) {
			await ConfiguratorLibrariesApi.update({
				library: values,
			});
			return configuratorLibrary.configuratorLibraryId;
		} else {
			return await ConfiguratorLibrariesApi.insert({
				library: values,
			});
		}
	}
};
