import React from "react";
import { Box, Typography } from "@mui/material";
import { mergeSx } from "src/utils/styles.ts";
import { SurveyFormField } from "src/api/generated/erp/db/types/tables/surveyFormField.ts";

interface SurveySubmissionFormFieldWrapperProps {
	field: SurveyFormField;
	children: React.ReactNode;
}

export const SurveySubmissionFormFieldWrapper = ({ field, children }: SurveySubmissionFormFieldWrapperProps) => {
	const spanAllColumns = field.spanAllColumns || field.fieldType === "SECTION_BREAK";
	return (
		<Box
			sx={mergeSx(
				{
					display: "flex",
					flexDirection: "column",
				},
				spanAllColumns ? { gridColumn: "1 / -1" } : { gridColumnStart: field.placeOnNewRow ? `1` : "auto" },
			)}
		>
			{children}
			{field.instruction && (
				<Typography
					variant={"caption"}
					sx={{
						marginY: 0.5,
						marginLeft: 1,
						color: "text.secondary",
					}}
				>
					{field.instruction}
				</Typography>
			)}
		</Box>
	);
};
