import { useClientSideDataGridModel } from "src/components/common/dataGrid/gridModel/useClientSideDataGridModel.tsx";
import i18n from "i18next";
import { textColumn } from "src/components/common/dataGrid/columns.tsx";
import { SalesPartCategory } from "src/api/generated/erp/db/types/tables/salesPartCategory.ts";
import { ControlledAsyncCrudDataGrid } from "src/components/common/dataGrid/crud/ControlledAsyncCrudDataGrid.tsx";
import { SalesPartCategoryEditApi } from "src/api/generated/erp/sales/basedata/api/salesPartCategoryEditApi.ts";
import { SalesPartCategoryForm } from "src/components/views/erp/sales/basedata/salesPartCategories/SalesPartCategoryForm.tsx";
import { SalesPartCategoryApi } from "src/api/generated/erp/sales/basedata/api/salesPartCategoryApi.ts";

export const SalesPartCategoriesDataGrid = () => {
	const { dataGridProps } = useClientSideDataGridModel({
		fetchData: () => SalesPartCategoryApi.getAllSalesPartCategories(),
		initialParams: {},
		getRowId: (row) => row.salesPartCategoryId,
		gridId: "10DF11DB11D19766",
	});

	return (
		<ControlledAsyncCrudDataGrid<SalesPartCategory>
			columns={[
				textColumn({
					field: "name",
					headerName: i18n.t("name"),
					width: 200,
				}),
				textColumn({
					field: "description",
					headerName: i18n.t("description"),
					width: 300,
				}),
			]}
			form={{
				addRowEnabled: true,
				editEnabled: true,
				dialogSize: "sm",
				dialogTitle: i18n.t("sales_part_category"),
				component: ({ row, onCompleted, onFormEdited }) => (
					<SalesPartCategoryForm
						salesPartCategoryId={row?.salesPartCategoryId}
						onCompleted={onCompleted}
						onFormEdited={onFormEdited}
					/>
				),
			}}
			remove={({ items }) =>
				SalesPartCategoryEditApi.delete_({
					salesPartCategoryIds: items.map((item) => item.salesPartCategoryId),
				})
			}
			{...dataGridProps}
		/>
	);
};
