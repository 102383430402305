import { AavoMosaic } from "src/components/common/mosaic/AavoMosaic.tsx";
import { ComponentCountDataGrid } from "src/tenantCustomizations/tenants/mellano/views/ComponentCountDataGrid.tsx";

export const ComponentCountPage = () => {
	return (
		<AavoMosaic
			viewId={"B0FE2CDF64F13B76"}
			layout={{
				type: "row",
				first: {
					type: "panel",
					title: "Keskeneräinen tuotanto",
					content: (
						<div></div>
						// <ComponentCountDataGrid
						// 	componentState={"Tulostettu"}
						// 	notInStates={["Kollitettu", "Paketoitu", "Lähetetty"]}
						// />
					),
				},
				second: {
					type: "panel",
					title: "Valmiit",
					content: (
						<ComponentCountDataGrid
							componentState={"Paketoitu"}
							notInStates={["Lähetetty"]}
						/>
					),
				},
			}}
		/>
	);
};
