import { useAsyncState } from "src/utils/async/asyncState.ts";
import { useEffect } from "react";
import { UnmanagedFilesApi } from "src/api/generated/documents/unmanagedFiles/unmanagedFilesApi.ts";
import { logError } from "src/errorHandling/errorLogging.ts";
import { VerticalBox } from "src/components/common/box/VerticalBox.tsx";
import { SelectField } from "src/components/common/inputFields/SelectField.tsx";
import {
	CONFIGURATION_PROPERTY_NULL_VALUE,
	configurationPropertyValueToString,
} from "src/components/views/erp/configurator/configuratorUtils.ts";
import { ConfigurationFieldSelectionOptions_Option } from "src/api/generated/io/aavo/applications/db/erp/types/configurationFieldSelectionOptions.ts";
import {
	ConfigurationPropertyValue,
	ConfigurationPropertyValue_StringValue,
} from "src/api/generated/io/aavo/applications/db/erp/types/configurationPropertyValue.ts";
import { AsyncRender } from "src/components/common/async/AsyncRender.tsx";
import i18n from "i18next";
import { ConfiguratorFieldComponentProps } from "src/components/views/erp/configurator/configuratorForm/components/field/ConfiguratorFieldComponent.tsx";
import {
	getConfiguratorFieldComponentErrorMessage,
	getConfiguratorFieldComponentLabelWithRequiredMark,
} from "src/components/views/erp/configurator/configuratorForm/components/field/configuratorFieldComponentUtils.ts";
import { useInputDialog } from "src/components/common/dialogs/input/useInputDialog.tsx";
import { faPen } from "@fortawesome/pro-regular-svg-icons";
import { AsyncButton } from "src/components/common/buttons/AsyncButton.tsx";
import { HorizontalBox } from "src/components/common/box/HorizontalBox.tsx";

export const ConfiguratorSelectField = (props: ConfiguratorFieldComponentProps) => {
	const { field, onSubmit } = props;

	const showInputDialog = useInputDialog();

	const [optionImageUrlAsync, setOptionImageUrlAsync, setOptionImageUrlImmediate] = useAsyncState<string | null>({
		defaultValue: null,
	});

	const imageUuid = field.currentValue.type === "string" ? field.currentValue.fileUuid : null;
	const currentFreeInputValue: ConfigurationPropertyValue_StringValue | null =
		field.currentValue.type === "string" && field.currentValue.isFreeInput ? field.currentValue : null;

	const mayHaveImage =
		field.valueInfoTextVisibility === "CONFIGURATOR_AND_PRINTOUT" &&
		field.selectionFieldOptions.some((option) => option.file != null);

	// TODO create some kind of caching mechanism for the image urls.
	useEffect(() => {
		if (imageUuid == null) {
			setOptionImageUrlImmediate(() => null);
		} else {
			setOptionImageUrlImmediate(() => null);
			setOptionImageUrlAsync(() =>
				UnmanagedFilesApi.getUnmanagedFilePresignedDownloadUrl({
					uuid: imageUuid,
				}),
			).catch(logError);
		}
	}, [imageUuid, setOptionImageUrlAsync, setOptionImageUrlImmediate]);

	return (
		<VerticalBox flex={1} gap={1}>
			<HorizontalBox flex={1} gap={1}>
				<SelectField
					label={getConfiguratorFieldComponentLabelWithRequiredMark(field)}
					disabled={!field.enabled}
					value={configurationPropertyValueToString(field.currentValue)}
					options={getOptionsWithFreeInput()}
					getOptionKey={(option) => option.key}
					getOptionLabel={(option) => option.label}
					disableClearable={field.required}
					sx={{
						flex: 1,
					}}
					error={getConfiguratorFieldComponentErrorMessage(field, field.currentValue)}
					onChange={(_, option: ConfigurationFieldSelectionOptions_Option | null) => onChange(option)}
				/>
				{currentFreeInputValue != null && (
					<AsyncButton icon={faPen} tooltip={i18n.t("edit")} onClick={() => askAndSubmitFreeInput()} />
				)}
			</HorizontalBox>
			{mayHaveImage && (
				<AsyncRender
					asyncData={optionImageUrlAsync}
					containerSx={{
						flexDirection: "row",
						alignItems: "flex-start",
						maxWidth: "120px",
						height: "120px",
					}}
					content={(imageUrl) => {
						if (imageUrl === null) return null;
						return (
							<img
								src={imageUrl}
								alt={i18n.t("image")}
								style={{
									objectFit: "contain",
									maxWidth: "100%",
								}}
							/>
						);
					}}
				/>
			)}
		</VerticalBox>
	);

	function getOptionsWithFreeInput(): ConfigurationFieldSelectionOptions_Option[] {
		if (!currentFreeInputValue) return field.selectionFieldOptions;

		const freeInputOption = field.selectionFieldOptions.find((option) => option.freeInput);
		if (!freeInputOption) return field.selectionFieldOptions;

		return [
			...field.selectionFieldOptions.filter((option) => !option.freeInput),
			{
				...freeInputOption,
				key: currentFreeInputValue.value,
				label: currentFreeInputValue.label,
			},
		];
	}

	async function onChange(option: ConfigurationFieldSelectionOptions_Option | null) {
		let newConfigurationPropertyValue: ConfigurationPropertyValue;

		if (option?.freeInput) {
			await askAndSubmitFreeInput();
		} else {
			newConfigurationPropertyValue =
				option == null ?
					CONFIGURATION_PROPERTY_NULL_VALUE
				:	{
						type: "string",
						value: option.key,
						label: option.label,
						// Keep the current info text for now. Backend will update it
						infoText: field.currentValue.infoText,
						fileUuid: option.file?.fileUuid,
						isFreeInput: false,
					};
			await onSubmit(newConfigurationPropertyValue);
		}
	}

	async function askAndSubmitFreeInput() {
		const freeInputOption = field.selectionFieldOptions.find((option) => option.freeInput);
		if (freeInputOption == null) {
			logError("Attempt to submit free input value, but no free input option found.");
			return;
		}

		const newFreeInputValue = await showInputDialog({
			type: "string",
			title: field.label,
			fieldLabel: freeInputOption.label,
			required: true,
			defaultValue: currentFreeInputValue?.value ?? "",
		});
		if (newFreeInputValue === undefined) return;

		const newConfigurationPropertyValue: ConfigurationPropertyValue_StringValue = {
			type: "string",
			value: newFreeInputValue,
			label: freeInputOption.label + ": " + newFreeInputValue,
			fileUuid: null,
			isFreeInput: true,
		};
		await onSubmit(newConfigurationPropertyValue);
	}
};
