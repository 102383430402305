// This file is automatically generated. Do not edit manually.

import { t } from "i18next";

export const ConfigurationComponentTypeValues = [
	"FIELD",
	"TEXT",
	"SECTION_BREAK",
	"SUB_CONFIGURATOR",
	"SUB_CONFIGURATOR_LIST",
	"TAB",
	"LIBRARY_COMPONENT_REFERENCE",
] as const;

export type ConfigurationComponentType = (typeof ConfigurationComponentTypeValues)[number];

export const getConfigurationComponentTypeLabels = () => ({
	FIELD: t("field"),
	TEXT: t("text"),
	SECTION_BREAK: t("section_break"),
	SUB_CONFIGURATOR: t("sub_configurator"),
	SUB_CONFIGURATOR_LIST: t("sub_configurator_list"),
	TAB: t("tab"),
	LIBRARY_COMPONENT_REFERENCE: t("library_component_reference"),
});

export const getConfigurationComponentTypeLabel = (value: ConfigurationComponentType): string => {
	return getConfigurationComponentTypeLabels()[value];
};
