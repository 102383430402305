import { dataGridPersistentStateProps } from "src/components/common/dataGrid/dataGridStateStorage";
import { enumColumn, textColumn } from "src/components/common/dataGrid/columns";
import i18n from "i18next";
import { SystemDiagramNode } from "src/api/generated/postgres/db/types/public_/tables/systemDiagramNode";
import { SystemDiagramNodeCrudApi } from "src/api/generated/systemDiagrams/management/systemDiagramNodeCrudApi";
import { CrudDataGrid } from "src/components/common/dataGrid/crud/CrudDataGrid";
import { AsyncFetchRender } from "src/components/common/async/AsyncFetchRender.tsx";
import { getSystemDiagramNodeShapeLabels } from "src/api/generated/postgres/db/types/public_/enums/systemDiagramNodeShape";
import { CenteredTypography } from "src/components/common/CenteredTypography";
import { SystemDiagramFunction } from "src/api/generated/systemDiagrams/functions/systemDiagramFunction";
import { SystemDiagramNodeForm } from "./SystemDiagramNodeForm";

export interface SystemDiagramNodesDataGridProps {
	systemDiagramId: number | null;
}

export const SystemDiagramNodesDataGrid = ({ systemDiagramId }: SystemDiagramNodesDataGridProps) => {
	if (systemDiagramId == null)
		return <CenteredTypography>{i18n.t("select_system_diagram")}</CenteredTypography>;

	return (
		<AsyncFetchRender
			fetch={() => SystemDiagramNodeCrudApi.getForSystemDiagram({ systemDiagramId })}
			content={(data, reload) => {
				return (
					<SystemDiagramsNodesDataGridInner
						systemDiagramId={systemDiagramId}
						nodes={data.nodes}
						functionOptions={data.functionOptions}
						reload={reload}
					/>
				);
			}}
		/>
	);
};

export interface SystemDiagramNodesDataGridInnerProps {
	systemDiagramId: number;
	nodes: SystemDiagramNode[];
	functionOptions: SystemDiagramFunction[];
	reload: () => Promise<unknown>;
}

export const SystemDiagramsNodesDataGridInner = ({
	systemDiagramId,
	nodes,
	functionOptions,
	reload,
}: SystemDiagramNodesDataGridInnerProps) => {
	return (
		<CrudDataGrid<SystemDiagramNode>
			rows={nodes}
			refreshData={reload}
			getRowId={(row) => row.systemDiagramNodeId}
			columns={[
				textColumn({
					field: "name",
					headerName: i18n.t("name"),
					width: 200,
				}),
				enumColumn({
					field: "shape",
					headerName: i18n.t("shape"),
					enumLabels: getSystemDiagramNodeShapeLabels(),
				}),
				textColumn({
					field: "functionId",
					headerName: i18n.t("function"),
					valueGetter: (_, row) =>
						functionOptions.find((f) => f.id === row.functionId)?.label ?? "",
				}),
			]}
			disableMultipleRowSelection={true}
			remove={{
				type: "enabled",
				action: async (params) =>
					await SystemDiagramNodeCrudApi.delete_({
						systemDiagramNodes: params.items,
					}),
			}}
			form={{
				dialogSize: "sm",
				dialogTitle: i18n.t("system_diagram_node"),
				addRowEnabled: true,
				editEnabled: true,
				component: ({ row, onCompleted, onFormEdited }) => {
					return (
						<SystemDiagramNodeForm
							systemDiagramId={systemDiagramId}
							systemDiagramNodeId={row?.systemDiagramNodeId}
							onCompleted={onCompleted}
							onFormEdited={onFormEdited}
						/>
					);
				},
			}}
			{...dataGridPersistentStateProps("A210F9B1338FFBA6")}
		/>
	);
};
