// This file is automatically generated. Do not edit manually.

import { t } from "i18next";

export const DeliveryPackageStateValues = ["INITIAL", "COMPLETED", "DELIVERED"] as const;

export type DeliveryPackageState = (typeof DeliveryPackageStateValues)[number];

export const getDeliveryPackageStateLabels = () => ({
	INITIAL: t("initial"),
	COMPLETED: t("completed"),
	DELIVERED: t("delivered"),
});

export const getDeliveryPackageStateLabel = (value: DeliveryPackageState): string => {
	return getDeliveryPackageStateLabels()[value];
};
