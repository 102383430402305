import { FormCommonProps } from "src/components/common/forms/types.ts";
import { ControlChartParentGroup } from "src/api/generated/postgres/db/types/public_/tables/controlChartParentGroup.ts";
import { ControlChartParentGroupEditApi } from "src/api/generated/spc/controlChart/api/controlChartParentGroupEditApi.ts";
import { FormTextField } from "src/components/common/forms/fields/FormTextField.tsx";
import i18n from "i18next";
import { requireRule } from "src/components/common/forms/validation.ts";
import { AsyncForm } from "src/components/common/forms/AsyncForm.tsx";

export interface ControlChartParentGroupFormProps extends FormCommonProps<number> {
	parentGroupId: number | undefined;
}

export const ControlChartParentGroupForm = (props: ControlChartParentGroupFormProps) => {
	const { onCompleted, onFormEdited, parentGroupId } = props;
	return (
		<AsyncForm
			fetch={() => ControlChartParentGroupEditApi.getFormInitData({ parentGroupId })}
			getDefaultValues={({ parentGroup }) => parentGroup ?? {}}
			submit={submit}
			onCompleted={onCompleted}
			onFormEdited={onFormEdited}
			render={({ control }) => (
				<>
					<FormTextField control={control} name={"name"} label={i18n.t("name")} rules={requireRule()} />
					<FormTextField control={control} name={"description"} label={i18n.t("description")} />
				</>
			)}
		/>
	);

	async function submit(values: ControlChartParentGroup) {
		if (values.id == null) {
			return ControlChartParentGroupEditApi.insert({ parentGroup: values });
		} else {
			return ControlChartParentGroupEditApi.update({ parentGroup: values });
		}
	}
};
