import { AavoForm } from "../../../../common/forms/AavoForm.tsx";
import { FormCommonProps } from "../../../../common/forms/types.ts";
import {
	CustomerOrderLineActionApi,
	CustomerOrderLineActionApi_CustomerOrderServiceLineDeliveryDto,
} from "src/api/generated/erp/sales/customerOrder/api/customerOrderLineActionApi.ts";
import { FormNumberField } from "../../../../common/forms/fields/FormNumberField.tsx";
import { CustomerOrderLineView } from "src/api/generated/erp/db/types/tables/customerOrderLineView.ts";
import { requiredNonNegativeFloatRule } from "../../../../common/forms/validation.ts";

export interface CustomerOrderServiceLineDeliveryFormProps extends FormCommonProps<void> {
	customerOrderLines: CustomerOrderLineView[];
	isRevert: boolean;
}

interface FormValues {
	serviceLineDeliveryDtos: CustomerOrderLineActionApi_CustomerOrderServiceLineDeliveryDto[];
}

export const CustomerOrderServiceLineDeliveryForm = ({
	onFormEdited,
	customerOrderLines,
	onCompleted,
	isRevert,
}: CustomerOrderServiceLineDeliveryFormProps) => {
	return (
		<AavoForm
			defaultValues={{
				serviceLineDeliveryDtos: customerOrderLines.map((customerOrderLine) => ({
					customerOrderLineId: customerOrderLine.customerOrderLineId,
					amount:
						isRevert ?
							customerOrderLine.deliveredQuantity
						:	customerOrderLine.salesQuantityAsWarehouseUnits -
							customerOrderLine.deliveredQuantity,
				})),
			}}
			submit={submitForm}
			columns={1}
			onCompleted={onCompleted}
			onFormEdited={onFormEdited}
			render={({ control }) => (
				<>
					{customerOrderLines.map((customerOrderLine, index) => (
						<FormNumberField
							key={index}
							control={control}
							name={`serviceLineDeliveryDtos.${index}.amount`}
							label={`${customerOrderLine.lineNumber} | ${customerOrderLine.salesPartNo} | ${customerOrderLine.salesPartDescription}`}
							rules={requiredNonNegativeFloatRule()}
						/>
					))}
				</>
			)}
		/>
	);

	async function submitForm(formValues: FormValues) {
		await CustomerOrderLineActionApi.changeCustomerOrderServiceLineDeliveredQuantity({
			customerOrderLineDtos:
				!isRevert ?
					formValues.serviceLineDeliveryDtos
				:	formValues.serviceLineDeliveryDtos.map((dto) => ({
						customerOrderLineId: dto.customerOrderLineId,
						amount: -dto.amount,
					})),
		});
	}
};
