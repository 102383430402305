// This file is automatically generated. Do not edit manually.

import { t } from "i18next";

export const PurchaseOrderLineStateValues = [
	"INITIAL",
	"RELEASED",
	"CONFIRMED",
	"PARTIALLY_RECEIVED",
	"RECEIVED",
	"CANCELLED",
] as const;

export type PurchaseOrderLineState = (typeof PurchaseOrderLineStateValues)[number];

export const getPurchaseOrderLineStateLabels = () => ({
	INITIAL: t("initial"),
	RELEASED: t("released"),
	CONFIRMED: t("confirmed"),
	PARTIALLY_RECEIVED: t("partially_received"),
	RECEIVED: t("received"),
	CANCELLED: t("cancelled"),
});

export const getPurchaseOrderLineStateLabel = (value: PurchaseOrderLineState): string => {
	return getPurchaseOrderLineStateLabels()[value];
};
