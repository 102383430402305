// This file is automatically generated. Do not edit manually.

import { SystemDiagramLink } from "src/api/generated/postgres/db/types/public_/tables/systemDiagramLink";
import { SystemDiagramNode } from "src/api/generated/postgres/db/types/public_/tables/systemDiagramNode";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace SystemDiagramLinkCrudApi {
	export async function getForSystemDiagram(args: {
		systemDiagramId: number;
	}): Promise<SystemDiagramLinkCrudApi_SystemDiagramLinkData> {
		const response = await makeAxiosRequest({
			url: "/api/ext/common/systemDiagrams/manage/links/getForSystemDiagram",
			method: "POST",
			data: {
				systemDiagramId: args.systemDiagramId,
			},
		});
		return response as SystemDiagramLinkCrudApi_SystemDiagramLinkData;
	}

	export async function delete_(args: { systemDiagramLinks: Array<SystemDiagramLink> }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/common/systemDiagrams/manage/links/delete",
			method: "POST",
			data: {
				systemDiagramLinks: args.systemDiagramLinks,
			},
		});
		return response as void;
	}

	export async function save(args: {
		systemDiagramLinks: Array<SystemDiagramLink>;
	}): Promise<Array<SystemDiagramLink>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/common/systemDiagrams/manage/links/save",
			method: "POST",
			data: {
				systemDiagramLinks: args.systemDiagramLinks,
			},
		});
		return response as Array<SystemDiagramLink>;
	}
}

export interface SystemDiagramLinkCrudApi_SystemDiagramLinkData {
	links: Array<SystemDiagramLink>;
	nodes: Array<SystemDiagramNode>;
}
