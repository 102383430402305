import React, { useMemo, useState } from "react";
import { FeatureChangeHintComponent } from "./FeatureChangeHintComponent";

export interface FeatureChangeHint {
	content: React.ReactNode;
	anchorEl: HTMLElement;
}

export interface FeatureChangeHintContextValue {
	showHint: (hint: FeatureChangeHint) => void;
	hideHint: () => void;
}

export const FeatureChangeHintContext = React.createContext<FeatureChangeHintContextValue | undefined>(
	undefined,
);

export const FeatureChangeHintContextProvider = ({ children }: React.PropsWithChildren) => {
	const [hint, setHint] = useState<FeatureChangeHint | undefined>(undefined);
	const contextValue = useMemo(
		() => ({
			showHint: (hint: FeatureChangeHint) => {
				setHint(hint);
			},
			hideHint: () => {
				setHint(undefined);
			},
		}),
		[setHint],
	);

	return (
		<FeatureChangeHintContext.Provider value={contextValue}>
			<FeatureChangeHintComponent hint={hint} hideHint={contextValue.hideHint} />
			{children}
		</FeatureChangeHintContext.Provider>
	);
};
