import { FutureInventoryEventsApi } from "src/api/generated/erp/warehouse/inventory/inventoryEvent/api/futureInventoryEventsApi.ts";
import { dateColumn, floatColumn, integerColumn, textColumn } from "src/components/common/dataGrid/columns.tsx";
import i18n from "i18next";
import { ClientSideDataGridModel } from "src/components/common/dataGrid/gridModel/ClientSideDataGridModel.tsx";
import { CrudDataGrid } from "src/components/common/dataGrid/crud/CrudDataGrid.tsx";
import { dataGridCellClassNames } from "src/components/common/dataGrid/styles/dataGridClassNames.ts";
import { OpenShopOrderButton } from "src/components/views/erp/utilComponents/OpenShopOrderButton.tsx";
import { OpenPurchaseRequestButton } from "src/components/views/erp/utilComponents/OpenPurchaseRequestButton.tsx";
import { OpenPurchaseOrderButton } from "src/components/views/erp/utilComponents/OpenPurchaseOrderButton.tsx";
import { OpenCustomerOrderButton } from "src/components/views/erp/utilComponents/OpenCustomerOrderButton.tsx";
import { OpenProjectActivityButton } from "../../utilComponents/OpenProjectActivityButton.tsx";
import { FutureInventoryEvent } from "../../../../../api/generated/erp/warehouse/inventory/inventoryEvent/futureInventoryEvent.ts";
import {
	FutureInventoryEventSource
} from "../../../../../api/generated/erp/warehouse/inventory/inventoryEvent/futureInventoryEventSource.ts";

export interface FutureInventoryEventsDataGridProps {
	partId: number;
}

export const FutureInventoryEventsDataGrid = ({ partId }: FutureInventoryEventsDataGridProps) => {
	return (
		<ClientSideDataGridModel
			gridId={"83EED8452AFD86E1"}
			initialParams={{}}
			fetchData={() => FutureInventoryEventsApi.getFutureInventoryEvents({ partId: partId })}
			getRows={(data) => data}
			getRowId={(row) => `${row.source.type}_${getSourceId(row)}`}
			render={({ dataGridProps }) => (
				<CrudDataGrid<FutureInventoryEvent>
					columns={[
						dateColumn({
							field: "eventDate",
							headerName: i18n.t("date"),
						}),
						textColumn({
							field: "source",
							headerName: i18n.t("source"),
							valueGetter: (_, { source }) => getSourceLabel(source),
							width: 130,
						}),
						floatColumn({
							field: "quantity",
							headerName: i18n.t("quantity"),
						}),
						floatColumn({
							field: "cumulativeInventoryLevel",
							headerName: i18n.t("stock_quantity"),
							cellClassName: (params) =>
								params.row.cumulativeInventoryLevel > 0 ?
									dataGridCellClassNames.textColorSuccess
								:	dataGridCellClassNames.textColorError,
						}),
						integerColumn({
							field: "sourceId" as any,
							headerName: i18n.t("id"),
							valueGetter: (_, row) => getSourceId(row),
						}),
					]}
					rowContextMenuComponents={({ row }) => {
						const source = row.source;
						return [
							source.type === "shopOrder" && (
								<OpenShopOrderButton key={"shopOrder"} shopOrderId={source.shopOrderId} />
							),
							source.type === "shopOrderLine" && (
								<OpenShopOrderButton key={"shopOrderLine"} shopOrderId={source.shopOrderId} />
							),
							source.type === "purchaseRequest" && (
								<OpenPurchaseRequestButton
									key={"purchaseRequest"}
									purchaseRequestId={source.purchaseRequestId}
								/>
							),
							source.type === "purchaseOrderLine" && (
								<OpenPurchaseOrderButton
									key={"purchaseOrderLine"}
									purchaseOrderId={source.purchaseOrderId}
								/>
							),
							source.type === "customerOrderLine" && (
								<OpenCustomerOrderButton
									key={"customerOrderLine"}
									customerOrderId={source.customerOrderId}
								/>
							),
							source.type === "projectActivityMaterial" && (
								<OpenProjectActivityButton
									key={"projectActivity"}
									projectActivityId={source.projectActivityId}
								/>
							),
						];
					}}
					{...dataGridProps}
				/>
			)}
		/>
	);

	function getSourceLabel(source: FutureInventoryEventSource): string {
		switch (source.type) {
			case "shopOrder":
				return i18n.t("shop_order");
			case "shopOrderLine":
				return i18n.t("shop_order_line");
			case "purchaseRequest":
				return i18n.t("purchase_request");
			case "purchaseOrderLine":
				return i18n.t("purchase_order_line");
			case "customerOrderLine":
				return i18n.t("customer_order_line");
			case "projectActivityMaterial":
				return i18n.t("project_activity_material");
		}
	}

	function getSourceId(row: FutureInventoryEvent): number {
		const source = row.source;
		switch (source.type) {
			case "shopOrder":
				return source.shopOrderId;
			case "shopOrderLine":
				return source.shopOrderLineId;
			case "purchaseRequest":
				return source.purchaseRequestId;
			case "purchaseOrderLine":
				return source.purchaseOrderLineId;
			case "customerOrderLine":
				return source.customerOrderLineId;
			case "projectActivityMaterial":
				return source.projectActivityMaterialId;
		}
	}
};
