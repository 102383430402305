// This file is automatically generated. Do not edit manually.

import { ProjectView } from "src/api/generated/erp/db/types/tables/projectView";
import { Project } from "src/api/generated/erp/db/types/tables/project";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace ProjectQueryApi {
	export async function getProject(args: { projectId: number }): Promise<ProjectView> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/project/getProject",
			method: "POST",
			data: {
				projectId: args.projectId,
			},
		});
		return response as ProjectView;
	}

	export async function getProjectSelectionOptions(args: {
		searchQuery?: string;
		currentSelection: number | null | undefined;
		siteId?: number | null | undefined;
		includeTemplates?: boolean;
		excludeClosedProjects?: boolean;
	}): Promise<Array<Project>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/project/getProjectSelectionOptions",
			method: "POST",
			data: {
				searchQuery: args.searchQuery,
				currentSelection: args.currentSelection,
				siteId: args.siteId,
				includeTemplates: args.includeTemplates,
				excludeClosedProjects: args.excludeClosedProjects,
			},
		});
		return response as Array<Project>;
	}
}
