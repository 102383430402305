// This file is automatically generated. Do not edit manually.

import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace PushNotificationApi {
	export async function sendNotificationToUsers(args: {
		message: string;
		receiverAppUserIds: Array<number>;
	}): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/common/notifications/sendNotificationToUsers",
			method: "POST",
			data: {
				message: args.message,
				receiverAppUserIds: args.receiverAppUserIds,
			},
		});
		return response as void;
	}
}
