import {
	DocumentConnectionApi,
	DocumentConnectionApi_DocumentConnectionDto
} from "src/api/generated/documents/api/documentConnectionApi";
import { dataGridPersistentStateProps } from "src/components/common/dataGrid/dataGridStateStorage.ts";
import { enumColumn, integerColumn, textColumn } from "src/components/common/dataGrid/columns.tsx";
import i18n from "i18next";
import { AsyncCrudDataGrid } from "src/components/common/dataGrid/crud/AsyncCrudDataGrid.tsx";
import { getAavoObjectTypeLabels } from "src/api/generated/common/sourceType/aavoObjectType.ts";

export interface DocumentConnectionsDataGridProps {
	documentId: number;
}

export const DocumentConnectionsDataGrid = (
	{
		documentId
	}: DocumentConnectionsDataGridProps) => {

	return <AsyncCrudDataGrid<DocumentConnectionApi_DocumentConnectionDto>
		fetchData={() => DocumentConnectionApi.getDocumentConnections({ documentId })}
		getRowId={row => row.documentConnectionId}
		columns={[
			enumColumn({
				field: "objectType",
				headerName: i18n.t("object_type"),
				enumLabels: getAavoObjectTypeLabels(),
				width: 200
			}),
			textColumn({
				field: "objectDescription",
				headerName: i18n.t("object_description"),
				width: 350
			}),
			integerColumn({
				field: "objectId",
				headerName: i18n.t("object_id")
			})
		]}
		remove={{
			type: "enabled",
			action: async ({ items }) => {
				await DocumentConnectionApi.deleteDocumentConnections({
					documentConnections: items
				});
			}
		}}
		{...dataGridPersistentStateProps("243DD792791C4662")}
	/>;
};