import { ClientSideDataGridModel } from "src/components/common/dataGrid/gridModel/ClientSideDataGridModel.tsx";
import { SurveyCategoryApi } from "src/api/generated/erp/surveys/api/surveyCategoryApi.ts";
import i18n from "i18next";
import { CrudDataGrid } from "src/components/common/dataGrid/crud/CrudDataGrid.tsx";
import { SurveyCategoryForm } from "src/components/views/erp/surveys/management/categories/SurveyCategoryForm.tsx";
import { integerColumn, textColumn } from "src/components/common/dataGrid/columns.tsx";
import { SurveyCategory } from "src/api/generated/erp/db/types/tables/surveyCategory.ts";

export const SurveyCategoriesDataGrid = () => {
	return (
		<ClientSideDataGridModel
			fetchData={() => SurveyCategoryApi.getSurveyCategories()}
			initialParams={{}}
			gridId="D7E1F081F012772A"
			getRows={(response) => response}
			getRowId={(row) => row.surveyCategoryId}
			render={({ dataGridProps }) => (
				<CrudDataGrid<SurveyCategory>
					columns={[
						textColumn({
							field: "name",
							headerName: i18n.t("name"),
							width: 250,
						}),
						integerColumn({
							field: "surveyCategoryId",
							headerName: i18n.t("id"),
							width: 80,
						}),
					]}
					form={{
						addRowEnabled: true,
						editEnabled: true,
						dialogSize: "sm",
						dialogTitle: i18n.t("survey_category"),
						component: ({ row, onCompleted, onFormEdited }) => (
							<SurveyCategoryForm
								surveyCategory={row}
								onCompleted={onCompleted}
								onFormEdited={onFormEdited}
							/>
						),
					}}
					remove={({ row }) =>
						SurveyCategoryApi.delete_({
							surveyCategoryId: row.surveyCategoryId,
						})
					}
					{...dataGridProps}
				/>
			)}
		/>
	);
};
