import {
    PartWarehouseLocationEditApi,
    PartWarehouseLocationEditApi_FormInitData,
} from "src/api/generated/erp/parts/partWarehouseLocations/api/partWarehouseLocationEditApi";
import {AsyncForm} from "src/components/common/forms/AsyncForm.tsx";
import {FormAsyncSelectField} from "src/components/common/forms/fields/FormAsyncSelectField";
import i18n from "i18next";
import {FormCommonProps} from "src/components/common/forms/types.ts";
import {WarehouseLocation} from "src/api/generated/erp/db/types/tables/warehouseLocation.ts";
import {PartWarehouseLocation} from "src/api/generated/erp/db/types/tables/partWarehouseLocation.ts";
import {requireRule} from "src/components/common/forms/validation.ts";
import {FormNumberField} from "src/components/common/forms/fields/FormNumberField.tsx";
import {DefaultValues} from "react-hook-form";
import {FormEnumSelectField} from "src/components/common/forms/fields/FormEnumSelectField.tsx";
import {FormSelectField} from "src/components/common/forms/fields/FormSelectField.tsx";
import {distinctBy} from "src/utils/arrayUtils.ts";
import {FormCheckbox} from "src/components/common/forms/fields/FormCheckbox.tsx";
import {
    getPartWarehouseLocationReplenishmentTriggerLabels
} from "src/api/generated/erp/db/types/enums/partWarehouseLocationReplenishmentTrigger.ts";
import {dropProps} from "src/utils/dropProps.ts";
import {
    getWarehouseReplenishmentMethodLabel,
} from "src/api/generated/erp/db/types/enums/warehouseReplenishmentMethod.ts";
import {useTenantCustomizations} from "src/tenantCustomizations/useTenantCustomizations.tsx";
import {
    getReplenishmentMethodOptions
} from "src/components/views/erp/parts/siteParts/partWarehouseLocations/GetReplenishmentMethodOptions.tsx";

export interface PartWarehouseLocationFormProps extends FormCommonProps<number> {
	partId: number;
	partWarehouseLocationId: number | undefined;
}

export const PartWarehouseLocationForm = ({
	partId,
	partWarehouseLocationId,
	onCompleted,
	onFormEdited,
}: PartWarehouseLocationFormProps) => {
	const { tenantConfig } = useTenantCustomizations();
	const warehouseReplenishmentMethodOptions = getReplenishmentMethodOptions(tenantConfig);
	return (
		<AsyncForm<PartWarehouseLocationEditApi_FormInitData, PartWarehouseLocation, number>
			fetch={() =>
				PartWarehouseLocationEditApi.getFormInitData({
					partId: partId,
					partWarehouseLocationId: partWarehouseLocationId,
				})
			}
			getDefaultValues={getDefaultValues}
			submit={submitForm}
			onCompleted={onCompleted}
			onFormEdited={onFormEdited}
			columns={2}
			render={({ data: { givingWarehouseLocationOptions }, control, watch, setValue }) => {
				const givingSiteOptions = distinctBy(givingWarehouseLocationOptions, (x) => x.siteId).map((x) => ({
					siteId: x.siteId,
					siteName: x.siteName,
				}));

				const replenishmentMethod = watch("replenishmentMethod");
				const givingSiteId = watch("givingSiteId");

				return (
					<>
						<FormAsyncSelectField
							control={control}
							name={"warehouseLocationId"}
							label={i18n.t("warehouse_location")}
							getOptionKey={(option: WarehouseLocation) => option.warehouseLocationId}
							getOptionLabel={(option) => option.locationCode}
							fetchOptions={async ({ searchQuery, currentSelection }) =>
								PartWarehouseLocationEditApi.getWarehouseLocationOptions({
									partId: partId,
									partWarehouseLocationId: partWarehouseLocationId,
									searchQuery: searchQuery,
									currentSelection: currentSelection,
								})
							}
							rules={requireRule()}
						/>
						<FormSelectField
							control={control}
							name={"replenishmentMethod"}
							label={i18n.t("replenishment_method")}
							options={warehouseReplenishmentMethodOptions}
							getOptionKey={(option) => option}
							getOptionLabel={(option) => getWarehouseReplenishmentMethodLabel(option)}
							disableClearable={false}
						/>
						<FormNumberField
							control={control}
							name={"locationQuantity"}
							label={i18n.t("quantity")}
							rules={requireRule()}
						/>
						{replenishmentMethod != null && (
							<FormNumberField
								control={control}
								name={"defaultReplenishmentQuantity"}
								label={i18n.t("default_replenishment_quantity")}
							/>
						)}
						{replenishmentMethod === "WAREHOUSE_TRANSFER" && (
							<>
								<FormSelectField
									control={control}
									name={"givingSiteId"}
									label={i18n.t("giving_site")}
									options={givingSiteOptions}
									getOptionKey={(option) => option.siteId}
									getOptionLabel={(option) => option.siteName}
									onChange={() => {
										setValue("givingPartWarehouseLocationId", null);
									}}
									startNewGridRow
									rules={requireRule()}
								/>
								<FormSelectField
									control={control}
									name={"givingPartWarehouseLocationId"}
									label={i18n.t("giving_warehouse_location")}
									options={givingWarehouseLocationOptions.filter((x) => x.siteId === givingSiteId)}
									getOptionKey={(option) => option.partWarehouseLocationId}
									getOptionLabel={(option) => option.locationCode}
									rules={requireRule()}
								/>
							</>
						)}
						{replenishmentMethod != null && (
							<FormEnumSelectField
								control={control}
								name={"replenishmentTrigger"}
								label={i18n.t("replenish_at")}
								options={dropProps(
									getPartWarehouseLocationReplenishmentTriggerLabels(),
									replenishmentMethod !== "SHOP_ORDER" ? ["BEFORE_TRANSFER_OUT"] : [],
								)}
								disableClearable
								rules={requireRule()}
							/>
						)}
						<FormCheckbox
							control={control}
							name={"isDefault"}
							label={i18n.t("part_default_warehouse_location")}
							startNewGridRow
						/>
						<FormCheckbox
							control={control}
							name={"inventoryLevelEnabled"}
							label={i18n.t("inventory_level_enabled")}
							startNewGridRow
						/>
						{replenishmentMethod != null && (
							<>
								<FormCheckbox
									control={control}
									name={"askTransferInfo"}
									label={i18n.t("ask_supply_request_info")}
									startNewGridRow
								/>
								<FormCheckbox
									control={control}
									name={"askQuantity"}
									label={i18n.t("ask_quantity")}
									startNewGridRow
								/>
							</>
						)}
					</>
				);
			}}
		/>
	);

	function getDefaultValues({
		partWarehouseLocation,
		inventoryLevelEnabledByDefault,
		partHasOtherWarehouseLocations,
		siteId,
	}: PartWarehouseLocationEditApi_FormInitData): DefaultValues<PartWarehouseLocation> {
		if (partWarehouseLocation != null) return partWarehouseLocation;
		else
			return {
				partId: partId,
				locationQuantity: 1,
				defaultReplenishmentQuantity: 1,
				isDefault: !partHasOtherWarehouseLocations,
				askTransferInfo: false,
				askQuantity: false,
				inventoryLevelEnabled: inventoryLevelEnabledByDefault,
				givingSiteId: siteId,
				replenishmentMethod: "WAREHOUSE_TRANSFER",
				replenishmentTrigger: "MANUAL",
			};
	}

	async function submitForm(values: PartWarehouseLocation) {
		if (partWarehouseLocationId == null) {
			return await PartWarehouseLocationEditApi.insert({
				partWarehouseLocation: values,
			});
		} else {
			await PartWarehouseLocationEditApi.update({
				partWarehouseLocation: values,
			});
			return partWarehouseLocationId;
		}
	}
};
