// This file is automatically generated. Do not edit manually.

import { ControlChart } from "src/api/generated/postgres/db/types/public_/tables/controlChart";
import { ControlChartParentGroup } from "src/api/generated/postgres/db/types/public_/tables/controlChartParentGroup";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace ControlChartCommonApi {
	export async function getControlChart(args: { controlChartId: number }): Promise<ControlChart> {
		const response = await makeAxiosRequest({
			url: "/api/ext/spc/controlCharts/getControlChart",
			method: "POST",
			data: {
				controlChartId: args.controlChartId,
			},
		});
		return response as ControlChart;
	}

	export async function getAllChartParentGroups(): Promise<Array<ControlChartParentGroup>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/spc/controlCharts/getAllChartParentGroups",
			method: "POST",
			data: {},
		});
		return response as Array<ControlChartParentGroup>;
	}

	export async function disableRecord(args: { recordId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/spc/controlCharts/disableRecord",
			method: "POST",
			data: {
				recordId: args.recordId,
			},
		});
		return response as void;
	}
}
