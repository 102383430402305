// This file is automatically generated. Do not edit manually.

import { CustomerOrderSalesCondition } from "src/api/generated/erp/db/types/tables/customerOrderSalesCondition";
import { SalesCondition } from "src/api/generated/erp/db/types/tables/salesCondition";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace CustomerOrderSalesConditionFormApi {
	export async function getInitData(args: {
		customerOrderId: number;
		customerOrderSalesConditionId: number | null | undefined;
	}): Promise<CustomerOrderSalesConditionFormApi_InitData> {
		const response = await makeAxiosRequest({
			url: "/api/ext/customerOrderSalesConditionForm/getInitData",
			method: "POST",
			data: {
				customerOrderId: args.customerOrderId,
				customerOrderSalesConditionId: args.customerOrderSalesConditionId,
			},
		});
		return response as CustomerOrderSalesConditionFormApi_InitData;
	}

	export async function save(args: { condition: CustomerOrderSalesCondition }): Promise<number> {
		const response = await makeAxiosRequest({
			url: "/api/ext/customerOrderSalesConditionForm/save",
			method: "POST",
			data: {
				condition: args.condition,
			},
		});
		return response as number;
	}
}

export interface CustomerOrderSalesConditionFormApi_InitData {
	condition: CustomerOrderSalesCondition | null | undefined;
	conditionOptions: Array<SalesCondition>;
	customerOrderId: number;
	defaultResponsiblePersonId: number;
}
