// This file is automatically generated. Do not edit manually.

import { ShopOrderOperationView } from "src/api/generated/erp/db/types/tables/shopOrderOperationView";
import { ShopOrderLineView } from "src/api/generated/erp/db/types/tables/shopOrderLineView";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace ShopOrderBomReservationDataGridApi {
	export async function getShopOrderReservableBom(args: {
		shopOrderId: number;
		shopOrderOperationId: number | null | undefined;
	}): Promise<ShopOrderBomReservationDataGridApi_DataGridData> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/shopOrderBomReservationDataGrid/getShopOrderReservableBom",
			method: "POST",
			data: {
				shopOrderId: args.shopOrderId,
				shopOrderOperationId: args.shopOrderOperationId,
			},
		});
		return response as ShopOrderBomReservationDataGridApi_DataGridData;
	}
}

export interface ShopOrderBomReservationDataGridApi_DataGridData {
	operationOptions: Array<ShopOrderOperationView>;
	shopOrderLines: Array<ShopOrderLineView>;
}
