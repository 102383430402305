// This file is automatically generated. Do not edit manually.

import { ControlChartParentGroup } from "src/api/generated/postgres/db/types/public_/tables/controlChartParentGroup";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace UserControlChartParentGroupsApi {
	export async function getUserControlChartParentGroups(): Promise<Array<ControlChartParentGroup>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/spc/controlCharts/getUserControlChartParentGroups",
			method: "POST",
			data: {},
		});
		return response as Array<ControlChartParentGroup>;
	}
}
