import i18n from "i18next";
import { ShopOrderApi } from "src/api/generated/erp/production/api/shopOrder/shopOrderApi.ts";
import { ShopOrderActionApi } from "src/api/generated/erp/production/api/shopOrder/shopOrderActionApi.ts";
import { ShowConfirmDialogFunc } from "src/components/common/dialogs/confirmDialog/ConfirmDialogProvider.tsx";

export async function releaseShopOrder({
	shopOrderId,
	showConfirmDialog,
}: {
	shopOrderId: number;
	showConfirmDialog: ShowConfirmDialogFunc;
}) {
	const releaseConfirmed = await showConfirmDialog({
		title: i18n.t("release"),
		message: i18n.t("confirm_release_shop_order"),
	});
	if (!releaseConfirmed) return;

	const hasOperations = await ShopOrderApi.shopOrderHasAnyOperations({
		shopOrderId: shopOrderId,
	});
	if (!hasOperations) {
		const confirmed = await showConfirmDialog({
			title: i18n.t("are_you_sure"),
			message: i18n.t("shop_order_has_no_operations"),
		});
		if (!confirmed) {
			return;
		}
	}
	await ShopOrderActionApi.releaseShopOrders({
		shopOrderIds: [shopOrderId],
	});
}
