// This file is automatically generated. Do not edit manually.

import { AccountingDimension } from "src/api/generated/erp/db/types/tables/accountingDimension";
import { ObjectAccountingCodeView } from "src/api/generated/erp/db/types/tables/objectAccountingCodeView";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace InvoiceLinesDataGridApi {
	export async function getDataGridData(args: { invoiceId: number }): Promise<InvoiceLinesDataGridApi_DataGridData> {
		const response = await makeAxiosRequest({
			url: "/api/ext/invoicing/invoiceLinesDataGrid/getDataGridData",
			method: "POST",
			data: {
				invoiceId: args.invoiceId,
			},
		});
		return response as InvoiceLinesDataGridApi_DataGridData;
	}
}

export interface InvoiceLinesDataGridApi_DataGridData {
	accountingDimensions: Array<AccountingDimension>;
	invoiceLines: Array<InvoiceLinesDataGridApi_InvoiceLineDto>;
}

export interface InvoiceLinesDataGridApi_InvoiceLineDto {
	accountingCodes: Array<ObjectAccountingCodeView>;
	billingPlanLineId: number | null | undefined;
	customerOrderLineId: number | null | undefined;
	discountFactor: number;
	discountPercent: number;
	invoiceId: number;
	invoiceLineId: number;
	mark: string;
	priceInputWithVat: boolean;
	priceUnit: string;
	priceUnitFactor: number;
	quantityInBaseUnits: number;
	quantityInPriceUnits: number;
	quantityInSalesUnits: number;
	salesPart: string;
	salesPartId: number | null | undefined;
	salesUnit: string;
	salesUnitFactor: number;
	totalPrice: number;
	totalPriceWithVat: number;
	totalVat: number;
	unitPrice: number;
	unitPriceWithVat: number;
	vatPercent: number;
	vatPercentFactor: number;
}
