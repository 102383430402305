import { AavoDialogSize } from "../../common/dialogs/AavoDialog";
import i18n from "i18next";
import { View, ViewAssociation } from "./types";
import { ViewActionEvent } from "./events";
import { StartUpData } from "src/api/mainApi";
import { primitiveToString } from "src/types/primitive.ts";
import { KeyValuePair } from "src/utils/keyValuePair.ts";

const AAVO_STATIC_PARAM_MODAL_SIZE_KEY = "__MODAL_SIZE__";

export const getModalViewSize = (view: View): AavoDialogSize => {
	const sizePropObject = view.staticParams.find((param) => {
		return param.key === AAVO_STATIC_PARAM_MODAL_SIZE_KEY;
	});
	return sizePropObject
		? ((sizePropObject.value as string).toLowerCase() as AavoDialogSize)
		: "fullscreen";
};

const DYNAMIC_VIEW_DEFINITION_PARAM = "__DYNAMIC_VIEW_DEFINITION__";

export const getModalViewParamsFromEvent = (e: ViewActionEvent): KeyValuePair[] => {
	return e.allParams.filter((param) => {
		return param.key !== DYNAMIC_VIEW_DEFINITION_PARAM;
	});
};

export const getViewOrErrorFromStartUpData = (
	viewId: string,
	startUpData?: StartUpData
): ViewAssociation => {
	return startUpData?.views[viewId] ?? getErrorViewDefinition(viewId);
};

export const getDynamicViewDefinitionFromEvent = (e: ViewActionEvent): ViewAssociation => {
	const dynamicViewParam = e.allParams.find((param) => {
		return param.key === DYNAMIC_VIEW_DEFINITION_PARAM;
	});
	if (!dynamicViewParam) {
		throw Error(
			`OPEN_DYNAMIC_VIEW event did not contain ${DYNAMIC_VIEW_DEFINITION_PARAM} parameter`
		);
	}
	return JSON.parse(primitiveToString(dynamicViewParam.value));
};
const ERROR_VIEW_ID = "error_view";

export const getErrorViewDefinition = (viewId: string): ViewAssociation => {
	return {
		showDirectly: false,
		view: {
			id: ERROR_VIEW_ID,
			displayName: i18n.t("error"),
			groupId: null,
			icon: "home",
			pos: [],
			staticParams: [{ key: "viewId", value: viewId }]
		}
	};
};

export const isErrorViewDefinition = (v: ViewAssociation): boolean => {
	return v.view.id === ERROR_VIEW_ID;
};

export const getErrorViewIdFromParams = (v: ViewAssociation): string => {
	const errorViewId = v.view.staticParams.find((param) => {
		return param.key === "viewId";
	})?.value;
	return errorViewId ? primitiveToString(errorViewId) : "undefined";
};
