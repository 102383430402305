import { useClientSideDataGridModel } from "src/components/common/dataGrid/gridModel/useClientSideDataGridModel.tsx";
import { SalesCommissionPaymentView } from "src/api/generated/erp/db/types/tables/salesCommissionPaymentView.ts";
import { ControlledAsyncDataGrid } from "src/components/common/dataGrid/ControlledAsyncDataGrid.tsx";
import i18n from "i18next";
import { faPlus } from "@fortawesome/pro-regular-svg-icons";
import { AsyncButton } from "src/components/common/buttons/AsyncButton.tsx";
import { AddLinesToSalesCommissionPaymentViewApi } from "src/api/generated/erp/sales/salesCommissions/api/addLinesToSalesCommissionPaymentViewApi.ts";
import { SalesCommissionPaymentApi } from "src/api/generated/erp/sales/salesCommissions/api/salesCommissionPaymentApi.ts";
import { getSalesCommissionLinesDataGridColumns } from "src/components/views/erp/sales/salesCommissions/salesCommissionUtils.ts";
import { OpenCustomerOrderButton } from "src/components/views/erp/utilComponents/OpenCustomerOrderButton.tsx";

export interface AddLinesToSalesCommissionPaymentViewProps {
	payment: SalesCommissionPaymentView;
	closeDialog: () => Promise<unknown>;
}

export const AddLinesToSalesCommissionPaymentView = ({
	payment,
	closeDialog,
}: AddLinesToSalesCommissionPaymentViewProps) => {
	const { dataGridProps, selectedRows } = useClientSideDataGridModel({
		fetchData: () =>
			AddLinesToSalesCommissionPaymentViewApi.getSalespersonApprovedCommissionLines({
				salespersonId: payment.salespersonId,
			}),
		initialParams: {},
		getRowId: (row) => row.salesCommissionLineId,
		gridId: "CD604615B3E1DE21",
	});

	return (
		<ControlledAsyncDataGrid
			checkboxSelection
			columns={getSalesCommissionLinesDataGridColumns({
				showState: false,
				showSalesperson: false,
				showEstimatedApprovalDate: false,
				showPaymentColumns: false,
			})}
			actionBarComponents={[
				<AsyncButton
					label={i18n.t("add_selected")}
					icon={faPlus}
					disabled={selectedRows.length === 0}
					variant={"outlined"}
					onClick={async () => {
						await SalesCommissionPaymentApi.addCommissionLinesToPayment({
							salesCommissionPaymentId: payment.salesCommissionPaymentId,
							commissionLineIds: selectedRows.map((row) => row.salesCommissionLineId),
						});
						await closeDialog();
					}}
				/>,
			]}
			rowContextMenuComponents={({ row }) => [
				<OpenCustomerOrderButton key={"openCustomerOrder"} customerOrderId={row.customerOrderId} />,
			]}
			{...dataGridProps}
		/>
	);
};
