import {
    MellanoTransactionStatusViewApi,
    MellanoTransactionStatusViewApi_MellanoComponentBatchContent,
} from "../../../../api/generated/tenants/mellano/api/mellanoTransactionStatusViewApi.ts";
import {textColumn} from "../../../../components/common/dataGrid/columns.tsx";
import {dataGridPersistentStateProps} from "../../../../components/common/dataGrid/dataGridStateStorage.ts";
import {AsyncDataGrid} from "../../../../components/common/dataGrid/AsyncDataGrid.tsx";

export interface ComponentBatchContentViewProps {
	transactionId: number;
	workOrderCode: string;
}

export const ComponentBatchContentView = ({
	transactionId,
	workOrderCode,
}: ComponentBatchContentViewProps) => {
	return (
		<AsyncDataGrid<MellanoTransactionStatusViewApi_MellanoComponentBatchContent>
			getRowId={(row) => row.componentId}
			fetchRows={() =>
				MellanoTransactionStatusViewApi.getComponentBatchContent({
					transactionId: transactionId,
					workOrderCode: workOrderCode,
				})
			}
			columns={[
				textColumn({
					field: "componentId",
					headerName: "ID",
				}),
				textColumn({
					field: "componentCode",
					headerName: "Koodi",
					width: 70,
				}),
				textColumn({
					field: "productionInfo",
					headerName: "Info",
					width: 250,
				}),
				textColumn({
					field: "dimensionX",
					headerName: "X",
					width: 50,
				}),
				textColumn({
					field: "dimensionY",
					headerName: "Y",
					width: 50,
				}),
				textColumn({
					field: "dimensionZ",
					headerName: "Z",
					width: 50,
				}),
				textColumn({
					field: "color",
					headerName: "Väri",
					width: 300,
				}),
				textColumn({
					field: "packageId",
					headerName: "Paketti id",
				}),
				textColumn({
					field: "workOrderCode",
					headerName: "Työtilaus koodi",
					width: 150,
				}),
				textColumn({
					field: "workOrderRowNumber",
					headerName: "Työtilaus rivinumero",
					width: 150,
				}),
			]}
			{...dataGridPersistentStateProps("E2001DB73065842D")}
		/>
	);
};
