import { TransformedConfigurationComponent_Field } from "src/api/generated/erp/configurator/componentTransformation/model/transformedConfigurationComponent.ts";
import { ConfigurationPropertyValue } from "src/api/generated/io/aavo/applications/db/erp/types/configurationPropertyValue.ts";
import { HorizontalBox } from "src/components/common/box/HorizontalBox.tsx";
import { ComponentDocumentButton } from "src/components/views/erp/configurator/configuratorForm/componentDocument/ComponentDocumentButton.tsx";
import { configuratorComponentIndentToMargin } from "src/components/views/erp/configurator/configuratorUtils.ts";
import { VerticalBox } from "src/components/common/box/VerticalBox.tsx";
import { RawHtmlContent } from "src/components/common/RawHtmlContent.tsx";
import { ConfiguratorCheckboxField } from "src/components/views/erp/configurator/configuratorForm/components/field/impl/ConfiguratorCheckboxField.tsx";
import { ConfiguratorDocumentField } from "src/components/views/erp/configurator/configuratorForm/components/field/impl/ConfiguratorDocumentField.tsx";
import {
	ConfiguratorSelectField
} from "src/components/views/erp/configurator/configuratorForm/components/field/impl/ConfiguratorSelectField.tsx";
import {
	ConfiguratorTextField
} from "src/components/views/erp/configurator/configuratorForm/components/field/impl/ConfiguratorTextField.tsx";

export interface ConfiguratorFieldComponentProps {
	field: TransformedConfigurationComponent_Field;
	onChange: (value: ConfigurationPropertyValue) => void;
	onSubmit: (value: ConfigurationPropertyValue) => Promise<unknown>;
}

export const ConfiguratorFieldComponent = (props: ConfiguratorFieldComponentProps) => {
	const { field } = props;

	return (
		<VerticalBox
			sx={{
				marginLeft: configuratorComponentIndentToMargin(field.layout.indent),
			}}
		>
			<HorizontalBox
				sx={{
					gap: 1,
					alignItems: "center",
				}}
			>
				<Field {...props} />
				<ComponentDocumentButton componentId={field.configurationComponentId} />
			</HorizontalBox>
			<FieldValueInfoText {...props} />
		</VerticalBox>
	);
};

const Field = (props: ConfiguratorFieldComponentProps) => {
	const { field } = props;

	switch (field.fieldType) {
		case "TEXT":
			return <ConfiguratorTextField {...props} />;
		case "TEXTAREA":
			return <ConfiguratorTextField {...props} multiline minRows={2} />;
		case "INTEGER":
		case "DECIMAL":
			return <ConfiguratorTextField {...props} type={"number"} />; // TODO
		case "CHECKBOX":
			return <ConfiguratorCheckboxField {...props} />;
		case "SELECTION":
			return <ConfiguratorSelectField {...props} />;
		case "DOCUMENT":
			return <ConfiguratorDocumentField {...props} />;
	}
};

const FieldValueInfoText = ({ field }: ConfiguratorFieldComponentProps) => {
	const infoText = field.currentValue.infoText;
	if (infoText == null || infoText.trim() == "") return null;
	if (field.valueInfoTextVisibility === "PRINTOUT") return null;

	return (
		<RawHtmlContent
			sx={{
				marginTop: "0.5rem",
			}}
			html={infoText}
		/>
	);
};
