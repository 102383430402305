import { AavoCheckbox } from "src/components/common/inputFields/AavoCheckbox.tsx";
import { labelWithRequiredMark } from "src/components/views/erp/surveys/surveyUtils.ts";
import { SurveySubmissionFormFieldProps } from "src/components/views/erp/surveys/submissions/fields/SurveySubmissionFormField.tsx";

export const SurveySubmissionFormCheckboxField = ({
	field,
	value,
	onChange,
	disabled,
	error
}: SurveySubmissionFormFieldProps) => {
	return (
		<AavoCheckbox
			label={labelWithRequiredMark(field)}
			checked={value?.type === "boolean" ? value.value : false}
			disabled={disabled}
			onChange={(value) =>
				onChange({
					type: "boolean",
					value: value,
				})
			}
			error={error}
		/>
	);
};
