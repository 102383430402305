import { AavoGridColDef } from "src/components/common/dataGrid/AavoDataGrid.tsx";
import {
	dateColumn,
	dateTimeColumn,
	enumColumn,
	floatColumn,
	textColumn,
} from "src/components/common/dataGrid/columns.tsx";
import i18n from "i18next";
import { SalesCommissionLineView } from "src/api/generated/erp/db/types/tables/salesCommissionLineView.ts";
import { getSalesCommissionLineStateLabels } from "src/api/generated/io/aavo/applications/db/erp/types/salesCommissionLineState.ts";

export const getSalesCommissionLinesDataGridColumns = ({
	showSalesperson = true,
	showState = true,
	showEstimatedApprovalDate = true,
	showApprovalColumns = true,
	showPaymentColumns = true,
	showCustomerOrderColumns = true,
}: {
	showSalesperson?: boolean;
	showState?: boolean;
	showEstimatedApprovalDate?: boolean;
	showApprovalColumns?: boolean;
	showPaymentColumns?: boolean;
	showCustomerOrderColumns?: boolean;
}): AavoGridColDef<SalesCommissionLineView>[] => [
	showSalesperson &&
		textColumn({
			field: "salespersonName",
			headerName: i18n.t("salesperson"),
			width: 150,
		}),
	showCustomerOrderColumns && [
		textColumn({
			field: "customerOrderId",
			headerName: i18n.t("customer_order"),
		}),
		textColumn({
			field: "orderReference",
			headerName: i18n.t("order_reference"),
			width: 120,
		}),
	],
	textColumn({
		field: "name",
		headerName: i18n.t("name"),
		width: 150,
	}),
	floatColumn({
		field: "sum",
		headerName: i18n.t("sum"),
	}),
	textColumn({
		field: "note",
		headerName: i18n.t("note"),
		width: 150,
	}),
	dateTimeColumn({
		field: "createdAt",
		headerName: i18n.t("created_at"),
		width: 150,
	}),
	showEstimatedApprovalDate &&
		dateColumn({
			field: "estimatedApprovalDate",
			headerName: i18n.t("estimated_approval"),
			width: 150,
		}),
	showState &&
		enumColumn({
			field: "salesCommissionLineState",
			headerName: i18n.t("state"),
			enumLabels: getSalesCommissionLineStateLabels(),
			width: 150,
		}),
	showApprovalColumns && [
		dateTimeColumn({
			field: "approvedAt",
			headerName: i18n.t("approved_at"),
			width: 140,
		}),
		textColumn({
			field: "approvedByUserName",
			headerName: i18n.t("approved_by"),
			width: 120,
		}),
	],
	showPaymentColumns && [
		dateTimeColumn({
			field: "paymentApprovedAt",
			headerName: i18n.t("payment_approved_at"),
			width: 150,
		}),
		textColumn({
			field: "paymentApprovedByUserName",
			headerName: i18n.t("payment_approved_by"),
			width: 160,
		}),
	],
];
