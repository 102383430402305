// This file is automatically generated. Do not edit manually.

import { CustomerOrderBillingPlanLineView } from "src/api/generated/erp/db/types/tables/customerOrderBillingPlanLineView";
import { BillingPlanSummary } from "src/api/generated/erp/sales/billingPlan/base/billingPlanSummary";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace CustomerOrderBillingPlanApi {
	export async function getCustomerOrderBillingPlan(args: {
		customerOrderId: number;
		includeCancelledLines?: boolean;
	}): Promise<CustomerOrderBillingPlanApi_BillingPlanDto> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/customerOrder/billingPlan/getCustomerOrderBillingPlan",
			method: "POST",
			data: {
				customerOrderId: args.customerOrderId,
				includeCancelledLines: args.includeCancelledLines,
			},
		});
		return response as CustomerOrderBillingPlanApi_BillingPlanDto;
	}

	export async function approveCustomerOrderBillingPlanLine(args: { billingPlanLineId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/customerOrder/billingPlan/approveCustomerOrderBillingPlanLine",
			method: "POST",
			data: {
				billingPlanLineId: args.billingPlanLineId,
			},
		});
		return response as void;
	}

	export async function revertApproveCustomerOrderBillingPlanLine(args: {
		billingPlanLineId: number;
	}): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/customerOrder/billingPlan/revertApproveCustomerOrderBillingPlanLine",
			method: "POST",
			data: {
				billingPlanLineId: args.billingPlanLineId,
			},
		});
		return response as void;
	}

	export async function deleteBillingPlanLines(args: { billingPlanLineIds: Array<number> }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/customerOrder/billingPlan/deleteBillingPlanLines",
			method: "POST",
			data: {
				billingPlanLineIds: args.billingPlanLineIds,
			},
		});
		return response as void;
	}

	export async function changeBillingPlanLineVatCode(args: {
		billingPlanLineId: number;
		newVatCodeId: number;
	}): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/customerOrder/billingPlan/changeBillingPlanLineVatCode",
			method: "POST",
			data: {
				billingPlanLineId: args.billingPlanLineId,
				newVatCodeId: args.newVatCodeId,
			},
		});
		return response as void;
	}
}

export interface CustomerOrderBillingPlanApi_BillingPlanDto {
	lines: Array<CustomerOrderBillingPlanLineView>;
	summary: BillingPlanSummary;
}
