import { FormCommonProps } from "src/components/common/forms/types.ts";
import { AsyncForm } from "src/components/common/forms/AsyncForm.tsx";
import { WorkspaceEditApi } from "src/api/generated/workspaces/api/workspaceEditApi.ts";
import { Workspace } from "src/api/generated/postgres/db/types/workspaces/tables/workspace.ts";
import { FormTextField } from "src/components/common/forms/fields/FormTextField.tsx";
import i18n from "i18next";
import { requireRule } from "src/components/common/forms/validation.ts";
import { FormCheckbox } from "src/components/common/forms/fields/FormCheckbox.tsx";
import { FormSelectField } from "src/components/common/forms/fields/FormSelectField.tsx";
import { FormAsyncUserSelectField } from "src/components/views/users/FormAsyncUserSelectField.tsx";
import { FormDateTimeField } from "src/components/common/forms/fields/FormDateTimeField.tsx";

export interface WorkspaceFormProps extends FormCommonProps<number> {
	parentId: number | null;
	workspaceId: number | null;
}

export const WorkspaceForm = ({ parentId, workspaceId, onFormEdited, onCompleted }: WorkspaceFormProps) => {
	return (
		<AsyncForm
			fetch={() => WorkspaceEditApi.getFormInitData({ workspaceId: workspaceId, parentId: parentId })}
			getDefaultValues={({ workspace, parentCategoryId }) =>
				workspaceId != null ?
					{
						...workspace,
					}
				:	{
						parentId: parentId,
						workspaceId: workspaceId,
						isLocked: false,
						tasksEnabled: true,
						documentsEnabled: true,
						connectionsEnabled: true,
						categoryId: parentCategoryId ?? undefined,
					}
			}
			submit={submit}
			onCompleted={onCompleted}
			onFormEdited={onFormEdited}
			render={({ data: { categoryOptions }, control }) => (
				<>
					<FormTextField control={control} name={"name"} label={i18n.t("name")} rules={requireRule()} />
					<FormTextField control={control} name={"description"} label={i18n.t("description")} />
					<FormSelectField
						control={control}
						name={"categoryId"}
						label={i18n.t("category")}
						options={categoryOptions}
						getOptionKey={(o) => o.id}
						getOptionLabel={(o) => o.categoryName}
						rules={requireRule()}
					/>
					<FormAsyncUserSelectField
						control={control}
						label={i18n.t("responsible_person")}
						name={"responsiblePersonId"}
						rules={requireRule()}
					/>
					<FormDateTimeField control={control} name={"pointOfTime"} label={i18n.t("point_of_time")} />
					<FormCheckbox
						control={control}
						name={"tasksEnabled"}
						label={i18n.t("tasks_enabled")}
						startNewGridRow={true}
					/>
					<FormCheckbox
						control={control}
						name={"documentsEnabled"}
						label={i18n.t("documents_enabled")}
						startNewGridRow={true}
					/>
					<FormCheckbox
						control={control}
						name={"connectionsEnabled"}
						label={i18n.t("connections_enabled")}
						startNewGridRow={true}
					/>
				</>
			)}
		/>
	);

	async function submit(values: Workspace) {
		if (values.id == null) {
			return await WorkspaceEditApi.insert({ workspace: values });
		} else {
			return await WorkspaceEditApi.update({ workspace: values });
		}
	}
};
