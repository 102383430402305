import { PartialTenantCustomizations } from "src/tenantCustomizations/TenantCustomizations.ts";
import { getKaskipuuAggregateRecordSpcSamplingMethod } from "src/tenantCustomizations/tenants/kaskipuu/kaskipuuAggregateRecordSpcSamplingMethod.tsx";

export const kaskipuuCustomizations: PartialTenantCustomizations = {
	tenantConfig: {
		documentsEnabled: false,
		tasks: {
			enabled: false,
		},
	},
	spcSamplingMethods: () => [getKaskipuuAggregateRecordSpcSamplingMethod()],
} as const;
