import { filterNulls } from "src/utils/arrayUtils.ts";
import { AavoTreeViewBaseItem } from "src/components/common/tree/AavoTreeView.tsx";

export type FilterableAavoTreeViewItemModel<R extends object> = AavoTreeViewBaseItem<
	R & {
		directSearchMatch: boolean;
		expand: boolean;
	}
>;

export interface FilterTreeItemsParams<R extends object> {
	rootTreeItems: FilterableAavoTreeViewItemModel<R>[];
	isSearchMatch: (item: FilterableAavoTreeViewItemModel<R>) => boolean;
}

export function filterTreeItems<R extends object>({
	rootTreeItems,
	isSearchMatch,
}: FilterTreeItemsParams<R>): FilterableAavoTreeViewItemModel<R>[] {
	return filterNulls(
		rootTreeItems.map((rootItem) =>
			filterTreeItem({
				node: rootItem,
				isSearchMatch: isSearchMatch,
				keepEvery: false,
			}),
		),
	);
}

interface FilterTreeItemParams<R extends object> {
	node: FilterableAavoTreeViewItemModel<R>;
	isSearchMatch: (item: R) => boolean;
	keepEvery: boolean;
}

function filterTreeItem<R extends object>({
	node: node,
	isSearchMatch,
	keepEvery,
}: FilterTreeItemParams<R>): FilterableAavoTreeViewItemModel<R> | null {
	const ret = { ...node };

	ret.directSearchMatch = isSearchMatch(node);

	const allChildren = node.children ?? [];
	ret.children = filterNulls(
		allChildren.map((child) =>
			filterTreeItem({
				node: child,
				isSearchMatch: isSearchMatch,
				keepEvery: isSearchMatch(node) || keepEvery,
			}),
		),
	);

	// Keep this node if it's direct match or any child is kept or keepEvery is true
	const keepThisNode = isSearchMatch(node) || ret.children.length > 0 || keepEvery;
	if (!keepThisNode) {
		return null;
	}

	const anyChildIsDirectMatchOrExpanded = ret.children.some((child) => child.directSearchMatch || child.expand);
	if (anyChildIsDirectMatchOrExpanded) {
		ret.expand = true;
	}

	return ret;
}
