import { SurveySubmissionFormFieldProps } from "src/components/views/erp/surveys/submissions/fields/SurveySubmissionFormField.tsx";
import { Divider } from "@mui/material";

export const SurveySubmissionFormSectionBreak = ({ field }: SurveySubmissionFormFieldProps) => {
	return (
		<Divider
			textAlign="left"
			orientation="horizontal"
			variant="fullWidth"
			sx={{
				flex: 1,
				gridColumn: "1 / -1",
				color: "primary.main",
			}}
		>
			{field.label}
		</Divider>
	);
};
