import {useState} from "react";
import {ReclamationView} from "src/api/generated/erp/db/types/tables/reclamationView.ts";
import {ReclamationsView} from "./ReclamationsView.tsx";
import i18n from "i18next";
import {ReclamationsDataGridBase} from "src/components/views/erp/reclamation/ReclamationsDataGridBase.tsx";

export interface SingleReclamationViewProps {
	reclamationId: number;
}

export const SingleReclamationView = ({ reclamationId }: SingleReclamationViewProps) => {
	const [selectedReclamations, setSelectedReclamations] = useState<ReclamationView[]>([]);
	return (
		<ReclamationsView
			selectedReclamations={selectedReclamations}
			upperContent={{
				type: "panel",
				title: i18n.t("reclamation"),
				content: (
					<ReclamationsDataGridBase
						fetchOnlySingleReclamationId={reclamationId}
						selectedReclamations={selectedReclamations}
						onSelectionChanged={setSelectedReclamations}
						usePagination={false}
						onDataLoaded={(newRows) => {
							setSelectedReclamations(newRows);
						}}
						gridId={"169CFE533A890E12"}
					/>
				),
			}}
		/>
	);
};
