// This file is automatically generated. Do not edit manually.

import { SupplierView } from "src/api/generated/erp/db/types/tables/supplierView";
import { SupplierPartView } from "src/api/generated/erp/db/types/tables/supplierPartView";
import { SupplierPart } from "src/api/generated/erp/db/types/tables/supplierPart";
import { ErpUnit } from "src/api/generated/erp/db/types/tables/erpUnit";
import { PartView } from "src/api/generated/erp/db/types/tables/partView";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace SitePartSupplierEditApi {
	export async function getInitData(args: {
		partId: number;
		supplierPartId: number | null | undefined;
	}): Promise<SitePartSupplierEditApi_InitData> {
		const response = await makeAxiosRequest({
			url: "/api/ext/sitePartSupplierEdit/getInitData",
			method: "POST",
			data: {
				partId: args.partId,
				supplierPartId: args.supplierPartId,
			},
		});
		return response as SitePartSupplierEditApi_InitData;
	}

	export async function getSupplierOptions(args: {
		searchQuery: string;
		currentSelectionId: number | null | undefined;
		partId: number;
		supplierPartId: number | null | undefined;
	}): Promise<Array<SupplierView>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/sitePartSupplierEdit/getSupplierOptions",
			method: "POST",
			data: {
				searchQuery: args.searchQuery,
				currentSelectionId: args.currentSelectionId,
				partId: args.partId,
				supplierPartId: args.supplierPartId,
			},
		});
		return response as Array<SupplierView>;
	}

	export async function delete_(args: { supplierParts: Array<SupplierPartView> }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/sitePartSupplierEdit/delete",
			method: "POST",
			data: {
				supplierParts: args.supplierParts,
			},
		});
		return response as void;
	}

	export async function save(args: { supplierPart: SupplierPart }): Promise<number> {
		const response = await makeAxiosRequest({
			url: "/api/ext/sitePartSupplierEdit/save",
			method: "POST",
			data: {
				supplierPart: args.supplierPart,
			},
		});
		return response as number;
	}
}

export interface SitePartSupplierEditApi_InitData {
	erpUnitOptions: Array<ErpUnit>;
	part: PartView;
	supplierPart: SupplierPart | null | undefined;
}
