// This file is automatically generated. Do not edit manually.

import { PartCategory } from "src/api/generated/erp/db/types/tables/partCategory";
import { ObjectAttributeField } from "src/api/generated/erp/common/objectAttributes/objectAttributeField";
import { PartCategoryAttributeField } from "src/api/generated/erp/db/types/tables/partCategoryAttributeField";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace PartCategoryApi {
	export async function getAll(): Promise<Array<PartCategory>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/productManagement/partCategories/getAll",
			method: "POST",
			data: {},
		});
		return response as Array<PartCategory>;
	}

	export async function getSingle(args: { partCategoryId: number }): Promise<PartCategory> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/productManagement/partCategories/getSingle",
			method: "POST",
			data: {
				partCategoryId: args.partCategoryId,
			},
		});
		return response as PartCategory;
	}

	export async function insert(args: { partCategory: PartCategory }): Promise<number> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/productManagement/partCategories/insert",
			method: "POST",
			data: {
				partCategory: args.partCategory,
			},
		});
		return response as number;
	}

	export async function update(args: { partCategory: PartCategory }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/productManagement/partCategories/update",
			method: "POST",
			data: {
				partCategory: args.partCategory,
			},
		});
		return response as void;
	}

	export async function delete_(args: { partCategoryId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/productManagement/partCategories/delete",
			method: "POST",
			data: {
				partCategoryId: args.partCategoryId,
			},
		});
		return response as void;
	}

	export async function getAllPartAttributeFields(): Promise<Array<ObjectAttributeField>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/productManagement/partCategories/attributeFields/getAll",
			method: "POST",
			data: {},
		});
		return response as Array<ObjectAttributeField>;
	}

	export async function getAttributeFields(args: {
		partCategoryId: number;
	}): Promise<Array<PartCategoryAttributeField>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/productManagement/partCategories/attributeFields/getForPartCategory",
			method: "POST",
			data: {
				partCategoryId: args.partCategoryId,
			},
		});
		return response as Array<PartCategoryAttributeField>;
	}

	export async function connectAttributesFields(args: {
		partCategoryId: number;
		attributeFieldIds: Array<number>;
	}): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/productManagement/partCategories/attributeFields/connect",
			method: "POST",
			data: {
				partCategoryId: args.partCategoryId,
				attributeFieldIds: args.attributeFieldIds,
			},
		});
		return response as void;
	}

	export async function removeAttributesFields(args: {
		partCategoryId: number;
		attributeFieldIds: Array<number>;
	}): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/productManagement/partCategories/attributeFields/remove",
			method: "POST",
			data: {
				partCategoryId: args.partCategoryId,
				attributeFieldIds: args.attributeFieldIds,
			},
		});
		return response as void;
	}
}
