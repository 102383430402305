// This file is automatically generated. Do not edit manually.

import { t } from "i18next";

export const SalesCommissionLineStateValues = ["INITIAL", "APPROVED", "PAYMENT_INITIAL", "PAYMENT_APPROVED"] as const;

export type SalesCommissionLineState = (typeof SalesCommissionLineStateValues)[number];

export const getSalesCommissionLineStateLabels = () => ({
	INITIAL: t("initial"),
	APPROVED: t("approved"),
	PAYMENT_INITIAL: t("payment_initial"),
	PAYMENT_APPROVED: t("payment_approved"),
});

export const getSalesCommissionLineStateLabel = (value: SalesCommissionLineState): string => {
	return getSalesCommissionLineStateLabels()[value];
};
