import i18n from "i18next";
import { EnvironmentId } from "src/storage/appState/types";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { capitalize } from "src/utils/strings";
import { mergeSx } from "src/utils/styles";
import { dNone } from "src/styles/sx";
import { selectionSx } from "./styles";
import { Environment } from "src/api/mainApi";

const EMPTY_SELECTION = "";

export interface EnvironmentSelectionProps {
	options: Environment[];
	value: EnvironmentId;
	onChange: (value: EnvironmentId) => void;
}

export const EnvironmentSelection = ({
	options,
	value,
	onChange,
}: EnvironmentSelectionProps) => {
	const hide = options.length <= 1;
	return (
		<FormControl
			variant="standard"
			sx={mergeSx(hide ? dNone : {}, selectionSx)}
		>
			<InputLabel id="environment-select-label">
				{capitalize(i18n.t("environment"))}
			</InputLabel>
			<Select
				labelId="environment-select-label"
				id="environment-select"
				value={value || (options[0]?.id.toString() ?? EMPTY_SELECTION)}
				onChange={(e) => {
					e.preventDefault();
					onChange(
						e.target.value === EMPTY_SELECTION
							? null
							: e.target.value
					);
				}}
				label={capitalize(i18n.t("environment"))}
			>
				<MenuItem
					value={EMPTY_SELECTION}
					disabled
					sx={
						value !== EMPTY_SELECTION
							? { display: "none" }
							: undefined
					}
				>
					{i18n.t("undefined")}
				</MenuItem>
				{mapOptionsToMenuItems(options)}
			</Select>
		</FormControl>
	);
};

const mapOptionsToMenuItems = (options: Environment[]): React.ReactNode[] => {
	return options.map((option) => {
		return (
			<MenuItem
				key={`${option.id}-${option.name}-environment-item`}
				value={option.id.toString()}
			>
				{option.name}
			</MenuItem>
		);
	});
};
