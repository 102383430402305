import { PartialTenantCustomizations } from "src/tenantCustomizations/TenantCustomizations";

export const puukotiCustomizations: PartialTenantCustomizations = {
	tenantConfig: {
		erp: {
			enabled: true,
			configuratorEnabled: true,
		},
		tasks: {
			enabled: true,
		},
		documentsEnabled: true,
	},
} as const;
