import React, { useContext, useState } from "react";
import { AavoButton, AavoButtonProps } from "./AavoButton";
import { useErrorDialog } from "src/components/common/dialogs/errorDialog/userErrorDialog.ts";
import { AavoMenuItem } from "src/components/common/contextMenu/AavoMenuItem.tsx";
import { AavoContextMenuClosingContext } from "src/components/common/contextMenu/AavoContextMenuClosingContext.ts";

export interface AsyncButtonProps extends Omit<AavoButtonProps, "loading" | "onClick" | "variant"> {
	onClick?: (e: React.MouseEvent<HTMLButtonElement>) => unknown | Promise<unknown>;
	variant?: AavoButtonProps["variant"] | "menu";
}

export const AsyncButton = (props: AsyncButtonProps) => {
	const { onClick, variant, ...other } = props;
	const [loading, setLoading] = useState<boolean>(false);
	const { logErrorAndShowOnDialog } = useErrorDialog();

	const contextMenuClosingContext = useContext(AavoContextMenuClosingContext);
	if (variant === "menu")
		return (
			<AavoMenuItem>
				<AsyncButton
					{...props}
					color={"black"}
					variant={"text"}
					onClick={async (event) => {
						if (onClick) {
							await onClick(event);
						}
						contextMenuClosingContext?.closeContextMenu?.();
					}}
				/>
			</AavoMenuItem>
		);

	return (
		<AavoButton
			variant={variant}
			onClick={async (e) => {
				if (onClick === undefined) return;
				setLoading(true);
				try {
					await onClick(e);
				} catch (e) {
					logErrorAndShowOnDialog(e);
				} finally {
					setLoading(false);
				}
			}}
			loading={loading}
			{...other}
		/>
	);
};
