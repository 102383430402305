// This file is automatically generated. Do not edit manually.

import { WorkspaceCategory } from "src/api/generated/postgres/db/types/workspaces/tables/workspaceCategory";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace WorkspaceCategoryApi {
	export async function getWorkspaceCategories(): Promise<Array<WorkspaceCategory>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/workspaces/workspaceCategory/getWorkspaceCategories",
			method: "POST",
			data: {},
		});
		return response as Array<WorkspaceCategory>;
	}

	export async function insert(args: { workspaceCategory: WorkspaceCategory }): Promise<number> {
		const response = await makeAxiosRequest({
			url: "/api/ext/workspaces/workspaceCategory/insert",
			method: "POST",
			data: {
				workspaceCategory: args.workspaceCategory,
			},
		});
		return response as number;
	}

	export async function update(args: { workspaceCategory: WorkspaceCategory }): Promise<number> {
		const response = await makeAxiosRequest({
			url: "/api/ext/workspaces/workspaceCategory/update",
			method: "POST",
			data: {
				workspaceCategory: args.workspaceCategory,
			},
		});
		return response as number;
	}

	export async function delete_(args: { workspaceCategoryId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/workspaces/workspaceCategory/delete",
			method: "POST",
			data: {
				workspaceCategoryId: args.workspaceCategoryId,
			},
		});
		return response as void;
	}
}
