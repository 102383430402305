// This file is automatically generated. Do not edit manually.

import { SalesContract } from "src/api/generated/erp/db/types/tables/salesContract";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace CustomerSalesContractEditApi {
	export async function insert(args: { contract: SalesContract }): Promise<number> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/pricing/salesContracts/insert",
			method: "POST",
			data: {
				contract: args.contract,
			},
		});
		return response as number;
	}

	export async function update(args: { contract: SalesContract }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/pricing/salesContracts/update",
			method: "POST",
			data: {
				contract: args.contract,
			},
		});
		return response as void;
	}

	export async function delete_(args: { contractId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/pricing/salesContracts/delete",
			method: "POST",
			data: {
				contractId: args.contractId,
			},
		});
		return response as void;
	}

	export async function releaseSalesContract(args: { salesContractId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/pricing/salesContracts/releaseSalesContract",
			method: "POST",
			data: {
				salesContractId: args.salesContractId,
			},
		});
		return response as void;
	}

	export async function deactivateSalesContract(args: { salesContractId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/pricing/salesContracts/deactivateSalesContract",
			method: "POST",
			data: {
				salesContractId: args.salesContractId,
			},
		});
		return response as void;
	}
}
