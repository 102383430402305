import { ClientSideDataGridModel } from "src/components/common/dataGrid/gridModel/ClientSideDataGridModel.tsx";
import { SalesContractPriceGroupDiscountsApi } from "src/api/generated/erp/sales/pricing/api/salesContractPriceGroupDiscountsApi.ts";
import { SalesContractPriceGroupDiscountView } from "src/api/generated/erp/db/types/tables/salesContractPriceGroupDiscountView.ts";
import { floatColumn, textColumn } from "src/components/common/dataGrid/columns.tsx";
import { CrudDataGrid } from "src/components/common/dataGrid/crud/CrudDataGrid.tsx";
import i18n from "i18next";
import { SalesContractPriceGroupDiscountForm } from "src/components/views/erp/sales/pricing/salesContracts/SalesContractPriceGroupDiscountForm.tsx";

export interface SalesContractPriceGroupDiscountsDataGridProps {
	salesContractId: number;
}

export const SalesContractPriceGroupDiscountsDataGrid = ({
	salesContractId,
}: SalesContractPriceGroupDiscountsDataGridProps) => {
	return (
		<ClientSideDataGridModel
			fetchData={() =>
				SalesContractPriceGroupDiscountsApi.getSalesContractDiscounts({ salesContractId: salesContractId })
			}
			initialParams={{}}
			getRows={(data) => data}
			getRowId={(row) => row.salesContractPriceGroupDiscountId}
			gridId={"D9C3D7C1A8B2D6E4"}
			render={({ dataGridProps }) => (
				<CrudDataGrid<SalesContractPriceGroupDiscountView>
					columns={[
						textColumn({
							field: "salesPartPriceGroupName",
							headerName: i18n.t("price_group"),
							width: 300,
						}),
						floatColumn({
							field: "discountPercentage",
							headerName: i18n.t("discount_percent"),
							width: 150,
						}),
					]}
					form={{
						addRowEnabled: true,
						editEnabled: true,
						dialogSize: "sm",
						dialogTitle: i18n.t("discount"),
						component: ({ row, onCompleted, onFormEdited }) => (
							<SalesContractPriceGroupDiscountForm
								salesContractId={salesContractId}
								discountId={row?.salesContractPriceGroupDiscountId}
								onCompleted={onCompleted}
								onFormEdited={onFormEdited}
							/>
						),
					}}
					remove={({ items }) =>
						SalesContractPriceGroupDiscountsApi.delete_({
							salesContractPriceGroupDiscountIds: items.map(
								(item) => item.salesContractPriceGroupDiscountId,
							),
						})
					}
					{...dataGridProps}
				/>
			)}
		/>
	);
};
