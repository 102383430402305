import { AsyncButton, AsyncButtonProps } from "src/components/common/buttons/AsyncButton.tsx";
import { faPrint } from "@fortawesome/pro-regular-svg-icons";
import i18n from "i18next";
import { downloadFile } from "src/utils/fileDownloading.ts";
import { DeliveryView } from "src/api/generated/erp/db/types/tables/deliveryView.ts";
import { DeliveryActionApi } from "src/api/generated/erp/delivery/api/deliveryActionApi.ts";

export const PrintDeliveryConsignmentNoteButton = ({
	delivery,
	...other
}: AsyncButtonProps & {
	delivery: DeliveryView | undefined;
}) => {
	return (
		<AsyncButton
			icon={faPrint}
			label={i18n.t("print_consignment_note")}
			variant={"outlined"}
			disabled={delivery == null}
			onClick={async () => {
				if (delivery == null) return;
				const fileHandle = await DeliveryActionApi.printDeliveryConsignmentNote({
					deliveryId: delivery.deliveryId,
				});
				downloadFile(fileHandle);
			}}
			{...other}
		/>
	);
};

export const PrintDeliveryNoteButton = ({
	delivery,
	...other
}: AsyncButtonProps & {
	delivery: DeliveryView | undefined;
}) => {
	return (
		<AsyncButton
			icon={faPrint}
			label={i18n.t("print_delivery_note")}
			variant={"outlined"}
			disabled={delivery == null}
			onClick={async () => {
				if (delivery == null) return;
				const fileHandle = await DeliveryActionApi.printDeliveryNote({
					deliveryId: delivery.deliveryId,
				});
				downloadFile(fileHandle);
			}}
			{...other}
		/>
	);
};
