// This file is automatically generated. Do not edit manually.

import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace WarehousePickingObjectReservationFormApi {
	export async function fetchInitData(args: {
		partIds: Array<number>;
	}): Promise<WarehousePickingObjectReservationFormApi_InitData> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/warehouse/pickingObjectReservationForm/fetchInitData",
			method: "POST",
			data: {
				partIds: args.partIds,
			},
		});
		return response as WarehousePickingObjectReservationFormApi_InitData;
	}
}

export interface WarehousePickingObjectReservationFormApi_InitData {
	parts: Array<WarehousePickingObjectReservationFormApi_InitData_PartDto>;
}

export interface WarehousePickingObjectReservationFormApi_InitData_PartDto {
	defaultPartWarehouseLocationId: number | null | undefined;
	partDescription: string;
	partId: number;
	partWarehouseLocations: Array<WarehousePickingObjectReservationFormApi_InitData_PartWarehouseLocationDto>;
}

export interface WarehousePickingObjectReservationFormApi_InitData_PartWarehouseLocationDto {
	inventoryLevelEnabled: boolean;
	partWarehouseLocationCode: string;
	partWarehouseLocationId: number;
	partWarehouseUnit: string;
	reservedQuantity: number | null | undefined;
	stockQuantity: number | null | undefined;
}
