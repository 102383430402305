import { PurchaseOrderLinesDataGridApi } from "src/api/generated/erp/purchase/purchaseOrder/api/purchaseOrderLinesDataGridApi";
import { IsoDateString } from "src/types/dateTime.ts";
import { genericNullableValue } from "src/utils/genericNullableValue.ts";
import {
	booleanColumn,
	dateColumn,
	enumColumn,
	floatColumn,
	integerColumn,
	textColumn,
} from "src/components/common/dataGrid/columns.tsx";
import i18n from "i18next";
import { getPurchaseOrderLineStateLabels } from "src/api/generated/erp/db/types/enums/purchaseOrderLineState.ts";
import { getPurchaseOrderLineSourceTypeLabels } from "src/api/generated/erp/db/types/enums/purchaseOrderLineSourceType.ts";
import { getPurchaseOrderStateLabels } from "src/api/generated/erp/db/types/enums/purchaseOrderState.ts";
import { getCurrencyLabels } from "src/api/generated/erp/db/types/enums/currency.ts";
import { getPurchaseOrderTypeLabels } from "src/api/generated/erp/db/types/enums/purchaseOrderType.ts";
import { MenuCheckbox } from "src/components/common/contextMenu/MenuCheckbox.tsx";
import { DocumentsOfObjectButton } from "src/components/views/documents/objectDocuments/DocumentsOfObjectButton.tsx";
import { nullableAavoObjectRef } from "src/utils/aavoObjectRefUtils.ts";
import { AavoTextField } from "src/components/common/inputFields/AavoTextField.tsx";
import { ServerSideDataGridModel } from "src/components/common/dataGrid/gridModel/ServerSideDataGridModel.tsx";
import { Typography } from "@mui/material";
import { OpenPartConfigurationButton } from "src/components/views/erp/configurator/inspecting/OpenPartConfigurationButton.tsx";
import { AsyncMenuButton } from "src/components/common/contextMenu/AsyncMenuButton.tsx";
import { faBan, faTools } from "@fortawesome/pro-regular-svg-icons";
import { PurchaseOrderLineConfiguratorView } from "src/components/views/erp/configurator/configuratorForm/impl/PurchaseOrderLineConfiguratorView.tsx";
import { useGenericDialog } from "src/components/common/dialogs/useGenericDialog.ts";
import { OpenObjectChangeLogButton } from "src/components/views/changeLogging/OpenObjectChangeLogButton.tsx";
import { PurchaseOrderLineView } from "src/api/generated/erp/db/types/tables/purchaseOrderLineView.ts";
import { useConfirmDialog } from "src/components/common/dialogs/confirmDialog/useConfirmDialog.ts";
import { PurchaseOrderLineActionApi } from "src/api/generated/erp/purchase/purchaseOrder/api/purchaseOrderLineActionApi.ts";
import { CrudDataGrid } from "src/components/common/dataGrid/crud/CrudDataGrid.tsx";
import { formatMoney } from "src/utils/numberUtils.ts";
import { PurchaseOrderLineForm } from "src/components/views/erp/purchase/purchaseOrders/PurchaseOrderLineForm.tsx";
import { PurchaseOrder } from "src/api/generated/erp/db/types/tables/purchaseOrder.ts";
import { RefreshableElementProps } from "src/utils/useRefreshRef.ts";
import { OpenConfigurationHistoryButton } from "src/components/views/erp/configurator/objectConfigurationHistory/OpenConfigurationHistoryButton.tsx";
import { expectNonFileResult } from "src/components/common/dataGrid/gridModel/serverSideDataModelUtils.ts";

export interface PurchaseOrderLinesDataGridBaseProps extends RefreshableElementProps {
	purchaseOrder?: PurchaseOrder;
}

export const PurchaseOrderLinesDataGridBase = ({
	purchaseOrder,
	refreshRef,
}: PurchaseOrderLinesDataGridBaseProps) => {
	const { openDialog } = useGenericDialog();
	const showConfirmDialog = useConfirmDialog();
	const purchaseOrderId = purchaseOrder?.purchaseOrderId;

	return (
		<ServerSideDataGridModel
			fetchData={(params) =>
				PurchaseOrderLinesDataGridApi.getData({
					purchaseOrderId: purchaseOrderId,
					...params,
				})
			}
			getDataModelResult={(data) => data.dataModelResult}
			gridId={"D30504F80A65DBD"}
			initialParams={{
				searchQuery: "",
				showCancelledLines: false,
				showReceivedLines: purchaseOrderId != null,
				fromDate: genericNullableValue<IsoDateString>(),
				toDate: genericNullableValue<IsoDateString>(),
			}}
			getRowId={(row) => row.purchaseOrderLineId}
			refreshRef={refreshRef}
			render={({
				dataGridProps,
				refreshData,
				currentParams,
				onlySelectedRow,
				data: { dataModelResult, purchaseOrderTotalPrice },
			}) => {
				const rows = expectNonFileResult(dataModelResult).rows;
				const shouldShowAwaitingConfigurationColumn =
					purchaseOrderId != null && rows.some((row) => row.partIsConfigurable);

				return (
					<CrudDataGrid<PurchaseOrderLineView>
						columns={[
							integerColumn({
								field: "lineNumber",
								headerName: i18n.t("line_number"),
								width: 80,
							}),
							textColumn({
								field: "partNo",
								headerName: i18n.t("part_no"),
								width: 80,
							}),
							textColumn({
								field: "purchasePartDescription",
								headerName: i18n.t("description"),
								width: 200,
							}),
							floatColumn({
								field: "purchaseQuantity",
								headerName: i18n.t("quantity"),
								width: 80,
							}),
							textColumn({
								field: "purchaseUnit",
								headerName: i18n.t("purchase_unit"),
							}),
							floatColumn({
								field: "purchasePrice",
								headerName: i18n.t("unit_price"),
								width: 80,
							}),
							textColumn({
								field: "purchasePriceUnit",
								headerName: i18n.t("price_unit"),
								width: 80,
							}),
							floatColumn({
								field: "totalPrice",
								headerName: i18n.t("total_price"),
								width: 100,
							}),
							dateColumn({
								field: "plannedArrivalDate",
								headerName: i18n.t("planned_arrival_date"),
								width: 150,
							}),
							enumColumn({
								field: "purchaseOrderLineState",
								headerName: i18n.t("state"),
								enumLabels: getPurchaseOrderLineStateLabels(),
								width: 150,
							}),
							shouldShowAwaitingConfigurationColumn &&
								booleanColumn({
									field: "isAwaitingConfiguration",
									headerName: i18n.t("awaiting_configuration"),
									width: 150,
								}),
							textColumn({
								field: "note",
								headerName: i18n.t("note"),
								width: 150,
							}),
							textColumn({
								field: "supplierPartNo",
								headerName: i18n.t("supplier_part_no"),
								width: 150,
							}),
							textColumn({
								field: "supplierPartDescription",
								headerName: i18n.t("supplier_part_description"),
								width: 150,
							}),
							floatColumn({
								field: "receivedQuantity",
								headerName: i18n.t("received_quantity"),
								width: 150,
							}),
							booleanColumn({
								field: "fullyReceived",
								headerName: i18n.t("fully_received"),
								width: 150,
							}),
							enumColumn({
								field: "source",
								headerName: i18n.t("source"),
								enumLabels: getPurchaseOrderLineSourceTypeLabels(),
								width: 150,
							}),
							integerColumn({
								field: "sourceId",
								headerName: i18n.t("source_id"),
							}),
							purchaseOrderId == null && [
								textColumn({
									field: "siteName",
									headerName: i18n.t("site"),
								}),
								textColumn({
									field: "purchaseOrderId",
									headerName: i18n.t("order_no"),
								}),
								textColumn({
									field: "supplierName",
									headerName: i18n.t("supplier"),
									width: 150,
								}),
								enumColumn({
									field: "purchaseOrderType",
									headerName: i18n.t("order_type"),
									enumLabels: getPurchaseOrderTypeLabels(),
									width: 150,
								}),
								enumColumn({
									field: "purchaseOrderState",
									headerName: i18n.t("order_state"),
									enumLabels: getPurchaseOrderStateLabels(),
								}),
								textColumn({
									field: "orderReference",
									headerName: i18n.t("reference"),
									width: 150,
								}),
								textColumn({
									field: "ourContact",
									headerName: i18n.t("our_contact"),
									width: 150,
								}),
								dateColumn({
									field: "releasedDate",
									headerName: i18n.t("released_at"),
									width: 150,
								}),
								dateColumn({
									field: "printedDate",
									headerName: i18n.t("printed_at"),
									width: 150,
								}),
								dateColumn({
									field: "confirmedDate",
									headerName: i18n.t("confirmed_at"),
									width: 150,
								}),
								textColumn({
									field: "supplierContact",
									headerName: i18n.t("supplier_contact"),
									width: 150,
								}),
								enumColumn({
									field: "currency",
									headerName: i18n.t("currency"),
									enumLabels: getCurrencyLabels(),
								}),
							],
							integerColumn({
								field: "purchaseOrderLineId",
								headerName: i18n.t("id"),
							}),
						]}
						actionBarComponents={
							<>
								<DocumentsOfObjectButton
									objectRef={nullableAavoObjectRef(
										"PURCHASE_ORDER_LINE",
										onlySelectedRow?.purchaseOrderLineId,
									)}
								/>
								<AavoTextField
									label={i18n.t("search")}
									onSubmit={(searchQuery) => refreshData({ searchQuery })}
								/>
								{purchaseOrderTotalPrice && (
									<Typography
										alignSelf={"center"}
										marginLeft={1}
										children={`${i18n.t("sum")}: ${formatMoney(purchaseOrderTotalPrice)}`}
									/>
								)}
							</>
						}
						actionBarMenuComponents={[
							purchaseOrderId == null && (
								<MenuCheckbox
									key={"showReceived"}
									label={i18n.t("show_received")}
									onChange={(value) => refreshData({ showReceivedLines: value })}
									checked={currentParams.showReceivedLines}
								/>
							),
							<MenuCheckbox
								key={"showCancelled"}
								label={i18n.t("show_cancelled")}
								onChange={(value) => refreshData({ showCancelledLines: value })}
								checked={currentParams.showCancelledLines}
							/>,
						]}
						rowContextMenuComponents={({ onlySingleRowSelected, row }) => [
							onlySingleRowSelected && row.partIsConfigurable && (
								<AsyncMenuButton
									key={"configure"}
									label={i18n.t("configure")}
									icon={faTools}
									disabled={row.purchaseOrderLineState !== "INITIAL"}
									onClick={() => {
										openDialog(({ closeDialog }) => ({
											title: i18n.t("configure"),
											content: (
												<PurchaseOrderLineConfiguratorView
													purchaseOrderLineId={row.purchaseOrderLineId}
													closeDialog={closeDialog}
													refreshSourceView={refreshData}
												/>
											),
										}));
									}}
								/>
							),
							onlySingleRowSelected && (
								<OpenPartConfigurationButton
									key={"openConfiguration"}
									partConfigurationId={row.partConfigurationId}
								/>
							),
							onlySingleRowSelected && row.partConfigurationId != null && (
								<OpenConfigurationHistoryButton
									key={"openConfigurationHistoryButton"}
									objectType={"PURCHASE_ORDER_LINE"}
									objectId={row.purchaseOrderLineId}
									refreshSourceView={refreshData}
								/>
							),
							onlySingleRowSelected && (
								<OpenObjectChangeLogButton
									key={"changeLog"}
									objectRef={{
										objectType: "PURCHASE_ORDER_LINE",
										objectId: row.purchaseOrderLineId,
									}}
								/>
							),
							onlySingleRowSelected && (
								<AsyncMenuButton
									key={"cancel"}
									label={i18n.t("cancel")}
									icon={faBan}
									onClick={() => cancelPurchaseOrderLine(row)}
								/>
							),
						]}
						form={{
							dialogTitle: i18n.t("purchase_order_line"),
							dialogSize: "lg",
							editEnabled: true,
							addRowEnabled:
								purchaseOrder != null &&
								["INITIAL", "RELEASED", "CONFIRMED"].includes(
									purchaseOrder.purchaseOrderState,
								),
							component: ({ row, ...other }) => (
								<PurchaseOrderLineForm
									purchaseOrderId={row?.purchaseOrderId || purchaseOrderId!}
									purchaseOrderLineId={row?.purchaseOrderLineId}
									{...other}
								/>
							),
						}}
						{...dataGridProps}
					/>
				);

				async function cancelPurchaseOrderLine(purchaseOrderLine: PurchaseOrderLineView) {
					const confirmed = await showConfirmDialog({
						message: i18n.t("confirm_cancel_purchase_order_line"),
					});
					if (!confirmed) return;
					await PurchaseOrderLineActionApi.cancelPurchaseOrderLine({
						purchaseOrderLineId: purchaseOrderLine.purchaseOrderLineId,
					});
					await refreshData();
				}
			}}
		/>
	);
};
