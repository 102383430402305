// This file is automatically generated. Do not edit manually.

import { FrontendDownloadFileHandle } from "src/api/generated/common/frontendDownloadFileHandle";
import { DocumentFileReference } from "src/api/generated/documents/api/documentFileReference";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace DocumentFileApi {
	export async function downloadDocumentsAsSingleFile(args: {
		documentIds: Array<number>;
	}): Promise<FrontendDownloadFileHandle> {
		const response = await makeAxiosRequest({
			url: "/api/ext/documents/downloadDocumentsAsSingleFile",
			method: "POST",
			data: {
				documentIds: args.documentIds,
			},
		});
		return response as FrontendDownloadFileHandle;
	}

	export async function downloadDocumentRevisionFile(args: {
		documentRevisionId: number;
	}): Promise<FrontendDownloadFileHandle> {
		const response = await makeAxiosRequest({
			url: "/api/ext/documents/downloadDocumentRevisionFile",
			method: "POST",
			data: {
				documentRevisionId: args.documentRevisionId,
			},
		});
		return response as FrontendDownloadFileHandle;
	}

	export async function getDocumentFileReference(args: {
		documentId: number;
		documentRevisionId: number | null | undefined;
	}): Promise<DocumentFileReference | null> {
		const response = await makeAxiosRequest({
			url: "/api/ext/documents/getDocumentFileReference",
			method: "POST",
			data: {
				documentId: args.documentId,
				documentRevisionId: args.documentRevisionId,
			},
		});
		return response === "" ? null : (response as DocumentFileReference | null);
	}
}
