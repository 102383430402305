import { CrudDataGridProps } from "src/components/common/dataGrid/crud/CrudDataGrid";
import { useAsyncFetch } from "src/utils/async/asyncFetch.ts";
import { ControlledAsyncCrudDataGrid } from "./ControlledAsyncCrudDataGrid";
import { RefreshableElementProps, setRefreshRefValue } from "src/utils/useRefreshRef.ts";

export interface AsyncCrudDataGridProps<RowData extends object>
	extends Omit<CrudDataGridProps<RowData>, "rows" | "refreshData">,
		RefreshableElementProps {
	fetchData: () => Promise<RowData[]>;
}

export const AsyncCrudDataGrid = <RowData extends object>({
	refreshRef,
	fetchData,
	...other
}: AsyncCrudDataGridProps<RowData>) => {
	const [asyncRows, refresh] = useAsyncFetch(fetchData);
	setRefreshRefValue(refreshRef, refresh);
	return <ControlledAsyncCrudDataGrid rowsAsync={asyncRows} refreshData={refresh} {...other} />;
};
