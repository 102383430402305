import { CostEventsOfObjectDataGridApi } from "src/api/generated/erp/common/costEvents/costEventsOfObjectDataGridApi.ts";
import { dateColumn, dateTimeColumn, textColumn } from "../../../../common/dataGrid/columns.tsx";
import i18n from "i18next";
import { CostEventView } from "src/api/generated/erp/db/types/tables/costEventView.ts";
import { CostEventObjectRef } from "src/api/generated/erp/common/costEvents/costEventObjectRef.ts";
import { CostEventForm } from "./CostEventForm.tsx";
import { CrudDataGrid } from "../../../../common/dataGrid/crud/CrudDataGrid.tsx";
import { Typography } from "@mui/material";
import { HorizontalBox } from "src/components/common/box/HorizontalBox.tsx";
import { formatMoney } from "src/utils/numberUtils.ts";
import { AavoGridColDef } from "src/components/common/dataGrid/AavoDataGrid.tsx";
import { ServerSideDataModelResult } from "src/api/generated/common/dataGrids/serverSideDataModelResult.ts";
import {
	ParamsWithDataModelRequest,
	ServerSideDataGridModel,
	ServerSideDataGridModelRenderProps,
} from "src/components/common/dataGrid/gridModel/ServerSideDataGridModel.tsx";
import React from "react";
import { logError } from "src/errorHandling/errorLogging.ts";

export interface CostEventsDataGridBaseProps<
	TRow extends CostEventView,
	TParams extends object,
	TApiResponse extends CostEventsDataGridApiResponse<TRow>,
> {
	fetchData: (params: ParamsWithDataModelRequest<TParams>) => Promise<TApiResponse>;
	initialParams: TParams;
	gridId: string;
	getRowCostEventPriceListId: (row: TRow) => number;
	disableEdit?: boolean;
	disableDelete?: boolean;
	insert?:
		| { type: "disabled" }
		| {
				type: "enabled";
				newEventObjectRef: CostEventObjectRef;
				newEventPriceListId: number;
		  };
	extraColumns?: AavoGridColDef<TRow>[];
	actionBarComponents?: (
		params: Pick<
			ServerSideDataGridModelRenderProps<TApiResponse, CostEventView, TParams>,
			"currentParams" | "refreshData"
		>,
	) => React.ReactNode;
}

interface CostEventsDataGridApiResponse<TRow extends CostEventView> {
	costEventsData: ServerSideDataModelResult<TRow>;
	totalCosts: number;
	totalPrice: number;
}

export const CostEventsDataGridBase = <
	TRow extends CostEventView,
	TParams extends object,
	TApiResponse extends CostEventsDataGridApiResponse<TRow>,
>({
	fetchData,
	disableEdit = false,
	disableDelete = false,
	insert: insertConfig,
	gridId,
	extraColumns,
	initialParams,
	getRowCostEventPriceListId,
	actionBarComponents,
}: CostEventsDataGridBaseProps<TRow, TParams, TApiResponse>) => {
	return (
		<ServerSideDataGridModel<TApiResponse, CostEventView, TParams>
			fetchData={fetchData}
			getDataModelResult={(data) => data.costEventsData}
			getRowId={(row) => row.costEventId}
			gridId={gridId}
			initialParams={initialParams}
			render={({ dataGridProps, currentParams, refreshData, data: { totalCosts, totalPrice } }) => (
				<CrudDataGrid<CostEventView>
					columns={[
						textColumn({
							headerName: i18n.t("item"),
							field: "itemName",
						}),
						textColumn({
							headerName: i18n.t("quantity"),
							field: "quantity",
						}),
						dateColumn({
							headerName: i18n.t("date_short"),
							field: "eventDate",
						}),
						textColumn({
							headerName: i18n.t("description"),
							field: "description",
							width: 400,
						}),
						textColumn({
							headerName: i18n.t("cost"),
							field: "cost",
						}),
						textColumn({
							headerName: i18n.t("price"),
							field: "price",
						}),
						textColumn({
							headerName: i18n.t("total_cost"),
							field: "totalCost",
							width: 140,
						}),
						textColumn({
							headerName: i18n.t("total_price"),
							field: "totalPrice",
						}),
						textColumn({
							headerName: i18n.t("vat_code"),
							field: "vatCodeName",
						}),
						textColumn({
							headerName: i18n.t("created_by"),
							field: "createdByUserName",
						}),
						dateTimeColumn({
							headerName: i18n.t("created_at"),
							field: "createdAt",
							width: 150,
						}),
						// Can't make TypeScript happy here.
						(extraColumns ?? []) as AavoGridColDef<CostEventView>[],
					]}
					form={{
						dialogSize: "sm",
						dialogTitle: i18n.t("cost_event"),
						editEnabled: !disableEdit,
						addRowEnabled: insertConfig?.type === "enabled",
						component: ({ row, onCompleted, onFormEdited }) => {
							let priceListId;
							if (row != null) {
								priceListId = getRowCostEventPriceListId(row as TRow);
							} else if (insertConfig?.type === "enabled") {
								priceListId = insertConfig.newEventPriceListId;
							} else {
								logError("Row and insertConfig are both null when trying to open form");
								return;
							}

							return (
								<CostEventForm
									costEventId={row?.costEventId}
									costEventPriceListId={priceListId}
									onCompleted={onCompleted}
									onFormEdited={onFormEdited}
									newCostEventObjectRef={
										insertConfig?.type === "enabled" ?
											insertConfig.newEventObjectRef
										:	undefined
									}
								/>
							);
						},
					}}
					remove={
						!disableDelete && {
							type: "enabled",
							action: ({ items }) =>
								CostEventsOfObjectDataGridApi.delete_({ costEvents: items }),
						}
					}
					actionBarComponents={
						<>
							{actionBarComponents?.({ currentParams, refreshData })}
							<HorizontalBox alignItems="center" gap={3} ml={1}>
								<Typography>{`${i18n.t("price")} ${formatMoney(totalPrice)}`}</Typography>
								<Typography>{`${i18n.t("cost")} ${formatMoney(totalCosts)}`}</Typography>
							</HorizontalBox>
						</>
					}
					{...dataGridProps}
				/>
			)}
		/>
	);
};
