import {
	CostEventItemApi,
	CostEventItemApi_FormInitData,
} from "src/api/generated/erp/common/costEvents/costEventItemApi";
import { CostEventItem } from "src/api/generated/erp/db/types/tables/costEventItem";
import { AsyncForm } from "src/components/common/forms/AsyncForm";
import { FormCommonProps } from "src/components/common/forms/types.ts";
import i18n from "i18next";
import { requireRule } from "src/components/common/forms/validation.ts";
import { FormSelectField } from "src/components/common/forms/fields/FormSelectField.tsx";
import { FormTextField } from "src/components/common/forms/fields/FormTextField.tsx";

export interface CostEventItemFormProps extends FormCommonProps<number> {
	costEventItemId: number | null;
}

export const CostEventItemForm = ({
	costEventItemId,
	onCompleted,
	onFormEdited,
}: CostEventItemFormProps) => {
	return (
		<AsyncForm<CostEventItemApi_FormInitData, CostEventItem, number>
			fetch={() => CostEventItemApi.getFormInitData({ costEventItemId })}
			getDefaultValues={({ costEventItem }) => {
				return costEventItem ?? {};
			}}
			onCompleted={onCompleted}
			onFormEdited={onFormEdited}
			render={({ data: { vatCodeOptions }, control }) => (
				<>
					<FormTextField
						control={control}
						name="itemName"
						label={i18n.t("name")}
						rules={requireRule()}
					/>
					<FormSelectField
						control={control}
						name="vatCodeId"
						label={i18n.t("vat_code")}
						rules={requireRule()}
						options={vatCodeOptions}
						getOptionKey={(option) => option.vatCodeId}
						getOptionLabel={(option) => option.vatCodeName}
					/>
				</>
			)}
			submit={async (values) => {
				if (costEventItemId == null) {
					return await CostEventItemApi.insert({ costEventItem: values });
				} else {
					await CostEventItemApi.update({ costEventItem: values });
					return costEventItemId;
				}
			}}
		/>
	);
};
