// This file is automatically generated. Do not edit manually.

import { Reclamation } from "src/api/generated/erp/db/types/tables/reclamation";
import { ReclamationState } from "src/api/generated/erp/db/types/enums/reclamationState";
import { ServerSideDataModelRequest } from "src/api/generated/common/dataGrids/serverSideDataModelRequest";
import { ServerSideDataModelResult } from "src/api/generated/common/dataGrids/serverSideDataModelResult";
import { ReclamationView } from "src/api/generated/erp/db/types/tables/reclamationView";
import { ErpAppUser } from "src/api/generated/erp/db/types/tables/erpAppUser";
import { ReclamationCategory } from "src/api/generated/erp/db/types/tables/reclamationCategory";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace ReclamationQueryApi {
	export async function getReclamationOptions(args: {
		currentSelection: number | null | undefined;
		searchQuery: string;
		notConnectedToCustomerOrderId?: number | null | undefined;
		siteId?: number | null | undefined;
		limit?: number;
	}): Promise<Array<Reclamation>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/reclamations/getReclamationsOptions",
			method: "POST",
			data: {
				currentSelection: args.currentSelection,
				searchQuery: args.searchQuery,
				notConnectedToCustomerOrderId: args.notConnectedToCustomerOrderId,
				siteId: args.siteId,
				limit: args.limit,
			},
		});
		return response as Array<Reclamation>;
	}

	export async function getReclamations(args: {
		states?: Array<ReclamationState>;
		reclamationId?: number | null | undefined;
		siteId?: number | null | undefined;
		customerOrderId?: number | null | undefined;
		responsiblePersonId?: number | null | undefined;
		searchQuery?: string;
		categoryIds?: Array<number>;
		dataModelRequest: ServerSideDataModelRequest;
	}): Promise<ServerSideDataModelResult<ReclamationView>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/reclamations/getReclamations",
			method: "POST",
			data: {
				states: args.states,
				reclamationId: args.reclamationId,
				siteId: args.siteId,
				customerOrderId: args.customerOrderId,
				responsiblePersonId: args.responsiblePersonId,
				searchQuery: args.searchQuery,
				categoryIds: args.categoryIds,
				dataModelRequest: args.dataModelRequest,
			},
		});
		return response as ServerSideDataModelResult<ReclamationView>;
	}

	export async function getResponsiblePersonOptions(): Promise<Array<ErpAppUser>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/reclamations/getResponsiblePersonOptions",
			method: "POST",
			data: {},
		});
		return response as Array<ErpAppUser>;
	}

	export async function getReclamationCategoryOptions(): Promise<Array<ReclamationCategory>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/reclamations/getReclamationCategoryOptions",
			method: "POST",
			data: {},
		});
		return response as Array<ReclamationCategory>;
	}
}
