import {booleanColumn, floatColumn, integerColumn, textColumn,} from "src/components/common/dataGrid/columns.tsx";
import i18n from "i18next";
import {useClientSideDataGridModel} from "src/components/common/dataGrid/gridModel/useClientSideDataGridModel";
import {ControlledAsyncCrudDataGrid} from "src/components/common/dataGrid/crud/ControlledAsyncCrudDataGrid.tsx";
import {PurchaseOrderReceptionApi} from "src/api/generated/erp/purchase/purchaseOrder/api/purchaseOrderReceptionApi.ts";
import {PurchaseOrderReceiptLineView} from "src/api/generated/erp/db/types/tables/purchaseOrderReceiptLineView";
import {concatWithPipe} from "src/utils/strings.tsx";
import {RefreshableElementProps, RefreshableElementRef} from "src/utils/useRefreshRef.ts";
import {PurchaseOrderReceipt} from "src/api/generated/erp/db/types/tables/purchaseOrderReceipt.ts";

export interface PurchaseOrderReceiptLinesDataGridProps extends RefreshableElementProps {
	purchaseOrderReceipt: PurchaseOrderReceipt;
	editEnabled?: boolean;
	purchaseOrderLinesToReceiveViewRefreshRef?: RefreshableElementRef;
}

export const PurchaseOrderReceiptLinesDataGrid = ({
	purchaseOrderReceipt,
	editEnabled = false,
	refreshRef,
	purchaseOrderLinesToReceiveViewRefreshRef,
}: PurchaseOrderReceiptLinesDataGridProps) => {
	const { dataGridProps } = useClientSideDataGridModel({
		fetchData: () =>
			PurchaseOrderReceptionApi.getPurchaseOrderReceiptLines({
				purchaseOrderReceiptId: purchaseOrderReceipt.purchaseOrderReceiptId,
			}),
		getRowId: (row) => row.purchaseOrderReceiptLineId,
		gridId: "752688624A18A965",
		initialParams: {},
		refreshRef: refreshRef,
	});

	return (
		<ControlledAsyncCrudDataGrid<PurchaseOrderReceiptLineView>
			columns={[
				textColumn({
					field: "partWarehouseLocationCode",
					headerName: i18n.t("warehouse_location"),
				}),
				{
					field: "part",
					headerName: i18n.t("part"),
					valueGetter: (_, row) => concatWithPipe(row.partNo, row.purchasePartDescription),
					width: 200,
				},
				floatColumn({
					field: "receivedQuantityInPurchaseUnit",
					headerName: i18n.t("received_quantity"),
					width: 120,
				}),
				textColumn({
					field: "purchaseUnit",
					headerName: i18n.t("purchase_unit"),
					width: 120,
				}),
				booleanColumn({
					field: "fullyReceived",
					headerName: i18n.t("fully_received"),
					width: 140,
				}),
				textColumn({
					field: "note",
					headerName: i18n.t("note"),
					width: 200,
				}),
				integerColumn({
					field: "purchaseOrderReceiptLineId",
					headerName: i18n.t("id"),
				}),
			]}
			remove={
				!editEnabled ? { type: "hidden" }
				: purchaseOrderReceipt.purchaseOrderReceiptState === "RECEIPTED" ?
					{
						type: "disabled",
						tooltip: i18n.t("receipt_is_already_marked_as_completed"),
					}
				:	{
						type: "enabled",
						action: async ({ items }) => {
							await PurchaseOrderReceptionApi.deletePurchaseOrderReceiptLines({
								purchaseOrderReceiptLineIds: items.map(
									(item) => item.purchaseOrderReceiptLineId,
								),
							});
							purchaseOrderLinesToReceiveViewRefreshRef?.refresh();
						},
					}

			}
			{...dataGridProps}
		/>
	);
};
