// This file is automatically generated. Do not edit manually.

import { t } from "i18next";

export const ReclamationAutomaticClosingValues = [
	"NONE",
	"SEND_EMAIL_WHEN_TASKS_COMPLETED",
	"CLOSE_WHEN_TASKS_COMPLETED",
] as const;

export type ReclamationAutomaticClosing = (typeof ReclamationAutomaticClosingValues)[number];

export const getReclamationAutomaticClosingLabels = () => ({
	NONE: t("none"),
	SEND_EMAIL_WHEN_TASKS_COMPLETED: t("send_email_when_tasks_completed"),
	CLOSE_WHEN_TASKS_COMPLETED: t("close_when_tasks_completed"),
});

export const getReclamationAutomaticClosingLabel = (value: ReclamationAutomaticClosing): string => {
	return getReclamationAutomaticClosingLabels()[value];
};
