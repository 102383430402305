import { useEffect, useRef } from "react";
import { Box } from "@mui/system";
import { logError } from "src/errorHandling/errorLogging.ts";

export const ThreeJsView = ({ fileUrl }: { fileUrl: string }) => {
	const containerRef = useRef<HTMLDivElement>(null);
	const canvasRef = useRef<HTMLCanvasElement>(null);

	useEffect(() => {
		if (!containerRef.current || !canvasRef.current) return;

		canvasRef.current.innerHTML = "";
		initialize(fileUrl, containerRef.current, canvasRef.current).catch(logError);
	}, [fileUrl]);

	return (
		<Box
			ref={containerRef}
			sx={{
				flex: 1,
			}}
		>
			<canvas ref={canvasRef} />
		</Box>
	);
};

const initialize = async (sourceUrl: string, container: HTMLDivElement, canvas: HTMLCanvasElement) => {
	const { IFCLoader } = await import("web-ifc-three");
	const THREE = await import("three");
	const { OrbitControls } = await import("three/examples/jsm/controls/OrbitControls.js");

	const canvasWidth = container.offsetWidth;
	const canvasHeight = container.offsetHeight;

	// scene
	const scene = new THREE.Scene();
	scene.background = new THREE.Color(0xbfe3dd);

	// camera
	const camera = new THREE.PerspectiveCamera(75, canvasWidth / canvasHeight);
	camera.position.z = 15;
	camera.position.y = 13;
	camera.position.x = 8;

	// lights
	const lightColor = 0xffffff;

	const ambientLight = new THREE.AmbientLight(lightColor, 0.5);
	scene.add(ambientLight);

	const directionalLight = new THREE.DirectionalLight(lightColor, 0.8);
	directionalLight.position.set(1, 1, 1);
	scene.add(directionalLight);

	// renderer
	const renderer = new THREE.WebGLRenderer({
		canvas: canvas,
		alpha: true,
	});
	renderer.setSize(canvasWidth, canvasHeight);
	renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));

	const renderCanvas = function () {
		renderer.render(scene, camera);
	};

	// controls
	const controls = new OrbitControls(camera, canvas);
	controls.enableDamping = true;
	controls.addEventListener("change", () => {
		renderCanvas();
	});

	// load model
	const ifcLoader = new IFCLoader();
	await ifcLoader.ifcManager.setWasmPath("/static/ifc/wasm/");
	ifcLoader.load(sourceUrl, (ifcModel) => {
		scene.add(ifcModel);
		renderCanvas();
	});
};
