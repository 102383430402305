// This file is automatically generated. Do not edit manually.

import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace ResourceItemChartsApi {
	export async function disconnectChartFromItem(args: { itemId: number; chartId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/spc/resourceItemCharts/disconnectChartFromItem",
			method: "POST",
			data: {
				itemId: args.itemId,
				chartId: args.chartId,
			},
		});
		return response as void;
	}
}
