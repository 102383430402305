import { AavoMosaic } from "src/components/common/mosaic/AavoMosaic.tsx";
import i18n from "i18next";
import React from "react";
import {
	DeliverySourcePickingDataGrid,
	DeliverySourcePickingDataGridProps,
} from "src/components/views/erp/delivery/deliveryPackage/picking/DeliverySourcePickingDataGrid.tsx";
import { DeliveryPackageView } from "src/api/generated/erp/db/types/tables/deliveryPackageView.ts";
import { CenteredTypography } from "src/components/common/CenteredTypography.tsx";
import { DeliveryPackageLinesDataGrid } from "src/components/views/erp/delivery/deliveryPackage/DeliveryPackageLinesDataGrid.tsx";
import { useRefreshRef } from "src/utils/useRefreshRef.ts";
import { useInterval } from "src/utils/useInterval.ts";
import { DeliverySource } from "src/api/generated/erp/delivery/model/deliverySource.ts";
import { DeliveryPackagesDataGrid } from "../DeliveryPackagesDataGrid.tsx";
import { AavoMosaicTabItem } from "src/components/common/mosaic/types.ts";
import { DeliverySourcePickingViewContext } from "./DeliverySourcePickingViewContext";

export interface DeliverySourcePickingViewProps {
	deliverySource: DeliverySource;
	deliverySourceHasAutomaticReservation: boolean;
	reservationView: AavoMosaicTabItem;
	revertReservation: DeliverySourcePickingDataGridProps["revertReservation"];
}

export const DeliverySourcePickingView = ({
	deliverySource,
	deliverySourceHasAutomaticReservation,
	reservationView,
	revertReservation,
}: DeliverySourcePickingViewProps) => {
	const [selectedPackage, setSelectedPackage] = React.useState<DeliveryPackageView | undefined>(undefined);

	const reservableLinesViewRefreshRef = useRefreshRef();
	const pickableLinesViewRefreshRef = useRefreshRef();
	const pickedLinesViewRefreshRef = useRefreshRef();

	useInterval(async () => {
		await Promise.all([reservableLinesViewRefreshRef.refresh(), pickableLinesViewRefreshRef.refresh()]);
	}, 60 * 1000);

	return (
		<DeliverySourcePickingViewContext.Provider
			value={{
				reservableLinesViewRefreshRef,
				pickableLinesViewRefreshRef,
				pickedLinesViewRefreshRef,
			}}
		>
			<AavoMosaic
				viewId={"C2FFEDB0721F4F07"}
				layout={{
					type: "column",
					first: {
						type: "panel",
						title: i18n.t("incomplete_packages"),
						content: (
							<DeliveryPackagesDataGrid
								gridId={"C2FB309364222769"}
								addEnabled
								editable
								deliverySource={deliverySource}
								packageStates={["INITIAL", "COMPLETED"]}
								onSelectedPackageChanged={(selectedPackages) => setSelectedPackage(selectedPackages[0])}
								selectedRows={selectedPackage == undefined ? [] : [selectedPackage]}
								showSourceDocumentsButton
								showPrintPackageLabelButton
								showCalculateWeightButton
								showStateChangeActions
							/>
						),
					},
					second: {
						type: "tabs",
						items: [
							reservationView,
							{
								type: "panel",
								title: i18n.t("pickable_lines"),
								content: (
									<DeliverySourcePickingDataGrid
										deliverySource={deliverySource}
										deliveryPackage={selectedPackage}
										refreshRef={pickableLinesViewRefreshRef}
										pickedLinesViewRefreshRef={pickedLinesViewRefreshRef}
										reservableLinesViewRefreshRef={reservableLinesViewRefreshRef}
										deliverySourceHasAutomaticReservation={deliverySourceHasAutomaticReservation}
										revertReservation={revertReservation}
									/>
								),
							},
							{
								type: "panel",
								title: i18n.t("package_lines"),
								content:
									!selectedPackage ?
										<CenteredTypography>{i18n.t("select_package")}</CenteredTypography>
									:	<DeliveryPackageLinesDataGrid
											key={selectedPackage.deliveryPackageId}
											deliveryPackage={selectedPackage}
											pickableLinesViewRefreshRef={pickableLinesViewRefreshRef}
											refreshRef={pickedLinesViewRefreshRef}
										/>,
							},
						],
					},
				}}
			/>
		</DeliverySourcePickingViewContext.Provider>
	);
};
