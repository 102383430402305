// This file is automatically generated. Do not edit manually.

import { t } from "i18next";

export const CurrencyValues = ["EUR"] as const;

export type Currency = (typeof CurrencyValues)[number];

export const getCurrencyLabels = () => ({
	EUR: t("eur"),
});

export const getCurrencyLabel = (value: Currency): string => {
	return getCurrencyLabels()[value];
};
