// This file is automatically generated. Do not edit manually.

import { CustomerGroup } from "src/api/generated/erp/db/types/tables/customerGroup";
import { ObjectAccountingDimensionValue } from "src/api/generated/erp/sales/accounting/objectAccountingDimensionValue";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace CustomerGroupEditApi {
	export async function getFormInitData(args: {
		customerGroupId: number | null | undefined;
	}): Promise<CustomerGroupEditApi_FormInitData> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/customerGroups/getFormInitData",
			method: "POST",
			data: {
				customerGroupId: args.customerGroupId,
			},
		});
		return response as CustomerGroupEditApi_FormInitData;
	}

	export async function insert(args: {
		customerGroup: CustomerGroup;
		accountingCodeIds: Array<number>;
	}): Promise<number> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/customerGroups/insert",
			method: "POST",
			data: {
				customerGroup: args.customerGroup,
				accountingCodeIds: args.accountingCodeIds,
			},
		});
		return response as number;
	}

	export async function update(args: {
		customerGroup: CustomerGroup;
		accountingCodeIds: Array<number>;
	}): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/customerGroups/update",
			method: "POST",
			data: {
				customerGroup: args.customerGroup,
				accountingCodeIds: args.accountingCodeIds,
			},
		});
		return response as void;
	}

	export async function delete_(args: { customerGroupIds: Array<number> }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/customerGroups/delete",
			method: "POST",
			data: {
				customerGroupIds: args.customerGroupIds,
			},
		});
		return response as void;
	}
}

export interface CustomerGroupEditApi_FormInitData {
	accountingDimensions: Array<ObjectAccountingDimensionValue>;
	customerGroup: CustomerGroup | null | undefined;
}
