// This file is automatically generated. Do not edit manually.

import { ResourceStateChartView } from "src/api/generated/postgres/db/types/ext_resourcing/tables/resourceStateChartView";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace ResourceStateChartsApi {
	export async function getGridData(args: { resourceId: number }): Promise<Array<ResourceStateChartView>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/spc/resourceStateCharts/getGridData",
			method: "POST",
			data: {
				resourceId: args.resourceId,
			},
		});
		return response as Array<ResourceStateChartView>;
	}
}
