import { PartialTenantCustomizations } from "src/tenantCustomizations/TenantCustomizations.ts";

export const uponorCustomizations: PartialTenantCustomizations = {
	tenantConfig: {
		tasks: {
			enabled: false,
		},
		documentsEnabled: false,
	},
} as const;
