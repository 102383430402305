import { useMediaQuery, useTheme } from "@mui/material";
import { Breakpoint } from "@mui/system";

export interface BreakpointStates {
	xs: boolean;
	sm: boolean;
	md: boolean;
	lg: boolean;
	xl: boolean;
}

export const useMediaQueryForAllBreakpointsUp = (): BreakpointStates => {
	const theme = useTheme();
	const xs = useMediaQuery(theme.breakpoints.up("xs"));
	const sm = useMediaQuery(theme.breakpoints.up("sm"));
	const md = useMediaQuery(theme.breakpoints.up("md"));
	const lg = useMediaQuery(theme.breakpoints.up("lg"));
	const xl = useMediaQuery(theme.breakpoints.up("xl"));
	return { xs, sm, md, lg, xl };
};

export const useMediaQueryForAllBreakpointsDown = (): BreakpointStates => {
	const theme = useTheme();
	const xs = useMediaQuery(theme.breakpoints.down("xs"));
	const sm = useMediaQuery(theme.breakpoints.down("sm"));
	const md = useMediaQuery(theme.breakpoints.down("md"));
	const lg = useMediaQuery(theme.breakpoints.down("lg"));
	const xl = useMediaQuery(theme.breakpoints.down("xl"));
	return { xs, sm, md, lg, xl };
};

export const useMediaQueryBreakpointUp = (breakpoint: Breakpoint): boolean => {
	const theme = useTheme();
	return useMediaQuery(theme.breakpoints.up(breakpoint));
};

export const useMediaQueryBreakpointDown = (breakpoint: Breakpoint): boolean => {
	const theme = useTheme();
	return useMediaQuery(theme.breakpoints.down(breakpoint));
};

export const getLargestActiveBreakpoint = (states: BreakpointStates): Breakpoint => {
	if (states.xl) return "xl";
	if (states.lg) return "lg";
	if (states.md) return "md";
	if (states.sm) return "sm";
	return "xs";
};
