import { useState } from "react";
import { SalesPriceList } from "src/api/generated/erp/db/types/tables/salesPriceList.ts";
import { AavoMosaic } from "src/components/common/mosaic/AavoMosaic.tsx";
import i18n from "i18next";
import { SalesPriceListsDataGrid } from "src/components/views/erp/sales/pricing/salesPriceLists/SalesPriceListsDataGrid.tsx";
import { SalesPriceListLinesDataGrid } from "src/components/views/erp/sales/pricing/salesPriceLists/SalesPriceListLinesDataGrid.tsx";
import { CenteredTypography } from "src/components/common/CenteredTypography.tsx";

export interface SalesPriceListsViewProps {}

export const SalesPriceListsView = ({}: SalesPriceListsViewProps) => {
	const [selectedPriceList, setSelectedPriceList] = useState<SalesPriceList | undefined>(undefined);

	return (
		<AavoMosaic
			viewId={"59658D05962A494A"}
			layout={{
				type: "row",
				splitPercentage: 35,
				first: {
					type: "panel",
					title: i18n.t("price_lists"),
					content: <SalesPriceListsDataGrid onSelectionChanged={setSelectedPriceList} />,
				},
				second: {
					type: "panel",
					title: i18n.t("price_list_lines"),
					content:
						selectedPriceList == null ?
							<CenteredTypography children={i18n.t("select_a_price_list")} />
						:	<SalesPriceListLinesDataGrid
								key={selectedPriceList.salesPriceListId}
								salesPriceListId={selectedPriceList.salesPriceListId}
							/>,
				},
			}}
		/>
	);
};
