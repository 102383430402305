// This file is automatically generated. Do not edit manually.

import { t } from "i18next";

export const ProductChangeRequestPriorityValues = ["NORMAL", "HIGH"] as const;

export type ProductChangeRequestPriority = (typeof ProductChangeRequestPriorityValues)[number];

export const getProductChangeRequestPriorityLabels = () => ({
	NORMAL: t("normal"),
	HIGH: t("high"),
});

export const getProductChangeRequestPriorityLabel = (value: ProductChangeRequestPriority): string => {
	return getProductChangeRequestPriorityLabels()[value];
};
