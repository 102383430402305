import { AsyncDataGrid } from "src/components/common/dataGrid/AsyncDataGrid";
import { TestConfigurationResultViewCommonProps } from "../TestConfigurationResultViewCommonProps";
import {
	TestConfigurationAttributesApi,
	TestConfigurationAttributesApi_Row,
} from "src/api/generated/erp/configurator/api/configuratorTesting/testConfigurationAttributesApi.ts";
import i18n from "i18next";
import { dataGridPersistentStateProps } from "src/components/common/dataGrid/dataGridStateStorage.ts";
import {
	attributeValueToUserFriendlyString,
	decodeObjectAttributeValue,
} from "src/components/views/erp/objectAttributes/objectAttributeValueConverting.ts";

export const TestConfigurationAttributeResultsDataGrid = ({
	configurationSessionId,
	configuratorType,
	propertyValues,
}: TestConfigurationResultViewCommonProps) => {
	return (
		<AsyncDataGrid<TestConfigurationAttributesApi_Row>
			fetchRows={() =>
				TestConfigurationAttributesApi.getTestConfigurationAttributes({
					configurationSessionId,
					configuratorType,
					propertyValues,
				})
			}
			getRowId={(row) => row.attributeField.id}
			columns={[
				{
					headerName: i18n.t("attribute"),
					field: "attribute",
					width: 120,
					valueGetter: (_, row) => row.attributeField.label,
				},
				{
					headerName: i18n.t("value"),
					field: "value",
					width: 200,
					valueGetter: (_, row) =>
						attributeValueToUserFriendlyString(
							decodeObjectAttributeValue(row.attributeValue ?? null, row.attributeField.type),
							row.attributeField.type,
						),
				},
			]}
			{...dataGridPersistentStateProps("AE602DFB73807648")}
		/>
	);
};
