import {AavoForm} from "src/components/common/forms/AavoForm.tsx";
import {FormCommonProps} from "src/components/common/forms/types.ts";
import {FormNumberField} from "src/components/common/forms/fields/FormNumberField.tsx";
import i18n from "i18next";
import {
    SalesContractSalesPartsDataGridApi
} from "src/api/generated/erp/sales/pricing/api/salesContractSalesPartsDataGridApi.ts";

export interface SalesContractSalesPartPriceFormProps extends FormCommonProps<number> {
	salesContractSalesPartId: number;
	price: number;
}

interface FormValues {
	price: number;
}

export const SalesContractSalesPartPriceForm = ({
	salesContractSalesPartId,
	price,
	onCompleted,
	onFormEdited,
}: SalesContractSalesPartPriceFormProps) => {
	return (
		<AavoForm<FormValues, number>
			onCompleted={onCompleted}
			onFormEdited={onFormEdited}
			defaultValues={{ price: price }}
			render={({ control }) => <FormNumberField control={control} name={"price"} label={i18n.t("price")} />}
			submit={async (values) => {
				await SalesContractSalesPartsDataGridApi.update({
					salesContractSalesPartId: salesContractSalesPartId,
					price: values.price,
				});
				return salesContractSalesPartId;
			}}
		/>
	);
};
