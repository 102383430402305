import { useFileInputDnd } from "src/components/common/inputFields/fileInput/useFileInputDnd.tsx";
import { useForwardedRef } from "src/utils/useForwardedRef";
import { AavoButton, AavoButtonProps } from "src/components/common/buttons/AavoButton.tsx";
import React, { useState } from "react";
import { Typography } from "@mui/material";
import { useErrorDialog } from "src/components/common/dialogs/errorDialog/userErrorDialog.ts";

export interface AavoAsyncFileInputButtonProps<TMultiple extends boolean>
	extends Omit<AavoButtonProps, "loading" | "onClick" | "onSubmit"> {
	multiple: TMultiple;
	onSubmit: (value: TMultiple extends true ? File[] : File) => Promise<unknown>;
}

export const AavoAsyncFileInputButton = <TMultiple extends boolean>({
	ref: forwardedRef,
	multiple,
	onSubmit,
	children,
	sx,
	label,
	...other
}: AavoAsyncFileInputButtonProps<TMultiple>) => {
	const ref = useForwardedRef(forwardedRef);

	const [submitLoading, setSubmitLoading] = useState<boolean>(false);
	const { logErrorAndShowOnDialog } = useErrorDialog();

	const submitInternal = async (files: File[]) => {
		setSubmitLoading(true);
		try {
			if (multiple) await onSubmit(files as TMultiple extends true ? File[] : File);
			else await onSubmit(files[0] as TMultiple extends true ? File[] : File);
		} catch (e) {
			logErrorAndShowOnDialog(e);
		} finally {
			setSubmitLoading(false);
		}
	};

	const { isDraggingOver } = useFileInputDnd({ ref, onDrop: submitInternal });

	const inputId = React.useId();

	return (
		<AavoButton
			ref={ref}
			loading={submitLoading}
			sx={{
				...sx,
				backgroundColor: isDraggingOver ? "lightgrey" : "transparent",
				"& > label": {
					flex: 1,
					textAlign: "left",
					marginBottom: 0,
				},
			}}
			{...other}
		>
			<label htmlFor={inputId}>
				<input
					id={inputId}
					type="file"
					multiple={multiple}
					hidden
					onChange={async (e) => {
						const files = Array.from(e.target.files ?? []);
						await submitInternal(files);
					}}
				/>
				{label ?
					<Typography
						variant={"button"}
						sx={{
							flex: 1,
							textAlign: "left",
						}}
					>
						{label}
					</Typography>
				:	children}
			</label>
		</AavoButton>
	);
};
