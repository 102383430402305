// This file is automatically generated. Do not edit manually.

import { DeliveryMethod } from "src/api/generated/erp/db/types/tables/deliveryMethod";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace DeliveryMethodApi {
	export async function getAll(): Promise<Array<DeliveryMethod>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/warehouse/basedata/deliveryMethodDataGrid/getAll",
			method: "POST",
			data: {},
		});
		return response as Array<DeliveryMethod>;
	}

	export async function save(args: { deliveryMethods: Array<DeliveryMethod> }): Promise<Array<DeliveryMethod>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/warehouse/basedata/deliveryMethodDataGrid/save",
			method: "POST",
			data: {
				deliveryMethods: args.deliveryMethods,
			},
		});
		return response as Array<DeliveryMethod>;
	}

	export async function delete_(args: { deliveryMethods: Array<DeliveryMethod> }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/warehouse/basedata/deliveryMethodDataGrid/delete",
			method: "POST",
			data: {
				deliveryMethods: args.deliveryMethods,
			},
		});
		return response as void;
	}
}
