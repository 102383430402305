import {
	SitePartSupplierEditApi,
	SitePartSupplierEditApi_InitData,
} from "src/api/generated/erp/parts/sitePart/api/sitePartSupplierEditApi";
import { AsyncForm } from "src/components/common/forms/AsyncForm.tsx";
import { FormCommonProps } from "src/components/common/forms/types.ts";
import { SupplierPart } from "src/api/generated/erp/db/types/tables/supplierPart.ts";
import { FormSelectField } from "src/components/common/forms/fields/FormSelectField.tsx";
import i18n from "i18next";
import { FormAsyncSelectField } from "src/components/common/forms/fields/FormAsyncSelectField.tsx";
import { FormNumberField } from "src/components/common/forms/fields/FormNumberField.tsx";
import { FormTextField } from "src/components/common/forms/fields/FormTextField.tsx";
import { FormCheckbox } from "src/components/common/forms/fields/FormCheckbox.tsx";
import { FetchAsyncOptionParams } from "src/components/common/inputFields/AsyncSelectField.tsx";
import { requireRule } from "src/components/common/forms/validation.ts";

export interface SitePartSupplierFormProps extends FormCommonProps<number> {
	partId: number;
	supplierPartId?: number;
}

export const SitePartSupplierForm = ({
	partId,
	supplierPartId,
	onCompleted,
	onFormEdited,
}: SitePartSupplierFormProps) => {
	return (
		<AsyncForm<SitePartSupplierEditApi_InitData, SupplierPart, number>
			columns={2}
			fetch={() =>
				SitePartSupplierEditApi.getInitData({
					partId: partId,
					supplierPartId: supplierPartId,
				})
			}
			getDefaultValues={(initData) => {
				const part = initData.part;
				return initData.supplierPart != null ?
						initData.supplierPart
					:	{
							purchaseUnitId: part.partUnitId,
							purchaseFactor: 1,
							purchasePriceUnitId: part.partUnitId,
							purchasePriceUnitFactor: 1,
							purchasePrice: 1,
							purchasePriceFactor: 1,
							packSize: 1,
							isDefault: false,
							deliveryDuration: 1,
						};
			}}
			onCompleted={onCompleted}
			onFormEdited={onFormEdited}
			render={({ data: { erpUnitOptions }, control }) => (
				<>
					<FormAsyncSelectField
						control={control}
						name={"supplierId"}
						label={i18n.t("supplier")}
						fetchOptions={({
							searchQuery,
							currentSelection,
						}: FetchAsyncOptionParams<number>) =>
							SitePartSupplierEditApi.getSupplierOptions({
								searchQuery: searchQuery,
								currentSelectionId: currentSelection,
								partId: partId,
								supplierPartId: supplierPartId,
							})
						}
						getOptionKey={(option) => option.supplierId}
						getOptionLabel={(option) => option.supplierName}
						rules={requireRule()}
					/>
					<FormSelectField
						control={control}
						name={"purchaseUnitId"}
						label={i18n.t("purchase_unit")}
						options={erpUnitOptions}
						getOptionKey={(option) => option.unitId}
						getOptionLabel={(option) => option.unitName}
					/>
					<FormNumberField
						control={control}
						name={"purchaseFactor"}
						label={i18n.t("purchase_factor")}
					/>

					<FormSelectField
						control={control}
						name={"purchasePriceUnitId"}
						label={i18n.t("purchase_price_unit")}
						options={erpUnitOptions}
						getOptionKey={(option) => option.unitId}
						getOptionLabel={(option) => option.unitName}
					/>
					<FormNumberField
						control={control}
						name={"purchasePriceFactor"}
						label={i18n.t("purchase_price_factor")}
					/>
					<FormNumberField
						control={control}
						name={"purchasePrice"}
						label={i18n.t("purchase_price")}
					/>
					<FormNumberField
						control={control}
						name={"packSize"}
						label={i18n.t("pack_size")}
					/>
					<FormTextField
						control={control}
						name={"supplierPartNo"}
						label={i18n.t("supplier_part_no")}
					/>
					<FormTextField
						control={control}
						name={"supplierPartDescription"}
						label={i18n.t("supplier_part_description")}
					/>
					<FormNumberField
						control={control}
						name={"deliveryDuration"}
						label={i18n.t("delivery_duration")}
					/>
					<FormCheckbox
						control={control}
						name={"isDefault"}
						label={i18n.t("default")}
						disabled
					/>
				</>
			)}
			submit={async (supplierPart) => {
				supplierPart.partId = partId;
				return await SitePartSupplierEditApi.save({
					supplierPart: supplierPart,
				});
			}}
		/>
	);
};
