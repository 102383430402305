import { TestConfigurationResultViewCommonProps } from "src/components/views/erp/configurator/configuratorTesting/TestConfigurationResultViewCommonProps.ts";
import i18n from "i18next";
import { AavoMosaic } from "src/components/common/mosaic/AavoMosaic";
import { ConfiguratorLogApi } from "src/api/generated/erp/configurator/api/configuratorLogApi.ts";
import { SitePartTestConfigurationOperationsDataGrid } from "src/components/views/erp/configurator/configuratorTesting/sitePart/SitePartTestConfigurationOperationsDataGrid.tsx";
import { ConfiguratorLogView } from "src/components/views/erp/configurator/configuratorTesting/logViews/ConfiguratorLogView.tsx";

export const SitePartTestConfigurationOperationsWithLogView = (
	props: TestConfigurationResultViewCommonProps,
) => {
	return (
		<AavoMosaic
			viewId={"BA17C8371ED45761"}
			layout={{
				type: "row",
				first: {
					type: "panel",
					title: i18n.t("operations"),
					content: <SitePartTestConfigurationOperationsDataGrid {...props} />,
				},
				second: {
					type: "panel",
					title: i18n.t("operation_log"),
					content: (
						<ConfiguratorLogView
							fetchLogFile={() =>
								ConfiguratorLogApi.getTestConfigurationPhaseResolvingLog({
									sessionId: props.configurationSessionId,
									phase: "OPERATIONS",
								})
							}
						/>
					),
				},
			}}
		/>
	);
};
