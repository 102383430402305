import {RefreshableElementRef} from "src/utils/useRefreshRef.ts";
import React from "react";

export interface CustomerOrdersPageContextValue {
	customerOrdersRefreshRef: RefreshableElementRef;
	customerOrderLinesRefreshRef: RefreshableElementRef;
	attributeViewRefreshRef: RefreshableElementRef;
	tasksViewRefreshRef: RefreshableElementRef;
	billingPlanViewRefreshRef: RefreshableElementRef;
	salesCommissionViewRefreshRef: RefreshableElementRef;
}

export const CustomerOrdersPageContext = React.createContext<
	CustomerOrdersPageContextValue | undefined
>(undefined);
