import {AsyncCrudDataGrid} from "../../../../common/dataGrid/crud/AsyncCrudDataGrid.tsx";
import {integerColumn, textColumn} from "../../../../common/dataGrid/columns.tsx";
import i18n from "i18next";
import {dataGridPersistentStateProps} from "../../../../common/dataGrid/dataGridStateStorage.ts";
import {CargoDriver} from "../../../../../api/generated/erp/db/types/tables/cargoDriver.ts";
import {CargoDriversApi} from "../../../../../api/generated/erp/warehouse/basedata/api/cargoDriversApi.ts";

export const CargoDriversDataGrid = () => {
	return (
		<AsyncCrudDataGrid<CargoDriver>
			fetchData={CargoDriversApi.getAll}
			getRowId={(row) => row.cargoDriverId}
			columns={[
				textColumn({
					field: "cargoDriverName",
					headerName: i18n.t("driver"),
					editable: true,
					validate: "required",
				}),
				textColumn({
					field: "phone",
					headerName: i18n.t("phone"),
					editable: true,
				}),
				integerColumn({
					field: "cargoDriverId",
					headerName: i18n.t("id"),
				}),
			]}
			save={{
				type: "enabled",
				action: async (params) => {
					return await CargoDriversApi.save({
						cargoDrivers: params.items,
					});
				},
			}}
			add={{
				type: "enabled",
				action: async () => {
					return {
						cargoDriverName: "",
						phone: "",
						lastUpdated: new Date().toISOString(),
					};
				},
			}}
			remove={{
				type: "enabled",
				action: async (params) => {
					await CargoDriversApi.delete_({
						cargoDrivers: params.items,
					});
				},
			}}
			{...dataGridPersistentStateProps("A2245DF42B6966CA")}
		/>
	);
};
