import i18n from "i18next";
import { CurrentUserApi } from "src/api/currentUserApi.ts";
import { useConfirmDialog } from "src/components/common/dialogs/confirmDialog/useConfirmDialog.ts";
import { AsyncButton } from "src/components/common/buttons/AsyncButton.tsx";
import { useMessageDialog } from "src/components/common/dialogs/messageDialog/useMessageDialog";
import { useGlobalInitData } from "src/contexts/useGlobalInitData.ts";

export const ResetMFAButton = () => {
	const userData = useGlobalInitData();
	const hidden = !userData.mfaIsEnabled;

	const confirm = useConfirmDialog();
	const showMessage = useMessageDialog();

	if (hidden) return null;

	return (
		<AsyncButton
			label={i18n.t("reset_mfa")}
			variant={"outlined"}
			onClick={async () => {
				const confirmed = await confirm({
					message: i18n.t("mfa_confirm_explanation"),
				});
				if (!confirmed) return;

				await CurrentUserApi.resetMfa();
				showMessage({
					title: i18n.t("success"),
					content: i18n.t("mfa_successfully_reset"),
				});
			}}
		/>
	);
};
