// This file is automatically generated. Do not edit manually.

import { t } from "i18next";

export const WarehouseReplenishmentMethodValues = ["WAREHOUSE_TRANSFER", "PURCHASE_REQUEST", "SHOP_ORDER"] as const;

export type WarehouseReplenishmentMethod = (typeof WarehouseReplenishmentMethodValues)[number];

export const getWarehouseReplenishmentMethodLabels = () => ({
	WAREHOUSE_TRANSFER: t("warehouse_transfer"),
	PURCHASE_REQUEST: t("purchase_request"),
	SHOP_ORDER: t("shop_order"),
});

export const getWarehouseReplenishmentMethodLabel = (value: WarehouseReplenishmentMethod): string => {
	return getWarehouseReplenishmentMethodLabels()[value];
};
