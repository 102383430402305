import {SalesCommissionView} from "src/api/generated/erp/db/types/tables/salesCommissionView.ts";
import {SalesCommission} from "src/api/generated/erp/db/types/tables/salesCommission.ts";
import {FormCommonProps} from "src/components/common/forms/types.ts";
import {AavoForm, AavoFormContentParams} from "src/components/common/forms/AavoForm.tsx";
import {requireRule} from "src/components/common/forms/validation.ts";
import {FormNumberField} from "src/components/common/forms/fields/FormNumberField.tsx";
import {FormTextField} from "src/components/common/forms/fields/FormTextField.tsx";
import {SalesCommissionApi} from "src/api/generated/erp/sales/salesCommissions/api/salesCommissionApi.ts";
import i18n from "i18next";

export interface SalesCommissionFormProps extends FormCommonProps<void> {
	salesCommission: SalesCommissionView;
}

interface FormValues extends SalesCommission {}

export const SalesCommissionForm = (props: SalesCommissionFormProps) => {
	const { onCompleted, onFormEdited, salesCommission } = props;
	return (
		<AavoForm<FormValues, void>
			defaultValues={salesCommission}
			submit={submit}
			onCompleted={onCompleted}
			onFormEdited={onFormEdited}
			render={(contentParams) => <FormContent {...props} {...contentParams} />}
		/>
	);

	async function submit(values: FormValues) {
		await SalesCommissionApi.updateSalesCommission({
			salesCommission: values,
		});
	}
};

interface FormContentProps extends SalesCommissionFormProps, AavoFormContentParams<FormValues> {}

const FormContent = ({ control }: FormContentProps) => {
	return (
		<>
			<FormTextField
				control={control}
				name={"note"}
				label={i18n.t("note")}
				multiline
			/>
			<FormNumberField
				control={control}
				name={"totalSum"}
				label={i18n.t("total_sum")}
				rules={requireRule()}
			/>
		</>
	);
};
