// This file is automatically generated. Do not edit manually.

import { CatalogPartRevisionLineView } from "src/api/generated/erp/db/types/tables/catalogPartRevisionLineView";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace CatalogPartReferencesDataGridApi {
	export async function getCatalogPartReferences(args: {
		catalogPartId: number;
	}): Promise<Array<CatalogPartRevisionLineView>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/productManagement/catalogPartReferences/getCatalogPartReferences",
			method: "POST",
			data: {
				catalogPartId: args.catalogPartId,
			},
		});
		return response as Array<CatalogPartRevisionLineView>;
	}
}
