import {TenantConfig, TenantCustomizations} from "src/tenantCustomizations/TenantCustomizations.ts";

export const DEFAULT_TENANT_CONFIG: TenantConfig = {
	erp: {
		enabled: false,
		salesEnabled: true,
		ledgerIntegrationEnabled: false,
		useCustomerOrderTenantStates: false,
		customerOrderTenantStates: [],
		customerOrderTenantStateColumnLabel: "",
		customerOrderFreezingEnabled: false,
		customerOrderCheckingEnabled: true,
		customerOrderCommentingEnabled: true,
		projectEnabled: true,
		reclamationsEnabled: false,
		customerOrderPreLineImportEnabled: false,
		configuratorEnabled: false,
		inputPricesWithVatByDefault: false,
		defaultCustomerType: "COMPANY",
		projectActivitySchedulingEnabled: false,
		surveysEnabled: false,
		purchaseEnabled: true,
		manufacturingEnabled: true,
	},
	spc: {
		enabled: true,
		processImprovementsEnabled: false,
	},
	tasks: {
		enabled: false,
	},
	maps: {
		enabled: false,
	},
	documentsEnabled: false,
} as const;

export const DEFAULT_TENANT_CUSTOMIZATIONS: TenantCustomizations = {
	tenantConfig: DEFAULT_TENANT_CONFIG,
	spcSamplingMethods: () => [],
	erp: {
		sales: {
			customerOrderDataGridCustomColumns: () => [],
		},
	},
} as const;
