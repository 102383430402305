import {
    PickersActionBar,
    DateTimePickerProps,
    DateTimePicker,
    PickerChangeHandlerContext,
} from "@mui/x-date-pickers-pro";
import {Dayjs} from "dayjs";
import {BaseTextFieldProps} from "@mui/material/TextField/TextField";
import React from "react";
import {mergeSx} from "src/utils/styles.ts";

export interface AavoDateTimePickerProps
    extends Omit<DateTimePickerProps<Dayjs>, "onChange">,
        Pick<BaseTextFieldProps, "size"> {
    errorMessage?: string;
    onChange?: (date: Dayjs | null) => void;
}

export const AavoDateTimePicker = React.forwardRef(
    (
        {
            slots,
            slotProps,
            size,
            errorMessage,
            onChange,
            displayWeekNumber = true,
            sx,
            ...other
        }: AavoDateTimePickerProps,
        ref: React.ForwardedRef<HTMLDivElement>,
    ) => {
        const slotPropsWrapped = {
            ...slotProps,
            textField: {
                ...{
                    size: size,
                    error: errorMessage !== undefined,
                    helperText: errorMessage,
                },
                ...slotProps?.textField,
            },
        };

        const onChangeWrapped = (value: Dayjs | null, context: PickerChangeHandlerContext<any>) => {
            const actualValue = context.validationError == null ? value : null;
            onChange?.(actualValue);
        };

        return (
            <DateTimePicker
                ref={ref}
                sx={mergeSx({
                    "& .MuiInputBase-root": {
                        minHeight: 0,
                    },
                    sx,
                })}
                onChange={onChangeWrapped}
                slotProps={slotPropsWrapped}
                displayWeekNumber={displayWeekNumber}
                slots={{
                    ...slots,
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    actionBar: ({actions, ...other}) => {
                        return (
                            <PickersActionBar actions={["today", "clear", "cancel", "accept"]} {...other} />
                        );
                    },
                }}
                {...other}
            />
        );
    },
);
