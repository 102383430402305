// This file is automatically generated. Do not edit manually.

import { ObjectCommentObjectType } from "src/api/generated/erp/db/types/enums/objectCommentObjectType";
import { ObjectCommentView } from "src/api/generated/erp/db/types/tables/objectCommentView";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace ObjectCommentApi {
	export async function getObjectComments(args: {
		objectType: ObjectCommentObjectType;
		objectId: number;
	}): Promise<Array<ObjectCommentView>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/objectComments/getObjectComments",
			method: "POST",
			data: {
				objectType: args.objectType,
				objectId: args.objectId,
			},
		});
		return response as Array<ObjectCommentView>;
	}

	export async function addComment(args: {
		objectType: ObjectCommentObjectType;
		objectId: number;
		content: string;
	}): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/objectComments/addComment",
			method: "POST",
			data: {
				objectType: args.objectType,
				objectId: args.objectId,
				content: args.content,
			},
		});
		return response as void;
	}
}
