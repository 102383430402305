// Copied from StackOverflow.
export function inverseNormalStdDev(prob: number): number {
	const a1 = -39.6968302866538,
		a2 = 220.946098424521,
		a3 = -275.928510446969;
	const a4 = 138.357751867269,
		a5 = -30.6647980661472,
		a6 = 2.50662827745924;
	const b1 = -54.4760987982241,
		b2 = 161.585836858041,
		b3 = -155.698979859887;
	const b4 = 66.8013118877197,
		b5 = -13.2806815528857,
		c1 = -7.78489400243029e-3;
	const c2 = -0.322396458041136,
		c3 = -2.40075827716184,
		c4 = -2.54973253934373;
	const c5 = 4.37466414146497,
		c6 = 2.93816398269878,
		d1 = 7.78469570904146e-3;
	const d2 = 0.32246712907004,
		d3 = 2.445134137143,
		d4 = 3.75440866190742;
	const p_low = 0.02425,
		p_high = 1 - p_low;

	if (prob < 0 || prob > 1) {
		throw new Error("Probability must be between 0 and 1");
	} else if (prob < p_low) {
		const q = Math.sqrt(-2 * Math.log(prob));
		return (
			(((((c1 * q + c2) * q + c3) * q + c4) * q + c5) * q + c6) /
			((((d1 * q + d2) * q + d3) * q + d4) * q + 1)
		);
	} else if (prob <= p_high) {
		const q = prob - 0.5;
		const r = q * q;
		return (
			((((((a1 * r + a2) * r + a3) * r + a4) * r + a5) * r + a6) * q) /
			(((((b1 * r + b2) * r + b3) * r + b4) * r + b5) * r + 1)
		);
	} else {
		const q = Math.sqrt(-2 * Math.log(1 - prob));
		return (
			-(((((c1 * q + c2) * q + c3) * q + c4) * q + c5) * q + c6) /
			((((d1 * q + d2) * q + d3) * q + d4) * q + 1)
		);
	}
}
