import { FormCommonProps } from "src/components/common/forms/types.ts";
import {
	ObjectAccountingForm,
	ObjectAccountingFormValues,
} from "src/components/views/erp/sales/accounting/ObjectAccountingForm.tsx";
import {
	SalesPartCategoryEditApi,
	SalesPartCategoryEditApi_FormInitData,
} from "src/api/generated/erp/sales/basedata/api/salesPartCategoryEditApi.ts";
import { requireRule } from "src/components/common/forms/validation.ts";
import i18n from "i18next";
import { FormTextField } from "src/components/common/forms/fields/FormTextField.tsx";
import { DeepPartial } from "react-hook-form";
import {
	getObjectAccountingCodeIdsFromFormValues,
	getObjectAccountingFormDefaultValues,
} from "src/components/views/erp/sales/accounting/ObjectAccountingForm.utils.ts";
import { SalesPartCategory } from "src/api/generated/erp/db/types/tables/salesPartCategory.ts";
import { AsyncForm } from "src/components/common/forms/AsyncForm.tsx";

export interface SalesPartCategoryFormProps extends FormCommonProps<number> {
	salesPartCategoryId: number | undefined;
}

interface FormValues extends SalesPartCategory, ObjectAccountingFormValues {}

export const SalesPartCategoryForm = (props: SalesPartCategoryFormProps) => {
	const { salesPartCategoryId, onCompleted, onFormEdited } = props;
	return (
		<AsyncForm
			fetch={() => SalesPartCategoryEditApi.getFormInitData({ salesPartCategoryId })}
			getDefaultValues={getDefaultValues}
			submit={submit}
			onCompleted={onCompleted}
			onFormEdited={onFormEdited}
			render={({ control }) => (
				<>
					<FormTextField
						control={control}
						name={"name"}
						label={i18n.t("name")}
						rules={requireRule()}
					/>
					<FormTextField
						control={control}
						name={"description"}
						label={i18n.t("description")}
					/>
					<ObjectAccountingForm control={control} />
				</>
			)}
		/>
	);

	function getDefaultValues({
		salesPartCategory,
		accountingDimensions,
	}: SalesPartCategoryEditApi_FormInitData): DeepPartial<FormValues> {
		const accountingValues = getObjectAccountingFormDefaultValues(accountingDimensions);
		if (salesPartCategory != null) {
			return {
				...salesPartCategory,
				...accountingValues,
			};
		} else {
			return {
				...accountingValues,
			};
		}
	}

	async function submit(values: FormValues) {
		const { accountingDimensionValues, ...salesPartCategory } = values;
		const accountingCodeIds = getObjectAccountingCodeIdsFromFormValues(accountingDimensionValues);
		if (salesPartCategoryId != null) {
			await SalesPartCategoryEditApi.update({
				salesPartCategory,
				accountingCodeIds,
			});
			return salesPartCategoryId;
		} else {
			return await SalesPartCategoryEditApi.insert({
				salesPartCategory,
				accountingCodeIds,
			});
		}
	}
};
