import {html, LitElement} from 'lit';
import OpenSeadragon from 'openseadragon';

class OpenSeadragonWebComponent extends LitElement {
    static get properties() {
        return {
            sourceUrl: {type: String}
        };
    }

    createRenderRoot() {
        return this;
    }

    constructor() {
        super();
        this.sourceUrl = null;
    }

    render() {
        return html`
            <div class="openseadragon-container"/> `;
    }

    firstUpdated(_changedProperties) {
        super.firstUpdated(_changedProperties);
        const container = this.querySelector('.openseadragon-container');
        OpenSeadragon({
            element: container,
            visibilityRatio: 1,
            prefixUrl: '/icons/openseadragon/images/',
            showFullPageControl: false,
            zoomPerScroll: 1.5,
            springStiffness: 10,
            animationTime: 1,
            tileSources: {
                type: 'image',
                url: this.sourceUrl,
                buildPyramid: false
            }
        });
    }
}

customElements.define('openseadragon-component', OpenSeadragonWebComponent);
