import { PartialTenantCustomizations } from "src/tenantCustomizations/TenantCustomizations";
import { getOperationCompletedAmountSamplingMethod } from "src/tenantCustomizations/tenants/mellano/spcSamplingMethods/operationCompletedAmountSamplingMethod.tsx";
import { getOperationWorkInProgressAmountSamplingMethod } from "src/tenantCustomizations/tenants/mellano/spcSamplingMethods/operationWorkInProgressAmountSamplingMethod.tsx";
import { MellanoShopOrderBatchContextMenuComponents } from "src/tenantCustomizations/tenants/mellano/MellanoShopOrderBatchContextMenuComponents.tsx";

export const mellanoCustomizations: PartialTenantCustomizations = {
	tenantConfig: {
		erp: {
			enabled: true,
		},
		tasks: {
			enabled: false,
		},
		documentsEnabled: false,
	},
	erp: {
		production: {
			shopOrderBatchContextMenuComponents: MellanoShopOrderBatchContextMenuComponents,
		},
	},
	spcSamplingMethods: () => [
		getOperationCompletedAmountSamplingMethod(),
		getOperationWorkInProgressAmountSamplingMethod(),
	],
} as const;
