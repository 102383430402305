import {
    PartConfigurationApi,
    PartConfigurationApi_PartConfigurationDto,
} from "src/api/generated/erp/configurator/api/partConfigurationApi";
import {AsyncFetchRender} from "src/components/common/async/AsyncFetchRender";
import {AavoMosaic} from "src/components/common/mosaic/AavoMosaic";
import i18n from "i18next";
import {
    PartConfigurationTopLevelDataView
} from "src/components/views/erp/configurator/inspecting/PartConfigurationTopLevelDataView.tsx";
import {PartConfigurationPropertiesDataGrid} from "./PartConfigurationPropertiesDataGrid";
import {
    DocumentsOfSingleObjectView
} from "src/components/views/documents/objectDocuments/DocumentsOfSingleObjectView.tsx";
import {PartConfigurationBomDataGrid} from "./PartConfigurationBomDataGrid";
import {
    PartConfigurationOperationsDataGrid
} from "src/components/views/erp/configurator/inspecting/PartConfigurationOperationsDataGrid.tsx";
import {
    PartConfigurationAttributesDataGrid
} from "src/components/views/erp/configurator/inspecting/PartConfigurationAttributesDataGrid.tsx";
import {
    PartConfigurationBomPricesByCategoriesDataGrid
} from "src/components/views/erp/configurator/inspecting/PartConfigurationBomPricesByCategoriesDataGrid.tsx";
import {useTenantCustomizations} from "../../../../../tenantCustomizations/useTenantCustomizations.tsx";

export interface PartConfigurationInspectingViewProps {
    partConfigurationId: number;
    showBom?: boolean;
}

export const PartConfigurationInspectingView = (props: PartConfigurationInspectingViewProps) => {
    const {partConfigurationId} = props;
    return (
        <AsyncFetchRender
            fetch={() => PartConfigurationApi.getPartConfiguration({partConfigurationId})}
            content={(partConfiguration) => (
                <Content {...props} partConfiguration={partConfiguration}/>
            )}
        />
    );
};

interface ContentProps extends PartConfigurationInspectingViewProps {
    partConfiguration: PartConfigurationApi_PartConfigurationDto;
}

const Content = ({partConfigurationId, partConfiguration, showBom = true}: ContentProps) => {
    const {tenantConfig} = useTenantCustomizations()

    return (
        <AavoMosaic
            viewId={"49778551B880215B"}
            layout={{
                type: "tabs",
                items: [
                    {
                        title: i18n.t("configuration"),
                        type: "row",
                        first: {
                            type: "column",
                            first: {
                                type: "panel",
                                title: i18n.t("configuration"),
                                content: (
                                    <PartConfigurationTopLevelDataView
                                        partConfiguration={partConfiguration}
                                    />
                                ),
                            },
                            second: {
                                type: "panel",
                                title: i18n.t("bom_pricing"),
                                hidden: partConfiguration.priceCalculationMethod !== "BOM",
                                content: (
                                    <PartConfigurationBomPricesByCategoriesDataGrid
                                        partConfiguration={partConfiguration}
                                    />
                                ),
                            },
                        },
                        second: {
                            type: "panel",
                            title: i18n.t("properties"),
                            content: (
                                <PartConfigurationPropertiesDataGrid
                                    partConfigurationId={partConfigurationId}
                                />
                            ),
                        },
                    },
                    {
                        title: i18n.t("bom"),
                        hidden: !showBom,
                        type: "row",
                        first: {
                            type: "panel",
                            title: i18n.t("bom"),
                            content: (
                                <PartConfigurationBomDataGrid
                                    partConfiguration={partConfiguration}
                                    editable={partConfiguration.isEditable}
                                />
                            ),
                        },
                        second: {
                            type: "panel",
                            title: i18n.t("operations"),
                            content: (
                                <PartConfigurationOperationsDataGrid
                                    partConfiguration={partConfiguration}
                                    editable={partConfiguration.isEditable}
                                />
                            ),
                        },
                    },
                    tenantConfig.documentsEnabled && {
                        title: i18n.t("documents"),
                        type: "panel",
                        content: (
                            <DocumentsOfSingleObjectView
                                objectRef={{
                                    objectType: "PART_CONFIGURATION",
                                    objectId: partConfigurationId,
                                }}
                                editable={partConfiguration.isEditable}
                            />
                        ),
                    },
                    {
                        title: i18n.t("attributes"),
                        type: "panel",
                        content: (
                            <PartConfigurationAttributesDataGrid
                                partConfigurationId={partConfiguration.partConfigurationId}
                                editable={partConfiguration.isEditable}
                            />
                        ),
                    },
                ],
            }}
        />
    );
};
