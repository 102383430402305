// This file is automatically generated. Do not edit manually.

import { t } from "i18next";

export const ShopOrderLineStateValues = ["INITIAL", "RELEASED", "RESERVED", "PICKED", "CANCELLED"] as const;

export type ShopOrderLineState = (typeof ShopOrderLineStateValues)[number];

export const getShopOrderLineStateLabels = () => ({
	INITIAL: t("initial"),
	RELEASED: t("released"),
	RESERVED: t("reserved"),
	PICKED: t("picked"),
	CANCELLED: t("cancelled"),
});

export const getShopOrderLineStateLabel = (value: ShopOrderLineState): string => {
	return getShopOrderLineStateLabels()[value];
};
