import { Project } from "src/api/generated/erp/db/types/tables/project.ts";
import { useFormInput } from "src/components/common/dialogs/formInput/useFormInput.tsx";
import { faPen } from "@fortawesome/pro-solid-svg-icons";
import i18n from "i18next";
import { AsyncButton } from "src/components/common/buttons/AsyncButton.tsx";
import { VerticalBox } from "src/components/common/box/VerticalBox.tsx";
import { HorizontalBox } from "src/components/common/box/HorizontalBox.tsx";
import { ProjectEditApi } from "src/api/generated/erp/project/project/api/projectEditApi.ts";
import { useState } from "react";
import { FormRichTextEditor } from "src/components/common/forms/fields/FormRichTextEditor.tsx";
import { RawHtmlContent } from "src/components/common/RawHtmlContent.tsx";

export interface ProjectNotesViewProps {
	project: Project;
}

export const ProjectNotesView = ({ project }: ProjectNotesViewProps) => {
	const showFormInput = useFormInput();

	const [notes, setNotes] = useState<string>(project.notes);

	return (
		<HorizontalBox
			sx={{
				flex: 1,
				overflow: "auto",
				padding: 2,
			}}
		>
			<RawHtmlContent
				html={notes}
				sx={{
					flex: 1,
				}}
			/>
			<VerticalBox>
				<AsyncButton icon={faPen} variant={"contained"} onClick={openForm} />
			</VerticalBox>
		</HorizontalBox>
	);

	async function openForm() {
		interface FormInput {
			notes: string;
		}

		const formValues = await showFormInput<FormInput>({
			title: i18n.t("memo"),
			size: "lg",
			defaultValues: {
				notes: notes,
			},
			content: ({ control }) => (
				<FormRichTextEditor
					control={control}
					name={"notes"}
					sx={{
						flex: 1,
					}}
				/>
			),
		});
		if (formValues === undefined) return;
		setNotes(formValues.notes);

		await ProjectEditApi.setProjectNotes({
			projectId: project.projectId,
			notes: formValues.notes,
		});
	}
};
