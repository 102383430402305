// This file is automatically generated. Do not edit manually.

import { ErpUnit } from "src/api/generated/erp/db/types/tables/erpUnit";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace UnitsApi {
	export async function getAll(): Promise<Array<ErpUnit>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/productManagement/units/getAll",
			method: "POST",
			data: {},
		});
		return response as Array<ErpUnit>;
	}

	export async function save(args: { items: Array<ErpUnit> }): Promise<Array<ErpUnit>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/productManagement/units/save",
			method: "POST",
			data: {
				items: args.items,
			},
		});
		return response as Array<ErpUnit>;
	}

	export async function delete_(args: { items: Array<ErpUnit> }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/productManagement/units/delete",
			method: "POST",
			data: {
				items: args.items,
			},
		});
		return response as void;
	}
}
