import {RefreshableElementRef} from "src/utils/useRefreshRef.ts";
import {
    WarehouseTransferJobQueueDataGridBase
} from "src/components/views/erp/warehouse/transfer/WarehouseTransferJobQueueDataGridBase.tsx";
import {AsyncButton} from "src/components/common/buttons/AsyncButton.tsx";
import i18n from "i18next";
import {
    WarehouseTransferActionApi
} from "src/api/generated/erp/warehouse/warehouseTransfer/api/warehouseTransferActionApi.ts";
import { faSignInAlt, faUndo } from "@fortawesome/pro-regular-svg-icons";

export interface WarehouseTransferJobQueuePickedDataGridProps {
	refreshRef: RefreshableElementRef;
	reservedRefreshRef: RefreshableElementRef;
	deliveredRefreshRef: RefreshableElementRef;
}

export const WarehouseTransferJobQueuePickedDataGrid = ({
	refreshRef,
	reservedRefreshRef,
	deliveredRefreshRef,
}: WarehouseTransferJobQueuePickedDataGridProps) => {
	return (
		<WarehouseTransferJobQueueDataGridBase
			warehouseTransferState={"PICKED"}
			gridId={"B8499EAF8BEB22E9"}
			refreshRef={refreshRef}
			sortModel={[
				{ field: "requestingSiteName", sort: "asc" },
				{ field: "requestingWarehouseLocationCode", sort: "asc" },
			]}
			actionBarComponents={(selectedRows) => (
				<>
					<AsyncButton
						label={i18n.t("deliver")}
						icon={faSignInAlt}
						onClick={async () => {
							await WarehouseTransferActionApi.transferSelectedTransfers({
								warehouseTransferIds: selectedRows.map((row) => row.warehouseTransferId),
							});
							await deliveredRefreshRef.refresh();
							await refreshRef.refresh();
						}}
						disabled={selectedRows.length === 0}
						variant={"outlined"}
					/>
					<AsyncButton
						label={i18n.t("deliver_without_purchasing")}
						icon={faSignInAlt}
						onClick={async () => {
							await WarehouseTransferActionApi.transferWithoutPurchasingSelectedTransfers({
								warehouseTransferIds: selectedRows.map((row) => row.warehouseTransferId),
							});
							await deliveredRefreshRef.refresh();
							await refreshRef.refresh();
						}}
						disabled={selectedRows.length === 0}
						variant={"outlined"}
					/>
					<AsyncButton
						label={i18n.t("revert_picking")}
						icon={faUndo}
						onClick={async () => {
							await WarehouseTransferActionApi.revertPickSelectedTransfers({
								warehouseTransferIds: selectedRows.map((row) => row.warehouseTransferId),
							});
							await reservedRefreshRef.refresh();
							await refreshRef.refresh();
						}}
						disabled={selectedRows.length === 0}
						variant={"outlined"}
					/>
				</>
			)}
		/>
	);
};
