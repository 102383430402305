import { MainPage } from "./MainPage";
import { Navigate, Outlet, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { join_paths, url_param, withSubRoutes } from "src/utils/paths";
import { HOME, INDEX, VIEW_ID, VIEWS } from "src/urls";
import { PageNotFound } from "../../common/PageNotFound";
import { MainContent } from "./MainContent";
import { useEffect } from "react";
import { useFrontendViewsContext } from "src/components/views/frontendViews/useFrontendViewsContext.ts";
import { useStartUpData } from "src/contexts/useStartUpData.ts";
import { HomePage } from "../HomePage";

export const ViewRouting = () => {
	const startUpData = useStartUpData();
	const { allMainMenuFrontendViews } = useFrontendViewsContext();

	const defaultViewPath =
		startUpData.defaultViewId ?
			join_paths([VIEWS, startUpData.defaultViewId.toString()])
		:	null;

	const navigate = useNavigate();
	const { pathname } = useLocation();

	useEffect(() => {
		// Handle default view navigation from empty path
		if (pathname === HOME && defaultViewPath !== null) {
			navigate(defaultViewPath, { replace: true });
		}
	}, [defaultViewPath, navigate, pathname]);

	return (
		<Routes>
			<Route path={INDEX} element={<Navigate to={HOME} replace={true} />} />
			<Route
				path={HOME}
				element={
					<MainPage>
						{/*React router Outlet component sets main page children components according to matching route*/}
						<Outlet />
					</MainPage>
				}
			>
				<Route index element={<HomePage />} />
				<Route
					path={join_paths([VIEWS, withSubRoutes(url_param(VIEW_ID))], true)}
					element={<MainContent />}
				/>
				{allMainMenuFrontendViews.map(({ key, url }) => {
					return (
						<Route
							path={withSubRoutes(url)}
							element={<MainContent />}
							key={`start-up-data-loader-route-${key}`}
						/>
					);
				})}
			</Route>
			<Route path={"*"} element={<PageNotFound />} />
		</Routes>
	);
};
