import { FrontendUploadFileHandle } from "src/api/generated/common/frontendUploadFileHandle";
import { makeAxiosRequest } from "src/api/utils/apiClient.ts";

export const uploadFile = async (file: File): Promise<FrontendUploadFileHandle> => {
	return (await uploadFiles([file]))[0]!;
};

export const uploadFiles = async (files: File[]): Promise<FrontendUploadFileHandle[]> => {
	const formData = new FormData();
	files.forEach((file, index) => {
		formData.append(`file${index}`, file);
	});

	const response = await makeAxiosRequest<FileUploadResponse>({
		url: "/api/files/cache/upload",
		method: "PUT",
		headers: {
			"Content-Type": "multipart/form-data"
		},
		data: formData
	});

	return response.cacheFileNames.map((cacheFileName) => ({
		fullFileName: cacheFileName
	}));
};

interface FileUploadResponse {
	cacheFileNames: string[];
}
