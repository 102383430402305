// This file is automatically generated. Do not edit manually.

import { t } from "i18next";

export const PurchaseRequestSourceTypeValues = ["MANUAL", "REORDER_POINT", "WAREHOUSE_REPLENISHMENT"] as const;

export type PurchaseRequestSourceType = (typeof PurchaseRequestSourceTypeValues)[number];

export const getPurchaseRequestSourceTypeLabels = () => ({
	MANUAL: t("manual"),
	REORDER_POINT: t("reorder_point"),
	WAREHOUSE_REPLENISHMENT: t("warehouse_replenishment"),
});

export const getPurchaseRequestSourceTypeLabel = (value: PurchaseRequestSourceType): string => {
	return getPurchaseRequestSourceTypeLabels()[value];
};
