import { useClientSideDataGridModel } from "src/components/common/dataGrid/gridModel/useClientSideDataGridModel.tsx";
import i18n from "i18next";
import { BillingPlanModel } from "src/api/generated/erp/db/types/tables/billingPlanModel.ts";
import { textColumn } from "src/components/common/dataGrid/columns.tsx";
import { ControlledAsyncCrudDataGrid } from "src/components/common/dataGrid/crud/ControlledAsyncCrudDataGrid.tsx";
import { BillingPlanModelForm } from "src/components/views/erp/sales/billingPlan/model/BillingPlanModelForm.tsx";
import { BillingPlanModelApi } from "src/api/generated/erp/sales/billingPlan/model/api/billingPlanModelApi.ts";
import { BillingPlanModelEditApi } from "src/api/generated/erp/sales/billingPlan/model/api/billingPlanModelEditApi.ts";

export interface BillingPlanModelsDataGridProps {
	onSelectionChanged: (billingPlanModel: BillingPlanModel | undefined) => void;
}

export const BillingPlanModelsDataGrid = ({ onSelectionChanged }: BillingPlanModelsDataGridProps) => {
	const { dataGridProps } = useClientSideDataGridModel({
		fetchData: () => BillingPlanModelApi.getAllBillingPlanModels(),
		initialParams: {},
		getRowId: (row) => row.billingPlanModelId,
		gridId: "15DF13DB11D19766",
		onSelectionChanged: (rows) => onSelectionChanged(rows[0]),
	});

	return (
		<ControlledAsyncCrudDataGrid<BillingPlanModel>
			columns={[
				textColumn({
					field: "name",
					headerName: i18n.t("name"),
					width: 200,
				}),
				textColumn({
					field: "description",
					headerName: i18n.t("description"),
					width: 200,
				}),
			]}
			disableMultipleRowSelection
			remove={({ items }) =>
				BillingPlanModelEditApi.delete_({
					billingPlanModelIds: items.map((item) => item.billingPlanModelId),
				})
			}
			form={{
				addRowEnabled: true,
				editEnabled: true,
				dialogSize: "sm",
				dialogTitle: i18n.t("billing_plan_model"),
				component: ({ row, onCompleted, onFormEdited }) => (
					<BillingPlanModelForm
						billingPlanModel={row}
						onCompleted={onCompleted}
						onFormEdited={onFormEdited}
					/>
				),
			}}
			{...dataGridProps}
		/>
	);
};
