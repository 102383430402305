// This file is automatically generated. Do not edit manually.

import { ServerSideDataModelRequest } from "src/api/generated/common/dataGrids/serverSideDataModelRequest";
import { ServerSideDataModelResult } from "src/api/generated/common/dataGrids/serverSideDataModelResult";
import { SurveySubmissionView } from "src/api/generated/erp/db/types/tables/surveySubmissionView";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace SurveyFormSubmissionsDataGridApi {
	export async function getData(args: {
		surveyFormId: number;
		dataModelRequest: ServerSideDataModelRequest;
	}): Promise<ServerSideDataModelResult<SurveySubmissionView>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/surveys/surveyFormSubmissionsDataGrid/getData",
			method: "POST",
			data: {
				surveyFormId: args.surveyFormId,
				dataModelRequest: args.dataModelRequest,
			},
		});
		return response as ServerSideDataModelResult<SurveySubmissionView>;
	}
}
