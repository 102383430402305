import { FormSelectField } from "src/components/common/forms/fields/FormSelectField.tsx";
import i18n from "i18next";
import { FormSingleFileInputField } from "src/components/common/forms/fields/FormSingleFileInputField.tsx";
import { requireRule } from "src/components/common/forms/validation.ts";
import { NewDocumentFileCreationType } from "src/api/generated/documents/newDocumentFileCreationType.ts";
import { Control, FieldValues, UseFormWatch } from "react-hook-form";
import { SimpleSelectFieldOption } from "src/components/common/inputFields/types.ts";
import { castControl, castWatch } from "src/components/common/forms/formCasting";

export interface DocumentFileCreationFormPartProps<
	TFieldValues extends DocumentFileCreationFormValues,
> {
	control: Control<TFieldValues>;
	watch: UseFormWatch<TFieldValues>;
	isExistingRecord: boolean;
}

interface DocumentFileCreationFormValues extends FieldValues {
	fileCreationType: NewDocumentFileCreationType;
	file: File;
}

export const DocumentFileCreationFormPart = <TFieldValues extends DocumentFileCreationFormValues>({
	control,
	watch,
	isExistingRecord,
}: DocumentFileCreationFormPartProps<TFieldValues>) => {
	const castedControl = castControl<TFieldValues, DocumentFileCreationFormValues>(control);
	const castedWatch = castWatch<TFieldValues, DocumentFileCreationFormValues>(watch);

	return (
		<>
			{!isExistingRecord && (
				<FormSelectField
					control={castedControl}
					name={"fileCreationType"}
					label={i18n.t("creation_method")}
					disableClearable={true}
					options={getFileCreationTypeOptions()}
					getOptionKey={(o) => o.key}
					getOptionLabel={(o) => o.label}
				/>
			)}
			{castedWatch("fileCreationType") === "UPLOAD" && (
				<FormSingleFileInputField
					control={castedControl}
					name={"file"}
					label={i18n.t("file")}
					placeholder={
						isExistingRecord ? i18n.t("current_file") : i18n.t("choose_or_drop_file")
					}
					rules={isExistingRecord ? undefined : requireRule()}
				/>
			)}
		</>
	);
};

const getFileCreationTypeOptions = (): SimpleSelectFieldOption<string>[] => {
	return Object.entries({
		UPLOAD: i18n.t("upload_file"),
		NEW_WORD_FILE: i18n.t("new_microsoft_office_file", { app: "Word" }),
		NEW_EXCEL_FILE: i18n.t("new_microsoft_office_file", { app: "Excel" }),
		NEW_POWERPOINT_FILE: i18n.t("new_microsoft_office_file", { app: "PowerPoint" }),
		NEW_VISIO_FILE: i18n.t("new_microsoft_office_file", { app: "OneNote" }),
		NEW_ONENOTE_FILE: i18n.t("new_microsoft_office_file", { app: "Visio" }),
	}).map(([key, label]) => ({
		key: key,
		label: label,
	}));
};
