// This file is automatically generated. Do not edit manually.

import { ShopOrderBatchView } from "src/api/generated/erp/db/types/tables/shopOrderBatchView";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace ShopOrderBatchJobQueueApi {
	export async function getWorkcenterInitialShopOrderBatches(): Promise<Array<ShopOrderBatchView>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/shopOrderBatch/getWorkcenterInitialShopOrderBatches",
			method: "POST",
			data: {},
		});
		return response as Array<ShopOrderBatchView>;
	}

	export async function getWorkcenterIncompleteShopOrderBatches(): Promise<Array<ShopOrderBatchView>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/shopOrderBatch/getWorkcenterIncompleteShopOrderBatches",
			method: "POST",
			data: {},
		});
		return response as Array<ShopOrderBatchView>;
	}
}
