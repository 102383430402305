// This file is automatically generated. Do not edit manually.

import { DeliverySource } from "src/api/generated/erp/delivery/model/deliverySource";
import { WarehousePickingObjectLocationView } from "src/api/generated/erp/db/types/tables/warehousePickingObjectLocationView";
import { FrontendDownloadFileHandle } from "src/api/generated/common/frontendDownloadFileHandle";
import { DeliveryPackageLineSource } from "src/api/generated/erp/delivery/model/deliveryPackageLineSource";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace DeliverySourcePickingApi {
	export async function getPickableLocationsForPackageSource(args: {
		source: DeliverySource;
	}): Promise<Array<WarehousePickingObjectLocationView>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/delivery/deliverySourcePicking/getPickableLocationsForPackageSource",
			method: "POST",
			data: {
				source: args.source,
			},
		});
		return response as Array<WarehousePickingObjectLocationView>;
	}

	export async function printDeliveryPackageSourcePickingList(args: {
		source: DeliverySource;
	}): Promise<FrontendDownloadFileHandle> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/delivery/deliverySourcePicking/printDeliveryPackageSourcePickingList",
			method: "POST",
			data: {
				source: args.source,
			},
		});
		return response as FrontendDownloadFileHandle;
	}

	export async function pickPartsToPackage(args: {
		deliveryPackageId: number;
		parts: Array<DeliverySourcePickingApi_PickPartToPackageParams>;
	}): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/delivery/deliverySourcePicking/pickPartsToPackage",
			method: "POST",
			data: {
				deliveryPackageId: args.deliveryPackageId,
				parts: args.parts,
			},
		});
		return response as void;
	}
}

export interface DeliverySourcePickingApi_PickPartToPackageParams {
	partId: number;
	partWarehouseLocationId: number | null | undefined;
	quantityToPickInBaseUnit: number;
	source: DeliveryPackageLineSource;
}
