import { CustomerOrderOfferActionsApi } from "src/api/generated/erp/sales/customerOrderOffer/api/customerOrderOfferActionsApi.ts";
import { AsyncForm } from "src/components/common/forms/AsyncForm.tsx";
import { FormCheckbox } from "src/components/common/forms/fields/FormCheckbox.tsx";
import { CustomerOrderTypeApi } from "src/api/generated/erp/sales/basedata/api/customerOrderTypeApi.ts";
import i18n from "i18next";
import { FormSelectField } from "src/components/common/forms/fields/FormSelectField.tsx";
import { FormCommonProps } from "src/components/common/forms/types.ts";
import { CustomerOrderType } from "src/api/generated/erp/db/types/tables/customerOrderType.ts";
import { requireRule } from "src/components/common/forms/validation.ts";

export interface CreateOrderFromOfferFormProps extends FormCommonProps<void> {
	customerOrderOfferId: number;
}

interface FormValues {
	copyOfferDocuments: boolean;
	copyOfferLineDocuments: boolean;
	copyPrintoutText: boolean;
	customerOrderTypeId: number;
}

export const CreateOrderFromOfferForm = ({
	customerOrderOfferId,
	onCompleted,
	onFormEdited,
}: CreateOrderFromOfferFormProps) => {
	return (
		<AsyncForm<CustomerOrderType[], FormValues, void>
			fetch={CustomerOrderTypeApi.getAll}
			onCompleted={onCompleted}
			onFormEdited={onFormEdited}
			getDefaultValues={() => ({
				copyOfferDocuments: true,
				copyOfferLineDocuments: true,
				copyPrintoutText: true,
				customerOrderTypeId: undefined,
			})}
			render={({ control, data: customerOrderTypeOptions }) => (
				<>
					<FormCheckbox
						control={control}
						name={"copyOfferDocuments"}
						label={i18n.t("copy_offer_documents_to_order")}
					/>
					<FormCheckbox
						control={control}
						name={"copyOfferLineDocuments"}
						label={i18n.t("copy_offer_line_documents_to_order_lines")}
					/>
					<FormCheckbox
						control={control}
						name={"copyPrintoutText"}
						label={i18n.t("copy_printout_texts")}
					/>
					<FormSelectField
						control={control}
						name={"customerOrderTypeId"}
						options={customerOrderTypeOptions}
						label={i18n.t("customer_order_type")}
						getOptionKey={(option) => option.customerOrderTypeId}
						getOptionLabel={(option) => option.name}
						rules={requireRule()}
					/>
				</>
			)}
			submit={async (values) => {
				await CustomerOrderOfferActionsApi.createCustomerOrderFromOffer({
					customerOrderOfferId,
					...values,
				});
			}}
		/>
	);
};
