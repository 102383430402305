import {useClientSideDataGridModel} from "src/components/common/dataGrid/gridModel/useClientSideDataGridModel";
import {PurchaseOrderReceptionApi} from "src/api/generated/erp/purchase/purchaseOrder/api/purchaseOrderReceptionApi.ts";
import {ControlledAsyncDataGrid} from "src/components/common/dataGrid/ControlledAsyncDataGrid.tsx";
import {concatWithPipe} from "src/utils/strings.tsx";
import {PurchaseOrderLineView} from "src/api/generated/erp/db/types/tables/purchaseOrderLineView.ts";
import i18n from "i18next";
import {dateColumn, textColumn} from "src/components/common/dataGrid/columns.tsx";
import {faCheck} from "@fortawesome/pro-regular-svg-icons";
import {AsyncButton} from "src/components/common/buttons/AsyncButton.tsx";
import {PurchaseOrderReceipt} from "src/api/generated/erp/db/types/tables/purchaseOrderReceipt.ts";
import {
    ReceivePurchaseOrderLinesForm
} from "src/components/views/erp/purchase/reception/ReceivePurchaseOrderLinesForm.tsx";
import {useGenericDialog} from "src/components/common/dialogs/useGenericDialog.ts";
import {RefreshableElementProps, RefreshableElementRef} from "src/utils/useRefreshRef.ts";

export interface PurchaseOrderLinesToReceiveDataGridProps extends RefreshableElementProps {
    purchaseOrderId: number;
    targetPurchaseOrderReceipt: PurchaseOrderReceipt | undefined;
    receiptLinesViewRefreshRef: RefreshableElementRef;
}

export const PurchaseOrderLinesToReceiveDataGrid = ({
                                                        purchaseOrderId,
                                                        targetPurchaseOrderReceipt,
                                                        refreshRef,
                                                        receiptLinesViewRefreshRef,
                                                    }: PurchaseOrderLinesToReceiveDataGridProps) => {
    const {openDialog} = useGenericDialog();

    const {dataGridProps, selectedRows, refreshData} = useClientSideDataGridModel({
        fetchData: () => PurchaseOrderReceptionApi.getPurchaseOrderLinesToReceive({purchaseOrderId}),
        getRowId: (row) => row.purchaseOrderLineId,
        gridId: "6D1117E466F08831",
        initialParams: {},
        refreshRef: refreshRef,
    });

    return (
		<ControlledAsyncDataGrid<PurchaseOrderLineView>
			checkboxSelectOnTouchDevice
			columns={[
				{
					field: "part",
					headerName: i18n.t("part"),
					valueGetter: (_, row) => concatWithPipe(row.partNo, row.purchasePartDescription),
				},
				{
					field: "remainingQuantity",
					headerName: i18n.t("remaining_quantity"),
					valueGetter: (_, row) => row.purchaseQuantity - row.receivedQuantity,
				},
				textColumn({
					field: "purchaseUnit",
					headerName: i18n.t("purchase_unit"),
				}),
				textColumn({
					field: "note",
					headerName: i18n.t("note"),
				}),
				dateColumn({
					field: "plannedArrivalDate",
					headerName: i18n.t("planned_arrival_date_short"),
				}),
			]}
			actionBarComponents={
				<>
					<AsyncButton
						label={i18n.t("receive")}
						icon={faCheck}
						variant="outlined"
						disabled={
							selectedRows.length === 0 ? i18n.t("select_purchase_order_lines_to_receive")
							: targetPurchaseOrderReceipt == null ?
								i18n.t("select_a_receipt")
							: targetPurchaseOrderReceipt.purchaseOrderReceiptState === "RECEIPTED" ?
								i18n.t("receipt_is_already_marked_as_completed")
							:	undefined
						}
						onClick={() => {
							if (targetPurchaseOrderReceipt == null) return;
							openDialog(({ closeDialog }) => ({
								title: i18n.t("reception"),
								size: "md",
								content: (
									<ReceivePurchaseOrderLinesForm
										purchaseOrderLines={selectedRows}
										targetReceiptId={targetPurchaseOrderReceipt.purchaseOrderReceiptId}
										onCompleted={async () => {
											await closeDialog();
											await Promise.all([
												receiptLinesViewRefreshRef.refresh(),
												refreshData(),
											]);
										}}
									/>
								),
							}));
						}}
					/>
				</>
			}
			{...dataGridProps}
		/>
	);
};
