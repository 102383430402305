import { MutableRefObject, useRef } from "react";

export const useRefreshRef = (): RefreshableElementRef => {
	const ref = useRef<RefreshableElement | undefined>(undefined);
	return {
		ref: ref,
		refresh: async () => {
			if (ref.current !== undefined) await ref.current.refresh();
		},
	};
};

export interface RefreshableElementProps {
	refreshRef?: RefreshableElementRef;
}

export type RefreshableElementRef = {
	ref: MutableRefObject<RefreshableElement | undefined>;
	refresh: () => Promise<unknown>;
};

export interface RefreshableElement {
	refresh: () => Promise<unknown>;
}

export const setRefreshRefValue = (
	refreshRef: RefreshableElementRef | undefined,
	refresh: () => Promise<unknown> | void,
) => {
	if (refreshRef !== undefined)
		refreshRef.ref.current = {
			refresh: async () => await refresh(),
		};
};

export const useForwardedRefreshRef = (ref: RefreshableElementRef | undefined): RefreshableElementRef => {
	const newRef = useRefreshRef();
	return ref ?? newRef;
};
