// This file is automatically generated. Do not edit manually.

import { t } from "i18next";

export const CustomerTypeValues = ["COMPANY", "CONSUMER"] as const;

export type CustomerType = (typeof CustomerTypeValues)[number];

export const getCustomerTypeLabels = () => ({
	COMPANY: t("company"),
	CONSUMER: t("consumer"),
});

export const getCustomerTypeLabel = (value: CustomerType): string => {
	return getCustomerTypeLabels()[value];
};
