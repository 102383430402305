import { ControlledAsyncDataGrid } from "src/components/common/dataGrid/ControlledAsyncDataGrid";
import { useClientSideDataGridModel } from "src/components/common/dataGrid/gridModel/useClientSideDataGridModel";
import {
	PartConfigurationBomPricesApi,
	PartConfigurationBomPricesApi_PartCategoryPricesDto,
} from "src/api/generated/erp/configurator/api/partConfigurationBomPricesApi.ts";
import { floatColumn, textColumn } from "src/components/common/dataGrid/columns";
import i18n from "i18next";
import { useGenericDialog } from "src/components/common/dialogs/useGenericDialog.ts";
import { faArrowDown } from "@fortawesome/pro-regular-svg-icons";
import { AavoButton } from "src/components/common/buttons/AavoButton.tsx";
import { PartConfigurationBomPricesOfCategoryDataGrid } from "src/components/views/erp/configurator/inspecting/PartConfigurationBomPricesOfCategoryDataGrid.tsx";
import { PartConfiguration } from "src/api/generated/erp/db/types/tables/partConfiguration.ts";

export interface PartConfigurationBomPricesByCategoriesDataGridProps {
	partConfiguration: PartConfiguration;
}

export const PartConfigurationBomPricesByCategoriesDataGrid = ({
	partConfiguration,
}: PartConfigurationBomPricesByCategoriesDataGridProps) => {
	const { dataGridProps, onlySelectedRow } = useClientSideDataGridModel({
		fetchData: () =>
			PartConfigurationBomPricesApi.getPartCategoryPrices({
				partConfigurationId: partConfiguration.partConfigurationId,
			}),
		getRowId: (row) => row.salesPartCategoryId ?? -1,
		gridId: "A2F3D8E9C4F4808E",
		initialParams: {},
	});

	const { openDialog } = useGenericDialog();

	return (
		<ControlledAsyncDataGrid<PartConfigurationBomPricesApi_PartCategoryPricesDto>
			hideRefreshButton
			columns={[
				textColumn({
					headerName: i18n.t("sales_part_category"),
					field: "salesPartCategoryName",
					width: 300,
					valueGetter: (_, row) => row.salesPartCategoryName ?? i18n.t("others"),
				}),
				floatColumn({
					headerName: i18n.t("total_price"),
					field: "totalPrice",
					width: 120,
				}),
				floatColumn({
					headerName: i18n.t("total_cost"),
					field: "totalCost",
					width: 150,
				}),
			]}
			onRowDoubleClick={({ row }) => {
				openCategory(row);
			}}
			actionBarComponents={
				<>
					<AavoButton
						label={i18n.t("drill")}
						icon={faArrowDown}
						disabled={onlySelectedRow == null}
						onClick={() => openCategory(onlySelectedRow!)}
						variant="outlined"
					/>
				</>
			}
			{...dataGridProps}
		/>
	);

	function openCategory(row: PartConfigurationBomPricesApi_PartCategoryPricesDto) {
		openDialog(() => ({
			title: row.salesPartCategoryName ?? i18n.t("others"),
			size: "xl",
			content: (
				<PartConfigurationBomPricesOfCategoryDataGrid
					salesPartCategoryId={row.salesPartCategoryId ?? null}
					partConfiguration={partConfiguration}
				/>
			),
		}));
	}
};
