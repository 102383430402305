import { LegacyViews } from "../legacy/LegacyViews";
import { LegacyModalViews } from "../legacy/LegacyModalViews";
import { FrontendModalViews } from "../legacy/FrontendModalViews";
import { FrontendViewsWrapper } from "src/components/views/frontendViews/FrontendViewsWrapper.tsx";

export const MainContent = () => {
	return (
		<>
			<FrontendViewsWrapper />
			<LegacyViews />
			<LegacyModalViews />
			<FrontendModalViews />
		</>
	);
};
