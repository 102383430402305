import { Resource } from "src/api/generated/postgres/db/types/ext_resourcing/tables/resource.ts";
import { ClientSideDataGridModel } from "src/components/common/dataGrid/gridModel/ClientSideDataGridModel.tsx";
import { ResourcesDataGridApi } from "src/api/generated/spc/resources/api/resourcesDataGridApi.ts";
import { CrudDataGrid } from "src/components/common/dataGrid/crud/CrudDataGrid.tsx";
import { integerColumn, textColumn } from "src/components/common/dataGrid/columns.tsx";
import i18n from "i18next";
import { DocumentsOfObjectButton } from "src/components/views/documents/objectDocuments/DocumentsOfObjectButton.tsx";
import { nullableAavoObjectRef } from "src/utils/aavoObjectRefUtils.ts";
import { AavoTextField } from "src/components/common/inputFields/AavoTextField.tsx";
import { ResourceEditApi } from "src/api/generated/spc/resources/api/resourceEditApi.ts";
import { ResourceForm } from "src/components/views/spc/basedata/resources/ResourceForm.tsx";

export interface ResourcesDataGridProps {
	setSelectedResource: (resource: Resource | undefined) => void;
}

export const ResourcesDataGrid = ({ setSelectedResource }: ResourcesDataGridProps) => {
	return (
		<ClientSideDataGridModel
			gridId={"814E3C31A2F2BB8B"}
			fetchData={({ searchQuery }) => ResourcesDataGridApi.getResources({ searchQuery: searchQuery })}
			initialParams={{ searchQuery: "" }}
			getRows={(data) => data}
			getRowId={(row) => row.id}
			onSelectionChanged={(selectedRows) => {
				setSelectedResource(selectedRows[0]);
			}}
			render={({ dataGridProps, onlySelectedRow, refreshData }) => (
				<CrudDataGrid<Resource>
					columns={[
						textColumn({
							field: "name",
							headerName: i18n.t("name"),
							width: 150,
						}),
						textColumn({
							field: "externalId",
							headerName: i18n.t("external_id"),
							width: 150,
						}),
						textColumn({
							field: "description",
							headerName: i18n.t("description"),
							width: 250,
						}),
						integerColumn({
							field: "id",
							headerName: i18n.t("id"),
						}),
					]}
					remove={async ({ items }) => {
						await ResourceEditApi.delete_({ resourceIds: items.map((resource) => resource.id) });
					}}
					actionBarComponents={
						<>
							<DocumentsOfObjectButton
								objectRef={nullableAavoObjectRef("SPC_RESOURCE", onlySelectedRow?.id)}
							/>
							<AavoTextField
								label={i18n.t("search")}
								onSubmit={async (input) => {
									await refreshData({ searchQuery: input });
								}}
							/>
						</>
					}
					form={{
						addRowEnabled: true,
						editEnabled: true,
						dialogTitle: i18n.t("resource"),
						dialogSize: "sm",
						component: (props) => <ResourceForm resourceId={props.row?.id ?? null} {...props} />,
					}}
					{...dataGridProps}
				/>
			)}
		/>
	);
};
