import { useEffect } from "react";
import { logError } from "src/errorHandling/errorLogging.ts";

/**
 * Runs the effect on mount and logs any errors.
 * Should be used if the effect required cleanup.
 */
export function useAsyncEffectOnMount(effect: () => Promise<void>) {
	useEffect(() => {
		effect().catch(logError);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
}
