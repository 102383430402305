// This file is automatically generated. Do not edit manually.

import { t } from "i18next";

export const ControlChartDataInputMethodValues = ["DEFAULT_", "PRODUCTIVITY"] as const;

export type ControlChartDataInputMethod = (typeof ControlChartDataInputMethodValues)[number];

export const getControlChartDataInputMethodLabels = () => ({
	DEFAULT_: t("default"),
	PRODUCTIVITY: t("productivity"),
});

export const getControlChartDataInputMethodLabel = (value: ControlChartDataInputMethod): string => {
	return getControlChartDataInputMethodLabels()[value];
};
