import { ProjectActivityJobQueueApi } from "src/api/generated/erp/project/projectActivity/api/projectActivityJobQueueApi.ts";
import { ProjectActivityJobQueueBaseDataGrid } from "src/components/views/erp/project/activityJobQueue/ProjectActivityJobQueueBaseDataGrid.tsx";
import { ProjectActivityJobQueueActionsApi } from "src/api/generated/erp/project/projectActivity/api/projectActivityJobQueueActionsApi.ts";
import { faPlay } from "@fortawesome/pro-regular-svg-icons";
import { AsyncButton } from "src/components/common/buttons/AsyncButton.tsx";
import i18n from "i18next";
import { useContextOrThrow } from "src/utils/useContextOrThrow.tsx";
import { ProjectActivityJobQueueViewContext } from "src/components/views/erp/project/activityJobQueue/ProjectActivityJobQueueViewContext.ts";
import { useConfirmDialog } from "src/components/common/dialogs/confirmDialog/useConfirmDialog.ts";
import { DocumentsOfObjectButton } from "src/components/views/documents/objectDocuments/DocumentsOfObjectButton.tsx";
import { nullableAavoObjectRef } from "src/utils/aavoObjectRefUtils.ts";
import { ProjectActivityCustomerOrderView } from "src/api/generated/erp/db/types/tables/projectActivityCustomerOrderView.ts";

export interface ProjectActivityJobQueueInitialActivitiesDataGridProps {
	onSelectionChanged: (rows: ProjectActivityCustomerOrderView[]) => void;
}

export const ProjectActivityJobQueueInitialActivitiesDataGrid = ({
	onSelectionChanged,
}: ProjectActivityJobQueueInitialActivitiesDataGridProps) => {
	const { initialActivitiesRefreshRef, inProgressActivitiesRefreshRef } = useContextOrThrow(
		ProjectActivityJobQueueViewContext,
	);
	const showConfirmDialog = useConfirmDialog();

	return (
		<ProjectActivityJobQueueBaseDataGrid
			fetchData={ProjectActivityJobQueueApi.getInitialActivities}
			refreshRef={initialActivitiesRefreshRef}
			subProjectTypeFiltering
			resourceFiltering
			gridId={"FE41DD341B0B58A0"}
			onSelectionChanged={onSelectionChanged}
			actionBarComponents={({ selectedRows, onlySelectedRow }) => (
				<>
					<AsyncButton
						label={i18n.t("start")}
						icon={faPlay}
						disabled={selectedRows.length === 0}
						variant={"outlined"}
						onClick={async () => {
							const confirmed = await showConfirmDialog();
							if (!confirmed) return;

							await ProjectActivityJobQueueActionsApi.startActivities({
								activityIds: selectedRows.map((row) => row.activityId),
							});
							await Promise.all([
								inProgressActivitiesRefreshRef.refresh(),
								initialActivitiesRefreshRef.refresh(),
							]);
						}}
					/>
					<DocumentsOfObjectButton
						label={i18n.t("order")}
						objectRef={nullableAavoObjectRef("CUSTOMER_ORDER", onlySelectedRow?.customerOrderId)}
						variant={"outlined"}
						tooltip={i18n.t("customer_order_documents")}
					/>
				</>
			)}
		/>
	);
};
