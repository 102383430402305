import { ServerSideDataGridModel } from "src/components/common/dataGrid/gridModel/ServerSideDataGridModel.tsx";
import { useGlobalInitData } from "src/contexts/useGlobalInitData.ts";
import { PickableProjectsDataGridApi } from "src/api/generated/erp/project/delivery/api/pickableProjectsDataGridApi.ts";
import { AavoDataGrid } from "src/components/common/dataGrid/AavoDataGrid.tsx";
import { integerColumn, textColumn } from "src/components/common/dataGrid/columns.tsx";
import i18n from "i18next";
import { AavoTextField } from "src/components/common/inputFields/AavoTextField.tsx";
import { AavoButton } from "src/components/common/buttons/AavoButton.tsx";
import { faBoxOpen } from "@fortawesome/pro-regular-svg-icons";
import { useGenericDialog } from "src/components/common/dialogs/useGenericDialog.ts";
import { OpenProjectInTreeViewButton } from "src/components/views/erp/utilComponents/OpenProjectInTreeViewButton.tsx";
import { SelectSiteField } from "src/components/views/erp/common/sites/SelectSiteField.tsx";
import { ProjectView } from "src/api/generated/erp/db/types/tables/projectView.ts";
import { ProjectPickingView } from "src/components/views/erp/project/delivery/picking/ProjectPickingView.tsx";
import { genericNullableValue } from "src/utils/genericNullableValue.ts";

export const PickableProjectsDataGrid = () => {
	const { defaultSiteId } = useGlobalInitData();

	const { openDialog } = useGenericDialog();

	return (
		<ServerSideDataGridModel
			fetchData={(params) => PickableProjectsDataGridApi.getRows(params)}
			initialParams={{
				siteId: genericNullableValue<number>(defaultSiteId),
				searchQuery: "",
			}}
			getDataModelResult={(response) => response}
			getRowId={(row) => row.projectId}
			gridId={"430F6E2B5D869B90"}
			render={({ dataGridProps, onlySelectedRow, refreshData, currentParams }) => (
				<AavoDataGrid<ProjectView>
					columns={[
						integerColumn({
							field: "projectId",
							headerName: i18n.t("number_shortened"),
							width: 80,
						}),
						textColumn({
							field: "siteName",
							headerName: i18n.t("site"),
							width: 80,
						}),
						textColumn({
							field: "projectDescription",
							headerName: i18n.t("name"),
							width: 200,
						}),
						textColumn({
							field: "projectType",
							headerName: i18n.t("type"),
						}),
						textColumn({
							field: "projectResponsiblePersonName",
							headerName: i18n.t("responsible_person"),
							width: 120,
						}),
						textColumn({
							field: "addressName",
							headerName: i18n.t("address_name"),
							width: 120,
						}),
						textColumn({
							field: "address_1",
							headerName: i18n.t("address_1"),
							width: 120,
						}),
						textColumn({
							field: "address_2",
							headerName: i18n.t("address_2"),
							width: 120,
						}),
						textColumn({
							field: "postalCode",
							headerName: i18n.t("postal_code"),
						}),
						textColumn({
							field: "city",
							headerName: i18n.t("city"),
							width: 120,
						}),
						textColumn({
							field: "addressContact",
							headerName: i18n.t("address_contact"),
							width: 150,
						}),
					]}
					onRowDoubleClick={({ row }) => {
						openPickingView(row);
					}}
					actionBarComponents={
						<>
							<AavoTextField
								placeholder={i18n.t("search")}
								onSubmit={async (search) => {
									await refreshData({ searchQuery: search });
								}}
							/>
							<SelectSiteField
								value={currentParams.siteId}
								onChange={(siteId) => refreshData({ siteId })}
							/>
							<AavoButton
								label={i18n.t("picking")}
								icon={faBoxOpen}
								variant={"outlined"}
								disabled={onlySelectedRow === undefined}
								onClick={() => {
									if (onlySelectedRow === undefined) return;
									openPickingView(onlySelectedRow);
								}}
							/>
						</>
					}
					rowContextMenuComponents={({ row }) => [
						<OpenProjectInTreeViewButton key="openProject" projectId={row.projectId} />,
					]}
					{...dataGridProps}
				/>
			)}
		/>
	);

	function openPickingView(project: ProjectView) {
		openDialog(() => ({
			title: i18n.t("picking"),
			content: <ProjectPickingView project={project} />,
		}));
	}
};
