// This file is automatically generated. Do not edit manually.

import { SalesPartPriceGroup } from "src/api/generated/erp/db/types/tables/salesPartPriceGroup";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace SalesPartPriceGroupApi {
	export async function getSalesPartPriceGroups(): Promise<Array<SalesPartPriceGroup>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/salesPartPricoGroups/getSalesPartPriceGroups",
			method: "POST",
			data: {},
		});
		return response as Array<SalesPartPriceGroup>;
	}
}
