import { AavoMosaic } from "src/components/common/mosaic/AavoMosaic.tsx";
import { CustomerSalesContractsDataGrid } from "src/components/views/erp/sales/pricing/salesContracts/CustomerSalesContractsDataGrid.tsx";
import { SalesContractSalesPartsDataGrid } from "src/components/views/erp/sales/pricing/salesContracts/SalesContractSalesPartsDataGrid.tsx";
import { useState } from "react";
import { CenteredTypography } from "src/components/common/CenteredTypography.tsx";
import i18n from "i18next";
import { CustomerSalesContractsDataGridApi_SalesContractDto } from "src/api/generated/erp/sales/pricing/api/customerSalesContractsDataGridApi.ts";
import { SalesContractPriceGroupDiscountsDataGrid } from "src/components/views/erp/sales/pricing/salesContracts/SalesContractPriceGroupDiscountsDataGrid.tsx";

export interface CustomerSalesContractsViewProps {
	customerId: number;
}

export const CustomerSalesContractsView = ({ customerId }: CustomerSalesContractsViewProps) => {
	const [selectedContract, setSelectedContract] = useState<CustomerSalesContractsDataGridApi_SalesContractDto | null>(
		null,
	);
	return (
		<AavoMosaic
			layout={{
				type: "row",
				first: {
					type: "panel",
					title: i18n.t("sales_contracts"),
					content: (
						<CustomerSalesContractsDataGrid
							customerId={customerId}
							onSelectionChanged={setSelectedContract}
						/>
					),
				},
				second: {
					type: "tabs",
					items: [
						{
							type: "panel",
							title: i18n.t("parts"),
							content:
								selectedContract == null ?
									<CenteredTypography>{i18n.t("select_contract")}</CenteredTypography>
								:	<SalesContractSalesPartsDataGrid
										key={selectedContract.salesContractId}
										salesContractId={selectedContract.salesContractId}
									/>,
						},
						{
							type: "panel",
							title: i18n.t("discounts"),
							content:
								selectedContract == null ?
									<CenteredTypography>{i18n.t("select_contract")}</CenteredTypography>
								:	<SalesContractPriceGroupDiscountsDataGrid
										key={selectedContract.salesContractId}
										salesContractId={selectedContract.salesContractId}
									/>,
						},
					],
				},
			}}
		/>
	);
};
