// This file is automatically generated. Do not edit manually.

import { UpOrDown } from "src/api/generated/common/upOrDown";
import { ShopOrderOperationView } from "src/api/generated/erp/db/types/tables/shopOrderOperationView";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace ShopOrderOperationEditApi {
	export async function movePosition(args: { shopOrderOperationId: number; direction: UpOrDown }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/shopOrderOperation/movePosition",
			method: "POST",
			data: {
				shopOrderOperationId: args.shopOrderOperationId,
				direction: args.direction,
			},
		});
		return response as void;
	}

	export async function delete_(args: { shopOrderOperations: Array<ShopOrderOperationView> }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/shopOrderOperation/delete",
			method: "POST",
			data: {
				shopOrderOperations: args.shopOrderOperations,
			},
		});
		return response as void;
	}
}
