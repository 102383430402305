// This file is automatically generated. Do not edit manually.

import { InventoryLevelEventType } from "src/api/generated/erp/db/types/enums/inventoryLevelEventType";
import { PartView } from "src/api/generated/erp/db/types/tables/partView";
import { WarehouseLocationView } from "src/api/generated/erp/db/types/tables/warehouseLocationView";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace ManualInventoryEventFormApi {
	export async function makeInventoryEvents(args: {
		events: Array<ManualInventoryEventFormApi_InventoryEventInput>;
	}): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/warehouse/inventory/manualEventsForm/makeInventoryEvents",
			method: "POST",
			data: {
				events: args.events,
			},
		});
		return response as void;
	}

	export async function getPartAndWarehouseLocation(args: {
		partNo: string;
		locationCode: string;
	}): Promise<ManualInventoryEventFormApi_PartAndWarehouseLocation> {
		const response = await makeAxiosRequest({
			url: "/api/ext/warehouse/inventory/manualEventsForm/getPartAndWarehouseLocation",
			method: "POST",
			data: {
				partNo: args.partNo,
				locationCode: args.locationCode,
			},
		});
		return response as ManualInventoryEventFormApi_PartAndWarehouseLocation;
	}
}

export interface ManualInventoryEventFormApi_InventoryEventInput {
	eventQuantity: number;
	eventType: InventoryLevelEventType;
	partConfigurationId: number | null | undefined;
	partId: number;
	warehouseLocationId: number;
}

export interface ManualInventoryEventFormApi_PartAndWarehouseLocation {
	part: PartView;
	warehouseLocation: WarehouseLocationView;
}
