import { useErrorDialog } from "src/components/common/dialogs/errorDialog/userErrorDialog.ts";
import { useGenericDialog } from "src/components/common/dialogs/useGenericDialog.ts";
import { useState } from "react";
import { HorizontalBox } from "src/components/common/box/HorizontalBox.tsx";
import { AavoFileInput } from "src/components/common/inputFields/fileInput/AavoFileInput.tsx";
import i18n from "i18next";
import { AavoLoading } from "src/components/common/AavoLoading.tsx";
import { AsyncButton } from "src/components/common/buttons/AsyncButton.tsx";
import { faExternalLinkAlt } from "@fortawesome/pro-regular-svg-icons";
import { CONFIGURATION_PROPERTY_NULL_VALUE } from "src/components/views/erp/configurator/configuratorUtils.ts";
import { uploadFile } from "src/utils/fileUploading.ts";
import { UnmanagedFilesApi } from "src/api/generated/documents/unmanagedFiles/unmanagedFilesApi.ts";
import { FilePreviewView } from "src/components/views/documents/preview/FilePreviewView.tsx";
import { getFileExtension } from "src/utils/fileUtils.ts";
import {
	ConfiguratorFieldComponentProps
} from "src/components/views/erp/configurator/configuratorForm/components/field/ConfiguratorFieldComponent.tsx";
import {
	getConfiguratorFieldComponentErrorMessage,
	getConfiguratorFieldComponentLabelWithRequiredMark
} from "src/components/views/erp/configurator/configuratorForm/components/field/configuratorFieldComponentUtils.ts";

export const ConfiguratorDocumentField = ({ field, onSubmit }: ConfiguratorFieldComponentProps) => {
	const { logErrorAndShowOnDialog } = useErrorDialog();
	const { openDialog } = useGenericDialog();

	const currentValue = field.currentValue.type === "document" ? field.currentValue : null;

	const [file, setFile] = useState<File | null>(currentValue == null ? null : new File([], currentValue.fileName));
	const [valueHasChanged, setValueHasChanged] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	return (
		<HorizontalBox gap={1}>
			<AavoFileInput<false>
				multiple={false}
				value={file}
				label={getConfiguratorFieldComponentLabelWithRequiredMark(field)}
				disabled={!field.enabled}
				error={getConfiguratorFieldComponentErrorMessage(field, field.currentValue)}
				placeholder={i18n.t("select_a_file")}
				onChange={onChange}
				hideSizeText={!valueHasChanged} // Hide the size text when "fake File" created from current value
				InputProps={{
					endAdornment: isLoading ? <AavoLoading size={"xl"} /> : undefined
				}}
			/>
			{currentValue != null && (
				<AsyncButton icon={faExternalLinkAlt} tooltip={i18n.t("open")} onClick={openSelectedFile} />
			)}
		</HorizontalBox>
	);

	async function onChange(newFile: File | null) {
		try {
			setFile(newFile);
			setValueHasChanged(true);
			if (newFile == null) {
				await onSubmit(CONFIGURATION_PROPERTY_NULL_VALUE);
				return;
			}
			setIsLoading(true);
			const fileHandle = await uploadFile(newFile);
			const fileUuid = await UnmanagedFilesApi.createUnmanagedFile({ fileHandle });
			await onSubmit({
				type: "document",
				value: fileUuid,
				documentCategoryId: field.documentFieldDocumentCategoryId!,
				documentDescription: field.documentFieldDocumentDescription,
				label: field.documentFieldDocumentDescription,
				fileName: newFile.name
			});
		} catch (e) {
			logErrorAndShowOnDialog(e);
		} finally {
			setIsLoading(false);
		}
	}

	async function openSelectedFile() {
		const { value: fileUuid, fileName } = currentValue!;
		const fileUrl = await UnmanagedFilesApi.getUnmanagedFilePresignedDownloadUrl({
			uuid: fileUuid
		});
		openDialog({
			size: "fullscreen",
			title: fileName,
			content: <FilePreviewView fileUrl={fileUrl} extension={getFileExtension(fileName)!} />
		});
	}
};