import { FormCommonProps } from "src/components/common/forms/types.ts";
import { AsyncForm } from "src/components/common/forms/AsyncForm.tsx";
import { ResourceItemEditApi } from "src/api/generated/spc/resources/api/resourceItemEditApi.ts";
import { FormTextField } from "src/components/common/forms/fields/FormTextField.tsx";
import { requireRule } from "src/components/common/forms/validation.ts";
import i18n from "i18next";
import { Item } from "src/api/generated/postgres/db/types/ext_resourcing/tables/item.ts";

export interface ResourceItemFormProps extends FormCommonProps<number> {
	resourceId: number;
	resourceItemId: number | null;
}

export const ResourceItemForm = ({
	resourceId,
	resourceItemId,
	onFormEdited,
	onCompleted,
}: ResourceItemFormProps) => {
	return (
		<AsyncForm<Item | null, Item, number>
			fetch={() =>
				ResourceItemEditApi.getItem({
					itemId: resourceItemId,
				})
			}
			getDefaultValues={(data) =>
				data != null ? data : (
					{
						resourceId: resourceId,
					}
				)
			}
			onCompleted={onCompleted}
			onFormEdited={onFormEdited}
			render={({ control }) => {
				return (
					<>
						<FormTextField
							control={control}
							name={"name"}
							label={i18n.t("name")}
							rules={requireRule()}
						/>
						<FormTextField control={control} name={"externalId"} label={i18n.t("external_id")} />
						<FormTextField control={control} name={"description"} label={i18n.t("description")} />
					</>
				);
			}}
			submit={async (values) => {
				if (resourceItemId == null) {
					return await ResourceItemEditApi.insert({ item: values });
				} else {
					await ResourceItemEditApi.update({ item: values });
					return resourceItemId;
				}
			}}
		/>
	);
};
