export type AppEnvironment = "dev" | "staging" | "prod";

export const getAppEnvironment = (hostname?: string): AppEnvironment => {
	if (import.meta.env.DEV) return "dev";

	const actualHostname = hostname ?? window.location.hostname;
	if (actualHostname.includes(".staging.")) return "staging";

	return "prod";
};
