// This file is automatically generated. Do not edit manually.

import { t } from "i18next";

export const SurveySubmissionStateValues = ["SUBMITTED", "CHECKED", "APPROVED"] as const;

export type SurveySubmissionState = (typeof SurveySubmissionStateValues)[number];

export const getSurveySubmissionStateLabels = () => ({
	SUBMITTED: t("submitted"),
	CHECKED: t("checked"),
	APPROVED: t("approved"),
});

export const getSurveySubmissionStateLabel = (value: SurveySubmissionState): string => {
	return getSurveySubmissionStateLabels()[value];
};
