import { useState } from "react";
import { FrontendDownloadFileHandle } from "src/api/generated/common/frontendDownloadFileHandle.ts";
import { AsyncFetchRender } from "src/components/common/async/AsyncFetchRender";
import { AavoButton } from "src/components/common/buttons/AavoButton";
import { FileHandlePreviewView } from "src/components/views/documents/preview/FileHandlePreviewView.tsx";
import i18n from "i18next";
import { AavoActionBar } from "src/components/common/actionBar/AavoActionBar.tsx";
import { VerticalBox } from "src/components/common/box/VerticalBox.tsx";
import { faRefresh } from "@fortawesome/pro-regular-svg-icons";
import { AsyncButton } from "src/components/common/buttons/AsyncButton.tsx";

export interface ConfiguratorLogViewBaseProps {
	fetchLogFile: () => Promise<FrontendDownloadFileHandle>;
}

export const ConfiguratorLogView = ({ fetchLogFile }: ConfiguratorLogViewBaseProps) => {
	const [initialFetchRequested, setInitialFetchRequested] = useState(false);

	if (!initialFetchRequested)
		return (
			<AavoButton
				label={i18n.t("load")}
				onClick={() => setInitialFetchRequested(true)}
				variant={"contained"}
				sx={{
					margin: "auto",
				}}
			/>
		);

	return (
		<AsyncFetchRender
			fetch={fetchLogFile}
			content={(logFileHandle, refresh) => {
				return (
					<VerticalBox flex={1}>
						<AavoActionBar
							sx={{
								alignSelf: "stretch",
								borderBottom: "1px solid",
								borderColor: "divider",
							}}
						>
							<AsyncButton icon={faRefresh} color={"primary"} onClick={refresh} />
						</AavoActionBar>
						<FileHandlePreviewView fileHandle={logFileHandle} />
					</VerticalBox>
				);
			}}
		/>
	);
};
