import { textColumn } from "src/components/common/dataGrid/columns.tsx";
import i18n from "i18next";
import { InvoiceView } from "src/api/generated/erp/db/types/tables/invoiceView.ts";
import { ClientSideDataGridModel } from "src/components/common/dataGrid/gridModel/ClientSideDataGridModel.tsx";
import { AavoDataGrid, AavoGridColDef } from "src/components/common/dataGrid/AavoDataGrid.tsx";
import { AccountingDimension } from "src/api/generated/erp/db/types/tables/accountingDimension.ts";
import {
	InvoiceLinesDataGridApi,
	InvoiceLinesDataGridApi_InvoiceLineDto,
} from "src/api/generated/erp/sales/invoicing/api/invoiceLinesDataGridApi.ts";

export interface InvoiceLinesDataGridProps {
	invoice: InvoiceView;
}

export const InvoiceLinesDataGrid = ({ invoice }: InvoiceLinesDataGridProps) => {
	return (
		<ClientSideDataGridModel
			fetchData={() => InvoiceLinesDataGridApi.getDataGridData({ invoiceId: invoice.invoiceId })}
			getRows={(data) => data.invoiceLines}
			getRowId={(row) => row.invoiceLineId}
			initialParams={{}}
			gridId={"C128689FC7163A61"}
			render={({ dataGridProps, data: { accountingDimensions } }) => (
				<AavoDataGrid<InvoiceLinesDataGridApi_InvoiceLineDto>
					columns={[
						textColumn({
							field: "salesPart",
							headerName: i18n.t("sales_part"),
							width: 150,
						}),
						textColumn({
							field: "mark",
							headerName: i18n.t("mark"),
						}),
						textColumn({
							field: "quantityInSalesUnits",
							headerName: i18n.t("quantity_in_sales_units"),
							width: 180,
						}),
						textColumn({
							field: "salesUnit",
							headerName: i18n.t("sales_unit"),
						}),
						textColumn({
							field: "quantityInPriceUnits",
							headerName: i18n.t("quantity_in_price_units"),
							width: 180,
						}),
						textColumn({
							field: "priceUnit",
							headerName: i18n.t("price_unit"),
						}),
						textColumn({
							field: "unitPrice",
							headerName: i18n.t("unit_price_in_price_units"),
							width: 200,
						}),
						textColumn({
							field: "discountPercent",
							headerName: i18n.t("discount_percent"),
						}),
						textColumn({
							field: "totalPrice",
							headerName: i18n.t("sum_without_vat"),
							width: 150,
						}),
						textColumn({
							field: "totalVat",
							headerName: i18n.t("vat"),
						}),
						textColumn({
							field: "totalPriceWithVat",
							headerName: i18n.t("sum_with_vat"),
							width: 150,
						}),
						...getAccountingDimensionColumns(accountingDimensions),
					]}
					{...dataGridProps}
				/>
			)}
		/>
	);
};

function getAccountingDimensionColumns(
	dimensions: AccountingDimension[],
): AavoGridColDef<InvoiceLinesDataGridApi_InvoiceLineDto>[] {
	return dimensions.map((dimension) =>
		textColumn({
			field: `accounting_dimension_${dimension.accountingDimensionId}` as any,
			headerName: dimension.name,
			width: 100,
			valueGetter: (_, row) => {
				const accountingCode = row.accountingCodes.find(
					(ac) => ac.accountingDimensionId === dimension.accountingDimensionId,
				);
				return accountingCode?.accountingCodeCode ?? "";
			},
		}),
	);
}
