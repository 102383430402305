import { FormCommonProps } from "../../../../common/forms/types.ts";
import { DeepPartial } from "react-hook-form";
import { AsyncForm } from "src/components/common/forms/AsyncForm.tsx";
import {
	CustomerOrderAccountingApi,
	CustomerOrderAccountingApi_FormInitData,
} from "src/api/generated/erp/sales/customerOrder/api/customerOrderAccountingApi.ts";
import {
	ObjectAccountingForm,
	ObjectAccountingFormValues,
} from "src/components/views/erp/sales/accounting/ObjectAccountingForm.tsx";
import {
	getObjectAccountingCodeIdsFromFormValues,
	getObjectAccountingFormDefaultValues,
} from "src/components/views/erp/sales/accounting/ObjectAccountingForm.utils.ts";

export interface CustomerOrderAccountingFormProps extends FormCommonProps<void> {
	customerOrderId: number;
}

interface FormValues extends ObjectAccountingFormValues {}

export const CustomerOrderAccountingForm = (props: CustomerOrderAccountingFormProps) => {
	const { customerOrderId, onCompleted, onFormEdited } = props;
	return (
		<AsyncForm
			fetch={() => CustomerOrderAccountingApi.getFormInitData({ customerOrderId })}
			getDefaultValues={getDefaultValues}
			submit={submit}
			onCompleted={onCompleted}
			onFormEdited={onFormEdited}
			render={({ control }) => <ObjectAccountingForm control={control} />}
		/>
	);

	function getDefaultValues({
		accountingDimensions,
	}: CustomerOrderAccountingApi_FormInitData): DeepPartial<FormValues> {
		return getObjectAccountingFormDefaultValues(accountingDimensions);
	}

	async function submit(values: FormValues) {
		await CustomerOrderAccountingApi.setCustomerOrderAccountingDimensions({
			customerOrderId: customerOrderId,
			accountingCodeIds: getObjectAccountingCodeIdsFromFormValues(values.accountingDimensionValues),
		});
	}
};
