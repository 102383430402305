import { AsyncFetchRender } from "src/components/common/async/AsyncFetchRender.tsx";
import {
	DocumentsOfMultipleObjectsView,
	DocumentsOfMultipleObjectsViewProps,
} from "src/components/views/documents/objectDocuments/DocumentsOfMultipleObjectsView.tsx";
import { filterNulls } from "src/utils/arrayUtils.ts";
import { ShopOrderView } from "src/api/generated/erp/db/types/tables/shopOrderView.ts";
import { AavoObjectRef } from "src/api/generated/common/sourceType/aavoObjectRef.ts";
import { ShopOrderApi } from "src/api/generated/erp/production/api/shopOrder/shopOrderApi.ts";

export interface ShopOrdersExtendedDocumentsViewProps
	extends Omit<DocumentsOfMultipleObjectsViewProps, "objectRefs" | "editable"> {
	shopOrderIds: number[];
}

/**
 * View for displaying documents of all documents of multiple shop orders.
 * For each shop order, the documents of the shop order, part, customer order, and customer order line are displayed.
 */
export const ShopOrdersExtendedDocumentsView = ({
	shopOrderIds,
	...other
}: ShopOrdersExtendedDocumentsViewProps) => {
	return (
		<AsyncFetchRender
			fetch={() => ShopOrderApi.getShopOrders({ shopOrderIds })}
			content={(shopOrders) => {
				return (
					<DocumentsOfMultipleObjectsView
						objectRefs={shopOrders.flatMap(getObjectRefsForShopOrder)}
						editable={false}
						{...other}
					/>
				);
			}}
		/>
	);

	function getObjectRefsForShopOrder(shopOrder: ShopOrderView): AavoObjectRef[] {
		return filterNulls([
			{ objectType: "SHOP_ORDER", objectId: shopOrder.shopOrderId },
			{ objectType: "PART_REVISION", objectId: shopOrder.partRevisionId },
			shopOrder.partConfigurationId != null ?
				{ objectType: "PART_CONFIGURATION", objectId: shopOrder.partConfigurationId }
			:	null,
			shopOrder.customerOrderId != null ?
				{ objectType: "CUSTOMER_ORDER", objectId: shopOrder.customerOrderId }
			:	null,
			shopOrder.customerOrderLineId != null ?
				{ objectType: "CUSTOMER_ORDER_LINE", objectId: shopOrder.customerOrderLineId }
			:	null,
		]);
	}
};
