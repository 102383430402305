import { useClientSideDataGridModel } from "src/components/common/dataGrid/gridModel/useClientSideDataGridModel.tsx";
import { SalesCommissionLineNamesApi } from "src/api/generated/erp/sales/salesCommissions/api/salesCommissionLineNamesApi.ts";
import { textColumn } from "src/components/common/dataGrid/columns.tsx";
import { ControlledAsyncCrudDataGrid } from "src/components/common/dataGrid/crud/ControlledAsyncCrudDataGrid.tsx";
import { SalesCommissionLineNameForm } from "src/components/views/erp/sales/basedata/salesCommissions/SalesCommissionLineNameForm.tsx";
import { SalesCommissionLineName } from "src/api/generated/erp/db/types/tables/salesCommissionLineName.ts";
import i18n from "i18next";

export const SalesCommissionLineNamesDataGrid = () => {
	const { dataGridProps } = useClientSideDataGridModel({
		fetchData: () => SalesCommissionLineNamesApi.getSalesCommissionLineNames(),
		initialParams: {},
		getRowId: (row) => row.salesCommissionLineNameId,
		gridId: "A768001B457A44D1",
	});

	return (
		<ControlledAsyncCrudDataGrid<SalesCommissionLineName>
			columns={[
				textColumn({
					field: "name",
					headerName: i18n.t("name"),
					width: 200,
				}),
			]}
			form={{
				addRowEnabled: true,
				editEnabled: true,
				dialogTitle: i18n.t("commission_line_name"),
				dialogSize: "sm",
				component: ({ row, onCompleted, onFormEdited }) => (
					<SalesCommissionLineNameForm
						salesCommissionLineName={row}
						onCompleted={onCompleted}
						onFormEdited={onFormEdited}
					/>
				),
			}}
			remove={({ items }) =>
				SalesCommissionLineNamesApi.delete_({
					salesCommissionLineNameId: items[0]!.salesCommissionLineNameId,
				})
			}
			{...dataGridProps}
		/>
	);
};
