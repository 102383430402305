import { RefreshableElementRef } from "src/utils/useRefreshRef.ts";
import { ControlChartGraphViewApi } from "src/components/views/spc/controlChart/controlChartGraph/ControlChartGraphView.tsx";
import React from "react";

export interface ControlChartInspectingViewContextValue {
	graphViewApiRef: React.MutableRefObject<ControlChartGraphViewApi | null>;
	eventsDataGridRefreshRef: RefreshableElementRef;
	unresolvedSpecialCausesDataGridRefreshRef: RefreshableElementRef;
}

export const ControlChartInspectingViewContext = React.createContext<
	ControlChartInspectingViewContextValue | undefined
>(undefined);
