import { SurveyFormFieldApi } from "src/api/generated/erp/surveys/api/surveyFormFieldApi.ts";
import { CrudDataGrid } from "src/components/common/dataGrid/crud/CrudDataGrid.tsx";
import { SurveyFormField } from "src/api/generated/erp/db/types/tables/surveyFormField.ts";
import { booleanColumn, enumColumn, textColumn } from "src/components/common/dataGrid/columns.tsx";
import { getSurveyFormFieldTypeLabels } from "src/api/generated/erp/db/types/enums/surveyFormFieldType.ts";
import { ClientSideDataGridModel } from "src/components/common/dataGrid/gridModel/ClientSideDataGridModel.tsx";
import { SurveyFormRevisionView } from "src/api/generated/erp/db/types/tables/surveyFormRevisionView.ts";
import i18n from "i18next";
import { AsyncButton } from "src/components/common/buttons/AsyncButton.tsx";
import { faAngleDown, faAngleUp } from "@fortawesome/pro-regular-svg-icons";
import { SurveyFormFieldForm } from "src/components/views/erp/surveys/management/fields/SurveyFormFieldForm.tsx";

export interface SurveyFormFieldsDataGridProps {
	surveyFormRevision: SurveyFormRevisionView;
}

export const SurveyFormFieldsDataGrid = ({ surveyFormRevision }: SurveyFormFieldsDataGridProps) => {
	const revisionIsInitial = surveyFormRevision.state === "INITIAL";

	return (
		<ClientSideDataGridModel
			gridId={"BB5440D86478D9A"}
			fetchData={() =>
				SurveyFormFieldApi.getSurveyFormRevisionFields({
					surveyFormRevisionId: surveyFormRevision.surveyFormRevisionId,
				})
			}
			initialParams={{}}
			getRows={(response) => response}
			getRowId={(row) => row.surveyFormFieldId}
			render={({ dataGridProps, onlySelectedRow, refreshData }) => (
				<CrudDataGrid<SurveyFormField>
					disableMultipleRowSelection
					columns={[
						textColumn({
							field: "label",
							headerName: i18n.t("name"),
							width: 200,
						}),
						enumColumn({
							field: "fieldType",
							headerName: i18n.t("type"),
							width: 200,
							enumLabels: getSurveyFormFieldTypeLabels(),
						}),
						booleanColumn({
							field: "required",
							headerName: i18n.t("required"),
							width: 100,
						}),
					]}
					actionBarComponents={
						<>
							<AsyncButton
								icon={faAngleUp}
								disabled={!onlySelectedRow || !revisionIsInitial}
								onClick={async () => {
									await SurveyFormFieldApi.move({
										surveyFormFieldId: onlySelectedRow!.surveyFormFieldId,
										direction: "UP",
									});
									await refreshData();
								}}
							/>
							<AsyncButton
								icon={faAngleDown}
								disabled={!onlySelectedRow || !revisionIsInitial}
								onClick={async () => {
									await SurveyFormFieldApi.move({
										surveyFormFieldId: onlySelectedRow!.surveyFormFieldId,
										direction: "DOWN",
									});
									await refreshData();
								}}
							/>
						</>
					}
					form={{
						addRowEnabled: revisionIsInitial,
						editEnabled: true,
						dialogTitle: i18n.t("field"),
						dialogSize: "lg",
						component: ({ row, onCompleted, onFormEdited }) => (
							<SurveyFormFieldForm
								surveyFormRevision={surveyFormRevision}
								surveyFormFieldId={row?.surveyFormFieldId}
								onCompleted={onCompleted}
								onFormEdited={onFormEdited}
							/>
						),
					}}
					remove={
						revisionIsInitial ?
							({ row }) => SurveyFormFieldApi.delete_({ surveyFormFieldId: row.surveyFormFieldId })
						:	{ type: "disabled" }
					}
					{...dataGridProps}
				/>
			)}
		/>
	);
};
