import { useController, UseControllerProps } from "react-hook-form";
import "react-quill/dist/quill.snow.css";
import { SxProps, Theme } from "@mui/material/styles";
import { AavoRichTextEditor } from "src/components/common/inputFields/AavoRichTextEditor.tsx";

export interface FormRichTextEditorProps<TFieldValues extends object> {
	control: UseControllerProps<TFieldValues>["control"];
	name: UseControllerProps<TFieldValues>["name"];
	label?: string;
	sx?: SxProps<Theme>;
}

export const FormRichTextEditor = <TFieldValues extends object>({
	control,
	name,
	...other
}: FormRichTextEditorProps<TFieldValues>) => {
	const { field } = useController({
		name,
		control,
	});

	return (
		<AavoRichTextEditor
			value={field.value}
			onChange={(newValue, _delta, source) => {
				// Without source check the onChange will be called on mount.
				// react-quill seems format the value and then call onChange.
				// This causes form field to be marked as dirty on mount.
				if (source === "user") {
					field.onChange(newValue);
				}
			}}
			onBlur={field.onBlur}
			readOnly={field.disabled}
			{...other}
		/>
	);
};
