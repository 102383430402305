// This file is automatically generated. Do not edit manually.

import { ConfiguratorTableCell } from "src/api/generated/erp/db/types/tables/configuratorTableCell";
import { ConfiguratorTableColumn } from "src/api/generated/erp/db/types/tables/configuratorTableColumn";
import { ConfiguratorTableRow } from "src/api/generated/erp/db/types/tables/configuratorTableRow";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace ConfiguratorTableTabContentQueryApi {
	export async function getTabContent(args: {
		configuratorTableTabId: number;
	}): Promise<ConfiguratorTableTabContentQueryApi_TabContent> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/configurator/tables/tab/content/getTabContent",
			method: "POST",
			data: {
				configuratorTableTabId: args.configuratorTableTabId,
			},
		});
		return response as ConfiguratorTableTabContentQueryApi_TabContent;
	}
}

export interface ConfiguratorTableTabContentQueryApi_TabContent {
	cells: Array<ConfiguratorTableCell>;
	columns: Array<ConfiguratorTableColumn>;
	rows: Array<ConfiguratorTableRow>;
}
