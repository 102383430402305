import { SelectCatalogPartRevisionView } from "src/components/views/erp/parts/catalogPart/catalogPartRevisionSelection/SelectCatalogPartRevisionView";
import { AsyncButton } from "src/components/common/buttons/AsyncButton.tsx";
import { faLink } from "@fortawesome/pro-regular-svg-icons";
import i18n from "i18next";
import {
	ProjectActivityCatalogPartsApi
} from "src/api/generated/erp/project/projectActivity/api/projectActivityCatalogPartsApi.ts";

export interface ConnectCatalogPartRevisionToProjectActivityViewProps {
	activityId: number;
	onCompleted: () => Promise<unknown>;
}

export const ConnectCatalogPartRevisionToProjectActivityView = ({
	activityId,
	onCompleted,
}: ConnectCatalogPartRevisionToProjectActivityViewProps) => {
	return (
		<SelectCatalogPartRevisionView
			actionBarComponents={(selectedRevisions) => (
				<AsyncButton
					icon={faLink}
					label={i18n.t("connect")}
					variant={"outlined"}
					disabled={selectedRevisions.length === 0}
					onClick={async () => {
						await ProjectActivityCatalogPartsApi.connectRevisionsToActivity({
							projectActivityId: activityId,
							catalogPartRevisionIds: selectedRevisions.map((r) => r.catalogPartRevisionId),
						});
						await onCompleted();
					}}
				/>
			)}
		/>
	);
};
