// This file is automatically generated. Do not edit manually.

import { ConfiguratorTable } from "src/api/generated/erp/db/types/tables/configuratorTable";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace ConfiguratorTablesApi {
	export async function getConfiguratorTables(args: { searchQuery: string }): Promise<Array<ConfiguratorTable>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/configurator/tables/getConfiguratorTables",
			method: "POST",
			data: {
				searchQuery: args.searchQuery,
			},
		});
		return response as Array<ConfiguratorTable>;
	}

	export async function insert(args: { configuratorTable: ConfiguratorTable }): Promise<number> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/configurator/tables/insert",
			method: "POST",
			data: {
				configuratorTable: args.configuratorTable,
			},
		});
		return response as number;
	}

	export async function update(args: { configuratorTable: ConfiguratorTable }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/configurator/tables/update",
			method: "POST",
			data: {
				configuratorTable: args.configuratorTable,
			},
		});
		return response as void;
	}

	export async function delete_(args: { configuratorTableId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/configurator/tables/delete",
			method: "POST",
			data: {
				configuratorTableId: args.configuratorTableId,
			},
		});
		return response as void;
	}
}
