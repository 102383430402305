import { GridValidRowModel } from "@mui/x-data-grid/models/gridRows";
import { GridRenderEditCellParams } from "@mui/x-data-grid-pro";
import { Dayjs } from "dayjs";
import { AavoDatePicker } from "src/components/common/inputFields/AavoDatePicker";
import React from "react";

export interface AavoDataGridDateEditorProps<RowData extends GridValidRowModel>
	extends GridRenderEditCellParams<RowData, Dayjs> {}

export const AavoDataGridDateEditor = <RowData extends GridValidRowModel>({
	api,
	id,
	value,
	field,
	hasFocus,
}: AavoDataGridDateEditorProps<RowData>) => {
	const [isOpen, setIsOpen] = React.useState(hasFocus);

	return (
		<AavoDatePicker
			value={value}
			open={isOpen}
			onOpen={() => setIsOpen(true)}
			onClose={() => setIsOpen(false)}
			onChange={(newValue) => {
				api.setEditCellValue({ id, field, value: newValue });
			}}
		/>
	);
};
