import {ClientSideDataGridModel} from "src/components/common/dataGrid/gridModel/ClientSideDataGridModel";
import {
	ConfiguratorLibrariesApi
} from "src/api/generated/erp/configurator/management/libraries/api/configuratorLibrariesApi.ts";
import {CrudDataGrid} from "src/components/common/dataGrid/crud/CrudDataGrid";
import {integerColumn, textColumn} from "src/components/common/dataGrid/columns.tsx";
import i18n from "i18next";
import {ConfiguratorLibrary} from "src/api/generated/erp/db/types/tables/configuratorLibrary.ts";
import {
	ConfiguratorLibraryForm
} from "src/components/views/erp/configurator/managing/libraries/ConfiguratorLibraryForm.tsx";

export interface ConfiguratorLibrariesDataGridProps {
	onSelectionChanged: (selectedLibrary: ConfiguratorLibrary | undefined) => void;
}

export const ConfiguratorLibrariesDataGrid = ({ onSelectionChanged }: ConfiguratorLibrariesDataGridProps) => {
	return (
		<ClientSideDataGridModel
			fetchData={() => ConfiguratorLibrariesApi.getConfiguratorLibraries()}
			initialParams={{}}
			getRows={(data) => data}
			getRowId={(row) => row.configuratorLibraryId}
			gridId={"72A57A99F052CDAD"}
			onSelectionChanged={(rows) => onSelectionChanged(rows[0])}
			render={({ dataGridProps }) => (
				<CrudDataGrid<ConfiguratorLibrary>
					disableMultipleRowSelection
					columns={[
						textColumn({
							field: "name",
							headerName: i18n.t("name"),
						}),
						integerColumn({
							field: "configuratorLibraryId",
							headerName: i18n.t("id"),
						}),
					]}
					form={{
						addRowEnabled: true,
						editEnabled: true,
						dialogSize: "sm",
						dialogTitle: i18n.t("configurator_library"),
						component: ({ row, onCompleted, onFormEdited }) => (
							<ConfiguratorLibraryForm
								configuratorLibrary={row}
								onCompleted={onCompleted}
								onFormEdited={onFormEdited}
							/>
						),
					}}
					remove={({ items }) =>
						ConfiguratorLibrariesApi.delete_({
							libraryId: items[0]!.configuratorLibraryId,
						})
					}
					{...dataGridProps}
				/>
			)}
		/>
	);
};
