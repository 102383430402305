// This file is automatically generated. Do not edit manually.

import { IsoDateString } from "src/types/dateTime";
import { FrontendDownloadFileHandle } from "src/api/generated/common/frontendDownloadFileHandle";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace ControlChartExportApi {
	export async function exportControlChartsData(args: {
		controlChartIds: Array<number>;
		startDate: IsoDateString;
		endDate: IsoDateString;
	}): Promise<FrontendDownloadFileHandle> {
		const response = await makeAxiosRequest({
			url: "/api/ext/spc/controlCharts/exportControlChartsData",
			method: "POST",
			data: {
				controlChartIds: args.controlChartIds,
				startDate: args.startDate,
				endDate: args.endDate,
			},
		});
		return response as FrontendDownloadFileHandle;
	}
}
