import { AsyncForm } from "src/components/common/forms/AsyncForm.tsx";
import {
	ResourceStateChartEditApi,
	ResourceStateChartEditApi_InitData,
} from "src/api/generated/spc/resources/api/resourceStateChartEditApi.ts";
import { FormCommonProps } from "src/components/common/forms/types.ts";
import { FormSelectField } from "src/components/common/forms/fields/FormSelectField.tsx";
import { FormEnumSelectField } from "src/components/common/forms/fields/FormEnumSelectField.tsx";
import { getResourceStateLabels } from "src/api/generated/io/aavo/applications/db/postgres/enums/resourceState.ts";
import i18n from "i18next";
import { requireRule } from "src/components/common/forms/validation.ts";
import { ResourceStateChart } from "src/api/generated/postgres/db/types/ext_resourcing/tables/resourceStateChart.ts";
import { dropProps } from "src/utils/dropProps.ts";

export interface ResourceStateChartFormProps extends FormCommonProps<number> {
	resourceId: number;
	resourceStateChartId: number | null;
}

export const ResourceStateChartForm = ({
	resourceId,
	resourceStateChartId,
	onFormEdited,
	onCompleted,
}: ResourceStateChartFormProps) => {
	return (
		<AsyncForm<ResourceStateChartEditApi_InitData, ResourceStateChart, number>
			fetch={() =>
				ResourceStateChartEditApi.getInitData({
					resourceId: resourceId,
					resourceStateChartId: resourceStateChartId,
				})
			}
			getDefaultValues={({ resourceStateChart }) =>
				resourceStateChart != null ? resourceStateChart : (
					{
						resourceId: resourceId,
					}
				)
			}
			onCompleted={onCompleted}
			onFormEdited={onFormEdited}
			render={({ data: { resourceControlChartOptions, otherResourceStates }, control, trigger }) => {
				return (
					<>
						<FormEnumSelectField
							control={control}
							name={"resourceState"}
							label={i18n.t("state")}
							rules={requireRule()}
							options={dropProps(
								getResourceStateLabels(),
								otherResourceStates.map((state) => state.resourceState),
							)}
						/>
						<FormSelectField
							control={control}
							name={"stateDurationChartId"}
							label={i18n.t("state_duration_chart")}
							options={resourceControlChartOptions}
							getOptionKey={(option) => option.chartId}
							getOptionLabel={(option) => option.chartName ?? ""}
							disabled={
								resourceControlChartOptions.length === 0 ?
									i18n.t("resource_has_not_time_based_charts")
								:	undefined
							}
							rules={{
								validate: (_, values) => validateEitherChartIsSelected(values),
							}}
							onChange={async () => {
								await trigger("stateFrequencyChartId");
							}}
						/>
						<FormSelectField
							control={control}
							name={"stateFrequencyChartId"}
							label={i18n.t("state_frequency_chart")}
							options={resourceControlChartOptions}
							getOptionKey={(option) => option.chartId}
							getOptionLabel={(option) => option.chartName ?? ""}
							disabled={
								resourceControlChartOptions.length === 0 ?
									i18n.t("resource_has_not_time_based_charts")
								:	undefined
							}
							rules={{
								validate: (_, values) => validateEitherChartIsSelected(values),
							}}
							onChange={async () => {
								await trigger("stateDurationChartId");
							}}
						/>
					</>
				);
			}}
			submit={submitForm}
		/>
	);

	async function submitForm(values: ResourceStateChart) {
		return await ResourceStateChartEditApi.save({ resourceStateChart: values });
	}

	function validateEitherChartIsSelected(values: ResourceStateChart) {
		if (values.stateDurationChartId == undefined && values.stateFrequencyChartId == undefined) {
			return i18n.t("either_chart_must_be_selected");
		}
		return undefined;
	}
};
