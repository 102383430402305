// This file is automatically generated. Do not edit manually.

import { PurchaseOrderLineView } from "src/api/generated/erp/db/types/tables/purchaseOrderLineView";
import { PurchaseOrderReceipt } from "src/api/generated/erp/db/types/tables/purchaseOrderReceipt";
import { PurchaseOrderReceiptLineView } from "src/api/generated/erp/db/types/tables/purchaseOrderReceiptLineView";
import { FrontendDownloadFileHandle } from "src/api/generated/common/frontendDownloadFileHandle";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace PurchaseOrderReceptionApi {
	export async function getPurchaseOrderLinesToReceive(args: {
		purchaseOrderId: number;
	}): Promise<Array<PurchaseOrderLineView>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/purchase/purchaseOrder/reception/getPurchaseOrderLinesToReceive",
			method: "POST",
			data: {
				purchaseOrderId: args.purchaseOrderId,
			},
		});
		return response as Array<PurchaseOrderLineView>;
	}

	export async function getPurchaseOrderReceipts(args: {
		purchaseOrderId: number;
	}): Promise<Array<PurchaseOrderReceipt>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/purchase/purchaseOrder/reception/getPurchaseOrderReceipts",
			method: "POST",
			data: {
				purchaseOrderId: args.purchaseOrderId,
			},
		});
		return response as Array<PurchaseOrderReceipt>;
	}

	export async function getPurchaseOrderReceiptLines(args: {
		purchaseOrderReceiptId: number;
	}): Promise<Array<PurchaseOrderReceiptLineView>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/purchase/purchaseOrder/reception/getPurchaseOrderReceiptLines",
			method: "POST",
			data: {
				purchaseOrderReceiptId: args.purchaseOrderReceiptId,
			},
		});
		return response as Array<PurchaseOrderReceiptLineView>;
	}

	export async function createReceipt(args: { purchaseOrderId: number; note: string }): Promise<number> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/purchase/purchaseOrder/reception/createReceipt",
			method: "POST",
			data: {
				purchaseOrderId: args.purchaseOrderId,
				note: args.note,
			},
		});
		return response as number;
	}

	export async function updateReceipt(args: { purchaseOrderReceiptId: number; note: string }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/purchase/purchaseOrder/reception/updateReceipt",
			method: "POST",
			data: {
				purchaseOrderReceiptId: args.purchaseOrderReceiptId,
				note: args.note,
			},
		});
		return response as void;
	}

	export async function deleteReceipts(args: { purchaseOrderReceiptIds: Array<number> }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/purchase/purchaseOrder/reception/deleteReceipts",
			method: "POST",
			data: {
				purchaseOrderReceiptIds: args.purchaseOrderReceiptIds,
			},
		});
		return response as void;
	}

	export async function markPurchaseOrderReceiptReceived(args: { purchaseOrderReceiptId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/purchase/purchaseOrder/reception/markPurchaseOrderReceiptReceived",
			method: "POST",
			data: {
				purchaseOrderReceiptId: args.purchaseOrderReceiptId,
			},
		});
		return response as void;
	}

	export async function revertMarkPurchaseOrderReceiptReceived(args: {
		purchaseOrderReceiptId: number;
	}): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/purchase/purchaseOrder/reception/revertMarkPurchaseOrderReceiptReceived",
			method: "POST",
			data: {
				purchaseOrderReceiptId: args.purchaseOrderReceiptId,
			},
		});
		return response as void;
	}

	export async function deletePurchaseOrderReceiptLines(args: {
		purchaseOrderReceiptLineIds: Array<number>;
	}): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/purchase/purchaseOrder/reception/deletePurchaseOrderReceiptLines",
			method: "POST",
			data: {
				purchaseOrderReceiptLineIds: args.purchaseOrderReceiptLineIds,
			},
		});
		return response as void;
	}

	export async function printPurchaseOrderReceipt(args: {
		purchaseOrderReceiptId: number;
	}): Promise<FrontendDownloadFileHandle> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/purchase/purchaseOrder/reception/printPurchaseOrderReceipt",
			method: "POST",
			data: {
				purchaseOrderReceiptId: args.purchaseOrderReceiptId,
			},
		});
		return response as FrontendDownloadFileHandle;
	}
}
