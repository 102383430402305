// This file is automatically generated. Do not edit manually.

import { ServerSideDataModelRequest } from "src/api/generated/common/dataGrids/serverSideDataModelRequest";
import { ServerSideDataModelResult } from "src/api/generated/common/dataGrids/serverSideDataModelResult";
import { PartReorderEvent } from "src/api/generated/erp/db/types/tables/partReorderEvent";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace SitePartReorderEventApi {
	export async function getPartReorderEvents(args: {
		sitePartId: number;
		dataModelRequest: ServerSideDataModelRequest;
	}): Promise<ServerSideDataModelResult<PartReorderEvent>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/parts/sitePart/partReorderEvents/getPartReorderEvents",
			method: "POST",
			data: {
				sitePartId: args.sitePartId,
				dataModelRequest: args.dataModelRequest,
			},
		});
		return response as ServerSideDataModelResult<PartReorderEvent>;
	}
}
