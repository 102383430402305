import { SurveyForm } from "src/api/generated/erp/db/types/tables/surveyForm.ts";
import {
	SurveyFormEditApi,
	SurveyFormEditApi_FormInitData,
} from "src/api/generated/erp/surveys/api/surveyFormEditApi.ts";
import { DeepPartial } from "react-hook-form";
import { requireRule } from "src/components/common/forms/validation.ts";
import { FormTextField } from "src/components/common/forms/fields/FormTextField.tsx";
import { FormSelectField } from "src/components/common/forms/fields/FormSelectField.tsx";
import i18n from "i18next";
import { AsyncForm, AsyncFormContentParams } from "src/components/common/forms/AsyncForm.tsx";
import { SurveyCategory } from "src/api/generated/erp/db/types/tables/surveyCategory.ts";
import { FormCommonProps } from "src/components/common/forms/types.ts";

export interface SurveyFormFormProps extends FormCommonProps<number> {
	surveyFormId: number | undefined;
}

interface FormValues extends SurveyForm {}

export const SurveyFormForm = (props: SurveyFormFormProps) => {
	const { surveyFormId, onCompleted, onFormEdited } = props;

	return (
		<AsyncForm
			fetch={() =>
				SurveyFormEditApi.getFormInitData({
					surveyFormId,
				})
			}
			getDefaultValues={getDefaultValues}
			submit={submit}
			onCompleted={onCompleted}
			onFormEdited={onFormEdited}
			render={(contentParams) => <FormContent {...props} {...contentParams} />}
		/>
	);

	function getDefaultValues({
		surveyForm,
		surveyCategories,
	}: SurveyFormEditApi_FormInitData): DeepPartial<FormValues> {
		return (
			surveyForm ?? {
				surveyCategoryId: surveyCategories[0]?.surveyCategoryId,
			}
		);
	}

	async function submit(values: FormValues) {
		if (surveyFormId) {
			await SurveyFormEditApi.update({
				surveyForm: values,
			});
			return surveyFormId;
		} else {
			return await SurveyFormEditApi.insert({
				surveyForm: values,
			});
		}
	}
};

interface FormContentProps
	extends SurveyFormFormProps,
		AsyncFormContentParams<SurveyFormEditApi_FormInitData, FormValues> {}

const FormContent = ({ control, data: { surveyCategories } }: FormContentProps) => {
	return (
		<>
			<FormTextField control={control} name={"name"} label={i18n.t("name")} rules={requireRule()} autoFocus />
			<FormTextField control={control} name={"description"} label={i18n.t("description")} multiline />
			<FormSelectField
				control={control}
				name={"surveyCategoryId"}
				label={i18n.t("category")}
				options={surveyCategories}
				getOptionKey={(option: SurveyCategory) => option.surveyCategoryId}
				getOptionLabel={(option) => option.name}
				rules={requireRule()}
				disableClearable
			/>
		</>
	);
};
