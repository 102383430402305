import i18n from "i18next";
import { SalesCommissionPaymentLinesDataGridApi } from "src/api/generated/erp/sales/salesCommissions/api/salesCommissionPaymentLinesDataGridApi.ts";
import { SalesCommissionPaymentView } from "src/api/generated/erp/db/types/tables/salesCommissionPaymentView.ts";
import { AddLinesToSalesCommissionPaymentView } from "src/components/views/erp/sales/salesCommissions/paymentsView/AddLinesToSalesCommissionPaymentView.tsx";
import { faPlus } from "@fortawesome/pro-regular-svg-icons";
import { AsyncButton } from "src/components/common/buttons/AsyncButton.tsx";
import { useGenericDialog } from "src/components/common/dialogs/useGenericDialog.ts";
import { SalesCommissionPaymentApi } from "src/api/generated/erp/sales/salesCommissions/api/salesCommissionPaymentApi.ts";
import { getSalesCommissionLinesDataGridColumns } from "src/components/views/erp/sales/salesCommissions/salesCommissionUtils.ts";
import {
	ClientSideDataGridModel,
	ClientSideDataGridModelRenderProps,
} from "src/components/common/dataGrid/gridModel/ClientSideDataGridModel.tsx";
import { SalesCommissionLineView } from "src/api/generated/erp/db/types/tables/salesCommissionLineView.ts";
import { CrudDataGrid } from "src/components/common/dataGrid/crud/CrudDataGrid.tsx";
import { OpenCustomerOrderButton } from "src/components/views/erp/utilComponents/OpenCustomerOrderButton.tsx";

export interface SalesCommissionPaymentLinesDataGridProps {
	salesCommissionPayment: SalesCommissionPaymentView;
}

export const SalesCommissionPaymentLinesDataGrid = (props: SalesCommissionPaymentLinesDataGridProps) => {
	const { salesCommissionPayment } = props;
	return (
		<ClientSideDataGridModel<SalesCommissionLineView[], SalesCommissionLineView, object>
			fetchData={() =>
				SalesCommissionPaymentLinesDataGridApi.getCommissionPaymentLines({
					salesCommissionPaymentId: salesCommissionPayment.salesCommissionPaymentId,
				})
			}
			initialParams={{}}
			getRowId={(row) => row.salesCommissionLineId}
			getRows={(data) => data}
			gridId={"635EF7E13AB2F514"}
			render={(params) => <Content {...props} {...params} />}
		/>
	);
};

interface ContentProps
	extends SalesCommissionPaymentLinesDataGridProps,
		ClientSideDataGridModelRenderProps<SalesCommissionLineView[], SalesCommissionLineView, object> {}

const Content = ({ salesCommissionPayment, dataGridProps, refreshData }: ContentProps) => {
	const { openDialog } = useGenericDialog();

	return (
		<CrudDataGrid<SalesCommissionLineView>
			columns={getSalesCommissionLinesDataGridColumns({
				showState: false,
				showSalesperson: false,
				showEstimatedApprovalDate: false,
				showPaymentColumns: false,
			})}
			actionBarComponents={
				<>
					{salesCommissionPayment.state == "INITIAL" && (
						<AsyncButton
							label={i18n.t("add_installments")}
							icon={faPlus}
							variant={"outlined"}
							onClick={() => onAddLinesClick()}
						/>
					)}
				</>
			}
			remove={
				salesCommissionPayment.state === "INITIAL" ?
					{
						type: "enabled",
						action: async ({ items }) => {
							await SalesCommissionPaymentApi.removeCommissionLinesFromPayment({
								salesCommissionPaymentId: salesCommissionPayment.salesCommissionPaymentId,
								commissionLineIds: items.map((item) => item.salesCommissionLineId),
							});
						},
					}
				:	{ type: "hidden" }
			}
			rowContextMenuComponents={({ row }) => [
				<OpenCustomerOrderButton key={"openCustomerOrder"} customerOrderId={row.customerOrderId} />,
			]}
			{...dataGridProps}
		/>
	);

	async function onAddLinesClick() {
		openDialog(({ closeDialog }) => ({
			title: i18n.t("add_installments"),
			size: "xl",
			content: (
				<AddLinesToSalesCommissionPaymentView
					payment={salesCommissionPayment}
					closeDialog={async () => {
						await closeDialog();
						await refreshData();
					}}
				/>
			),
		}));
	}
};
