import { ObjectMemberType } from "src/api/generated/iam/model/objectMemberType.ts";

export type ObjectPermissionId = string;

export function getObjectPermissionId({
	memberType,
	memberId,
}: {
	memberType: ObjectMemberType;
	memberId: number;
}): ObjectPermissionId {
	return `${memberType}_${memberId}`;
}
