// This file is automatically generated. Do not edit manually.

import { t } from "i18next";

export const SystemDiagramNodeShapeValues = [
	"RECTANGULAR",
	"TRAPEZOID",
	"CIRCLE",
	"CYLINDER",
	"DIAMOND",
	"HEXAGON",
] as const;

export type SystemDiagramNodeShape = (typeof SystemDiagramNodeShapeValues)[number];

export const getSystemDiagramNodeShapeLabels = () => ({
	RECTANGULAR: t("rectangular"),
	TRAPEZOID: t("trapezoid"),
	CIRCLE: t("circle"),
	CYLINDER: t("cylinder"),
	DIAMOND: t("diamond"),
	HEXAGON: t("hexagon"),
});

export const getSystemDiagramNodeShapeLabel = (value: SystemDiagramNodeShape): string => {
	return getSystemDiagramNodeShapeLabels()[value];
};
