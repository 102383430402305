// This file is automatically generated. Do not edit manually.

import { t } from "i18next";

export const ReclamationStateValues = ["INITIAL", "RELEASED", "CLOSED"] as const;

export type ReclamationState = (typeof ReclamationStateValues)[number];

export const getReclamationStateLabels = () => ({
	INITIAL: t("initial"),
	RELEASED: t("released"),
	CLOSED: t("closed"),
});

export const getReclamationStateLabel = (value: ReclamationState): string => {
	return getReclamationStateLabels()[value];
};
