// This file is automatically generated. Do not edit manually.

import { t } from "i18next";

export const ProductChangeRequestStateValues = [
	"SHELVED",
	"CLOSED",
	"READY_TO_PRODUCT_DESIGN",
	"REQUIREMENT_SPECIFICATION_COMPLETED",
	"IN_REQUIREMENT_SPECIFICATION",
	"PRE_EVALUATION_COMPLETED",
	"IN_PRE_EVALUATION",
	"INITIAL",
] as const;

export type ProductChangeRequestState = (typeof ProductChangeRequestStateValues)[number];

export const getProductChangeRequestStateLabels = () => ({
	SHELVED: t("shelved"),
	CLOSED: t("closed"),
	READY_TO_PRODUCT_DESIGN: t("ready_to_product_design"),
	REQUIREMENT_SPECIFICATION_COMPLETED: t("requirement_specification_completed"),
	IN_REQUIREMENT_SPECIFICATION: t("in_requirement_specification"),
	PRE_EVALUATION_COMPLETED: t("pre_evaluation_completed"),
	IN_PRE_EVALUATION: t("in_pre_evaluation"),
	INITIAL: t("initial"),
});

export const getProductChangeRequestStateLabel = (value: ProductChangeRequestState): string => {
	return getProductChangeRequestStateLabels()[value];
};
