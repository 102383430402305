// This file is automatically generated. Do not edit manually.

import { t } from "i18next";

export const SurveySubmissionObjectTypeValues = ["SHOP_ORDER_OPERATION"] as const;

export type SurveySubmissionObjectType = (typeof SurveySubmissionObjectTypeValues)[number];

export const getSurveySubmissionObjectTypeLabels = () => ({
	SHOP_ORDER_OPERATION: t("shop_order_operation"),
});

export const getSurveySubmissionObjectTypeLabel = (value: SurveySubmissionObjectType): string => {
	return getSurveySubmissionObjectTypeLabels()[value];
};
