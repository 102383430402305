import {
	deletePushNotificationRegistrations,
	PushNotificationCallbacks,
	setupFirebaseMessaging
} from "./firebaseMessaging";
import { useAsync } from "react-async-hook";
import { getAndResetInitialNotificationAction } from "./initialNotificationAction";
import { LegacyPushAction } from "./legacyPushAction";
import { makeAxiosRequest } from "../api/utils/apiClient";
import React from "react";

export interface PushNotificationsContextValue {
	registrationToken: string | null;
	legacyPushAction: LegacyPushAction | null;
	onPushActionHandled: (actionId: string) => void;
	deleteRegistration: () => Promise<void>;
}

export const PushNotificationsContext = React.createContext<
	PushNotificationsContextValue | undefined
>(undefined);

type PushNotificationsProviderProps = {
	children: React.ReactNode;
};

export function PushNotificationsContextProvider({
													 children
												 }: PushNotificationsProviderProps) {
	const [registrationToken, setRegistrationToken] = React.useState<
		string | null
	>(null);

	const [legacyPushAction, setLegacyPushAction] =
		React.useState<LegacyPushAction | null>(null);

	const pushNotificationCallbacks: PushNotificationCallbacks = {
		legacyAavoActionReceived: (action: LegacyPushAction) => {
			setLegacyPushAction(action);
		}
	};

	useAsync(async () => {
		await setupMessaging(pushNotificationCallbacks, setRegistrationToken);
		handlePossibleInitialMessageAction(setLegacyPushAction);
	}, []);

	const contextValue = {
		registrationToken,
		legacyPushAction,
		onPushActionHandled: (actionId: string) => {
			if (legacyPushAction?.actionId === actionId)
				setLegacyPushAction(null);
		},
		deleteRegistration: async () => {
			setRegistrationToken(null);
			await deletePushNotificationRegistrations();
		}
	};

	return (
		<PushNotificationsContext.Provider value={contextValue}>
			{children}
		</PushNotificationsContext.Provider>
	);
}

async function setupMessaging(
	pushNotificationCallbacks: PushNotificationCallbacks,
	setRegistrationToken: (value: string | null) => void
) {
	try {
		const token = await setupFirebaseMessaging(pushNotificationCallbacks);
		if (token == null) {
			console.info("Null push notification token returned");
			return;
		}
		setRegistrationToken(token);
		await sendRegistrationTokenToServer(token);
	} catch (error) {
		console.error("Push notification token fetching failed", error);
	}
}

async function sendRegistrationTokenToServer(token: string) {
	await makeAxiosRequest({
		url: "/api/notifications/subscribe",
		method: "POST",
		data: {
			registrationToken: token
		}
	});
}

function handlePossibleInitialMessageAction(
	setLegacyPushAction: (pushAction: LegacyPushAction) => void
) {
	const initialActionEvent = getAndResetInitialNotificationAction();
	if (initialActionEvent) setLegacyPushAction(initialActionEvent);
}