import { RefreshableElementRef } from "src/utils/useRefreshRef.ts";
import React from "react";

export interface DeliverySourcePickingViewContextValue {
	reservableLinesViewRefreshRef: RefreshableElementRef;
	pickableLinesViewRefreshRef: RefreshableElementRef;
	pickedLinesViewRefreshRef: RefreshableElementRef;
}

export const DeliverySourcePickingViewContext = React.createContext<DeliverySourcePickingViewContextValue | undefined>(
	undefined,
);
