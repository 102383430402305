// This file is automatically generated. Do not edit manually.

import { PartType } from "src/api/generated/erp/db/types/enums/partType";
import { ServerSideDataModelRequest } from "src/api/generated/common/dataGrids/serverSideDataModelRequest";
import { ServerSideDataModelResult } from "src/api/generated/common/dataGrids/serverSideDataModelResult";
import { AcquisitionMethod } from "src/api/generated/erp/db/types/enums/acquisitionMethod";
import { PartBatchSizeCalculationParams } from "src/api/generated/io/aavo/applications/db/erp/types/partBatchSizeCalculationParams";
import { IsoDateTimeString } from "src/types/dateTime";
import { PartReorderPointCalculationParams } from "src/api/generated/io/aavo/applications/db/erp/types/partReorderPointCalculationParams";
import { PartValuationMethod } from "src/api/generated/erp/db/types/enums/partValuationMethod";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace InventoryLevelProfileDataGridApi {
	export async function getGridData(args: {
		partCategoryId: number | null | undefined;
		partType: PartType | null | undefined;
		responsiblePersonId: number | null | undefined;
		searchQuery?: string;
		onlyPartToShowId?: number | null | undefined;
		showOnlyDefaultSiteParts: boolean;
		dataModelRequest: ServerSideDataModelRequest;
	}): Promise<ServerSideDataModelResult<InventoryLevelProfileDataGridApi_Row>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/warehouse/inventory/inventoryLevelProfileDataGrid/getGridData",
			method: "POST",
			data: {
				partCategoryId: args.partCategoryId,
				partType: args.partType,
				responsiblePersonId: args.responsiblePersonId,
				searchQuery: args.searchQuery,
				onlyPartToShowId: args.onlyPartToShowId,
				showOnlyDefaultSiteParts: args.showOnlyDefaultSiteParts,
				dataModelRequest: args.dataModelRequest,
			},
		});
		return response as ServerSideDataModelResult<InventoryLevelProfileDataGridApi_Row>;
	}
}

export interface InventoryLevelProfileDataGridApi_Row {
	availableQuantity: number;
	reservedQuantity: number;
	stockQuantity: number;
	acquisitionMethod: AcquisitionMethod;
	active: boolean;
	additionalNumber: string;
	automaticClose: boolean;
	automaticRelease: boolean;
	automaticReorder: boolean;
	batchSize: number | null | undefined;
	batchSizeCalculationParams: PartBatchSizeCalculationParams | null | undefined;
	capacityQuantity: number | null | undefined;
	catalogPartId: number | null | undefined;
	categoryId: number;
	createdAt: IsoDateTimeString;
	createdByUserId: number;
	defaultProductionLineDescription: string | null | undefined;
	defaultProductionLineId: number | null | undefined;
	defaultProductionLineName: string | null | undefined;
	defaultProductionLineNo: string | null | undefined;
	demandControlChartId: number | null | undefined;
	designUnitFactor: number | null | undefined;
	designUnitId: number | null | undefined;
	designUnitName: string | null | undefined;
	estimatedLeadtime: number | null | undefined;
	estimatedProductionTimeHours: number | null | undefined;
	isConfigurable: boolean;
	lastPurchasePrice: number | null | undefined;
	lastUpdated: IsoDateTimeString;
	manufacturerPartNumber: string;
	partCategory: string | null | undefined;
	partCategoryDescription: string;
	partDescription_1: string;
	partDescription_2: string;
	partId: number;
	partLongName: string;
	partNo: string;
	partType: PartType;
	partUnit: string | null | undefined;
	partUnitId: number;
	reorderPoint: number | null | undefined;
	reorderPointCalculationParams: PartReorderPointCalculationParams | null | undefined;
	responsiblePersonCode: string;
	responsiblePersonId: number;
	responsiblePersonName: string;
	siteId: number;
	siteName: string;
	standardCost: number;
	valuationMethod: PartValuationMethod;
	warehouseTransferFactor: number;
	warehouseTransferUnit: string | null | undefined;
	warehouseTransferUnitId: number;
	weight: number | null | undefined;
}
