// This file is automatically generated. Do not edit manually.

import { ObjectAttributeFieldWithValue } from "src/api/generated/erp/common/objectAttributes/objectAttributeFieldWithValue";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace SitePartAttributesApi {
	export async function getPartAttributes(args: { partId: number }): Promise<Array<ObjectAttributeFieldWithValue>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sitePart/attributes/getPartAttributes",
			method: "POST",
			data: {
				partId: args.partId,
			},
		});
		return response as Array<ObjectAttributeFieldWithValue>;
	}
}
