import { ConfigurationComponent } from "src/api/generated/erp/db/types/tables/configurationComponent.ts";
import { useContextMenu } from "src/components/common/contextMenu/useContextMenu.ts";
import { useContextOrThrow } from "src/utils/useContextOrThrow.tsx";
import { ProductFamilyVersionManagingViewContext } from "src/components/views/erp/configurator/managing/productFamilyVersions/ProductFamilyVersionManagingViewContext.tsx";
import { HorizontalBox } from "src/components/common/box/HorizontalBox.tsx";
import Typography from "@mui/material/Typography";
import { AavoContextMenu } from "src/components/common/contextMenu/AavoContextMenu.tsx";
import { MouseEvent } from "react";
import { AsyncMenuButton } from "src/components/common/contextMenu/AsyncMenuButton.tsx";
import { faPen, faTrash } from "@fortawesome/pro-regular-svg-icons";
import i18n from "i18next";
import { useGenericDialog } from "src/components/common/dialogs/useGenericDialog.ts";
import { Box, ButtonBase } from "@mui/material";
import { ConfigurationComponentForm } from "src/components/views/erp/configurator/managing/productFamilyVersions/components/componentForm/ConfigurationComponentForm.tsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDrag, useDrop } from "react-dnd";
import { mergeSx } from "src/utils/styles.ts";

export interface TabComponentTitleProps {
	tabComponent: ConfigurationComponent;
	tabIndex: number;
}

export const TabComponentTitle = (props: TabComponentTitleProps) => {
	const { tabIndex, tabComponent } = props;
	const [openContextMenu, contextMenuState] = useContextMenu();

	const { deleteComponent } = useContextOrThrow(ProductFamilyVersionManagingViewContext);

	return (
		<>
			<HorizontalBox
				onContextMenu={openContextMenuWrapped}
				sx={{
					alignItems: "stretch",
					gap: 0.5,
					position: "relative",
				}}
			>
				{tabIndex === 0 && <DropArea {...props} type={"before"} />}
				<Label {...props} />
				<EditButton {...props} />
				<DropArea {...props} type={"after"} />
			</HorizontalBox>
			<AavoContextMenu state={contextMenuState} />
		</>
	);

	function openContextMenuWrapped(e: MouseEvent) {
		const buttonCommonProps = {
			iconProps: { size: "2xs" },
		} as const;

		openContextMenu({
			mouseEvent: e,
			content: [
				<AsyncMenuButton
					{...buttonCommonProps}
					key={"delete"}
					icon={faTrash}
					label={i18n.t("delete")}
					onClick={async () => {
						await deleteComponent(tabComponent.configurationComponentId);
					}}
				/>,
			],
		});
	}
};

const Label = ({ tabComponent }: TabComponentTitleProps) => {
	const [{ isDragging }, dragRef] = useDrag<TabDragItem, unknown, { isDragging: boolean }>(
		() => ({
			type: TAB_DND_KEY,
			item: tabComponent,
			collect: (monitor) => ({
				isDragging: monitor.isDragging(),
			}),
		}),
		[tabComponent],
	);

	return (
		<Typography
			ref={dragRef}
			variant={"button"}
			sx={{
				opacity: isDragging ? 0.5 : 1,
			}}
		>
			{tabComponent.label}
		</Typography>
	);
};

interface DropAreaProps extends TabComponentTitleProps {
	type: "before" | "after";
}

const DropArea = ({ tabComponent, type }: DropAreaProps) => {
	const { onComponentDropped } = useContextOrThrow(ProductFamilyVersionManagingViewContext);

	const onDrop = async (item: ConfigurationComponent) => {
		await onComponentDropped({
			dragItem: {
				type: "existingComponent",
				componentId: item.configurationComponentId,
				currentPosition: item.orderNum,
			},
			targetPosition: type === "before" ? tabComponent.orderNum : tabComponent.orderNum + 1,
			parentTabComponentId: undefined,
		});
	};

	const [{ isDraggingOver }, dropRef] = useDrop<
		TabDragItem,
		unknown,
		{
			isDraggingOver: boolean;
		}
	>(() => {
		return {
			accept: TAB_DND_KEY,
			drop: onDrop,
			collect: (monitor) => ({
				isDraggingOver: monitor.isOver(),
			}),
		};
	}, [onDrop]);

	return (
		<Box
			ref={dropRef}
			sx={mergeSx(
				{
					zIndex: 9999,
					backgroundColor: "secondary.main",
					borderRadius: 1,
					width: "2rem",
					opacity: 0,
					transitionDuration: "0.1s",
				},
				type === "before" ? { marginRight: "-1rem" } : { marginLeft: "-1rem" },
				isDraggingOver && {
					marginLeft: "-1.5rem",
					opacity: 0.8,
					width: "3rem",
				},
			)}
		/>
	);
};

const EditButton = ({ tabComponent }: TabComponentTitleProps) => {
	const { openDialog } = useGenericDialog();
	const { updateComponent, productFamilyVersionIsReadOnly } = useContextOrThrow(
		ProductFamilyVersionManagingViewContext,
	);

	return (
		<ButtonBase
			component={"div"}
			sx={{
				paddingX: 1,
			}}
			onClick={(e: any) => {
				e.stopPropagation();
				openDialog(({ closeDialog, onDataDirtyStateChanged }) => ({
					title: tabComponent.label,
					size: "sm",
					content: (
						<ConfigurationComponentForm
							component={tabComponent}
							setIsDirty={(isDirty) => onDataDirtyStateChanged({ isDirty })}
							saveComponent={updateComponent}
							onCompleted={async () => {
								await closeDialog();
							}}
							disabled={productFamilyVersionIsReadOnly}
						/>
					),
				}));
			}}
		>
			<FontAwesomeIcon icon={faPen} />
		</ButtonBase>
	);
};

const TAB_DND_KEY = "tabComponentDnd";

type TabDragItem = ConfigurationComponent;
