import { AsyncButton, AsyncButtonProps } from "../../../common/buttons/AsyncButton.tsx";
import { useGenericDialog } from "../../../common/dialogs/useGenericDialog.ts";
import i18n from "i18next";
import { InventoryLevelProfileView } from "../warehouse/inventory/InventoryLevelProfileView.tsx";
import { faTachometerAlt } from "@fortawesome/pro-regular-svg-icons";

export interface OpenInventoryProfileButtonProps extends Omit<AsyncButtonProps, "onClick"> {
	partId: number | null | undefined;
}

export const OpenInventoryProfileButton = ({ partId, variant = "menu", ...props }: OpenInventoryProfileButtonProps) => {
	const { openDialog } = useGenericDialog();

	if (partId == null) return null;

	return (
		<AsyncButton
			label={i18n.t("inventory_level_profile")}
			icon={faTachometerAlt}
			variant={variant}
			onClick={async () => {
				openDialog({
					title: i18n.t("inventory_level_profile"),
					content: <InventoryLevelProfileView onlyPartToShowId={partId} />,
				});
			}}
			{...props}
		/>
	);
};
