import {
	dateColumn,
	enumColumn,
	integerColumn,
	textColumn,
} from "src/components/common/dataGrid/columns.tsx";
import i18n from "i18next";
import { getCatalogPartRevisionStateLabels } from "src/api/generated/erp/db/types/enums/catalogPartRevisionState.ts";
import { CatalogPartRevisionView } from "src/api/generated/erp/db/types/tables/catalogPartRevisionView.ts";
import { useClientSideDataGridModel } from "src/components/common/dataGrid/gridModel/useClientSideDataGridModel";
import { concatWithPipe } from "src/utils/strings.tsx";
import { CatalogPartRevisionApi } from "src/api/generated/erp/parts/catalogPart/api/catalogPartRevisionApi.ts";
import { ControlledAsyncDataGrid } from "src/components/common/dataGrid/ControlledAsyncDataGrid.tsx";

export interface CatalogPartRevisionSelectionDataGridProps {
	catalogPartIds: number[];
	actionBarComponents?: (selectedRows: CatalogPartRevisionView[]) => React.ReactNode;
}

export const CatalogPartRevisionSelectionDataGrid = ({
	catalogPartIds,
	actionBarComponents,
}: CatalogPartRevisionSelectionDataGridProps) => {
	const { dataGridProps, selectedRows } = useClientSideDataGridModel({
		fetchData: () =>
			CatalogPartRevisionApi.getRevisionsForCatalogParts({
				catalogPartIds: catalogPartIds,
			}),
		initialParams: {},
		getRowId: (row) => row.catalogPartRevisionId,
		gridId: "A767996B457A44D1",
	});

	return (
		<ControlledAsyncDataGrid<CatalogPartRevisionView>
			columns={[
				textColumn({
					field: "part" as any,
					headerName: i18n.t("part"),
					width: 200,
					valueGetter: (_, row) =>
						concatWithPipe(
							row.partNo,
							row.catalogPartDescription_1,
							row.catalogPartDescription_2,
						),
				}),
				integerColumn({
					field: "revisionNumber",
					headerName: i18n.t("revision"),
				}),
				textColumn({
					field: "note",
					headerName: i18n.t("note"),
				}),
				enumColumn({
					field: "state",
					headerName: i18n.t("state"),
					enumLabels: getCatalogPartRevisionStateLabels(),
				}),
				dateColumn({
					field: "createdAt",
					headerName: i18n.t("created_at"),
				}),
				dateColumn({
					field: "publishedAt",
					headerName: i18n.t("published_at"),
				}),
			]}
			actionBarComponents={actionBarComponents?.(selectedRows)}
			{...dataGridProps}
		/>
	);
};
