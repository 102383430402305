import { TasksGanttView } from "src/components/views/tasks/gantt/TasksGanttView.tsx";
import { ReclamationView } from "src/api/generated/erp/db/types/tables/reclamationView.ts";
import { RefreshableElementProps, setRefreshRefValue } from "src/utils/useRefreshRef.ts";
import { VerticalBox } from "src/components/common/box/VerticalBox";
import { faRefresh } from "@fortawesome/pro-regular-svg-icons";
import i18n from "i18next";
import { AsyncSelectField } from "src/components/common/inputFields/AsyncSelectField.tsx";
import { TaskQueryApi } from "src/api/generated/tasks/api/taskQueryApi.ts";
import { TaskQueryParams } from "src/components/views/tasks/types.ts";
import { useParameterizedAsyncData } from "src/utils/async/parameterizedAsyncData.ts";
import { emptyDataModelRequest } from "src/utils/serverSideDataModelRequestUtils.ts";
import { AsyncRender } from "src/components/common/async/AsyncRender.tsx";
import { AppUser } from "src/api/generated/postgres/db/types/role_management/tables/appUser.ts";
import { AsyncButton } from "src/components/common/buttons/AsyncButton";
import { genericNullableValue } from "src/utils/genericNullableValue.ts";
import React from "react";
import { AavoActionBar } from "src/components/common/actionBar/AavoActionBar";
import { expectNonFileResult } from "src/components/common/dataGrid/gridModel/serverSideDataModelUtils.ts";

export interface ReclamationPlanningTasksViewProps extends RefreshableElementProps {
	selectedReclamations: ReclamationView[];
	onSelectedTaskExecutorChanged: (appUserId: number | null) => void;
	actionBarComponents?: React.ReactNode;
}

export const ReclamationPlanningTasksView = ({
	refreshRef,
	selectedReclamations,
	onSelectedTaskExecutorChanged,
	actionBarComponents,
}: ReclamationPlanningTasksViewProps) => {
	const taskQueryParams: TaskQueryParams = {
		sources: selectedReclamations.map((reclamation) => ({
			sourceType: "RECLAMATION_V2",
			sourceId: reclamation.reclamationId,
		})),
		states: ["INITIAL", "RELEASED", "STARTED"],
	};

	const { dataAsync, paramValues, refresh } = useParameterizedAsyncData({
		fetchData: async (params) => {
			const result = await TaskQueryApi.queryTasks({
				dataModelRequest: emptyDataModelRequest,
				...taskQueryParams,
				...params,
			});
			return expectNonFileResult(result)
		},
		initialParams: {
			assignedToUserId: genericNullableValue<number>(),
		},
	});

	setRefreshRefValue(refreshRef, refresh);

	return (
		<AsyncRender
			asyncData={dataAsync}
			content={({ rows: tasks }) => (
				<VerticalBox
					sx={{
						flex: 1,
					}}
				>
					<AavoActionBar>
						<AsyncButton
							icon={faRefresh}
							label={""}
							color={"primary"}
							onClick={async () => {
								await refresh();
							}}
						/>
						{actionBarComponents}
						<AsyncSelectField<AppUser, number>
							label={i18n.t("executor")}
							fetchOptions={({ searchQuery, currentSelection }) =>
								TaskQueryApi.getTaskAssignedToUserOptions({
									...taskQueryParams,
									currentSelection: currentSelection,
									userNameSearchQuery: searchQuery,
								})
							}
							defaultValue={paramValues.assignedToUserId ?? null}
							getOptionKey={(option) => option.id}
							getOptionLabel={(option) => option.name}
							onChange={async (value) => {
								const appUserId = value?.id ?? null;
								await refresh({
									assignedToUserId: appUserId,
								});
								onSelectedTaskExecutorChanged(appUserId);
							}}
						/>
					</AavoActionBar>
					<TasksGanttView
						tasks={tasks}
						reload={refresh}
						renderTaskLabel={(task) => `${task.sourceDescription} | ${task.title}`}
					/>
				</VerticalBox>
			)}
		/>
	);
};
