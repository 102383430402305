import i18n from "i18next";
import { formatMoney, formatNumber } from "src/utils/numberUtils.ts";
import { Box, Typography } from "@mui/material";
import { BillingPlanSummary } from "src/api/generated/erp/sales/billingPlan/base/billingPlanSummary.ts";

export const BillingPlanSummaryText = ({ summary }: { summary: BillingPlanSummary }) => {
	const fields = [
		{
			label: i18n.t("sum"),
			value: formatMoney(summary.billingPlanSum),
		},
		{
			label: i18n.t("sum_with_vat"),
			value: formatMoney(summary.billingPlanSumWithVat),
		},
		{
			label: i18n.t("share_of_price_without_vat"),
			value: formatNumber(summary.percentOfTotalPrice, { maxDigits: 0 }) + " %",
		},
	];

	return (
		<Box
			sx={{
				display: "flex",
				gap: 2,
				alignSelf: "center",
				marginLeft: 1,
			}}
		>
			{fields.map(({ label, value }) => (
				<Typography key={label}>
					<i>{label}</i>: {value}
				</Typography>
			))}
		</Box>
	);
};
