// This file is automatically generated. Do not edit manually.

import { t } from "i18next";

export const ObjectMemberTypeValues = ["USER", "ROLE"] as const;

export type ObjectMemberType = (typeof ObjectMemberTypeValues)[number];

export const getObjectMemberTypeLabels = () => ({
	USER: t("user"),
	ROLE: t("role"),
});

export const getObjectMemberTypeLabel = (value: ObjectMemberType): string => {
	return getObjectMemberTypeLabels()[value];
};
