import { FormCommonProps } from "src/components/common/forms/types.ts";
import { requireRule } from "src/components/common/forms/validation.ts";
import i18n from "i18next";
import { FormTextField } from "src/components/common/forms/fields/FormTextField.tsx";
import { BillingPlanModel } from "src/api/generated/erp/db/types/tables/billingPlanModel.ts";
import { AavoForm } from "src/components/common/forms/AavoForm.tsx";
import { BillingPlanModelEditApi } from "src/api/generated/erp/sales/billingPlan/model/api/billingPlanModelEditApi.ts";

export interface BillingPlanModelFormProps extends FormCommonProps<number> {
	billingPlanModel: BillingPlanModel | undefined;
}

interface FormValues extends BillingPlanModel {}

export const BillingPlanModelForm = (props: BillingPlanModelFormProps) => {
	const { billingPlanModel, onCompleted, onFormEdited } = props;
	return (
		<AavoForm
			defaultValues={billingPlanModel ?? {}}
			submit={submit}
			onCompleted={onCompleted}
			onFormEdited={onFormEdited}
			render={({ control }) => (
				<>
					<FormTextField
						control={control}
						name={"name"}
						label={i18n.t("name")}
						rules={requireRule()}
					/>
					<FormTextField control={control} name={"description"} label={i18n.t("description")} />
				</>
			)}
		/>
	);

	async function submit(values: FormValues) {
		if (billingPlanModel != null) {
			await BillingPlanModelEditApi.update({
				billingPlanModel: values,
			});
			return billingPlanModel.billingPlanModelId;
		} else {
			return await BillingPlanModelEditApi.insert({
				billingPlanModel: values,
			});
		}
	}
};
