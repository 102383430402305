import { AsyncForm, AsyncFormContentParams } from "src/components/common/forms/AsyncForm";
import {
	PurchaseOrderEditApi,
	PurchaseOrderEditApi_FormInitData,
} from "src/api/generated/erp/purchase/purchaseOrder/api/purchaseOrderEditApi.ts";
import { PurchaseOrder } from "src/api/generated/erp/db/types/tables/purchaseOrder.ts";
import { FormCommonProps } from "src/components/common/forms/types.ts";
import { DeepPartial } from "react-hook-form";
import { useGlobalInitData } from "src/contexts/useGlobalInitData.ts";
import { currentLocalDateAsIsoString } from "src/utils/dayjsUtils.ts";
import { FormSelectField } from "src/components/common/forms/fields/FormSelectField.tsx";
import i18n from "i18next";
import { requireRule } from "src/components/common/forms/validation.ts";
import { SupplierApi } from "src/api/generated/erp/purchase/suppliers/supplierApi.ts";
import { FormAsyncSelectField } from "src/components/common/forms/fields/FormAsyncSelectField.tsx";
import { Supplier } from "src/api/generated/erp/db/types/tables/supplier.ts";
import {
	getPurchaseOrderTypeLabels,
	PurchaseOrderType,
} from "src/api/generated/erp/db/types/enums/purchaseOrderType.ts";
import { FormEnumSelectField } from "src/components/common/forms/fields/FormEnumSelectField.tsx";
import { FormTextField } from "src/components/common/forms/fields/FormTextField.tsx";
import { FormDateField } from "src/components/common/forms/fields/FormDateField.tsx";
import { FormCheckbox } from "src/components/common/forms/fields/FormCheckbox.tsx";
import { FormAsyncUserSelectField } from "src/components/views/users/FormAsyncUserSelectField.tsx";
import { getCurrencyLabels } from "src/api/generated/erp/db/types/enums/currency.ts";
import { FormSection } from "src/components/common/forms/FormSection.tsx";
import { concatWithPipe } from "src/utils/strings.tsx";

export interface PurchaseOrderFormProps extends FormCommonProps<number> {
	purchaseOrderId: number | undefined;
}

interface FormValues extends PurchaseOrder {
	updatePlannedArrivalDateToLines: boolean;
}

export const PurchaseOrderForm = ({ purchaseOrderId, onFormEdited, onCompleted }: PurchaseOrderFormProps) => {
	const { defaultSiteId, appUserId } = useGlobalInitData();

	return (
		<AsyncForm
			fetch={() => PurchaseOrderEditApi.getFormInitData({ purchaseOrderId })}
			getDefaultValues={getDefaultValues}
			render={(params) => <FormContent {...params} />}
			submit={submitForm}
			onFormEdited={onFormEdited}
			onCompleted={onCompleted}
			columns={3}
			getUseFormProps={({ purchaseOrder }) => ({
				disabled: purchaseOrder?.purchaseOrderState === "RECEIVED",
			})}
		/>
	);

	function getDefaultValues({
		purchaseOrder,
		siteOptions,
		deliveryMethodOptions,
		deliveryTermOptions,
		paymentTermOptions,
	}: PurchaseOrderEditApi_FormInitData): DeepPartial<FormValues> {
		const defaultSite = siteOptions.find((site) => site.siteId === defaultSiteId);
		if (purchaseOrder == null) {
			return {
				siteId: defaultSiteId,
				purchaseOrderType: "TO_SITE",
				plannedArrivalDate: currentLocalDateAsIsoString(),
				responsiblePersonId: appUserId,
				currency: "EUR",
				deliveryMethodId: deliveryMethodOptions[0]?.deliveryMethodId,
				deliveryTermsId: deliveryTermOptions[0]?.deliveryTermsId,
				paymentTermId: paymentTermOptions[0]?.paymentTermId,
				deliveryName: defaultSite?.description,
				deliveryAddress_1: defaultSite?.address_1,
				deliveryAddress_2: defaultSite?.address_2,
				deliveryPostalCode: defaultSite?.postalCode,
				deliveryCity: defaultSite?.city,
				updatePlannedArrivalDateToLines: false,
			};
		}
		return {
			...purchaseOrder,
			updatePlannedArrivalDateToLines: true,
		};
	}

	async function submitForm({ updatePlannedArrivalDateToLines, ...purchaseOrder }: FormValues) {
		if (purchaseOrderId != null) {
			await PurchaseOrderEditApi.update({
				purchaseOrder: purchaseOrder,
				updatePlannedArrivalDateToLines: updatePlannedArrivalDateToLines,
			});
			return purchaseOrderId;
		} else {
			return PurchaseOrderEditApi.insert({ purchaseOrder });
		}
	}
};

interface FormContentProps extends AsyncFormContentParams<PurchaseOrderEditApi_FormInitData, FormValues> {}

const FormContent = ({
	control,
	watch,
	setValue,
	formState: { dirtyFields },
	data: { purchaseOrder, siteOptions, deliveryMethodOptions, deliveryTermOptions, paymentTermOptions },
}: FormContentProps) => {
	const isExistingRecord = purchaseOrder !== null;
	const deliveryAddressEditDisabled = watch("purchaseOrderType") === "TO_SITE";

	return (
		<>
			<FormSelectField
				control={control}
				name={"siteId"}
				label={i18n.t("site")}
				options={siteOptions}
				getOptionKey={(site) => site.siteId}
				getOptionLabel={(site) => site.siteName}
				rules={requireRule()}
				disabled={isExistingRecord}
				disableClearable
				onChange={(siteId) => resetDeliveryAddress({ siteId })}
			/>
			<FormAsyncSelectField
				control={control}
				name={"supplierId"}
				label={i18n.t("supplier")}
				getOptionKey={(option: Supplier) => option.supplierId}
				getOptionLabel={(option) => option.supplierName}
				fetchOptions={({ currentSelection, searchQuery }) =>
					SupplierApi.getSupplierOptions({
						currentSelectionId: currentSelection,
						searchQuery,
					})
				}
				rules={requireRule()}
				disabled={isExistingRecord}
				onChange={onSupplierChanged}
			/>
			<FormEnumSelectField
				control={control}
				name={"purchaseOrderType"}
				label={i18n.t("type")}
				options={getPurchaseOrderTypeLabels()}
				disabled={isExistingRecord}
				rules={requireRule()}
				onChange={(purchaseOrderType) => resetDeliveryAddress({ purchaseOrderType })}
			/>
			<FormTextField control={control} name={"orderReference"} label={i18n.t("order_reference")} />
			<FormDateField
				control={control}
				name={"plannedArrivalDate"}
				label={i18n.t("planned_arrival_date")}
				rules={requireRule()}
			/>
			{dirtyFields.plannedArrivalDate && (
				<FormCheckbox
					control={control}
					name={"updatePlannedArrivalDateToLines"}
					label={i18n.t("update_planned_arrival_date_to_lines")}
				/>
			)}
			<FormAsyncUserSelectField
				control={control}
				name={"responsiblePersonId"}
				label={i18n.t("responsible_person")}
				rules={requireRule()}
				startNewGridRow
			/>
			<FormSelectField
				control={control}
				name={"paymentTermId"}
				label={i18n.t("payment_term")}
				options={paymentTermOptions}
				getOptionKey={(option) => option.paymentTermId}
				getOptionLabel={(option) => concatWithPipe(option.paymentTerm, option.paymentTermDesc)}
				rules={requireRule()}
				disableClearable
			/>
			<FormEnumSelectField
				control={control}
				name={"currency"}
				label={i18n.t("currency")}
				options={getCurrencyLabels()}
				rules={requireRule()}
				disableClearable
			/>
			<FormSelectField
				control={control}
				name={"deliveryMethodId"}
				label={i18n.t("delivery_method")}
				options={deliveryMethodOptions}
				getOptionKey={(option) => option.deliveryMethodId}
				getOptionLabel={(option) =>
					concatWithPipe(option.deliveryMethodCode, option.deliveryMethodDescription)
				}
				rules={requireRule()}
				disableClearable
				startNewGridRow
			/>
			<FormSelectField
				control={control}
				name={"deliveryTermsId"}
				label={i18n.t("delivery_terms")}
				options={deliveryTermOptions}
				getOptionKey={(option) => option.deliveryTermsId}
				getOptionLabel={(option) =>
					concatWithPipe(option.deliveryTermsCode, option.deliveryTermsDescription)
				}
				rules={requireRule()}
				disableClearable
			/>
			<FormTextField
				control={control}
				name={"deliveryTermsDestination"}
				label={i18n.t("delivery_terms_destination")}
			/>
			<FormTextField
				control={control}
				name={"ourContact"}
				label={i18n.t("our_contact")}
				multiline
				spanGridColumns
			/>
			<FormTextField control={control} name={"note"} label={i18n.t("note")} multiline spanGridColumns />
			<FormSection label={i18n.t("supplier")}>
				<FormTextField control={control} name={"supplierContact"} label={i18n.t("contact")} />
				<FormTextField control={control} name={"supplierContactPhone"} label={i18n.t("phone")} />
				<FormTextField control={control} name={"supplierContactEmail"} label={i18n.t("email")} />
			</FormSection>
			<FormSection label={i18n.t("delivery_address")}>
				<FormTextField
					control={control}
					name={"deliveryName"}
					label={i18n.t("description")}
					disabled={deliveryAddressEditDisabled}
				/>
				<FormTextField
					control={control}
					name={"deliveryAddress_1"}
					label={i18n.t("address_1")}
					rules={requireRule()}
					disabled={deliveryAddressEditDisabled}
				/>
				<FormTextField
					control={control}
					name={"deliveryAddress_2"}
					label={i18n.t("address_2")}
					disabled={deliveryAddressEditDisabled}
				/>
				<FormTextField
					control={control}
					name={"deliveryPostalCode"}
					label={i18n.t("postal_code")}
					rules={requireRule()}
					disabled={deliveryAddressEditDisabled}
				/>
				<FormTextField
					control={control}
					name={"deliveryCity"}
					label={i18n.t("city")}
					rules={requireRule()}
					disabled={deliveryAddressEditDisabled}
				/>
			</FormSection>
		</>
	);

	function onSupplierChanged(supplier: Supplier | null) {
		setValue("supplierContact", supplier?.contact);
		setValue("supplierContactPhone", supplier?.phone);
		setValue("supplierContactEmail", supplier?.email);
		setValue("paymentTermId", supplier?.paymentTermId);
		setValue("deliveryMethodId", supplier?.deliveryMethodId);
		setValue("deliveryTermsId", supplier?.deliveryTermsId);
	}

	function resetDeliveryAddress({
		siteId = watch("siteId"),
		purchaseOrderType = watch("purchaseOrderType"),
	}: {
		siteId?: number;
		purchaseOrderType?: PurchaseOrderType;
	}) {
		const site = siteOptions.find((site) => site.siteId === siteId);
		switch (purchaseOrderType) {
			case "TO_SITE":
				setValue("deliveryName", site?.description);
				setValue("deliveryAddress_1", site?.address_1);
				setValue("deliveryAddress_2", site?.address_2);
				setValue("deliveryPostalCode", site?.postalCode);
				setValue("deliveryCity", site?.city);
				break;
			case "DIRECT":
				setValue("deliveryName", "");
				setValue("deliveryAddress_1", "");
				setValue("deliveryAddress_2", "");
				setValue("deliveryPostalCode", "");
				setValue("deliveryCity", "");
				break;
		}
	}
};
