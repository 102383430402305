// This file is automatically generated. Do not edit manually.

import { t } from "i18next";

export const InvoiceTypeValues = ["CHARGE", "REFUND"] as const;

export type InvoiceType = (typeof InvoiceTypeValues)[number];

export const getInvoiceTypeLabels = () => ({
	CHARGE: t("charge"),
	REFUND: t("refund"),
});

export const getInvoiceTypeLabel = (value: InvoiceType): string => {
	return getInvoiceTypeLabels()[value];
};
