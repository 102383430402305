import type { Extension } from "@codemirror/state";
import { autocompletion, CompletionContext, CompletionResult } from "@codemirror/autocomplete";
import { Diagnostic, linter } from "@codemirror/lint";
import { EditorView } from "codemirror";
import i18n from "i18next";
import { ConfiguratorLuaEditorApi } from "src/api/generated/erp/configurator/api/configuratorLuaEditorApi.ts";
import { useAsyncFetch } from "src/utils/async/asyncFetch.ts";

interface UseConfiguratorLuaEditorExtensionsParams {
	productFamilyVersionId: number | null | undefined;
	catalogPartRevisionId: number | null | undefined;
}

export function useConfiguratorLuaEditorExtensions({
	productFamilyVersionId,
	catalogPartRevisionId,
}: UseConfiguratorLuaEditorExtensionsParams): Extension[] {
	const [lintingDataAsync] = useAsyncFetch(async () =>
		productFamilyVersionId == null ? undefined : (
			await ConfiguratorLuaEditorApi.getLintingData({
				productFamilyVersionId,
				catalogPartRevisionId,
			})
		),
	);

	return [
		autocompletion({
			override: [
				getGlobalCompletions,
				getSelfPropertyNameCompletions,
				getParentPropertyNameCompletions,
			],
		}),
		linter(propertyNameLinterSource),
	];

	function getGlobalCompletions(context: CompletionContext): CompletionResult | null {
		// Match if the cursor is at the beginning of a word
		const word = context.matchBefore(/(?<![\w.])\w*/);

		if (word == null) return null;
		if (word.from === word.to && !context.explicit) return null;

		return {
			from: word.from,
			options: [
				{ label: "local", type: "keyword" },
				{ label: "properties", type: "variable" },
				{ label: "parent.properties", type: "variable" },
			],
		};
	}

	function getSelfPropertyNameCompletions(context: CompletionContext): CompletionResult | null {
		if (lintingDataAsync.data == undefined) return null;
		const configuratorProperties = lintingDataAsync.data.properties;

		const propertyMatch = getPropertyMatchBefore(context);
		if (propertyMatch == null || propertyMatch.isParentProp) return null;

		return {
			from: propertyMatch.propNameFrom,
			options: configuratorProperties.map((property) => ({
				label: property.propertyName,
				type: "variable",
				apply: `${property.propertyName}.value`,
			})),
			validFor: (text) => {
				return text.match(/\w*/) != null;
			},
		};
	}

	function getParentPropertyNameCompletions(context: CompletionContext): CompletionResult | null {
		if (lintingDataAsync.data == undefined) return null;
		const parentProperties = lintingDataAsync.data.parentProperties;

		const propertyMatch = getPropertyMatchBefore(context);
		if (propertyMatch == null || !propertyMatch.isParentProp) return null;

		return {
			from: propertyMatch.propNameFrom,
			options: parentProperties.map((property) => ({
				label: property.propertyName,
				info: `${i18n.t("from_product_family")} ${property.parentProductFamilyName}`,
				type: "variable",
				apply: `${property.propertyName}.value`,
			})),
			validFor: (text) => {
				return text.match(/\w*/) != null;
			},
		};
	}

	function getPropertyMatchBefore(context: CompletionContext): PropertyNameMatch | null {
		const regex = /(\bparent\.)?\bproperties\.(\w*)/;
		const match1 = context.matchBefore(regex);
		if (match1 == null) return null;
		const match2 = match1.text.match(regex); // matchBefore doesn't return the groups, so we have to match again
		if (match2 == null) return null;
		const propName = match2[2];
		if (propName == undefined) return null;

		const isParentProp = match2[1] != undefined;

		// Calculate prefix length. Prefix may be "properties." or "parent.properties."
		const prefixLength =
			propName.length === 0 ? match1.text.length : match1.text.slice(0, -propName.length).length;

		return {
			propName,
			isParentProp: isParentProp,
			propNameFrom: match1.from + prefixLength,
		};
	}

	function propertyNameLinterSource(view: EditorView): Diagnostic[] {
		if (lintingDataAsync.data == undefined) return [];
		const selfPropertyNames = new Set(
			lintingDataAsync.data.properties.map((property) => property.propertyName),
		);
		const parentPropertyNames = new Set(
			lintingDataAsync.data.parentProperties.map((property) => property.propertyName),
		);

		const content = view.state.doc.toString();
		// Any word that follows "properties." or "parent.properties."
		const propertyRegex = /(\bparent\.)?\bproperties\.(\w*)/g;
		const attemptedProperties = [...content.matchAll(propertyRegex)];

		return attemptedProperties.reduce<Diagnostic[]>((acc, attemptedProp) => {
			const propName = attemptedProp[2]!;
			const isParentProp = attemptedProp[1] != undefined;

			if (!isParentProp && selfPropertyNames.has(propName)) return acc;
			if (isParentProp && parentPropertyNames.has(propName)) return acc;

			return [
				...acc,
				{
					from: attemptedProp.index,
					to: attemptedProp.index + attemptedProp[0].length,
					severity: "error",
					message: i18n.t("property_not_found", { name: propName }),
				},
			];
		}, []);
	}
}

interface PropertyNameMatch {
	propName: string;
	isParentProp: boolean;
	propNameFrom: number;
}
