import HighchartsGantt from "highcharts/highcharts-gantt";
import dayjs from "dayjs";
import { TaskView } from "src/api/generated/postgres/db/types/tasks/tables/taskView.ts";
import { Theme } from "@mui/material/styles";
import { useTheme } from "@mui/material";
import { getTaskStateLabel } from "src/api/generated/io/aavo/applications/db/postgres/enums/taskState.ts";
import {
	dayJsToDateIsoString,
	dayJsToDateIsoStringNullable,
	formatDayJs,
	formatIsoString, parseDateStringToDayJsUtc
} from "src/utils/dayjsUtils.ts";
import { AavoContextMenu } from "src/components/common/contextMenu/AavoContextMenu.tsx";
import {
	OpenTaskContextMenuFunc,
	useTaskContextMenu,
} from "src/components/views/tasks/desktop/TaskContextMenu.tsx";
import { OpenGenericDialogFunc } from "src/components/common/dialogs/GenericDialogProvider.tsx";
import { useGenericDialog } from "src/components/common/dialogs/useGenericDialog.ts";
import { TaskDesktopForm } from "src/components/views/tasks/desktop/TaskDesktopForm.tsx";
import { useConfirmDialog } from "src/components/common/dialogs/confirmDialog/useConfirmDialog.ts";
import { ShowConfirmDialogFunc } from "src/components/common/dialogs/confirmDialog/ConfirmDialogProvider.tsx";
import i18n from "i18next";
import { TaskUpdateApi } from "src/api/generated/tasks/api/taskUpdateApi.ts";
import { useErrorDialog } from "src/components/common/dialogs/errorDialog/userErrorDialog.ts";
import { useMemo } from "react";
import { AavoGanttView } from "src/components/common/gantt/AavoGanttView.tsx";
import { getTaskColor } from "src/components/views/tasks/taskUtils.ts";

export interface TasksGanttViewProps {
	tasks: TaskView[];
	reload: () => Promise<unknown>;
	renderTaskLabel?: (task: TaskView) => string;
	renderTaskCategory?: (task: TaskView) => string;
}

export const TasksGanttView = (props: TasksGanttViewProps) => {
	const { tasks, reload } = props;
	const theme = useTheme();
	const { openTaskContextMenu, taskContextMenuState } = useTaskContextMenu({
		refreshData: reload,
	});
	const { openDialog } = useGenericDialog();
	const confirm = useConfirmDialog();
	const { logErrorAndShowOnDialog } = useErrorDialog();

	// Options must be memoized to prevent dragged tasks from being reset during the confirmation.
	// Most deps are functions that are not memoized, but they should not change during the lifetime of the component.
	const options = useMemo(
		() =>
			getChartOptions(props, theme, openTaskContextMenu, openDialog, confirm, logErrorAndShowOnDialog),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[tasks, theme, reload],
	);

	return (
		<>
			<AavoGanttView options={options} />
			<AavoContextMenu state={taskContextMenuState} />
		</>
	);
};

const getChartOptions = (
	props: TasksGanttViewProps,
	theme: Theme,
	openContextMenu: OpenTaskContextMenuFunc,
	openDialog: OpenGenericDialogFunc,
	confirm: ShowConfirmDialogFunc,
	handleError: (e: unknown) => void,
): HighchartsGantt.Options => {
	const {
		tasks,
		reload,
		renderTaskLabel = (task) => task.title,
		renderTaskCategory = (task) => task.title,
	} = props;

	const getTaskById = (taskId: number) => tasks.find((task) => task.taskId === taskId);

	return {
		chart: {
			scrollablePlotArea: {
				opacity: 0.5,
				minHeight: tasks.length * Y_AXIS_STATIC_SCALE + 300,
			},
		},
		title: {
			text: undefined,
		},
		yAxis: {
			title: {
				text: null,
			},
			categories: tasks.map((task) => renderTaskCategory(task)),
			staticScale: Y_AXIS_STATIC_SCALE,
			showEmpty: false,
			visible: false,
		},
		navigator: {
			enabled: true,
		},
		rangeSelector: {
			enabled: true,
		},
		legend: {
			enabled: false,
		},
		series: [
			{
				type: "gantt",
				data: tasks.map((task, idx) => ({
					id: task.taskId.toString(),
					name: renderTaskLabel(task),
					y: idx,
					start: (task.earliestStartDate ?
						parseDateStringToDayJsUtc(task.earliestStartDate)
					:	parseDateStringToDayJsUtc(task.deadlineDate)
					)?.valueOf(),
					end: parseDateStringToDayJsUtc(task.deadlineDate).add(1, "day").valueOf(),
					color: getTaskColor(task, theme),
					tooltip: getTooltip(task),
					pointWidth: Y_AXIS_STATIC_SCALE - 10,
				})),
			},
		],
		plotOptions: {
			gantt: {
				dragDrop: {
					draggableX: true,
					draggableY: false,
					dragPrecisionX: dayjs.duration(1, "day").asMilliseconds(),
					liveRedraw: true,
				},
				dataLabels: {
					enabled: true,
					format: "{point.name}",
				},
				tooltip: {
					headerFormat: "",
					pointFormat: "{point.tooltip}",
				},
				point: {
					events: {
						contextmenu: (event) => {
							const taskId = Number(event.point.options.id);
							const task = getTaskById(taskId);
							if (task == null) return;
							openContextMenu(event, [task]);
						},
						dblclick: (event) => {
							const taskId = Number(event.point.options.id);
							const task = getTaskById(taskId);
							if (task == null) return;
							openDialog(({ closeDialog, onContentEdited }) => ({
								title: task.title,
								size: "lg",
								content: (
									<TaskDesktopForm
										taskId={task.taskId}
										newTaskSourceRef={undefined}
										onCompleted={async () => {
											await closeDialog();
											await reload();
										}}
										onFormEdited={onContentEdited}
									/>
								),
							}));
						},
						drop: async (point: any) => {
							const taskId = Number(point.target.id);
							const task = getTaskById(taskId);
							if (!task) return false;

							const newDeadlineDate = dayjs(point.target.end).subtract(1, "day");
							const newEarliestStartDate =
								task.earliestStartDate != null ? dayjs(point.target.start) : null;

							const confirmed = await confirm({
								message: i18n.t("confirm_reschedule_task", {
									newDeadlineDate: formatDayJs(newDeadlineDate),
									newEarliestStartDate: formatDayJs(newEarliestStartDate),
								}),
							});
							if (!confirmed) {
								await reload();
								return false;
							}

							try {
								await TaskUpdateApi.scheduleTasks({
									taskIds: [taskId],
									earliestStartDate: dayJsToDateIsoStringNullable(newEarliestStartDate),
									deadlineDate: dayJsToDateIsoString(newDeadlineDate),
								});
							} catch (e) {
								handleError(e);
							} finally {
								await reload();
							}
							return true;
						},
					},
				},
			},
		},
	};
};

const getTooltip = (task: TaskView) => {
	return `
		<b>Tehtävä:</b> ${task.title}<br/>
		<b>Lähde:</b> ${task.sourceDescription}<br/>
		<b>Tila:</b> ${getTaskStateLabel(task.taskState)}<br/>
		<b>Tekijä:</b> ${task.assignedToUserName}<br/>
		<b>Valmiina viimeistään:</b> ${formatIsoString(task.deadlineDate)}<br/>
		<b>Aloitus aikaisintaan:</b> ${formatIsoString(task.earliestStartDate)}
	`;
};

const Y_AXIS_STATIC_SCALE = 35;
