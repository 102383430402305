import { ClientSideDataGridModel } from "src/components/common/dataGrid/gridModel/ClientSideDataGridModel.tsx";
import { ControlChartSelectionApi } from "src/api/generated/spc/controlChart/api/controlChartSelectionApi.ts";
import { CrudDataGrid } from "src/components/common/dataGrid/crud/CrudDataGrid.tsx";
import { ItemControlChartView } from "src/api/generated/postgres/db/types/ext_resourcing/tables/itemControlChartView.ts";
import { textColumn } from "src/components/common/dataGrid/columns.tsx";
import i18n from "i18next";
import { ResourceItemChartsApi } from "src/api/generated/spc/resources/api/resourceItemChartsApi.ts";

export interface ChartConnectedResourceItemsDataGridProps {
	controlChartId: number;
}

export const ChartConnectedResourceItemsDataGrid = ({
	controlChartId,
}: ChartConnectedResourceItemsDataGridProps) => {
	return (
		<ClientSideDataGridModel
			gridId={"EB5B61E06CA9764A"}
			fetchData={() =>
				ControlChartSelectionApi.getControlChartConnectedItems({ controlChartId: controlChartId })
			}
			initialParams={{}}
			getRows={(data) => data}
			getRowId={(row) => row.itemId}
			render={({ dataGridProps }) => (
				<CrudDataGrid<ItemControlChartView>
					columns={[
						textColumn({
							field: "itemName",
							headerName: i18n.t("name"),
							width: 150,
						}),
						textColumn({
							field: "itemDescription",
							headerName: i18n.t("description"),
							width: 250,
						}),
					]}
					remove={{
						type: "enabled",
						action: async ({ items }) => {
							if (items[0] == null) return;
							await ResourceItemChartsApi.disconnectChartFromItem({
								itemId: items[0].itemId,
								chartId: controlChartId,
							});
						},
					}}
					disableMultipleRowSelection={true}
					{...dataGridProps}
				/>
			)}
		/>
	);
};
