// This file is automatically generated. Do not edit manually.

import { ServerSideDataModelRequest } from "src/api/generated/common/dataGrids/serverSideDataModelRequest";
import { ServerSideDataModelResult } from "src/api/generated/common/dataGrids/serverSideDataModelResult";
import { CustomerView } from "src/api/generated/erp/db/types/tables/customerView";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace CustomerDataGridApi {
	export async function getCustomersGridData(args: {
		onlyCustomerId?: number | null | undefined;
		searchQuery?: string;
		includeDeactivated?: boolean;
		dataModelRequest: ServerSideDataModelRequest;
	}): Promise<ServerSideDataModelResult<CustomerView>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/basedata/customer/getCustomersGridData",
			method: "POST",
			data: {
				onlyCustomerId: args.onlyCustomerId,
				searchQuery: args.searchQuery,
				includeDeactivated: args.includeDeactivated,
				dataModelRequest: args.dataModelRequest,
			},
		});
		return response as ServerSideDataModelResult<CustomerView>;
	}
}
