// This file is automatically generated. Do not edit manually.

import { ServerSideDataModelRequest } from "src/api/generated/common/dataGrids/serverSideDataModelRequest";
import { ServerSideDataModelResult } from "src/api/generated/common/dataGrids/serverSideDataModelResult";
import { CapacityDay } from "src/api/generated/erp/db/types/tables/capacityDay";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace ProductionLineCapacityDataGridApi {
	export async function getDataGridData(args: {
		productionLineId: number;
		dataModelRequest: ServerSideDataModelRequest;
	}): Promise<ServerSideDataModelResult<CapacityDay>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/productionLines/capacity/dataGrid/getDataGridData",
			method: "POST",
			data: {
				productionLineId: args.productionLineId,
				dataModelRequest: args.dataModelRequest,
			},
		});
		return response as ServerSideDataModelResult<CapacityDay>;
	}
}
