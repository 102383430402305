// This file is automatically generated. Do not edit manually.

import { DeliveryPackageLineView } from "src/api/generated/erp/db/types/tables/deliveryPackageLineView";
import { FrontendDownloadFileHandle } from "src/api/generated/common/frontendDownloadFileHandle";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace DeliveryPackageApi {
	export async function getPackageLines(args: {
		deliveryPackageId: number;
	}): Promise<Array<DeliveryPackageLineView>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/delivery/deliveryPackage/getPackageLines",
			method: "POST",
			data: {
				deliveryPackageId: args.deliveryPackageId,
			},
		});
		return response as Array<DeliveryPackageLineView>;
	}

	export async function setPackageStateToPicked(args: { deliveryPackageId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/delivery/deliveryPackage/setPackageStateToPicked",
			method: "POST",
			data: {
				deliveryPackageId: args.deliveryPackageId,
			},
		});
		return response as void;
	}

	export async function setPackageStateToUnpicked(args: { deliveryPackageId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/delivery/deliveryPackage/setPackageStateToUnpicked",
			method: "POST",
			data: {
				deliveryPackageId: args.deliveryPackageId,
			},
		});
		return response as void;
	}

	export async function printDeliveryPackageLabel(args: {
		deliveryPackageId: number;
	}): Promise<FrontendDownloadFileHandle> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/delivery/deliveryPackage/printDeliveryPackageLabel",
			method: "POST",
			data: {
				deliveryPackageId: args.deliveryPackageId,
			},
		});
		return response as FrontendDownloadFileHandle;
	}

	export async function calculateDeliveryPackageWeight(args: { deliveryPackageId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/delivery/deliveryPackage/calculateDeliveryPackageWeight",
			method: "POST",
			data: {
				deliveryPackageId: args.deliveryPackageId,
			},
		});
		return response as void;
	}

	export async function movePackagesToDelivery(args: {
		deliveryId: number;
		deliveryPackageIds: Array<number>;
	}): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/delivery/deliveryPackage/movePackagesToDelivery",
			method: "POST",
			data: {
				deliveryId: args.deliveryId,
				deliveryPackageIds: args.deliveryPackageIds,
			},
		});
		return response as void;
	}

	export async function deletePackageFromDelivery(args: { deliveryPackageIds: Array<number> }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/delivery/deliveryPackage/deletePackageFromDelivery",
			method: "POST",
			data: {
				deliveryPackageIds: args.deliveryPackageIds,
			},
		});
		return response as void;
	}
}
