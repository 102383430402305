// This file is automatically generated. Do not edit manually.

import { Project } from "src/api/generated/erp/db/types/tables/project";
import { CostEventPriceList } from "src/api/generated/erp/db/types/tables/costEventPriceList";
import { ProjectView } from "src/api/generated/erp/db/types/tables/projectView";
import { ProjectGroup } from "src/api/generated/erp/db/types/tables/projectGroup";
import { ProjectType } from "src/api/generated/erp/db/types/tables/projectType";
import { Site } from "src/api/generated/erp/db/types/tables/site";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace ProjectEditApi {
	export async function getFormInitData(args: {
		projectId: number | null | undefined;
	}): Promise<ProjectEditApi_FormInitData> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/project/edit/getFormInitData",
			method: "POST",
			data: {
				projectId: args.projectId,
			},
		});
		return response as ProjectEditApi_FormInitData;
	}

	export async function insert(args: { project: Project }): Promise<number> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/project/edit/insert",
			method: "POST",
			data: {
				project: args.project,
			},
		});
		return response as number;
	}

	export async function update(args: { project: Project; inheritScheduleChange: boolean }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/project/edit/update",
			method: "POST",
			data: {
				project: args.project,
				inheritScheduleChange: args.inheritScheduleChange,
			},
		});
		return response as void;
	}

	export async function setProjectNotes(args: { projectId: number; notes: string }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/project/edit/setProjectNotes",
			method: "POST",
			data: {
				projectId: args.projectId,
				notes: args.notes,
			},
		});
		return response as void;
	}
}

export interface ProjectEditApi_FormInitData {
	costEventPriceListOptions: Array<CostEventPriceList>;
	project: ProjectView | null | undefined;
	projectGroupOptions: Array<ProjectGroup>;
	projectTypeOptions: Array<ProjectType>;
	siteOptions: Array<Site>;
}
