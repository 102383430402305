import i18n from "i18next";
import { AsyncButton } from "src/components/common/buttons/AsyncButton.tsx";
import { Container } from "@mui/material";
import { DocumentRevisionApi } from "src/api/generated/documents/api/documentRevisionApi.ts";
import { uploadFile } from "src/utils/fileUploading.ts";
import { DocumentUpdateApi } from "src/api/generated/documents/api/documentUpdateApi.ts";

export interface DropFileToDocumentViewProps {
	file: File;
	documentId: number;
	afterSubmit?: () => Promise<unknown>;
}

export const DropFileToDocumentView = (
	{
		file,
		documentId,
		afterSubmit
	}: DropFileToDocumentViewProps) => {

	return <Container
		sx={{
			display: "flex",
			flexDirection: "column",
			gap: "1rem",
			padding: "1rem",
			alignItems: "center"
		}}
	>
		<AsyncButton
			variant={"contained"}
			label={i18n.t("replace_current_revision_file")}
			onClick={async () => {
				const fileHandle = await uploadFile(file);
				await DocumentUpdateApi.updateDocumentActiveRevisionFile({
					documentId,
					newFile: fileHandle
				});
				afterSubmit?.();
			}}
		/>
		<AsyncButton
			variant={"contained"}
			label={i18n.t("create_a_new_revision")}
			onClick={async () => {
				const fileHandle = await uploadFile(file);
				await DocumentRevisionApi.createDocumentRevision({
					documentId: documentId,
					file: fileHandle
				});
				afterSubmit?.();
			}}
		/>
	</Container>;
};