import {ProjectActivityType} from "src/api/generated/erp/db/types/tables/projectActivityType.ts";
import {ProjectActivityTypeApi} from "src/api/generated/erp/project/projectActivity/api/projectActivityTypeApi.ts";
import {FormCommonProps} from "src/components/common/forms/types.ts";
import {AavoForm} from "src/components/common/forms/AavoForm.tsx";
import {requireRule} from "src/components/common/forms/validation.ts";
import {FormTextField} from "src/components/common/forms/fields/FormTextField.tsx";
import i18n from "i18next";

export interface ProjectActivityTypeFormProps extends FormCommonProps<number> {
	projectActivityType: ProjectActivityType | undefined;
}

interface FormValues extends ProjectActivityType {}

export const ProjectActivityTypeForm = (props: ProjectActivityTypeFormProps) => {
	const { projectActivityType, onCompleted, onFormEdited } = props;
	return (
		<AavoForm
			defaultValues={projectActivityType ?? {}}
			submit={submit}
			onCompleted={onCompleted}
			onFormEdited={onFormEdited}
			render={({ control }) => (
				<>
					<FormTextField
						control={control}
						name={"activityTypeName"}
						label={i18n.t("activity_type")}
						rules={requireRule()}
					/>
				</>
			)}
		/>
	);

	async function submit(values: FormValues) {
		if (projectActivityType == null) {
			return await ProjectActivityTypeApi.insert({ activityType: values });
		} else {
			await ProjectActivityTypeApi.update({ activityType: values });
			return projectActivityType.activityTypeId;
		}
	}
};
