// This file is automatically generated. Do not edit manually.

import { SubProject } from "src/api/generated/erp/db/types/tables/subProject";
import { ProjectView } from "src/api/generated/erp/db/types/tables/projectView";
import { SubProjectType } from "src/api/generated/erp/db/types/tables/subProjectType";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace SubProjectEditApi {
	export async function getFormInitData(args: {
		projectId: number;
		subProjectId: number | null | undefined;
	}): Promise<SubProjectEditApi_FormInitData> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/project/subProject/getFormInitData",
			method: "POST",
			data: {
				projectId: args.projectId,
				subProjectId: args.subProjectId,
			},
		});
		return response as SubProjectEditApi_FormInitData;
	}

	export async function insert(args: { subProject: SubProject }): Promise<number> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/project/subProject/insert",
			method: "POST",
			data: {
				subProject: args.subProject,
			},
		});
		return response as number;
	}

	export async function update(args: { subProject: SubProject; inheritScheduleChange: boolean }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/project/subProject/update",
			method: "POST",
			data: {
				subProject: args.subProject,
				inheritScheduleChange: args.inheritScheduleChange,
			},
		});
		return response as void;
	}
}

export interface SubProjectEditApi_FormInitData {
	project: ProjectView;
	projectOtherSubProjects: Array<SubProject>;
	subProject: SubProject | null | undefined;
	subProjectTypeOptions: Array<SubProjectType>;
}
