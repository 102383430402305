import { FormCommonProps } from "src/components/common/forms/types";
import { getSystemDiagramNodeShapeLabels } from "src/api/generated/postgres/db/types/public_/enums/systemDiagramNodeShape";
import i18n from "i18next";
import {
	SystemDiagramNodeCrudApi,
	SystemDiagramNodeCrudApi_FormInitialData,
} from "src/api/generated/systemDiagrams/management/systemDiagramNodeCrudApi";
import { FormTextField } from "src/components/common/forms/fields/FormTextField";
import { FormSelectField } from "src/components/common/forms/fields/FormSelectField";
import { SystemDiagramFunctionParamsForm } from "src/components/views/erp/common/systemDiagrams/functionParamForms/SystemDiagramFunctionParamsForm";
import { requireRule } from "src/components/common/forms/validation";
import { FormEnumSelectField } from "../../../../common/forms/fields/FormEnumSelectField.tsx";
import { AsyncForm } from "src/components/common/forms/AsyncForm.tsx";
import { SystemDiagramNode } from "src/api/generated/postgres/db/types/public_/tables/systemDiagramNode.ts";

export interface SystemDiagramNodeFormProps extends FormCommonProps<number> {
	systemDiagramId: number;
	systemDiagramNodeId: number | undefined;
}

export const SystemDiagramNodeForm = ({
	systemDiagramId,
	systemDiagramNodeId,
	onFormEdited,
	onCompleted,
}: SystemDiagramNodeFormProps) => {
	return (
		<AsyncForm<SystemDiagramNodeCrudApi_FormInitialData, SystemDiagramNode, number>
			fetch={() =>
				SystemDiagramNodeCrudApi.getFormInitialData({
					systemDiagramId,
					systemDiagramNodeId,
				})
			}
			getDefaultValues={({ systemDiagramNode }) =>
				systemDiagramNode ?? {
					name: "",
					shape: "RECTANGULAR",
					systemDiagramId: systemDiagramId,
				}
			}
			onCompleted={onCompleted}
			onFormEdited={onFormEdited}
			submit={async (systemDiagramNode) => {
				const result = await SystemDiagramNodeCrudApi.save({
					systemDiagramNode,
				});
				return result.systemDiagramNodeId;
			}}
			render={({ control, data: initData, watch }) => {
				const functionId = watch("functionId");
				return (
					<>
						<FormTextField
							control={control}
							name={"name"}
							label={i18n.t("name")}
							rules={requireRule()}
							autoFocus
						/>
						<FormEnumSelectField
							control={control}
							name={"shape"}
							label={i18n.t("shape")}
							options={getSystemDiagramNodeShapeLabels()}
							rules={requireRule()}
							disableClearable
						/>
						<FormSelectField
							control={control}
							name={"functionId"}
							label={i18n.t("function")}
							options={initData.functionOptions}
							getOptionKey={(f) => f.id}
							getOptionLabel={(f) => f.label}
							rules={requireRule()}
							disableClearable
						/>
						<SystemDiagramFunctionParamsForm
							control={control}
							nodeFunction={initData.functionOptions.find((f) => f.id === functionId)}
							formInitialData={initData}
						/>
					</>
				);
			}}
		/>
	);
};
