import { FormCommonProps } from "src/components/common/forms/types.ts";
import { Control, DeepPartial, useFieldArray } from "react-hook-form";
import { AavoForm, AavoFormContentParams } from "src/components/common/forms/AavoForm.tsx";
import i18n from "i18next";
import { Box, Typography } from "@mui/material";
import { FormSelectField } from "src/components/common/forms/fields/FormSelectField.tsx";
import { Theme } from "@mui/material/styles";
import {
	WarehousePickingObjectReservationFormApi,
	WarehousePickingObjectReservationFormApi_InitData,
	WarehousePickingObjectReservationFormApi_InitData_PartWarehouseLocationDto,
} from "src/api/generated/erp/warehouse/inventory/warehousePickingObjectReservationFormApi.ts";
import { AsyncFetchRender } from "src/components/common/async/AsyncFetchRender.tsx";
import { FormNumberField } from "src/components/common/forms/fields/FormNumberField.tsx";
import { requireRule } from "src/components/common/forms/validation.ts";

export interface WarehousePickingObjectReservationFormBaseProps extends FormCommonProps<void> {
	warehousePickingObjects: WarehousePickingObjectDto[];
	submit: (values: FormValues) => Promise<void>;
}

export interface WarehousePickingObjectDto {
	pickingObjectId: number;
	partId: number;
	objectTotalValueInWarehouseUnit: number;
	objectReservedQuantityInWarehouseUnit: number;
	partDescription: string;
	subTitle: string;
}

interface FormValues {
	pickingObjects: WarehousePickingObjectFormValues[];
}

interface WarehousePickingObjectFormValues extends WarehousePickingObjectDto {
	partWarehouseLocationOptions: WarehousePickingObjectReservationFormApi_InitData_PartWarehouseLocationDto[];
	partWarehouseLocationId: number;
	quantityToReserveInWarehouseUnit: number;
}

export const WarehousePickingObjectReservationFormBase = (
	props: WarehousePickingObjectReservationFormBaseProps,
) => {
	const { warehousePickingObjects } = props;
	return (
		<AsyncFetchRender
			fetch={() =>
				WarehousePickingObjectReservationFormApi.fetchInitData({
					partIds: warehousePickingObjects.map((line) => line.partId),
				})
			}
			content={(initData) => (
				<WarehousePickingObjectReservationFormBaseContent initData={initData} {...props} />
			)}
		/>
	);
};

interface WarehousePickingObjectReservationFormBaseContentProps
	extends WarehousePickingObjectReservationFormBaseProps {
	initData: WarehousePickingObjectReservationFormApi_InitData;
}

const WarehousePickingObjectReservationFormBaseContent = ({
	initData,
	warehousePickingObjects,
	onCompleted,
	submit,
}: WarehousePickingObjectReservationFormBaseContentProps) => {
	return (
		<AavoForm<FormValues, void>
			defaultValues={getDefaultValues()}
			submit={submit}
			onCompleted={onCompleted}
			render={(params) => <FormContent {...params} />}
		/>
	);

	function getDefaultValues(): DeepPartial<FormValues> {
		return {
			pickingObjects: warehousePickingObjects.map((pickingObject) => {
				const part = initData.parts.find((part) => part.partId == pickingObject.partId);
				return {
					...pickingObject,
					partWarehouseLocationOptions: part?.partWarehouseLocations ?? [],
					partWarehouseLocationId: part?.defaultPartWarehouseLocationId ?? undefined,
					quantityToReserveInWarehouseUnit:
						pickingObject.objectTotalValueInWarehouseUnit -
						pickingObject.objectReservedQuantityInWarehouseUnit,
				};
			}),
		};
	}
};

interface FormContentProps extends AavoFormContentParams<FormValues> {}

const FormContent = ({ control }: FormContentProps) => {
	const pickingObjectsFieldArray = useFieldArray({
		control,
		name: "pickingObjects",
	});

	return (
		<>
			{pickingObjectsFieldArray.fields.map((field, index) => (
				<SinglePickingObjectForm
					key={field.id}
					pickingObject={field}
					control={control}
					index={index}
				/>
			))}
		</>
	);
};

interface SinglePickingObjectFormProps {
	pickingObject: WarehousePickingObjectFormValues;
	control: Control<FormValues> | undefined;
	index: number;
}

const SinglePickingObjectForm = ({
	pickingObject: {
		partWarehouseLocationId,
		partWarehouseLocationOptions,
		objectTotalValueInWarehouseUnit,
		objectReservedQuantityInWarehouseUnit,
		subTitle,
		partDescription,
	},
	control,
	index,
}: SinglePickingObjectFormProps) => {
	const currentWarehouseLocation = partWarehouseLocationOptions.find(
		(option) => option.partWarehouseLocationId == partWarehouseLocationId,
	);
	const availableQuantity = calculateAvailableQuantity(currentWarehouseLocation);
	const quantityToReserve = objectTotalValueInWarehouseUnit - objectReservedQuantityInWarehouseUnit;

	return (
		<Box
			sx={{
				borderBottom: "1px solid",
				borderColor: (theme: Theme) => theme.palette.divider,
				display: "flex",
				gap: 1,
				paddingBottom: 1,
				marginBottom: 1,
			}}
		>
			<Box
				key={"partDescription"}
				sx={{
					flex: 1,
				}}
			>
				<Typography key={"headTitle"} variant={"h6"}>
					{partDescription}
				</Typography>
				<Typography key={"subTitle"}> {subTitle}</Typography>
			</Box>
			<Box
				key={"warehouseLocationInformation"}
				sx={{
					minWidth: 200,
					display: "flex",
					gap: 1,
					flexDirection: "column",
				}}
			>
				<FormSelectField
					key={"warehouseLocationId"}
					control={control}
					name={`pickingObjects.${index}.partWarehouseLocationId`}
					label={i18n.t("warehouse_location")}
					options={partWarehouseLocationOptions}
					getOptionKey={(option) => option.partWarehouseLocationId}
					getOptionLabel={(option) => {
						const availableQuantity = calculateAvailableQuantity(option);
						return `${option.partWarehouseLocationCode} ${
							availableQuantity == null ? "" : (
								`(${availableQuantity} ${option.partWarehouseUnit})`
							)
						}`;
					}}
				/>

				<Typography
					key={"inventoryLevelStatus"}
					sx={{
						color:
							availableQuantity !== null && availableQuantity < quantityToReserve ?
								"error.main"
							:	"unset",
					}}
				>
					{getInventoryLevelStatus(currentWarehouseLocation)}
				</Typography>
			</Box>
			<Box
				key={"quantityInformation"}
				sx={{
					display: "flex",
					gap: 1,
					flexDirection: "column",
				}}
			>
				<FormNumberField
					key={"quantityToReserveInWarehouseUnit"}
					control={control}
					name={`pickingObjects.${index}.quantityToReserveInWarehouseUnit`}
					label={i18n.t("quantity")}
					rules={requireRule()}
				/>
				<Typography key={"targetReserveQuantity"}>
					{i18n.t("quantity_to_reserve")}: {quantityToReserve}
				</Typography>
			</Box>
		</Box>
	);
};

const getInventoryLevelStatus = (
	location: WarehousePickingObjectReservationFormApi_InitData_PartWarehouseLocationDto | undefined,
) => {
	if (location?.inventoryLevelEnabled === false) return i18n.t("inventory_level_disabled");

	const availableQuantity = calculateAvailableQuantity(location);
	if (availableQuantity == null) return "-";
	return `${i18n.t("available")}: ${availableQuantity}`;
};

const calculateAvailableQuantity = (
	location: WarehousePickingObjectReservationFormApi_InitData_PartWarehouseLocationDto | undefined | null,
) => {
	if (location?.stockQuantity == null || location?.reservedQuantity == null) return null;
	return location.stockQuantity - location.reservedQuantity;
};
