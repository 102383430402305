// This file is automatically generated. Do not edit manually.

import { ReclamationWithCoordinates } from "src/api/generated/erp/reclamations/api/reclamationWithCoordinates";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace ReclamationPlanningApi {
	export async function getReclamationsWithCoordinates(args: {
		reclamationIds: Array<number>;
	}): Promise<Array<ReclamationWithCoordinates>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/reclamations/getOpenReclamationsWithCoordinates",
			method: "POST",
			data: {
				reclamationIds: args.reclamationIds,
			},
		});
		return response as Array<ReclamationWithCoordinates>;
	}
}
