import { FormCommonProps } from "src/components/common/forms/types.ts";
import {
	ObjectAccountingForm,
	ObjectAccountingFormValues,
} from "src/components/views/erp/sales/accounting/ObjectAccountingForm.tsx";
import {
	CustomerGroupEditApi,
	CustomerGroupEditApi_FormInitData,
} from "src/api/generated/erp/sales/basedata/api/customerGroupEditApi.ts";
import { requireRule } from "src/components/common/forms/validation.ts";
import i18n from "i18next";
import { FormTextField } from "src/components/common/forms/fields/FormTextField.tsx";
import { DeepPartial } from "react-hook-form";
import {
	getObjectAccountingCodeIdsFromFormValues,
	getObjectAccountingFormDefaultValues,
} from "src/components/views/erp/sales/accounting/ObjectAccountingForm.utils.ts";
import { CustomerGroup } from "src/api/generated/erp/db/types/tables/customerGroup.ts";
import { AsyncForm } from "src/components/common/forms/AsyncForm.tsx";

export interface CustomerGroupFormProps extends FormCommonProps<number> {
	customerGroupId: number | undefined;
}

interface FormValues extends CustomerGroup, ObjectAccountingFormValues {}

export const CustomerGroupForm = (props: CustomerGroupFormProps) => {
	const { customerGroupId, onCompleted, onFormEdited } = props;
	return (
		<AsyncForm
			fetch={() => CustomerGroupEditApi.getFormInitData({ customerGroupId })}
			getDefaultValues={getDefaultValues}
			submit={submit}
			onCompleted={onCompleted}
			onFormEdited={onFormEdited}
			render={({ control }) => (
				<>
					<FormTextField
						control={control}
						name={"name"}
						label={i18n.t("name")}
						rules={requireRule()}
					/>
					<FormTextField
						control={control}
						name={"description"}
						label={i18n.t("description")}
					/>
					<ObjectAccountingForm control={control} />
				</>
			)}
		/>
	);

	function getDefaultValues({
		customerGroup,
		accountingDimensions,
	}: CustomerGroupEditApi_FormInitData): DeepPartial<FormValues> {
		const accountingValues = getObjectAccountingFormDefaultValues(accountingDimensions);
		if (customerGroup != null) {
			return {
				...customerGroup,
				...accountingValues,
			};
		} else {
			return {
				...accountingValues,
			};
		}
	}

	async function submit(values: FormValues) {
		const { accountingDimensionValues, ...customerGroup } = values;
		const accountingCodeIds = getObjectAccountingCodeIdsFromFormValues(accountingDimensionValues);
		if (customerGroupId != null) {
			await CustomerGroupEditApi.update({
				customerGroup,
				accountingCodeIds,
			});
			return customerGroupId;
		} else {
			return await CustomerGroupEditApi.insert({
				customerGroup,
				accountingCodeIds,
			});
		}
	}
};
