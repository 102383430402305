// This file is automatically generated. Do not edit manually.

import { t } from "i18next";

export const ProjectActivityMaterialStateValues = [
	"INITIAL",
	"RELEASED",
	"RESERVED",
	"PICKED",
	"PARTIALLY_DELIVERED",
	"DELIVERED",
	"CANCELLED",
] as const;

export type ProjectActivityMaterialState = (typeof ProjectActivityMaterialStateValues)[number];

export const getProjectActivityMaterialStateLabels = () => ({
	INITIAL: t("initial"),
	RELEASED: t("released"),
	RESERVED: t("reserved"),
	PICKED: t("picked"),
	PARTIALLY_DELIVERED: t("partially_delivered"),
	DELIVERED: t("delivered"),
	CANCELLED: t("cancelled"),
});

export const getProjectActivityMaterialStateLabel = (value: ProjectActivityMaterialState): string => {
	return getProjectActivityMaterialStateLabels()[value];
};
