import {
    SpcSamplingMethod
} from "src/components/views/spc/basedata/controlCharts/form/samplingMethod/SpcSamplingMethod.tsx";
import i18n from "i18next";
import {FormMultiSelectField} from "src/components/common/forms/fields/FormMultiSelectField.tsx";
import {SitesApi} from "src/api/generated/erp/common/sites/sitesApi.ts";
import {CustomerOrderTypeApi} from "src/api/generated/erp/sales/basedata/api/customerOrderTypeApi.ts";
import {TenantConfig} from "src/tenantCustomizations/TenantCustomizations.ts";
import {FormLazySelectField} from "src/components/common/forms/fields/FormLazySelectField.tsx";
import {requireRule} from "src/components/common/forms/validation.ts";
import {FormLazyMultiSelectField} from "src/components/common/forms/fields/FormLazyMultiSelectField.tsx";
import {toIntOrNull} from "src/utils/strings.tsx";

export const getCustomerOrderTenantStateSpcSamplingMethod = (
	tenantConfig: TenantConfig,
): SpcSamplingMethod => {
	return {
		key: "erp_customer_order_tenant_state",
		label: i18n.t("spc_sampling_method_label.customer_order_tenant_state", {
			tenantState: tenantConfig.erp.customerOrderTenantStateColumnLabel,
		}),
		renderParameterFields: ({ control }) => {
			return (
				<>
					<FormMultiSelectField
						control={control}
						name={"samplingParams.param1"}
						label={i18n.t("customer_order_tenant_state")}
						options={tenantConfig.erp.customerOrderTenantStates.map((state) => ({
							key: state.key,
							label: state.label,
						}))}
						rules={requireRule()}
						decodeValue={(v) => (v != null ? JSON.parse(v) : [])}
						encodeValue={(v) => JSON.stringify(v)}
					/>
					<FormLazySelectField
						control={control}
						name={"samplingParams.param2"}
						label={i18n.t("site")}
						fetchOptions={SitesApi.getAll}
						getOptionKey={(o) => o.siteId}
						getOptionLabel={(o) => o.siteName}
						rules={requireRule()}
						fieldValueToKey={(v) => toIntOrNull(v)}
						selectionToFieldValue={(v) => v?.toString() ?? ""}
					/>
					<FormLazyMultiSelectField
						control={control}
						name={"samplingParams.param3"}
						label={i18n.t("customer_order_type")}
						fetchOptions={CustomerOrderTypeApi.getAll}
						getOptionKey={(o) => o.customerOrderTypeId}
						getOptionLabel={(o) => o.name}
						rules={requireRule()}
						encodeValue={(v) => JSON.stringify(v)}
						decodeValue={(v) => (v != null ? JSON.parse(v) : [])}
					/>
				</>
			);
		},
	};
};
