import { TaskQueryApi } from "src/api/generated/tasks/api/taskQueryApi.ts";
import i18n from "i18next";
import { TaskQueryParams } from "src/components/views/tasks/types.ts";
import {
	decodeTaskSourceNullable,
	encodeTaskSource,
	encodeTaskSourceNullable,
} from "src/components/views/tasks/taskSources/taskSourceUtils.ts";
import { AsyncSelectField } from "src/components/common/inputFields/AsyncSelectField";
import { faPlus, faSync } from "@fortawesome/pro-regular-svg-icons";
import { AavoIconButton } from "src/components/common/buttons/AavoIconButton.tsx";
import { HorizontalBox } from "src/components/common/box/HorizontalBox.tsx";
import { TaskSourceOption } from "src/api/generated/tasks/api/taskSourceOption.ts";
import { useGenericDialog } from "src/components/common/dialogs/useGenericDialog.ts";
import { SingleTaskMobileView } from "src/components/views/tasks/mobile/SingleTaskMobileView.tsx";
import { TaskSourceRef } from "src/api/generated/tasks/taskSource/taskSourceRef.ts";
import { Box } from "@mui/material";

export interface TasksMobileViewFooterProps {
	queryParamValues: TaskQueryParams;
	refreshAllTasks: (params: Partial<TaskQueryParams>) => Promise<unknown>;
	newTaskSourceRef: TaskSourceRef | undefined;
	showSourceFilter: boolean;
}

export const TasksMobileViewFooter = ({
	queryParamValues,
	refreshAllTasks,
	newTaskSourceRef,
	showSourceFilter,
}: TasksMobileViewFooterProps) => {
	const { openDialog } = useGenericDialog();

	return (
		<HorizontalBox
			sx={{
				padding: 1,
				borderTop: "1px solid",
				borderColor: "divider",
				gap: 1,
			}}
		>
			{
				showSourceFilter ?
					<AsyncSelectField<TaskSourceOption, string>
						getOptionKey={(option) => encodeTaskSource(option)}
						getOptionLabel={(option) => option.sourceDescription}
						label={i18n.t("source")}
						defaultValue={encodeTaskSourceNullable(queryParamValues.sources?.at(0))}
						fetchOptions={({ searchQuery, currentSelection }) =>
							TaskQueryApi.getTaskSourceOptions({
								searchQuery: searchQuery,
								currentSelection: decodeTaskSourceNullable(currentSelection),
								states: queryParamValues.states,
								assignedToUserId: queryParamValues.assignedToUserId,
								responsiblePersonId: queryParamValues.responsiblePersonId,
							})
						}
						popperProps={{
							placement: "top-start",
						}}
						slotProps={{
							paper: {
								sx: {
									background: (theme) => theme.palette.grey[100],
								},
							},
						}}
						blurOnSelect
						onChange={async (value) => {
							await refreshAllTasks({
								sources: value == null ? null : [value],
							});
						}}
						size={"small"}
						sx={{
							flex: 1,
						}}
					/>
				:	<Box flex={1} /> // Fill space if source filter hidden.
			}
			<AavoIconButton
				icon={faPlus}
				onClick={async () => {
					openDialog(({ closeDialog }) => ({
						title: i18n.t("new_task"),
						content: (
							<SingleTaskMobileView
								task={null}
								closeTaskView={closeDialog}
								refreshTasks={async () => {
									await refreshAllTasks({});
								}}
								newTaskSourceRef={newTaskSourceRef}
							/>
						),
					}));
				}}
			/>
			<AavoIconButton
				icon={faSync}
				onClick={async () => {
					await refreshAllTasks({});
				}}
			/>
		</HorizontalBox>
	);
};
