import { PropsWithChildren, useEffect } from "react";
import * as Sentry from "@sentry/react";
import i18n from "i18next";
import { metrics } from "@sentry/react";
import { OpenErrorReportDialogButton } from "src/sentry/OpenErrorReportDialogButton.tsx";
import { VerticalBox } from "src/components/common/box/VerticalBox";
import Typography from "@mui/material/Typography";
import { linebreaksToHtmlBreaks } from "src/utils/strings.tsx";
import { HorizontalBox } from "src/components/common/box/HorizontalBox";
import { faRefresh } from "@fortawesome/pro-regular-svg-icons";
import { unregisterServiceWorkers } from "src/utils/serviceWorkerUtils.ts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@mui/material";

export const ErrorBoundary = ({ children }: PropsWithChildren) => {
	if (import.meta.env.DEV) return children;

	return (
		<Sentry.ErrorBoundary showDialog={false} fallback={(params) => <Fallback {...params} />}>
			{children}
		</Sentry.ErrorBoundary>
	);
};

interface FallbackProps {
	error: unknown;
	eventId: string;
}

const Fallback = ({ eventId }: FallbackProps) => {
	useEffect(() => {
		metrics.increment("error_fallback_mount");
	}, []);
	return (
		<VerticalBox
			sx={{
				flex: 1,
				alignSelf: "stretch",
				alignItems: "center",
				justifyContent: "center",
				gap: 2,
			}}
		>
			<Typography variant={"h5"}>{i18n.t("error_boundary_fallback_title")}</Typography>
			{linebreaksToHtmlBreaks(i18n.t("error_boundary_fallback_content"), {
				maxWidth: "80%",
				textAlign: "center",
			})}
			<HorizontalBox gap={1}>
				<OpenErrorReportDialogButton eventId={eventId} />
				<Button
					startIcon={<FontAwesomeIcon icon={faRefresh} />}
					variant={"outlined"}
					onClick={async () => {
						localStorage.clear();
						await unregisterServiceWorkers();
						window.location.reload();
					}}
				>
					<Typography
						variant={"button"}
						sx={{
							flex: 1,
							textAlign: "left",
						}}
					>
						{i18n.t("clear_cache_and_update_app")}
					</Typography>
				</Button>
			</HorizontalBox>
		</VerticalBox>
	);
};
