// This file is automatically generated. Do not edit manually.

import { FrontendDownloadFileHandle } from "src/api/generated/common/frontendDownloadFileHandle";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace SsaCustomerOrderDegreeOfReadinessApi {
	export async function printCustomerOrderDegreeOfReadinessReport(args: {
		customerOrderId: number;
	}): Promise<FrontendDownloadFileHandle> {
		const response = await makeAxiosRequest({
			url: "/api/ext/ssa/sales/customerOrderDegreeOfReadiness/printCustomerOrderDegreeOfReadinessReport",
			method: "POST",
			data: {
				customerOrderId: args.customerOrderId,
			},
		});
		return response as FrontendDownloadFileHandle;
	}
}
