import { AavoMosaic } from "src/components/common/mosaic/AavoMosaic";
import i18n from "i18next";
import { ProductFamilyVersionConnectedRevisionsDataGrid } from "src/components/views/erp/configurator/managing/productFamilyVersions/revisionConnections/ProductFamilyVersionConnectedRevisionsDataGrid.tsx";
import { ProductFamilyVersionUnconnectedRevisionsDataGrid } from "src/components/views/erp/configurator/managing/productFamilyVersions/revisionConnections/ProductFamilyVersionUnconnectedRevisionsDataGrid.tsx";
import { useRefreshRef } from "src/utils/useRefreshRef.ts";

export interface ProductFamilyVersionRevisionConnectionManagementViewProps {
	productFamilyVersionId: number;
}

export const ProductFamilyVersionRevisionConnectionManagementView = ({
	productFamilyVersionId,
}: ProductFamilyVersionRevisionConnectionManagementViewProps) => {
	const connectedRevisionsRefreshRef = useRefreshRef();
	return (
		<AavoMosaic
			viewId={"CBC9469CAC9D3CFB"}
			layout={{
				type: "row",
				first: {
					type: "panel",
					title: i18n.t("unconnected_revisions"),
					content: (
						<ProductFamilyVersionUnconnectedRevisionsDataGrid
							productFamilyVersionId={productFamilyVersionId}
							connectedRevisionsRefreshRef={connectedRevisionsRefreshRef}
						/>
					),
				},
				second: {
					type: "panel",
					title: i18n.t("connected_revisions"),
					content: (
						<ProductFamilyVersionConnectedRevisionsDataGrid
							productFamilyVersionId={productFamilyVersionId}
							refreshRef={connectedRevisionsRefreshRef}
						/>
					),
				},
			}}
		/>
	);
};
