// This file is automatically generated. Do not edit manually.

import { Task } from "src/api/generated/postgres/db/types/tasks/tables/task";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace TaskCreationApi {
	export async function createTask(args: { task: Task }): Promise<number> {
		const response = await makeAxiosRequest({
			url: "/api/ext/tasks/createTask",
			method: "POST",
			data: {
				task: args.task,
			},
		});
		return response as number;
	}
}
