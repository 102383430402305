import {ClientSideDataGridModel} from "src/components/common/dataGrid/gridModel/ClientSideDataGridModel.tsx";
import {CrudDataGrid} from "src/components/common/dataGrid/crud/CrudDataGrid.tsx";
import {PartRevisionView} from "src/api/generated/erp/db/types/tables/partRevisionView.ts";
import {dateColumn, enumColumn, textColumn} from "src/components/common/dataGrid/columns.tsx";
import i18n from "i18next";
import {getPartRevisionStateLabels} from "src/api/generated/erp/db/types/enums/partRevisionState";
import {DocumentsOfObjectButton} from "src/components/views/documents/objectDocuments/DocumentsOfObjectButton.tsx";
import {nullableAavoObjectRef} from "src/utils/aavoObjectRefUtils.ts";
import {AavoButton} from "src/components/common/buttons/AavoButton.tsx";
import {
    faBars,
    faCalculator,
    faComment,
    faEuroSign,
    faFileDownload,
    faShare,
    faSitemap,
    faStethoscope,
    faSyncAlt,
    faTable,
    faUndo,
} from "@fortawesome/pro-regular-svg-icons";
import {useOpenLegacyView} from "src/components/views/legacy/useOpenLegacyView.ts";
import {AsyncMenuButton} from "src/components/common/contextMenu/AsyncMenuButton.tsx";
import {useInputDialog} from "src/components/common/dialogs/input/useInputDialog.tsx";
import {useConfirmDialog} from "src/components/common/dialogs/confirmDialog/useConfirmDialog.ts";
import {RefreshableElementRef} from "src/utils/useRefreshRef.ts";
import {useGenericDialog} from "src/components/common/dialogs/useGenericDialog.ts";
import {
    SitePartTestConfiguratorView
} from "src/components/views/erp/configurator/configuratorTesting/sitePart/SitePartTestConfiguratorView.tsx";
import {dayJsToDateIsoString} from "src/utils/dayjsUtils.ts";
import {SitePartRevisionsDataGridApi} from "src/api/generated/erp/parts/sitePart/api/sitePartRevisionsDataGridApi.ts";
import dayjs from "dayjs";
import {SitePartRevisionProductStructureView} from "./SitePartRevisionProductStructureView";
import {openFormOnDialog} from "src/components/common/dialogs/formDialog/openFormOnDialog.ts";
import {
    SitePartRevisionPriceConfiguratorForm
} from "src/components/views/erp/parts/siteParts/SitePartRevisionPriceConfiguratorForm.tsx";

export interface SitePartRevisionsDataGridProps {
	partId: number;
	catalogPartRevisionId?: number;
	partViewRefreshRef?: RefreshableElementRef;
}

export const SitePartRevisionsDataGrid = ({
	partId,
	catalogPartRevisionId,
	partViewRefreshRef,
}: SitePartRevisionsDataGridProps) => {
	const openLegacyView = useOpenLegacyView();
	const showConfirm = useConfirmDialog();
	const showInputDialog = useInputDialog();
	const { openDialog } = useGenericDialog();

	return (
		<ClientSideDataGridModel
			gridId={"58181636C73A69B3"}
			fetchData={() => SitePartRevisionsDataGridApi.getData({ partId, catalogPartRevisionId })}
			getRows={(data) => data.partRevisions}
			getRowId={(row) => row.partRevisionId}
			initialParams={{}}
			render={({ data, dataGridProps, onlySelectedRow, refreshData }) => {
				const { part, configurationPriceCalculationMethod } = data;
				return (
					<CrudDataGrid<PartRevisionView>
						columns={[
							textColumn({
								field: "revisionNumber",
								headerName: i18n.t("revision"),
								width: 60,
							}),
							enumColumn({
								field: "partRevisionState",
								headerName: i18n.t("state"),
								enumLabels: getPartRevisionStateLabels(),
							}),
							dateColumn({
								field: "validFrom",
								headerName: i18n.t("valid_from"),
								width: 125,
							}),
							textColumn({
								field: "note",
								headerName: i18n.t("comment"),
								width: 200,
							}),
							part.isConfigurable &&
								textColumn({
									field: "configurationProductFamilyVersionNumber",
									headerName: i18n.t("product_family_version"),
								}),
							dateColumn({
								field: "createdAt",
								headerName: i18n.t("created_at"),
							}),
							textColumn({
								field: "createdByUserName",
								headerName: i18n.t("created_by"),
								width: 120,
							}),
							dateColumn({
								field: "releasedAt",
								headerName: i18n.t("released_at"),
							}),
							textColumn({
								field: "releasedByUserName",
								headerName: i18n.t("released_by"),
								width: 120,
							}),
							textColumn({
								field: "materialCost",
								headerName: i18n.t("material_cost"),
								width: 140,
							}),
							textColumn({
								field: "workCost",
								headerName: i18n.t("work_cost"),
							}),
							textColumn({
								field: "partRevisionId",
								headerName: i18n.t("id"),
							}),
						]}
						actionBarComponents={
							<>
								<DocumentsOfObjectButton
									objectRef={nullableAavoObjectRef(
										"PART_REVISION",
										onlySelectedRow?.partRevisionId,
									)}
								/>
								<AavoButton
									label={i18n.t("product_structure")}
									icon={faTable}
									variant={"outlined"}
									disabled={onlySelectedRow == null}
									onClick={() => {
										if (onlySelectedRow == null) return;
										openDialog(({ onDataDirtyStateChanged }) => ({
											title: i18n.t("product_structure"),
											content: (
												<SitePartRevisionProductStructureView
													partRevisionId={onlySelectedRow.partRevisionId}
													dataDirtyStateChanged={onDataDirtyStateChanged}
												/>
											),
										}));
									}}
								/>
								<AavoButton
									label={i18n.t("product_structure_tree")}
									icon={faSitemap}
									variant={"outlined"}
									onClick={() => {
										if (onlySelectedRow?.partRevisionId == null) return;

										openLegacyView("286E4A2AC5B8EA87", {
											_rootSitePartRevisionId: onlySelectedRow.partRevisionId,
											partId: null,
											siteId: onlySelectedRow.siteId,
										});
									}}
									disabled={onlySelectedRow == null}
								/>
							</>
						}
						rowContextMenuComponents={({ row, onlySingleRowSelected }) => [
							onlySingleRowSelected && (
								<AsyncMenuButton
									key={"importContent"}
									label={i18n.t("import_content")}
									icon={faFileDownload}
									onClick={() => {
										openLegacyView("19fe0a9c-3009-40a2-ba48-068640f7ce24", {
											_targetPartRevisionId: row.partRevisionId,
											partId: row.partId,
											siteId: row.siteId,
										});
									}}
								/>
							),
							onlySingleRowSelected && row.partRevisionState !== "RELEASED" && (
								<AsyncMenuButton
									key={"release"}
									label={i18n.t("release")}
									icon={faShare}
									onClick={async () => {
										const validFrom = await showInputDialog({
											title: i18n.t("release"),
											type: "date",
											fieldLabel: i18n.t("valid_from"),
											required: true,
											defaultValue: dayjs(),
										});
										if (validFrom == null) return;
										await SitePartRevisionsDataGridApi.releaseRevision({
											partRevisionId: row.partRevisionId,
											validFrom: dayJsToDateIsoString(validFrom),
										});
										await refreshData();
									}}
								/>
							),
							onlySingleRowSelected && row.partRevisionState === "RELEASED" && (
								<AsyncMenuButton
									key={"unrelease"}
									label={i18n.t("revert_release")}
									icon={faUndo}
									onClick={async () => {
										const confirmed = await showConfirm({
											title: i18n.t("are_you_sure"),
											message: i18n.t("return_revision_to_initial"),
										});
										if (!confirmed) return;
										await SitePartRevisionsDataGridApi.revertReleasePartRevision({
											partRevisionId: row.partRevisionId,
										});
										await refreshData();
									}}
								/>
							),
							onlySingleRowSelected && (
								<AsyncMenuButton
									key={"calculateCosts"}
									label={i18n.t("calculate_costs")}
									icon={faCalculator}
									onClick={async () => {
										await SitePartRevisionsDataGridApi.calculatePartRevisionCosts({
											partRevisionId: row.partRevisionId,
										});
										await refreshData();
									}}
								/>		
							),
							onlySingleRowSelected && (
								<AsyncMenuButton
									key={"syncCostToPart"}
									label={i18n.t("sync_cost_to_part")}
									icon={faSyncAlt}
									onClick={async () => {
										await SitePartRevisionsDataGridApi.updatePartStandardCost({
											partRevisionId: row.partRevisionId,
										});
										await refreshData();
										partViewRefreshRef?.refresh();
									}}
								/>
							),
							onlySingleRowSelected && (
								<AsyncMenuButton
									key={"costHistory"}
									label={i18n.t("cost_log")}
									icon={faBars}
									onClick={() => {
										openLegacyView("8551196064DD54C9", {
											partRevisionId: row.partRevisionId,
										});
									}}
								/>
							),
							part.isConfigurable &&
								configurationPriceCalculationMethod === "RULE" &&
								onlySingleRowSelected && (
									<AsyncMenuButton
										key={"priceConfiguratorRules"}
										label={i18n.t("price_configurator_rules")}
										icon={faEuroSign}
										onClick={async () => {
											openFormOnDialog({
												openDialog,
												component: SitePartRevisionPriceConfiguratorForm,
												title: i18n.t("price_configurator_rules"),
												size: "xl",
												props: {
													partRevisionId: row.partRevisionId,
												},
											});
										}}
									/>
								),
							part.isConfigurable && onlySingleRowSelected && (
								<AsyncMenuButton
									key={"testConfiguration"}
									label={i18n.t("test_configuration")}
									icon={faStethoscope}
									onClick={() => {
										openDialog(() => ({
											title: i18n.t("test_configuration"),
											size: "lg",
											content: (
												<SitePartTestConfiguratorView
													sitePartRevisionId={row.partRevisionId}
												/>
											),
										}));
									}}
								/>
							),
							<AsyncMenuButton
								key={"comment"}
								label={i18n.t("comment")}
								icon={faComment}
								onClick={async () => {
									await showNoteUpdateDialog(row);
									await refreshData();
								}}
							/>,
						]}
						onRowDoubleClick={async () => {
							if (onlySelectedRow == null) return;
							await showNoteUpdateDialog(onlySelectedRow);
							await refreshData();
						}}
						{...dataGridProps}
					/>
				);
			}}
		/>
	);

	async function showNoteUpdateDialog(row: PartRevisionView) {
		const newComment = await showInputDialog({
			type: "string",
			title: i18n.t("comment"),
			fieldLabel: i18n.t("comment"),
			defaultValue: row.note ?? "",
			fieldProps: {
				text: {
					multiLine: true,
				},
			},
		});
		if (newComment == null) return;
		await SitePartRevisionsDataGridApi.saveNote({
			partRevisionId: row.partRevisionId,
			note: newComment,
		});
	}
};
