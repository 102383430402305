import { CatalogPartRevisionAttributeRulesApi_AttributeRuleDto } from "src/api/generated/erp/configurator/management/catalogPart/api/catalogPartRevisionAttributeRulesApi.ts";
import i18n from "i18next";
import { CatalogPartRevision } from "src/api/generated/erp/db/types/tables/catalogPartRevision.ts";
import { AavoMosaic } from "src/components/common/mosaic/AavoMosaic.tsx";
import { useState } from "react";
import { CatalogPartRevisionAttributeRulesDataGrid } from "src/components/views/erp/configurator/managing/catalogPart/attributeRules/CatalogPartRevisionAttributeRulesDataGrid.tsx";
import { AavoCodeMirror } from "src/components/common/codeMirror/AavoCodeMirror.tsx";
import { CenteredTypography } from "src/components/common/CenteredTypography.tsx";

export interface CatalogPartRevisionAttributeRulesViewProps {
	catalogPartRevision: CatalogPartRevision;
}

export const CatalogPartRevisionAttributeRulesView = ({
	catalogPartRevision,
}: CatalogPartRevisionAttributeRulesViewProps) => {
	const [selectedRule, setSelectedRule] = useState<CatalogPartRevisionAttributeRulesApi_AttributeRuleDto | undefined>(
		undefined,
	);
	return (
		<AavoMosaic
			viewId={"CADDA7EA89969CB0"}
			layout={{
				type: "row",
				first: {
					type: "panel",
					title: i18n.t("attributes"),
					content: (
						<CatalogPartRevisionAttributeRulesDataGrid
							catalogPartRevision={catalogPartRevision}
							onSelectionChanged={setSelectedRule}
						/>
					),
				},
				second: {
					type: "panel",
					title: i18n.t("function"),
					content:
						selectedRule == null ?
							<CenteredTypography>{i18n.t("select_attribute")}</CenteredTypography>
						:	<AavoCodeMirror
								key={selectedRule?.catalogPartRevisionAttributeConfigurationRuleId}
								value={selectedRule?.ruleScript}
								disabled
							/>,
				},
			}}
		/>
	);
};
