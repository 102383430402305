// This file is automatically generated. Do not edit manually.

import { t } from "i18next";

export const SystemDiagramDirectionValues = ["UP_TO_DOWN", "LEFT_TO_RIGHT"] as const;

export type SystemDiagramDirection = (typeof SystemDiagramDirectionValues)[number];

export const getSystemDiagramDirectionLabels = () => ({
	UP_TO_DOWN: t("up_to_down"),
	LEFT_TO_RIGHT: t("left_to_right"),
});

export const getSystemDiagramDirectionLabel = (value: SystemDiagramDirection): string => {
	return getSystemDiagramDirectionLabels()[value];
};
