// This file is automatically generated. Do not edit manually.

import { ProjectActivityCatalogPartRevisionView } from "src/api/generated/erp/db/types/tables/projectActivityCatalogPartRevisionView";
import { CatalogPartRevisionView } from "src/api/generated/erp/db/types/tables/catalogPartRevisionView";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace ProjectActivityCatalogPartsApi {
	export async function getActivityCatalogPartRevisions(args: {
		projectActivityId: number;
	}): Promise<Array<ProjectActivityCatalogPartRevisionView>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/project/projectActivityCatalogParts/getActivityCatalogPartRevisions",
			method: "POST",
			data: {
				projectActivityId: args.projectActivityId,
			},
		});
		return response as Array<ProjectActivityCatalogPartRevisionView>;
	}

	export async function getCatalogPartRevisionOptions(args: {
		catalogPartIds: Array<number>;
	}): Promise<Array<CatalogPartRevisionView>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/project/projectActivityCatalogParts/getCatalogPartRevisionOptions",
			method: "POST",
			data: {
				catalogPartIds: args.catalogPartIds,
			},
		});
		return response as Array<CatalogPartRevisionView>;
	}

	export async function connectRevisionsToActivity(args: {
		projectActivityId: number;
		catalogPartRevisionIds: Array<number>;
	}): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/project/projectActivityCatalogParts/connectRevisionsToActivity",
			method: "POST",
			data: {
				projectActivityId: args.projectActivityId,
				catalogPartRevisionIds: args.catalogPartRevisionIds,
			},
		});
		return response as void;
	}

	export async function deleteConnections(args: {
		projectActivityCatalogPartRevisionIds: Array<number>;
	}): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/project/projectActivityCatalogParts/deleteConnections",
			method: "POST",
			data: {
				projectActivityCatalogPartRevisionIds: args.projectActivityCatalogPartRevisionIds,
			},
		});
		return response as void;
	}
}
