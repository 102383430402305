import i18n from "i18next";
import { FormAsyncSelectField } from "src/components/common/forms/fields/FormAsyncSelectField.tsx";
import { ProjectQueryApi } from "src/api/generated/erp/project/project/api/projectQueryApi.ts";
import { requireRule } from "src/components/common/forms/validation.ts";
import { FormLazySelectField } from "src/components/common/forms/fields/FormLazySelectField.tsx";
import { SubProjectApi } from "src/api/generated/erp/project/subProject/api/subProjectApi.ts";
import { IsoDateString } from "src/types/dateTime.ts";
import { AavoForm, AavoFormContentParams } from "src/components/common/forms/AavoForm.tsx";
import { openFormOnDialog } from "src/components/common/dialogs/formDialog/openFormOnDialog.ts";
import { SubProjectForm } from "src/components/views/erp/project/subProject/SubProjectForm.tsx";
import { useGenericDialog } from "src/components/common/dialogs/useGenericDialog.ts";
import { FormCommonProps } from "src/components/common/forms/types.ts";
import { FormDateField } from "src/components/common/forms/fields/FormDateField.tsx";
import { Project } from "src/api/generated/erp/db/types/tables/project.ts";
import { faPenField } from "@fortawesome/pro-regular-svg-icons";
import { AsyncButton } from "src/components/common/buttons/AsyncButton.tsx";
import { CloseDialogFunc } from "src/components/common/dialogs/GenericDialogProvider.tsx";
import { useTenantCustomizations } from "src/tenantCustomizations/useTenantCustomizations.tsx";
import { SubProjectActionsApi } from "src/api/generated/erp/project/subProject/api/subProjectActionsApi.ts";
import { concatWithPipe } from "src/utils/strings.tsx";

export interface CreateNewSubProjectFormProps extends FormCommonProps<number> {
	projectId: number;
	closeDialog: CloseDialogFunc;
}

export type CreateNewSubProjectFormRenderParams = AavoFormContentParams<FormValues> & {
	targetProjectId: number;
};

export interface FormValues {
	templateProjectId: number;
	templateSubProjectId: number;
	plannedStartDate: IsoDateString;
}

export const CreateNewSubProjectForm = ({
	projectId,
	onFormEdited,
	onCompleted,
	closeDialog,
}: CreateNewSubProjectFormProps) => {
	const { openDialog } = useGenericDialog();
	const tenantCustomizations = useTenantCustomizations();

	return (
		<AavoForm
			defaultValues={{}}
			submitLabel={i18n.t("ok")}
			submit={submit}
			onFormEdited={onFormEdited}
			onCompleted={onCompleted}
			render={(params) => {
				const { control, watch, setValue } = params;
				const templateProjectId = watch("templateProjectId");
				return (
					<>
						<AsyncButton
							icon={faPenField}
							label={i18n.t("create_without_template")}
							onClick={createWithoutTemplate}
							sx={{
								paddingY: 0.5,
							}}
						/>
						<FormAsyncSelectField
							control={control}
							name={"templateProjectId"}
							label={i18n.t("template_project")}
							getOptionKey={(o: Project) => o.projectId}
							getOptionLabel={(o) => concatWithPipe(o.projectId, o.projectDescription)}
							fetchOptions={({ searchQuery, currentSelection }) =>
								ProjectQueryApi.getProjectSelectionOptions({
									includeTemplates: true,
									searchQuery,
									currentSelection,
								})
							}
							rules={requireRule()}
							onChange={() => {
								setValue("templateSubProjectId", null);
							}}
						/>
						<FormLazySelectField
							control={control}
							name={"templateSubProjectId"}
							label={i18n.t("template_sub_project")}
							disabled={
								templateProjectId == null ? i18n.t("select_template_project_first") : false
							}
							fetchOptions={() =>
								SubProjectApi.getProjectSubProjects({
									projectId: templateProjectId,
								})
							}
							getOptionKey={(o) => o.subProjectId}
							getOptionLabel={(o) =>
								concatWithPipe(o.subProjectId, o.subProjectDescription)
							}
							rules={requireRule()}
						/>
						<FormDateField
							control={control}
							name={"plannedStartDate"}
							label={i18n.t("planned_start")}
							rules={requireRule()}
						/>
						{tenantCustomizations.erp?.project?.createSubProjectFormComponents?.({
							...params,
							targetProjectId: projectId,
						})}
					</>
				);
			}}
		/>
	);

	async function createWithoutTemplate() {
		await closeDialog();
		openFormOnDialog({
			openDialog,
			component: SubProjectForm,
			title: i18n.t("sub_project"),
			size: "md",
			props: {
				projectId,
				subProjectId: undefined,
			},
			onSubmit: (subProjectId: number) => onCompleted({ type: "success", value: subProjectId }),
		});
	}

	async function submit({ templateSubProjectId, plannedStartDate }: FormValues) {
		return await SubProjectActionsApi.cloneSubProject({
			targetProjectId: projectId,
			subProjectId: templateSubProjectId,
			newPlannedStartDate: plannedStartDate,
		});
	}
};
