// This file is automatically generated. Do not edit manually.

import { WarehouseLocation } from "src/api/generated/erp/db/types/tables/warehouseLocation";
import { WarehouseLocationView } from "src/api/generated/erp/db/types/tables/warehouseLocationView";
import { WarehousePickerGroup } from "src/api/generated/erp/db/types/tables/warehousePickerGroup";
import { Site } from "src/api/generated/erp/db/types/tables/site";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace WarehouseLocationsCrudApi {
	export async function getWarehouseLocationsData(args: {
		showOnlyDefaultSite: boolean;
		showArchived: boolean;
		searchQuery?: string;
	}): Promise<WarehouseLocationsCrudApi_WarehouseLocationsData> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/warehouse/warehouseLocations/getWarehouseLocationsData",
			method: "POST",
			data: {
				showOnlyDefaultSite: args.showOnlyDefaultSite,
				showArchived: args.showArchived,
				searchQuery: args.searchQuery,
			},
		});
		return response as WarehouseLocationsCrudApi_WarehouseLocationsData;
	}

	export async function setWarehouseLocationIsActive(args: {
		warehouseLocationId: number;
		isActive: boolean;
	}): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/warehouse/warehouseLocations/setIsActive",
			method: "POST",
			data: {
				warehouseLocationId: args.warehouseLocationId,
				isActive: args.isActive,
			},
		});
		return response as void;
	}

	export async function save(args: {
		warehouseLocations: Array<WarehouseLocation>;
	}): Promise<Array<WarehouseLocationView>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/warehouse/warehouseLocations/save",
			method: "POST",
			data: {
				warehouseLocations: args.warehouseLocations,
			},
		});
		return response as Array<WarehouseLocationView>;
	}

	export async function delete_(args: { warehouseLocations: Array<WarehouseLocation> }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/warehouse/warehouseLocations/delete",
			method: "POST",
			data: {
				warehouseLocations: args.warehouseLocations,
			},
		});
		return response as void;
	}
}

export interface WarehouseLocationsCrudApi_WarehouseLocationsData {
	pickerGroupOptions: Array<WarehousePickerGroup>;
	siteOptions: Array<Site>;
	userDefaultSiteId: number;
	warehouseLocations: Array<WarehouseLocationView>;
}
