// This file is automatically generated. Do not edit manually.

import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace SalesPriceListActionApi {
	export async function releaseSalesPriceList(args: { salesPriceListId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/pricing/salesPriceLists/releaseSalesPriceList",
			method: "POST",
			data: {
				salesPriceListId: args.salesPriceListId,
			},
		});
		return response as void;
	}

	export async function deactivateSalesPriceList(args: { salesPriceListId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/pricing/salesPriceLists/deactivateSalesPriceList",
			method: "POST",
			data: {
				salesPriceListId: args.salesPriceListId,
			},
		});
		return response as void;
	}

	export async function revertDeactivateSalesPriceList(args: { salesPriceListId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/pricing/salesPriceLists/revertDeactivateSalesPriceList",
			method: "POST",
			data: {
				salesPriceListId: args.salesPriceListId,
			},
		});
		return response as void;
	}
}
