import { GridValidRowModel } from "@mui/x-data-grid/models/gridRows";
import { GridRenderEditCellParams, GridRowId } from "@mui/x-data-grid-pro";
import { SelectField, SelectFieldProps } from "src/components/common/inputFields/SelectField";
import React from "react";
import { ConditionalNullable } from "src/components/common/inputFields/types";

export interface AavoDataGridAutoCompleteSingleSelectEditorProps<
	TRowData extends GridValidRowModel,
	TOption,
	TKey extends string | number,
	DisableClearable extends boolean | undefined,
> extends GridRenderEditCellParams<TRowData, TKey>,
		Pick<
			SelectFieldProps<TOption, TKey, DisableClearable>,
			"options" | "getOptionKey" | "getOptionLabel"
		> {
	onChange?: (params: {
		id: GridRowId,
		row: TRowData;
		key: ConditionalNullable<TKey, DisableClearable>;
		option: ConditionalNullable<TOption, DisableClearable>;
	}) => void;
}

export const AavoDataGridAutoCompleteSingleSelectEditor = <
	TRowData extends GridValidRowModel,
	TOption,
	TKey extends string | number,
	DisableClearable extends boolean | undefined,
>({
	api,
	id,
	value,
	field,
	row,
	hasFocus,
	options,
	getOptionKey,
	getOptionLabel,
	disableClearable,
	onChange,
}: AavoDataGridAutoCompleteSingleSelectEditorProps<TRowData, TOption, TKey, DisableClearable>) => {
	const [isOpen, setIsOpen] = React.useState(hasFocus);

	const inputRef = (input: HTMLInputElement | null) => {
		if (hasFocus && input != null) {
			input.focus();
			input.select();
		}
	};

	return (
		<SelectField<TOption, TKey, DisableClearable>
			value={value ?? null}
			label={""}
			open={isOpen}
			onOpen={() => setIsOpen(true)}
			onClose={() => setIsOpen(false)}
			options={options}
			getOptionKey={getOptionKey}
			getOptionLabel={getOptionLabel}
			disableClearable={disableClearable}
			onChange={(newKey, newOption) => {
				api.setEditCellValue({ id, field, value: newKey });
				onChange?.({
					id: id,
					row: row,
					key: newKey,
					option: newOption,
				});
			}}
			InputProps={{
				inputRef: inputRef,
			}}
			sx={{
				width: "100%",
			}}
		/>
	);
};
