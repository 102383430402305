import { faShare } from "@fortawesome/pro-regular-svg-icons";
import i18n from "i18next";
import { AsyncButton } from "src/components/common/buttons/AsyncButton";
import { OmegakeittiotPurchaseRequestApi } from "src/api/generated/tenants/omegakeittiot/omegakeittiotPurchaseRequestApi";
import { PurchaseRequestDataTableApi_RowDto } from "src/api/generated/erp/purchase/purchaseRequest/purchaseRequestDataTableApi.ts";

export const OmegakeittiotPurchaseRequestDataGridActionBarComponents = ({
	selectedRows,
	refreshData,
}: {
	selectedRows: PurchaseRequestDataTableApi_RowDto[];
	refreshData: () => Promise<unknown>;
}) => {
	return (
		<>
			<AsyncButton
				disabled={selectedRows.length === 0}
				icon={faShare}
				onClick={async () => {
					await OmegakeittiotPurchaseRequestApi.createIntraOrderFromPurchaseRequests({
						purchaseRequestIds: selectedRows.map((row) => row.purchaseRequestId),
					});
					await refreshData();
				}}
			>
				{i18n.t("create_purchase_order_to_intra")}
			</AsyncButton>
		</>
	);
};
