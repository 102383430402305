import i18n from "i18next";
import { FormAsyncSelectField } from "src/components/common/forms/fields/FormAsyncSelectField.tsx";
import { ProjectQueryApi } from "src/api/generated/erp/project/project/api/projectQueryApi.ts";
import { requireRule } from "src/components/common/forms/validation.ts";
import { IsoDateString } from "src/types/dateTime.ts";
import { AavoForm } from "src/components/common/forms/AavoForm.tsx";
import { openFormOnDialog } from "src/components/common/dialogs/formDialog/openFormOnDialog.ts";
import { useGenericDialog } from "src/components/common/dialogs/useGenericDialog.ts";
import { FormCommonProps } from "src/components/common/forms/types.ts";
import { FormDateField } from "src/components/common/forms/fields/FormDateField.tsx";
import { Project } from "src/api/generated/erp/db/types/tables/project.ts";
import { faPenField } from "@fortawesome/pro-regular-svg-icons";
import { AsyncButton } from "src/components/common/buttons/AsyncButton.tsx";
import { CloseDialogFunc } from "src/components/common/dialogs/GenericDialogProvider.tsx";
import { concatWithPipe } from "src/utils/strings.tsx";
import { FormTextField } from "src/components/common/forms/fields/FormTextField.tsx";
import { ProjectForm } from "src/components/views/erp/project/project/ProjectForm.tsx";
import { ProjectActionsApi } from "src/api/generated/erp/project/project/api/projectActionsApi.ts";

export interface CreateNewProjectFormProps extends FormCommonProps<number> {
	closeDialog: CloseDialogFunc;
}

export interface FormValues {
	templateProjectId: number;
	newProjectName: string;
	newProjectPlannedStartDate: IsoDateString;
}

export const CreateNewProjectForm = ({
	onFormEdited,
	onCompleted,
	closeDialog,
}: CreateNewProjectFormProps) => {
	const { openDialog } = useGenericDialog();

	return (
		<AavoForm
			submitLabel={i18n.t("ok")}
			submit={submit}
			onFormEdited={onFormEdited}
			onCompleted={onCompleted}
			render={({ control, setValue }) => {
				return (
					<>
						<AsyncButton
							icon={faPenField}
							label={i18n.t("create_without_template")}
							onClick={createWithoutTemplate}
							sx={{
								paddingY: 0.5,
							}}
						/>
						<FormAsyncSelectField
							control={control}
							name={"templateProjectId"}
							label={i18n.t("template_project")}
							getOptionKey={(o: Project) => o.projectId}
							getOptionLabel={(o) => concatWithPipe(o.projectId, o.projectDescription)}
							fetchOptions={({ searchQuery, currentSelection }) =>
								ProjectQueryApi.getProjectSelectionOptions({
									includeTemplates: true,
									searchQuery,
									currentSelection,
								})
							}
							rules={requireRule()}
							onChange={(templateProject) => {
								if (templateProject == null) setValue("newProjectName", null);
								else
									setValue(
										"newProjectName",
										templateProject.projectDescription + " - " + i18n.t("copy.noun"),
									);
							}}
						/>
						<FormTextField
							control={control}
							name={"newProjectName"}
							label={i18n.t("name")}
							rules={requireRule()}
						/>
						<FormDateField
							control={control}
							name={"newProjectPlannedStartDate"}
							label={i18n.t("planned_start")}
							rules={requireRule()}
						/>
					</>
				);
			}}
		/>
	);

	async function createWithoutTemplate() {
		await closeDialog();
		openFormOnDialog({
			openDialog,
			component: ProjectForm,
			title: i18n.t("project"),
			size: "md",
			props: {
				projectId: null,
			},
			onSubmit: (projectId: number) => onCompleted({ type: "success", value: projectId }),
		});
	}

	async function submit({ templateProjectId, newProjectName, newProjectPlannedStartDate }: FormValues) {
		return await ProjectActionsApi.cloneProject({
			projectId: templateProjectId,
			newProjectName: newProjectName,
			newProjectPlannedStartDate: newProjectPlannedStartDate,
		});
	}
};
