import { DeepPartial } from "react-hook-form";
import { FormTextField } from "src/components/common/forms/fields/FormTextField.tsx";
import { FormSelectField } from "src/components/common/forms/fields/FormSelectField.tsx";
import i18n from "i18next";
import { AsyncForm, AsyncFormContentParams } from "src/components/common/forms/AsyncForm.tsx";
import { FormCommonProps } from "src/components/common/forms/types.ts";
import {
	SurveyFormRevisionApi,
	SurveyFormRevisionApi_FormInitData,
} from "src/api/generated/erp/surveys/api/surveyFormRevisionApi.ts";
import { getSurveyFormRevisionStateLabel } from "src/api/generated/erp/db/types/enums/surveyFormRevisionState.ts";
import { concatWithPipe } from "src/utils/strings.tsx";
import { FormNumberField } from "src/components/common/forms/fields/FormNumberField.tsx";
import { requireRule } from "src/components/common/forms/validation.ts";
import { SurveyFormRevision } from "src/api/generated/erp/db/types/tables/surveyFormRevision.ts";

export interface SurveyFormRevisionProps extends FormCommonProps<number> {
	surveyFormId: number;
	surveyFormRevisionId: number | undefined;
}

interface FormValues extends SurveyFormRevision {
	sourceRevisionId: number;
}

export const SurveyFormRevisionForm = (props: SurveyFormRevisionProps) => {
	const { surveyFormId, surveyFormRevisionId, onCompleted, onFormEdited } = props;

	return (
		<AsyncForm
			fetch={() =>
				SurveyFormRevisionApi.getFormInitData({
					surveyFormId,
					surveyFormRevisionId,
				})
			}
			getDefaultValues={getDefaultValues}
			submit={submit}
			onCompleted={onCompleted}
			onFormEdited={onFormEdited}
			render={(params) => <FormContent {...props} {...params} />}
		/>
	);

	function getDefaultValues({
		surveyFormRevision,
		sourceRevisionOptions,
	}: SurveyFormRevisionApi_FormInitData): DeepPartial<FormValues> {
		if (surveyFormRevision) {
			return surveyFormRevision;
		} else {
			const sourceRevision = sourceRevisionOptions[0];
			return {
				surveyFormId: surveyFormId,
				sourceRevisionId: sourceRevision?.surveyFormRevisionId,
				columnCount: sourceRevision?.columnCount,
			};
		}
	}

	async function submit({ sourceRevisionId, ...revisionValues }: FormValues) {
		if (surveyFormRevisionId) {
			await SurveyFormRevisionApi.update({
				revision: revisionValues,
			});
			return surveyFormRevisionId;
		} else {
			return await SurveyFormRevisionApi.create({
				revision: revisionValues,
				sourceRevisionId: sourceRevisionId,
			});
		}
	}
};

interface FormContentProps
	extends SurveyFormRevisionProps,
		AsyncFormContentParams<SurveyFormRevisionApi_FormInitData, FormValues> {}

const FormContent = ({ control, surveyFormRevisionId, data: { sourceRevisionOptions } }: FormContentProps) => {
	const isNewRecord = surveyFormRevisionId == null;
	return (
		<>
			{isNewRecord && (
				<FormSelectField
					control={control}
					name="sourceRevisionId"
					label={i18n.t("source_revision")}
					options={sourceRevisionOptions}
					getOptionKey={(option) => option.surveyFormRevisionId}
					getOptionLabel={(option) =>
						concatWithPipe(
							option.revisionNumber,
							getSurveyFormRevisionStateLabel(option.state),
							option.description,
						)
					}
				/>
			)}
			<FormTextField control={control} name="description" label={i18n.t("description")} multiline />
			<FormNumberField
				control={control}
				name="columnCount"
				label={i18n.t("column_count")}
				type={"integer"}
				rules={requireRule()}
			/>
		</>
	);
};
