import { AavoIconButton } from "src/components/common/buttons/AavoIconButton.tsx";
import { faQuestion } from "@fortawesome/pro-regular-svg-icons";
import { SxProps } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { PartConfiguratorDocumentContext } from "src/components/views/erp/configurator/configuratorForm/componentDocument/PartConfiguratorDocumentContext.tsx";
import { useContext } from "react";

export interface ComponentDocumentButtonProps {
	componentId: number;
	sx?: SxProps<Theme>;
}

export const ComponentDocumentButton = ({ componentId, sx }: ComponentDocumentButtonProps) => {
	const documentContext = useContext(PartConfiguratorDocumentContext);
	if (!documentContext) return null;

	const { getComponentHasDocument, componentIdWithActiveDocument, setComponentIdWithActiveDocument } =
		documentContext;

	const componentHasDocument = getComponentHasDocument(componentId);
	if (!componentHasDocument) return null;

	return (
		<AavoIconButton
			icon={faQuestion}
			sx={sx}
			color={componentIdWithActiveDocument === componentId ? "secondary" : "primary"}
			onClick={() => {
				setComponentIdWithActiveDocument(componentId);
			}}
		/>
	);
};
