import React, { createContext, useMemo, useState } from "react";
import { ErrorDialog } from "src/components/common/dialogs/errorDialog/ErrorDialog.tsx";
import { logError, LoggedError } from "src/errorHandling/errorLogging.ts";

export interface ShowErrorDialogContextValue {
	showErrorDialog: (error: LoggedError) => void;
	logErrorAndShowOnDialog: LogErrorAndShowOnDialogFunc;
	withErrorHandling: (func: () => unknown | Promise<unknown>) => Promise<void>;
}

export type LogErrorAndShowOnDialogFunc = (error: unknown, internalMessage?: string) => LoggedError;

export const ErrorDialogContext = createContext<ShowErrorDialogContextValue | undefined>(undefined);

export const ErrorDialogProvider = ({ children }: React.PropsWithChildren) => {
	const [error, setError] = useState<LoggedError | undefined>(undefined);

	const value = useMemo(() => {
		const showErrorDialog = (error: LoggedError) => {
			setError(error);
		};

		const logErrorAndShowOnDialog = (error: unknown, internalMessage?: string) => {
			const loggedError = logError(error, internalMessage);
			setError(loggedError);
			return loggedError;
		};

		return {
			showErrorDialog,
			logErrorAndShowOnDialog,
			withErrorHandling: async (func: () => unknown | Promise<unknown>): Promise<void> => {
				try {
					await func();
				} catch (error) {
					showErrorDialog(logError(error));
				}
			},
		};
	}, [setError]);

	return (
		<>
			<ErrorDialogContext.Provider value={value}>{children}</ErrorDialogContext.Provider>
			{error && (
				<ErrorDialog
					size={"sm"}
					closeDialog={() => {
						setError(undefined);
					}}
					error={error}
				/>
			)}
		</>
	);
};
