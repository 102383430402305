import {AsyncButton, AsyncButtonProps} from "src/components/common/buttons/AsyncButton.tsx";
import {useGenericDialog} from "src/components/common/dialogs/useGenericDialog.ts";
import i18n from "i18next";
import {faProjectDiagram} from "@fortawesome/pro-regular-svg-icons";
import {ProjectActivityApi} from "src/api/generated/erp/project/projectActivity/api/projectActivityApi.ts";
import {
    ProjectActivitiesContainerView
} from "src/components/views/erp/project/projectActivity/ProjectActivitiesContainerView.tsx";
import {resolveValueOrProvider, ValueOrProvider} from "src/utils/valueOrProvider.ts";

export interface OpenProjectActivityButtonProps extends Omit<AsyncButtonProps, "onClick"> {
	projectActivityId: ValueOrProvider<number | Promise<number>>;
}

export const OpenProjectActivityButton = ({
	projectActivityId,
	variant = "menu",
	...other
}: OpenProjectActivityButtonProps) => {
	const { openDialog } = useGenericDialog();
	return (
		<AsyncButton
			label={i18n.t("open_project_activity")}
			icon={faProjectDiagram}
			variant={variant}
			onClick={async () => {
				const projectActivity = await ProjectActivityApi.getSingleActivity({
					activityId: await resolveValueOrProvider(projectActivityId),
				});
				openDialog({
					title: i18n.t("project_activity"),
					content: (
						<ProjectActivitiesContainerView
							subProjectId={projectActivity.subProjectId}
							onlyActivityId={projectActivity.activityId}
						/>
					),
				});
			}}
			{...other}
		/>
	);
};
