import {FormCommonProps} from "src/components/common/forms/types.ts";
import {AsyncForm, AsyncFormContentParams} from "src/components/common/forms/AsyncForm.tsx";
import {FormSelectField} from "src/components/common/forms/fields/FormSelectField.tsx";
import {concatWithPipe} from "src/utils/strings.tsx";
import i18n from "i18next";
import {requireRule} from "src/components/common/forms/validation.ts";
import {ShopOrderOperationView} from "src/api/generated/erp/db/types/tables/shopOrderOperationView.ts";
import {FormDateTimeField} from "src/components/common/forms/fields/FormDateTimeField.tsx";
import {FormTextField} from "src/components/common/forms/fields/FormTextField.tsx";
import {FormNumberField} from "src/components/common/forms/fields/FormNumberField.tsx";
import {
    ShopOrderOperationFormApi,
    ShopOrderOperationFormApi_InitData,
} from "src/api/generated/erp/production/api/shopOrderOperation/shopOrderOperationFormApi.ts";

export interface ShopOrderOperationFormProps extends FormCommonProps<number> {
	shopOrderId: number;
	shopOrderOperationId: number | null;
}

export const ShopOrderOperationForm = ({
	shopOrderId,
	shopOrderOperationId,
	onCompleted,
	onFormEdited,
}: ShopOrderOperationFormProps) => {
	return (
		<AsyncForm<ShopOrderOperationFormApi_InitData, ShopOrderOperationView, number>
			fetch={async () =>
				ShopOrderOperationFormApi.getInitData({
					shopOrderId: shopOrderId,
					shopOrderOperationId: shopOrderOperationId,
				})
			}
			columns={2}
			getDefaultValues={({ shopOrderOperation }) => {
				return shopOrderOperation != null ?
						{ ...shopOrderOperation }
					:	{
							materialOperationId: -1,
							shopOrderId: shopOrderId,
						};
			}}
			onCompleted={onCompleted}
			onFormEdited={onFormEdited}
			submit={submitForm}
			render={(props) => (
				<FormContent
					shopOrderId={shopOrderId}
					shopOrderOperationId={shopOrderOperationId}
					onCompleted={onCompleted}
					{...props}
				/>
			)}
		/>
	);

	async function submitForm(values: ShopOrderOperationView) {
		if (shopOrderOperationId == null) {
			return await ShopOrderOperationFormApi.insert({
				shopOrderOperation: values,
			});
		} else {
			return await ShopOrderOperationFormApi.update({
				shopOrderOperation: values,
			});
		}
	}
};

interface FormContentProps
	extends ShopOrderOperationFormProps,
		AsyncFormContentParams<ShopOrderOperationFormApi_InitData, ShopOrderOperationView> {}

const FormContent = ({
	data: { shopOrder, operationOptions, productionLineOptions, shopOrderOperation, existingShopOrderOperations },
	control,
	watch,
}: FormContentProps) => {
	const isNewRecord = shopOrderOperation == null;
	const shopOrderClosed = shopOrder?.shopOrderState === "READY" || shopOrder?.shopOrderState === "CANCELLED";
	return (
		<>
			<FormSelectField
				control={control}
				name={"operationId"}
				label={i18n.t("operation")}
				options={operationOptions}
				getOptionKey={(o) => o.operationId}
				getOptionLabel={(o) => concatWithPipe(o.operationNo, o.operationDescription)}
				rules={requireRule()}
				disabled={shopOrderClosed}
			/>
			<FormSelectField
				control={control}
				name={"materialOperationId"}
				label={i18n.t("material_operation")}
				options={getMaterialOperationOptions()}
				getOptionKey={(o) => o.shopOrderOperationId}
				getOptionLabel={(o) => o.operationLongName ?? ""}
				rules={requireRule()}
				disabled={shopOrderClosed}
			/>
			<FormNumberField
				control={control}
				name={"setUpTime"}
				label={i18n.t("set_up_time")}
				disabled={shopOrderClosed}
			/>
			<FormNumberField
				control={control}
				name={"workingTime"}
				label={i18n.t("working_time")}
				disabled={shopOrderClosed}
			/>
			<FormDateTimeField
				control={control}
				name={"actualBeginDate"}
				label={i18n.t("actual_begin_date")}
				rules={
					watch("actualEndDate") != null ?
						requireRule(i18n.t("required_when_actual_end_date_set"))
					:	undefined
				}
			/>
			<FormDateTimeField control={control} name={"actualEndDate"} label={i18n.t("actual_end_date")} />
			<FormTextField
				control={control}
				name={"operator"}
				label={i18n.t("shop_order_operation.operator")}
				rules={
					watch("actualBeginDate") != null ?
						requireRule(i18n.t("operator_must_be_specified_for_started_operation"))
					:	undefined
				}
			/>
			<FormSelectField
				control={control}
				name={"productionLineId"}
				label={i18n.t("production_line")}
				options={productionLineOptions}
				getOptionKey={(o) => o.productionLineId}
				getOptionLabel={(o) => o.productionLineName}
			/>
		</>
	);

	function getMaterialOperationOptions(): Pick<
		ShopOrderOperationView,
		"shopOrderOperationId" | "operationLongName"
	>[] {
		const optionsFromSavedOperations = existingShopOrderOperations;

		if (!isNewRecord) {
			return optionsFromSavedOperations;
		}

		const selectedOperation = operationOptions.find((op) => op.operationId === watch("operationId"));

		// Option -1 is used to point material operation to self when creating a new shop order operation.
		const selfOption = {
			shopOrderOperationId: -1,
			operationLongName: selectedOperation?.operationLongName ?? "",
		};

		return [selfOption, ...optionsFromSavedOperations];
	}
};
