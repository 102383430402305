// This file is automatically generated. Do not edit manually.

import { ControlChartParentGroup } from "src/api/generated/postgres/db/types/public_/tables/controlChartParentGroup";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace ControlChartParentGroupEditApi {
	export async function getFormInitData(args: {
		parentGroupId: number | null | undefined;
	}): Promise<ControlChartParentGroupEditApi_FormInitData> {
		const response = await makeAxiosRequest({
			url: "/api/ext/spc/controlChartParentGroup/getFormInitData",
			method: "POST",
			data: {
				parentGroupId: args.parentGroupId,
			},
		});
		return response as ControlChartParentGroupEditApi_FormInitData;
	}

	export async function save(args: {
		parentGroups: Array<ControlChartParentGroup>;
	}): Promise<Array<ControlChartParentGroup>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/spc/controlChartParentGroup/save",
			method: "POST",
			data: {
				parentGroups: args.parentGroups,
			},
		});
		return response as Array<ControlChartParentGroup>;
	}

	export async function insert(args: { parentGroup: ControlChartParentGroup }): Promise<number> {
		const response = await makeAxiosRequest({
			url: "/api/ext/spc/controlChartParentGroup/insert",
			method: "POST",
			data: {
				parentGroup: args.parentGroup,
			},
		});
		return response as number;
	}

	export async function update(args: { parentGroup: ControlChartParentGroup }): Promise<number> {
		const response = await makeAxiosRequest({
			url: "/api/ext/spc/controlChartParentGroup/update",
			method: "POST",
			data: {
				parentGroup: args.parentGroup,
			},
		});
		return response as number;
	}

	export async function delete_(args: { parentGroupId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/spc/controlChartParentGroup/delete",
			method: "POST",
			data: {
				parentGroupId: args.parentGroupId,
			},
		});
		return response as void;
	}
}

export interface ControlChartParentGroupEditApi_FormInitData {
	parentGroup: ControlChartParentGroup | null | undefined;
}
