import { ClientSideDataGridModel } from "src/components/common/dataGrid/gridModel/ClientSideDataGridModel.tsx";
import { CrudDataGrid } from "src/components/common/dataGrid/crud/CrudDataGrid.tsx";
import { enumColumn, textColumn } from "src/components/common/dataGrid/columns.tsx";
import i18n from "i18next";
import { CatalogPartSitesDataGridApi } from "src/api/generated/erp/parts/catalogPart/api/catalogPartSitesDataGridApi.ts";
import { SitePartRevisionsDataGrid } from "src/components/views/erp/parts/siteParts/SitePartRevisionsDataGrid.tsx";
import { CenteredTypography } from "src/components/common/CenteredTypography.tsx";
import { useState } from "react";
import { AavoMosaic } from "src/components/common/mosaic/AavoMosaic.tsx";
import { getAcquisitionMethodLabels } from "src/api/generated/erp/db/types/enums/acquisitionMethod.ts";
import { getPartTypeLabels } from "src/api/generated/erp/db/types/enums/partType.ts";
import { CatalogPartSitePartsView } from "src/api/generated/erp/db/types/tables/catalogPartSitePartsView.ts";
import { CatalogPartRevision } from "src/api/generated/erp/db/types/tables/catalogPartRevision.ts";

export interface CatalogPartSiteRevisionsConfiguratorManagingViewProps {
	catalogPartRevision: CatalogPartRevision;
}

export const CatalogPartSiteRevisionsConfiguratorManagingView = ({
	catalogPartRevision,
}: CatalogPartSiteRevisionsConfiguratorManagingViewProps) => {
	const [selectedSitePart, setSelectedSitePart] = useState<CatalogPartSitePartsView | undefined>(undefined);
	return (
		<AavoMosaic
			viewId={"886D1C0B15CC4793"}
			layout={{
				type: "row",
				splitPercentage: 20,
				first: {
					type: "panel",
					title: i18n.t("sites"),
					content: (
						<SitePartSelectionDataGrid
							catalogPartId={catalogPartRevision.catalogPartId}
							onSelectionChanged={setSelectedSitePart}
						/>
					),
				},
				second: {
					type: "panel",
					title: i18n.t("revisions"),
					content:
						selectedSitePart == null ?
							<CenteredTypography>{i18n.t("select_a_site")}</CenteredTypography>
						:	<SitePartRevisionsDataGrid partId={selectedSitePart.sitePartId} />,
				},
			}}
		/>
	);
};

interface SitePartSelectionDataGridProps {
	catalogPartId: number;
	onSelectionChanged: (sitePart: CatalogPartSitePartsView | undefined) => void;
}

const SitePartSelectionDataGrid = ({ catalogPartId, onSelectionChanged }: SitePartSelectionDataGridProps) => {
	return (
		<ClientSideDataGridModel
			gridId={"7746802271D8D37C"}
			fetchData={() =>
				CatalogPartSitesDataGridApi.getCatalogPartSites({ catalogPartId: catalogPartId })
			}
			initialParams={{}}
			getRows={(data) => data}
			getRowId={(row) => row.sitePartId}
			onSelectionChanged={(rows) => onSelectionChanged(rows[0])}
			selectFirstRowOnLoad
			render={({ dataGridProps }) => (
				<CrudDataGrid<CatalogPartSitePartsView>
					disableMultipleRowSelection
					columns={[
						textColumn({
							field: "siteName",
							headerName: i18n.t("site"),
						}),
						enumColumn({
							field: "partType",
							headerName: i18n.t("type"),
							enumLabels: getPartTypeLabels(),
							width: 120,
						}),
						enumColumn({
							field: "acquisitionMethod",
							headerName: i18n.t("acquisition_method"),
							enumLabels: getAcquisitionMethodLabels(),
							width: 140,
						}),
						textColumn({
							field: "responsiblePersonName",
							headerName: i18n.t("responsible_person"),
						}),
					]}
					{...dataGridProps}
				/>
			)}
		/>
	);
};
