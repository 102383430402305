import DOMPurify from "dompurify";
import { valueIsNullOrEmpty } from "./agGridFormatters";

export const htmlCellRenderer = (params) => {
	if (valueIsNullOrEmpty(params)) return "";
	return DOMPurify.sanitize(params.value.value);
};

export const iconCellRenderer = (params) => {
	if (valueIsNullOrEmpty(params)) return "";
	const icon = params.value.value;
	const html = `<i class="fas fa-${icon}"</i>`;
	return DOMPurify.sanitize(html);
};

export const linkCellRenderer = (params) => {
	if (valueIsNullOrEmpty(params)) return "";
	const linkLiteral = params.value.value;
	const split = linkLiteral.split(";", 2);
	const url = split[0];
	const label = split[1] || url;
	const html = `<a href="${url}" target="_blank">${label}</a>`;
	return DOMPurify.sanitize(html);
};

export const imageCellRenderer = (params) => {
	if (valueIsNullOrEmpty(params)) return "";
	const source = params.value.value;
	const html = `<img src="${source}" class="image-cell" alt="-" loading="lazy"/>`;
	return DOMPurify.sanitize(html);
};
