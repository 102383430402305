import { ServerSideDataModelRequest } from "src/api/generated/common/dataGrids/serverSideDataModelRequest";

export const emptyDataModelRequest: ServerSideDataModelRequest = {
	resultType: "DATA",
	paginationModel: null,
	sortModel: [],
	filterModel: {
		items: [],
		logicOperator: "AND",
	},
};
