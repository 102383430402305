import { CapacityDay } from "src/api/generated/erp/db/types/tables/capacityDay.ts";
import { FormCommonProps } from "src/components/common/forms/types.ts";
import { AavoForm, AavoFormContentParams } from "src/components/common/forms/AavoForm.tsx";
import { DeepPartial } from "react-hook-form";
import { ProductionLineCapacityEditApi } from "src/api/generated/erp/production/basedata/productionLines/api/productionLineCapacityEditApi.ts";
import i18n from "i18next";
import { requireRule } from "src/components/common/forms/validation.ts";
import { FormTextField } from "src/components/common/forms/fields/FormTextField.tsx";
import { FormNumberField } from "src/components/common/forms/fields/FormNumberField.tsx";
import { FormDateField } from "src/components/common/forms/fields/FormDateField.tsx";

export interface ProductionLineCapacityDayFormProps extends FormCommonProps<number> {
	capacityDay: CapacityDay | undefined;
	productionLineId: number;
}

interface FormValues extends CapacityDay {}

export const ProductionLineCapacityDayForm = (props: ProductionLineCapacityDayFormProps) => {
	const { capacityDay, productionLineId, onCompleted, onFormEdited } = props;
	return (
		<AavoForm
			defaultValues={getDefaultValues()}
			submit={submit}
			onCompleted={onCompleted}
			onFormEdited={onFormEdited}
			render={(contentParams) => <FormContent {...props} {...contentParams} />}
		/>
	);

	function getDefaultValues(): DeepPartial<FormValues> {
		return (
			capacityDay ?? {
				productionLineId,
			}
		);
	}

	async function submit(values: FormValues) {
		if (capacityDay != null) {
			await ProductionLineCapacityEditApi.update({ capacityDay: values });
			return capacityDay.capacityId;
		} else {
			return await ProductionLineCapacityEditApi.insert({ capacityDay: values });
		}
	}
};

interface FormContentProps extends ProductionLineCapacityDayFormProps, AavoFormContentParams<FormValues> {}

const FormContent = ({ control }: FormContentProps) => {
	return <>
		<FormDateField
			control={control}
			name={"productionDate"}
			label={i18n.t("date")}
			rules={requireRule()}
		/>
		<FormNumberField
			control={control}
			name={"quantity"}
			label={i18n.t("capacity")}
			rules={requireRule()}
		/>
		<FormTextField
			control={control}
			name={"comment"}
			label={i18n.t("comment")}
		/>
		<FormNumberField
			control={control}
			name={"actualWorkHours"}
			label={i18n.t("actual_work_hours")}
		/>
	</>;
};
