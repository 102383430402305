import { DocumentRevisionFileApi } from "src/api/generated/documents/api/documentRevisionFileApi";
import { useAsyncFetch } from "src/utils/async/asyncFetch.ts";
import { ControlledAsyncDataGrid } from "src/components/common/dataGrid/ControlledAsyncDataGrid.tsx";
import { DocumentFile } from "src/api/generated/postgres/db/types/documents/tables/documentFile";
import {
	dateTimeColumn,
	enumColumn,
	integerColumn,
	textColumn,
} from "src/components/common/dataGrid/columns.tsx";
import i18n from "i18next";
import { getFileMirrorStrategyLabels } from "src/api/generated/postgres/db/types/documents/enums/fileMirrorStrategy.ts";
import { dataGridPersistentStateProps } from "src/components/common/dataGrid/dataGridStateStorage.ts";
import { prettyBytes } from "src/utils/prettyBytes.ts";

export interface DocumentRevisionFilesDataGridProps {
	documentRevisionId: number;
}

export const DocumentRevisionFilesDataGrid = ({
	documentRevisionId,
}: DocumentRevisionFilesDataGridProps) => {
	const [rowsAsync, refreshData] = useAsyncFetch(() =>
		DocumentRevisionFileApi.getDocumentRevisionFiles({ documentRevisionId }),
	);
	return (
		<ControlledAsyncDataGrid<DocumentFile>
			refreshData={refreshData}
			rowsAsync={rowsAsync}
			getRowId={(row) => row.documentFileId}
			columns={[
				integerColumn({
					field: "fileNumber",
					headerName: i18n.t("number_shortened"),
					width: 80,
				}),
				textColumn({
					field: "fileExtension",
					headerName: i18n.t("type"),
				}),
				integerColumn({
					field: "fileVersion",
					headerName: i18n.t("version"),
					width: 80,
				}),
				textColumn({
					field: "fileSize",
					headerName: i18n.t("size"),
					valueGetter: (_, row) => prettyBytes(row.fileSize),
				}),
				dateTimeColumn({
					field: "createdAt",
					headerName: i18n.t("created_at"),
					width: 150,
				}),
				enumColumn({
					field: "mirrorStrategy",
					headerName: i18n.t("generation_method"),
					enumLabels: getFileMirrorStrategyLabels(),
					width: 400,
				}),
				textColumn({
					field: "fileUuid",
					headerName: i18n.t("uuid"),
					width: 300,
				}),
			]}
			{...dataGridPersistentStateProps("4E2F0557C684DFBE")}
		/>
	);
};
