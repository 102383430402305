import { Box, Divider, Typography } from "@mui/material";
import i18n from "i18next";
import { LegacySettingsView } from "../legacy/LegacySettingsView";
import { NavigateBackButton } from "./NavigateBackButton";
import { booleanToString, capitalize } from "src/utils/strings";
import { isNotificationsAllowed } from "src/utils/notifications";
import { mergeSx } from "src/utils/styles";
import { columnFlex, dFlex, flex1, gap1, mt1, my1, p1 } from "src/styles/sx";
import { ChangePasswordButton } from "./ChangePasswordButton";
import { ResetMFAButton } from "./ResetMFAButton";
import { getFrontendReleaseCode } from "src/config/appConfig.ts";
import { useGlobalInitData } from "src/contexts/useGlobalInitData.ts";

export interface SettingsPageProps {}

export const SettingsPage = ({}: SettingsPageProps) => {
	const { userEmail } = useGlobalInitData();

	const frontendVersion = getFrontendReleaseCode();

	return (
		<Box sx={mergeSx(p1, flex1, columnFlex)}>
			<NavigateBackButton
				sx={{
					px: 2,
					alignSelf: "start",
				}}
			/>
			<Divider sx={my1} />
			<Typography>{`${capitalize(i18n.t("user_email"))}: ${userEmail}`}</Typography>
			<Typography>{`${capitalize(
				i18n.t("frontend_version"),
			)}: ${frontendVersion}`}</Typography>
			<Typography>{`${capitalize(i18n.t("notifications_allowed"))}: ${capitalize(
				i18n.t(booleanToString(isNotificationsAllowed())),
			)}`}</Typography>
			<Box sx={mergeSx(dFlex, gap1, mt1)}>
				<ChangePasswordButton />
				<ResetMFAButton />
			</Box>

			<Divider sx={my1} />
			<LegacySettingsView />
		</Box>
	);
};

export default SettingsPage;
