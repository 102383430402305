import React from "react";
import { logError } from "src/errorHandling/errorLogging.ts";

export type AavoMosaicNode = AavoMosaicRowOrColumn | AavoMosaicTabContainer | AavoMosaicPanel;

export interface AavoMosaicRowOrColumn {
	type: "row" | "column";
	first: AavoMosaicNode;
	second: AavoMosaicNode;
	splitPercentage?: number;
}

export interface AavoMosaicTabContainer {
	type: "tabs";
	items: (AavoMosaicTabItem | false)[]; // false is for conditionally hiding tabs
	hideToolbar?: boolean;
	confirmChangeActiveItem?: (newActiveItemKey: string | null) => boolean | Promise<boolean>;
	activeTabItemKey?: string | null;
	onActiveTabItemChanged?: (newActiveItemKey: string | null) => void;
}

export interface AavoMosaicPanel {
	type: "panel";
	title?: React.ReactNode;
	hidden?: boolean;
	content: React.ReactElement;
}

export interface AavoMosaicTabContainerWithKey extends AavoMosaicTabContainer {
	key: string;
}

export type AavoMosaicTabItem = AavoMosaicNode & {
	// If key is not provided, title will be used as key
	// -> titles should unique and static strings if keys are not provided.
	key?: string;
	title?: React.ReactNode;
	hidden?: boolean;
};

export function resolveAavoMosaicTabItemKey(item: AavoMosaicTabItem): string | null {
	if (item.key != null) {
		return item.key;
	} else if (typeof item.title === "string") {
		return item.title;
	} else {
		logError("No key provided for Mosaic Tab and label is not string");
		return null;
	}
}
