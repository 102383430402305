// This file is automatically generated. Do not edit manually.

import { t } from "i18next";

export const ActivityStateValues = ["INITIAL", "RELEASED", "STARTED", "READY", "CANCELLED"] as const;

export type ActivityState = (typeof ActivityStateValues)[number];

export const getActivityStateLabels = () => ({
	INITIAL: t("initial"),
	RELEASED: t("released"),
	STARTED: t("started"),
	READY: t("ready"),
	CANCELLED: t("cancelled"),
});

export const getActivityStateLabel = (value: ActivityState): string => {
	return getActivityStateLabels()[value];
};
