import { ShopOrderBatchJobQueueMobileListViewBase } from "src/components/views/erp/production/shopOrderBatch/shopOrderBatchJobQueue/mobile/ShopOrderBatchJobQueueMobileListViewBase.tsx";
import { faCheck } from "@fortawesome/pro-regular-svg-icons";
import i18n from "i18next";
import { ShopOrderBatchActionApi } from "src/api/generated/erp/production/shopOrderBatch/api/shopOrderBatchActionApi.ts";
import { ShopOrderBatchJobQueueApi } from "src/api/generated/erp/production/shopOrderBatch/api/shopOrderBatchJobQueueApi.ts";
import { RefreshableElementRef } from "src/utils/useRefreshRef.ts";
import { AsyncButton } from "src/components/common/buttons/AsyncButton.tsx";

export interface ShopOrderBatchJobQueueInProgressMobileViewProps {
	refreshRef: RefreshableElementRef;
	initialsRefreshRef: RefreshableElementRef;
	focusToInitialsTab: () => void;
}

export const ShopOrderBatchJobQueueInProgressMobileView = ({
	refreshRef,
	initialsRefreshRef,
	focusToInitialsTab,
}: ShopOrderBatchJobQueueInProgressMobileViewProps) => {
	return (
		<ShopOrderBatchJobQueueMobileListViewBase
			fetchData={() => ShopOrderBatchJobQueueApi.getWorkcenterIncompleteShopOrderBatches()}
			singleBatchDialogExtra={({ shopOrderBatch, shopOrderBatches, closeDialog, reload }) => (
				<AsyncButton
					icon={faCheck}
					label={i18n.t("complete")}
					variant="contained"
					onClick={async () => {
						const isLastInProgressBatch = shopOrderBatches.length === 1;
						await ShopOrderBatchActionApi.completeShopOrderBatchUserWorkcenterOperations({
							shopOrderBatchId: shopOrderBatch.shopOrderBatchId,
						});
						await closeDialog();

						if (isLastInProgressBatch) focusToInitialsTab();

						await Promise.all([reload(), initialsRefreshRef.refresh()]);
					}}
				/>
			)}
			refreshRef={refreshRef}
		/>
	);
};
