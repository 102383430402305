// This file is automatically generated. Do not edit manually.

import { t } from "i18next";

export const ControlChartProcessRoleValues = ["X", "Y", "OTHER"] as const;

export type ControlChartProcessRole = (typeof ControlChartProcessRoleValues)[number];

export const getControlChartProcessRoleLabels = () => ({
	X: t("x_upper_case"),
	Y: t("y_upper_case"),
	OTHER: t("other"),
});

export const getControlChartProcessRoleLabel = (value: ControlChartProcessRole): string => {
	return getControlChartProcessRoleLabels()[value];
};
