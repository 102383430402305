// This file is automatically generated. Do not edit manually.

import { SiteView } from "src/api/generated/erp/db/types/tables/siteView";
import { Site } from "src/api/generated/erp/db/types/tables/site";
import { ObjectAttribute } from "src/api/generated/erp/db/types/tables/objectAttribute";
import { ObjectAccountingDimensionValue } from "src/api/generated/erp/sales/accounting/objectAccountingDimensionValue";
import { ObjectAttributeField } from "src/api/generated/erp/common/objectAttributes/objectAttributeField";
import { Company } from "src/api/generated/erp/db/types/tables/company";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace SitesApi {
	export async function getSiteFormData(args: { siteId: number | null | undefined }): Promise<SitesApi_SiteFormData> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/common/sites/getSiteFormData",
			method: "POST",
			data: {
				siteId: args.siteId,
			},
		});
		return response as SitesApi_SiteFormData;
	}

	export async function getAll(): Promise<Array<SiteView>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/common/sites/getAll",
			method: "POST",
			data: {},
		});
		return response as Array<SiteView>;
	}

	export async function save(args: {
		site: Site;
		attributes: Array<ObjectAttribute>;
		accountingCodeIds: Array<number>;
	}): Promise<Site> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/common/sites/save",
			method: "POST",
			data: {
				site: args.site,
				attributes: args.attributes,
				accountingCodeIds: args.accountingCodeIds,
			},
		});
		return response as Site;
	}

	export async function delete_(args: { sites: Array<Site> }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/common/sites/delete",
			method: "POST",
			data: {
				sites: args.sites,
			},
		});
		return response as void;
	}
}

export interface SitesApi_SiteFormData {
	accountingDimensions: Array<ObjectAccountingDimensionValue>;
	attributeFields: Array<ObjectAttributeField>;
	attributeValues: Array<ObjectAttribute>;
	companyOptions: Array<Company>;
	site: Site | null | undefined;
}
