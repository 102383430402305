import { html, LitElement } from "lit";
import { BrowserMultiFormatReader, NotFoundException } from "@zxing/library";

class ZxingJsWebComponent extends LitElement {
	static get properties() {
		return {};
	}

	createRenderRoot() {
		return this;
	}

	constructor() {
		super();
	}

	render() {
		return html``;
	}

	firstUpdated(_changedProperties) {
		super.firstUpdated(_changedProperties);
		this._codeReader = new BrowserMultiFormatReader();
		(async () => {
			try {
				if ((await this._checkCameraAvailability()) === false) {
					this._requestToCloseScanner();
					return;
				}
				this.innerHTML = `
                    <div class="scanner-background">
                        <div class="scanner-container">
                            <video/>
                        </div>
                    </div>
                `;
				const background = this.querySelector(".scanner-background");
				background.addEventListener("click", () => {
					this._requestToCloseScanner();
				});
				const videoElement = this.querySelector(
					".scanner-container > video"
				);
				await this._startScanning(videoElement);
			} catch (err) {
				console.log("zxing-scanner", "Unknown error", err);
			}
		})();
	}

	disconnectedCallback() {
		super.disconnectedCallback();

		if (this._codeReader) {
			this._codeReader.reset();
			this._codeReader = null;
		}
	}

	async _startScanning(videoElement) {
		if (!this._codeReader) return;
		await this._codeReader.decodeFromVideoDevice(
			null,
			videoElement,
			(result, err) => {
				if (result) {
					this._onCodeRead(result.getText());
				}
				if (err && !(err instanceof NotFoundException)) {
					console.error("ZXing scan failed", err);
				}
			}
		);
	}

	async _checkCameraAvailability() {
		if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
			console.log(
				"zxing-scanner",
				"Browser does not support the mediaDevices-API"
			);
			return false;
		}
		try {
			const mediaStream = await navigator.mediaDevices.getUserMedia({
				video: true,
			});
			const hasAccess = mediaStream.getVideoTracks().length > 0;
			mediaStream.getTracks().forEach((track) => {
				track.stop();
			});
			return hasAccess;
		} catch (err) {
			if (err.name === "NotAllowedError") {
				console.log("zxing-scanner", "User denied camera access");
				return false;
			} else {
				console.error("Error thrown when asking for video device", err);
				return false;
			}
		}
	}

	_onCodeRead(code) {
		this.dispatchEvent(
			new CustomEvent("code-read", {
				detail: {
					code,
				},
			})
		);
	}

	_requestToCloseScanner() {
		this.dispatchEvent(new CustomEvent("close-requested"));
	}
}

customElements.define("zxing-js-component", ZxingJsWebComponent);
