import { AavoMenuItem } from "src/components/common/contextMenu/AavoMenuItem.tsx";
import {
	DocumentsOfObjectButton,
	DocumentsOfObjectButtonProps,
} from "src/components/views/documents/objectDocuments/DocumentsOfObjectButton.tsx";

export const DocumentsOfObjectContextMenuButton = (props: DocumentsOfObjectButtonProps) => {
	return (
		<AavoMenuItem>
			<DocumentsOfObjectButton {...props} color="black" />
		</AavoMenuItem>
	);
};
