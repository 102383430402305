import {AsyncButton, AsyncButtonProps} from "src/components/common/buttons/AsyncButton.tsx";
import {useGenericDialog} from "src/components/common/dialogs/useGenericDialog.ts";
import {useTenantCustomizations} from "src/tenantCustomizations/useTenantCustomizations.tsx";
import {faChartLine} from "@fortawesome/pro-regular-svg-icons";
import {
    ControlChartInspectingView
} from "src/components/views/spc/controlChart/controlChartInspectingView/ControlChartInspectingView.tsx";
import i18n from "i18next";

export interface OpenControlChartGraphButtonProps extends Omit<AsyncButtonProps, "onClick"> {
	controlChartId: number;
}

export const OpenControlChartGraphButton = ({
	controlChartId,
	variant = "menu",
	label = i18n.t("graph"),
}: OpenControlChartGraphButtonProps) => {
	const { openDialog } = useGenericDialog();
	const { tenantConfig } = useTenantCustomizations();
	if (!tenantConfig.spc.enabled) return null;

	return (
		<AsyncButton
			label={label}
			icon={faChartLine}
			variant={variant}
			onClick={async () => {
				openDialog({
					title: "Control Chart",
					content: <ControlChartInspectingView controlChartId={controlChartId} />,
				});
			}}
		/>
	);
};
