import {
	DeliveryPackagesWithLinesView
} from "src/components/views/erp/delivery/deliveryPackage/DeliveryPackagesWithLinesView.tsx";

export const AllUndeliveredProjectPackagesView = () => {
	return (
		<DeliveryPackagesWithLinesView
			deliverySourceType={"PROJECT"}
			gridId={"548BE3FC12D394C90"}
			packageStates={["INITIAL", "COMPLETED"]}
			editable
			showSearchField
			showSourceDocumentsButton
		/>
	);
};
