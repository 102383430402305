import { AsyncFunction } from "../../types/functions.ts";
import { useEffect } from "react";
import { logError } from "../../errorHandling/errorLogging.ts";
import { AsyncState, useAsyncState } from "./asyncState.ts";

export const useAsyncFetch = <TResult, TParam = void>(
	fetch: AsyncFunction<TParam | undefined, TResult>,
	{ fetchOnMount = true, defaultValue }: UseAsyncFetchOptions<TResult> = {},
): readonly [AsyncState<TResult>, (param?: TParam) => Promise<TResult>] => {
	const [response, update] = useAsyncState<TResult>({ defaultValue: defaultValue, isInitiallyLoading: fetchOnMount });
	const reload = (param?: TParam) => update(() => fetch(param));

	useEffect(() => {
		if (fetchOnMount) {
			reload().catch(logError);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return [response, reload] as const;
};

export interface UseAsyncFetchOptions<Result> {
	defaultValue?: Result;
	fetchOnMount?: boolean;
}
