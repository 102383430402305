import {ProjectActivityMaterialsDataGridBase} from "src/components/views/erp/project/projectActivity/material/ProjectActivityMaterialsDataGridBase.tsx";
import {
	ProjectActivityMaterialsDataGridApi
} from "src/api/generated/erp/project/projectActivityMaterial/api/projectActivityMaterialsDataGridApi.ts";

export interface SingleActivityMaterialsDataGridProps {
	activityId: number;
}

export const SingleActivityMaterialsDataGrid = ({
	activityId,
}: SingleActivityMaterialsDataGridProps) => {
	return (
		<ProjectActivityMaterialsDataGridBase
			gridId={"D368AAECDED5C493"}
			fetchData={({ showCancelledLines, dataModelRequest }) =>
				ProjectActivityMaterialsDataGridApi.getDataGridDataForActivity({
					projectActivityId: activityId,
					cancelledLines: showCancelledLines,
					dataModelRequest: dataModelRequest,
				})
			}
			showLineNumberColumn
		/>
	);
};
