// This file is automatically generated. Do not edit manually.

import { IsoDateTimeString } from "src/types/dateTime";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace ProductionLineDataGridApi {
	export async function getDataGridData(args: {
		productionLineGroupId: number;
		showOnlyActiveProductionLines: boolean;
	}): Promise<Array<ProductionLineDataGridApi_ProductionLineDto>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/productionLines/getDataGridData",
			method: "POST",
			data: {
				productionLineGroupId: args.productionLineGroupId,
				showOnlyActiveProductionLines: args.showOnlyActiveProductionLines,
			},
		});
		return response as Array<ProductionLineDataGridApi_ProductionLineDto>;
	}
}

export interface ProductionLineDataGridApi_ProductionLineDto {
	taktTimeViewName: string | null | undefined;
	capacityUnitId: number;
	capacityUnitName: string;
	groupName: string;
	isActive: boolean;
	lastUpdated: IsoDateTimeString;
	operationId: number | null | undefined;
	productionLineDescription: string;
	productionLineGroupId: number;
	productionLineId: number;
	productionLineName: string;
	productionLineOperationDescription: string | null | undefined;
	productionLineOperationId: number | null | undefined;
	productionLineOperationLongName: string | null | undefined;
	productionLineOperationNo: string | null | undefined;
	siteId: number;
	siteName: string;
	taktTimeViewId: number | null | undefined;
}
