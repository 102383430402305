// This file is automatically generated. Do not edit manually.

import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace OmegakeittiotPurchaseRequestApi {
	export async function createIntraOrderFromPurchaseRequests(args: {
		purchaseRequestIds: Array<number>;
	}): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/omegakeittiot/purchaseRequests/createPurchaseOrderToIntra",
			method: "POST",
			data: {
				purchaseRequestIds: args.purchaseRequestIds,
			},
		});
		return response as void;
	}
}
