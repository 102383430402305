import i18n from "i18next";
import { FieldValues, Validate } from "react-hook-form";
import {
	EMAIL_REGEX,
	FLOAT_INPUT_REGEX,
	INT_INPUT_REGEX,
	NON_NEGATIVE_FLOAT_INPUT_REGEX,
	NON_NEGATIVE_INT_INPUT_REGEX,
} from "src/utils/regexPatterns.ts";

export const requireRule = (message: string = i18n.t("required")) => ({
	required: message,
});

export const integerRule = <TFieldValues extends FieldValues>(
	other?: NumericValidate<TFieldValues>,
) => regexValidateNumeric(INT_INPUT_REGEX, i18n.t("integer_required"), other);

export const floatRule = <TFieldValues extends FieldValues>(
	other?: NumericValidate<TFieldValues>,
) => regexValidateNumeric(FLOAT_INPUT_REGEX, i18n.t("number_required"), other);

export const nonNegativeIntegerRule = <TFieldValues extends FieldValues>(
	other?: NumericValidate<TFieldValues>,
) =>
	regexValidateNumeric(
		NON_NEGATIVE_INT_INPUT_REGEX,
		i18n.t("non_negative_integer_required"),
		other,
	);

export const nonNegativeFloatRule = <TFieldValues extends FieldValues>(
	other?: NumericValidate<TFieldValues>,
) =>
	regexValidateNumeric(
		NON_NEGATIVE_FLOAT_INPUT_REGEX,
		i18n.t("non_negative_number_required"),
		other,
	);

export const requiredNonNegativeIntegerRule = <TFieldValues extends FieldValues>(
	other?: NumericValidate<TFieldValues>,
) => ({
	...requireRule(),
	...nonNegativeIntegerRule(other),
});

export const requiredNonNegativeFloatRule = <TFieldValues extends FieldValues>(
	other?: NumericValidate<TFieldValues>,
) => ({
	...requireRule(),
	...nonNegativeFloatRule(other),
});

export const emailRule = () => ({
	validate: (value: string) => {
		if (value.length > 0 && value.match(EMAIL_REGEX) === null) {
			return i18n.t("invalid_email");
		}

		return true;
	},
});

export type NumericValidate<TFieldValues extends FieldValues> =
	| Validate<number, TFieldValues>
	| undefined;

const regexValidateNumeric = <TFieldValues extends FieldValues>(
	regex: RegExp,
	message: string,
	other: NumericValidate<TFieldValues>,
) => {
	return {
		validate: (value: string | number | undefined | null, fieldValues: TFieldValues) => {
			if (typeof value === "string" && value.length > 0 && value.match(regex) === null) {
				return message;
			}
			if (other !== undefined) {
				const withoutComma = typeof value === "string" ? value.replace(",", ".") : value;
				const asNumber = Number(withoutComma);
				return other(asNumber, fieldValues);
			}

			return true;
		},
	};
};
