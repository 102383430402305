// This file is automatically generated. Do not edit manually.

import { ProjectView } from "src/api/generated/erp/db/types/tables/projectView";
import { SubProjectView } from "src/api/generated/erp/db/types/tables/subProjectView";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace SubProjectsDataGridApi {
	export async function getDataGridData(args: {
		projectId: number;
		cancelledSubProjects: boolean;
	}): Promise<SubProjectsDataGridApi_DataGridData> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/project/subProjectDataGrid/getDataGridData",
			method: "POST",
			data: {
				projectId: args.projectId,
				cancelledSubProjects: args.cancelledSubProjects,
			},
		});
		return response as SubProjectsDataGridApi_DataGridData;
	}
}

export interface SubProjectsDataGridApi_DataGridData {
	project: ProjectView;
	subProjects: Array<SubProjectView>;
}
