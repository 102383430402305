import { GridColumnHeaderParams } from "@mui/x-data-grid-pro";
import {
	ConfiguratorTableDataGridColumn,
	ConfiguratorTableDataGridRow,
	ConfiguratorTableDataGridState,
	ConfiguratorTableDataGridStateAction,
} from "src/components/views/erp/configurator/tables/revisionContentView/tabDataGrid/configuratorTableDataGridState.ts";
import { useContextMenu } from "src/components/common/contextMenu/useContextMenu.ts";
import React, { useState } from "react";
import { AavoTextField } from "src/components/common/inputFields/AavoTextField.tsx";
import { VerticalBox } from "src/components/common/box/VerticalBox.tsx";
import { mergeSx } from "src/utils/styles.ts";
import { alpha } from "@mui/material/styles";
import { AavoContextMenu } from "src/components/common/contextMenu/AavoContextMenu.tsx";
import { useInputDialog } from "src/components/common/dialogs/input/useInputDialog.tsx";
import { AsyncMenuButton } from "src/components/common/contextMenu/AsyncMenuButton.tsx";
import i18n from "i18next";
import { InsertColumnsBeforeIcon } from "src/components/views/erp/configurator/tables/revisionContentView/tabDataGrid/icons/InsertColumnsBeforeIcon.tsx";
import { InsertColumnsAfterIcon } from "src/components/views/erp/configurator/tables/revisionContentView/tabDataGrid/icons/InsertColumnsAfterIcon.tsx";
import { faTrash } from "@fortawesome/pro-regular-svg-icons";

export interface ConfiguratorTableTabDataGridColumnHeaderRendererProps
	extends GridColumnHeaderParams<ConfiguratorTableDataGridRow> {
	state: ConfiguratorTableDataGridState;
	dispatchState: (action: ConfiguratorTableDataGridStateAction) => void;
}

export const ConfiguratorTableTabDataGridColumnHeaderRenderer = (
	props: ConfiguratorTableTabDataGridColumnHeaderRendererProps,
) => {
	const { colDef, state, dispatchState } = props;

	const [openContextMenu, contextMenuState] = useContextMenu();
	const [editorOpen, setEditorOpen] = useState(false);

	const [value, setValue] = useState(colDef.headerName);

	return editorOpen ?
			<AavoTextField
				autoFocus
				value={value}
				onChange={(newValue) => setValue(newValue)}
				onSubmit={(value) => {
					dispatchState({
						type: "updateColumn",
						updatedColumn: {
							columnId: colDef.field,
							columnName: value,
						},
					});
					setEditorOpen(false);
				}}
				onBlur={() => setEditorOpen(false)}
				sx={{
					backgroundColor: "white",
				}}
			/>
		:	<VerticalBox
				sx={mergeSx({
					flex: 1,
					justifyContent: "center",
					padding: "0 0.5rem",
					alignSelf: "stretch",
					fontWeight: "bold",
					fontSize: "1.20rem",
					borderRight: "1px solid",
					borderColor: "grey.600",
					backgroundColor: (theme) => alpha(theme.palette.secondary.main, 0.5),
				})}
				onContextMenu={(e) => onContextMenu(e)}
				onDoubleClick={() => setEditorOpen(true)}
				tabIndex={-1}
				onKeyDown={() => setEditorOpen(true)}
			>
				{value}
				<AavoContextMenu state={contextMenuState} />
			</VerticalBox>;

	function onContextMenu(e: React.MouseEvent<HTMLDivElement>) {
		const columnIndex = state.columns.findIndex((column) => column.columnId === colDef.field);
		const column = state.columns[columnIndex];
		if (!column) return;
		openContextMenu({
			mouseEvent: e,
			content: <ColumnHeaderContextMenuContent columnIndex={columnIndex} column={column} {...props} />,
		});
	}
};

interface ColumnHeaderContextMenuContentProps extends ConfiguratorTableTabDataGridColumnHeaderRendererProps {
	column: ConfiguratorTableDataGridColumn;
	columnIndex: number;
}

const ColumnHeaderContextMenuContent = ({ column, columnIndex, dispatchState }: ColumnHeaderContextMenuContentProps) => {
	const showInputDialog = useInputDialog();

	return [
		<AsyncMenuButton
			key={"addColumnsAfter"}
			label={i18n.t("insert_columns_before")}
			iconEl={<InsertColumnsBeforeIcon />}
			onClick={() => insertColumns("before")}
		/>,
		<AsyncMenuButton
			key={"addRowsBelow"}
			label={i18n.t("insert_columns_after")}
			iconEl={<InsertColumnsAfterIcon />}
			onClick={() => insertColumns("after")}
		/>,
		<AsyncMenuButton
			key={"deleteColumn"}
			label={i18n.t("delete_column")}
			icon={faTrash}
			onClick={() => {
				dispatchState({ type: "deleteColumns", columnIds: [column.columnId] });
			}}
		/>,
	];

	async function insertColumns(direction: "before" | "after") {
		const columnCount = await showInputDialog({
			type: "integer",
			title: i18n.t("insert_columns"),
			fieldLabel: i18n.t("number_of_columns"),
			defaultValue: null,
			required: true,
		});
		if (columnCount === undefined) return;

		const startIndex = direction === "before" ? columnIndex : columnIndex + 1;
		const addColumnsParams = Array.from({ length: columnCount }).map((_row, index) => {
			return {
				columnName: "",
				index: startIndex + index,
			};
		});
		dispatchState({ type: "addColumns", params: addColumnsParams });
	}
};
