import { useStaticDataGridModel } from "src/components/common/dataGrid/gridModel/useStaticDataGridModel.tsx";
import { CatalogPartRevisionView } from "src/api/generated/erp/db/types/tables/catalogPartRevisionView.ts";
import { enumColumn, integerColumn, textColumn } from "src/components/common/dataGrid/columns.tsx";
import i18n from "i18next";
import { concatWithPipe } from "src/utils/strings.tsx";
import { getCatalogPartRevisionStateLabels } from "src/api/generated/erp/db/types/enums/catalogPartRevisionState.ts";
import { filterNulls } from "src/utils/arrayUtils.ts";
import { CrudDataGrid } from "src/components/common/dataGrid/crud/CrudDataGrid.tsx";

export interface RevisionsToConnectDataGridProps {
	catalogPartRevisions: CatalogPartRevisionView[];
	selectedRevision: CatalogPartRevisionView | undefined;
	onSelectionChanged: (selectedRevision: CatalogPartRevisionView | undefined) => void;
	removeRevision: (revision: CatalogPartRevisionView) => void;
}

export const RevisionsToConnectDataGrid = ({
	catalogPartRevisions,
	selectedRevision,
	onSelectionChanged,
	removeRevision,
}: RevisionsToConnectDataGridProps) => {
	const { dataGridProps } = useStaticDataGridModel({
		rows: catalogPartRevisions,
		getRowId: (row) => row.catalogPartRevisionId,
		gridId: "C00376DEED99C702",
		selectedRows: filterNulls([selectedRevision]),
		onSelectionChanged: (rows) => onSelectionChanged(rows[0]),
	});

	return (
		<CrudDataGrid<CatalogPartRevisionView>
			disableMultipleRowSelection
			refreshData={async () => catalogPartRevisions}
			columns={[
				textColumn({
					field: "part" as any,
					headerName: i18n.t("part"),
					valueGetter: (_, row) =>
						concatWithPipe(
							row.partNo,
							row.catalogPartDescription_1,
							row.catalogPartDescription_2,
						),
					width: 120,
				}),
				integerColumn({
					field: "revisionNumber",
					headerName: i18n.t("revision"),
					width: 80,
				}),
				enumColumn({
					field: "state",
					headerName: i18n.t("state"),
					enumLabels: getCatalogPartRevisionStateLabels(),
				}),
				integerColumn({
					field: "note",
					headerName: i18n.t("note"),
					width: 150,
				}),
				textColumn({
					field: "categoryName",
					headerName: i18n.t("category"),
					width: 100,
				}),
			]}
			remove={async ({ items }) => {
				for (const revision of items) {
					removeRevision(revision);
				}
			}}
			{...dataGridProps}
		/>
	);
};
