import { faBullhorn } from "@fortawesome/pro-regular-svg-icons";
import i18n from "i18next";
import { showErrorReportDialog } from "src/sentry/showErrorReportDialog.ts";
import { Button, SxProps, Typography } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export interface OpenErrorReportDialogButtonProps {
	eventId: string;
	onClick?: () => void;
	sx?: SxProps<Theme>;
}

export const OpenErrorReportDialogButton = ({
												eventId,
												onClick,
												sx
											}: OpenErrorReportDialogButtonProps) => {
	return (
		<Button
			sx={sx}
			startIcon={<FontAwesomeIcon icon={faBullhorn} />}
			variant={"outlined"}
			onClick={() => {
				showErrorReportDialog(eventId);
				onClick?.();
			}}
		>
			<Typography
				variant={"button"}
				sx={{
					flex: 1,
					textAlign: "left"
				}}
			>
				{i18n.t("error_report")}
			</Typography>
		</Button>
	);
};
