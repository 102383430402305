// This file is automatically generated. Do not edit manually.

import { t } from "i18next";

export const SubProjectStateValues = ["INITIAL", "RELEASED", "READY", "CANCELLED"] as const;

export type SubProjectState = (typeof SubProjectStateValues)[number];

export const getSubProjectStateLabels = () => ({
	INITIAL: t("initial"),
	RELEASED: t("released"),
	READY: t("ready"),
	CANCELLED: t("cancelled"),
});

export const getSubProjectStateLabel = (value: SubProjectState): string => {
	return getSubProjectStateLabels()[value];
};
