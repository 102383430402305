// This file is automatically generated. Do not edit manually.

import { ErpAppbarSelections } from "src/api/generated/erp/common/api/erpAppbarSelections";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace ErpAppbarSelectionsApi {
	export async function getCurrentSelections(): Promise<ErpAppbarSelections> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/common/getCurrentSelections",
			method: "POST",
			data: {},
		});
		return response as ErpAppbarSelections;
	}

	export async function setUserSite(args: { siteId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/common/setUserSite",
			method: "POST",
			data: {
				siteId: args.siteId,
			},
		});
		return response as void;
	}

	export async function setUserWorkcenter(args: { workcenterId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/common/setUserWorkcenter",
			method: "POST",
			data: {
				workcenterId: args.workcenterId,
			},
		});
		return response as void;
	}

	export async function setUserProductionLine(args: { productionLineId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/common/setUserProductionLine",
			method: "POST",
			data: {
				productionLineId: args.productionLineId,
			},
		});
		return response as void;
	}
}
