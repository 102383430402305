import {ClientSideDataGridModel} from "src/components/common/dataGrid/gridModel/ClientSideDataGridModel.tsx";
import {
    WarehousePickingObjectLocationReservationsDataGridApi,
    WarehousePickingObjectLocationReservationsDataGridApi_Row,
} from "src/api/generated/erp/warehouse/inventory/inventoryEvent/api/warehousePickingObjectLocationReservationsDataGridApi.ts";
import {CrudDataGrid} from "src/components/common/dataGrid/crud/CrudDataGrid.tsx";
import {enumColumn, floatColumn, textColumn} from "src/components/common/dataGrid/columns.tsx";
import i18n from "i18next";
import {getWarehousePickingObjectTypeLabels} from "src/api/generated/erp/db/types/enums/warehousePickingObjectType.ts";
import {OpenCustomerOrderButton} from "src/components/views/erp/utilComponents/OpenCustomerOrderButton.tsx";
import {ShopOrderBomApi} from "src/api/generated/erp/production/api/shopOrderBom/shopOrderBomApi.ts";
import {OpenProjectActivityButton} from "src/components/views/erp/utilComponents/OpenProjectActivityButton.tsx";
import {CustomerOrderLineApi} from "src/api/generated/erp/sales/customerOrder/api/customerOrderLineApi.ts";
import {OpenShopOrderButton} from "src/components/views/erp/utilComponents/OpenShopOrderButton.tsx";
import {
    ProjectActivityMaterialApi
} from "src/api/generated/erp/project/projectActivityMaterial/api/projectActivityMaterialApi.ts";
import {OpenWarehouseTransferButton} from "src/components/views/erp/utilComponents/OpenWarehouseTransferButton.tsx";

export interface WarehousePickingObjectLocationReservationsDataGridProps {
	partId: number;
	partWarehouseLocationId: number | null;
}

export const WarehousePickingObjectLocationReservationsDataGrid = ({
	partId,
	partWarehouseLocationId,
}: WarehousePickingObjectLocationReservationsDataGridProps) => {
	return (
		<ClientSideDataGridModel
			gridId={"5FA072BA0F1B7332B6"}
			fetchData={() =>
				WarehousePickingObjectLocationReservationsDataGridApi.getData({
					partId: partId,
					partWarehouseLocationId: partWarehouseLocationId,
				})
			}
			getRows={(data) => data}
			getRowId={(row) => row.warehousePickingObjectLocationId}
			initialParams={{}}
			render={({ dataGridProps }) => {
				return (
					<CrudDataGrid<WarehousePickingObjectLocationReservationsDataGridApi_Row>
						columns={[
							textColumn({
								field: "locationCode",
								headerName: i18n.t("warehouse_location"),
							}),
							enumColumn({
								field: "objectType",
								headerName: i18n.t("object_type"),
								enumLabels: getWarehousePickingObjectTypeLabels(),
								width: 150,
							}),
							textColumn({
								field: "objectDescription",
								headerName: i18n.t("source"),
								width: 300,
							}),
							floatColumn({
								field: "reservedQuantity",
								headerName: i18n.t("quantity"),
								afterColumn: "objectDescription",
							}),
							textColumn({
								field: "partUnit",
								headerName: i18n.t("unit"),
							}),
						]}
						rowContextMenuComponents={({ row }) => [
							row.objectType === "CUSTOMER_ORDER_LINE" && (
								<OpenCustomerOrderButton
									customerOrderId={async () => {
										const customerOrderLine = await CustomerOrderLineApi.getCustomerOrderLine({
											customerOrderLineId: row.objectId,
										});
										return customerOrderLine.customerOrderId;
									}}
								/>
							),
							row.objectType === "SHOP_ORDER_LINE" && (
								<OpenShopOrderButton
									shopOrderId={async () => {
										const shopOrderBomLine = await ShopOrderBomApi.getShopOrderBomLine({
											shopOrderLineId: row.objectId,
										});
										return shopOrderBomLine.shopOrderId;
									}}
								/>
							),
							row.objectType === "WAREHOUSE_TRANSFER" && (
								<OpenWarehouseTransferButton warehouseTransferId={row.objectId} />
							),
							row.objectType === "PROJECT_ACTIVITY_MATERIAL" && (
								<OpenProjectActivityButton
									projectActivityId={async () => {
										const projectActivityMaterial =
											await ProjectActivityMaterialApi.getProjectActivityMaterial({
												projectActivityMaterialId: row.objectId,
											});
										return projectActivityMaterial.activityId;
									}}
								/>
							),
						]}
						{...dataGridProps}
					/>
				);
			}}
		/>
	);
};
