import { getMainMenuItemLabel, MainMenuItem } from "src/components/views/drawer/mainMenuTree/mainMenuItem.ts";

export function filterMenuItems(menuItems: MainMenuItem[], filter: string): MainMenuItem[] {
	if (filter.trim() === "") {
		return menuItems;
	}

	const filterLower = filter.toLowerCase();
	return menuItems
		.map((item) => {
			return {
				...item,
				children: filterMenuItems(item.children, filter),
				expanded: item.type === "viewGroup", // Expand all view groups that have matching children
			};
		})
		.filter((item) => {
			if (item.isFavoritesGroupShortcutItem) {
				return true;
			}
			switch (item.type) {
				case "view":
					return getMainMenuItemLabel(item).toLowerCase().includes(filterLower);
				case "viewGroup":
					return item.children.length > 0;
			}
		});
}
