// This file is automatically generated. Do not edit manually.

import { PartRevisionLineView } from "src/api/generated/erp/db/types/tables/partRevisionLineView";
import { PartRevisionOperationView } from "src/api/generated/erp/db/types/tables/partRevisionOperationView";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace SitePartRevisionBomDataGridApi {
	export async function getPartRevisionBom(args: {
		partRevisionId: number;
		searchQuery?: string;
	}): Promise<SitePartRevisionBomDataGridApi_GridData> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/parts/sitePart/partRevisionBom/getPartRevisionBom",
			method: "POST",
			data: {
				partRevisionId: args.partRevisionId,
				searchQuery: args.searchQuery,
			},
		});
		return response as SitePartRevisionBomDataGridApi_GridData;
	}

	export async function update(args: {
		partRevisionBomLines: Array<PartRevisionLineView>;
	}): Promise<Array<PartRevisionLineView>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/parts/sitePart/partRevisionBom/update",
			method: "POST",
			data: {
				partRevisionBomLines: args.partRevisionBomLines,
			},
		});
		return response as Array<PartRevisionLineView>;
	}
}

export interface SitePartRevisionBomDataGridApi_GridData {
	partIsConfigurable: boolean;
	partRevisionBomLines: Array<PartRevisionLineView>;
	partRevisionOperationOptions: Array<PartRevisionOperationView>;
}
