// This file is automatically generated. Do not edit manually.

import { ChartGroupView } from "src/api/generated/postgres/db/types/public_/tables/chartGroupView";
import { ControlChartParentGroup } from "src/api/generated/postgres/db/types/public_/tables/controlChartParentGroup";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace ControlChartGroupTreeViewApi {
	export async function getTreeData(): Promise<ControlChartGroupTreeViewApi_TreeData> {
		const response = await makeAxiosRequest({
			url: "/api/ext/spc/controlChartGroupTreeView/getTreeData",
			method: "POST",
			data: {},
		});
		return response as ControlChartGroupTreeViewApi_TreeData;
	}
}

export interface ControlChartGroupTreeViewApi_TreeData {
	groups: Array<ChartGroupView>;
	parentGroups: Array<ControlChartParentGroup>;
}
