import { SubProjectView } from "src/api/generated/erp/db/types/tables/subProjectView.ts";
import i18n from "i18next";
import { DocumentsOfObjectButton } from "src/components/views/documents/objectDocuments/DocumentsOfObjectButton.tsx";
import { AsyncMenuButton } from "src/components/common/contextMenu/AsyncMenuButton.tsx";
import {
	faBan,
	faCalendar,
	faChartGantt,
	faCheck,
	faCopy,
	faKey,
	faMoneyBill,
	faPlus,
	faProjectDiagram,
	faShare,
	faTasks,
	faUndo,
} from "@fortawesome/pro-regular-svg-icons";
import { SubProjectGanttView } from "src/components/views/erp/project/gantt/SubProjectGanttView.tsx";
import { useGenericDialog } from "src/components/common/dialogs/useGenericDialog.ts";
import { OpenObjectChangeLogButton } from "src/components/views/changeLogging/OpenObjectChangeLogButton.tsx";
import { SubProjectCostEventsDataGrid } from "./SubProjectCostEventsDataGrid";
import { useTenantCustomizations } from "src/tenantCustomizations/useTenantCustomizations.tsx";
import { FormCheckbox } from "src/components/common/forms/fields/FormCheckbox.tsx";
import { SubProjectActionsApi } from "src/api/generated/erp/project/subProject/api/subProjectActionsApi.ts";
import { useConfirmDialog } from "src/components/common/dialogs/confirmDialog/useConfirmDialog.ts";
import {
	openLegacySourceSetTasksView,
	openLegacyTaskWeekPlanningView,
} from "src/components/views/legacy/legacyViewAdapters.ts";
import { useOpenLegacyView } from "src/components/views/legacy/useOpenLegacyView.ts";
import { useFormInput } from "src/components/common/dialogs/formInput/useFormInput.tsx";
import { IsoDateString } from "src/types/dateTime.ts";
import { requireRule } from "src/components/common/forms/validation.ts";
import { FormDateField } from "src/components/common/forms/fields/FormDateField.tsx";
import { FormAsyncSelectField } from "src/components/common/forms/fields/FormAsyncSelectField.tsx";
import { ProjectQueryApi } from "src/api/generated/erp/project/project/api/projectQueryApi.ts";
import { Project } from "src/api/generated/erp/db/types/tables/project.ts";
import { CreateNewProjectActivityForm } from "src/components/views/erp/project/projectActivity/CreateNewProjectActivityForm.tsx";
import { openFormOnDialog } from "src/components/common/dialogs/formDialog/openFormOnDialog.ts";
import { ProjectActivitiesContainerView } from "src/components/views/erp/project/projectActivity/ProjectActivitiesContainerView.tsx";

export interface SubProjectContextMenuProps {
	subProject: SubProjectView;
	refreshData?: () => Promise<unknown>;
}

export const SubProjectContextMenu = (props: SubProjectContextMenuProps) => {
	const { subProject, refreshData } = props;
	const tenantCustomizations = useTenantCustomizations();
	const { openDialog } = useGenericDialog();
	const showConfirmDialog = useConfirmDialog();
	const showFormInput = useFormInput();
	const openLegacyView = useOpenLegacyView();

	const subProjectId = subProject.subProjectId;

	return [
		subProject.subProjectState === "INITIAL" && (
			<AsyncMenuButton
				key={"release"}
				label={i18n.t("release")}
				icon={faShare}
				onClick={onReleaseClick}
			/>
		),
		subProject.subProjectState === "INITIAL" && (
			<AsyncMenuButton
				key={"start"}
				label={i18n.t("start.sub_project")}
				icon={faKey}
				onClick={async () => {
					const confirmed = await showConfirmDialog({
						message: i18n.t("confirm_start_sub_project"),
					});
					if (!confirmed) return;
					await SubProjectActionsApi.startSubProject({ subProjectId });
					await refreshData?.();
				}}
			/>
		),
		subProject.subProjectState === "RELEASED" && (
			<AsyncMenuButton
				key={"revertRelease"}
				label={i18n.t("revert_release")}
				icon={faUndo}
				onClick={async () => {
					const confirmed = await showConfirmDialog({
						message: i18n.t("confirm_revert_release_sub_project"),
					});
					if (!confirmed) return;
					await SubProjectActionsApi.revertReleaseSubProject({ subProjectId });
					await refreshData?.();
				}}
			/>
		),
		subProject.subProjectState === "RELEASED" && (
			<AsyncMenuButton
				key={"complete"}
				label={i18n.t("mark_as_completed")}
				icon={faCheck}
				onClick={async () => {
					const confirmed = await showConfirmDialog({
						message: i18n.t("confirm_complete_sub_project"),
					});
					if (!confirmed) return;
					await SubProjectActionsApi.completeSubProject({ subProjectId });
					await refreshData?.();
				}}
			/>
		),
		subProject.subProjectState === "READY" && (
			<AsyncMenuButton
				key={"revertComplete"}
				label={i18n.t("revert_complete")}
				icon={faUndo}
				onClick={async () => {
					const confirmed = await showConfirmDialog({
						message: i18n.t("confirm_revert_complete_sub_project"),
					});
					if (!confirmed) return;
					await SubProjectActionsApi.revertCompleteSubProject({ subProjectId });
					await refreshData?.();
				}}
			/>
		),
		["INITIAL", "RELEASED"].includes(subProject.subProjectState) && (
			<AsyncMenuButton
				key={"cancel"}
				label={i18n.t("cancel")}
				icon={faBan}
				onClick={async () => {
					const confirmed = await showConfirmDialog({
						message: i18n.t("confirm_cancel_sub_project"),
					});
					if (!confirmed) return;
					await SubProjectActionsApi.cancelSubProject({ subProjectId });
					await refreshData?.();
				}}
			/>
		),
		["INITIAL", "RELEASED"].includes(subProject.subProjectState) && (
			<AsyncMenuButton
				key={"addActivity"}
				label={i18n.t("add_activity")}
				icon={faPlus}
				onClick={async () => {
					openFormOnDialog({
						openDialog,
						title: i18n.t("new_activity"),
						size: "sm",
						component: CreateNewProjectActivityForm,
						props: (closeDialog) => ({
							subProjectId: subProjectId,
							closeDialog: closeDialog,
						}),
						confirmCloseIfEdited: false,
						onSubmit: refreshData,
					});
				}}
			/>
		),
		<AsyncMenuButton
			key={"clone"}
			label={i18n.t("create_a_copy")}
			icon={faCopy}
			onClick={onCloneSubProjectClick}
		/>,
		tenantCustomizations?.erp?.project?.subProjectContextMenuComponents?.(props),
		<DocumentsOfObjectButton
			key={"documents"}
			variant={"menu"}
			label={i18n.t("documents")}
			objectRef={{ objectType: "SUB_PROJECT", objectId: subProject.subProjectId }}
		/>,
		<AsyncMenuButton
			key={"gantt"}
			label={i18n.t("gantt")}
			icon={faChartGantt}
			onClick={() => {
				openDialog({
					title: i18n.t("gantt"),
					content: <SubProjectGanttView subProjectIds={[subProject.subProjectId]} />,
				});
			}}
		/>,
		<AsyncMenuButton
			key={"activities"}
			label={i18n.t("activities")}
			icon={faProjectDiagram}
			onClick={() => {
				openDialog({
					title: i18n.t("activities"),
					content: <ProjectActivitiesContainerView subProjectId={subProjectId} />,
				});
			}}
		/>,
		<AsyncMenuButton
			key={"tasks"}
			label={i18n.t("tasks")}
			icon={faTasks}
			onClick={() => {
				openLegacySourceSetTasksView({
					openLegacyView,
					sourceSetType: "SubProject",
					sourceSetId: subProjectId,
				});
			}}
		/>,
		<AsyncMenuButton
			key={"weekPlanning"}
			label={i18n.t("week_planning")}
			icon={faCalendar}
			onClick={() => {
				openLegacyTaskWeekPlanningView({
					openLegacyView,
					sourceSetType: "SubProject",
					sourceSetId: subProjectId,
				});
			}}
		/>,
		subProject.costEventPriceListId != null && (
			<AsyncMenuButton
				key={"costEvents"}
				label={i18n.t("cost_events")}
				icon={faMoneyBill}
				onClick={() => {
					openDialog({
						title: i18n.t("cost_events"),
						size: "xl",
						content: (
							<SubProjectCostEventsDataGrid
								subProjectId={subProject.subProjectId}
								costEventPriceListId={subProject.costEventPriceListId!}
							/>
						),
					});
				}}
			/>
		),
		<OpenObjectChangeLogButton
			key={"changeLog"}
			objectRef={{ objectType: "SUB_PROJECT", objectId: subProject.subProjectId }}
		/>,
	];

	async function onReleaseClick() {
		interface FormInput {
			releaseActivities: boolean;
			releaseTasks: boolean;
		}

		const input = await showFormInput<FormInput>({
			title: i18n.t("release_sub_project"),
			defaultValues: {
				releaseActivities: false,
				releaseTasks: false,
			},
			submitLabel: i18n.t("release"),
			content: ({ control, watch }) => (
				<>
					<FormCheckbox
						control={control}
						name={"releaseActivities"}
						label={i18n.t("release_also_activities")}
					/>
					{watch("releaseActivities") && (
						<FormCheckbox
							control={control}
							name={"releaseTasks"}
							label={i18n.t("release_also_tasks")}
						/>
					)}
				</>
			),
		});
		if (!input) return;

		await SubProjectActionsApi.releaseSubProject({
			subProjectId: subProjectId,
			releaseProjectActivities: input.releaseActivities,
			releaseTasks: input.releaseTasks,
		});
		await refreshData?.();
	}

	async function onCloneSubProjectClick() {
		const formInput = await showFormInput<{ targetProjectId: number; plannedStartDate: IsoDateString }>({
			title: i18n.t("clone_sub_project"),
			submitLabel: i18n.t("clone"),
			content: ({ control }) => (
				<>
					<FormAsyncSelectField
						control={control}
						name={"targetProjectId"}
						label={i18n.t("copy_to_project")}
						fetchOptions={({ currentSelection, searchQuery }) =>
							ProjectQueryApi.getProjectSelectionOptions({
								excludeClosedProjects: true,
								includeTemplates: false,
								searchQuery,
								currentSelection,
							})
						}
						getOptionKey={(o: Project) => o.projectId}
						getOptionLabel={(o) => o.projectDescription}
						rules={requireRule()}
					/>
					<FormDateField
						control={control}
						name={"plannedStartDate"}
						label={i18n.t("planned_start")}
						rules={requireRule()}
					/>
				</>
			),
		});
		if (!formInput) return;

		await SubProjectActionsApi.cloneSubProject({
			subProjectId: subProjectId,
			targetProjectId: formInput.targetProjectId,
			newPlannedStartDate: formInput.plannedStartDate,
		});
		await refreshData?.();
	}
};
