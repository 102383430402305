import i18n from "i18next";
import { LabelValueView } from "src/components/common/misc/LabelValueView.tsx";
import { formatIsoString } from "src/utils/dayjsUtils.ts";
import { concatWithPipe } from "src/utils/strings.tsx";
import { JobQueueShopOrderOperation } from "src/components/views/erp/production/jobQueue/base/JobQueueShopOrderOperation.ts";
import Box from "@mui/material/Box";

export interface SelectedOperationInfoViewProps {
	selectedOperation: JobQueueShopOrderOperation | undefined;
}

export const SelectedOperationInfoView = ({ selectedOperation }: SelectedOperationInfoViewProps) => {
	if (selectedOperation == null) return <Box flex={1} />;

	return (
		<LabelValueView
			sx={{
				flex: 1,
			}}
			items={[
				{
					label: i18n.t("shop_order"),
					value: selectedOperation.shopOrderId,
				},
				{
					label: i18n.t("planned_start_date_short"),
					value: formatIsoString(selectedOperation.shopOrderPlannedBeginDate, "L"),
				},
				{
					label: i18n.t("operation"),
					value: `${selectedOperation.operationNo} ${selectedOperation.operationDescription}`,
				},
				{
					label: i18n.t("reference"),
					value: selectedOperation.sourceRefData,
				},
				{
					label: i18n.t("part") + ", " + i18n.t("revision_abbr"),
					value: concatWithPipe(
						selectedOperation.configurationOrPartDescription,
						selectedOperation.partRevision,
					),
				},
				{
					label: i18n.t("quantity"),
					value: `${selectedOperation.quantity} ${selectedOperation.partUnit}`,
				},
				{
					label: i18n.t("package_info"),
					value: selectedOperation.packageInfo,
				},
				{
					label: i18n.t("shop_order_batch"),
					value: selectedOperation.shopOrderBatchName,
				},
				{
					label: i18n.t("production_line"),
					value: selectedOperation.shopOrderProductionLineName,
				},
				{
					label: i18n.t("note"),
					value: selectedOperation.shopOrderNote,
					multiline: true,
				},
			]}
		/>
	);
};
