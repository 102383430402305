import { PartialTenantCustomizations } from "src/tenantCustomizations/TenantCustomizations.ts";
import { getCompleteProductTroughputSampling } from "src/tenantCustomizations/tenants/lumon/spcSamplingMethods/completeProductTroughputSampling.tsx";
import { getOperationThroughputSampling } from "src/tenantCustomizations/tenants/lumon/spcSamplingMethods/operationThroughputSampling.tsx";
import { getWipBetweenMachinesSampling } from "src/tenantCustomizations/tenants/lumon/spcSamplingMethods/wipBetweenMachinesSampling.tsx";

export const lumonCustomizations: PartialTenantCustomizations = {
	tenantConfig: {
		spc: {
			processImprovementsEnabled: true,
		},
		tasks: {
			enabled: true,
		},
		documentsEnabled: true,
	},
	spcSamplingMethods: () => [
		getCompleteProductTroughputSampling(),
		getOperationThroughputSampling(),
		getWipBetweenMachinesSampling(),
	],
} as const;
