import { AsyncForm, AsyncFormContentParams } from "src/components/common/forms/AsyncForm.tsx";
import {
	ShopOrderLineFormApi,
	ShopOrderLineFormApi_InitData,
} from "src/api/generated/erp/production/api/shopOrderBom/shopOrderLineFormApi.ts";
import { FormCommonProps } from "src/components/common/forms/types.ts";
import { ShopOrderLine } from "src/api/generated/erp/db/types/tables/shopOrderLine.ts";
import { FormNumberField } from "src/components/common/forms/fields/FormNumberField.tsx";
import { requireRule } from "src/components/common/forms/validation.ts";
import { FormAsyncSelectField } from "src/components/common/forms/fields/FormAsyncSelectField.tsx";
import { SitePartQueryApi } from "src/api/generated/erp/parts/sitePart/api/sitePartQueryApi.ts";
import { FormSelectField } from "src/components/common/forms/fields/FormSelectField.tsx";
import i18n from "i18next";
import { concatWithPipe } from "src/utils/strings.tsx";
import { getPartRevisionLineAcquisitionMethodLabels } from "src/api/generated/erp/db/types/enums/partRevisionLineAcquisitionMethod.ts";
import { FormEnumSelectField } from "src/components/common/forms/fields/FormEnumSelectField.tsx";
import { FormTextField } from "src/components/common/forms/fields/FormTextField.tsx";
import { PartView } from "src/api/generated/erp/db/types/tables/partView.ts";
import { FetchAsyncOptionParams } from "src/components/common/inputFields/AsyncSelectField.tsx";

export interface ShopOrderLineFormProps extends FormCommonProps<number> {
	shopOrderLineId: number | null;
	shopOrderId: number;
}

interface FormValues extends ShopOrderLine {
	part: PartView;
}

export const ShopOrderLineForm = ({
	shopOrderLineId,
	shopOrderId,
	onCompleted,
	onFormEdited,
}: ShopOrderLineFormProps) => {
	return (
		<AsyncForm<ShopOrderLineFormApi_InitData, FormValues, number>
			columns={2}
			fetch={async () =>
				ShopOrderLineFormApi.getInitData({
					shopOrderId: shopOrderId,
					shopOrderLineId: shopOrderLineId,
				})
			}
			getDefaultValues={(data) => {
				return data.shopOrderLine != null && data.part != null ?
						{
							part: data.part,
							...data.shopOrderLine,
						}
					:	{
							referenceNo: data.defaultReferenceNo,
						};
			}}
			onCompleted={onCompleted}
			onFormEdited={onFormEdited}
			submit={submitForm}
			render={(params) => (
				<FormContent
					shopOrderLineId={shopOrderLineId}
					shopOrderId={shopOrderId}
					onCompleted={onCompleted}
					{...params}
				/>
			)}
		/>
	);

	async function submitForm(values: FormValues) {
		if (shopOrderLineId == null) {
			return await ShopOrderLineFormApi.insert({
				shopOrderLine: values,
				shopOrderId: shopOrderId,
				partId: values.part.partId,
			});
		} else {
			return await ShopOrderLineFormApi.update({ shopOrderLine: values, partId: values.part.partId });
		}
	}
};

interface FormContentProps
	extends ShopOrderLineFormProps,
		AsyncFormContentParams<ShopOrderLineFormApi_InitData, FormValues> {}

const FormContent = ({ data, control, setValue }: FormContentProps) => {
	return (
		<>
			<FormNumberField
				control={control}
				name={"referenceNo"}
				label={i18n.t("reference_no")}
				rules={requireRule()}
			/>
			<FormAsyncSelectField
				control={control}
				name={"part"}
				label={i18n.t("part")}
				fetchOptions={async ({ searchQuery, currentSelection }: FetchAsyncOptionParams<number>) =>
					await SitePartQueryApi.getPartSelectionOptions({
						searchQuery: searchQuery,
						currentPartId: currentSelection,
						siteId: data.siteId,
					})
				}
				formValueType={"option"}
				getOptionKey={(option) => option.partId}
				getOptionLabel={(option) => option.partLongName}
				rules={requireRule()}
				onChange={(part: PartView | null) => {
					setValue(
						"acquisitionMethod",
						part == null ? null
						: part.acquisitionMethod === "PURCHASE_DIRECT" ? null
						: part.acquisitionMethod,
					);
				}}
			/>
			<FormNumberField
				name={"quantity"}
				label={i18n.t("quantity")}
				control={control}
				rules={requireRule()}
			/>
			<FormSelectField
				control={control}
				name={"operationId"}
				label={i18n.t("operation")}
				options={data.shopOrderOperationOptions}
				getOptionKey={(o) => o.shopOrderOperationId}
				getOptionLabel={(o) => concatWithPipe(o.operationNo, o.operationDescription)}
			/>
			<FormEnumSelectField
				control={control}
				name={"acquisitionMethod"}
				label={i18n.t("acquisition_method")}
				options={getPartRevisionLineAcquisitionMethodLabels()}
				rules={requireRule()}
			/>
			<FormTextField control={control} name={"note"} label={i18n.t("note")} />
		</>
	);
};
