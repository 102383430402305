import { ClientSideDataGridModel } from "src/components/common/dataGrid/gridModel/ClientSideDataGridModel.tsx";
import { CatalogPartReferencesDataGridApi } from "src/api/generated/erp/parts/catalogPart/api/catalogPartReferencesDataGridApi.ts";
import { CrudDataGrid } from "src/components/common/dataGrid/crud/CrudDataGrid.tsx";
import { enumColumn, integerColumn, textColumn } from "src/components/common/dataGrid/columns.tsx";
import i18n from "i18next";
import { getPartRevisionStateLabels } from "src/api/generated/erp/db/types/enums/partRevisionState.ts";
import { AavoButton } from "src/components/common/buttons/AavoButton.tsx";
import { faSitemap } from "@fortawesome/pro-regular-svg-icons";
import { useOpenLegacyView } from "src/components/views/legacy/useOpenLegacyView.ts";
import { concatWithPipe } from "src/utils/strings.tsx";
import { CatalogPartRevisionLineView } from "src/api/generated/erp/db/types/tables/catalogPartRevisionLineView.ts";

export interface CatalogPartReferencesDataGridProps {
	catalogPartId: number;
}

export const CatalogPartReferencesDataGrid = ({ catalogPartId }: CatalogPartReferencesDataGridProps) => {
	const openLegacyView = useOpenLegacyView();
	return (
		<ClientSideDataGridModel
			gridId={"B6D36825CD2088A6"}
			fetchData={() =>
				CatalogPartReferencesDataGridApi.getCatalogPartReferences({ catalogPartId: catalogPartId })
			}
			initialParams={{}}
			getRows={(data) => data}
			getRowId={(row) => row.catalogPartRevisionLineId}
			render={({ dataGridProps, onlySelectedRow }) => {
				return (
					<CrudDataGrid<CatalogPartRevisionLineView>
						columns={[
							textColumn({
								field: "parentPartNo",
								headerName: i18n.t("part"),
							}),
							textColumn({
								field: "parentPartDescription_1",
								headerName: i18n.t("description_1"),
								width: 200,
							}),
							textColumn({
								field: "parentPartDescription_2",
								headerName: i18n.t("description_2"),
								width: 200,
							}),
							integerColumn({
								field: "parentRevisionNumber",
								headerName: i18n.t("revision"),
							}),
							enumColumn({
								field: "parentRevisionState",
								headerName: i18n.t("revision_state"),
								enumLabels: getPartRevisionStateLabels(),
							}),
							integerColumn({
								field: "referenceNo",
								headerName: i18n.t("reference_no"),
							}),
						]}
						actionBarComponents={
							<>
								<AavoButton
									icon={faSitemap}
									label={i18n.t("product_structure")}
									variant={"outlined"}
									disabled={onlySelectedRow == null}
									onClick={() => {
										if (onlySelectedRow == null) return;
										const viewName = concatWithPipe(
											onlySelectedRow.parentPartNo,
											onlySelectedRow.parentPartDescription_1,
											onlySelectedRow.parentRevisionNumber,
										);
										if (onlySelectedRow.linePartIsConfigurable) {
											openLegacyView("764EE7EEDA473ECE", {
												catalogPartRevisionId: onlySelectedRow.catalogPartRevisionId,
												_dynamicViewName: viewName,
												__FORM_VIEW_EDIT_MODE__: "__READONLY__",
											});
										} else {
											openLegacyView("E25D7108B88103F6", {
												catalogPartRevisionId: onlySelectedRow.catalogPartRevisionId,
												_dynamicViewName: viewName,
											});
										}
									}}
								/>
							</>
						}
						{...dataGridProps}
					/>
				);
			}}
		/>
	);
};
