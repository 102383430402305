import {AavoToastDispatchContext} from "../components/common/toast/context";

import { useContextOrThrow } from "src/utils/useContextOrThrow.tsx";

export const useAavoToast = () => {
	const dispatch = useContextOrThrow(AavoToastDispatchContext);
	return (title?: string, message?: string): void => {
		dispatch({
			type: "displayContent",
			payload: {
				title: title,
				message: message,
			},
		});
	};
};
