// This file is automatically generated. Do not edit manually.

import { SalesContractSalesPartView } from "src/api/generated/erp/db/types/tables/salesContractSalesPartView";
import { Site } from "src/api/generated/erp/db/types/tables/site";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace SalesContractSalesPartsDataGridApi {
	export async function getSalesContractSalesParts(args: {
		salesContractId: number;
		siteId: number | null | undefined;
		searchQuery: string;
	}): Promise<SalesContractSalesPartsDataGridApi_GridData> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/pricing/salesContractSalesParts/getSalesContractSalesParts",
			method: "POST",
			data: {
				salesContractId: args.salesContractId,
				siteId: args.siteId,
				searchQuery: args.searchQuery,
			},
		});
		return response as SalesContractSalesPartsDataGridApi_GridData;
	}

	export async function update(args: { salesContractSalesPartId: number; price: number }): Promise<number> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/pricing/salesContractSalesParts/update",
			method: "POST",
			data: {
				salesContractSalesPartId: args.salesContractSalesPartId,
				price: args.price,
			},
		});
		return response as number;
	}

	export async function delete_(args: { salesContractSalesPartId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/pricing/salesContractSalesParts/delete",
			method: "POST",
			data: {
				salesContractSalesPartId: args.salesContractSalesPartId,
			},
		});
		return response as void;
	}
}

export interface SalesContractSalesPartsDataGridApi_GridData {
	salesParts: Array<SalesContractSalesPartView>;
	siteOptions: Array<Site>;
}
