import { ClientSideDataGridModel } from "../../../../common/dataGrid/gridModel/ClientSideDataGridModel.tsx";
import {
	dateColumn,
	dateTimeColumn,
	integerColumn,
	textColumn,
} from "../../../../common/dataGrid/columns.tsx";
import i18n from "i18next";
import { AsyncUserSelectField } from "../../../users/AsyncUserSelectField.tsx";
import { CustomerOrderSalesConditionApi } from "src/api/generated/erp/sales/customerOrder/api/customerOrderSalesConditionApi.ts";
import { CustomerOrderSalesConditionView } from "src/api/generated/erp/db/types/tables/customerOrderSalesConditionView.ts";
import { AsyncButton } from "src/components/common/buttons/AsyncButton.tsx";
import { faCheck, faUndo } from "@fortawesome/pro-regular-svg-icons";
import { CustomerOrderSalesConditionForm } from "src/components/views/erp/sales/customerOrder/CustomerOrderSalesConditionForm.tsx";
import { CrudDataGrid } from "src/components/common/dataGrid/crud/CrudDataGrid.tsx";
import { OpenCustomerOrderButton } from "src/components/views/erp/utilComponents/OpenCustomerOrderButton.tsx";
import {genericNullableValue} from "src/utils/genericNullableValue.ts";

export interface CustomerOrderSalesConditionDataGridProps {
	customerOrderId: number | null;
	onlyIncomplete: boolean;
	editable: boolean;
}

export const CustomerOrderSalesConditionDataGrid = ({
	customerOrderId,
	onlyIncomplete,
	editable,
}: CustomerOrderSalesConditionDataGridProps) => {
	return (
		<ClientSideDataGridModel
			gridId={"893211C1CC5870B2"}
			fetchData={(params) =>
				CustomerOrderSalesConditionApi.getCustomerOrderSalesConditions({
					customerOrderId: customerOrderId,
					responsiblePersonId: params.responsiblePersonId,
					onlyIncomplete: onlyIncomplete,
				})
			}
			getRows={(data) => data}
			getRowId={(row) => row.customerOrderSalesConditionId}
			initialParams={{
				responsiblePersonId: genericNullableValue<number | null>(null),
			}}
			render={({ onlySelectedRow, refreshData, dataGridProps }) => {
				return (
					<CrudDataGrid<CustomerOrderSalesConditionView>
						columns={[
							textColumn({
								field: "salesConditionName",
								headerName: i18n.t("condition"),
								width: 300,
							}),
							dateColumn({
								field: "validUntil",
								headerName: i18n.t("valid_until"),
							}),
							textColumn({
								field: "responsiblePersonName",
								headerName: i18n.t("responsible_person"),
								width: 150,
							}),
							textColumn({
								field: "note",
								headerName: i18n.t("note"),
								width: 300,
							}),
							customerOrderId == null && [
								integerColumn({
									field: "customerOrderId",
									headerName: i18n.t("order_number"),
								}),
								textColumn({
									field: "customerName",
									headerName: i18n.t("customer"),
								}),
								textColumn({
									field: "orderReference",
									headerName: i18n.t("reference"),
								}),
							],
							dateTimeColumn({
								field: "completedAt",
								headerName: i18n.t("acknowledged"),
								width: 150,
							}),
							textColumn({
								field: "completedByUserName",
								headerName: i18n.t("acknowledged_by"),
							}),
						]}
						actionBarComponents={
							<>
								<AsyncUserSelectField
									label={i18n.t("responsible_person")}
									onChange={async (value) =>
										await refreshData({ responsiblePersonId: value?.id })
									}
								/>
								{onlySelectedRow?.completedAt == null && (
									<AsyncButton
										icon={faCheck}
										label={i18n.t("acknowledge_to_completed")}
										onClick={async () => {
											if (onlySelectedRow == null) return;
											await CustomerOrderSalesConditionApi.completeCustomerOrderSalesCondition(
												{
													conditionId:
														onlySelectedRow.customerOrderSalesConditionId,
												},
											);
											await refreshData();
										}}
										variant={"outlined"}
									/>
								)}
								{onlySelectedRow?.completedAt != null && (
									<AsyncButton
										icon={faUndo}
										label={i18n.t("revert_acknowledge")}
										variant={"outlined"}
										onClick={async () => {
											if (onlySelectedRow == null) return;
											await CustomerOrderSalesConditionApi.revertCompleteCustomerOrderSalesCondition(
												{
													conditionId:
														onlySelectedRow.customerOrderSalesConditionId,
												},
											);
											await refreshData();
										}}
									/>
								)}
							</>
						}
						rowContextMenuComponents={({ row }) => [
							customerOrderId == null && (
								<OpenCustomerOrderButton
									customerOrderId={row.customerOrderId}
								/>
							),
						]}
						form={
							customerOrderId == null ? undefined : (
								{
									editEnabled: editable,
									addRowEnabled: editable,
									dialogSize: "sm",
									dialogTitle: i18n.t("customer_order_condition"),
									component: ({ row, ...other }) => (
										<CustomerOrderSalesConditionForm
											customerOrderId={customerOrderId}
											customerOrderSalesConditionId={
												row?.customerOrderSalesConditionId
											}
											{...other}
										/>
									),
								}
							)
						}
						remove={
							!editable ? undefined : (
								{
									type: "enabled",
									action: async (params) =>
										await CustomerOrderSalesConditionApi.delete_({
											customerOrderSalesConditions: params.items,
										}),
								}
							)
						}
						{...dataGridProps}
					/>
				);
			}}
		/>
	);
};
