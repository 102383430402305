import {useMemo, useState} from "react";
import {AavoDataGrid} from "src/components/common/dataGrid/AavoDataGrid.tsx";
import {ConfigurationComponentView} from "src/api/generated/erp/db/types/tables/configurationComponentView.ts";
import {textColumn} from "src/components/common/dataGrid/columns.tsx";
import i18n from "i18next";
import {dataGridPersistentStateProps} from "src/components/common/dataGrid/dataGridStateStorage.ts";
import {AavoMosaic} from "src/components/common/mosaic/AavoMosaic.tsx";
import {AavoTextField} from "src/components/common/inputFields/AavoTextField.tsx";
import {
	useCatalogPartRevisionConfiguratorManagingViewContext
} from "src/components/views/erp/configurator/managing/catalogPart/configuratorSelections/catalogPartRevisionConfiguratorSelectionsViewContext.ts";
import {formatConfigurationComponentType} from "src/components/views/erp/configurator/configuratorUtils.ts";

export const ComponentSelectionView = () => {
	const { tabComponents, setComponentSelectionsOnTab } =
		useCatalogPartRevisionConfiguratorManagingViewContext();

	return (
		<AavoMosaic
			layout={{
				type: "tabs",
				items: tabComponents.map(({ tabComponent, childComponents, selectedChildComponentsIds }) => ({
					type: "panel",
					key: tabComponent.configurationComponentId.toString(),
					title: tabComponent.label,
					content: (
						<SingleTabComponents
							childComponents={childComponents}
							selectedRowIds={selectedChildComponentsIds}
							setSelectedRowIds={(selectedComponentIds) => {
								setComponentSelectionsOnTab(
									tabComponent.configurationComponentId,
									selectedComponentIds,
								);
							}}
						/>
					),
				})),
			}}
		/>
	);
};

interface SingleTabComponentsProps {
	childComponents: ConfigurationComponentView[];
	selectedRowIds: number[];
	setSelectedRowIds: (selectedComponentIds: number[]) => void;
}

const SingleTabComponents = ({
	childComponents,
	selectedRowIds,
	setSelectedRowIds,
}: SingleTabComponentsProps) => {
	const [filterQuery, setFilterQuery] = useState("");

	const filteredComponents = useMemo(() => {
		if (filterQuery === "") return childComponents;
		return childComponents.filter((c) => c.label.toLowerCase().includes(filterQuery.toLowerCase()));
	}, [childComponents, filterQuery]);

	return (
		<AavoDataGrid<ConfigurationComponentView>
			rows={filteredComponents}
			getRowId={(row) => row.configurationComponentId}
			checkboxSelection
			selectedRows={selectedRowIds}
			onRowSelectionChanged={(rowIds) => setSelectedRowIds(rowIds.map((id) => parseInt(id.toString())))}
			columns={[
				textColumn({
					headerName: i18n.t("component"),
					field: "label",
					width: 200,
				}),
				textColumn({
					headerName: i18n.t("type"),
					field: "type" as any,
					width: 300,
					valueGetter: (_, row) => formatConfigurationComponentType(row)
				}),
				textColumn({
					headerName: i18n.t("internal_name"),
					field: "name",
					width: 200,
				}),
			]}
			actionBarComponents={
				<>
					<AavoTextField label={i18n.t("search")} onSubmit={setFilterQuery} />
				</>
			}
			{...dataGridPersistentStateProps("AE77BADB0A813191")}
		/>
	);
};
