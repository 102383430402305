import { faBullhorn } from "@fortawesome/pro-regular-svg-icons";
import i18n from "i18next";
import * as Sentry from "@sentry/react";
import { memo } from "react";
import { useTheme } from "@mui/material";
import { AsyncButton } from "src/components/common/buttons/AsyncButton.tsx";

export const SentryFeedbackButton = memo(() => {
	const { palette } = useTheme();
	return (
		<AsyncButton
			icon={faBullhorn}
			variant={"contained"}
			tooltip={i18n.t("send_feedback")}
			onClick={async () => {
				const feedback = Sentry.feedbackIntegration({
					autoInject: false,
					colorScheme: "light",
					showBranding: false,
					cancelButtonLabel: i18n.t("cancel"),
					submitButtonLabel: i18n.t("send"),
					formTitle: i18n.t("send_feedback_form_title"),
					emailLabel: i18n.t("email"),
					emailPlaceholder: "",
					messageLabel: i18n.t("message"),
					messagePlaceholder: i18n.t("feedback_message_placeholder"),
					nameLabel: i18n.t("name"),
					namePlaceholder: "",
					successMessageText: i18n.t("feedback_sent_success"),
					enableScreenshot: true,
					addScreenshotButtonLabel: i18n.t("add_screenshot"),
					removeScreenshotButtonLabel: i18n.t("remove_screenshot"),
					themeLight: {
						submitBackground: palette.primary.main,
						submitBorder: palette.primary.main,
					},
				});
				const form = await feedback.createForm({});
				form.appendToDom();
				form.open();
			}}
		/>
	);
});
