import { CatalogPartDataGridApi } from "src/api/generated/erp/parts/catalogPart/api/catalogPartDataGridApi.ts";
import { useServerSideDataGridModel } from "src/components/common/dataGrid/gridModel/useServerSideDataGridModel";
import { ControlledAsyncCrudDataGrid } from "src/components/common/dataGrid/crud/ControlledAsyncCrudDataGrid.tsx";
import { textColumn } from "src/components/common/dataGrid/columns.tsx";
import i18n from "i18next";
import { AavoTextField } from "src/components/common/inputFields/AavoTextField.tsx";
import { PartCategoryApi } from "src/api/generated/erp/parts/categories/api/partCategoryApi.ts";
import { CatalogPartView } from "src/api/generated/erp/db/types/tables/catalogPartView.ts";
import { LazySelectField } from "src/components/common/inputFields/LazySelectField.tsx";
import { genericNullableValue } from "src/utils/genericNullableValue.ts";

export interface CatalogPartSelectionDataGridProps {
	onSelectionChanged: (catalogParts: CatalogPartView[]) => void;
}

export const CatalogPartSelectionDataGrid = ({ onSelectionChanged }: CatalogPartSelectionDataGridProps) => {
	const { dataGridProps, refreshData } = useServerSideDataGridModel({
		fetchData: (params) =>
			CatalogPartDataGridApi.searchCatalogParts({
				...params,
			}),
		initialParams: {
			searchQuery: "",
			partCategoryId: genericNullableValue<number>(null),
		},
		getRowId: (row) => row.catalogPartId,
		gridId: "3EFF00D10CE83A86D",
		onSelectionChanged: onSelectionChanged,
	});

	return (
		<ControlledAsyncCrudDataGrid<CatalogPartView>
			columns={[
				textColumn({
					field: "partNo",
					headerName: i18n.t("part_no"),
					width: 80,
				}),
				textColumn({
					field: "description_1",
					headerName: i18n.t("description"),
					width: 250,
				}),
				textColumn({
					field: "description_2",
					headerName: i18n.t("description_2"),
					width: 150,
				}),
				textColumn({
					field: "categoryName",
					headerName: i18n.t("category"),
					width: 120,
				}),
			]}
			actionBarComponents={
				<>
					<AavoTextField
						name={"searchQuery"}
						label={i18n.t("search")}
						onSubmit={async (input) => {
							await refreshData({ searchQuery: input });
						}}
					/>
					<LazySelectField
						label={i18n.t("category")}
						fetchOptions={PartCategoryApi.getAll}
						getOptionKey={(option) => option.partCategoryId}
						getOptionLabel={(option) => option.categoryName}
						onChange={async (key) => {
							await refreshData({ partCategoryId: key });
						}}
					/>
				</>
			}
			{...dataGridProps}
		/>
	);
};
