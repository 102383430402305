import { ProjectActivityJobQueueBaseDataGrid } from "src/components/views/erp/project/activityJobQueue/ProjectActivityJobQueueBaseDataGrid.tsx";
import { ProjectActivityJobQueueApi } from "src/api/generated/erp/project/projectActivity/api/projectActivityJobQueueApi.ts";
import { useContextOrThrow } from "src/utils/useContextOrThrow.tsx";
import { ProjectActivityJobQueueViewContext } from "src/components/views/erp/project/activityJobQueue/ProjectActivityJobQueueViewContext.ts";
import { useConfirmDialog } from "src/components/common/dialogs/confirmDialog/useConfirmDialog.ts";
import { ProjectActivityJobQueueActionsApi } from "src/api/generated/erp/project/projectActivity/api/projectActivityJobQueueActionsApi.ts";
import { AsyncButton } from "src/components/common/buttons/AsyncButton.tsx";
import i18n from "i18next";
import { faUndo } from "@fortawesome/pro-regular-svg-icons";

export const ProjectActivityJobQueueCompletedActivitiesDataGrid = () => {
	const { inProgressActivitiesRefreshRef, completedActivitiesRefreshRef } = useContextOrThrow(
		ProjectActivityJobQueueViewContext,
	);
	const showConfirmDialog = useConfirmDialog();

	return (
		<ProjectActivityJobQueueBaseDataGrid
			fetchData={ProjectActivityJobQueueApi.getCompletedActivities}
			refreshRef={completedActivitiesRefreshRef}
			showResponsiblePersonColumn
			gridId={"FE35DD341B0B58A0"}
			actionBarComponents={({ selectedRows }) => (
				<>
					<AsyncButton
						label={i18n.t("revert_complete")}
						icon={faUndo}
						disabled={selectedRows.length === 0}
						variant={"outlined"}
						onClick={async () => {
							const confirmed = await showConfirmDialog();
							if (!confirmed) return;

							await ProjectActivityJobQueueActionsApi.revertCompleteActivities({
								activityIds: selectedRows.map((row) => row.activityId),
							});
							await Promise.all([
								inProgressActivitiesRefreshRef.refresh(),
								completedActivitiesRefreshRef.refresh(),
							]);
						}}
					/>
				</>
			)}
		/>
	);
};
