import { ClientSideDataGridModel } from "src/components/common/dataGrid/gridModel/ClientSideDataGridModel.tsx";
import { CrudDataGrid } from "src/components/common/dataGrid/crud/CrudDataGrid.tsx";
import { textColumn } from "src/components/common/dataGrid/columns.tsx";
import i18n from "i18next";
import { Contact } from "src/api/generated/erp/db/types/tables/contact.ts";
import { ContactApi } from "src/api/generated/erp/contacts/api/contactApi.ts";
import { ContactConnectedItemType } from "src/api/generated/erp/db/types/enums/contactConnectedItemType.ts";
import { ContactForm } from "src/components/views/erp/contacts/ContactForm.tsx";

export interface ContactsDataGridProps {
	connectedItemType: ContactConnectedItemType;
	connectedItemId: number;
}

export const ContactsDataGrid = ({ connectedItemType, connectedItemId }: ContactsDataGridProps) => {
	return (
		<ClientSideDataGridModel
			gridId={"E0CCF741533BF1D0FF"}
			fetchData={() =>
				ContactApi.getItemContacts({
					connectedItemType,
					connectedItemId,
				})
			}
			getRows={(data) => data}
			getRowId={(row) => row.contactId}
			initialParams={{}}
			render={({ dataGridProps }) => {
				return (
					<CrudDataGrid<Contact>
						columns={[
							textColumn({
								field: "name",
								headerName: i18n.t("name"),
								width: 200,
							}),
							textColumn({
								field: "designation",
								headerName: i18n.t("designation"),
								width: 120,
							}),
							textColumn({
								field: "email",
								headerName: i18n.t("email"),
								width: 120,
							}),
							textColumn({
								field: "phone",
								headerName: i18n.t("phone"),
								width: 120,
							}),
							textColumn({
								field: "note",
								headerName: i18n.t("note"),
								width: 500,
							}),
						]}
						form={{
							addRowEnabled: true,
							editEnabled: true,
							dialogSize: "sm",
							dialogTitle: i18n.t("contact"),
							component: (props) => {
								const { row, onCompleted, onFormEdited } = props;
								return (
									<ContactForm
										connectedItemId={connectedItemId}
										connectedItemType={connectedItemType}
										contact={row}
										onCompleted={onCompleted}
										onFormEdited={onFormEdited}
									/>
								);
							},
						}}
						remove={{
							type: "enabled",
							action: async ({ items }) => {
								await ContactApi.delete_({ contactId: items[0]!.contactId });
							},
						}}
						{...dataGridProps}
					/>
				);
			}}
		/>
	);
};
