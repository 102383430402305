import { FormCommonProps } from "src/components/common/forms/types.ts";
import { AsyncForm, AsyncFormContentParams } from "src/components/common/forms/AsyncForm.tsx";
import { Controller } from "react-hook-form";
import {
	SurveySubmissionFormApi,
	SurveySubmissionFormApi_FormInitData,
} from "src/api/generated/erp/surveys/api/surveySubmissionFormApi.ts";
import { SurveyFieldValue } from "src/api/generated/io/aavo/applications/db/erp/types/surveyFieldValue";
import { SurveySubmissionFormFieldWrapper } from "src/components/views/erp/surveys/submissions/SurveySubmissionFormFieldWrapper.tsx";
import { SurveySubmissionFormField } from "src/components/views/erp/surveys/submissions/fields/SurveySubmissionFormField.tsx";
import { SurveySubmissionObjectRef } from "src/api/generated/erp/surveys/model/surveySubmissionObjectRef.ts";
import i18n from "i18next";

export interface SurveySubmissionFormProps {
	surveyFormId: number;
	surveyFormRevisionId?: number;
	surveySubmissionId?: number;
	objectRef?: SurveySubmissionObjectRef;
	readOnly?: boolean;
	disableSubmit?: boolean;
	onCompleted?: FormCommonProps<number>[`onCompleted`];
	onFormEdited?: FormCommonProps<number>[`onFormEdited`];
}

interface FormValues {
	valuesByFieldIds: Record<string, SurveyFieldValue>;
}

export const SurveySubmissionForm = (props: SurveySubmissionFormProps) => {
	const {
		surveyFormId,
		surveySubmissionId,
		surveyFormRevisionId,
		objectRef,
		disableSubmit,
		onCompleted = () => {},
		onFormEdited,
		readOnly,
	} = props;

	return (
		<AsyncForm
			fetch={() =>
				SurveySubmissionFormApi.getFormInitData({
					surveyFormId,
					surveyFormRevisionId,
					surveySubmissionId,
				})
			}
			getDefaultValues={getDefaultValues}
			submit={submit}
			onCompleted={onCompleted}
			onFormEdited={onFormEdited}
			hideActions={disableSubmit}
			getUseFormProps={({ submitAllowed }) => ({ disabled: readOnly || !submitAllowed })}
			columns={({ surveyFormRevision }) => surveyFormRevision.columnCount}
			render={(contentParams) => <FormContent {...props} {...contentParams} />}
		/>
	);

	function getDefaultValues({ fieldSubmissions, fields }: SurveySubmissionFormApi_FormInitData): FormValues {
		return {
			valuesByFieldIds: fields.reduce((acc, field) => {
				const fieldSubmission = fieldSubmissions.find((f) => f.surveyFormFieldId === field.surveyFormFieldId);
				return {
					...acc,
					[field.surveyFormFieldId.toString()]: fieldSubmission?.value ?? field.defaultValue,
				};
			}, {}),
		};
	}

	async function submit({ valuesByFieldIds }: FormValues) {
		return await SurveySubmissionFormApi.submit({
			surveyFormId,
			surveySubmissionId,
			valuesByFieldIds,
			objectRef,
		});
	}
};

interface FormContentProps
	extends SurveySubmissionFormProps,
		AsyncFormContentParams<SurveySubmissionFormApi_FormInitData, FormValues> {}

const FormContent = ({ control, data: { fields } }: FormContentProps) => {
	return fields.map((field) => (
		<Controller
			key={field.surveyFormFieldId}
			control={control}
			name={`valuesByFieldIds.${field.surveyFormFieldId.toString()}`}
			rules={
				field.required ?
					{
						validate: validateRequiredFieldValue,
					}
				:	undefined
			}
			render={({ field: { value, onChange, disabled }, fieldState }) => (
				<SurveySubmissionFormFieldWrapper field={field}>
					<SurveySubmissionFormField
						field={field}
						value={value}
						error={fieldState.error?.message}
						onChange={(newValue) => onChange(newValue)}
						disabled={disabled}
					/>
				</SurveySubmissionFormFieldWrapper>
			)}
		/>
	));
};

function validateRequiredFieldValue(value: SurveyFieldValue | null | undefined): string | undefined {
	const errorMessage = i18n.t("required");
	if (value == null) {
		return errorMessage;
	}
	let valueIsEmpty: boolean = false;
	switch (value.type) {
		case "string":
			valueIsEmpty = value.value === "";
			break;
		case "boolean":
			valueIsEmpty = !value.value;
			break;
		case "documents":
			valueIsEmpty = value.documents.length === 0;
			break;
		default:
			break;
	}

	return valueIsEmpty ? errorMessage : undefined;
}
