import i18n from "i18next";
import { CatalogPartTestConfigurationBomDataGrid } from "src/components/views/erp/configurator/configuratorTesting/catalogPart/CatalogPartTestConfigurationBomDataGrid.tsx";
import { TestConfigurationResultViewCommonProps } from "../TestConfigurationResultViewCommonProps";
import { ConfiguratorLogView } from "../logViews/ConfiguratorLogView";
import { AavoMosaic } from "src/components/common/mosaic/AavoMosaic.tsx";
import { ConfiguratorLogApi } from "src/api/generated/erp/configurator/api/configuratorLogApi.ts";

export const CatalogPartTestConfigurationBomWithLogView = (
	props: TestConfigurationResultViewCommonProps,
) => {
	const { configuratorType, configurationSessionId } = props;
	return (
		<AavoMosaic
			viewId={"BA13C8371ED45761"}
			layout={{
				type: "row",
				first: {
					type: "panel",
					title: i18n.t("bom"),
					content: <CatalogPartTestConfigurationBomDataGrid {...props} />,
				},
				second: {
					type: "panel",
					title: i18n.t("bom_log"),
					content: (
						<ConfiguratorLogView
							fetchLogFile={() =>
								ConfiguratorLogApi.getTestConfigurationPhaseResolvingLog({
									phase: "BOM",
									sessionId: configurationSessionId,
									configuratorType: configuratorType,
								})
							}
						/>
					),
				},
			}}
		/>
	);
};
