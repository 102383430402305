// This file is automatically generated. Do not edit manually.

import { FrontendDownloadFileHandle } from "src/api/generated/common/frontendDownloadFileHandle";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace ReclamationOperationApi {
	export async function releaseReclamation(args: { reclamationId: number; releaseTasks: boolean }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/reclamations/releaseReclamation",
			method: "POST",
			data: {
				reclamationId: args.reclamationId,
				releaseTasks: args.releaseTasks,
			},
		});
		return response as void;
	}

	export async function closeReclamation(args: { reclamationId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/reclamations/closeReclamation",
			method: "POST",
			data: {
				reclamationId: args.reclamationId,
			},
		});
		return response as void;
	}

	export async function revertCloseReclamation(args: { reclamationId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/reclamations/revertCloseReclamation",
			method: "POST",
			data: {
				reclamationId: args.reclamationId,
			},
		});
		return response as void;
	}

	export async function deleteReclamation(args: { reclamationId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/reclamations/deleteReclamation",
			method: "POST",
			data: {
				reclamationId: args.reclamationId,
			},
		});
		return response as void;
	}

	export async function printReclamation(args: { reclamationId: number }): Promise<FrontendDownloadFileHandle> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/reclamations/printReclamation",
			method: "POST",
			data: {
				reclamationId: args.reclamationId,
			},
		});
		return response as FrontendDownloadFileHandle;
	}

	export async function connectToCustomerOrder(args: {
		reclamationId: number;
		customerOrderId: number;
	}): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/reclamations/connectToCustomerOrder",
			method: "POST",
			data: {
				reclamationId: args.reclamationId,
				customerOrderId: args.customerOrderId,
			},
		});
		return response as void;
	}

	export async function removeConnectionToCustomerOrder(args: {
		reclamationId: number;
		customerOrderId: number;
	}): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/reclamations/removeConnectionToCustomerOrder",
			method: "POST",
			data: {
				reclamationId: args.reclamationId,
				customerOrderId: args.customerOrderId,
			},
		});
		return response as void;
	}
}
