export interface ConfiguratorTableDataGridState {
	columns: ConfiguratorTableDataGridColumn[];
	rows: ConfiguratorTableDataGridRow[];
	isDirty: boolean;
}

export interface ConfiguratorTableDataGridColumn {
	columnId: string;
	columnName: string;
}

export interface ConfiguratorTableDataGridRow {
	rowId: string;
	rowHeaderName: string;
	valuesByColumnIds: Record<string, string>;
}

export type ConfiguratorTableDataGridStateAction =
	| {
			type: "setState";
			state: ConfiguratorTableDataGridState;
	  }
	| {
			type: "setIsDirty";
			isDirty: boolean;
	  }
	| {
			type: "updateRow";
			updatedRow: ConfiguratorTableDataGridRow;
	  }
	| {
			type: "updateColumn";
			updatedColumn: Pick<ConfiguratorTableDataGridColumn, "columnId" | "columnName">;
	  }
	| {
			type: "addRows";
			params: AddRowParams[];
	  }
	| {
			type: "addColumns";
			params: AddColumnParams[];
	  }
	| {
			type: "deleteRows";
			rowIds: string[];
	  }
	| {
			type: "deleteColumns";
			columnIds: string[];
	  }
	| {
			type: "moveRow";
			params: MoveRowParams;
	  }
	| {
			type: "moveColumn";
			params: MoveColumnParams;
	  };

export interface AddRowParams {
	rowName: string;
	index?: number;
}

export interface AddColumnParams {
	columnName: string;
	index?: number;
}

export interface MoveRowParams {
	rowId: string;
	targetIndex: number;
}

export interface MoveColumnParams {
	columnId: string;
	targetIndex: number;
}

export function configuratorTableDataGridStateReducer(
	state: ConfiguratorTableDataGridState,
	action: ConfiguratorTableDataGridStateAction,
): ConfiguratorTableDataGridState {
	switch (action.type) {
		case "setState":
			return action.state;
		case "setIsDirty":
			return {
				...state,
				isDirty: action.isDirty,
			};
		case "updateRow":
			return updateRow(state, action.updatedRow);
		case "updateColumn":
			return updateColumn(state, action.updatedColumn);
		case "addRows":
			return addRows(state, action.params);
		case "addColumns":
			return addColumns(state, action.params);
		case "deleteRows":
			return deleteRows(state, action.rowIds);
		case "deleteColumns":
			return deleteColumns(state, action.columnIds);
		case "moveRow":
			return moveRow(state, action.params);
		case "moveColumn":
			return moveColumn(state, action.params);
	}
}

function updateRow(
	state: ConfiguratorTableDataGridState,
	updatedRow: ConfiguratorTableDataGridRow,
): ConfiguratorTableDataGridState {
	const rowIndex = state.rows.findIndex((r) => r.rowId === updatedRow.rowId);
	const oldRow = state.rows[rowIndex];
	if (rowIndex === -1 || oldRow == null) {
		return state;
	}

	const newRows = [...state.rows];
	newRows[rowIndex] = updatedRow;

	return {
		...state,
		rows: newRows,
		isDirty: true,
	};
}

function updateColumn(
	state: ConfiguratorTableDataGridState,
	updatedColumn: Pick<ConfiguratorTableDataGridColumn, "columnId" | "columnName">,
): ConfiguratorTableDataGridState {
	const columnIndex = state.columns.findIndex((c) => c.columnId === updatedColumn.columnId);
	const oldColumn = state.columns[columnIndex];
	if (columnIndex === -1 || oldColumn == null) {
		return state;
	}

	const newColumns = [...state.columns];
	newColumns[columnIndex] = {
		...oldColumn,
		columnName: updatedColumn.columnName,
	};

	return {
		...state,
		columns: newColumns,
		isDirty: true,
	};
}

function addRows(state: ConfiguratorTableDataGridState, params: AddRowParams[]): ConfiguratorTableDataGridState {
	return params.reduce((acc, params) => addRow(acc, params), state);
}

function addRow(state: ConfiguratorTableDataGridState, params: AddRowParams): ConfiguratorTableDataGridState {
	const newRowPosition = params.index ?? state.rows.length;
	const newRow = {
		rowId: crypto.randomUUID(),
		rowHeaderName: params.rowName,
		position: newRowPosition,
		valuesByColumnIds: {},
	};
	const newRows = state.rows.toSpliced(newRowPosition, 0, newRow);
	return {
		...state,
		rows: newRows,
		isDirty: true,
	};
}

function addColumns(state: ConfiguratorTableDataGridState, params: AddColumnParams[]): ConfiguratorTableDataGridState {
	return params.reduce((acc, params) => addColumn(acc, params), state);
}

function addColumn(state: ConfiguratorTableDataGridState, params: AddColumnParams): ConfiguratorTableDataGridState {
	const newColumnPosition = params.index ?? state.columns.length;
	const newColumn = {
		columnId: crypto.randomUUID(),
		columnName: params.columnName,
		position: newColumnPosition,
	};
	const newColumns = state.columns.toSpliced(newColumnPosition, 0, newColumn);

	return {
		...state,
		columns: newColumns,
		isDirty: true,
	};
}

function deleteRows(state: ConfiguratorTableDataGridState, rowIds: string[]): ConfiguratorTableDataGridState {
	const newRows = state.rows.filter((row) => !rowIds.includes(row.rowId));
	return {
		...state,
		rows: newRows,
		isDirty: true,
	};
}

function deleteColumns(state: ConfiguratorTableDataGridState, columnIds: string[]): ConfiguratorTableDataGridState {
	const newColumns = state.columns.filter((column) => !columnIds.includes(column.columnId));
	const newRows = state.rows.map((row) => {
		const newValueByColumnIds = { ...row.valuesByColumnIds };
		columnIds.forEach((columnId) => delete newValueByColumnIds[columnId]);
		return {
			...row,
			valuesByColumnIds: newValueByColumnIds,
		};
	});
	return {
		...state,
		columns: newColumns,
		rows: newRows,
		isDirty: true,
	};
}

function moveRow(state: ConfiguratorTableDataGridState, params: MoveRowParams): ConfiguratorTableDataGridState {
	const { rowId, targetIndex } = params;
	const currentIndex = state.rows.findIndex((row) => row.rowId === rowId);
	const rowToMove = state.rows[currentIndex];
	if (currentIndex == -1 || rowToMove == null) {
		return state;
	}

	const newRows = state.rows.toSpliced(currentIndex, 1);
	newRows.splice(targetIndex, 0, rowToMove);

	return {
		...state,
		rows: newRows,
		isDirty: true,
	};
}

function moveColumn(state: ConfiguratorTableDataGridState, params: MoveColumnParams): ConfiguratorTableDataGridState {
	const { columnId, targetIndex } = params;
	const currentColumnIndex = state.columns.findIndex((col) => col.columnId === columnId);
	const columnToMove = state.columns[currentColumnIndex];
	if (currentColumnIndex == -1 || columnToMove == null) {
		return state;
	}

	const newColumns = state.columns.toSpliced(currentColumnIndex, 1);
	newColumns.splice(targetIndex, 0, columnToMove);

	return {
		...state,
		columns: newColumns,
		isDirty: true,
	};
}
