import i18n from "i18next";
import { JobQueueShopOrderOperation } from "src/components/views/erp/production/jobQueue/base/JobQueueShopOrderOperation.ts";
import { OpenGenericDialogFunc } from "src/components/common/dialogs/GenericDialogProvider.tsx";
import { ProductionLineWorkCenterOperationActionView } from "src/components/views/erp/production/jobQueue/common/ProductionLineWorkCenterOperationActionView.tsx";

export function openProductionLineWorkCenterOperationActionViewDialog({
	openDialog,
	shopOrderOperation,
}: {
	openDialog: OpenGenericDialogFunc;
	shopOrderOperation: JobQueueShopOrderOperation;
}) {
	openDialog({
		size: "fullscreen",
		title: `${i18n.t("operation_completion")} 
		(
			${i18n.t("shop_order")} 
			${shopOrderOperation.shopOrderId}, ${shopOrderOperation.sourceRefData}
		)`,
		content: <ProductionLineWorkCenterOperationActionView shopOrderId={shopOrderOperation.shopOrderId} />,
	});
}
