import {
	ShowFormInputDialogParams,
	useFormInput,
} from "src/components/common/dialogs/formInput/useFormInput.tsx";
import i18n from "i18next";
import { FormTextField } from "src/components/common/forms/fields/FormTextField.tsx";
import { Typography } from "@mui/material";

export interface ConfirmWithInputDialogProps
	extends Omit<ShowFormInputDialogParams<object>, "defaultValues" | "content"> {
	message: string;
	inputToMatch: string;
}

export type OpenConfirmWithInputDialogFunc = (params: ConfirmWithInputDialogProps) => Promise<boolean>;

export const useConfirmWithInputDialog = (): OpenConfirmWithInputDialogFunc => {
	const showFormInput = useFormInput();

	return async (props: ConfirmWithInputDialogProps) => {
		const { inputToMatch, message } = props;
		const input = await showFormInput<{ inputText: string }>({
			...props,
			size: "sm",
			content: ({ control }) => (
				<>
					<Typography
						sx={{
							whiteSpace: "pre-line",
						}}
					>
						{message}
					</Typography>
					<FormTextField
						control={control}
						name="inputText"
						rules={{
							validate: (value) =>
								value === inputToMatch ? true : i18n.t("input_does_not_match"),
						}}
					/>
				</>
			),
		});
		return input !== undefined;
	};
};
