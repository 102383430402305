import { RefreshableElementRef } from "src/utils/useRefreshRef.ts";
import { createContext } from "react";
import { useContextOrThrow } from "src/utils/useContextOrThrow.tsx";

interface JobQueueViewContextValue {
	initialsViewRefreshRef: RefreshableElementRef;
	inProgressViewRefreshRef: RefreshableElementRef;
	completedViewRefreshRef: RefreshableElementRef;
	inProgressViewDocumentsRefreshRef: RefreshableElementRef;
}

export const JobQueueViewContext = createContext<JobQueueViewContextValue | undefined>(
	undefined,
);

export const useJobQueueViewContext = () => useContextOrThrow(JobQueueViewContext);
