import { GridPaginationModel, GridRowId } from "@mui/x-data-grid-pro";
import { filterNulls } from "src/utils/arrayUtils.ts";
import { GridApiPro } from "@mui/x-data-grid-pro/models/gridApiPro";
import { GridValidRowModel } from "@mui/x-data-grid/models/gridRows";
import type { GridColDef } from "@mui/x-data-grid/models/colDef/gridColDef";
import { logError } from "src/errorHandling/errorLogging.ts";
import { AavoGridColDef } from "src/components/common/dataGrid/AavoDataGrid.tsx";

export const mergeRowClassNames = (...classNames: (string | undefined)[]): string => {
	return filterNulls(classNames).join(" ");
};

export const DEFAULT_PAGINATION_MODEL: GridPaginationModel = {
	pageSize: 100,
	page: 0,
};

export const getDataGridRowsByIds = <TRow extends GridValidRowModel>(gridApi: GridApiPro, ids: GridRowId[]): TRow[] => {
	return filterNulls(
		ids.map((id) => {
			return gridApi.getRow<TRow>(id);
		}),
	);
};

export const flattenAavoDataGridColumns = <RowData extends object>(
	columns: AavoGridColDef<RowData>[],
): GridColDef<RowData>[] => {
	const ret: GridColDef<RowData>[] = [];

	const columnsWithAbsolutePosition: Array<GridColDef<RowData> & { position: number }> = [];

	columns.forEach((column) => {
		if (column === false || column === undefined) return;
		if (Array.isArray(column)) {
			ret.push(...flattenAavoDataGridColumns(column));
			return;
		}

		const afterColumn = column.afterColumn;
		const position = column.position;
		if (afterColumn !== undefined) {
			const afterIndex = ret.findIndex((c) => c.field === afterColumn);
			if (afterIndex === -1) {
				logError(`Column ${column.field} has afterColumn ${afterColumn} but it was not found`);
				ret.push(column);
			} else {
				ret.splice(afterIndex + 1, 0, column);
			}
		} else if (position !== undefined) {
			columnsWithAbsolutePosition.push({ ...column, position: position });
		} else {
			ret.push(column);
		}
	});

	columnsWithAbsolutePosition.forEach((column) => {
		ret.splice(column.position, 0, column);
	});

	return ret;
};
