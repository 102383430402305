import { AsyncForm, AsyncFormContentParams } from "src/components/common/forms/AsyncForm.tsx";
import {
	CustomerAddressEditApi,
	CustomerAddressEditApi_InitData,
} from "src/api/generated/erp/sales/customer/api/customerAddressEditApi.ts";
import { CustomerAddress } from "src/api/generated/erp/db/types/tables/customerAddress.ts";
import { FormCommonProps } from "src/components/common/forms/types.ts";
import { FormTextField } from "src/components/common/forms/fields/FormTextField.tsx";
import i18n from "i18next";
import { FormSelectField } from "src/components/common/forms/fields/FormSelectField.tsx";
import { FormEnumSelectField } from "src/components/common/forms/fields/FormEnumSelectField.tsx";
import { getCountryLabels } from "src/api/generated/erp/db/types/enums/country.ts";
import { getCustomerAddressTypeLabels } from "src/api/generated/erp/db/types/enums/customerAddressType.ts";
import { CustomerAddressView } from "src/api/generated/erp/db/types/tables/customerAddressView.ts";
import { FormAddressAutofill } from "src/components/common/forms/fields/FormAddressAutofill.tsx";
import { requireRule } from "src/components/common/forms/validation.ts";

export interface CustomerAddressFormProps extends FormCommonProps<number> {
	customerId: number;
	customerAddressId?: number;
	copyCustomerAddressSource?: CustomerAddressView;
}

export const CustomerAddressForm = (props: CustomerAddressFormProps) => {
	const { customerId, customerAddressId, copyCustomerAddressSource, onFormEdited, onCompleted } = props;
	return (
		<AsyncForm<CustomerAddressEditApi_InitData, CustomerAddress, number>
			columns={2}
			fetch={() =>
				CustomerAddressEditApi.getFormInitData({
					customerAddressId: customerAddressId,
				})
			}
			getDefaultValues={(data) => getDefaultValues({ initData: data })}
			onCompleted={onCompleted}
			onFormEdited={onFormEdited}
			submit={async (values) => {
				if (customerAddressId == null) {
					return await CustomerAddressEditApi.insert({ customerAddress: values });
				} else {
					return await CustomerAddressEditApi.update({ customerAddress: values });
				}
			}}
			render={(params) => <FormContent {...params} {...props} />}
		/>
	);

	function getDefaultValues({ initData }: { initData: CustomerAddressEditApi_InitData }) {
		const customerAddress = initData.customerAddress;
		if (customerAddress != undefined) {
			return {
				...customerAddress,
			};
		} else if (copyCustomerAddressSource != null) {
			return {
				...copyCustomerAddressSource,
				customerAddressId: undefined,
			};
		} else {
			return {
				customerId: customerId,
				country: "FINLAND",
			} as const;
		}
	}
};

interface FormContentProps
	extends CustomerAddressFormProps,
		AsyncFormContentParams<CustomerAddressEditApi_InitData, CustomerAddress> {}

const FormContent = ({ control, watch, setValue, data }: FormContentProps) => {
	return (
		<>
			<FormTextField control={control} name={"name"} label={i18n.t("description")} spanGridColumns />
			<FormAddressAutofill
				control={control}
				name={`address_1`}
				label={i18n.t("address_1")}
				rules={requireRule()}
				onSelectLocation={(location) => {
					setValue(`address_2`, location.address_line2);
					setValue(`postalCode`, location.postcode);
					setValue(`city`, location.address_level2);
					return location.address_line1;
				}}
			/>
			<FormTextField control={control} name={"address_2"} label={i18n.t("address_2")} />
			<FormTextField control={control} name={"postalCode"} label={i18n.t("postal_code")} />
			<FormTextField control={control} name={"city"} label={i18n.t("city")} />
			<FormEnumSelectField
				label={i18n.t("country")}
				name={"country"}
				options={getCountryLabels()}
				control={control}
			/>
			<FormTextField control={control} name={"contact"} label={i18n.t("contact")} />
			<FormTextField control={control} name={"phone"} label={i18n.t("phone")} />
			<FormTextField control={control} name={"email"} label={i18n.t("email")} />
			<FormEnumSelectField
				control={control}
				name={"addressType"}
				label={i18n.t("type")}
				options={getCustomerAddressTypeLabels()}
			/>
			{watch("addressType") === "DELIVERY" && (
				<FormSelectField
					control={control}
					name={"transportRouteId"}
					label={i18n.t("transport_route")}
					options={data.transportRouteOptions}
					getOptionKey={(option) => option.transportRouteId}
					getOptionLabel={(option) => option.name}
				/>
			)}
		</>
	);
};
