// This file is automatically generated. Do not edit manually.

import { SurveySubmissionObjectType } from "src/api/generated/erp/db/types/enums/surveySubmissionObjectType";
import { SurveySubmission } from "src/api/generated/erp/db/types/tables/surveySubmission";
import { SurveySubmissionView } from "src/api/generated/erp/db/types/tables/surveySubmissionView";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace ObjectSurveySubmissionApi {
	export async function getObjectSurveySubmission(args: {
		objectType: SurveySubmissionObjectType;
		objectId: number;
	}): Promise<SurveySubmission | null> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/surveys/objectSurveySubmission/getObjectSurveySubmission",
			method: "POST",
			data: {
				objectType: args.objectType,
				objectId: args.objectId,
			},
		});
		return response === "" ? null : (response as SurveySubmission | null);
	}

	export async function getSurveySubmissionsOfObjects(args: {
		objectType: SurveySubmissionObjectType;
		objectIds: Array<number>;
	}): Promise<Array<SurveySubmissionView>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/surveys/objectSurveySubmission/getSurveySubmissionsOfObjects",
			method: "POST",
			data: {
				objectType: args.objectType,
				objectIds: args.objectIds,
			},
		});
		return response as Array<SurveySubmissionView>;
	}
}
