import { FormCommonProps } from "src/components/common/forms/types.ts";
import { AsyncForm, AsyncFormContentParams } from "src/components/common/forms/AsyncForm.tsx";
import { ControlChart } from "src/api/generated/postgres/db/types/public_/tables/controlChart.ts";
import {
	ControlChartEditApi,
	ControlChartEditApi_InitData,
} from "src/api/generated/spc/controlChart/api/controlChartEditApi.ts";
import { FormTextField } from "src/components/common/forms/fields/FormTextField.tsx";
import i18n from "i18next";
import { FormCheckbox } from "src/components/common/forms/fields/FormCheckbox.tsx";
import { requiredNonNegativeIntegerRule, requireRule } from "src/components/common/forms/validation.ts";
import { getControlChartTypeLabels } from "src/api/generated/io/aavo/applications/db/postgres/enums/controlChartType.ts";
import { FormEnumSelectField } from "src/components/common/forms/fields/FormEnumSelectField.tsx";
import { FormNumberField } from "src/components/common/forms/fields/FormNumberField.tsx";
import { getControlChartTimeUnitLabels } from "src/api/generated/io/aavo/applications/db/postgres/enums/controlChartTimeUnit.ts";
import { FormSelectField } from "src/components/common/forms/fields/FormSelectField.tsx";
import { useTenantCustomizations } from "src/tenantCustomizations/useTenantCustomizations.tsx";
import { Controller } from "react-hook-form";
import { DurationField } from "src/components/common/inputFields/DurationField.tsx";
import dayjs from "dayjs";
import { getControlChartProcessRoleLabels } from "src/api/generated/io/aavo/applications/db/postgres/enums/controlChartProcessRole.ts";
import { getControlChartDataInputMethodLabels } from "src/api/generated/postgres/db/types/public_/enums/controlChartDataInputMethod.ts";
import { FormSection } from "src/components/common/forms/FormSection.tsx";
import { FormDateTimeField } from "src/components/common/forms/fields/FormDateTimeField";

export interface ControlChartFormProps extends FormCommonProps<number> {
	controlChartId?: number;
	chartGroupId?: number;
	itemId?: number;
	emptyFormDefaultValues?: Partial<ControlChart>;
}

export type ControlChartFormRenderProps = AsyncFormContentParams<ControlChartEditApi_InitData, ControlChart>;

export const ControlChartForm = ({
	controlChartId,
	chartGroupId,
	itemId,
	onFormEdited,
	onCompleted,
	emptyFormDefaultValues,
}: ControlChartFormProps) => {
	return (
		<AsyncForm<ControlChartEditApi_InitData, ControlChart, number>
			columns={3}
			fetch={() =>
				ControlChartEditApi.getInitData({
					controlChartId: controlChartId,
				})
			}
			getDefaultValues={(data) =>
				data.controlChart != null ?
					data.controlChart
				:	{
						name: "",
						enabled: true,
						description: "",
						chartType: "I_MR",
						subgroupSize: 1,
						shownPointCount: 20,
						timeBasedMeasurements: false,
						samplingReferenceTime: dayjs().toISOString(),
						specialCauseEmailsEnabled: true,
						notifyUpcomingSpecialCauses: false,
						disableDuplicateSpecialCauses: false,
						drawSpecificationLimits: false,
						isAimSettingTargetChart: false,
						dataInputMethod: "DEFAULT_",
						chartGroupId: chartGroupId,
						itemId: itemId,
						...emptyFormDefaultValues,
					}
			}
			onCompleted={onCompleted}
			onFormEdited={onFormEdited}
			render={(props) => {
				return <FormContent {...props} />;
			}}
			submit={async (controlChart) => {
				if (controlChartId == null) {
					const chartTypeNotC = controlChart.chartType !== "C";
					const chartTypeNotCNorI = chartTypeNotC && controlChart.chartType !== "I";
					const chartWithResetSpecialCauseTests = {
						...controlChart,
						scTestX_1Enabled: true,
						scTestX_2Enabled: true,
						scTestX_3Enabled: true,
						scTestX_4Enabled: true,
						scTestX_5Enabled: chartTypeNotC,
						scTestX_6Enabled: chartTypeNotC,
						scTestX_7Enabled: chartTypeNotC,
						scTestX_8Enabled: chartTypeNotC,
						scTestR_1Enabled: chartTypeNotCNorI,
						scTestR_2Enabled: chartTypeNotCNorI,
						scTestR_3Enabled: chartTypeNotCNorI,
						scTestR_4Enabled: chartTypeNotCNorI,
					};
					return await ControlChartEditApi.insert({
						controlChart: chartWithResetSpecialCauseTests,
						itemId: itemId,
					});
				} else {
					return await ControlChartEditApi.update({ controlChart: controlChart });
				}
			}}
		/>
	);
};

const FormContent = (props: ControlChartFormRenderProps) => {
	return (
		<>
			<CommonSectionFields {...props} />
			<SamplingSectionFields {...props} />
			<SpecificationLimitsSectionFields {...props} />
			<FieldDescriptionsSectionFields {...props} />
			<CChartEventDimensionsSectionFields {...props} />
			<OthersSectionFields {...props} />
		</>
	);
};

const CommonSectionFields = ({ control }: ControlChartFormRenderProps) => (
	<FormSection label={i18n.t("control_chart")}>
		<FormTextField name={"name"} control={control} label={i18n.t("name")} rules={requireRule()} />
		<FormTextField name={"externalId"} control={control} label={i18n.t("external_id")} />
		<FormCheckbox name={"enabled"} control={control} label={i18n.t("enabled")} />
		<FormTextField
			name={"description"}
			control={control}
			label={i18n.t("description")}
			multiline={true}
			sx={{ gridColumn: "1 / -1" }}
		/>
		<FormEnumSelectField
			control={control}
			name={"chartType"}
			label={i18n.t("type")}
			options={getControlChartTypeLabels()}
			rules={requireRule()}
		/>
		<FormNumberField
			control={control}
			name={"subgroupSize"}
			label={i18n.t("subgroup_size")}
			rules={requireRule()}
		/>
		<FormNumberField
			control={control}
			name={"shownPointCount"}
			label={i18n.t("shown_point_count")}
			rules={requireRule()}
		/>
		<FormEnumSelectField
			control={control}
			options={getControlChartDataInputMethodLabels()}
			name={"dataInputMethod"}
			label={i18n.t("data_input_method")}
			rules={requireRule()}
		/>
	</FormSection>
);

const SamplingSectionFields = (props: ControlChartFormRenderProps) => {
	const { tenantConfig, spcSamplingMethods: spcSamplingMethodsProvider } = useTenantCustomizations();
	const spcSamplingMethods = spcSamplingMethodsProvider({ tenantConfig });
	const {
		data: { calendarOptions },
		control,
		watch,
	} = props;
	const currentSamplingMethodKey = watch("samplingMethod");
	const currentSamplingMethod = spcSamplingMethods.find((value) => value.key === currentSamplingMethodKey);
	const isTimeBasedMeasurements = watch("timeBasedMeasurements");

	return (
		<FormSection label={i18n.t("sampling")}>
			<FormCheckbox control={control} name={"timeBasedMeasurements"} label={i18n.t("time_based")} />
			<FormEnumSelectField
				control={control}
				name={"timeUnit"}
				label={i18n.t("time_unit")}
				options={getControlChartTimeUnitLabels()}
				hidden={isTimeBasedMeasurements === false}
				rules={isTimeBasedMeasurements ? requireRule() : undefined}
			/>
			<FormSelectField
				control={control}
				name={"calendarId"}
				label={i18n.t("calendar")}
				options={calendarOptions}
				getOptionKey={(option) => option.id}
				getOptionLabel={(option) => option.name}
			/>
			<FormSelectField
				control={control}
				name={"samplingMethod"}
				label={i18n.t("sampling_method")}
				options={spcSamplingMethods}
				getOptionKey={(option) => option.key}
				getOptionLabel={(option) => option.label}
				hidden={spcSamplingMethods.length === 0}
				sx={{ gridColumn: "1" }}
			/>
			{currentSamplingMethod != null && (
				<>
					<Controller
						control={control}
						name={"sampleRateMs"}
						render={({ field, fieldState }) => {
							return (
								<DurationField
									label={i18n.t("sample_rate")}
									value={field.value != null ? dayjs.duration({ milliseconds: field.value }) : null}
									onChange={(value) => field.onChange(value.asMilliseconds())}
									error={fieldState.error?.message}
								/>
							);
						}}
						rules={requiredNonNegativeIntegerRule()}
					/>
					<FormDateTimeField
						control={control}
						name={"samplingReferenceTime"}
						label={i18n.t("sampling_reference_time")}
						asLocalTime
					/>
					{currentSamplingMethod.renderParameterFields(props)}
				</>
			)}
		</FormSection>
	);
};

const SpecificationLimitsSectionFields = ({ control }: ControlChartFormRenderProps) => (
	<FormSection label={i18n.t("specification_limits")}>
		<FormNumberField control={control} name={"lsl"} label={i18n.t("lsl")} />
		<FormNumberField control={control} name={"target"} label={i18n.t("target.spc")} />
		<FormNumberField control={control} name={"usl"} label={i18n.t("usl")} />
	</FormSection>
);

const FieldDescriptionsSectionFields = ({ control }: ControlChartFormRenderProps) => (
	<FormSection label={i18n.t("field_descriptions")}>
		<FormTextField control={control} name={"valueDescription"} label={i18n.t("value_description")} />
		<FormTextField control={control} name={"info1description"} label={i18n.t("info1_description")} />
		<FormTextField control={control} name={"info2description"} label={i18n.t("info2_description")} />
		<FormTextField control={control} name={"xDescription"} label={i18n.t("x_description")} startNewGridRow={true} />
		<FormTextField control={control} name={"yDescription"} label={i18n.t("y_description")} />
	</FormSection>
);

const CChartEventDimensionsSectionFields = (props: ControlChartFormRenderProps) => {
	const { control, watch } = props;
	const currentChartType = watch("chartType");
	const indexList: Array<1 | 2 | 3 | 4> = [1, 2, 3, 4];
	return (
		<>
			{currentChartType === "C" && (
				<FormSection label={i18n.t("c_chart")}>
					<FormCheckbox
						control={control}
						name={"cChartEventDocumentsEnabled"}
						label={i18n.t("file_download")}
						sx={{ gridColumn: "1/-1" }}
					/>
					{indexList.map((index) => (
						<FormTextField
							control={control}
							name={`cChartEventDimension_${index}`}
							label={i18n.t("additional_info_n.spc", { n: index })}
						/>
					))}
				</FormSection>
			)}
		</>
	);
};
const OthersSectionFields = (props: ControlChartFormRenderProps) => {
	const { control, watch } = props;
	const currentChartType = watch("chartType");
	const currentProcessRole = watch("processRole");
	return (
		<FormSection label={i18n.t("other.spc")}>
			<FormEnumSelectField
				control={control}
				name={"processRole"}
				label={i18n.t("process_role.spc")}
				options={getControlChartProcessRoleLabels()}
			/>
			<FormCheckbox
				control={control}
				name={"specialCauseEmailsEnabled"}
				label={i18n.t("notifications_enabled")}
				spanGridColumns
			/>
			{currentChartType === "C" && (
				<FormCheckbox
					control={control}
					name={"notifyUpcomingSpecialCauses"}
					label={i18n.t("notify_upcoming_special_causes")}
					spanGridColumns
				/>
			)}
			<FormCheckbox
				control={control}
				name={"disableDuplicateSpecialCauses"}
				label={i18n.t("ignore_consecutive_special_causes")}
				spanGridColumns
			/>
			<FormCheckbox
				control={control}
				name={"drawSpecificationLimits"}
				label={i18n.t("draw_specification_limits")}
				spanGridColumns
			/>
			{currentProcessRole === "Y" && (
				<FormCheckbox
					control={control}
					name={"isAimSettingTargetChart"}
					label={i18n.t("aim_setting_chart")}
					spanGridColumns
				/>
			)}
		</FormSection>
	);
};
