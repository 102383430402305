// This file is automatically generated. Do not edit manually.

import { t } from "i18next";

export const TaskSourceTypeValues = [
	"PROJECT_ACTIVITY",
	"CUSTOMER_ORDER",
	"CUSTOMER_ORDER_OFFER",
	"SAFETY_CONTROL_SURVEY",
	"PRODUCT_CHANGE_REQUEST",
	"RECLAMATION",
	"PROCESS_IMPROVEMENT",
	"WORKSPACE",
	"RECLAMATION_V2",
] as const;

export type TaskSourceType = (typeof TaskSourceTypeValues)[number];

export const getTaskSourceTypeLabels = () => ({
	PROJECT_ACTIVITY: t("project_activity"),
	CUSTOMER_ORDER: t("customer_order"),
	CUSTOMER_ORDER_OFFER: t("customer_order_offer"),
	SAFETY_CONTROL_SURVEY: t("safety_control_survey"),
	PRODUCT_CHANGE_REQUEST: t("product_change_request"),
	RECLAMATION: t("reclamation"),
	PROCESS_IMPROVEMENT: t("process_improvement"),
	WORKSPACE: t("workspace"),
	RECLAMATION_V2: t("reclamation_v2"),
});

export const getTaskSourceTypeLabel = (value: TaskSourceType): string => {
	return getTaskSourceTypeLabels()[value];
};
