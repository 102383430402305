// This file is automatically generated. Do not edit manually.

import { Resource } from "src/api/generated/postgres/db/types/ext_resourcing/tables/resource";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace ResourcesDataGridApi {
	export async function getResources(args: { searchQuery?: string }): Promise<Array<Resource>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/spc/resources/getResources",
			method: "POST",
			data: {
				searchQuery: args.searchQuery,
			},
		});
		return response as Array<Resource>;
	}
}
