import { SurveyCategory } from "src/api/generated/erp/db/types/tables/surveyCategory.ts";
import { FormCommonProps } from "src/components/common/forms/types.ts";
import { SurveyCategoryApi } from "src/api/generated/erp/surveys/api/surveyCategoryApi.ts";
import { requireRule } from "src/components/common/forms/validation.ts";
import { AavoForm } from "src/components/common/forms/AavoForm.tsx";
import i18n from "i18next";
import { FormTextField } from "src/components/common/forms/fields/FormTextField.tsx";

export interface SurveyCategoryFormProps extends FormCommonProps<number> {
	surveyCategory: SurveyCategory | undefined;
}

interface FormValues extends SurveyCategory {}

export const SurveyCategoryForm = (props: SurveyCategoryFormProps) => {
	const { surveyCategory, onCompleted, onFormEdited } = props;
	return (
		<AavoForm
			defaultValues={surveyCategory ?? {}}
			submit={submit}
			onCompleted={onCompleted}
			onFormEdited={onFormEdited}
			render={({ control }) => (
				<>
					<FormTextField control={control} name={"name"} label={i18n.t("name")} rules={requireRule()} />
				</>
			)}
		/>
	);

	async function submit(values: FormValues) {
		if (surveyCategory == null) {
			return await SurveyCategoryApi.insert({ surveyCategory: values });
		} else {
			await SurveyCategoryApi.update({ surveyCategory: values });
			return surveyCategory.surveyCategoryId;
		}
	}
};
