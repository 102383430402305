// This file is automatically generated. Do not edit manually.

import { ServerSideDataModelRequest } from "src/api/generated/common/dataGrids/serverSideDataModelRequest";
import { ServerSideDataModelResult } from "src/api/generated/common/dataGrids/serverSideDataModelResult";
import { CustomerOrderLineExportView } from "src/api/generated/erp/db/types/tables/customerOrderLineExportView";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace AllCustomerOrderLinesDataGridApi {
	export async function getAllCustomerOrderLines(args: {
		siteId: number | null | undefined;
		dataModelRequest: ServerSideDataModelRequest;
	}): Promise<ServerSideDataModelResult<CustomerOrderLineExportView>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/allCustomerOrderLines/getAllCustomerOrderLines",
			method: "POST",
			data: {
				siteId: args.siteId,
				dataModelRequest: args.dataModelRequest,
			},
		});
		return response as ServerSideDataModelResult<CustomerOrderLineExportView>;
	}
}
