import { ManualInventoryEventFormBase } from "src/components/views/erp/warehouse/inventory/manualEventViews/ManualInventoryEventFormBase.tsx";
import { FormCommonProps } from "src/components/common/forms/types.ts";
import { InventoryLevelQueryApi } from "src/api/generated/erp/warehouse/inventory/inventoryEvent/api/inventoryLevelQueryApi.ts";
import { ManualInventoryEventFormApi } from "src/api/generated/erp/warehouse/inventory/inventoryEvent/api/manualInventoryEventFormApi.ts";

export interface ManualInventoryFormProps extends FormCommonProps<void> {}

export const ManualInventoryForm = ({ onCompleted }: ManualInventoryFormProps) => {
	return (
		<ManualInventoryEventFormBase
			onCompleted={onCompleted}
			submitForm={async (formValues) => {
				const currentStockQuantity = await InventoryLevelQueryApi.getCurrentStockQuantity({
					partId: formValues.part.partId,
					partConfigurationId: formValues.partConfigurationId,
					warehouseLocationId: formValues.warehouseLocationId!,
				});

				await ManualInventoryEventFormApi.makeInventoryEvents({
					events: [
						{
							eventType: "MANUAL_INVENTORY_DIFFERENCE",
							eventQuantity: formValues.quantity - currentStockQuantity,
							partConfigurationId: formValues.partConfigurationId,
							partId: formValues.part.partId,
							warehouseLocationId: formValues.warehouseLocationId,
						},
					],
				});
			}}
		/>
	);
};
