// This file is automatically generated. Do not edit manually.

import { AavoObjectRef } from "src/api/generated/common/sourceType/aavoObjectRef";
import { ServerSideDataModelRequest } from "src/api/generated/common/dataGrids/serverSideDataModelRequest";
import { ServerSideDataModelResult } from "src/api/generated/common/dataGrids/serverSideDataModelResult";
import { DocumentActiveRevisionView } from "src/api/generated/postgres/db/types/documents/tables/documentActiveRevisionView";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace DocumentSearchApi {
	export async function searchDocuments(args: {
		documentIdOrDescription1Query: string;
		description2Query: string;
		categoryIdFilter: Array<number>;
		filterDocumentsConnectedToObject?: AavoObjectRef | null | undefined;
		dataModelRequest: ServerSideDataModelRequest;
	}): Promise<ServerSideDataModelResult<DocumentActiveRevisionView>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/documents/searchDocuments",
			method: "POST",
			data: {
				documentIdOrDescription1Query: args.documentIdOrDescription1Query,
				description2Query: args.description2Query,
				categoryIdFilter: args.categoryIdFilter,
				filterDocumentsConnectedToObject: args.filterDocumentsConnectedToObject,
				dataModelRequest: args.dataModelRequest,
			},
		});
		return response as ServerSideDataModelResult<DocumentActiveRevisionView>;
	}
}
