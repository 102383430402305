// This file is automatically generated. Do not edit manually.

import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace InventoryLevelQueryApi {
	export async function getCurrentStockQuantity(args: {
		partId: number;
		partConfigurationId: number | null | undefined;
		warehouseLocationId: number;
	}): Promise<number> {
		const response = await makeAxiosRequest({
			url: "/api/ext/warehouse/inventoryLevel/getCurrentStockQuantity",
			method: "POST",
			data: {
				partId: args.partId,
				partConfigurationId: args.partConfigurationId,
				warehouseLocationId: args.warehouseLocationId,
			},
		});
		return response as number;
	}
}
