import { AavoMosaic } from "src/components/common/mosaic/AavoMosaic.tsx";
import i18n from "i18next";
import { DeliveryPackageLinesDataGrid } from "src/components/views/erp/delivery/deliveryPackage/DeliveryPackageLinesDataGrid.tsx";
import { useState } from "react";
import { DeliveryPackageView } from "src/api/generated/erp/db/types/tables/deliveryPackageView.ts";
import { CenteredTypography } from "src/components/common/CenteredTypography.tsx";
import {
	DeliveryPackagesDataGrid,
	DeliveryPackagesDataGridProps
} from "./DeliveryPackagesDataGrid.tsx";

export interface DeliveryPackagesWithLinesViewProps
	extends Omit<DeliveryPackagesDataGridProps, "selectedRows" | "onSelectedPackageChanged"> {}

export const DeliveryPackagesWithLinesView = (props: DeliveryPackagesWithLinesViewProps) => {
	const [selectedPackage, setSelectedPackage] = useState<DeliveryPackageView | undefined>(undefined);
	return (
		<AavoMosaic
			viewId={"D741C35965230EC"}
			layout={{
				type: "row",
				first: {
					type: "panel",
					title: i18n.t("packages"),
					content: (
						<DeliveryPackagesDataGrid
							selectedRows={selectedPackage == undefined ? [] : [selectedPackage]}
							onSelectedPackageChanged={(selectedPackages) => setSelectedPackage(selectedPackages[0])}
							{...props}
						/>
					),
				},
				second: {
					type: "panel",
					title: i18n.t("package_lines"),
					content:
						selectedPackage == null ?
							<CenteredTypography>{i18n.t("select_package")}</CenteredTypography>
						:	<DeliveryPackageLinesDataGrid
								key={selectedPackage.deliveryPackageId}
								deliveryPackage={selectedPackage}
							/>,
				},
				splitPercentage: 55,
			}}
		/>
	);
};
