import { AsyncMenuButton } from "src/components/common/contextMenu/AsyncMenuButton.tsx";
import { faFileDownload } from "@fortawesome/pro-regular-svg-icons";
import { downloadFile } from "src/utils/fileDownloading.ts";
import { MellanoHolzmaFileDownloadApi } from "src/api/generated/tenants/mellano/holzma/mellanoHolzmaFileDownloadApi.ts";
import { ShopOrderBatchContextMenuCustomComponentsProps } from "src/tenantCustomizations/TenantCustomizations.ts";

export const MellanoShopOrderBatchContextMenuComponents = ({
	shopOrderBatch,
}: ShopOrderBatchContextMenuCustomComponentsProps) => {
	return [
		<AsyncMenuButton
			key={"holzma"}
			icon={faFileDownload}
			label={"Holzma-ohjelma"}
			onClick={async () => {
				const fileHandle = await MellanoHolzmaFileDownloadApi.downloadHolzmaFile({
					shopOrderBatchId: shopOrderBatch.shopOrderBatchId,
				});
				downloadFile(fileHandle);
			}}
		/>,
	];
};
