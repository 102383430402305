import { ControlledAsyncCrudDataGrid } from "src/components/common/dataGrid/crud/ControlledAsyncCrudDataGrid.tsx";
import { floatColumn, integerColumn, textColumn } from "src/components/common/dataGrid/columns.tsx";
import i18n from "i18next";
import { AsyncButton } from "src/components/common/buttons/AsyncButton.tsx";
import { faBoxOpen, faPrint, faUndo } from "@fortawesome/pro-regular-svg-icons";
import { downloadFile } from "src/utils/fileDownloading.ts";
import { DeliveryPackageView } from "src/api/generated/erp/db/types/tables/deliveryPackageView.ts";
import { RefreshableElementProps, RefreshableElementRef } from "src/utils/useRefreshRef.ts";
import { useInputDialog } from "src/components/common/dialogs/input/useInputDialog.tsx";
import { useClientSideDataGridModel } from "src/components/common/dataGrid/gridModel/useClientSideDataGridModel.tsx";
import { useGenericDialog } from "src/components/common/dialogs/useGenericDialog.ts";
import { openFormOnDialog } from "src/components/common/dialogs/formDialog/openFormOnDialog.ts";
import { DeliverySourcePickingApi } from "src/api/generated/erp/delivery/api/deliverySourcePickingApi.ts";
import { DeliverySource } from "src/api/generated/erp/delivery/model/deliverySource.ts";
import { WarehousePickingObjectLocationView } from "src/api/generated/erp/db/types/tables/warehousePickingObjectLocationView.ts";
import { DeliveryPackagePickingForm } from "src/components/views/erp/delivery/deliveryPackage/picking/DeliveryPackagePickingForm.tsx";

export interface DeliverySourcePickingDataGridProps extends RefreshableElementProps {
	deliverySource: DeliverySource;
	deliveryPackage: DeliveryPackageView | undefined;
	pickedLinesViewRefreshRef: RefreshableElementRef;
	reservableLinesViewRefreshRef: RefreshableElementRef;
	deliverySourceHasAutomaticReservation: boolean;
	revertReservation: (params: {
		pickingObjectLocation: WarehousePickingObjectLocationView;
		quantityToRevertInBaseUnit: number;
	}) => Promise<unknown>;
}

export const DeliverySourcePickingDataGrid = ({
	deliverySource,
	deliveryPackage,
	refreshRef,
	pickedLinesViewRefreshRef,
	reservableLinesViewRefreshRef,
	deliverySourceHasAutomaticReservation,
	revertReservation,
}: DeliverySourcePickingDataGridProps) => {
	const { dataGridProps, refreshData, onlySelectedRow, selectedRows } = useClientSideDataGridModel({
		fetchData: () =>
			DeliverySourcePickingApi.getPickableLocationsForPackageSource({
				source: deliverySource,
			}),
		gridId: "AC8A87916768FBE8",
		getRowId: (row) => row.warehousePickingObjectLocationId,
		initialParams: {},
		refreshRef,
	});

	const showInputDialog = useInputDialog();
	const { openDialog } = useGenericDialog();

	return (
		<ControlledAsyncCrudDataGrid<WarehousePickingObjectLocationView>
			checkboxSelection
			columns={[
				integerColumn({
					field: "objectLineNumber",
					headerName: i18n.t("line_number"),
				}),
				textColumn({
					field: "configurationOrPartDescription",
					headerName: i18n.t("part"),
					width: 300,
				}),
				textColumn({
					field: "objectMark",
					headerName: i18n.t("mark"),
					width: 300,
				}),
				floatColumn({
					field: "reservedQuantity",
					headerName: i18n.t("pickable_quantity"),
					width: 150,
				}),
				textColumn({
					field: "partUnit",
					headerName: i18n.t("unit"),
				}),
				textColumn({
					field: "locationCode",
					headerName: i18n.t("warehouse_location"),
				}),
			]}
			actionBarComponents={
				<>
					<AsyncButton
						label={i18n.t("picking_list")}
						variant={"outlined"}
						icon={faPrint}
						onClick={async () => {
							const fileHandle = await DeliverySourcePickingApi.printDeliveryPackageSourcePickingList({
								source: deliverySource,
							});
							downloadFile(fileHandle);
						}}
					/>
					<AsyncButton
						label={i18n.t("pick")}
						variant={"outlined"}
						icon={faBoxOpen}
						disabled={
							selectedRows.length === 0 ? i18n.t("select_line")
							: deliveryPackage === undefined ?
								i18n.t("select_package")
							: deliveryPackage.state !== "INITIAL" ?
								i18n.t("package_is_picked")
							:	false
						}
						onClick={async () => {
							if (deliveryPackage?.deliveryPackageId == null) return;
							openFormOnDialog({
								openDialog: openDialog,
								title: i18n.t("pick"),
								size: "md",
								component: DeliveryPackagePickingForm,
								props: {
									deliveryPackageId: deliveryPackage.deliveryPackageId,
									pickingObjectLocations: selectedRows,
								},
								onSubmit: async () => {
									await Promise.all([pickedLinesViewRefreshRef?.refresh(), refreshData()]);
								},
							});
						}}
					/>
					{!deliverySourceHasAutomaticReservation && (
						<AsyncButton
							label={i18n.t("revert_reservation")}
							variant={"outlined"}
							icon={faUndo}
							disabled={
								!onlySelectedRow ? i18n.t("select_a_single_row")
								: onlySelectedRow.objectAcquisitionMethod !== "WAREHOUSE" ?
									i18n.t("reservation_can_be_reverted_only_for_lines_with_warehouse_acquisition")
								:	undefined
							}
							onClick={async () => {
								if (!onlySelectedRow) return;
								const revertQuantity = await showInputDialog({
									title: i18n.t("quantity_to_cancel"),
									type: "decimal",
									defaultValue: onlySelectedRow.reservedQuantity,
									required: true,
								});
								if (revertQuantity === undefined) return;
								await revertReservation({
									pickingObjectLocation: onlySelectedRow,
									quantityToRevertInBaseUnit: revertQuantity,
								});
								await Promise.all([reservableLinesViewRefreshRef?.refresh(), refreshData()]);
							}}
						/>
					)}
				</>
			}
			{...dataGridProps}
		/>
	);
};
