import { Box, Typography, TypographyProps } from "@mui/material";

export const CenteredTypography = (props: TypographyProps) => {
	return (
		<Box
			sx={{
				display: "flex",
				flex: 1,
				justifyContent: "center",
				alignItems: "center",
				padding: "1rem"
			}}
		>
			<Typography {...props} />
		</Box>
	);
};
