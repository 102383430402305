// This file is automatically generated. Do not edit manually.

import { ServerSideDataModelRequest } from "src/api/generated/common/dataGrids/serverSideDataModelRequest";
import { ServerSideDataModelResult } from "src/api/generated/common/dataGrids/serverSideDataModelResult";
import { SalesPartView } from "src/api/generated/erp/db/types/tables/salesPartView";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace SalesPartApi {
	export async function searchSalesParts(args: {
		searchQuery?: string;
		partId: number | null | undefined;
		onlyDefaultSite?: boolean;
		showInactiveSalesParts?: boolean;
		dataModelRequest: ServerSideDataModelRequest;
	}): Promise<ServerSideDataModelResult<SalesPartView>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/salesParts/searchSalesParts",
			method: "POST",
			data: {
				searchQuery: args.searchQuery,
				partId: args.partId,
				onlyDefaultSite: args.onlyDefaultSite,
				showInactiveSalesParts: args.showInactiveSalesParts,
				dataModelRequest: args.dataModelRequest,
			},
		});
		return response as ServerSideDataModelResult<SalesPartView>;
	}

	export async function getSalesPartOptions(args: {
		siteId: number;
		searchQuery: string;
		currentSelection: number | null | undefined;
		onlyIfCanBeUsedOnCustomerOrderLine?: boolean;
		limit?: number;
	}): Promise<Array<SalesPartView>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/salesParts/getSalesPartOptions",
			method: "POST",
			data: {
				siteId: args.siteId,
				searchQuery: args.searchQuery,
				currentSelection: args.currentSelection,
				onlyIfCanBeUsedOnCustomerOrderLine: args.onlyIfCanBeUsedOnCustomerOrderLine,
				limit: args.limit,
			},
		});
		return response as Array<SalesPartView>;
	}

	export async function getSalesPartDefaultPrice(args: {
		salesPartId: number;
		customerId: number;
		withVat: boolean;
		vatCodeId: number;
	}): Promise<number> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/salesParts/getSalesPartDefaultPrice",
			method: "POST",
			data: {
				salesPartId: args.salesPartId,
				customerId: args.customerId,
				withVat: args.withVat,
				vatCodeId: args.vatCodeId,
			},
		});
		return response as number;
	}
}
