import { SitePartTestConfigurationResultBomApi } from "src/api/generated/erp/configurator/api/configuratorTesting/sitePartTestConfigurationResultBomApi.ts";
import { TestConfigurationResultViewCommonProps } from "src/components/views/erp/configurator/configuratorTesting/TestConfigurationResultViewCommonProps.ts";
import { SitePartTestConfiguratorResultsView } from "src/components/views/erp/configurator/configuratorTesting/sitePart/SitePartTestConfiguratorResultsView.tsx";
import { TestConfigurationBomDataGridBase } from "../TestConfigurationBomDataGridBase";

export const SitePartTestConfigurationBomDataGrid = (
	props: TestConfigurationResultViewCommonProps,
) => {
	const { configurationSessionId, configuratorType, propertyValues } = props;

	return (
		<TestConfigurationBomDataGridBase
			{...props}
			fetchRows={() =>
				SitePartTestConfigurationResultBomApi.getTestConfigurationResultBom({
					configurationSessionId,
					configuratorType,
					propertyValues,
				})
			}
			subConfigurationResultsView={SitePartTestConfiguratorResultsView}
		/>
	);
};
