// This file is automatically generated. Do not edit manually.

import { Item } from "src/api/generated/postgres/db/types/ext_resourcing/tables/item";
import { ControlChart } from "src/api/generated/postgres/db/types/public_/tables/controlChart";
import { ItemControlChart } from "src/api/generated/postgres/db/types/ext_resourcing/tables/itemControlChart";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace ResourceItemsDataGridApi {
	export async function getItems(args: {
		searchQuery?: string;
		resourceId: number;
		disableItemId?: number | null | undefined;
	}): Promise<Array<Item>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/spc/resourceItems/getItems",
			method: "POST",
			data: {
				searchQuery: args.searchQuery,
				resourceId: args.resourceId,
				disableItemId: args.disableItemId,
			},
		});
		return response as Array<Item>;
	}

	export async function getConnectableControlChartOptions(args: {
		sourceResourceItemId: number;
		targetResourceItemId: number;
	}): Promise<Array<ControlChart>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/spc/resourceItems/getConnectableControlChartOprtions",
			method: "POST",
			data: {
				sourceResourceItemId: args.sourceResourceItemId,
				targetResourceItemId: args.targetResourceItemId,
			},
		});
		return response as Array<ControlChart>;
	}

	export async function connectControlChartToResourceItem(args: {
		controlChartId: number;
		resourceItemId: number;
	}): Promise<ItemControlChart> {
		const response = await makeAxiosRequest({
			url: "/api/ext/spc/resourceItems/connectControlChartToResourceItem",
			method: "POST",
			data: {
				controlChartId: args.controlChartId,
				resourceItemId: args.resourceItemId,
			},
		});
		return response as ItemControlChart;
	}

	export async function getChartCopySourceResourceItemOptions(args: {
		targetResourceItemId: number;
	}): Promise<Array<Item>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/spc/resourceItems/getChartCopySourceResourceItemOptions",
			method: "POST",
			data: {
				targetResourceItemId: args.targetResourceItemId,
			},
		});
		return response as Array<Item>;
	}

	export async function copyResourceItemControlCharts(args: {
		sourceResourceItemId: number;
		targetResourceItemId: number;
	}): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/spc/resourceItems/copyResourceItemControlCharts",
			method: "POST",
			data: {
				sourceResourceItemId: args.sourceResourceItemId,
				targetResourceItemId: args.targetResourceItemId,
			},
		});
		return response as void;
	}
}
