import { EnvironmentId } from "./types";
import {
	getOrInitAppStateStorage,
	setAppStateStorage,
} from "./appState";

export const setSelectedEnvironmentIdStorage = (
	key: EnvironmentId
): EnvironmentId => {
	const appState = getOrInitAppStateStorage();
	setAppStateStorage({ ...appState, selectedEnvAssociationId: key });
	return key;
};

export const getOrInitSelectedEnvironmentIdStorage = (): EnvironmentId => {
	return getOrInitAppStateStorage().selectedEnvAssociationId;
};
