// This file is automatically generated. Do not edit manually.

import { AccountingCode } from "src/api/generated/erp/db/types/tables/accountingCode";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace AccountingCodesApi {
	export async function getAccountingCodesOfDimension(args: {
		dimensionId: number;
		includeInactiveCodes: boolean;
	}): Promise<Array<AccountingCode>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/accounting/accountingCodes/getAccountingCodesOfDimension",
			method: "POST",
			data: {
				dimensionId: args.dimensionId,
				includeInactiveCodes: args.includeInactiveCodes,
			},
		});
		return response as Array<AccountingCode>;
	}

	export async function save(args: { accountingCodes: Array<AccountingCode> }): Promise<Array<AccountingCode>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/accounting/accountingCodes/save",
			method: "POST",
			data: {
				accountingCodes: args.accountingCodes,
			},
		});
		return response as Array<AccountingCode>;
	}

	export async function delete_(args: { accountingCodeIds: Array<number> }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/accounting/accountingCodes/delete",
			method: "POST",
			data: {
				accountingCodeIds: args.accountingCodeIds,
			},
		});
		return response as void;
	}

	export async function setAccountingCodeIsActive(args: {
		accountingCodeId: number;
		isActive: boolean;
	}): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/accounting/accountingCodes/setAccountingCodeIsActive",
			method: "POST",
			data: {
				accountingCodeId: args.accountingCodeId,
				isActive: args.isActive,
			},
		});
		return response as void;
	}
}
