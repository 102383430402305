// This file is automatically generated. Do not edit manually.

import { ProjectActivityView } from "src/api/generated/erp/db/types/tables/projectActivityView";
import { ProjectView } from "src/api/generated/erp/db/types/tables/projectView";
import { SubProjectView } from "src/api/generated/erp/db/types/tables/subProjectView";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace ProjectTreeViewApi {
	export async function getTreeViewData(args: {
		searchQuery: string;
		showCompleted?: boolean;
		showCancelled?: boolean;
		onlyDefaultSite?: boolean;
		showTemplateProjects?: boolean;
		onlyProjectId?: number | null | undefined;
		pageSize: number;
		pageOffset: number;
	}): Promise<ProjectTreeViewApi_TreeViewData> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/projects/treeView/getTreeViewData",
			method: "POST",
			data: {
				searchQuery: args.searchQuery,
				showCompleted: args.showCompleted,
				showCancelled: args.showCancelled,
				onlyDefaultSite: args.onlyDefaultSite,
				showTemplateProjects: args.showTemplateProjects,
				onlyProjectId: args.onlyProjectId,
				pageSize: args.pageSize,
				pageOffset: args.pageOffset,
			},
		});
		return response as ProjectTreeViewApi_TreeViewData;
	}
}

export interface ProjectTreeViewApi_TreeViewData {
	activities: Array<ProjectActivityView>;
	projects: Array<ProjectView>;
	subProjects: Array<SubProjectView>;
	totalProjectCount: number;
}
