import {
	ProjectActivityMaterialEditApi,
	ProjectActivityMaterialEditApi_FormInitData,
} from "src/api/generated/erp/project/projectActivityMaterial/api/projectActivityMaterialEditApi.ts";
import { AsyncForm, AsyncFormContentParams } from "src/components/common/forms/AsyncForm.tsx";
import { FormCommonProps } from "src/components/common/forms/types.ts";
import { ProjectActivityMaterial } from "src/api/generated/erp/db/types/tables/projectActivityMaterial.ts";
import { FormAsyncSelectField } from "src/components/common/forms/fields/FormAsyncSelectField";
import i18n from "i18next";
import { requireRule } from "src/components/common/forms/validation.ts";
import { concatWithPipe } from "src/utils/strings.tsx";
import { FormEnumSelectField } from "src/components/common/forms/fields/FormEnumSelectField.tsx";
import { Typography } from "@mui/material";
import { PartView } from "src/api/generated/erp/db/types/tables/partView.ts";
import { FormTextField } from "src/components/common/forms/fields/FormTextField.tsx";
import { FormDateField } from "src/components/common/forms/fields/FormDateField.tsx";
import { useState } from "react";
import { FormSelectField } from "src/components/common/forms/fields/FormSelectField.tsx";
import { SupplierApi } from "src/api/generated/erp/purchase/suppliers/supplierApi.ts";
import { FormNumberField } from "src/components/common/forms/fields/FormNumberField.tsx";
import { SupplierPartView } from "src/api/generated/erp/db/types/tables/supplierPartView.ts";
import { getAcquisitionMethodLabels } from "src/api/generated/erp/db/types/enums/acquisitionMethod.ts";

export interface ProjectActivityMaterialLineFormProps extends FormCommonProps<number> {
	activityId: number;
	materialLineId: number | undefined;
}

interface FormValues extends ProjectActivityMaterial {
	part: PartView;
}

export const ProjectActivityMaterialForm = (props: ProjectActivityMaterialLineFormProps) => {
	const { activityId, materialLineId, onCompleted, onFormEdited } = props;
	return (
		<AsyncForm<ProjectActivityMaterialEditApi_FormInitData, FormValues, number>
			fetch={() =>
				ProjectActivityMaterialEditApi.getFormInitData({
					activityId,
					materialLineId,
				})
			}
			getDefaultValues={getDefaultValues}
			submit={submit}
			onCompleted={onCompleted}
			onFormEdited={onFormEdited}
			columns={2}
			render={(renderParams) => <FormContent {...renderParams} {...props} />}
		/>
	);

	function getDefaultValues({
		activity,
		materialLine,
		part,
		defaultLineNumber,
	}: ProjectActivityMaterialEditApi_FormInitData): Partial<FormValues> {
		if (materialLine != null)
			return {
				...materialLine,
				part: part ?? undefined,
			};
		else
			return {
				activityId: activityId,
				lineNumber: defaultLineNumber,
				demandDate: activity.plannedStartDate
			};
	}

	async function submit(values: FormValues) {
		const materialLine = {
			...values,
			part: undefined,
			partId: values.part?.partId,
		};
		if (materialLineId == null) {
			return await ProjectActivityMaterialEditApi.insert({ materialLine });
		} else {
			await ProjectActivityMaterialEditApi.update({ materialLine });
			return materialLineId;
		}
	}
};

interface FormContentProps
	extends ProjectActivityMaterialLineFormProps,
		AsyncFormContentParams<ProjectActivityMaterialEditApi_FormInitData, FormValues> {}

const FormContent = ({
	activityId,
	control,
	watch,
	setValue,
	data: { materialLine, supplierPartOptions: initialSupplierPartOptions },
}: FormContentProps) => {
	const isExistingRecord = materialLine != null;
	const materialIsAlreadyReleased = materialLine != null && materialLine.state === "RELEASED";

	const [supplierPartOptions, setSupplierPartOptions] = useState<SupplierPartView[]>(
		initialSupplierPartOptions ?? [],
	);

	const part = watch("part");
	const acquisitionMethod = watch("acquisitionMethod");

	return (
		<>
			<FormAsyncSelectField
				control={control}
				name={"part"}
				formValueType={"option"}
				label={i18n.t("part")}
				getOptionKey={(o: PartView) => o.partId}
				getOptionLabel={(o) => concatWithPipe(o.partNo, o.partDescription_1, o.partDescription_2)}
				fetchOptions={({ currentSelection, searchQuery }) =>
					ProjectActivityMaterialEditApi.getPartOptions({
						activityId,
						currentSelection,
						searchQuery,
					})
				}
				rules={requireRule()}
				disabled={isExistingRecord}
				onChange={async (newPart) => {
					await onPartChanged(newPart);
				}}
			/>
			{part && <Typography alignSelf={"center"} children={`${i18n.t("base_unit")}: ${part.partUnit}`} />}
			<FormEnumSelectField
				control={control}
				name={"acquisitionMethod"}
				label={i18n.t("acquisition_method")}
				options={getAcquisitionMethodLabels()}
				rules={requireRule()}
				disabled={materialIsAlreadyReleased}
				startNewGridRow
			/>
			<FormNumberField
				control={control}
				name={"quantityInBaseUnit"}
				label={i18n.t("quantity")}
				rules={requireRule()}
				disabled={materialIsAlreadyReleased}
			/>
			<FormNumberField
				control={control}
				name={"lineNumber"}
				label={i18n.t("line_no")}
				disabled={materialIsAlreadyReleased}
				type={"integer"}
			/>
			<FormTextField
				control={control}
				name={"mark"}
				label={i18n.t("mark")}
				disabled={materialIsAlreadyReleased}
			/>
			<FormDateField
				control={control}
				name={"demandDate"}
				label={i18n.t("demand_date")}
				rules={requireRule()}
				disabled={materialIsAlreadyReleased}
			/>
			{["PURCHASE_TRANSIT", "PURCHASE_DIRECT"].includes(acquisitionMethod) && (
				<FormSelectField
					control={control}
					name={"supplierId"}
					label={i18n.t("supplier")}
					options={supplierPartOptions}
					getOptionKey={(o) => o.supplierId}
					getOptionLabel={(o) => o.supplierName}
					rules={requireRule()}
					disabled={materialIsAlreadyReleased}
					disableClearable
				/>
			)}
		</>
	);

	async function onPartChanged(part: PartView | null) {
		setValue("acquisitionMethod", part?.acquisitionMethod);

		const newPartSupplierParts =
			part == null ?
				[]
			:	await SupplierApi.getPartSupplierOptions({
					partId: part.partId,
				});
		setSupplierPartOptions(newPartSupplierParts);
		setValue("supplierId", newPartSupplierParts[0]?.supplierId);
	}
};
