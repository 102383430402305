import { useMemo } from "react";
import { AavoViewsContext, AavoViewsDispatchContext } from "./aavoViewsContext";
import { LegacyModalView } from "./LegacyModalView";
import { getErrorViewIdFromParams, isErrorViewDefinition } from "./utils";
import i18n from "i18next";
import { ErrorDialog } from "src/components/common/dialogs/errorDialog/ErrorDialog.tsx";
import { useContextOrThrow } from "src/utils/useContextOrThrow.tsx";
import { logError } from "src/errorHandling/errorLogging.ts";
import { useStartUpData } from "src/contexts/useStartUpData.ts";

export const LegacyModalViews = () => {
	const startUpData = useStartUpData();
	const { state } = useContextOrThrow(AavoViewsContext);
	const dispatch = useContextOrThrow(AavoViewsDispatchContext);
	const modals = state.legacyModals;
	return useMemo(() => {
		return (
			<>
				{modals.map((modalDefinition, index) => {
					if (isErrorViewDefinition(modalDefinition.view)) {
						const error = logError(
							`${i18n.t("error")}: ${i18n.t(
								"you_do_not_have_permissions_to_view_with_id",
								{
									viewId: getErrorViewIdFromParams(modalDefinition.view),
								},
							)}`,
						);
						return (
							<ErrorDialog
								key={`error-modal-${modalDefinition.uuid}`}
								closeDialog={() => {
									dispatch({
										type: "closeLegacyModalWithUuid",
										uuid: modalDefinition.uuid,
									});
								}}
								error={error}
							/>
						);
					}
					return (
						<LegacyModalView
							key={`aavo-legacy-modal-view-${modalDefinition.uuid}`}
							modalViewDefinition={modalDefinition}
							startUpData={startUpData}
							viewDefinition={modalDefinition.view}
							onClose={() => {
								dispatch({
									type: "closeLegacyModalWithUuid",
									uuid: modalDefinition.uuid,
								});
							}}
							isUppermostView={index === 0}
						/>
					);
				})}
			</>
		);
	}, [modals, dispatch, startUpData]);
};
