// This file is automatically generated. Do not edit manually.

import { t } from "i18next";

export const CountryValues = ["FINLAND"] as const;

export type Country = (typeof CountryValues)[number];

export const getCountryLabels = () => ({
	FINLAND: t("finland"),
});

export const getCountryLabel = (value: Country): string => {
	return getCountryLabels()[value];
};
