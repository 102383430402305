import { SurveyFieldValue } from "src/api/generated/io/aavo/applications/db/erp/types/surveyFieldValue.ts";
import { SurveyFormField } from "src/api/generated/erp/db/types/tables/surveyFormField.ts";
import { dateTimeColumn, enumColumn, integerColumn, textColumn } from "src/components/common/dataGrid/columns.tsx";
import i18n from "i18next";
import { getSurveySubmissionObjectTypeLabel } from "src/api/generated/erp/db/types/enums/surveySubmissionObjectType.ts";
import { getSurveySubmissionStateLabels } from "src/api/generated/erp/db/types/enums/surveySubmissionState.ts";
import { SurveySubmissionView } from "src/api/generated/erp/db/types/tables/surveySubmissionView.ts";
import { AavoGridColDef } from "src/components/common/dataGrid/AavoDataGrid.tsx";

export function surveyFieldValueToString(value: SurveyFieldValue | null | undefined): string {
	if (value == null) return "";
	switch (value.type) {
		case "string":
			return value.value;
		case "number":
			return value.value.toString();
		default:
			return "";
	}
}

export function labelWithRequiredMark(field: SurveyFormField): string {
	return field.required ? `${field.label} *` : field.label;
}

export function getSurveySubmissionDataGridColumns<TRowExtension extends SurveySubmissionView>({
	showFormName = true,
	showRevision = false,
}: {
	showFormName?: boolean;
	showRevision?: boolean;
}): AavoGridColDef<SurveySubmissionView & TRowExtension>[] {
	return [
		integerColumn({
			field: "surveySubmissionId",
			headerName: i18n.t("number_shortened"),
		}),
		showFormName &&
			textColumn({
				field: "surveyFormName",
				headerName: i18n.t("survey"),
				width: 120,
			}),
		showRevision &&
			integerColumn({
				field: "surveyFormRevisionNumber",
				headerName: i18n.t("revision"),
				width: 80,
			}),
		textColumn({
			field: "object" as any,
			filterable: false,
			sortable: false,
			headerName: i18n.t("object"),
			width: 200,
			valueGetter: (_, row) =>
				row.objectType == null || row.objectId == null ?
					""
				:	getSurveySubmissionObjectTypeLabel(row.objectType) +
					" " +
					row.objectId +
					" | " +
					row.objectDescription,
		}),
		enumColumn({
			field: "state",
			headerName: i18n.t("state"),
			enumLabels: getSurveySubmissionStateLabels(),
		}),
		dateTimeColumn({
			field: "submittedAt",
			headerName: i18n.t("submitted_at"),
			width: 150,
		}),
		textColumn({
			field: "submittedByUserName",
			headerName: i18n.t("submitted_by"),
		}),
		dateTimeColumn({
			field: "checkedAt",
			headerName: i18n.t("checked_at"),
			width: 150,
		}),
		textColumn({
			field: "checkedByUserName",
			headerName: i18n.t("checked_by"),
		}),
		dateTimeColumn({
			field: "approvedAt",
			headerName: i18n.t("approved_at"),
			width: 150,
		}),
		textColumn({
			field: "approvedByUserName",
			headerName: i18n.t("approved_by"),
		}),
	];
}
