// This file is automatically generated. Do not edit manually.

import { PurchaseOrderState } from "src/api/generated/erp/db/types/enums/purchaseOrderState";
import { ServerSideDataModelRequest } from "src/api/generated/common/dataGrids/serverSideDataModelRequest";
import { ServerSideDataModelResult } from "src/api/generated/common/dataGrids/serverSideDataModelResult";
import { PurchaseOrderView } from "src/api/generated/erp/db/types/tables/purchaseOrderView";
import { AppUser } from "src/api/generated/postgres/db/types/role_management/tables/appUser";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace PurchaseOrdersDataGridApi {
	export async function getData(args: {
		onlyPurchaseOrderId: number | null | undefined;
		searchQuery: string;
		stateFilter: Array<PurchaseOrderState>;
		responsiblePersonId: number | null | undefined;
		cancelledOrders: boolean;
		receivedOrders: boolean;
		onlyDefaultSite: boolean;
		dataModelRequest: ServerSideDataModelRequest;
	}): Promise<ServerSideDataModelResult<PurchaseOrderView>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/purchase/purchaseOrder/purchaseOrderDataGrid/getData",
			method: "POST",
			data: {
				onlyPurchaseOrderId: args.onlyPurchaseOrderId,
				searchQuery: args.searchQuery,
				stateFilter: args.stateFilter,
				responsiblePersonId: args.responsiblePersonId,
				cancelledOrders: args.cancelledOrders,
				receivedOrders: args.receivedOrders,
				onlyDefaultSite: args.onlyDefaultSite,
				dataModelRequest: args.dataModelRequest,
			},
		});
		return response as ServerSideDataModelResult<PurchaseOrderView>;
	}

	export async function getResponsiblePersonFilterOptions(args: {
		currentSelection: number | null | undefined;
		userNameSearchQuery: string;
	}): Promise<Array<AppUser>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/purchase/purchaseOrder/purchaseOrderDataGrid/getResponsiblePersonFilterOptions",
			method: "POST",
			data: {
				currentSelection: args.currentSelection,
				userNameSearchQuery: args.userNameSearchQuery,
			},
		});
		return response as Array<AppUser>;
	}
}
