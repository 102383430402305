import { CostEventPriceListLine } from "src/api/generated/erp/db/types/tables/costEventPriceListLine.ts";
import { CostEventPriceListLineDataGridApi } from "src/api/generated/erp/common/costEvents/costEventPriceListLineDataGridApi.ts";
import {
	dateColumn,
	floatColumn,
	singleSelectColumn,
} from "../../../../common/dataGrid/columns.tsx";
import i18n from "i18next";
import { CostEventPriceList } from "src/api/generated/erp/db/types/tables/costEventPriceList.ts";
import { dataGridPersistentStateProps } from "../../../../common/dataGrid/dataGridStateStorage.ts";
import { useParameterizedAsyncData } from "src/utils/async/parameterizedAsyncData.ts";
import { AsyncRender } from "../../../../common/async/AsyncRender.tsx";
import { CrudDataGrid } from "../../../../common/dataGrid/crud/CrudDataGrid.tsx";

export interface CostEventPriceListLineDataGridProps {
	costEventPriceList: CostEventPriceList;
}

export const CostEventPriceListLineDataGrid = ({
	costEventPriceList,
}: CostEventPriceListLineDataGridProps) => {
	const { dataAsync, refresh } = useParameterizedAsyncData({
		fetchData: CostEventPriceListLineDataGridApi.getLines,
		initialParams: {
			costEventPriceListId: costEventPriceList.costEventPriceListId,
		},
	});
	return (
		<AsyncRender
			asyncData={dataAsync}
			content={({ costEventPriceListLines, costEventItems }) => (
				<CrudDataGrid<CostEventPriceListLine>
					rows={costEventPriceListLines}
					refreshData={refresh}
					editMode={"row"}
					getRowId={(row) => row.costEventPriceListLineId}
					remove={{
						type: "enabled",
						action: async (params) =>
							await CostEventPriceListLineDataGridApi.delete_({
								costEventPriceListLines: params.items,
							}),
					}}
					save={{
						type: "enabled",
						action: async (params) =>
							await CostEventPriceListLineDataGridApi.save({
								costEventPriceListLines: params.items,
							}),
					}}
					add={{
						type: "enabled",
						action: async () => {
							return {
								costEventPriceListId: costEventPriceList.costEventPriceListId,
							};
						},
					}}
					columns={[
						singleSelectColumn({
							headerName: i18n.t("item"),
							field: "costEventItemId",
							editable: true,
							valueOptions: costEventItems.map((item) => ({
								value: item.costEventItemId,
								label: `${item.costEventItemId} | ${item.itemName}`,
							})),
							validate: "required",
						}),
						floatColumn({
							headerName: i18n.t("cost"),
							field: "cost",
							editable: true,
							validate: "required",
						}),
						floatColumn({
							headerName: i18n.t("price"),
							field: "price",
							editable: true,
							validate: "required",
						}),
						dateColumn({
							headerName: i18n.t("valid_from"),
							field: "validFrom",
							editable: true,
							validate: "required",
							width: 150,
						}),
					]}
					{...dataGridPersistentStateProps("E6FC38776E0C38A7")}
				/>
			)}
		/>
	);
};
