// This file is automatically generated. Do not edit manually.

import { t } from "i18next";

export const BillingPlanModelLineTypeValues = ["SUM", "PERCENT", "REMAINDER"] as const;

export type BillingPlanModelLineType = (typeof BillingPlanModelLineTypeValues)[number];

export const getBillingPlanModelLineTypeLabels = () => ({
	SUM: t("sum"),
	PERCENT: t("percent"),
	REMAINDER: t("remainder"),
});

export const getBillingPlanModelLineTypeLabel = (value: BillingPlanModelLineType): string => {
	return getBillingPlanModelLineTypeLabels()[value];
};
