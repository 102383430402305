import { LegacyAavoView } from "./LegacyAavoView";
import { View, ViewAssociation } from "./types";
import { makeAxiosRequest } from "src/api/utils/apiClient";
import { AsyncFetchRender } from "../../common/async/AsyncFetchRender.tsx";
import { StartUpData } from "src/api/mainApi";

export const LegacySettingsView = () => {
	return (
		<AsyncFetchRender
			fetch={getSettingsView}
			content={(data) => {
				return (
					<LegacyAavoView
						isOnForeground={true}
						isUppermostView={true}
						viewDefinition={data}
						startUpData={EMPTY_STARTUP_DATA}
					/>
				);
			}}
		/>
	);
};

const getSettingsView = async (): Promise<ViewAssociation> => {
	return await makeAxiosRequest({
		url: "api/user/settingsView",
		method: "GET",
	});
};

const EMPTY_VIEW: View = {
	id: "",
	displayName: "",
	groupId: null,
	icon: "home",
	pos: [],
	staticParams: [],
};

const EMPTY_STARTUP_DATA: StartUpData = {
	appBarView: {
		showDirectly: false,
		view: EMPTY_VIEW,
	},
	views: {},
	viewGroups: {},
	codeSeparators: [],
	defaultParameters: [],
	environments: [],
	identityOptions: [],
	frontendViews: [],
	defaultViewId: null
};
