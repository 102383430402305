import { AsyncButton, AsyncButtonProps } from "src/components/common/buttons/AsyncButton";
import i18n from "i18next";
import { faTable } from "@fortawesome/pro-regular-svg-icons";
import { PartConfigurationInspectingView } from "src/components/views/erp/configurator/inspecting/PartConfigurationInspectingView.tsx";
import { useGenericDialog } from "src/components/common/dialogs/useGenericDialog.ts";

export interface OpenPartConfigurationButtonProps extends Omit<AsyncButtonProps, "onClick"> {
	partConfigurationId: number | undefined | null; // hidden if null
	showBom?: boolean;
}

export const OpenPartConfigurationButton = ({
	partConfigurationId,
	showBom,
	variant = "menu",
	...other
}: OpenPartConfigurationButtonProps) => {
	const { openDialog } = useGenericDialog();

	if (partConfigurationId == null) return;

	return (
		<AsyncButton
			label={i18n.t("configuration")}
			icon={faTable}
			variant={variant}
			onClick={() => {
				openDialog(() => ({
					title: i18n.t("configuration"),
					content: (
						<PartConfigurationInspectingView
							partConfigurationId={partConfigurationId!}
							showBom={showBom}
						/>
					),
				}));
			}}
			{...other}
		/>
	);
};
