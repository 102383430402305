import { useMemo } from "react";
import { AavoViewsContext, AavoViewsDispatchContext } from "./aavoViewsContext";
import { KappaView } from "src/legacyViews/KappaView";
import { getLocalSettings } from "src/localSettings/utils";
import { LegacyAavoViewProps } from "./types";
import { PushNotificationsContext } from "src/notifications/pushNotificationsContext";
import { getSelectedIdentityStorage } from "src/storage/appState/identitySelection";
import { IncomingViewActionEvents } from "./events";
import { getOrInitAppStateStorage } from "src/storage/appState";
import { extractSpecificViewState } from "src/storage/appState/viewStates";
import { useContextOrThrow } from "src/utils/useContextOrThrow.tsx";

export const LegacyAavoView = ({
                                   startUpData,
                                   viewDefinition,
                                   isOnForeground,
                                   isUppermostView,
                                   modalViewDefinition
                               }: LegacyAavoViewProps) => {
    const dispatch = useContextOrThrow(AavoViewsDispatchContext);
    const {state} = useContextOrThrow(AavoViewsContext);
    const {legacyPushAction, onPushActionHandled} =
        useContextOrThrow(PushNotificationsContext);
    const viewActionEvents = state.viewActionEvents;
    const dragDropData = state.dragDropData;
    const appState = getOrInitAppStateStorage();
    const environmentId = appState.selectedEnvAssociationId;
    const selectedIdentity = getSelectedIdentityStorage();
    const allViewStates = appState.viewStates;
    return useMemo(() => {
        return (
            <KappaView
                startUpData={startUpData}
                viewDefinition={viewDefinition}
                isOnForeground={isOnForeground}
                isUppermostView={isUppermostView}
                incomingViewActionEvents={viewActionEvents}
                incomingPushAction={legacyPushAction}
                onPushActionHandled={(e) => {
                    onPushActionHandled(e.detail);
                }}
                handleActionEventsRaised={(e: IncomingViewActionEvents) => {
                    dispatch({
                        type: "setViewActionEvents",
                        events: e.detail,
                        startUpData: startUpData
                    });
                }}
                dragDropData={dragDropData}
                handleDragDropDataChanged={(e) => {
                    dispatch({
                        type: "setDragDropData",
                        dragDropData: e.detail
                    });
                }}
                localSettings={getLocalSettings()}
                identityId={selectedIdentity}
                branchKey={"prod"}
                environmentId={environmentId}
                viewState={extractSpecificViewState(
                    allViewStates,
                    viewDefinition.view.id
                )}
                allViewStates={allViewStates}
                sourceViewId={modalViewDefinition?.sourceViewId}
                viewParams={modalViewDefinition?.viewParams}
            />
        );
    }, [
        startUpData,
        viewDefinition,
        isOnForeground,
        isUppermostView,
        modalViewDefinition,
        viewActionEvents,
        legacyPushAction,
        onPushActionHandled,
        dragDropData,
        dispatch,
        selectedIdentity,
        environmentId,
        allViewStates
    ]);
};
