import { ConfigurationComponent } from "src/api/generated/erp/db/types/tables/configurationComponent.ts";
import { VerticalBox } from "src/components/common/box/VerticalBox";
import { RawHtmlContent } from "src/components/common/RawHtmlContent";

export interface TextComponentPreviewProps {
	component: ConfigurationComponent;
}

export const TextComponentPreview = ({ component }: TextComponentPreviewProps) => {
	return (
		<VerticalBox
			sx={{
				border: "1px solid",
				borderRadius: 1,
				padding: 1,
				flex: 1,
				minHeight: "2rem",
			}}
		>
			<RawHtmlContent html={component.textComponentContent} />
		</VerticalBox>
	);
};
