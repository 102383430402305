import { ConfigurationComponent } from "src/api/generated/erp/db/types/tables/configurationComponent.ts";
import Typography from "@mui/material/Typography";
import { VerticalBox } from "src/components/common/box/VerticalBox.tsx";

export interface SubConfiguratorComponentPreviewProps {
	component: ConfigurationComponent;
}

export const SubConfiguratorComponentPreview = ({ component }: SubConfiguratorComponentPreviewProps) => {
	return (
		<VerticalBox
			sx={{
				border: "1px solid",
				borderRadius: 1,
				padding: 1,
				flex: 1,
			}}
		>
			<Typography variant="h6">{component.label}</Typography>
		</VerticalBox>
	);
};
