import { FormCommonProps } from "src/components/common/forms/types.ts";
import { AsyncForm, AsyncFormContentParams } from "src/components/common/forms/AsyncForm.tsx";
import { DeepPartial } from "react-hook-form";
import {
	SalesCommissionLineEditApi,
	SalesCommissionLineEditApi_FormInitData,
} from "src/api/generated/erp/sales/salesCommissions/api/salesCommissionLineEditApi.ts";
import { SalesCommissionLine } from "src/api/generated/erp/db/types/tables/salesCommissionLine.ts";
import i18n from "i18next";
import { requireRule } from "src/components/common/forms/validation.ts";
import { FormNumberField } from "src/components/common/forms/fields/FormNumberField.tsx";
import { FormDateField } from "src/components/common/forms/fields/FormDateField.tsx";
import { FormTextField } from "src/components/common/forms/fields/FormTextField.tsx";
import { currentLocalDateAsIsoString } from "src/utils/dayjsUtils.ts";
import { SalesCommissionSourceType } from "src/components/views/erp/sales/salesCommissions/orderAndOfferViews/SalesCommissionSourceType.ts";
import { FormSelectField } from "src/components/common/forms/fields/FormSelectField.tsx";

export interface SalesCommissionLineFormProps extends FormCommonProps<number> {
	salesCommissionId: number;
	salesCommissionLineId: number | undefined;
	sourceType: SalesCommissionSourceType;
}

interface FormValues extends SalesCommissionLine {}

export const SalesCommissionLineForm = (props: SalesCommissionLineFormProps) => {
	const { onCompleted, onFormEdited, salesCommissionId, salesCommissionLineId } = props;
	return (
		<AsyncForm
			fetch={() =>
				SalesCommissionLineEditApi.getFormInitData({
					salesCommissionLineId,
				})
			}
			getDefaultValues={getDefaultValues}
			getUseFormProps={({ salesCommissionLine }) => ({
				disabled:
					salesCommissionLine != null && salesCommissionLine.salesCommissionLineState !== "INITIAL",
			})}
			submit={submit}
			onCompleted={onCompleted}
			onFormEdited={onFormEdited}
			render={(contentParams) => <FormContent {...props} {...contentParams} />}
		/>
	);

	function getDefaultValues({
		salesCommissionLine,
	}: SalesCommissionLineEditApi_FormInitData): DeepPartial<FormValues> {
		if (salesCommissionLine != null) return salesCommissionLine;
		else
			return {
				salesCommissionId: salesCommissionId,
				estimatedApprovalDate: currentLocalDateAsIsoString(),
			};
	}

	async function submit(values: FormValues) {
		if (salesCommissionLineId == null)
			return await SalesCommissionLineEditApi.insert({
				line: values,
			});
		else {
			await SalesCommissionLineEditApi.update({
				line: values,
			});
			return salesCommissionLineId;
		}
	}
};

interface FormContentProps
	extends SalesCommissionLineFormProps,
		AsyncFormContentParams<SalesCommissionLineEditApi_FormInitData, FormValues> {}

const FormContent = ({ control, sourceType, data: { nameOptions } }: FormContentProps) => {
	return (
		<>
			<FormSelectField
				control={control}
				name={"name"}
				label={i18n.t("name")}
				rules={requireRule()}
				options={nameOptions}
				getOptionKey={(o) => o}
				getOptionLabel={(o) => o}
				autoFocus
			/>
			<FormNumberField
				control={control}
				name={"sum"}
				label={i18n.t("sum") + " €"}
				rules={requireRule()}
			/>
			{sourceType === "CUSTOMER_ORDER" && (
				<FormDateField
					control={control}
					name={"estimatedApprovalDate"}
					label={i18n.t("estimated_approval")}
					rules={requireRule()}
				/>
			)}
			<FormTextField control={control} name={"note"} label={i18n.t("note")} multiline />
		</>
	);
};
