import Handlebars from "handlebars";
import { StartUpData } from "../api/mainApi";
import { KeyValuePair, keyValuePairsToRecord } from "src/utils/keyValuePair.ts";
import { Primitive } from "src/types/primitive.ts";

export const resolveHandlebarsTemplate = (
	template: string,
	params: Record<string, Primitive>
): string => {
	return Handlebars.compile(template)(params);
};

export const getHandlebarsParams = (
	startUpData?: StartUpData,
	staticParams?: KeyValuePair[],
	viewParams?: KeyValuePair[]
): Record<string, Primitive> => {
	return keyValuePairsToRecord([
		...(startUpData?.defaultParameters ?? []),
		...(staticParams ?? []),
		...(viewParams ?? [])
	]);
};
