import { SpcSamplingMethod } from "src/components/views/spc/basedata/controlCharts/form/samplingMethod/SpcSamplingMethod.tsx";
import i18n from "i18next";
import { FormNumberField } from "src/components/common/forms/fields/FormNumberField.tsx";
import { FormTextField } from "src/components/common/forms/fields/FormTextField.tsx";
import {requireRule} from "src/components/common/forms/validation.ts";

export const getRandomValueSamplingMethod = (): SpcSamplingMethod => {
	return {
		key: "demo_random_value",
		label: i18n.t("random_value"),
		renderParameterFields: ({ control }) => (
			<>
				<FormNumberField
					control={control}
					name={"samplingParams.param1"}
					label={i18n.t("lower_limit")}
					rules={requireRule()}
				/>
				<FormNumberField
					control={control}
					name={"samplingParams.param2"}
					label={i18n.t("upper_limit")}
					rules={requireRule()}
				/>
				<FormTextField control={control} name={"samplingParams.param3"} label={i18n.t("info1")} />
			</>
		),
	};
};
