import { MosaicNode, MosaicUncontrolledProps, MosaicWithoutDragDropContext } from "react-mosaic-component";
import { Box, Theme } from "@mui/material";
import "react-mosaic-component/react-mosaic-component.css";
import { AavoMosaicWindow, AavoMosaicWindowProps } from "src/components/common/mosaic/AavoMosaicWindow.tsx";
import { AavoMosaicNode, AavoMosaicTabContainerWithKey } from "./types";
import { mapAavoMosaicNodes } from "src/components/common/mosaic/nodeMapping.ts";
import { SxProps } from "@mui/system";
import { mergeSx } from "src/utils/styles.ts";
import { useMaybeStoredState } from "src/utils/useMaybeStoredState.ts";

export type AavoMosaicProps = Omit<MosaicUncontrolledProps<string>, "renderTile" | "initialValue"> &
	Pick<AavoMosaicWindowProps, "toolbarExtraComponents"> & {
		viewId?: string;
		layout: AavoMosaicNode;
		sx?: SxProps<Theme>;
	};

export const AavoMosaic = ({ viewId, layout: layoutProp, sx, toolbarExtraComponents, ...other }: AavoMosaicProps) => {
	const { rootNode: initialLayoutRootNode, allTabs } = mapAavoMosaicNodes(layoutProp);
	const storedLayoutStateKey = viewId != null ? `mosaic-layout--${viewId}` : undefined;
	const [layout, saveLayout] = useMaybeStoredState<MosaicNode<string> | null>(
		initialLayoutRootNode,
		storedLayoutStateKey,
	);

	const tabsByKeys: Record<string, AavoMosaicTabContainerWithKey> = Object.fromEntries(allTabs.map((tab) => [tab.key, tab]));

	return (
		<Box className={"aavo-mosaic"} sx={mergeSx(mosaicSx, sx)}>
			<MosaicWithoutDragDropContext<string>
				className={""}
				initialValue={layout}
				onChange={saveLayout}
				resize={{
					minimumPaneSizePercentage: 5,
				}}
				renderTile={(id, path) => (
					<AavoMosaicWindow
						viewId={viewId}
						tabContainerKey={id}
						path={path}
						tabContainersByKeys={tabsByKeys}
						resetLayout={() => saveLayout(initialLayoutRootNode)}
						toolbarExtraComponents={toolbarExtraComponents}
					/>
				)}
				{...other}
			/>
		</Box>
	);
};

const mosaicSx: SxProps<Theme> = {
	flex: 1,
	display: "flex",
	"& .mosaic-root": {
		top: 0,
		bottom: 0,
		left: 0,
		right: 0,
		margin: "-3px",
	},
	"& .mosaic-tile": {
		margin: "3px",
	},
	"& .mosaic-window .mosaic-window-toolbar": {
		height: "1.75rem",
		justifyContent: "start",
	},
	"& .mosaic-split:hover": {
		backgroundColor: "primary.light",
		opacity: 0.2,
	},
	// Hide toolbar if it has no children.
	"& .mosaic-window-toolbar:not(:has(*))": {
		display: "none !important",
	},
};
