// This file is automatically generated. Do not edit manually.

import { WarehouseTransferState } from "src/api/generated/erp/db/types/enums/warehouseTransferState";
import { ServerSideDataModelRequest } from "src/api/generated/common/dataGrids/serverSideDataModelRequest";
import { ServerSideDataModelResult } from "src/api/generated/common/dataGrids/serverSideDataModelResult";
import { WarehouseTransferView } from "src/api/generated/erp/db/types/tables/warehouseTransferView";
import { Site } from "src/api/generated/erp/db/types/tables/site";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace WarehouseTransferDataGridApi {
	export async function getWarehouseTransfers(args: {
		searchQuery?: string;
		transferState: WarehouseTransferState;
		requestingSiteId: number | null | undefined;
		requestingWarehouseLocationCode: string | null | undefined;
		onlyTransferId: number | null | undefined;
		dataModelRequest: ServerSideDataModelRequest;
	}): Promise<WarehouseTransferDataGridApi_DataGridData> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/warehouse/warehouseTransfer/getWarehouseTransfers",
			method: "POST",
			data: {
				searchQuery: args.searchQuery,
				transferState: args.transferState,
				requestingSiteId: args.requestingSiteId,
				requestingWarehouseLocationCode: args.requestingWarehouseLocationCode,
				onlyTransferId: args.onlyTransferId,
				dataModelRequest: args.dataModelRequest,
			},
		});
		return response as WarehouseTransferDataGridApi_DataGridData;
	}
}

export interface WarehouseTransferDataGridApi_DataGridData {
	data: ServerSideDataModelResult<WarehouseTransferView>;
	siteOptions: Array<Site>;
}
