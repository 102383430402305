import { FormCommonProps } from "src/components/common/forms/types.ts";
import { AavoForm } from "src/components/common/forms/AavoForm.tsx";
import { Contact } from "src/api/generated/erp/db/types/tables/contact.ts";
import { FormTextField } from "src/components/common/forms/fields/FormTextField.tsx";
import i18n from "i18next";
import { requireRule } from "src/components/common/forms/validation.ts";
import { ContactApi } from "src/api/generated/erp/contacts/api/contactApi.ts";
import { ContactConnectedItemType } from "src/api/generated/erp/db/types/enums/contactConnectedItemType.ts";

export interface ContactFormProps extends FormCommonProps<number> {
	connectedItemType: ContactConnectedItemType;
	connectedItemId: number;
	contact?: Contact;
}

export const ContactForm = ({
	connectedItemType,
	connectedItemId,
	contact,
	onFormEdited,
	onCompleted,
}: ContactFormProps) => {
	return (
		<AavoForm<Contact, number>
			defaultValues={{
				connectedItemId: connectedItemId,
				connectedItemType: connectedItemType,
				...contact,
			}}
			onCompleted={onCompleted}
			onFormEdited={onFormEdited}
			submit={async (values) => {
				if (contact == null) {
					return await ContactApi.insert({ contact: values });
				} else {
					return await ContactApi.update({ contact: values });
				}
			}}
			render={({ control }) => (
				<>
					<FormTextField control={control} name={"name"} label={i18n.t("name")} rules={requireRule()} />
					<FormTextField control={control} name={"designation"} label={i18n.t("designation")} />
					<FormTextField control={control} name={"email"} label={i18n.t("email")} />
					<FormTextField control={control} name={"phone"} label={i18n.t("phone")} />
					<FormTextField control={control} name={"note"} label={i18n.t("note")} />
				</>
			)}
		/>
	);
};
