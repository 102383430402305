// This file is automatically generated. Do not edit manually.

import { ConfigurationComponentView } from "src/api/generated/erp/db/types/tables/configurationComponentView";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace ConfiguratorLibraryComponentsApi {
	export async function getLibraryVersionComponents(args: {
		configuratorLibraryVersionId: number;
	}): Promise<Array<ConfigurationComponentView>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/configurator/managing/libraryComponents/getLibraryVersionComponents",
			method: "POST",
			data: {
				configuratorLibraryVersionId: args.configuratorLibraryVersionId,
			},
		});
		return response as Array<ConfigurationComponentView>;
	}

	export async function getLatestLibraryVersionComponents(args: {
		configuratorLibraryId: number;
	}): Promise<Array<ConfigurationComponentView>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/configurator/managing/libraryComponents/getLatestLibraryVersionComponents",
			method: "POST",
			data: {
				configuratorLibraryId: args.configuratorLibraryId,
			},
		});
		return response as Array<ConfigurationComponentView>;
	}

	export async function getLibraryComponentIsReferencedByAny(args: {
		libraryComponentUuid: string;
	}): Promise<boolean> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/configurator/managing/libraryComponents/getLibraryComponentIsReferencedByAny",
			method: "POST",
			data: {
				libraryComponentUuid: args.libraryComponentUuid,
			},
		});
		return response as boolean;
	}
}
