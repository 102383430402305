import {AavoMosaic} from "src/components/common/mosaic/AavoMosaic.tsx";
import {ChartGroupSelectionView} from "src/components/views/spc/cChartEvents/searchView/ChartGroupSelectionView.tsx";
import i18n from "i18next";
import {CenteredTypography} from "src/components/common/CenteredTypography.tsx";
import {useState} from "react";
import {ChartGroupView} from "src/api/generated/postgres/db/types/public_/tables/chartGroupView.ts";
import {ControlChart} from "src/api/generated/postgres/db/types/public_/tables/controlChart.ts";
import {
    ControlChartsSelectionView
} from "src/components/views/spc/cChartEvents/searchView/ControlChartsSelectionView.tsx";
import {CChartEventSearchView} from "src/components/views/spc/cChartEvents/searchView/CChartEventSearchView.tsx";

export const CChartEventSearchPage = () => {
    const [selectedChartGroup, setSelectedChartGroup] = useState<ChartGroupView | undefined>(
        undefined,
    );
    const [selectedControlCharts, setSelectedControlCharts] = useState<ControlChart[]>([]);

    return (
        <AavoMosaic
            viewId={"2134E3A5A1FF737"}
            layout={{
                type: "row",
                splitPercentage: 20,
                first: {
                    type: "column",
                    first: {
                        type: "panel",
                        title: i18n.t("defect_group"),
                        content: (
                            <ChartGroupSelectionView
                                onChartGroupSelected={(chartGroup) => {
                                    setSelectedChartGroup(chartGroup);
                                    setSelectedControlCharts([]);
                                }}
                            />
                        ),
                    },
                    second: {
                        type: "panel",
                        title: i18n.t("control_charts"),
                        content: !selectedChartGroup ? (
                            <CenteredTypography>{i18n.t("select_defect_group")}</CenteredTypography>
                        ) : (
                            <ControlChartsSelectionView
                                key={selectedChartGroup.chartGroupId}
                                chartGroupId={selectedChartGroup.chartGroupId}
                                onRowSelectionChanged={(selectedRows) => {
                                    setSelectedControlCharts(selectedRows);
                                }}
                            />
                        ),
                    },
                },
                second: {
                    type: "panel",
                    title: i18n.t("events"),
                    content:
                        selectedControlCharts.length === 0 ? (
                            <CenteredTypography>
                                {i18n.t("select_control_charts")}
                            </CenteredTypography>
                        ) : (
                            <CChartEventSearchView
                                key={selectedControlCharts
                                    .map((controlChart) => controlChart.id)
                                    .join(",")}
                                controlChartIds={selectedControlCharts.map(
                                    (controlChart) => controlChart.id,
                                )}
                            />
                        ),
                },
            }}
        />
    );
};
