// This file is automatically generated. Do not edit manually.

import { BillingPlanModelLine } from "src/api/generated/erp/db/types/tables/billingPlanModelLine";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace BillingPlanModelLineApi {
	export async function getBillingPlanModelLines(args: {
		billingPlanModelId: number;
	}): Promise<Array<BillingPlanModelLine>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/billingPlanModelLine/getBillingPlanModelLines",
			method: "POST",
			data: {
				billingPlanModelId: args.billingPlanModelId,
			},
		});
		return response as Array<BillingPlanModelLine>;
	}
}
