// This file is automatically generated. Do not edit manually.

import { Resource } from "src/api/generated/postgres/db/types/ext_resourcing/tables/resource";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace ResourceEditApi {
	export async function getResource(args: { resourceId: number | null | undefined }): Promise<Resource | null> {
		const response = await makeAxiosRequest({
			url: "/api/ext/spc/resource/getResource",
			method: "POST",
			data: {
				resourceId: args.resourceId,
			},
		});
		return response === "" ? null : (response as Resource | null);
	}

	export async function insert(args: { resource: Resource }): Promise<number> {
		const response = await makeAxiosRequest({
			url: "/api/ext/spc/resource/insert",
			method: "POST",
			data: {
				resource: args.resource,
			},
		});
		return response as number;
	}

	export async function update(args: { resource: Resource }): Promise<number> {
		const response = await makeAxiosRequest({
			url: "/api/ext/spc/resource/update",
			method: "POST",
			data: {
				resource: args.resource,
			},
		});
		return response as number;
	}

	export async function delete_(args: { resourceIds: Array<number> }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/spc/resource/delete",
			method: "POST",
			data: {
				resourceIds: args.resourceIds,
			},
		});
		return response as void;
	}
}
