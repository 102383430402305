// This file is automatically generated. Do not edit manually.

import { t } from "i18next";

export const CatalogPartRevisionStateValues = ["INITIAL", "DESIGNED"] as const;

export type CatalogPartRevisionState = (typeof CatalogPartRevisionStateValues)[number];

export const getCatalogPartRevisionStateLabels = () => ({
	INITIAL: t("initial"),
	DESIGNED: t("designed"),
});

export const getCatalogPartRevisionStateLabel = (value: CatalogPartRevisionState): string => {
	return getCatalogPartRevisionStateLabels()[value];
};
