import {ClientSideDataGridModel} from "src/components/common/dataGrid/gridModel/ClientSideDataGridModel.tsx";
import {CustomerOrderLineView} from "src/api/generated/erp/db/types/tables/customerOrderLineView.ts";
import {
    booleanColumn,
    dateColumn,
    dateTimeColumn,
    enumColumn,
    floatColumn,
    integerColumn,
    textColumn,
} from "src/components/common/dataGrid/columns.tsx";
import i18n from "i18next";
import {getCustomerOrderLineStateLabels} from "src/api/generated/erp/db/types/enums/customerOrderLineState.ts";
import {getSalesPartTypeLabels} from "src/api/generated/erp/db/types/enums/salesPartType.ts";
import {getAcquisitionMethodLabels} from "src/api/generated/erp/db/types/enums/acquisitionMethod.ts";
import {
    CustomerOrderLinesDataGridContextMenuComponents
} from "src/components/views/erp/sales/customerOrderLine/CustomerOrderLinesDataGridContextMenuComponents.tsx";
import {
    CustomerOrderLinesDataGridActionBarComponents
} from "src/components/views/erp/sales/customerOrderLine/CustomerOrderLinesDataGridActionBarComponents.tsx";
import {CrudDataGrid} from "src/components/common/dataGrid/crud/CrudDataGrid.tsx";
import {CustomerOrderLineForm} from "src/components/views/erp/sales/customerOrderLine/CustomerOrderLineForm.tsx";
import {
    CustomerOrderLineDataGridApi
} from "src/api/generated/erp/sales/customerOrder/api/customerOrderLineDataGridApi.ts";
import {MenuCheckbox} from "src/components/common/contextMenu/MenuCheckbox.tsx";
import {useContext} from "react";
import {CustomerOrdersPageContext} from "src/components/views/erp/sales/customerOrder/CustomerOrdersPageContext.tsx";
import {
    ImportDocumentsToCustomerOrderLinesButton
} from "src/components/views/erp/sales/customerOrderLine/ImportDocumentsToCustomerOrderLinesButton.tsx";

export interface CustomerOrderLinesDataGridProps {
	customerOrderId: number;
	parentLineId: number | null;
}

export interface CustomerOrderLinesFilterParams {
	searchQuery: string;
}

export const CustomerOrderLinesDataGrid = ({
	customerOrderId,
	parentLineId,
}: CustomerOrderLinesDataGridProps) => {
	const refreshRef = useContext(CustomerOrdersPageContext)?.customerOrderLinesRefreshRef;
	return (
		<ClientSideDataGridModel
			refreshRef={refreshRef}
			gridId={"3ADC38DC6632BEF6"}
			fetchData={(params) =>
				CustomerOrderLineDataGridApi.getData({
					customerOrderId: customerOrderId,
					parentLineId: parentLineId,
					...params,
				})
			}
			getRows={(data) => data.customerOrderLines}
			getRowId={(row) => row.customerOrderLineId}
			initialParams={{
				searchQuery: "",
				showCancelledLines: false,
			}}
			render={({ data, onlySelectedRow, refreshData, dataGridProps, currentParams }) => {
				const { customerOrderSummary, customerOrder } = data;
				return (
					<CrudDataGrid<CustomerOrderLineView>
						columns={[
							integerColumn({
								field: "lineNumber",
								headerName: i18n.t("number_shortened"),
								width: 50,
							}),
							textColumn({
								field: "configurationOrPartDescription",
								headerName: i18n.t("part"),
								width: 250,
							}),
							floatColumn({
								field: "salesQuantityAsSalesUnits",
								headerName: i18n.t("ordered_quantity"),
							}),
							textColumn({
								field: "salesUnit",
								headerName: i18n.t("sales_unit"),
							}),
							textColumn({
								field: "mark",
								headerName: i18n.t("mark"),
								width: 200,
							}),
							parentLineId == null && [
								dateColumn({
									field: "plannedDeliveryDate",
									headerName: i18n.t("planned_delivery_date"),
									width: 160,
								}),
								floatColumn({
									field: "unitPrice",
									headerName: i18n.t("unit_price"),
									width: 90,
								}),
								floatColumn({
									field: "unitPriceWithVat",
									headerName: i18n.t("unit_price_with_vat"),
									width: 150,
								}),
								floatColumn({
									field: "discountPercentage",
									headerName: i18n.t("discount_percentage"),
									width: 80,
								}),
								floatColumn({
									field: "totalPrice",
									headerName: i18n.t("total_price"),
								}),
								floatColumn({
									field: "totalPriceWithVat",
									headerName: i18n.t("total_price_with_vat"),
									width: 155,
								}),
								textColumn({
									field: "salesPriceUnit",
									headerName: i18n.t("sales_price_unit"),
								}),
							],
							enumColumn({
								field: "customerOrderLineState",
								headerName: i18n.t("state"),
								enumLabels: getCustomerOrderLineStateLabels(),
							}),
							parentLineId != null &&
								textColumn({
									field: "packageNumber_1",
									headerName: i18n.t("package_number"),
								}),
							parentLineId != null &&
								textColumn({
									field: "packageNumber_2",
									headerName: i18n.t("sequence_number"),
								}),
							data.customerOrderLines.some((line) => line.partIsConfigurable === true) &&
								booleanColumn({
									field: "isAwaitingConfiguration",
									headerName: i18n.t("awaiting_configuration"),
									width: 155,
								}),
							enumColumn({
								field: "salesPartType",
								headerName: i18n.t("type"),
								enumLabels: getSalesPartTypeLabels(),
								width: 75,
							}),
							enumColumn({
								field: "acquisitionMethod",
								headerName: i18n.t("acquisition_method"),
								enumLabels: getAcquisitionMethodLabels(),
								width: 125,
							}),
							textColumn({
								field: "shopOrderBatchCode",
								headerName: i18n.t("shop_order_batch"),
							}),
							textColumn({
								field: "capacityQuantity",
								headerName: i18n.t("capacity_quantity"),
								width: 125,
							}),
							floatColumn({
								field: "unitCost",
								headerName: i18n.t("unit_cost"),
								width: 125,
							}),
							floatColumn({
								field: "totalCost",
								headerName: i18n.t("total_cost"),
								width: 135,
							}),
							parentLineId == null &&
								textColumn({
									field: "vatCodeName",
									headerName: i18n.t("vat_code"),
								}),
							dateTimeColumn({
								field: "releaseDate",
								headerName: i18n.t("released"),
								width: 130,
							}),
							textColumn({
								field: "partUnit",
								headerName: i18n.t("warehouse_unit"),
								width: 105,
							}),
							textColumn({
								field: "purchaseUnit",
								headerName: i18n.t("purchase_unit"),
								width: 90,
							}),
							dateColumn({
								field: "lastPickingDate",
								headerName: i18n.t("last_picking_date"),
								width: 155,
							}),
							dateColumn({
								field: "lastShippingDate",
								headerName: i18n.t("last_shipping_date"),
								width: 150,
							}),
							floatColumn({
								field: "reservedQuantity",
								headerName: i18n.t("reserved"),
								width: 60,
							}),
							floatColumn({
								field: "pickedQuantity",
								headerName: i18n.t("picked"),
								width: 65,
							}),
							floatColumn({
								field: "deliveredQuantity",
								headerName: i18n.t("delivered"),
								width: 70,
							}),
							floatColumn({
								field: "invoicedQuantity",
								headerName: i18n.t("invoiced"),
								width: 75,
							}),
							integerColumn({
								field: "customerOrderLineId",
								headerName: i18n.t("id"),
								width: 75,
							}),
						]}
						actionBarComponents={
							<CustomerOrderLinesDataGridActionBarComponents
								customerOrderId={customerOrderId}
								parentLineId={parentLineId}
								onlySelectedRow={onlySelectedRow}
								refreshData={refreshData}
								customerOrderSummary={customerOrderSummary}
							/>
						}
						actionBarMenuComponents={[
							<MenuCheckbox
								key={"showCancelled"}
								label={i18n.t("show_cancelled_items")}
								checked={currentParams.showCancelledLines}
								onChange={(checked) => refreshData({ showCancelledLines: checked })}
							/>,
							<ImportDocumentsToCustomerOrderLinesButton
								key={"importDocumentsToCustomerOrderLinesButton"}
								customerOrderId={customerOrderId}
							/>,
						]}
						rowContextMenuComponents={({
							row,
							allSelectedRows,
							onlySingleRowSelected,
							multipleRowsSelected,
						}) => (
							<CustomerOrderLinesDataGridContextMenuComponents
								row={row}
								allSelectedRows={allSelectedRows}
								onlySingleRowSelected={onlySingleRowSelected}
								multipleRowsSelected={multipleRowsSelected}
								refreshData={refreshData}
								parentLineId={parentLineId}
							/>
						)}
						{...dataGridProps}
						form={{
							editEnabled: true,
							addRowEnabled: !getCustomerOrderIsDelivered(),
							dialogSize: "lg",
							dialogTitle: i18n.t("customer_order_line"),
							component: ({ row, ...other }) => (
								<CustomerOrderLineForm
									customerOrderId={customerOrderId}
									customerOrderLineId={row?.customerOrderLineId}
									parentLineId={
										(row != null ? row?.parentLineId : parentLineId) ?? undefined
									}
									{...other}
								/>
							),
						}}
					/>
				);

				function getCustomerOrderIsDelivered() {
					return ["DELIVERED", "INVOICED", "CANCELLED"].includes(customerOrder.customerOrderState);
				}
			}}
		/>
	);
};
