// This file is automatically generated. Do not edit manually.

import { PartRevisionView } from "src/api/generated/erp/db/types/tables/partRevisionView";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace SitePartRevisionPriceConfiguratorFormApi {
	export async function getFormInitData(args: {
		partRevisionId: number;
	}): Promise<SitePartRevisionPriceConfiguratorFormApi_FormInitData> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sitePart/revision/priceConfiguratorForm/getFormInitData",
			method: "POST",
			data: {
				partRevisionId: args.partRevisionId,
			},
		});
		return response as SitePartRevisionPriceConfiguratorFormApi_FormInitData;
	}

	export async function submit(args: { partRevisionId: number; priceTransformationScript: string }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sitePart/revision/priceConfiguratorForm/submit",
			method: "POST",
			data: {
				partRevisionId: args.partRevisionId,
				priceTransformationScript: args.priceTransformationScript,
			},
		});
		return response as void;
	}
}

export interface SitePartRevisionPriceConfiguratorFormApi_FormInitData {
	partRevision: PartRevisionView;
}
