import { AsyncMenuButton } from "src/components/common/contextMenu/AsyncMenuButton.tsx";
import i18n from "i18next";
import { useInputDialog } from "src/components/common/dialogs/input/useInputDialog.tsx";
import { faFileCsv } from "@fortawesome/pro-regular-svg-icons";
import { ConfiguratorTableTab } from "src/api/generated/erp/db/types/tables/configuratorTableTab.ts";
import { uploadFile } from "src/utils/fileUploading.ts";
import { ConfiguratorTableTabContentEditApi } from "src/api/generated/erp/configurator/tables/api/configuratorTableTabContentEditApi.ts";

export interface ConfiguratorTableTabDataGridActionBarMenuProps {
	tab: ConfiguratorTableTab;
	refreshTabContent: () => Promise<unknown>;
}

export const ConfiguratorTableTabDataGridActionBarMenu = ({
	tab,
	refreshTabContent,
}: ConfiguratorTableTabDataGridActionBarMenuProps) => {
	const showInputDialog = useInputDialog();

	return [
		<AsyncMenuButton
			key={"importCsv"}
			label={i18n.t("import_csv")}
			icon={faFileCsv}
			onClick={async () => {
				const file = await showInputDialog({
					type: "singleFile",
					title: i18n.t("import_csv"),
					defaultValue: null,
					required: true,
				});
				if (file == null) return;

				const fileHandle = await uploadFile(file);

				await ConfiguratorTableTabContentEditApi.importContentFromFile({
					configuratorTableTabId: tab.configuratorTableTabId,
					fileHandle: fileHandle,
				});
				await refreshTabContent();
			}}
		/>,
	];
};
