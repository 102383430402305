import elmWebComponents from "./elmWebComponent";
import { Elm as ElmKappaViewEn } from "../../../elm-js-source/en/KappaView";
import { Elm as ElmKappaViewFi } from "../../../elm-js-source/fi/KappaView";
import { DateTime, Duration } from "luxon";
import styles from "../../../scss/views/kappaview.scss?inline";
import { setAndApplyLocalSettings } from "src/localSettings/utils";
import { saveViewState } from "../../../../storage/appState/viewStates";

export function registerKappaViewWebComponent(userLanguage) {
	const ElmKappaView =
		userLanguage === "en" ? ElmKappaViewEn : ElmKappaViewFi;
	elmWebComponents.register("aavo-view", ElmKappaView.Views.KappaView, {
		styles: styles,
		staticFlags: {},
		defaultFlags: {},
		portsToEvents: [
			{
				portName: "raiseActionEvents",
				eventName: "viewActionEventsRaised"
			},
			{
				portName: "raiseViewLabelChanged",
				eventName: "viewLabelChanged"
			},
			{
				portName: "setPushActionToHandled",
				eventName: "pushActionHandled"
			},
			{
				portName: "setDragDropData",
				eventName: "dragDropDataChanged"
			}
		],
		portProperties: [
			{
				propName: "incomingViewActionEvents",
				portName: "actionEventsReceived",
				type: Object,
				hasChanged: (oldVal, newVal) =>
					(oldVal || {}).version !== (newVal || {}).version
			},
			{
				propName: "isOnForeground",
				portName: "isOnForegroundChanged",
				type: Boolean
			},
			{
				propName: "isUppermostView",
				portName: "isUppermostViewChanged",
				type: Boolean
			},
			{
				propName: "incomingNdefMessage",
				portName: "ndefMessageRead",
				type: Object,
				hasChanged: (oldVal, newVal) =>
					(oldVal || {}).messageId !== (newVal || {}).messageId
			},
			{
				propName: "incomingPushAction",
				portName: "pushActionReceived",
				type: Object,
				hasChanged: (oldVal, newVal) =>
					(oldVal || {}).actionId !== (newVal || {}).actionId
			},
			{
				propName: "dragDropData",
				portName: "dragDataChanged",
				type: String
			}
		],
		portSubscriptions: [
			{
				portName: "focusElementWithCssSelector",
				handler: ({ rootElement, data: selector }) => {
					// Have to use hack with setTimeout, because field may not be rendered yet
					// when event is raised.
					setTimeout(
						() =>
							focusElementWithCssSelector(rootElement, selector),
						100
					);
				}
			},
			{
				portName: "saveLocalSettings",
				handler: ({ data: settings }) => {
					setAndApplyLocalSettings(settings);
				}
			},
			{
				portName: "openUrl",
				handler: ({ data }) => {
					window.open(data.url, data.targetName, data.specs);
				}
			},
			{
				portName: "saveViewState",
				handler: ({ data }) => {
					saveViewState(data.viewId, data.state);
				}
			},
			{
				portName: "executeScriptAction",
				handler: ({ data, elmElement }) => {
					const result = runScriptAction(data);
					elmElement.ports.scriptActionCompleted.send(result);
				}
			}
		]
	});
}

function focusElementWithCssSelector(rootElement, selector) {
	const element = rootElement.querySelector(selector);
	if (!element) return;
	element.focus();
}

function runScriptAction(actionRequest) {
	const func = new Function(
		"actionParams",
		"actionRunner",
		"libraries",
		actionRequest.script
	);
	try {
		const libraries = {
			Datetime: DateTime, // TODO remove this
			DateTime: DateTime,
			Duration: Duration
		};
		const functionResult =
			func(actionRequest.params, null, libraries) || {};
		return {
			type: "success",
			actionInitiator: actionRequest.actionInitiator,
			returnedParams: functionResult.params || {}
		};
	} catch (e) {
		return {
			type: "failure",
			actionInitiator: actionRequest.actionInitiator,
			errorMessage: e.toString()
		};
	}
}
