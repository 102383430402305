import { IconName } from "@fortawesome/pro-regular-svg-icons";
import { AsyncState } from "src/utils/async/asyncState";
import { ReactNode } from "react";

export interface MainMenuItem {
	key: string;
	parentKey: string | undefined;
	label: string | AsyncState<string>;
	icon: IconName;
	type: MainMenuItemType;
	children: MainMenuItem[];
	expanded?: boolean;
	isFavoritesGroupShortcutItem?: boolean;
	helpText?: ReactNode;
}

export type MainMenuItemType = "view" | "viewGroup";

export function getMainMenuItemLabel(item: MainMenuItem): string {
	if (typeof item.label === "string") {
		return item.label;
	}

	return item.label.data ?? "";
}

export function getMainMenuItemTreeViewNodeKey(item: MainMenuItem): string {
	const favoritePrefix = item.isFavoritesGroupShortcutItem ? "favorite_" : "";
	return `${favoritePrefix}${item.type}_${item.key}`;
}

export function flatMapMainMenuItem(root: MainMenuItem): MainMenuItem[] {
	return [root, ...root.children.flatMap((c) => flatMapMainMenuItem(c))];
}

export function getAncestorsOfMainMenuItem(item: MainMenuItem, allItems: MainMenuItem[]): MainMenuItem[] {
	if (item.parentKey == null) return [];
	const parent = allItems.find((i) => i.key === item.parentKey);
	if (parent == null) return [];
	return [parent, ...getAncestorsOfMainMenuItem(parent, allItems)];
}
