import { useCallback, useEffect, useRef } from "react";

type Timer = ReturnType<typeof setTimeout>;
export type UseDebounceReturn = (delay: number, func: () => void) => void;

export function useDebounce(): UseDebounceReturn {
	const timer = useRef<Timer>();

	useEffect(() => {
		return () => {
			if (!timer.current) return;
			clearTimeout(timer.current);
		};
	}, []);

	return useCallback(
		(delay: number, func: () => void) => {
			const newTimer = setTimeout(() => {
				func();
			}, delay);
			clearTimeout(timer.current);
			timer.current = newTimer;
		},
		[timer],
	);
}
