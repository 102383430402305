import { AavoDataGrid } from "src/components/common/dataGrid/AavoDataGrid.tsx";
import { enumColumn, textColumn } from "src/components/common/dataGrid/columns.tsx";
import i18n from "i18next";
import { dataGridPersistentStateProps } from "src/components/common/dataGrid/dataGridStateStorage.ts";
import { useCatalogPartRevisionConfiguratorManagingViewContext } from "src/components/views/erp/configurator/managing/catalogPart/configuratorSelections/catalogPartRevisionConfiguratorSelectionsViewContext.ts";
import { ConfigurationProperty } from "src/api/generated/erp/db/types/tables/configurationProperty.ts";
import { getConfigurationPropertyDatatypeLabels } from "src/api/generated/erp/db/types/enums/configurationPropertyDatatype.ts";
import Typography from "@mui/material/Typography";

export const PropertySelectionView = () => {
	const { allComputedProperties, selectedPropertyIds, setSelectedPropertyIds } =
		useCatalogPartRevisionConfiguratorManagingViewContext();

	return (
		<AavoDataGrid<ConfigurationProperty>
			rows={allComputedProperties}
			getRowId={(row) => row.configurationPropertyId}
			checkboxSelection
			selectedRows={selectedPropertyIds}
			onRowSelectionChanged={(rowIds) =>
				setSelectedPropertyIds(rowIds.map((id) => parseInt(id.toString())))
			}
			columns={[
				textColumn({
					headerName: i18n.t("property"),
					field: "title",
					width: 200,
				}),
				enumColumn({
					headerName: i18n.t("type"),
					field: "datatype",
					width: 300,
					enumLabels: getConfigurationPropertyDatatypeLabels(),
				}),
				textColumn({
					headerName: i18n.t("internal_name"),
					field: "name",
					width: 200,
				}),
			]}
			actionBarComponents={
				<>
					<Typography
						children={i18n.t("properties_of_components_are_connected_automatically")}
						sx={{
							alignSelf: "center",
						}}
					/>
				</>
			}
			{...dataGridPersistentStateProps("AE82BADB0A813191")}
		/>
	);
};
