import { textColumn } from "src/components/common/dataGrid/columns.tsx";
import { AavoDataGrid, AavoGridColDef } from "src/components/common/dataGrid/AavoDataGrid.tsx";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import i18n from "i18next";
import { faPlus, faRedo, faSave, faSync, faUndo } from "@fortawesome/pro-regular-svg-icons";
import { AavoButton } from "src/components/common/buttons/AavoButton.tsx";
import { AsyncButton } from "src/components/common/buttons/AsyncButton.tsx";
import {
	ConfiguratorTableDataGridRow,
	ConfiguratorTableDataGridState,
	ConfiguratorTableDataGridStateAction,
} from "src/components/views/erp/configurator/tables/revisionContentView/tabDataGrid/configuratorTableDataGridState.ts";
import { ConfiguratorTableTabDataGridActionBarMenu } from "src/components/views/erp/configurator/tables/revisionContentView/tabDataGrid/ConfiguratorTableTabDataGridActionBarMenu.tsx";
import { ConfiguratorTableTab } from "src/api/generated/erp/db/types/tables/configuratorTableTab.ts";
import { useStaticDataGridModel } from "src/components/common/dataGrid/gridModel/useStaticDataGridModel.tsx";
import { ConfiguratorTableTabDataGridCellRenderer } from "src/components/views/erp/configurator/tables/revisionContentView/tabDataGrid/components/ConfiguratorTableTabDataGridCellRenderer.tsx";
import { ConfiguratorTableTabDataGridColumnHeaderRenderer } from "src/components/views/erp/configurator/tables/revisionContentView/tabDataGrid/components/ConfiguratorTableTabDataGridColumnHeaderRenderer.tsx";
import { ROW_HEADER_NAME_FIELD } from "src/components/views/erp/configurator/tables/revisionContentView/tabDataGrid/ConfiguratorTableTabDataGrid.constants.tsx";

export interface ConfiguratorTableTabDataGridProps {
	tab: ConfiguratorTableTab;
	state: ConfiguratorTableDataGridState;
	dispatchState: (action: ConfiguratorTableDataGridStateAction) => void;
	refresh: () => Promise<unknown>;
	save: () => Promise<unknown>;
	undoLastChange: (() => void) | undefined;
	redoLastChange: (() => void) | undefined;
}

export const ConfiguratorTableTabDataGrid = ({
	tab,
	state,
	dispatchState,
	refresh,
	save,
	undoLastChange,
	redoLastChange,
}: ConfiguratorTableTabDataGridProps) => {
	const columnDefinitions = createColumnDefinitions();

	const { dataGridProps } = useStaticDataGridModel({
		rows: state.rows,
		gridId: `79149E153C4BDC08_${tab.configuratorTableTabId}`,
		getRowId: (row) => row.rowId,
	});

	return (
		<AavoDataGrid<ConfiguratorTableDataGridRow>
			columns={columnDefinitions}
			disableColumnFilter
			disableColumnPinning
			disableColumnMenu
			disableColumnSelector
			disableColumnSorting
			disableColumnOrderPersist
			rowReordering
			editMode={"cell"}
			processRowUpdate={(row) => {
				dispatchState({ type: "updateRow", updatedRow: row });
				return row;
			}}
			isCellEditable={(params) => {
				const isHeaderCornerCell = params.row.isHeaderRow && params.field === ROW_HEADER_NAME_FIELD;
				return !isHeaderCornerCell;
			}}
			onRowOrderChange={({ row, targetIndex }) => {
				const targetRow = state.rows[targetIndex];
				if (!targetRow) return;
				dispatchState({ type: "moveRow", params: { rowId: row.rowId, targetIndex: targetIndex } });
			}}
			onColumnOrderChange={({ column, targetIndex }) => {
				const actualTargetIndex = targetIndex - 2; // Drop row ordering and row header columns
				const targetColumn = state.columns[actualTargetIndex];
				if (!targetColumn) return;
				dispatchState({
					type: "moveColumn",
					params: { columnId: column.field, targetIndex: actualTargetIndex },
				});
			}}
			actionBarComponents={
				<>
					<AsyncButton icon={faSync} tooltip={i18n.t("refresh")} onClick={refresh} />
					<AsyncButton
						icon={faSave}
						disabled={!state.isDirty ? i18n.t("no_unsaved_changes") : undefined}
						onClick={save}
					/>
					<AavoButton
						icon={faUndo}
						tooltip={i18n.t("undo")}
						disabled={undoLastChange === undefined}
						onClick={() => undoLastChange?.()}
					/>
					<AavoButton
						icon={faRedo}
						tooltip={i18n.t("redo")}
						disabled={redoLastChange === undefined}
						onClick={() => redoLastChange?.()}
					/>
					<AavoButton
						icon={faPlus}
						label={i18n.t("row")}
						variant={"outlined"}
						onClick={() => {
							dispatchState({ type: "addRows", params: [{ rowName: "" }] });
						}}
					/>
					<AavoButton
						icon={faPlus}
						label={i18n.t("column")}
						variant={"outlined"}
						onClick={() => {
							dispatchState({ type: "addColumns", params: [{ columnName: "" }] });
						}}
					/>
				</>
			}
			actionBarMenuComponents={
				<ConfiguratorTableTabDataGridActionBarMenu
					key={"actionBarMenuItems"}
					tab={tab}
					refreshTabContent={refresh}
				/>
			}
			sx={{
				"& .MuiDataGrid-cell": {
					padding: "0 !important",
					border: "none !important",
				},
				"& .MuiDataGrid-columnHeader": {
					padding: "0 !important",
					border: "none !important",
					borderBottom: "none !important",
					backgroundColor: "white",
					"& .MuiDataGrid-iconButtonContainer": {
						display: "none",
					},
					"& .MuiDataGrid-columnHeaderTitleContainerContent": {
						flex: 1,
						alignSelf: "stretch",
					},
				},
			}}
			{...dataGridProps}
		/>
	);

	function createColumnDefinitions(): AavoGridColDef<ConfiguratorTableDataGridRow>[] {
		const renderCell = (params: GridRenderCellParams<ConfiguratorTableDataGridRow>) => (
			<ConfiguratorTableTabDataGridCellRenderer {...params} state={state} dispatchState={dispatchState} />
		);

		const rowHeaderColumn: AavoGridColDef<ConfiguratorTableDataGridRow> = textColumn({
			field: "rowHeaderName",
			headerName: "",
			editable: true,
			renderCell: renderCell,
		});

		const dynamicColumns: AavoGridColDef<ConfiguratorTableDataGridRow>[] = state.columns.map((column) =>
			textColumn({
				field: column.columnId as any,
				editable: true,
				headerName: column.columnName,
				renderCell: renderCell,
				renderHeader: (params) => (
					<ConfiguratorTableTabDataGridColumnHeaderRenderer
						{...params}
						state={state}
						dispatchState={dispatchState}
					/>
				),
				valueGetter: (_, row) => row.valuesByColumnIds[column.columnId] ?? "",
				valueSetter: (newValue, row) => ({
					...row,
					valuesByColumnIds: {
						...row.valuesByColumnIds,
						[column.columnId]: newValue,
					},
				}),
			}),
		);

		return [rowHeaderColumn, ...dynamicColumns];
	}
};
