import React, {useState} from "react";
import {StoredStateType, StoredStateTypeLiteral, useStoredState} from "src/utils/useStoredState.ts";

export const useMaybeStoredState = <T extends StoredStateType>(
    initialState: T,
    key: string | undefined,
    type?: StoredStateTypeLiteral,
): [state: T, setState: React.Dispatch<React.SetStateAction<T>>] => {
    const [nonStoredState, setNonStoredState] = useState<T>(initialState);
    const [storedState, setStoredState] = useStoredState<T>(
        {defaultValue: initialState, key: key ?? "__maybeStoredState_placeholder__", type: type},
    );
    if (key === undefined) {
        return [nonStoredState, setNonStoredState];
    } else {
        return [storedState, setStoredState];
    }
};
