import { enumColumn, integerColumn, textColumn } from "src/components/common/dataGrid/columns.tsx";
import i18n from "i18next";
import { getCatalogPartRevisionStateLabels } from "src/api/generated/erp/db/types/enums/catalogPartRevisionState.ts";
import { DocumentsOfObjectButton } from "src/components/views/documents/objectDocuments/DocumentsOfObjectButton.tsx";
import { nullableAavoObjectRef } from "src/utils/aavoObjectRefUtils.ts";
import { ProjectActivityCatalogPartRevisionView } from "src/api/generated/erp/db/types/tables/projectActivityCatalogPartRevisionView.ts";
import { CatalogPartsPage } from "src/components/views/erp/parts/catalogPart/CatalogPartsPage.tsx";
import { AavoButton } from "src/components/common/buttons/AavoButton.tsx";
import { faExternalLinkAlt, faLink } from "@fortawesome/pro-regular-svg-icons";
import { useGenericDialog } from "src/components/common/dialogs/useGenericDialog.ts";
import { ConnectCatalogPartRevisionToProjectActivityView } from "src/components/views/erp/project/projectActivity/connectedParts/ConnectCatalogPartRevisionToProjectActivityView.tsx";
import { useClientSideDataGridModel } from "src/components/common/dataGrid/gridModel/useClientSideDataGridModel";
import { ControlledAsyncCrudDataGrid } from "src/components/common/dataGrid/crud/ControlledAsyncCrudDataGrid.tsx";
import { ProjectActivityCatalogPartsApi } from "src/api/generated/erp/project/projectActivity/api/projectActivityCatalogPartsApi.ts";

export interface ProjectActivityCatalogPartsDataGridProps {
	projectActivityId: number;
}

export const ProjectActivityCatalogPartsDataGrid = ({
	projectActivityId,
}: ProjectActivityCatalogPartsDataGridProps) => {
	const { openDialog } = useGenericDialog();

	const { dataGridProps, refreshData, onlySelectedRow } = useClientSideDataGridModel({
		fetchData: (params) =>
			ProjectActivityCatalogPartsApi.getActivityCatalogPartRevisions({
				...params,
				projectActivityId: projectActivityId,
			}),
		initialParams: {},
		getRowId: (row) => row.projectActivityCatalogPartRevisionId,
		gridId: "7193E0C58A9FA65B",
	});

	return (
		<ControlledAsyncCrudDataGrid<ProjectActivityCatalogPartRevisionView>
			columns={[
				textColumn({
					field: "partNo",
					headerName: i18n.t("part_no"),
				}),
				textColumn({
					field: "catalogPartDescription_1",
					headerName: i18n.t("description"),
					width: 200,
				}),
				textColumn({
					field: "catalogPartDescription_2",
					headerName: i18n.t("description_2"),
					width: 150,
				}),
				integerColumn({
					field: "revisionNumber",
					headerName: i18n.t("revision"),
				}),
				enumColumn({
					field: "state",
					headerName: i18n.t("state"),
					enumLabels: getCatalogPartRevisionStateLabels(),
				}),
				textColumn({
					field: "categoryName",
					headerName: i18n.t("category"),
				}),
				textColumn({
					field: "partUnitName",
					headerName: i18n.t("base_unit"),
				}),
			]}
			onRowDoubleClick={({ row }) => openCatalogPart(row)}
			remove={({ items }) =>
				ProjectActivityCatalogPartsApi.deleteConnections({
					projectActivityCatalogPartRevisionIds: items.map(
						(item) => item.projectActivityCatalogPartRevisionId,
					),
				})
			}
			actionBarComponents={
				<>
					<DocumentsOfObjectButton
						objectRef={nullableAavoObjectRef(
							"CATALOG_PART_REVISION",
							onlySelectedRow?.catalogPartRevisionId,
						)}
					/>
					<AavoButton
						icon={faExternalLinkAlt}
						label={i18n.t("open")}
						variant={"outlined"}
						disabled={!onlySelectedRow}
						onClick={() => {
							openCatalogPart(onlySelectedRow!);
						}}
					/>
					<AavoButton
						icon={faLink}
						label={i18n.t("connect_new")}
						variant={"outlined"}
						onClick={() => {
							openDialog(({ closeDialog }) => ({
								title: i18n.t("connect_catalog_part"),
								content: (
									<ConnectCatalogPartRevisionToProjectActivityView
										activityId={projectActivityId}
										onCompleted={async () => {
											await closeDialog();
											await refreshData();
										}}
									/>
								),
							}));
						}}
					/>
				</>
			}
			{...dataGridProps}
		/>
	);

	function openCatalogPart(row: ProjectActivityCatalogPartRevisionView) {
		openDialog(() => ({
			title: i18n.t("catalog_part"),
			content: <CatalogPartsPage catalogPartId={row.catalogPartId} />,
		}));
	}
};
