// This file is automatically generated. Do not edit manually.

import { WorkspaceCategory } from "src/api/generated/postgres/db/types/workspaces/tables/workspaceCategory";
import { WorkspaceView } from "src/api/generated/postgres/db/types/workspaces/tables/workspaceView";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace WorkspacesTreeViewApi {
	export async function getTreeViewData(args: {
		showArchived: boolean;
		onlyWorkspaceIdToShow: number | null | undefined;
	}): Promise<WorkspacesTreeViewApi_TreeViewData> {
		const response = await makeAxiosRequest({
			url: "/api/ext/workspaces/treeView/getTreeViewData",
			method: "POST",
			data: {
				showArchived: args.showArchived,
				onlyWorkspaceIdToShow: args.onlyWorkspaceIdToShow,
			},
		});
		return response as WorkspacesTreeViewApi_TreeViewData;
	}
}

export interface WorkspacesTreeViewApi_TreeViewData {
	categoryOptions: Array<WorkspaceCategory>;
	workspaces: Array<WorkspaceView>;
}
