import { ClientSideDataGridModel } from "src/components/common/dataGrid/gridModel/ClientSideDataGridModel";
import { SubProjectsDataGridApi } from "src/api/generated/erp/project/subProject/api/subProjectsDataGridApi.ts";
import { CrudDataGrid } from "src/components/common/dataGrid/crud/CrudDataGrid";
import {
	dateColumn,
	dateTimeColumn,
	enumColumn,
	integerColumn,
	textColumn,
} from "src/components/common/dataGrid/columns.tsx";
import i18n from "i18next";
import { getSubProjectStateLabels } from "src/api/generated/erp/db/types/enums/subProjectState.ts";
import { SubProjectContextMenu } from "src/components/views/erp/project/subProject/SubProjectContextMenu.tsx";
import { MenuCheckbox } from "src/components/common/contextMenu/MenuCheckbox.tsx";
import { DocumentsOfObjectButton } from "src/components/views/documents/objectDocuments/DocumentsOfObjectButton.tsx";
import { nullableAavoObjectRef } from "src/utils/aavoObjectRefUtils.ts";
import { AsyncButton } from "src/components/common/buttons/AsyncButton.tsx";
import { faProjectDiagram } from "@fortawesome/pro-regular-svg-icons";
import { SubProjectForm } from "src/components/views/erp/project/subProject/SubProjectForm.tsx";
import { SubProjectView } from "src/api/generated/erp/db/types/tables/subProjectView.ts";
import { useGenericDialog } from "src/components/common/dialogs/useGenericDialog.ts";
import { ProjectActivitiesContainerView } from "src/components/views/erp/project/projectActivity/ProjectActivitiesContainerView.tsx";
import { CreateNewSubProjectForm } from "src/components/views/erp/project/subProject/CreateNewSubProjectForm.tsx";

export interface SubProjectsDataGridProps {
	projectId: number;
}

export const SubProjectsDataGrid = ({ projectId }: SubProjectsDataGridProps) => {
	const { openDialog } = useGenericDialog();

	return (
		<ClientSideDataGridModel
			fetchData={({ cancelledSubProjects }) =>
				SubProjectsDataGridApi.getDataGridData({
					projectId,
					cancelledSubProjects,
				})
			}
			initialParams={{
				cancelledSubProjects: false,
			}}
			getRows={(data) => data.subProjects}
			getRowId={(row) => row.subProjectId}
			gridId={"72E8155F075E9EA8"}
			render={({ data: { project }, dataGridProps, currentParams, refreshData, onlySelectedRow }) => {
				const projectStateAllowsModify = ["INITIAL", "RELEASED"].includes(project.projectState);

				return (
					<CrudDataGrid<SubProjectView>
						disableMultipleRowSelection
						columns={[
							integerColumn({
								field: "subProjectId",
								headerName: i18n.t("number_shortened"),
								width: 80,
							}),
							textColumn({
								field: "subProjectDescription",
								headerName: i18n.t("name"),
								width: 200,
							}),
							textColumn({
								field: "responsiblePersonName",
								headerName: i18n.t("responsible_person"),
								width: 200,
							}),
							textColumn({
								field: "note",
								headerName: i18n.t("note"),
								width: 200,
							}),
							dateColumn({
								field: "plannedStartDate",
								headerName: i18n.t("planned_start"),
							}),
							dateColumn({
								field: "plannedEndDate",
								headerName: i18n.t("planned_end"),
							}),
							enumColumn({
								field: "subProjectState",
								headerName: i18n.t("state"),
								enumLabels: getSubProjectStateLabels(),
							}),
							dateColumn({
								field: "createdDate",
								headerName: i18n.t("created_at"),
							}),
							textColumn({
								field: "createdByUserName",
								headerName: i18n.t("created_by"),
							}),
							dateTimeColumn({
								field: "releasedDate",
								headerName: i18n.t("released_at"),
							}),
							dateTimeColumn({
								field: "completedDate",
								headerName: i18n.t("completed_at"),
							}),
							dateTimeColumn({
								field: "cancelledDate",
								headerName: i18n.t("cancelled_at"),
							}),
						]}
						rowContextMenuComponents={({ row }) => (
							<SubProjectContextMenu subProject={row} refreshData={refreshData} />
						)}
						actionBarComponents={
							<>
								<DocumentsOfObjectButton
									objectRef={nullableAavoObjectRef(
										"SUB_PROJECT",
										onlySelectedRow?.subProjectId,
									)}
								/>
								<AsyncButton
									label={i18n.t("activities")}
									icon={faProjectDiagram}
									disabled={!onlySelectedRow}
									variant={"outlined"}
									onClick={() => {
										if (!onlySelectedRow) return;
										openDialog({
											title: i18n.t("activities"),
											content: (
												<ProjectActivitiesContainerView
													subProjectId={onlySelectedRow.subProjectId}
												/>
											),
										});
									}}
								/>
							</>
						}
						actionBarMenuComponents={[
							<MenuCheckbox
								key={"showCancelled"}
								label={i18n.t("show_cancelled")}
								checked={currentParams.cancelledSubProjects}
								onChange={(checked) => refreshData({ cancelledSubProjects: checked })}
							/>,
						]}
						form={{
							addRowEnabled: projectStateAllowsModify,
							editEnabled: true,
							dialogTitle: (row) =>
								row == null ? i18n.t("new_sub_project") : i18n.t("sub_project"),
							dialogSize: (row) => (row == null ? "sm" : "md"),
							component: ({ row, closeDialog, ...other }) =>
								row == null ?
									<CreateNewSubProjectForm
										projectId={projectId}
										closeDialog={closeDialog}
										{...other}
									/>
								:	<SubProjectForm
										projectId={projectId}
										subProjectId={row.subProjectId}
										disabled={!projectStateAllowsModify}
										{...other}
									/>,
						}}
						{...dataGridProps}
					/>
				);
			}}
		/>
	);
};
