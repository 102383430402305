import { SurveySubmissionObjectType } from "src/api/generated/erp/db/types/enums/surveySubmissionObjectType.ts";
import { SurveySubmissionsOfObjectsDataGrid } from "src/components/views/erp/surveys/objectSurveySubmissions/SurveySubmissionsOfObjectsDataGrid.tsx";
import { AavoMosaic } from "src/components/common/mosaic/AavoMosaic.tsx";
import { useState } from "react";
import { SurveySubmissionView } from "src/api/generated/erp/db/types/tables/surveySubmissionView.ts";
import { CenteredTypography } from "src/components/common/CenteredTypography.tsx";
import { SurveySubmissionForm } from "src/components/views/erp/surveys/submissions/SurveySubmissionForm.tsx";
import i18n from "i18next";

export interface SurveySubmissionsOfObjectsViewProps {
	objectType: SurveySubmissionObjectType;
	objectIds: number[];
}

export const SurveySubmissionsOfObjectsView = ({ objectType, objectIds }: SurveySubmissionsOfObjectsViewProps) => {
	const [selectedSubmission, setSelectedSubmission] = useState<SurveySubmissionView | undefined>(undefined);

	return (
		<AavoMosaic
			viewId={"49C722EDBCDDC12F"}
			layout={{
				type: "row",
				first: {
					type: "panel",
					title: i18n.t("surveys"),
					content: (
						<SurveySubmissionsOfObjectsDataGrid
							objectType={objectType}
							objectIds={objectIds}
							onSelectionChanged={setSelectedSubmission}
						/>
					),
				},
				second: {
					type: "panel",
					title: i18n.t("survey"),
					content:
						selectedSubmission == null ?
							<CenteredTypography>{i18n.t("select_survey")}</CenteredTypography>
						:	<SurveySubmissionForm
								key={selectedSubmission.surveySubmissionId}
								surveyFormId={selectedSubmission.surveyFormId}
								surveySubmissionId={selectedSubmission.surveySubmissionId}
								readOnly
								disableSubmit
							/>,
				},
			}}
		/>
	);
};
