// This file is automatically generated. Do not edit manually.

import { CatalogPartRevisionView } from "src/api/generated/erp/db/types/tables/catalogPartRevisionView";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace CatalogPartRevisionsDataGridApi {
	export async function getData(args: { catalogPartId: number }): Promise<CatalogPartRevisionsDataGridApi_GridData> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/productManagement/catalogPartRevisions/getData",
			method: "POST",
			data: {
				catalogPartId: args.catalogPartId,
			},
		});
		return response as CatalogPartRevisionsDataGridApi_GridData;
	}

	export async function publishCatalogPartRevision(args: { catalogPartRevisionId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/productManagement/catalogPartRevisions/publishCatalogPartRevision",
			method: "POST",
			data: {
				catalogPartRevisionId: args.catalogPartRevisionId,
			},
		});
		return response as void;
	}

	export async function hasProductFamilyNewerReleasedVersions(args: {
		catalogPartRevisionId: number;
	}): Promise<boolean> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/productManagement/catalogPartRevisions/hasProductFamilyNewerReleasedVersions",
			method: "POST",
			data: {
				catalogPartRevisionId: args.catalogPartRevisionId,
			},
		});
		return response as boolean;
	}
}

export interface CatalogPartRevisionsDataGridApi_GridData {
	partIsConfigurable: boolean;
	revisions: Array<CatalogPartRevisionView>;
}
