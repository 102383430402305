// This file is automatically generated. Do not edit manually.

import { SurveyFormRevisionView } from "src/api/generated/erp/db/types/tables/surveyFormRevisionView";
import { SurveyFormRevision } from "src/api/generated/erp/db/types/tables/surveyFormRevision";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace SurveyFormRevisionApi {
	export async function getSurveyFormRevisions(args: {
		surveyFormId: number;
	}): Promise<Array<SurveyFormRevisionView>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/surveys/management/surveyFormRevision/getSurveyFormRevisions",
			method: "POST",
			data: {
				surveyFormId: args.surveyFormId,
			},
		});
		return response as Array<SurveyFormRevisionView>;
	}

	export async function getFormInitData(args: {
		surveyFormId: number;
		surveyFormRevisionId: number | null | undefined;
	}): Promise<SurveyFormRevisionApi_FormInitData> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/surveys/management/surveyFormRevision/getFormInitData",
			method: "POST",
			data: {
				surveyFormId: args.surveyFormId,
				surveyFormRevisionId: args.surveyFormRevisionId,
			},
		});
		return response as SurveyFormRevisionApi_FormInitData;
	}

	export async function create(args: {
		revision: SurveyFormRevision;
		sourceRevisionId: number | null | undefined;
	}): Promise<number> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/surveys/management/surveyFormRevision/create",
			method: "POST",
			data: {
				revision: args.revision,
				sourceRevisionId: args.sourceRevisionId,
			},
		});
		return response as number;
	}

	export async function update(args: { revision: SurveyFormRevision }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/surveys/management/surveyFormRevision/update",
			method: "POST",
			data: {
				revision: args.revision,
			},
		});
		return response as void;
	}

	export async function delete_(args: { surveyFormRevisionId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/surveys/management/surveyFormRevision/delete",
			method: "POST",
			data: {
				surveyFormRevisionId: args.surveyFormRevisionId,
			},
		});
		return response as void;
	}

	export async function releaseRevision(args: { surveyFormRevisionId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/surveys/management/surveyFormRevision/releaseRevision",
			method: "POST",
			data: {
				surveyFormRevisionId: args.surveyFormRevisionId,
			},
		});
		return response as void;
	}

	export async function revertReleaseRevision(args: { surveyFormRevisionId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/surveys/management/surveyFormRevision/revertReleaseRevision",
			method: "POST",
			data: {
				surveyFormRevisionId: args.surveyFormRevisionId,
			},
		});
		return response as void;
	}
}

export interface SurveyFormRevisionApi_FormInitData {
	sourceRevisionOptions: Array<SurveyFormRevision>;
	surveyFormRevision: SurveyFormRevision | null | undefined;
}
