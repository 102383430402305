import {CompanyApi} from "src/api/generated/erp/common/companies/companyApi";
import {Company} from "src/api/generated/erp/db/types/tables/company";
import {FormCommonProps} from "../../../../common/forms/types";
import {getCompanyCurrencyLabels} from "src/api/generated/erp/db/types/enums/companyCurrency";
import {DeepPartial} from "react-hook-form";

import {FormTextField} from "src/components/common/forms/fields/FormTextField";
import {spanGridColumns} from "src/components/common/forms/styles";
import {nonNegativeFloatRule, nonNegativeIntegerRule, requireRule,} from "src/components/common/forms/validation";
import i18n from "i18next";
import {FormEnumSelectField} from "src/components/common/forms/fields/FormEnumSelectField.tsx";
import {FormSelectField} from "src/components/common/forms/fields/FormSelectField.tsx";
import {AsyncForm} from "src/components/common/forms/AsyncForm.tsx";

export interface CompanyFormProps extends FormCommonProps<number> {
	companyId?: number;
}

export const CompanyForm = ({ companyId, onFormEdited, onCompleted }: CompanyFormProps) => {
	return (
		<AsyncForm
			fetch={() => CompanyApi.getFormData({ companyId })}
			getDefaultValues={(data) => data.company ?? NEW_RECORD_DEFAULT_VALUES}
			submit={submitForm}
			columns={2}
			onCompleted={onCompleted}
			onFormEdited={onFormEdited}
			render={({ data, control }) => (
				<>
					<FormTextField
						control={control}
						name={"companyName"}
						rules={requireRule()}
						label={i18n.t("company")}
						autoFocus
					/>
					<FormTextField
						control={control}
						name={"businessId"}
						label={i18n.t("business_id")}
						rules={requireRule()}
					/>
					<FormTextField
						control={control}
						name={"invoiceAddress"}
						label={i18n.t("invoice_address")}
						multiline={true}
						rules={requireRule()}
						sx={spanGridColumns}
					/>
					<FormTextField
						control={control}
						name={"einvoiceId"}
						label={i18n.t("e_invoice_id")}
						rules={requireRule()}
					/>
					<FormSelectField
						control={control}
						name={"eInvoiceOperatorId"}
						label={i18n.t("e_invoice_operator")}
						options={data.eInvoiceOperatorOptions}
						getOptionKey={(o) => o.eInvoiceOperatorId}
						getOptionLabel={(o) => o.eInvoiceOperatorName}
					/>

					<FormTextField
						control={control}
						name={"printoutText_1"}
						label={i18n.t("additional_info", { number: "1" })}
						multiline={true}
						sx={spanGridColumns}
					/>

					<FormTextField
						control={control}
						name={"printoutText_2"}
						label={i18n.t("additional_info", { number: "2" })}
						multiline={true}
						sx={spanGridColumns}
					/>

					<FormTextField
						control={control}
						name={"printoutText_3"}
						label={i18n.t("additional_info", { number: "3" })}
						multiline={true}
						sx={spanGridColumns}
					/>

					<FormTextField
						control={control}
						name={"printoutText_4"}
						label={i18n.t("additional_info", { number: "4" })}
						multiline={true}
						sx={spanGridColumns}
					/>
					<FormEnumSelectField
						control={control}
						name={"currency"}
						label={i18n.t("currency")}
						disableClearable={true}
						rules={requireRule()}
						options={getCompanyCurrencyLabels()}
					/>
					<FormTextField
						control={control}
						name={"invoicePenaltyInterest"}
						label={i18n.t("invoice_penalty_interest_%")}
						rules={{
							...requireRule(),
							...nonNegativeFloatRule(),
						}}
						type={"number"}
					/>
					<FormTextField
						control={control}
						name={"invoiceRemindDays"}
						label={i18n.t("invoice_remind_days")}
						type={"number"}
						rules={{
							...requireRule(),
							...nonNegativeIntegerRule(),
						}}
					/>
					<FormTextField
						control={control}
						name={"bankAccountNumber"}
						label={i18n.t("bank_account_number")}
						rules={requireRule()}
					/>
				</>
			)}
		/>
	);
};

const NEW_RECORD_DEFAULT_VALUES: DeepPartial<Company> = {
	invoicePenaltyInterest: 5,
	invoiceRemindDays: 7,
	currency: "EUR",
};

const submitForm = async (company: Company): Promise<number> => {
	const result = await CompanyApi.save({ company: company });
	return result.companyId;
};
