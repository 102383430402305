import { AavoGridColDef } from "src/components/common/dataGrid/AavoDataGrid.tsx";
import i18n from "i18next";
import { ObjectAttributeFieldWithValue } from "src/api/generated/erp/common/objectAttributes/objectAttributeFieldWithValue.ts";
import {
	attributeValueToUserFriendlyString,
	decodeObjectAttributeValue,
} from "src/components/views/erp/objectAttributes/objectAttributeValueConverting.ts";

export const objectAttributeDataGridColumns: AavoGridColDef<ObjectAttributeFieldWithValue>[] = [
	{
		headerName: i18n.t("attribute"),
		field: "attribute",
		width: 120,
		valueGetter: (_, row) => row.field.label,
	},
	{
		headerName: i18n.t("value"),
		field: "value",
		width: 200,
		valueGetter: (_, row) =>
			attributeValueToUserFriendlyString(
				decodeObjectAttributeValue(row.attributeValue ?? null, row.field.type),
				row.field.type,
			),
	},
];
