import { StackedIcon } from "src/components/common/icons/StackedIcon.tsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faPlus } from "@fortawesome/pro-regular-svg-icons";

export const InsertColumnsAfterIcon = () => {
	return (
		<StackedIcon>
			<FontAwesomeIcon
				icon={faPlus}
				transform={{
					size: 14,
					x: -2,
					y: 0
				}}
			/>
			<FontAwesomeIcon
				icon={faArrowRight}
				transform={{
					size: 8,
					x: 5,
					y: -4
				}}
			/>
		</StackedIcon>
	);
};