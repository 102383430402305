// This file is automatically generated. Do not edit manually.

import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace PurchaseOrderLineActionApi {
	export async function cancelPurchaseOrderLine(args: { purchaseOrderLineId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/purchase/purchaseOrder/cancelPurchaseOrderLine",
			method: "POST",
			data: {
				purchaseOrderLineId: args.purchaseOrderLineId,
			},
		});
		return response as void;
	}
}
