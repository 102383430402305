import i18n from "i18next";
import { AavoMosaic } from "src/components/common/mosaic/AavoMosaic.tsx";
import { SalesCommissionPaymentsDataGrid } from "src/components/views/erp/sales/salesCommissions/paymentsView/SalesCommissionPaymentsDataGrid.tsx";
import { useState } from "react";
import { SalesCommissionPaymentLinesDataGrid } from "src/components/views/erp/sales/salesCommissions/paymentsView/SalesCommissionPaymentLinesDataGrid.tsx";
import { CenteredTypography } from "src/components/common/CenteredTypography.tsx";
import { SalesCommissionPaymentView } from "src/api/generated/erp/db/types/tables/salesCommissionPaymentView.ts";

export const SalesCommissionPaymentsView = () => {
	const [selectedPayment, setSelectedPayment] = useState<SalesCommissionPaymentView | undefined>(undefined);

	return (
		<AavoMosaic
			viewId={"DEE0913C9D32F0AB"}
			layout={{
				type: "column",
				first: {
					type: "panel",
					title: i18n.t("commission_payments"),
					content: <SalesCommissionPaymentsDataGrid onSelectionChanged={setSelectedPayment} />,
				},
				second: {
					type: "panel",
					title: i18n.t("installments"),
					content:
						selectedPayment === undefined ?
							<CenteredTypography>{i18n.t("select_commission_payment")}</CenteredTypography>
						:	<SalesCommissionPaymentLinesDataGrid
								key={selectedPayment.salesCommissionPaymentId}
								salesCommissionPayment={selectedPayment}
							/>,
				},
			}}
		/>
	);
};
