import { DocumentPreviewView } from "src/components/views/documents/preview/DocumentPreviewView.tsx";
import { AsyncFetchRender } from "src/components/common/async/AsyncFetchRender.tsx";
import { PartConfiguratorFormApi } from "src/api/generated/erp/configurator/api/form/partConfiguratorFormApi.ts";
import { CenteredTypography } from "src/components/common/CenteredTypography.tsx";
import i18n from "i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestion } from "@fortawesome/pro-regular-svg-icons";
import { useTheme } from "@mui/material";

export interface ConfigurationComponentDocumentViewProps {
	componentId: number | null;
}

export const ConfigurationComponentDocumentView = ({
	componentId,
}: ConfigurationComponentDocumentViewProps) => {
	const { palette } = useTheme();

	if (componentId == null) {
		return (
			<CenteredTypography>
				{i18n.t("configuration_component_document_alt_text_1")}
				<FontAwesomeIcon icon={faQuestion} color={palette.primary.main} />
				{i18n.t("configuration_component_document_alt_text_2")}
			</CenteredTypography>
		);
	}

	return (
		<AsyncFetchRender
			key={componentId}
			fetch={() =>
				PartConfiguratorFormApi.getComponentDocumentId({
					configuratorComponentId: componentId,
				})
			}
			content={(documentId) => {
				if (documentId == null) return null;

				return <DocumentPreviewView documentId={documentId} />;
			}}
		/>
	);
};
