import {
    CustomerOrderSalesConditionFormApi,
    CustomerOrderSalesConditionFormApi_InitData,
} from "src/api/generated/erp/sales/customerOrder/api/customerOrderSalesConditionFormApi.ts";
import {FormCommonProps} from "src/components/common/forms/types.ts";
import {AsyncForm} from "src/components/common/forms/AsyncForm.tsx";
import {FormSelectField} from "src/components/common/forms/fields/FormSelectField.tsx";
import i18n from "i18next";
import {FormDateField} from "src/components/common/forms/fields/FormDateField.tsx";
import {requireRule} from "src/components/common/forms/validation.ts";
import {FormAsyncUserSelectField} from "src/components/views/users/FormAsyncUserSelectField.tsx";
import {FormTextField} from "src/components/common/forms/fields/FormTextField.tsx";
import {CustomerOrderSalesCondition} from "src/api/generated/erp/db/types/tables/customerOrderSalesCondition.ts";

export interface CustomerOrderSalesConditionFormProps extends FormCommonProps<number> {
	customerOrderId: number;
	customerOrderSalesConditionId: number | undefined;
}

export const CustomerOrderSalesConditionForm = ({
	customerOrderId,
	customerOrderSalesConditionId,
	onCompleted,
	onFormEdited,
}: CustomerOrderSalesConditionFormProps) => {
	return (
		<AsyncForm<CustomerOrderSalesConditionFormApi_InitData, CustomerOrderSalesCondition, number>
			fetch={() =>
				CustomerOrderSalesConditionFormApi.getInitData({
					customerOrderId: customerOrderId,
					customerOrderSalesConditionId: customerOrderSalesConditionId,
				})
			}
			onCompleted={onCompleted}
			onFormEdited={onFormEdited}
			getDefaultValues={(initData) => {
				return initData.condition != null ?
						initData.condition
					:	{
							customerOrderId: initData.customerOrderId,
							responsiblePersonUserId: initData.defaultResponsiblePersonId,
						};
			}}
			render={({ data, control }) => (
				<>
					<FormSelectField
						control={control}
						name={"salesConditionId"}
						label={i18n.t("condition")}
						options={data.conditionOptions}
						getOptionKey={(option) => option.salesConditionId}
						getOptionLabel={(option) => option.name}
						rules={requireRule()}
						disableClearable={true}
					/>
					<FormDateField
						control={control}
						name={"validUntil"}
						label={i18n.t("valid_until")}
						rules={requireRule()}
					/>
					<FormAsyncUserSelectField
						control={control}
						name={"responsiblePersonUserId"}
						label={i18n.t("responsible_person")}
						rules={requireRule()}
					/>
					<FormTextField control={control} name={"note"} label={i18n.t("note")} />
				</>
			)}
			submit={(values) =>
				CustomerOrderSalesConditionFormApi.save({
					condition: values,
				})
			}
		/>
	);
};
