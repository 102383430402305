// This file is automatically generated. Do not edit manually.

import { AccountingDimension } from "src/api/generated/erp/db/types/tables/accountingDimension";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace AccountingDimensionsApi {
	export async function getAll(): Promise<Array<AccountingDimension>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/accounting/dimensions/getAll",
			method: "POST",
			data: {},
		});
		return response as Array<AccountingDimension>;
	}

	export async function insert(args: { dimension: AccountingDimension }): Promise<number> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/accounting/dimensions/insert",
			method: "POST",
			data: {
				dimension: args.dimension,
			},
		});
		return response as number;
	}

	export async function update(args: { dimension: AccountingDimension }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/accounting/dimensions/update",
			method: "POST",
			data: {
				dimension: args.dimension,
			},
		});
		return response as void;
	}

	export async function delete_(args: { dimensionIds: Array<number> }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/accounting/dimensions/delete",
			method: "POST",
			data: {
				dimensionIds: args.dimensionIds,
			},
		});
		return response as void;
	}
}
