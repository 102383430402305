import { DocumentFileApi } from "src/api/generated/documents/api/documentFileApi.ts";
import { AsyncFetchRender } from "src/components/common/async/AsyncFetchRender.tsx";
import { CenteredTypography } from "src/components/common/CenteredTypography.tsx";
import i18n from "i18next";
import { FileHandlePreviewView } from "src/components/views/documents/preview/FileHandlePreviewView.tsx";
import { WopiFileView } from "src/components/views/documents/wopi/WopiFileView.tsx";
import { RefreshableElementProps, setRefreshRefValue, useRefreshRef } from "src/utils/useRefreshRef.ts";

export interface DocumentPreviewViewProps extends RefreshableElementProps {
	documentId: number;
	documentRevisionId?: number;
}

export const DocumentPreviewView = ({
	documentId,
	documentRevisionId,
	refreshRef,
}: DocumentPreviewViewProps) => {
	const thisComponentRefreshRef = useRefreshRef();
	const wopiFileViewRefreshRef = useRefreshRef();

	setRefreshRefValue(refreshRef, () =>
		Promise.all([thisComponentRefreshRef.refresh(), wopiFileViewRefreshRef.refresh()]),
	);

	return (
		<AsyncFetchRender
			refreshRef={thisComponentRefreshRef}
			fetch={() =>
				DocumentFileApi.getDocumentFileReference({
					documentId,
					documentRevisionId,
				})
			}
			content={(fileRef) => {
				if (fileRef == null)
					return <CenteredTypography>{i18n.t("document_file_not_found")}</CenteredTypography>;

				if (fileRef.type == "wopiFile")
					return <WopiFileView refreshRef={wopiFileViewRefreshRef} clientConfig={fileRef.config} />;
				else return <FileHandlePreviewView fileHandle={fileRef.fileHandle} />;
			}}
		/>
	);
};
