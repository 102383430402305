// This global event is required for components like HighCharts and GoldenLayout
// to resize content when sidebar is toggled or window resized.

export function fireLegacyAppContentAreaChangedEvent(detail: unknown) {
	// Some elements may require time to render first.
	setTimeout(function () {
		window.dispatchEvent(
			new CustomEvent("appContentAreaChanged", {
				detail: detail || {},
			}),
		);
	}, 100);
}

export function onAppContentAreaChanged(callback: () => unknown) {
	window.addEventListener("appContentAreaChanged", callback);
}
