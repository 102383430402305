import { ControlledAsyncCrudDataGrid } from "src/components/common/dataGrid/crud/ControlledAsyncCrudDataGrid.tsx";
import { useClientSideDataGridModel } from "src/components/common/dataGrid/gridModel/useClientSideDataGridModel.tsx";
import i18n from "i18next";
import { integerColumn, textColumn } from "src/components/common/dataGrid/columns.tsx";
import {
	ProductionLineWorkCentersDataGridApi,
	ProductionLineWorkCentersDataGridApi_ProductionLineWorkcenterDto,
} from "src/api/generated/erp/production/basedata/productionLines/api/productionLineWorkCentersDataGridApi.ts";
import { AsyncButton } from "src/components/common/buttons/AsyncButton.tsx";
import { faAngleDown, faAngleUp } from "@fortawesome/pro-regular-svg-icons";
import { ProductionLineWorkCentersEditApi } from "src/api/generated/erp/production/basedata/productionLines/api/productionLineWorkCentersEditApi.ts";
import { ProductionLineWorkCenterForm } from "src/components/views/erp/production/baseData/productionLines/productionLineWorkCenters/ProductionLineWorkCenterForm.tsx";

export interface ProductionLineWorkCentersDataGridProps {
	productionLineId: number;
}

export const ProductionLineWorkCentersDataGrid = ({ productionLineId }: ProductionLineWorkCentersDataGridProps) => {
	const { dataGridProps, onlySelectedRow, refreshData } = useClientSideDataGridModel({
		fetchData: () => ProductionLineWorkCentersDataGridApi.getDataGridData({ productionLineId }),
		initialParams: {},
		gridId: "DE33715116004C9B",
		getRowId: (row) => row.productionLineWorkcenterId,
	});

	return (
		<ControlledAsyncCrudDataGrid<ProductionLineWorkCentersDataGridApi_ProductionLineWorkcenterDto>
			columns={[
				integerColumn({
					field: "position",
					headerName: i18n.t("order_num.as_position"),
					width: 120,
				}),
				textColumn({
					field: "workcenterLongName",
					headerName: i18n.t("work_center"),
					width: 250,
				}),
				textColumn({
					field: "controlChart",
					headerName: i18n.t("control_chart"),
					width: 150,
				}),
			]}
			actionBarComponents={
				<>
					<AsyncButton
						icon={faAngleUp}
						disabled={!onlySelectedRow}
						onClick={async () => {
							await ProductionLineWorkCentersEditApi.movePosition({
								productionLineWorkcenterId: onlySelectedRow!.productionLineWorkcenterId,
								direction: "UP",
							});
							await refreshData();
						}}
					/>
					<AsyncButton
						icon={faAngleDown}
						disabled={!onlySelectedRow}
						onClick={async () => {
							await ProductionLineWorkCentersEditApi.movePosition({
								productionLineWorkcenterId: onlySelectedRow!.productionLineWorkcenterId,
								direction: "DOWN",
							});
							await refreshData();
						}}
					/>
				</>
			}
			form={{
				addRowEnabled: true,
				editEnabled: true,
				dialogTitle: i18n.t("production_line_work_center"),
				dialogSize: "sm",
				component: ({ row, onCompleted, onFormEdited }) => (
					<ProductionLineWorkCenterForm
						productionLineId={productionLineId}
						productionLineWorkCenterId={row?.productionLineWorkcenterId}
						onCompleted={onCompleted}
						onFormEdited={onFormEdited}
					/>
				),
			}}
			remove={({items}) =>
				ProductionLineWorkCentersEditApi.delete_({
					productionLineWorkcenterIds: items.map((item) => item.productionLineWorkcenterId),
				})
			}
			{...dataGridProps}
		/>
	);
};
