import { SelectField } from "src/components/common/inputFields/SelectField.tsx";
import { labelWithRequiredMark } from "src/components/views/erp/surveys/surveyUtils.ts";
import { SurveySubmissionFormFieldProps } from "src/components/views/erp/surveys/submissions/fields/SurveySubmissionFormField.tsx";

export const SurveySubmissionFormSelectionField = ({
	field,
	value,
	onChange,
	error,
	disabled,
}: SurveySubmissionFormFieldProps) => {
	return (
		<SelectField
			label={labelWithRequiredMark(field)}
			options={field.selectionFieldOptions}
			getOptionKey={(option) => option.key}
			getOptionLabel={(option) => option.key}
			value={value?.type === "string" ? value.value : null}
			error={error}
			disabled={disabled}
			onChange={(value) => {
				if (value == null) onChange(null);
				else
					onChange({
						type: "string",
						value: value,
					});
			}}
		/>
	);
};
