import { Control, useFieldArray } from "react-hook-form";
import { AccountingDimension } from "src/api/generated/erp/db/types/tables/accountingDimension.ts";
import { castControl } from "src/components/common/forms/formCasting";
import { ObjectAccountingApi } from "src/api/generated/erp/sales/accounting/api/objectAccountingApi.ts";
import { AccountingCode } from "src/api/generated/erp/db/types/tables/accountingCode.ts";
import { concatWithPipe } from "src/utils/strings.tsx";
import { FormAsyncSelectField } from "src/components/common/forms/fields/FormAsyncSelectField.tsx";

export interface ObjectAccountingFormProps<TFieldValues extends ObjectAccountingFormValues> {
	control: Control<TFieldValues>;
}

export interface ObjectAccountingFormValues {
	accountingDimensionValues: ObjectAccountingFormDimensionValues[];
}

export interface ObjectAccountingFormDimensionValues {
	dimension: AccountingDimension;
	accountingCodeId: number | null;
}

export const ObjectAccountingForm = <TFieldValues extends ObjectAccountingFormValues>({
	control: controlProp,
}: ObjectAccountingFormProps<TFieldValues>) => {
	const control = castControl<TFieldValues, ObjectAccountingFormValues>(controlProp);

	const dimensionsFieldArray = useFieldArray({
		control,
		name: "accountingDimensionValues",
	});

	return dimensionsFieldArray.fields.map((field, index) => (
		<FormAsyncSelectField
			key={field.id}
			control={control}
			name={`accountingDimensionValues.${index}.accountingCodeId`}
			label={field.dimension.name}
			getOptionKey={(option: AccountingCode) => option.accountingCodeId}
			getOptionLabel={(option) => concatWithPipe(option.code, option.description)}
			fetchOptions={({ currentSelection, searchQuery }) =>
				ObjectAccountingApi.getAccountingCodeOptions({
					dimensionId: field.dimension.accountingDimensionId,
					currentSelection,
					searchQuery,
				})
			}
			startNewGridRow={index === 0}
		/>
	));
};
