import {ClientSideDataGridModel} from "src/components/common/dataGrid/gridModel/ClientSideDataGridModel.tsx";
import {EInvoiceOperatorApi} from "src/api/generated/erp/sales/basedata/api/eInvoiceOperatorApi.ts";
import {textColumn} from "src/components/common/dataGrid/columns.tsx";
import i18n from "i18next";
import {EInvoiceOperator} from "src/api/generated/erp/db/types/tables/eInvoiceOperator.ts";
import {EInvoiceOperatorForm} from "src/components/views/erp/sales/basedata/eInvoiceOperators/EInvoiceOperatorForm.tsx";
import {CrudDataGrid} from "src/components/common/dataGrid/crud/CrudDataGrid.tsx";

export const EInvoiceOperatorsDataGrid = () => {
	return (
		<ClientSideDataGridModel
			fetchData={EInvoiceOperatorApi.getAll}
			getRows={(data) => data}
			gridId={"2495E741BDFBFF37"}
			getRowId={(row) => row.eInvoiceOperatorId}
			initialParams={{}}
			render={({ dataGridProps, selectedRows }) => (
				<CrudDataGrid<EInvoiceOperator>
					columns={[
						textColumn({
							field: "eInvoiceOperatorCode",
							headerName: i18n.t("e_invoice_operator_code"),
							width: 200,
						}),
						textColumn({
							field: "eInvoiceOperatorName",
							headerName: i18n.t("name"),
							width: 350,
						}),
					]}
					form={{
						addRowEnabled: true,
						editEnabled: true,
						dialogTitle: i18n.t("e_invoice_operator"),
						dialogSize: "sm",
						component: ({ row, ...other }) => (
							<EInvoiceOperatorForm eInvoiceOperator={row} {...other} />
						),
					}}
					remove={() =>
						EInvoiceOperatorApi.delete_({
							eInvoiceOperatorIds: selectedRows.map((row) => row.eInvoiceOperatorId),
						})
					}
					{...dataGridProps}
				/>
			)}
		/>
	);
};
