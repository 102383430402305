// This file is automatically generated. Do not edit manually.

import { ShopOrder } from "src/api/generated/erp/db/types/tables/shopOrder";
import { ShopOrderOperationView } from "src/api/generated/erp/db/types/tables/shopOrderOperationView";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace ShopOrderOperationDataGridApi {
	export async function getData(args: {
		searchQuery?: string;
		shopOrderId: number;
	}): Promise<ShopOrderOperationDataGridApi_ShopOrderOperationData> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/shopOrderOperation/getData",
			method: "POST",
			data: {
				searchQuery: args.searchQuery,
				shopOrderId: args.shopOrderId,
			},
		});
		return response as ShopOrderOperationDataGridApi_ShopOrderOperationData;
	}
}

export interface ShopOrderOperationDataGridApi_ShopOrderOperationData {
	shopOrder: ShopOrder;
	shopOrderOperations: Array<ShopOrderOperationView>;
}
