import { AsyncForm } from "src/components/common/forms/AsyncForm.tsx";
import { ResourceEditApi } from "src/api/generated/spc/resources/api/resourceEditApi.ts";
import { FormCommonProps } from "src/components/common/forms/types.ts";
import { FormTextField } from "src/components/common/forms/fields/FormTextField.tsx";
import { requireRule } from "src/components/common/forms/validation.ts";
import i18n from "i18next";
import { Resource } from "src/api/generated/postgres/db/types/ext_resourcing/tables/resource.ts";

export interface ResourceFormProps extends FormCommonProps<number> {
	resourceId: number | null;
}

export const ResourceForm = ({ resourceId, onCompleted, onFormEdited }: ResourceFormProps) => {
	return (
		<AsyncForm<Resource | null, Resource, number>
			fetch={() => ResourceEditApi.getResource({ resourceId: resourceId })}
			getDefaultValues={(data) => (data != null ? data : { name: "", externalId: "", description: "" })}
			onCompleted={onCompleted}
			onFormEdited={onFormEdited}
			render={({ control }) => (
				<>
					<FormTextField
						control={control}
						name={"name"}
						label={i18n.t("name")}
						rules={requireRule()}
					/>
					<FormTextField control={control} name={"externalId"} label={i18n.t("external_id")} />
					<FormTextField control={control} name={"description"} label={i18n.t("description")} />
				</>
			)}
			submit={async (values) => {
				if (resourceId == null) {
					return await ResourceEditApi.insert({ resource: values });
				} else {
					await ResourceEditApi.update({ resource: values });
					return resourceId;
				}
			}}
		/>
	);
};
