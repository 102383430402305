import { FrontendDownloadFileHandle } from "src/api/generated/common/frontendDownloadFileHandle.ts";
import { FilePreviewView } from "./FilePreviewView";

export interface FileHandlePreviewViewProps {
	fileHandle: FrontendDownloadFileHandle;
}

export const FileHandlePreviewView = ({ fileHandle }: FileHandlePreviewViewProps) => {
	const extension = fileHandle.fileExtension.toLowerCase();
	return <FilePreviewView fileUrl={fileHandle.fileUrl} extension={extension} />;
};
