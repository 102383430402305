// This file is automatically generated. Do not edit manually.

import { Customer } from "src/api/generated/erp/db/types/tables/customer";
import { CustomerAddressType } from "src/api/generated/erp/db/types/enums/customerAddressType";
import { CustomerAddress } from "src/api/generated/erp/db/types/tables/customerAddress";
import { CustomerWithAddresses } from "src/api/generated/erp/sales/customer/service/customerWithAddresses";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace CustomerApi {
	export async function getCustomerSelectionOptions(args: {
		currentSelection: number | null | undefined;
		searchQuery: string;
	}): Promise<Array<Customer>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/customer/getCustomerSelectionOptions",
			method: "POST",
			data: {
				currentSelection: args.currentSelection,
				searchQuery: args.searchQuery,
			},
		});
		return response as Array<Customer>;
	}

	export async function getCustomerAddresses(args: {
		customerId: number;
		addressType?: CustomerAddressType | null | undefined;
	}): Promise<Array<CustomerAddress>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/customer/fetchCustomerAddressOptions",
			method: "POST",
			data: {
				customerId: args.customerId,
				addressType: args.addressType,
			},
		});
		return response as Array<CustomerAddress>;
	}

	export async function getCustomerWithAddresses(args: { customerId: number }): Promise<CustomerWithAddresses> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/customer/getCustomerWithAddresses",
			method: "POST",
			data: {
				customerId: args.customerId,
			},
		});
		return response as CustomerWithAddresses;
	}

	export async function setCustomerIsActive(args: { customerId: number; isActive: boolean }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/customer/setCustomerIsActive",
			method: "POST",
			data: {
				customerId: args.customerId,
				isActive: args.isActive,
			},
		});
		return response as void;
	}
}
