// This file is automatically generated. Do not edit manually.

import { Task } from "src/api/generated/postgres/db/types/tasks/tables/task";
import { IsoDateString } from "src/types/dateTime";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace TaskUpdateApi {
	export async function updateTask(args: { task: Task }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/tasks/updateTask",
			method: "POST",
			data: {
				task: args.task,
			},
		});
		return response as void;
	}

	export async function setTaskNote(args: { taskId: number; note: string }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/tasks/setTaskNote",
			method: "POST",
			data: {
				taskId: args.taskId,
				note: args.note,
			},
		});
		return response as void;
	}

	export async function setAssignedToUserIdOfTasks(args: {
		taskIds: Array<number>;
		assignedToUserId: number | null | undefined;
	}): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/tasks/setAssignedToUserIdOfTasks",
			method: "POST",
			data: {
				taskIds: args.taskIds,
				assignedToUserId: args.assignedToUserId,
			},
		});
		return response as void;
	}

	export async function scheduleTasks(args: {
		taskIds: Array<number>;
		deadlineDate: IsoDateString;
		earliestStartDate: IsoDateString | null | undefined;
		setEarliestStartDateOnlyIfAlreadySet?: boolean;
	}): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/tasks/scheduleTasks",
			method: "POST",
			data: {
				taskIds: args.taskIds,
				deadlineDate: args.deadlineDate,
				earliestStartDate: args.earliestStartDate,
				setEarliestStartDateOnlyIfAlreadySet: args.setEarliestStartDateOnlyIfAlreadySet,
			},
		});
		return response as void;
	}
}
