import { SelectField } from "src/components/common/inputFields/SelectField.tsx";
import i18n from "i18next";
import {
	coloredBooleanColumn,
	dateColumn,
	integerColumn,
	textColumn,
} from "src/components/common/dataGrid/columns.tsx";
import { genericNullableValue } from "src/utils/genericNullableValue.ts";
import { IsoDateString } from "src/types/dateTime.ts";
import { AavoDataGrid } from "src/components/common/dataGrid/AavoDataGrid.tsx";
import { formatIsoString } from "src/utils/dayjsUtils.ts";
import { faHandPointRight } from "@fortawesome/pro-regular-svg-icons";
import { AavoButton } from "src/components/common/buttons/AavoButton.tsx";
import { useGenericDialog } from "src/components/common/dialogs/useGenericDialog.ts";
import { AavoCheckbox } from "src/components/common/inputFields/AavoCheckbox.tsx";
import { CustomerOrderLineReservationForm } from "src/components/views/erp/sales/delivery/picking/CustomerOrderLineReservationForm.tsx";
import {
	ReservableCustomerOrderLinesDataGridApi,
	ReservableCustomerOrderLinesDataGridApi_Row,
} from "src/api/generated/erp/sales/delivery/api/reservableCustomerOrderLinesDataGridApi.ts";
import { useContext } from "react";
import { DeliverySourcePickingViewContext } from "src/components/views/erp/delivery/deliveryPackage/picking/DeliverySourcePickingViewContext.tsx";
import { ClientSideDataGridModel } from "src/components/common/dataGrid/gridModel/ClientSideDataGridModel.tsx";

export interface ReservableCustomerOrderLinesDataGridProps {
	customerOrderId: number;
}

export const ReservableCustomerOrderLinesDataGrid = ({
	customerOrderId,
}: ReservableCustomerOrderLinesDataGridProps) => {
	const { reservableLinesViewRefreshRef, pickableLinesViewRefreshRef } =
		useContext(DeliverySourcePickingViewContext) ?? {};

	const { openDialog } = useGenericDialog();

	return (
		<ClientSideDataGridModel
			fetchData={(params) =>
				ReservableCustomerOrderLinesDataGridApi.getViewData({
					customerOrderId,
					...params,
				})
			}
			initialParams={{
				plannedDeliveryDateFilter: genericNullableValue<IsoDateString>(null),
				onlyFromOwnWarehouseArea: false,
			}}
			getRows={(response) => response.rows}
			getRowId={(row) => row.customerOrderLineId}
			refreshRef={reservableLinesViewRefreshRef}
			gridId={"C7054BEFBDB74F29"}
			render={({ dataGridProps, currentParams, refreshData, data: { deliveryDateOptions, rows } }) => (
				<AavoDataGrid<ReservableCustomerOrderLinesDataGridApi_Row>
					columns={[
						{
							type: "string",
							field: "configurationOrPartDescription",
							headerName: i18n.t("part"),
							width: 250,
						},
						{
							type: "number",
							field: "reservableQuantity",
							valueGetter: (_, row) => row.salesQuantityAsWarehouseUnits - row.reservedQuantity,
							headerName: i18n.t("reservable_quantity"),
							width: 120,
						},
						coloredBooleanColumn({
							field: "hasEnoughAvailableQuantity",
							headerName: i18n.t("reservable"),
						}),
						textColumn({
							field: "partUnit",
							headerName: i18n.t("unit"),
						}),
						textColumn({
							field: "parentLineDescription",
							headerName: i18n.t("package_part"),
							width: 130,
						}),
						integerColumn({
							field: "lineNumber",
							headerName: i18n.t("line_number"),
						}),
						textColumn({
							field: "mark",
							headerName: i18n.t("mark"),
						}),
						dateColumn({
							field: "plannedDeliveryDate",
							headerName: i18n.t("delivery_date"),
						}),
					]}
					actionBarComponents={
						<>
							<AavoButton
								label={i18n.t("reserve")}
								icon={faHandPointRight}
								variant={"outlined"}
								onClick={() => {
									openDialog(({ closeDialog }) => ({
										title: i18n.t("reserve"),
										size: "md",
										content: (
											<CustomerOrderLineReservationForm
												customerOrderLines={rows.filter(
													(row) => row.hasEnoughAvailableQuantity,
												)}
												onCompleted={async (result) => {
													await closeDialog();
													if (result.type === "success") {
														await refreshData();
													}
													await pickableLinesViewRefreshRef?.refresh();
												}}
											/>
										),
									}));
								}}
							/>
							<SelectField
								label={i18n.t("delivery_date")}
								options={deliveryDateOptions}
								getOptionKey={(o) => o}
								getOptionLabel={(o) => formatIsoString(o)}
								onChange={(v) => refreshData({ plannedDeliveryDateFilter: v })}
								value={currentParams.plannedDeliveryDateFilter}
								sx={{
									minWidth: 200,
								}}
							/>
							<AavoCheckbox
								label={i18n.t("only_from_own_warehouse_area")}
								onChange={(v) => refreshData({ onlyFromOwnWarehouseArea: v })}
								checked={currentParams.onlyFromOwnWarehouseArea}
							/>
						</>
					}
					{...dataGridProps}
				/>
			)}
		/>
	);
};
