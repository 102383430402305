import {useTranslation} from "react-i18next";
import {FormCommonProps} from "../../../../common/forms/types";
import {SitesApi, SitesApi_SiteFormData} from "src/api/generated/erp/common/sites/sitesApi";
import {Site} from "src/api/generated/erp/db/types/tables/site";
import {FormTextField} from "src/components/common/forms/fields/FormTextField";
import {FormSelectField} from "src/components/common/forms/fields/FormSelectField";
import {FormCheckbox} from "src/components/common/forms/fields/FormCheckbox";
import {spanGridColumns, startNewGridRow} from "src/components/common/forms/styles";
import {ObjectAttributeField} from "src/api/generated/erp/common/objectAttributes/objectAttributeField";
import {getSiteCertificateTypeLabels} from "src/api/generated/erp/db/types/enums/siteCertificateType";
import {DeepPartial, useFieldArray} from "react-hook-form";
import {ObjectAttribute} from "src/api/generated/erp/db/types/tables/objectAttribute";
import {requiredNonNegativeIntegerRule, requireRule} from "src/components/common/forms/validation";
import {getPurchaseOrderLineAppendModeLabels} from "src/api/generated/erp/db/types/enums/purchaseOrderLineAppendMode";
import {
    ObjectAttributeFieldFormComponent
} from "src/components/views/erp/objectAttributes/ObjectAttributeFieldFormComponent.tsx";
import {FormEnumSelectField} from "../../../../common/forms/fields/FormEnumSelectField.tsx";
import {FormNumberField} from "src/components/common/forms/fields/FormNumberField.tsx";
import {getDeliveryPackageLabelSizeLabels} from "src/api/generated/erp/db/types/enums/deliveryPackageLabelSize.ts";
import {AsyncForm, AsyncFormContentParams} from "src/components/common/forms/AsyncForm.tsx";
import {
    getObjectAccountingCodeIdsFromFormValues,
    getObjectAccountingFormDefaultValues,
} from "src/components/views/erp/sales/accounting/ObjectAccountingForm.utils.ts";
import {
    ObjectAccountingForm,
    ObjectAccountingFormValues,
} from "src/components/views/erp/sales/accounting/ObjectAccountingForm.tsx";
import {
    getReclamationAutomaticClosingLabels
} from "src/api/generated/erp/db/types/enums/reclamationAutomaticClosing.ts";
import i18n from "i18next";

export interface SiteFormProps extends FormCommonProps<number> {
	siteId?: number;
}

interface FormValues extends Site, ObjectAccountingFormValues {
	attributes: ObjectAttribute[];
}

export const SiteForm = ({ siteId, onFormEdited, onCompleted }: SiteFormProps) => {
	return (
		<AsyncForm<SitesApi_SiteFormData, FormValues, number>
			fetch={() => SitesApi.getSiteFormData({ siteId })}
			getDefaultValues={({ site, attributeValues, accountingDimensions, attributeFields }) => {
				const defaultValues =
					site !== null ?
						{
							...site,
							attributes: attributeValues,
						}
					:	FORM_DEFAULT_VALUES;

				return {
					...setAttributeValues(defaultValues, attributeFields),
					...getObjectAccountingFormDefaultValues(accountingDimensions),
				};
			}}
			onFormEdited={onFormEdited}
			onCompleted={onCompleted}
			submit={submitForm}
			columns={2}
			render={(params) => <SiteFormContent {...params} />}
		/>
	);
};

interface SiteFormContentProps extends AsyncFormContentParams<SitesApi_SiteFormData, FormValues> {}

const SiteFormContent = ({ control, data: { companyOptions, attributeFields } }: SiteFormContentProps) => {
	const { t } = useTranslation();

	const attributeFieldArray = useFieldArray({
		control,
		name: "attributes",
	});

	return (
		<>
			<FormTextField control={control} name={"siteName"} label={t("site")} rules={requireRule()} autoFocus />
			<FormTextField control={control} name={"description"} label={t("description")} />
			<FormSelectField
				control={control}
				name={"companyId"}
				options={companyOptions}
				getOptionKey={(o) => o.companyId}
				getOptionLabel={(o) => o.companyName}
				label={t("company")}
				rules={requireRule()}
			/>
			<FormNumberField control={control} name={"interestRatePercent"} label={`${t("interest_rate")} %`} />
			<FormTextField control={control} name={"address_1"} label={t("address_1")} />
			<FormTextField control={control} name={"address_2"} label={t("address_2")} />
			<FormTextField control={control} name={"postalCode"} label={t("postal_code")} />
			<FormTextField control={control} name={"city"} label={t("city")} />
			<FormTextField
				control={control}
				name={"pickingDurationDays"}
				label={t("picking_duration_days")}
				type={"number"}
				rules={requiredNonNegativeIntegerRule()}
				startNewGridRow
			/>
			<FormTextField
				control={control}
				name={"shopOrderLineObjectAdvanceDays"}
				label={t("shop_order_bom_acquisition_advance_days")}
				rules={requiredNonNegativeIntegerRule()}
			/>
			<FormEnumSelectField
				control={control}
				name={"certificateType"}
				label={t("certificate_type")}
				options={getSiteCertificateTypeLabels()}
				sx={startNewGridRow}
			/>
			<FormTextField control={control} name={"certificateNo"} label={t("certificate_number")} />
			<FormCheckbox
				control={control}
				name={"showFullOperationProductionBom"}
				label={t("show_full_operation_production_bom")}
				sx={spanGridColumns}
			/>
			<FormCheckbox
				control={control}
				name={"projectAutomaticClose"}
				label={t("close_projects_automatically")}
				sx={spanGridColumns}
			/>
			<FormCheckbox
				control={control}
				name={"subProjectAutomaticClose"}
				label={t("close_sub_projects_automatically")}
				sx={spanGridColumns}
			/>
			<FormCheckbox
				control={control}
				name={"shopOrderAutomaticReservation"}
				label={t("shop_order_automatic_reservation_on_release")}
				sx={spanGridColumns}
			/>
			<FormCheckbox
				control={control}
				name={"shopOrderAutomaticPicking"}
				label={t("shop_order_automatic_picking_on_release")}
				sx={spanGridColumns}
			/>
			<FormCheckbox
				control={control}
				name={"updateCustomerOrderLineCostWhenPicking"}
				label={t("update_customer_order_line_cost_when_picking")}
				sx={spanGridColumns}
			/>
			<FormCheckbox
				control={control}
				name={"inventoryLevelEnabledAsDefault"}
				label={t("inventory_level_enabled_as_default")}
				sx={spanGridColumns}
			/>
			<FormCheckbox
				control={control}
				name={"enableNegativeInventoryLevel"}
				label={t("enable_negative_inventory_level")}
				sx={spanGridColumns}
			/>
			<FormEnumSelectField
				control={control}
				name={"customerOrderPurchaseOrderLineAppendMode"}
				label={t("purchase_order_from_customer_order_line_append_mode")}
				options={getPurchaseOrderLineAppendModeLabels()}
				sx={spanGridColumns}
			/>
			<FormEnumSelectField
				control={control}
				name={"shopOrderPurchaseOrderLineAppendMode"}
				label={t("purchase_order_from_shop_order_line_append_mode")}
				options={getPurchaseOrderLineAppendModeLabels()}
				sx={spanGridColumns}
			/>
			<FormEnumSelectField
				control={control}
				name={"projectPurchaseOrderLineAppendMode"}
				label={t("purchase_order_from_project_append_mode")}
				options={getPurchaseOrderLineAppendModeLabels()}
				sx={spanGridColumns}
			/>
			<FormEnumSelectField
				control={control}
				name={"customerOrderPackageLabelDefaultSize"}
				label={t("package_label_size")}
				options={getDeliveryPackageLabelSizeLabels()}
				sx={spanGridColumns}
			/>
			<FormEnumSelectField
				control={control}
				name={"reclamationAutomaticClosing"}
				label={t("reclamation_automatic_closing")}
				options={getReclamationAutomaticClosingLabels()}
				sx={spanGridColumns}
			/>
			<FormNumberField
				control={control}
				name={"partReorderPointCalculationDefaultParams.replenishmentTimeDaysAvg"}
				label={i18n.t("part_reorder_point_default_replenishment_time_days_avg")}
				sx={spanGridColumns}
			/>
			<FormNumberField
				control={control}
				name={"partReorderPointCalculationDefaultParams.replenishmentTimeDaysStDev"}
				label={i18n.t("part_reorder_point_default_replenishment_time_days_st_dev")}
				sx={spanGridColumns}
			/>
			<FormNumberField
				control={control}
				name={"partReorderPointCalculationDefaultParams.serviceLevelPercent"}
				label={i18n.t("part_reorder_point_default_service_level_percent")}
				sx={spanGridColumns}
			/>

			<ObjectAccountingForm control={control} />

			{attributeFieldArray.fields.map((field, index) => {
				const attributeField = attributeFields.find((f) => f.id === field.attributeFieldId)!;
				return (
					<ObjectAttributeFieldFormComponent
						key={field.id}
						control={control}
						name={`attributes.${index}.attributeValue`}
						fieldDefinition={attributeField}
					/>
				);
			})}
			<FormTextField
				control={control}
				name={"consignmentNoteRepText"}
				label={t("consignment_note_print_default_text")}
				multiline
				sx={spanGridColumns}
			/>
			<FormTextField
				control={control}
				name={"purchaseOrderRepText"}
				label={t("purchase_order_print_default_text")}
				multiline
				sx={spanGridColumns}
			/>
			<FormTextField
				control={control}
				name={"deliveryNoteRepText"}
				label={t("delivery_note_print_default_text")}
				multiline
				sx={spanGridColumns}
			/>
			<FormTextField
				control={control}
				name={"invoiceRepText"}
				label={t("invoice_print_default_text")}
				multiline
				sx={spanGridColumns}
			/>
			<FormTextField
				control={control}
				name={"customerOrderOfferRepText"}
				label={t("customer_order_offer_print_default_text")}
				multiline
				sx={spanGridColumns}
			/>
			<FormTextField
				control={control}
				name={"customerOrderConfirmationRepText"}
				label={t("customer_order_confirmation_print_default_text")}
				multiline
				sx={spanGridColumns}
			/>
		</>
	);
};

const FORM_DEFAULT_VALUES: DeepPartial<FormValues> = {
	timeZone: "Europe/Helsinki",
	pickingDurationDays: 0,
	shopOrderLineObjectAdvanceDays: 0,
	showFullOperationProductionBom: false,
	projectAutomaticClose: false,
	subProjectAutomaticClose: false,
	shopOrderAutomaticPicking: true,
	shopOrderAutomaticReservation: true,
	attributes: [],
};

const setAttributeValues = (
	site: DeepPartial<FormValues>,
	attributeFields: ObjectAttributeField[],
): DeepPartial<FormValues> => {
	const attributeValues = attributeFields.map((f) => {
		const existingValue = site.attributes?.find((a) => a?.attributeFieldId === f.id);
		return existingValue ?? { attributeFieldId: f.id, value: null };
	});
	return {
		...site,
		attributes: attributeValues,
	};
};

const submitForm = async (values: FormValues): Promise<number> => {
	const { attributes, accountingDimensionValues, ...site } = values;
	const result = await SitesApi.save({
		site: site,
		attributes: attributes,
		accountingCodeIds: getObjectAccountingCodeIdsFromFormValues(accountingDimensionValues),
	});
	return result.siteId;
};
