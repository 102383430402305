import {
	MellanoTransactionStatusViewApi,
	MellanoTransactionStatusViewApi_TransactionRow,
} from "../../../../api/generated/tenants/mellano/api/mellanoTransactionStatusViewApi.ts";
import { textColumn } from "../../../../components/common/dataGrid/columns.tsx";
import { dataGridPersistentStateProps } from "../../../../components/common/dataGrid/dataGridStateStorage.ts";
import { AsyncDataGrid } from "../../../../components/common/dataGrid/AsyncDataGrid.tsx";

export interface TransactionRowViewProps {
	transactionId: number;
}

export const TransactionRowView = ({ transactionId }: TransactionRowViewProps) => {
	return (
		<AsyncDataGrid<MellanoTransactionStatusViewApi_TransactionRow>
			fetchRows={() =>
				MellanoTransactionStatusViewApi.getTransactionRows({ transactionId: transactionId })
			}
			getRowId={(row) => row.transactionRowId}
			columns={[
				textColumn({
					field: "code",
					headerName: "Koodi",
					width: 150,
				}),
				textColumn({
					field: "name",
					headerName: "Nimi",
					width: 250,
				}),
				textColumn({
					field: "quantity",
					headerName: "Määrä",
				}),
				textColumn({
					field: "material",
					headerName: "Materiaali",
					width: 300,
				}),
				textColumn({
					field: "color",
					headerName: "Väri",
					width: 3000,
				}),
			]}
			{...dataGridPersistentStateProps("3CA084FC16F4DCBD")}
		/>
	);
};
