import { SxProps, Theme } from "@mui/material/styles";

export const dFlex: SxProps<Theme> = {
	display: "flex",
};

export const columnFlex: SxProps<Theme> = {
	display: "flex",
	flexDirection: "column",
};

export const flex1: SxProps<Theme> = {
	flex: 1,
};

export const justifyCenter: SxProps<Theme> = {
	justifyContent: "center",
};

export const alignCenter: SxProps<Theme> = {
	alignItems: "center",
};

export const centerContent: SxProps<Theme> = {
	justifyContent: "center",
	alignItems: "center",
};

export const justifyEnd: SxProps<Theme> = {
	justifyContent: "flex-end",
};

export const dNone: SxProps<Theme> = {
	display: "none",
};

export const h100: SxProps<Theme> = {
	height: "100%",
};

export const primary: SxProps<Theme> = {
	color: "primary.main",
};

export const gap1: SxProps<Theme> = {
	gap: 1,
};
export const p1: SxProps<Theme> = {
	p: 1,
};

export const p0: SxProps<Theme> = {
	p: 0,
};
export const my1: SxProps<Theme> = {
	my: 1,
};

export const ml1: SxProps<Theme> = {
	ml: 1,
};
export const mt1: SxProps<Theme> = {
	mt: 1,
};

export const overflowAuto: SxProps<Theme> = {
	overflow: "auto",
};
export const relative: SxProps<Theme> = {
	position: "relative",
};

export const absolute: SxProps<Theme> = {
	position: "absolute",
};

export const overlaySx: SxProps<Theme> = {
	position: "absolute",
	left: 0,
	top: 0,
	right: 0,
	bottom: 0,
	opacity: 0.7,
	backgroundColor: (theme) => theme.palette.common.white,
};

export const positionAbsoluteFullSize: SxProps<Theme> = {
	position: "absolute",
	left: 0,
	top: 0,
	right: 0,
	bottom: 0,
};

export const hiddenElementKeepingState: SxProps<Theme> = {
	opacity: 0,
	pointerEvents: "none",
	"& *": {
		pointerEvents: "none",
	},
};
