import { ControlChart } from "src/api/generated/postgres/db/types/public_/tables/controlChart.ts";
import { IsoDateTimeString } from "src/types/dateTime.ts";
import { AavoFormContentParams } from "src/components/common/forms/AavoForm.tsx";
import { FormCommonProps } from "src/components/common/forms/types.ts";
import { DeepPartial } from "react-hook-form";
import { FormDateTimeField } from "src/components/common/forms/fields/FormDateTimeField.tsx";
import { requireRule } from "src/components/common/forms/validation.ts";
import { FormNumberField } from "src/components/common/forms/fields/FormNumberField.tsx";
import { nullIfBlank } from "src/utils/strings.tsx";
import i18n from "i18next";
import { FormTextField } from "src/components/common/forms/fields/FormTextField.tsx";
import dayjs from "dayjs";
import { formatNumber } from "src/utils/numberUtils.ts";
import { ControlChartDataCollectionApi } from "src/api/generated/spc/controlChart/api/controlChartDataCollectionApi";
import { AsyncForm, AsyncFormContentParams } from "src/components/common/forms/AsyncForm.tsx";
import { ControlChartCommonApi } from "src/api/generated/spc/controlChart/api/controlChartCommonApi.ts";
import { useEffect } from "react";

export interface ControlChartDataInputFormProps extends FormCommonProps<number> {
	controlChartId: number;
}

interface FormValues {
	observationTime: IsoDateTimeString;
	valueInput: DefaultValueInputFormValues | ProductivityInputFormValues;
}

interface DefaultValueInputFormValues {
	inputType: "default";
	values: number[];
	info1: string;
	info2: string;
}

interface ProductivityInputFormValues {
	inputType: "productivity";
	dividend: number;
	divisor: number;
}

export const ControlChartDataInputForm = ({
	controlChartId,
	onFormEdited,
	onCompleted,
}: ControlChartDataInputFormProps) => {
	return (
		<AsyncForm<ControlChart, FormValues, number>
			fetch={() => ControlChartCommonApi.getControlChart({ controlChartId })}
			getDefaultValues={getDefaultValues}
			submit={submit}
			onCompleted={onCompleted}
			onFormEdited={onFormEdited}
			render={(renderParams) => <FormContent {...renderParams} />}
		/>
	);

	function getDefaultValues(controlChart: ControlChart): DeepPartial<FormValues> {
		return {
			observationTime: dayjs().toISOString(),
			valueInput:
				controlChart.dataInputMethod === "PRODUCTIVITY" ?
					{
						inputType: "productivity",
					}
				:	{
						inputType: "default",
						values: Array.from({ length: controlChart.subgroupSize }, () => undefined),
						info1: "",
						info2: "",
					},
		};
	}

	async function submit(
		{ observationTime, valueInput }: FormValues,
		{ reset, setValue, data: controlChart }: AsyncFormContentParams<ControlChart, FormValues>,
	) {
		let recordValues: number[];
		let info1: string;
		let info2: string;
		switch (valueInput.inputType) {
			case "default":
				recordValues = valueInput.values;
				info1 = valueInput.info1;
				info2 = valueInput.info2;
				break;
			case "productivity":
				recordValues = [valueInput.dividend / valueInput.divisor];
				info1 = formatNumber(valueInput.dividend);
				info2 = formatNumber(valueInput.divisor);
				break;
		}

		const recordId = await ControlChartDataCollectionApi.createValueBasedRecord({
			controlChartId: controlChart.id,
			observationTime: observationTime,
			values: recordValues,
			info1: info1,
			info2: info2,
		});

		reset();
		setValue("observationTime", dayjs().toISOString());

		return recordId;
	}
};

const FormContent = (props: AsyncFormContentParams<ControlChart, FormValues>) => {
	const {
		control,
		watch,
		formState: { isDirty },
		setFocus,
		data: controlChart,
	} = props;
	const valueInput = watch("valueInput");

	// Focus to the first input field when the form is opened or after reset
	// See https://github.com/orgs/react-hook-form/discussions/8006
	useEffect(() => {
		if (isDirty) return;
		switch (controlChart.dataInputMethod) {
			case "DEFAULT_":
				setFocus("valueInput.values.0");
				break;
			case "PRODUCTIVITY":
				setFocus("valueInput.dividend");
				break;
		}
	}, [isDirty, setFocus, controlChart.dataInputMethod]);

	return (
		<>
			<FormDateTimeField control={control} name={"observationTime"} rules={requireRule()} />
			{valueInput.inputType === "default" && (
				<DefaultInputForm controlChart={controlChart} valueInput={valueInput} {...props} />
			)}
			{valueInput.inputType === "productivity" && (
				<ProductivityInputForm controlChart={controlChart} {...props} />
			)}
		</>
	);
};

interface DefaultInputFormProps extends AavoFormContentParams<FormValues> {
	controlChart: ControlChart;
	valueInput: DefaultValueInputFormValues;
}

const DefaultInputForm = ({ control, controlChart, valueInput }: DefaultInputFormProps) => {
	const valueDescription = nullIfBlank(controlChart.valueDescription) ?? i18n.t("value");
	return (
		<>
			{valueInput.values.map((_, index) => {
				const labelIndexPart = controlChart.subgroupSize > 1 ? ` ${index + 1}` : "";
				return (
					<FormNumberField
						key={index}
						control={control}
						name={`valueInput.values.${index}`}
						label={`${valueDescription}${labelIndexPart}`}
						rules={requireRule()}
						autoFocus={index === 0}
					/>
				);
			})}
			{nullIfBlank(controlChart.info1description) && (
				<FormTextField
					control={control}
					name={"valueInput.info1"}
					label={controlChart.info1description}
				/>
			)}
			{nullIfBlank(controlChart.info2description) && (
				<FormTextField
					control={control}
					name={"valueInput.info2"}
					label={controlChart.info2description}
				/>
			)}
		</>
	);
};

interface ProductivityInputFormProps extends AavoFormContentParams<FormValues> {
	controlChart: ControlChart;
}

const ProductivityInputForm = ({ control, controlChart }: ProductivityInputFormProps) => {
	return (
		<>
			<FormNumberField
				control={control}
				name={"valueInput.dividend"}
				label={controlChart.info1description}
				// autoFocus
				rules={requireRule()}
			/>
			<FormNumberField
				control={control}
				name={"valueInput.divisor"}
				label={controlChart.info2description}
				rules={requireRule()}
			/>
		</>
	);
};
