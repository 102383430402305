import {StackedIcon, StackedIconProps} from "src/components/common/icons/StackedIcon.tsx";
import {faChartLine} from "@fortawesome/pro-regular-svg-icons";
import {faPlus} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export interface ChartWithPlusIconProps extends StackedIconProps {}

export const ChartWithPlusIcon = ({ ...other }: ChartWithPlusIconProps) => {
	return (
		<StackedIcon {...other}>
			<FontAwesomeIcon
				icon={faChartLine}
				transform={{
					size: 14,
					x: -2,
					y: 1,
				}}
			/>
			<FontAwesomeIcon
				icon={faPlus}
				transform={{
					size: 11,
					x: 6,
					y: -6,
				}}
			/>
		</StackedIcon>
	);
};
