// This file is automatically generated. Do not edit manually.

import { PartConfiguratorType } from "src/api/generated/erp/configurator/configuratorType/partConfiguratorType";
import { ConfigurationPropertyValues } from "src/api/generated/erp/configurator/model/configurationPropertyValues";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace TestConfigurationDocumentsApi {
	export async function getTestConfigurationDocuments(args: {
		configurationSessionId: string;
		configuratorType: PartConfiguratorType;
		propertyValues: ConfigurationPropertyValues;
	}): Promise<Array<TestConfigurationDocumentsApi_DocumentDto>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/configurator/testing/documents/getTestConfigurationDocuments",
			method: "POST",
			data: {
				configurationSessionId: args.configurationSessionId,
				configuratorType: args.configuratorType,
				propertyValues: args.propertyValues,
			},
		});
		return response as Array<TestConfigurationDocumentsApi_DocumentDto>;
	}
}

export interface TestConfigurationDocumentsApi_DocumentDto {
	categoryName: string;
	description: string;
	uniqueId: string;
}
