// This file is automatically generated. Do not edit manually.

import { t } from "i18next";

export const AccountingObjectTypeValues = [
	"SALES_PART",
	"SALES_PART_CATEGORY",
	"CUSTOMER_GROUP",
	"CUSTOMER_ORDER_TYPE",
	"CUSTOMER_ORDER",
	"SITE",
	"CUSTOMER_ORDER_BILLING_PLAN_LINE",
	"CUSTOMER_OFFER_BILLING_PLAN_LINE",
	"BILLING_PLAN_MODEL_LINE",
	"INVOICE_LINE",
] as const;

export type AccountingObjectType = (typeof AccountingObjectTypeValues)[number];

export const getAccountingObjectTypeLabels = () => ({
	SALES_PART: t("sales_part"),
	SALES_PART_CATEGORY: t("sales_part_category"),
	CUSTOMER_GROUP: t("customer_group"),
	CUSTOMER_ORDER_TYPE: t("customer_order_type"),
	CUSTOMER_ORDER: t("customer_order"),
	SITE: t("site"),
	CUSTOMER_ORDER_BILLING_PLAN_LINE: t("customer_order_billing_plan_line"),
	CUSTOMER_OFFER_BILLING_PLAN_LINE: t("customer_offer_billing_plan_line"),
	BILLING_PLAN_MODEL_LINE: t("billing_plan_model_line"),
	INVOICE_LINE: t("invoice_line"),
});

export const getAccountingObjectTypeLabel = (value: AccountingObjectType): string => {
	return getAccountingObjectTypeLabels()[value];
};
