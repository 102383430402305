// This file is automatically generated. Do not edit manually.

import { t } from "i18next";

export const ShopOrderOperationStateValues = ["RELEASED", "STARTED", "READY"] as const;

export type ShopOrderOperationState = (typeof ShopOrderOperationStateValues)[number];

export const getShopOrderOperationStateLabels = () => ({
	RELEASED: t("released"),
	STARTED: t("started"),
	READY: t("ready"),
});

export const getShopOrderOperationStateLabel = (value: ShopOrderOperationState): string => {
	return getShopOrderOperationStateLabels()[value];
};
