// This file is automatically generated. Do not edit manually.

import { SalesPriceListLine } from "src/api/generated/erp/db/types/tables/salesPriceListLine";
import { IsoDateString } from "src/types/dateTime";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace SalesPriceListLineEditApi {
	export async function update(args: { salesPriceListLines: Array<SalesPriceListLine> }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/pricing/salesPriceLists/lines/update",
			method: "POST",
			data: {
				salesPriceListLines: args.salesPriceListLines,
			},
		});
		return response as void;
	}

	export async function deletePriceLists(args: { salesPriceListLineIds: Array<number> }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/pricing/salesPriceLists/lines/deletePriceLists",
			method: "POST",
			data: {
				salesPriceListLineIds: args.salesPriceListLineIds,
			},
		});
		return response as void;
	}

	export async function completelyRemoveSalesPartsFromPriceList(args: {
		salesPriceListId: number;
		salesPartIds: Array<number>;
	}): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/pricing/salesPriceLists/lines/completelyRemoveSalesPartsFromPriceList",
			method: "POST",
			data: {
				salesPriceListId: args.salesPriceListId,
				salesPartIds: args.salesPartIds,
			},
		});
		return response as void;
	}

	export async function setMarginForAllPartsInPriceList(args: {
		salesPriceListId: number;
		activeFrom: IsoDateString;
		marginPercent: number;
	}): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/pricing/salesPriceLists/lines/setMarginForAllPartsInPriceList",
			method: "POST",
			data: {
				salesPriceListId: args.salesPriceListId,
				activeFrom: args.activeFrom,
				marginPercent: args.marginPercent,
			},
		});
		return response as void;
	}

	export async function changePriceByFactorForAllPartsInPriceList(args: {
		salesPriceListId: number;
		activeFrom: IsoDateString;
		factor: number;
	}): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/pricing/salesPriceLists/lines/changePriceByFactorForAllPartsInPriceList",
			method: "POST",
			data: {
				salesPriceListId: args.salesPriceListId,
				activeFrom: args.activeFrom,
				factor: args.factor,
			},
		});
		return response as void;
	}
}
