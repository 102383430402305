import { useEffect, useRef } from "react";

export type IntervalCallback = (params: IntervalCallbackParams) => unknown;

export interface IntervalCallbackParams {
	isCancelled: () => boolean;
}

export const useInterval = (
	callback: IntervalCallback,
	intervalMs: number,
	immediate: boolean = false,
) => {
	const ref = useRef<IntervalCallback>();

	// keep reference to callback without restarting the interval
	useEffect(() => {
		ref.current = callback;
	}, [callback]);

	useEffect(() => {
		// when this flag is set, closure is stale
		let cancelled = false;

		// wrap callback to pass isCancelled getter as an argument
		const fn = () => {
			ref.current?.({
				isCancelled: () => cancelled,
			});
		};

		const intervalId = setInterval(fn, intervalMs);
		if (immediate) fn();

		return () => {
			cancelled = true;
			clearInterval(intervalId);
		};
	}, [intervalMs, immediate]);
};
