import {useServerSideDataGridModel} from "src/components/common/dataGrid/gridModel/useServerSideDataGridModel.tsx";
import {
    SalesCommissionPaymentDataGridApi
} from "src/api/generated/erp/sales/salesCommissions/api/salesCommissionPaymentDataGridApi.ts";
import {genericNullableValue} from "src/utils/genericNullableValue.ts";
import {
    getSalesCommissionPaymentStateLabel,
    getSalesCommissionPaymentStateLabels,
    SalesCommissionPaymentState,
    SalesCommissionPaymentStateValues,
} from "src/api/generated/erp/db/types/enums/salesCommissionPaymentState.ts";
import {ControlledAsyncDataGrid} from "src/components/common/dataGrid/ControlledAsyncDataGrid.tsx";
import {dateTimeColumn, enumColumn, integerColumn, textColumn,} from "src/components/common/dataGrid/columns.tsx";
import {getSalespersonPaymentMethodLabels} from "src/api/generated/erp/db/types/enums/salespersonPaymentMethod.ts";
import i18n from "i18next";
import {SalesCommissionPaymentView} from "src/api/generated/erp/db/types/tables/salesCommissionPaymentView.ts";
import {SelectField} from "src/components/common/inputFields/SelectField.tsx";
import {faCheck, faPrint} from "@fortawesome/pro-regular-svg-icons";
import {SalesCommissionPaymentApi} from "src/api/generated/erp/sales/salesCommissions/api/salesCommissionPaymentApi.ts";
import {useConfirmDialog} from "src/components/common/dialogs/confirmDialog/useConfirmDialog.ts";
import {AsyncMenuButton} from "src/components/common/contextMenu/AsyncMenuButton.tsx";
import {downloadFile} from "src/utils/fileDownloading.ts";
import {formatIsoString} from "src/utils/dayjsUtils.ts";

export interface SalesCommissionPaymentsDataGridProps {
	onSelectionChanged: (selectedRow: SalesCommissionPaymentView | undefined) => void;
}

export const SalesCommissionPaymentsDataGrid = ({
	onSelectionChanged,
}: SalesCommissionPaymentsDataGridProps) => {
	const { dataGridProps, refreshData, currentParams } = useServerSideDataGridModel({
		fetchData: async (params) => SalesCommissionPaymentDataGridApi.getDataGridData(params),
		initialParams: {
			state: genericNullableValue<SalesCommissionPaymentState>(),
		},
		getRowId: (row) => row.salesCommissionPaymentId,
		gridId: "629EF7E14AB2F514",
		onSelectionChanged: (rows) => onSelectionChanged(rows[0]),
	});

	const showConfirmDialog = useConfirmDialog();

	return (
		<ControlledAsyncDataGrid<SalesCommissionPaymentView>
			disableMultipleRowSelection
			columns={[
				integerColumn({
					field: "salesCommissionPaymentId",
					headerName: i18n.t("number_shortened"),
					width: 80,
				}),
				textColumn({
					field: "salespersonName",
					headerName: i18n.t("salesperson"),
					width: 150,
				}),
				enumColumn({
					field: "salespersonPaymentMethod",
					headerName: i18n.t("payment_method"),
					width: 120,
					enumLabels: getSalespersonPaymentMethodLabels(),
				}),
				enumColumn({
					field: "state",
					headerName: i18n.t("state"),
					enumLabels: getSalesCommissionPaymentStateLabels(),
				}),
				dateTimeColumn({
					field: "createdAt",
					headerName: i18n.t("created_at"),
					width: 150,
				}),
				textColumn({
					field: "createdByUserName",
					headerName: i18n.t("created_by"),
					width: 150,
				}),
				textColumn({
					field: "approvedAtMonth" as any,
					headerName: i18n.t("approved_on_mm"),
					width: 110,
					filterable: false,
					sortable: false,
					valueGetter: (_, row) => formatIsoString(row.approvedAt, "YYYY / MM"),
				}),
				dateTimeColumn({
					field: "approvedAt",
					headerName: i18n.t("approved_at"),
					width: 150,
				}),
				textColumn({
					field: "approvedByUserName",
					headerName: i18n.t("approved_by"),
					width: 150,
				}),
			]}
			actionBarComponents={
				<>
					<SelectField
						label={i18n.t("state")}
						options={SalesCommissionPaymentStateValues}
						getOptionKey={(option) => option}
						getOptionLabel={(option) => getSalesCommissionPaymentStateLabel(option)}
						value={currentParams.state}
						onChange={(value) => refreshData({ state: value })}
					/>
				</>
			}
			rowContextMenuComponents={({ row }) => [
				<AsyncMenuButton
					key={"print"}
					label={i18n.t("print")}
					icon={faPrint}
					onClick={async () => {
					const fileHandle = await SalesCommissionPaymentApi.printSalesCommissionPayment({
							salesCommissionPaymentId: row.salesCommissionPaymentId,
						});
						downloadFile(fileHandle);
					}}
				/>,
				<AsyncMenuButton
					key={"approve"}
					label={i18n.t("approve")}
					icon={faCheck}
					disabled={row.state !== "INITIAL"}
					onClick={() => onApprovePaymentClick(row)}
				/>,
			]}
			{...dataGridProps}
		/>
	);

	async function onApprovePaymentClick(payment: SalesCommissionPaymentView) {
		const confirmed = await showConfirmDialog({
			message: i18n.t("confirm_approve_sales_commission_payment"),
		});
		if (!confirmed) return;

		await SalesCommissionPaymentApi.approvePayment({
			salesCommissionPaymentId: payment.salesCommissionPaymentId,
		});
		await refreshData();
	}
};
