import { SalesCommissionLineNamesApi } from "src/api/generated/erp/sales/salesCommissions/api/salesCommissionLineNamesApi.ts";
import { SalesCommissionLineName } from "src/api/generated/erp/db/types/tables/salesCommissionLineName.ts";
import { FormTextField } from "src/components/common/forms/fields/FormTextField.tsx";
import { requireRule } from "src/components/common/forms/validation.ts";
import { AavoForm } from "src/components/common/forms/AavoForm.tsx";
import { FormCommonProps } from "src/components/common/forms/types.ts";
import { DeepPartial } from "react-hook-form";
import i18n from "i18next";

export interface SalesCommissionLineNameFormProps extends FormCommonProps<number> {
	salesCommissionLineName: SalesCommissionLineName | undefined;
}

interface FormValues extends SalesCommissionLineName {}

export const SalesCommissionLineNameForm = (props: SalesCommissionLineNameFormProps) => {
	const { onCompleted, onFormEdited, salesCommissionLineName } = props;
	return (
		<AavoForm
			defaultValues={getDefaultValues()}
			submit={submit}
			onCompleted={onCompleted}
			onFormEdited={onFormEdited}
			render={({ control }) => (
				<FormTextField
					control={control}
					name={"name"}
					label={i18n.t("name")}
					rules={requireRule()}
					autoFocus
				/>
			)}
		/>
	);

	function getDefaultValues(): DeepPartial<FormValues> {
		return salesCommissionLineName ?? {};
	}

	async function submit(values: FormValues) {
		if (salesCommissionLineName != null) {
			await SalesCommissionLineNamesApi.update({
				salesCommissionLineNameId: salesCommissionLineName.salesCommissionLineNameId,
				name: values.name,
			});
			return salesCommissionLineName.salesCommissionLineNameId;
		} else {
			return await SalesCommissionLineNamesApi.insert({
				name: values.name,
			});
		}
	}
};
