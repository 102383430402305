import { GridInitialStatePro, GridStatePro } from "@mui/x-data-grid-pro/models/gridStatePro";
import { DEFAULT_PAGINATION_MODEL } from "src/components/common/dataGrid/utils";
import { setLocalStorageItemManaged } from "src/storage/localStorageUtils.ts";
import { DeepPartial } from "react-hook-form";
import { GridInitialState } from "@mui/x-data-grid-pro";

export interface DataGridPersistStateProps {
	onStateChange: (state: DeepPartial<GridStatePro>) => void;
	initialState: GridInitialStatePro;
}

export const dataGridPersistentStateProps = (
	gridId: string,
	initialState: GridInitialStatePro = DEFAULT_GRID_INITIAL_STATE,
): DataGridPersistStateProps => {
	return {
		onStateChange: (state: DeepPartial<GridStatePro>) => saveDataGridState(gridId, state),
		initialState: getStoredDataGridState(gridId, initialState),
	};
};

const saveDataGridState = (gridId: string, state: DeepPartial<GridStatePro>) => {
	if (gridId === undefined) return;
	const toSave = {
		...state,
		rows: undefined,
		rowsMeta: undefined,
		sorting: {
			...state.sorting,
			sortedRows: undefined,
		},
	};
	setLocalStorageItemManaged(getStoredStateKey(gridId), JSON.stringify(toSave));
};

export const getStoredDataGridState = (
	gridId: string | undefined,
	defaultState: GridInitialStatePro = DEFAULT_GRID_INITIAL_STATE,
) => {
	if (gridId === undefined) return defaultState;

	const savedState = localStorage.getItem(getStoredStateKey(gridId));
	if (savedState === null) return defaultState;

	return {
		...JSON.parse(savedState),
		density: undefined, // Migration from V6 to V7 broke the density setting, old values are not valid anymore
	};
};

const getStoredStateKey = (gridId: string) => {
	return `data-grid-state--${gridId}`;
};

export const DEFAULT_GRID_INITIAL_STATE: GridInitialState = {
	pagination: { paginationModel: DEFAULT_PAGINATION_MODEL },
};
