import { useMemo } from "react";
import { BoxProps, Button, Typography } from "@mui/material";
import { mergeSx } from "src/utils/styles";
import i18n from "i18next";
import { linebreaksToHtmlBreaks } from "src/utils/strings";
import { OpenErrorReportDialogButton } from "src/sentry/OpenErrorReportDialogButton";
import { VerticalBox } from "../box/VerticalBox";
import { LoggedError } from "src/errorHandling/errorLogging.ts";
import { faRefresh } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { HorizontalBox } from "src/components/common/box/HorizontalBox.tsx";
import Box from "@mui/material/Box";
import { getFrontendReleaseCode } from "src/config/appConfig.ts";
import { unregisterServiceWorkers } from "src/utils/serviceWorkerUtils.ts";

export interface ErrorTextProps extends BoxProps {
	error: LoggedError;
	showReportButton?: boolean;
	reload?: () => void;
}

export const ErrorText = ({ error, reload, showReportButton = true, sx, ...other }: ErrorTextProps) => {
	const errorContent = useMemo(() => {
		const ret = linebreaksToHtmlBreaks(error.message, {
			textAlign: "center",
			marginBottom: 1,
		});
		if (error.sentryEventId)
			ret.push(
				<Typography
					key={"aavo-error-text-log-id"}
					sx={{
						textAlign: "center",
					}}
				>
					{`${i18n.t("log_id")}: ${error.sentryEventId}`}
					{error.backendLogId && `-${error.backendLogId}`}
				</Typography>,
			);
		return ret;
	}, [error]);

	return (
		<VerticalBox
			sx={mergeSx(
				{
					flex: 1,
					gap: 1,
					justifyContent: "center",
				},
				sx,
			)}
			{...other}
		>
			<Box
				sx={{
					gap: 1,
					overflow: "auto",
				}}
			>
				{errorContent}
			</Box>
			<HorizontalBox
				sx={{
					justifyContent: "center",
					alignItems: "center",
					minHeight: "auto",
					flexWrap: "wrap",
					gap: 1,
					marginY: 1,
				}}
			>
				{reload && (
					<Button
						variant={"outlined"}
						startIcon={<FontAwesomeIcon icon={faRefresh} />}
						onClick={() => {
							reload();
						}}
					>
						<Typography variant={"button"}>{i18n.t("reload")}</Typography>
					</Button>
				)}
				{showReportButton && error.treatAsError && error.sentryEventId && (
					<OpenErrorReportDialogButton eventId={error.sentryEventId} />
				)}
				<OutdatedFrontendVersionSection error={error} />
			</HorizontalBox>
		</VerticalBox>
	);
};

const OutdatedFrontendVersionSection = ({ error }: Pick<ErrorTextProps, "error">) => {
	const userFrontendVersion = getFrontendReleaseCode();
	const latestFrontendVersion = error.latestFrontendVersion;
	if (latestFrontendVersion === "") return null;
	if (userFrontendVersion === latestFrontendVersion) return null;

	return (
		<>
			<Typography
				sx={{
					textAlign: "center",
					marginTop: 1,
					fontWeight: "bold",
				}}
			>
				{i18n.t("outdated_frontend_version_message_on_error")}
			</Typography>
			<Button
				variant={"outlined"}
				startIcon={<FontAwesomeIcon icon={faRefresh} />}
				onClick={async () => {
					await unregisterServiceWorkers();
					window.location.reload();
				}}
			>
				<Typography variant={"button"}>{i18n.t("update_app_now")}</Typography>
			</Button>
		</>
	);
};
