import { CustomerAddress } from "src/api/generated/erp/db/types/tables/customerAddress.ts";
import i18n from "i18next";

export function getDeliveryAddresses(allAddresses: CustomerAddress[]): CustomerAddress[] {
	return allAddresses.filter((address) => address.addressType === "DELIVERY");
}

export function getInvoiceAddresses(allAddresses: CustomerAddress[]): CustomerAddress[] {
	return allAddresses.filter((address) => address.addressType === "DOCUMENT");
}

export function getDefaultDeliveryAddress(addresses: CustomerAddress[]): CustomerAddress {
	const defaultAddress = addresses.find(
		(address) => address.addressType === "DELIVERY" && address.isDefault,
	);
	if (defaultAddress == null) {
		throw new Error(i18n.t("customer_has_no_default_delivery_address"));
	}
	return defaultAddress;
}

export function getDefaultInvoiceAddress(addresses: CustomerAddress[]): CustomerAddress {
	const defaultAddress = addresses.find(
		(address) => address.addressType === "DOCUMENT" && address.isDefault,
	);
	if (defaultAddress == null) {
		throw new Error(i18n.t("customer_has_no_default_invoice_address"));
	}
	return defaultAddress;
}

interface CustomerAddresses {
	deliveryAddresses: CustomerAddress[];
	invoiceAddresses: CustomerAddress[];
	defaultDeliveryAddress: CustomerAddress;
	defaultInvoiceAddress: CustomerAddress;
}

export function resolveCustomerAddresses(addresses: CustomerAddress[]): CustomerAddresses {
	return {
		deliveryAddresses: getDeliveryAddresses(addresses),
		invoiceAddresses: getInvoiceAddresses(addresses),
		defaultDeliveryAddress: getDefaultDeliveryAddress(addresses),
		defaultInvoiceAddress: getDefaultInvoiceAddress(addresses),
	};
}
