export const dataGridRowClassNames = {
	even: "row-even",
	odd: "row-odd",
	edited: "row-edited",
	unsavedNew: "row-unsaved-new",
	green: "row-green",
	yellow: "row-yellow",
	red: "row-red",
	invalid: "row-invalid",
} as const;

export const dataGridCellClassNames = {
	borderColorError: "cell-border-color-error",
	backgroundColorWarning: "cell-background-color-warning",
	textColorSuccess: "cell-text-color-success",
	textColorError: "cell-text-color-error",
} as const;
