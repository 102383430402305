import { Backdrop, Box, Popper } from "@mui/material";
import { FeatureChangeHint } from "src/components/common/featureChangeHint/FeatureChangeHintContext.tsx";
import { AavoButton } from "../buttons/AavoButton";
import { VerticalBox } from "../box/VerticalBox";
import i18n from "i18next";

export interface FeatureChangeHintComponentProps {
	hint: FeatureChangeHint | undefined;
	hideHint: () => void;
}

export const FeatureChangeHintComponent = ({ hint, hideHint }: FeatureChangeHintComponentProps) => {
	if (hint == undefined) return;

	return (
		<>
			<Backdrop
				sx={{
					zIndex: 6000,
				}}
				open={true}
				onClick={hideHint}
			></Backdrop>
			<Popper
				open={true}
				anchorEl={hint.anchorEl}
				sx={{
					zIndex: 6001,
				}}
			>
				<VerticalBox
					sx={{
						padding: 1,
						borderRadius: 1,
						background: "background",
					}}
				>
					<Box padding={1}>{hint.content}</Box>
					<AavoButton
						label={i18n.t("got_it")}
						onClick={hideHint}
						sx={{
							alignSelf: "flex-start",
						}}
					/>
				</VerticalBox>
			</Popper>
		</>
	);
};
