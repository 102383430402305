// This file is automatically generated. Do not edit manually.

import { CatalogPartToSiteParams } from "src/api/generated/erp/parts/catalogPart/service/catalogPartToSiteParams";
import { PartView } from "src/api/generated/erp/db/types/tables/partView";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace CopyCatalogPartToSitesFormApi {
	export async function savePartToSites(args: { parts: Array<CatalogPartToSiteParams> }): Promise<Array<number>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/productManagement/catalogParts/savePartToSites",
			method: "POST",
			data: {
				parts: args.parts,
			},
		});
		return response as Array<number>;
	}

	export async function getExistingPartsOnSites(args: {
		siteIds: Array<number>;
		catalogPartIds: Array<number>;
	}): Promise<Array<PartView>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/productManagement/catalogParts/getExistingPartsOnSites",
			method: "POST",
			data: {
				siteIds: args.siteIds,
				catalogPartIds: args.catalogPartIds,
			},
		});
		return response as Array<PartView>;
	}
}
