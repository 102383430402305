// This file is automatically generated. Do not edit manually.

import { IsoDateString } from "src/types/dateTime";
import { InventoryLevelEventType } from "src/api/generated/erp/db/types/enums/inventoryLevelEventType";
import { ServerSideDataModelRequest } from "src/api/generated/common/dataGrids/serverSideDataModelRequest";
import { ServerSideDataModelResult } from "src/api/generated/common/dataGrids/serverSideDataModelResult";
import { InventoryLevelEventView } from "src/api/generated/erp/db/types/tables/inventoryLevelEventView";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace AllInventoryLevelEventsDataGridApi {
	export async function getGridData(args: {
		siteId: number | null | undefined;
		partId: number | null | undefined;
		warehouseLocationId: number | null | undefined;
		fromDate: IsoDateString | null | undefined;
		toDate: IsoDateString | null | undefined;
		eventTypes: Array<InventoryLevelEventType>;
		dataModelRequest: ServerSideDataModelRequest;
	}): Promise<ServerSideDataModelResult<InventoryLevelEventView>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/api/warehouse/inventory/allInventoryLevelEventsDataGrid/getGridData",
			method: "POST",
			data: {
				siteId: args.siteId,
				partId: args.partId,
				warehouseLocationId: args.warehouseLocationId,
				fromDate: args.fromDate,
				toDate: args.toDate,
				eventTypes: args.eventTypes,
				dataModelRequest: args.dataModelRequest,
			},
		});
		return response as ServerSideDataModelResult<InventoryLevelEventView>;
	}
}
