import { Box, useTheme } from "@mui/material";
import { SxProps, Theme } from "@mui/material/styles";
import React from "react";

export interface AavoFormLayoutProps extends React.PropsWithChildren {
	columns?: number;
	gap?: number;
	columnMinWidth?: string;
	fitContent?: boolean;
	layoutSx?: SxProps<Theme>;
}

export const AavoFormLayout = ({
	columns = 1,
	gap = 2,
	columnMinWidth = "200px",
	fitContent = false,
	children,
	layoutSx,
}: AavoFormLayoutProps) => {
	const theme = useTheme();

	const gapCount = columns - 1;
	const totalGapWidth = theme.spacing(gapCount * gap);
	const columnMaxWidth = `calc((100% - ${totalGapWidth}) / ${columns})`;

	const singleColumnOrGridSx: SxProps<Theme> =
		columns === 1
			? {
					display: "flex",
					flexDirection: "column",
			  }
			: {
					display: "grid",
					gridTemplateColumns: `repeat(auto-fill, minmax(max(${columnMinWidth}, ${columnMaxWidth}), 1fr))`,
			  };

	const wrapperSx: SxProps<Theme> = fitContent
		? {
				flex: 1,
				display: "flex",
				flexDirection: "column",
		  }
		: {
				flex: 1,
				overflow: "auto",
		  };

	return (
		<Box sx={wrapperSx}>
			<Box
				sx={{
					...singleColumnOrGridSx,
					padding: 1,
					gap: theme.spacing(gap),
					flex: 1,
					marginTop: 0.5,
					...layoutSx,
				}}
			>
				{children}
			</Box>
		</Box>
	);
};
