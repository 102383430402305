import { html, LitElement } from "lit";
import DOMPurify from "dompurify";

const domPurifySettings = { ADD_TAGS: ["iframe"] };

class CustomHtmlWebComponent extends LitElement {
	static get properties() {
		return {
			htmlContent: { type: String },
		};
	}

	createRenderRoot() {
		return this;
	}

	constructor() {
		super();
		this.htmlContent = "";
	}

	render() {
		return html``;
	}

	firstUpdated(_changedProperties) {
		super.firstUpdated(_changedProperties);
		this.setHtmlContent();
	}

	updated(changedProperties) {
		if (changedProperties.has("htmlContent")) {
			this.setHtmlContent();
		}
	}

	setHtmlContent() {
		const dirtyHtml = this.htmlContent;
		this.innerHTML = DOMPurify.sanitize(dirtyHtml, domPurifySettings);
	}
}

customElements.define("custom-html-component", CustomHtmlWebComponent);
