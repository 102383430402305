// This file is automatically generated. Do not edit manually.

import { ControlChart } from "src/api/generated/postgres/db/types/public_/tables/controlChart";
import { Calendar } from "src/api/generated/postgres/db/types/public_/tables/calendar";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace ControlChartEditApi {
	export async function getInitData(args: {
		controlChartId: number | null | undefined;
	}): Promise<ControlChartEditApi_InitData> {
		const response = await makeAxiosRequest({
			url: "/api/ext/spc/controlChartEdit/getInitData",
			method: "POST",
			data: {
				controlChartId: args.controlChartId,
			},
		});
		return response as ControlChartEditApi_InitData;
	}

	export async function removeControlChart(args: { controlChartId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/spc/controlChartEdit/removeControlChart",
			method: "POST",
			data: {
				controlChartId: args.controlChartId,
			},
		});
		return response as void;
	}

	export async function insert(args: {
		controlChart: ControlChart;
		itemId: number | null | undefined;
	}): Promise<number> {
		const response = await makeAxiosRequest({
			url: "/api/ext/spc/controlChartEdit/insert",
			method: "POST",
			data: {
				controlChart: args.controlChart,
				itemId: args.itemId,
			},
		});
		return response as number;
	}

	export async function update(args: { controlChart: ControlChart }): Promise<number> {
		const response = await makeAxiosRequest({
			url: "/api/ext/spc/controlChartEdit/update",
			method: "POST",
			data: {
				controlChart: args.controlChart,
			},
		});
		return response as number;
	}
}

export interface ControlChartEditApi_InitData {
	calendarOptions: Array<Calendar>;
	controlChart: ControlChart | null | undefined;
}
