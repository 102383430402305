import { AavoMosaic } from "src/components/common/mosaic/AavoMosaic.tsx";
import i18n from "i18next";
import { TestConfigurationTopLevelResultsView } from "src/components/views/erp/configurator/configuratorTesting/TestConfigurationTopLevelResultsView.tsx";
import { ConfiguratorLogView } from "src/components/views/erp/configurator/configuratorTesting/logViews/ConfiguratorLogView.tsx";
import { ConfiguratorLogApi } from "src/api/generated/erp/configurator/api/configuratorLogApi.ts";
import { TestConfigurationResultViewCommonProps } from "src/components/views/erp/configurator/configuratorTesting/TestConfigurationResultViewCommonProps.ts";

export function TestConfigurationTopLevelResultsWithLogView(
	props: TestConfigurationResultViewCommonProps,
) {
	return (
		<AavoMosaic
			viewId={"BA9CA8374ED45761"}
			layout={{
				type: "row",
				first: {
					type: "panel",
					title: i18n.t("top_level_info"),
					content: <TestConfigurationTopLevelResultsView {...props} />,
				},
				second: {
					type: "panel",
					title: i18n.t("top_level_info_log"),
					content: (
						<ConfiguratorLogView
							fetchLogFile={() =>
								ConfiguratorLogApi.getTestConfigurationPhaseResolvingLog({
									sessionId: props.configurationSessionId,
									phase: "TOP_LEVEL",
								})
							}
						/>
					),
				},
			}}
		/>
	);
}
