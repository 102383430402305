import {
    SpcSamplingMethod
} from "src/components/views/spc/basedata/controlCharts/form/samplingMethod/SpcSamplingMethod.tsx";

export const getFailingSamplingMethod = (): SpcSamplingMethod => {
    return {
        key: "failing_sampling_method",
        label: "Dev method (Failing)",
        renderParameterFields: () => <></>,
    };
};
