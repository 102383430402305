import { useAsyncFetch } from "src/utils/async/asyncFetch.ts";
import { CustomerOrderInvoicingApi } from "src/api/generated/erp/sales/invoicing/api/customerOrderInvoicingApi.ts";
import { dataGridPersistentStateProps } from "src/components/common/dataGrid/dataGridStateStorage.ts";
import { ControlledAsyncDataGrid } from "src/components/common/dataGrid/ControlledAsyncDataGrid.tsx";
import { CustomerOrderLineView } from "src/api/generated/erp/db/types/tables/customerOrderLineView.ts";
import { floatColumn, textColumn } from "src/components/common/dataGrid/columns.tsx";
import i18n from "i18next";
import { AavoButton } from "src/components/common/buttons/AavoButton.tsx";
import { faFileInvoice } from "@fortawesome/pro-regular-svg-icons";
import { CustomerOrderInvoiceCreationForm } from "src/components/views/erp/sales/invoicing/customerOrderInvoicing/CustomerOrderInvoiceCreationForm.tsx";
import { InvoiceCreationApi } from "src/api/generated/erp/sales/invoicing/api/invoiceCreationApi.ts";
import { useGenericDialog } from "src/components/common/dialogs/useGenericDialog.ts";

export interface CustomerOrderLineInvoicingViewProps {
	customerOrderId: number;
	onInvoicesModified?: () => void;
}

export const CustomerOrderLineInvoicingView = ({
	customerOrderId,
	onInvoicesModified,
}: CustomerOrderLineInvoicingViewProps) => {
	const [rowsAsync, refresh] = useAsyncFetch(() =>
		CustomerOrderInvoicingApi.getCustomerOrderDirectlyBillableLines({ customerOrderId }),
	);

	const { openDialog } = useGenericDialog();

	const showInvoiceCreationForm = () => {
		openDialog(({ closeDialog }) => ({
			title: i18n.t("create_invoice"),
			size: "sm",
			content: (
				<CustomerOrderInvoiceCreationForm
					customerOrderId={customerOrderId}
					closeForm={closeDialog}
					submit={async ({ invoiceDate, selectedCustomerOrderLineIds }) => {
						await InvoiceCreationApi.createInvoiceFromCustomerOrderLines({
							customerOrderId: customerOrderId,
							customerOrderLineIds: selectedCustomerOrderLineIds,
							invoiceDate: invoiceDate,
						});
						await refresh();
						onInvoicesModified?.();
					}}
				/>
			),
		}));
	};

	return (
		<ControlledAsyncDataGrid<CustomerOrderLineView>
			rowsAsync={rowsAsync}
			refreshData={refresh}
			getRowId={(row) => row.customerOrderLineId}
			columns={[
				textColumn({
					field: "lineNumber",
					headerName: i18n.t("number_shortened"),
				}),
				textColumn({
					field: "salesPartDescription",
					headerName: i18n.t("part"),
				}),
				textColumn({
					field: "mark",
					headerName: i18n.t("mark"),
				}),
				{
					type: "number",
					field: "billableQuantity",
					headerName: i18n.t("mark"),
					valueGetter: (_, row) => row.deliveredQuantity - row.invoicedQuantity,
				},
				floatColumn({
					field: "unitPrice",
					headerName: i18n.t("unit_price"),
				}),
				textColumn({
					field: "salesPriceUnit",
					headerName: i18n.t("price_unit"),
				}),
				textColumn({
					field: "vatCodeName",
					headerName: i18n.t("vat_code"),
				}),
			]}
			actionBarComponents={
				<AavoButton
					icon={faFileInvoice}
					label={i18n.t("create_invoice")}
					variant={"outlined"}
					onClick={showInvoiceCreationForm}
				/>
			}
			{...dataGridPersistentStateProps("40D5022C2386F21C")}
		/>
	);
};
