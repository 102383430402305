// This file is automatically generated. Do not edit manually.

import { SurveyFormField } from "src/api/generated/erp/db/types/tables/surveyFormField";
import { UpOrDown } from "src/api/generated/common/upOrDown";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace SurveyFormFieldApi {
	export async function getSurveyFormRevisionFields(args: {
		surveyFormRevisionId: number;
	}): Promise<Array<SurveyFormField>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/surveys/management/surveyFormField/getSurveyFormRevisionFields",
			method: "POST",
			data: {
				surveyFormRevisionId: args.surveyFormRevisionId,
			},
		});
		return response as Array<SurveyFormField>;
	}

	export async function getFormInitData(args: {
		surveyFormFieldId: number | null | undefined;
	}): Promise<SurveyFormFieldApi_FormInitData> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/surveys/management/surveyFormField/getFormInitData",
			method: "POST",
			data: {
				surveyFormFieldId: args.surveyFormFieldId,
			},
		});
		return response as SurveyFormFieldApi_FormInitData;
	}

	export async function insert(args: { surveyFormField: SurveyFormField }): Promise<number> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/surveys/management/surveyFormField/insert",
			method: "POST",
			data: {
				surveyFormField: args.surveyFormField,
			},
		});
		return response as number;
	}

	export async function update(args: { surveyFormField: SurveyFormField }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/surveys/management/surveyFormField/update",
			method: "POST",
			data: {
				surveyFormField: args.surveyFormField,
			},
		});
		return response as void;
	}

	export async function delete_(args: { surveyFormFieldId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/surveys/management/surveyFormField/delete",
			method: "POST",
			data: {
				surveyFormFieldId: args.surveyFormFieldId,
			},
		});
		return response as void;
	}

	export async function move(args: { surveyFormFieldId: number; direction: UpOrDown }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/surveys/management/surveyFormField/move",
			method: "POST",
			data: {
				surveyFormFieldId: args.surveyFormFieldId,
				direction: args.direction,
			},
		});
		return response as void;
	}
}

export interface SurveyFormFieldApi_FormInitData {
	surveyFormField: SurveyFormField | null | undefined;
}
