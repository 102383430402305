// This file is automatically generated. Do not edit manually.

import { t } from "i18next";

export const PartValuationMethodValues = ["STANDARD_COST", "LAST_PURCHASE_PRICE", "WEIGHTED_AVERAGE_PRICE"] as const;

export type PartValuationMethod = (typeof PartValuationMethodValues)[number];

export const getPartValuationMethodLabels = () => ({
	STANDARD_COST: t("standard_cost"),
	LAST_PURCHASE_PRICE: t("last_purchase_price"),
	WEIGHTED_AVERAGE_PRICE: t("weighted_average_price"),
});

export const getPartValuationMethodLabel = (value: PartValuationMethod): string => {
	return getPartValuationMethodLabels()[value];
};
