import {AavoMosaic} from "../../../../common/mosaic/AavoMosaic.tsx";
import {CostEventPriceListDataGrid} from "./CostEventPriceListDataGrid.tsx";
import {CostEventPriceList} from "../../../../../api/generated/erp/db/types/tables/costEventPriceList.ts";
import {CenteredTypography} from "../../../../common/CenteredTypography.tsx";
import i18n from "i18next";
import React from "react";
import {CostEventPriceListLineDataGrid} from "./CostEventPriceListLineDataGrid.tsx";

export const CostEventPriceListPage = () => {
	const [selectedPriceList, setSelectedPriceList] = React.useState<
		CostEventPriceList | undefined
	>(undefined);

	return (
		<AavoMosaic
			viewId={"FE6B6DB0BD434652"}
			layout={{
				type: "row",
				splitPercentage: 20,
				first: {
					type: "panel",
					title: i18n.t("price_lists"),
					content: (
						<CostEventPriceListDataGrid
							onSelectedPriceListChanged={setSelectedPriceList}
						/>
					),
				},
				second: {
					type: "panel",
					title: i18n.t("price_list_lines"),
					content:
						!selectedPriceList ?
							<CenteredTypography>{i18n.t("select_price_list")}</CenteredTypography>
						:	<CostEventPriceListLineDataGrid
								key={selectedPriceList.costEventPriceListId}
								costEventPriceList={selectedPriceList}
							/>,
				},
			}}
		/>
	);
};
