import { FormCommonProps } from "src/components/common/forms/types.ts";
import i18n from "i18next";
import { requiredNonNegativeIntegerRule } from "src/components/common/forms/validation.ts";
import { FormTextField } from "src/components/common/forms/fields/FormTextField.tsx";
import { NewDocumentFileCreationType } from "src/api/generated/documents/newDocumentFileCreationType.ts";
import { DocumentRevision } from "src/api/generated/postgres/db/types/documents/tables/documentRevision.ts";
import { DocumentRevisionApi } from "src/api/generated/documents/api/documentRevisionApi.ts";
import { uploadFile } from "src/utils/fileUploading";
import { DocumentQueryApi } from "src/api/generated/documents/api/documentQueryApi";
import { openWopiFilePage } from "./wopi/wopiFilePageUtils";
import { DocumentFileCreationFormPart } from "src/components/views/documents/DocumentFileCreationFormPart.tsx";
import { FormCheckbox } from "src/components/common/forms/fields/FormCheckbox";
import { AsyncForm, AsyncFormContentParams } from "src/components/common/forms/AsyncForm.tsx";

export interface DocumentRevisionFormProps extends FormCommonProps<number> {
	documentId: number;
	documentRevision: DocumentRevision | undefined;
}

interface DocumentRevisionFormValues {
	revisionNumber: number;
	note: string;
	release: boolean;
	file: File;
	fileCreationType: NewDocumentFileCreationType;
}

export const DocumentRevisionForm = (props: DocumentRevisionFormProps) => {
	const { documentId, documentRevision, onFormEdited, onCompleted } = props;
	return (
		<AsyncForm<number, DocumentRevisionFormValues, number>
			fetch={() =>
				DocumentRevisionApi.getNextDocumentRevisionNumber({
					documentId,
				})
			}
			getDefaultValues={(defaultRevisionNumber) => {
				if (documentRevision !== undefined)
					return {
						...documentRevision,
						fileCreationType: "UPLOAD",
					};
				else {
					return {
						revisionNumber: defaultRevisionNumber,
						release: true,
						fileCreationType: "UPLOAD",
					};
				}
			}}
			submit={(formValues) => submitForm(documentRevision?.documentRevisionId, documentId, formValues)}
			columns={1}
			onFormEdited={onFormEdited}
			onCompleted={onCompleted}
			render={(params) => <DocumentRevisionFormContent {...params} {...props} />}
		/>
	);
};

interface DocumentRevisionFormContentProps
	extends DocumentRevisionFormProps,
		AsyncFormContentParams<number, DocumentRevisionFormValues> {}

const DocumentRevisionFormContent = ({
	documentRevision,
	control,
	watch,
}: DocumentRevisionFormContentProps) => {
	return (
		<>
			<FormTextField
				control={control}
				name={"revisionNumber"}
				rules={requiredNonNegativeIntegerRule()}
				label={i18n.t("revision")}
			/>
			{documentRevision === undefined && (
				<FormCheckbox control={control} name={"release"} label={i18n.t("release")} />
			)}
			<FormTextField
				control={control}
				name={"note"}
				label={i18n.t("note")}
				multiline={true}
				minRows={5}
			/>
			<DocumentFileCreationFormPart
				control={control}
				watch={watch}
				isExistingRecord={documentRevision !== undefined}
			/>
		</>
	);
};

const submitForm = async (
	inputDocumentRevisionId: number | undefined,
	documentId: number,
	formValues: DocumentRevisionFormValues,
): Promise<number> => {
	const fileHandle = formValues.file ? await uploadFile(formValues.file) : undefined;

	let documentRevisionId;
	if (inputDocumentRevisionId !== undefined) {
		await DocumentRevisionApi.updateDocumentRevision({
			documentRevisionId: inputDocumentRevisionId,
			revisionNumber: formValues.revisionNumber,
			note: formValues.note,
			newFile: fileHandle,
		});
		documentRevisionId = inputDocumentRevisionId;
	} else {
		documentRevisionId = await DocumentRevisionApi.createDocumentRevision({
			documentId: documentId,
			revisionNumber: formValues.revisionNumber,
			release: formValues.release,
			note: formValues.note,
			fileCreationType: formValues.fileCreationType,
			file: fileHandle,
		});
		if (formValues.fileCreationType !== "UPLOAD") {
			const fileUuid = await DocumentQueryApi.getDocumentRevisionPrimaryFileUuid({
				documentRevisionId,
			});
			openWopiFilePage("edit_new", fileUuid);
		}
	}

	return documentRevisionId;
};
