import { MenuItem, MenuItemProps } from "@mui/material";

export interface AavoContextMenuItemProps extends MenuItemProps {
	visible?: boolean;
}

export const AavoMenuItem = (
	{
		visible,
		...other
	}: AavoContextMenuItemProps) => {

	if (visible === false) return null;

	return <MenuItem
		sx={{
			margin: 0,
			padding: 0,
			"& > *": {
				flex: 1
			},
			// Hack to make the button fill the entire width of the menu item,
			// even if it has some wrapper element.
			"& button": {
				paddingX: 2,
				paddingY: 1.25,
				flex: 1
			}
		}}
		{...other} />;
};