import {
	MellanoComponentCountApi,
	MellanoComponentCountApi_Row,
} from "src/api/generated/tenants/mellano/api/mellanoComponentCountApi";
import { AsyncDataGrid } from "src/components/common/dataGrid/AsyncDataGrid";
import { integerColumn, textColumn } from "src/components/common/dataGrid/columns";
import { dataGridPersistentStateProps } from "src/components/common/dataGrid/dataGridStateStorage.ts";
import { faFileDownload } from "@fortawesome/pro-regular-svg-icons";
import { AsyncButton } from "src/components/common/buttons/AsyncButton";
import { downloadFile } from "src/utils/fileDownloading.ts";

export interface ComponentCountDataGridProps {
	componentState: string;
	notInStates: string[];
}

export const ComponentCountDataGrid = ({
	componentState,
	notInStates,
}: ComponentCountDataGridProps) => {
	return (
		<AsyncDataGrid<MellanoComponentCountApi_Row>
			fetchRows={() =>
				MellanoComponentCountApi.getComponentCountsByRoutes({
					componentState,
					notInStates,
				})
			}
			getRowId={(row) => row.route}
			columns={[
				textColumn({
					field: "route",
					headerName: "Reitti",
					width: 200,
				}),
				integerColumn({
					field: "count",
					headerName: "Komponenttien määrä",
					width: 200,
				}),
			]}
			actionBarComponents={
				<>
					<AsyncButton
						label={"Lataa CSV"}
						icon={faFileDownload}
						variant={"outlined"}
						onClick={async () => {
							const file =
								await MellanoComponentCountApi.getComponentCountsByRoutesCsv({
									componentState,
									notInStates,
								});
							downloadFile(file);
						}}
					/>
				</>
			}
			{...dataGridPersistentStateProps("276B8D6768E24850")}
		/>
	);
};
