// This file is automatically generated. Do not edit manually.

import { ShopOrderOperationView } from "src/api/generated/erp/db/types/tables/shopOrderOperationView";
import { FrontendUploadFileHandle } from "src/api/generated/common/frontendUploadFileHandle";
import { ShopOrderOperationDocumentService_UploadDocumentsToShopOrderOperationsResult } from "src/api/generated/erp/production/service/shopOrderOperationDocumentService";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace ShopOrderOperationApi {
	export async function getShopOrderOperations(args: {
		searchQuery?: string;
		shopOrderId: number;
	}): Promise<Array<ShopOrderOperationView>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/shopOrderOperation/getShopOrderOperations",
			method: "POST",
			data: {
				searchQuery: args.searchQuery,
				shopOrderId: args.shopOrderId,
			},
		});
		return response as Array<ShopOrderOperationView>;
	}

	export async function uploadDocumentsToShopOrderOperations(args: {
		shopOrderId: number;
		categoryId?: number | null | undefined;
		filesToUpload: Array<FrontendUploadFileHandle>;
	}): Promise<ShopOrderOperationDocumentService_UploadDocumentsToShopOrderOperationsResult> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/shopOrderOperation/uploadDocumentsToShopOrderOperations",
			method: "POST",
			data: {
				shopOrderId: args.shopOrderId,
				categoryId: args.categoryId,
				filesToUpload: args.filesToUpload,
			},
		});
		return response as ShopOrderOperationDocumentService_UploadDocumentsToShopOrderOperationsResult;
	}
}
