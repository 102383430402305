import {FormCommonProps} from "src/components/common/forms/types.ts";
import {AavoForm} from "src/components/common/forms/AavoForm.tsx";
import {
    ConfigurationProductFamilyCategory
} from "src/api/generated/erp/db/types/tables/configurationProductFamilyCategory.ts";
import {FormTextField} from "src/components/common/forms/fields/FormTextField.tsx";
import i18n from "i18next";
import {requireRule} from "src/components/common/forms/validation.ts";
import {
    ConfigurationProductFamilyCategoryApi
} from "src/api/generated/erp/configurator/management/productFamilies/api/configurationProductFamilyCategoryApi.ts";

export interface ConfigurationProductFamilyCategoryFormProps extends FormCommonProps<number> {
	productFamilyCategory: ConfigurationProductFamilyCategory | undefined;
}

export const ConfigurationProductFamilyCategoryForm = ({
	productFamilyCategory,
	onFormEdited,
	onCompleted,
}: ConfigurationProductFamilyCategoryFormProps) => {
	return (
		<AavoForm
			defaultValues={productFamilyCategory ?? {}}
			submit={submit}
			onCompleted={onCompleted}
			onFormEdited={onFormEdited}
			render={({ control }) => (
				<FormTextField control={control} name={"name"} label={i18n.t("name")} rules={requireRule()} />
			)}
		/>
	);

	async function submit(values: ConfigurationProductFamilyCategory) {
		if (values.configurationProductFamilyCategoryId != null) {
			return await ConfigurationProductFamilyCategoryApi.update({
				productFamilyCategory: values,
			});
		} else {
			return await ConfigurationProductFamilyCategoryApi.insert({
				productFamilyCategory: values,
			});
		}
	}
};
