// This file is automatically generated. Do not edit manually.

import { t } from "i18next";

export const SubProjectPlannedStartDateProvider_ReferenceTypeValues = [
	"PRODUCTION_PLAN",
	"PLANNED_DELIVERY_DATE",
] as const;

export type SubProjectPlannedStartDateProvider_ReferenceType =
	(typeof SubProjectPlannedStartDateProvider_ReferenceTypeValues)[number];

export const getSubProjectPlannedStartDateProvider_ReferenceTypeLabels = () => ({
	PRODUCTION_PLAN: t("production_plan"),
	PLANNED_DELIVERY_DATE: t("planned_delivery_date"),
});

export const getSubProjectPlannedStartDateProvider_ReferenceTypeLabel = (
	value: SubProjectPlannedStartDateProvider_ReferenceType,
): string => {
	return getSubProjectPlannedStartDateProvider_ReferenceTypeLabels()[value];
};
