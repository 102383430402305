// This file is automatically generated. Do not edit manually.

import { IsoDateString } from "src/types/dateTime";
import { ServerSideDataModelRequest } from "src/api/generated/common/dataGrids/serverSideDataModelRequest";
import { ServerSideDataModelResult } from "src/api/generated/common/dataGrids/serverSideDataModelResult";
import { PartAcquisitionsView } from "src/api/generated/erp/db/types/tables/partAcquisitionsView";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace SitePartAcquisitionsApi {
	export async function getPartAcquisitions(args: {
		partId: number;
		beforeDate: IsoDateString | null | undefined;
		dataModelRequest: ServerSideDataModelRequest;
	}): Promise<ServerSideDataModelResult<PartAcquisitionsView>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sitePart/acquisitions/getPartAcquisitions",
			method: "POST",
			data: {
				partId: args.partId,
				beforeDate: args.beforeDate,
				dataModelRequest: args.dataModelRequest,
			},
		});
		return response as ServerSideDataModelResult<PartAcquisitionsView>;
	}
}
