// This file is automatically generated. Do not edit manually.

import { Project } from "src/api/generated/erp/db/types/tables/project";
import { ProjectActivityView } from "src/api/generated/erp/db/types/tables/projectActivityView";
import { CostEventItem } from "src/api/generated/erp/db/types/tables/costEventItem";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace ProjectActivityCostEventApi {
	export async function getProjectOptions(args: {
		searchQuery?: string;
		currentSelection: number | null | undefined;
	}): Promise<Array<Project>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/project/projectActivityCostEvent/getProjectOptions",
			method: "POST",
			data: {
				searchQuery: args.searchQuery,
				currentSelection: args.currentSelection,
			},
		});
		return response as Array<Project>;
	}

	export async function getProjectActivityOptions(args: { projectId: number }): Promise<Array<ProjectActivityView>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/project/projectActivityCostEvent/getProjectActivityOptions",
			method: "POST",
			data: {
				projectId: args.projectId,
			},
		});
		return response as Array<ProjectActivityView>;
	}

	export async function getProjectAvailablePriceListItemOptions(args: {
		projectId: number;
	}): Promise<Array<CostEventItem>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/project/projectActivityCostEvent/getProjectAvailablePriceListItemOptions",
			method: "POST",
			data: {
				projectId: args.projectId,
			},
		});
		return response as Array<CostEventItem>;
	}
}
