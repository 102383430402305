// This file is automatically generated. Do not edit manually.

import { SalesCommissionPaymentState } from "src/api/generated/erp/db/types/enums/salesCommissionPaymentState";
import { ServerSideDataModelRequest } from "src/api/generated/common/dataGrids/serverSideDataModelRequest";
import { ServerSideDataModelResult } from "src/api/generated/common/dataGrids/serverSideDataModelResult";
import { SalesCommissionPaymentView } from "src/api/generated/erp/db/types/tables/salesCommissionPaymentView";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace SalesCommissionPaymentDataGridApi {
	export async function getDataGridData(args: {
		state: SalesCommissionPaymentState | null | undefined;
		dataModelRequest: ServerSideDataModelRequest;
	}): Promise<ServerSideDataModelResult<SalesCommissionPaymentView>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/commissions/payments/dataGrid/getDataGridData",
			method: "POST",
			data: {
				state: args.state,
				dataModelRequest: args.dataModelRequest,
			},
		});
		return response as ServerSideDataModelResult<SalesCommissionPaymentView>;
	}
}
