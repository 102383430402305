import { AavoObjectRef } from "src/api/generated/common/sourceType/aavoObjectRef.ts";
import {
	DocumentsOfMultipleObjectsView,
	DocumentsOfMultipleObjectsViewProps,
} from "src/components/views/documents/objectDocuments/DocumentsOfMultipleObjectsView.tsx";

export interface DocumentsOfSingleObjectViewProps
	extends Omit<DocumentsOfMultipleObjectsViewProps, "objectRefs"> {
	objectRef: AavoObjectRef;
}

export const DocumentsOfSingleObjectView = ({
	objectRef,
	viewId = "503BFB574CC17D77",
	...other
}: DocumentsOfSingleObjectViewProps) => {
	return <DocumentsOfMultipleObjectsView objectRefs={[objectRef]} viewId={viewId} {...other} />;
};
