import { CenteredTypography } from "src/components/common/CenteredTypography.tsx";
import i18n from "i18next";
import { PdfView } from "./PdfView";
import { ThreeJsView } from "./ThreeJsView";
import { ImageView } from "./ImageView";
import { Box } from "@mui/material";

export interface FilePreviewViewProps {
	fileUrl: string;
	extension: string;
}

export const FilePreviewView = ({ fileUrl, extension: extensionProp }: FilePreviewViewProps) => {
	const extension = extensionProp.toLowerCase();
	if (extension === "pdf") return <PdfView fileUrl={fileUrl} />;

	if (extension === "ifc") return <ThreeJsView fileUrl={fileUrl} />;

	if (["jpg", "jpeg", "png", "gif", "svg", "ico"].includes(extension))
		return <ImageView fileUrl={fileUrl} />;

	if (["txt", "btl", "wup", "asc"].includes(extension)) return <TextView fileUrl={fileUrl} />;

	if (["mp4", "webm", "ogg", "mp3", "wav"].includes(extension)) return <VideoView fileUrl={fileUrl} />;

	return (
		<CenteredTypography>{i18n.t("cannot_render_file_with_extension", { extension })}</CenteredTypography>
	);
};

const VideoView = ({ fileUrl }: { fileUrl: string }) => {
	return (
		<Box
			sx={{
				flex: 1,
				display: "flex",
				"& video": {
					flex: 1,
				},
			}}
		>
			<video controls={true}>
				<source src={fileUrl} />
			</video>
		</Box>
	);
};

const TextView = ({ fileUrl }: { fileUrl: string }) => {
	return (
		<object
			data={fileUrl}
			style={{
				flex: 1,
			}}
		/>
	);
};
