// This file is automatically generated. Do not edit manually.

import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace FeeliaLeanSystemInventoryEventApi {
	export async function importInventoryEvents(args: { filePath: string }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/feelia/leanSystem/inventoryEvent/importInventoryEvents",
			method: "POST",
			data: {
				filePath: args.filePath,
			},
		});
		return response as void;
	}

	export async function importInventoryEventsScheduled(): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/feelia/leanSystem/inventoryEvent/importInventoryEventsScheduled",
			method: "POST",
			data: {},
		});
		return response as void;
	}
}
