import { AavoForm } from "src/components/common/forms/AavoForm.tsx";
import { FormCommonProps } from "src/components/common/forms/types.ts";
import { FormTextField } from "src/components/common/forms/fields/FormTextField.tsx";
import { AsyncFetchRender } from "src/components/common/async/AsyncFetchRender.tsx";
import { InvoiceReadingApi } from "src/api/generated/erp/sales/invoicing/api/invoiceReadingApi";
import { InvoiceLine } from "src/api/generated/erp/db/types/tables/invoiceLine.ts";
import i18n from "i18next";
import { requiredNonNegativeFloatRule } from "src/components/common/forms/validation.ts";
import { Box, Typography } from "@mui/material";
import { InvoiceCreationApi } from "src/api/generated/erp/sales/invoicing/api/invoiceCreationApi.ts";
import { InvoiceLineRefundRequest } from "src/api/generated/erp/sales/invoicing/service/invoiceLineRefundRequest.ts";
import { IsoDateString } from "src/types/dateTime.ts";
import { currentLocalDateAsIsoString } from "src/utils/dayjsUtils.ts";
import { FormDateField } from "src/components/common/forms/fields/FormDateField";

export interface CreateRefundInvoiceFormProps extends FormCommonProps<void> {
	chargeInvoiceId: number;
}

export const CreateRefundInvoiceForm = (props: CreateRefundInvoiceFormProps) => {
	const { chargeInvoiceId } = props;
	return (
		<AsyncFetchRender
			fetch={() => InvoiceReadingApi.getInvoiceLines({ invoiceId: chargeInvoiceId })}
			content={(invoiceLines) => (
				<CreateRefundInvoiceFormContent chargeInvoiceLines={invoiceLines} {...props} />
			)}
		/>
	);
};

interface FormValues {
	refundInvoiceDate: IsoDateString;
	invoiceLineRefundRequests: (InvoiceLineRefundRequest & {
		label: string;
	})[];
}

interface CreateRefundInvoiceFormContentProps extends CreateRefundInvoiceFormProps {
	chargeInvoiceLines: InvoiceLine[];
}

const CreateRefundInvoiceFormContent = ({
	chargeInvoiceId,
	chargeInvoiceLines,
	onFormEdited,
	onCompleted,
}: CreateRefundInvoiceFormContentProps) => {
	const submitForm = async (values: FormValues) => {
		await InvoiceCreationApi.createRefundInvoice({
			chargeInvoiceId: chargeInvoiceId,
			refundInvoiceDate: values.refundInvoiceDate,
			invoiceLineRefundRequests: values.invoiceLineRefundRequests,
		});
	};

	return (
		<AavoForm<FormValues, void>
			defaultValues={{
				refundInvoiceDate: currentLocalDateAsIsoString(),
				invoiceLineRefundRequests: chargeInvoiceLines.map((line) => ({
					chargeInvoiceLineId: line.invoiceLineId,
					quantityInBaseUnit: line.quantityInBaseUnits,
					unitPrice: line.unitPrice,
				})),
			}}
			submit={submitForm}
			onFormEdited={onFormEdited}
			onCompleted={onCompleted}
			columns={1}
			render={({ control }) => (
				<>
					<FormDateField
						control={control}
						name={"refundInvoiceDate"}
						label={i18n.t("invoice_date")}
					/>
					{chargeInvoiceLines.map((line, index) => (
						<Box
							key={line.invoiceLineId}
							sx={{
								display: "grid",
								gridTemplateColumns: "1fr auto auto",
								alignItems: "baseline",
								paddingBottom: 2,
								borderBottom: "2px solid",
								borderColor: (theme) => theme.palette.divider,
							}}
						>
							<Typography
								variant={"h6"}
								sx={{
									marginRight: 3,
								}}
							>
								{line.salesPart}:
							</Typography>
							<Box
								sx={{
									display: "flex",
									gap: 2,
								}}
							>
								<FormTextField
									control={control}
									name={`invoiceLineRefundRequests.${index}.quantityInBaseUnit`}
									label={i18n.t("quantity")}
									rules={requiredNonNegativeFloatRule()}
								/>
								<FormTextField
									control={control}
									name={`invoiceLineRefundRequests.${index}.unitPrice`}
									label={i18n.t("unit_price")}
									rules={requiredNonNegativeFloatRule()}
								/>
							</Box>
						</Box>
					))}
				</>
			)}
		/>
	);
};
