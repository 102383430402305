import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { IdentityId } from "src/storage/appState/types";
import i18n from "i18next";
import { capitalize } from "src/utils/strings";
import { mergeSx } from "src/utils/styles";
import { dNone } from "src/styles/sx";
import { selectionSx } from "./styles";
import { IdentityOption } from "src/api/mainApi";

const EMPTY_SELECTION = "empty_identity_selection";

export interface IdentitySelectionProps {
	options: IdentityOption[];
	value: IdentityId;
	onChange: (value: IdentityId) => void;
}

export const IdentitySelection = ({ options, value, onChange }: IdentitySelectionProps) => {
	const hide = options.length === 0;
	return (
		<FormControl variant="standard" sx={mergeSx(hide ? dNone : {}, selectionSx)}>
			<InputLabel id="identity-select-label">{capitalize(i18n.t("user"))}</InputLabel>
			<Select
				labelId="identity-select-label"
				id="identity-select"
				value={value || EMPTY_SELECTION}
				onChange={(e) => {
					e.preventDefault();

					onChange(e.target.value === EMPTY_SELECTION ? null : e.target.value);
				}}
				label={i18n.t("user")}
			>
				<MenuItem value={EMPTY_SELECTION}>{capitalize(i18n.t("own_user"))}</MenuItem>
				{mapOptionsToMenuItems(options)}
			</Select>
		</FormControl>
	);
};

const mapOptionsToMenuItems = (options: IdentityOption[]): React.ReactNode[] => {
	return options.map((option) => {
		return (
			<MenuItem
				key={`${option.principalId}-${option.name}-identity-item`}
				value={option.principalId}
			>
				{option.name}
			</MenuItem>
		);
	});
};
