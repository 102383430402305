import { FieldPath, FieldPathValue, useController, UseControllerProps } from "react-hook-form";
import { FormFieldLayoutProps, formFieldLayoutPropsToSx } from "src/components/common/forms/styles.ts";
import { mergeSx } from "src/utils/styles.ts";
import { AavoTimePicker, AavoTimePickerProps } from "src/components/common/inputFields/AavoTimePicker.tsx";
import dayjs from "dayjs";

export interface FormTimeFieldProps<TFieldValues extends object, TFieldName extends FieldPath<TFieldValues>>
	extends Omit<AavoTimePickerProps, "value" | "name" | "onChange" | "defaultValue" | "errorMessage">,
		Pick<UseControllerProps<TFieldValues, TFieldName>, "control" | "name" | "rules">,
		FormFieldLayoutProps {}

export const FormTimeField = <TFieldValues extends object, TFieldName extends FieldPath<TFieldValues>>({
	control,
	name,
	rules,
	spanGridColumns,
	startNewGridRow,
	sx,
	...other
}: FormTimeFieldProps<TFieldValues, TFieldName>) => {
	const { field, fieldState } = useController({
		name,
		control,
		rules,
		defaultValue: null as FieldPathValue<TFieldValues, TFieldName>,
	});

	return (
		<AavoTimePicker
			errorMessage={fieldState.error?.message}
			sx={mergeSx(
				formFieldLayoutPropsToSx({
					spanGridColumns,
					startNewGridRow,
				}),
				sx,
			)}
			value={field.value != null ? dayjs(field.value, "HH:mm:ss") : null}
			onChange={(newValue) => {
				field.onChange(newValue?.format("HH:mm:ss") ?? null);
			}}
			disabled={field.disabled}
			name={field.name}
			{...other}
		/>
	);
};
