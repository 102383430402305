import { SpcSamplingMethod } from "src/components/views/spc/basedata/controlCharts/form/samplingMethod/SpcSamplingMethod.tsx";
import i18n from "i18next";
import { FormTextField } from "src/components/common/forms/fields/FormTextField.tsx";
import { FormSelectField } from "src/components/common/forms/fields/FormSelectField.tsx";
import { requireRule } from "src/components/common/forms/validation.ts";

export const getOperationCompletedAmountSamplingMethod = (): SpcSamplingMethod => {
	return {
		key: "futuraplan_operation_completed_amount",
		label: i18n.t("operation_completed_amount"),
		renderParameterFields: ({ control }) => (
			<>
				<FormTextField
					control={control}
					name={"samplingParams.param1"}
					label={i18n.t("state_of_completed")}
				/>
				<FormSelectField
					control={control}
					name={"samplingParams.param2"}
					label={"Työvaiheesta valmistunut määrä"}
					options={["Maali", "Melamiini"]}
					getOptionKey={(o) => o}
					getOptionLabel={(o) => o}
					rules={requireRule()}
				/>
			</>
		),
	};
};
