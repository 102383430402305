import { AavoObjectRef } from "src/api/generated/common/sourceType/aavoObjectRef.ts";
import { useState } from "react";
import { DocumentActiveRevisionView } from "src/api/generated/postgres/db/types/documents/tables/documentActiveRevisionView.ts";
import i18n from "i18next";
import { ConnectDocumentsToObjectDataGrid } from "src/components/views/documents/connections/ConnectDocumentsToObjectDataGrid.tsx";
import { DocumentPreviewView } from "src/components/views/documents/preview/DocumentPreviewView.tsx";
import { AavoMosaic } from "src/components/common/mosaic/AavoMosaic.tsx";
import { useMediaQueryBreakpointUp } from "src/utils/breakpointUtils.ts";
import { CenteredTypography } from "src/components/common/CenteredTypography.tsx";

export interface ConnectDocumentsToObjectViewProps {
	objectRef: AavoObjectRef;
	onDocumentsConnected: () => void;
}

export const ConnectDocumentsToObjectView = ({
	objectRef,
	onDocumentsConnected,
}: ConnectDocumentsToObjectViewProps) => {
	const [selectedDocuments, setSelectedDocuments] = useState<DocumentActiveRevisionView[]>([]);
	const documentToPreview = selectedDocuments[0];

	const connectDocumentsToObjectDataGrid = (
		<ConnectDocumentsToObjectDataGrid
			objectRef={objectRef}
			onSelectedDocumentsChanged={setSelectedDocuments}
			onDocumentsConnected={onDocumentsConnected}
		/>
	);

	const showPreview = useMediaQueryBreakpointUp("md");
	if (!showPreview) return connectDocumentsToObjectDataGrid;

	return (
		<AavoMosaic
			viewId={"61D749A534DEFB11"}
			layout={{
				type: "row",
				splitPercentage: 65,
				first: {
					type: "panel",
					title: i18n.t("documents"),
					content: connectDocumentsToObjectDataGrid,
				},
				second: {
					type: "panel",
					title: i18n.t("preview"),
					content:
						documentToPreview == null ?
							<CenteredTypography>{i18n.t("select_a_document")}</CenteredTypography>
						:	<DocumentPreviewView
								key={documentToPreview?.documentId}
								documentId={documentToPreview?.documentId}
							/>,
				},
			}}
		/>
	);
};
