import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton, IconButtonProps, useTheme } from "@mui/material";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { faSpinnerThird } from "@fortawesome/pro-regular-svg-icons";
import React, { ForwardedRef, forwardRef, ReactElement } from "react";
import { alpha } from "@mui/material/styles";
import { mergeSx } from "src/utils/styles.ts";
import { TooltipWithWrapper } from "src/components/common/tooltips/TooltipWithWrapper.tsx";

export interface AavoIconButtonProps extends IconButtonProps {
	variant?: "contained" | "text";
	color?: Exclude<IconButtonProps["color"], "default" | "inherit">;
	icon?: IconDefinition;
	iconEl?: React.ReactNode;
	loading?: boolean;
	tooltip?: string;
	disableTooltipEl?: boolean;
}

export const AavoIconButton = forwardRef(
	(
		{
			icon,
			iconEl: iconElProp,
			loading,
			tooltip,
			color = "primary",
			variant,
			sx,
			disableTooltipEl,
			...other
		}: AavoIconButtonProps,
		ref: ForwardedRef<HTMLButtonElement>,
	) => {
		const { palette, spacing } = useTheme();

		const iconEl =
			iconElProp ? iconElProp
			: icon ? <FontAwesomeIcon icon={icon} fixedWidth />
			: undefined;

		if (iconEl === undefined) {
			console.error("AavoIconButton: icon or iconEl must be provided");
			return null;
		}

		return wrapToTooltip(
			<IconButton
				ref={ref}
				sx={mergeSx(
					{
						borderRadius: spacing(1),
					},
					getVariantSx(),
					sx,
				)}
				color={color}
				{...other}
			>
				{loading ?
					<FontAwesomeIcon icon={faSpinnerThird} spin={true} fixedWidth />
				:	iconEl}
			</IconButton>,
		);

		function wrapToTooltip(node: ReactElement) {
			return disableTooltipEl ? node : <TooltipWithWrapper title={tooltip}>{node}</TooltipWithWrapper>;
		}

		function getVariantSx() {
			const paletteColor = palette[color];
			switch (variant) {
				case "contained":
					return {
						backgroundColor: paletteColor.main,
						color: paletteColor.contrastText,
						"&:hover": {
							backgroundColor: alpha(paletteColor.main, 0.8),
						},
						"&:disabled": {
							backgroundColor: palette.action.disabled,
						},
					};
				default:
					return {};
			}
		}
	},
);
