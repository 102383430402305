// This file is automatically generated. Do not edit manually.

import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace ShopOrderActionApi {
	export async function releaseShopOrders(args: { shopOrderIds: Array<number | null | undefined> }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/shopOrders/releaseShopOrders",
			method: "POST",
			data: {
				shopOrderIds: args.shopOrderIds,
			},
		});
		return response as void;
	}

	export async function revertReleaseShopOrder(args: { shopOrderId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/shopOrders/revertReleaseShopOrder",
			method: "POST",
			data: {
				shopOrderId: args.shopOrderId,
			},
		});
		return response as void;
	}

	export async function closeShopOrder(args: { shopOrderId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/shopOrders/closeShopOrder",
			method: "POST",
			data: {
				shopOrderId: args.shopOrderId,
			},
		});
		return response as void;
	}

	export async function revertCloseShopOrder(args: { shopOrderId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/shopOrders/revertCloseShopOrder",
			method: "POST",
			data: {
				shopOrderId: args.shopOrderId,
			},
		});
		return response as void;
	}

	export async function cancelShopOrder(args: { shopOrderId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/shopOrders/cancelShopOrder",
			method: "POST",
			data: {
				shopOrderId: args.shopOrderId,
			},
		});
		return response as void;
	}
}
