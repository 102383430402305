import "regenerator-runtime/runtime";
import { css, html, LitElement, unsafeCSS } from "lit";

function register(
	name,
	ElmComponent,
	{
		styles = "",
		staticFlags = {},
		// Map outgoing ports to events
		portsToEvents = [],
		portSubscriptions = [],
		// Map certain properties to incoming ports
		portProperties = [],
		defaultFlags = {},
		useShadowRoot = true,
	} = {}
) {
	class ElmElement extends LitElement {
		static get properties() {
			const ret = {
				flags: { type: Object },
			};
			return portProperties.reduce(
				(acc, { propName, type, hasChanged }) => {
					return {
						...acc,
						[propName]: { type, hasChanged },
					};
				},
				ret
			);
		}

		constructor() {
			super();
			this.flags = null;
			this._elmElement = null;
			this._portSubscriptions = {};
		}

		createRenderRoot() {
			return useShadowRoot ? super.createRenderRoot() : this;
		}

		static get styles() {
			return [
				css`
					:host {
						flex: 1;
						display: flex;
						height: inherit;
						min-width: 0;
						min-height: 0;
					}

					.elm-container-wrapper {
						flex: 1;
						display: flex;
						min-width: 0;
						min-height: 0;
					}

					.elm-container-wrapper > div {
						flex: 1;
						display: flex;
						flex-direction: column;
						width: inherit;
						min-width: 0;
						min-height: 0;
					}
				`,
				unsafeCSS(styles),
			];
		}

		render() {
			return html` <div class="elm-container-wrapper">
				<div class="elm-container" />
			</div>`;
		}

		firstUpdated(_changedProperties) {
			if (this.flags === null) {
				console.warn(
					`Attempt to initialize elmWebComponent '${name}' without flags`
				);
				return;
			}

			const elmDiv =
				this._getRootElement().querySelector(".elm-container");
			const allFlags = Object.assign(
				{},
				defaultFlags,
				staticFlags,
				this.flags
			);
			this._elmElement = ElmComponent.init({
				flags: allFlags,
				node: elmDiv,
			});

			// These outgoing ports should be converted to events.
			portsToEvents.forEach(({ portName, eventName }) => {
				this._subscribeToPort(portName, (data) => {
					const event = new CustomEvent(eventName, {
						detail: data,
					});
					this.dispatchEvent(event);
				});
			});

			// These outgoing ports should be propagated to provided handlers.
			portSubscriptions.forEach(({ portName, handler }) => {
				this._subscribeToPort(portName, (data) => {
					handler({
						data,
						rootElement: this._getRootElement(),
						componentRef: this,
						elmElement: this._elmElement,
					});
				});
			});
		}

		updated(changedProperties) {
			portProperties.forEach(({ propName, portName }) => {
				if (changedProperties.has(propName) && this._elmElement) {
					this._elmElement.ports[portName].send(this[propName]);
				}
			});
		}

		_getRootElement() {
			return useShadowRoot ? this.shadowRoot : this;
		}

		_subscribeToPort(portName, handler) {
			this._unsubscribePort(portName);
			this._elmElement.ports[portName].subscribe(handler);
			this._portSubscriptions[portName] = handler;
		}

		disconnectedCallback() {
			if (!this._elmElement) {
				console.warn("Elm element not found on disconnectedCallback");
				return;
			}
			for (const portName in Object.keys(this._portSubscriptions)) {
				this._unsubscribePort(portName);
			}
			this._portSubscriptions = {};
			this._elmElement.ports.unmount.send(null);
		}

		_unsubscribePort(portName) {
			const handler = this._portSubscriptions[portName];
			if (handler) this._elmElement.ports[portName].unsubscribe(handler);
		}
	}

	customElements.define(name, ElmElement);
}

export default { register };
