import {TenantConfig} from "src/tenantCustomizations/TenantCustomizations.ts";
import {
    WarehouseReplenishmentMethod,
    WarehouseReplenishmentMethodValues,
} from "src/api/generated/erp/db/types/enums/warehouseReplenishmentMethod.ts";

export function getReplenishmentMethodOptions(tenantConfig: TenantConfig): WarehouseReplenishmentMethod[] {
	let replenishmentMethodOptions: WarehouseReplenishmentMethod[] = [...WarehouseReplenishmentMethodValues];
	if (!tenantConfig.erp.purchaseEnabled)
		replenishmentMethodOptions = replenishmentMethodOptions.filter((method) => method !== "PURCHASE_REQUEST");
	if (!tenantConfig.erp.manufacturingEnabled)
		replenishmentMethodOptions = replenishmentMethodOptions.filter((method) => method !== "SHOP_ORDER");
	return replenishmentMethodOptions;
}