import { dateColumn, floatColumn, integerColumn, textColumn } from "src/components/common/dataGrid/columns.tsx";
import i18n from "i18next";
import { AsyncUserSelectField } from "src/components/views/users/AsyncUserSelectField.tsx";
import { CustomerOrderBillingPlanApi } from "src/api/generated/erp/sales/billingPlan/customerOrder/api/customerOrderBillingPlanApi.ts";
import { CustomerOrderBillingPlanLineView } from "src/api/generated/erp/db/types/tables/customerOrderBillingPlanLineView.ts";
import { OpenCustomerOrderButton } from "src/components/views/erp/utilComponents/OpenCustomerOrderButton.tsx";
import { AsyncButton } from "src/components/common/buttons/AsyncButton.tsx";
import { faCheck } from "@fortawesome/pro-regular-svg-icons";
import { genericNullableValue } from "src/utils/genericNullableValue.ts";
import { useConfirmDialog } from "src/components/common/dialogs/confirmDialog/useConfirmDialog.ts";
import { CustomerOrderBillingPlanLinesToApproveDataGridApi } from "src/api/generated/erp/sales/billingPlan/customerOrder/api/customerOrderBillingPlanLinesToApproveDataGridApi.ts";
import { useServerSideDataGridModel } from "src/components/common/dataGrid/gridModel/useServerSideDataGridModel.tsx";
import { ControlledAsyncDataGrid } from "src/components/common/dataGrid/ControlledAsyncDataGrid.tsx";

export const CustomerOrderBillingPlanLinesToApproveDataGrid = () => {
	const showConfirmDialog = useConfirmDialog();

	const { dataGridProps, onlySelectedRow, refreshData } = useServerSideDataGridModel({
		fetchData: (params) => CustomerOrderBillingPlanLinesToApproveDataGridApi.getDataGridData(params),
		initialParams: {
			responsiblePersonId: genericNullableValue<number>(),
		},
		getRowId: (row) => row.customerOrderBillingPlanLineId,
		gridId: "42EE67271287F854",
	});

	return (
		<ControlledAsyncDataGrid<CustomerOrderBillingPlanLineView>
			columns={[
				integerColumn({
					field: "customerOrderId",
					headerName: i18n.t("customer_order"),
				}),
				textColumn({
					field: "orderReference",
					headerName: i18n.t("order_reference"),
				}),
				textColumn({
					field: "customerName",
					headerName: i18n.t("customer"),
				}),
				textColumn({
					field: "description",
					headerName: i18n.t("batch_description"),
				}),
				dateColumn({
					field: "estimatedApprovalDate",
					headerName: i18n.t("estimated_approval_date"),
					width: 140,
				}),
				dateColumn({
					field: "estimatedPaymentDate",
					headerName: i18n.t("estimated_payment_date"),
					width: 140,
				}),
				floatColumn({
					field: "billingPlanSum",
					headerName: i18n.t("sum"),
				}),
				integerColumn({
					field: "billingPlanPercent",
					headerName: i18n.t("percent"),
				}),
				textColumn({
					field: "customerOrderResponsiblePersonName",
					headerName: i18n.t("responsible_person"),
				}),
			]}
			actionBarComponents={
				<>
					<AsyncUserSelectField
						label={i18n.t("responsible_person")}
						fetchOptions={({ searchQuery, currentSelection }) =>
							CustomerOrderBillingPlanLinesToApproveDataGridApi.getResponsiblePersonOptions({
								searchQuery,
								currentSelection,
							})
						}
						onChange={async (value) => {
							await refreshData({ responsiblePersonId: value?.id ?? null });
						}}
					/>
					<OpenCustomerOrderButton
						customerOrderId={onlySelectedRow?.customerOrderId}
						variant={"outlined"}
						disabled={!onlySelectedRow}
					/>
					<AsyncButton
						label={i18n.t("approve")}
						icon={faCheck}
						disabled={!onlySelectedRow}
						variant={"outlined"}
						onClick={async () => {
							if (!onlySelectedRow) return;
							const confirmed = await showConfirmDialog({});
							if (!confirmed) return;

							await CustomerOrderBillingPlanApi.approveCustomerOrderBillingPlanLine({
								billingPlanLineId: onlySelectedRow.customerOrderBillingPlanLineId,
							});
							await refreshData();
						}}
					/>
				</>
			}
			{...dataGridProps}
		/>
	);
};
