import { IsoDateString } from "src/types/dateTime.ts";
import { CustomerOrderLineView } from "src/api/generated/erp/db/types/tables/customerOrderLineView.ts";
import { currentLocalDateAsIsoString } from "src/utils/dayjsUtils.ts";
import { AavoForm } from "src/components/common/forms/AavoForm.tsx";
import { FormDateField } from "src/components/common/forms/fields/FormDateField.tsx";
import { Box, Typography } from "@mui/material";
import i18n from "i18next";
import { requireRule } from "src/components/common/forms/validation.ts";
import { FormCheckbox } from "src/components/common/forms/fields/FormCheckbox.tsx";
import { AsyncFetchRender } from "src/components/common/async/AsyncFetchRender.tsx";
import { CustomerOrderInvoicingApi } from "src/api/generated/erp/sales/invoicing/api/customerOrderInvoicingApi.ts";

export interface InvoiceCreationFormProps {
	customerOrderId: number;
	submit: (formValues: InvoiceCreationFormValues) => Promise<unknown>;
	closeForm: () => void;
	showCustomerOrderLines?: boolean;
}

export interface InvoiceCreationFormValues {
	invoiceDate: IsoDateString;
	selectedCustomerOrderLineIds: number[];
}

export const CustomerOrderInvoiceCreationForm = (props: InvoiceCreationFormProps) => {
	const { customerOrderId } = props;
	return (
		<AsyncFetchRender
			fetch={() => CustomerOrderInvoicingApi.getCustomerOrderDirectlyBillableLines({ customerOrderId })}
			content={(selectableCustomerOrderLines) => (
				<InvoiceCreationFormContent
					{...props}
					selectableCustomerOrderLines={selectableCustomerOrderLines}
				/>
			)}
		/>
	);
};

interface FormValuesInternal {
	invoiceDate: IsoDateString;
	selectableCustomerOrderLines: CustomerOrderLineWithCheck[];
}

interface CustomerOrderLineWithCheck extends CustomerOrderLineView {
	checked: boolean;
}

interface InvoiceCreationFormContentProps extends InvoiceCreationFormProps {
	selectableCustomerOrderLines: CustomerOrderLineView[];
}

export const InvoiceCreationFormContent = ({
	selectableCustomerOrderLines,
	submit,
	closeForm,
	showCustomerOrderLines = true,
}: InvoiceCreationFormContentProps) => {
	const submitForm = async (values: FormValuesInternal) => {
		return await submit({
			invoiceDate: values.invoiceDate,
			selectedCustomerOrderLineIds: values.selectableCustomerOrderLines
				.filter((line) => line.checked)
				.map((line) => line.customerOrderLineId),
		});
	};

	return (
		<AavoForm
			defaultValues={{
				invoiceDate: currentLocalDateAsIsoString(),
				selectableCustomerOrderLines: selectableCustomerOrderLines.map((line) => ({
					...line,
					checked: true,
				})),
			}}
			submit={submitForm}
			columns={1}
			onCompleted={closeForm}
			render={({ control }) => (
				<>
					<FormDateField
						control={control}
						name={"invoiceDate"}
						label={i18n.t("invoice_date")}
						rules={requireRule()}
					/>
					{showCustomerOrderLines && selectableCustomerOrderLines.length !== 0 && (
						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
							}}
						>
							<Typography variant={"h6"}>
								{i18n.t("customer_order_lines_to_invoice")}:
							</Typography>
							{selectableCustomerOrderLines.map((customerOrderLine, index) => {
								return (
									<FormCheckbox
										key={customerOrderLine.customerOrderLineId}
										control={control}
										name={`selectableCustomerOrderLines.${index}.checked`}
										label={`${customerOrderLine.lineNumber}
											 | ${customerOrderLine.salesPartDescription}
											 | ${customerOrderLine.mark}`}
									/>
								);
							})}
						</Box>
					)}
				</>
			)}
		/>
	);
};
