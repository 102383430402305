import {Part} from "src/api/generated/erp/db/types/tables/part.ts";
import {DeepPartial} from "react-hook-form";
import {AavoForm} from "src/components/common/forms/AavoForm.tsx";
import {FormNumberField} from "src/components/common/forms/fields/FormNumberField";
import {FormCommonProps} from "src/components/common/forms/types.ts";
import {requireRule} from "src/components/common/forms/validation";
import {Site} from "src/api/generated/erp/db/types/tables/site.ts";
import i18n from "i18next";
import {useState} from "react";
import {AavoTextField} from "src/components/common/inputFields/AavoTextField.tsx";
import {
    PartBatchSizeCalculationParams
} from "src/api/generated/io/aavo/applications/db/erp/types/partBatchSizeCalculationParams.ts";
import {FormTextField} from "src/components/common/forms/fields/FormTextField.tsx";

export interface PartBatchSizeCalculationFormProps extends FormCommonProps<FormResult> {
	part: Part;
	site: Site;
}

export interface FormValues extends PartBatchSizeCalculationParams {}

export interface FormResult {
	batchSize: number | null;
	batchSizeCalculationParams: PartBatchSizeCalculationParams;
}

export const PartBatchSizeCalculationForm = ({
	site,
	part,
	onCompleted,
	onFormEdited,
}: PartBatchSizeCalculationFormProps) => {
	const defaultValues = part.batchSizeCalculationParams != null ? part.batchSizeCalculationParams : {};

	const [annualHoldingCosts, setAnnualHoldingCosts] = useState<number | null>(calculateHoldingCosts(defaultValues));
	const [batchSize, setBatchSize] = useState<number | null>(calculateBatchSize(defaultValues));

	return (
		<AavoForm
			defaultValues={defaultValues}
			onCompleted={onCompleted}
			onFormEdited={onFormEdited}
			submit={submit}
			render={({ control, watch }) => {
				watch((newValues) => {
					setAnnualHoldingCosts(calculateHoldingCosts(newValues));
					setBatchSize(calculateBatchSize(newValues));
				});

				const inventoryTurnoverDays = calculateInventoryTurnover();

				return (
					<>
						<FormNumberField
							control={control}
							name={"annualDemand"}
							label={`${i18n.t("annual_demand")}*`}
							rules={requireRule()}
						/>
						<FormNumberField
							control={control}
							name={"orderPlacementCost"}
							label={`${i18n.t("order_placement_cost")}*`}
							rules={requireRule()}
						/>
						<AavoTextField label={i18n.t("unit_cost")} value={`${part.standardCost}€`} disabled />
						<AavoTextField
							label={i18n.t("site_interest_rate")}
							value={`${site.interestRatePercent} %`}
							disabled
						/>
						<FormNumberField
							control={control}
							name={"annualStorageCost"}
							label={`${i18n.t("annual_storage_cost")}*`}
							rules={requireRule()}
						/>
						<AavoTextField
							label={i18n.t("annual_holding_costs")}
							value={annualHoldingCosts == null ? "-" : `${annualHoldingCosts.toFixed(2)} €`}
							disabled
						/>
						<AavoTextField
							label={i18n.t("batch_size")}
							value={batchSize == null ? "-" : `${batchSize}`}
							disabled
						/>
						<AavoTextField
							label={i18n.t("inventory_turnover_days")}
							value={inventoryTurnoverDays == null ? "-" : `${inventoryTurnoverDays}`}
							disabled
						/>
						<FormTextField control={control} name={"note"} label={i18n.t("note")} />
					</>
				);

				function calculateInventoryTurnover(): number | null {
					const annualDemand = watch("annualDemand");
					if (annualDemand == null || batchSize == null) return null;

					return 365 / (annualDemand / batchSize);
				}
			}}
		/>
	);

	function calculateHoldingCosts(values: DeepPartial<FormValues>): number | null {
		if (values.annualStorageCost == null || part.standardCost == null || site.interestRatePercent == null)
			return null;

		const interestRateFactor = site.interestRatePercent / 100;

		return interestRateFactor * part.standardCost + values.annualStorageCost;
	}

	function calculateBatchSize(values: DeepPartial<FormValues>): number | null {
		const annualHoldingCosts = calculateHoldingCosts(values);
		if (annualHoldingCosts == null || values.annualDemand == null || values.orderPlacementCost == null) return null;

		return Math.round(Math.sqrt((2 * values.annualDemand * values.orderPlacementCost) / annualHoldingCosts));
	}

	function submit(formValues: FormValues): FormResult {
		return {
			batchSize,
			batchSizeCalculationParams: formValues,
		};
	}
};
