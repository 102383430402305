import { AavoDialog, AavoDialogProps } from "src/components/common/dialogs/AavoDialog";
import i18n from "i18next";
import { AavoButton } from "src/components/common/buttons/AavoButton";
import { Typography } from "@mui/material";

export interface ConfirmDialogProps
	extends Omit<AavoDialogProps, "title" | "actions" | "size" | "children"> {
	title?: string;
	message?: string;
	onConfirm: () => void;
	onCancel?: () => void;
	confirmButtonText?: string;
	cancelButtonText?: string;
}

export const ConfirmDialog = ({
	title,
	message,
	onConfirm,
	onCancel,
	onClose,
	confirmButtonText,
	cancelButtonText,
	...other
}: ConfirmDialogProps) => {
	return (
		<AavoDialog
			size={"sm"}
			title={title || i18n.t("are_you_sure")}
			actions={
				<>
					<AavoButton
						label={cancelButtonText ?? i18n.t("cancel")}
						color={"secondary"}
						onClick={() => {
							if (onCancel) {
								onCancel();
							} else if (onClose) {
								onClose("escapeKeyDown");
							}
						}}
					/>
					<AavoButton
						label={confirmButtonText ?? i18n.t("ok")}
						onClick={() => {
							onConfirm();
						}}
					/>
				</>
			}
			onClose={onClose}
			{...other}
		>
			<Typography
				sx={{
					whiteSpace: "pre-wrap",
					marginY: 1,
					marginX: 2
				}}
			>
				{message}
			</Typography>
		</AavoDialog>
	);
};
