// This file is automatically generated. Do not edit manually.

import { CostEventObjectTypeSettingsView } from "src/api/generated/erp/db/types/tables/costEventObjectTypeSettingsView";
import { CostEventPriceList } from "src/api/generated/erp/db/types/tables/costEventPriceList";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace CostEventObjectTypeSettingsApi {
	export async function getAll(): Promise<CostEventObjectTypeSettingsApi_CostEventObjectTypeSettingsData> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/costEvents/costEventObjectTypeSettings/getAll",
			method: "POST",
			data: {},
		});
		return response as CostEventObjectTypeSettingsApi_CostEventObjectTypeSettingsData;
	}

	export async function save(args: {
		costEventObjectTypeSettings: Array<CostEventObjectTypeSettingsView>;
	}): Promise<Array<CostEventObjectTypeSettingsView>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/costEvents/costEventObjectTypeSettings/save",
			method: "POST",
			data: {
				costEventObjectTypeSettings: args.costEventObjectTypeSettings,
			},
		});
		return response as Array<CostEventObjectTypeSettingsView>;
	}
}

export interface CostEventObjectTypeSettingsApi_CostEventObjectTypeSettingsData {
	costEventObjectTypeSettings: Array<CostEventObjectTypeSettingsView>;
	priceLists: Array<CostEventPriceList>;
}
