// This file is automatically generated. Do not edit manually.

import { SalesCommissionLineView } from "src/api/generated/erp/db/types/tables/salesCommissionLineView";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace SalesCommissionPaymentLinesDataGridApi {
	export async function getCommissionPaymentLines(args: {
		salesCommissionPaymentId: number;
	}): Promise<Array<SalesCommissionLineView>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/commissions/payments/dataGrid/getCommissionPaymentLines",
			method: "POST",
			data: {
				salesCommissionPaymentId: args.salesCommissionPaymentId,
			},
		});
		return response as Array<SalesCommissionLineView>;
	}
}
