// This file is automatically generated. Do not edit manually.

import { t } from "i18next";

export const SalesPriceListStateValues = ["INITIAL", "RELEASED", "DEACTIVATED"] as const;

export type SalesPriceListState = (typeof SalesPriceListStateValues)[number];

export const getSalesPriceListStateLabels = () => ({
	INITIAL: t("initial"),
	RELEASED: t("released"),
	DEACTIVATED: t("deactivated"),
});

export const getSalesPriceListStateLabel = (value: SalesPriceListState): string => {
	return getSalesPriceListStateLabels()[value];
};
