// This file is automatically generated. Do not edit manually.

import { PartWarehouseLocationView } from "src/api/generated/erp/db/types/tables/partWarehouseLocationView";
import { ShopOrderView } from "src/api/generated/erp/db/types/tables/shopOrderView";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace IncreaseShopOrderCompletedQuantityFormApi {
	export async function getFormInitData(args: {
		shopOrderId: number;
	}): Promise<IncreaseShopOrderCompletedQuantityFormApi_FormInitData> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/shopOrders/increaseShopOrderCompletedQuantityForm/getFormInitData",
			method: "POST",
			data: {
				shopOrderId: args.shopOrderId,
			},
		});
		return response as IncreaseShopOrderCompletedQuantityFormApi_FormInitData;
	}

	export async function increaseShopOrderCompletedQuantity(args: {
		shopOrderId: number;
		completedQuantityInWarehouseUnits: number;
		partWarehouseLocationId: number | null | undefined;
		closeShopOrder: boolean;
	}): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/shopOrders/increaseShopOrderCompletedQuantityForm/increaseShopOrderCompletedQuantity",
			method: "POST",
			data: {
				shopOrderId: args.shopOrderId,
				completedQuantityInWarehouseUnits: args.completedQuantityInWarehouseUnits,
				partWarehouseLocationId: args.partWarehouseLocationId,
				closeShopOrder: args.closeShopOrder,
			},
		});
		return response as void;
	}
}

export interface IncreaseShopOrderCompletedQuantityFormApi_FormInitData {
	defaultPartWarehouseLocationId: number | null | undefined;
	partWarehouseLocationOptions: Array<PartWarehouseLocationView>;
	shopOrder: ShopOrderView;
}
