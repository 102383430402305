import { Primitive } from "src/types/primitive";
import { FieldPath, FieldPathValue, useController, UseControllerProps } from "react-hook-form";
import { LazySelectField, LazySelectFieldApi, LazySelectFieldProps } from "../../inputFields/LazySelectField";
import { ConditionalNullable } from "src/components/common/inputFields/types.ts";
import { useEffect, useRef } from "react";
import { logError } from "src/errorHandling/errorLogging.ts";

export interface FormLazySelectFieldProps<
	TFieldValues extends object,
	TFieldName extends FieldPath<TFieldValues>,
	Option,
	Key extends Primitive,
	DisableClearable extends boolean | undefined,
> extends Omit<LazySelectFieldProps<Option, Key, DisableClearable>, "value">,
		Pick<UseControllerProps<TFieldValues, TFieldName>, "control" | "name" | "rules"> {
	fieldValueToKey?: (v: FieldPathValue<TFieldValues, TFieldName>) => Key;
	selectionToFieldValue?: (
		key: ConditionalNullable<Key, DisableClearable>,
		option: ConditionalNullable<Option, DisableClearable>,
	) => FieldPathValue<TFieldValues, TFieldName> | null;
}

export const FormLazySelectField = <
	TFieldValues extends object,
	TFieldName extends FieldPath<TFieldValues>,
	Option,
	Key extends Primitive,
	DisableClearable extends boolean | undefined,
>({
	control,
	name,
	rules,
	onChange,
	disabled,
	fieldValueToKey,
	selectionToFieldValue,
	...other
}: FormLazySelectFieldProps<TFieldValues, TFieldName, Option, Key, DisableClearable>) => {
	const { field, fieldState } = useController<TFieldValues, TFieldName>({
		name,
		control,
		rules,
	});
	const selectFieldApiRef = useRef<LazySelectFieldApi<Key> | null>(null);

	useEffect(() => {
		if (field.value === undefined) return;
		if (selectFieldApiRef.current == null) {
			logError("FormLazySelectField: selectFieldApiRef value not set");
			return;
		}
		selectFieldApiRef.current.setValue(convertFieldValueToKey(field.value));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [field.value]);

	return (
		<LazySelectField
			apiRef={selectFieldApiRef}
			error={fieldState.error?.message}
			disabled={disabled || field.disabled}
			onBlur={field.onBlur}
			defaultValue={convertFieldValueToKey(field.value)}
			onChange={(key: Key | null, option: Option | null) => {
				const keyMaybeNullable = key as ConditionalNullable<Key, DisableClearable>;
				const optionMaybeNullable = option as ConditionalNullable<Option, DisableClearable>;

				const fieldOnChangeInput =
					selectionToFieldValue != null ?
						selectionToFieldValue(keyMaybeNullable, optionMaybeNullable)
					:	keyMaybeNullable;

				field.onChange(fieldOnChangeInput);

				onChange?.(keyMaybeNullable, optionMaybeNullable);
			}}
			{...other}
		/>
	);

	function convertFieldValueToKey(value: FieldPathValue<TFieldValues, TFieldName>): Key | null {
		try {
			return fieldValueToKey ? fieldValueToKey(value) : value;
		} catch (e) {
			logError(e);
			return null;
		}
	}
};
