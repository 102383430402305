import { CustomerOrderBillingPlanDataGridBase } from "src/components/views/erp/sales/billingPlan/customerOrder/CustomerOrderBillingPlanDataGridBase.tsx";
import i18n from "i18next";
import { faCopy } from "@fortawesome/pro-regular-svg-icons";
import { AsyncButton } from "src/components/common/buttons/AsyncButton.tsx";
import { useInputDialog } from "src/components/common/dialogs/input/useInputDialog.tsx";
import { RefreshableElementRef } from "src/utils/useRefreshRef.ts";
import { BillingPlanModelApi } from "src/api/generated/erp/sales/billingPlan/model/api/billingPlanModelApi.ts";

export interface CustomerOrderBillingPlanCrudDataGridProps {
	customerOrderId: number;
	refreshRef: RefreshableElementRef;
	customerOrdersRefreshRef?: RefreshableElementRef;
}

export const CustomerOrderBillingPlanCrudDataGrid = ({
	customerOrderId,
	refreshRef,
	customerOrdersRefreshRef,
}: CustomerOrderBillingPlanCrudDataGridProps) => {
	const showInputDialog = useInputDialog();

	return (
		<CustomerOrderBillingPlanDataGridBase
			refreshRef={refreshRef}
			customerOrderId={customerOrderId}
			editable
			enableCancelledLines
			actionBarComponents={
				<>
					<AsyncButton
						label={i18n.t("create_from_template")}
						icon={faCopy}
						variant={"outlined"}
						onClick={createLineFromModel}
					/>
				</>
			}
			customerOrderRefreshRef={customerOrdersRefreshRef}
		/>
	);

	async function createLineFromModel() {
		const models = await BillingPlanModelApi.getAllBillingPlanModels();

		const billingPlanModelId = await showInputDialog({
			type: "singleNumberSelect",
			title: i18n.t("create_from_template"),
			fieldLabel: i18n.t("template"),
			required: true,
			defaultValue: null,
			fieldProps: {
				selection: {
					options: models.map((option) => ({
						value: option.billingPlanModelId,
						label: option.name,
					})),
				},
			},
		});
		if (billingPlanModelId === undefined) return;

		await BillingPlanModelApi.copyBillingPlanModelToCustomerOrder({
			customerOrderId,
			billingPlanModelId,
		});

		await Promise.all([refreshRef.refresh(), customerOrdersRefreshRef?.refresh()]);
	}
};
