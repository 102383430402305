// This file is automatically generated. Do not edit manually.

import { ConfiguratorLibraryVersion } from "src/api/generated/erp/db/types/tables/configuratorLibraryVersion";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace ConfiguratorLibraryVersionsApi {
	export async function getConfiguratorLibraryVersions(args: {
		libraryId: number;
	}): Promise<Array<ConfiguratorLibraryVersion>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/configurator/managing/libraryVersions/getConfiguratorLibraryVersions",
			method: "POST",
			data: {
				libraryId: args.libraryId,
			},
		});
		return response as Array<ConfiguratorLibraryVersion>;
	}

	export async function getFormInitData(args: {
		libraryId: number;
		libraryVersionId: number | null | undefined;
	}): Promise<ConfiguratorLibraryVersionsApi_FormInitData> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/configurator/managing/libraryVersions/getFormInitData",
			method: "POST",
			data: {
				libraryId: args.libraryId,
				libraryVersionId: args.libraryVersionId,
			},
		});
		return response as ConfiguratorLibraryVersionsApi_FormInitData;
	}

	export async function insert(args: {
		configuratorLibraryId: number;
		versionNumber: number;
		note: string;
	}): Promise<number> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/configurator/managing/libraryVersions/insert",
			method: "POST",
			data: {
				configuratorLibraryId: args.configuratorLibraryId,
				versionNumber: args.versionNumber,
				note: args.note,
			},
		});
		return response as number;
	}

	export async function update(args: { configuratorLibraryVersionId: number; note: string }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/configurator/managing/libraryVersions/update",
			method: "POST",
			data: {
				configuratorLibraryVersionId: args.configuratorLibraryVersionId,
				note: args.note,
			},
		});
		return response as void;
	}

	export async function delete_(args: { libraryVersionId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/configurator/managing/libraryVersions/delete",
			method: "POST",
			data: {
				libraryVersionId: args.libraryVersionId,
			},
		});
		return response as void;
	}

	export async function releaseLibraryVersion(args: { libraryVersionId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/configurator/managing/libraryVersions/releaseLibraryVersion",
			method: "POST",
			data: {
				libraryVersionId: args.libraryVersionId,
			},
		});
		return response as void;
	}

	export async function revertReleaseLibraryVersion(args: { libraryVersionId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/configurator/managing/libraryVersions/revertReleaseLibraryVersion",
			method: "POST",
			data: {
				libraryVersionId: args.libraryVersionId,
			},
		});
		return response as void;
	}
}

export interface ConfiguratorLibraryVersionsApi_FormInitData {
	configuratorLibraryVersion: ConfiguratorLibraryVersion | null | undefined;
	defaultVersionNumber: number;
}
