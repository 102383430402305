// noinspection EqualityComparisonWithCoercionJS

import Handlebars from "handlebars/dist/handlebars.min";

export function registerHandlebarsHelpers() {
	Handlebars.registerHelper("eq", function (arg1, arg2, options) {
		return arg1 == arg2 ? options.fn(this) : options.inverse(this);
	});
	Handlebars.registerHelper("notEq", function (arg1, arg2, options) {
		return arg1 != arg2 ? options.fn(this) : options.inverse(this);
	});
}
