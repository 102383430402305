// This file is automatically generated. Do not edit manually.

import { DeliveryTerm } from "src/api/generated/erp/db/types/tables/deliveryTerm";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace DeliveryTermsApi {
	export async function getAll(): Promise<Array<DeliveryTerm>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/warehouse/basedata/DeliveryTermsDataGrid/getAll",
			method: "POST",
			data: {},
		});
		return response as Array<DeliveryTerm>;
	}

	export async function save(args: { deliveryTerms: Array<DeliveryTerm> }): Promise<Array<DeliveryTerm>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/warehouse/basedata/DeliveryTermsDataGrid/save",
			method: "POST",
			data: {
				deliveryTerms: args.deliveryTerms,
			},
		});
		return response as Array<DeliveryTerm>;
	}

	export async function delete_(args: { deliveryTerms: Array<DeliveryTerm> }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/warehouse/basedata/DeliveryTermsDataGrid/delete",
			method: "POST",
			data: {
				deliveryTerms: args.deliveryTerms,
			},
		});
		return response as void;
	}
}
