import {AsyncCrudDataGrid} from "../../../../common/dataGrid/crud/AsyncCrudDataGrid.tsx";
import {dataGridPersistentStateProps} from "../../../../common/dataGrid/dataGridStateStorage.ts";
import {textColumn} from "../../../../common/dataGrid/columns.tsx";
import i18n from "i18next";
import {DeliveryTermsApi} from "../../../../../api/generated/erp/warehouse/basedata/api/deliveryTermsApi.ts";
import {DeliveryTerm} from "../../../../../api/generated/erp/db/types/tables/deliveryTerm.ts";

export const DeliveryTermsDataGrid = () => {
	return (
		<AsyncCrudDataGrid<DeliveryTerm>
			fetchData={DeliveryTermsApi.getAll}
			getRowId={(row) => row.deliveryTermsId}
			columns={[
				textColumn({
					field: "deliveryTermsCode",
					headerName: i18n.t("delivery_term_code"),
					editable: true,
					validate: "required",
				}),
				textColumn({
					field: "deliveryTermsDescription",
					headerName: i18n.t("description"),
					editable: true,
					width: 500,
				}),
			]}
			save={{
				type: "enabled",
				action: async (params) =>
					await DeliveryTermsApi.save({
						deliveryTerms: params.items,
					}),
			}}
			add={{
				type: "enabled",
				action: async () => {
					return {
						deliveryTermsCode: "",
						deliveryTermsDescription: "",
						lastUpdated: new Date().toISOString(),
					};
				},
			}}
			remove={{
				type: "enabled",
				action: async (params) =>
					await DeliveryTermsApi.delete_({
						deliveryTerms: params.items,
					}),
			}}
			{...dataGridPersistentStateProps("C8F5402B36F21DD5")}
		/>
	);
};
