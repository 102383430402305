import { Box } from "@mui/material";
import { BoxProps } from "@mui/material/Box/Box";
import { mergeSx } from "src/utils/styles";
import React from "react";
import { AavoFormDivider } from "src/components/common/forms/AavoFormDivider.tsx";

export interface FormSectionProps extends BoxProps {
	label: string;
	children?: React.ReactNode;
}

export const FormSection = ({ label, children, sx, ...other }: FormSectionProps) => {
	return (
		<Box
			sx={mergeSx(
				{
					gridColumn: "1 / -1",
					display: "inherit",
					gridTemplateColumns: "inherit",
					gap: "inherit",
					flexDirection: "inherit",
				},
				sx,
			)}
			{...other}
		>
			<AavoFormDivider>{label}</AavoFormDivider>
			{children}
		</Box>
	);
};
