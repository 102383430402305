import { DeliveryPackagesDataGrid } from "../../delivery/deliveryPackage/DeliveryPackagesDataGrid.tsx";

export const AllCustomerOrderDeliveryPackagesView = () => {
	return (
		<DeliveryPackagesDataGrid
			deliverySourceType={"CUSTOMER_ORDER"}
			gridId={"DD5074D67212A7DC"}
			editable
			showSearchField
			showDeliveryStateSelection
		/>
	);
};
