import { AavoObjectRef } from "src/api/generated/common/sourceType/aavoObjectRef.ts";
import { faHistory } from "@fortawesome/pro-regular-svg-icons";
import i18n from "i18next";
import { useOpenLegacyView } from "src/components/views/legacy/useOpenLegacyView.ts";
import { openLegacyObjectChangeLogDataTableView } from "../legacy/legacyViewAdapters.ts";
import { AsyncButton, AsyncButtonProps } from "src/components/common/buttons/AsyncButton.tsx";

export interface OpenObjectChangeLogButtonProps extends Pick<AsyncButtonProps, "variant"> {
	objectRef: AavoObjectRef;
}

export const OpenObjectChangeLogButton = ({
	objectRef,
	variant = "menu",
}: OpenObjectChangeLogButtonProps) => {
	const openLegacyView = useOpenLegacyView();
	return (
		<AsyncButton
			icon={faHistory}
			label={i18n.t("change_log")}
			variant={variant}
			onClick={async () => {
				if (objectRef.objectId == null) return;
				await openLegacyObjectChangeLogDataTableView({
					openLegacyView: openLegacyView,
					objectRef: objectRef,
				});
			}}
		/>
	);
};
