import {FormCommonProps} from "src/components/common/forms/types.ts";
import {AsyncFetchRender} from "src/components/common/async/AsyncFetchRender.tsx";
import {ShopOrderBomApi} from "src/api/generated/erp/production/api/shopOrderBom/shopOrderBomApi.ts";
import {
    ShopOrderReservationBaseForm
} from "src/components/views/erp/production/shopOrderPicking/ShopOrderBomReservationFormBase.tsx";

export interface ShopOrderBatchBomReservationFormProps extends FormCommonProps<void> {
    shopOrderBatchId: number;
}

export const ShopOrderBatchBomReservationForm = (props: ShopOrderBatchBomReservationFormProps) => {
    const { shopOrderBatchId } = props;

    return (
        <AsyncFetchRender
            fetch={() => ShopOrderBomApi.getShopOrderBatchBom({ shopOrderBatchId: shopOrderBatchId })}
            content={(data) => <ShopOrderReservationBaseForm shopOrderBomLines={data} {...props} />}
        />
    );
};
