import i18n from "i18next";
import { AavoMosaic } from "src/components/common/mosaic/AavoMosaic.tsx";
import { SitePartRevisionBomDataGrid } from "src/components/views/erp/parts/siteParts/SitePartRevisionBomDataGrid.tsx";
import { SitePartRevisionOperationsDataGrid } from "src/components/views/erp/parts/siteParts/SitePartRevisionOperationsDataGrid.tsx";
import { DataDirtyStateChangeHandler } from "src/utils/dataDirtyStateChangeHandler.ts";

export interface SitePartRevisionProductStructureViewProps {
	partRevisionId: number;
	dataDirtyStateChanged?: DataDirtyStateChangeHandler;
}

export const SitePartRevisionProductStructureView = ({
	partRevisionId,
	dataDirtyStateChanged,
}: SitePartRevisionProductStructureViewProps) => {
	return (
		<AavoMosaic
			viewId={"A471ADE65A365497"}
			layout={{
				type: "row",
				first: {
					type: "panel",
					title: i18n.t("operations"),
					content: (
						<SitePartRevisionOperationsDataGrid
							partRevisionId={partRevisionId}
							dataDirtyStateChanged={dataDirtyStateChanged}
						/>
					),
				},
				second: {
					type: "panel",
					title: i18n.t("bom"),
					content: (
						<SitePartRevisionBomDataGrid
							partRevisionId={partRevisionId}
							dataDirtyStateChanged={dataDirtyStateChanged}
						/>
					),
				},
			}}
		/>
	);
};
