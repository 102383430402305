// This file is automatically generated. Do not edit manually.

import { FrontendDownloadFileHandle } from "src/api/generated/common/frontendDownloadFileHandle";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace MellanoComponentCountApi {
	export async function getComponentCountsByRoutes(args: {
		componentState: string;
		notInStates: Array<string>;
	}): Promise<Array<MellanoComponentCountApi_Row>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/mellano/componentCounts/getComponentCountsByRoutes",
			method: "POST",
			data: {
				componentState: args.componentState,
				notInStates: args.notInStates,
			},
		});
		return response as Array<MellanoComponentCountApi_Row>;
	}

	export async function getComponentCountsByRoutesCsv(args: {
		componentState: string;
		notInStates: Array<string>;
	}): Promise<FrontendDownloadFileHandle> {
		const response = await makeAxiosRequest({
			url: "/api/ext/mellano/componentCounts/getComponentCountsByRoutesCsv",
			method: "POST",
			data: {
				componentState: args.componentState,
				notInStates: args.notInStates,
			},
		});
		return response as FrontendDownloadFileHandle;
	}
}

export interface MellanoComponentCountApi_Row {
	count: number;
	route: string;
}
