import { html, LitElement } from "lit";
import { createEmbeddingContext } from "amazon-quicksight-embedding-sdk";

class QuickSightWebComponent extends LitElement {
	static get properties() {
		return {
			embedType: { type: String }, // either "dashboard" or "session"
			embedUrl: { type: String },
		};
	}

	createRenderRoot() {
		return this;
	}

	constructor() {
		super();
		this.embedType = null;
		this.embedUrl = null;
	}

	render() {
		return html` <div class="quicksight-container" /> `;
	}

	firstUpdated(_changedProperties) {
		super.firstUpdated(_changedProperties);
		const container = this.querySelector(".quicksight-container");

		createEmbeddingContext().then((embeddingCtx) => {
			const { embedDashboard, embedConsole } = embeddingCtx;

			const embedFunction =
				this.embedType === "dashboard" ? embedDashboard : embedConsole;

			embedFunction(
				{
					url: this.embedUrl,
					container: container,
				},
				{
					locale: this.getLocale(),
				}
			);
		});
	}

	getLocale() {
		const browserLanguage =
			(navigator.languages && navigator.languages[0]) ||
			navigator.language;
		if (browserLanguage.toLowerCase().startsWith("fi")) return "fi-FI";
		else return "en-US";
	}
}

customElements.define("quicksight-component", QuickSightWebComponent);
