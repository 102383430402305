// This file is automatically generated. Do not edit manually.

import { t } from "i18next";

export const SalesContractStateValues = ["INITIAL", "RELEASED", "DEACTIVATED"] as const;

export type SalesContractState = (typeof SalesContractStateValues)[number];

export const getSalesContractStateLabels = () => ({
	INITIAL: t("initial"),
	RELEASED: t("released"),
	DEACTIVATED: t("deactivated"),
});

export const getSalesContractStateLabel = (value: SalesContractState): string => {
	return getSalesContractStateLabels()[value];
};
