// This file is automatically generated. Do not edit manually.

import { BillingPlanModel } from "src/api/generated/erp/db/types/tables/billingPlanModel";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace BillingPlanModelApi {
	export async function getAllBillingPlanModels(): Promise<Array<BillingPlanModel>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/billingPlanModel/getAllBillingPlanModels",
			method: "POST",
			data: {},
		});
		return response as Array<BillingPlanModel>;
	}

	export async function copyBillingPlanModelToCustomerOrder(args: {
		customerOrderId: number;
		billingPlanModelId: number;
	}): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/billingPlanModel/copyBillingPlanModelToCustomerOrder",
			method: "POST",
			data: {
				customerOrderId: args.customerOrderId,
				billingPlanModelId: args.billingPlanModelId,
			},
		});
		return response as void;
	}

	export async function copyBillingPlanModelToCustomerOffer(args: {
		customerOfferId: number;
		billingPlanModelId: number;
	}): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/billingPlanModel/copyBillingPlanModelToCustomerOffer",
			method: "POST",
			data: {
				customerOfferId: args.customerOfferId,
				billingPlanModelId: args.billingPlanModelId,
			},
		});
		return response as void;
	}
}
