// This file is automatically generated. Do not edit manually.

import { CustomerOrderTenantStateConfiguration } from "src/api/generated/postgres/db/types/salvos/tables/customerOrderTenantStateConfiguration";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace CustomerOrderTenantStateApi {
	export async function getCustomerOrderTenantStateConfig(args: {
		customerOrderTenantState: string;
	}): Promise<CustomerOrderTenantStateConfiguration> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/customerOrder/tenantState/getCustomerOrderTenantStateConfig",
			method: "POST",
			data: {
				customerOrderTenantState: args.customerOrderTenantState,
			},
		});
		return response as CustomerOrderTenantStateConfiguration;
	}
}
