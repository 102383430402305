// This file is automatically generated. Do not edit manually.

import { ServerSideDataModelRequest } from "src/api/generated/common/dataGrids/serverSideDataModelRequest";
import { ServerSideDataModelResult } from "src/api/generated/common/dataGrids/serverSideDataModelResult";
import { SalesPartView } from "src/api/generated/erp/db/types/tables/salesPartView";
import { Site } from "src/api/generated/erp/db/types/tables/site";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace AddSalesPartsToSalesContractApi {
	export async function getGridData(args: {
		salesContractId: number;
		siteId: number | null | undefined;
		searchQuery?: string;
		dataModelRequest: ServerSideDataModelRequest;
	}): Promise<AddSalesPartsToSalesContractApi_GridData> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/pricing/addSalesPartsToSalesContract/getGridData",
			method: "POST",
			data: {
				salesContractId: args.salesContractId,
				siteId: args.siteId,
				searchQuery: args.searchQuery,
				dataModelRequest: args.dataModelRequest,
			},
		});
		return response as AddSalesPartsToSalesContractApi_GridData;
	}

	export async function addSelectedParts(args: {
		salesContractId: number;
		salesPartIds: Array<number>;
	}): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/pricing/addSalesPartsToSalesContract/addSelectedParts",
			method: "POST",
			data: {
				salesContractId: args.salesContractId,
				salesPartIds: args.salesPartIds,
			},
		});
		return response as void;
	}
}

export interface AddSalesPartsToSalesContractApi_GridData {
	data: ServerSideDataModelResult<SalesPartView>;
	siteOptions: Array<Site>;
}
