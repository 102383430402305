// This file is automatically generated. Do not edit manually.

import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace TaskActionsApi {
	export async function releaseTasks(args: { taskIds: Array<number> }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/tasks/releaseTask",
			method: "POST",
			data: {
				taskIds: args.taskIds,
			},
		});
		return response as void;
	}

	export async function startTask(args: { taskId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/tasks/startTask",
			method: "POST",
			data: {
				taskId: args.taskId,
			},
		});
		return response as void;
	}

	export async function revertStartTask(args: { taskId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/tasks/revertStartTask",
			method: "POST",
			data: {
				taskId: args.taskId,
			},
		});
		return response as void;
	}

	export async function completeTask(args: { taskId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/tasks/completeTask",
			method: "POST",
			data: {
				taskId: args.taskId,
			},
		});
		return response as void;
	}

	export async function revertCompleteTask(args: { taskId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/tasks/revertCompleteTask",
			method: "POST",
			data: {
				taskId: args.taskId,
			},
		});
		return response as void;
	}

	export async function cancelTasks(args: { taskIds: Array<number> }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/tasks/cancelTasks",
			method: "POST",
			data: {
				taskIds: args.taskIds,
			},
		});
		return response as void;
	}
}
