// This file is automatically generated. Do not edit manually.

import { DeliveryPackageType } from "src/api/generated/erp/db/types/tables/deliveryPackageType";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace DeliveryPackageTypesApi {
	export async function getDeliveryPackageTypes(args: {
		includeInactive?: boolean;
	}): Promise<Array<DeliveryPackageType>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/warehouse/basedata/deliveryPackageTypes/getDeliveryPackageTypes",
			method: "POST",
			data: {
				includeInactive: args.includeInactive,
			},
		});
		return response as Array<DeliveryPackageType>;
	}

	export async function insert(args: { deliveryPackageType: DeliveryPackageType }): Promise<number> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/warehouse/basedata/deliveryPackageTypes/insert",
			method: "POST",
			data: {
				deliveryPackageType: args.deliveryPackageType,
			},
		});
		return response as number;
	}

	export async function update(args: { deliveryPackageType: DeliveryPackageType }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/warehouse/basedata/deliveryPackageTypes/update",
			method: "POST",
			data: {
				deliveryPackageType: args.deliveryPackageType,
			},
		});
		return response as void;
	}

	export async function delete_(args: { deliveryPackageTypeId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/warehouse/basedata/deliveryPackageTypes/delete",
			method: "POST",
			data: {
				deliveryPackageTypeId: args.deliveryPackageTypeId,
			},
		});
		return response as void;
	}

	export async function setPackageTypeIsActive(args: { packageTypeId: number; isActive: boolean }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/warehouse/basedata/deliveryPackageTypes/setPackageTypeIsActive",
			method: "POST",
			data: {
				packageTypeId: args.packageTypeId,
				isActive: args.isActive,
			},
		});
		return response as void;
	}
}
