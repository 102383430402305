import { ConfigurationProductFamilyVersionView } from "src/api/generated/erp/db/types/tables/configurationProductFamilyVersionView";
import { AsyncForm, AsyncFormContentParams } from "src/components/common/forms/AsyncForm.tsx";
import { FormCommonProps } from "src/components/common/forms/types.ts";
import { ConfigurationProductFamilyVersionApi } from "src/api/generated/erp/configurator/management/productFamilies/api/configurationProductFamilyVersionApi.ts";
import { FormTextField } from "src/components/common/forms/fields/FormTextField.tsx";
import i18n from "i18next";
import { FormConfiguratorLuaEditor } from "src/components/views/erp/configurator/scripting/FormConfiguratorLuaEditor.tsx";

export interface ProductFamilyVersionTopLevelFormProps extends FormCommonProps<void> {
	productFamilyVersionId: number;
	disabled?: boolean;
}

interface FormValues {
	description: string;
	configurationTopLevelScript: string;
}

export const ProductFamilyVersionTopLevelForm = (props: ProductFamilyVersionTopLevelFormProps) => {
	const { productFamilyVersionId, disabled, onCompleted, onFormEdited } = props;
	return (
		<AsyncForm<ConfigurationProductFamilyVersionView, FormValues, void>
			fetch={() =>
				ConfigurationProductFamilyVersionApi.getProductFamilyVersion({
					productFamilyVersionId,
				})
			}
			getDefaultValues={(version) => version}
			submit={submit}
			onCompleted={onCompleted}
			onFormEdited={onFormEdited}
			getUseFormProps={() => ({ disabled: disabled })}
			hideCancel
			render={(contentParams) => <FormContent {...props} {...contentParams} />}
		/>
	);

	async function submit(values: FormValues) {
		await ConfigurationProductFamilyVersionApi.updateProductFamilyVersion({
			productFamilyVersionId: productFamilyVersionId,
			description: values.description,
			configurationTopLevelScript: values.configurationTopLevelScript,
		});
	}
};

interface FormContentProps
	extends ProductFamilyVersionTopLevelFormProps,
		AsyncFormContentParams<ConfigurationProductFamilyVersionView, FormValues> {}

const FormContent = ({ control, productFamilyVersionId }: FormContentProps) => {
	return (
		<>
			<FormTextField control={control} name={"description"} label={i18n.t("version_description")} multiline />
			<FormConfiguratorLuaEditor
				control={control}
				name={"configurationTopLevelScript"}
				label={i18n.t("configuration_top_level_function")}
				productFamilyVersionId={productFamilyVersionId}
				catalogPartRevisionId={null}
			/>
		</>
	);
};
