// This file is automatically generated. Do not edit manually.

import { DeliverySource } from "src/api/generated/erp/delivery/model/deliverySource";
import { DeliveryPackage } from "src/api/generated/erp/db/types/tables/deliveryPackage";
import { DeliveryPackageType } from "src/api/generated/erp/db/types/tables/deliveryPackageType";
import { WarehouseLocation } from "src/api/generated/erp/db/types/tables/warehouseLocation";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace DeliveryPackageFormApi {
	export async function getInitData(args: {
		source: DeliverySource;
		deliveryPackageId: number | null | undefined;
	}): Promise<DeliveryPackageFormApi_InitData> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/delivery/deliveryPackageForm/getInitData",
			method: "POST",
			data: {
				source: args.source,
				deliveryPackageId: args.deliveryPackageId,
			},
		});
		return response as DeliveryPackageFormApi_InitData;
	}

	export async function insert(args: { deliveryPackage: DeliveryPackage }): Promise<number> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/delivery/deliveryPackageForm/insert",
			method: "POST",
			data: {
				deliveryPackage: args.deliveryPackage,
			},
		});
		return response as number;
	}

	export async function update(args: { deliveryPackage: DeliveryPackage }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/delivery/deliveryPackageForm/update",
			method: "POST",
			data: {
				deliveryPackage: args.deliveryPackage,
			},
		});
		return response as void;
	}
}

export interface DeliveryPackageFormApi_InitData {
	deliveryPackage: DeliveryPackage | null | undefined;
	nextPackageNumber: number;
	packageTypeOptions: Array<DeliveryPackageType>;
	warehouseLocationOptions: Array<WarehouseLocation>;
}
