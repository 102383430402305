import { ControlChartSelectionApi } from "src/api/generated/spc/controlChart/api/controlChartSelectionApi.ts";
import { ControlledAsyncDataGrid } from "src/components/common/dataGrid/ControlledAsyncDataGrid";
import { useClientSideDataGridModel } from "src/components/common/dataGrid/gridModel/useClientSideDataGridModel";
import { integerColumn } from "src/components/common/dataGrid/columns";
import { textColumn } from "src/components/common/dataGrid/columns.tsx";
import i18n from "i18next";
import { AavoTextField } from "src/components/common/inputFields/AavoTextField.tsx";
import { AsyncButton } from "src/components/common/buttons/AsyncButton.tsx";
import { faChartBar } from "@fortawesome/pro-regular-svg-icons";
import { openLegacyControlChartGroupBoxPlotView } from "src/components/views/legacy/legacyViewAdapters.ts";
import { useOpenLegacyView } from "src/components/views/legacy/useOpenLegacyView.ts";
import { nullableAavoObjectRef } from "src/utils/aavoObjectRefUtils.ts";
import { DocumentsOfObjectButton } from "src/components/views/documents/objectDocuments/DocumentsOfObjectButton.tsx";
import {ChartGroupView} from "src/api/generated/postgres/db/types/public_/tables/chartGroupView.ts";

export interface ChartGroupsDataGridProps {
	parentGroupId: number;
	onSelectionChanged: (row: ChartGroupView | undefined) => void;
}

export const ChartGroupsDataGrid = ({ parentGroupId, onSelectionChanged }: ChartGroupsDataGridProps) => {
	const openLegacyView = useOpenLegacyView();

	const { dataGridProps, onlySelectedRow, refreshData } = useClientSideDataGridModel({
		fetchData: ({ searchQuery }) =>
			ControlChartSelectionApi.getControlChartGroups({
				parentGroupId,
				searchQuery,
			}),
		initialParams: {
			searchQuery: "",
		},
		gridId: "C549922245EA7EF0",
		getRowId: (row) => row.chartGroupId,
		onSelectionChanged: (rows) => {
			onSelectionChanged(rows[0]);
		},
	});

	return (
		<ControlledAsyncDataGrid<ChartGroupView>
			disableMultipleRowSelection
			columns={[
				textColumn({
					field: "name",
					headerName: i18n.t("name"),
				}),
				integerColumn({
					field: "chartGroupId",
					headerName: i18n.t("id"),
				}),
			]}
			actionBarComponents={
				<>
					<DocumentsOfObjectButton
						objectRef={nullableAavoObjectRef("CHART_GROUP", onlySelectedRow?.chartGroupId)}
					/>
					<AavoTextField
						label={i18n.t("search")}
						onSubmit={(searchQuery) => refreshData({ searchQuery })}
					/>
				</>
			}
			rowContextMenuComponents={({ row, onlySingleRowSelected }) => [
				onlySingleRowSelected && (
					<AsyncButton
						key={"boxPlot"}
						label={i18n.t("box_plot")}
						icon={faChartBar}
						variant={"menu"}
						onClick={() => {
							openLegacyControlChartGroupBoxPlotView({
								openLegacyView,
								chartGroupId: row.chartGroupId,
								chartGroupName: row.name,
							});
						}}
					/>
				),
			]}
			{...dataGridProps}
		/>
	);
};
