// This file is automatically generated. Do not edit manually.

import { t } from "i18next";

export const DocumentRevisionStateValues = ["INITIAL", "RELEASED"] as const;

export type DocumentRevisionState = (typeof DocumentRevisionStateValues)[number];

export const getDocumentRevisionStateLabels = () => ({
	INITIAL: t("initial"),
	RELEASED: t("released"),
});

export const getDocumentRevisionStateLabel = (value: DocumentRevisionState): string => {
	return getDocumentRevisionStateLabels()[value];
};
