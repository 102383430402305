// This file is automatically generated. Do not edit manually.

import { UserEnvironmentType } from "src/api/generated/config/userEnvironmentType";
import { Site } from "src/api/generated/erp/db/types/tables/site";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace GlobalInitDataApi {
	export async function getInitData(): Promise<GlobalInitDataApi_InitData> {
		const response = await makeAxiosRequest({
			url: "/api/ext/initData/getInitData",
			method: "POST",
			data: {},
		});
		return response as GlobalInitDataApi_InitData;
	}
}

export interface GlobalInitDataApi_InitData {
	appUserId: number;
	environmentType: UserEnvironmentType;
	erp: GlobalInitDataApi_InitData_Erp;
	userName: string;
	userPermissions: Array<string>;
}

export interface GlobalInitDataApi_InitData_Erp {
	defaultSiteId: number;
	userSites: Array<Site>;
}
