import i18n from "i18next";
import { faProjectDiagram } from "@fortawesome/pro-regular-svg-icons";
import { AsyncButton, AsyncButtonProps } from "src/components/common/buttons/AsyncButton.tsx";
import { useGenericDialog } from "src/components/common/dialogs/useGenericDialog.ts";
import { ProjectTreeViewContainer } from "src/components/views/erp/project/treeView/ProjectTreeViewContainer.tsx";

export interface OpenProjectInTreeViewButtonProps extends Omit<AsyncButtonProps, "onClick"> {
	projectId: number;
}

export const OpenProjectInTreeViewButton = ({
	projectId,
	variant = "menu",
	...other
}: OpenProjectInTreeViewButtonProps) => {
	const { openDialog } = useGenericDialog();
	return (
		<AsyncButton
			label={i18n.t("open_project")}
			icon={faProjectDiagram}
			variant={variant}
			onClick={() => {
				openDialog({
					title: i18n.t("project"),
					content: <ProjectTreeViewContainer onlyProjectId={projectId} />,
				});
			}}
			{...other}
		/>
	);
};
