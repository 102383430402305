import { FormCommonProps } from "src/components/common/forms/types.ts";
import i18n from "i18next";
import { FormTextField } from "src/components/common/forms/fields/FormTextField.tsx";
import { requireRule } from "src/components/common/forms/validation.ts";
import { concatWithPipe } from "src/utils/strings.tsx";
import { ControlChartActionApi } from "src/api/generated/spc/controlChart/api/controlChartActionApi";
import { AavoForm } from "src/components/common/forms/AavoForm.tsx";
import { ControlChartSelectionApi } from "src/api/generated/spc/controlChart/api/controlChartSelectionApi.ts";
import { FormAsyncSelectField } from "src/components/common/forms/fields/FormAsyncSelectField.tsx";

export interface CloneChartFormProps extends FormCommonProps<void> {
	sourceControlChartId: number;
	cloneFormType: CloneControlChartFormType;
}

export type CloneControlChartFormType =
	| {
			type: "chartGroup";
			chartGroupId: number;
	  }
	| {
			type: "resourceItem";
			resourceItemId: number;
	  };

interface FormValues {
	newChartName: string;
	newChartGroupId: number;
	newResourceItemId: number;
}

interface SubmitValues extends FormValues {
	sourceControlChartId: number;
}

export const CloneChartForm = ({ sourceControlChartId, onCompleted, cloneFormType }: CloneChartFormProps) => {
	return (
		<AavoForm<FormValues, void>
			defaultValues={{
				newChartName: "",
			}}
			submit={(values) => submitForm({ ...values, sourceControlChartId })}
			onCompleted={onCompleted}
			render={({ control }) => (
				<>
					<FormTextField
						name={"newChartName"}
						control={control}
						label={i18n.t("new_chart_name")}
						rules={requireRule()}
					/>
					{cloneFormType.type === "chartGroup" && (
						<FormAsyncSelectField
							control={control}
							name={"newChartGroupId"}
							label={i18n.t("chart_group_to_connect")}
							rules={requireRule()}
							fetchOptions={({ searchQuery }) =>
								ControlChartSelectionApi.getControlChartGroups({
									parentGroupId: null,
									searchQuery: searchQuery,
								})
							}
							getOptionKey={(option) => option.chartGroupId}
							getOptionLabel={(option) =>
								concatWithPipe(option.parentGroupName, option.name)
							}
						/>
					)}
					{cloneFormType.type === "resourceItem" && (
						<FormAsyncSelectField
							control={control}
							name={"newResourceItemId"}
							label={i18n.t("resource_item_to_connect")}
							rules={requireRule()}
							fetchOptions={({ searchQuery }) =>
								ControlChartSelectionApi.getResourceItems({
									resourceId: null,
									searchQuery: searchQuery,
								})
							}
							getOptionKey={(option) => option.id}
							getOptionLabel={(option) => concatWithPipe(option.resourceName, option.name)}
						/>
					)}
				</>
			)}
		/>
	);

	async function submitForm({
		sourceControlChartId,
		newChartName,
		newChartGroupId,
		newResourceItemId,
	}: SubmitValues) {
		switch (cloneFormType.type) {
			case "chartGroup":
				await ControlChartActionApi.cloneChartGroupControlChart({
					sourceControlChartId: sourceControlChartId,
					newChartName: newChartName,
					targetChartGroupId: newChartGroupId,
				});
				break;
			case "resourceItem":
				await ControlChartActionApi.cloneResourceItemControlChart({
					sourceControlChartId: sourceControlChartId,
					newChartName: newChartName,
					targetItemId: newResourceItemId,
				});
				break;
		}
	}
};
