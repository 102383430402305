// This file is automatically generated. Do not edit manually.

import { CustomerOrderReclamationView } from "src/api/generated/erp/db/types/tables/customerOrderReclamationView";
import { CustomerOrderLineView } from "src/api/generated/erp/db/types/tables/customerOrderLineView";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace ReclamationCustomerOrdersDataGridApi {
	export async function getReclamationCustomerOrders(args: {
		reclamationId: number;
	}): Promise<Array<CustomerOrderReclamationView>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/reclamations/getReclamationCustomerOrders",
			method: "POST",
			data: {
				reclamationId: args.reclamationId,
			},
		});
		return response as Array<CustomerOrderReclamationView>;
	}

	export async function getReclamationCustomerOrderLines(args: {
		customerOrderId: number;
		parentLineId: number | null | undefined;
	}): Promise<Array<CustomerOrderLineView>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/reclamations/getReclamationCustomerOrderLines",
			method: "POST",
			data: {
				customerOrderId: args.customerOrderId,
				parentLineId: args.parentLineId,
			},
		});
		return response as Array<CustomerOrderLineView>;
	}
}
