import dayjs, { Dayjs } from "dayjs";
import i18next from "i18next";
import { isValidDate as isValidDateString, isValidISODateString } from "iso-datestring-validator";
import { IsoDateString, IsoDateTimeString } from "../types/dateTime";
import "dayjs/locale/fi";
import "dayjs/locale/en";

export const formatDayJs = (value: Dayjs | undefined | null, format: string = "L"): string => {
	if (value === undefined || value === null) return "-";
	return value.locale(i18next.language).format(format);
};

export const formatIsoString = (value: IsoDateTimeString | undefined | null, format: string = "L"): string => {
	if (value === undefined || value === null) return "-";
	return formatDayJs(dayjs(value), format);
};

export const isValidISOString = (value: string | null | undefined): boolean => {
	if (value === null || value === undefined) {
		return false;
	} else {
		return isValidISODateString(value);
	}
};

export const isValidLocalDateTimeString = (value: string): boolean => {
	return dayjs(value, "YYYY-MM-DDThh:mm", true).isValid();
};

export const dayJsToDateIsoString = (dayJs: Dayjs): IsoDateString => dayJs.format("YYYY-MM-DD");

export const dayJsToDateIsoStringNullable = (dayJs: Dayjs | null): IsoDateString | null => {
	if (dayJs == null) return null;
	return dayJsToDateIsoString(dayJs);
};

export const dayJsToLocalDateTimeIsoString = (dayJs: Dayjs): IsoDateString => dayJs.format("YYYY-MM-DDTHH:mm:ss");

export const dayJsToLocalDateTimeIsoStringNullable = (dayJs: Dayjs | null | undefined): IsoDateString | null => {
	if (dayJs == null) return null;
	return dayJsToLocalDateTimeIsoString(dayJs);
};

export const parseIsoDateNullable = (value: string | null | undefined): Dayjs | null => {
	if (value === null) return null;
	return dayjs(value);
};

export const parseIsoTimeNullable = (value: string | null | undefined): Dayjs | null => {
	if (value == null) return null;
	return parseIsoTime(value);
};

export const parseIsoTime = (value: string): Dayjs => {
	return dayjs(value, "HH:mm:ss");
};

export const formatDate = formatIsoString;

export const isValidDate = (d: any): d is Date => {
	return d instanceof Date && !isNaN(d.valueOf());
};

export const currentLocalDateAsIsoString = (): string => {
	return dayJsToDateIsoString(dayjs().local());
};

export const getUserTimeZoneName = (): string => {
	return dayjs.tz.guess();
};

export const parseDayJsNullable = (value: string | null | undefined): Dayjs | undefined => {
	if (value == null) return undefined;
	return dayjs(value);
};

/*
 * Parse a date string to a DayJs object on start of given day in UTC timezone.
 * e.g. "2021-08-16" -> "2021-08-16T00:00:00Z"
 */
export const parseDateStringToDayJsUtc = (value: string): Dayjs => {
	return dayjs(value).startOf("day").utc(true);
};

export { isValidDateString };
