// This file is automatically generated. Do not edit manually.

import { ProductionLineGroupShopOrderOperationView } from "src/api/generated/erp/db/types/tables/productionLineGroupShopOrderOperationView";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace ProductionLineGroupJobQueueInitialsApi {
	export async function getInitialOperations(): Promise<ProductionLineGroupJobQueueInitialsApi_GetOperationsResponse> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/productionLineGroupJobQueue/getInitialOperations",
			method: "POST",
			data: {},
		});
		return response as ProductionLineGroupJobQueueInitialsApi_GetOperationsResponse;
	}
}

export interface ProductionLineGroupJobQueueInitialsApi_GetOperationsResponse {
	rows: Array<ProductionLineGroupShopOrderOperationView>;
	startOperationEnabled: boolean;
}
