import {
	CustomerOrderOrOfferSalesCommissionView,
	CustomerOrderOrOfferSalesCommissionViewProps,
} from "src/components/views/erp/sales/salesCommissions/orderAndOfferViews/CustomerOrderOfOfferSalesCommissionView.tsx";

export interface CustomerOrderSalesCommissionViewProps
	extends Omit<CustomerOrderOrOfferSalesCommissionViewProps, "sourceType" | "editingEnabled"> {}

export const CustomerOrderSalesCommissionView = (props: CustomerOrderSalesCommissionViewProps) => {
	return (
		<CustomerOrderOrOfferSalesCommissionView
			{...props}
			sourceType={"CUSTOMER_ORDER"}
			editingEnabled={true}
		/>
	);
};
