import { AavoObjectRef } from "src/api/generated/common/sourceType/aavoObjectRef.ts";
import { Divider, Grid } from "@mui/material";
import { Control, useFieldArray } from "react-hook-form";
import { FormTextField } from "src/components/common/forms/fields/FormTextField.tsx";
import { DocumentCreationApi } from "src/api/generated/documents/api/documentCreationApi.ts";
import { DocumentCategory } from "src/api/generated/postgres/db/types/documents/tables/documentCategory.ts";
import { FormSelectField } from "src/components/common/forms/fields/FormSelectField.tsx";
import { removeFileExtension } from "src/utils/strings.tsx";
import { uploadFile } from "src/utils/fileUploading.ts";
import { FormResult } from "src/components/common/forms/types.ts";
import { DocumentQueryApi } from "src/api/generated/documents/api/documentQueryApi.ts";
import { promiseRecord } from "src/utils/promiseRecord.ts";
import { requireRule } from "src/components/common/forms/validation";
import i18n from "i18next";
import { AsyncForm, AsyncFormContentParams } from "src/components/common/forms/AsyncForm.tsx";

export interface ImportMultipleDocumentsFormProps {
	objectRef?: AavoObjectRef;
	files: File[];
	onCompleted: (result: FormResult<void>) => void;
}

interface FormInitData {
	categoryOptions: DocumentCategory[];
	defaultCategoryId: number;
}

interface ImportDocumentsFormValues {
	defaultCategoryId: number;
	documents: {
		description1: string;
		categoryId: number;
		note: string;
		file: File;
	}[];
}

export const ImportMultipleDocumentsForm = (props: ImportMultipleDocumentsFormProps) => {
	const { objectRef, files, onCompleted } = props;

	const fetchImportDocumentsViewInitData = async () => {
		const { categoryOptions, defaultCategory } = await promiseRecord({
			categoryOptions: DocumentQueryApi.getDocumentCategoryOptions(),
			defaultCategory: DocumentCreationApi.getDefaultDocumentCategoryForNewDocument({
				objectType: objectRef?.objectType,
			}),
		});
		return {
			categoryOptions,
			defaultCategoryId: defaultCategory.documentCategoryId,
		};
	};

	return (
		<AsyncForm<FormInitData, ImportDocumentsFormValues, void>
			fetch={() => fetchImportDocumentsViewInitData()}
			getDefaultValues={({ defaultCategoryId }) => ({
				defaultCategoryId,
				documents: files.map((file) => ({
					description1: removeFileExtension(file.name),
					categoryId: defaultCategoryId,
					file: file,
				})),
			})}
			submit={(values) => submitForm(objectRef, values)}
			columns={1}
			onCompleted={onCompleted}
			render={(params) => <ImportMultipleDocumentsFormContent {...props} {...params} />}
		/>
	);
};

interface ImportMultipleDocumentsFormContentProps
	extends ImportMultipleDocumentsFormProps,
		AsyncFormContentParams<FormInitData, ImportDocumentsFormValues> {}

export const ImportMultipleDocumentsFormContent = ({
	files,
	control,
	setValue,
	data: { categoryOptions },
}: ImportMultipleDocumentsFormContentProps) => {
	const documentsFieldArray = useFieldArray({ control, name: "documents" });

	return (
		<>
			{files.length > 1 && (
				<FormSelectField
					control={control}
					name={"defaultCategoryId"}
					label={i18n.t("set_category_for_all")}
					options={categoryOptions}
					getOptionKey={(o) => o.documentCategoryId}
					getOptionLabel={(o) => o.name}
					onChange={(defaultCategoryId) => {
						for (let i = 0; i < files.length; i++) {
							setValue(`documents.${i}.categoryId`, defaultCategoryId);
						}
					}}
				/>
			)}
			{documentsFieldArray.fields.map((_, index) => (
				<SingleDocumentFormContent
					key={index}
					control={control}
					index={index}
					documentCategoryOptions={categoryOptions}
				/>
			))}
		</>
	);
};

interface SingleDocumentFormContentProps {
	index: number;
	control: Control<ImportDocumentsFormValues>;
	documentCategoryOptions: DocumentCategory[];
}

const SingleDocumentFormContent = ({
	control,
	index,
	documentCategoryOptions,
}: SingleDocumentFormContentProps) => {
	return (
		<>
			<Divider
				textAlign={"left"}
				sx={{
					marginY: "1rem",
				}}
			>
				{i18n.t("file_at_index", { idx: index + 1 })}
			</Divider>
			<Grid container spacing={2} sx={{}}>
				<Grid item xs={12} sm={4}>
					<FormTextField
						control={control}
						name={`documents.${index}.description1`}
						label={i18n.t("description_1")}
						rules={requireRule()}
						fullWidth
					/>
				</Grid>
				<Grid
					item
					xs={12}
					sm={4}
					sx={{
						display: "flex",
					}}
				>
					<FormSelectField
						control={control}
						name={`documents.${index}.categoryId`}
						label={i18n.t("category")}
						rules={requireRule()}
						options={documentCategoryOptions}
						getOptionKey={(o) => o.documentCategoryId}
						getOptionLabel={(o) => o.name}
						fullWidth
						sx={{
							flex: 1,
						}}
					/>
				</Grid>
				<Grid item xs={12} sm={4}>
					<FormTextField
						control={control}
						name={`documents.${index}.note`}
						label={i18n.t("note")}
						multiline
						fullWidth
					/>
				</Grid>
			</Grid>
		</>
	);
};

const submitForm = async (objectRef: AavoObjectRef | undefined, { documents }: ImportDocumentsFormValues) => {
	for (const document of documents) {
		const fileHandle = await uploadFile(document.file);
		await DocumentCreationApi.createDocument({
			objectRef: objectRef,
			description1: document.description1,
			categoryId: document.categoryId,
			note: document.note,
			fileCreationType: "UPLOAD",
			uploadedFile: fileHandle,
		});
	}
};
