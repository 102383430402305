// This file is automatically generated. Do not edit manually.

import { ServerSideDataModelRequest } from "src/api/generated/common/dataGrids/serverSideDataModelRequest";
import { ServerSideDataModelResult } from "src/api/generated/common/dataGrids/serverSideDataModelResult";
import { InventoryLevelEventView } from "src/api/generated/erp/db/types/tables/inventoryLevelEventView";
import { InventoryLevelSummary } from "src/api/generated/erp/warehouse/inventory/inventoryEvent/inventoryLevelSummary";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace PartInventoryLevelEventsDataGridApi {
	export async function getGridData(args: {
		partId: number;
		partWarehouseLocationId: number | null | undefined;
		dataModelRequest: ServerSideDataModelRequest;
	}): Promise<PartInventoryLevelEventsDataGridApi_GridData> {
		const response = await makeAxiosRequest({
			url: "/api/ext/api/warehouse/inventory/partInventoryLevelEvents/getGridData",
			method: "POST",
			data: {
				partId: args.partId,
				partWarehouseLocationId: args.partWarehouseLocationId,
				dataModelRequest: args.dataModelRequest,
			},
		});
		return response as PartInventoryLevelEventsDataGridApi_GridData;
	}
}

export interface PartInventoryLevelEventsDataGridApi_GridData {
	rows: ServerSideDataModelResult<InventoryLevelEventView>;
	summary: InventoryLevelSummary;
}
