// This file is automatically generated. Do not edit manually.

import { DocumentFile } from "src/api/generated/postgres/db/types/documents/tables/documentFile";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace DocumentRevisionFileApi {
	export async function getDocumentRevisionFiles(args: { documentRevisionId: number }): Promise<Array<DocumentFile>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/documents/getDocumentRevisionFiles",
			method: "POST",
			data: {
				documentRevisionId: args.documentRevisionId,
			},
		});
		return response as Array<DocumentFile>;
	}
}
