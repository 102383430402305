import { faCamera } from "@fortawesome/pro-regular-svg-icons";
import { QrcodeReader } from "src/components/common/QrcodeReader.tsx";
import { AavoButton } from "src/components/common/buttons/AavoButton.tsx";
import i18n from "i18next";
import { AavoTextField } from "src/components/common/inputFields/AavoTextField.tsx";
import { Box, Modal, useTheme } from "@mui/material";
import { useState } from "react";
import { SxProps, Theme } from "@mui/material/styles";
import { HorizontalBox } from "src/components/common/box/HorizontalBox.tsx";
import { useErrorDialog } from "src/components/common/dialogs/errorDialog/userErrorDialog.ts";

export interface AavoQrcodeFieldProps {
	onQrCodeRead: (qrCodeContent: string) => Promise<void> | void;
	showTextField?: boolean;
	sx?: SxProps<Theme>;
}

export const QrcodeInputField = ({ onQrCodeRead, showTextField, sx }: AavoQrcodeFieldProps) => {
	const theme = useTheme();
	const [open, setOpen] = useState(false);
	const { logErrorAndShowOnDialog } = useErrorDialog();
	const [isLoading, setIsLoading] = useState(false);

	return (
		<HorizontalBox sx={sx}>
			{showTextField && (
				<AavoTextField
					label={i18n.t("code")}
					onSubmit={(value) => onQrCodeRead(value)}
					size={"small"}
				/>
			)}
			<AavoButton
				icon={faCamera}
				size={"large"}
				sx={{ color: theme.palette.primary.main }}
				loading={isLoading}
				onClick={async () => {
					setOpen(true);
				}}
			/>
			<Modal open={open} onClose={() => setOpen(false)} onClick={() => setOpen(false)}>
				<Box
					sx={{
						display: "flex",
					}}
				>
					<QrcodeReader
						onQrCodeRead={(qrCodeContent) => {
							setOpen(false);
							setIsLoading(true);
							try {
								onQrCodeRead(qrCodeContent);
							} catch (e) {
								logErrorAndShowOnDialog(e);
							} finally {
								setIsLoading(false);
							}
						}}
					/>
				</Box>
			</Modal>
		</HorizontalBox>
	);
};
