import { useClientSideDataGridModel } from "src/components/common/dataGrid/gridModel/useClientSideDataGridModel.tsx";
import i18n from "i18next";
import { booleanColumn, integerColumn, textColumn } from "src/components/common/dataGrid/columns.tsx";
import { ControlledAsyncCrudDataGrid } from "src/components/common/dataGrid/crud/ControlledAsyncCrudDataGrid.tsx";
import { BillingPlanModelLine } from "src/api/generated/erp/db/types/tables/billingPlanModelLine.ts";
import { formatMoney, formatNumber } from "src/utils/numberUtils.ts";
import { BillingPlanModelLineForm } from "src/components/views/erp/sales/billingPlan/model/BillingPlanModelLineForm.tsx";
import { AsyncButton } from "src/components/common/buttons/AsyncButton.tsx";
import { faAngleDown, faAngleUp } from "@fortawesome/pro-regular-svg-icons";
import { BillingPlanModelLineApi } from "src/api/generated/erp/sales/billingPlan/model/api/billingPlanModelLineApi.ts";
import {
	BillingPlanModelLineEditApi
} from "src/api/generated/erp/sales/billingPlan/model/api/billingPlanModelLineEditApi.ts";

export interface BillingPlanModelLinesDataGridProps {
	billingPlanModelId: number;
}

export const BillingPlanModelLinesDataGrid = ({ billingPlanModelId }: BillingPlanModelLinesDataGridProps) => {
	const { dataGridProps, onlySelectedRow, refreshData } = useClientSideDataGridModel({
		fetchData: () => BillingPlanModelLineApi.getBillingPlanModelLines({ billingPlanModelId }),
		initialParams: {},
		getRowId: (row) => row.billingPlanModelLineId,
		gridId: "19DF15DB11D19766",
	});

	return (
		<ControlledAsyncCrudDataGrid<BillingPlanModelLine>
			columns={[
				integerColumn({
					field: "position",
					headerName: i18n.t("number_shortened"),
				}),
				textColumn({
					field: "description",
					headerName: i18n.t("description"),
					width: 200,
				}),
				textColumn({
					field: "share" as any,
					headerName: i18n.t("share.noun"),
					width: 200,
					valueGetter: (_, row) => {
						switch (row.type) {
							case "SUM":
								return formatMoney(row.amount ?? 0);
							case "PERCENT":
								return `${formatNumber(row.amount ?? 0)} %`;
							case "REMAINDER":
								return i18n.t("rest_of_order");
						}
					},
				}),
				booleanColumn({
					field: "funded",
					headerName: i18n.t("funded"),
				}),
			]}
			disableMultipleRowSelection
			form={{
				addRowEnabled: true,
				editEnabled: true,
				dialogSize: "sm",
				dialogTitle: i18n.t("billing_plan_model_line"),
				component: ({ row, onCompleted, onFormEdited }) => (
					<BillingPlanModelLineForm
						billingPlanModelId={billingPlanModelId}
						billingPlanModelLineId={row?.billingPlanModelLineId}
						onCompleted={onCompleted}
						onFormEdited={onFormEdited}
					/>
				),
			}}
			remove={({ items }) =>
				BillingPlanModelLineEditApi.delete_({
					billingPlanModelLineIds: items.map((item) => item.billingPlanModelLineId),
				})
			}
			actionBarComponents={
				<>
					<AsyncButton
						icon={faAngleUp}
						disabled={!onlySelectedRow}
						onClick={async () => {
							await BillingPlanModelLineEditApi.move({
								billingPlanModelLineId: onlySelectedRow!.billingPlanModelLineId,
								direction: "UP",
							});
							await refreshData();
						}}
					/>
					<AsyncButton
						icon={faAngleDown}
						disabled={!onlySelectedRow}
						onClick={async () => {
							await BillingPlanModelLineEditApi.move({
								billingPlanModelLineId: onlySelectedRow!.billingPlanModelLineId,
								direction: "DOWN",
							});
							await refreshData();
						}}
					/>
				</>
			}
			{...dataGridProps}
		/>
	);
};
