import {
	AsyncSelectField,
	AsyncSelectFieldApi,
	AsyncSelectFieldProps,
} from "../../inputFields/AsyncSelectField";
import { FieldPath, FieldPathValue, PathValue, useController, UseControllerProps } from "react-hook-form";
import { useCallback, useEffect, useRef } from "react";
import { logError } from "src/errorHandling/errorLogging.ts";
import { FormFieldLayoutProps, formFieldLayoutPropsToSx } from "src/components/common/forms/styles.ts";
import { mergeSx } from "src/utils/styles.ts";

export interface FormAsyncSelectFieldProps<
	TFieldValues extends object,
	TOption,
	Key extends string | number,
	TFieldName extends FieldPath<TFieldValues>,
> extends Omit<AsyncSelectFieldProps<TOption, Key>, "defaultValue" | "onBlur" | "onChange">,
		Pick<UseControllerProps<TFieldValues, TFieldName>, "control" | "name" | "rules">,
		FormFieldLayoutProps {
	formValueType?: "key" | "option";
	onChange?: (v: TOption | null) => void | Promise<unknown>;
}

export const FormAsyncSelectField = <
	TFieldValues extends object,
	TOption,
	Key extends string | number,
	TFieldName extends FieldPath<TFieldValues>,
>({
	control,
	name,
	rules,
	fetchOptions,
	getOptionKey,
	formValueType = "key",
	onChange,
	disabled,
	spanGridColumns,
	startNewGridRow,
	sx,
	...other
}: FormAsyncSelectFieldProps<TFieldValues, TOption, Key, TFieldName>) => {
	const { field, fieldState } = useController({
		name,
		control,
		rules,
		defaultValue: null as FieldPathValue<TFieldValues, TFieldName>,
	});
	const selectFieldApiRef = useRef<AsyncSelectFieldApi<Key> | null>(null);

	// getOptionKey is expected to be stable.
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const getOptionKeyMemoized = useCallback(getOptionKey, []);

	const mapFormValueToKey = useCallback(
		(formValue: PathValue<TFieldValues, TFieldName>): Key | null => {
			if (formValue == null) return null;
			if (formValueType === "key") return formValue;
			else return getOptionKeyMemoized(formValue);
		},
		[formValueType, getOptionKeyMemoized],
	);

	useEffect(() => {
		if (field.value === undefined) return;
		if (selectFieldApiRef.current == null) {
			logError("FormAsyncSelectField: selectFieldApiRef value not set");
			return;
		}
		const fieldKey = mapFormValueToKey(field.value);
		selectFieldApiRef.current.setValue(fieldKey);
	}, [field.value, mapFormValueToKey]);

	return (
		<AsyncSelectField
			apiRef={selectFieldApiRef}
			fetchOptions={fetchOptions}
			error={fieldState.error?.message}
			getOptionKey={getOptionKey}
			onBlur={field.onBlur}
			disabled={disabled || field.disabled}
			defaultValue={mapFormValueToKey(field.value)}
			onChange={async (v: TOption | null) => {
				const mappedValue =
					v == null ? null
					: formValueType === "key" ? getOptionKey(v)
					: v;
				field.onChange(mappedValue);
				await onChange?.(v);
			}}
			sx={mergeSx(
				formFieldLayoutPropsToSx({
					spanGridColumns,
					startNewGridRow,
				}),
				sx,
			)}
			{...other}
		/>
	);
};
