import i18n from "i18next";

export function formatNumber(
	num: number,
	{ minDigits, maxDigits }: { minDigits?: number; maxDigits?: number } = {
		maxDigits: 2,
	},
): string {
	return num.toLocaleString(i18n.language, {
		minimumFractionDigits: minDigits,
		maximumFractionDigits: maxDigits,
	});
}

export function formatMoney(amount: number): string {
	return formatNumber(amount, { minDigits: 2, maxDigits: 2 }) + " €";
}

export function round(amount: number, decimals: number): number {
	return Math.round(amount * 10 ** decimals) / 10 ** decimals;
}
