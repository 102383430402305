import {
	PartConfigurationBomApi,
	PartConfigurationBomApi_FormInitData,
} from "src/api/generated/erp/configurator/api/partConfigurationBomApi.ts";
import { PartConfigurationBomLineView } from "src/api/generated/erp/db/types/tables/partConfigurationBomLineView";
import i18n from "i18next";
import { PartConfigurationBomLine } from "src/api/generated/erp/db/types/tables/partConfigurationBomLine.ts";
import { SitePartQueryApi } from "src/api/generated/erp/parts/sitePart/api/sitePartQueryApi.ts";
import { DeepPartial } from "react-hook-form";
import { requireRule } from "src/components/common/forms/validation.ts";
import { FormCommonProps } from "src/components/common/forms/types.ts";
import { PartConfigurationView } from "src/api/generated/erp/db/types/tables/partConfigurationView.ts";
import { FormNumberField } from "src/components/common/forms/fields/FormNumberField.tsx";
import { FormAsyncSelectField } from "src/components/common/forms/fields/FormAsyncSelectField.tsx";
import { getPartLongName } from "src/components/views/erp/erpUtils.ts";
import { FetchAsyncOptionParams } from "src/components/common/inputFields/AsyncSelectField.tsx";
import { PartView } from "src/api/generated/erp/db/types/tables/partView.ts";
import { FormSelectField } from "src/components/common/forms/fields/FormSelectField.tsx";
import { FormTextField } from "src/components/common/forms/fields/FormTextField.tsx";
import { AsyncForm, AsyncFormContentParams } from "src/components/common/forms/AsyncForm.tsx";

export interface PartConfigurationBomLineFormProps extends FormCommonProps<number> {
	partConfiguration: PartConfigurationView;
	existingBomLine: PartConfigurationBomLineView | undefined;
}

interface FormValues extends Omit<PartConfigurationBomLine, "bomLinePartId"> {
	bomLinePart: PartView;
}

export const PartConfigurationBomLineForm = (props: PartConfigurationBomLineFormProps) => {
	const { partConfiguration, existingBomLine, onFormEdited, onCompleted } = props;
	return (
		<AsyncForm
			fetch={() =>
				PartConfigurationBomApi.getFormInitData({
					partConfigurationId: partConfiguration.partConfigurationId,
				})
			}
			getDefaultValues={getDefaultValues}
			submit={submitForm}
			onCompleted={onCompleted}
			onFormEdited={onFormEdited}
			render={(params) => <FormContent {...props} {...params} />}
		/>
	);

	function getDefaultValues({
		defaultReferenceNo,
	}: PartConfigurationBomApi_FormInitData): DeepPartial<FormValues> {
		return existingBomLine ?
				{
					...existingBomLine,
					bomLinePart: {
						partId: existingBomLine.bomLinePartId,
						partUnit: existingBomLine.linePartUnitName,
					},
				}
			:	{
					partConfigurationId: partConfiguration.partConfigurationId,
					referenceNo: defaultReferenceNo,
					quantity: 1,
					acquisitionMethod: "WAREHOUSE",
				};
	}

	async function submitForm(values: FormValues) {
		const { bomLinePart, ...rest } = values;
		return await PartConfigurationBomApi.saveBomLine({
			partConfigurationBomLine: {
				bomLinePartId: bomLinePart.partId,
				...rest,
			},
		});
	}
};

interface FormContentProps
	extends PartConfigurationBomLineFormProps,
		AsyncFormContentParams<PartConfigurationBomApi_FormInitData, FormValues> {}

const FormContent = ({ partConfiguration, control, watch, data: { operationOptions } }: FormContentProps) => {
	const currentBomLinePart = watch("bomLinePart");

	return (
		<>
			<FormNumberField
				control={control}
				name={"referenceNo"}
				label={i18n.t("reference_no")}
				rules={requireRule()}
				type={"integer"}
			/>
			<FormAsyncSelectField
				control={control}
				name={"bomLinePart"}
				formValueType={"option"}
				label={i18n.t("part")}
				rules={requireRule()}
				fetchOptions={({ currentSelection, searchQuery }: FetchAsyncOptionParams<number>) =>
					SitePartQueryApi.getPartSelectionOptions({
						currentPartId: currentSelection,
						searchQuery: searchQuery,
						siteId: partConfiguration.siteId,
					})
				}
				getOptionKey={(part) => part.partId}
				getOptionLabel={getPartLongName}
			/>
			<FormNumberField
				control={control}
				name={"quantity"}
				label={`${i18n.t("quantity")} (${currentBomLinePart?.partUnit ?? ""})`}
				rules={requireRule()}
			/>
			<FormSelectField
				control={control}
				name={"partConfigurationOperationId"}
				label={i18n.t("operation")}
				options={operationOptions}
				getOptionKey={(o) => o.partConfigurationOperationId}
				getOptionLabel={(o) => o.operationLongName ?? ""}
			/>
			<FormTextField control={control} name={"note"} label={i18n.t("note")} multiline />
		</>
	);
};
