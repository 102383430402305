// @ts-ignore
import { ElmKappaView } from "./ElmKappaView";
import { Types } from "../localSettings/types";
import {
	AllViewStates,
	EnvironmentId,
	IdentityId,
	ViewState,
} from "../storage/appState/types";
import { LegacyPushAction } from "../notifications/legacyPushAction";
import { ViewAssociation } from "../components/views/legacy/types";
import {
	IncomingViewActionEvents,
	VersionedViewActionEventList,
} from "../components/views/legacy/events";
import { StartUpData } from "../api/mainApi";
import { KeyValuePair } from "src/utils/keyValuePair.ts";

export interface KappaViewProps {
	startUpData: StartUpData;
	viewDefinition: ViewAssociation;
	isOnForeground: boolean;
	isUppermostView: boolean;
	incomingViewActionEvents: VersionedViewActionEventList;
	incomingPushAction: LegacyPushAction | null;
	onPushActionHandled: (e: any) => void;
	handleActionEventsRaised: (event: IncomingViewActionEvents) => void;
	dragDropData: any;
	handleDragDropDataChanged: (e: any) => void;
	localSettings: Types;
	environmentId: EnvironmentId;
	branchKey: string;
	identityId: IdentityId;
	viewState: ViewState;
	allViewStates: AllViewStates;
	sourceViewId?: string | null;
	viewParams?: KeyValuePair[];
}

export const KappaView = ({
	startUpData,
	viewDefinition,
	isOnForeground,
	isUppermostView,
	incomingViewActionEvents,
	incomingPushAction,
	handleActionEventsRaised,
	dragDropData,
	handleDragDropDataChanged,
	onPushActionHandled,
	localSettings,
	environmentId,
	branchKey,
	identityId,
	viewState,
	allViewStates,
	sourceViewId = null,
	viewParams = [],
}: KappaViewProps) => {
	return (
		<ElmKappaView
			startUpData={startUpData}
			viewDefinition={viewDefinition}
			isOnForeground={isOnForeground}
			isUppermostView={isUppermostView}
			incomingViewActionEvents={incomingViewActionEvents}
			incomingPushAction={incomingPushAction}
			handleActionEventsRaised={handleActionEventsRaised}
			onPushActionHandled={onPushActionHandled}
			localSettings={localSettings}
			environmentId={environmentId}
			branchKey={branchKey}
			identityId={identityId}
			sourceViewId={sourceViewId}
			viewParams={viewParams}
			dragDropData={dragDropData}
			handleDragDropDataChanged={handleDragDropDataChanged}
			viewState={viewState}
			allViewStates={allViewStates}
		/>
	);
};
