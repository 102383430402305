// This file is automatically generated. Do not edit manually.

import { FrontendUploadFileHandle } from "src/api/generated/common/frontendUploadFileHandle";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace DocumentUpdateApi {
	export async function updateDocument(args: {
		documentId: number;
		description1: string;
		categoryId: number;
		note: string;
		newFile: FrontendUploadFileHandle | null | undefined;
	}): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/documents/updateDocument",
			method: "POST",
			data: {
				documentId: args.documentId,
				description1: args.description1,
				categoryId: args.categoryId,
				note: args.note,
				newFile: args.newFile,
			},
		});
		return response as void;
	}

	export async function updateDocumentActiveRevisionFile(args: {
		documentId: number;
		newFile: FrontendUploadFileHandle;
	}): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/documents/updateDocumentActiveRevisionFile",
			method: "POST",
			data: {
				documentId: args.documentId,
				newFile: args.newFile,
			},
		});
		return response as void;
	}
}
