// This file is automatically generated. Do not edit manually.

import { FrontendUploadFileHandle } from "src/api/generated/common/frontendUploadFileHandle";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace CustomerOrderCsvImportApi {
	export async function importCustomersAndOrdersFromCsv(args: {
		csvFileHandle: FrontendUploadFileHandle;
	}): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/sales/import/importCustomersAndOrders",
			method: "POST",
			data: {
				csvFileHandle: args.csvFileHandle,
			},
		});
		return response as void;
	}
}
