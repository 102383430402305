import { VerticalBox } from "src/components/common/box/VerticalBox.tsx";
import { List, ListItem, ListItemButton, ListItemText } from "@mui/material";
import { concatWithPipe } from "src/utils/strings.tsx";
import i18n from "i18next";
import { formatIsoString } from "src/utils/dayjsUtils.ts";
import { JobQueueShopOrderOperation } from "src/components/views/erp/production/jobQueue/base/JobQueueShopOrderOperation.ts";
import React from "react";
import { selectWithShiftAndCtrl } from "src/utils/shiftCtrlSelection.ts";

export interface OperationListProps {
	rows: JobQueueShopOrderOperation[];
	selectedOperations: JobQueueShopOrderOperation[];
	setSelectedOperations: (operations: JobQueueShopOrderOperation[]) => void;
}

export const OperationList = ({ rows, selectedOperations, setSelectedOperations }: OperationListProps) => {
	const selectedOperationIds = selectedOperations.map((operation) => operation.shopOrderOperationId);

	return (
		<VerticalBox
			sx={{
				flex: 1,
			}}
		>
			<List
				sx={{
					overflow: "auto",
					flex: 1,
				}}
			>
				{rows.map((operation) => (
					<ListItem
						key={operation.shopOrderOperationId}
						disablePadding
						sx={{
							borderBottom: "1px solid",
							borderColor: "divider",
						}}
					>
						<ListItemButton
							dense
							selected={selectedOperationIds.includes(operation.shopOrderOperationId)}
							onClick={(e) => onListItemClick(e, operation)}
						>
							<ListItemText
								primary={concatWithPipe(operation.shopOrderId, operation.operationDescription)}
								secondary={
									<>
										{`${i18n.t("planned_start_date_short")}: ${formatIsoString(operation.shopOrderPlannedBeginDate, "L LT")}`}
										<br />
										{operation.configurationOrPartDescription}
										<br />
										{operation.sourceRefData}
									</>
								}
							/>
						</ListItemButton>
					</ListItem>
				))}
			</List>
		</VerticalBox>
	);

	function onListItemClick(e: React.MouseEvent<HTMLDivElement>, operation: JobQueueShopOrderOperation) {
		const newSelectedOperations = selectWithShiftAndCtrl({
			clickedItem: operation,
			selectedItems: selectedOperations,
			allItems: rows,
			getItemKey: (op) => op.shopOrderOperationId,
			shiftKey: e.shiftKey,
			ctrlKey: e.ctrlKey,
		});
		setSelectedOperations(newSelectedOperations);
	}
};
