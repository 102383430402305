import { Typography } from "@mui/material";
import i18n from "i18next";
import { AavoButton } from "src/components/common/buttons/AavoButton.tsx";
import { HOME } from "src/urls.ts";
import { useNavigate } from "react-router-dom";
import { VerticalBox } from "src/components/common/box/VerticalBox";

export const LoggedOutPage = () => {
	const navigate = useNavigate();

	return (
		<VerticalBox flex={1} justifyContent={"center"} alignItems={"center"} gap={2}>
			<Typography color={"primary"} variant={"h5"}>
				{i18n.t("you_are_logged_out")}
			</Typography>
			<AavoButton
				label={i18n.t("log_in")}
				variant={"contained"}
				onClick={() => {
					navigate(HOME);
				}}
			/>
		</VerticalBox>
	);
};
