import { TransformedConfigurationComponent_SubConfiguratorList } from "src/api/generated/erp/configurator/componentTransformation/model/transformedConfigurationComponent.ts";
import { VerticalBox } from "src/components/common/box/VerticalBox.tsx";
import { HorizontalBox } from "src/components/common/box/HorizontalBox.tsx";
import { Typography } from "@mui/material";
import { ComponentDocumentButton } from "src/components/views/erp/configurator/configuratorForm/componentDocument/ComponentDocumentButton.tsx";
import {
	ConfigurationPropertyValue_SubConfiguration,
	ConfigurationPropertyValue_SubConfigurationList,
} from "src/api/generated/io/aavo/applications/db/erp/types/configurationPropertyValue.ts";
import { faPen } from "@fortawesome/pro-regular-svg-icons";
import i18n from "i18next";
import { useGenericDialog } from "src/components/common/dialogs/useGenericDialog.ts";
import { PartConfiguratorType } from "src/api/generated/erp/configurator/configuratorType/partConfiguratorType.ts";
import { ConfigurationPropertyValues } from "src/api/generated/erp/configurator/model/configurationPropertyValues.ts";
import { SubConfiguratorListViewApi } from "src/components/views/erp/configurator/configuratorForm/components/subConfiguratorList/SubConfiguratorListStatelessView.tsx";
import { AavoButton } from "src/components/common/buttons/AavoButton.tsx";
import { useRef } from "react";
import { useErrorDialog } from "src/components/common/dialogs/errorDialog/userErrorDialog.ts";
import { SubConfiguratorListStatefulView } from "./SubConfiguratorListStatefulView";

export interface SubConfiguratorListComponentProps {
	component: TransformedConfigurationComponent_SubConfiguratorList;
	onSubmit: (value: ConfigurationPropertyValue_SubConfigurationList) => Promise<unknown>;
	parentConfiguratorType: PartConfiguratorType;
	parentPropertyValues: ConfigurationPropertyValues;
}

export const SubConfiguratorListComponent = (props: SubConfiguratorListComponentProps) => {
	const { openDialog } = useGenericDialog();
	const { logErrorAndShowOnDialog } = useErrorDialog();
	const { component, onSubmit, parentConfiguratorType, parentPropertyValues } = props;

	const subConfiguratorListViewApiRef = useRef<SubConfiguratorListViewApi | null>(null);

	const currentValue = component.currentValue;

	return (
		<VerticalBox
			sx={{
				border: "1px solid",
				borderColor: "grey.300",
				borderRadius: "3px",
				padding: "0.5rem",
				paddingTop: "0.25rem",
				gap: 1,
			}}
		>
			<HorizontalBox alignItems={"center"} gap={1}>
				<Typography fontWeight="bold" paragraph={false}>
					{component.label}
				</Typography>
				<AavoButton
					icon={faPen}
					tooltip={i18n.t("edit")}
					onClick={() => {
						openSubConfiguratorListView();
					}}
				/>
				<ComponentDocumentButton
					componentId={component.configurationComponentId}
					sx={{
						marginY: "-0.5rem",
					}}
				/>
			</HorizontalBox>
			{currentValue.value.map((subConfigurationValue, idx) => (
				<SubConfiguratorListItem key={idx} subConfigurationValue={subConfigurationValue} />
			))}
		</VerticalBox>
	);

	function openSubConfiguratorListView() {
		openDialog(({ closeDialog }) => ({
			title: component.label,
			size: "fullscreen",
			content: (
				<SubConfiguratorListStatefulView
					component={component}
					parentConfiguratorType={parentConfiguratorType}
					parentPropertyValues={parentPropertyValues}
					initialValues={currentValue}
					onSubmit={async (result) => {
						await onSubmit(result);
						await closeDialog();
					}}
					apiRef={subConfiguratorListViewApiRef}
				/>
			),
			onClose: async () => {
				try {
					if (subConfiguratorListViewApiRef.current) {
						const value = await subConfiguratorListViewApiRef.current.getValue();
						await onSubmit(value);
					}
				} catch (e) {
					logErrorAndShowOnDialog(e);
				}
			},
		}));
	}
};

interface SubConfiguratorListItemProps {
	subConfigurationValue: ConfigurationPropertyValue_SubConfiguration;
}

const SubConfiguratorListItem = ({ subConfigurationValue }: SubConfiguratorListItemProps) => {
	return (
		<VerticalBox
			sx={{
				paddingLeft: "0.5rem",
				":not(:last-child)": {
					borderBottom: "1px solid",
					borderColor: "grey.300",
					paddingBottom: "0.5rem",
				},
			}}
		>
			<VerticalBox>
				<Typography>{subConfigurationValue.label}</Typography>
				{subConfigurationValue.error && (
					<Typography color={"error.main"} fontSize={"0.875rem"} paragraph={false}>
						{subConfigurationValue.error}
					</Typography>
				)}
			</VerticalBox>
		</VerticalBox>
	);
};
