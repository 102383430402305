// This file is automatically generated. Do not edit manually.

import { ConfigurationComponentView } from "src/api/generated/erp/db/types/tables/configurationComponentView";
import { ConfigurationProperty } from "src/api/generated/erp/db/types/tables/configurationProperty";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace ConfiguratorManagingApi {
	export async function getProductFamilyVersionConfiguratorComponents(args: {
		productFamilyVersionId: number;
	}): Promise<Array<ConfigurationComponentView>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/configurator/managing/getProductFamilyVersionConfiguratorComponents",
			method: "POST",
			data: {
				productFamilyVersionId: args.productFamilyVersionId,
			},
		});
		return response as Array<ConfigurationComponentView>;
	}

	export async function getProductFamilyVersionConfiguratorProperties(args: {
		productFamilyVersionId: number;
		onlyComputedProperties?: boolean;
		searchQuery?: string;
	}): Promise<Array<ConfigurationProperty>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/configurator/managing/getProductFamilyVersionConfiguratorProperties",
			method: "POST",
			data: {
				productFamilyVersionId: args.productFamilyVersionId,
				onlyComputedProperties: args.onlyComputedProperties,
				searchQuery: args.searchQuery,
			},
		});
		return response as Array<ConfigurationProperty>;
	}
}
