import { useCallback, useState } from "react";

export type UseStateWithHistoryReturn<T> = readonly [
	T,
	(newState: T) => void,
	{
		undo: (() => void) | undefined;
		redo: (() => void) | undefined;
		clearHistory: () => void;
	},
];

export const useStateWithHistory = <T>(
	initialState: T,
	{
		maxHistoryLength,
	}: {
		maxHistoryLength: number;
	} = {
		maxHistoryLength: 100,
	},
): UseStateWithHistoryReturn<T> => {
	const [state, setState] = useState<T>(initialState);

	const [history, setHistory] = useState<T[]>([initialState]);

	// Current index of the history
	const [index, setIndex] = useState<number>(0);

	const setAndSaveState = useCallback(
		(newState: T) => {
			setHistory((prevHistory) => {
				// Drop the future history when a new state is set
				const newHistory = [...prevHistory.slice(0, index + 1), newState];
				if (newHistory.length > maxHistoryLength) {
					newHistory.shift();
				}
				return newHistory;
			});
			setIndex((prevIndex) => {
				if (prevIndex + 1 < maxHistoryLength) {
					return prevIndex + 1;
				}
				return prevIndex;
			});
			setState(newState);
		},
		[index, maxHistoryLength],
	);

	const undo = useCallback(() => {
		if (index > 0) {
			const newIndex = index - 1;
			setIndex(newIndex);
			const newState = history[newIndex];
			if (newState !== undefined) {
				setState(newState);
			}
		}
	}, [index, history]);

	const redo = useCallback(() => {
		if (index < history.length - 1) {
			const newIndex = index + 1;
			setIndex(newIndex);
			const newState = history[newIndex];
			if (newState !== undefined) {
				setState(newState);
			}
		}
	}, [index, history]);

	const clearHistory = useCallback(() => {
		setHistory([state]);
		setIndex(0);
	}, [state]);

	const canUndo = index > 0;
	const canRedo = index < history.length - 1;

	return [
		state,
		setAndSaveState,
		{
			undo: canUndo ? undo : undefined,
			redo: canRedo ? redo : undefined,
			clearHistory,
		},
	] as const;
};
