import { CrudDataGrid } from "src/components/common/dataGrid/crud/CrudDataGrid";
import { ClientSideDataGridModel } from "src/components/common/dataGrid/gridModel/ClientSideDataGridModel";
import { booleanColumn, enumColumn, textColumn } from "src/components/common/dataGrid/columns.tsx";
import i18n from "i18next";
import { SurveyFormPermissionApi } from "src/api/generated/erp/surveys/api/surveyFormPermissionApi.ts";
import { ObjectPermission } from "src/api/generated/iam/model/objectPermission.ts";
import { getObjectMemberTypeLabels } from "src/api/generated/iam/model/objectMemberType.ts";
import { SurveyFormPermissionForm } from "src/components/views/erp/surveys/management/permissions/SurveyFormPermissionForm.tsx";
import { getObjectPermissionId } from "src/components/views/erp/surveys/management/permissions/ObjectPermissionId.ts";

export interface SurveyFormPermissionsDataGridProps {
	surveyFormId: number;
}

export const SurveyFormPermissionsDataGrid = ({ surveyFormId }: SurveyFormPermissionsDataGridProps) => {
	return (
		<ClientSideDataGridModel
			fetchData={() =>
				SurveyFormPermissionApi.getSurveyFormPermissions({
					surveyFormId,
				})
			}
			initialParams={{}}
			getRows={(response) => response}
			getRowId={(row) =>
				getObjectPermissionId({
					memberType: row.memberType,
					memberId: row.memberId,
				})
			}
			gridId={"F10C04491275C8C34"}
			render={({ dataGridProps }) => (
				<CrudDataGrid<ObjectPermission>
					disableMultipleRowSelection
					columns={[
						enumColumn({
							field: "memberType",
							headerName: i18n.t("role_slash_user"),
							enumLabels: getObjectMemberTypeLabels(),
							width: 150
						}),
						textColumn({
							field: "memberName",
							headerName: i18n.t("name"),
							width: 200,
						}),
						booleanColumn({
							field: "allowChecking" as any,
							headerName: i18n.t("allow_checking"),
							valueGetter: (_, row) => row.actions.includes("SURVEY_CHECK"),
							width: 150
						}),
						booleanColumn({
							field: "allowApproving" as any,
							headerName: i18n.t("allow_approving"),
							valueGetter: (_, row) => row.actions.includes("SURVEY_APPROVE"),
							width: 150
						}),
					]}
					form={{
						addRowEnabled: true,
						editEnabled: true,
						dialogTitle: i18n.t("permission"),
						dialogSize: "sm",
						component: ({ row, onCompleted, onFormEdited }) => (
							<SurveyFormPermissionForm
								surveyFormId={surveyFormId}
								objectPermission={row}
								onCompleted={onCompleted}
								onFormEdited={onFormEdited}
							/>
						),
					}}
					remove={({ row }) =>
						SurveyFormPermissionApi.deletePermission({
							surveyFormId,
							memberType: row.memberType,
							memberId: row.memberId,
						})
					}
					{...dataGridProps}
				/>
			)}
		/>
	);
};
