import { dataGridPersistentStateProps } from "src/components/common/dataGrid/dataGridStateStorage";
import { singleSelectColumn } from "src/components/common/dataGrid/columns";
import i18n from "i18next";
import { SystemDiagramNode } from "src/api/generated/postgres/db/types/public_/tables/systemDiagramNode";
import { CrudDataGrid } from "src/components/common/dataGrid/crud/CrudDataGrid";
import { CenteredTypography } from "src/components/common/CenteredTypography";
import { SystemDiagramLink } from "src/api/generated/postgres/db/types/public_/tables/systemDiagramLink";
import { SystemDiagramLinkCrudApi } from "src/api/generated/systemDiagrams/management/systemDiagramLinkCrudApi.ts";
import { AsyncFetchRender } from "../../../../common/async/AsyncFetchRender.tsx";

export interface SystemDiagramLinksDataGridProps {
	systemDiagramId: number | null;
}

export const SystemDiagramLinksDataGrid = ({
											   systemDiagramId
										   }: SystemDiagramLinksDataGridProps) => {
	if (systemDiagramId == null)
		return <CenteredTypography>{i18n.t("select_system_diagram")}</CenteredTypography>;

	return (
		<AsyncFetchRender
			fetch={() => SystemDiagramLinkCrudApi.getForSystemDiagram({ systemDiagramId })}
			content={({ links, nodes }, reload) => (
				<SystemDiagramLinksDataGridInner
					systemDiagramId={systemDiagramId}
					links={links}
					nodes={nodes}
					reload={reload}
				/>
			)}
		/>
	);
};

interface SystemDiagramLinksDataGridInnerProps {
	systemDiagramId: number;
	links: SystemDiagramLink[];
	nodes: SystemDiagramNode[];
	reload: () => Promise<unknown>;
}

const SystemDiagramLinksDataGridInner = ({
											 systemDiagramId,
											 links,
											 nodes,
											 reload
										 }: SystemDiagramLinksDataGridInnerProps) => {
	const nodeOptions = nodes.map((node) => ({
		value: node.systemDiagramNodeId,
		label: node.name
	}));

	return (
		<CrudDataGrid<SystemDiagramLink>
			refreshData={reload}
			rows={links}
			getRowId={(row) => row.systemDiagramLinkId}
			columns={[
				singleSelectColumn({
					field: "nodeAId",
					headerName: i18n.t("node_1"),
					valueOptions: nodeOptions,
					editable: true,
					width: 250,
					validate: "required"
				}),
				singleSelectColumn({
					field: "nodeBId",
					headerName: i18n.t("node_2"),
					valueOptions: nodeOptions,
					editable: true,
					width: 300,
					validate: "required"
				})
			]}
			disableColumnReorder={true}
			add={{
				type: "enabled",
				action: async () => ({
					systemDiagramId: systemDiagramId
				})
			}}
			save={{
				type: "enabled",
				action: ({ items }) => SystemDiagramLinkCrudApi.save({ systemDiagramLinks: items })
			}}
			remove={{
				type: "enabled",
				action: ({ items }) =>
					SystemDiagramLinkCrudApi.delete_({ systemDiagramLinks: items })
			}}
			{...dataGridPersistentStateProps("A7D4720D0BB1C49")}
		/>
	);
};
