// This file is automatically generated. Do not edit manually.

import { ServerSideDataModelRequest } from "src/api/generated/common/dataGrids/serverSideDataModelRequest";
import { ServerSideDataModelResult } from "src/api/generated/common/dataGrids/serverSideDataModelResult";
import { WinnerDesign } from "src/api/generated/postgres/db/types/mellano/tables/winnerDesign";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace WinnerDesignApi {
	export async function getWinnerDesigns(args: {
		includeHandled: boolean;
		dataModelRequest: ServerSideDataModelRequest;
	}): Promise<ServerSideDataModelResult<WinnerDesign>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/mellano/winner/getWinnerDesigns",
			method: "POST",
			data: {
				includeHandled: args.includeHandled,
				dataModelRequest: args.dataModelRequest,
			},
		});
		return response as ServerSideDataModelResult<WinnerDesign>;
	}

	export async function setWinnerDesignsIsHandled(args: {
		designGuids: Array<string>;
		isHandled: boolean;
	}): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/mellano/winner/setWinnerDesignsIsHandled",
			method: "POST",
			data: {
				designGuids: args.designGuids,
				isHandled: args.isHandled,
			},
		});
		return response as void;
	}
}
