// This file is automatically generated. Do not edit manually.

import { t } from "i18next";

export const ConfigurationComponentFieldTypeValues = [
	"TEXT",
	"TEXTAREA",
	"INTEGER",
	"DECIMAL",
	"CHECKBOX",
	"SELECTION",
	"DOCUMENT",
] as const;

export type ConfigurationComponentFieldType = (typeof ConfigurationComponentFieldTypeValues)[number];

export const getConfigurationComponentFieldTypeLabels = () => ({
	TEXT: t("text"),
	TEXTAREA: t("textarea"),
	INTEGER: t("integer"),
	DECIMAL: t("decimal"),
	CHECKBOX: t("checkbox"),
	SELECTION: t("selection"),
	DOCUMENT: t("document"),
});

export const getConfigurationComponentFieldTypeLabel = (value: ConfigurationComponentFieldType): string => {
	return getConfigurationComponentFieldTypeLabels()[value];
};
