import { PartCategory } from "src/api/generated/erp/db/types/tables/partCategory.ts";
import { AsyncFetchRender } from "src/components/common/async/AsyncFetchRender.tsx";
import { PartCategoriesTreeView } from "src/components/views/erp/parts/partCategories/PartCategoriesTreeView.tsx";
import { PartCategoryApi } from "src/api/generated/erp/parts/categories/api/partCategoryApi.ts";
import i18n from "i18next";
import { AavoCheckbox } from "src/components/common/inputFields/AavoCheckbox.tsx";

export interface PartSelectionPartCategoryTreeViewProps {
	selectedPartCategory: PartCategory | null;
	setSelectedPartCategory: (partCategory: PartCategory | null) => void;
}

export const PartSelectionPartCategoryTreeView = ({
	selectedPartCategory,
	setSelectedPartCategory,
}: PartSelectionPartCategoryTreeViewProps) => {
	return (
		<AsyncFetchRender
			fetch={() => PartCategoryApi.getAll()}
			content={(partCategories, reload) => (
				<PartCategoriesTreeView
					partCategories={partCategories}
					selectedPartCategory={selectedPartCategory}
					onSelectionChanged={setSelectedPartCategory}
					refresh={reload}
					actionBarComponents={
						<AavoCheckbox
							label={i18n.t("show_all")}
							onChange={(value) => {
								if (value) setSelectedPartCategory(null);
							}}
							checked={selectedPartCategory === null}
						/>
					}
				/>
			)}
		/>
	);
};
