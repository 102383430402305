import { Divider } from "@mui/material";
import React, { useState } from "react";
import { AavoButton } from "src/components/common/buttons/AavoButton.tsx";
import { faChevronDown, faChevronRight } from "@fortawesome/pro-regular-svg-icons";
import { HorizontalBox } from "src/components/common/box/HorizontalBox.tsx";
import { InheritLayoutBox } from "src/components/common/box/InheritLayoutBox.tsx";
import { mergeSx } from "src/utils/styles.ts";
import { ConfiguratorSectionComponents } from "src/components/views/erp/configurator/configuratorForm/components/types/ConfiguratorSectionComponents.ts";
import { NonLayoutTransformedConfigurationComponent } from "src/components/views/erp/configurator/configuratorForm/components/types/NonLayoutTransformedConfigurationComponent.tsx";

export interface ConfiguratorSectionComponentProps {
	components: ConfiguratorSectionComponents;
	renderChildComponent: (component: NonLayoutTransformedConfigurationComponent) => React.ReactNode;
}

export const ConfiguratorSectionComponent = ({
	components: { sectionBreakComponent, childComponents },
	renderChildComponent,
}: ConfiguratorSectionComponentProps) => {
	const [collapsed, setCollapsed] = useState(false);

	if (sectionBreakComponent == null && childComponents.length === 0) return null;
	if (sectionBreakComponent?.visible === false) return null;

	return (
		<InheritLayoutBox>
			{sectionBreakComponent && (
				<Divider
					textAlign="left"
					orientation="horizontal"
					variant="fullWidth"
					sx={{
						alignSelf: "stretch",
						gridColumn: "1 / -1",
					}}
				>
					<HorizontalBox alignItems={"center"} gap={0.5}>
						{sectionBreakComponent.label}
						<AavoButton
							icon={collapsed ? faChevronRight : faChevronDown}
							color={"black"}
							iconProps={{ size: "xs" }}
							onClick={() => setCollapsed(!collapsed)}
							sx={{
								borderRadius: "50%",
							}}
						/>
					</HorizontalBox>
				</Divider>
			)}
			<InheritLayoutBox
				sx={mergeSx(
					{
						marginLeft: sectionBreakComponent != null ? 2 : 0,
						transition: "max-height 0.1s",
						maxHeight: "100%",
					},
					collapsed && {
						maxHeight: 0,
						overflow: "hidden",
					},
				)}
			>
				{childComponents.map(renderChildComponent)}
			</InheritLayoutBox>
		</InheritLayoutBox>
	);
};
