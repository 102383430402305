import DOMPurify from "dompurify";

export const setupDomPurify = () => {
	DOMPurify.addHook("afterSanitizeAttributes", function (node) {
		// set all elements owning target to target=_blank
		if ("target" in node) {
			node.setAttribute("target", "_blank");
			node.setAttribute("rel", "noopener");
		}
	});
};
