import { PartialTenantCustomizations } from "src/tenantCustomizations/TenantCustomizations";
import { getRandomValueSamplingMethod } from "src/tenantCustomizations/tenants/demo1/spcSamplingMethods/randomValueSamplingMethod.tsx";
import { OmegakeittiotShopOrderBatchContextMenuComponents } from "src/tenantCustomizations/tenants/omegakeittiot/OmegakeittiotShopOrderBatchContextMenuComponents.tsx";
import { getInventoryLevelEventQuantitySpcSamplingMethod } from "src/components/views/erp/spcSamplingMethods/InventoryLevelEventQuantitySpcSamplingMethod.tsx";
import { getCumulativeRecordSamplingMethod } from "src/components/views/spc/commonSamplingMethods/cumulativeRecordSamplingMethod.tsx";

export const demo1Customizations: PartialTenantCustomizations = {
	tenantConfig: {
		erp: {
			enabled: true,
			customerOrderCheckingEnabled: true,
			reclamationsEnabled: true,
			customerOrderPreLineImportEnabled: true,
			configuratorEnabled: true,
			projectActivitySchedulingEnabled: true,
			surveysEnabled: true,
		},
		spc: {
			processImprovementsEnabled: true,
		},
		tasks: {
			enabled: true,
		},
		documentsEnabled: true,
	},
	erp: {
		production: {
			shopOrderBatchContextMenuComponents: OmegakeittiotShopOrderBatchContextMenuComponents,
			addShopOrdersToBatchConfiguratorFilterComponents: [
				{ componentUuid: "6a146274-4234-427a-bb7b-4c4b1d82ad5c" },
			],
		},
	},
	spcSamplingMethods: () => [
		getRandomValueSamplingMethod(),
		getInventoryLevelEventQuantitySpcSamplingMethod(),
		getCumulativeRecordSamplingMethod(),
	],
} as const;
