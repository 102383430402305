import { FrontendDownloadFileHandle } from "src/api/generated/common/frontendDownloadFileHandle.ts";

export const downloadFile = (fileHandle: FrontendDownloadFileHandle) => {
	downloadFileHelper(fileHandle.fileName, fileHandle.fileUrl);
};

export const createAndDownloadFile = (filename: string, content: string, encoding: string = "utf-8") => {
	downloadFileHelper(filename, `data:text/plain;charset=${encoding},` + encodeURIComponent(content));
};

const downloadFileHelper = (filename: string, href: string) => {
	const link = document.createElement("a");
	link.setAttribute("download", filename);
	link.href = href;
	link.style.display = "none";
	document.body.appendChild(link);
	link.click();
	link.remove();
};
