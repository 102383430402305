import {
	ProjectObjectGanttBase,
	ProjectObjectGanttBaseProps,
} from "src/components/views/erp/project/gantt/ProjectObjectGanttBase.tsx";
import { ProjectGanttViewApi } from "src/api/generated/erp/project/api/projectGanttViewApi.ts";

export interface ProjectGanttViewProps
	extends Pick<
		ProjectObjectGanttBaseProps,
		"onProjectScheduled" | "onSubProjectScheduled" | "onActivityScheduled" | "onTaskScheduled"
	> {
	projectIds: number[];
}

export const ProjectGanttView = ({ projectIds, ...other }: ProjectGanttViewProps) => {
	return (
		<ProjectObjectGanttBase
			rootType={"PROJECT"}
			collapseActivities
			fetchData={() => ProjectGanttViewApi.getGanttDataForProjects({ projectIds: projectIds })}
			{...other}
		/>
	);
};
