import React, { useCallback, useState } from "react";
import {
	ConfirmDialog,
	ConfirmDialogProps,
} from "src/components/common/dialogs/confirmDialog/ConfirmDialog";
import i18n from "i18next";

export interface ConfirmDialogOptions
	extends Pick<
		ConfirmDialogProps,
		"title" | "message" | "cancelButtonText" | "confirmButtonText"
	> {}

const DEFAULT_CONFIRM_OPTIONS = {
	title: i18n.t("are_you_sure"),
};

export type ShowConfirmDialogFunc = (options?: ConfirmDialogOptions) => Promise<boolean>;
type ResolveFunction = (value: boolean | PromiseLike<boolean>) => void;

export const ConfirmDialogContext = React.createContext<ShowConfirmDialogFunc | undefined>(
	undefined,
);

export interface ConfirmProviderProps extends React.PropsWithChildren {}

export const ConfirmDialogProvider = ({ children }: ConfirmProviderProps) => {
	const [options, setOptions] = useState<ConfirmDialogOptions>(DEFAULT_CONFIRM_OPTIONS);
	const [resolveFuncList, setResolveFuncList] = useState<[ResolveFunction] | []>([]);
	const resolveFunc = resolveFuncList[0];

	const confirm = (o?: ConfirmDialogOptions) => {
		return new Promise<boolean>((resolve) => {
			setOptions(o ?? {});
			setResolveFuncList([resolve]);
		});
	};

	const closeDialog = useCallback(() => {
		setResolveFuncList([]);
	}, [setResolveFuncList]);

	const onCancel = useCallback(() => {
		resolveFunc?.(false);
		closeDialog();
	}, [resolveFunc, closeDialog]);

	const onConfirm = useCallback(() => {
		resolveFunc?.(true);
		closeDialog();
	}, [resolveFunc, closeDialog]);

	return (
		<>
			<ConfirmDialogContext.Provider value={confirm}>
				{children}
			</ConfirmDialogContext.Provider>
			{resolveFunc !== undefined && (
				<ConfirmDialog
					onConfirm={onConfirm}
					onClose={onCancel}
					{...options}
				></ConfirmDialog>
			)}
		</>
	);
};
