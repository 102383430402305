import { WopiApp } from "src/api/generated/documents/wopi/discovery/wopiApp.ts";

const WOPI_APP_EXTENSIONS: Record<WopiApp, string[]> = {
	WORD: ["doc", "docm", "docx", "dot", "dotm", "dotx", "odt", "rtf"],
	EXCEL: ["csv", "ods", "xls", "xlsb", "xlsm", "xlsx"],
	POWERPOINT: ["odp", "pot", "potm", "potx", "pps", "ppsm", "ppsx", "ppt", "pptm", "pptx"],
	ONENOTE: ["one", "onepkg", "onetoc2"],
	VISIO: ["vdw", "vsd", "vsdm", "vsdx"],
	WOPITEST: ["wopitest", "wopitestx"]
};

export const getWopiAppForExtension = (extension: string): WopiApp | undefined => {
	if (extension === undefined) {
		return undefined;
	}
	for (const [app, extensions] of Object.entries(WOPI_APP_EXTENSIONS) as [WopiApp, string[]][]) {
		if (extensions.includes(extension)) {
			return app;
		}
	}

	return undefined;
};