// This file is automatically generated. Do not edit manually.

import { CustomerOrderOffer } from "src/api/generated/erp/db/types/tables/customerOrderOffer";
import { CustomerOfferBillingPlanLineView } from "src/api/generated/erp/db/types/tables/customerOfferBillingPlanLineView";
import { BillingPlanSummary } from "src/api/generated/erp/sales/billingPlan/base/billingPlanSummary";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace CustomerOfferBillingPlanApi {
	export async function getCustomerOfferBillingPlan(args: {
		customerOfferId: number;
	}): Promise<CustomerOfferBillingPlanApi_BillingPlanDto> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/customerOffer/billingPlan/getCustomerOfferBillingPlan",
			method: "POST",
			data: {
				customerOfferId: args.customerOfferId,
			},
		});
		return response as CustomerOfferBillingPlanApi_BillingPlanDto;
	}
}

export interface CustomerOfferBillingPlanApi_BillingPlanDto {
	customerOffer: CustomerOrderOffer;
	lines: Array<CustomerOfferBillingPlanLineView>;
	summary: BillingPlanSummary;
}
