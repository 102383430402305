// This file is automatically generated. Do not edit manually.

import { CustomerGroup } from "src/api/generated/erp/db/types/tables/customerGroup";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace CustomerGroupApi {
	export async function getAllCustomerGroups(): Promise<Array<CustomerGroup>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/customerGroup/getAllCustomerGroups",
			method: "POST",
			data: {},
		});
		return response as Array<CustomerGroup>;
	}
}
