import {
	DocumentsOfObjectsDataGridContextMenuProps
} from "src/components/views/documents/objectDocuments/DocumentsOfObjectsDataGridContextMenu.tsx";
import { AsyncMenuButton } from "src/components/common/contextMenu/AsyncMenuButton.tsx";
import { useOpenLegacyView } from "src/components/views/legacy/useOpenLegacyView.ts";
import { faFile } from "@fortawesome/pro-regular-svg-icons";
import i18n from "i18next";

export const LehtoDocumentsOfObjectDataGridContextMenuComponents = (
	{
		row
	}: DocumentsOfObjectsDataGridContextMenuProps) => {

	const openLegacyView = useOpenLegacyView();

	return row.primaryFileExtension?.toLowerCase() !== "bltetl" &&
		<AsyncMenuButton
			label={i18n.t("create_asc_files")}
			icon={faFile}
			onClick={async () => {
				openLegacyView(LEHTO_BTL_PROJECT_TREE_VIEW_REF, {
					documentId: row.documentId
				});
			}}
		/>;
};

const LEHTO_BTL_PROJECT_TREE_VIEW_REF = "69A04CD5DF570322";