import i18n from "i18next";
import { AavoMosaic } from "src/components/common/mosaic/AavoMosaic.tsx";
import { BillingPlanModelsDataGrid } from "src/components/views/erp/sales/billingPlan/model/BillingPlanModelsDataGrid.tsx";
import { useState } from "react";
import { BillingPlanModel } from "src/api/generated/erp/db/types/tables/billingPlanModel.ts";
import {
	BillingPlanModelLinesDataGrid
} from "src/components/views/erp/sales/billingPlan/model/BillingPlanModelLinesDataGrid.tsx";
import { CenteredTypography } from "src/components/common/CenteredTypography.tsx";

export const BillingPlanModelsView = () => {
	const [selectedBillingPlanModel, setSelectedBillingPlanModel] = useState<BillingPlanModel | undefined>(
		undefined,
	);

	return (
		<AavoMosaic
			viewId={"C5DBBCEE0A5920F6"}
			layout={{
				type: "column",
				first: {
					type: "panel",
					title: i18n.t("models"),
					content: <BillingPlanModelsDataGrid onSelectionChanged={setSelectedBillingPlanModel} />,
				},
				second: {
					type: "panel",
					title: i18n.t("billing_plan"),
					content:
						selectedBillingPlanModel == null ?
							<CenteredTypography>{i18n.t("select_a_billing_plan_model")}</CenteredTypography>
						:	<BillingPlanModelLinesDataGrid
								key={selectedBillingPlanModel.billingPlanModelId}
								billingPlanModelId={selectedBillingPlanModel.billingPlanModelId}
							/>,
				},
			}}
		/>
	);
};
