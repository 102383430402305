import { AsyncButton } from "src/components/common/buttons/AsyncButton.tsx";
import i18n from "i18next";
import { faBell, faBellSlash, faCheck, faUndo } from "@fortawesome/pro-regular-svg-icons";
import { WorkCenterJobQueueInProgressApi } from "src/api/generated/erp/production/jobQueues/api/workCenter/workCenterJobQueueInProgressApi.ts";
import { SpcDataCollectionApi } from "src/api/generated/spc/dataCollection/spcDataCollectionApi.ts";
import { WorkCenterSpcData } from "src/api/generated/erp/production/jobQueues/api/workCenterSpcData.ts";

export interface JobQueueSpcButtonsProps {
	workCenterSpcData: WorkCenterSpcData;
	refresh?: () => Promise<unknown>;
}

export const JobQueueSpcButtons = ({ workCenterSpcData, refresh }: JobQueueSpcButtonsProps) => {
	const controlChartId = workCenterSpcData.controlChartId;

	return (
		<>
			{workCenterSpcData.andonEnabled && (
				<AsyncButton
					label={workCenterSpcData.andonActive ? i18n.t("clear_andon") : i18n.t("andon")}
					icon={workCenterSpcData.andonActive ? faBellSlash : faBell}
					variant={"outlined"}
					onClick={async () => {
						await WorkCenterJobQueueInProgressApi.setWorkCenterHasAndon({
							workcenterId: workCenterSpcData.workCenterId,
							hasAndon: !workCenterSpcData.andonActive,
						});
						await refresh?.();
					}}
				/>
			)}
			{controlChartId != null &&
				(workCenterSpcData.hasIncompleteRecord ?
					<AsyncButton
						icon={faCheck}
						label={i18n.t("work_center_completed")}
						variant={"outlined"}
						onClick={async () => {
							await SpcDataCollectionApi.finishTimeBasedRecord({
								controlChartId: controlChartId,
							});
							await refresh?.();
						}}
					/>
				:	<AsyncButton
						icon={faUndo}
						label={i18n.t("revert_work_center_completion")}
						variant={"outlined"}
						onClick={async () => {
							await SpcDataCollectionApi.revertLastRecordToIncomplete({
								controlChartId: controlChartId,
							});
							await refresh?.();
						}}
					/>)}
		</>
	);
};
