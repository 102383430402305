// This file is automatically generated. Do not edit manually.

import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace ProjectActivityMaterialActionApi {
	export async function cancelMaterial(args: { projectActivityMaterialId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/project/activityMaterials/cancelMaterial",
			method: "POST",
			data: {
				projectActivityMaterialId: args.projectActivityMaterialId,
			},
		});
		return response as void;
	}

	export async function releaseMaterials(args: { projectActivityMaterialIds: Array<number> }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/project/activityMaterials/releaseMaterials",
			method: "POST",
			data: {
				projectActivityMaterialIds: args.projectActivityMaterialIds,
			},
		});
		return response as void;
	}
}
