import { DocumentsOfMultipleObjectsView } from "src/components/views/documents/objectDocuments/DocumentsOfMultipleObjectsView.tsx";

export interface OperationAndWorkCenterDocumentsViewProps {
	operationId: number;
	workCenterId: number;
}

export const OperationAndWorkCenterDocumentsView = ({
	operationId,
	workCenterId,
}: OperationAndWorkCenterDocumentsViewProps) => {
	return (
		<DocumentsOfMultipleObjectsView
			objectRefs={[
				{ objectType: "WORKCENTER", objectId: workCenterId },
				{ objectType: "OPERATION", objectId: operationId },
			]}
			editable={false}
		/>
	);
};
