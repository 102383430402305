import { PartConfiguratorType } from "src/api/generated/erp/configurator/configuratorType/partConfiguratorType.ts";
import { PartConfiguratorView } from "../PartConfiguratorView";
import { PartConfiguratorFormResult } from "../PartConfiguratorForm";
import { ConfigurationCreationApi } from "src/api/generated/erp/configurator/api/configurationCreationApi";
import i18n from "i18next";
import {
	PartConfigurationInspectingView
} from "src/components/views/erp/configurator/inspecting/PartConfigurationInspectingView.tsx";
import { useGenericDialog } from "src/components/common/dialogs/useGenericDialog.ts";
import {
	askObjectConfigurationHistoryEntryInput
} from "src/components/views/erp/configurator/objectConfigurationHistory/ObjectConfigurationHistoryEntryInputForm.utils.tsx";

export interface PurchaseOrderLineConfiguratorViewProps {
	purchaseOrderLineId: number;
	closeDialog: () => void;
	refreshSourceView: () => void;
}

export const PurchaseOrderLineConfiguratorView = ({
	purchaseOrderLineId,
	closeDialog,
	refreshSourceView,
}: PurchaseOrderLineConfiguratorViewProps) => {
	const { openDialog } = useGenericDialog();

	const configuratorType: PartConfiguratorType = {
		type: "purchaseOrderLine",
		purchaseOrderLineId: purchaseOrderLineId,
		isTestConfigurator: false,
		isSubConfigurator: false,
	};

	return (
		<PartConfiguratorView
			configuratorType={configuratorType}
			onCompleted={async (result) => {
				if (result.type === "success") {
					await onSubmit(result.value);
				} else {
					closeDialog?.();
				}
			}}
		/>
	);

	async function onSubmit({
		propertyValues,
		configurationSessionId,
	}: PartConfiguratorFormResult) {
		const historyEntryInput = await askObjectConfigurationHistoryEntryInput({
			openDialog,
			objectType: "PURCHASE_ORDER_LINE",
			objectId: purchaseOrderLineId,
		});
		if (historyEntryInput === undefined) return;

		const partConfigurationId =
			await ConfigurationCreationApi.createPartConfigurationForPurchaseOrderLine({
				purchaseOrderLineId: purchaseOrderLineId,
				propertyValues: propertyValues,
				configurationSessionId: configurationSessionId,
				historyEntryInput: historyEntryInput,
			});

		closeDialog();
		refreshSourceView();

		openDialog(() => ({
			title: i18n.t("configuration"),
			content: (
				<PartConfigurationInspectingView
					partConfigurationId={partConfigurationId}
					showBom={false}
				/>
			),
		}));
	}
};
