import { logError } from "src/errorHandling/errorLogging.ts";
import { APP_STATE_STORAGE_KEY } from "src/storage/appState/appState.ts";
import { LOCAL_SETTINGS_STORAGE_KEY } from "src/storage/settings.ts";
import { MAIN_MENU_FAVORITES_STORAGE_KEY } from "src/components/views/drawer/mainMenuFavoriteViewsStorage.ts";

export function setLocalStorageItemManaged(key: string, value: string) {
	try {
		localStorage.setItem(key, value);
	} catch (e) {
		logError(e, "Failed to set local storage item");
		softClearLocalStorage();
		try {
			localStorage.setItem(key, value);
		} catch (e) {
			logError(e, "Failed to set local storage item even after clearing storage");
			throw e;
		}
	}
}

function softClearLocalStorage() {
	const KEYS_TO_KEEP = [APP_STATE_STORAGE_KEY, LOCAL_SETTINGS_STORAGE_KEY, MAIN_MENU_FAVORITES_STORAGE_KEY];

	for (let i = 0; i < localStorage.length; i++) {
		const key = localStorage.key(i);
		if (key != null && !KEYS_TO_KEEP.includes(key)) {
			localStorage.removeItem(key);
		}
	}
}
