// This file is automatically generated. Do not edit manually.

import { CostEventPriceList } from "src/api/generated/erp/db/types/tables/costEventPriceList";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace CostEventPriceListDataGridApi {
	export async function getAll(): Promise<Array<CostEventPriceList>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/costEvents/costEventPriceList/getAll",
			method: "POST",
			data: {},
		});
		return response as Array<CostEventPriceList>;
	}

	export async function save(args: {
		costEventPriceLists: Array<CostEventPriceList>;
	}): Promise<Array<CostEventPriceList>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/costEvents/costEventPriceList/save",
			method: "POST",
			data: {
				costEventPriceLists: args.costEventPriceLists,
			},
		});
		return response as Array<CostEventPriceList>;
	}

	export async function delete_(args: { costEventPriceLists: Array<CostEventPriceList> }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/costEvents/costEventPriceList/delete",
			method: "POST",
			data: {
				costEventPriceLists: args.costEventPriceLists,
			},
		});
		return response as void;
	}
}
