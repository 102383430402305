// This file is automatically generated. Do not edit manually.

import { ConfiguratorTableTabContentSaveService_ColumnParams } from "src/api/generated/erp/configurator/tables/service/configuratorTableTabContentSaveService";
import { ConfiguratorTableTabContentSaveService_RowParams } from "src/api/generated/erp/configurator/tables/service/configuratorTableTabContentSaveService";
import { ConfiguratorTableTabContentSaveService_CellParams } from "src/api/generated/erp/configurator/tables/service/configuratorTableTabContentSaveService";
import { FrontendUploadFileHandle } from "src/api/generated/common/frontendUploadFileHandle";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace ConfiguratorTableTabContentEditApi {
	export async function saveTabContent(args: {
		configuratorTableTabId: number;
		columns: Array<ConfiguratorTableTabContentSaveService_ColumnParams>;
		rows: Array<ConfiguratorTableTabContentSaveService_RowParams>;
		cells: Array<ConfiguratorTableTabContentSaveService_CellParams>;
	}): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/configurator/tables/content/saveTabContent",
			method: "POST",
			data: {
				configuratorTableTabId: args.configuratorTableTabId,
				columns: args.columns,
				rows: args.rows,
				cells: args.cells,
			},
		});
		return response as void;
	}

	export async function importContentFromFile(args: {
		configuratorTableTabId: number;
		fileHandle: FrontendUploadFileHandle;
	}): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/configurator/tables/content/importContentFromFile",
			method: "POST",
			data: {
				configuratorTableTabId: args.configuratorTableTabId,
				fileHandle: args.fileHandle,
			},
		});
		return response as void;
	}
}
