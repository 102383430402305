import { FormCommonProps } from "src/components/common/forms/types.ts";
import { DeepPartial } from "react-hook-form";
import { AsyncForm, AsyncFormContentParams } from "src/components/common/forms/AsyncForm.tsx";
import { Delivery } from "src/api/generated/erp/db/types/tables/delivery.ts";
import i18n from "i18next";
import { FormSelectField } from "src/components/common/forms/fields/FormSelectField.tsx";
import { FormDateTimeField } from "src/components/common/forms/fields/FormDateTimeField.tsx";
import dayjs from "dayjs";
import { FormTextField } from "src/components/common/forms/fields/FormTextField.tsx";
import { requireRule } from "src/components/common/forms/validation.ts";
import { FormEnumSelectField } from "src/components/common/forms/fields/FormEnumSelectField.tsx";
import { getCountryLabels } from "src/api/generated/erp/db/types/enums/country.ts";
import { FormSection } from "src/components/common/forms/FormSection.tsx";
import { DeliveryEditApi, DeliveryEditApi_FormInitData } from "src/api/generated/erp/delivery/api/deliveryEditApi.ts";
import { DeliverySource } from "src/api/generated/erp/delivery/model/deliverySource.ts";
import {
	deliverySourceToSourceId,
	deliverySourceToSourceType
} from "src/components/views/erp/delivery/deliverySourceUtils.ts";
import { useStoredState } from "../../../../../utils/useStoredState.ts";
import { genericNullableValue } from "../../../../../utils/genericNullableValue.ts";

export interface DeliveryFormProps extends FormCommonProps<number> {
	deliverySource: DeliverySource;
	deliveryId?: number;
}

interface FormValues extends Delivery {}

export const DeliveryForm = (props: DeliveryFormProps) => {
	const { onCompleted, onFormEdited, deliverySource, deliveryId } = props;

	const [storedDefaultValues, setStoredDefaultValues] = useStoredState({
		defaultValue: {
			cargoDriverId: genericNullableValue<number>(),
		},
		key: "12B932BA61966B645",
	});

	return (
		<AsyncForm
			fetch={() =>
				DeliveryEditApi.getFormInitData({
					deliverySource,
					deliveryId,
				})
			}
			getDefaultValues={getDefaultValues}
			submit={submit}
			onCompleted={onCompleted}
			onFormEdited={onFormEdited}
			columns={3}
			render={(contentParams) => <FormContent {...props} {...contentParams} />}
		/>
	);

	function getDefaultValues({
		delivery,
		sourceData
	}: DeliveryEditApi_FormInitData): DeepPartial<FormValues> {
		if (delivery != null) return delivery;
		else {
			const defaultLoadUnloadDateTime = dayjs().startOf("day").add(12, "hour").toISOString();

			return {
				siteId: sourceData.siteId,
				sourceType: deliverySourceToSourceType(deliverySource),
				sourceId: deliverySourceToSourceId(deliverySource),
				cargoDriverId: storedDefaultValues.cargoDriverId ?? undefined,
				deliveryName: sourceData.addressDescription,
				deliveryAddress_1: sourceData.address.address_1,
				deliveryAddress_2: sourceData.address.address_2,
				deliveryCity: sourceData.address.city,
				deliveryPostalCode: sourceData.address.postalCode,
				deliveryCountry: sourceData.address.country,
				recipientContact: sourceData.recipientContact ?? "",
				transportRouteId: sourceData.transportRouteId,
				plannedLoadingDate: defaultLoadUnloadDateTime,
				plannedUnloadingDate: defaultLoadUnloadDateTime,
			};
		}
	}

	async function submit(values: FormValues) {
		setStoredDefaultValues({
			cargoDriverId: values.cargoDriverId,
		})

		if (deliveryId == null) {
			return await DeliveryEditApi.insert({ delivery: values });
		} else {
			await DeliveryEditApi.update({ delivery: values });
			return deliveryId;
		}
	}
};

interface FormContentProps
	extends DeliveryFormProps,
		AsyncFormContentParams<DeliveryEditApi_FormInitData, FormValues> {}

const FormContent = ({
	control,
	data: { cargoDriverOptions, transportRouteOptions, delivery },
}: FormContentProps) => {
	const isDelivered = delivery?.deliveryState === "DELIVERED";

	return (
		<>
			<FormDateTimeField
				control={control}
				name={"plannedLoadingDate"}
				label={i18n.t("planned_loading_time")}
				disabled={isDelivered}
			/>
			<FormDateTimeField
				control={control}
				name={"plannedUnloadingDate"}
				label={i18n.t("planned_unloading_time")}
				disabled={isDelivered}
			/>
			<FormSelectField
				control={control}
				name={"cargoDriverId"}
				label={i18n.t("cargo_driver")}
				options={cargoDriverOptions}
				getOptionKey={(option) => option.cargoDriverId}
				getOptionLabel={(option) => option.cargoDriverName}
				disabled={isDelivered}
				startNewGridRow
				rules={requireRule()}
			/>
			<FormTextField
				control={control}
				name={"trackingCode"}
				label={i18n.t("tracking_code")}
				disabled={isDelivered}
			/>
			<FormTextField
				control={control}
				name={"senderContact"}
				label={i18n.t("sender_contact")}
				disabled={isDelivered}
				startNewGridRow
			/>
			<FormTextField
				control={control}
				name={"recipientContact"}
				label={i18n.t("recipient_contact")}
				disabled={isDelivered}
			/>
			<FormTextField control={control} name={"note"} label={i18n.t("note")} multiline spanGridColumns />

			<FormSection label={i18n.t("delivery_address")}>
				<FormTextField
					control={control}
					name={"deliveryName"}
					label={i18n.t("name")}
					disabled={isDelivered}
				/>
				<FormTextField
					control={control}
					name={"deliveryAddress_1"}
					label={i18n.t("address_1")}
					rules={requireRule()}
					disabled={isDelivered}
				/>
				<FormTextField
					control={control}
					name={"deliveryAddress_2"}
					label={i18n.t("address_2")}
					disabled={isDelivered}
				/>
				<FormTextField
					control={control}
					name={"deliveryPostalCode"}
					label={i18n.t("postal_code")}
					rules={requireRule()}
					disabled={isDelivered}
				/>
				<FormTextField
					control={control}
					name={"deliveryCity"}
					label={i18n.t("city")}
					rules={requireRule()}
					disabled={isDelivered}
				/>
				<FormEnumSelectField
					control={control}
					name={"deliveryCountry"}
					label={i18n.t("country")}
					options={getCountryLabels()}
					disableClearable
					disabled={isDelivered}
				/>
				<FormSelectField
					control={control}
					name={"transportRouteId"}
					label={i18n.t("transport_route")}
					options={transportRouteOptions}
					getOptionKey={(o) => o.transportRouteId}
					getOptionLabel={(o) => o.name}
					disabled={isDelivered}
				/>
			</FormSection>
		</>
	);
};
