import { PartConfiguratorType } from "src/api/generated/erp/configurator/configuratorType/partConfiguratorType";
import { AsyncFetchRender } from "src/components/common/async/AsyncFetchRender";
import {
	TestConfigurationContextDataFormApi,
	TestConfigurationContextDataFormApi_InitData,
	TestConfigurationContextDataFormApi_InitData_ConfigurableBomLine,
	TestConfigurationContextDataFormApi_InitData_ConfiguratorLibraryDto,
} from "src/api/generated/erp/configurator/api/configuratorTesting/testConfigurationContextDataFormApi.ts";
import {
	ConfigurationTestContextData,
	ConfigurationTestContextData_LibraryOptions,
	ConfigurationTestContextData_SubConfigurationOptions,
} from "src/api/generated/erp/configurator/contextData/configurationTestContextData.ts";
import { ObjectAttributeField } from "src/api/generated/erp/common/objectAttributes/objectAttributeField.ts";
import { useFieldArray, useForm } from "react-hook-form";
import i18n from "i18next";
import { FormAsyncSelectField } from "src/components/common/forms/fields/FormAsyncSelectField.tsx";
import { CustomerApi } from "src/api/generated/erp/sales/customer/api/customerApi";
import { FetchAsyncOptionParams } from "src/components/common/inputFields/AsyncSelectField.tsx";
import { FormSection } from "src/components/common/forms/FormSection";
import { FormNumberField } from "src/components/common/forms/fields/FormNumberField.tsx";
import { FormTextField } from "src/components/common/forms/fields/FormTextField.tsx";
import { FormDateField } from "src/components/common/forms/fields/FormDateField.tsx";
import { useCallback, useEffect, useState } from "react";
import { useErrorDialog } from "src/components/common/dialogs/errorDialog/userErrorDialog.ts";
import { useDebounce } from "src/utils/useDebounce.ts";
import { AavoFormLayout } from "src/components/common/forms/AavoFormLayout.tsx";
import { VerticalBox } from "src/components/common/box/VerticalBox";
import { SyncedToCloudIcon } from "src/components/common/icons/SyncedToCloudIcon.tsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsRotate } from "@fortawesome/pro-regular-svg-icons";
import { HorizontalBox } from "src/components/common/box/HorizontalBox";
import { FormSelectField } from "src/components/common/forms/fields/FormSelectField.tsx";
import { getCatalogPartRevisionStateLabel } from "src/api/generated/erp/db/types/enums/catalogPartRevisionState.ts";
import { ObjectAttribute } from "src/api/generated/erp/db/types/tables/objectAttribute.ts";
import dayjs from "dayjs";
import { ObjectAttributeFieldFormComponent } from "src/components/views/erp/objectAttributes/ObjectAttributeFieldFormComponent.tsx";
import { getConfiguratorLibraryVersionStateLabel } from "src/api/generated/erp/db/types/enums/configuratorLibraryVersionState.ts";

export interface TestConfiguratorContextDataFormProps {
	configuratorType: PartConfiguratorType;
}

export const TestConfiguratorContextDataForm = (props: TestConfiguratorContextDataFormProps) => {
	const { configuratorType } = props;
	return (
		<AsyncFetchRender
			fetch={() =>
				TestConfigurationContextDataFormApi.getInitData({
					configuratorType: configuratorType,
				})
			}
			content={(initData) => <Content {...props} initData={initData} />}
		/>
	);
};

interface ContentProps extends TestConfiguratorContextDataFormProps {
	initData: TestConfigurationContextDataFormApi_InitData;
}

interface FormValues
	extends Omit<
		ConfigurationTestContextData,
		"subConfiguratorOptions" | "customerOrderAttributes" | "libraryOptions"
	> {
	subConfiguratorOptions: SubConfigurationOptionsFormValues[];
	libraryOptions: LibraryOptionsFormValues[];
	customerOrderAttributes: CustomerOrderAttributeFormValues[];
}

interface SubConfigurationOptionsFormValues
	extends ConfigurationTestContextData_SubConfigurationOptions,
		TestConfigurationContextDataFormApi_InitData_ConfigurableBomLine {}

interface LibraryOptionsFormValues
	extends ConfigurationTestContextData_LibraryOptions,
		TestConfigurationContextDataFormApi_InitData_ConfiguratorLibraryDto {}

interface CustomerOrderAttributeFormValues extends ObjectAttributeField, ObjectAttribute {}

const Content = ({
	configuratorType,
	initData: { contextData: currentContextData, configurableBomLines, customerOrderAttributeFields, libraries },
}: ContentProps) => {
	const { logErrorAndShowOnDialog } = useErrorDialog();

	const { control, handleSubmit, watch } = useForm<FormValues>({
		mode: "all",
		defaultValues: {
			...currentContextData,
			subConfiguratorOptions: configurableBomLines.map(getSubConfiguratorOptionsFormValues),
			customerOrderAttributes: customerOrderAttributeFields.map(getCustomerOrderAttributeFormValues),
			libraryOptions: libraries.map(getLibraryOptionsFormValues),
		},
	});

	const subConfiguratorOptionsFieldArray = useFieldArray({
		control: control,
		name: "subConfiguratorOptions",
	});

	const customerOrderAttributesFieldArray = useFieldArray({
		control: control,
		name: "customerOrderAttributes",
	});

	const libraryOptionsFieldArray = useFieldArray({
		control: control,
		name: "libraryOptions",
	});

	const [isSaving, setIsSaving] = useState(false);
	const debounceSave = useDebounce();
	const onChange = useCallback(() => {
		executeSubmit();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const subscription = watch(() => onChange());
		return () => subscription.unsubscribe();
	}, [watch, onChange]);

	return (
		<VerticalBox flex={1} padding={1}>
			<AavoFormLayout columns={3}>
				<FormAsyncSelectField
					control={control}
					name={"customerId"}
					label={i18n.t("customer")}
					fetchOptions={({ searchQuery, currentSelection }: FetchAsyncOptionParams<number>) =>
						CustomerApi.getCustomerSelectionOptions({
							searchQuery,
							currentSelection,
						})
					}
					getOptionKey={(option) => option.customerId}
					getOptionLabel={(option) => option.customerName}
				/>
				<FormSection label={i18n.t("customer_order_line")}>
					<FormNumberField control={control} name={"customerOrderLineQuantity"} label={i18n.t("quantity")} />
					<FormTextField
						control={control}
						name={"customerOrderLineBatchCode"}
						label={i18n.t("shop_order_batch_code")}
					/>
					<FormDateField
						control={control}
						name={"customerOrderLinePlannedDeliveryDate"}
						label={i18n.t("planned_delivery_date")}
					/>
				</FormSection>
				<FormSection label={i18n.t("customer_order_attributes")}></FormSection>
				{customerOrderAttributesFieldArray.fields.map((field, index) => (
					<ObjectAttributeFieldFormComponent
						key={field.id}
						control={control}
						name={`customerOrderAttributes.${index}.attributeValue`}
						fieldDefinition={field}
					/>
				))}
				{subConfiguratorOptionsFieldArray.fields.length > 0 && (
					<FormSection label={i18n.t("revisions_to_use_for_configurable_bom_lines")}>
						{subConfiguratorOptionsFieldArray.fields.map((field, index) => (
							<FormSelectField
								key={field.id}
								control={control}
								label={`${field.partNo} | ${field.partDescription1}`}
								name={`subConfiguratorOptions.${index}.catalogPartRevisionIdToUse`}
								disableClearable
								options={field.revisionOptions}
								getOptionKey={(rev) => rev.catalogPartRevisionId}
								getOptionLabel={(rev) =>
									`${rev.revisionNumber} | ${getCatalogPartRevisionStateLabel(rev.state)}`
								}
							/>
						))}
					</FormSection>
				)}
				{libraryOptionsFieldArray.fields.length > 0 && (
					<FormSection label={i18n.t("library_versions_to_use")}>
						{libraryOptionsFieldArray.fields.map((field, index) => (
							<FormSelectField
								key={field.id}
								control={control}
								label={field.name}
								name={`libraryOptions.${index}.configuratorLibraryVersionIdToUse`}
								disableClearable
								options={field.versionOptions}
								getOptionKey={(rev) => rev.configuratorLibraryVersionId}
								getOptionLabel={(ver) =>
									`${ver.versionNumber} | ${getConfiguratorLibraryVersionStateLabel(ver.state)}`
								}
							/>
						))}
					</FormSection>
				)}
			</AavoFormLayout>
			<HorizontalBox margin={2}>
				{isSaving ?
					<FontAwesomeIcon icon={faArrowsRotate} spin />
				:	<SyncedToCloudIcon />}
			</HorizontalBox>
		</VerticalBox>
	);

	function executeSubmit() {
		const submitForm = (values: ConfigurationTestContextData) => {
			setIsSaving(true);
			debounceSave(500, async () => {
				try {
					await TestConfigurationContextDataFormApi.saveTestConfigurationContextData({
						configuratorType: configuratorType,
						contextData: values,
					});
				} catch (e) {
					logErrorAndShowOnDialog(e);
				} finally {
					setIsSaving(false);
				}
			});
		};

		handleSubmit(submitForm)();
	}

	function getSubConfiguratorOptionsFormValues(
		bomLine: TestConfigurationContextDataFormApi_InitData_ConfigurableBomLine,
	): SubConfigurationOptionsFormValues {
		const currentValues = currentContextData.subConfiguratorOptions.find(
			(options) => options.subConfigurationCatalogPartId === bomLine.catalogPartId,
		);
		return {
			...bomLine,
			catalogPartRevisionIdToUse: currentValues?.catalogPartRevisionIdToUse ?? bomLine.activeRevisionId,
			subConfigurationCatalogPartId: bomLine.catalogPartId,
		};
	}

	function getLibraryOptionsFormValues(
		library: TestConfigurationContextDataFormApi_InitData_ConfiguratorLibraryDto,
	): LibraryOptionsFormValues {
		const currentValues = currentContextData.libraryOptions.find(
			(options) => options.configuratorLibraryId === library.configuratorLibraryId,
		);
		const activeVersion = library.versionOptions
			.filter((v) => v.state === "RELEASED")
			.toSorted((a, b) => b.versionNumber - a.versionNumber)[0];

		return {
			...library,
			configuratorLibraryVersionIdToUse:
				currentValues?.configuratorLibraryVersionIdToUse ?? activeVersion?.configuratorLibraryVersionId,
		};
	}

	function getCustomerOrderAttributeFormValues(
		attributeField: ObjectAttributeField,
	): CustomerOrderAttributeFormValues {
		const attribute = currentContextData.customerOrderAttributes.find(
			(attribute) => attribute.attributeFieldId === attributeField.id,
		) ?? {
			// Values other than attributeValue are not used.
			attributeFieldId: attributeField.id,
			attributeValue: null,
			objectId: -1,
			objectAttributeId: -1,
			objectType: "CUSTOMER_ORDER",
			valueModifiedAt: dayjs().toISOString(),
			valueModifiedByUserId: -1,
		};
		return {
			...attributeField,
			...attribute,
		};
	}
};
