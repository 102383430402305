// This file is automatically generated. Do not edit manually.

import { Item } from "src/api/generated/postgres/db/types/ext_resourcing/tables/item";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace ResourceItemEditApi {
	export async function getItem(args: { itemId: number | null | undefined }): Promise<Item | null> {
		const response = await makeAxiosRequest({
			url: "/api/ext/spc/resourceItem/getItem",
			method: "POST",
			data: {
				itemId: args.itemId,
			},
		});
		return response === "" ? null : (response as Item | null);
	}

	export async function insert(args: { item: Item }): Promise<number> {
		const response = await makeAxiosRequest({
			url: "/api/ext/spc/resourceItem/insert",
			method: "POST",
			data: {
				item: args.item,
			},
		});
		return response as number;
	}

	export async function update(args: { item: Item }): Promise<number> {
		const response = await makeAxiosRequest({
			url: "/api/ext/spc/resourceItem/update",
			method: "POST",
			data: {
				item: args.item,
			},
		});
		return response as number;
	}

	export async function delete_(args: { itemIds: Array<number> }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/spc/resourceItem/delete",
			method: "POST",
			data: {
				itemIds: args.itemIds,
			},
		});
		return response as void;
	}
}
