import {
	ControlChartSelectionApi,
	ControlChartSelectionApi_Selection,
	getControlChartSelectionApi_ControlChartGroupOrResourceLabels,
} from "src/api/generated/spc/controlChart/api/controlChartSelectionApi";
import { useForm } from "react-hook-form";
import { FormLazySelectField } from "src/components/common/forms/fields/FormLazySelectField";
import { AsyncFetchRender } from "src/components/common/async/AsyncFetchRender.tsx";
import i18n from "i18next";
import { requireRule } from "src/components/common/forms/validation.ts";
import { FormEnumSelectField } from "../../../common/forms/fields/FormEnumSelectField.tsx";

export interface ControlChartSelectionFormProps {
	currentControlChartId: number | undefined;
	onChange: (controlChartId: number | undefined) => void;
}

export const ControlChartSelectionForm = ({
	currentControlChartId,
	onChange,
}: ControlChartSelectionFormProps) => {
	return (
		<AsyncFetchRender
			noContainer
			fetch={async () =>
				currentControlChartId !== undefined ?
					await ControlChartSelectionApi.getSelectionValuesForControlChart({
						controlChartId: currentControlChartId,
					})
				:	EMPTY_FORM_VALUES
			}
			content={(currentSelection) => (
				<ControlChartSelectionFormContent currentSelection={currentSelection} onChange={onChange} />
			)}
		/>
	);
};

interface ControlChartSelectionFormContentProps {
	currentSelection: ControlChartSelectionApi_Selection;
	onChange: (controlChartId: number | undefined) => void;
}

const ControlChartSelectionFormContent = ({
	onChange,
	currentSelection,
}: ControlChartSelectionFormContentProps) => {
	const { control, setValue, watch } = useForm<ControlChartSelectionApi_Selection>({
		mode: "all",
		defaultValues: currentSelection,
	});

	const chartGroupOrResource = watch("chartGroupOrResource") ?? undefined;
	const chartParentGroupId = watch("chartParentGroupId") ?? undefined;
	const chartGroupId = watch("chartGroupId") ?? undefined;
	const resourceId = watch("resourceId") ?? undefined;
	const resourceItemId = watch("resourceItemId") ?? undefined;

	return (
		<>
			<FormEnumSelectField
				control={control}
				name={"chartGroupOrResource"}
				label={i18n.t("from_control_chart_group_or_resource")}
				options={getControlChartSelectionApi_ControlChartGroupOrResourceLabels()}
				onChange={() => {
					setValue("chartParentGroupId", null);
					setValue("chartGroupId", null);
					setValue("resourceId", null);
					setValue("resourceItemId", null);
					setValue("controlChartId", null);
				}}
			/>
			{chartGroupOrResource === "CONTROL_CHART_GROUP" && (
				<FormLazySelectField
					control={control}
					name={"chartParentGroupId"}
					label={i18n.t("chart_parent_group")}
					fetchOptions={() => ControlChartSelectionApi.getControlChartParentGroups({})}
					getOptionKey={(o) => o.id}
					getOptionLabel={(o) => o.name}
					rules={requireRule()}
					onChange={() => {
						setValue("chartGroupId", null);
						setValue("controlChartId", null);
					}}
				/>
			)}
			{chartParentGroupId !== undefined && (
				<FormLazySelectField
					control={control}
					name={"chartGroupId"}
					label={i18n.t("chart_group")}
					fetchOptions={() =>
						ControlChartSelectionApi.getControlChartGroups({
							parentGroupId: chartParentGroupId,
						})
					}
					getOptionKey={(o) => o.chartGroupId}
					getOptionLabel={(o) => o.name}
					rules={requireRule()}
					onChange={() => {
						setValue("controlChartId", null);
					}}
				/>
			)}
			{chartGroupOrResource === "RESOURCE" && (
				<FormLazySelectField
					control={control}
					name={"resourceId"}
					label={i18n.t("resource")}
					fetchOptions={() => ControlChartSelectionApi.getResources()}
					getOptionKey={(o) => o.id}
					getOptionLabel={(o) => o.name}
					rules={requireRule()}
					onChange={() => {
						setValue("resourceItemId", null);
						setValue("controlChartId", null);
					}}
				/>
			)}
			{resourceId !== undefined && (
				<FormLazySelectField
					control={control}
					name={"resourceItemId"}
					label={i18n.t("resource_item")}
					fetchOptions={() => ControlChartSelectionApi.getResourceItems({ resourceId: resourceId })}
					getOptionKey={(o) => o.id}
					getOptionLabel={(o) => o.name}
					rules={requireRule()}
					onChange={() => {
						setValue("controlChartId", null);
					}}
				/>
			)}
			{(chartGroupId !== undefined || resourceItemId !== undefined) && (
				<FormLazySelectField
					control={control}
					name={"controlChartId"}
					label={i18n.t("control_chart")}
					fetchOptions={() => {
						if (chartGroupId !== undefined)
							return ControlChartSelectionApi.getControlChartsInChartGroup({
								chartGroupId: chartGroupId,
							});
						else
							return ControlChartSelectionApi.getControlChartsForResourceItem({
								resourceItemId: resourceItemId!,
							});
					}}
					getOptionKey={(o) => o.id}
					getOptionLabel={(o) => o.name}
					rules={requireRule()}
					onChange={(controlChartId) => onChange(controlChartId ?? undefined)}
				/>
			)}
		</>
	);
};

const EMPTY_FORM_VALUES: ControlChartSelectionApi_Selection = {
	chartGroupOrResource: "CONTROL_CHART_GROUP",
	chartParentGroupId: undefined,
	chartGroupId: undefined,
	resourceId: undefined,
	resourceItemId: undefined,
	controlChartId: undefined,
};
