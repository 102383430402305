// This file is automatically generated. Do not edit manually.

import { t } from "i18next";

export const SurveyFormFieldTypeValues = [
	"TEXT",
	"TEXTAREA",
	"INTEGER",
	"DECIMAL",
	"CHECKBOX",
	"DATE",
	"DATETIME",
	"SELECTION",
	"DOCUMENT",
	"SECTION_BREAK",
] as const;

export type SurveyFormFieldType = (typeof SurveyFormFieldTypeValues)[number];

export const getSurveyFormFieldTypeLabels = () => ({
	TEXT: t("text"),
	TEXTAREA: t("textarea"),
	INTEGER: t("integer"),
	DECIMAL: t("decimal"),
	CHECKBOX: t("checkbox"),
	DATE: t("date"),
	DATETIME: t("datetime"),
	SELECTION: t("selection"),
	DOCUMENT: t("document"),
	SECTION_BREAK: t("section_break"),
});

export const getSurveyFormFieldTypeLabel = (value: SurveyFormFieldType): string => {
	return getSurveyFormFieldTypeLabels()[value];
};
