// This file is automatically generated. Do not edit manually.

import { FrontendDownloadFileHandle } from "src/api/generated/common/frontendDownloadFileHandle";
import { IsoDateString } from "src/types/dateTime";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace CustomerOrderOfferActionsApi {
	export async function createCustomerOrderFromOffer(args: {
		customerOrderOfferId: number;
		copyOfferDocuments: boolean;
		copyOfferLineDocuments: boolean;
		copyPrintoutText: boolean;
		customerOrderTypeId: number;
	}): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/customerOrderOffer/createCustomerOrderFromOffer",
			method: "POST",
			data: {
				customerOrderOfferId: args.customerOrderOfferId,
				copyOfferDocuments: args.copyOfferDocuments,
				copyOfferLineDocuments: args.copyOfferLineDocuments,
				copyPrintoutText: args.copyPrintoutText,
				customerOrderTypeId: args.customerOrderTypeId,
			},
		});
		return response as void;
	}

	export async function printCustomerOrderOffer(args: {
		customerOrderOfferId: number;
	}): Promise<FrontendDownloadFileHandle> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/customerOrderOffer/printCustomerOrderOffer",
			method: "POST",
			data: {
				customerOrderOfferId: args.customerOrderOfferId,
			},
		});
		return response as FrontendDownloadFileHandle;
	}

	export async function updateOfferLinePlannedDeliveryDates(args: {
		customerOrderOfferLineIds: Array<number>;
		newPlannedDeliveryDate: IsoDateString;
	}): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/customerOrderOffer/updateOfferLinePlannedDeliveryDates",
			method: "POST",
			data: {
				customerOrderOfferLineIds: args.customerOrderOfferLineIds,
				newPlannedDeliveryDate: args.newPlannedDeliveryDate,
			},
		});
		return response as void;
	}

	export async function releaseCustomerOrderOffer(args: { customerOrderOfferId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/customerOrderOffer/releaseCustomerOrderOffer",
			method: "POST",
			data: {
				customerOrderOfferId: args.customerOrderOfferId,
			},
		});
		return response as void;
	}

	export async function cancelCustomerOrderOffer(args: { customerOrderOfferId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/customerOrderOffer/cancelCustomerOrderOffer",
			method: "POST",
			data: {
				customerOrderOfferId: args.customerOrderOfferId,
			},
		});
		return response as void;
	}

	export async function discardCustomerOrderOffer(args: { customerOrderOfferId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/customerOrderOffer/discardCustomerOrderOffer",
			method: "POST",
			data: {
				customerOrderOfferId: args.customerOrderOfferId,
			},
		});
		return response as void;
	}

	export async function markCustomerOrderOfferToLost(args: {
		customerOrderOfferId: number;
		loseReason: string;
	}): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/customerOrderOffer/markCustomerOrderOfferToLost",
			method: "POST",
			data: {
				customerOrderOfferId: args.customerOrderOfferId,
				loseReason: args.loseReason,
			},
		});
		return response as void;
	}
}
