// This file is automatically generated. Do not edit manually.

import { TransportRoute } from "src/api/generated/erp/db/types/tables/transportRoute";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace TransportRouteApi {
	export async function getAllTransportRoutes(): Promise<Array<TransportRoute>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/transportRoutes/getAllTransportRoutes",
			method: "POST",
			data: {},
		});
		return response as Array<TransportRoute>;
	}
}
