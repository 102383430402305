// This file is automatically generated. Do not edit manually.

import { PartConfigurationBomLineView } from "src/api/generated/erp/db/types/tables/partConfigurationBomLineView";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace PartConfigurationBomPricesApi {
	export async function getPartCategoryPrices(args: {
		partConfigurationId: number;
	}): Promise<Array<PartConfigurationBomPricesApi_PartCategoryPricesDto>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/configurator/configuration/bom/prices/getPartCategoryPrices",
			method: "POST",
			data: {
				partConfigurationId: args.partConfigurationId,
			},
		});
		return response as Array<PartConfigurationBomPricesApi_PartCategoryPricesDto>;
	}

	export async function getBomLinesInPartCategory(args: {
		partConfigurationId: number;
		salesPartCategoryId: number | null | undefined;
	}): Promise<Array<PartConfigurationBomLineView>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/configurator/configuration/bom/prices/getBomLinesInPartCategory",
			method: "POST",
			data: {
				partConfigurationId: args.partConfigurationId,
				salesPartCategoryId: args.salesPartCategoryId,
			},
		});
		return response as Array<PartConfigurationBomLineView>;
	}
}

export interface PartConfigurationBomPricesApi_PartCategoryPricesDto {
	salesPartCategoryId: number | null | undefined;
	salesPartCategoryName: string | null | undefined;
	totalCost: number;
	totalPrice: number;
}
