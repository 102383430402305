import {ShopOrderActionApi} from "src/api/generated/erp/production/api/shopOrder/shopOrderActionApi.ts";
import {ShowConfirmDialogFunc} from "src/components/common/dialogs/confirmDialog/ConfirmDialogProvider.tsx";
import {confirmCloseShopOrder} from "./confirmCloseShopOrder.ts";

export async function closeShopOrder({
                                         shopOrderId,
                                         showConfirmDialog,
                                     }: {
    shopOrderId: number;
    showConfirmDialog: ShowConfirmDialogFunc;
}) {

    const confirmed = await confirmCloseShopOrder({shopOrderId, showConfirmDialog});
    if (!confirmed) return;

    await ShopOrderActionApi.closeShopOrder({
        shopOrderId: shopOrderId,
    });
}


