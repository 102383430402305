// This file is automatically generated. Do not edit manually.

import { ShopOrderLinePickingObjectLocationView } from "src/api/generated/erp/db/types/tables/shopOrderLinePickingObjectLocationView";
import { ShopOrderOperationView } from "src/api/generated/erp/db/types/tables/shopOrderOperationView";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace ShopOrderBomPickingDataGridApi {
	export async function getShopOrderPickableBom(args: {
		shopOrderId: number;
		shopOrderOperationId: number | null | undefined;
	}): Promise<ShopOrderBomPickingDataGridApi_DataGridData> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/shopOrderBomPickingDataGrid/getShopOrderPickableBom",
			method: "POST",
			data: {
				shopOrderId: args.shopOrderId,
				shopOrderOperationId: args.shopOrderOperationId,
			},
		});
		return response as ShopOrderBomPickingDataGridApi_DataGridData;
	}

	export async function getShopOrderPickedBom(args: {
		shopOrderId: number;
		shopOrderOperationId: number | null | undefined;
	}): Promise<ShopOrderBomPickingDataGridApi_DataGridData> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/shopOrderBomPickingDataGrid/getShopOrderPickedBom",
			method: "POST",
			data: {
				shopOrderId: args.shopOrderId,
				shopOrderOperationId: args.shopOrderOperationId,
			},
		});
		return response as ShopOrderBomPickingDataGridApi_DataGridData;
	}

	export async function pickShopOrderBom(args: {
		pickingObjects: Array<ShopOrderLinePickingObjectLocationView>;
	}): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/shopOrderBomPickingDataGrid/pickShopOrderBom",
			method: "POST",
			data: {
				pickingObjects: args.pickingObjects,
			},
		});
		return response as void;
	}

	export async function revertPickShopOrderBom(args: {
		pickingObjects: Array<ShopOrderLinePickingObjectLocationView>;
	}): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/shopOrderBomPickingDataGrid/revertPickShopOrderBom",
			method: "POST",
			data: {
				pickingObjects: args.pickingObjects,
			},
		});
		return response as void;
	}
}

export interface ShopOrderBomPickingDataGridApi_DataGridData {
	operationOptions: Array<ShopOrderOperationView>;
	shopOrderLinePickingLocations: Array<ShopOrderLinePickingObjectLocationView>;
}
