// This file is automatically generated. Do not edit manually.

import { ServerSideDataModelRequest } from "src/api/generated/common/dataGrids/serverSideDataModelRequest";
import { ServerSideDataModelResult } from "src/api/generated/common/dataGrids/serverSideDataModelResult";
import { MellanoComponentBatch } from "src/api/generated/tenants/mellano/models/mellanoComponentBatch";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace MellanoComponentProductionStatusViewApi {
	export async function getOrders(args: {
		orderNumFilter: string | null | undefined;
		dataModelRequest: ServerSideDataModelRequest;
	}): Promise<ServerSideDataModelResult<MellanoComponentProductionStatusViewApi_Order>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/mellano/componentProduction/getOrders",
			method: "POST",
			data: {
				orderNumFilter: args.orderNumFilter,
				dataModelRequest: args.dataModelRequest,
			},
		});
		return response as ServerSideDataModelResult<MellanoComponentProductionStatusViewApi_Order>;
	}

	export async function getOrderComponentBatches(args: {
		orderNumber: number;
	}): Promise<Array<MellanoComponentBatch>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/mellano/componentProduction/getOrderComponentBatches",
			method: "POST",
			data: {
				orderNumber: args.orderNumber,
			},
		});
		return response as Array<MellanoComponentBatch>;
	}
}

export interface MellanoComponentProductionStatusViewApi_Order {
	completed: boolean;
	componentBatchCount: number;
	orderNum: number;
}
