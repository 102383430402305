// This file is automatically generated. Do not edit manually.

import { MellanoTransaction } from "src/api/generated/tenants/mellano/models/mellanoTransaction";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace MellanoTransactionStatusViewApi {
	export async function getTransactions(args: { transactionNumber: number }): Promise<Array<MellanoTransaction>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/mellano/transactionStatus/getTransactions",
			method: "POST",
			data: {
				transactionNumber: args.transactionNumber,
			},
		});
		return response as Array<MellanoTransaction>;
	}

	export async function getTransactionRows(args: {
		transactionId: number;
	}): Promise<Array<MellanoTransactionStatusViewApi_TransactionRow>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/mellano/transactionStatus/getTransactionsRows",
			method: "POST",
			data: {
				transactionId: args.transactionId,
			},
		});
		return response as Array<MellanoTransactionStatusViewApi_TransactionRow>;
	}

	export async function getComponentBatches(args: {
		transactionId: number;
	}): Promise<Array<MellanoTransactionStatusViewApi_ComponentBatch>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/mellano/transactionStatus/getComponentBatches",
			method: "POST",
			data: {
				transactionId: args.transactionId,
			},
		});
		return response as Array<MellanoTransactionStatusViewApi_ComponentBatch>;
	}

	export async function getComponentBatchContent(args: {
		transactionId: number;
		workOrderCode: string;
	}): Promise<Array<MellanoTransactionStatusViewApi_MellanoComponentBatchContent>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/mellano/transactionStatus/getComponentBatchContent",
			method: "POST",
			data: {
				transactionId: args.transactionId,
				workOrderCode: args.workOrderCode,
			},
		});
		return response as Array<MellanoTransactionStatusViewApi_MellanoComponentBatchContent>;
	}
}

export interface MellanoTransactionStatusViewApi_TransactionRow {
	code: string;
	color: string;
	material: string;
	name: string;
	quantity: number;
	transactionRowId: number;
}

export interface MellanoTransactionStatusViewApi_ComponentBatch {
	firstComponentState: string;
	transactionId: number;
	workFlowGroup: string;
	workOrderCode: string;
}

export interface MellanoTransactionStatusViewApi_MellanoComponentBatchContent {
	color: string | null | undefined;
	componentCode: string;
	componentId: number;
	componentName: string;
	componentState: string;
	dimensionX: number | null | undefined;
	dimensionY: number | null | undefined;
	dimensionZ: number | null | undefined;
	packageId: number | null | undefined;
	productionInfo: string;
	transactionId: number;
	workOrderCode: string;
	workOrderRowNumber: number;
}
