import {ControlChartGraphDto} from "src/api/generated/spc/graphs/controlChartGraphDto.ts";
import {AavoECharts} from "src/components/common/echarts/AavoECharts.tsx";
import {useTheme} from "@mui/material";
import {formatIsoString} from "src/utils/dayjsUtils.ts";
import i18n from "i18next";
import {isNullOrBlank, nullIfBlank} from "src/utils/strings.tsx";
import {ControlChartGraphRecordDto} from "src/api/generated/spc/graphs/controlChartGraphRecordDto.ts";
import {getSpecialCauseDescriptionForCode} from "src/components/views/spc/specialCauseUtils.ts";
import {formatNumber} from "src/utils/numberUtils.ts";
import {ECElementEvent, LineSeriesOption} from "echarts";
import {OpenContextMenuFunc} from "src/components/common/contextMenu/useContextMenu.ts";
import {useCallback} from "react";
import {
	ControlChartGraphDataPointContextMenu
} from "src/components/views/spc/controlChart/controlChartGraph/ControlChartGraphDataPointContextMenu.tsx";
import dataPointSymbol from "./symbols/dataPoint.svg";
import dataPointWithSpecialCauseSymbol from "./symbols/dataPointWithSpecialCause.svg";
import {logError} from "src/errorHandling/errorLogging.ts";
import {filterNulls} from "src/utils/arrayUtils.ts";

export interface ControlChartSingleGraphProps {
	controlCharts: ControlChartGraphDto[];
	valueDescription: string;
	xDescription: string;
	yDescription: string;
	getRecordValue: (record: ControlChartGraphRecordDto) => number;
	getAverage: (record: ControlChartGraphRecordDto) => number | null | undefined;
	getLcl: (record: ControlChartGraphRecordDto) => number | null | undefined;
	getUcl: (record: ControlChartGraphRecordDto) => number | null | undefined;
	filterSpecialCauses: (scCode: number) => boolean;
	openContextMenu: OpenContextMenuFunc;
	refreshData: () => Promise<unknown>;
	drawSpecificationLimits: boolean;
}

/**
 * Represents a single control chart graph, e.g. I-MR chart will have two of these.
 * Single graph can be drawn for multiple control charts, in which case the data points are combined horizontally.
 * Specification limits are drawn only if there is exactly one control chart.
 */
export const ControlChartSingleGraph = ({
	controlCharts,
	xDescription,
	yDescription,
	valueDescription,
	getRecordValue,
	getAverage,
	getLcl,
	getUcl,
	filterSpecialCauses,
	openContextMenu,
	refreshData,
	drawSpecificationLimits,
}: ControlChartSingleGraphProps) => {
	const { palette } = useTheme();

	const dataPointColor = palette.info.main;
	const specialCauseColor = palette.error.light;

	const allRecords = controlCharts.flatMap((cc) => cc.records);
	const [yMin, yMax] = getYAxisLimits();

	const onContextMenu = useCallback(
		({ componentType, componentSubType, event, data }: ECElementEvent) => {
			if (
				componentType === "series" &&
				componentSubType === "line" &&
				event != null &&
				"clientX" in event.event
			) {
				const record = (data as any).recordData as ControlChartGraphRecordDto;
				if (record === undefined) {
					logError("Record data not found for data point", JSON.stringify(data));
					return;
				}
				openContextMenu({
					mouseEvent: event.event,
					content: (
						<ControlChartGraphDataPointContextMenu record={record} refreshGraph={refreshData} />
					),
				});
			}
		},
		[openContextMenu, refreshData],
	);

	return (
		<AavoECharts
			sx={{
				flex: 1,
				border: "1px solid",
				borderColor: palette.grey[500],
				borderRadius: 1,
			}}
			onEvents={{
				contextmenu: onContextMenu,
			}}
			option={{
				animation: false,
				legend: {
					show: false,
				},
				grid: {
					containLabel: true,
					left: isNullOrBlank(yDescription) ? 15 : 30,
					right: 15,
					top: 20,
					bottom: isNullOrBlank(xDescription) ? 15 : 40,
				},
				xAxis: {
					show: true,
					name: xDescription,
					nameLocation: "center",
					min: 0,
					max: allRecords.length,
					axisLabel: {
						show: false,
					},
					axisTick: {
						show: false,
					},
					splitLine: {
						show: false,
					},
				},
				yAxis: {
					min: yMin,
					max: yMax,
					name: yDescription,
					nameLocation: "center",
					nameGap: 27,
				},
				series: createAllSeries(),
				tooltip: {},
			}}
		/>
	);

	function createAllSeries() {
		interface ReduceAcc {
			currentXIndex: number;
			seriesList: LineSeriesOption[];
		}

		return controlCharts.reduce<ReduceAcc>(
			(acc, cc) => {
				const controlChartSeries = createAllSeriesForControlChart(cc, acc.currentXIndex);
				return {
					currentXIndex: acc.currentXIndex + cc.records.length,
					seriesList: [...acc.seriesList, ...controlChartSeries],
				};
			},
			{ currentXIndex: 0, seriesList: [] },
		).seriesList;
	}

	function createAllSeriesForControlChart(
		controlChart: ControlChartGraphDto,
		startIndex: number,
	): LineSeriesOption[] {
		return [
			createDataSeriesForControlChart(controlChart, startIndex),
			createLimitSeries({
				controlChart,
				startIndex,
				name: "X̄",
				getValue: (r) => getAverage(r),
				color: palette.success.light,
			}),
			createLimitSeries({
				controlChart,
				startIndex,
				name: i18n.t("lcl"),
				getValue: (r) => getLcl(r),
				color: specialCauseColor,
			}),
			createLimitSeries({
				controlChart,
				startIndex,
				name: i18n.t("ucl"),
				getValue: (r) => getUcl(r),
				color: specialCauseColor,
			}),
			...(drawSpecificationLimits ?
				filterNulls([
					createSpecLimitSeries({
						controlChart,
						startIndex,
						name: "LSL",
						value: controlChart.lsl,
					}),
					createSpecLimitSeries({
						controlChart,
						startIndex,
						name: "USL",
						value: controlChart.usl,
					}),
				])
			:	[]),
		];
	}

	function createDataSeriesForControlChart(
		controlChart: ControlChartGraphDto,
		startIndex: number,
	): LineSeriesOption {
		return {
			name: i18n.t("data.spc"),
			type: "line",
			color: dataPointColor,
			data: controlChart.records.map((r, idx) => getDataSeriesPoint(controlChart, r, startIndex + idx)),
			symbolSize: 28,
			markLine:
				controlCharts.length === 1 ?
					undefined
				:	{
						symbol: "none",
						silent: true,
						data: [
							// Show vertical line between control charts
							{
								xAxis: startIndex,
								label: {
									show: false,
								},
							},
							// A bit hacky way to show control chart name in the middle of the chart (hidden mark line)
							{
								xAxis: startIndex + controlChart.records.length / 2,
								label: {
									formatter: controlChart.name,
								},
								lineStyle: {
									width: 0,
								},
							},
						],
					},
		};
	}

	function getDataSeriesPoint(
		controlChart: ControlChartGraphDto,
		record: ControlChartGraphRecordDto,
		idx: number,
	): any {
		const hasSpecialCause = record.specialCauseCodes.filter(filterSpecialCauses).length > 0;
		// These SVG symbols has small circle with larger transparent area for tooltip and context menu.
		const symbolUrl = hasSpecialCause ? dataPointWithSpecialCauseSymbol : dataPointSymbol;
		return {
			value: [idx + 0.5, getRecordValue(record)],
			tooltip: {
				formatter: getDataPointTooltip(controlChart, record),
			},
			label: {
				show: nullIfBlank(record.eventOcs) != null,
				fontWeight: "bold",
				formatter: record.eventOcs,
				position: [0, -10],
			},
			symbol: `image://${symbolUrl}`,
			recordData: record,
		};
	}

	function getDataPointTooltip(controlChart: ControlChartGraphDto, record: ControlChartGraphRecordDto) {
		const lines = [
			{
				label: i18n.t("recorded_at"),
				value: formatIsoString(record.observationTime, "L LT"),
			},
			record.startTime != null && {
				label: i18n.t("start_time"),
				value: formatIsoString(record.startTime, "L LT"),
			},
			record.stopTime != null && {
				label: i18n.t("end_time"),
				value: formatIsoString(record.stopTime, "L LT"),
			},
			nullIfBlank(record.info1) != null && {
				label: controlChart.info1description ?? i18n.t("info1"),
				value: record.info1,
			},
			nullIfBlank(record.info2) != null && {
				label: controlChart.info2description ?? i18n.t("info2"),
				value: record.info2,
			},
			{
				label: valueDescription,
				value: record.primaryValue,
			},
			...record.specialCauseCodes
				.filter(filterSpecialCauses)
				.map((scCode) => getSpecialCauseDescriptionForCode(scCode))
				.map((sc) => ({ label: null, value: sc })),
		];
		return lines
			.reduce((acc, item) => {
				if (typeof item === "boolean") return acc;

				const { label, value } = item;
				const lineStr = label == null ? value : `<strong>${label}</strong>: ${value}`;
				return [...acc, lineStr];
			}, Array<string>())
			.join("<br/>");
	}

	function createLimitSeries({
		controlChart,
		startIndex,
		name,
		color,
		getValue,
	}: {
		controlChart: ControlChartGraphDto;
		startIndex: number;
		name: string;
		color: string;
		getValue: (record: ControlChartGraphRecordDto) => number | null | undefined;
	}): LineSeriesOption {
		return {
			name: name,
			type: "line",
			color: color,
			// Fake data is required to ensure that the markLine is drawn.
			data: [[0, 0]],
			showSymbol: false,
			markLine: {
				symbol: "none",
				lineStyle: {
					type: "solid",
					width: 2,
				},
				emphasis: {
					disabled: true,
				},
				label: {
					show: false,
				},
				data: controlChart.records.map((r, idx) => {
					const value = getValue(r);
					if (value == null) return [{}, {}];

					const valueFormatted = formatNumber(value);
					return [
						{
							coord: [startIndex + idx, value],
							value: valueFormatted,
						},
						{
							coord: [startIndex + idx + 1, value],
							value: valueFormatted,
						},
					];
				}),
			},
		};
	}

	function createSpecLimitSeries({
		controlChart,
		startIndex,
		name,
		value,
	}: {
		controlChart: ControlChartGraphDto;
		startIndex: number;
		name: string;
		value: number | null | undefined;
	}): LineSeriesOption | null {
		if (value == null || !controlChart.drawSpecificationLimits) return null;

		const recordCount = controlChart.records.length;
		const valueFormatted = formatNumber(value);
		return {
			name: name,
			type: "line",
			color: palette.yellow.main,
			data: [[0, 0]],
			showSymbol: false,
			markLine: {
				symbol: "none",
				lineStyle: {
					type: "solid",
					width: 2,
				},
				data: [
					[
						{
							coord: [startIndex, value],
							value: valueFormatted,
						},
						{
							coord: [startIndex + recordCount, value],
							value: valueFormatted,
							label: {
								show: false,
							},
						},
					],
				],
			},
		};
	}

	function getYAxisLimits() {
		const getAllYValuesOfRecord = (rec: ControlChartGraphRecordDto) => [
			getRecordValue(rec),
			getLcl(rec),
			getUcl(rec),
		];

		const allYValues = controlCharts.flatMap((cc) =>
			filterNulls([cc.lsl, cc.usl, ...cc.records.flatMap((r) => getAllYValuesOfRecord(r))]),
		);
		if (allYValues.length === 0) {
			return [0, 1];
		}

		const yMin = Math.floor(Math.min(...allYValues));
		const yMax = Math.ceil(Math.max(...allYValues));

		return [yMin, yMax];
	}
};
