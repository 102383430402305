import {
	CustomerOrderOrOfferSalesCommissionView,
	CustomerOrderOrOfferSalesCommissionViewProps,
} from "src/components/views/erp/sales/salesCommissions/orderAndOfferViews/CustomerOrderOfOfferSalesCommissionView.tsx";
import { CustomerOrderOfferView } from "src/api/generated/erp/db/types/tables/customerOrderOfferView.ts";

export interface CustomerOfferSalesCommissionViewProps
	extends Omit<CustomerOrderOrOfferSalesCommissionViewProps, "sourceType" | "editingEnabled"> {
	customerOffer: CustomerOrderOfferView;
}

export const CustomerOfferSalesCommissionView = ({
	customerOffer,
	...other
}: CustomerOfferSalesCommissionViewProps) => {
	return (
		<CustomerOrderOrOfferSalesCommissionView
			{...other}
			sourceType={"CUSTOMER_OFFER"}
			editingEnabled={!customerOffer.isClosed}
		/>
	);
};
