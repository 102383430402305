import { Typography } from "@mui/material";
import React, { forwardRef } from "react";
import { TypographyProps } from "@mui/material";
import { mergeSx } from "src/utils/styles";

export const ClickableTypography = forwardRef(
	({ sx, ...other }: TypographyProps, ref: React.Ref<HTMLSpanElement>) => {
		return (
			<Typography
				ref={ref}
				sx={mergeSx(
					{
						"&:hover": {
							color: "grey.500",
							cursor: "pointer",
						},
					},
					sx,
				)}
				{...other}
			/>
		);
	},
);
