import { SitePartReorderEventApi } from "src/api/generated/erp/parts/sitePart/api/sitePartReorderEventApi";
import { useServerSideDataGridModel } from "src/components/common/dataGrid/gridModel/useServerSideDataGridModel";
import { ControlledAsyncDataGrid } from "src/components/common/dataGrid/ControlledAsyncDataGrid.tsx";
import { dateTimeColumn, floatColumn } from "src/components/common/dataGrid/columns.tsx";
import i18n from "i18next";
import { PartReorderEvent } from "src/api/generated/erp/db/types/tables/partReorderEvent.ts";

export interface SitePartReorderEventsDataGridProps {
	sitePartId: number;
}

export const SitePartReorderEventsDataGrid = ({
	sitePartId,
}: SitePartReorderEventsDataGridProps) => {
	const { dataGridProps } = useServerSideDataGridModel({
		fetchData: ({ dataModelRequest }) =>
			SitePartReorderEventApi.getPartReorderEvents({
				sitePartId,
				dataModelRequest,
			}),

		initialParams: {},
		getRowId: (row) => row.partReorderEventId,
		gridId: "C93FDACC5BFE093",
	});

	return (
		<ControlledAsyncDataGrid<PartReorderEvent>
			columns={[
				dateTimeColumn({
					field: "createdAt",
					headerName: i18n.t("created_at"),
					width: 150
				}),
				floatColumn({
					field: "reorderPoint",
					headerName: i18n.t("reorder_point"),
					width: 150
				}),
				floatColumn({
					field: "batchSize",
					headerName: i18n.t("batch_size"),
					width: 150
				}),
				floatColumn({
					field: "inventoryLevel",
					headerName: i18n.t("inventory_level"),
					width: 100
				}),
				floatColumn({
					field: "incompleteShopOrderQuantity",
					headerName: i18n.t("shop_order_quantity"),
					width: 200
				}),
				floatColumn({
					field: "incompletePurchaseOrderQuantity",
					headerName: i18n.t("purchase_order_quantity"),
					width: 200
				}),
				floatColumn({
					field: "incompletePurchaseRequestQuantity",
					headerName: i18n.t("purchase_request_quantity"),
					width: 200
				}),
				floatColumn({
					field: "totalQuantity",
					headerName: i18n.t("total_quantity"),
					width: 150
				}),
			]}
			{...dataGridProps}
		/>
	);
};
