import { PurchaseOrderReceptionApi } from "src/api/generated/erp/purchase/purchaseOrder/api/purchaseOrderReceptionApi.ts";
import { PurchaseOrderReceipt } from "src/api/generated/erp/db/types/tables/purchaseOrderReceipt.ts";
import {
	dateColumn,
	enumColumn,
	integerColumn,
	textColumn,
} from "src/components/common/dataGrid/columns.tsx";
import i18n from "i18next";
import { getPurchaseOrderReceiptStateLabels } from "src/api/generated/erp/db/types/enums/purchaseOrderReceiptState.ts";
import { useClientSideDataGridModel } from "src/components/common/dataGrid/gridModel/useClientSideDataGridModel";
import { nullableAavoObjectRef } from "src/utils/aavoObjectRefUtils.ts";
import { DocumentsOfObjectButton } from "src/components/views/documents/objectDocuments/DocumentsOfObjectButton.tsx";
import { PurchaseOrderReceiptForm } from "src/components/views/erp/purchase/reception/PurchaseOrderReceiptForm.tsx";
import { ControlledAsyncCrudDataGrid } from "src/components/common/dataGrid/crud/ControlledAsyncCrudDataGrid.tsx";
import { faCheck, faExternalLinkAlt, faUndo } from "@fortawesome/pro-regular-svg-icons";
import { AsyncButton } from "src/components/common/buttons/AsyncButton.tsx";
import { useGenericDialog } from "src/components/common/dialogs/useGenericDialog.ts";
import { PurchaseOrderReceptionView } from "src/components/views/erp/purchase/reception/PurchaseOrderReceptionView.tsx";

export interface PurchaseOrderReceiptsDataGridProps {
	purchaseOrderId: number;
	onSelectionChanged?: (selection: PurchaseOrderReceipt | undefined) => void;
	selectFirstRowOnLoad?: boolean;
	editEnabled?: boolean;
	showOpenReceptionButton?: boolean;
}

export const PurchaseOrderReceiptsDataGrid = ({
	purchaseOrderId,
	onSelectionChanged,
	selectFirstRowOnLoad = false,
	editEnabled = false,
	showOpenReceptionButton = false,
}: PurchaseOrderReceiptsDataGridProps) => {
	const { openDialog } = useGenericDialog();

	const { dataGridProps, onlySelectedRow, refreshData } = useClientSideDataGridModel({
		fetchData: () => PurchaseOrderReceptionApi.getPurchaseOrderReceipts({ purchaseOrderId }),
		getRowId: (row) => row.purchaseOrderReceiptId,
		gridId: "752688618A17A960",
		initialParams: {},
		onSelectionChanged: (selection) => {
			onSelectionChanged?.(selection[0]);
		},
		selectFirstRowOnLoad: selectFirstRowOnLoad,
	});

	return (
		<ControlledAsyncCrudDataGrid<PurchaseOrderReceipt>
			columns={[
				enumColumn({
					field: "purchaseOrderReceiptState",
					headerName: i18n.t("state"),
					enumLabels: getPurchaseOrderReceiptStateLabels(),
					width: 120,
				}),
				dateColumn({
					field: "createdDate",
					headerName: i18n.t("created_at"),
					width: 120,
				}),
				textColumn({
					field: "createdBy",
					headerName: i18n.t("created_by"),
					width: 150,
				}),
				dateColumn({
					field: "receiptedDate",
					headerName: i18n.t("receipted_at"),
					width: 120,
				}),
				textColumn({
					field: "note",
					headerName: i18n.t("note"),
					width: 300,
				}),
				integerColumn({
					field: "purchaseOrderReceiptId",
					headerName: i18n.t("id"),
				}),
			]}
			actionBarComponents={
				<>
					<DocumentsOfObjectButton
						objectRef={nullableAavoObjectRef(
							"PURCHASE_ORDER_RECEIPT",
							onlySelectedRow?.purchaseOrderReceiptId,
						)}
					/>
					{showOpenReceptionButton && (
						<AsyncButton
							label={i18n.t("open_reception")}
							icon={faExternalLinkAlt}
							variant={"outlined"}
							onClick={() => {
								openDialog({
									title: i18n.t("reception"),
									content: <PurchaseOrderReceptionView purchaseOrderId={purchaseOrderId} />,
								});
							}}
						/>
					)}
					{editEnabled && onlySelectedRow?.purchaseOrderReceiptState === "INITIAL" && (
						<AsyncButton
							label={i18n.t("mark_as_completed")}
							icon={faCheck}
							variant={"outlined"}
							onClick={async () => {
								await PurchaseOrderReceptionApi.markPurchaseOrderReceiptReceived({
									purchaseOrderReceiptId: onlySelectedRow.purchaseOrderReceiptId,
								});
								await refreshData();
							}}
						/>
					)}
					{editEnabled && onlySelectedRow?.purchaseOrderReceiptState === "RECEIPTED" && (
						<AsyncButton
							label={i18n.t("return_to_handling")}
							icon={faUndo}
							variant={"outlined"}
							onClick={async () => {
								await PurchaseOrderReceptionApi.revertMarkPurchaseOrderReceiptReceived({
									purchaseOrderReceiptId: onlySelectedRow.purchaseOrderReceiptId,
								});
								await refreshData();
							}}
						/>
					)}
				</>
			}
			form={{
				addRowEnabled: editEnabled,
				editEnabled: editEnabled,
				dialogTitle: i18n.t("receipt"),
				dialogSize: "sm",
				component: ({ row, ...other }) => (
					<PurchaseOrderReceiptForm
						purchaseOrderId={purchaseOrderId}
						purchaseOrderReceipt={row}
						{...other}
					/>
				),
			}}
			remove={
				editEnabled ?
					{
						type: "enabled",
						action: ({ items }) =>
							PurchaseOrderReceptionApi.deleteReceipts({
								purchaseOrderReceiptIds: items.map((item) => item.purchaseOrderReceiptId),
							}),
					}
				:	{ type: "hidden" }
			}
			{...dataGridProps}
		/>
	);
};
