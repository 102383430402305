// This file is automatically generated. Do not edit manually.

import { ServerSideDataModelRequest } from "src/api/generated/common/dataGrids/serverSideDataModelRequest";
import { ServerSideDataModelResult } from "src/api/generated/common/dataGrids/serverSideDataModelResult";
import { ControlChartEventView } from "src/api/generated/postgres/db/types/public_/tables/controlChartEventView";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace ControlChartEventsDataGridApi {
	export async function getGridData(args: {
		controlChartId: number;
		dataModelRequest: ServerSideDataModelRequest;
	}): Promise<ServerSideDataModelResult<ControlChartEventView>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/spc/controlChartEventsDataGrid/getGridData",
			method: "POST",
			data: {
				controlChartId: args.controlChartId,
				dataModelRequest: args.dataModelRequest,
			},
		});
		return response as ServerSideDataModelResult<ControlChartEventView>;
	}
}
