import { AavoDialog, AavoDialogProps } from "src/components/common/dialogs/AavoDialog.tsx";
import { useCallback, useEffect, useRef, useState } from "react";
import React from "react";
import { removeKeyFromObject } from "src/utils/objectUtils";
import { logError } from "src/errorHandling/errorLogging.ts";
import { useConfirmDialog } from "src/components/common/dialogs/confirmDialog/useConfirmDialog.ts";
import { DataDirtyStateChangeHandler } from "src/utils/dataDirtyStateChangeHandler.ts";
import { confirmUnsavedChangesWillBeLost } from "src/components/common/dialogs/confirmDialog/confirmDialogUtils.ts";

export const GenericDialogContext = React.createContext<GenericDialogContextValue | undefined>(undefined);

export interface GenericDialogContextValue {
	openDialog: OpenGenericDialogFunc;
}

export type OpenGenericDialogFunc = (
	propsProvider: OpenGenericDialogFuncProps | OpenGenericDialogFuncPropsProvider,
) => void;

export type OpenGenericDialogFuncPropsProvider = (params: {
	closeDialog: CloseDialogFunc;
	onContentEdited: () => void;
	onDataDirtyStateChanged: DataDirtyStateChangeHandler;
}) => OpenGenericDialogFuncProps;

export type CloseDialogFunc = (params?: CloseDialogParams) => Promise<void>;

export interface CloseDialogParams {
	confirmIfEdited?: boolean;
}

export interface OpenGenericDialogFuncProps
	extends Omit<AavoDialogProps, "onClose" | "children" | "content"> {
	onClose?: () => unknown | Promise<unknown>;
	content: React.ReactNode;
}

const DIALOG_LOCATION_HASH = "#dialog";

export const GenericDialogProvider = ({ children }: React.PropsWithChildren) => {
	const [dialogPropsByIds, setDialogPropsByIds] = useState<Record<string, OpenGenericDialogFuncProps>>({});
	const dialogContentEditedByIds = useRef<Record<string, boolean>>({});
	const dialogStack = useRef<string[]>([]);

	const navigateBackTriggeredOnClose = useRef<boolean>(false);

	const showConfirmDialog = useConfirmDialog();

	const closeDialog = useCallback(
		async (
			dialogId: string,
			params: CloseDialogParams | undefined,
			fromBrowserBackButton: boolean = false,
		) => {
			// Text fields may rely on blur event to save changes.
			if (document.activeElement instanceof HTMLElement) {
				document.activeElement.blur();
			}

			const isEdited = dialogContentEditedByIds.current[dialogId] ?? false;
			const confirmIfEdited = params?.confirmIfEdited ?? false;
			const confirmed =
				!isEdited || !confirmIfEdited || (await confirmUnsavedChangesWillBeLost(showConfirmDialog));
			if (!confirmed) {
				if (fromBrowserBackButton) {
					// Dialog was not close. Compensate back button effect by pushing state back to history.
					window.history.pushState(null, "", "");
				}
				return;
			}

			const dialogProps = dialogPropsByIds[dialogId];
			await dialogProps?.onClose?.();
			setDialogPropsByIds((prev) => removeKeyFromObject(prev, dialogId));
			dialogContentEditedByIds.current = removeKeyFromObject(
				dialogContentEditedByIds.current,
				dialogId,
			);

			dialogStack.current = dialogStack.current.filter((id) => id !== dialogId);

			const location = window.location;
			if (!fromBrowserBackButton && location.hash === DIALOG_LOCATION_HASH) {
				// Mark flag to prevent browser back button from closing next dialog, too.
				navigateBackTriggeredOnClose.current = true;
				// Remove dialog from history, if this close was not triggered by browser back button.
				window.history.back();
				if (dialogStack.current.length === 0) {
					const urlWithoutHash = location.href.replace(location.hash, "");
					window.history.replaceState(null, "", urlWithoutHash);
				}
			}
		},
		[dialogPropsByIds, showConfirmDialog],
	);

	const onDialogDirtyStateChanged = (dialogId: string, isDirty: boolean) => {
		dialogContentEditedByIds.current = {
			...dialogContentEditedByIds.current,
			[dialogId]: isDirty,
		};
	};

	const openDialog: OpenGenericDialogFunc = (propsProvider) => {
		const dialogId = crypto.randomUUID();
		const closeThisDialog = async (closeParams?: CloseDialogParams) => {
			await closeDialog(dialogId, closeParams);
		};
		const onThisDialogDirtyStateChanged = (isDirty: boolean) => {
			onDialogDirtyStateChanged(dialogId, isDirty);
		};
		const newProps =
			typeof propsProvider === "function" ?
				propsProvider({
					closeDialog: closeThisDialog,
					onContentEdited: () => onThisDialogDirtyStateChanged(true),
					onDataDirtyStateChanged: ({ isDirty }) => onThisDialogDirtyStateChanged(isDirty),
				})
			:	propsProvider;

		setDialogPropsByIds((prev) => {
			return {
				...prev,
				[dialogId]: newProps,
			};
		});
		dialogStack.current = [...dialogStack.current, dialogId];
		// Push state to history, so that browser back button can be used to close dialog.
		window.history.pushState(null, "", DIALOG_LOCATION_HASH);
	};

	useEffect(() => {
		const onPopStateListener = () => {
			if (navigateBackTriggeredOnClose.current) {
				// popState was triggered manually, because dialog was closed without browser back button.
				// Do not close another dialog and reset flag.
				navigateBackTriggeredOnClose.current = false;
				return;
			}

			const uppermostDialogId = dialogStack.current[dialogStack.current.length - 1];
			// Close uppermost dialog, if it exists.
			if (uppermostDialogId) {
				closeDialog(uppermostDialogId, { confirmIfEdited: true }, true).catch(logError);
			}
		};
		window.addEventListener("popstate", onPopStateListener);

		return () => {
			window.removeEventListener("popstate", onPopStateListener);
		};
	}, [closeDialog]);

	const contextValue = {
		openDialog: openDialog,
	};

	return (
		<>
			<GenericDialogContext.Provider value={contextValue}>
				{children}
				{Object.entries(dialogPropsByIds).map(([dialogId, { content, onClose, ...other }]) => (
					<AavoDialog
						key={dialogId}
						children={content}
						onClose={async () => {
							const closeParams = {
								confirmIfEdited: true,
							};
							await onClose?.();
							await closeDialog(dialogId, closeParams);
						}}
						{...other}
					/>
				))}
			</GenericDialogContext.Provider>
		</>
	);
};
