import { IdentityId } from "./types";

const IDENTITY_ITEM = "aavo_identity";

export const setSelectedIdentityStorage = (value: IdentityId): IdentityId => {
	if (value !== null) {
		sessionStorage.setItem(IDENTITY_ITEM, value);
	} else {
		sessionStorage.removeItem(IDENTITY_ITEM);
	}
	return value;
};

export const getSelectedIdentityStorage = (): IdentityId => {
	return sessionStorage.getItem(IDENTITY_ITEM);
};
