import { ClientSideDataGridModel } from "src/components/common/dataGrid/gridModel/ClientSideDataGridModel";
import { ProjectActivityDataGridApi } from "src/api/generated/erp/project/projectActivity/api/projectActivityDataGridApi.ts";
import { CrudDataGrid } from "src/components/common/dataGrid/crud/CrudDataGrid";
import { ProjectActivityView } from "src/api/generated/erp/db/types/tables/projectActivityView";
import i18n from "i18next";
import { MenuCheckbox } from "src/components/common/contextMenu/MenuCheckbox.tsx";
import { ProjectActivityForm } from "src/components/views/erp/project/projectActivity/ProjectActivityForm.tsx";
import { ProjectActivityContextMenu } from "src/components/views/erp/project/projectActivity/ProjectActivityContextMenu.tsx";
import { nullableAavoObjectRef } from "src/utils/aavoObjectRefUtils.ts";
import { DocumentsOfObjectButton } from "src/components/views/documents/objectDocuments/DocumentsOfObjectButton.tsx";
import {
	dateColumn,
	dateTimeColumn,
	enumColumn,
	integerColumn,
	textColumn,
} from "src/components/common/dataGrid/columns.tsx";
import { getActivityStateLabels } from "src/api/generated/erp/db/types/enums/activityState.ts";
import { CreateNewProjectActivityForm } from "src/components/views/erp/project/projectActivity/CreateNewProjectActivityForm.tsx";
import { RefreshableElementRef } from "src/utils/useRefreshRef.ts";

export interface ProjectActivitiesDataGridProps {
	subProjectId: number;
	onlyActivityId?: number;
	onSelectionChanged?: (activity: ProjectActivityView | undefined) => void;
	tasksViewRefreshRef: RefreshableElementRef;
}

export const ProjectActivitiesDataGrid = ({
	subProjectId,
	onlyActivityId,
	onSelectionChanged,
	tasksViewRefreshRef,
}: ProjectActivitiesDataGridProps) => {
	return (
		<ClientSideDataGridModel
			fetchData={({ showCancelledActivities }) =>
				ProjectActivityDataGridApi.getDataGridData({
					subProjectId,
					onlyActivityId,
					cancelledActivities: showCancelledActivities,
				})
			}
			initialParams={{
				showCancelledActivities: false,
			}}
			getRows={(data) => data.activities}
			getRowId={(row) => row.activityId}
			gridId={"65D07E5C2175F4E4"}
			selectFirstRowOnLoad={onlyActivityId != null}
			onSelectionChanged={(rows) => onSelectionChanged?.(rows[0])}
			render={({
				dataGridProps,
				data: { subProject },
				currentParams,
				refreshData,
				onlySelectedRow,
			}) => (
				<CrudDataGrid<ProjectActivityView>
					disableMultipleRowSelection
					columns={[
						textColumn({
							field: "activityName",
							headerName: i18n.t("activity"),
							width: 150,
						}),
						textColumn({
							field: "responsiblePersonName",
							headerName: i18n.t("responsible_person"),
						}),
						textColumn({
							field: "resourceName",
							headerName: i18n.t("resource"),
						}),
						dateColumn({
							field: "plannedStartDate",
							headerName: i18n.t("planned_start"),
						}),
						dateColumn({
							field: "plannedEndDate",
							headerName: i18n.t("planned_end"),
						}),
						enumColumn({
							field: "activityState",
							headerName: i18n.t("state"),
							enumLabels: getActivityStateLabels(),
						}),
						textColumn({
							field: "note",
							headerName: i18n.t("note"),
						}),
						dateTimeColumn({
							field: "creationDate",
							headerName: i18n.t("created_at"),
						}),
						textColumn({
							field: "createdByUserName",
							headerName: i18n.t("created_by"),
						}),
						dateTimeColumn({
							field: "releasedDate",
							headerName: i18n.t("released_at"),
						}),
						textColumn({
							field: "releasedByUserName",
							headerName: i18n.t("released_by"),
						}),
						dateTimeColumn({
							field: "actualStartDate",
							headerName: i18n.t("started_at"),
						}),
						textColumn({
							field: "startedByUserName",
							headerName: i18n.t("started_by"),
						}),
						dateTimeColumn({
							field: "actualEndDate",
							headerName: i18n.t("completed_at"),
						}),
						textColumn({
							field: "completedByUserName",
							headerName: i18n.t("completed_by"),
						}),
						dateTimeColumn({
							field: "cancelledDate",
							headerName: i18n.t("cancelled_at"),
						}),
						textColumn({
							field: "cancelledByUserName",
							headerName: i18n.t("cancelled_by"),
						}),
						integerColumn({
							field: "activityId",
							headerName: i18n.t("id"),
						}),
					]}
					actionBarComponents={
						<>
							<DocumentsOfObjectButton
								objectRef={nullableAavoObjectRef(
									"PROJECT_ACTIVITY",
									onlySelectedRow?.activityId,
								)}
							/>
						</>
					}
					actionBarMenuComponents={[
						<MenuCheckbox
							key={"showCancelled"}
							label={i18n.t("show_cancelled")}
							checked={currentParams.showCancelledActivities}
							onChange={(checked) => refreshData({ showCancelledActivities: checked })}
						/>,
					]}
					rowContextMenuComponents={({ row }) => (
						<ProjectActivityContextMenu
							activity={row}
							refreshData={refreshData}
							tasksViewRefreshRef={tasksViewRefreshRef}
						/>
					)}
					form={{
						addRowEnabled: ["INITIAL", "RELEASED"].includes(subProject.subProjectState),
						editEnabled: true,
						dialogTitle: (row) => (row == null ? i18n.t("new_activity") : i18n.t("activity")),
						dialogSize: (row) => (row == null ? "sm" : "md"),
						component: ({ row, closeDialog, ...other }) =>
							row == null ?
								<CreateNewProjectActivityForm
									subProjectId={subProjectId}
									closeDialog={closeDialog}
									{...other}
								/>
							:	<ProjectActivityForm
									subProjectId={subProjectId}
									activityId={row?.activityId ?? null}
									{...other}
								/>,
					}}
					{...dataGridProps}
				/>
			)}
		/>
	);
};
