import { ConfiguratorTable } from "src/api/generated/erp/db/types/tables/configuratorTable.ts";
import i18n from "i18next";
import { ConfiguratorTablesDataGrid } from "src/components/views/erp/configurator/tables/ConfiguratorTablesDataGrid.tsx";
import { AavoMosaic } from "src/components/common/mosaic/AavoMosaic.tsx";
import { CenteredTypography } from "src/components/common/CenteredTypography.tsx";
import { useState } from "react";
import { ConfiguratorTableRevision } from "src/api/generated/erp/db/types/tables/configuratorTableRevision.ts";
import { ConfiguratorTableRevisionsDataGrid } from "src/components/views/erp/configurator/tables/ConfiguratorTableRevisionsDataGrid.tsx";
import { ConfiguratorTableRevisionContentView } from "src/components/views/erp/configurator/tables/revisionContentView/ConfiguratorTableRevisionContentView.tsx";

export const ConfiguratorTablesView = () => {
	const [selectedTable, setSelectedTable] = useState<ConfiguratorTable | undefined>(undefined);
	const [selectedRevision, setSelectedRevision] = useState<ConfiguratorTableRevision | undefined>(undefined);

	return (
		<AavoMosaic
			layout={{
				type: "row",
				splitPercentage: 35,
				first: {
					type: "column",
					first: {
						type: "panel",
						title: i18n.t("tables"),
						content: (
							<ConfiguratorTablesDataGrid
								onSelectionChanged={(selectedRow) => {
									setSelectedTable(selectedRow);
									setSelectedRevision(undefined);
								}}
							/>
						),
					},
					second: {
						type: "panel",
						title: i18n.t("revisions"),
						content:
							selectedTable == null ?
								<CenteredTypography children={i18n.t("select_a_table")} />
							:	<ConfiguratorTableRevisionsDataGrid
									key={selectedTable.configuratorTableId}
									configuratorTableId={selectedTable.configuratorTableId}
									onSelectionChanged={setSelectedRevision}
								/>,
					},
				},
				second: {
					type: "panel",
					title: selectedTable?.name ?? "-",
					content:
						selectedRevision == null ?
							<CenteredTypography children={i18n.t("select_a_revision")} />
						:	<ConfiguratorTableRevisionContentView
								key={selectedRevision.configuratorTableRevisionId}
								revision={selectedRevision}
							/>,
				},
			}}
		/>
	);
};
