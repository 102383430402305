// This file is automatically generated. Do not edit manually.

import { InventoryLevelView } from "src/api/generated/erp/db/types/tables/inventoryLevelView";
import { InventoryLevelSummary } from "src/api/generated/erp/warehouse/inventory/inventoryEvent/inventoryLevelSummary";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace InventoryLevelsByPartWarehouseLocationsDataGridApi {
	export async function getGridData(args: {
		partId: number;
	}): Promise<InventoryLevelsByPartWarehouseLocationsDataGridApi_GridData> {
		const response = await makeAxiosRequest({
			url: "/api/ext/api/warehouse/inventory/inventoryLevelsByPartWarehouseLocation/getGridData",
			method: "POST",
			data: {
				partId: args.partId,
			},
		});
		return response as InventoryLevelsByPartWarehouseLocationsDataGridApi_GridData;
	}
}

export interface InventoryLevelsByPartWarehouseLocationsDataGridApi_GridData {
	partIsConfigurable: boolean;
	rows: Array<InventoryLevelView>;
	summary: InventoryLevelSummary;
}
