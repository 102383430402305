// This file is automatically generated. Do not edit manually.

import { ServerSideDataModelRequest } from "src/api/generated/common/dataGrids/serverSideDataModelRequest";
import { ServerSideDataModelResult } from "src/api/generated/common/dataGrids/serverSideDataModelResult";
import { IsoDateTimeString } from "src/types/dateTime";
import { SurveySubmissionObjectType } from "src/api/generated/erp/db/types/enums/surveySubmissionObjectType";
import { SurveySubmissionState } from "src/api/generated/erp/db/types/enums/surveySubmissionState";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace SurveyAuditApi {
	export async function getSurveySubmissionsToAudit(args: {
		includeAudited?: boolean;
		surveyCategoryId?: number | null | undefined;
		dataModelRequest: ServerSideDataModelRequest;
	}): Promise<ServerSideDataModelResult<SurveyAuditApi_SurveySubmissionToAuditDto>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/surveys/audit/getSurveySubmissionsToAudit",
			method: "POST",
			data: {
				includeAudited: args.includeAudited,
				surveyCategoryId: args.surveyCategoryId,
				dataModelRequest: args.dataModelRequest,
			},
		});
		return response as ServerSideDataModelResult<SurveyAuditApi_SurveySubmissionToAuditDto>;
	}

	export async function checkSurveySubmission(args: { surveySubmissionId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/surveys/audit/checkSurveySubmission",
			method: "POST",
			data: {
				surveySubmissionId: args.surveySubmissionId,
			},
		});
		return response as void;
	}

	export async function revertCheckSurveySubmission(args: { surveySubmissionId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/surveys/audit/revertCheckSurveySubmission",
			method: "POST",
			data: {
				surveySubmissionId: args.surveySubmissionId,
			},
		});
		return response as void;
	}

	export async function approveSurveySubmission(args: { surveySubmissionId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/surveys/audit/approveSurveySubmission",
			method: "POST",
			data: {
				surveySubmissionId: args.surveySubmissionId,
			},
		});
		return response as void;
	}

	export async function revertApproveSurveySubmission(args: { surveySubmissionId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/surveys/audit/revertApproveSurveySubmission",
			method: "POST",
			data: {
				surveySubmissionId: args.surveySubmissionId,
			},
		});
		return response as void;
	}
}

export interface SurveyAuditApi_SurveySubmissionToAuditDto {
	approvingAllowed: boolean;
	checkingAllowed: boolean;
	approvedAt: IsoDateTimeString | null | undefined;
	approvedByUserId: number | null | undefined;
	approvedByUserName: string | null | undefined;
	autoCheckedOnApprove: boolean;
	categoryName: string;
	checkedAt: IsoDateTimeString | null | undefined;
	checkedByUserId: number | null | undefined;
	checkedByUserName: string | null | undefined;
	lastUpdated: IsoDateTimeString;
	objectDescription: string | null | undefined;
	objectId: number | null | undefined;
	objectType: SurveySubmissionObjectType | null | undefined;
	state: SurveySubmissionState | null | undefined;
	submittedAt: IsoDateTimeString;
	submittedByUserId: number;
	submittedByUserName: string;
	surveyCategoryId: number;
	surveyFormId: number;
	surveyFormName: string;
	surveyFormRevisionDescription: string;
	surveyFormRevisionId: number;
	surveyFormRevisionNumber: number;
	surveySubmissionId: number;
}
