import { Menu, MenuProps } from "@mui/material";
import React, { useState } from "react";
import { AavoButton, AavoButtonProps } from "../buttons/AavoButton.tsx";
import { faBars } from "@fortawesome/pro-regular-svg-icons";
import { AavoContextMenuClosingContext } from "../contextMenu/AavoContextMenuClosingContext.ts";

export interface AavoMenuButtonProps extends Omit<AavoButtonProps, "onClick"> {
	buttonRef?: React.Ref<HTMLButtonElement>;
	menuContent: React.ReactNode | ((closeMenu: () => void) => React.ReactNode);
	menuProps?: Partial<Omit<MenuProps, "onClose" | "open" | "anchorEl">>;
}

export const AavoMenuButton = ({
	menuContent: menuContentProvider,
	buttonRef,
	menuProps,
	icon = faBars,
	...other
}: AavoMenuButtonProps) => {
	const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
	const menuContent =
		menuContentProvider instanceof Function ?
			menuContentProvider(() => setMenuAnchorEl(null))
		:	menuContentProvider;

	const closeMenu = () => {
		setMenuAnchorEl(null);
	};

	return (
		<>
			<AavoButton
				ref={buttonRef}
				icon={icon}
				onClick={(e) => {
					setMenuAnchorEl(e.currentTarget);
				}}
				{...other}
			/>
			<AavoContextMenuClosingContext.Provider value={{ closeContextMenu: closeMenu }}>
				<Menu open={menuAnchorEl !== null} anchorEl={menuAnchorEl} onClose={closeMenu} {...menuProps}>
					{menuContent}
				</Menu>
			</AavoContextMenuClosingContext.Provider>
		</>
	);
};
