// This file is automatically generated. Do not edit manually.

import { ProjectActivityMaterialView } from "src/api/generated/erp/db/types/tables/projectActivityMaterialView";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace ReservableProjectActivityMaterialsDataGridApi {
	export async function getRows(args: { projectId: number }): Promise<Array<ProjectActivityMaterialView>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/project/picking/reservableProjectActivityMaterialsDataGrid/getRows",
			method: "POST",
			data: {
				projectId: args.projectId,
			},
		});
		return response as Array<ProjectActivityMaterialView>;
	}
}
