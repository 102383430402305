// This file is automatically generated. Do not edit manually.

import { ContactConnectedItemType } from "src/api/generated/erp/db/types/enums/contactConnectedItemType";
import { Contact } from "src/api/generated/erp/db/types/tables/contact";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace ContactApi {
	export async function getItemContacts(args: {
		connectedItemType: ContactConnectedItemType;
		connectedItemId: number;
	}): Promise<Array<Contact>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/contacts/getItemContacts",
			method: "POST",
			data: {
				connectedItemType: args.connectedItemType,
				connectedItemId: args.connectedItemId,
			},
		});
		return response as Array<Contact>;
	}

	export async function insert(args: { contact: Contact }): Promise<number> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/contacts/insert",
			method: "POST",
			data: {
				contact: args.contact,
			},
		});
		return response as number;
	}

	export async function update(args: { contact: Contact }): Promise<number> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/contacts/update",
			method: "POST",
			data: {
				contact: args.contact,
			},
		});
		return response as number;
	}

	export async function delete_(args: { contactId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/contacts/delete",
			method: "POST",
			data: {
				contactId: args.contactId,
			},
		});
		return response as void;
	}
}
