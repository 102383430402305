import { useClientSideDataGridModel } from "src/components/common/dataGrid/gridModel/useClientSideDataGridModel.tsx";
import { AccountingDimensionTargetsApi } from "src/api/generated/erp/sales/accounting/api/accountingDimensionTargetsApi.ts";
import { enumColumn, integerColumn } from "src/components/common/dataGrid/columns.tsx";
import i18n from "i18next";
import { AccountingDimensionTarget } from "src/api/generated/erp/db/types/tables/accountingDimensionTarget.ts";
import { getAccountingObjectTypeLabels } from "src/api/generated/erp/db/types/enums/accountingObjectType.ts";
import { ControlledAsyncCrudDataGrid } from "src/components/common/dataGrid/crud/ControlledAsyncCrudDataGrid.tsx";
import { AccountingDimensionTargetForm } from "src/components/views/erp/sales/accounting/managing/AccountingDimensionTargetForm.tsx";
import { faAngleDown, faAngleUp } from "@fortawesome/pro-regular-svg-icons";
import { AsyncButton } from "src/components/common/buttons/AsyncButton.tsx";

export interface AccountingDimensionTargetsDataGridProps {
	dimensionId: number;
}

export const AccountingDimensionTargetsDataGrid = ({
	dimensionId,
}: AccountingDimensionTargetsDataGridProps) => {
	const { dataGridProps, onlySelectedRow, refreshData } = useClientSideDataGridModel({
		fetchData: () => AccountingDimensionTargetsApi.getDimensionTargets({ dimensionId }),
		getRowId: (row) => row.accountingDimensionTargetId,
		initialParams: {},
		gridId: "6D5F11DB11D19766",
	});

	return (
		<ControlledAsyncCrudDataGrid<AccountingDimensionTarget>
			columns={[
				enumColumn({
					field: "objectType",
					headerName: i18n.t("target"),
					width: 300,
					enumLabels: getAccountingObjectTypeLabels(),
				}),
				integerColumn({
					field: "priority",
					headerName: i18n.t("priority"),
				}),
			]}
			form={{
				addRowEnabled: true,
				editEnabled: false,
				dialogSize: "sm",
				dialogTitle: i18n.t("add_target"),
				component: ({ onCompleted }) => (
					<AccountingDimensionTargetForm dimensionId={dimensionId} onCompleted={onCompleted} />
				),
			}}
			remove={({ items }) =>
				AccountingDimensionTargetsApi.delete_({
					targetIds: items.map((item) => item.accountingDimensionTargetId),
				})
			}
			actionBarComponents={
				<>
					<AsyncButton
						icon={faAngleUp}
						disabled={!onlySelectedRow}
						onClick={async () => {
							await AccountingDimensionTargetsApi.changeTargetPriority({
								targetId: onlySelectedRow!.accountingDimensionTargetId,
								direction: "UP",
							});
							await refreshData();
						}}
					/>
					<AsyncButton
						icon={faAngleDown}
						disabled={!onlySelectedRow}
						onClick={async () => {
							await AccountingDimensionTargetsApi.changeTargetPriority({
								targetId: onlySelectedRow!.accountingDimensionTargetId,
								direction: "DOWN",
							});
							await refreshData();
						}}
					/>
				</>
			}
			{...dataGridProps}
		/>
	);
};
