// This file is automatically generated. Do not edit manually.

import { AccountingDimensionTarget } from "src/api/generated/erp/db/types/tables/accountingDimensionTarget";
import { UpOrDown } from "src/api/generated/common/upOrDown";
import { AccountingObjectType } from "src/api/generated/erp/db/types/enums/accountingObjectType";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace AccountingDimensionTargetsApi {
	export async function getDimensionTargets(args: {
		dimensionId: number;
	}): Promise<Array<AccountingDimensionTarget>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/accounting/dimension/targets/getDimensionTargets",
			method: "POST",
			data: {
				dimensionId: args.dimensionId,
			},
		});
		return response as Array<AccountingDimensionTarget>;
	}

	export async function getFormInitData(args: {
		dimensionId: number;
	}): Promise<AccountingDimensionTargetsApi_FormInitData> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/accounting/dimension/targets/getFormInitData",
			method: "POST",
			data: {
				dimensionId: args.dimensionId,
			},
		});
		return response as AccountingDimensionTargetsApi_FormInitData;
	}

	export async function insert(args: { target: AccountingDimensionTarget }): Promise<number> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/accounting/dimension/targets/insert",
			method: "POST",
			data: {
				target: args.target,
			},
		});
		return response as number;
	}

	export async function delete_(args: { targetIds: Array<number> }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/accounting/dimension/targets/delete",
			method: "POST",
			data: {
				targetIds: args.targetIds,
			},
		});
		return response as void;
	}

	export async function changeTargetPriority(args: { targetId: number; direction: UpOrDown }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/accounting/dimension/targets/changeTargetPriority",
			method: "POST",
			data: {
				targetId: args.targetId,
				direction: args.direction,
			},
		});
		return response as void;
	}
}

export interface AccountingDimensionTargetsApi_FormInitData {
	connectedObjectTypes: Array<AccountingObjectType>;
}
