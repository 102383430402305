// This file is automatically generated. Do not edit manually.

import { ChartGroupView } from "src/api/generated/postgres/db/types/public_/tables/chartGroupView";
import { ControlChart } from "src/api/generated/postgres/db/types/public_/tables/controlChart";
import { IsoDateString } from "src/types/dateTime";
import { ServerSideDataModelRequest } from "src/api/generated/common/dataGrids/serverSideDataModelRequest";
import { ServerSideDataModelResult } from "src/api/generated/common/dataGrids/serverSideDataModelResult";
import { CChartEventView } from "src/api/generated/postgres/db/types/public_/tables/cChartEventView";
import { FrontendDownloadFileHandle } from "src/api/generated/common/frontendDownloadFileHandle";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace CChartEventSearchApi {
	export async function getChartGroupOptions(args: {
		chartParentGroupIds: Array<number>;
	}): Promise<Array<ChartGroupView>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/spc/cChartEvents/getChartGroupOptions",
			method: "POST",
			data: {
				chartParentGroupIds: args.chartParentGroupIds,
			},
		});
		return response as Array<ChartGroupView>;
	}

	export async function getControlChartOptions(args: { chartGroupId: number }): Promise<Array<ControlChart>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/spc/cChartEvents/getControlChartOptions",
			method: "POST",
			data: {
				chartGroupId: args.chartGroupId,
			},
		});
		return response as Array<ControlChart>;
	}

	export async function searchCChartEvents(args: {
		controlChartIds: Array<number>;
		fromDate: IsoDateString | null | undefined;
		toDate: IsoDateString | null | undefined;
		dataModelRequest: ServerSideDataModelRequest;
	}): Promise<ServerSideDataModelResult<CChartEventView>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/spc/cChartEvents/searchCChartEvents",
			method: "POST",
			data: {
				controlChartIds: args.controlChartIds,
				fromDate: args.fromDate,
				toDate: args.toDate,
				dataModelRequest: args.dataModelRequest,
			},
		});
		return response as ServerSideDataModelResult<CChartEventView>;
	}

	export async function downloadCChartEventsAsCsv(args: {
		controlChartIds: Array<number>;
		fromDate: IsoDateString;
		toDate: IsoDateString;
	}): Promise<FrontendDownloadFileHandle> {
		const response = await makeAxiosRequest({
			url: "/api/ext/spc/cChartEvents/downloadCChartEventsAsCsv",
			method: "POST",
			data: {
				controlChartIds: args.controlChartIds,
				fromDate: args.fromDate,
				toDate: args.toDate,
			},
		});
		return response as FrontendDownloadFileHandle;
	}
}
