// This file is automatically generated. Do not edit manually.

import { FrontendDownloadFileHandle } from "src/api/generated/common/frontendDownloadFileHandle";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace MellanoHolzmaFileDownloadApi {
	export async function downloadHolzmaFile(args: { shopOrderBatchId: number }): Promise<FrontendDownloadFileHandle> {
		const response = await makeAxiosRequest({
			url: "/api/ext/holzma/downloadHolzmaFile",
			method: "POST",
			data: {
				shopOrderBatchId: args.shopOrderBatchId,
			},
		});
		return response as FrontendDownloadFileHandle;
	}
}
