import { SpcSamplingMethod } from "src/components/views/spc/basedata/controlCharts/form/samplingMethod/SpcSamplingMethod.tsx";
import { FormTextField } from "src/components/common/forms/fields/FormTextField.tsx";

export const getOperationWorkInProgressAmountSamplingMethod = (): SpcSamplingMethod => {
	return {
		key: "futuraplan_operation_wip_amount",
		label: "Työvaiheessa oleva määrä",
		renderParameterFields: ({ control }) => (
			<>
				<FormTextField control={control} name={"samplingParams.param1"} label={"Tila"} />
				<FormTextField
					control={control}
					name={"samplingParams.param2"}
					label={"Työvaihe (tyhjä = kaikki)"}
				/>
			</>
		),
	};
};
