import { Resource } from "src/api/generated/postgres/db/types/ext_resourcing/tables/resource.ts";
import { Item } from "src/api/generated/postgres/db/types/ext_resourcing/tables/item.ts";
import { ClientSideDataGridModel } from "src/components/common/dataGrid/gridModel/ClientSideDataGridModel.tsx";
import { ResourceItemsDataGridApi } from "src/api/generated/spc/resources/api/resourceItemsDataGridApi.ts";
import { CrudDataGrid } from "src/components/common/dataGrid/crud/CrudDataGrid.tsx";
import { integerColumn, textColumn } from "src/components/common/dataGrid/columns.tsx";
import i18n from "i18next";
import { AavoTextField } from "src/components/common/inputFields/AavoTextField.tsx";
import { AavoButton } from "src/components/common/buttons/AavoButton.tsx";
import { faFileDownload, faLink } from "@fortawesome/pro-regular-svg-icons";
import { useInputDialog } from "src/components/common/dialogs/input/useInputDialog.tsx";
import { AsyncMenuButton } from "src/components/common/contextMenu/AsyncMenuButton.tsx";
import { ResourceItemEditApi } from "src/api/generated/spc/resources/api/resourceItemEditApi.ts";
import { ResourceItemForm } from "src/components/views/spc/basedata/resources/ResourceItemForm.tsx";
import { RefreshableElementRef } from "src/utils/useRefreshRef.ts";

export interface ResourceItemsDataGridProps {
	setSelectedResourceItem: (resourceItem: Item | undefined) => void;
	selectedResource: Resource;
	controlChartsRefreshRef: RefreshableElementRef;
}

export const ResourceItemsDataGrid = ({
	setSelectedResourceItem,
	selectedResource,
	controlChartsRefreshRef,
}: ResourceItemsDataGridProps) => {
	return (
		<ClientSideDataGridModel
			gridId={"3F60F568A74F2D4F"}
			fetchData={({ searchQuery }) =>
				ResourceItemsDataGridApi.getItems({
					searchQuery: searchQuery,
					resourceId: selectedResource.id,
				})
			}
			initialParams={{ searchQuery: "" }}
			getRows={(data) => data}
			getRowId={(row) => row.id}
			onSelectionChanged={(selectedRows) => {
				setSelectedResourceItem(selectedRows[0]);
			}}
			render={({ dataGridProps, refreshData, onlySelectedRow }) => (
				<CrudDataGrid<Item>
					columns={[
						textColumn({
							field: "name",
							headerName: i18n.t("name"),
							width: 150,
						}),
						textColumn({
							field: "externalId",
							headerName: i18n.t("external_id"),
							width: 150,
						}),
						textColumn({
							field: "description",
							headerName: i18n.t("description"),
							width: 250,
						}),
						integerColumn({
							field: "id",
							headerName: i18n.t("id"),
						}),
					]}
					actionBarComponents={
						<>
							<AavoTextField
								label={i18n.t("search")}
								onSubmit={async (input) => {
									await refreshData({ searchQuery: input });
								}}
							/>
							<ConnectControlChartToResourceItem
								targetItem={onlySelectedRow ?? null}
								targetResource={selectedResource}
								refreshControlChartsData={controlChartsRefreshRef}
							/>
						</>
					}
					rowContextMenuComponents={({ row }) => [
						<CopyResourceItemControlChartsMenuButton
							targetResourceItemId={row.id}
							controlChartsRefreshRef={controlChartsRefreshRef}
						/>,
					]}
					remove={async ({ items }) => {
						await ResourceItemEditApi.delete_({ itemIds: items.map((item) => item.id) });
					}}
					form={{
						editEnabled: true,
						addRowEnabled: true,
						dialogSize: "sm",
						dialogTitle: i18n.t("resource_item"),
						component: (props) => (
							<ResourceItemForm
								resourceId={selectedResource.id}
								resourceItemId={props.row?.id ?? null}
								{...props}
							/>
						),
					}}
					{...dataGridProps}
				/>
			)}
		/>
	);
};

interface CopyResourceItemControlChartsMenuButtonProps {
	targetResourceItemId: number;
	controlChartsRefreshRef: RefreshableElementRef;
}

const CopyResourceItemControlChartsMenuButton = ({
	targetResourceItemId,
	controlChartsRefreshRef,
}: CopyResourceItemControlChartsMenuButtonProps) => {
	const showInputDialog = useInputDialog();
	return (
		<AsyncMenuButton
			key={"copy"}
			icon={faFileDownload}
			label={i18n.t("copy.verb")}
			onClick={async () => {
				if (targetResourceItemId == null) return;
				const sourceResourceItemOptions =
					await ResourceItemsDataGridApi.getChartCopySourceResourceItemOptions({
						targetResourceItemId: targetResourceItemId,
					});
				const sourceResourceItemId = await showInputDialog({
					defaultValue: null,
					type: "singleNumberSelect",
					title: i18n.t("source_item"),
					fieldLabel: i18n.t("source_item"),
					required: true,
					fieldProps: {
						selection: {
							options: sourceResourceItemOptions.map((item) => ({
								value: item.id,
								label: item.name,
							})),
						},
					},
				});
				if (sourceResourceItemId == null) return;
				await ResourceItemsDataGridApi.copyResourceItemControlCharts({
					sourceResourceItemId: sourceResourceItemId,
					targetResourceItemId: targetResourceItemId,
				});
				await controlChartsRefreshRef.refresh();
			}}
		/>
	);
};

interface ConnectControlChartToResourceItemProps {
	targetItem: Item | null;
	targetResource: Resource;
	refreshControlChartsData: RefreshableElementRef;
}

const ConnectControlChartToResourceItem = ({
	targetItem,
	targetResource,
	refreshControlChartsData,
}: ConnectControlChartToResourceItemProps) => {
	const showInputDialog = useInputDialog();
	return (
		<AavoButton
			label={i18n.t("connect_control_chart")}
			variant={"outlined"}
			icon={faLink}
			disabled={targetItem == null}
			onClick={async () => {
				if (targetItem == null) return;
				const sourceItemOptions = await ResourceItemsDataGridApi.getItems({
					resourceId: targetResource.id,
					disableItemId: targetItem?.id,
				});
				const sourceItemId = await showInputDialog({
					defaultValue: null,
					type: "singleNumberSelect",
					title: i18n.t("source_item"),
					fieldLabel: i18n.t("source_item"),
					required: true,
					fieldProps: {
						selection: {
							options: sourceItemOptions.map((item) => ({
								value: item.id,
								label: item.name,
							})),
						},
					},
				});
				if (sourceItemId == null) return;
				const sourceChartOptions = await ResourceItemsDataGridApi.getConnectableControlChartOptions({
					sourceResourceItemId: sourceItemId,
					targetResourceItemId: targetItem.id,
				});
				const sourceChartId = await showInputDialog({
					defaultValue: null,
					type: "singleNumberSelect",
					title: i18n.t("control_chart"),
					fieldLabel: i18n.t("control_chart"),
					required: true,
					fieldProps: {
						selection: {
							options: sourceChartOptions.map((chart) => ({
								value: chart.id,
								label: chart.name,
							})),
						},
					},
				});
				if (sourceChartId == null) return;
				await ResourceItemsDataGridApi.connectControlChartToResourceItem({
					controlChartId: sourceChartId,
					resourceItemId: targetItem.id,
				});
				await refreshControlChartsData.refresh();
			}}
		/>
	);
};
