// This file is automatically generated. Do not edit manually.

import { CustomerOrderOffer } from "src/api/generated/erp/db/types/tables/customerOrderOffer";
import { CustomerOrderOfferLineView } from "src/api/generated/erp/db/types/tables/customerOrderOfferLineView";
import { CustomerOrderOfferSummary } from "src/api/generated/erp/sales/customerOrderOffer/service/customerOrderOfferSummary";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace CustomerOrderOfferLinesDataGridApi {
	export async function getData(args: {
		customerOrderOfferId: number;
		parentLineId: number | null | undefined;
		searchQuery: string;
	}): Promise<CustomerOrderOfferLinesDataGridApi_Data> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/customerOrderOffer/offerLinesDataGrid/getData",
			method: "POST",
			data: {
				customerOrderOfferId: args.customerOrderOfferId,
				parentLineId: args.parentLineId,
				searchQuery: args.searchQuery,
			},
		});
		return response as CustomerOrderOfferLinesDataGridApi_Data;
	}
}

export interface CustomerOrderOfferLinesDataGridApi_Data {
	customerOrderOffer: CustomerOrderOffer;
	customerOrderOfferLines: Array<CustomerOrderOfferLineView>;
	offerSummary: CustomerOrderOfferSummary;
}
