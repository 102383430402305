import "src/sentry/setupSentry.ts";
import "src/config/i18nConfig.ts";
import "src/config/dayjsConfig.ts";
import "src/config/highchartsConfig.ts";
import "src/config/mapboxConfig.ts";
import "src/polyfills.ts";
import React from "react";
import ReactDOM from "react-dom/client";
import "src/styles/css/index.scss";
import { App } from "./App";
import "./legacyViews/js/index";
import "@fortawesome/fontawesome-pro/css/all.css";
import { far } from "@fortawesome/pro-regular-svg-icons";
import { applyLocalSettings } from "./localSettings/utils";
import { setupInitialBackgroundMessageEventHandler } from "./notifications/firebaseMessaging";
import { applyDomManipulationWorkaround } from "./utils/domManipulationWorkaround";
import { library as fontawesomeLibrary } from "@fortawesome/fontawesome-svg-core";
import { LicenseInfo as MuiLicenceInfo } from "@mui/x-license";

MuiLicenceInfo.setLicenseKey(
	"cd086ca66e37f2598c8867bddb023211Tz05NjMyNyxFPTE3NTU0MzIwNDAwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLFBWPWluaXRpYWwsS1Y9Mg==",
);

applyLocalSettings(false);
applyDomManipulationWorkaround();

fontawesomeLibrary.add(far);

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>,
);

setupInitialBackgroundMessageEventHandler();
