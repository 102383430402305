import { ClientSideDataGridModel } from "src/components/common/dataGrid/gridModel/ClientSideDataGridModel.tsx";
import { WorkspaceCategoryApi } from "src/api/generated/workspaces/api/workspaceCategoryApi.ts";
import { CrudDataGrid } from "src/components/common/dataGrid/crud/CrudDataGrid.tsx";
import { textColumn } from "src/components/common/dataGrid/columns.tsx";
import i18n from "i18next";
import { WorkspaceCategory } from "src/api/generated/postgres/db/types/workspaces/tables/workspaceCategory.ts";
import { WorkspaceCategoryForm } from "src/components/views/workspaces/WorkspaceCategoryForm.tsx";

export const WorkspaceCategoriesDataGrid = () => {
	return (
		<ClientSideDataGridModel
			gridId={"54ED26482A87556A"}
			fetchData={WorkspaceCategoryApi.getWorkspaceCategories}
			getRows={(data) => data}
			getRowId={(row) => row.id}
			initialParams={{}}
			render={({ dataGridProps, onlySelectedRow }) => (
				<CrudDataGrid<WorkspaceCategory>
					columns={[
						textColumn({
							field: "categoryName",
							headerName: i18n.t("name"),
							width: 200,
						}),
						textColumn({
							field: "categoryDescription",
							headerName: i18n.t("description"),
							width: 200,
						}),
					]}
					form={{
						editEnabled: true,
						addRowEnabled: true,
						dialogSize: "sm",
						dialogTitle: i18n.t("workspace_category"),
						component: ({ row, onCompleted, onFormEdited }) => (
							<WorkspaceCategoryForm
								workspaceCategory={row}
								onCompleted={onCompleted}
								onFormEdited={onFormEdited}
							/>
						),
					}}
					remove={
						onlySelectedRow != null ?
							{
								type: "enabled",
								action: () => WorkspaceCategoryApi.delete_({ workspaceCategoryId: onlySelectedRow.id }),
							}
						:	undefined
					}
					{...dataGridProps}
				/>
			)}
		/>
	);
};
