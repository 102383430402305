import { ConfiguratorLibrariesDataGrid } from "src/components/views/erp/configurator/managing/libraries/ConfiguratorLibrariesDataGrid.tsx";
import { ConfiguratorLibraryVersionsDataGrid } from "src/components/views/erp/configurator/managing/libraries/ConfiguratorLibraryVersionsDataGrid.tsx";
import { ConfiguratorLibrary } from "src/api/generated/erp/db/types/tables/configuratorLibrary.ts";
import i18n from "i18next";
import { CenteredTypography } from "src/components/common/CenteredTypography.tsx";
import { AavoMosaic } from "src/components/common/mosaic/AavoMosaic.tsx";
import { useState } from "react";
import { ConfiguratorLibraryVersion } from "src/api/generated/erp/db/types/tables/configuratorLibraryVersion.ts";
import { ConfiguratorLibraryVersionComponentsDataGrid } from "src/components/views/erp/configurator/managing/libraries/ConfiguratorLibraryVersionComponentsDataGrid.tsx";
import { ConfiguratorLibraryFunctionsDataGrid } from "src/components/views/erp/configurator/managing/libraries/libraryFunctions/ConfiguratorLibraryFunctionsDataGrid.tsx";

export const ConfiguratorLibrariesView = () => {
	const [selectedLibrary, setSelectedLibrary] = useState<ConfiguratorLibrary | undefined>(undefined);
	const [selectedLibraryVersion, setSelectedLibraryVersion] = useState<ConfiguratorLibraryVersion | undefined>(
		undefined,
	);

	return (
		<AavoMosaic
			viewId={"C665E032DE021D8E"}
			layout={{
				type: "row",
				first: {
					type: "column",
					splitPercentage: 30,
					first: {
						type: "panel",
						title: i18n.t("libraries"),
						content: (
							<ConfiguratorLibrariesDataGrid
								onSelectionChanged={(row) => {
									setSelectedLibrary(row);
									setSelectedLibraryVersion(undefined);
								}}
							/>
						),
					},
					second: {
						type: "panel",
						title: i18n.t("versions"),
						content:
							selectedLibrary == null ?
								<CenteredTypography children={i18n.t("select_a_library")} />
							:	<ConfiguratorLibraryVersionsDataGrid
									key={selectedLibrary.configuratorLibraryId}
									configuratorLibraryId={selectedLibrary.configuratorLibraryId}
									onSelectionChanged={setSelectedLibraryVersion}
								/>,
					},
				},
				second: {
					type: "tabs",
					items: [
						{
							type: "panel",
							title: i18n.t("components"),
							content:
								selectedLibraryVersion == null ?
									<CenteredTypography children={i18n.t("select_a_library_version")} />
								:	<ConfiguratorLibraryVersionComponentsDataGrid
										key={selectedLibraryVersion.configuratorLibraryVersionId}
										configuratorLibraryVersion={selectedLibraryVersion}
									/>,
						},
						{
							type: "panel",
							title: i18n.t("functions"),
							content:
								selectedLibraryVersion == null ?
									<CenteredTypography children={i18n.t("select_a_library_version")} />
								:	<ConfiguratorLibraryFunctionsDataGrid
										key={selectedLibraryVersion.configuratorLibraryVersionId}
										configuratorLibraryVersion={selectedLibraryVersion}
									/>,
						},
					],
				},
			}}
		/>
	);
};
