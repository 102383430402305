import { ConfiguratorInput } from "src/api/generated/erp/configurator/model/configuratorInput.ts";
import { ConfigurationPropertyValue } from "src/api/generated/io/aavo/applications/db/erp/types/configurationPropertyValue.ts";
import i18n from "i18next";
import { filterNulls } from "src/utils/arrayUtils.ts";
import { CONFIGURATION_PROPERTY_NULL_VALUE } from "src/components/views/erp/configurator/configuratorUtils.ts";
import {
	TransformedConfigurationComponent
} from "src/api/generated/erp/configurator/componentTransformation/model/transformedConfigurationComponent.ts";

export function getConfiguratorFormErrors(
	components: TransformedConfigurationComponent[],
	input: ConfiguratorInput,
): string[] {
	return filterNulls(
		components.map((component) => {
			const value =
				input.inputComponentValues.find(
					({ componentId }) => componentId === component.configurationComponentId,
				)?.value ?? CONFIGURATION_PROPERTY_NULL_VALUE;

			return getComponentError(component, value);
		}),
	);
}

function getComponentError(
	component: TransformedConfigurationComponent,
	value: ConfigurationPropertyValue,
): string | null {
	switch (component.type) {
		case "field": {
			if (component.required && component.visible && value.type == "null") {
				return getRequiredError(component);
			}
			return null;
		}

		case "subConfigurator": {
			if (
				component.required &&
				component.visible &&
				(value.type != "sub_configuration" || Object.keys(value.value).length === 0)
			) {
				return getRequiredError(component);
			}
			return null;
		}

		case "subConfiguratorList": {
			if (value.type === "sub_configuration_list") {
				const hasErrors = value.value.some((subConfigValue) => subConfigValue.error != null);
				if (hasErrors) {
					return i18n.t("sub_configurator_list_has_errors", { label: component.label });
				}
			}
			return null;
		}

		default:
			return null;
	}
}

function getRequiredError(component: TransformedConfigurationComponent) {
	return i18n.t("configurator_component_error_required", { label: component.label });
}
