import { FormCommonProps } from "src/components/common/forms/types.ts";
import { PurchaseOrderReceipt } from "src/api/generated/erp/db/types/tables/purchaseOrderReceipt.ts";
import { FormTextField } from "src/components/common/forms/fields/FormTextField.tsx";
import { AavoForm } from "src/components/common/forms/AavoForm.tsx";
import { PurchaseOrderReceptionApi } from "src/api/generated/erp/purchase/purchaseOrder/api/purchaseOrderReceptionApi.ts";
import i18n from "i18next";

export interface PurchaseOrderReceiptFormProps extends FormCommonProps<number> {
	purchaseOrderId: number;
	purchaseOrderReceipt: PurchaseOrderReceipt | undefined;
}

export const PurchaseOrderReceiptForm = ({
	purchaseOrderId,
	purchaseOrderReceipt,
	onFormEdited,
	onCompleted,
}: PurchaseOrderReceiptFormProps) => {
	return (
		<AavoForm
			defaultValues={purchaseOrderReceipt}
			submit={submit}
			onCompleted={onCompleted}
			onFormEdited={onFormEdited}
			render={({control}) => (
				<>
					<FormTextField
						control={control}
						name={"note"}
						label={i18n.t("note")}
						multiline
						spanGridColumns
					/>
				</>
			)}
		/>
	);

	async function submit({ note }: PurchaseOrderReceipt) {
		if (purchaseOrderReceipt == null) {
			return await PurchaseOrderReceptionApi.createReceipt({
				purchaseOrderId: purchaseOrderId,
				note: note,
			});
		} else {
			await PurchaseOrderReceptionApi.updateReceipt({
				purchaseOrderReceiptId: purchaseOrderReceipt.purchaseOrderReceiptId,
				note: note,
			});
			return purchaseOrderReceipt.purchaseOrderReceiptId;
		}
	}
};
