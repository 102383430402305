// This file is automatically generated. Do not edit manually.

import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace DocumentDeleteApi {
	export async function deleteDocument(args: { documentId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/documents/deleteDocument",
			method: "POST",
			data: {
				documentId: args.documentId,
			},
		});
		return response as void;
	}
}
