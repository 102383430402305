import {
    ProjectActivityCustomerOrderView
} from "src/api/generated/erp/db/types/tables/projectActivityCustomerOrderView.ts";
import {dateColumn, dateTimeColumn, textColumn} from "src/components/common/dataGrid/columns.tsx";
import i18n from "i18next";
import {AavoTextField} from "src/components/common/inputFields/AavoTextField.tsx";
import {AavoCheckbox} from "src/components/common/inputFields/AavoCheckbox.tsx";
import {
    ServerSideDataGridModel,
    ServerSideDataGridModelProps,
} from "src/components/common/dataGrid/gridModel/ServerSideDataGridModel.tsx";
import {SelectField} from "src/components/common/inputFields/SelectField.tsx";
import {
    ProjectActivityJobQueueApi_DataGridData
} from "src/api/generated/erp/project/projectActivity/api/projectActivityJobQueueApi.ts";
import {AavoDataGrid} from "src/components/common/dataGrid/AavoDataGrid.tsx";
import {OpenCustomerOrderButton} from "src/components/views/erp/utilComponents/OpenCustomerOrderButton.tsx";
import {OpenProjectInTreeViewButton} from "src/components/views/erp/utilComponents/OpenProjectInTreeViewButton.tsx";
import React from "react";
import {useUserPermissions} from "src/components/views/erp/common/userPermissions.ts";
import {formatDayJs} from "src/utils/dayjsUtils.ts";

export interface ProjectActivityJobQueueBaseDataGridProps
	extends Pick<
		ServerSideDataGridModelProps<
			ProjectActivityJobQueueApi_DataGridData,
			ProjectActivityCustomerOrderView,
			ProjectActivityJobQueueDataGridFilterParams
		>,
		"fetchData" | "refreshRef" | "onSelectionChanged"
	> {
	showActivityStartInfoColumns?: boolean;
	showResponsiblePersonColumn?: boolean;
	subProjectTypeFiltering?: boolean;
	resourceFiltering?: boolean;
	onlyActivitiesStartedByMeFiltering?: boolean;
	gridId: string;
	actionBarComponents?: (params: {
		onlySelectedRow: ProjectActivityCustomerOrderView | undefined;
		selectedRows: ProjectActivityCustomerOrderView[];
	}) => React.ReactNode;
	showActualStartDate?: boolean;
}

export interface ProjectActivityJobQueueDataGridFilterParams {
	searchQuery: string;
	subProjectTypeId: number | null;
	resourceId: number | null;
	onlyActivitiesStartedByMe: boolean;
}

export const ProjectActivityJobQueueBaseDataGrid = ({
	fetchData,
	refreshRef,
	onSelectionChanged,
	showActivityStartInfoColumns = false,
	showResponsiblePersonColumn = false,
	subProjectTypeFiltering = false,
	resourceFiltering = false,
	onlyActivitiesStartedByMeFiltering = false,
	actionBarComponents,
	gridId,
	showActualStartDate = false,
}: ProjectActivityJobQueueBaseDataGridProps) => {
	const isExternalProjectUser = useUserPermissions().project.isExternalProjectUser;

	return (
		<ServerSideDataGridModel<
			ProjectActivityJobQueueApi_DataGridData,
			ProjectActivityCustomerOrderView,
			ProjectActivityJobQueueDataGridFilterParams
		>
			fetchData={fetchData}
			refreshRef={refreshRef}
			getDataModelResult={(data) => data.data}
			gridId={gridId}
			initialParams={{
				searchQuery: "",
				subProjectTypeId: null,
				resourceId: null,
				onlyActivitiesStartedByMe: onlyActivitiesStartedByMeFiltering,
			}}
			getRowId={(row) => row.activityId}
			onSelectionChanged={onSelectionChanged}
			render={({
				dataGridProps,
				refreshData,
				currentParams,
				selectedRows,
				onlySelectedRow,
				data: { subProjectTypeOptions, resourceOptions },
			}) => (
				<AavoDataGrid<ProjectActivityCustomerOrderView>
					columns={[
						{
							field: "activityDescription",
							headerName: i18n.t("activity"),
							valueGetter: (_, row) =>
								`${row.projectDescription} | ${row.subProjectDescription} | ${row.activityName}`,
							filterable: false,
							sortable: false,
							width: 250,
						},
						showActualStartDate &&
							dateColumn({
								field: "actualStartDate",
							headerName: i18n.t("started_at"),
								width: 150,
								valueFormatter: (value) =>
									`${formatDayJs(value)} (${i18n.t("week_abbr")} ${value.week()})`,
							}),
						dateColumn({
							field: "plannedStartDate",
							headerName: i18n.t("planned_start_date_short"),
							width: 150,
							valueFormatter: (value) => `${formatDayJs(value)} (${i18n.t("week_abbr")} ${value.week()})`,
						}),
						dateColumn({
							field: "plannedEndDate",
							headerName: i18n.t("planned_end_date_short"),
							width: 150,
							valueFormatter: (value) => `${formatDayJs(value)} (${i18n.t("week_abbr")} ${value.week()})`,
						}),
						showResponsiblePersonColumn &&
							textColumn({
								field: "responsiblePersonName",
								headerName: i18n.t("responsible_person"),
								width: 150,
							}),
						textColumn({
							field: "note",
							headerName: i18n.t("note"),
							width: 200,
						}),
						textColumn({
							field: "customerName",
							headerName: i18n.t("customer"),
							width: 200,
						}),
						dateColumn({
							field: "customerOrderPlannedDeliveryDate",
							headerName: i18n.t("customer_order_planned_delivery_date_short"),
							width: 200,
						}),
						textColumn({
							field: "customerOrderResponsiblePersonName",
							headerName: i18n.t("customer_order_responsible_person"),
							width: 200,
						}),
						textColumn({
							field: "customerOrderContactName",
							headerName: i18n.t("customer_order_contact"),
							width: 200,
						}),
						showActivityStartInfoColumns && [
							textColumn({
								field: "startedByUserName",
								headerName: i18n.t("started_by"),
								width: 200,
							}),
							dateTimeColumn({
								field: "actualStartDate",
								headerName: i18n.t("started_at"),
								width: 200,
							}),
						],
						textColumn({
							field: "activityId",
							headerName: i18n.t("id"),
						}),
					]}
					actionBarComponents={
						<>
							<AavoTextField
								label={i18n.t("search")}
								defaultValue={currentParams.searchQuery}
								onSubmit={(searchQuery) => refreshData({ searchQuery })}
							/>
							{subProjectTypeFiltering && (
								<SelectField
									label={i18n.t("sub_project_type")}
									options={subProjectTypeOptions}
									getOptionKey={(option) => option.subProjectTypeId}
									getOptionLabel={(option) => option.name}
									value={currentParams.subProjectTypeId}
									onChange={(subProjectTypeId) => refreshData({ subProjectTypeId })}
									placeholder={i18n.t("all")}
								/>
							)}
							{resourceFiltering && (
								<SelectField
									label={i18n.t("resource")}
									options={resourceOptions}
									getOptionKey={(option) => option.activityResourceId}
									getOptionLabel={(option) => option.resourceName}
									value={currentParams.resourceId}
									onChange={(resourceId) => refreshData({ resourceId })}
									placeholder={i18n.t("all")}
								/>
							)}
							{onlyActivitiesStartedByMeFiltering && (
								<AavoCheckbox
									label={i18n.t("only_started_by_me")}
									checked={currentParams.onlyActivitiesStartedByMe}
									onChange={(onlyActivitiesStartedByMe) => refreshData({ onlyActivitiesStartedByMe })}
									sx={{
										mr: 1,
									}}
								/>
							)}
							{actionBarComponents?.({ onlySelectedRow, selectedRows })}
						</>
					}
					rowContextMenuComponents={({ row }) => [
						!isExternalProjectUser && row.customerOrderId != null && (
							<OpenCustomerOrderButton key={"openCustomerOrder"} customerOrderId={row.customerOrderId} />
						),
						!isExternalProjectUser && (
							<OpenProjectInTreeViewButton key={"openProject"} projectId={row.projectId} />
						),
					]}
					{...dataGridProps}
				/>
			)}
		/>
	);
};
