// This file is automatically generated. Do not edit manually.

import { CatalogPartSitePartsView } from "src/api/generated/erp/db/types/tables/catalogPartSitePartsView";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace CatalogPartSitesDataGridApi {
	export async function getCatalogPartSites(args: {
		catalogPartId: number;
	}): Promise<Array<CatalogPartSitePartsView>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/productManagement/catalogPartSites/getCatalogPartSites",
			method: "POST",
			data: {
				catalogPartId: args.catalogPartId,
			},
		});
		return response as Array<CatalogPartSitePartsView>;
	}

	export async function syncCatalogPartDocumentsToSites(args: { catalogPartId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/productManagement/catalogPartSites/syncCatalogPartDocumentsToSites",
			method: "POST",
			data: {
				catalogPartId: args.catalogPartId,
			},
		});
		return response as void;
	}
}
