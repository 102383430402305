import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import fiTranslations from "src/localization/languages/fi.json";
import enTranslations from "src/localization/languages/en.json";
import { SUPPORTED_LANGUAGES } from "src/types/languages.ts";
import {
	AAVO_LANGUAGE_COOKIE_EXPIRATION_MINUTES,
	AAVO_LANGUAGE_COOKIE_ITEM,
} from "src/cookies/language.ts";
import { DEFAULT_LANGUAGE } from "src/storage/settings.ts";

const resources = {
	en: {
		translation: enTranslations,
	},
	fi: {
		translation: fiTranslations,
	},
} as const;

declare module "i18next" {
	interface CustomTypeOptions {
		resources: (typeof resources)["en"];
	}
}

i18n.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		detection: {
			order: ["querystring", "cookie", "navigator", "htmlTag"],
			lookupCookie: AAVO_LANGUAGE_COOKIE_ITEM,
			caches: ["cookie"],
			cookieMinutes: AAVO_LANGUAGE_COOKIE_EXPIRATION_MINUTES,
		},
		resources: resources,
		supportedLngs: SUPPORTED_LANGUAGES,
		nonExplicitSupportedLngs: true,
		fallbackLng: DEFAULT_LANGUAGE,
		debug: false,
		interpolation: {
			escapeValue: false, // not needed for react!!
		},
	});
