export class AavoInputFieldCellEditor {
	init(params) {
		this.inputField = document.createElement("input-field-component");

		if (params.showAsPopup) this.inputField.classList.add("position-fixed");

		this.inputValue = params.value ? params.value : null;

		const nodeId = params.node.id;
		const columnId = params.colDef.colId;
		const rowData = params.node.data;
		const rowDataParams = Object.keys(rowData).map((key) => ({
			key: key,
			value: rowData[key].value,
		}));
		const fieldParams = params.getAavoParams().concat(rowDataParams);

		this.inputField.flags = {
			inputField: params.inputField,
			viewConfiguration: params.viewConfiguration,
			showLabel: false,
			autoFocus: true,
			readOnly: false,
			updateOnEveryKeyPress: true,
			fieldValue: this.inputValue,
			params: fieldParams,
		};

		this.inputField.addEventListener("stateChanged", (event) => {
			const newValue = event.detail.newValue;
			if (newValue && newValue.value !== this.inputValue.value) {
				this.inputValue = newValue;
				params.markRowToUnsaved(nodeId);
				params.node.setDataValue(columnId, this.inputValue);
			}
			const validationError = event.detail.validationError;
			if (validationError)
				params.setValidationError(nodeId, columnId, validationError);
			else {
				params.removeValidationError(nodeId, columnId);
				// AgGrid cellClassRules does not reflect changes when editing, so we have
				// to remove invalid-input class manually here.
				params.eGridCell.classList.remove(
					"ag-grid__cell--invalid-input"
				);
			}
		});
		this.inputField.addEventListener("fieldSubmitted", (event) => {
			this.inputValue = event.detail;
			params.markRowToUnsaved(nodeId);
			params.stopEditing();
		});
		this.documentClickEventHandler = (event) => {
			// Stop editing when users click somewhere on document,
			// but don't if the click target is a flatpickr calendar
			if (!flatpickrCalendarIsInEventPath(event)) params.stopEditing();
		};
		document.addEventListener("click", this.documentClickEventHandler);

		this.inputField.addEventListener("keydown", (event) => {
			// Prevent default on Shift+Enter to continue editing with new row.
			// Stop editing with plain Enter.
			if (event.key === "Enter" && event.shiftKey) {
				event.stopPropagation();
				return false;
			}
		});

		// Stop propagation on keypress to prevent save/new row shortcuts (Shift+S, Shift+N) to happen
		// when typing inside cells.
		this.inputField.addEventListener("keypress", (event) => {
			event.stopPropagation();
			return false;
		});
	}

	getGui() {
		return this.inputField;
	}

	getValue() {
		return this.inputValue;
	}

	destroy() {
		document.removeEventListener("click", this.documentClickEventHandler);
	}
}

const flatpickrCalendarIsInEventPath = (event) => {
	const eventPath =
		event.path || (event.composedPath && event.composedPath());
	if (!eventPath) return false;
	const allClassesOnEventPath = eventPath
		.flatMap((p) => p.classList)
		.filter((c) => c)
		.map((c) => c.toString());
	return allClassesOnEventPath.some((c) => c.includes("flatpickr-calendar"));
};
