// This file is automatically generated. Do not edit manually.

import { t } from "i18next";

export const InvoiceChannelValues = ["EMAIL", "EINVOICE", "NO_SENDING"] as const;

export type InvoiceChannel = (typeof InvoiceChannelValues)[number];

export const getInvoiceChannelLabels = () => ({
	EMAIL: t("email"),
	EINVOICE: t("einvoice"),
	NO_SENDING: t("no_sending"),
});

export const getInvoiceChannelLabel = (value: InvoiceChannel): string => {
	return getInvoiceChannelLabels()[value];
};
