import {
	DeliveryPackagesWithLinesView
} from "src/components/views/erp/delivery/deliveryPackage/DeliveryPackagesWithLinesView.tsx";

export const AllUndeliveredCustomerOrderPackagesView = () => {
	return (
		<DeliveryPackagesWithLinesView
			deliverySourceType={"CUSTOMER_ORDER"}
			gridId={"548BE3FC5D394C90"}
			packageStates={["INITIAL", "COMPLETED"]}
			editable
			showSearchField
			showSourceDocumentsButton
		/>
	);
};
