import { Duration } from "dayjs/plugin/duration";
import { SxProps, Theme } from "@mui/material/styles";
import { Typography } from "@mui/material";
import { faPen } from "@fortawesome/pro-regular-svg-icons";
import { useGenericDialog } from "src/components/common/dialogs/useGenericDialog.ts";
import { AavoButton } from "src/components/common/buttons/AavoButton.tsx";
import { AavoForm } from "src/components/common/forms/AavoForm.tsx";
import { FormCommonProps } from "src/components/common/forms/types.ts";
import dayjs from "dayjs";
import { FormNumberField } from "src/components/common/forms/fields/FormNumberField.tsx";
import i18n from "i18next";
import { HorizontalBox } from "src/components/common/box/HorizontalBox.tsx";
import { separateDuration } from "src/components/common/inputFields/DurationField.utils.ts";
import { mergeSx } from "src/utils/styles.ts";
import {VerticalBox} from "src/components/common/box/VerticalBox.tsx";

export interface DurationFieldProps {
	label: string;
	value: Duration | null;
	onChange: (value: Duration) => void;
	sx?: SxProps<Theme>;
	error?: string;
}

export const DurationField = ({ label, value, onChange, sx, error }: DurationFieldProps) => {
	const { openDialog } = useGenericDialog();
	const durationString = getDurationString(value);
	return (
		<VerticalBox>
			<HorizontalBox
				sx={mergeSx(
					{
						border: "1px solid",
						borderRadius: "3px",
						borderColor: error ? "error.main" : "grey.400",
						padding: "0.5rem",
						justifyContent: "space-between",
						color: error ? "error.main" : "black",
						position: "relative",
						alignItems: "center",
					},
					sx,
				)}
			>
				<Typography
					sx={{
						position: "absolute",
						top: "-0.75rem",
						backgroundColor: "background.paper",
						padding: "0 0.5rem",
						color: error ? "error.main" : "primary.main",
					}}
				>
					{label}
				</Typography>
				<Typography
					sx={{
						marginLeft: "0.25rem",
					}}
				>
					{durationString}
				</Typography>
				<AavoButton
					icon={faPen}
					onClick={() =>
						openDialog(({ closeDialog }) => ({
							size: "sm",
							title: label,
							content: (
								<DurationForm
									defaultValue={value ?? null}
									onCompleted={async (result) => {
										if (result.type === "cancel") return;
										onChange(result.value);
										await closeDialog();
									}}
								/>
							),
						}))
					}
				/>
			</HorizontalBox>
			{error != null && (
				<Typography
					sx={{
						marginLeft: "0.75rem",
						marginTop: "0.25rem",
					}}
					fontSize={"0.75rem"} color={"error"}>
					{error}
				</Typography>
			)}
		</VerticalBox>
	);

	function getDurationString(duration: Duration | null): string {
		if (duration == null) return "-";
		const { days, hours, minutes } = separateDuration(duration);
		return `${days}d ${hours}h ${minutes}m`;
	}
};

interface DurationFormProps extends FormCommonProps<Duration> {
	defaultValue: Duration | null;
}

interface FormValues {
	days: number;
	hours: number;
	minutes: number;
}

const DurationForm = ({ defaultValue, onCompleted }: DurationFormProps) => {
	return (
		<AavoForm<FormValues, Duration>
			onCompleted={onCompleted}
			defaultValues={
				defaultValue != null ? separateDuration(defaultValue) : { days: 0, hours: 0, minutes: 0 }
			}
			submit={submitForm}
			render={({ control }) => (
				<>
					<FormNumberField control={control} name={"days"} label={i18n.t("days")} />
					<FormNumberField control={control} name={"hours"} label={i18n.t("hours")} />
					<FormNumberField control={control} name={"minutes"} label={i18n.t("minutes")} />
				</>
			)}
		/>
	);

	function submitForm({ days, hours, minutes }: FormValues): Duration {
		return dayjs.duration({ days: days, hours: hours, minutes: minutes });
	}
};
