// This file is automatically generated. Do not edit manually.

import { SupplierPartView } from "src/api/generated/erp/db/types/tables/supplierPartView";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace SitePartSupplierApi {
	export async function getPartSuppliers(args: { partId: number }): Promise<Array<SupplierPartView>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/warehouse/part/supplier/getPartSuppliers",
			method: "POST",
			data: {
				partId: args.partId,
			},
		});
		return response as Array<SupplierPartView>;
	}

	export async function setDefaultSupplier(args: { partId: number; supplierId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/warehouse/part/supplier/setDefaultSupplier",
			method: "POST",
			data: {
				partId: args.partId,
				supplierId: args.supplierId,
			},
		});
		return response as void;
	}
}
