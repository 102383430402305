import i18n from "i18next";
import { InvoiceView } from "src/api/generated/erp/db/types/tables/invoiceView.ts";
import { InvoiceContentView } from "src/components/views/erp/sales/invoicing/InvoiceContentView.tsx";
import { InvoiceLinesDataGrid } from "src/components/views/erp/sales/invoicing/InvoiceLinesDataGrid.tsx";
import { AavoMosaic } from "src/components/common/mosaic/AavoMosaic.tsx";

export interface InspectInvoiceViewProps {
	invoice: InvoiceView;
}

export const InspectInvoiceView = ({ invoice }: InspectInvoiceViewProps) => {
	return (
		<AavoMosaic
			viewId={"66D749A534DEFB11"}
			layout={{
				type: "row",
				splitPercentage: 30,
				first: {
					type: "panel",
					title: i18n.t("invoice"),
					content: <InvoiceContentView invoice={invoice} />,
				},
				second: {
					type: "panel",
					title: i18n.t("invoice_lines"),
					content: <InvoiceLinesDataGrid invoice={invoice} />,
				},
			}}
		/>
	);
};
