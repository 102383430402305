import { ShopOrderBatchJobQueueMobileListViewBase } from "src/components/views/erp/production/shopOrderBatch/shopOrderBatchJobQueue/mobile/ShopOrderBatchJobQueueMobileListViewBase.tsx";
import { faPlay } from "@fortawesome/pro-regular-svg-icons";
import i18n from "i18next";
import { ShopOrderBatchActionApi } from "src/api/generated/erp/production/shopOrderBatch/api/shopOrderBatchActionApi.ts";
import { ShopOrderBatchJobQueueApi } from "src/api/generated/erp/production/shopOrderBatch/api/shopOrderBatchJobQueueApi.ts";
import { RefreshableElementRef } from "src/utils/useRefreshRef.ts";
import { AsyncButton } from "src/components/common/buttons/AsyncButton.tsx";

export interface ShopOrderBatchJobQueueInitialMobileViewProps {
	refreshRef: RefreshableElementRef;
	inProgressBatchesRefreshRef: RefreshableElementRef;
	focusToInProgressBatches: () => void;
}

export const ShopOrderBatchJobQueueInitialMobileView = ({
	refreshRef,
	inProgressBatchesRefreshRef,
	focusToInProgressBatches,
}: ShopOrderBatchJobQueueInitialMobileViewProps) => {
	return (
		<ShopOrderBatchJobQueueMobileListViewBase
			refreshRef={refreshRef}
			fetchData={() => ShopOrderBatchJobQueueApi.getWorkcenterInitialShopOrderBatches()}
			singleBatchDialogExtra={({ shopOrderBatch, closeDialog, reload }) => {
				return (
					<AsyncButton
						icon={faPlay}
						label={i18n.t("start")}
						variant="contained"
						onClick={async () => {
							await ShopOrderBatchActionApi.startShopOrderBatchUserWorkcenterOperations({
								shopOrderBatchId: shopOrderBatch.shopOrderBatchId,
							});
							await closeDialog();
							focusToInProgressBatches();
							await Promise.all([reload(), inProgressBatchesRefreshRef.refresh()]);
						}}
					/>
				);
			}}
		/>
	);
};
