import React from "react";
import { useContextOrThrow } from "src/utils/useContextOrThrow.tsx";
import { ConfigurationComponentView } from "src/api/generated/erp/db/types/tables/configurationComponentView.ts";
import { ConfigurationProperty } from "src/api/generated/erp/db/types/tables/configurationProperty.ts";

export interface CatalogPartRevisionConfiguratorSelectionsViewContextValue {
	catalogPartRevisionId: number;
	tabComponents: TabComponentWithChildren[];
	setComponentSelectionsOnTab: (tabComponentId: number, selectedComponentIds: number[]) => void;
	allComputedProperties: ConfigurationProperty[];
	selectedPropertyIds: number[];
	setSelectedPropertyIds: (selectedPropertyIds: number[]) => void;
	submitAndClose?: () => Promise<unknown>;
	cancelAndClose?: () => Promise<unknown>;
}

export interface TabComponentWithChildren {
	tabComponent: ConfigurationComponentView;
	childComponents: ConfigurationComponentView[];
	selectedChildComponentsIds: number[];
}

export const CatalogPartRevisionConfiguratorSelectionsViewContext = React.createContext<
	CatalogPartRevisionConfiguratorSelectionsViewContextValue | undefined
>(undefined);

export const useCatalogPartRevisionConfiguratorManagingViewContext = () =>
	useContextOrThrow(CatalogPartRevisionConfiguratorSelectionsViewContext);
