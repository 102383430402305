// This file is automatically generated. Do not edit manually.

import { IsoDateString } from "src/types/dateTime";
import { ConfigurationPriceCalculationMethod } from "src/api/generated/erp/db/types/enums/configurationPriceCalculationMethod";
import { PartView } from "src/api/generated/erp/db/types/tables/partView";
import { PartRevisionView } from "src/api/generated/erp/db/types/tables/partRevisionView";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace SitePartRevisionsDataGridApi {
	export async function getData(args: {
		partId: number;
		catalogPartRevisionId: number | null | undefined;
	}): Promise<SitePartRevisionsDataGridApi_DataGridData> {
		const response = await makeAxiosRequest({
			url: "/api/ext/warehouse/part/revisions/getData",
			method: "POST",
			data: {
				partId: args.partId,
				catalogPartRevisionId: args.catalogPartRevisionId,
			},
		});
		return response as SitePartRevisionsDataGridApi_DataGridData;
	}

	export async function releaseRevision(args: { partRevisionId: number; validFrom: IsoDateString }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/warehouse/part/revisions/releaseRevision",
			method: "POST",
			data: {
				partRevisionId: args.partRevisionId,
				validFrom: args.validFrom,
			},
		});
		return response as void;
	}

	export async function revertReleasePartRevision(args: { partRevisionId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/warehouse/part/revisions/revertReleasePartRevision",
			method: "POST",
			data: {
				partRevisionId: args.partRevisionId,
			},
		});
		return response as void;
	}

	export async function calculatePartRevisionCosts(args: { partRevisionId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/warehouse/part/revisions/calculatePartRevisionCosts",
			method: "POST",
			data: {
				partRevisionId: args.partRevisionId,
			},
		});
		return response as void;
	}

	export async function updatePartStandardCost(args: { partRevisionId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/warehouse/part/revisions/updatePartStandardCost",
			method: "POST",
			data: {
				partRevisionId: args.partRevisionId,
			},
		});
		return response as void;
	}

	export async function saveNote(args: { partRevisionId: number; note: string }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/warehouse/part/revisions/saveNote",
			method: "POST",
			data: {
				partRevisionId: args.partRevisionId,
				note: args.note,
			},
		});
		return response as void;
	}
}

export interface SitePartRevisionsDataGridApi_DataGridData {
	configurationPriceCalculationMethod: ConfigurationPriceCalculationMethod | null | undefined;
	part: PartView;
	partRevisions: Array<PartRevisionView>;
}
