// This file is automatically generated. Do not edit manually.

import { IsoDateString } from "src/types/dateTime";
import { CostEventObjectRef } from "src/api/generated/erp/common/costEvents/costEventObjectRef";
import { CostEvent } from "src/api/generated/erp/db/types/tables/costEvent";
import { CostEventItem } from "src/api/generated/erp/db/types/tables/costEventItem";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace CostEventFormApi {
	export async function getInitData(args: {
		costEventId: number | null | undefined;
		costEventPriceListId: number;
	}): Promise<CostEventFormApi_InitData> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/costEvents/costEventForm/getInitData",
			method: "POST",
			data: {
				costEventId: args.costEventId,
				costEventPriceListId: args.costEventPriceListId,
			},
		});
		return response as CostEventFormApi_InitData;
	}

	export async function createNew(args: {
		costEventItemId: number;
		costEventPriceListId: number;
		description: string | null | undefined;
		quantity: number | null | undefined;
		eventDate: IsoDateString;
		objectRef: CostEventObjectRef;
	}): Promise<number> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/costEvents/costEventForm/createNew",
			method: "POST",
			data: {
				costEventItemId: args.costEventItemId,
				costEventPriceListId: args.costEventPriceListId,
				description: args.description,
				quantity: args.quantity,
				eventDate: args.eventDate,
				objectRef: args.objectRef,
			},
		});
		return response as number;
	}

	export async function update(args: { costEvent: CostEvent; costEventPriceListId: number }): Promise<number> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/costEvents/costEventForm/update",
			method: "POST",
			data: {
				costEvent: args.costEvent,
				costEventPriceListId: args.costEventPriceListId,
			},
		});
		return response as number;
	}
}

export interface CostEventFormApi_InitData {
	availableCostEventItems: Array<CostEventItem>;
	costEvent: CostEvent | null | undefined;
}
