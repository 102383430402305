import { HorizontalBox } from "src/components/common/box/HorizontalBox.tsx";
import { VerticalBox } from "src/components/common/box/VerticalBox.tsx";
import { List, ListItem, ListItemButton, ListItemText, Tooltip, useTheme } from "@mui/material";
import i18n from "i18next";
import {
	faArrowDown,
	faArrowUp,
	faExclamationCircle,
	faPlus,
	faTrash,
} from "@fortawesome/pro-regular-svg-icons";
import { SubConfiguratorListItemState } from "src/components/views/erp/configurator/configuratorForm/components/subConfiguratorList/SubConfiguratorListItemState.tsx";
import { AsyncButton } from "src/components/common/buttons/AsyncButton.tsx";
import { useConfirmDialog } from "src/components/common/dialogs/confirmDialog/useConfirmDialog.ts";
import { AavoLoading } from "src/components/common/AavoLoading.tsx";
import { useErrorDialog } from "src/components/common/dialogs/errorDialog/userErrorDialog.ts";
import { mergeSx } from "src/utils/styles.ts";
import { overlaySx } from "src/styles/sx.ts";
import Typography from "@mui/material/Typography";
import { alpha } from "@mui/material/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export interface SubConfiguratorSelectionViewProps {
	subConfiguratorStates: SubConfiguratorListItemState[];
	selectedSubConfiguratorKey: string | undefined;
	selectSubConfigurator: (key: string) => Promise<unknown>;
	addSubConfigurator: () => Promise<unknown>;
	removeSubConfigurator: (key: string) => void;
	swapSubConfigurators: (key1: string, key2: string) => void;
}

export const SubConfiguratorSelectionView = ({
	subConfiguratorStates,
	selectSubConfigurator,
	selectedSubConfiguratorKey,
	addSubConfigurator,
	removeSubConfigurator,
	swapSubConfigurators,
}: SubConfiguratorSelectionViewProps) => {
	const showConfirmDialog = useConfirmDialog();

	return (
		<VerticalBox
			sx={{
				gap: 1,
				width: 380,
				borderRight: "1px solid",
				borderColor: "grey.300",
			}}
		>
			{subConfiguratorStates.length === 0 ?
				<Typography padding={2} fontStyle={"italic"}>
					--- {i18n.t("no_sub_configurations")} ---
				</Typography>
			:	<List
					sx={{
						overflow: "auto",
					}}
				>
					{subConfiguratorStates.map((subConfiguratorState, index) => {
						const { key } = subConfiguratorState;
						return (
							<SubConfiguratorListItem
								{...subConfiguratorState}
								key={key}
								subConfiguratorKey={key}
								isSelected={key === selectedSubConfiguratorKey}
								onClick={() => selectSubConfigurator(key)}
								onRemove={() => onRemoveSubConfiguratorClick(key)}
								swapSubConfigurators={swapSubConfigurators}
								subConfiguratorStates={subConfiguratorStates}
								index={index}
							/>
						);
					})}
				</List>
			}
			<HorizontalBox
				sx={{
					paddingY: 1,
					paddingX: 2,
				}}
			>
				<AsyncButton
					icon={faPlus}
					label={i18n.t("new")}
					variant={"contained"}
					onClick={async () => {
						await addSubConfigurator();
					}}
				/>
			</HorizontalBox>
		</VerticalBox>
	);

	async function onRemoveSubConfiguratorClick(key: string) {
		const confirmed = await showConfirmDialog();
		if (!confirmed) return;

		removeSubConfigurator(key);
	}
};

interface SubConfiguratorListItemProps
	extends Pick<SubConfiguratorSelectionViewProps, "swapSubConfigurators" | "subConfiguratorStates">,
		Omit<SubConfiguratorListItemState, "key"> {
	subConfiguratorKey: string;
	onRemove: () => Promise<unknown>;
	isSelected: boolean;
	onClick: () => Promise<unknown>;
	index: number;
}

const SubConfiguratorListItem = ({
	subConfiguratorKey,
	subConfigurationValue,
	errors,
	isLoading,
	onRemove,
	isSelected,
	onClick,
	index,
	subConfiguratorStates,
	swapSubConfigurators,
}: SubConfiguratorListItemProps) => {
	const { withErrorHandling } = useErrorDialog();

	const previousSubConfiguratorKey = subConfiguratorStates[index - 1]?.key;
	const nextSubConfiguratorKey = subConfiguratorStates[index + 1]?.key;

	return (
		<ListItem disablePadding>
			<ListItemButton
				role={undefined}
				selected={isSelected}
				onClick={() => {
					withErrorHandling(async () => {
						await onClick();
					});
				}}
				sx={{
					paddingRight: 0.5,
				}}
			>
				<ListItemText
					primary={
						<HorizontalBox alignItems={"center"} gap={1}>
							<Typography>{subConfigurationValue.label}</Typography>
							<ErrorIcon errors={errors} />
						</HorizontalBox>
					}
				/>
				<HorizontalBox position={"relative"}>
					<AsyncButton
						icon={faTrash}
						size={"small"}
						onClick={async (e) => {
							e.stopPropagation();
							await onRemove();
						}}
					/>
					<VerticalBox>
						<AsyncButton
							icon={faArrowUp}
							size={"small"}
							disabled={!previousSubConfiguratorKey}
							onClick={async (e) => {
								e.stopPropagation();
								swapSubConfigurators(subConfiguratorKey, previousSubConfiguratorKey!);
							}}
						/>
						<AsyncButton
							icon={faArrowDown}
							size={"small"}
							disabled={!nextSubConfiguratorKey}
							onClick={async (e) => {
								e.stopPropagation();
								swapSubConfigurators(subConfiguratorKey, nextSubConfiguratorKey!);
							}}
						/>
					</VerticalBox>
					{isLoading && (
						<HorizontalBox
							sx={mergeSx(overlaySx, {
								opacity: 0.5,
								backgroundColor: ({ palette }) => alpha(palette.background.default, 0.5),
								alignItems: "center",
								justifyContent: "center",
							})}
						>
							<AavoLoading size={"lg"} />
						</HorizontalBox>
					)}
				</HorizontalBox>
			</ListItemButton>
		</ListItem>
	);
};

const ErrorIcon = ({ errors }: { errors: string[] }) => {
	const { palette } = useTheme();

	if (errors.length === 0) return null;

	const tooltipContent = (
		<VerticalBox>
			{errors.map((error) => (
				<Typography key={error}>{error}</Typography>
			))}
		</VerticalBox>
	);

	return (
		<Tooltip title={tooltipContent}>
			<FontAwesomeIcon icon={faExclamationCircle} color={palette.error.main} />
		</Tooltip>
	);
};
