import { SpcSamplingMethod } from "src/components/views/spc/basedata/controlCharts/form/samplingMethod/SpcSamplingMethod.tsx";
import i18n from "i18next";

export const getCumulativeRecordSamplingMethod = (): SpcSamplingMethod => {
	return {
		key: "cumulative_record",
		label: i18n.t("cumulative_counter"),
		renderParameterFields: () => {
			return <></>;
		},
	};
};
