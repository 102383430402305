import { useState } from "react";
import i18n from "i18next";
import { DocumentBaseFields } from "./types";
import { DocumentRevisionsDataGrid } from "./DocumentRevisionsDataGrid";
import { DocumentPreviewView } from "src/components/views/documents/preview/DocumentPreviewView.tsx";
import { DocumentRevisionView } from "src/api/generated/postgres/db/types/documents/tables/documentRevisionView.ts";
import { useMediaQueryBreakpointUp } from "src/utils/breakpointUtils.ts";
import { AavoMosaic } from "src/components/common/mosaic/AavoMosaic.tsx";
import { DocumentsViewEditable } from "../types.ts";
import { CenteredTypography } from "src/components/common/CenteredTypography.tsx";

export interface DocumentRevisionsViewProps {
	document: DocumentBaseFields;
	editable: DocumentsViewEditable;
}

export const DocumentRevisionsView = ({ document, editable }: DocumentRevisionsViewProps) => {
	const [selectedRevision, setSelectedRevision] = useState<DocumentRevisionView | undefined>(undefined);

	const documentRevisionsDataGrid = (
		<DocumentRevisionsDataGrid
			document={document}
			onSelectionChanged={setSelectedRevision}
			editable={editable}
		/>
	);

	const showPreview = useMediaQueryBreakpointUp("md");
	if (!showPreview) return documentRevisionsDataGrid;

	return (
		<AavoMosaic
			viewId={"65D749A534DEFB11"}
			layout={{
				type: "row",
				splitPercentage: 65,
				first: {
					type: "panel",
					title: i18n.t("revisions"),
					content: documentRevisionsDataGrid,
				},
				second: {
					type: "panel",
					title: i18n.t("preview"),
					content:
						selectedRevision == null ?
							<CenteredTypography>{i18n.t("select_a_document")}</CenteredTypography>
						:	<DocumentPreviewView
								key={selectedRevision.documentRevisionId}
								documentId={selectedRevision.documentId}
								documentRevisionId={selectedRevision.documentRevisionId}
							/>,
				},
			}}
		/>
	);
};
