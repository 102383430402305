import { AavoMosaic } from "src/components/common/mosaic/AavoMosaic";
import { TestConfigurationResultPropertiesDataGrid } from "./TestConfigurationResultPropertiesDataGrid";
import i18n from "i18next";
import { ConfiguratorSessionLogView } from "./logViews/ConfiguratorSessionLogView";
import { TestConfigurationResultViewCommonProps } from "src/components/views/erp/configurator/configuratorTesting/TestConfigurationResultViewCommonProps.ts";

export const TestConfigurationResultPropertiesWithLogView = ({
	configurationSessionId,
	configuratorType,
	propertyValues,
}: TestConfigurationResultViewCommonProps) => {
	return (
		<AavoMosaic
			viewId={"BA9CA8371ED45761"}
			layout={{
				type: "row",
				first: {
					type: "panel",
					title: i18n.t("properties"),
					content: (
						<TestConfigurationResultPropertiesDataGrid
							configuratorType={configuratorType}
							propertyValues={propertyValues}
						/>
					),
				},
				second: {
					type: "panel",
					title: i18n.t("configurator_log"),
					content: (
						<ConfiguratorSessionLogView
							sessionId={configurationSessionId}
							configuratorType={configuratorType}
						/>
					),
				},
			}}
		/>
	);
};
