import {AsyncCrudDataGrid} from "../../../../common/dataGrid/crud/AsyncCrudDataGrid.tsx";
import {WarehousePickerGroup} from "../../../../../api/generated/erp/db/types/tables/warehousePickerGroup.ts";
import {dataGridPersistentStateProps} from "../../../../common/dataGrid/dataGridStateStorage.ts";
import i18n from "i18next";
import {textColumn} from "../../../../common/dataGrid/columns.tsx";
import {
    WarehousePickerGroupDataCrudApi
} from "../../../../../api/generated/erp/warehouse/basedata/api/warehousePickerGroupDataCrudApi.ts";

export const WarehousePickerGroupsDataGrid = () => {
	return (
		<AsyncCrudDataGrid<WarehousePickerGroup>
			fetchData={WarehousePickerGroupDataCrudApi.getAll}
			getRowId={(row) => {
				return row.warehousePickerGroupId;
			}}
			columns={[
				textColumn({
					headerName: i18n.t("code"),
					field: "warehousePickerGroupCode",
					editable: true,
					validate: "required",
				}),
				textColumn({
					headerName: i18n.t("description"),
					field: "warehousePickerGroupDescription",
					editable: true,
					width: 500
				}),
			]}
			add={{
				type: "enabled",
				action: async () => {
					return {
						warehousePickerGroupCode: "",
						warehousePickerGroupDescription: "",
						lastUpdated: new Date().toISOString(),
					};
				},
			}}
			save={{
				type: "enabled",
				action: async (params) =>
					await WarehousePickerGroupDataCrudApi.save({
						warehousePickerGroups: params.items,
					}),
			}}
			remove={{
				type: "enabled",
				action: async (params) =>
					await WarehousePickerGroupDataCrudApi.delete_({
						warehousePickerGroups: params.items,
					}),
			}}
			{...dataGridPersistentStateProps("37FD8172430C34EF")}
		/>
	);
};
