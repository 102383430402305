// This file is automatically generated. Do not edit manually.

import { ServerSideDataModelRequest } from "src/api/generated/common/dataGrids/serverSideDataModelRequest";
import { ServerSideDataModelResult } from "src/api/generated/common/dataGrids/serverSideDataModelResult";
import { ProjectActivityCustomerOrderView } from "src/api/generated/erp/db/types/tables/projectActivityCustomerOrderView";
import { ProjectActivityResource } from "src/api/generated/erp/db/types/tables/projectActivityResource";
import { SubProjectType } from "src/api/generated/erp/db/types/tables/subProjectType";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace ProjectActivityJobQueueApi {
	export async function getInitialActivities(args: {
		searchQuery: string;
		subProjectTypeId: number | null | undefined;
		resourceId: number | null | undefined;
		dataModelRequest: ServerSideDataModelRequest;
	}): Promise<ProjectActivityJobQueueApi_DataGridData> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/project/activityJobQueue/getInitialActivities",
			method: "POST",
			data: {
				searchQuery: args.searchQuery,
				subProjectTypeId: args.subProjectTypeId,
				resourceId: args.resourceId,
				dataModelRequest: args.dataModelRequest,
			},
		});
		return response as ProjectActivityJobQueueApi_DataGridData;
	}

	export async function getActivitiesInProgress(args: {
		searchQuery: string;
		subProjectTypeId: number | null | undefined;
		resourceId: number | null | undefined;
		onlyActivitiesStartedByMe: boolean;
		dataModelRequest: ServerSideDataModelRequest;
	}): Promise<ProjectActivityJobQueueApi_DataGridData> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/project/activityJobQueue/getActivitiesInProgress",
			method: "POST",
			data: {
				searchQuery: args.searchQuery,
				subProjectTypeId: args.subProjectTypeId,
				resourceId: args.resourceId,
				onlyActivitiesStartedByMe: args.onlyActivitiesStartedByMe,
				dataModelRequest: args.dataModelRequest,
			},
		});
		return response as ProjectActivityJobQueueApi_DataGridData;
	}

	export async function getCompletedActivities(args: {
		searchQuery: string;
		subProjectTypeId: number | null | undefined;
		resourceId: number | null | undefined;
		dataModelRequest: ServerSideDataModelRequest;
	}): Promise<ProjectActivityJobQueueApi_DataGridData> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/project/activityJobQueue/getCompletedActivities",
			method: "POST",
			data: {
				searchQuery: args.searchQuery,
				subProjectTypeId: args.subProjectTypeId,
				resourceId: args.resourceId,
				dataModelRequest: args.dataModelRequest,
			},
		});
		return response as ProjectActivityJobQueueApi_DataGridData;
	}
}

export interface ProjectActivityJobQueueApi_DataGridData {
	data: ServerSideDataModelResult<ProjectActivityCustomerOrderView>;
	resourceOptions: Array<ProjectActivityResource>;
	subProjectTypeOptions: Array<SubProjectType>;
}
