// This file is automatically generated. Do not edit manually.

import { t } from "i18next";

export const InventoryLevelEventTypeValues = [
	"MANUAL_WITHDRAWAL",
	"MANUAL_INPUT",
	"MANUAL_INVENTORY_DIFFERENCE",
	"INVENTORY_DIFFERENCE",
	"CUSTOMER_ORDER_PICKING",
	"CANCEL_CUSTOMER_ORDER_PICKING",
	"SHIPPING_WAREHOUSE_TRANSFER",
	"CANCEL_SHIPPING_WAREHOUSE_TRANSFER",
	"CUSTOMER_ORDER_DELIVERY",
	"CANCEL_CUSTOMER_ORDER_DELIVERY",
	"PURCHASE_ORDER_RECEPTION",
	"CANCEL_PURCHASE_ORDER_RECEPTION",
	"SCRAPPING",
	"CANCEL_SCRAPPING",
	"WAREHOUSE_TRANSFER",
	"CANCEL_WAREHOUSE_TRANSFER",
	"SHOP_ORDER_PICKING",
	"CANCEL_SHOP_ORDER_PICKING",
	"SHOP_ORDER_COMPLETION",
	"CANCEL_SHOP_ORDER_COMPLETION",
	"PROJECT_ACTIVITY_MATERIAL_PICKING",
	"CANCEL_PROJECT_ACTIVITY_MATERIAL_PICKING",
] as const;

export type InventoryLevelEventType = (typeof InventoryLevelEventTypeValues)[number];

export const getInventoryLevelEventTypeLabels = () => ({
	MANUAL_WITHDRAWAL: t("manual_withdrawal"),
	MANUAL_INPUT: t("manual_input"),
	MANUAL_INVENTORY_DIFFERENCE: t("manual_inventory_difference"),
	INVENTORY_DIFFERENCE: t("inventory_difference"),
	CUSTOMER_ORDER_PICKING: t("customer_order_picking"),
	CANCEL_CUSTOMER_ORDER_PICKING: t("cancel_customer_order_picking"),
	SHIPPING_WAREHOUSE_TRANSFER: t("shipping_warehouse_transfer"),
	CANCEL_SHIPPING_WAREHOUSE_TRANSFER: t("cancel_shipping_warehouse_transfer"),
	CUSTOMER_ORDER_DELIVERY: t("customer_order_delivery"),
	CANCEL_CUSTOMER_ORDER_DELIVERY: t("cancel_customer_order_delivery"),
	PURCHASE_ORDER_RECEPTION: t("purchase_order_reception"),
	CANCEL_PURCHASE_ORDER_RECEPTION: t("cancel_purchase_order_reception"),
	SCRAPPING: t("scrapping"),
	CANCEL_SCRAPPING: t("cancel_scrapping"),
	WAREHOUSE_TRANSFER: t("warehouse_transfer"),
	CANCEL_WAREHOUSE_TRANSFER: t("cancel_warehouse_transfer"),
	SHOP_ORDER_PICKING: t("shop_order_picking"),
	CANCEL_SHOP_ORDER_PICKING: t("cancel_shop_order_picking"),
	SHOP_ORDER_COMPLETION: t("shop_order_completion"),
	CANCEL_SHOP_ORDER_COMPLETION: t("cancel_shop_order_completion"),
	PROJECT_ACTIVITY_MATERIAL_PICKING: t("project_activity_material_picking"),
	CANCEL_PROJECT_ACTIVITY_MATERIAL_PICKING: t("cancel_project_activity_material_picking"),
});

export const getInventoryLevelEventTypeLabel = (value: InventoryLevelEventType): string => {
	return getInventoryLevelEventTypeLabels()[value];
};
