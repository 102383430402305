import { ViewAssociation } from "../components/views/legacy/types";
import { makeAxiosRequest } from "./utils/apiClient";
import { IconName } from "@fortawesome/fontawesome-svg-core";
import { KeyValuePair } from "src/utils/keyValuePair.ts";

export namespace MainApi {
	export const getStartUpData = async (): Promise<StartUpData> => {
		return await makeAxiosRequest({
			url: "api/main/startUpData",
			method: "GET"
		});
	};
}

export interface StartUpData {
	appBarView: ViewAssociation;
	views: Record<string, ViewAssociation>;
	viewGroups: Record<string, ViewGroup>;
	codeSeparators: string[];
	defaultParameters: KeyValuePair[];
	environments: Environment[];
	identityOptions: IdentityOption[];
	frontendViews: FrontendView[];
	defaultViewId: number | null;
}

export interface ViewGroup {
	id: number;
	displayName: string;
	icon: IconName;
	parentId: number | null;
	transparent: boolean;
	pos: number[];
}

export interface Branch {
	isProductionBranch: boolean;
	key: string;
	title: string;
}

export interface Environment {
	id: number;
	name: string;
}

export interface IdentityOption {
	name: string;
	principalId: string;
}

export interface FrontendView {
	groupId: number | null;
	key: string;
	pos: number;
	replacesView: number | null;
}
