import { InvoiceView } from "src/api/generated/erp/db/types/tables/invoiceView";
import { InvoiceUpdatingApi } from "src/api/generated/erp/sales/invoicing/api/invoiceUpdatingApi.ts";
import { FormCommonProps } from "src/components/common/forms/types";
import { IsoDateString } from "src/types/dateTime";
import { AavoForm } from "src/components/common/forms/AavoForm.tsx";
import { FormTextField } from "src/components/common/forms/fields/FormTextField.tsx";
import { requireRule } from "src/components/common/forms/validation.ts";
import i18n from "i18next";
import { FormDateField } from "src/components/common/forms/fields/FormDateField.tsx";

export interface InvoiceFormProps extends FormCommonProps<number> {
	invoice: InvoiceView;
}

interface FormValues {
	invoicePrintoutText: string;
	invoiceDate: IsoDateString;
	externalId: string;
}

export const InvoiceForm = ({ invoice, onFormEdited, onCompleted }: InvoiceFormProps) => {
	const submitForm = async (values: FormValues) => {
		await InvoiceUpdatingApi.updateInvoice({
			...values,
			invoiceId: invoice.invoiceId,
		});
		return invoice.invoiceId;
	};

	return (
		<AavoForm
			defaultValues={{
				invoicePrintoutText: invoice.invoicePrintoutText,
				invoiceDate: invoice.invoiceDate,
				externalId: invoice.externalId ?? "",
			}}
			onFormEdited={onFormEdited}
			onCompleted={onCompleted}
			submit={submitForm}
			columns={1}
			render={({ control }) => (
				<>
					<FormTextField control={control} name={"externalId"} label={i18n.t("external_id")} />
					<FormDateField
						control={control}
						name={"invoiceDate"}
						label={i18n.t("invoice_date")}
						rules={requireRule()}
						disabled={invoice.invoiceState !== "INITIAL"}
					/>
					<FormTextField
						control={control}
						name={"invoicePrintoutText"}
						label={i18n.t("invoice_printout_text")}
						multiline={true}
						minRows={2}
					/>
				</>
			)}
		/>
	);
};
