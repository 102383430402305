import { mergeSx } from "src/utils/styles.ts";
import { SxProps, Theme } from "@mui/material/styles";
import { actionBarSxContentOnly } from "src/components/common/actionBar/actionBarStyles.ts";

export const toolbarSx: SxProps<Theme> = mergeSx(actionBarSxContentOnly, {
	display: "flex",
	gap: 0.5,
	alignItems: "center",
	paddingY: "0.25rem",
	minHeight: "2.125rem",
});
