// This file is automatically generated. Do not edit manually.

import { ShopOrderOperation } from "src/api/generated/erp/db/types/tables/shopOrderOperation";
import { ShopOrderOperationView } from "src/api/generated/erp/db/types/tables/shopOrderOperationView";
import { Operation } from "src/api/generated/erp/db/types/tables/operation";
import { ProductionLineView } from "src/api/generated/erp/db/types/tables/productionLineView";
import { ShopOrder } from "src/api/generated/erp/db/types/tables/shopOrder";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace ShopOrderOperationFormApi {
	export async function getInitData(args: {
		shopOrderOperationId: number | null | undefined;
		shopOrderId: number;
	}): Promise<ShopOrderOperationFormApi_InitData> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/shopOrderOperationForm/getInitData",
			method: "POST",
			data: {
				shopOrderOperationId: args.shopOrderOperationId,
				shopOrderId: args.shopOrderId,
			},
		});
		return response as ShopOrderOperationFormApi_InitData;
	}

	export async function insert(args: { shopOrderOperation: ShopOrderOperation }): Promise<number> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/shopOrderOperationForm/insert",
			method: "POST",
			data: {
				shopOrderOperation: args.shopOrderOperation,
			},
		});
		return response as number;
	}

	export async function update(args: { shopOrderOperation: ShopOrderOperation }): Promise<number> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/shopOrderOperationForm/update",
			method: "POST",
			data: {
				shopOrderOperation: args.shopOrderOperation,
			},
		});
		return response as number;
	}
}

export interface ShopOrderOperationFormApi_InitData {
	existingShopOrderOperations: Array<ShopOrderOperationView>;
	operationOptions: Array<Operation>;
	productionLineOptions: Array<ProductionLineView>;
	shopOrder: ShopOrder | null | undefined;
	shopOrderOperation: ShopOrderOperationView | null | undefined;
}
