// This file is automatically generated. Do not edit manually.

import { ShopOrderLine } from "src/api/generated/erp/db/types/tables/shopOrderLine";
import { PartView } from "src/api/generated/erp/db/types/tables/partView";
import { ShopOrderLineView } from "src/api/generated/erp/db/types/tables/shopOrderLineView";
import { ShopOrderOperationView } from "src/api/generated/erp/db/types/tables/shopOrderOperationView";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace ShopOrderLineFormApi {
	export async function getInitData(args: {
		shopOrderId: number;
		shopOrderLineId: number | null | undefined;
	}): Promise<ShopOrderLineFormApi_InitData> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/shopOrderBom/getInitData",
			method: "POST",
			data: {
				shopOrderId: args.shopOrderId,
				shopOrderLineId: args.shopOrderLineId,
			},
		});
		return response as ShopOrderLineFormApi_InitData;
	}

	export async function insert(args: {
		shopOrderLine: ShopOrderLine;
		shopOrderId: number;
		partId: number;
	}): Promise<number> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/shopOrderBom/insert",
			method: "POST",
			data: {
				shopOrderLine: args.shopOrderLine,
				shopOrderId: args.shopOrderId,
				partId: args.partId,
			},
		});
		return response as number;
	}

	export async function update(args: { shopOrderLine: ShopOrderLine; partId: number }): Promise<number> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/shopOrderBom/update",
			method: "POST",
			data: {
				shopOrderLine: args.shopOrderLine,
				partId: args.partId,
			},
		});
		return response as number;
	}
}

export interface ShopOrderLineFormApi_InitData {
	defaultReferenceNo: number;
	part: PartView | null | undefined;
	shopOrderLine: ShopOrderLineView | null | undefined;
	shopOrderOperationOptions: Array<ShopOrderOperationView>;
	siteId: number;
}
