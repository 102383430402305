import { CrudDataGrid, CrudDataGridProps } from "src/components/common/dataGrid/crud/CrudDataGrid";
import { AsyncState } from "src/utils/async/asyncState";
import { AsyncRender } from "src/components/common/async/AsyncRender.tsx";

export interface ControlledAsyncCrudDataGridProps<RowData extends object>
	extends Omit<CrudDataGridProps<RowData>, "rows" | "loading"> {
	rowsAsync: AsyncState<RowData[]>;
}

export const ControlledAsyncCrudDataGrid = <RowData extends object>({
	rowsAsync,
	refreshData,
	...other
}: ControlledAsyncCrudDataGridProps<RowData>) => {
	return (
		<AsyncRender
			asyncData={rowsAsync}
			reloadOnError={refreshData}
			content={(rows) => <CrudDataGrid rows={rows} refreshData={refreshData} {...other} />}
		/>
	);
};
