import flatpickr from "flatpickr";
import { Finnish } from "flatpickr/dist/l10n/fi";
import { LitElement, html } from "lit";
import { DateTime } from "luxon";

class FlatPickr extends LitElement {
	static get properties() {
		return {
			pickerType: { type: String }, // {'date', 'time', 'dateTime'}
			selectedDate: { type: String },
			placeholder: { type: String },
			isOpen: { type: Boolean },
			isDisabled: { type: Boolean },
			addZoneOffsetToResult: { type: Boolean },
		};
	}

	createRenderRoot() {
		return this;
	}

	constructor() {
		super();
		this._picker = null;
		this.pickerType = "date";
		this.selectedDate = null;
		this.placeholder = "";
		this.isDisabled = false;
		this.addZoneOffsetToResult = false;
		this.isOpen = false;
	}

	render() {
		return html`
			<input
				class="flatpickr"
				type="text"
				placeholder="${this.placeholder}"
				data-input
			/>
		`;
	}

	firstUpdated(changedProperties) {
		const altDateFormat = "d.m.Y";
		const altTimeFormat = "H:i";

		const initialDate = this._parseDateInput(this.selectedDate);

		const config = {
			locale: Finnish,
			dateFormat: "U",
			defaultDate: initialDate,
			altInput: true,
			mode: "single",
			enableSeconds: false,
			onChange: (_, dateStr) => this._onSelectedDateChanged(dateStr),
			onClose: (_, dateStr) => this._onClosed(dateStr),
			onOpen: FlatPickr._onPickerOpen.bind(this),
			weekNumbers: true,
		};
		switch (this.pickerType) {
			case "date":
				config.altFormat = altDateFormat;
				break;
			case "time":
				config.enableTime = true;
				config.noCalendar = true;
				config.altFormat = altTimeFormat;
				break;
			case "dateTime":
				config.enableTime = true;
				config.altFormat = `${altDateFormat} ${altTimeFormat}`;
				break;
		}
		this._picker = flatpickr(this._getPickerElement(), config);
	}

	_getPickerElement() {
		return this.querySelector(".flatpickr");
	}

	_onSelectedDateChanged(dateStr) {
		this._dispatchEventWithSelectedDate("selectedDateChanged", dateStr);
	}

	_onClosed(dateStr) {
		this._dispatchEventWithSelectedDate("pickerClosed", dateStr);
	}

	_dispatchEventWithSelectedDate(eventName, selectedDate) {
		const parsedDate = this._parseDateInput(selectedDate);
		const newEvent = new CustomEvent(eventName, {
			detail: {
				dateStr: this._formatOutputDate(parsedDate),
			},
		});
		this.dispatchEvent(newEvent);
	}

	static _onPickerOpen(selectedDates, dateStr, instance) {
		// Add buttons for clearing the date and submitting the window.
		const calendarContainer = instance.calendarContainer;
		const containerClassName = "flatpickr-btn-container";

		const existingButtonContainer =
			calendarContainer.getElementsByClassName(containerClassName)[0];
		if (existingButtonContainer) return;

		const buttonContainer = instance._createElement(
			"div",
			containerClassName,
			""
		);
		buttonContainer.innerHTML = `
            <button class="clear-button btn btn-sm btn-outline-primary" data-clear>Tyhjennä</button>
            <button class="now-button btn btn-sm btn-outline-primary">Nyt</button>
            <button class="ok-button btn btn-sm btn-outline-primary">OK</button>
            `;
		const clearButton = buttonContainer.querySelector(".clear-button");
		clearButton.addEventListener("click", () => {
			if (!this._picker) return;
			this._picker.setDate(null, true);
			this._picker.close();
		});
		const nowButton = buttonContainer.querySelector(".now-button");
		nowButton.addEventListener("click", () => {
			if (!this._picker) return;
			this._picker.setDate(DateTime.local().toJSDate(), true);
			this._picker.close();
		});
		const okButton = buttonContainer.querySelector(".ok-button");
		okButton.addEventListener("click", () => {
			if (!this._picker) return;
			this._picker.close();
		});
		calendarContainer.appendChild(buttonContainer);
	}

	updated(changedProperties) {
		if (!this._picker) return;

		if (changedProperties.has("selectedDate")) {
			const date = this._parseDateInput(this.selectedDate);
			this._picker.setDate(date);
		}
		if (changedProperties.has("isOpen")) {
			if (this.isOpen && !this._picker.isOpen) this._picker.open();
			else if (!this.isOpen && this._picker.isOpen) this._picker.close();
		}
		if (changedProperties.has("isDisabled")) {
			this._picker.altInput.classList.toggle(
				"flatpick-alt-input-disabled",
				this.isDisabled
			);
		}
		if (changedProperties.has("placeholder")) {
			this._getPickerElement().placeholder = this.placeholder;
		}
	}

	disconnectedCallback() {
		if (this._picker) {
			this._picker.destroy();
		}
	}

	_parseDateInput(input) {
		if (!input || input === "") return null;

		if (input === "__NOW__") return DateTime.local().toJSDate();

		const unixTimestamp = Number(input);
		if (unixTimestamp && !isNaN(unixTimestamp)) {
			return DateTime.fromSeconds(unixTimestamp).toJSDate();
		}
		return DateTime.fromISO(input).toJSDate();
	}

	_formatOutputDate(selectedDate) {
		if (!selectedDate || selectedDate === "") return null;
		const dateTime = DateTime.fromJSDate(selectedDate);
		switch (this.pickerType) {
			case "dateTime":
				return dateTime.toISO({
					includeOffset: this.addZoneOffsetToResult,
				});
			case "date":
				return dateTime.toISODate();
			case "time":
				return dateTime.toISOTime({
					includeOffset: this.addZoneOffsetToResult,
				});
		}
	}
}

customElements.define("flatpickr-picker", FlatPickr);
