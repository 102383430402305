import { AavoCodeMirror, AavoCodeMirrorProps } from "src/components/common/codeMirror/AavoCodeMirror.tsx";
import { useConfiguratorLuaEditorExtensions } from "./useConfiguratorLuaEditorExtensions";

export interface ConfiguratorLuaEditorProps extends AavoCodeMirrorProps {
	productFamilyVersionId: number | undefined | null;
	catalogPartRevisionId: number | null | undefined;
}

export const ConfiguratorLuaEditor = ({
	productFamilyVersionId,
	catalogPartRevisionId,
	...other
}: ConfiguratorLuaEditorProps) => {
	const extensions = useConfiguratorLuaEditorExtensions({ productFamilyVersionId, catalogPartRevisionId });

	return <AavoCodeMirror extensions={extensions} {...other} />;
};
