// This file is automatically generated. Do not edit manually.

import { CustomerOrderLineView } from "src/api/generated/erp/db/types/tables/customerOrderLineView";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace CustomerOrderLineApi {
	export async function getCustomerOrderLine(args: { customerOrderLineId: number }): Promise<CustomerOrderLineView> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/customerOrderLine/getCustomerOrderLine",
			method: "POST",
			data: {
				customerOrderLineId: args.customerOrderLineId,
			},
		});
		return response as CustomerOrderLineView;
	}
}
