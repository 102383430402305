import React, { useCallback, useMemo } from "react";
import {
	Autocomplete,
	AutocompleteProps,
	CircularProgress,
	FormControl,
	FormHelperText,
	InputLabel,
	Select,
} from "@mui/material";
import { InputError } from "./types";
import {
	decodeNativeSelectKey,
	encodeNativeSelectKey,
	getMultiSelectAutocompleteInputWidthStyle,
} from "./selectFieldUtils.ts";
import { AavoTextField } from "./AavoTextField";
import { isTouchDevice } from "src/utils/isTouchDevice";
import { useMaybeControlledState } from "src/utils/useMaybeControlledState.ts";
import { CustomPopper, CustomPopperProps } from "src/components/common/popper/CustomPopper.tsx";

export interface MultiSelectFieldProps<T, Key>
	extends Omit<
		AutocompleteProps<T, true, false, false>,
		| "onChange"
		| "renderInput"
		| "getOptionKey"
		| "getOptionLabel"
		| "freeSolo"
		| "multiple"
		| "disableClearable"
		| "isOptionEqualToValue"
		| "value"
		| "ref"
	> {
	getOptionKey: (o: T) => Key;
	getOptionLabel: (o: T) => string;
	label: string;
	error?: InputError;
	onChange: (v: Key[]) => void;
	value?: Key[];
	popperProps?: Partial<CustomPopperProps>;
}

export const MultiSelectField = <T, Key>(props: MultiSelectFieldProps<T, Key>) => {
	if (isTouchDevice()) return <NativeMultiSelectField {...props} />;
	else return <AutoCompleteMultiSelectField {...props} />;
};

const AutoCompleteMultiSelectField = <T, Key>({
	label,
	value: valueProp,
	onChange: onChangeProp,
	options,
	getOptionKey,
	getOptionLabel,
	error,
	autoFocus,
	loading,
	popperProps,
	...other
}: MultiSelectFieldProps<T, Key>) => {
	const [value, setValue] = useMaybeControlledState({
		controlledValue: valueProp,
		onChange: onChangeProp,
		defaultValue: [],
	});
	const optionsForValue = useMemo(
		() =>
			options.filter((o: T) => {
				return value.includes(getOptionKey(o));
			}),
		[options, value, getOptionKey],
	);

	return (
		<Autocomplete
			renderInput={(params) => {
				return (
					<AavoTextField
						{...params}
						fullWidth
						label={label}
						error={error !== undefined}
						helperText={error ?? undefined}
						autoFocus={autoFocus}
						inputProps={{
							...params.inputProps,
							style: {
								...getMultiSelectAutocompleteInputWidthStyle(label, value),
								...params.inputProps.style,
							},
						}}
						InputProps={{
							...params.InputProps,
							endAdornment: (
								<>
									{loading ?
										<CircularProgress color="inherit" size={20} />
									:	null}
								</>
							),
						}}
					/>
				);
			}}
			options={options}
			getOptionLabel={getOptionLabel}
			isOptionEqualToValue={(o1, o2) => {
				return getOptionKey(o1) === getOptionKey(o2);
			}}
			multiple={true}
			value={optionsForValue}
			onChange={(_, value) => {
				setValue(value.map(getOptionKey));
			}}
			PopperComponent={(defaultPopperProps) => (
				<CustomPopper {...defaultPopperProps} placement={"bottom-start"} {...popperProps} />
			)}
			{...other}
		/>
	);
};

const NativeMultiSelectField = <T, Key>({
	label,
	value: valueProp,
	onChange: onChangeProp,
	options,
	getOptionKey,
	getOptionLabel,
	error,
	autoFocus,
	sx,
}: MultiSelectFieldProps<T, Key>) => {
	const [value, setValue] = useMaybeControlledState({
		controlledValue: valueProp,
		onChange: onChangeProp,
		defaultValue: [],
	});
	const encodedValue = useMemo(() => value.map((v) => encodeNativeSelectKey(v)), [value]);

	const onChangeDecoded = useCallback(
		(e: any) => {
			setValue(decodeNativeMultiSelectFieldValue(e));
		},
		[setValue],
	);

	return (
		<FormControl error={error !== undefined} sx={sx}>
			<InputLabel htmlFor={"multiple-selection-label"} shrink>
				{label}
			</InputLabel>
			<Select
				native
				label={label}
				multiple
				autoFocus={autoFocus}
				inputProps={{
					id: "multiple-selection-label",
				}}
				onChange={onChangeDecoded}
				value={encodedValue}
			>
				{options.map((o, index) => {
					return (
						<option
							key={`select-field-option-${index}`}
							value={encodeNativeSelectKey(getOptionKey(o))}
						>
							{getOptionLabel(o)}
						</option>
					);
				})}
			</Select>
			<FormHelperText>{error ?? ""}</FormHelperText>
		</FormControl>
	);
};

const decodeNativeMultiSelectFieldValue = <Key,>(
	event: React.ChangeEvent<HTMLSelectElement>,
): Key[] => {
	const { options } = event.target;
	const selectedItems: Key[] = [];
	for (let i = 0; i < options.length; i += 1) {
		const option = options[i];
		if (option && option.selected) {
			const selectedItem = decodeNativeSelectKey(option.value);
			if (selectedItem !== undefined) {
				selectedItems.push(selectedItem);
			}
		}
	}
	return selectedItems;
};
