import { FormCommonProps } from "src/components/common/forms/types.ts";
import { ChartGroup } from "src/api/generated/postgres/db/types/public_/tables/chartGroup.ts";
import { FormTextField } from "src/components/common/forms/fields/FormTextField.tsx";
import i18n from "i18next";
import { requireRule } from "src/components/common/forms/validation.ts";
import { FormCheckbox } from "src/components/common/forms/fields/FormCheckbox.tsx";
import {
	ControlChartGroupEditApi,
	ControlChartGroupEditApi_FormInitData,
} from "src/api/generated/spc/controlChart/api/controlChartGroupEditApi.ts";
import { AsyncForm } from "src/components/common/forms/AsyncForm.tsx";

export interface ControlChartGroupFormProps extends FormCommonProps<number> {
	controlChartGroupId?: number;
	parentGroupId: number;
}

export const ControlChartGroupForm = ({
	controlChartGroupId,
	parentGroupId,
	onFormEdited,
	onCompleted,
}: ControlChartGroupFormProps) => {
	return (
		<AsyncForm<ControlChartGroupEditApi_FormInitData, ChartGroup, number>
			fetch={() => ControlChartGroupEditApi.getFormInitData({ chartGroupId: controlChartGroupId })}
			getDefaultValues={(data) =>
				data.chartGroup ?? {
					superGroupId: parentGroupId,
				}
			}
			submit={submit}
			onCompleted={onCompleted}
			onFormEdited={onFormEdited}
			render={({ control }) => (
				<>
					<FormTextField
						control={control}
						name={"name"}
						label={i18n.t("name")}
						rules={requireRule()}
						autoFocus
					/>
					<FormCheckbox control={control} name={"isDefectGroup"} label={i18n.t("is_defect_group")} />
				</>
			)}
		/>
	);

	async function submit(values: ChartGroup) {
		if (controlChartGroupId == null) {
			return await ControlChartGroupEditApi.insert({ chartGroup: values });
		} else {
			await ControlChartGroupEditApi.update({ chartGroup: values });
			return controlChartGroupId;
		}
	}
};
