import axios from "axios";
import { setupInterceptors } from "./interceptors";
import { AxiosRequestConfig } from "axios";
import { getSelectedIdentityStorage } from "src/storage/appState/identitySelection";
import { getOrInitSelectedEnvironmentIdStorage } from "src/storage/appState/environment";
import { filterNullValues } from "src/utils/objectUtils.ts";

const ApiClient = setupInterceptors(
	axios.create({
		baseURL: window.location.origin,
		headers: {
			"Content-type": "application/json",
		},
	}),
);

export const makeAxiosRequest = async <ResponseData>(axiosConfig: AxiosRequestConfig = {}) => {
	const aavoHeaders = filterNullValues({
		"X-aavo-identity": getSelectedIdentityStorage(),
		"X-env-association-id": getOrInitSelectedEnvironmentIdStorage(),
		"X-Client-Time-Zone": Intl.DateTimeFormat().resolvedOptions().timeZone,
	});

	const response = await ApiClient.request<ResponseData>({
		...axiosConfig,
		headers: {
			...aavoHeaders,
			...axiosConfig.headers,
		},
	});

	return response.data;
};
