// This file is automatically generated. Do not edit manually.

import { t } from "i18next";

export const ShopOrderBatchStateValues = ["INITIAL", "PLANNED", "RELEASED", "CANCELLED"] as const;

export type ShopOrderBatchState = (typeof ShopOrderBatchStateValues)[number];

export const getShopOrderBatchStateLabels = () => ({
	INITIAL: t("initial"),
	PLANNED: t("planned"),
	RELEASED: t("released"),
	CANCELLED: t("cancelled"),
});

export const getShopOrderBatchStateLabel = (value: ShopOrderBatchState): string => {
	return getShopOrderBatchStateLabels()[value];
};
