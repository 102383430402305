import { FormCommonProps } from "src/components/common/forms/types.ts";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import { FormNumberField } from "src/components/common/forms/fields/FormNumberField.tsx";
import i18n from "i18next";
import { AavoForm, AavoFormContentParams } from "src/components/common/forms/AavoForm.tsx";
import { WarehousePickingObjectLocationView } from "src/api/generated/erp/db/types/tables/warehousePickingObjectLocationView.ts";
import { DeliverySourcePickingApi } from "src/api/generated/erp/delivery/api/deliverySourcePickingApi.ts";
import { warehousePickingObjectToDeliveryPackageLineSource } from "src/components/views/erp/delivery/deliveryPackageLineSourceUtils.ts";
import { VerticalBox } from "src/components/common/box/VerticalBox.tsx";

export interface DeliveryPackagePickingFormProps extends FormCommonProps<void> {
	pickingObjectLocations: WarehousePickingObjectLocationView[];
	deliveryPackageId: number;
}

interface FormValues {
	pickingObjectLocations: PickingObjectLocationFormValues[];
}

interface PickingObjectLocationFormValues extends WarehousePickingObjectLocationView {
	quantityToPickInBaseUnit: number;
}

export const DeliveryPackagePickingForm = (props: DeliveryPackagePickingFormProps) => {
	const { pickingObjectLocations, deliveryPackageId, onCompleted, onFormEdited } = props;

	return (
		<AavoForm<FormValues, void>
			defaultValues={{
				pickingObjectLocations: pickingObjectLocations.map((location) => ({
					...location,
					quantityToPickInBaseUnit: location.reservedQuantity,
				})),
			}}
			render={(params) => <FormContent {...props} {...params} />}
			submit={async (values) => {
				await DeliverySourcePickingApi.pickPartsToPackage({
					deliveryPackageId: deliveryPackageId,
					parts: values.pickingObjectLocations.map((location) => ({
						partId: location.partId,
						source: warehousePickingObjectToDeliveryPackageLineSource(location),
						quantityToPickInBaseUnit: location.quantityToPickInBaseUnit,
						partWarehouseLocationId: location.partWarehouseLocationId,
					})),
				});
			}}
			onCompleted={onCompleted}
			onFormEdited={onFormEdited}
		/>
	);
};

interface FormContentProps extends DeliveryPackagePickingFormProps, AavoFormContentParams<FormValues> {}

const FormContent = (props: FormContentProps) => {
	const { pickingObjectLocations } = props;

	return (
		<VerticalBox>
			{pickingObjectLocations.map((location, index) => (
				<SingleLocationForm
					key={location.warehousePickingObjectLocationId}
					index={index}
					pickingObjectLocation={location}
					{...props}
				/>
			))}
		</VerticalBox>
	);
};

interface SingleObjectFormSectionProps extends FormContentProps {
	index: number;
	pickingObjectLocation: WarehousePickingObjectLocationView;
}

const SingleLocationForm = ({
	pickingObjectLocation: { locationCode, partUnit, configurationOrPartDescription },
	control,
	index,
}: SingleObjectFormSectionProps) => {
	return (
		<Box
			sx={{
				borderBottom: "1px solid",
				paddingBottom: 2,
				marginBottom: 2,
				paddingX: 1,
				borderColor: "divider",
				display: "flex",
				gap: 2,
				flexDirection: {
					xs: "column",
					sm: "row",
				},
			}}
		>
			<Typography
				variant={"h6"}
				sx={{
					flex: 1,
				}}
			>
				{configurationOrPartDescription}
			</Typography>
			<VerticalBox gap={1}>
				<FormNumberField
					key={"quantityToReserveInWarehouseUnit"}
					control={control}
					name={`pickingObjectLocations.${index}.quantityToPickInBaseUnit`}
					label={i18n.t("quantity_with_unit", { unit: partUnit })}
				/>
				<Typography key={"locationCode"}>{`${i18n.t("warehouse_location")} ${locationCode ?? "-"}`}</Typography>
			</VerticalBox>
		</Box>
	);
};
