import {DeliveryMethod} from "../../../../../api/generated/erp/db/types/tables/deliveryMethod.ts";
import {AsyncCrudDataGrid} from "../../../../common/dataGrid/crud/AsyncCrudDataGrid.tsx";
import {integerColumn, textColumn} from "../../../../common/dataGrid/columns.tsx";
import i18n from "i18next";
import {dataGridPersistentStateProps} from "../../../../common/dataGrid/dataGridStateStorage.ts";
import {DeliveryMethodApi} from "../../../../../api/generated/erp/warehouse/basedata/api/deliveryMethodApi.ts";

export const DeliveryMethodDataGrid = () => {
    return (
        <AsyncCrudDataGrid<DeliveryMethod>
            fetchData={DeliveryMethodApi.getAll}
            save={{
                type: "enabled",
                action: async (params)=>
                    await DeliveryMethodApi.save({
                        deliveryMethods: params.items,
                    }),
            }}
            add={{
                type: "enabled",
                action: async () => {
                    return {
                        deliveryMethodName: "",
                        deliveryMethodDescription: "",
                        transportDuration: 0,
                        lastUpdated: new Date().toISOString(),
                    };
                },
            }}
            remove={{
                type: "enabled",
                action: async (params) =>
                    await DeliveryMethodApi.delete_({
                        deliveryMethods: params.items,
                    }),
            }}
            columns={[
                textColumn({
                    headerName: i18n.t("delivery_method_code"),
                    editable: true,
                    field: "deliveryMethodCode",
                    validate: "required",
                }),
                textColumn({
                    headerName: i18n.t("description"),
                    editable: true,
                    field: "deliveryMethodDescription",
                }),
                integerColumn({
                    headerName: i18n.t("transport_duration"),
                    field: "transportDuration",
                    editable: true,
                    validate: "required",
                }),
            ]}
            getRowId={(row) => {
                return row.deliveryMethodId;
            }}
            {...dataGridPersistentStateProps("2A6A2A2F6E2F2A2A")}
        />
    );
}