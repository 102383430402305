import { ConfigurationComponent } from "src/api/generated/erp/db/types/tables/configurationComponent.ts";
import { Box } from "@mui/material";
import { ConfiguratorComponentPreview } from "src/components/views/erp/configurator/managing/productFamilyVersions/components/componentPreview/ConfiguratorComponentPreview.tsx";
import {
	CONFIGURATOR_COMPONENT_DND_KEY,
	ConfiguratorComponentDragItem,
} from "src/components/views/erp/configurator/managing/productFamilyVersions/components/configuratorComponentDnd.ts";
import { useDrop } from "react-dnd";
import { useContextOrThrow } from "src/utils/useContextOrThrow.tsx";
import { ProductFamilyVersionManagingViewContext } from "src/components/views/erp/configurator/managing/productFamilyVersions/ProductFamilyVersionManagingViewContext.tsx";
import { VerticalBox } from "src/components/common/box/VerticalBox.tsx";

export interface TabComponentContentProps {
	tabComponent: ConfigurationComponent;
	tabChildren: Array<ConfigurationComponent>;
}

export const TabComponentContent = (props: TabComponentContentProps) => {
	const { tabComponent, tabChildren } = props;

	const columnCount = tabComponent.tabColumnCount ?? 1;

	return (
		<VerticalBox flex={1}>
			<VerticalBox
				sx={{
					flex: "0 1 auto",
				}}
			>
				<Box flex={1} overflow="auto">
					<Box
						sx={{
							rowGap: 2,
							paddingBottom: 1,
							paddingTop: 2,
							minWidth: 0,
							minHeight: 0,
							display: {
								xs: "flex",
								sm: "flex",
								md: "grid",
							},
							flexDirection: "column",
							gridTemplateColumns: `repeat(${columnCount}, 1fr)`,
						}}
					>
						{tabChildren
							.toSorted((a, b) => a.orderNum - b.orderNum)
							.map((component) => (
								<ConfiguratorComponentPreview
									key={component.configurationComponentId}
									component={component}
									layoutColumnCount={columnCount}
								/>
							))}
					</Box>
				</Box>
			</VerticalBox>
			<DropComponentToEndZone {...props} />
		</VerticalBox>
	);
};

const DropComponentToEndZone = ({ tabComponent, tabChildren }: TabComponentContentProps) => {
	const { onComponentDropped } = useContextOrThrow(ProductFamilyVersionManagingViewContext);

	const onDrop = (item: ConfiguratorComponentDragItem) => {
		const position = tabChildren.length === 0 ? 0 : Math.max(...tabChildren.map((c) => c.orderNum)) + 1;

		onComponentDropped({
			dragItem: item,
			parentTabComponentId: tabComponent.configurationComponentId,
			targetPosition: position,
		});
	};

	const [{ isDraggingOver }, dropRef] = useDrop<
		ConfiguratorComponentDragItem,
		unknown,
		{ isDraggingOver: boolean }
	>(
		() => ({
			accept: CONFIGURATOR_COMPONENT_DND_KEY,
			drop: onDrop,
			collect: (monitor) => ({
				isDraggingOver: monitor.isOver(),
			}),
		}),
		[onDrop],
	);

	return (
		<Box
			ref={dropRef}
			sx={{
				backgroundColor: "secondary.main",
				opacity: isDraggingOver ? 0.8 : 0,
				flex: "1 1 0",
				minHeight: "1rem",
			}}
		/>
	);
};
