import { Primitive } from "src/types/primitive";
import { logError } from "src/errorHandling/errorLogging";
import { useAsyncFetch } from "src/utils/async/asyncFetch.ts";
import i18n from "i18next";
import {
	MultiSelectField,
	MultiSelectFieldProps,
} from "src/components/common/inputFields/MultiSelectField.tsx";

export interface LazyMultiSelectFieldProps<T, Key extends Primitive>
	extends Omit<MultiSelectFieldProps<T, Key>, "onFocus" | "loading" | "options"> {
	fetchOptions: () => Promise<T[]>;
	fetchOnMount?: boolean;
}

export const LazyMultiSelectField = <T, Key extends Primitive>({
	fetchOptions,
	fetchOnMount,
	error,
	defaultValue,
	...other
}: LazyMultiSelectFieldProps<T, Key>) => {
	const [{ loading, error: optionsError, data }, update] = useAsyncFetch<T[]>(fetchOptions, {
		fetchOnMount: defaultValue != null || fetchOnMount,
	});

	const combinedError =
		error ?? (optionsError !== undefined ? i18n.t("failed_to_fetch_options") : undefined);

	return (
		<MultiSelectField
			error={combinedError}
			options={data ?? []}
			loading={loading}
			onFocus={() => {
				update().catch(logError);
			}}
			defaultValue={defaultValue}
			{...other}
		/>
	);
};
