// This file is automatically generated. Do not edit manually.

import { AavoObjectRef } from "src/api/generated/common/sourceType/aavoObjectRef";
import { AavoObjectType } from "src/api/generated/common/sourceType/aavoObjectType";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace DocumentConnectionApi {
	export async function getDocumentConnections(args: {
		documentId: number;
	}): Promise<Array<DocumentConnectionApi_DocumentConnectionDto>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/documents/getDocumentConnections",
			method: "POST",
			data: {
				documentId: args.documentId,
			},
		});
		return response as Array<DocumentConnectionApi_DocumentConnectionDto>;
	}

	export async function deleteDocumentConnections(args: {
		documentConnections: Array<DocumentConnectionApi_DocumentConnectionDto>;
	}): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/documents/deleteDocumentConnections",
			method: "POST",
			data: {
				documentConnections: args.documentConnections,
			},
		});
		return response as void;
	}

	export async function connectDocumentsToObject(args: {
		objectRef: AavoObjectRef;
		documentIds: Array<number>;
	}): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/documents/connectDocumentsToObject",
			method: "POST",
			data: {
				objectRef: args.objectRef,
				documentIds: args.documentIds,
			},
		});
		return response as void;
	}
}

export interface DocumentConnectionApi_DocumentConnectionDto {
	documentConnectionId: number;
	objectDescription: string;
	objectId: number;
	objectType: AavoObjectType;
}
