import { AsyncFetchRender } from "src/components/common/async/AsyncFetchRender";
import { VerticalBox } from "src/components/common/box/VerticalBox";
import Typography from "@mui/material/Typography";
import i18n from "i18next";
import { TestConfigurationResultViewCommonProps } from "src/components/views/erp/configurator/configuratorTesting/TestConfigurationResultViewCommonProps.ts";
import { TestConfigurationCommonResultsApi } from "src/api/generated/erp/configurator/api/configuratorTesting/testConfigurationCommonResultsApi.ts";

export const TestConfigurationTopLevelResultsView = ({
	configurationSessionId,
	configuratorType,
	propertyValues,
}: TestConfigurationResultViewCommonProps) => {
	return (
		<AsyncFetchRender
			fetch={() =>
				TestConfigurationCommonResultsApi.getTopLevelData({
					configurationSessionId,
					configuratorType,
					propertyValues,
				})
			}
			content={({ topLevelData, price }) => {
				return (
					<VerticalBox padding={1}>
						<Typography>
							<b>{i18n.t("description")}:</b> {topLevelData.description}
						</Typography>
						<Typography>
							<b>{i18n.t("price")}:</b> {price}
						</Typography>
						<Typography>
							<b>{i18n.t("capacity")}:</b> {topLevelData.capacity}
						</Typography>
						<Typography>
							<b>{i18n.t("weight")}:</b> {topLevelData.weight}
						</Typography>
					</VerticalBox>
				);
			}}
		/>
	);
};
