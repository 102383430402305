import { ConfigurationPropertyValues } from "src/api/generated/erp/configurator/model/configurationPropertyValues.ts";
import { PartConfiguratorType } from "src/api/generated/erp/configurator/configuratorType/partConfiguratorType.ts";
import { ConfigurationProperty } from "src/api/generated/erp/db/types/tables/configurationProperty.ts";
import { ConfigurationPropertyValue } from "src/api/generated/io/aavo/applications/db/erp/types/configurationPropertyValue.ts";
import { CONFIGURATION_PROPERTY_NULL_VALUE } from "src/components/views/erp/configurator/configuratorUtils.ts";
import { AsyncDataGrid } from "src/components/common/dataGrid/AsyncDataGrid";
import { dataGridPersistentStateProps } from "src/components/common/dataGrid/dataGridStateStorage.ts";
import i18n from "i18next";
import { TestConfigurationCommonResultsApi } from "src/api/generated/erp/configurator/api/configuratorTesting/testConfigurationCommonResultsApi.ts";

export interface TestConfigurationResultPropertiesDataGridProps {
	configuratorType: PartConfiguratorType;
	propertyValues: ConfigurationPropertyValues;
}

export const TestConfigurationResultPropertiesDataGrid = ({
	configuratorType,
	propertyValues,
}: TestConfigurationResultPropertiesDataGridProps) => {
	return (
		<AsyncDataGrid<Row>
			fetchRows={fetchRows}
			getRowId={(row) => row.configurationPropertyId}
			columns={[
				{
					field: "title",
					headerName: i18n.t("property"),
					width: 300,
				},
				{
					field: "value",
					headerName: i18n.t("value"),
					width: 500,
					valueGetter: (_, row) => row.value.label,
				},
			]}
			{...dataGridPersistentStateProps("CA47B80C2D5F9B30")}
		/>
	);

	async function fetchRows(): Promise<Row[]> {
		const properties = await TestConfigurationCommonResultsApi.getConfigurationProperties({
			configuratorType: configuratorType,
		});
		return properties.map((property) => {
			return {
				...property,
				value:
					propertyValues.valuesByPropertyNames[property.name] ?? CONFIGURATION_PROPERTY_NULL_VALUE,
			};
		});
	}
};

interface Row extends ConfigurationProperty {
	value: ConfigurationPropertyValue;
}
