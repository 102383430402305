import { CostEventsOfObjectDataGridApi } from "src/api/generated/erp/common/costEvents/costEventsOfObjectDataGridApi.ts";
import { CostEventObjectRef } from "src/api/generated/erp/common/costEvents/costEventObjectRef.ts";
import { CostEventsDataGridBase } from "src/components/views/erp/common/costEvents/CostEventsDataGridBase.tsx";

export interface CostEventsOfObjectDataGridProps {
	objectRef: CostEventObjectRef;
	costEventPriceListId: number;
}

export const CostEventsOfObjectDataGrid = ({
	objectRef,
	costEventPriceListId,
}: CostEventsOfObjectDataGridProps) => {
	return (
		<CostEventsDataGridBase
			fetchData={({ dataModelRequest }) =>
				CostEventsOfObjectDataGridApi.getDataGridData({
					objectRef,
					dataModelRequest,
				})
			}
			initialParams={{}}
			getRowCostEventPriceListId={() => costEventPriceListId}
			insert={{
				type: "enabled",
				newEventObjectRef: objectRef,
				newEventPriceListId: costEventPriceListId,
			}}
			gridId={"A5FA2F0C5AE1EA16"}
		/>
	);
};
