import { CatalogPartSitesDataGridApi } from "src/api/generated/erp/parts/catalogPart/api/catalogPartSitesDataGridApi";
import { ClientSideDataGridModel } from "src/components/common/dataGrid/gridModel/ClientSideDataGridModel.tsx";
import { CrudDataGrid } from "src/components/common/dataGrid/crud/CrudDataGrid.tsx";
import { enumColumn, textColumn } from "src/components/common/dataGrid/columns.tsx";
import i18n from "i18next";
import { getPartTypeLabels } from "src/api/generated/erp/db/types/enums/partType.ts";
import { AavoButton } from "src/components/common/buttons/AavoButton.tsx";
import { faLink, faSync } from "@fortawesome/pro-regular-svg-icons";
import { getAcquisitionMethodLabels } from "src/api/generated/erp/db/types/enums/acquisitionMethod.ts";
import { CatalogPartSitePartsView } from "src/api/generated/erp/db/types/tables/catalogPartSitePartsView.ts";
import { useConfirmDialog } from "src/components/common/dialogs/confirmDialog/useConfirmDialog.ts";
import { useAavoToast } from "src/utils/toast.ts";
import { useGenericDialog } from "src/components/common/dialogs/useGenericDialog.ts";
import { SitePartsPage } from "src/components/views/erp/parts/siteParts/SitePartsPage.tsx";
import { AsyncButton } from "src/components/common/buttons/AsyncButton.tsx";

export interface CatalogPartSitesDataGridProps {
	catalogPartId: number;
}

export const CatalogPartSitesDataGrid = ({ catalogPartId }: CatalogPartSitesDataGridProps) => {
	const { openDialog } = useGenericDialog();
	const showConfirm = useConfirmDialog();
	const showToast = useAavoToast();
	return (
		<ClientSideDataGridModel
			gridId={"7746802265D8D37C"}
			fetchData={() =>
				CatalogPartSitesDataGridApi.getCatalogPartSites({ catalogPartId: catalogPartId })
			}
			initialParams={{}}
			getRows={(data) => data}
			getRowId={(row) => row.sitePartId}
			render={({ dataGridProps, onlySelectedRow }) => (
				<CrudDataGrid<CatalogPartSitePartsView>
					columns={[
						textColumn({
							field: "siteName",
							headerName: i18n.t("site"),
						}),
						enumColumn({
							field: "partType",
							headerName: i18n.t("type"),
							enumLabels: getPartTypeLabels(),
							width: 120,
						}),
						enumColumn({
							field: "acquisitionMethod",
							headerName: i18n.t("acquisition_method"),
							enumLabels: getAcquisitionMethodLabels(),
							width: 140,
						}),
						textColumn({
							field: "responsiblePersonName",
							headerName: i18n.t("responsible_person"),
						}),
					]}
					onRowDoubleClick={({ row }) => {
						openSitePart(row);
					}}
					actionBarComponents={
						<>
							<AsyncButton
								label={i18n.t("open")}
								icon={faLink}
								disabled={!onlySelectedRow}
								variant={"outlined"}
								onClick={() => {
									if (!onlySelectedRow) return;
									openSitePart(onlySelectedRow);
								}}
							/>
							<AavoButton
								icon={faSync}
								label={i18n.t("sync_documents")}
								variant={"outlined"}
								onClick={async () => {
									const confirmed = await showConfirm({
										title: i18n.t("sync_documents"),
										message: i18n.t("sync_to_all_sites_message"),
									});
									if (!confirmed) return;
									await CatalogPartSitesDataGridApi.syncCatalogPartDocumentsToSites({
										catalogPartId: catalogPartId,
									});
									showToast(`${i18n.t("documents_synced_to_all_sites")}`);
								}}
							/>
						</>
					}
					{...dataGridProps}
				/>
			)}
		/>
	);

	function openSitePart(row: CatalogPartSitePartsView) {
		openDialog({
			title: i18n.t("part"),
			content: <SitePartsPage sitePartId={row.sitePartId} />,
		});
	}
};
