import { ProjectActivityProductChangeRequestsApi } from "src/api/generated/erp/project/projectActivity/api/projectActivityProductChangeRequestsApi";
import { ControlledAsyncCrudDataGrid } from "src/components/common/dataGrid/crud/ControlledAsyncCrudDataGrid";
import { useClientSideDataGridModel } from "src/components/common/dataGrid/gridModel/useClientSideDataGridModel";
import { ProductChangeRequestProjectActivityView } from "src/api/generated/erp/db/types/tables/productChangeRequestProjectActivityView.ts";
import { dateColumn, enumColumn, textColumn } from "src/components/common/dataGrid/columns.tsx";
import i18n from "i18next";
import { getProductChangeRequestPriorityLabels } from "src/api/generated/erp/db/types/enums/productChangeRequestPriority.ts";
import { getProductChangeRequestStateLabels } from "src/api/generated/erp/db/types/enums/productChangeRequestState.ts";
import { faExternalLinkAlt, faLink } from "@fortawesome/pro-regular-svg-icons";
import { AsyncButton } from "src/components/common/buttons/AsyncButton.tsx";
import { useFormInput } from "src/components/common/dialogs/formInput/useFormInput.tsx";
import { FormMultiSelectField } from "src/components/common/forms/fields/FormMultiSelectField.tsx";
import { requireRule } from "src/components/common/forms/validation.ts";
import { openLegacyProductChangeRequestView } from "src/components/views/legacy/legacyViewAdapters.ts";
import { useOpenLegacyView } from "src/components/views/legacy/useOpenLegacyView.ts";

export interface ProjectActivityProductChangeRequestsDataGridProps {
	activityId: number;
}

export const ProjectActivityProductChangeRequestsDataGrid = ({
	activityId,
}: ProjectActivityProductChangeRequestsDataGridProps) => {
	const openLegacyView = useOpenLegacyView();
	const showFormInput = useFormInput();

	const { dataGridProps, refreshData, onlySelectedRow } = useClientSideDataGridModel({
		fetchData: () =>
			ProjectActivityProductChangeRequestsApi.getActivityProductChangeRequests({
				projectActivityId: activityId,
			}),
		initialParams: {},
		getRowId: (row) => row.productChangeRequestId,
		gridId: "F5A9E6E6A0A9D3E2",
	});

	return (
		<ControlledAsyncCrudDataGrid<ProductChangeRequestProjectActivityView>
			columns={[
				textColumn({
					field: "changeRequestTitle",
					headerName: i18n.t("name"),
					width: 200,
				}),
				textColumn({
					field: "changeRequestDescription",
					headerName: i18n.t("description"),
					width: 200,
				}),
				enumColumn({
					field: "changeRequestState",
					headerName: i18n.t("state"),
					enumLabels: getProductChangeRequestStateLabels(),
					width: 150,
				}),
				enumColumn({
					field: "changeRequestPriority",
					headerName: i18n.t("state"),
					enumLabels: getProductChangeRequestPriorityLabels(),
				}),
				dateColumn({
					field: "changeRequestDeadlineDate",
					headerName: i18n.t("deadline_date"),
					width: 120,
				}),
			]}
			onRowDoubleClick={({ row }) => openProductChangeRequest(row)}
			actionBarComponents={
				<>
					<AsyncButton
						label={i18n.t("open")}
						icon={faExternalLinkAlt}
						variant={"outlined"}
						disabled={!onlySelectedRow}
						onClick={() => openProductChangeRequest(onlySelectedRow!)}
					/>
					<AsyncButton
						label={i18n.t("connect_new")}
						icon={faLink}
						variant={"outlined"}
						onClick={onConnectNewChangeRequestClick}
					/>
				</>
			}
			remove={({ items }) =>
				ProjectActivityProductChangeRequestsApi.deleteConnections({
					projectActivityId: activityId,
					productChangeRequestIds: items.map((item) => item.productChangeRequestId),
				})
			}
			{...dataGridProps}
		/>
	);

	function openProductChangeRequest(row: ProductChangeRequestProjectActivityView) {
		openLegacyProductChangeRequestView({
			openLegacyView,
			productChangeRequestId: row.productChangeRequestId,
		});
	}

	async function onConnectNewChangeRequestClick() {
		const changeRequestOptions =
			await ProjectActivityProductChangeRequestsApi.getChangeRequestToConnectOptions({
				projectActivityId: activityId,
			});

		const formInput = await showFormInput<{ changeRequestIds: number[] }>({
			title: i18n.t("connect_product_change_requests"),
			content: ({ control }) => (
				<FormMultiSelectField
					control={control}
					name={"changeRequestIds"}
					label={i18n.t("product_change_requests")}
					options={changeRequestOptions.map((option) => ({
						key: option.productChangeRequestId,
						label: option.title,
					}))}
					rules={requireRule()}
				/>
			),
		});
		if (formInput === undefined) return;
		const { changeRequestIds } = formInput;

		await ProjectActivityProductChangeRequestsApi.connectProductChangeRequests({
			projectActivityId: activityId,
			productChangeRequestIds: changeRequestIds,
		});
		await refreshData();
	}
};
