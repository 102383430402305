import { CustomerOrderAttributesApi } from "src/api/generated/erp/sales/customerOrder/api/customerOrderAttributesApi.ts";
import { AavoActionBar } from "src/components/common/actionBar/AavoActionBar";
import { AsyncButton } from "src/components/common/buttons/AsyncButton";
import { faRefresh } from "@fortawesome/pro-regular-svg-icons";
import i18n from "i18next";
import { VerticalBox } from "src/components/common/box/VerticalBox";
import { ObjectAttributesForm } from "src/components/views/erp/objectAttributes/ObjectAttributesForm.tsx";
import { useRemount } from "src/utils/userRemount.ts";
import { useContext } from "react";
import { setRefreshRefValue } from "src/utils/useRefreshRef.ts";
import { CustomerOrdersPageContext } from "src/components/views/erp/sales/customerOrder/CustomerOrdersPageContext.tsx";

export interface CustomerOrderAttributesFormProps {
	customerOrderId: number;
}

export const CustomerOrderAttributesForm = ({
	customerOrderId,
}: CustomerOrderAttributesFormProps) => {
	const remounter = useRemount();
	const refreshRef = useContext(CustomerOrdersPageContext)?.attributeViewRefreshRef;

	setRefreshRefValue(refreshRef, () => remounter.remount());

	return (
		<VerticalBox flex={1}>
			<AavoActionBar>
				<AsyncButton
					icon={faRefresh}
					tooltip={i18n.t("refresh")}
					onClick={() => {
						remounter.remount();
					}}
				/>
			</AavoActionBar>
			<ObjectAttributesForm
				key={remounter.key}
				fetchAttrs={() =>
					CustomerOrderAttributesApi.getCustomerOrderAttributes({ customerOrderId })
				}
				saveAttrs={(attrs) =>
					CustomerOrderAttributesApi.saveCustomerOrderAttributes({
						customerOrderId,
						attributes: attrs,
					})
				}
			/>
		</VerticalBox>
	);
};
