import { AsyncMultiSelectField, AsyncMultiSelectFieldProps } from "../../inputFields/AsyncMultiSelectField";
import { useController, UseControllerProps } from "react-hook-form";

export interface FormAsyncMultiSelectFieldProps<
	TFieldValues extends object,
	TOption,
	Key extends string | number,
> extends Omit<AsyncMultiSelectFieldProps<TOption, Key>, "onChange" | "value">,
		Pick<UseControllerProps<TFieldValues>, "control" | "name" | "rules"> {}

export const FormAsyncMultiSelectField = <TFieldValues extends object, TOption, Key extends string | number>({
	control,
	name,
	rules,
	fetchOptions,
	getOptionKey,
	...other
}: FormAsyncMultiSelectFieldProps<TFieldValues, TOption, Key>) => {
	const { field, fieldState } = useController({
		name,
		control,
		rules,
	});

	const transformedField = {
		...field,
		ref: undefined,
		onChange: (values: TOption[]) => {
			field.onChange(values.map((v) => getOptionKey(v)));
		},
	};

	return (
		<AsyncMultiSelectField
			fetchOptions={fetchOptions}
			error={fieldState.error?.message}
			getOptionKey={getOptionKey}
			{...transformedField}
			{...other}
		/>
	);
};
