import { faCamera } from "@fortawesome/pro-regular-svg-icons";
import i18n from "i18next";
import { useInputDialog } from "src/components/common/dialogs/input/useInputDialog.tsx";
import { AsyncButton } from "src/components/common/buttons/AsyncButton.tsx";
import { useGenericDialog } from "src/components/common/dialogs/useGenericDialog.ts";
import { ImportMultipleDocumentsForm } from "src/components/views/documents/import/ImportMultipleDocumentsForm.tsx";
import { AavoObjectRef } from "src/api/generated/common/sourceType/aavoObjectRef.ts";
import { MaybeAsyncFunction } from "src/types/functions.ts";

export interface ImportDocumentsButtonProps {
	objectRef: AavoObjectRef | undefined;
	onSubmit: MaybeAsyncFunction<void, unknown>;
}

export const ImportDocumentsButton = ({ objectRef, onSubmit }: ImportDocumentsButtonProps) => {
	const showInputDialog = useInputDialog();
	const { openDialog } = useGenericDialog();
	return (
		<AsyncButton
			icon={faCamera}
			tooltip={i18n.t("add_document")}
			color={"primary"}
			onClick={async () => {
				const files = await showInputDialog({
					title: i18n.t("add_documents"),
					type: "multipleFiles",
					defaultValue: [],
					required: true,
				});

				if (files === undefined) return;

				openDialog(({ closeDialog }) => ({
					title: i18n.t("import_documents"),
					size: "lg",
					content: (
						<ImportMultipleDocumentsForm
							objectRef={objectRef}
							onCompleted={async () => {
								await closeDialog();
								await onSubmit();
							}}
							files={files}
						/>
					),
				}));
			}}
		/>
	);
};
