// This file is automatically generated. Do not edit manually.

import { CatalogPartRevisionDocumentConfigurationRule } from "src/api/generated/erp/db/types/tables/catalogPartRevisionDocumentConfigurationRule";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace CatalogPartRevisionDocumentConfigurationRuleApi {
	export async function getRevisionDocumentConfigurationRules(args: {
		catalogPartRevisionId: number;
		documentId: number;
	}): Promise<CatalogPartRevisionDocumentConfigurationRule | null> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/configurator/managing/catalogPartRevisionDocumentRules/getRevisionDocumentConfigurationRules",
			method: "POST",
			data: {
				catalogPartRevisionId: args.catalogPartRevisionId,
				documentId: args.documentId,
			},
		});
		return response === "" ? null : (response as CatalogPartRevisionDocumentConfigurationRule | null);
	}

	export async function setRevisionDocumentConfigurationRules(args: {
		catalogPartRevisionId: number;
		documentId: number;
		ruleScript: string;
	}): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/configurator/managing/catalogPartRevisionDocumentRules/setRevisionDocumentConfigurationRules",
			method: "POST",
			data: {
				catalogPartRevisionId: args.catalogPartRevisionId,
				documentId: args.documentId,
				ruleScript: args.ruleScript,
			},
		});
		return response as void;
	}
}
