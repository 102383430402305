import {ClientSideDataGridModel} from "src/components/common/dataGrid/gridModel/ClientSideDataGridModel.tsx";
import {
    InventoryLevelsByPartWarehouseLocationsDataGridApi
} from "src/api/generated/erp/warehouse/inventory/inventoryEvent/api/inventoryLevelsByPartWarehouseLocationsDataGridApi.ts";
import {CrudDataGrid} from "src/components/common/dataGrid/crud/CrudDataGrid.tsx";
import {InventoryLevelView} from "src/api/generated/erp/db/types/tables/inventoryLevelView.ts";
import {floatColumn, integerColumn, textColumn} from "src/components/common/dataGrid/columns.tsx";
import i18n from "i18next";
import {AsyncMenuButton} from "src/components/common/contextMenu/AsyncMenuButton.tsx";
import {useGenericDialog} from "src/components/common/dialogs/useGenericDialog.ts";
import {faClipboardCheck} from "@fortawesome/pro-regular-svg-icons";
import {PartWarehouseLocationInventoryLevelEventsAndReservationsView} from "src/components/views/erp/warehouse/inventory/PartWarehouseLocationInventoryLevelEventsAndReservationsView.tsx";
import {InventoryLevelSummaryText} from "src/components/views/erp/warehouse/inventory/InventoryLevelSummaryText.tsx";

export interface InventoryLevelsByPartWarehouseLocationsDataGridProps {
	partId: number;
}

export const InventoryLevelsByPartWarehouseLocationsDataGrid = ({
	partId,
}: InventoryLevelsByPartWarehouseLocationsDataGridProps) => {
	const { openDialog } = useGenericDialog();
	return (
		<ClientSideDataGridModel
			gridId={"95ECF16CB3520BE01"}
			fetchData={() => InventoryLevelsByPartWarehouseLocationsDataGridApi.getGridData({ partId: partId })}
			getRows={(data) => data.rows}
			getRowId={(row) => row.inventoryLevelId}
			initialParams={{}}
			render={({ data: { partIsConfigurable, summary }, dataGridProps }) => (
				<CrudDataGrid<InventoryLevelView>
					columns={[
						textColumn({
							field: "locationCode",
							headerName: i18n.t("warehouse_location"),
						}),
						partIsConfigurable &&
							integerColumn({
								field: "partConfigurationId",
								headerName: i18n.t("configuration"),
								width: 150,
							}),
						partIsConfigurable &&
							textColumn({
								field: "partConfigurationDescription",
								headerName: i18n.t("configuration_description"),
								width: 250,
							}),
						floatColumn({
							field: "stockQuantity",
							headerName: i18n.t("stock_quantity"),
							width: 80,
						}),
						floatColumn({
							field: "reservedQuantity",
							headerName: i18n.t("reserved"),
							width: 80,
						}),
						floatColumn({
							field: "availableQuantity" as any,
							headerName: i18n.t("available"),
							valueGetter: (_, row) => row.stockQuantity - row.reservedQuantity,
							sortable: false,
							width: 80,
						}),
					]}
					actionBarComponents={
						<>
							<InventoryLevelSummaryText summary={summary} />
						</>
					}
					rowContextMenuComponents={({ row }) => [
						<AsyncMenuButton
							key={"inventory_events"}
							icon={faClipboardCheck}
							label={i18n.t("inventory_events")}
							onClick={() => {
								openDialog({
									title: `${i18n.t("inventory_events")}: ${row.partLongName}`,
									size: "lg",
									content: (
										<PartWarehouseLocationInventoryLevelEventsAndReservationsView
											partId={row.partId}
											partWarehouseLocationId={row.partWarehouseLocationId}
										/>
									),
								});
							}}
						/>,
					]}
					{...dataGridProps}
				/>
			)}
		/>
	);
};

