// This file is automatically generated. Do not edit manually.

import { FrontendDownloadFileHandle } from "src/api/generated/common/frontendDownloadFileHandle";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace ShopOrderPrintApi {
	export async function printSurveysOfShopOrderOperations(args: {
		shopOrderId: number;
	}): Promise<FrontendDownloadFileHandle> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/shopOrder/printSurveysOfShopOrderOperations",
			method: "POST",
			data: {
				shopOrderId: args.shopOrderId,
			},
		});
		return response as FrontendDownloadFileHandle;
	}

	export async function printShopOrderPickingList(args: {
		shopOrderLineIds: Array<number>;
	}): Promise<FrontendDownloadFileHandle> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/shopOrder/printShopOrderPickingList",
			method: "POST",
			data: {
				shopOrderLineIds: args.shopOrderLineIds,
			},
		});
		return response as FrontendDownloadFileHandle;
	}
}
