// This file is automatically generated. Do not edit manually.

import { DeliverySource } from "src/api/generated/erp/delivery/model/deliverySource";
import { Delivery } from "src/api/generated/erp/db/types/tables/delivery";
import { CargoDriver } from "src/api/generated/erp/db/types/tables/cargoDriver";
import { DeliveryView } from "src/api/generated/erp/db/types/tables/deliveryView";
import { TransportRoute } from "src/api/generated/erp/db/types/tables/transportRoute";
import { Address } from "src/api/generated/erp/db/types/embeddables/address";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace DeliveryEditApi {
	export async function getFormInitData(args: {
		deliverySource: DeliverySource;
		deliveryId: number | null | undefined;
	}): Promise<DeliveryEditApi_FormInitData> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/delivery/getFormInitData",
			method: "POST",
			data: {
				deliverySource: args.deliverySource,
				deliveryId: args.deliveryId,
			},
		});
		return response as DeliveryEditApi_FormInitData;
	}

	export async function insert(args: { delivery: Delivery }): Promise<number> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/delivery/insert",
			method: "POST",
			data: {
				delivery: args.delivery,
			},
		});
		return response as number;
	}

	export async function update(args: { delivery: Delivery }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/delivery/update",
			method: "POST",
			data: {
				delivery: args.delivery,
			},
		});
		return response as void;
	}

	export async function delete_(args: { deliveryIds: Array<number> }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/delivery/delete",
			method: "POST",
			data: {
				deliveryIds: args.deliveryIds,
			},
		});
		return response as void;
	}
}

export interface DeliveryEditApi_FormInitData {
	cargoDriverOptions: Array<CargoDriver>;
	delivery: DeliveryView | null | undefined;
	sourceData: DeliveryEditApi_FormInitData_SourceData;
	transportRouteOptions: Array<TransportRoute>;
}

export interface DeliveryEditApi_FormInitData_SourceData {
	address: Address;
	addressDescription: string;
	recipientContact: string;
	siteId: number;
	transportRouteId: number | null | undefined;
}
