import { Box, BoxProps, useTheme } from "@mui/material";
import { mergeSx } from "src/utils/styles";

interface AavoAppIconProps extends Omit<BoxProps, "color"> {
	color?: string;
}

export const AavoAppIcon = ({ color, sx, ...other }: AavoAppIconProps) => {
	const theme = useTheme();
	return (
		<Box
			sx={mergeSx(
				{
					flex: 1,
					display: "flex",
					alignItems: "center",
				},
				sx,
			)}
			{...other}
		>
			<svg
				viewBox="0 0 5120 5120"
				style={{
					flex: 1,
				}}
			>
				<path
					fill={color ?? theme.palette.primary.main}
					d="M1733 4466 c-67 -31 -67 -31 -73 -425 l-5 -356 -112 4 c-131 3 -177 -8 -198 -49 -20 -38 -19 -59 3 -95 22 -36 137 -95 236 -122 l66 -17 0 -1331 c0 -1316 0 -1330 20 -1363 24 -39 89 -71 144 -72 71 0 94 24 184 197 45 87 110 212 145 278 106 201 497 946 622 1185 65 124 121 229 126 234 5 5 17 -1 28 -15 75 -89 255 -363 397 -604 42 -71 100 -161 128 -200 29 -38 81 -112 115 -163 59 -87 84 -108 93 -80 3 7 1 26 -3 43 -4 16 -22 104 -40 195 -65 332 -219 637 -502 993 l-78 98 72 137 c109 208 173 330 274 522 51 96 123 234 160 305 38 72 108 206 157 299 50 93 92 183 95 201 7 45 -27 108 -71 129 -47 22 -87 20 -127 -7 -36 -24 -34 -21 -281 -492 -229 -435 -270 -513 -366 -697 -53 -103 -101 -188 -105 -188 -5 0 -32 22 -60 49 -207 197 -498 386 -764 497 l-93 39 0 390 c0 369 -1 392 -20 422 -37 61 -107 86 -167 59z m367 -1243 c189 -99 353 -218 539 -391 l74 -69 -188 -359 c-104 -197 -236 -449 -294 -560 -58 -110 -151 -287 -206 -392 -55 -105 -103 -192 -107 -192 -10 0 -10 1196 -1 1688 l6 361 36 -15 c20 -8 83 -40 141 -71z"
				/>
			</svg>
		</Box>
	);
};
