// This file is automatically generated. Do not edit manually.

import { FrontendUploadFileHandle } from "src/api/generated/common/frontendUploadFileHandle";
import { ControlChartEventView } from "src/api/generated/postgres/db/types/public_/tables/controlChartEventView";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace SpcRecordActionEditApi {
	export async function getFormInitData(args: { recordId: number }): Promise<SpcRecordActionEditApi_FormInitData> {
		const response = await makeAxiosRequest({
			url: "/api/ext/spc/actions/getFormInitData",
			method: "POST",
			data: {
				recordId: args.recordId,
			},
		});
		return response as SpcRecordActionEditApi_FormInitData;
	}

	export async function insert(args: {
		recordIds: Array<number>;
		ocs: string;
		description: string;
		createdBy: string;
	}): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/spc/actions/insert",
			method: "POST",
			data: {
				recordIds: args.recordIds,
				ocs: args.ocs,
				description: args.description,
				createdBy: args.createdBy,
			},
		});
		return response as void;
	}

	export async function upsertActions(args: {
		recordIds: Array<number>;
		ocs: string;
		description: string;
		createdBy: string;
		file: FrontendUploadFileHandle | null | undefined;
	}): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/spc/actions/upsertActions",
			method: "POST",
			data: {
				recordIds: args.recordIds,
				ocs: args.ocs,
				description: args.description,
				createdBy: args.createdBy,
				file: args.file,
			},
		});
		return response as void;
	}
}

export interface SpcRecordActionEditApi_FormInitData {
	action: ControlChartEventView | null | undefined;
}
