import { FormTextField } from "src/components/common/forms/fields/FormTextField.tsx";
import i18n from "i18next";
import { SpcSamplingMethod } from "src/components/views/spc/basedata/controlCharts/form/samplingMethod/SpcSamplingMethod.tsx";

export const getWipBetweenMachinesSampling = (): SpcSamplingMethod => {
	return {
		key: "wip_between_machines",
		label: i18n.t("wip_quantity"),
		renderParameterFields: ({ control }) => {
			return (
				<>
					<FormTextField
						control={control}
						name={"samplingParams.param1"}
						label={i18n.t("completed_equipments")}
					/>
					<FormTextField
						control={control}
						name={"samplingParams.param2"}
						label={i18n.t("incomplete_equipments")}
					/>
				</>
			);
		},
	};
};
