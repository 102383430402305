import {TaskView} from "src/api/generated/postgres/db/types/tasks/tables/taskView.ts";
import {
    CustomerOrderTenantStateApi
} from "src/api/generated/erp/sales/customerOrder/tenantState/api/customerOrderTenantStateApi.ts";
import {
    SalvosSendEmailAboutCustomerOrderTenantStateButton
} from "src/tenantCustomizations/tenants/salvos/SendEmailAboutCustomerOrderTenantStateButton.tsx";
import {CustomerOrderQueryApi} from "src/api/generated/erp/sales/customerOrder/api/customerOrderQueryApi.ts";

export const getSalvosTaskContextMenuComponents = async (task: TaskView) => {
	return [await getSendEmailAboutCustomerOrderTenantStateButton(task)];
};

const getSendEmailAboutCustomerOrderTenantStateButton = async (task: TaskView) => {
	if (task.internalContext == null) return null;

	if (task.sourceType !== "CUSTOMER_ORDER" || task.sourceId == null) return;

	const regexMatch = task.internalContext.match(/CUSTOMER_ORDER_TENANT_STATE\|(\w+)/);
	if (regexMatch == null) return null;

	const newTenantState = regexMatch[1];
	if (newTenantState == null) return null;

	const tenantStateConfig = await CustomerOrderTenantStateApi.getCustomerOrderTenantStateConfig({
		customerOrderTenantState: newTenantState,
	});
	if (tenantStateConfig == null || !tenantStateConfig.createMailTask) return null;

	const customerOrder = await CustomerOrderQueryApi.getCustomerOrder({
		customerOrderId: task.sourceId,
	});

	return [
		<SalvosSendEmailAboutCustomerOrderTenantStateButton
			tenantStateConfig={tenantStateConfig}
			customerOrder={customerOrder}
		/>,
	];
};
