// This file is automatically generated. Do not edit manually.

import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace ProjectActivityMaterialReservationFormApi {
	export async function createReservations(args: {
		reservationRequests: Array<ProjectActivityMaterialReservationFormApi_ReservationRequest>;
	}): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/project/picking/activityMaterialReservationForm/createReservations",
			method: "POST",
			data: {
				reservationRequests: args.reservationRequests,
			},
		});
		return response as void;
	}
}

export interface ProjectActivityMaterialReservationFormApi_ReservationRequest {
	partWarehouseLocationId: number | null | undefined;
	projectActivityMaterialId: number;
	quantityInWarehouseUnit: number;
}
