import { PartConfiguratorView } from "../PartConfiguratorView";
import { ConfigurationCreationApi } from "src/api/generated/erp/configurator/api/configurationCreationApi";
import { UnsavedCustomerOrderLinePartConfiguratorType } from "src/api/generated/erp/configurator/configuratorType/impl/unsavedCustomerOrderLinePartConfiguratorType.ts";
import { CustomerOrderLine } from "src/api/generated/erp/db/types/tables/customerOrderLine.ts";
import { FormResult } from "src/components/common/forms/types.ts";

export interface CustomerOrderLineBeforeCreateConfiguratorViewProps {
	customerOrderLine: CustomerOrderLine; // Unsaved customerOrderLine
	initialValuesFromConfigurationId: number | undefined;
	onCompleted: (result: FormResult<number>) => void;
}

type UnsavedCustomerOrderLinePartConfiguratorTypeBackwardCompatible = UnsavedCustomerOrderLinePartConfiguratorType &
	{ customerOrderLine: CustomerOrderLine };

export const CustomerOrderLineBeforeCreateConfiguratorView = ({
	customerOrderLine,
	initialValuesFromConfigurationId,
	onCompleted,
}: CustomerOrderLineBeforeCreateConfiguratorViewProps) => {
	const configuratorType: UnsavedCustomerOrderLinePartConfiguratorTypeBackwardCompatible = {
		type: "unsavedCustomerOrderLine",
		customerOrderId: customerOrderLine.customerOrderId,
		plannedDeliveryDate: customerOrderLine.plannedDeliveryDate,
		salesPartId: customerOrderLine.salesPartId,
		salesQuantityAsSalesUnits: customerOrderLine.salesQuantityAsSalesUnits,
		shopOrderBatchCode: customerOrderLine.shopOrderBatchCode,
		customerOrderLine: customerOrderLine,
		initialValuesFromConfigurationId: initialValuesFromConfigurationId,
		isTestConfigurator: false,
		isSubConfigurator: false,
	};

	return (
		<PartConfiguratorView
			configuratorType={configuratorType}
			onCompleted={async (result) => {
				if (result.type === "success") {
					const { propertyValues, configurationSessionId } = result.value;
					const partConfigurationId =
						await ConfigurationCreationApi.createPartConfigurationForUnsavedCustomerOrderLine({
							configuratorType: configuratorType,
							propertyValues: propertyValues,
							configurationSessionId: configurationSessionId,
						});
					onCompleted({ type: "success", value: partConfigurationId });
				} else {
					onCompleted(result);
				}
			}}
		/>
	);
};
