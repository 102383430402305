// This file is automatically generated. Do not edit manually.

import { t } from "i18next";

export const PurchaseRequestStateValues = ["INITIAL", "ORDERED", "CANCELLED"] as const;

export type PurchaseRequestState = (typeof PurchaseRequestStateValues)[number];

export const getPurchaseRequestStateLabels = () => ({
	INITIAL: t("initial"),
	ORDERED: t("ordered"),
	CANCELLED: t("cancelled"),
});

export const getPurchaseRequestStateLabel = (value: PurchaseRequestState): string => {
	return getPurchaseRequestStateLabels()[value];
};
