import { FormCommonProps } from "src/components/common/forms/types.ts";
import { FormSelectField } from "src/components/common/forms/fields/FormSelectField.tsx";
import i18n from "i18next";
import { DeliverySourcePickingApi } from "src/api/generated/erp/delivery/api/deliverySourcePickingApi.ts";
import { AsyncForm } from "src/components/common/forms/AsyncForm.tsx";
import { PartWarehouseLocationQueryApi } from "src/api/generated/erp/parts/partWarehouseLocations/api/partWarehouseLocationQueryApi.ts";
import { FormNumberField } from "src/components/common/forms/fields/FormNumberField.tsx";
import { PartWarehouseLocationView } from "src/api/generated/erp/db/types/tables/partWarehouseLocationView.ts";
import { DeliveryPackageLineView } from "src/api/generated/erp/db/types/tables/deliveryPackageLineView.ts";
import { requireRule } from "src/components/common/forms/validation.ts";
import { createDeliveryPackageLineSource } from "src/components/views/erp/delivery/deliveryPackageLineSourceUtils.ts";
import { Typography } from "@mui/material";

export interface DecreaseDeliveryPackageLineQuantityFormProps extends FormCommonProps<void> {
	deliveryPackageLine: DeliveryPackageLineView;
}

interface FormValues {
	partWarehouseLocationId: number | null;
	quantityInBaseUnit: number;
}

export const DecreaseDeliveryPackageLineQuantityForm = ({
	deliveryPackageLine,
	onCompleted,
	onFormEdited,
}: DecreaseDeliveryPackageLineQuantityFormProps) => {
	return (
		<AsyncForm<PartWarehouseLocationView[], FormValues, void>
			fetch={() =>
				PartWarehouseLocationQueryApi.getPartWarehouseLocations({
					sitePartId: deliveryPackageLine.partId,
				})
			}
			getDefaultValues={(partWarehouseLocationOptions) => ({
				partWarehouseLocationId: partWarehouseLocationOptions.find((location) => location.isDefault)
					?.partWarehouseLocationId,
				quantityInBaseUnit: deliveryPackageLine.quantityInBaseUnit,
			})}
			render={({ control, data: partWarehouseLocationOptions }) => (
				<>
					<Typography>{deliveryPackageLine.configurationOrPartDescription}</Typography>
					<FormSelectField
						control={control}
						name={"partWarehouseLocationId"}
						label={i18n.t("return_warehouse_location")}
						options={partWarehouseLocationOptions}
						getOptionKey={(location) => location.partWarehouseLocationId}
						getOptionLabel={(location) => location.locationCode ?? ""}
					/>
					<FormNumberField
						control={control}
						name={"quantityInBaseUnit"}
						label={i18n.t("quantity_to_return")}
						rules={requireRule()}
					/>
				</>
			)}
			submit={async ({ partWarehouseLocationId, quantityInBaseUnit }: FormValues) => {
				await DeliverySourcePickingApi.pickPartsToPackage({
					deliveryPackageId: deliveryPackageLine.deliveryPackageId,
					parts: [
						{
							partId: deliveryPackageLine.partId,
							source: createDeliveryPackageLineSource({
								sourceType: deliveryPackageLine.sourceType,
								sourceId: deliveryPackageLine.sourceId,
							}),
							partWarehouseLocationId: partWarehouseLocationId,
							quantityToPickInBaseUnit: -quantityInBaseUnit,
						},
					],
				});
			}}
			onCompleted={onCompleted}
			onFormEdited={onFormEdited}
		/>
	);
};
