import { TransformedConfigurationComponent_Field } from "src/api/generated/erp/configurator/componentTransformation/model/transformedConfigurationComponent.ts";
import { ConfigurationPropertyValue } from "src/api/generated/io/aavo/applications/db/erp/types/configurationPropertyValue.ts";
import i18n from "i18next";

export function getConfiguratorFieldComponentLabelWithRequiredMark(
	field: TransformedConfigurationComponent_Field,
): string {
	return field.label + (field.required ? " *" : "");
}

export function getConfiguratorFieldComponentErrorMessage(
	field: TransformedConfigurationComponent_Field,
	value: ConfigurationPropertyValue,
): string | undefined {
	return field.required && (value.type === "null" || value.value === "") ? i18n.t("required") : undefined;
}
