import {ServerSideDataGridModel} from "src/components/common/dataGrid/gridModel/ServerSideDataGridModel.tsx";
import {
    AddSalesPartsToSalesContractApi
} from "src/api/generated/erp/sales/pricing/api/addSalesPartsToSalesContractApi.ts";
import {genericNullableValue} from "src/utils/genericNullableValue.ts";
import {AavoDataGrid} from "src/components/common/dataGrid/AavoDataGrid.tsx";
import {floatColumn, textColumn} from "src/components/common/dataGrid/columns.tsx";
import i18n from "i18next";
import {SalesPartView} from "src/api/generated/erp/db/types/tables/salesPartView.ts";
import {AavoTextField} from "src/components/common/inputFields/AavoTextField.tsx";
import {SelectField} from "src/components/common/inputFields/SelectField.tsx";
import {AsyncButton} from "src/components/common/buttons/AsyncButton.tsx";
import {faLink} from "@fortawesome/pro-regular-svg-icons";

export interface AddSalesPartsToSalesContractDataGridProps {
	salesContractId: number;
	onSubmit: () => void;
}

export const AddSalesPartsToSalesContractDataGrid = ({
	salesContractId,
	onSubmit,
}: AddSalesPartsToSalesContractDataGridProps) => {
	return (
		<ServerSideDataGridModel
			gridId={"85D675DBA2BA01A5"}
			fetchData={(props) =>
				AddSalesPartsToSalesContractApi.getGridData({ salesContractId: salesContractId, ...props })
			}
			getDataModelResult={(data) => data.data}
			getRowId={(row) => row.salesPartId}
			initialParams={{
				searchQuery: "",
				siteId: genericNullableValue<number>(null),
			}}
			render={({ data, dataGridProps, refreshData, selectedRows }) => {
				return (
					<AavoDataGrid<SalesPartView>
						columns={[
							textColumn({
								field: "siteName",
								headerName: i18n.t("site"),
							}),
							textColumn({
								field: "salesPartNo",
								headerName: i18n.t("sales_part_no"),
							}),
							textColumn({
								field: "partDescription_1",
								headerName: i18n.t("description_1"),
								width: 150,
							}),
							textColumn({
								field: "partDescription_2",
								headerName: i18n.t("description_2"),
								width: 150,
							}),
							floatColumn({
								field: "standardCostAsPriceUnits",
								headerName: i18n.t("cost"),
							}),
							textColumn({
								field: "salesPriceUnit",
								headerName: i18n.t("price_unit"),
							}),
						]}
						actionBarComponents={
							<>
								<AavoTextField
									label={i18n.t("search")}
									onSubmit={async (value) => {
										await refreshData({ searchQuery: value });
									}}
								/>
								<SelectField
									label={i18n.t("site")}
									options={data.siteOptions}
									getOptionLabel={(option) => option.siteName}
									getOptionKey={(option) => option.siteId}
									onChange={async (value) => {
										await refreshData({ siteId: value });
									}}
									minWidth={150}
								/>
								<AsyncButton
									icon={faLink}
									label={i18n.t("connect_selected")}
									variant={"outlined"}
									onClick={async () => {
										await AddSalesPartsToSalesContractApi.addSelectedParts({
											salesContractId: salesContractId,
											salesPartIds: selectedRows.map((row) => row.salesPartId),
										});
										onSubmit();
									}}
								/>
							</>
						}
						{...dataGridProps}
					/>
				);
			}}
		/>
	);
};
