import i18n from "i18next";
import { integerColumn, textColumn } from "src/components/common/dataGrid/columns";
import { NumberSequence } from "src/api/generated/erp/db/types/tables/numberSequence";
import { NumberSequencesApi } from "src/api/generated/erp/common/numberSequencesApi";
import { AsyncCrudDataGrid } from "src/components/common/dataGrid/crud/AsyncCrudDataGrid";
import { dataGridPersistentStateProps } from "src/components/common/dataGrid/dataGridStateStorage";

export const NumberSequencesDataGrid = () => {
	return (
		<AsyncCrudDataGrid<NumberSequence>
			fetchData={NumberSequencesApi.getAll}
			getRowId={(row) => {
				return row.numberSequenceId;
			}}
			columns={[
				textColumn({
					headerName: i18n.t("name"),
					field: "sequenceName",
					editable: true,
					width: 200,
					validate: "required",
				}),
				textColumn({
					headerName: i18n.t("description"),
					field: "sequenceDescription",
					width: 300,
					editable: true,
				}),
				textColumn({
					headerName: i18n.t("prefix"),
					field: "prefix",
					editable: true,
				}),
				integerColumn({
					headerName: i18n.t("value"),
					field: "sequenceValue",
					editable: true,
					validate: "required",
				}),
			]}
			add={{
				type: "enabled",
				action: async () => ({
					sequenceName: "",
					sequenceDescription: "",
					prefix: "",
					sequenceValue: 0,
				}),
			}}
			save={{
				type: "enabled",
				action: async (params) =>
					await NumberSequencesApi.save({
						numberSequences: params.items,
					}),
			}}
			remove={{
				type: "enabled",
				action: async (params) =>
					await NumberSequencesApi.delete_({
						numberSequences: params.items,
					}),
			}}
			{...dataGridPersistentStateProps("720285762D1179FD")}
		/>
	);
};
