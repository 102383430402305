import { Box } from "@mui/material";
import { ForwardedRef, forwardRef, useEffect, useRef } from "react";
import mapboxgl, { MapboxOptions } from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { useErrorDialog } from "src/components/common/dialogs/errorDialog/userErrorDialog.ts";
import { useForwardedRef } from "src/utils/useForwardedRef.ts";
import { useTenantCustomizations } from "src/tenantCustomizations/useTenantCustomizations.tsx";
import i18n from "i18next";
import { CenteredTypography } from "../CenteredTypography";
import { defaultProximity } from "src/components/common/mapbox/mapboxUtils.ts";

export interface MapViewProps {
	options?: Omit<MapboxOptions, "container">;
	onLoaded: (map: mapboxgl.Map) => Promise<unknown> | void;
}

export const MapView = forwardRef(({ options, onLoaded }: MapViewProps, ref: ForwardedRef<mapboxgl.Map>) => {
	const mapContainerRef = useRef(null);
	const mapRef = useForwardedRef(ref);

	const { logErrorAndShowOnDialog } = useErrorDialog();
	const { tenantConfig } = useTenantCustomizations();

	useEffect(() => {
		if (mapRef.current) return; // initialize map only once
		if (mapContainerRef.current === null) {
			console.log("Cannot initialize map because container ref is null");
			return;
		}
		const map = new mapboxgl.Map({
			...{
				container: mapContainerRef.current,
				style: "mapbox://styles/mapbox/streets-v12",
				center: defaultProximity,
				zoom: 8,
			},
			...options,
		});
		mapRef.current = map;

		map.on("load", async () => {
			try {
				await onLoaded?.(map);
			} catch (e) {
				console.error(e);
				logErrorAndShowOnDialog(e);
			}
		});
	});

	if (!tenantConfig.maps.enabled)
		return <CenteredTypography>{i18n.t("maps_disabled_message")}</CenteredTypography>;

	return (
		<Box
			ref={mapContainerRef}
			sx={{
				flex: 1,
				display: "flex",
			}}
		/>
	);
});
