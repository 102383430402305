// This file is automatically generated. Do not edit manually.

import { DeliverySource } from "src/api/generated/erp/delivery/model/deliverySource";
import { DeliverySourceType } from "src/api/generated/erp/db/types/enums/deliverySourceType";
import { ServerSideDataModelRequest } from "src/api/generated/common/dataGrids/serverSideDataModelRequest";
import { ServerSideDataModelResult } from "src/api/generated/common/dataGrids/serverSideDataModelResult";
import { DeliveryView } from "src/api/generated/erp/db/types/tables/deliveryView";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace DeliveriesDataGridApi {
	export async function getDataGridData(args: {
		deliverySource?: DeliverySource | null | undefined;
		deliverySourceType?: DeliverySourceType | null | undefined;
		onlyUndelivered?: boolean;
		onlyDefaultSite?: boolean;
		searchQuery?: string;
		onlyDeliveryId?: number | null | undefined;
		dataModelRequest: ServerSideDataModelRequest;
	}): Promise<ServerSideDataModelResult<DeliveryView>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/delivery/getDataGridData",
			method: "POST",
			data: {
				deliverySource: args.deliverySource,
				deliverySourceType: args.deliverySourceType,
				onlyUndelivered: args.onlyUndelivered,
				onlyDefaultSite: args.onlyDefaultSite,
				searchQuery: args.searchQuery,
				onlyDeliveryId: args.onlyDeliveryId,
				dataModelRequest: args.dataModelRequest,
			},
		});
		return response as ServerSideDataModelResult<DeliveryView>;
	}
}
