// This file is automatically generated. Do not edit manually.

import { ConfigurationProductFamily } from "src/api/generated/erp/db/types/tables/configurationProductFamily";
import { ConfigurationProductFamilyCategory } from "src/api/generated/erp/db/types/tables/configurationProductFamilyCategory";
import { ConfigurationProductFamilyView } from "src/api/generated/erp/db/types/tables/configurationProductFamilyView";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace ConfigurationProductFamiliesApi {
	export async function getConfigurationProductFamiliesGridData(args: {
		searchQuery?: string;
		categoryId: number | null | undefined;
	}): Promise<ConfigurationProductFamiliesApi_GridData> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/configurator/managing/productFamily/getConfigurationProductFamiliesGridData",
			method: "POST",
			data: {
				searchQuery: args.searchQuery,
				categoryId: args.categoryId,
			},
		});
		return response as ConfigurationProductFamiliesApi_GridData;
	}

	export async function insert(args: { productFamily: ConfigurationProductFamily }): Promise<number> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/configurator/managing/productFamily/insert",
			method: "POST",
			data: {
				productFamily: args.productFamily,
			},
		});
		return response as number;
	}

	export async function update(args: { productFamily: ConfigurationProductFamily }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/configurator/managing/productFamily/update",
			method: "POST",
			data: {
				productFamily: args.productFamily,
			},
		});
		return response as void;
	}

	export async function delete_(args: { configurationProductFamilyId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/configurator/managing/productFamily/delete",
			method: "POST",
			data: {
				configurationProductFamilyId: args.configurationProductFamilyId,
			},
		});
		return response as void;
	}
}

export interface ConfigurationProductFamiliesApi_GridData {
	categoryOptions: Array<ConfigurationProductFamilyCategory>;
	productFamilies: Array<ConfigurationProductFamilyView>;
}
