// This file is automatically generated. Do not edit manually.

import { FrontendDownloadFileHandle } from "src/api/generated/common/frontendDownloadFileHandle";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace PurchaseOrderActionApi {
	export async function releasePurchaseOrder(args: { purchaseOrderId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/purchase/purchaseOrder/releasePurchaseOrder",
			method: "POST",
			data: {
				purchaseOrderId: args.purchaseOrderId,
			},
		});
		return response as void;
	}

	export async function confirmPurchaseOrder(args: { purchaseOrderId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/purchase/purchaseOrder/confirmPurchaseOrder",
			method: "POST",
			data: {
				purchaseOrderId: args.purchaseOrderId,
			},
		});
		return response as void;
	}

	export async function cancelPurchaseOrder(args: { purchaseOrderId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/purchase/purchaseOrder/cancelPurchaseOrder",
			method: "POST",
			data: {
				purchaseOrderId: args.purchaseOrderId,
			},
		});
		return response as void;
	}

	export async function printPurchaseOrder(args: { purchaseOrderId: number }): Promise<FrontendDownloadFileHandle> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/purchase/purchaseOrder/printPurchaseOrder",
			method: "POST",
			data: {
				purchaseOrderId: args.purchaseOrderId,
			},
		});
		return response as FrontendDownloadFileHandle;
	}
}
