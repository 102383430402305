import { ClientSideDataGridModel } from "src/components/common/dataGrid/gridModel/ClientSideDataGridModel.tsx";
import { CrudDataGrid } from "src/components/common/dataGrid/crud/CrudDataGrid.tsx";
import { CatalogPartWorkspacesDataGridApi } from "src/api/generated/erp/parts/catalogPart/api/catalogPartWorkspacesDataGridApi";
import { WorkspaceView } from "src/api/generated/postgres/db/types/workspaces/tables/workspaceView.ts";
import { textColumn } from "src/components/common/dataGrid/columns.tsx";
import i18n from "i18next";
import { faShareSquare } from "@fortawesome/pro-regular-svg-icons";
import { useOpenLegacyView } from "src/components/views/legacy/useOpenLegacyView.ts";
import { AsyncMenuButton } from "src/components/common/contextMenu/AsyncMenuButton.tsx";

export interface CatalogPartWorkspacesDataGridProps {
	catalogPartId: number;
}

export const CatalogPartWorkspacesDataGrid = ({ catalogPartId }: CatalogPartWorkspacesDataGridProps) => {
	const openLegacyView = useOpenLegacyView();
	return (
		<ClientSideDataGridModel
			gridId={"D1F928DD53230A04"}
			fetchData={() =>
				CatalogPartWorkspacesDataGridApi.getCatalogPartWorkspaces({ catalogPartId: catalogPartId })
			}
			initialParams={{}}
			getRows={(data) => data}
			getRowId={(row) => row.workspaceId}
			render={({ dataGridProps }) => {
				return (
					<CrudDataGrid<WorkspaceView>
						columns={[
							textColumn({
								field: "workspaceName",
								headerName: i18n.t("workspace"),
							}),
							textColumn({
								field: "description",
								headerName: i18n.t("description"),
								width: 300,
							}),
							textColumn({
								field: "responsiblePersonName",
								headerName: i18n.t("responsible_person"),
							}),
						]}
						rowContextMenuComponents={({ row }) => [
							<AsyncMenuButton
								icon={faShareSquare}
								label={i18n.t("open")}
								onClick={() => {
									if (row.workspaceId == null) return;
									openLegacyView("E5EA27DCCC63FBB4", {
										_workspace_id_filter_list: JSON.stringify([row.workspaceId]),
									});
								}}
							/>,
						]}
						{...dataGridProps}
					/>
				);
			}}
		/>
	);
};
