import { Task } from "src/api/generated/postgres/db/types/tasks/tables/task.ts";
import { TaskView } from "src/api/generated/postgres/db/types/tasks/tables/taskView.ts";
import { Theme } from "@mui/material/styles";

export const taskIsSaved = (task: Task) => {
	return task.taskId !== undefined && task.taskId !== -1;
};

export const getTaskColor = (task: TaskView, { palette }: Theme) => {
	if (task.taskState === "COMPLETED") return palette.state.completed;
	if (task.taskIsLate) return palette.error.light;
	switch (task.taskState) {
		case "INITIAL":
			return palette.state.initial
		case "RELEASED":
			return palette.state.released
		case "STARTED":
			return palette.state.inProgress
		case "CANCELLED":
			return palette.state.cancelled
	}
};
