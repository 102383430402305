// This file is automatically generated. Do not edit manually.

import { OAuthAuthenticationRequest } from "src/api/generated/common/oauth/oAuthAuthenticationRequest";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace DocumentSendingApi {
	export async function ensureDocumentSendingAuthentication(): Promise<OAuthAuthenticationRequest | null> {
		const response = await makeAxiosRequest({
			url: "/api/ext/documents/ensureDocumentSendingAuthentication",
			method: "POST",
			data: {},
		});
		return response === "" ? null : (response as OAuthAuthenticationRequest | null);
	}

	export async function sendDocuments(args: {
		mailReceiver: string;
		title: string;
		message: string;
		sendAsZipFile: boolean;
		documentIds: Array<number>;
	}): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/documents/sendDocuments",
			method: "POST",
			data: {
				mailReceiver: args.mailReceiver,
				title: args.title,
				message: args.message,
				sendAsZipFile: args.sendAsZipFile,
				documentIds: args.documentIds,
			},
		});
		return response as void;
	}
}
