// This file is automatically generated. Do not edit manually.

import { PartView } from "src/api/generated/erp/db/types/tables/partView";
import { ProjectActivityMaterial } from "src/api/generated/erp/db/types/tables/projectActivityMaterial";
import { ProjectActivityView } from "src/api/generated/erp/db/types/tables/projectActivityView";
import { ProjectActivityMaterialView } from "src/api/generated/erp/db/types/tables/projectActivityMaterialView";
import { SupplierPartView } from "src/api/generated/erp/db/types/tables/supplierPartView";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace ProjectActivityMaterialEditApi {
	export async function getFormInitData(args: {
		activityId: number;
		materialLineId: number | null | undefined;
	}): Promise<ProjectActivityMaterialEditApi_FormInitData> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/project/activityMaterials/getFormInitData",
			method: "POST",
			data: {
				activityId: args.activityId,
				materialLineId: args.materialLineId,
			},
		});
		return response as ProjectActivityMaterialEditApi_FormInitData;
	}

	export async function getPartOptions(args: {
		activityId: number;
		currentSelection: number | null | undefined;
		searchQuery: string;
	}): Promise<Array<PartView>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/project/activityMaterials/getPartOptions",
			method: "POST",
			data: {
				activityId: args.activityId,
				currentSelection: args.currentSelection,
				searchQuery: args.searchQuery,
			},
		});
		return response as Array<PartView>;
	}

	export async function insert(args: { materialLine: ProjectActivityMaterial }): Promise<number> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/project/activityMaterials/insert",
			method: "POST",
			data: {
				materialLine: args.materialLine,
			},
		});
		return response as number;
	}

	export async function update(args: { materialLine: ProjectActivityMaterial }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/project/activityMaterials/update",
			method: "POST",
			data: {
				materialLine: args.materialLine,
			},
		});
		return response as void;
	}
}

export interface ProjectActivityMaterialEditApi_FormInitData {
	activity: ProjectActivityView;
	defaultLineNumber: number;
	materialLine: ProjectActivityMaterialView | null | undefined;
	part: PartView | null | undefined;
	supplierPartOptions: Array<SupplierPartView> | null | undefined;
}
