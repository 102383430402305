import { AavoTextField } from "src/components/common/inputFields/AavoTextField.tsx";
import { labelWithRequiredMark, surveyFieldValueToString } from "src/components/views/erp/surveys/surveyUtils.ts";
import { FLOAT_INPUT_REGEX } from "src/utils/regexPatterns.ts";
import { toFloatOrNull } from "src/utils/strings.tsx";
import { SurveySubmissionFormFieldProps } from "src/components/views/erp/surveys/submissions/fields/SurveySubmissionFormField.tsx";

export const SurveySubmissionFormTextField = ({
	field,
	value: valueProp,
	onChange: onChangeProp,
	error,
	disabled,
}: SurveySubmissionFormFieldProps) => {
	return (
		<AavoTextField
			label={labelWithRequiredMark(field)}
			value={surveyFieldValueToString(valueProp)}
			onChange={onChange}
			multiline={field.fieldType === "TEXTAREA"}
			error={error}
			disabled={disabled}
			type={field.fieldType === "INTEGER" || field.fieldType === "DECIMAL" ? "number" : "text"}
		/>
	);

	function onChange(input: string) {
		switch (field.fieldType) {
			case "INTEGER":
			case "DECIMAL": {
				if (!input.match(FLOAT_INPUT_REGEX) && input !== "") return;
				const parsedValue = parseInputToNumber(input);
				onChangeProp(
					parsedValue == null ? null : (
						{
							type: "number",
							value: parsedValue,
						}
					),
				);
				break;
			}
			default: {
				onChangeProp({
					type: "string",
					value: input,
				});
				break;
			}
		}
	}

	function parseInputToNumber(value: string): number | null {
		const withoutComma = value.replace(",", ".");
		const withoutLastDot = withoutComma.replace(/\.$/, "");
		return toFloatOrNull(withoutLastDot);
	}
};
