// This file is automatically generated. Do not edit manually.

import { t } from "i18next";

export const CustomerAddressTypeValues = ["DOCUMENT", "DELIVERY", "VISIT"] as const;

export type CustomerAddressType = (typeof CustomerAddressTypeValues)[number];

export const getCustomerAddressTypeLabels = () => ({
	DOCUMENT: t("document"),
	DELIVERY: t("delivery"),
	VISIT: t("visit"),
});

export const getCustomerAddressTypeLabel = (value: CustomerAddressType): string => {
	return getCustomerAddressTypeLabels()[value];
};
