import { CustomerOrderDataGridApi_CustomerOrderDto } from "src/api/generated/erp/sales/customerOrder/api/customerOrderDataGridApi.ts";
import { CustomerOrderActionApi } from "src/api/generated/erp/sales/customerOrder/api/customerOrderActionApi.ts";
import { dayJsToDateIsoString } from "src/utils/dayjsUtils.ts";
import i18n from "i18next";
import { ProjectQueryApi } from "src/api/generated/erp/project/project/api/projectQueryApi.ts";
import { CustomerOrderQueryApi } from "src/api/generated/erp/sales/customerOrder/api/customerOrderQueryApi.ts";
import { OpenInputDialogFunc } from "../../../../common/dialogs/input/useInputDialog.tsx";

export async function createProjectForCustomerOrder(
	row: CustomerOrderDataGridApi_CustomerOrderDto,
	showInputDialog: OpenInputDialogFunc,
) {
	const templateProjectId = await askModelProject();
	const startDate = await askStartDate();
	if (templateProjectId == null || startDate == null) return;
	await CustomerOrderActionApi.createProjectFromCustomerOrder({
		customerOrderId: row.customerOrderId,
		templateProjectId: templateProjectId,
		plannedStartDate: dayJsToDateIsoString(startDate),
	});

	function askModelProject() {
		return showInputDialog({
			defaultValue: null,
			type: "singleNumberSelect",
			title: i18n.t("select_model_project"),
			fieldLabel: i18n.t("model_project"),
			required: true,
			fieldProps: {
				selection: {
					options: async ({ currentSelection, searchQuery }) => {
						const modelProjects = await ProjectQueryApi.getProjectSelectionOptions({
							siteId: row.siteId,
							currentSelection,
							searchQuery,
						});
						return modelProjects.map((x) => ({
							value: x.projectId,
							label: x.projectDescription,
						}));
					},
				},
			},
		});
	}

	function askStartDate() {
		return showInputDialog({
			defaultValue: null,
			type: "date",
			title: i18n.t("select_start_date"),
			fieldLabel: i18n.t("start_date"),
			required: true,
		});
	}
}

export async function connectCustomerOrderToProject(
	row: CustomerOrderDataGridApi_CustomerOrderDto,
	showInputDialog: OpenInputDialogFunc,
) {
	const projectId = await askProject();
	if (projectId == null) return;
	await CustomerOrderActionApi.connectCustomerOrderToProject({
		customerOrderId: row.customerOrderId,
		projectId: projectId,
	});

	function askProject() {
		return showInputDialog({
			defaultValue: null,
			type: "singleNumberSelect",
			title: i18n.t("connect_to_project"),
			fieldLabel: i18n.t("project"),
			required: true,
			fieldProps: {
				selection: {
					options: async ({ searchQuery, currentSelection }) => {
						const projects = await ProjectQueryApi.getProjectSelectionOptions({
							searchQuery: searchQuery,
							currentSelection: currentSelection,
							siteId: row.siteId,
							includeTemplates: false,
						});
						return projects.map((x) => ({
							value: x.projectId,
							label: x.projectDescription,
						}));
					},
				},
			},
		});
	}
}

export async function copyCustomerOrderTasks(
	row: CustomerOrderDataGridApi_CustomerOrderDto,
	showInputDialog: OpenInputDialogFunc,
) {
	const sourceCustomerOrderId = await askSourceCustomerOrder();
	if (sourceCustomerOrderId == undefined) return;
	await CustomerOrderActionApi.copyCustomerOrderTasks({
		sourceCustomerOrderId: sourceCustomerOrderId,
		targetCustomerOrderId: row.customerOrderId,
	});

	function askSourceCustomerOrder() {
		return showInputDialog({
			defaultValue: null,
			type: "singleNumberSelect",
			title: i18n.t("copy_tasks_from_customer_order"),
			fieldLabel: i18n.t("customer_order"),
			required: true,
			fieldProps: {
				selection: {
					options: async ({ searchQuery }) => {
						const customerOrders = await CustomerOrderQueryApi.getCustomerOrderOptions({
							searchQuery: searchQuery,
							siteId: row.siteId,
						});
						return customerOrders.map((order) => ({
							value: order.customerOrderId,
							label: `${order.customerOrderId} | ${order.orderReference}`,
						}));
					},
				},
			},
		});
	}
}
