// This file is automatically generated. Do not edit manually.

import { Supplier } from "src/api/generated/erp/db/types/tables/supplier";
import { SupplierPartView } from "src/api/generated/erp/db/types/tables/supplierPartView";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace SupplierApi {
	export async function getSupplierOptions(args: {
		searchQuery: string;
		currentSelectionId: number | null | undefined;
	}): Promise<Array<Supplier>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/purchase/suppliers/getSupplierOptions",
			method: "POST",
			data: {
				searchQuery: args.searchQuery,
				currentSelectionId: args.currentSelectionId,
			},
		});
		return response as Array<Supplier>;
	}

	export async function getPartSupplierOptions(args: { partId: number }): Promise<Array<SupplierPartView>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/purchase/suppliers/getPartSupplierOptions",
			method: "POST",
			data: {
				partId: args.partId,
			},
		});
		return response as Array<SupplierPartView>;
	}
}
