// This file is automatically generated. Do not edit manually.

import { t } from "i18next";

export const WarehousePickingObjectTypeValues = [
	"CUSTOMER_ORDER_LINE",
	"SHOP_ORDER_LINE",
	"WAREHOUSE_TRANSFER",
	"PROJECT_ACTIVITY_MATERIAL",
] as const;

export type WarehousePickingObjectType = (typeof WarehousePickingObjectTypeValues)[number];

export const getWarehousePickingObjectTypeLabels = () => ({
	CUSTOMER_ORDER_LINE: t("customer_order_line"),
	SHOP_ORDER_LINE: t("shop_order_line"),
	WAREHOUSE_TRANSFER: t("warehouse_transfer"),
	PROJECT_ACTIVITY_MATERIAL: t("project_activity_material"),
});

export const getWarehousePickingObjectTypeLabel = (value: WarehousePickingObjectType): string => {
	return getWarehousePickingObjectTypeLabels()[value];
};
