import { AsyncFetchRender } from "src/components/common/async/AsyncFetchRender.tsx";
import { CatalogPartRevisionConfiguratorSelectionsApi } from "src/api/generated/erp/configurator/management/catalogPart/api/catalogPartRevisionConfiguratorSelectionsApi.ts";
import {
	CatalogPartRevisionConfiguratorSelectionsView,
	CatalogPartRevisionConfiguratorSelectionsViewProps,
} from "src/components/views/erp/configurator/managing/catalogPart/configuratorSelections/CatalogPartRevisionConfiguratorSelectionsView.tsx";

export interface CatalogPartRevisionConfiguratorSelectionsAsyncViewProps
	extends Omit<
		CatalogPartRevisionConfiguratorSelectionsViewProps,
		"productFamilySelectionOptions" | "currentSelections"
	> {}

export const CatalogPartRevisionConfiguratorSelectionsAsyncView = (
	props: CatalogPartRevisionConfiguratorSelectionsAsyncViewProps,
) => {
	const { catalogPartRevisionId, productFamilyVersionId } = props;

	return (
		<AsyncFetchRender<
			Pick<
				CatalogPartRevisionConfiguratorSelectionsViewProps,
				"productFamilySelectionOptions" | "currentSelections"
			>
		>
			fetch={fetchData}
			content={(data) => <CatalogPartRevisionConfiguratorSelectionsView {...data} {...props} />}
		/>
	);

	async function fetchData() {
		const [productFamilySelectionOptions, currentSelections] = await Promise.all([
			CatalogPartRevisionConfiguratorSelectionsApi.getProductFamilyVersionConfiguratorSelectionOptions({
				productFamilyVersionId,
			}),
			CatalogPartRevisionConfiguratorSelectionsApi.getRevisionConfiguratorSelections({
				catalogPartRevisionId,
				productFamilyVersionId,
			}),
		]);

		return {
			productFamilySelectionOptions,
			currentSelections,
		};
	}
};
