// This file is automatically generated. Do not edit manually.

import { FrontendUploadFileHandle } from "src/api/generated/common/frontendUploadFileHandle";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace CChartEventCollectionApi {
	export async function getUserEventCollectionChartGroups(): Promise<
		Array<CChartEventCollectionApi_CChartEventCollectionChartGroup>
	> {
		const response = await makeAxiosRequest({
			url: "/api/ext/spc/cChartEvents/getUserEventCollectionChartGroups",
			method: "POST",
			data: {},
		});
		return response as Array<CChartEventCollectionApi_CChartEventCollectionChartGroup>;
	}

	export async function getCCharts(args: {
		chartGroupId: number;
	}): Promise<Array<CChartEventCollectionApi_CChartDto>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/spc/cChartEvents/getCCharts",
			method: "POST",
			data: {
				chartGroupId: args.chartGroupId,
			},
		});
		return response as Array<CChartEventCollectionApi_CChartDto>;
	}

	export async function addEvent(args: {
		controlChartId: number;
		dimensions?: Array<string>;
		fileHandle?: FrontendUploadFileHandle | null | undefined;
		fileName?: string;
	}): Promise<number> {
		const response = await makeAxiosRequest({
			url: "/api/ext/spc/cChartEvents/addEvent",
			method: "POST",
			data: {
				controlChartId: args.controlChartId,
				dimensions: args.dimensions,
				fileHandle: args.fileHandle,
				fileName: args.fileName,
			},
		});
		return response as number;
	}

	export async function removeLatestEvent(args: { controlChartId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/spc/cChartEvents/removeLatestEvent",
			method: "POST",
			data: {
				controlChartId: args.controlChartId,
			},
		});
		return response as void;
	}
}

export interface CChartEventCollectionApi_CChartEventCollectionChartGroup {
	chartGroupId: number;
	name: string;
}

export interface CChartEventCollectionApi_CChartDto {
	controlChartId: number;
	currentEventCount: number;
	dimensions: Array<CChartEventCollectionApi_CChartDto_Dimension>;
	documentsEnabled: boolean;
	name: string;
}

export interface CChartEventCollectionApi_CChartDto_Dimension {
	label: string;
	previousValue: string;
}
