import { GridRenderEditCellParams } from "@mui/x-data-grid-pro";
import { FormControl, FormHelperText } from "@mui/material";
import { DataGridValidationContext } from "src/components/common/dataGrid/crud/validation/DataGridValidationContext.tsx";
import { ColumnValidationFunction } from "./columnValidation";
import React, { useEffect } from "react";

import { useContextOrThrow } from "src/utils/useContextOrThrow.tsx";

export interface CellEditorWithValidationProps<RowData extends object, V> {
	validationFunc: ColumnValidationFunction<RowData, V>;
	params: GridRenderEditCellParams<RowData, V, V>;
	cellEditor: React.ComponentType<GridRenderEditCellParams>;
}

export const CellEditorWithValidation = <RowData extends object, V>(
	props: CellEditorWithValidationProps<RowData, V>,
) => {
	const { params, validationFunc } = props;
	const { setError } = useContextOrThrow(DataGridValidationContext);
	const { value, field, id: rowId, row, hasFocus } = params;
	const error = validationFunc(value, row);
	const hasErrorAndNoFocus = error !== undefined && !hasFocus;

	useEffect(() => {
		setError(rowId, field, error);
	}, [setError, error, field, rowId]);

	return (
		<FormControl
			error={hasErrorAndNoFocus}
			variant={"standard"}
			tabIndex={0}
			sx={{
				height: "100%",
				flex: 1,
				alignSelf: "stretch",
				display: "flex",
				flexDirection: "row",
				alignItems: "stretch",
				"& > *": {
					flex: 1,
				},
			}}
		>
			<props.cellEditor
				{...params}
				aria-describedby={"cell-editor-helper-text"}
				tabIndex={0}
				error={hasErrorAndNoFocus}
			/>
			<FormHelperText
				id={"cell-editor-helper-text"}
				sx={{
					position: "absolute",
					left: "0.5rem",
				}}
			>
				{hasErrorAndNoFocus ? error : ""}
			</FormHelperText>
		</FormControl>
	);
};
