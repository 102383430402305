import {
	ProjectObjectGanttBase,
	ProjectObjectGanttBaseProps,
} from "src/components/views/erp/project/gantt/ProjectObjectGanttBase.tsx";
import { ProjectGanttViewApi } from "src/api/generated/erp/project/api/projectGanttViewApi.ts";

export interface ProjectActivityGanttViewProps
	extends Pick<
		ProjectObjectGanttBaseProps,
		"onProjectScheduled" | "onSubProjectScheduled" | "onActivityScheduled" | "onTaskScheduled"
	> {
	activityIds: number[];
}

export const ProjectActivityGanttView = ({
	activityIds,
	...other
}: ProjectActivityGanttViewProps) => {
	return (
		<ProjectObjectGanttBase
			rootType={"ACTIVITY"}
			fetchData={() => ProjectGanttViewApi.getGanttDataForActivities({ activityIds })}
			{...other}
		/>
	);
};
