import type { DropTargetMonitor } from "react-dnd";
import { useDrop } from "react-dnd";
import { NativeTypes } from "react-dnd-html5-backend";

export interface UseFileInputDndParams {
	ref: React.RefObject<unknown>;
	canDrop?: boolean;
	onDrop: (value: File[]) => void;
}

export interface UseFileInputDndReturn {
	isDraggingOver: boolean;
}

export const useFileInputDnd = (
	{
		ref,
		canDrop = true,
		onDrop
	}: UseFileInputDndParams
): UseFileInputDndReturn => {
	const [{ isOver }, drop] = useDrop(
		() => ({
			accept: [NativeTypes.FILE],
			drop(item: { files: File[] }) {
				onDrop(item.files);
			},
			canDrop() {
				return canDrop;
			},
			collect: (monitor: DropTargetMonitor) => {
				return {
					isOver: monitor.isOver(),
					canDrop: monitor.canDrop()
				};
			}
		})
	);

	drop(ref);

	return {
		isDraggingOver: isOver
	};
};