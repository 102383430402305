import { SimpleSelectFieldOption } from "../../inputFields/types";

export const enumOptions = <T extends string>(enumLabels: {
	[key in T]: string;
}): readonly SimpleSelectFieldOption<T>[] => {
	return Object.entries<string>(enumLabels).map(([key, label]) => {
		return {
			key: key as T,
			label: label,
		};
	});
};
