// This file is automatically generated. Do not edit manually.

import { CatalogPartView } from "src/api/generated/erp/db/types/tables/catalogPartView";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace ConfigurationProductFamilyCatalogPartsApi {
	export async function getConfigurationProductFamilyCatalogParts(args: {
		productFamilyId: number;
	}): Promise<Array<CatalogPartView>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/configurator/managing/productFamily/getConfigurationProductFamilyCatalogParts",
			method: "POST",
			data: {
				productFamilyId: args.productFamilyId,
			},
		});
		return response as Array<CatalogPartView>;
	}
}
