// This file is automatically generated. Do not edit manually.

import { PartConfigurationOperationView } from "src/api/generated/erp/db/types/tables/partConfigurationOperationView";
import { PartConfigurationOperation } from "src/api/generated/erp/db/types/tables/partConfigurationOperation";
import { Operation } from "src/api/generated/erp/db/types/tables/operation";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace PartConfigurationOperationApi {
	export async function getPartConfigurationOperations(args: {
		partConfigurationId: number;
	}): Promise<Array<PartConfigurationOperationView>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/configurator/configuration/operations/getPartConfigurationOperations",
			method: "POST",
			data: {
				partConfigurationId: args.partConfigurationId,
			},
		});
		return response as Array<PartConfigurationOperationView>;
	}

	export async function saveOperation(args: {
		partConfigurationOperation: PartConfigurationOperation;
	}): Promise<number> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/configurator/configuration/operations/saveOperation",
			method: "POST",
			data: {
				partConfigurationOperation: args.partConfigurationOperation,
			},
		});
		return response as number;
	}

	export async function deleteOperations(args: { partConfigurationOperationIds: Array<number> }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/configurator/configuration/operations/deleteOperations",
			method: "POST",
			data: {
				partConfigurationOperationIds: args.partConfigurationOperationIds,
			},
		});
		return response as void;
	}

	export async function getFormInitData(args: {
		partConfigurationId: number;
		partConfigurationOperationId: number | null | undefined;
	}): Promise<PartConfigurationOperationApi_FormInitData> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/configurator/configuration/operations/getFormInitData",
			method: "POST",
			data: {
				partConfigurationId: args.partConfigurationId,
				partConfigurationOperationId: args.partConfigurationOperationId,
			},
		});
		return response as PartConfigurationOperationApi_FormInitData;
	}
}

export interface PartConfigurationOperationApi_FormInitData {
	configurationOperations: Array<PartConfigurationOperationView>;
	nextPosition: number;
	operationOptions: Array<Operation>;
}
