import { AavoMosaic } from "src/components/common/mosaic/AavoMosaic.tsx";
import i18n from "i18next";
import { useState } from "react";
import { CenteredTypography } from "src/components/common/CenteredTypography.tsx";
import { ControlChartsDataGrid } from "src/components/views/spc/basedata/controlCharts/ControlChartsDataGrid.tsx";
import { UserNotificationsOfSourceDataGrid } from "src/components/views/notifications/UserNotificationsOfSourceDataGrid.tsx";
import { ControlChartDataGridApi } from "src/api/generated/spc/controlChart/api/controlChartDataGridApi.ts";
import { useRefreshRef } from "src/utils/useRefreshRef.ts";
import { ControlChartGroupTreeView } from "src/components/views/spc/basedata/controlCharts/treeView/ControlChartGroupTreeView.tsx";
import { ChartGroupTreeItemModel } from "src/components/views/spc/basedata/controlCharts/treeView/ControlChartGroupTreeViewItemMapping.ts";

export const ControlChartsManagementView = () => {
	const [selectedGroupItem, setSelectedGroupItem] = useState<ChartGroupTreeItemModel | null>(null);
	const controlChartRefreshRef = useRefreshRef();
	return (
		<AavoMosaic
			viewId={"E0F50F1CA205320D7DB"}
			layout={{
				type: "row",
				splitPercentage: 30,
				first: {
					type: "panel",
					title: i18n.t("groups"),
					content: <ControlChartGroupTreeView onSelectionChanged={setSelectedGroupItem} />,
				},
				second: {
					type: "tabs",
					items: [
						{
							type: "panel",
							title: i18n.t("control_charts"),
							content: (
								<ControlChartsDataGrid
									key={selectedGroupItem?.id ?? "-1"}
									fetchData={(props) =>
										ControlChartDataGridApi.getGroupOrParentGroupControlCharts({
											chartGroupId:
												selectedGroupItem?.type === "chartGroup" ?
													selectedGroupItem.chartGroupId
												:	undefined,
											parentGroupId:
												selectedGroupItem?.type === "chartParentGroup" ?
													selectedGroupItem.parentGroupId
												:	undefined,
											...props,
										})
									}
									newControlChartFormProps={
										selectedGroupItem?.type === "chartGroup" ?
											{
												chartGroupId: selectedGroupItem.chartGroupId,
											}
										:	null
									}
									refreshRef={controlChartRefreshRef}
									cloneProps={
										selectedGroupItem?.type === "chartGroup" ?
											{
												type: "chartGroup",
												chartGroupId: selectedGroupItem.chartGroupId,
											}
										:	null
									}
								/>
							),
						},
						{
							type: "panel",
							title: i18n.t("notifications"),
							hidden: selectedGroupItem?.type != "chartGroup",
							content:
								selectedGroupItem?.type != "chartGroup" ?
									<CenteredTypography children={i18n.t("select_chart_group")} />
								:	<UserNotificationsOfSourceDataGrid
										key={selectedGroupItem.id}
										sourceType={"CHART_GROUP"}
										sourceId={selectedGroupItem.chartGroupId}
									/>,
						},
					],
				},
			}}
		/>
	);
};
