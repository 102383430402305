import {MultiSelectField, MultiSelectFieldProps} from "../../inputFields/MultiSelectField";
import {SimpleSelectFieldOption} from "src/components/common/inputFields/types";
import {FieldPath, FieldPathValue, useController, UseControllerProps} from "react-hook-form";
import {logError} from "src/errorHandling/errorLogging.ts";

export interface FormMultiSelectFieldProps<
	TFieldValues extends object,
	TFieldName extends FieldPath<TFieldValues>,
	Key extends string | number,
> extends Omit<
			MultiSelectFieldProps<SimpleSelectFieldOption<Key>, Key>,
			"onChange" | "value" | "getOptionKey" | "getOptionLabel"
		>,
		Pick<UseControllerProps<TFieldValues, TFieldName>, "control" | "name" | "rules"> {
	encodeValue?: (v: Key[]) => string;
	decodeValue?: (v: string) => Key[];
}

export const FormMultiSelectField = <
	TFieldValues extends object,
	TFieldName extends FieldPath<TFieldValues>,
	Key extends string | number,
>({
	control,
	name,
	rules,
	options,
	encodeValue,
	decodeValue: decodeValueProp,
	...other
}: FormMultiSelectFieldProps<TFieldValues, TFieldName, Key>) => {
	const defaultValue = encodeValue ? encodeValue([]) : [];
	const { field, fieldState } = useController({
		name,
		control,
		rules,
		defaultValue: defaultValue as FieldPathValue<TFieldValues, TFieldName>,
	});
	const transformedField = {
		...field,
		value: decodeValue(),
		onChange: (v: Key[]) => {
			field.onChange(encodeValue ? encodeValue(v) : v);
		},
		ref: undefined,
	};

	return (
		<MultiSelectField<SimpleSelectFieldOption<Key>, Key>
			options={options}
			getOptionKey={(o): Key => {
				return o.key;
			}}
			getOptionLabel={(o): string => {
				return o.label;
			}}
			error={fieldState.error?.message}
			{...transformedField}
			{...other}
		/>
	);

	function decodeValue() {
		try {
			if (decodeValueProp == null || Array.isArray(field.value)) return field.value;
			else return decodeValueProp(field.value);
		} catch (e) {
			logError(e);
			return [];
		}
	}
};
