import "regenerator-runtime/runtime";
import { html, LitElement } from "lit";
import equal from "fast-deep-equal";
import {
	dateFormatter,
	dateTimeFormatter,
	firstLineFormatter,
	floatFormatter,
	selectionColumnFormatter,
	textFormatter,
	timeFormatter,
} from "./agGridFormatters";
import { onAppContentAreaChanged } from "src/legacyViews/js/appContentAreaChangedEvent";
import { elementIsVisible, isMobileOrTablet, isNullOrWhitespace, mapObjectKeys } from "../../utils";
import { AavoInputFieldCellEditor } from "./aavoInputFieldCellEditor";
import { CheckboxCellRenderer } from "./checkBoxCellRenderer";
import { htmlCellRenderer, iconCellRenderer, imageCellRenderer, linkCellRenderer } from "./htmlCellRenderers";
import { getFilesFromDropEvent } from "../dragDropUtils";
import agGrid from "ag-grid-community/dist/ag-grid-community";
import { setLocalStorageItemManaged } from "src/storage/localStorageUtils";

class AgGrid extends LitElement {
	static get properties() {
		return {
			gridId: { type: String },
			columnDefs: {
				type: Array,
				hasChanged: AgGrid._columnDefPropertyChanged,
			},
			rowData: { type: Array },
			draggableRows: { type: Boolean },
			rowDropZonesEnabled: { type: Boolean },
			viewConfiguration: { type: Object },
			params: { type: Array },
			selectionMode: { type: String },
			paginationMode: { type: String },
			paginationPageSize: { type: Number },
			selectedRowIds: {
				type: Array,
				hasChanged: (newVal, oldVal) => !equal(newVal, oldVal),
			},
			focusedRowId: { type: String },
			unsavedRowIds: { type: Array },
			showLoadingOverlay: { type: Boolean },
			classRules: { type: Array },
			idColumnName: { type: String },
			// DataVersion must be increment every time row data is changed to trigger grid update.
			dataVersion: { type: Number },
			serverSidePaginationRequestId: { type: String },
			cellValidationErrors: { type: Object },
		};
	}

	static _columnDefPropertyChanged(newVal, oldVal) {
		if (!newVal || !oldVal) return newVal !== oldVal;

		const mapColumnDef = (col) => ({
			identifier: col.identifier,
			header: col.header,
			isHidden: col.isHidden,
		});

		const newValMapped = newVal.map(mapColumnDef);
		const oldValMapped = oldVal.map(mapColumnDef);
		return !equal(newValMapped, oldValMapped);
	}

	constructor() {
		super();
		this.gridId = "";
		this.columnDefs = [];
		this.rowData = [];
		this.draggableRows = false;
		this.rowDropZonesEnabled = false;
		this.viewConfiguration = null;
		this.params = [];
		this.selectionMode = "single";
		this.paginationMode = "disabled";
		this.paginationPageSize = 150;
		this.dataVersion = null;
		this.selectedRowIds = [];
		this.focusedRowId = null;
		this.unsavedRowIds = [];
		this.showLoadingOverlay = false;
		this.idColumnName = null;
		this.columnSizesAdjusted = false;
		this.cellValidationErrors = {};

		this.classRules = [];
		this._rowClassRules = {};
		this._cellClassRules = {};

		// AgGrid infinite scroll mode requires use of 'successCallback' function passed with
		// load params. This does not fit very well with custom elements, because we have to propagate
		// request to containing element.
		// Only way to handle this is to cache request here.
		this._serverSidePaginationRequest = null;
	}

	createRenderRoot() {
		return this;
	}

	render() {
		return html` <div class="ag-grid-wrapper ag-basic ag-theme-alpine" style="height: 100%" /> `;
	}

	firstUpdated(changedProperties) {
		this._recreateAgGrid().catch((error) => {
			console.error(error);
		});
	}

	async _recreateAgGrid() {
		const gridElement = this._getGridDiv();
		gridElement.innerHTML = "";
		const idColumnName = this.idColumnName;
		if (idColumnName === null) {
			throw "Id column name must be provided to ag-grid web component";
		}

		this._resolveClassRules();
		const defaultRowClassRules = {
			"ag-grid__row--unsaved": ({ node }) => this.unsavedRowIds.includes(node.id),
		};

		const gridOptions = {
			...this._getPaginationModeDependentOptions(),
			components: {
				aavoInputFieldCellEditor: AavoInputFieldCellEditor,
				checkboxCellRenderer: CheckboxCellRenderer,
			},
			columnDefs: this._mapColumnDefsToAgGridColumns(this.columnDefs),
			rowSelection: this.selectionMode,
			rowMultiSelectWithClick: isMobileOrTablet() || this.selectionMode === "single",
			// Handle selection with onRowClick callback in single selection mode.
			suppressRowClickSelection: this.selectionMode === "single",
			enableCellTextSelection: true,
			suppressContextMenu: true,
			getRowId: function (params) {
				return params.data[idColumnName].value;
			},
			rowClassRules: { ...defaultRowClassRules, ...this._rowClassRules },
			processRowPostCreate: ({ node, eRow }) => {
				this._setupRowDropZone(node.id, eRow);
			},
			defaultColDef: {
				suppressKeyboardEvent: ({ event }) => {
					// Shift+S is for save and Shift+N for new row.
					return event.shiftKey && ["n", "s"].includes(event.key.toLowerCase());
				},
			},
			onGridReady: AgGrid._onGridReady.bind(this),
		};

		AgGrid._preventGridElementContextMenu(gridElement);

		new agGrid.Grid(gridElement, gridOptions);
		this.api = gridOptions.api;
		this.columnApi = gridOptions.columnApi;

		this.api.addEventListener("selectionChanged", AgGrid._onSelectionChanged.bind(this));
		if (this.selectionMode === "single")
			this.api.addEventListener("rowClicked", AgGrid._onRowClicked.bind(this));
		this.api.addEventListener("rowDoubleClicked", AgGrid._onRowDoubleClicked.bind(this));
		this.api.addEventListener("cellContextMenu", AgGrid._onCellContextMenu.bind(this));
		this.api.addEventListener("cellValueChanged", AgGrid._onCellValueChanged.bind(this));
		this.api.addEventListener("cellKeyPress", AgGrid._onCellKeyPress.bind(this));
		this.api.addEventListener("dragStopped", AgGrid._onDragStopped.bind(this));

		this._setSelectedRows();
		this._updateShowOverlay();

		onAppContentAreaChanged(() => {
			this._autoSizeAllColumns();
		});
	}

	updated(changedProperties) {
		if (!this.api) return;

		if (changedProperties.has("idColumnName")) {
			this._recreateAgGrid().catch(() => {
				console.error(error);
			});
		}
		if (changedProperties.has("dataVersion")) {
			if (this.paginationMode !== "serverSide") this.api.setRowData(this.rowData);
			else this._updateDataOnServerSidePaginationMode();
			this.columnSizesAdjusted = false;
			this._autoSizeAllColumns();
		}
		if (changedProperties.has("columnDefs")) {
			this.api.setColumnDefs(this._mapColumnDefsToAgGridColumns(this.columnDefs));
			this.columnSizesAdjusted = false;
			this._autoSizeAllColumns();
			this.columnDefs.forEach((col) => {
				this.columnApi.setColumnVisible(col.identifier, !col.hidden);
			});
		}
		if (changedProperties.has("selectedRowIds")) {
			this._setSelectedRows();
		}
		if (changedProperties.has("focusedRowId")) {
			this._setFocusedRow();
		}
		if (changedProperties.has("showLoadingOverlay")) {
			this._updateShowOverlay();
		}
	}

	disconnectedCallback() {
		if (this.api) this.api.destroy();
		this.api = undefined;
	}

	_resolveClassRules() {
		const modifiedClassRules = this.classRules.map((classRule) => ({
			...classRule,
			// AgGrid may run rules for empty rows on infinite row model.
			rule: `data != null && (${classRule.rule})`,
		}));
		this._rowClassRules = modifiedClassRules.filter((rule) => isNullOrWhitespace(rule.columnName));
		const rowClassRules = {};
		const cellClassRules = {};
		modifiedClassRules.forEach(({ columnName, className, rule }) => {
			if (isNullOrWhitespace(columnName)) {
				rowClassRules[className] = rule;
			} else {
				const columnNameTrimmed = columnName.trim();
				const columnClassRules = cellClassRules[columnNameTrimmed] || {};
				columnClassRules[className] = rule;
				cellClassRules[columnNameTrimmed] = columnClassRules;
			}
		});

		this._rowClassRules = rowClassRules;
		this._cellClassRules = cellClassRules;
	}

	_getPaginationModeDependentOptions() {
		if (this.paginationMode === "clientSide")
			return {
				pagination: true,
				paginationPageSize: this.paginationPageSize,
				rowData: this.rowData,
			};
		else if (this.paginationMode === "serverSide")
			return {
				pagination: true,
				paginationPageSize: this.paginationPageSize,
				cacheBlockSize: this.paginationPageSize,
				rowModelType: "infinite",
				maxConcurrentDatasourceRequests: 1,
				infiniteInitialRowCount: 0,
				maxBlocksInCache: 1,
				datasource: this._getInfiniteRowModelDatasource(),
			};
		else
			return {
				pagination: false,
				rowData: this.rowData,
			};
	}

	_getInfiniteRowModelDatasource() {
		const that = this;
		return {
			rowCount: null,
			getRows: (params) => {
				that._serverSidePaginationRequest = params;
				const event = new CustomEvent("serverSidePaginationRequest", {
					detail: {
						startRow: params.startRow,
						endRow: params.endRow,
					},
				});
				that.dispatchEvent(event);
			},
		};
	}

	_updateDataOnServerSidePaginationMode() {
		// Set maxRowFound-property to false on every time, because external filters
		// may been changed so that total row count is now different.
		this.api.setRowCount(this.api.getInfiniteRowCount(), false);
		const paginationRequest = this._serverSidePaginationRequest;
		if (!paginationRequest) return;

		const requestedCount = paginationRequest.endRow - paginationRequest.startRow;
		// Set lastRow if we did not get requested count of rows.
		let lastRow = null;
		if (this.rowData.length === 0) lastRow = paginationRequest.startRow + 1;
		// Will show one empty row on empty page.
		else if (this.rowData.length < requestedCount)
			lastRow = paginationRequest.startRow + this.rowData.length;
		paginationRequest.successCallback(this.rowData, lastRow);
	}

	_setupRowDropZone(rowId, eRow) {
		if (!this.rowDropZonesEnabled) return;

		const setDragOverClass = (isDragOver) => eRow.classList.toggle("ag-row--drag-over", isDragOver);

		eRow.ondragenter = (event) => {
			setDragOverClass(true);
			event.preventDefault();
		};
		eRow.ondragover = (event) => {
			setDragOverClass(true);
			event.preventDefault();
		};
		eRow.ondragleave = (event) => {
			setDragOverClass(false);
			event.preventDefault();
		};
		eRow.ondrop = (event) => {
			event.preventDefault();
			setDragOverClass(false);
			this.dispatchEvent(
				new CustomEvent("dropToRowDropZone", {
					detail: {
						rowId,
						files: getFilesFromDropEvent(event),
					},
				}),
			);
		};
	}

	_setSelectedRows() {
		if (this.selectedRowIds.length === 0) this.api.deselectAll();
		else
			this.api.forEachNode((rowNode) => {
				const isSelected = this.selectedRowIds.includes(rowNode.id.toString()) || false;
				rowNode.setSelected(isSelected, false, "api");
			});
	}

	_setFocusedRow() {
		if (!this.focusedRowId || !this.api) return;
		const rowNode = this.api.getRowNode(this.focusedRowId);
		if (!rowNode) return;
		this.api.ensureIndexVisible(rowNode.rowIndex);

		const firstCol = this.columnApi.getAllDisplayedColumns()[0];
		if (!firstCol) return;
		this.api.ensureColumnVisible(firstCol);
		this.api.setFocusedCell(rowNode.rowIndex, firstCol);
	}

	_autoSizeAllColumns() {
		// It is not possible to adjust columns of invisible grid.
		if (elementIsVisible(this._getGridDiv())) {
			this.columnApi.autoSizeAllColumns();
			this.columnSizesAdjusted = true;
		}
	}

	_updateShowOverlay() {
		if (this.showLoadingOverlay) this.api.showLoadingOverlay();
		else if (this.rowData.length === 0) this.api.showNoRowsOverlay();
		else this.api.hideOverlay();
	}

	_getGridDiv() {
		return this.querySelector(".ag-grid-wrapper");
	}

	static _preventGridElementContextMenu(gridElement) {
		gridElement.oncontextmenu = (event) => {
			event.preventDefault();
			return false;
		};
	}

	static _onSelectionChanged() {
		if (!this.api) return;
		const selectedRowIds = this.api.getSelectedNodes().map((rowNode) => rowNode.id);
		this.dispatchEvent(
			new CustomEvent("gridSelectionChanged", {
				detail: {
					selectedRowIds: selectedRowIds,
				},
			}),
		);
	}

	static _onRowClicked({ node, event }) {
		if (event.detail > 1) {
			event.stopPropagation();
			event.preventDefault();
			return false;
		}
		if (!node.isSelected()) node.setSelected(true);
	}

	static _onRowDoubleClicked(event) {
		const newEvent = new CustomEvent("gridRowDoubleClicked", {
			detail: {
				rowId: event.node.id,
			},
		});
		this.dispatchEvent(newEvent);
	}

	static _onCellContextMenu(event) {
		const newEvent = new CustomEvent("gridCellContextMenu", {
			detail: {
				rowId: event.node.id,
				event: event.event,
				documentRect: document.documentElement.getBoundingClientRect(),
			},
		});
		this.dispatchEvent(newEvent);
		return false;
	}

	static _onCellValueChanged(event) {
		this.dispatchEvent(
			new CustomEvent("cellValueChanged", {
				detail: {
					rowId: event.node.id,
					columnName: event.colDef.field,
					newValue: event.newValue,
				},
			}),
		);
	}

	static _onCellKeyPress(event) {
		this.dispatchEvent(
			new CustomEvent("cellKeyPress", {
				detail: event,
			}),
		);
	}

	static _onDragStopped(event) {
		const columnState = JSON.stringify(event.columnApi.getColumnState());
		setLocalStorageItemManaged(getColumnStateStorageKey(this.gridId), columnState);
	}

	static _onGridReady(event) {
		const columnState = JSON.parse(localStorage.getItem(getColumnStateStorageKey(this.gridId)));
		if (columnState) {
			event.columnApi.applyColumnState({
				state: columnState,
				applyOrder: true,
			});
		}
	}

	_mapColumnDefsToAgGridColumns(columnDefs) {
		const firstVisibleCol = columnDefs.find((col) => !col.hidden);
		return columnDefs.map((col) => {
			const isFirstColumn = col.identifier === firstVisibleCol.identifier;
			let formatter;
			let cellRenderer;
			let isEditable = col.editable;
			let cellRendererParams;
			let cellEditor = "aavoInputFieldCellEditor";
			let valueGetter;
			let cellEditorParams = {
				inputField: col.inputField,
				viewConfiguration: this.viewConfiguration,
				getAavoParams: () => this.params,
				markRowToUnsaved: (nodeId) => {
					this.unsavedRowIds.push(nodeId);
				},
				setValidationError: (nodeId, colId, validationError) => {
					this._setCellValidationError(nodeId, colId, validationError);
				},
				removeValidationError: (nodeId, colId) => {
					this._setCellValidationError(nodeId, colId, null);
				},
			};
			let comparator = (valueA, valueB) => {
				return defaultComparator(valueA.value, valueB.value, true);
			};

			switch (col.inputField.type.type) {
				case "selection":
					const optionsList = col.inputField.type.options;
					const optionsMap = new Map(optionsList.map((obj) => [obj.key, obj.label]));
					formatter = (params) => selectionColumnFormatter(optionsMap, params);
					break;
				default:
					switch (col.inputField.type.simpleFieldType) {
						case "Float":
							formatter = floatFormatter;
							break;
						case "DateTime":
							formatter = dateTimeFormatter;
							break;
						case "Date":
							formatter = dateFormatter;
							break;
						case "Time":
							formatter = timeFormatter;
							break;
						case "Checkbox":
							// With checkboxCellRenderer, column should always be non-editable
							// for AgGrid, so that double click will not open the default editor.
							// cellRendererParams tells to renderer if it is actually editable.
							isEditable = false;
							cellRenderer = "checkboxCellRenderer";
							cellRendererParams = {
								isEditableCheckBox: col.editable,
								markRowToUnsaved: (nodeId) => {
									this.unsavedRowIds.push(nodeId);
								},
							};
							cellEditorParams = null;
							cellEditor = null;
							break;
						case "TextArea":
							cellEditorParams.showAsPopup = true;
							formatter = firstLineFormatter;
							break;
						default:
							formatter = textFormatter;
							cellRenderer = this._parseCustomCellRenderer(col);
					}
			}
			return {
				colId: col.identifier,
				field: col.identifier,
				valueGetter,
				comparator,
				headerName: col.header,
				hide: col.hidden,
				resizable: col.resizable,
				sortable: col.sortable && this.paginationMode !== "serverSide",
				editable: isEditable,
				valueFormatter: formatter,
				cellClassRules: this._getCellClassRules(col.identifier),
				cellRenderer,
				cellRendererParams,
				cellEditor,
				cellEditorParams,
				cellStyle: { "font-size": "0.9rem" },
				dndSource: this.draggableRows && isFirstColumn,
				dndSourceOnRowDrag: (params) => this._onRowDrag(params),
				lockVisible: true,
			};
		});
	}

	_parseCustomCellRenderer(col) {
		switch (col.cellRenderer) {
			case "Html":
				return htmlCellRenderer;
			case "Image":
				return imageCellRenderer;
			case "Link":
				return linkCellRenderer;
			case "Icon":
				return iconCellRenderer;
			default:
				return undefined;
		}
	}

	_getCellClassRules(columnId) {
		const defaultClassRules = {
			"ag-grid__cell--invalid-input": ({ node }) => {
				const rowValidationErrors = this.cellValidationErrors[node.id];
				if (!rowValidationErrors) return false;
				return rowValidationErrors[columnId] != null;
			},
		};
		const customClassRules = this._cellClassRules[columnId] || {};
		return { ...defaultClassRules, ...customClassRules };
	}

	_setCellValidationError(nodeId, colId, validationError) {
		let rowValidationErrors = this.cellValidationErrors[nodeId];
		if (!rowValidationErrors) rowValidationErrors = {};
		if (validationError == null) delete rowValidationErrors[colId];
		else rowValidationErrors[colId] = validationError;
		this.cellValidationErrors[nodeId] = rowValidationErrors;

		this.dispatchEvent(
			new CustomEvent("cellValidationErrorsChanged", {
				detail: mapObjectKeys(this.cellValidationErrors, (key) => key.toString()),
			}),
		);
	}

	_onRowDrag({ rowNode }) {
		this.dispatchEvent(
			new CustomEvent("rowDragStarted", {
				detail: rowNode.id,
			}),
		);
	}
}

customElements.define("ag-grid", AgGrid);

// Copied from AgGrid source
function defaultComparator(valueA, valueB, accentedCompare) {
	if (accentedCompare === void 0) {
		accentedCompare = false;
	}
	const valueAMissing = valueA == null;
	const valueBMissing = valueB == null;
	// this is for aggregations sum and avg, where the result can be a number that is wrapped.
	// if we didn't do this, then the toString() value would be used, which would result in
	// the strings getting used instead of the numbers.
	if (valueA && valueA.toNumber) {
		valueA = valueA.toNumber();
	}
	if (valueB && valueB.toNumber) {
		valueB = valueB.toNumber();
	}
	if (valueAMissing && valueBMissing) {
		return 0;
	}
	if (valueAMissing) {
		return -1;
	}
	if (valueBMissing) {
		return 1;
	}

	function doQuickCompare(a, b) {
		return (
			a > b ? 1
			: a < b ? -1
			: 0
		);
	}

	if (typeof valueA === "string") {
		if (!accentedCompare) {
			return doQuickCompare(valueA, valueB);
		}
		try {
			// using local compare also allows chinese comparisons
			return valueA.localeCompare(valueB);
		} catch (e) {
			// if something wrong with localeCompare, eg not supported
			// by browser, then just continue with the quick one
			return doQuickCompare(valueA, valueB);
		}
	}
	return doQuickCompare(valueA, valueB);
}

function getColumnStateStorageKey(gridId) {
	return "ag-grid-column-state-2-" + gridId;
}
