import { AavoFormLayout, AavoFormLayoutProps } from "src/components/common/forms/AavoFormLayout.tsx";
import { FormResult, FormSubmitResult } from "src/components/common/forms/types.ts";
import { useErrorDialog } from "src/components/common/dialogs/errorDialog/userErrorDialog.ts";
import React from "react";
import {
	DefaultValues,
	FieldPath,
	FieldPathValue,
	FieldValues,
	SetValueConfig,
	useForm,
	UseFormProps,
	UseFormReturn,
} from "react-hook-form";
import { VerticalBox } from "src/components/common/box/VerticalBox.tsx";
import { AavoFormFooter } from "src/components/common/forms/AavoFormFooter.tsx";
import { AsyncButton } from "src/components/common/buttons/AsyncButton.tsx";
import i18n from "i18next";

export interface AavoFormProps<TFieldValues extends object, TResult> extends Omit<AavoFormLayoutProps, "children"> {
	onCompleted: (result: FormResult<TResult>, props: AavoFormContentParams<TFieldValues>) => void | Promise<unknown>;
	onFormEdited?: () => void;
	render: (props: AavoFormContentParams<TFieldValues>) => React.ReactNode;
	defaultValues?: DefaultValues<TFieldValues>;
	useFormProps?: Omit<UseFormProps<TFieldValues>, "defaultValues">;
	submit: AavoFormSubmitFunc<TFieldValues, TResult>;
	submitLabel?: string;
	disableSubmit?: boolean;
	hideCancel?: boolean;
	hideActions?: boolean;
	footerExtraComponents?: (props: AavoFormFooterExtraComponentParams<TFieldValues, TResult>) => React.ReactNode;
}

export interface AavoFormContentParams<TFieldValues extends FieldValues>
	extends Omit<UseFormReturn<TFieldValues>, "setValue"> {
	setValue: UseFormSetValueCustom<TFieldValues>;
	submit: () => Promise<void>;
}

export type AavoFormSubmitFunc<TFieldValues extends object, TResult> = (
	values: TFieldValues,
	props: AavoFormContentParams<TFieldValues>,
) => FormSubmitResult<TResult> | Promise<FormSubmitResult<TResult>>;

export interface AavoFormFooterExtraComponentParams<TFieldValues extends object, TResult> {
	contentProps: AavoFormContentParams<TFieldValues>;
	handleSubmit: (submitFunc: AavoFormSubmitFunc<TFieldValues, TResult>) => void;
}

// Allows to reset value to undefined unlike original method.
export type UseFormSetValueCustom<TFieldValues extends FieldValues> = <TFieldName extends FieldPath<TFieldValues>>(
	name: TFieldName,
	value: FieldPathValue<TFieldValues, TFieldName> | undefined | null,
	options?: SetValueConfig,
) => void;

export const AavoForm = <TFieldValues extends FieldValues, TResult = void>({
	render,
	defaultValues,
	useFormProps,
	submit,
	disableSubmit,
	onCompleted,
	onFormEdited,
	columnMinWidth,
	columns = 1,
	gap = 2,
	fitContent,
	layoutSx,
	submitLabel,
	hideCancel = false,
	hideActions = false,
	footerExtraComponents,
}: AavoFormProps<TFieldValues, TResult>) => {
	const { logErrorAndShowOnDialog } = useErrorDialog();

	const useFormReturn = useForm<TFieldValues>({
		mode: "all",
		defaultValues: defaultValues,
		...useFormProps,
	});
	const {
		handleSubmit,
		watch,
		formState: { isDirty, disabled },
	} = useFormReturn;

	if (onFormEdited)
		watch((_, { type }) => {
			if (type === "change") {
				onFormEdited();
			}
		});

	const contentProps = {
		...useFormReturn,
		setValue: setValueCustom,
		submit: handleSubmitWrapped,
	};

	return (
		<VerticalBox
			sx={{
				flex: 1,
			}}
			onKeyDown={onFormKeyDown}
		>
			<AavoFormLayout
				columns={columns}
				columnMinWidth={columnMinWidth}
				gap={gap}
				fitContent={fitContent}
				layoutSx={layoutSx}
			>
				{render(contentProps)}
			</AavoFormLayout>
			{!hideActions && (
				<AavoFormFooter>
					{!hideCancel && (
						<AsyncButton label={i18n.t("cancel")} color={"secondary"} onClick={() => handleCancel()} />
					)}
					<AsyncButton
						label={submitLabel ?? i18n.t("save")}
						disabled={disableSubmit || disabled}
						onClick={() => handleSubmitWrapped()}
					/>
					{footerExtraComponents?.({
						contentProps: contentProps,
						handleSubmit: handleSubmitWrapped,
					})}
				</AavoFormFooter>
			)}
		</VerticalBox>
	);

	function setValueCustom<TFieldName extends FieldPath<TFieldValues>>(
		name: FieldPath<TFieldValues>,
		value: FieldPathValue<TFieldValues, TFieldName> | undefined | null,
		options?: SetValueConfig,
	) {
		if (value === undefined) useFormReturn.resetField(name);
		else
			useFormReturn.setValue(
				name,
				// TypeScripts doesn't allow null here, but it's sometimes required.
				// e.g. FormLazySelectField behaves differently with resetField and setValue(null).
				value as any,
				options,
			);
	}

	async function handleSubmitWrapped(submitFunc: typeof submit = submit) {
		await handleSubmit(
			async (formData: TFieldValues) => {
				try {
					const submitResult = await submitFunc(formData, contentProps);
					if (submitResult === "interrupted") {
						return;
					}
					await onCompleted?.({ type: "success", value: submitResult }, contentProps);
				} catch (e) {
					logErrorAndShowOnDialog(e);
				}
			},
			(errors) => {
				console.info("Form has errors", errors);
			},
		)();
	}

	async function handleCancel() {
		onCompleted?.({ type: "cancel", isEdited: isDirty }, contentProps);
	}

	async function onFormKeyDown(e: React.KeyboardEvent<HTMLDivElement>) {
		if (e.key === "Enter" && e.ctrlKey && isDirty) {
			await handleSubmitWrapped();
		}
	}
};
