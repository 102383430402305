import {
	FormAsyncSelectField,
	FormAsyncSelectFieldProps,
} from "src/components/common/forms/fields/FormAsyncSelectField.tsx";
import { AppUser } from "src/api/generated/postgres/db/types/role_management/tables/appUser.ts";
import { UserApi } from "src/api/generated/users/api/userApi.ts";
import { PartialBy } from "src/utils/partialBy.ts";
import { FieldPath } from "react-hook-form";

export interface FormAsyncUserSelectFieldProps<
	TFieldValues extends object,
	TFieldName extends FieldPath<TFieldValues>,
> extends PartialBy<
		FormAsyncSelectFieldProps<TFieldValues, AppUser, number, TFieldName>,
		"fetchOptions" | "getOptionKey" | "getOptionLabel"
	> {}

export const FormAsyncUserSelectField = <
	TFieldValues extends object,
	TFieldName extends FieldPath<TFieldValues>,
>({
	...other
}: FormAsyncUserSelectFieldProps<TFieldValues, TFieldName>) => {
	return (
		<FormAsyncSelectField<TFieldValues, AppUser, number, TFieldName>
			fetchOptions={({ searchQuery, currentSelection }) =>
				UserApi.getUserSelectionOptions({
					searchQuery: searchQuery,
					currentSelectionUserId: currentSelection,
				})
			}
			getOptionKey={(option) => option.id}
			getOptionLabel={(option) => option.name}
			{...other}
		/>
	);
};
