import { Coordinates } from "src/api/generated/common/address/coordinates.ts";

export const buildGoogleMapsRouteUrl = ({
	origin,
	destination,
	waypoints,
}: {
	origin: Coordinates;
	destination: Coordinates;
	waypoints: Coordinates[];
}) => {
	const coordsToStr = (coords: Coordinates) => `${coords.lat},${coords.lng}`;

	const waypointsStr = waypoints.map(coordsToStr).join("|");

	return (
		`https://www.google.com/maps/dir/?api=1` +
		`&origin=${coordsToStr(origin)}` +
		`&destination=${coordsToStr(destination)}` +
		`&waypoints=${waypointsStr}&travelmode=driving`
	);
};
