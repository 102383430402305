// This file is automatically generated. Do not edit manually.

import { FrontendUploadFileHandle } from "src/api/generated/common/frontendUploadFileHandle";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace UnmanagedFilesApi {
	export async function createUnmanagedFile(args: { fileHandle: FrontendUploadFileHandle }): Promise<string> {
		const response = await makeAxiosRequest({
			url: "/api/ext/documents/unmanagedFiles/createUnmanagedFile",
			method: "POST",
			data: {
				fileHandle: args.fileHandle,
			},
		});
		return response as string;
	}

	export async function getUnmanagedFilePresignedDownloadUrl(args: { uuid: string }): Promise<string> {
		const response = await makeAxiosRequest({
			url: "/api/ext/documents/unmanagedFiles/getUnmanagedFilePresignedDownloadUrl",
			method: "POST",
			data: {
				uuid: args.uuid,
			},
		});
		return response as string;
	}
}
