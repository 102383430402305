export const removeKeyFromObject = <T extends object>(obj: T, key: keyof T) => {
	const newObj = { ...obj };
	delete newObj[key];
	return newObj;
};

export const removeKeysFromRecord = <K extends string, T>(obj: Record<K, T>, keys: K[]): Record<K, T> => {
	const newObj = { ...obj };
	keys.forEach((key) => {
		delete newObj[key];
	});
	return newObj;
};

export const isNullOrEmptyObject = (obj: unknown) => {
	if (obj === null || obj === undefined) {
		return true;
	}
	return typeof obj === "object" && Object.keys(obj).length === 0;
};

export const isNotNullOrEmptyObject = (obj: unknown): obj is object => {
	return !isNullOrEmptyObject(obj);
};

export const filterNullValues = <T extends object>(obj: T): Partial<T> => {
	const filteredObj: Partial<T> = {};
	Object.entries(obj).forEach(([key, value]) => {
		if (value !== null) {
			filteredObj[key as keyof T] = value;
		}
	});
	return filteredObj;
};
