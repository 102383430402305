import {
	CChartEventCollectionApi,
	CChartEventCollectionApi_CChartDto,
} from "src/api/generated/spc/cChartEvents/cChartEventCollectionApi.ts";
import { FormTextField } from "src/components/common/forms/fields/FormTextField";
import { uploadFile } from "src/utils/fileUploading";
import { AavoForm } from "src/components/common/forms/AavoForm.tsx";
import { FormCommonProps } from "src/components/common/forms/types.ts";
import { FormSingleFileInputField } from "src/components/common/forms/fields/FormSingleFileInputField.tsx";
import i18n from "i18next";
import { isNullOrBlank } from "src/utils/strings.tsx";

export interface AddCChartEventFormProps extends FormCommonProps<number> {
	controlChart: CChartEventCollectionApi_CChartDto;
}

interface FormValues {
	dimensions: {
		label: string;
		value: string;
	}[];
	file: File;
}

export const AddCChartEventForm = ({ controlChart, onCompleted }: AddCChartEventFormProps) => {
	const { controlChartId, documentsEnabled, dimensions } = controlChart;

	const submitForm = async (values: FormValues) => {
		const fileHandle = values.file ? await uploadFile(values.file) : undefined;
		return await CChartEventCollectionApi.addEvent({
			controlChartId: controlChartId,
			dimensions: values.dimensions.map((dimension) => dimension.value),
			fileHandle: fileHandle,
			fileName: values.file?.name ?? "",
		});
	};

	return (
		<AavoForm
			defaultValues={{
				dimensions: dimensions.map(({ label, previousValue }) => ({
					label: label,
					value: previousValue,
				})),
				file: undefined,
			}}
			submit={submitForm}
			columns={1}
			onCompleted={onCompleted}
			render={({ control }) => (
				<>
					{dimensions.map(({ label }, index) =>
						isNullOrBlank(label) ? null : (
							<FormTextField
								key={label}
								control={control}
								name={`dimensions.${index}.value`}
								label={label}
								autoFocus={index === 0}
							/>
						),
					)}
					{documentsEnabled && (
						<FormSingleFileInputField control={control} name={"file"} label={i18n.t("file")} />
					)}
				</>
			)}
		/>
	);
};
