import { useClientSideDataGridModel } from "src/components/common/dataGrid/gridModel/useClientSideDataGridModel.tsx";
import { CustomerGroupApi } from "src/api/generated/erp/sales/basedata/api/customerGroupApi.ts";
import i18n from "i18next";
import { textColumn } from "src/components/common/dataGrid/columns.tsx";
import { CustomerGroup } from "src/api/generated/erp/db/types/tables/customerGroup.ts";
import { ControlledAsyncCrudDataGrid } from "src/components/common/dataGrid/crud/ControlledAsyncCrudDataGrid.tsx";
import { CustomerGroupEditApi } from "src/api/generated/erp/sales/basedata/api/customerGroupEditApi.ts";
import { CustomerGroupForm } from "src/components/views/erp/sales/basedata/customerGroups/CustomerGroupForm.tsx";

export const CustomerGroupsDataGrid = () => {
	const { dataGridProps } = useClientSideDataGridModel({
		fetchData: () => CustomerGroupApi.getAllCustomerGroups(),
		initialParams: {},
		getRowId: (row) => row.customerGroupId,
		gridId: "10DF11DB11D19766",
	});

	return (
		<ControlledAsyncCrudDataGrid<CustomerGroup>
			columns={[
				textColumn({
					field: "name",
					headerName: i18n.t("name"),
					width: 200,
				}),
				textColumn({
					field: "description",
					headerName: i18n.t("description"),
					width: 300,
				}),
			]}
			form={{
				addRowEnabled: true,
				editEnabled: true,
				dialogSize: "sm",
				dialogTitle: i18n.t("customer_group"),
				component: ({ row, onCompleted, onFormEdited }) => (
					<CustomerGroupForm
						customerGroupId={row?.customerGroupId}
						onCompleted={onCompleted}
						onFormEdited={onFormEdited}
					/>
				),
			}}
			remove={({ items }) =>
				CustomerGroupEditApi.delete_({
					customerGroupIds: items.map((item) => item.customerGroupId),
				})
			}
			{...dataGridProps}
		/>
	);
};
