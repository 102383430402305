import {
	faAngleLeft,
	faAngleRight,
	faAnglesRight,
	faCalculator,
	faCloudDownloadAlt,
	faCogs,
	faDatabase,
	faExternalLink,
	faPlus,
	faRefresh,
	faSquareRootAlt,
} from "@fortawesome/pro-regular-svg-icons";
import {AavoActionBar} from "src/components/common/actionBar/AavoActionBar.tsx";
import {AsyncButton} from "src/components/common/buttons/AsyncButton.tsx";
import type {
	ControlChartGraphViewRefreshParams
} from "src/components/views/spc/controlChart/controlChartGraph/ControlChartGraphView.tsx";
import i18n from "i18next";
import {AavoDateTimePicker} from "src/components/common/inputFields/AavoDateTimePicker.tsx";
import {useState} from "react";
import {Dayjs} from "dayjs";
import {askInputAndOpenControlChartCapabilityView} from "src/components/views/spc/controlChart/utils.tsx";
import {useInputDialog} from "src/components/common/dialogs/input/useInputDialog.tsx";
import {useOpenLegacyView} from "src/components/views/legacy/useOpenLegacyView.ts";
import {AavoMenuButton} from "src/components/common/menu/AavoMenuButton.tsx";
import {actionBarSxContentOnly} from "src/components/common/actionBar/actionBarStyles.ts";
import {HorizontalBox} from "src/components/common/box/HorizontalBox.tsx";
import {
	ControlChartInspectingView
} from "src/components/views/spc/controlChart/controlChartInspectingView/ControlChartInspectingView.tsx";
import {useGenericDialog} from "src/components/common/dialogs/useGenericDialog.ts";
import {mergeSx} from "src/utils/styles.ts";
import {openFormOnDialog} from "src/components/common/dialogs/formDialog/openFormOnDialog.ts";
import {ControlChartDataInputForm} from "src/components/views/spc/controlChart/dataInput/ControlChartDataInputForm.tsx";
import {ControlChartGraphDto} from "src/api/generated/spc/graphs/controlChartGraphDto.ts";
import {Tooltip, Typography} from "@mui/material";
import {AavoTextField} from "src/components/common/inputFields/AavoTextField";
import {ControlChartRecordsDataGrid} from "src/components/views/spc/controlChart/ControlChartRecordsDataGrid.tsx";
import {
	ControlChartsDataExportForm
} from "src/components/views/spc/basedata/controlCharts/ControlChartsDataExportForm.tsx";
import {AsyncMenuButton} from "src/components/common/contextMenu/AsyncMenuButton.tsx";
import {getOnlyMember} from "src/utils/arrayUtils.ts";
import {openLegacyControlChartLimitsDataView} from "src/components/views/legacy/legacyViewAdapters.ts";
import {
	ControlChartSpecialCauseTestsForm
} from "src/components/views/spc/basedata/controlCharts/ControlChartSpecialCauseTestsForm.tsx";

export interface ControlChartGraphViewActionBarProps {
	controlCharts: ControlChartGraphDto[];
	refresh: (params?: ControlChartGraphViewRefreshParams) => Promise<unknown>;
	canNavigateBackwards: boolean;
	canNavigateForwards: boolean;
	showOpenInspectingButton?: boolean;
	recordCount: number | null;
}

export const ControlChartGraphViewActionBar = ({
	controlCharts,
	refresh,
	canNavigateBackwards,
	canNavigateForwards,
	showOpenInspectingButton = false,
	recordCount,
}: ControlChartGraphViewActionBarProps) => {
	const showInputDialog = useInputDialog();
	const openLegacyView = useOpenLegacyView();
	const { openDialog } = useGenericDialog();

	const [recordsBeforeLimit, setRecordsBeforeLimit] = useState<Dayjs | null>(null);

	const onlyControlChart = getOnlyMember(controlCharts);

	return (
		<HorizontalBox alignSelf={"stretch"} gap={1}>
			<AavoActionBar>
				<AsyncButton icon={faRefresh} onClick={() => refresh()} />
				{onlyControlChart && (
					<AsyncButton
						icon={faAngleLeft}
						tooltip={i18n.t("previous_page")}
						disabled={canNavigateBackwards}
						onClick={async () => {
							setRecordsBeforeLimit(null);
							await refresh({ paginationDirection: "BACKWARD" });
						}}
					/>
				)}
				{onlyControlChart && (
					<AsyncButton
						icon={faAngleRight}
						tooltip={i18n.t("next_page")}
						disabled={canNavigateForwards}
						onClick={async () => {
							setRecordsBeforeLimit(null);
							await refresh({ paginationDirection: "FORWARD" });
						}}
					/>
				)}
				{onlyControlChart && (
					<AsyncButton
						icon={faAnglesRight}
						tooltip={i18n.t("latest_data")}
						onClick={async () => {
							setRecordsBeforeLimit(null);
							await refresh({ paginationDirection: "LAST_PAGE" });
						}}
					/>
				)}
				<AavoDateTimePicker
					label={i18n.t("time_limit")}
					value={recordsBeforeLimit}
					onAccept={async (value: Dayjs | null) => {
						setRecordsBeforeLimit(value);
						await refresh({ recordsBeforeLimit: value, paginationDirection: "CURRENT_PAGE" });
					}}
				/>
				{onlyControlChart && showOpenInspectingButton && (
					<AsyncButton
						label={i18n.t("open")}
						icon={faExternalLink}
						variant={"outlined"}
						onClick={() => {
							openDialog({
								title: onlyControlChart.name,
								content: <ControlChartInspectingView controlChartId={onlyControlChart.id} />,
							});
						}}
					/>
				)}
				{onlyControlChart != null && !onlyControlChart.timeBasedMeasurements && (
					<AsyncButton
						label={i18n.t("input_data")}
						icon={faPlus}
						variant={"outlined"}
						onClick={() => {
							openFormOnDialog({
								title: onlyControlChart.name,
								size: "sm",
								component: ControlChartDataInputForm,
								props: {
									controlChartId: onlyControlChart.id,
								},
								onSubmit: refresh,
								openDialog: openDialog,
								confirmCloseIfEdited: false,
								keepOpenAfterSubmit: true,
							});
						}}
					/>
				)}
				{onlyControlChart && <TitleText controlChart={onlyControlChart} />}
			</AavoActionBar>
			<HorizontalBox
				sx={mergeSx(actionBarSxContentOnly, {
					paddingY: 1,
					minWidth: "unset",
					ml: "auto",
					justifyContent: "flex-end",
				})}
			>
				<AavoMenuButton
					menuContent={(closeMenu) => [
						onlyControlChart && (
							<AsyncButton
								key={"capability"}
								label={i18n.t("capability")}
								icon={faSquareRootAlt}
								variant={"menu"}
								onClick={async () => {
									await askInputAndOpenControlChartCapabilityView({
										showInputDialog,
										openLegacyView,
										controlChartId: onlyControlChart.id,
										chartName: onlyControlChart.name,
									});
									closeMenu();
								}}
							/>
						),
						onlyControlChart && (
							<AsyncButton
								key={"data"}
								label={i18n.t("data.spc")}
								icon={faDatabase}
								variant={"menu"}
								onClick={() => {
									openDialog({
										title: onlyControlChart.name,
										content: <ControlChartRecordsDataGrid controlChartId={onlyControlChart.id} />,
									});
									closeMenu();
								}}
							/>
						),
						<AsyncMenuButton
							key={"exportDataButton"}
							icon={faCloudDownloadAlt}
							label={i18n.t("export_data")}
							onClick={() => {
								openFormOnDialog({
									openDialog,
									title: i18n.t("export_data"),
									size: "sm",
									component: ControlChartsDataExportForm,
									props: {
										controlChartIds: controlCharts.map((c) => c.id),
									},
									confirmCloseIfEdited: false,
								});
							}}
						/>,
						onlyControlChart && (
							<AsyncMenuButton
								key={"controlLimitsButton"}
								icon={faCalculator}
								label={i18n.t("control_limits")}
								onClick={() => {
									openLegacyControlChartLimitsDataView({
										openLegacyView,
										controlChartId: onlyControlChart.id,
									});
								}}
							/>
						),
						onlyControlChart && (
							<AsyncMenuButton
								key={"selectSpecialCauseTestButton"}
								icon={faCogs}
								label={i18n.t("select_special_cause_tests")}
								onClick={async () => {
									openDialog(({ closeDialog }) => ({
										size: "md",
										title: i18n.t("select_special_cause_tests"),
										content: (
											<ControlChartSpecialCauseTestsForm
												controlChartId={onlyControlChart.id}
												onCompleted={async () => {
													await closeDialog();
												}}
											/>
										),
									}));
								}}
							/>
						),
						<AavoTextField
							key={"dataPointCount"}
							label={i18n.t("data_point_count")}
							type={"number"}
							defaultValue={recordCount}
							onSubmit={async (value) => {
								const asNum = Number(value);
								const valueToUse =
									isNaN(asNum) ? 10
									: asNum > 300 ? 300
									: asNum < 0 ? 0
									: asNum;
								await refresh({ recordCount: valueToUse });
							}}
							sx={{
								marginX: 1,
								marginTop: 1,
							}}
						/>,
					]}
				/>
			</HorizontalBox>
		</HorizontalBox>
	);
};

const TitleText = ({ controlChart }: { controlChart: ControlChartGraphDto }) => {
	const { currentLimits, lsl, usl, target } = controlChart;

	const parts = [
		{
			label: "X̄",
			value: currentLimits?.xAvg,
		},
		{
			label: i18n.t("lcl"),
			value: currentLimits?.xLcl,
		},
		{
			label: i18n.t("ucl"),
			value: currentLimits?.xUcl,
		},
		{
			label: i18n.t("target.spc"),
			value: target,
		},
		{
			label: i18n.t("lsl"),
			value: lsl,
		},
		{
			label: i18n.t("usl"),
			value: usl,
		},
	];

	return (
		<HorizontalBox alignItems={"center"} ml={1}>
			<Tooltip title={controlChart.description}>
				<Typography variant="h6">
					{controlChart.name} (
					{parts
						.filter((p) => p.value != null)
						.map((p) => `${p.label}: ${p.value}`)
						.join(", ")}
					)
				</Typography>
			</Tooltip>
		</HorizontalBox>
	);
};
