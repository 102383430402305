// This file is automatically generated. Do not edit manually.

import { t } from "i18next";

export const WopiAppValues = ["WORD", "EXCEL", "POWERPOINT", "ONENOTE", "VISIO", "WOPITEST"] as const;

export type WopiApp = (typeof WopiAppValues)[number];

export const getWopiAppLabels = () => ({
	WORD: t("word"),
	EXCEL: t("excel"),
	POWERPOINT: t("powerpoint"),
	ONENOTE: t("onenote"),
	VISIO: t("visio"),
	WOPITEST: t("wopitest"),
});

export const getWopiAppLabel = (value: WopiApp): string => {
	return getWopiAppLabels()[value];
};
