// This file is automatically generated. Do not edit manually.

import { CatalogPartRevisionAttributeConfigurationRule } from "src/api/generated/erp/db/types/tables/catalogPartRevisionAttributeConfigurationRule";
import { IsoDateTimeString } from "src/types/dateTime";
import { ObjectAttributeField } from "src/api/generated/erp/common/objectAttributes/objectAttributeField";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace CatalogPartRevisionAttributeRulesApi {
	export async function getCatalogPartRevisionAttributeRules(args: {
		catalogPartRevisionId: number;
	}): Promise<Array<CatalogPartRevisionAttributeRulesApi_AttributeRuleDto>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/configurator/managing/catalogPartRevision/attributeRules/getCatalogPartRevisionAttributeRules",
			method: "POST",
			data: {
				catalogPartRevisionId: args.catalogPartRevisionId,
			},
		});
		return response as Array<CatalogPartRevisionAttributeRulesApi_AttributeRuleDto>;
	}

	export async function getFormInitData(args: {
		catalogPartRevisionId: number;
		ruleId: number | null | undefined;
	}): Promise<CatalogPartRevisionAttributeRulesApi_FormInitData> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/configurator/managing/catalogPartRevision/attributeRules/getFormInitData",
			method: "POST",
			data: {
				catalogPartRevisionId: args.catalogPartRevisionId,
				ruleId: args.ruleId,
			},
		});
		return response as CatalogPartRevisionAttributeRulesApi_FormInitData;
	}

	export async function insert(args: { rule: CatalogPartRevisionAttributeConfigurationRule }): Promise<number> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/configurator/managing/catalogPartRevision/attributeRules/insert",
			method: "POST",
			data: {
				rule: args.rule,
			},
		});
		return response as number;
	}

	export async function update(args: { rule: CatalogPartRevisionAttributeConfigurationRule }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/configurator/managing/catalogPartRevision/attributeRules/update",
			method: "POST",
			data: {
				rule: args.rule,
			},
		});
		return response as void;
	}

	export async function delete_(args: { ruleId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/configurator/managing/catalogPartRevision/attributeRules/delete",
			method: "POST",
			data: {
				ruleId: args.ruleId,
			},
		});
		return response as void;
	}
}

export interface CatalogPartRevisionAttributeRulesApi_AttributeRuleDto {
	attributeFieldLabel: string;
	attributeFieldId: number;
	catalogPartRevisionAttributeConfigurationRuleId: number;
	catalogPartRevisionId: number;
	lastUpdated: IsoDateTimeString;
	ruleScript: string;
}

export interface CatalogPartRevisionAttributeRulesApi_FormInitData {
	attributeFieldOptions: Array<ObjectAttributeField>;
	rule: CatalogPartRevisionAttributeConfigurationRule | null | undefined;
}
