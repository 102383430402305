// This file is automatically generated. Do not edit manually.

import { IsoDateTimeString } from "src/types/dateTime";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace ControlChartDataCollectionApi {
	export async function createValueBasedRecord(args: {
		controlChartId: number;
		values: Array<number>;
		observationTime: IsoDateTimeString;
		info1: string;
		info2: string;
	}): Promise<number> {
		const response = await makeAxiosRequest({
			url: "/api/ext/spc/dataCollection/createValueBasedRecord",
			method: "POST",
			data: {
				controlChartId: args.controlChartId,
				values: args.values,
				observationTime: args.observationTime,
				info1: args.info1,
				info2: args.info2,
			},
		});
		return response as number;
	}
}
