import { ConfiguratorSectionComponents } from "src/components/views/erp/configurator/configuratorForm/components/types/ConfiguratorSectionComponents.ts";
import { NonTabTransformedConfigurationComponent } from "src/components/views/erp/configurator/configuratorForm/components/types/NonTabTransformedConfigurationComponent.tsx";

export function groupConfiguratorComponentsToSections(
	components: NonTabTransformedConfigurationComponent[],
): ConfiguratorSectionComponents[] {
	const initial: ConfiguratorSectionComponents = {
		sectionBreakComponent: undefined,
		childComponents: [],
	};
	return components.reduce(
		(acc, component) => {
			if (component.type === "sectionBreak") {
				return [
					...acc,
					{
						sectionBreakComponent: component,
						childComponents: [],
					},
				];
			} else {
				const currentSection = acc[acc.length - 1]!;
				currentSection.childComponents.push(component);
				return acc;
			}
		},
		[initial],
	);
}
