import { Toolbar, AppBar, Box, Typography } from "@mui/material";
import { AavoIconButton } from "../../common/buttons/AavoIconButton";
import { faBars, faComment, faRefresh } from "@fortawesome/pro-regular-svg-icons";
import { ActiveViewNameText } from "./ActiveViewNameText";
import { useGenericDialog } from "src/components/common/dialogs/useGenericDialog.ts";
import i18n from "i18next";
import { InternalMessagingForm } from "src/components/views/appBar/InternalMessagingForm.tsx";
import { toolbarSx } from "src/components/views/appBar/toolbarStyles.ts";
import { SentryFeedbackButton } from "src/sentry/SentryFeedbackButton";
import { mergeSx } from "src/utils/styles.ts";
import { AppBarDropdownMenuButton } from "src/components/views/appBar/AppBarDropdownMenuButton.tsx";
import { useGlobalInitData } from "src/contexts/useGlobalInitData.ts";
import { getAppEnvironment } from "src/config/appEnvironment.ts";

export interface AavoAppBarProps {
	openMainDrawer?: () => void;
	showOpenDrawerButton: boolean;
}

export const AavoAppBar = ({ openMainDrawer, showOpenDrawerButton }: AavoAppBarProps) => {
	const { openDialog } = useGenericDialog();
	const { environmentType } = useGlobalInitData();
	const isNotProductionEnvironment =
		environmentType !== "PROD" &&
		// Don't show the warnings in localhost
		getAppEnvironment() !== "dev";

	return (
		<AppBar
			position={"static"}
			sx={mergeSx(
				isNotProductionEnvironment && {
					border: "3px solid",
					borderColor: "error.main",
					boxSizing: "border-box",
				},
			)}
		>
			<Toolbar
				variant={"dense"}
				disableGutters
				sx={mergeSx(toolbarSx, {
					paddingLeft: 1,
					paddingRight: 1,
					"& > *": {
						flexShrink: 0,
					},
				})}
			>
				{showOpenDrawerButton && (
					<AavoIconButton
						icon={faBars}
						color={"primary"}
						variant={"contained"}
						onClick={openMainDrawer}
					/>
				)}
				<ActiveViewNameText />
				{isNotProductionEnvironment ?
					<NotProductionEnvironmentWarningText />
				:	<Box sx={{ flex: 1 }} />}
				<AavoIconButton
					icon={faRefresh}
					color={"primary"}
					variant={"contained"}
					tooltip={i18n.t("refresh_all")}
					onClick={() => {
						window.location.reload();
					}}
				/>
				<AavoIconButton
					icon={faComment}
					color={"primary"}
					variant={"contained"}
					tooltip={i18n.t("send_message")}
					onClick={() => {
						openDialog(({ closeDialog }) => ({
							title: i18n.t("send_message"),
							size: "md",
							content: <InternalMessagingForm closeDialog={closeDialog} />,
						}));
					}}
				/>
				<SentryFeedbackButton />
				<AppBarDropdownMenuButton />
			</Toolbar>
		</AppBar>
	);
};

const NotProductionEnvironmentWarningText = () => {
	return (
		<Typography
			color={"error"}
			variant={"h5"}
			sx={{
				flex: 1,
				textAlign: "center",
			}}
		>
			{i18n.t("not_production_environment_warning").toUpperCase()}
		</Typography>
	);
};
