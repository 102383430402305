import { PageLoading } from "../PageLoading";
import { ErrorText } from "../errors/ErrorText";
import { AsyncState } from "src/utils/async/asyncState";
import { ReactNode } from "react";
import { MaybeAsyncFunction } from "src/types/functions.ts";
import { HorizontalBox } from "../box/HorizontalBox";
import { SxProps, Theme } from "@mui/material/styles";
import { mergeSx } from "src/utils/styles.ts";
import { LoggedError } from "src/errorHandling/errorLogging.ts";

export interface AsyncRenderProps<T> {
	asyncData: AsyncState<T>;
	content?: (data: T) => ReactNode;
	noContainer?: boolean;
	containerSx?: SxProps<Theme>;
	reloadOnError?: MaybeAsyncFunction<void, unknown>;
	errorContent?: (props: AsyncRenderErrorContentProps) => ReactNode;
	skeleton?: ReactNode;
}

export interface AsyncRenderErrorContentProps {
	error: LoggedError;
	reload?: MaybeAsyncFunction<void, unknown>;
}

export function AsyncRender<T>({
	asyncData,
	content,
	reloadOnError,
	noContainer = false,
	containerSx,
	errorContent,
	skeleton,
}: AsyncRenderProps<T>) {
	const { data, loading, error } = asyncData;

	if (error != undefined) {
		if (errorContent != null) return errorContent({ error, reload: reloadOnError });
		else return <ErrorText error={error} reload={reloadOnError} />;
	}

	const loadingNode = skeleton ?? <PageLoading overlay />;

	if (noContainer && data !== undefined && content !== undefined) return content(data);
	if (noContainer && data === undefined && loading) return loadingNode;

	// Render content always inside loading spinner container to avoid
	// remounts when loading state changes
	// This can be
	return (
		<HorizontalBox
			sx={mergeSx(
				{
					flex: 1,
					position: "relative",
				},
				containerSx,
			)}
		>
			{data !== undefined && content !== undefined && content(data)}
			{loading && loadingNode}
		</HorizontalBox>
	);
}
