import { CostEventsDataGridBase } from "src/components/views/erp/common/costEvents/CostEventsDataGridBase.tsx";
import {
	ProjectCostEventsDataGridsApi,
	ProjectCostEventsDataGridsApi_GridData,
} from "src/api/generated/erp/project/project/api/projectCostEventsDataGridsApi.ts";
import { textColumn } from "src/components/common/dataGrid/columns.tsx";
import i18n from "i18next";
import { ProjectActivityCostEventView } from "src/api/generated/erp/db/types/tables/projectActivityCostEventView.ts";
import {
	getProjectStateLabel,
	ProjectState,
	ProjectStateValues,
} from "src/api/generated/erp/db/types/enums/projectState.ts";
import { ProjectQueryApi } from "src/api/generated/erp/project/project/api/projectQueryApi.ts";
import { MultiSelectField } from "src/components/common/inputFields/MultiSelectField.tsx";
import { concatWithPipe } from "src/utils/strings.tsx";
import { Project } from "src/api/generated/erp/db/types/tables/project.ts";
import { AsyncMultiSelectField } from "src/components/common/inputFields/AsyncMultiSelectField.tsx";

interface DataRequestParams {
	projectIds: number[];
	projectStates: ProjectState[];
}

export const MultipleProjectsCostEventsDataGrid = () => {
	return (
		<CostEventsDataGridBase<
			ProjectActivityCostEventView,
			DataRequestParams,
			ProjectCostEventsDataGridsApi_GridData
		>
			fetchData={ProjectCostEventsDataGridsApi.getGridDataForMultipleProjects}
			initialParams={{
				projectIds: [],
				projectStates: [],
			}}
			insert={{ type: "disabled" }}
			disableEdit
			disableDelete
			gridId={"A13FAF0C5AE1EA16"}
			getRowCostEventPriceListId={(row) => row.costEventPriceListId!}
			extraColumns={[
				textColumn({
					field: "projectDescription",
					headerName: i18n.t("project"),
					width: 150,
					position: 0,
				}),
				textColumn({
					field: "subProjectDescription",
					headerName: i18n.t("sub_project"),
					width: 150,
					position: 0,
				}),
				textColumn({
					field: "activityName",
					headerName: i18n.t("activity"),
					width: 150,
					position: 1,
				}),
			]}
			actionBarComponents={({ currentParams, refreshData }) => (
				<>
					<AsyncMultiSelectField
						label={i18n.t("projects")}
						getOptionKey={(o: Project) => o.projectId}
						getOptionLabel={(o) => concatWithPipe(o.projectId, o.projectDescription)}
						fetchOptions={({ searchQuery, currentSelection }) =>
							ProjectQueryApi.getProjectSelectionOptions({
								searchQuery,
								currentSelection: currentSelection?.at(0),
							})
						}
						value={currentParams.projectIds}
						onChange={(selectedProjects) =>
							refreshData({
								projectIds: selectedProjects.map((p) => p.projectId),
							})
						}
					/>
					<MultiSelectField
						label={i18n.t("project_state")}
						options={ProjectStateValues}
						getOptionKey={(option) => option}
						getOptionLabel={(option) => getProjectStateLabel(option)}
						value={currentParams.projectStates}
						onChange={(projectStates) => refreshData({ projectStates })}
					/>
				</>
			)}
		/>
	);
};
