import { ConfigurationComponent } from "src/api/generated/erp/db/types/tables/configurationComponent.ts";
import { AavoForm, AavoFormContentParams } from "src/components/common/forms/AavoForm.tsx";
import { requireRule } from "src/components/common/forms/validation.ts";
import i18n from "i18next";
import { FormTextField } from "src/components/common/forms/fields/FormTextField.tsx";
import { FormResult } from "src/components/common/forms/types.ts";
import { getConfigurationComponentFieldTypeLabels } from "src/api/generated/erp/db/types/enums/configurationComponentFieldType.ts";
import { FormCheckbox } from "src/components/common/forms/fields/FormCheckbox.tsx";
import { FormEnumSelectField } from "src/components/common/forms/fields/FormEnumSelectField.tsx";
import {
	CONFIGURATION_PROPERTY_NULL_VALUE,
	configurationPropertyValueToString,
} from "src/components/views/erp/configurator/configuratorUtils.ts";
import { DeepPartial, useController } from "react-hook-form";
import { AavoTextField } from "src/components/common/inputFields/AavoTextField.tsx";
import { SelectField } from "src/components/common/inputFields/SelectField.tsx";
import { AavoCheckbox } from "src/components/common/inputFields/AavoCheckbox.tsx";
import { FormNumberField } from "src/components/common/forms/fields/FormNumberField.tsx";
import { faPen } from "@fortawesome/pro-regular-svg-icons";
import { AavoButton } from "src/components/common/buttons/AavoButton.tsx";
import { openFormOnDialog } from "src/components/common/dialogs/formDialog/openFormOnDialog.ts";
import { ConfiguratorPropertyForm } from "src/components/views/erp/configurator/managing/productFamilyVersions/properties/ConfiguratorPropertyForm.tsx";
import { useGenericDialog } from "src/components/common/dialogs/useGenericDialog.ts";
import { FormAsyncSelectField } from "src/components/common/forms/fields/FormAsyncSelectField.tsx";
import { CatalogPartApi } from "src/api/generated/erp/parts/catalogPart/api/catalogPartApi.ts";
import { concatWithPipe } from "src/utils/strings.tsx";
import { CatalogPart } from "src/api/generated/erp/db/types/tables/catalogPart.ts";
import Typography from "@mui/material/Typography";
import { HorizontalBox } from "src/components/common/box/HorizontalBox.tsx";
import { BoxWithTitle } from "src/components/common/box/BoxWithTitle.tsx";
import { SelectionFieldComponentOptionsForm } from "src/components/views/erp/configurator/managing/productFamilyVersions/components/componentForm/SelectionFieldComponentOptionsForm.tsx";
import { ConfigurationFieldSelectionOptions_Option } from "src/api/generated/io/aavo/applications/db/erp/types/configurationFieldSelectionOptions.ts";
import { DocumentsOfObjectButton } from "src/components/views/documents/objectDocuments/DocumentsOfObjectButton.tsx";
import { DocumentQueryApi } from "src/api/generated/documents/api/documentQueryApi.ts";
import { FormConfiguratorLuaEditor } from "src/components/views/erp/configurator/scripting/FormConfiguratorLuaEditor.tsx";
import { FormRichTextEditor } from "src/components/common/forms/fields/FormRichTextEditor.tsx";
import { getConfigurationComponentFieldValueInfoTextVisibilityLabels } from "src/api/generated/erp/db/types/enums/configurationComponentFieldValueInfoTextVisibility.ts";
import { LabelValueView } from "src/components/common/misc/LabelValueView.tsx";

export interface ConfigurationComponentFormProps {
	component: ComponentInitialValues;
	backingPropertyId?: number;
	setIsDirty: (isDirty: boolean) => void;
	saveComponent: (component: ConfigurationComponent) => Promise<ConfigurationComponent>;
	onCompleted?: (result: FormResult<ConfigurationComponent>) => Promise<unknown>;
	disabled?: boolean;
}

export interface ComponentInitialValues
	extends Pick<
		ConfigurationComponent,
		| "productFamilyVersionId"
		| "configuratorLibraryVersionId"
		| "libraryComponentSelfUuid"
		| "name"
		| "componentType"
		| "parentTabComponentId"
		| "orderNum"
		| "tabColumnCount"
	> {
	configurationComponentId: number | undefined;
	fieldType?: ConfigurationComponent["fieldType"];
}

interface FormValues extends ConfigurationComponent {}

export const ConfigurationComponentForm = (props: ConfigurationComponentFormProps) => {
	const { component, setIsDirty, onCompleted: onCompletedProp, saveComponent, disabled = false } = props;
	return (
		<AavoForm
			defaultValues={getDefaultValues()}
			submit={submit}
			onCompleted={onCompleted}
			onFormEdited={() => setIsDirty(true)}
			useFormProps={{
				disabled,
			}}
			render={(renderParams) => {
				const contentProps = { ...props, ...renderParams };
				return (
					<>
						{component.componentType === "FIELD" ?
							<FieldForm {...contentProps} />
						: component.componentType === "TEXT" ?
							<TextComponentForm {...contentProps} />
						: component.componentType === "SUB_CONFIGURATOR" ?
							<SubConfiguratorForm {...contentProps} />
						: component.componentType === "SUB_CONFIGURATOR_LIST" ?
							<SubConfiguratorForm {...contentProps} />
						: component.componentType === "SECTION_BREAK" ?
							<SectionBreakForm {...contentProps} />
						: component.componentType === "TAB" ?
							<TabForm {...contentProps} />
						: component.componentType === "LIBRARY_COMPONENT_REFERENCE" ?
							<LibraryComponentReferenceForm {...contentProps} />
						:	null}
						<ComponentDocumentsButton {...contentProps} />
					</>
				);
			}}
		/>
	);

	function getDefaultValues(): DeepPartial<FormValues> {
		return {
			fieldEnabled: true,
			fieldSelectionOptions: [],
			...component,
		};
	}

	async function submit(values: FormValues, { reset }: AavoFormContentParams<FormValues>) {
		const saved = await saveComponent(values);
		reset(saved);
		setIsDirty(false);
		return saved;
	}

	async function onCompleted(
		result: FormResult<ConfigurationComponent>,
		{ reset }: AavoFormContentParams<FormValues>,
	) {
		if (result.type === "cancel") {
			reset();
		}
		await onCompletedProp?.(result);
	}
};

interface FormContentProps extends AavoFormContentParams<FormValues>, ConfigurationComponentFormProps {}

const FieldForm = (props: FormContentProps) => {
	const { control, setValue, watch, component } = props;

	const fieldType = watch("fieldType") ?? component.fieldType;

	const isLibraryComponent = component.configuratorLibraryVersionId != null;
	const isExistingLibraryComponent = component.configurationComponentId != null && isLibraryComponent;

	return (
		<>
			<NameField {...props} disabled={isExistingLibraryComponent} />
			<FormTextField
				control={control}
				name={"label"}
				label={i18n.t("label.configurator_component")}
				rules={requireRule()}
				disabled={isExistingLibraryComponent}
			/>
			<FormEnumSelectField
				control={control}
				name={"fieldType"}
				label={i18n.t("type")}
				options={getConfigurationComponentFieldTypeLabels()}
				rules={requireRule()}
				onChange={() => {
					setValue("fieldDefaultValue", null);
				}}
				disabled={isExistingLibraryComponent}
			/>
			{fieldType === "SELECTION" && <SelectionFieldFormParts {...props} />}
			{fieldType === "DOCUMENT" && <DocumentFieldFormParts {...props} />}
			<FieldDefaultValueField {...props} />
			<FormCheckbox
				control={control}
				name={"fieldRefreshOnChange"}
				label={i18n.t("refresh_view_on_value_change")}
			/>
			<FormCheckbox control={control} name={"fieldRequired"} label={i18n.t("required")} />
			<FormCheckbox control={control} name={"fieldEnabled"} label={i18n.t("enabled")} />
			<CommonLayoutFields {...props} />
			{!isLibraryComponent && (
				<FormNumberField control={control} name={"indent"} label={i18n.t("indent")} type={"integer"} />
			)}
			<FormConfiguratorLuaEditor
				control={control}
				name={"transformationScript"}
				label={i18n.t("transformation")}
				productFamilyVersionId={component.productFamilyVersionId}
				catalogPartRevisionId={null}
			/>
			<FormConfiguratorLuaEditor
				control={control}
				name={"fieldDefaultValueScript"}
				label={i18n.t("default_value_function")}
				productFamilyVersionId={component.productFamilyVersionId}
				catalogPartRevisionId={null}
			/>
			<FormConfiguratorLuaEditor
				control={control}
				name={"onChangeScript"}
				label={i18n.t("value_change_function")}
				productFamilyVersionId={component.productFamilyVersionId}
				catalogPartRevisionId={null}
			/>
			{fieldType !== "SELECTION" && (
				<>
					<FormRichTextEditor
						control={control}
						name={`fieldValueInfoText`}
						label={i18n.t("value_info_text")}
						sx={{
							marginTop: 2,
						}}
					/>
				</>
			)}
			<FormEnumSelectField
				control={control}
				name={`fieldValueInfoTextVisibility`}
				label={i18n.t("info_text_visibility")}
				options={getConfigurationComponentFieldValueInfoTextVisibilityLabels()}
			/>
			<OpenBackingPropertyFormButton {...props} />
		</>
	);
};

const FieldDefaultValueField = ({ control, watch, component, disabled }: FormContentProps) => {
	const fieldType = watch("fieldType") ?? component.fieldType;

	const commonProps = {
		label: i18n.t("default_value"),
	} as const;

	const { field } = useController({
		control: control,
		name: "fieldDefaultValue",
	});

	switch (fieldType) {
		case "TEXT":
		case "TEXTAREA":
			return (
				<AavoTextField
					{...commonProps}
					multiline={fieldType === "TEXTAREA"}
					value={configurationPropertyValueToString(field.value)}
					disabled={disabled}
					onChange={(value) =>
						field.onChange({
							type: "string",
							value: value,
						})
					}
				/>
			);
		case "INTEGER":
		case "DECIMAL":
			return (
				<AavoTextField
					{...commonProps}
					type={"number"}
					value={configurationPropertyValueToString(field.value)}
					disabled={disabled}
					onChange={(value) =>
						field.onChange(
							value == null ?
								CONFIGURATION_PROPERTY_NULL_VALUE
							:	{
									type: {
										DECIMAL: "decimal",
										INTEGER: "int",
									}[fieldType],
									value: value,
								},
						)
					}
				/>
			);
		case "CHECKBOX":
			return (
				<AavoCheckbox
					{...commonProps}
					checked={field.value?.type === "bool" ? field.value.value : false}
					disabled={disabled}
					onChange={(value) =>
						field.onChange({
							type: "bool",
							value: value,
						})
					}
				/>
			);
		case "SELECTION":
			return (
				<SelectField
					{...commonProps}
					options={watch("fieldSelectionOptions")}
					getOptionKey={(option) => option.key}
					getOptionLabel={(option) => option.label}
					value={configurationPropertyValueToString(field.value)}
					disabled={disabled}
					onChange={(value) =>
						field.onChange(
							value == null ?
								CONFIGURATION_PROPERTY_NULL_VALUE
							:	{
									type: "string",
									value: value,
								},
						)
					}
				/>
			);
	}
};

const SelectionFieldFormParts = ({ watch, setValue, setIsDirty }: FormContentProps) => {
	const options = watch("fieldSelectionOptions");

	const { openDialog } = useGenericDialog();

	return (
		<BoxWithTitle
			title={i18n.t("options")}
			sx={{
				display: "flex",
				flexDirection: "column",
				gap: 1,
				padding: 1,
			}}
		>
			{options.map((option) => (
				<HorizontalBox key={option.key}>
					<Typography>
						{option.key}: {option.label}
					</Typography>
				</HorizontalBox>
			))}
			<AavoButton
				icon={faPen}
				label={i18n.t("edit")}
				onClick={() => {
					openFormOnDialog({
						openDialog,
						component: SelectionFieldComponentOptionsForm,
						title: i18n.t("options"),
						size: "xl",
						props: {
							options: options,
						},
						onSubmit: (newOptions: ConfigurationFieldSelectionOptions_Option[]) => {
							setValue("fieldSelectionOptions", newOptions);
							setIsDirty(true);
						},
					});
				}}
			/>
		</BoxWithTitle>
	);
};

const DocumentFieldFormParts = ({ control }: FormContentProps) => {
	return (
		<>
			<FormAsyncSelectField
				control={control}
				name={"fieldDocumentCategoryId"}
				label={i18n.t("document_category")}
				fetchOptions={DocumentQueryApi.getDocumentCategoryOptions}
				getOptionKey={(option) => option.documentCategoryId}
				getOptionLabel={(option) => option.name}
			/>
			<FormTextField control={control} name={"fieldDocumentDescription"} label={i18n.t("document_description")} />
		</>
	);
};

const TextComponentForm = (props: FormContentProps) => {
	const { control } = props;
	return (
		<>
			<NameField {...props} />
			<FormRichTextEditor
				control={control}
				name={`textComponentContent`}
				label={i18n.t("content")}
				sx={{ marginTop: 2 }}
			/>
			<FormCheckbox control={control} name={`textComponentVisibleOnForm`} label={i18n.t("visible_on_form")} />
			<FormCheckbox
				control={control}
				name={`textComponentVisibleOnPrintout`}
				label={i18n.t("visible_on_printout")}
			/>
			<CommonLayoutFields {...props} hideVisibilityField />
		</>
	);
};

const SubConfiguratorForm = (props: FormContentProps) => {
	const { control } = props;
	return (
		<>
			<NameField {...props} />
			<FormTextField control={control} name={"label"} label={i18n.t("title")} rules={requireRule()} />
			<FormAsyncSelectField
				control={control}
				name={"subConfiguratorCatalogPartId"}
				label={i18n.t("part")}
				fetchOptions={({ searchQuery, currentSelection }) =>
					CatalogPartApi.getCatalogPartOptions({
						searchQuery,
						currentSelection,
						onlyIfConfigurable: true,
					})
				}
				getOptionKey={(option: CatalogPart) => option.catalogPartId}
				getOptionLabel={(option) => concatWithPipe(option.partNo, option.description_1, option.description_2)}
			/>
			<FormCheckbox control={control} name={"subConfiguratorRequired"} label={i18n.t("required")} />
			<CommonLayoutFields {...props} />
			<OpenBackingPropertyFormButton {...props} />
		</>
	);
};

const SectionBreakForm = ({ control, component }: FormContentProps) => {
	return (
		<>
			<FormTextField control={control} name={"label"} label={i18n.t("title")} rules={requireRule()} />
			<FormCheckbox control={control} name={"visible"} label={i18n.t("visible")} />
			<FormConfiguratorLuaEditor
				control={control}
				name={"transformationScript"}
				label={i18n.t("transformation")}
				productFamilyVersionId={component.productFamilyVersionId}
				catalogPartRevisionId={null}
			/>
		</>
	);
};

const TabForm = ({ control }: FormContentProps) => {
	return (
		<>
			<FormTextField control={control} name={"label"} label={i18n.t("title")} rules={requireRule()} />
			<FormNumberField
				control={control}
				name={"tabColumnCount"}
				label={i18n.t("column_count")}
				rules={requireRule()}
			/>
		</>
	);
};

const LibraryComponentReferenceForm = (props: FormContentProps) => {
	const { control, watch } = props;
	return (
		<>
			<LabelValueView
				items={[
					{
						label: i18n.t("library_component"),
						value: watch("referencedLibraryComponentUuid"),
					},
				]}
			/>
			<NameField {...props} />
			<FormTextField control={control} name={"label"} label={i18n.t("title")} rules={requireRule()} />
			<CommonLayoutFields {...props} />
			<OpenBackingPropertyFormButton {...props} />
		</>
	);
};

const NameField = ({ control, disabled }: FormContentProps & { disabled?: boolean }) => {
	return (
		<FormTextField
			control={control}
			name={"name"}
			label={i18n.t("name")}
			rules={requireRule()}
			disabled={disabled}
		/>
	);
};

interface CommonLayoutFieldsProps extends FormContentProps {
	hideVisibilityField?: boolean;
}

const CommonLayoutFields = ({ control, watch, component, hideVisibilityField = false }: CommonLayoutFieldsProps) => {
	if (component.configuratorLibraryVersionId != null) return;

	return (
		<>
			<FormCheckbox control={control} name={"spanAllColumns"} label={i18n.t("fill_whole_row")} />
			{!watch("spanAllColumns") && (
				<>
					<FormNumberField
						control={control}
						name={"columnSpan"}
						label={i18n.t("column_span")}
						type={"integer"}
						rules={requireRule()}
					/>
					<FormCheckbox control={control} name={"placeOnNewRow"} label={i18n.t("place_on_new_row")} />
				</>
			)}
			{!hideVisibilityField && <FormCheckbox control={control} name={"visible"} label={i18n.t("visible")} />}
		</>
	);
};

const OpenBackingPropertyFormButton = ({ component, backingPropertyId, disabled }: FormContentProps) => {
	const { openDialog } = useGenericDialog();

	const productFamilyVersionId = component.productFamilyVersionId;
	if (backingPropertyId == null || productFamilyVersionId == null) return;
	return (
		<AavoButton
			icon={faPen}
			label={i18n.t("property")}
			onClick={() => {
				openFormOnDialog({
					openDialog,
					component: ConfiguratorPropertyForm,
					title: i18n.t("property"),
					size: "xl",
					props: {
						productFamilyVersionId: productFamilyVersionId,
						configuratorPropertyId: backingPropertyId,
						disabled: disabled,
					},
				});
			}}
		/>
	);
};

const ComponentDocumentsButton = ({ component, disabled }: FormContentProps) => {
	if (component.componentType === "TAB") return;
	if (component.configurationComponentId == null) return;

	return (
		<DocumentsOfObjectButton
			label={i18n.t("documents")}
			objectRef={{
				objectType: "CONFIGURATION_COMPONENT",
				objectId: component.configurationComponentId,
			}}
			editable={!disabled}
		/>
	);
};
