// This file is automatically generated. Do not edit manually.

import { WarehouseLocation } from "src/api/generated/erp/db/types/tables/warehouseLocation";
import { PartWarehouseLocation } from "src/api/generated/erp/db/types/tables/partWarehouseLocation";
import { PartWarehouseLocationView } from "src/api/generated/erp/db/types/tables/partWarehouseLocationView";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace PartWarehouseLocationEditApi {
	export async function getFormInitData(args: {
		partId: number;
		partWarehouseLocationId: number | null | undefined;
	}): Promise<PartWarehouseLocationEditApi_FormInitData> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sitePart/partWarehouseLocations/getFormInitData",
			method: "POST",
			data: {
				partId: args.partId,
				partWarehouseLocationId: args.partWarehouseLocationId,
			},
		});
		return response as PartWarehouseLocationEditApi_FormInitData;
	}

	export async function getWarehouseLocationOptions(args: {
		partId: number;
		partWarehouseLocationId: number | null | undefined;
		searchQuery: string;
		currentSelection: number | null | undefined;
	}): Promise<Array<WarehouseLocation>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sitePart/partWarehouseLocations/getWarehouseLocationOptions",
			method: "POST",
			data: {
				partId: args.partId,
				partWarehouseLocationId: args.partWarehouseLocationId,
				searchQuery: args.searchQuery,
				currentSelection: args.currentSelection,
			},
		});
		return response as Array<WarehouseLocation>;
	}

	export async function insert(args: { partWarehouseLocation: PartWarehouseLocation }): Promise<number> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sitePart/partWarehouseLocations/insert",
			method: "POST",
			data: {
				partWarehouseLocation: args.partWarehouseLocation,
			},
		});
		return response as number;
	}

	export async function update(args: { partWarehouseLocation: PartWarehouseLocation }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sitePart/partWarehouseLocations/update",
			method: "POST",
			data: {
				partWarehouseLocation: args.partWarehouseLocation,
			},
		});
		return response as void;
	}

	export async function delete_(args: { partWarehouseLocationIds: Array<number> }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sitePart/partWarehouseLocations/delete",
			method: "POST",
			data: {
				partWarehouseLocationIds: args.partWarehouseLocationIds,
			},
		});
		return response as void;
	}
}

export interface PartWarehouseLocationEditApi_FormInitData {
	givingWarehouseLocationOptions: Array<PartWarehouseLocationView>;
	inventoryLevelEnabledByDefault: boolean;
	partHasOtherWarehouseLocations: boolean;
	partWarehouseLocation: PartWarehouseLocation | null | undefined;
	siteId: number;
}
