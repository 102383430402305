import { DeliverySource } from "src/api/generated/erp/delivery/model/deliverySource.ts";
import { DeliverySourceType } from "src/api/generated/erp/db/types/enums/deliverySourceType.ts";
import { logError } from "src/errorHandling/errorLogging.ts";

export function createDeliverySource({
	sourceType,
	sourceId,
}: {
	sourceType: DeliverySourceType;
	sourceId: number | null | undefined;
}): DeliverySource {
	switch (sourceType) {
		case "CUSTOMER_ORDER":
			if (sourceId == null) {
				logError("Source ID is null for delivery source type CUSTOMER_ORDER");
			}
			return {
				type: "customerOrder",
				customerOrderId: sourceId!,
			};
		case "PROJECT":
			if (sourceId == null) {
				logError("Source ID is null for delivery source type PROJECT");
			}
			return {
				type: "project",
				projectId: sourceId!,
			};
	}
}

export function createNullableDeliverySource({
	sourceType,
	sourceId,
}: {
	sourceType: DeliverySourceType | null | undefined;
	sourceId: number | null | undefined;
}): DeliverySource | null {
	if (sourceType != null && sourceId != null) {
		return createDeliverySource({ sourceType, sourceId });
	}
	return null;
}

export function deliverySourceToSourceType(source: DeliverySource): DeliverySourceType {
	switch (source.type) {
		case "customerOrder":
			return "CUSTOMER_ORDER";
		case "project":
			return "PROJECT";
	}
}

export function deliverySourceToSourceId(source: DeliverySource): number {
	switch (source.type) {
		case "customerOrder":
			return source.customerOrderId;
		case "project":
			return source.projectId;
	}
}
