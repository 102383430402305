import "regenerator-runtime/runtime";
import Highcharts from "highcharts/highcharts";
import HighchartsMore from "highcharts/highcharts-more";
import HighchartsGantt from "highcharts/modules/gantt";
import HighchartsPareto from "highcharts/modules/pareto";
import HighchartsHistogram from "highcharts/modules/histogram-bellcurve";
import HighchartsSolidGauge from "highcharts/modules/solid-gauge";
import HighchartsDraggablePoints from "highcharts/modules/draggable-points";
import HighChartsCustomEvents from "highcharts-custom-events";
import Exporting from "highcharts/modules/exporting";
import { html, LitElement } from "lit";
import { onAppContentAreaChanged } from "src/legacyViews/js/appContentAreaChangedEvent";
import { unixMillisToIso } from "../utils";
import { AAVO_MUI_THEME } from "../../../theme/theme";

Exporting(Highcharts);
HighchartsMore(Highcharts);
HighchartsGantt(Highcharts);
HighchartsPareto(Highcharts);
HighchartsHistogram(Highcharts);
HighchartsSolidGauge(Highcharts);
HighchartsDraggablePoints(Highcharts);
HighChartsCustomEvents(Highcharts);

const dateTimeLabelFormats = {
	day: "%d.%m",
	week: "%d.%m",
	month: "%m / %Y",
};

const palette = AAVO_MUI_THEME.palette;

Highcharts.setOptions({
	plotOptions: {
		series: {
			animation: false,
			events: {
				click: (e) => {
					e.preventDefault();
				},
			},
		},
	},
	xAxis: {
		dateTimeLabelFormats: dateTimeLabelFormats,
	},
	yAxis: {
		dateTimeLabelFormats: dateTimeLabelFormats,
	},
	tooltip: {
		dateTimeLabelFormats: dateTimeLabelFormats,
	},
	credits: {
		enabled: false,
	},
	accessibility: {
		enabled: false,
	},
	colors: [
		palette.primary.main,
		palette.secondary.dark,
		palette.info.main,
		"#00e272",
		"#6b8abc",
		"#d568fb",
		"#2ee0ca",
		"#fa4b42",
		"#feb56a",
		"#91e8e1",
	],
});

export default class HighChartsChart extends LitElement {
	static get properties() {
		return {
			chartBaseType: { type: String }, // CommonCharts, GanttChart
			highChartsOptions: { type: Object },
			isLoading: { type: Boolean },
			chartEventHandlers: { type: Array },
		};
	}

	constructor() {
		super();
		this.chartBaseType = "chart";
		this.highChartsOptions = {};
		this.isLoading = false;
		this.chartEventHandlers = [];
	}

	createRenderRoot() {
		return this;
	}

	render() {
		return html`
			<div id="highcharts-container" style="flex: 1" />`;
	}

	_reflowChart() {
		if (this._chart) {
			this._chart.reflow();
		}
	}

	firstUpdated(_) {
		onAppContentAreaChanged(() => {
			this._reflowChart();
		});
	}

	updated(changedProperties) {
		if (changedProperties.has("highChartsOptions")) {
			const container = this.querySelector("#highcharts-container");
			const parsedOptions = this.setCustomEventHandlers(this.highChartsOptions);

			if (!parsedOptions.chart) parsedOptions.chart = {};
			parsedOptions.chart.events = this.getChartEvents();

			if (this.chartBaseType === "GanttChart") this._chart = Highcharts.ganttChart(container, parsedOptions);
			else this._chart = Highcharts.chart(container, parsedOptions);

			setTimeout(this._reflowChart.bind(this), 0);
		}
		if (changedProperties.has("isLoading")) {
			if (this._chart) {
				if (this.isLoading) this._chart.showLoading();
				else this._chart.hideLoading();
			}
		}
	}

	disconnectedCallback() {
		super.disconnectedCallback();
		if (this._chart) this._chart.destroy();
		this._chart = null;
	}

	setCustomEventHandlers(highChartsOptions) {
		// traverseForScalarValues allows to replace selected scalar values in object
		return traverseForScalarValues(highChartsOptions, (el) => {
			const eventHandlersByKeys = {
				__CONTEXT_MENU__: (key, event) => this.onContextMenuEvent(key, event),
				__ACTION__: (key, event) => this.onChartActionEvent(key, event),
			};
			for (const [eventKeySelector, handler] of Object.entries(eventHandlersByKeys)) {
				const eventKeyRegex = new RegExp(`${eventKeySelector}(\\w+)$`);
				const eventKey = getMatchingEventKey(el, eventKeyRegex);
				// Return event handler if matching key found
				if (eventKey)
					return (event) => {
						return handler(eventKey, event);
					};
			}
			// Return original value by default.
			return el;
		});
	}

	getChartEvents() {
		const ret = {};
		const chartContainer = this.querySelector("#highcharts-container");
		for (const { eventName, handlerScript } of this.chartEventHandlers) {
			ret[eventName] = function (event) {
				const chart = this;
				const func = new Function("chartContainer", "chart", "event", handlerScript);
				return func(chartContainer, chart, event);
			};
		}
		return ret;
	}

	onContextMenuEvent(ctxMenuKey, event) {
		event.preventDefault();
		const point = event.point || {};
		this.dispatchEvent(
			new CustomEvent("chartContextMenu", {
				detail: {
					contextMenuKey: ctxMenuKey,
					event: event,
					documentRect: document.documentElement.getBoundingClientRect(),
					pointOptions: point.options || {},
				},
			}),
		);
	}

	onChartActionEvent(actionKey, event) {
		const targetPoint = event.point || event.target || {};
		const pointOptions = targetPoint.options || {};

		// Insert category ID if exists
		const category = targetPoint.yCategory || {};
		pointOptions["__CATEGORY_ID__"] = category["categoryId"] || null;

		// For Gantt-chart drag-drop
		pointOptions["__POINT_ID__"] = pointOptions.id;
		pointOptions["__START__"] = unixMillisToIso(pointOptions.start);
		pointOptions["__END__"] = unixMillisToIso(pointOptions.end);

		const xAxis = this._chart.xAxis[0];
		if (xAxis) {
			const xAxisExtremes = xAxis.getExtremes();
			pointOptions["__X_MAX__"] = xAxisExtremes.max;
			pointOptions["__X_MIN__"] = xAxisExtremes.min;
		}

		this.dispatchEvent(
			new CustomEvent("chartAction", {
				detail: {
					actionKey: actionKey,
					pointOptions: pointOptions,
				},
			}),
		);
	}

	static getEventPointOptions(event) {
		if (!event.point) return {};
		return event.point.options;
	}
}

function traverseForScalarValues(element, handler) {
	if (Array.isArray(element)) {
		return element.map((child) => traverseForScalarValues(child, handler));
	} else if (typeof element === "object" && element !== null) {
		const retObject = {};
		for (const [key, value] of Object.entries(element)) {
			retObject[key] = traverseForScalarValues(value, handler);
		}
		return retObject;
	}

	return handler(element);
}

function getMatchingEventKey(value, regex) {
	if (typeof value !== "string") return null;
	const match = value.match(regex);
	if (match && match.length === 2) {
		return match[1];
	}
	return null;
}

customElements.define("highcharts-chart", HighChartsChart);
