import {ClientSideDataGridModel} from "src/components/common/dataGrid/gridModel/ClientSideDataGridModel.tsx";
import {CrudDataGrid} from "src/components/common/dataGrid/crud/CrudDataGrid.tsx";
import {integerColumn, textColumn} from "src/components/common/dataGrid/columns.tsx";
import {ProjectActivityType} from "src/api/generated/erp/db/types/tables/projectActivityType.ts";
import i18n from "i18next";
import {ProjectActivityTypeApi} from "src/api/generated/erp/project/projectActivity/api/projectActivityTypeApi.ts";
import {
    ProjectActivityTypeForm
} from "src/components/views/erp/project/projectActivity/activityTypes/ProjectActivityTypeForm.tsx";

export const ProjectActivityTypesDataGrid = () => {
	return (
	<ClientSideDataGridModel
			fetchData={() => ProjectActivityTypeApi.getActivityTypes()}
			initialParams={{}}
			getRows={(response) => response}
			getRowId={(row) => row.activityTypeId}
			gridId={"D84732239F2845A7"}
			render={({ dataGridProps }) => (
				<CrudDataGrid<ProjectActivityType>
					disableMultipleRowSelection			
					columns={[
						textColumn({
							field: "activityTypeName",
							headerName: i18n.t("activity_type"),
							width: 200
						}),
						integerColumn({
							field: "activityTypeId",
							headerName: i18n.t("id"),
						}),
					]}
					form={{
						addRowEnabled: true,
						editEnabled: true,
						dialogTitle: i18n.t("activity_type"),
						dialogSize: "sm",
						component: ({ row, onCompleted, onFormEdited }) => (
							<ProjectActivityTypeForm
								projectActivityType={row}
								onCompleted={onCompleted}
								onFormEdited={onFormEdited}
							/>
						),
					}}
					remove={({ row }) => ProjectActivityTypeApi.delete_({ activityTypeId: row.activityTypeId })}
					{...dataGridProps}
				/>
			)}
		/>
	);
};
