// This file is automatically generated. Do not edit manually.

import { VatCode } from "src/api/generated/erp/db/types/tables/vatCode";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace VatCodeApi {
	export async function getVatCodes(): Promise<Array<VatCode>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/sales/vatCodes/getVatCodes",
			method: "POST",
			data: {},
		});
		return response as Array<VatCode>;
	}

	export async function insert(args: { vatCode: VatCode }): Promise<number> {
		const response = await makeAxiosRequest({
			url: "/api/ext/sales/vatCodes/insert",
			method: "POST",
			data: {
				vatCode: args.vatCode,
			},
		});
		return response as number;
	}

	export async function update(args: { vatCode: VatCode }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/sales/vatCodes/update",
			method: "POST",
			data: {
				vatCode: args.vatCode,
			},
		});
		return response as void;
	}

	export async function delete_(args: { vatCodeIds: Array<number> }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/sales/vatCodes/delete",
			method: "POST",
			data: {
				vatCodeIds: args.vatCodeIds,
			},
		});
		return response as void;
	}

	export async function setVatCodeIsActive(args: { vatCodeId: number; isActive: boolean }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/sales/vatCodes/setVatCodeIsActive",
			method: "POST",
			data: {
				vatCodeId: args.vatCodeId,
				isActive: args.isActive,
			},
		});
		return response as void;
	}

	export async function setToDefaultVatCode(args: { vatCodeId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/sales/vatCodes/setToDefaultVatCode",
			method: "POST",
			data: {
				vatCodeId: args.vatCodeId,
			},
		});
		return response as void;
	}
}
