import { Box, ListItem } from "@mui/material";
import { LogOutButton } from "src/components/views/login/LogOutButton.tsx";
import { DrawerSelectionFields } from "./DrawerSelectionFields";
import i18n from "i18next";
import { useNavigate } from "react-router-dom";
import { AavoButton } from "src/components/common/buttons/AavoButton";
import { faGear } from "@fortawesome/pro-regular-svg-icons";
import { absolute } from "src/utils/paths";
import { SETTINGS } from "src/urls";

export const DrawerBottomFields = () => {
	const navigate = useNavigate();
	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
			}}
		>
			<DrawerSelectionFields />
			<ListItem disablePadding>
				<AavoButton
					icon={faGear}
					variant={"text"}
					onClick={() => {
						navigate(absolute(SETTINGS));
					}}
					sx={buttonSx}
					fullWidth={true}
				>
					{i18n.t("settings")}
				</AavoButton>
			</ListItem>
			<ListItem disablePadding>
				<LogOutButton showIcon sx={buttonSx} fullWidth={true} />
			</ListItem>
		</Box>
	);
};

const buttonSx = {
	marginLeft: 1,
	justifyContent: "flex-start",
};
