import { Primitive } from "src/types/primitive.ts";
import { FieldPath, FieldPathValue, useController, UseControllerProps } from "react-hook-form";
import {
	LazyMultiSelectField,
	LazyMultiSelectFieldProps,
} from "src/components/common/inputFields/LazyMultiSelectField.tsx";
import { logError } from "src/errorHandling/errorLogging.ts";

export interface FormLazyMultiSelectFieldProps<TFieldValues extends object, T, Key extends Primitive>
	extends Omit<
		LazyMultiSelectFieldProps<T, Key>,
		"onChange" | "error" | "onBlur" | "defaultValue" | "fetchOnMount"
	> {
	control: UseControllerProps<TFieldValues>["control"];
	name: UseControllerProps<TFieldValues>["name"];
	rules?: UseControllerProps<TFieldValues>["rules"];
	decodeValue?: (v: string) => Key[];
	encodeValue?: (v: Key[]) => string;
}

export const FormLazyMultiSelectField = <
	TFieldValues extends object,
	TFieldName extends FieldPath<TFieldValues>,
	T,
	Key extends Primitive,
>({
	control,
	name,
	rules,
	disabled,
	decodeValue: decodeValueProp,
	encodeValue,
	...other
}: FormLazyMultiSelectFieldProps<TFieldValues, T, Key>) => {
	const { field, fieldState } = useController({
		name,
		control,
		rules,
		defaultValue: [] as FieldPathValue<TFieldValues, TFieldName>,
	});

	return (
		<LazyMultiSelectField
			error={fieldState.error?.message}
			disabled={disabled || field.disabled}
			onBlur={field.onBlur}
			value={decodeValue()}
			fetchOnMount={true}
			onChange={(v: Key[]) => {
				field.onChange(encodeValue ? encodeValue(v) : v);
			}}
			{...other}
		/>
	);

	function decodeValue() {
		try {
			return decodeValueProp ? decodeValueProp(field.value) : field.value;
		} catch (e) {
			logError(e);
			return [];
		}
	}
};
