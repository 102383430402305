// This file is automatically generated. Do not edit manually.

import { UserNotificationSourceType } from "src/api/generated/postgres/db/types/public_/enums/userNotificationSourceType";
import { UserNotificationView } from "src/api/generated/postgres/db/types/public_/tables/userNotificationView";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace UserNotificationsOfSourceDataGridApi {
	export async function getGridData(args: {
		sourceType: UserNotificationSourceType;
		sourceId: number;
	}): Promise<Array<UserNotificationView>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/userNotificationsOfSource/getGridData",
			method: "POST",
			data: {
				sourceType: args.sourceType,
				sourceId: args.sourceId,
			},
		});
		return response as Array<UserNotificationView>;
	}

	export async function sendTestNotifications(args: {
		userNotificationsId: number;
		testNotificationTitle: string;
		testNotificationContent: string;
	}): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/userNotificationsOfSource/sendTestNotifications",
			method: "POST",
			data: {
				userNotificationsId: args.userNotificationsId,
				testNotificationTitle: args.testNotificationTitle,
				testNotificationContent: args.testNotificationContent,
			},
		});
		return response as void;
	}
}
