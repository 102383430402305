import { Reducer, useCallback } from "react";
import { useStateWithHistory } from "src/utils/useStateWithHistory.ts";

export type UseReducerWithHistoryReturn<S, A> = readonly [
	S,
	(action: A) => void,
	{
		undo: (() => void) | undefined;
		redo: (() => void) | undefined;
		clearHistory: () => void;
	},
];

export const useReducerWithHistory = <S, A>(
	reducer: Reducer<S, A>,
	initialState: S,
	{
		maxHistoryLength,
	}: {
		maxHistoryLength: number;
	} = {
		maxHistoryLength: 100,
	},
): UseReducerWithHistoryReturn<S, A> => {
	const [state, setState, historyFunctions] = useStateWithHistory<S>(initialState, { maxHistoryLength });

	const dispatch = useCallback(
		(action: A) => {
			setState(reducer(state, action));
		},
		[setState, reducer, state],
	);

	return [state, dispatch, historyFunctions];
};
