import { dataGridPersistentStateProps } from "src/components/common/dataGrid/dataGridStateStorage";
import {
	booleanColumn,
	enumColumn,
	singleSelectColumn,
	textColumn,
} from "src/components/common/dataGrid/columns.tsx";
import { getWarehouseTypeLabels } from "src/api/generated/erp/db/types/enums/warehouseType";
import { WarehouseLocationView } from "src/api/generated/erp/db/types/tables/warehouseLocationView";
import i18n from "i18next";
import { AsyncRender } from "src/components/common/async/AsyncRender";
import { CrudDataGrid } from "src/components/common/dataGrid/crud/CrudDataGrid.tsx";
import { useParameterizedAsyncData } from "src/utils/async/parameterizedAsyncData.ts";
import { AsyncMenuButton } from "src/components/common/contextMenu/AsyncMenuButton.tsx";
import { AavoTextField } from "src/components/common/inputFields/AavoTextField";
import { faFileArchive, faUndo } from "@fortawesome/pro-regular-svg-icons";
import { AavoCheckbox } from "../../../../common/inputFields/AavoCheckbox.tsx";
import { WarehouseLocationsCrudApi } from "src/api/generated/erp/warehouse/basedata/api/warehouseLocationsCrudApi.ts";

export const WarehouseLocationsDataGrid = () => {
	const { dataAsync, paramValues, refresh } = useParameterizedAsyncData({
		fetchData: WarehouseLocationsCrudApi.getWarehouseLocationsData,
		initialParams: {
			searchQuery: "",
			showOnlyDefaultSite: false,
			showArchived: false,
		},
	});

	return (
		<AsyncRender
			asyncData={dataAsync}
			content={({
				warehouseLocations,
				pickerGroupOptions,
				siteOptions,
				userDefaultSiteId,
			}) => (
				<CrudDataGrid<WarehouseLocationView>
					rows={warehouseLocations}
					refreshData={refresh}
					editMode={"row"}
					columns={[
						singleSelectColumn({
							field: "siteId",
							headerName: i18n.t("site"),
							editable: true,
							valueOptions: siteOptions.map((site) => ({
								value: site.siteId,
								label: site.siteName,
							})),
						}),
						enumColumn({
							field: "warehouseType",
							headerName: i18n.t("warehouse_type"),
							editable: true,
							enumLabels: getWarehouseTypeLabels(),
							width: 150,
						}),
						singleSelectColumn({
							field: "warehousePickerGroupId",
							headerName: i18n.t("picker_group"),
							editable: true,
							width: 150,
							valueOptions: pickerGroupOptions.map((group) => ({
								value: group.warehousePickerGroupId,
								label: group.warehousePickerGroupCode,
							})),
							validate: "required"
						}),
						booleanColumn({
							field: "isManual",
							headerName: i18n.t("manual"),
							editable: true,
						}),
						textColumn({
							field: "locationCode",
							headerName: i18n.t("warehouse_location"),
							editable: true,
							width: 150,
						}),
						textColumn({
							field: "warehouse",
							headerName: i18n.t("warehouse"),
							editable: true,
						}),
						textColumn({
							field: "aisle",
							headerName: i18n.t("aisle"),
							editable: true,
						}),
						textColumn({
							field: "shelfSpace",
							headerName: i18n.t("shelf_space"),
							editable: true,
						}),
						textColumn({
							field: "tier",
							headerName: i18n.t("tier"),
							editable: true,
						}),
						textColumn({
							field: "locker",
							headerName: i18n.t("locker"),
							editable: true,
						}),
						booleanColumn({
							field: "isActive",
							headerName: i18n.t("active"),
						}),
					]}
					getRowId={(row) => row.warehouseLocationId}
					add={{
						type: "enabled",
						action: async () => ({
							warehouseType: "PICK",
							inventoryLevelEnabled: false,
							siteId: userDefaultSiteId,
							warehousePickerGroupId: pickerGroupOptions[0]?.warehousePickerGroupId,
							warehouse: "",
							aisle: "",
							shelfSpace: "",
							tier: "",
							locker: "",
						}),
					}}
					save={{
						type: "enabled",
						action: ({ items }) =>
							WarehouseLocationsCrudApi.save({
								warehouseLocations: items.map(processWarehouseLocationToSave),
							}),
					}}
					remove={{
						type: "enabled",
						action: ({ items }) =>
							WarehouseLocationsCrudApi.delete_({ warehouseLocations: items }),
					}}
					{...dataGridPersistentStateProps("545DE801890D4F0C")}
					actionBarComponents={
						<>
							<AavoTextField
								label={i18n.t("search")}
								onSubmit={async (input) => {
									await refresh({ searchQuery: input });
								}}
							/>
							<AavoCheckbox
								label={i18n.t("only_default_site")}
								checked={paramValues.showOnlyDefaultSite}
								hidden={siteOptions.length <= 1}
								onChange={async (checked) => {
									await refresh({ showOnlyDefaultSite: checked });
								}}
							/>
							<AavoCheckbox
								label={i18n.t("show_archived")}
								checked={paramValues.showArchived}
								onChange={async (checked) => {
									await refresh({ showArchived: checked });
								}}
							/>
						</>
					}
					rowContextMenuComponents={({ row }) => [
						row.isActive && (
							<AsyncMenuButton
								icon={faFileArchive}
								label={i18n.t("archive")}
								key={"archive"}
								onClick={async () => {
									await WarehouseLocationsCrudApi.setWarehouseLocationIsActive({
										warehouseLocationId: row.warehouseLocationId,
										isActive: false,
									});
									await refresh({});
								}}
							/>
						),
						!row.isActive && (
							<AsyncMenuButton
								icon={faUndo}
								label={i18n.t("undo_archive")}
								key={"undo_archive"}
								onClick={async () => {
									await WarehouseLocationsCrudApi.setWarehouseLocationIsActive({
										warehouseLocationId: row.warehouseLocationId,
										isActive: true,
									});
									await refresh({});
								}}
							/>
						),
					]}
				/>
			)}
		/>
	);
};

const processWarehouseLocationToSave = (row: WarehouseLocationView): WarehouseLocationView => {
	if (row.isManual) {
		return {
			...row,
			warehouse: "",
			aisle: "",
			shelfSpace: "",
			tier: "",
			locker: "",
		};
	} else {
		return {
			...row,
			locationCode: row.warehouse + row.aisle + row.shelfSpace + row.tier + row.locker,
		};
	}
};
