export const setFavicon = (faviconUrl: string) => {
	const links = document.querySelectorAll<HTMLLinkElement>("link[rel~='icon']");
	if (links.length === 0) {
		createNewFavicon(faviconUrl);
	} else {
		links.forEach(link => {
			link.href = faviconUrl;
		});
	}
};

const createNewFavicon = (faviconUrl: string) => {
	const newLink = document.createElement("link");
	newLink.rel = "icon";
	document.getElementsByTagName("head")[0]!.appendChild(newLink);
	newLink.href = faviconUrl;
};
