// This file is automatically generated. Do not edit manually.

import { t } from "i18next";

export const ConfigurationComponentFieldValueInfoTextVisibilityValues = [
	"CONFIGURATOR_AND_PRINTOUT",
	"PRINTOUT",
] as const;

export type ConfigurationComponentFieldValueInfoTextVisibility =
	(typeof ConfigurationComponentFieldValueInfoTextVisibilityValues)[number];

export const getConfigurationComponentFieldValueInfoTextVisibilityLabels = () => ({
	CONFIGURATOR_AND_PRINTOUT: t("configurator_and_printout"),
	PRINTOUT: t("printout"),
});

export const getConfigurationComponentFieldValueInfoTextVisibilityLabel = (
	value: ConfigurationComponentFieldValueInfoTextVisibility,
): string => {
	return getConfigurationComponentFieldValueInfoTextVisibilityLabels()[value];
};
