import React, { createContext, useMemo } from "react";

export interface PartConfiguratorDocumentContextValue {
	getComponentHasDocument: (componentId: number) => boolean;
	componentIdWithActiveDocument: number | null;
	setComponentIdWithActiveDocument: (componentId: number) => void;
}

export const PartConfiguratorDocumentContext = createContext<
	PartConfiguratorDocumentContextValue | undefined
>(undefined);

export interface PartConfiguratorDocumentContextProviderProps extends React.PropsWithChildren {
	componentsWithDocument: number[];
	componentIdWithActiveDocument: number | null;
	setComponentIdWithActiveDocument: (componentId: number) => void;
}

export const PartConfiguratorDocumentContextProvider = ({
	children,
	componentsWithDocument,
	componentIdWithActiveDocument,
	setComponentIdWithActiveDocument,
}: PartConfiguratorDocumentContextProviderProps) => {
	const contextValue = useMemo(
		() => ({
			getComponentHasDocument: (componentId: number) =>
				componentsWithDocument.includes(componentId),
			componentIdWithActiveDocument,
			setComponentIdWithActiveDocument,
		}),
		[componentsWithDocument, componentIdWithActiveDocument, setComponentIdWithActiveDocument],
	);

	return (
		<PartConfiguratorDocumentContext.Provider value={contextValue}>
			{children}
		</PartConfiguratorDocumentContext.Provider>
	);
};
