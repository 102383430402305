import {
	WorkspacesTreeViewState,
	WorkspacesTreeViewStateAction,
	WorkspaceTreeViewItemModel,
} from "src/components/views/workspaces/treeView/WorkspacesTreeViewState.ts";
import React from "react";
import { WorkspaceCategory } from "src/api/generated/postgres/db/types/workspaces/tables/workspaceCategory.ts";

export interface WorkspacesTreeViewContextValue {
	state: WorkspacesTreeViewState;
	dispatch: (action: WorkspacesTreeViewStateAction) => void;
	refresh: (params?: WorkspacesTreeViewFetchParams) => Promise<void>;
	categoryOptions: WorkspaceCategory[];
	setParentId: (params: SetParentIdParams) => Promise<void>;
	selectedItem: WorkspaceTreeViewItemModel | null;
}

export interface WorkspacesTreeViewFetchParams {
	showArchived?: boolean;
}

export interface SetParentIdParams {
	workspaceId: number;
	parentId: number | null;
}

export const WorkspacesTreeViewContext = React.createContext<WorkspacesTreeViewContextValue | undefined>(undefined);
