// This file is automatically generated. Do not edit manually.

import { ConfiguratorLibrary } from "src/api/generated/erp/db/types/tables/configuratorLibrary";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace ConfiguratorLibrariesApi {
	export async function getConfiguratorLibraries(): Promise<Array<ConfiguratorLibrary>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/configurator/managing/libraries/getConfiguratorLibraries",
			method: "POST",
			data: {},
		});
		return response as Array<ConfiguratorLibrary>;
	}

	export async function insert(args: { library: ConfiguratorLibrary }): Promise<number> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/configurator/managing/libraries/insert",
			method: "POST",
			data: {
				library: args.library,
			},
		});
		return response as number;
	}

	export async function update(args: { library: ConfiguratorLibrary }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/configurator/managing/libraries/update",
			method: "POST",
			data: {
				library: args.library,
			},
		});
		return response as void;
	}

	export async function delete_(args: { libraryId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/configurator/managing/libraries/delete",
			method: "POST",
			data: {
				libraryId: args.libraryId,
			},
		});
		return response as void;
	}
}
