import { PartialTenantCustomizations } from "src/tenantCustomizations/TenantCustomizations";
import { getInventoryLevelEventQuantitySpcSamplingMethod } from "src/components/views/erp/spcSamplingMethods/InventoryLevelEventQuantitySpcSamplingMethod.tsx";
import { FeeliaPartsDemandAvgDownloadCsvButton } from "src/tenantCustomizations/tenants/feelia/FeeliaPartsDemandAvgDownloadCsvButton.tsx";
import { FeeliaLeanSystemInventoryLevelEventImportButton } from "src/tenantCustomizations/tenants/feelia/FeeliaLeanSystemInventoryLevelEventImportButton.tsx";

export const feeliaCustomizations: PartialTenantCustomizations = {
	tenantConfig: {
		erp: {
			enabled: true,
			salesEnabled: false,
			projectEnabled: false,
			purchaseEnabled: false,
			manufacturingEnabled: false,
		},
		documentsEnabled: false,
	},
	spcSamplingMethods: () => [getInventoryLevelEventQuantitySpcSamplingMethod()],
	erp: {
		partManagement: {
			getPartDemandControlChartDefaultValues: (part) => {
				return {
					name: part.partLongName,
					externalId: part.partNo,
					subgroupSize: 1,
					shownPointCount: 60,
					chartType: "I_MR",
					calendarId: 1,
					sampleRateMs: 1000 * 60 * 60 * 24,
					xDescription: "Ajankohta",
					yDescription: "Laatikkomäärä",
					valueDescription: "me",
					processRole: "X",
					specialCauseEmailsEnabled: true,
					disableDuplicateSpecialCauses: true,
					enabled: true,
					dataInputMethod: "DEFAULT_",
				};
			},
			sitePartsActionBarMenuComponents: (props) => [
				<FeeliaPartsDemandAvgDownloadCsvButton {...props} />,
				<FeeliaLeanSystemInventoryLevelEventImportButton />,
			],
		},
	},
} as const;
