import { getCookie } from "typescript-cookie";
import { isSupportedLanguage, SupportedLanguage } from "../types/languages";

export const AAVO_LANGUAGE_COOKIE_ITEM = "aavo_lang";

// Cookies max expiration time is 400 days
// Every time Aavo is used, language cookie is refreshed
// => language setting is forgotten only if user does not use Aavo within 400 days.
export const AAVO_LANGUAGE_COOKIE_EXPIRATION_MINUTES: number = 60 * 24 * 400;
export const getAavoLangCookie = (): SupportedLanguage | undefined => {
	const cookieValue = getCookie(AAVO_LANGUAGE_COOKIE_ITEM);
	return isSupportedLanguage(cookieValue) ? cookieValue : undefined;
};
