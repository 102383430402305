import { HorizontalBox } from "src/components/common/box/HorizontalBox.tsx";
import { BoxProps } from "@mui/material";
import { mergeSx } from "src/utils/styles.ts";

export interface AavoFormFooterProps extends Omit<BoxProps, "ref"> {}

export const AavoFormFooter = ({ sx, children, ...other }: AavoFormFooterProps) => {
	return (
		<HorizontalBox
			sx={mergeSx(
				{
					backgroundColor: "grey.200",
					minHeight: "3rem",
					justifyContent: "flex-end",
					"& .MuiTypography-button": {
						textAlign: "center",
					},
				},
				sx,
			)}
			{...other}
		>
			{children}
		</HorizontalBox>
	);
};
