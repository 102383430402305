import i18n from "i18next";
import {
	PartReorderPointCalculationForm,
	PartReorderPointCalculationFormProps,
	FormResult,
} from "src/components/views/erp/parts/siteParts/PartReorderPointCalculationForm.tsx";
import { createFormDialogFunction } from "src/components/common/dialogs/formDialog/createFormDialogFunction.ts";

export const openPartReorderPointCalculationForm = createFormDialogFunction<
	PartReorderPointCalculationFormProps,
	FormResult
>({
	title: i18n.t("calculate_reorder_point"),
	size: "sm",
	component: PartReorderPointCalculationForm,
});
