// This file is automatically generated. Do not edit manually.

import { FrontendDownloadFileHandle } from "src/api/generated/common/frontendDownloadFileHandle";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace WarehouseTransferActionApi {
	export async function printWarehouseTransferPickingList(): Promise<FrontendDownloadFileHandle> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/warehouse/warehouseTransfer/printWarehouseTransferPickingList",
			method: "POST",
			data: {},
		});
		return response as FrontendDownloadFileHandle;
	}

	export async function cancelWarehouseTransfers(args: { warehouseTransferIds: Array<number> }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/warehouse/warehouseTransfer/cancelWarehouseTransfers",
			method: "POST",
			data: {
				warehouseTransferIds: args.warehouseTransferIds,
			},
		});
		return response as void;
	}

	export async function reserveWarehouseTransfers(args: { warehouseTransferIds: Array<number> }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/warehouse/warehouseTransfer/reserveWarehouseTransfers",
			method: "POST",
			data: {
				warehouseTransferIds: args.warehouseTransferIds,
			},
		});
		return response as void;
	}

	export async function revertReserveSelectedTransfers(args: { warehouseTransferIds: Array<number> }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/warehouse/warehouseTransfer/revertReserveSelectedTransfers",
			method: "POST",
			data: {
				warehouseTransferIds: args.warehouseTransferIds,
			},
		});
		return response as void;
	}

	export async function pickSelectedTransfers(args: { warehouseTransferIds: Array<number> }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/warehouse/warehouseTransfer/pickSelectedTransfers",
			method: "POST",
			data: {
				warehouseTransferIds: args.warehouseTransferIds,
			},
		});
		return response as void;
	}

	export async function revertPickSelectedTransfers(args: { warehouseTransferIds: Array<number> }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/warehouse/warehouseTransfer/revertPickSelectedTransfers",
			method: "POST",
			data: {
				warehouseTransferIds: args.warehouseTransferIds,
			},
		});
		return response as void;
	}

	export async function transferSelectedTransfers(args: { warehouseTransferIds: Array<number> }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/warehouse/warehouseTransfer/transferSelectedTransfers",
			method: "POST",
			data: {
				warehouseTransferIds: args.warehouseTransferIds,
			},
		});
		return response as void;
	}

	export async function revertTransferSelectedTransfers(args: {
		warehouseTransferIds: Array<number>;
	}): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/warehouse/warehouseTransfer/revertTransferSelectedTransfers",
			method: "POST",
			data: {
				warehouseTransferIds: args.warehouseTransferIds,
			},
		});
		return response as void;
	}

	export async function transferWithoutPurchasingSelectedTransfers(args: {
		warehouseTransferIds: Array<number>;
	}): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/warehouse/warehouseTransfer/transferWithoutPurchasingSelectedTransfers",
			method: "POST",
			data: {
				warehouseTransferIds: args.warehouseTransferIds,
			},
		});
		return response as void;
	}

	export async function pickAndTransferSelectedTransfers(args: {
		warehouseTransferIds: Array<number>;
	}): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/warehouse/warehouseTransfer/pickAndTransferSelectedTransfers",
			method: "POST",
			data: {
				warehouseTransferIds: args.warehouseTransferIds,
			},
		});
		return response as void;
	}

	export async function pickAndTransferWithoutPurchasingSelectedTransfers(args: {
		warehouseTransferIds: Array<number>;
	}): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/warehouse/warehouseTransfer/pickAndTransferWithoutPurchasingSelectedTransfers",
			method: "POST",
			data: {
				warehouseTransferIds: args.warehouseTransferIds,
			},
		});
		return response as void;
	}
}
