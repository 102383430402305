// This file is automatically generated. Do not edit manually.

import { ProductionLine } from "src/api/generated/erp/db/types/tables/productionLine";
import { Operation } from "src/api/generated/erp/db/types/tables/operation";
import { ProductionLineGroup } from "src/api/generated/erp/db/types/tables/productionLineGroup";
import { TaktTimeView } from "src/api/generated/postgres/db/types/public_/tables/taktTimeView";
import { ErpUnit } from "src/api/generated/erp/db/types/tables/erpUnit";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace ProductionLineEditApi {
	export async function getFormInitData(args: {
		productionLineGroupId: number;
		productionLineId: number | null | undefined;
	}): Promise<ProductionLineEditApi_FormInitData> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/productionLines/getFormInitData",
			method: "POST",
			data: {
				productionLineGroupId: args.productionLineGroupId,
				productionLineId: args.productionLineId,
			},
		});
		return response as ProductionLineEditApi_FormInitData;
	}

	export async function insert(args: { productionLine: ProductionLine }): Promise<number> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/productionLines/insert",
			method: "POST",
			data: {
				productionLine: args.productionLine,
			},
		});
		return response as number;
	}

	export async function update(args: { productionLine: ProductionLine }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/productionLines/update",
			method: "POST",
			data: {
				productionLine: args.productionLine,
			},
		});
		return response as void;
	}

	export async function delete_(args: { productionLineId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/productionLines/delete",
			method: "POST",
			data: {
				productionLineId: args.productionLineId,
			},
		});
		return response as void;
	}

	export async function setProductionLineActivity(args: {
		productionLineId: number;
		isActive: boolean;
	}): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/productionLines/setProductionLineActivity",
			method: "POST",
			data: {
				productionLineId: args.productionLineId,
				isActive: args.isActive,
			},
		});
		return response as void;
	}
}

export interface ProductionLineEditApi_FormInitData {
	operationOptions: Array<Operation>;
	productionLine: ProductionLine | null | undefined;
	productionLineGroup: ProductionLineGroup;
	productionLineGroupOptions: Array<ProductionLineGroup>;
	taktTimeViewOptions: Array<TaktTimeView>;
	unitOptions: Array<ErpUnit>;
}
