import { useEffect, useRef } from "react";
import PDFObject from "pdfobject";
import { Box } from "@mui/system";

export const PdfView = ({ fileUrl }: { fileUrl: string }) => {
	const containerRef = useRef<HTMLDivElement>();

	useEffect(() => {
		if (containerRef.current) {
			containerRef.current.innerHTML = "";
			PDFObject.embed(fileUrl, containerRef.current, {
				PDFJS_URL: `/static/pdfjs/web/viewer.html`,
			});
		}
	}, [fileUrl]);

	return (
		<Box
			ref={containerRef}
			sx={{
				flex: 1,
			}}
		/>
	);
};
