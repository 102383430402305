// This file is automatically generated. Do not edit manually.

import { ConfigurationProductFamilyCategory } from "src/api/generated/erp/db/types/tables/configurationProductFamilyCategory";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace ConfigurationProductFamilyCategoryApi {
	export async function getCategories(): Promise<Array<ConfigurationProductFamilyCategory>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/configurator/managing/productFamilyCategory/getCategories",
			method: "POST",
			data: {},
		});
		return response as Array<ConfigurationProductFamilyCategory>;
	}

	export async function insert(args: { productFamilyCategory: ConfigurationProductFamilyCategory }): Promise<number> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/configurator/managing/productFamilyCategory/insert",
			method: "POST",
			data: {
				productFamilyCategory: args.productFamilyCategory,
			},
		});
		return response as number;
	}

	export async function update(args: { productFamilyCategory: ConfigurationProductFamilyCategory }): Promise<number> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/configurator/managing/productFamilyCategory/update",
			method: "POST",
			data: {
				productFamilyCategory: args.productFamilyCategory,
			},
		});
		return response as number;
	}

	export async function delete_(args: { productFamilyCategoryId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/configurator/managing/productFamilyCategory/delete",
			method: "POST",
			data: {
				productFamilyCategoryId: args.productFamilyCategoryId,
			},
		});
		return response as void;
	}
}
