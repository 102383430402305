// This file is automatically generated. Do not edit manually.

import { IsoDateString } from "src/types/dateTime";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace SubProjectActionsApi {
	export async function releaseSubProject(args: {
		subProjectId: number;
		releaseProjectActivities: boolean;
		releaseTasks: boolean;
	}): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/project/subProject/releaseSubProject",
			method: "POST",
			data: {
				subProjectId: args.subProjectId,
				releaseProjectActivities: args.releaseProjectActivities,
				releaseTasks: args.releaseTasks,
			},
		});
		return response as void;
	}

	export async function revertReleaseSubProject(args: { subProjectId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/project/subProject/revertReleaseSubProject",
			method: "POST",
			data: {
				subProjectId: args.subProjectId,
			},
		});
		return response as void;
	}

	export async function completeSubProject(args: { subProjectId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/project/subProject/completeSubProject",
			method: "POST",
			data: {
				subProjectId: args.subProjectId,
			},
		});
		return response as void;
	}

	export async function revertCompleteSubProject(args: { subProjectId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/project/subProject/revertCompleteSubProject",
			method: "POST",
			data: {
				subProjectId: args.subProjectId,
			},
		});
		return response as void;
	}

	export async function cancelSubProject(args: { subProjectId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/project/subProject/cancelSubProject",
			method: "POST",
			data: {
				subProjectId: args.subProjectId,
			},
		});
		return response as void;
	}

	export async function cloneSubProject(args: {
		subProjectId: number;
		targetProjectId: number;
		newPlannedStartDate: IsoDateString | null | undefined;
	}): Promise<number> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/project/subProject/cloneSubProject",
			method: "POST",
			data: {
				subProjectId: args.subProjectId,
				targetProjectId: args.targetProjectId,
				newPlannedStartDate: args.newPlannedStartDate,
			},
		});
		return response as number;
	}

	export async function startSubProject(args: { subProjectId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/project/subProject/startSubProject",
			method: "POST",
			data: {
				subProjectId: args.subProjectId,
			},
		});
		return response as void;
	}
}
