// This file is automatically generated. Do not edit manually.

import { CatalogPart } from "src/api/generated/erp/db/types/tables/catalogPart";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace CatalogPartApi {
	export async function getCatalogPartOptions(args: {
		searchQuery?: string;
		currentSelection: number | null | undefined;
		onlyIfConfigurable?: boolean;
		configurationProductFamilyId?: number | null | undefined;
	}): Promise<Array<CatalogPart>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/productManagement/catalogPart/getCatalogPartOptions",
			method: "POST",
			data: {
				searchQuery: args.searchQuery,
				currentSelection: args.currentSelection,
				onlyIfConfigurable: args.onlyIfConfigurable,
				configurationProductFamilyId: args.configurationProductFamilyId,
			},
		});
		return response as Array<CatalogPart>;
	}
}
