import {DocumentsOfObjectDataGridApi} from "src/api/generated/documents/api/documentsOfObjectDataGridApi.ts";
import {
    EditDocumentWithWopiButtonMenuItem
} from "src/components/views/documents/wopi/EditDocumentWithWopiButtonMenuItem.tsx";
import {AsyncMenuButton} from "src/components/common/contextMenu/AsyncMenuButton.tsx";
import {
    faCheck,
    faExternalLink,
    faFilePlus,
    faMapPin,
    faUndo,
    faUpload,
} from "@fortawesome/pro-regular-svg-icons";
import {AavoDataGridRowContextMenuParams} from "src/components/common/dataGrid/AavoDataGrid.tsx";
import i18n from "i18next";
import {uploadFile} from "src/utils/fileUploading.ts";
import {DocumentUpdateApi} from "src/api/generated/documents/api/documentUpdateApi.ts";
import {AavoAsyncFileInputButton} from "src/components/common/buttons/AavoAsyncFileInputButton.tsx";
import {AavoMenuItem} from "src/components/common/contextMenu/AavoMenuItem.tsx";
import {DocumentRevisionApi} from "src/api/generated/documents/api/documentRevisionApi.ts";
import {DocumentRevisionsView} from "src/components/views/documents/revisions/DocumentRevisionsView.tsx";
import {SendDocumentsView} from "src/components/views/documents/mailing/SendDocumentsView.tsx";
import {useTenantCustomizations} from "src/tenantCustomizations/useTenantCustomizations.tsx";
import {GenericDialogContext} from "src/components/common/dialogs/GenericDialogProvider.tsx";
import {DocumentConnectionView} from "src/api/generated/postgres/db/types/documents/tables/documentConnectionView.ts";
import {useContextOrThrow} from "src/utils/useContextOrThrow.tsx";
import {AavoObjectRef} from "src/api/generated/common/sourceType/aavoObjectRef.ts";
import {faEnvelope, faFile} from "@fortawesome/pro-regular-svg-icons";
import {getOnlyMember} from "src/utils/arrayUtils.ts";
import {AavoContextMenuClosingContext} from "src/components/common/contextMenu/AavoContextMenuClosingContext.ts";
import {DocumentFileApi} from "src/api/generated/documents/api/documentFileApi.ts";
import {DocumentsViewEditable} from "../types.ts";

export interface DocumentsOfObjectsDataGridContextMenuProps
    extends AavoDataGridRowContextMenuParams<DocumentConnectionView> {
    objectRefs: AavoObjectRef[];
    editable: DocumentsViewEditable
    refresh: () => Promise<unknown>;
}

export const DocumentsOfObjectsDataGridContextMenu = (props: DocumentsOfObjectsDataGridContextMenuProps) => {
    const {row, allSelectedRows, onlySingleRowSelected, editable, refresh, objectRefs} = props;

    const onlyObjectRef = getOnlyMember(objectRefs);

    const {closeContextMenu} = useContextOrThrow(AavoContextMenuClosingContext);
    const {openDialog} = useContextOrThrow(GenericDialogContext);
    const tenantCustomizations = useTenantCustomizations();

    return [
        (editable === true || editable === "onlyOfficeForExistingDocuments") && onlySingleRowSelected && (
            <EditDocumentWithWopiButtonMenuItem
                key={"edit_with_wopi"}
                fileExtension={row.primaryFileExtension ?? undefined}
                fileUuid={row.primaryFileUuid ?? undefined}
            />
        ),
        <AsyncMenuButton
            key={"openAllInNewTabs"}
            label={i18n.t("open_in_new_tab")}
            icon={faExternalLink}
            onClick={async () => {
                const fileReferences = await Promise.all(
                    allSelectedRows.map((row) =>
                        DocumentFileApi.getDocumentFileReference({
                            documentId: row.documentId,
                            documentRevisionId: row.documentRevisionId,
                        }),
                    ),
                );
                for (const fileRef of fileReferences) {
                    if (fileRef != null && fileRef.type === "file") {
                        window.open(fileRef.fileHandle.fileUrl);
                    }
                }
            }}
        />,
        onlySingleRowSelected && (
            <AsyncMenuButton
                key={"revisions"}
                label={i18n.t("revisions")}
                icon={faFile}
                onClick={async () => {
                    openDialog(() => ({
                        title: `${i18n.t("revisions")} (${row.description1})`,
                        content: <DocumentRevisionsView editable={editable} document={row}/>,
                    }));
                }}
            />
        ),
        editable === true &&
        onlySingleRowSelected &&
        (row.isPinned ?
            <AsyncMenuButton
                key={"unpin"}
                label={i18n.t("unpin")}
                icon={faUndo}
                onClick={async () => {
                    await DocumentsOfObjectDataGridApi.setDocumentConnectionIsPinned({
                        documentConnectionId: row.documentConnectionId,
                        isPinned: false,
                    });
                    await refresh();
                }}
            />
            : <AsyncMenuButton
                key={"pin"}
                label={i18n.t("pin")}
                icon={faMapPin}
                onClick={async () => {
                    await DocumentsOfObjectDataGridApi.setDocumentConnectionIsPinned({
                        documentConnectionId: row.documentConnectionId,
                        isPinned: true,
                    });
                    await refresh();
                }}
            />),
        editable === true && onlySingleRowSelected && onlyObjectRef && (
            <AsyncMenuButton
                key={"set_as_main_document"}
                label={i18n.t("set_as_main_document")}
                icon={faCheck}
                onClick={async () => {
                    await DocumentsOfObjectDataGridApi.setDocumentToMainDocumentOfObject({
                        objectRef: onlyObjectRef,
                        documentId: row.documentId,
                    });
                    await refresh();
                }}
            />
        ),
        editable === true && (
            <AavoMenuItem key={"replace_current_revision_file"}>
                <AavoAsyncFileInputButton
                    label={i18n.t("replace_current_revision_file")}
                    multiple={false}
                    icon={faUpload}
                    color={"black"}
                    onSubmit={async (file: File) => {
                        const fileHandle = await uploadFile(file);
                        await DocumentUpdateApi.updateDocumentActiveRevisionFile({
                            documentId: row.documentId,
                            newFile: fileHandle,
                        });
                        closeContextMenu();
                        await refresh();
                    }}
                />
            </AavoMenuItem>
        ),
        editable === true && (
            <AavoMenuItem key={"create_a_new_revision"}>
                <AavoAsyncFileInputButton
                    label={i18n.t("create_a_new_revision")}
                    multiple={false}
                    icon={faFilePlus}
                    color={"black"}
                    onSubmit={async (file: File) => {
                        const fileHandle = await uploadFile(file);
                        await DocumentRevisionApi.createDocumentRevision({
                            documentId: row.documentId,
                            file: fileHandle,
                        });
                        closeContextMenu();
                        await refresh();
                    }}
                />
            </AavoMenuItem>
        ),
        <AsyncMenuButton
            key={"send_selected_documents"}
            label={i18n.t("send_selected_documents")}
            icon={faEnvelope}
            onClick={async () => {
                openDialog(({closeDialog}) => ({
                    title: i18n.t("send_documents"),
                    content: (
                        <SendDocumentsView
                            documents={allSelectedRows}
                            onCompletedSuccessfully={() => {
                                closeDialog();
                            }}
                        />
                    ),
                }));
            }}
        />,
        tenantCustomizations.documentsOfObjectDataGridContextMenuComponents?.(props),
    ];
};
