// This file is automatically generated. Do not edit manually.

import { OmegakeittiotMachineToolType } from "src/api/generated/tenants/omegakeittiot/machineTools/model/omegakeittiotMachineToolType";
import { OmegakeittiotMachineToolInstructionLine } from "src/api/generated/tenants/omegakeittiot/machineTools/model/omegakeittiotMachineToolInstructionLine";
import { FrontendDownloadFileHandle } from "src/api/generated/common/frontendDownloadFileHandle";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace OmegakeittiotMachineToolsApi {
	export async function getMachineToolInstructionViewData(args: {
		shopOrderBatchId: number;
		machineType: OmegakeittiotMachineToolType;
		searchQuery?: string;
	}): Promise<Array<OmegakeittiotMachineToolInstructionLine>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/omegakeittiot/machineTools/getMachineToolInstructionViewData",
			method: "POST",
			data: {
				shopOrderBatchId: args.shopOrderBatchId,
				machineType: args.machineType,
				searchQuery: args.searchQuery,
			},
		});
		return response as Array<OmegakeittiotMachineToolInstructionLine>;
	}

	export async function generateInstructionFile(args: {
		shopOrderBatchId: number;
		machineType: OmegakeittiotMachineToolType;
	}): Promise<FrontendDownloadFileHandle> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/omegakeittiot/machineTools/generateInstructionFile",
			method: "POST",
			data: {
				shopOrderBatchId: args.shopOrderBatchId,
				machineType: args.machineType,
			},
		});
		return response as FrontendDownloadFileHandle;
	}
}
