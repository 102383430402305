import i18n from "i18next";
import { faClipboardCheck } from "@fortawesome/pro-regular-svg-icons";
import { AsyncButton, AsyncButtonProps } from "src/components/common/buttons/AsyncButton.tsx";
import { useGenericDialog } from "src/components/common/dialogs/useGenericDialog.ts";
import { SitePartsPage } from "src/components/views/erp/parts/siteParts/SitePartsPage.tsx";

export interface OpenSitePartButtonProps extends Omit<AsyncButtonProps, "onClick"> {
	partId: number;
}

export const OpenSitePartButton = ({ partId, variant = "menu", ...props }: OpenSitePartButtonProps) => {
	const { openDialog } = useGenericDialog();

	return (
		<AsyncButton
			label={i18n.t("open_part")}
			icon={faClipboardCheck}
			variant={variant}
			onClick={() => {
				openDialog({
					title: i18n.t("part"),
					content: <SitePartsPage sitePartId={partId} />,
				});
			}}
			{...props}
		/>
	);
};
