// This file is automatically generated. Do not edit manually.

import { t } from "i18next";

export const CustomerOrderOfferStateValues = [
	"INITIAL",
	"OFFERED",
	"DISCARDED",
	"LOST",
	"CUSTOMER_ORDER_CREATED",
	"CANCELLED",
] as const;

export type CustomerOrderOfferState = (typeof CustomerOrderOfferStateValues)[number];

export const getCustomerOrderOfferStateLabels = () => ({
	INITIAL: t("initial"),
	OFFERED: t("offered"),
	DISCARDED: t("discarded"),
	LOST: t("lost"),
	CUSTOMER_ORDER_CREATED: t("customer_order_created"),
	CANCELLED: t("cancelled"),
});

export const getCustomerOrderOfferStateLabel = (value: CustomerOrderOfferState): string => {
	return getCustomerOrderOfferStateLabels()[value];
};
