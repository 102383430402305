import { VerticalBox } from "src/components/common/box/VerticalBox";
import { MapView } from "src/components/common/mapbox/MapView";
import mapboxgl from "mapbox-gl";
import React, { ForwardedRef, forwardRef, MutableRefObject, useRef } from "react";
import { AavoButton } from "src/components/common/buttons/AavoButton";
import { faArrowPointer, faRoute } from "@fortawesome/pro-regular-svg-icons";
import i18n from "i18next";
import { removeKeyFromObject } from "src/utils/objectUtils.ts";
import { ReclamationWithCoordinates } from "src/api/generated/erp/reclamations/api/reclamationWithCoordinates.ts";
import { useForwardedRef } from "src/utils/useForwardedRef.ts";
import { fitMapToCoords } from "src/components/common/mapbox/mapboxUtils.ts";
import { AsyncButton } from "src/components/common/buttons/AsyncButton";
import { showRouteOnMap } from "src/components/common/mapbox/routes.ts";
import { useTheme } from "@mui/material";
import { Waypoint } from "@mapbox/mapbox-sdk/services/directions";
import { useResizeObserver } from "src/utils/useResizeObserver.ts";
import { useRerender } from "src/utils/useRerender.ts";
import { Coordinates } from "src/api/generated/common/address/coordinates.ts";
import { Theme } from "@mui/material/styles";
import { getReclamationCustomerOrderLabel } from "src/components/views/erp/reclamation/reclamationUtils.ts";
import { AavoActionBar } from "src/components/common/actionBar/AavoActionBar";

export interface ReclamationPlanningMapViewProps {
	onLoaded: (api: ReclamationPlanningMapViewApi) => Promise<unknown> | void;
	actionBarComponents?: React.ReactNode;
	startLocationCoords: Coordinates;
	setStartLocationCoords: (coords: Coordinates) => void;
}

export interface ReclamationPlanningMapViewApi {
	addMarker: (reclamation: ReclamationWithCoordinates) => void;
	removeMarker: (reclamationId: number) => void;
	fitToMarkers: () => void;
	showRoute: () => void;
}

const routeSourceName = "route";

interface Model
	extends Pick<ReclamationPlanningMapViewProps, "startLocationCoords" | "setStartLocationCoords"> {
	map: mapboxgl.Map | null;
	theme: Theme;
	markersByReclamationIdsRef: MutableRefObject<Record<number, mapboxgl.Marker>>;
}

export const ReclamationPlanningMapView = forwardRef(
	(
		{
			onLoaded,
			actionBarComponents,
			startLocationCoords,
			setStartLocationCoords,
		}: ReclamationPlanningMapViewProps,
		ref: ForwardedRef<ReclamationPlanningMapViewApi>,
	) => {
		const thisRef = useForwardedRef(ref);
		const containerRef = useRef<HTMLDivElement>(null);
		const mapRef = useRef<mapboxgl.Map>(null);
		const theme = useTheme();
		const rerender = useRerender();
		const markersByReclamationIds = useRef<Record<number, mapboxgl.Marker>>({});

		const getModel = (map: mapboxgl.Map | null = mapRef.current): Model => {
			return {
				map: map,
				theme: theme,
				markersByReclamationIdsRef: markersByReclamationIds,
				startLocationCoords: startLocationCoords,
				setStartLocationCoords: setStartLocationCoords,
			};
		};

		useResizeObserver(containerRef, () => {
			mapRef.current?.resize();
		}, [mapRef.current]);

		const getThisApi = (map: mapboxgl.Map | null): ReclamationPlanningMapViewApi => {
			const model = getModel(map);
			return {
				addMarker: (reclamation) => addReclamationMarker(model, reclamation),
				removeMarker: (reclamationId) => removeMarker(model, reclamationId),
				fitToMarkers: () => fitMapToMarkers(model),
				showRoute: () => showRoute(model),
			};
		};
		thisRef.current = getThisApi(mapRef.current);

		return (
			<VerticalBox
				ref={containerRef}
				sx={{
					flex: 1,
					alignItems: "stretch",
				}}
			>
				<AavoActionBar>
					<AavoButton
						icon={faArrowPointer}
						label={i18n.t("focus")}
						variant={"outlined"}
						onClick={() => {
							fitMapToMarkers(getModel());
						}}
					/>
					<AsyncButton
						icon={faRoute}
						label={i18n.t("route")}
						variant={"outlined"}
						disabled={getAllMarkerCoords(getModel()).length < 2}
						onClick={async () => {
							await showRoute(getModel());
						}}
					/>
					{actionBarComponents}
				</AavoActionBar>
				<MapView
					ref={mapRef}
					onLoaded={async (map) => {
						const model = getModel(map);
						await onLoaded?.(getThisApi(map));
						createStartLocationMarker(model);
						rerender();
					}}
				/>
			</VerticalBox>
		);
	},
);

const addReclamationMarker = (model: Model, reclamation: ReclamationWithCoordinates) => {
	const { map, theme, markersByReclamationIdsRef } = model;
	if (!map || reclamation.addressCoords == undefined) return;

	const popupHtml = `
	  <b>${reclamation.title}</b><br/>
	  ${getReclamationCustomerOrderLabel(reclamation)}<br/>
	  ${reclamation.address.city}
	`;

	const marker = new mapboxgl.Marker({
		color: theme.palette.primary.main,
	})
		.setPopup(new mapboxgl.Popup().setHTML(popupHtml))
		.setLngLat(reclamation.addressCoords)
		.addTo(map);

	markersByReclamationIdsRef.current = {
		...markersByReclamationIdsRef.current,
		[reclamation.reclamationId]: marker,
	};
	clearRoute(model);
};

const removeMarker = (model: Model, reclamationId: number) => {
	const { markersByReclamationIdsRef } = model;
	const marker = markersByReclamationIdsRef.current[reclamationId];
	if (!marker) return;

	markersByReclamationIdsRef.current = removeKeyFromObject(
		markersByReclamationIdsRef.current,
		reclamationId,
	);
	marker.remove();
	clearRoute(model);
};

const clearRoute = ({ map }: Model) => {
	if (!map) return;
	if (map.getLayer(routeSourceName)) map.removeLayer(routeSourceName);
	if (map.getSource(routeSourceName)) map.removeSource(routeSourceName);
};

const showRoute = async (model: Model) => {
	const { map, theme } = model;
	if (!map) return;

	const waypoints: Waypoint[] = getAllMarkerCoords(model).map((coords) => ({
		coordinates: [coords.lng, coords.lat],
	}));
	await showRouteOnMap({
		map: map,
		waypoints: waypoints,
		routeId: routeSourceName,
		lineColor: theme.palette.grey[700],
	});
};

const fitMapToMarkers = (model: Model) => {
	const { map } = model;
	if (!map) return;
	fitMapToCoords(map, getAllMarkerCoords(model), { animate: false });
};

const createStartLocationMarker = (model: Model) => {
	const { map, theme, startLocationCoords, setStartLocationCoords } = model;
	if (!map) return;

	const startLocationMarker = new mapboxgl.Marker({
		color: theme.palette.secondary.main,
		draggable: true,
	})
		.setLngLat(startLocationCoords)
		.setPopup(new mapboxgl.Popup().setHTML(`<b>${i18n.t("start_location")}</b>`).addTo(map))
		.addTo(map);

	startLocationMarker.on("dragend", () => {
		const newCoords = startLocationMarker.getLngLat();
		clearRoute(model);
		setStartLocationCoords({ lng: newCoords.lng, lat: newCoords.lat });
	});
};

const getAllMarkerCoords = ({ startLocationCoords, markersByReclamationIdsRef }: Model): Coordinates[] => {
	const reclamationMarkerCoords = Object.values(markersByReclamationIdsRef.current).map((marker) =>
		marker.getLngLat(),
	);
	return [startLocationCoords, ...reclamationMarkerCoords];
};
