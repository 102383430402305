import i18n from "i18next";
import {CompanyApi} from "src/api/generated/erp/common/companies/companyApi";
import {CompanyForm} from "./CompanyForm";
import {floatColumn, integerColumn, textColumn} from "../../../../common/dataGrid/columns";
import {AsyncCrudDataGrid} from "src/components/common/dataGrid/crud/AsyncCrudDataGrid";
import {dataGridPersistentStateProps} from "src/components/common/dataGrid/dataGridStateStorage";
import {CompanyView} from "src/api/generated/erp/db/types/tables/companyView.ts";

export const CompaniesDataGrid = () => {
	return (
		<AsyncCrudDataGrid<CompanyView>
			fetchData={CompanyApi.getAll}
			remove={{
				type: "enabled",
				action: async (params) => await CompanyApi.delete_({ companies: params.items }),
			}}
			columns={[
				integerColumn({
					headerName: i18n.t("number_shortened"),
					field: "companyId",
				}),
				textColumn({
					headerName: i18n.t("company"),
					field: "companyName",
				}),
				textColumn({
					headerName: i18n.t("business_id"),
					field: "businessId",
				}),
				textColumn({
					headerName: i18n.t("invoice_address"),
					field: "invoiceAddress",
				}),
				textColumn({
					headerName: i18n.t("e_invoice_id"),
					field: "einvoiceId",
				}),
				textColumn({
					headerName: i18n.t("e_invoice_operator"),
					field: "eInvoiceOperatorName",
				}),
				textColumn({
					headerName: i18n.t("additional_info", { number: 1 }),
					field: "printoutText_1",
				}),
				textColumn({
					headerName: i18n.t("additional_info", { number: 2 }),
					field: "printoutText_2",
				}),
				textColumn({
					headerName: i18n.t("additional_info", { number: 3 }),
					field: "printoutText_3",
				}),
				textColumn({
					headerName: i18n.t("additional_info", { number: 4 }),
					field: "printoutText_4",
				}),
				textColumn({
					headerName: i18n.t("currency"),
					field: "currency",
				}),
				floatColumn({
					headerName: i18n.t("invoice_penalty_interest_%"),
					field: "invoicePenaltyInterest",
				}),
				integerColumn({
					headerName: i18n.t("invoice_remind_days"),
					field: "invoiceRemindDays",
				}),
				textColumn({
					headerName: i18n.t("bank_account_number"),
					field: "bankAccountNumber",
				}),
			]}
			getRowId={(row) => {
				return row.companyId;
			}}
			form={{
				dialogSize: "md",
				dialogTitle: i18n.t("company"),
				component: ({ row, onCompleted, onFormEdited }) => {
					return (
						<CompanyForm
							companyId={row?.companyId}
							onCompleted={(result) => {
								onCompleted(result);
							}}
							onFormEdited={onFormEdited}
						/>
					);
				},
				addRowEnabled: true,
				editEnabled: true,
			}}
			{...dataGridPersistentStateProps("DECAFEF12E28D9B71")}
		/>
	);
};
