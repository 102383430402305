import { ConfigurationComponent } from "src/api/generated/erp/db/types/tables/configurationComponent.ts";
import { Divider } from "@mui/material";

export interface SectionBreakComponentPreviewProps {
	component: ConfigurationComponent;
}

export const SectionBreakComponentPreview = ({ component }: SectionBreakComponentPreviewProps) => {
	return (
		<Divider
			textAlign="left"
			orientation="horizontal"
			variant="fullWidth"
			sx={{
				flex: 1,
			}}
		>
			{component.label}
		</Divider>
	);
};
