import { SxProps } from "@mui/system";
import { Theme } from "@mui/material/styles";

export const mergeSx = (
	...sxProps: ReadonlyArray<Readonly<SxProps<Theme>> | undefined>
): SxProps<Theme> => {
	const asSxArray = sxProps.flatMap((sxProp) => {
		if (sxProp === undefined) return [];
		if (Array.isArray(sxProp)) return sxProp;

		return [sxProp];
	});
	return asSxArray as SxProps<Theme>;
};
