import React, { PropsWithChildren, useState } from "react";
import { Typography } from "@mui/material";
import { AavoButton } from "src/components/common/buttons/AavoButton.tsx";
import i18n from "i18next";
import { AavoDialog } from "../AavoDialog";

export const MessageDialogContext = React.createContext<ShowMessageDialogFunc | undefined>(undefined);

export type ShowMessageDialogFunc = (params: MessageDialogParams) => void;

export interface MessageDialogParams {
	title?: string;
	content: React.ReactNode;
}

export const MessageDialogProvider = ({ children }: PropsWithChildren) => {
	const [messageParams, setMessageParams] = useState<MessageDialogParams | undefined>(undefined);
	return (
		<>
			<MessageDialogContext.Provider value={setMessageParams}>{children}</MessageDialogContext.Provider>
			{messageParams && (
				<AavoDialog
					size={"sm"}
					title={messageParams.title ?? ""}
					onClose={() => {
						setMessageParams(undefined);
					}}
					actions={
						<AavoButton
							onClick={() => {
								setMessageParams(undefined);
							}}
							label={i18n.t("ok")}
						/>
					}
				>
					{typeof messageParams.content === "string" ?
						<Typography
							sx={{
								marginY: 1,
								marginX: 2,
							}}
						>
							{messageParams.content}
						</Typography>
					:	messageParams.content}
				</AavoDialog>
			)}
		</>
	);
};
