import { FormCommonProps } from "src/components/common/forms/types.ts";
import { AsyncForm, AsyncFormContentParams } from "src/components/common/forms/AsyncForm.tsx";
import { FormSelectField } from "src/components/common/forms/fields/FormSelectField.tsx";
import i18n from "i18next";
import { FormNumberField } from "src/components/common/forms/fields/FormNumberField.tsx";
import { FormTextField } from "src/components/common/forms/fields/FormTextField.tsx";
import { FormCheckbox } from "src/components/common/forms/fields/FormCheckbox.tsx";
import { requiredNonNegativeFloatRule, requireRule } from "src/components/common/forms/validation.ts";
import { downloadFile } from "src/utils/fileDownloading.ts";
import {
	ShopOrderCompletionFormApi,
	ShopOrderCompletionFormApi_FormInitData,
} from "src/api/generated/erp/production/jobQueues/api/shopOrderCompletionFormApi.ts";
import { CenteredTypography } from "src/components/common/CenteredTypography.tsx";
import { faCalculator } from "@fortawesome/pro-regular-svg-icons";
import { useStoredState } from "src/utils/useStoredState.ts";
import { genericNullableValue } from "src/utils/genericNullableValue.ts";
import { AavoButton } from "src/components/common/buttons/AavoButton.tsx";
import { confirmCloseShopOrder } from "src/components/views/erp/production/shopOrder/utils/confirmCloseShopOrder.ts";
import { useConfirmDialog } from "src/components/common/dialogs/confirmDialog/useConfirmDialog.ts";
import { DeliveryPackageApi } from "src/api/generated/erp/delivery/api/deliveryPackageApi.ts";
import { filterNulls } from "src/utils/arrayUtils.ts";

export interface ShopOrderOperationCompletionFormProps extends FormCommonProps<void> {
	shopOrderId: number;
	shopOrderOperationIdToComplete?: number;
}

interface FormValues {
	quantityToCompleteInBaseUnit: number;
	completeOperation: boolean;
	closeShopOrder: boolean;
	customerOrderLinePackage: {
		deliveryPackageTypeId: number;
		packageDescription: string;
		length: number;
		width: number;
		height: number;
		weight: number;
		packageWarehouseLocationId: number;
		printPackageLabel: boolean;
	};
}

export const ShopOrderCompletionForm = (props: ShopOrderOperationCompletionFormProps) => {
	const {
		shopOrderId,
		onCompleted,
		onFormEdited,
		shopOrderOperationIdToComplete: shopOrderOperationIdToCompleteProp,
	} = props;

	const [storedDefaultValues, setStoredDefaultValues] = useStoredState({
		defaultValue: {
			deliveryPackageTypeId: genericNullableValue<number>(),
			packageWarehouseLocationId: genericNullableValue<number>(),
			printPackageLabel: false,
		},
		key: "6B932BA61956B645",
	});

	const showConfirmDialog = useConfirmDialog();

	return (
		<AsyncForm<ShopOrderCompletionFormApi_FormInitData, FormValues, void>
			fetch={() => ShopOrderCompletionFormApi.getFormInitData({ shopOrderId: shopOrderId })}
			getDefaultValues={getDefaultValues}
			submit={submit}
			onCompleted={onCompleted}
			columns={2}
			onFormEdited={onFormEdited}
			render={(params) => <FormContent {...props} {...params} />}
		/>
	);

	function getDefaultValues({
		shopOrder,
		defaultPackageDescription,
		packageWarehouseLocationOptions,
	}: ShopOrderCompletionFormApi_FormInitData) {
		const quantityToCompleteInBaseUnit = shopOrder.quantity - shopOrder.completedQuantity;
		return {
			quantityToCompleteInBaseUnit: quantityToCompleteInBaseUnit,
			closeShopOrder: false,
			completeOperation: false,
			customerOrderLinePackage: {
				deliveryPackageTypeId: storedDefaultValues.deliveryPackageTypeId ?? undefined,
				packageWarehouseLocationId:
					storedDefaultValues.packageWarehouseLocationId ??
					packageWarehouseLocationOptions[0]?.warehouseLocationId ??
					undefined,
				packageDescription: defaultPackageDescription,
				printPackageLabel: storedDefaultValues.printPackageLabel,
				weight: shopOrder.weight != null ? shopOrder.weight * quantityToCompleteInBaseUnit : undefined,
			},
		};
	}

	async function submit(
		values: FormValues,
		{ data: { shopOrder } }: AsyncFormContentParams<ShopOrderCompletionFormApi_FormInitData, FormValues>,
	) {
		const shopOrderOperationIdToComplete =
			values.completeOperation ? shopOrderOperationIdToCompleteProp : undefined;

		if (values.closeShopOrder) {
			const closeShopOrderConfirmed = await confirmCloseShopOrder({
				shopOrderId: shopOrderId,
				showConfirmDialog: showConfirmDialog,
				skipConfirmOnSimpleCase: true,
				allowedIncompleteOperations: filterNulls([shopOrderOperationIdToComplete]),
			});
			if (!closeShopOrderConfirmed) return;
		}

		const customerOrderLineId = shopOrder.customerOrderLineId;
		const packageCustomerOrderLineRequest =
			shopOrder.source !== "CUSTOMER_ORDER_LINE" || customerOrderLineId == null ?
				null
			:	{
					customerOrderLineId: customerOrderLineId,
					packageTypeId: values.customerOrderLinePackage.deliveryPackageTypeId,
					packageDescription: values.customerOrderLinePackage.packageDescription,
					width: values.customerOrderLinePackage.width,
					height: values.customerOrderLinePackage.height,
					length: values.customerOrderLinePackage.length,
					weight: values.customerOrderLinePackage.weight,
					packageWarehouseLocationId: values.customerOrderLinePackage.packageWarehouseLocationId,
				};

		const submitResult = await ShopOrderCompletionFormApi.submit({
			shopOrderId: shopOrderId,
			quantityToCompleteInBaseUnit: values.quantityToCompleteInBaseUnit,
			closeShopOrder: values.closeShopOrder,
			shopOrderOperationIdToComplete: shopOrderOperationIdToComplete,
			packageCustomerOrderLineRequest: packageCustomerOrderLineRequest,
		});

		setStoredDefaultValues({
			deliveryPackageTypeId: values.customerOrderLinePackage?.deliveryPackageTypeId ?? null,
			packageWarehouseLocationId: values.customerOrderLinePackage?.packageWarehouseLocationId ?? null,
			printPackageLabel: values.customerOrderLinePackage?.printPackageLabel ?? null,
		});

		const packageId = submitResult.createdPackageId;
		if (values.customerOrderLinePackage.printPackageLabel && packageId != null) {
			const fileHandle = await DeliveryPackageApi.printDeliveryPackageLabel({
				deliveryPackageId: packageId,
			});
			downloadFile(fileHandle);
		}
	}
};

interface FormContentProps
	extends ShopOrderOperationCompletionFormProps,
		AsyncFormContentParams<ShopOrderCompletionFormApi_FormInitData, FormValues> {}

const FormContent = (props: FormContentProps) => {
	const {
		control,
		setValue,
		data: { shopOrder },
		formState: { dirtyFields },
		shopOrderOperationIdToComplete,
	} = props;

	return (
		<>
			<FormNumberField
				control={control}
				name={"quantityToCompleteInBaseUnit"}
				label={i18n.t("completed_quantity")}
				rules={requiredNonNegativeFloatRule()}
				onSubmit={(newQuantity) =>
					setValue("customerOrderLinePackage.weight", (shopOrder.weight ?? 0) * (newQuantity ?? 0))
				}
			/>
			<CenteredTypography>{`${i18n.t("earlier_completed_quantity")}: ${shopOrder.completedQuantity}`}</CenteredTypography>
			<FormCheckbox
				control={control}
				name={"closeShopOrder"}
				label={i18n.t("close_shop_order")}
				startNewGridRow={true}
				onChange={(closeShopOrder) => {
					if (!dirtyFields.completeOperation) {
						setValue("completeOperation", closeShopOrder);
					}
				}}
			/>
			{shopOrderOperationIdToComplete && (
				<FormCheckbox control={control} name={"completeOperation"} label={i18n.t("complete_operation")} />
			)}
			{shopOrder.source === "CUSTOMER_ORDER_LINE" && <CustomerOrderLinePackagingForm {...props} />}
		</>
	);
};

const CustomerOrderLinePackagingForm = ({
	control,
	watch,
	setValue,
	data: { shopOrder, packageTypeOptions, packageWarehouseLocationOptions },
}: FormContentProps) => {
	const quantityToCompleteInBaseUnit = watch("quantityToCompleteInBaseUnit");

	return (
		<>
			<FormSelectField
				control={control}
				name={"customerOrderLinePackage.deliveryPackageTypeId"}
				label={i18n.t("package_type")}
				options={packageTypeOptions}
				getOptionKey={(option) => option.deliveryPackageTypeId}
				getOptionLabel={(option) => option.name}
				rules={requireRule()}
				startNewGridRow={true}
			/>
			<FormNumberField control={control} name={"customerOrderLinePackage.length"} label={i18n.t("length")} />
			<FormNumberField control={control} name={"customerOrderLinePackage.width"} label={i18n.t("width")} />
			<FormNumberField control={control} name={"customerOrderLinePackage.height"} label={i18n.t("height")} />
			<FormNumberField control={control} name={"customerOrderLinePackage.weight"} label={i18n.t("weight")} />
			<AavoButton
				icon={faCalculator}
				label={i18n.t("calculate_weight")}
				variant={"outlined"}
				onClick={() => {
					const weight = shopOrder.weight != null ? quantityToCompleteInBaseUnit * shopOrder.weight : 0;
					setValue("customerOrderLinePackage.weight", weight);
				}}
			/>
			<FormTextField
				control={control}
				name={"customerOrderLinePackage.packageDescription"}
				label={i18n.t("package_description")}
			/>
			<FormSelectField
				control={control}
				name={"customerOrderLinePackage.packageWarehouseLocationId"}
				label={i18n.t("package_warehouse_location")}
				options={packageWarehouseLocationOptions}
				getOptionKey={(o) => o.warehouseLocationId}
				getOptionLabel={(o) => o.locationCode}
				rules={requireRule()}
			/>
			<FormCheckbox
				control={control}
				name={"customerOrderLinePackage.printPackageLabel"}
				label={i18n.t("print_package_label")}
			/>
		</>
	);
};
