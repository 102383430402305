import { html, LitElement } from "lit";
import { setFavicon } from "../utils";

class WopiFileWebComponent extends LitElement {
	static get properties() {
		return {
			actionUrl: { type: String },
			accessToken: { type: String },
			accessTokenTtl: { type: Number },
			userId: { type: String },
			ownerId: { type: String },
			favicon: { type: String },
			error: { type: String },
		};
	}

	createRenderRoot() {
		return this;
	}

	constructor() {
		super();
		this.actionUrl = "";
		this.accessToken = "";
		this.accessTokenTtl = "";
		this.userId = "";
		this.ownerId = "";
		this.favicon = null;
	}

	render() {
		return html`
			<form
				class="office_form"
				name="office_form"
				target="office_frame"
				method="post"
				action=""
			>
				<input name="access_token" value="" type="hidden" />
				<input name="access_token_ttl" value="" type="hidden" />
				<input name="user_id" value="" type="hidden" />
				<input name="owner_id" value="" type="hidden" />
			</form>
			<span class="frameholder"></span>
		`;
	}

	firstUpdated(_changedProperties) {
		if (this.error != null) {
			this.showError(this.error);
			return;
		}

		this.createIframe();
		this.submitForm();
		if (this.favicon) setFavicon(this.favicon);
	}

	disconnectedCallback() {
		super.disconnectedCallback();
	}

	createIframe() {
		const frameholder = this.querySelector(".frameholder");
		const officeFrame = document.createElement("iframe");
		officeFrame.name = "office_frame";
		officeFrame.classList.add("office_frame");
		officeFrame.title = "Office Frame";
		officeFrame.setAttribute("allowfullscreen", "true");
		officeFrame.setAttribute(
			"sandbox",
			"allow-scripts allow-same-origin allow-forms allow-popups " +
				"allow-top-navigation allow-popups-to-escape-sandbox"
		);
		frameholder.appendChild(officeFrame);
	}

	submitForm() {
		const form = this.querySelector(".office_form");
		form.action = this.actionUrl;

		function setInputValue(name, value) {
			const input = form.elements[name];
			input.value = value;
		}

		setInputValue("access_token", this.accessToken);
		setInputValue("access_token_ttl", this.accessTokenTtl);
		setInputValue("user_id", this.userId);
		setInputValue("owner_id", this.ownerId);

		form.submit();
	}

	showError(error) {
		this.innerHTML = `
         <div class="error-message">${error}</div>
        `;
	}
}

customElements.define("wopi-file-component", WopiFileWebComponent);
