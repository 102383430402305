// This file is automatically generated. Do not edit manually.

import { t } from "i18next";

export const ConfiguratorLibraryVersionStateValues = ["INITIAL", "RELEASED"] as const;

export type ConfiguratorLibraryVersionState = (typeof ConfiguratorLibraryVersionStateValues)[number];

export const getConfiguratorLibraryVersionStateLabels = () => ({
	INITIAL: t("initial"),
	RELEASED: t("released"),
});

export const getConfiguratorLibraryVersionStateLabel = (value: ConfiguratorLibraryVersionState): string => {
	return getConfiguratorLibraryVersionStateLabels()[value];
};
