// This file is automatically generated. Do not edit manually.

import { AccountingCode } from "src/api/generated/erp/db/types/tables/accountingCode";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace ObjectAccountingApi {
	export async function getAccountingCodeOptions(args: {
		dimensionId: number;
		currentSelection: number | null | undefined;
		searchQuery: string;
	}): Promise<Array<AccountingCode>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/accounting/getAccountingCodeOptions",
			method: "POST",
			data: {
				dimensionId: args.dimensionId,
				currentSelection: args.currentSelection,
				searchQuery: args.searchQuery,
			},
		});
		return response as Array<AccountingCode>;
	}
}
