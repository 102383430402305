import { ConfiguratorTableTab } from "src/api/generated/erp/db/types/tables/configuratorTableTab.ts";
import { FormCommonProps } from "src/components/common/forms/types.ts";
import { AavoForm } from "src/components/common/forms/AavoForm.tsx";
import { DeepPartial } from "react-hook-form";
import { FormTextField } from "src/components/common/forms/fields/FormTextField.tsx";
import { requireRule } from "src/components/common/forms/validation.ts";
import i18n from "i18next";
import { ConfiguratorTableTabsApi } from "src/api/generated/erp/configurator/tables/api/configuratorTableTabsApi.ts";

export interface ConfiguratorTableTabFormProps extends FormCommonProps<number> {
	configuratorTableRevisionId: number;
	tab?: ConfiguratorTableTab;
}

interface FormValues extends ConfiguratorTableTab {}

export const ConfiguratorTableTabForm = (props: ConfiguratorTableTabFormProps) => {
	const { configuratorTableRevisionId, tab, onCompleted, onFormEdited } = props;
	return (
		<AavoForm
			defaultValues={getDefaultValues()}
			submit={submit}
			onCompleted={onCompleted}
			onFormEdited={onFormEdited}
			render={({ control }) => (
				<>
					<FormTextField
						control={control}
						name={"tabName"}
						label={i18n.t("name")}
						rules={requireRule()}
						autoFocus
					/>
				</>
			)}
		/>
	);

	function getDefaultValues(): DeepPartial<FormValues> {
		return (
			tab ?? {
				configuratorTableRevisionId: configuratorTableRevisionId,
			}
		);
	}

	async function submit(values: FormValues) {
		if (tab == null) {
			return await ConfiguratorTableTabsApi.insert({
				configuratorTableRevisionId: configuratorTableRevisionId,
				tabName: values.tabName,
			});
		} else {
			await ConfiguratorTableTabsApi.update({
				configuratorTableTab: values,
			});
			return tab.configuratorTableTabId;
		}
	}
};
