import { useState } from "react";

export interface UseRemountReturn {
	remount: () => void;
	key: number;
}

export function useRemount(): UseRemountReturn {
	const [key, setKey] = useState(0);
	const remount = () => setKey((prevKey) => prevKey + 1);
	return { remount, key };
}
