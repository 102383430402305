import { useRef, useState } from "react";

export interface UseMaybeControlledParams<T> {
	controlledValue: T | undefined;
	onChange?: (value: T) => void;
	defaultValue: T;
}

export const useMaybeControlledState = <T>({
	controlledValue,
	onChange,
	defaultValue,
}: UseMaybeControlledParams<T>): [T, (value: T) => void] => {
	const prevControlledValue = useRef<T | undefined>(controlledValue);

	if (prevControlledValue.current === undefined && controlledValue !== undefined) {
		console.error("Changing from uncontrolled to controlled state is not supported");
	}
	if (prevControlledValue.current !== undefined && controlledValue === undefined) {
		console.error("Changing from controlled to uncontrolled state is not supported");
	}
	prevControlledValue.current = controlledValue;

	const [uncontrolledState, setUncontrolledState] = useState(defaultValue);
	if (controlledValue !== undefined) {
		return [
			controlledValue,
			(newValue) => {
				onChange?.(newValue);
			},
		];
	} else {
		return [
			uncontrolledState,
			(newValue) => {
				setUncontrolledState(newValue);
				onChange?.(newValue);
			},
		];
	}
};
