import i18n from "i18next";
import {
    PurchaseOrderReceiptsDataGrid
} from "src/components/views/erp/purchase/reception/PurchaseOrderReceiptsDataGrid.tsx";
import {PurchaseOrderReceipt} from "src/api/generated/erp/db/types/tables/purchaseOrderReceipt.ts";
import {
    PurchaseOrderReceiptLinesDataGrid
} from "src/components/views/erp/purchase/reception/PurchaseOrderReceiptLinesDataGrid.tsx";
import {AavoMosaic} from "src/components/common/mosaic/AavoMosaic.tsx";
import {useState} from "react";
import {CenteredTypography} from "src/components/common/CenteredTypography.tsx";
import {
    PurchaseOrderLinesToReceiveDataGrid
} from "src/components/views/erp/purchase/reception/PurchaseOrderLinesToReceiveDataGrid.tsx";
import {useRefreshRef} from "src/utils/useRefreshRef.ts";

export interface PurchaseOrderReceptionViewProps {
	purchaseOrderId: number;
}

export const PurchaseOrderReceptionView = ({ purchaseOrderId }: PurchaseOrderReceptionViewProps) => {
	const [selectedReceipt, setSelectedReceipt] = useState<PurchaseOrderReceipt | undefined>(undefined);

	const selectReceiptTypography = <CenteredTypography children={i18n.t("select_a_receipt")} />;

	const purchaseOrderLinesRefreshRef = useRefreshRef()
	const receiptLinesRefreshRef = useRefreshRef()

	return (
		<AavoMosaic
			viewId={"4D1117E464F08827"}
			layout={{
				type: "column",
				first: {
					type: "panel",
					title: i18n.t("receipts"),
					content: (
						<PurchaseOrderReceiptsDataGrid
							purchaseOrderId={purchaseOrderId}
							editEnabled
							selectFirstRowOnLoad
							onSelectionChanged={(selection) => {
								setSelectedReceipt(selection);
							}}
						/>
					),
				},
				second: {
					type: "row",
					first: {
						type: "panel",
						title: i18n.t("purchase_order_lines"),
						content: (
							<PurchaseOrderLinesToReceiveDataGrid
								refreshRef={purchaseOrderLinesRefreshRef}
								receiptLinesViewRefreshRef={receiptLinesRefreshRef}
								purchaseOrderId={purchaseOrderId}
								targetPurchaseOrderReceipt={selectedReceipt}
							/>
						),
					},
					second: {
						type: "panel",
						title: i18n.t("receipt_lines"),
						content:
							selectedReceipt == null ? selectReceiptTypography : (
								<PurchaseOrderReceiptLinesDataGrid
									refreshRef={receiptLinesRefreshRef}
									purchaseOrderLinesToReceiveViewRefreshRef={purchaseOrderLinesRefreshRef}
									key={selectedReceipt.purchaseOrderReceiptId}
									purchaseOrderReceipt={selectedReceipt}
									editEnabled
								/>
							),
					},
				},
			}}
		/>
	);
};
