// This file is automatically generated. Do not edit manually.

import { t } from "i18next";

export const PurchaseOrderSourceTypeValues = [
	"MANUAL",
	"PURCHASE_REQUEST",
	"CUSTOMER_ORDER",
	"SHOP_ORDER",
	"PROJECT",
] as const;

export type PurchaseOrderSourceType = (typeof PurchaseOrderSourceTypeValues)[number];

export const getPurchaseOrderSourceTypeLabels = () => ({
	MANUAL: t("manual"),
	PURCHASE_REQUEST: t("purchase_request"),
	CUSTOMER_ORDER: t("customer_order"),
	SHOP_ORDER: t("shop_order"),
	PROJECT: t("project"),
});

export const getPurchaseOrderSourceTypeLabel = (value: PurchaseOrderSourceType): string => {
	return getPurchaseOrderSourceTypeLabels()[value];
};
