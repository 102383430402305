import { ThemeProvider } from "@mui/material/styles";
import { AAVO_MUI_THEME } from "src/theme/theme.ts";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import { AavoViewsProvider } from "./components/views/legacy/aavoViewsContext";
import { AavoToastProvider } from "./components/common/toast/AavoToastProvider";
import { PushNotificationsContextProvider } from "./notifications/pushNotificationsContext";
import { ConfirmDialogProvider } from "src/components/common/dialogs/confirmDialog/ConfirmDialogProvider.tsx";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
import { ErrorDialogProvider } from "src/components/common/dialogs/errorDialog/ErrorDialogProvider.tsx";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider as DatePickerLocalizationProvider } from "@mui/x-date-pickers-pro";
import { getLanguageSetting } from "./localSettings/utils";
import { TenantCustomizationsContextProvider } from "src/tenantCustomizations/TenantCustomizationsContext";
import { BrowserRouter } from "react-router-dom";
import { MessageDialogProvider } from "./components/common/dialogs/messageDialog/MessageDialogProvider";
import Box from "@mui/material/Box";
import { ErrorBoundary } from "src/sentry/ErrorBoundary.tsx";
import * as Sentry from "@sentry/react";
import { AavoRoutes } from "./AavoRoutes";
import { FeatureChangeHintContextProvider } from "src/components/common/featureChangeHint/FeatureChangeHintContext.tsx";

const cache = createCache({
	key: "css",
	prepend: true,
});

export const App = () => (
	<Box
		sx={{
			height: "100%",
			display: "flex",
		}}
	>
		<ErrorBoundary>
			<Sentry.Profiler>
				<ThemeProvider theme={AAVO_MUI_THEME}>
					<CacheProvider value={cache}>
						<ErrorDialogProvider>
							<ConfirmDialogProvider>
								<MessageDialogProvider>
									<PushNotificationsContextProvider>
										<TenantCustomizationsContextProvider>
											<FeatureChangeHintContextProvider>
												<DndProvider backend={HTML5Backend}>
													<DatePickerLocalizationProvider
														dateAdapter={AdapterDayjs}
														adapterLocale={getLanguageSetting()}
													>
														<BrowserRouter>
															<AavoViewsProvider>
																<AavoToastProvider>
																	<AavoRoutes />
																</AavoToastProvider>
															</AavoViewsProvider>
														</BrowserRouter>
													</DatePickerLocalizationProvider>
												</DndProvider>
											</FeatureChangeHintContextProvider>
										</TenantCustomizationsContextProvider>
									</PushNotificationsContextProvider>
								</MessageDialogProvider>
							</ConfirmDialogProvider>
						</ErrorDialogProvider>
					</CacheProvider>
				</ThemeProvider>
			</Sentry.Profiler>
		</ErrorBoundary>
	</Box>
);
