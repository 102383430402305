import { CChartEventSearchApi } from "src/api/generated/spc/cChartEvents/cChartEventSearchApi.ts";
import i18n from "i18next";
import { ControlChart } from "src/api/generated/postgres/db/types/public_/tables/controlChart.ts";
import { AsyncMenuButton } from "src/components/common/contextMenu/AsyncMenuButton.tsx";
import { faFileCsv } from "@fortawesome/pro-regular-svg-icons";
import { useInputDialog } from "src/components/common/dialogs/input/useInputDialog.tsx";
import { downloadFile } from "src/utils/fileDownloading.ts";
import { dayJsToDateIsoString } from "src/utils/dayjsUtils.ts";
import { ControlledAsyncDataGrid } from "src/components/common/dataGrid/ControlledAsyncDataGrid";
import { useClientSideDataGridModel } from "src/components/common/dataGrid/gridModel/useClientSideDataGridModel";

export interface ControlChartsSelectionViewProps {
	chartGroupId: number;
	onRowSelectionChanged: (controlCharts: ControlChart[]) => void;
}

export const ControlChartsSelectionView = ({
	chartGroupId,
	onRowSelectionChanged,
}: ControlChartsSelectionViewProps) => {
	const showInputDialog = useInputDialog();

	const { dataGridProps } = useClientSideDataGridModel({
		gridId: "10790D02CA4CA696",
		getRowId: (row) => row.id,
		fetchData: () => CChartEventSearchApi.getControlChartOptions({ chartGroupId }),
		initialParams: {},
		onSelectionChanged: onRowSelectionChanged,
	});

	return (
		<ControlledAsyncDataGrid<ControlChart>
			columns={[
				{
					field: "name",
					headerName: i18n.t("control_chart"),
					width: 200,
				},
			]}
			rowContextMenuComponents={({ allSelectedRows }) => [
				<AsyncMenuButton
					key={"download_csv"}
					label={i18n.t("download_csv")}
					icon={faFileCsv}
					onClick={async () => {
						const dateRange = await showInputDialog({
							title: i18n.t("date_range"),
							type: "dateRange",
							defaultValue: null,
							required: true,
						});
						if (dateRange === undefined) return;

						const fileHandle = await CChartEventSearchApi.downloadCChartEventsAsCsv({
							controlChartIds: allSelectedRows.map((row) => row.id),
							fromDate: dayJsToDateIsoString(dateRange.start),
							toDate: dayJsToDateIsoString(dateRange.end),
						});
						downloadFile(fileHandle);
					}}
				/>,
			]}
			{...dataGridProps}
		/>
	);
};
