// This file is automatically generated. Do not edit manually.

import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace SalesCommissionLineActionApi {
	export async function approveSalesCommissionLines(args: { salesCommissionLineIds: Array<number> }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/commissions/line/approveSalesCommissionLines",
			method: "POST",
			data: {
				salesCommissionLineIds: args.salesCommissionLineIds,
			},
		});
		return response as void;
	}

	export async function revertApproveSalesCommissionLines(args: {
		salesCommissionLineIds: Array<number>;
	}): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/commissions/line/revertApproveSalesCommissionLines",
			method: "POST",
			data: {
				salesCommissionLineIds: args.salesCommissionLineIds,
			},
		});
		return response as void;
	}
}
