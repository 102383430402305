import { MainApi, StartUpData } from "../api/mainApi";
import React from "react";
import { AsyncFetchRender } from "src/components/common/async/AsyncFetchRender.tsx";

export const StartUpDataContext = React.createContext<StartUpData | undefined>(undefined);

export function StartUpDataProvider({ children }: React.PropsWithChildren) {
	return (
		<AsyncFetchRender
			fetch={MainApi.getStartUpData}
			content={(startUpData) => (
				<StartUpDataContext.Provider value={startUpData}>
					{children}
				</StartUpDataContext.Provider>
			)}
		/>
	);
}

export default StartUpDataProvider;
