// This file is automatically generated. Do not edit manually.

import { ConfigurationPropertyValue_SubConfiguration } from "src/api/generated/io/aavo/applications/db/erp/types/configurationPropertyValue";
import { SubConfiguratorPartConfiguratorType } from "src/api/generated/erp/configurator/configuratorType/impl/subConfiguratorPartConfiguratorType";
import { ConfiguratorInput } from "src/api/generated/erp/configurator/model/configuratorInput";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace SubConfiguratorFormApi {
	export async function getSubConfigurationInputFromValue(args: {
		subConfiguratorValue: ConfigurationPropertyValue_SubConfiguration;
		configuratorType: SubConfiguratorPartConfiguratorType;
		configurationSessionId: string;
	}): Promise<ConfiguratorInput> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/configurator/subConfiguratorForm/getSubConfigurationInputFromValue",
			method: "POST",
			data: {
				subConfiguratorValue: args.subConfiguratorValue,
				configuratorType: args.configuratorType,
				configurationSessionId: args.configurationSessionId,
			},
		});
		return response as ConfiguratorInput;
	}

	export async function getSubConfigurationValueFromInput(args: {
		configurationSessionId: string;
		configuratorType: SubConfiguratorPartConfiguratorType;
		subConfiguratorInput: ConfiguratorInput;
	}): Promise<ConfigurationPropertyValue_SubConfiguration> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/configurator/subConfiguratorForm/getSubConfigurationValueFromInput",
			method: "POST",
			data: {
				configurationSessionId: args.configurationSessionId,
				configuratorType: args.configuratorType,
				subConfiguratorInput: args.subConfiguratorInput,
			},
		});
		return response as ConfigurationPropertyValue_SubConfiguration;
	}
}
