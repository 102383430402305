import { DocumentsOfMultipleObjectsView } from "src/components/views/documents/objectDocuments/DocumentsOfMultipleObjectsView.tsx";
import {JobQueueShopOrderOperation} from "src/components/views/erp/production/jobQueue/base/JobQueueShopOrderOperation.ts";
import {useJobQueueViewContext} from "src/components/views/erp/production/jobQueue/base/JobQueueViewContext.ts";

export interface SelectedOperationDocumentsViewProps {
	shopOrderOperation: JobQueueShopOrderOperation;
}

export const SelectedOperationDocumentsView = ({ shopOrderOperation }: SelectedOperationDocumentsViewProps) => {
	const { inProgressViewDocumentsRefreshRef } = useJobQueueViewContext();
	return (
		<DocumentsOfMultipleObjectsView
			refreshRef={inProgressViewDocumentsRefreshRef}
			objectRefs={[
				{ objectType: "SHOP_ORDER_OPERATION", objectId: shopOrderOperation.shopOrderOperationId },
				shopOrderOperation.partRevisionOperationId != null && {
					objectType: "PART_REVISION_OPERATION",
					objectId: shopOrderOperation.partRevisionOperationId,
				},
				{
					objectType: "PART_REVISION",
					objectId: shopOrderOperation.partRevisionId,
				},
				{
					objectType: "SHOP_ORDER",
					objectId: shopOrderOperation.shopOrderId,
				},
				shopOrderOperation.customerOrderLineId != null && {
					objectType: "CUSTOMER_ORDER_LINE",
					objectId: shopOrderOperation.customerOrderLineId,
				},
				shopOrderOperation.partConfigurationId != null && {
					objectType: "PART_CONFIGURATION",
					objectId: shopOrderOperation.partConfigurationId,
				},
			]}
			editable={false}
			hidePreview
		/>
	);
};
