// This file is automatically generated. Do not edit manually.

import { WorkspaceView } from "src/api/generated/postgres/db/types/workspaces/tables/workspaceView";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace CatalogPartWorkspacesDataGridApi {
	export async function getCatalogPartWorkspaces(args: { catalogPartId: number }): Promise<Array<WorkspaceView>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/productManagement/catalogPartWorkspaces/getCatalogPartWorkspaces",
			method: "POST",
			data: {
				catalogPartId: args.catalogPartId,
			},
		});
		return response as Array<WorkspaceView>;
	}
}
