// This file is automatically generated. Do not edit manually.

import { ShopOrderBatch } from "src/api/generated/erp/db/types/tables/shopOrderBatch";
import { IsoDateTimeString } from "src/types/dateTime";
import { ProductionLineView } from "src/api/generated/erp/db/types/tables/productionLineView";
import { Site } from "src/api/generated/erp/db/types/tables/site";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace ShopOrderBatchEditApi {
	export async function getFormInitData(args: {
		shopOrderBatchId: number | null | undefined;
	}): Promise<ShopOrderBatchEditApi_FormInitData> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/shopOrderBatch/getFormInitData",
			method: "POST",
			data: {
				shopOrderBatchId: args.shopOrderBatchId,
			},
		});
		return response as ShopOrderBatchEditApi_FormInitData;
	}

	export async function insert(args: { shopOrderBatch: ShopOrderBatch }): Promise<number> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/shopOrderBatch/insert",
			method: "POST",
			data: {
				shopOrderBatch: args.shopOrderBatch,
			},
		});
		return response as number;
	}

	export async function update(args: {
		shopOrderBatchId: number;
		batchName: string;
		plannedBeginDate: IsoDateTimeString | null | undefined;
		productionLineId: number;
	}): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/shopOrderBatch/update",
			method: "POST",
			data: {
				shopOrderBatchId: args.shopOrderBatchId,
				batchName: args.batchName,
				plannedBeginDate: args.plannedBeginDate,
				productionLineId: args.productionLineId,
			},
		});
		return response as void;
	}

	export async function delete_(args: { shopOrderBatchId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/shopOrderBatch/delete",
			method: "POST",
			data: {
				shopOrderBatchId: args.shopOrderBatchId,
			},
		});
		return response as void;
	}
}

export interface ShopOrderBatchEditApi_FormInitData {
	productionLineOptions: Array<ProductionLineView>;
	shopOrderBatch: ShopOrderBatch | null | undefined;
	siteOptions: Array<Site>;
}
