// This file is automatically generated. Do not edit manually.

import { ProjectActivityType } from "src/api/generated/erp/db/types/tables/projectActivityType";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace ProjectActivityTypeApi {
	export async function getActivityTypes(): Promise<Array<ProjectActivityType>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/project/activityTypes/getActivityTypes",
			method: "POST",
			data: {},
		});
		return response as Array<ProjectActivityType>;
	}

	export async function insert(args: { activityType: ProjectActivityType }): Promise<number> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/project/activityTypes/insert",
			method: "POST",
			data: {
				activityType: args.activityType,
			},
		});
		return response as number;
	}

	export async function update(args: { activityType: ProjectActivityType }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/project/activityTypes/update",
			method: "POST",
			data: {
				activityType: args.activityType,
			},
		});
		return response as void;
	}

	export async function delete_(args: { activityTypeId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/project/activityTypes/delete",
			method: "POST",
			data: {
				activityTypeId: args.activityTypeId,
			},
		});
		return response as void;
	}
}
