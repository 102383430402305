import { ClientSideDataGridModel } from "src/components/common/dataGrid/gridModel/ClientSideDataGridModel.tsx";
import { SitePartReferencesDataGridApi } from "src/api/generated/erp/parts/sitePart/api/sitePartReferencesDataGridApi.ts";
import { enumColumn, textColumn } from "src/components/common/dataGrid/columns.tsx";
import i18n from "i18next";
import { getPartRevisionStateLabels } from "src/api/generated/erp/db/types/enums/partRevisionState.ts";
import { PartRevisionLineView } from "src/api/generated/erp/db/types/tables/partRevisionLineView.ts";
import { CrudDataGrid } from "src/components/common/dataGrid/crud/CrudDataGrid.tsx";
import { AavoButton } from "src/components/common/buttons/AavoButton.tsx";
import { faSitemap } from "@fortawesome/pro-regular-svg-icons";
import { useOpenLegacyView } from "src/components/views/legacy/useOpenLegacyView.ts";

export interface SitePartReferencesDataGridProps {
	partId: number;
}

export const SitePartReferencesDataGrid = ({ partId }: SitePartReferencesDataGridProps) => {
	const openLegacyView = useOpenLegacyView();
	return (
		<ClientSideDataGridModel
			gridId={"1C6F3A86A0FBEA7D"}
			fetchData={SitePartReferencesDataGridApi.fetchSitePartReferences}
			getRows={(data) => data}
			getRowId={(row) => row.partRevisionLineId}
			initialParams={{
				partId: partId,
			}}
			render={({ dataGridProps, onlySelectedRow }) => {
				return (
					<CrudDataGrid<PartRevisionLineView>
						disableMultipleRowSelection
						columns={[
							textColumn({
								field: "parentPartNo",
								headerName: i18n.t("part"),
							}),
							textColumn({
								field: "parentPartDescription_1",
								headerName: i18n.t("description_1"),
								width: 200,
							}),
							textColumn({
								field: "parentPartDescription_2",
								headerName: i18n.t("description_2"),
								width: 200,
							}),
							textColumn({
								field: "revisionNumber",
								headerName: i18n.t("revision"),
							}),
							enumColumn({
								field: "revisionState",
								headerName: i18n.t("revision_state"),
								enumLabels: getPartRevisionStateLabels(),
							}),
							textColumn({
								field: "referenceNo",
								headerName: i18n.t("reference_no"),
							}),
						]}
						actionBarComponents={
							<>
								<AavoButton
									label={i18n.t("product_structure")}
									icon={faSitemap}
									variant={"outlined"}
									disabled={onlySelectedRow == null}
									onClick={() => {
										if (onlySelectedRow == null) return;
										openLegacyView("ca935b35-b03e-497d-bb2d-713428cc6217", {
											partRevisionId: onlySelectedRow.partRevisionId,
											partNo: onlySelectedRow.parentPartNo,
											partDescription_1:
												onlySelectedRow.parentPartDescription_1,
											revisionNumber: onlySelectedRow.revisionNumber,
										});
									}}
								/>
							</>
						}
						{...dataGridProps}
					/>
				);
			}}
		/>
	);
};
