// This file is automatically generated. Do not edit manually.

import { ShopOrderBomTreeViewResult } from "src/api/generated/erp/production/api/shopOrderBomTreeViewResult";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace ShopOrderBomTreeViewApi {
	export async function getTreeElements(args: {
		shopOrderId: number;
		searchQuery?: string;
	}): Promise<ShopOrderBomTreeViewResult> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/shopOrderBomTreeView/getTreeElements",
			method: "POST",
			data: {
				shopOrderId: args.shopOrderId,
				searchQuery: args.searchQuery,
			},
		});
		return response as ShopOrderBomTreeViewResult;
	}
}
