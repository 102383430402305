// This file is automatically generated. Do not edit manually.

import { ReclamationCategory } from "src/api/generated/erp/db/types/tables/reclamationCategory";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace ReclamationCategoryDataGridApi {
	export async function getAll(): Promise<Array<ReclamationCategory>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/reclamations/reclamationCategory/getAll",
			method: "POST",
			data: {},
		});
		return response as Array<ReclamationCategory>;
	}

	export async function save(args: {
		reclamationCategories: Array<ReclamationCategory>;
	}): Promise<Array<ReclamationCategory>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/reclamations/reclamationCategory/save",
			method: "POST",
			data: {
				reclamationCategories: args.reclamationCategories,
			},
		});
		return response as Array<ReclamationCategory>;
	}

	export async function delete_(args: { reclamationCategories: Array<ReclamationCategory> }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/reclamations/reclamationCategory/delete",
			method: "POST",
			data: {
				reclamationCategories: args.reclamationCategories,
			},
		});
		return response as void;
	}

	export async function setIsActive(args: { reclamationCategoryId: number; isActive: boolean }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/reclamations/reclamationCategory/setIsActive",
			method: "POST",
			data: {
				reclamationCategoryId: args.reclamationCategoryId,
				isActive: args.isActive,
			},
		});
		return response as void;
	}
}
