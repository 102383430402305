// This file is automatically generated. Do not edit manually.

import { DeliverySource } from "src/api/generated/erp/delivery/model/deliverySource";
import { DeliverySourceType } from "src/api/generated/erp/db/types/enums/deliverySourceType";
import { DeliveryPackageState } from "src/api/generated/erp/db/types/enums/deliveryPackageState";
import { ServerSideDataModelRequest } from "src/api/generated/common/dataGrids/serverSideDataModelRequest";
import { ServerSideDataModelResult } from "src/api/generated/common/dataGrids/serverSideDataModelResult";
import { DeliveryPackageView } from "src/api/generated/erp/db/types/tables/deliveryPackageView";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace DeliveryPackageDataGridApi {
	export async function getDeliveryPackages(args: {
		source: DeliverySource | null | undefined;
		sourceType: DeliverySourceType | null | undefined;
		packageState: Array<DeliveryPackageState>;
		deliveryId: number | null | undefined;
		onlyPackagesWithoutConnectedDelivery?: boolean;
		deliveryPackageId: number | null | undefined;
		siteId: number | null | undefined;
		searchQuery: string;
		dataModelRequest: ServerSideDataModelRequest;
	}): Promise<ServerSideDataModelResult<DeliveryPackageView>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/delivery/deliveryPackagesDataGrid/getDeliveryPackages",
			method: "POST",
			data: {
				source: args.source,
				sourceType: args.sourceType,
				packageState: args.packageState,
				deliveryId: args.deliveryId,
				onlyPackagesWithoutConnectedDelivery: args.onlyPackagesWithoutConnectedDelivery,
				deliveryPackageId: args.deliveryPackageId,
				siteId: args.siteId,
				searchQuery: args.searchQuery,
				dataModelRequest: args.dataModelRequest,
			},
		});
		return response as ServerSideDataModelResult<DeliveryPackageView>;
	}

	export async function delete_(args: { deliveryPackages: Array<DeliveryPackageView> }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/delivery/deliveryPackagesDataGrid/delete",
			method: "POST",
			data: {
				deliveryPackages: args.deliveryPackages,
			},
		});
		return response as void;
	}
}
