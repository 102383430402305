import { LegacyPushAction } from "./legacyPushAction";

/**
 * If service worker opens a new client and sends legacy Aavo action to it
 * immediately, handling of it is too late in react-code.
 * Therefore, it is saved here and checked on first render.
 */

let initialNotificationAction: LegacyPushAction | undefined = undefined;

export const setInitialNotificationAction = (event: LegacyPushAction) => {
	initialNotificationAction = event;
};

export const getAndResetInitialNotificationAction = () => {
	const ret = initialNotificationAction;
	// Delete value to prevent duplicate action executions.
	initialNotificationAction = undefined;
	return ret;
};
