// This file is automatically generated. Do not edit manually.

import { FrontendDownloadFileHandle } from "src/api/generated/common/frontendDownloadFileHandle";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace ShopOrderBatchActionApi {
	export async function releaseShopOrderBatch(args: { shopOrderBatchId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/shopOrderBatch/releaseShopOrderBatch",
			method: "POST",
			data: {
				shopOrderBatchId: args.shopOrderBatchId,
			},
		});
		return response as void;
	}

	export async function revertBatchStateToPlanned(args: { shopOrderBatchId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/shopOrderBatch/revertBatchStateToPlanned",
			method: "POST",
			data: {
				shopOrderBatchId: args.shopOrderBatchId,
			},
		});
		return response as void;
	}

	export async function revertBatchStateToInitial(args: { shopOrderBatchId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/shopOrderBatch/revertBatchStateToInitial",
			method: "POST",
			data: {
				shopOrderBatchId: args.shopOrderBatchId,
			},
		});
		return response as void;
	}

	export async function cancelBatch(args: { shopOrderBatchId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/shopOrderBatch/cancelBatch",
			method: "POST",
			data: {
				shopOrderBatchId: args.shopOrderBatchId,
			},
		});
		return response as void;
	}

	export async function releaseBatchShopOrderLinesWithEarmarkedAcquisition(args: {
		shopOrderBatchId: number;
	}): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/shopOrderBatch/releaseBatchShopOrderLinesWithEarmarkedAcquisition",
			method: "POST",
			data: {
				shopOrderBatchId: args.shopOrderBatchId,
			},
		});
		return response as void;
	}

	export async function printShopOrderBatchWorkOrder(args: {
		shopOrderBatchId: number;
	}): Promise<FrontendDownloadFileHandle> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/shopOrderBatch/printShopOrderBatchWorkOrder",
			method: "POST",
			data: {
				shopOrderBatchId: args.shopOrderBatchId,
			},
		});
		return response as FrontendDownloadFileHandle;
	}

	export async function startShopOrderBatchUserWorkcenterOperations(args: {
		shopOrderBatchId: number;
	}): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/shopOrderBatch/startShopOrderBatchUserWorkcenterOperations",
			method: "POST",
			data: {
				shopOrderBatchId: args.shopOrderBatchId,
			},
		});
		return response as void;
	}

	export async function completeShopOrderBatchUserWorkcenterOperations(args: {
		shopOrderBatchId: number;
	}): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/shopOrderBatch/completeShopOrderBatchUserWorkcenterOperations",
			method: "POST",
			data: {
				shopOrderBatchId: args.shopOrderBatchId,
			},
		});
		return response as void;
	}
}
