import { useGenericDialog } from "src/components/common/dialogs/useGenericDialog.ts";
import { AavoButton } from "src/components/common/buttons/AavoButton.tsx";
import { faPlus } from "@fortawesome/pro-regular-svg-icons";
import i18n from "i18next";
import { ConfiguratorTableTabForm } from "src/components/views/erp/configurator/tables/revisionContentView/ConfiguratorTableTabForm.tsx";
import { openFormOnDialog } from "src/components/common/dialogs/formDialog/openFormOnDialog.ts";

export interface NewConfiguratorTableTabButtonProps {
	configuratorTableRevisionId: number;
	refreshTabs: () => Promise<unknown>;
}

export const NewConfiguratorTableTabButton = ({
	configuratorTableRevisionId,
	refreshTabs,
}: NewConfiguratorTableTabButtonProps) => {
	const { openDialog } = useGenericDialog();

	return (
		<AavoButton
			icon={faPlus}
			iconProps={{ size: "sm" }}
			sx={{
				marginLeft: 0.5,
				alignSelf: "stretch",
			}}
			disableTooltipEl
			onClick={() => {
				openFormOnDialog({
					openDialog,
					title: i18n.t("new_tab"),
					size: "sm",
					component: ConfiguratorTableTabForm,
					props: {
						configuratorTableRevisionId,
					},
					onSubmit: refreshTabs,
					confirmCloseIfEdited: false
				});
			}}
		/>
	);
};
