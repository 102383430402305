import { AppUser } from "src/api/generated/postgres/db/types/role_management/tables/appUser.ts";
import { UserApi } from "src/api/generated/users/api/userApi.ts";
import { PartialBy } from "src/utils/partialBy.ts";
import {
	FormAsyncMultiSelectField,
	FormAsyncMultiSelectFieldProps,
} from "src/components/common/forms/fields/FormAsyncMultiselectField";

export interface FormAsyncUserMultiSelectFieldProps<TFieldValues extends object>
	extends PartialBy<
		FormAsyncMultiSelectFieldProps<TFieldValues, AppUser, number>,
		"fetchOptions" | "getOptionKey" | "getOptionLabel"
	> {}

export const FormAsyncUserMultiSelectField = <TFieldValues extends object>({
	...other
}: FormAsyncUserMultiSelectFieldProps<TFieldValues>) => {
	return (
		<FormAsyncMultiSelectField<TFieldValues, AppUser, number>
			fetchOptions={({ searchQuery }) =>
				UserApi.getUserSelectionOptions({
					searchQuery: searchQuery,
					currentSelectionUserId: null,
				})
			}
			getOptionKey={(option) => option.id}
			getOptionLabel={(option) => option.name}
			{...other}
		/>
	);
};
