import { Box, Typography } from "@mui/material";
import { InvoiceView } from "src/api/generated/erp/db/types/tables/invoiceView.ts";
import i18n from "i18next";
import { formatIsoString } from "src/utils/dayjsUtils.ts";
import React from "react";

export interface InvoiceContentViewProps {
	invoice: InvoiceView;
}

interface Section {
	title: string;
	fields: (Field | "break")[];
}

interface Field {
	label: string;
	value: React.ReactNode;
}

export const InvoiceContentView = ({ invoice }: InvoiceContentViewProps) => {
	const sections: Section[] = [
		{
			title: "",
			fields: [
				{
					label: i18n.t("number_shortened"),
					value: invoice.invoiceId.toString(),
				},
				{
					label: i18n.t("reference_number"),
					value: invoice.referenceNumber,
				},
				{
					label: i18n.t("order_number"),
					value: invoice.customerOrderId ?? "-",
				},
				{
					label: i18n.t("order_reference"),
					value: invoice.orderReference,
				},
				{
					label: i18n.t("customer_po_no"),
					value: invoice.customerPoNo,
				},
				{
					label: i18n.t("external_id"),
					value: invoice.externalId ?? "-",
				},
				{
					label: i18n.t("invoice_date"),
					value: formatIsoString(invoice.invoiceDate),
				},
				{
					label: i18n.t("created_by"),
					value: invoice.createdByUserName,
				},
				{
					label: i18n.t("created_at"),
					value: formatIsoString(invoice.createdAt),
				},
				"break",
				{
					label: i18n.t("delivery_method"),
					value: invoice.deliveryMethod,
				},
				{
					label: i18n.t("delivery_term"),
					value: invoice.deliveryTerm,
				},
				{
					label: i18n.t("delivery_term_destination"),
					value: invoice.deliveryTermsDestination,
				},
				{
					label: i18n.t("delivered"),
					value: formatIsoString(invoice.deliveryDate),
				},
				{
					label: i18n.t("approved"),
					value: formatIsoString(invoice.billingPlanApprovalDate),
				},
				{
					label: i18n.t("due_date"),
					value: formatIsoString(invoice.dueDate),
				},
				{
					label: i18n.t("penalty_interest"),
					value: invoice.penaltyInterest ?? "-",
				},
				{
					label: i18n.t("remind_days"),
					value: invoice.remindDays,
				},
			],
		},
		{
			title: i18n.t("customer"),
			fields: [
				{
					label: i18n.t("name"),
					value: invoice.customerName,
				},
				{
					label: i18n.t("business_id"),
					value: invoice.customerBusinessId,
				},
				{
					label: i18n.t("vat_no"),
					value: invoice.customerVatNo,
				},
			],
		},
		{
			title: i18n.t("amount"),
			fields: [
				{
					label: i18n.t("sum_without_vat"),
					value: invoice.totalAmount + " " + invoice.currency,
				},
				{
					label: i18n.t("vat"),
					value: invoice.totalVat + " " + invoice.currency,
				},
				{
					label: i18n.t("sum_with_vat"),
					value: invoice.totalAmountWithVat + " " + invoice.currency,
				},
			],
		},
		{
			title: i18n.t("delivery_address"),
			fields: [
				{
					label: i18n.t("name"),
					value: invoice.deliveryAddressName,
				},
				{
					label: i18n.t("street_address"),
					value: invoice.deliveryAddressAddress_1,
				},
				{
					label: i18n.t("street_address_2"),
					value: invoice.deliveryAddressAddress_2,
				},
				{
					label: i18n.t("postal_code"),
					value: invoice.deliveryAddressPostalCode,
				},
				{
					label: i18n.t("city"),
					value: invoice.deliveryAddressCity,
				},
			],
		},
	];

	return (
		<Box
			sx={{
				flex: 1,
				display: "flex",
				flexDirection: "column",
				gap: 1,
				padding: 1,
				overflow: "auto",
			}}
		>
			{sections.map((section, index) => (
				<Box
					key={index}
					sx={{
						display: "flex",
						flexDirection: "column",
						minHeight: "min-content",
					}}
				>
					<Typography variant={"h6"}>{section.title}</Typography>
					{section.fields.map((field, index) =>
						field === "break" ? (
							<br key={index} />
						) : (
							<Box
								key={index}
								sx={{
									display: "flex",
									alignItems: "center",
									gap: 1,
									minHeight: "min-content",
								}}
							>
								<Typography
									sx={{
										fontWeight: "bold",
									}}
								>
									{field.label}:
								</Typography>
								<Typography>{field.value}</Typography>
							</Box>
						),
					)}
				</Box>
			))}
		</Box>
	);
};
