import {
	OpenFormOnDialogParams,
	openFormOnDialog,
} from "src/components/common/dialogs/formDialog/openFormOnDialog.ts";
import { AavoDialogSize } from "src/components/common/dialogs/AavoDialog.tsx";

export interface CreateFormDialogFunctionParams<TComponentProps, TResult>
	extends Omit<OpenFormOnDialogParams<TComponentProps, TResult>, "openDialog" | "props" | "onSubmit"> {}

export type FormDialogFunction<TComponentProps, TResult> = (
	params: Pick<OpenFormOnDialogParams<TComponentProps, TResult>, "openDialog" | "props" | "onSubmit"> & {
		title?: string;
		size?: AavoDialogSize;
	},
) => void;

export function createFormDialogFunction<TComponentProps, TResult>(
	componentParams: CreateFormDialogFunctionParams<TComponentProps, TResult>,
): FormDialogFunction<TComponentProps, TResult> {
	return (callerParams) => {
		return openFormOnDialog({
			...componentParams,
			...callerParams,
		});
	};
}
