import * as Sentry from "@sentry/react";
import i18n from "i18next";

export const showErrorReportDialog = (eventId: string) => {
	Sentry.showReportDialog({
		eventId: eventId,
		lang: i18n.language,
		title: i18n.t("error_report_dialog_title"),
		subtitle: i18n.t("error_report_dialog_subtitle"),
		subtitle2: i18n.t("error_report_dialog_subtitle2"),
		labelName: i18n.t("name"),
		labelComments: i18n.t("what_happened"),
		errorGeneric: i18n.t("error_report_dialog_error_generic"),
		labelClose: i18n.t("close"),
		labelSubmit: i18n.t("send_crash_report"),
		successMessage: i18n.t("error_report_dialog_success_message"),
		// tabindex on MUI dialog makes the report dialog non-focusable.
		// Remove tabindex attr from possible dialogs when report dialog is opened.
		onLoad: () => {
			const muiDialogContainer = getMuiDialogContainer();
			if (muiDialogContainer) muiDialogContainer.removeAttribute("tabindex");
		},
		onClose: () => {
			const muiDialogContainer = getMuiDialogContainer();
			if (muiDialogContainer) muiDialogContainer.tabIndex = -1;
		}
	});
};

const getMuiDialogContainer = (): HTMLDivElement | null => {
	return document.querySelector<HTMLDivElement>(".MuiDialog-root .MuiDialog-container");
};
