// This file is automatically generated. Do not edit manually.

import { PartConfiguratorType } from "src/api/generated/erp/configurator/configuratorType/partConfiguratorType";
import { FrontendDownloadFileHandle } from "src/api/generated/common/frontendDownloadFileHandle";
import { ConfiguratorLogContext_ConfigurationResolving_Phase } from "src/api/generated/erp/configurator/logging/configuratorLogContext";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace ConfiguratorLogApi {
	export async function getConfiguratorSessionLogFile(args: {
		sessionId: string;
		configuratorType: PartConfiguratorType;
	}): Promise<FrontendDownloadFileHandle> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/configurator/logs/getConfiguratorSessionLogFile",
			method: "POST",
			data: {
				sessionId: args.sessionId,
				configuratorType: args.configuratorType,
			},
		});
		return response as FrontendDownloadFileHandle;
	}

	export async function getTestConfigurationPhaseResolvingLog(args: {
		sessionId: string;
		phase: ConfiguratorLogContext_ConfigurationResolving_Phase;
		configuratorType?: PartConfiguratorType | null | undefined;
	}): Promise<FrontendDownloadFileHandle> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/configurator/logs/getTestConfigurationPhaseResolvingLog",
			method: "POST",
			data: {
				sessionId: args.sessionId,
				phase: args.phase,
				configuratorType: args.configuratorType,
			},
		});
		return response as FrontendDownloadFileHandle;
	}
}
