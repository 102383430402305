// This file is automatically generated. Do not edit manually.

import { t } from "i18next";

export const CustomerOrderLineStateValues = [
	"INITIAL",
	"RELEASED",
	"RESERVED",
	"PICKED",
	"PARTIALLY_DELIVERED",
	"DELIVERED",
	"INVOICED",
	"CANCELLED",
] as const;

export type CustomerOrderLineState = (typeof CustomerOrderLineStateValues)[number];

export const getCustomerOrderLineStateLabels = () => ({
	INITIAL: t("initial"),
	RELEASED: t("released"),
	RESERVED: t("reserved"),
	PICKED: t("picked"),
	PARTIALLY_DELIVERED: t("partially_delivered"),
	DELIVERED: t("delivered"),
	INVOICED: t("invoiced"),
	CANCELLED: t("cancelled"),
});

export const getCustomerOrderLineStateLabel = (value: CustomerOrderLineState): string => {
	return getCustomerOrderLineStateLabels()[value];
};
