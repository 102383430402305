import { AavoMosaic } from "src/components/common/mosaic/AavoMosaic.tsx";
import i18n from "i18next";
import { CatalogPartSelectionDataGrid } from "src/components/views/erp/parts/catalogPart/catalogPartRevisionSelection/CatalogPartSelectionDataGrid.tsx";
import { CatalogPartView } from "src/api/generated/erp/db/types/tables/catalogPartView.ts";
import React, { useState } from "react";
import { CatalogPartRevisionSelectionDataGrid } from "src/components/views/erp/parts/catalogPart/catalogPartRevisionSelection/CatalogPartRevisionSelectionDataGrid.tsx";
import { CenteredTypography } from "src/components/common/CenteredTypography.tsx";
import { CatalogPartRevisionView } from "src/api/generated/erp/db/types/tables/catalogPartRevisionView.ts";

export interface SelectCatalogPartRevisionViewProps {
	actionBarComponents?: (selectedRevisions: CatalogPartRevisionView[]) => React.ReactNode;
}

export const SelectCatalogPartRevisionView = ({
	actionBarComponents,
}: SelectCatalogPartRevisionViewProps) => {
	const [selectedCatalogParts, setSelectedCatalogParts] = useState<CatalogPartView[]>([]);
	const selectedCatalogPartIds = selectedCatalogParts.map((p) => p.catalogPartId);
	return (
		<AavoMosaic
			viewId={"C385B905AD25E850"}
			layout={{
				type: "row",
				first: {
					type: "panel",
					title: i18n.t("catalog_parts"),
					content: <CatalogPartSelectionDataGrid onSelectionChanged={setSelectedCatalogParts} />,
				},
				second: {
					type: "panel",
					title: i18n.t("revisions"),
					content:
						selectedCatalogParts.length === 0 ?
							<CenteredTypography children={i18n.t("select_catalog_parts")} />
						:	<CatalogPartRevisionSelectionDataGrid
								key={selectedCatalogPartIds.join(",")}
								catalogPartIds={selectedCatalogPartIds}
								actionBarComponents={actionBarComponents}
							/>,
				},
			}}
		/>
	);
};
