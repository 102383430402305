import i18n from "i18next";
import { AsyncButton } from "src/components/common/buttons/AsyncButton.tsx";
import { MaybeAsyncFunction } from "src/types/functions.ts";
import { AavoFormFooter } from "src/components/common/forms/AavoFormFooter.tsx";

export interface AavoFormActionButtonsProps {
	onSubmit?: MaybeAsyncFunction<void, unknown>;
	onCancel?: MaybeAsyncFunction<void, unknown>;
	submitLabel?: string;
	disableSubmit?: boolean;
}

export const AavoFormActionButtons = ({
	onSubmit,
	onCancel,
	submitLabel,
	disableSubmit = false,
}: AavoFormActionButtonsProps) => {
	if (!onSubmit && !onCancel) {
		return null;
	}

	return (
		<AavoFormFooter>
			{onCancel && (
				<AsyncButton
					color={"secondary"}
					onClick={async () => {
						await onCancel();
					}}
					label={i18n.t("cancel")}
				/>
			)}
			{onSubmit && (
				<AsyncButton
					label={submitLabel ?? i18n.t("save")}
					disabled={disableSubmit}
					onClick={async () => {
						await onSubmit();
					}}
				/>
			)}
		</AavoFormFooter>
	);
};
