import {
	PartTreeElement,
	PartTreeElement_Part,
	PartTreeElement_Revision,
} from "src/api/generated/erp/parts/treeViews/partTreeElement.ts";
import { concatWithPipe } from "src/utils/strings.tsx";
import i18n from "i18next";
import dayjs from "dayjs";
import { AavoTreeViewBaseItem } from "src/components/common/tree/AavoTreeView.tsx";

export type ShopOrderBomTreeItemModel = AavoTreeViewBaseItem<
	ShopOrderBomTreeViewBaseProps & (PartTreeElement_Part | PartTreeElement_Revision)
>;

type ShopOrderBomTreeViewBaseProps = {
	id: string;
	label: string;
};

export function mapShopOrderBomTreeData(data: PartTreeElement[]): ShopOrderBomTreeItemModel[] {
	const topLevelItems = data.filter((item) => item.parentKey == null);
	return topLevelItems.map((topLevelItem) => {
		const children = data.filter((item) => item.parentKey === topLevelItem.key);
		return createItem(topLevelItem, children);
	});

	function createItem(bomItem: PartTreeElement, children: PartTreeElement[]): ShopOrderBomTreeItemModel {
		return {
			id: bomItem.key,
			label: getItemLabel(bomItem),
			children: children.map((child) =>
				createItem(
					child,
					data.filter((item) => item.parentKey === child.key),
				),
			),
			...bomItem,
		};
	}
}

function getItemLabel(bomItem: PartTreeElement): string {
	switch (bomItem.type) {
		case "part":
			return getPartLabel(bomItem);
		case "revision":
			return getRevisionLabel(bomItem);
	}
}

function getPartLabel(part: PartTreeElement_Part): string {
	const revisionString =
		part.activeRevisionNumber != null ? `${i18n.t("revision")} ${part.activeRevisionNumber}` : null;
	const quantityString = part.quantity != null ? `${part.quantity} ${part.partUnit}` : null;
	return concatWithPipe(
		part.partNo,
		part.partDescription1,
		part.partDescription2,
		revisionString,
		quantityString,
	);
}

function getRevisionLabel(revision: PartTreeElement_Revision): string {
	return (
		`${i18n.t("revision")} ${revision.revisionNumber}, ` +
		`${revision.stateLabel}, ` +
		`${dayjs(revision.dateToShow).format("D.M.YYYY HH:mm")}`
	);
}
