import {
	OperationApi,
	OperationApi_FormInitData,
} from "src/api/generated/erp/production/basedata/operations/api/operationApi.ts";
import { FormCommonProps } from "src/components/common/forms/types.ts";
import { AsyncForm, AsyncFormContentParams } from "src/components/common/forms/AsyncForm.tsx";
import { DeepPartial, FieldPath } from "react-hook-form";
import { Operation } from "src/api/generated/erp/db/types/tables/operation.ts";
import { useTenantCustomizations } from "src/tenantCustomizations/useTenantCustomizations.tsx";
import { useGlobalInitData } from "src/contexts/useGlobalInitData.ts";
import { requireRule } from "src/components/common/forms/validation.ts";
import { FormTextField } from "src/components/common/forms/fields/FormTextField.tsx";
import { concatWithPipe } from "src/utils/strings.tsx";
import { FormCheckbox } from "src/components/common/forms/fields/FormCheckbox.tsx";
import i18n from "i18next";
import { FormSelectField } from "src/components/common/forms/fields/FormSelectField.tsx";
import { FormNumberField } from "src/components/common/forms/fields/FormNumberField.tsx";

export interface OperationFormProps extends FormCommonProps<number> {
	siteId: number;
	operationId: number | undefined;
}

interface FormValues extends Operation {}

export const OperationForm = (props: OperationFormProps) => {
	const { siteId, operationId, onCompleted, onFormEdited } = props;
	return (
		<AsyncForm
			fetch={() =>
				OperationApi.getFormInitData({
					siteId,
					operationId,
				})
			}
			getDefaultValues={getDefaultValues}
			submit={submit}
			onCompleted={onCompleted}
			onFormEdited={onFormEdited}
			columns={2}
			render={(contentParams) => <FormContent {...props} {...contentParams} />}
		/>
	);

	function getDefaultValues({ operation }: OperationApi_FormInitData): DeepPartial<FormValues> {
		return (
			operation ?? {
				siteId: siteId,
			}
		);
	}

	async function submit(values: FormValues) {
		if (operationId == null) {
			return await OperationApi.insert({ operation: values });
		} else {
			await OperationApi.update({ operation: values });
			return operationId;
		}
	}
};

interface FormContentProps extends OperationFormProps, AsyncFormContentParams<OperationApi_FormInitData, FormValues> {}

const FormContent = (props: FormContentProps) => {
	const {
		operationId,
		control,
		watch,
		data: { surveyFormOptions, conwipReleasingOperationOptions },
	} = props;

	const { tenantConfig } = useTenantCustomizations();
	const { userSites } = useGlobalInitData();

	const isExistingRecord = operationId != null;

	return (
		<>
			<FormSelectField
				control={control}
				name={"siteId"}
				label={i18n.t("site")}
				options={userSites}
				getOptionKey={(site) => site.siteId}
				getOptionLabel={(site) => site.siteName}
				disabled={isExistingRecord}
				rules={requireRule()}
				disableClearable
			/>
			<FormTextField
				control={control}
				name={"operationNo"}
				label={i18n.t("operation_no")}
				rules={requireRule()}
			/>
			<FormTextField
				control={control}
				name={"operationDescription"}
				label={i18n.t("description")}
				rules={requireRule()}
				spanGridColumns
			/>
			{tenantConfig.erp.surveysEnabled && (
				<>
					<FormSelectField
						control={control}
						name={"surveyFormId"}
						label={i18n.t("survey_form")}
						options={surveyFormOptions}
						getOptionKey={(option) => option.surveyFormId}
						getOptionLabel={(option) => option.name}
					/>
					<FormCheckbox control={control} name={"surveyRequired"} label={i18n.t("survey_required")} />
				</>
			)}
			<ControlChartSelectionField name={"thControlChart"} label={i18n.t("throughput_control_chart")} {...props} />
			<ControlChartSelectionField name={"ctControlChart"} label={i18n.t("cycle_time_control_chart")} {...props} />
			<ControlChartSelectionField name={"wipControlChart"} label={i18n.t("wip_control_chart")} {...props} />
			<FormNumberField
				control={control}
				name={"conwipLimit"}
				label={i18n.t("conwip_limit")}
				type={"integer"}
				startNewGridRow
			/>
			{watch("conwipLimit") != null && (
				<FormSelectField
					control={control}
					name={"conwipReleasingOperationId"}
					label={i18n.t("conwip_releasing_operation")}
					options={conwipReleasingOperationOptions}
					getOptionKey={(option) => option.operationId}
					getOptionLabel={(option) => option.operationLongName}
				/>
			)}
		</>
	);
};

interface ControlChartSelectionFieldProps extends FormContentProps {
	label: string;
	name: FieldPath<FormValues>;
}

const ControlChartSelectionField = ({
	control,
	name,
	label,
	data: { controlChartOptions },
}: ControlChartSelectionFieldProps) => {
	return (
		<FormSelectField
			control={control}
			name={name}
			label={label}
			options={controlChartOptions}
			getOptionKey={(option) => option.chartId}
			getOptionLabel={(option) =>
				concatWithPipe(option.chartSuperGroupName, option.chartGroupName, option.chartName)
			}
		/>
	);
};
