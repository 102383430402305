import {ClientSideDataGridModel} from "src/components/common/dataGrid/gridModel/ClientSideDataGridModel.tsx";
import {
    SalesContractSalesPartsDataGridApi
} from "src/api/generated/erp/sales/pricing/api/salesContractSalesPartsDataGridApi.ts";
import {genericNullableValue} from "src/utils/genericNullableValue.ts";
import {floatColumn, textColumn} from "src/components/common/dataGrid/columns.tsx";
import i18n from "i18next";
import {SalesContractSalesPartView} from "src/api/generated/erp/db/types/tables/salesContractSalesPartView.ts";
import {CrudDataGrid} from "src/components/common/dataGrid/crud/CrudDataGrid.tsx";
import {AavoTextField} from "src/components/common/inputFields/AavoTextField.tsx";
import {SelectField} from "src/components/common/inputFields/SelectField.tsx";
import {AsyncButton} from "src/components/common/buttons/AsyncButton.tsx";
import {faLink} from "@fortawesome/pro-regular-svg-icons";
import {
    SalesContractSalesPartPriceForm
} from "src/components/views/erp/sales/pricing/salesContracts/SalesContractSalesPartPriceForm.tsx";
import {openFormOnDialog} from "src/components/common/dialogs/formDialog/openFormOnDialog.ts";
import {
    AddSalesPartsToSalesContractDataGrid
} from "src/components/views/erp/sales/pricing/salesContracts/AddSalesPartsToSalesContractDataGrid.tsx";
import {useGenericDialog} from "src/components/common/dialogs/useGenericDialog.ts";
import {calculateSalesMarginPercent} from "src/components/views/erp/sales/salesUtils.ts";
import {formatNumber} from "src/utils/numberUtils.ts";

export interface SalesContractSalesPartsDataGridProps {
	salesContractId: number;
}

export const SalesContractSalesPartsDataGrid = ({ salesContractId }: SalesContractSalesPartsDataGridProps) => {
	const { openDialog } = useGenericDialog();
	return (
		<ClientSideDataGridModel
			gridId={"F14FB46DAC08A7AB"}
			fetchData={(params) =>
				SalesContractSalesPartsDataGridApi.getSalesContractSalesParts({
					salesContractId: salesContractId,
					...params,
				})
			}
			getRows={(data) => data.salesParts}
			getRowId={(row) => row.salesContractSalesPartId}
			initialParams={{
				searchQuery: "",
				siteId: genericNullableValue<number>(null),
			}}
			render={({ data, dataGridProps, refreshData }) => {
				return (
					<CrudDataGrid<SalesContractSalesPartView>
						columns={[
							textColumn({
								field: "siteName",
								headerName: i18n.t("site"),
							}),
							textColumn({
								field: "salesPartNo",
								headerName: i18n.t("sales_part_no"),
							}),
							textColumn({
								field: "partDescription_1",
								headerName: i18n.t("description_1"),
								width: 150,
							}),
							textColumn({
								field: "partDescription_2",
								headerName: i18n.t("description_2"),
								width: 150,
							}),
							floatColumn({
								field: "price",
								headerName: i18n.t("price"),
							}),
							floatColumn({
								field: "standardCostAsPriceUnits",
								headerName: i18n.t("cost"),
							}),
							textColumn({
								field: "salesPriceUnit",
								headerName: i18n.t("price_unit"),
							}),
							textColumn({
								field: "profitMargin" as any,
								headerName: i18n.t("profit_margin"),
								valueGetter: (_, row) => {
									const value = formatNumber(
										calculateSalesMarginPercent({
											cost: row.standardCostAsPriceUnits ?? 0,
											salesPrice: row.price,
										}),
									);
									return `${value} %`;
								},
							}),
						]}
						actionBarComponents={
							<>
								<AavoTextField
									label={i18n.t("search")}
									onSubmit={async (input) => {
										await refreshData({ searchQuery: input });
									}}
								/>
								<SelectField
									label={i18n.t("site")}
									options={data.siteOptions}
									getOptionKey={(o) => o.siteId}
									getOptionLabel={(o) => o.siteName}
									onChange={async (value) => {
										await refreshData({ siteId: value });
									}}
									minWidth={150}
								/>
								<AsyncButton
									icon={faLink}
									label={i18n.t("connect_parts")}
									variant={"outlined"}
									onClick={() => {
										openFormOnDialog({
											openDialog,
											component: AddSalesPartsToSalesContractDataGrid,
											title: i18n.t("connect_parts"),
											size: "lg",
											props: (closeDialog) => ({
												salesContractId: salesContractId,
												onSubmit: async () => {
													await refreshData();
													await closeDialog();
												},
											}),
										});
									}}
								/>
							</>
						}
						form={{
							addRowEnabled: false,
							editEnabled: true,
							dialogSize: "sm",
							dialogTitle: i18n.t("edit_price"),
							component: ({ row, onCompleted }) => {
								if (row == null) return null;

								return (
									<SalesContractSalesPartPriceForm
										salesContractSalesPartId={row.salesContractSalesPartId}
										price={row.price}
										onCompleted={onCompleted}
									/>
								);
							},
						}}
						remove={{
							type: "enabled",
							action: async ({ items }) => {
								if (items?.[0] == null) return;
								await SalesContractSalesPartsDataGridApi.delete_({
									salesContractSalesPartId: items[0].salesContractSalesPartId,
								});
							},
						}}
						{...dataGridProps}
					/>
				);
			}}
		/>
	);
};
