import { AsyncDataGrid } from "src/components/common/dataGrid/AsyncDataGrid";
import { TestConfigurationResultViewCommonProps } from "../TestConfigurationResultViewCommonProps";
import {
	TestConfigurationDocumentsApi,
	TestConfigurationDocumentsApi_DocumentDto,
} from "src/api/generated/erp/configurator/api/configuratorTesting/testConfigurationDocumentsApi.ts";
import { textColumn } from "src/components/common/dataGrid/columns";
import i18n from "i18next";
import { dataGridPersistentStateProps } from "src/components/common/dataGrid/dataGridStateStorage.ts";

export const TestConfigurationDocumentResultsDataGrid = ({
	configurationSessionId,
	configuratorType,
	propertyValues,
}: TestConfigurationResultViewCommonProps) => {
	return (
		<AsyncDataGrid<TestConfigurationDocumentsApi_DocumentDto>
			fetchRows={() =>
				TestConfigurationDocumentsApi.getTestConfigurationDocuments({
					configurationSessionId,
					configuratorType,
					propertyValues,
				})
			}
			getRowId={(row) => row.uniqueId}
			columns={[
				textColumn({
					headerName: i18n.t("description"),
					field: "description",
					width: 300,
				}),
				textColumn({
					headerName: i18n.t("category"),
					field: "categoryName",
					width: 200,
				}),
			]}
			{...dataGridPersistentStateProps("AE602DFB73807646")}
		/>
	);
};
