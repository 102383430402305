import { AsyncFunction } from "src/types/functions";
import { AsyncRender, AsyncRenderProps } from "./AsyncRender";
import { useAsyncFetch } from "src/utils/async/asyncFetch.ts";
import { RefreshableElementProps, setRefreshRefValue } from "src/utils/useRefreshRef.ts";
import React from "react";

export interface AsyncFetchRenderProps<T>
	extends RefreshableElementProps,
		Omit<AsyncRenderProps<T>, "asyncData" | "content"> {
	fetch: AsyncFunction<void, T>;
	defaultValue?: T;
	content: (data: T, reload: () => Promise<T>) => React.ReactNode;
}

export const AsyncFetchRender = <Data,>({
	fetch,
	content,
	refreshRef,
	defaultValue,
	...other
}: AsyncFetchRenderProps<Data>) => {
	const [asyncState, reload] = useAsyncFetch(fetch, {
		defaultValue: defaultValue,
		fetchOnMount: defaultValue === undefined,
	});

	setRefreshRefValue(refreshRef, reload);

	return (
		<AsyncRender
			asyncData={asyncState}
			reloadOnError={reload}
			content={(data) => content(data, reload)}
			{...other}
		/>
	);
};
