import "@webcomponents/webcomponentsjs";
import "./webComponents/agGrid/agGridWebComponent";
import "./webComponents/highChartsWebComponent";
import "./webComponents/flatpickrWebComponent";
import "./webComponents/goldenLayoutWebComponent";
import "./webComponents/pdfObjectWebComponent";
import "./webComponents/openSeadragonWebComponent";
import "./webComponents/wopiFileWebComponent";
import "./webComponents/threeJsWebComponent";
import "./webComponents/quickSightWebComponent";
import "./webComponents/zxingJsWebComponent";
import "./webComponents/mermaidWebComponent";
import "./webComponents/selectizeWebComponent";
import "./webComponents/fullCalendarWebComponent";
import "./webComponents/customHtmlWebComponent";
import "./webComponents/codeMirrorWebComponent";
import { registerInputFieldWebComponent } from "./webComponents/elmComponents/inputFieldComponent";
import { registerKappaViewWebComponent } from "./webComponents/elmComponents/kappaViewWebComponent";
import { fixFirefoxDragDrop } from "./utils";
import { registerHandlebarsHelpers } from "./handlebarsHelpers";
import { setupDomPurify } from "./domPurifySetup";
import { fireLegacyAppContentAreaChangedEvent } from "src/legacyViews/js/appContentAreaChangedEvent";
import i18next from "i18next";
import * as luxon from 'luxon';

const userLang = i18next.language;

registerInputFieldWebComponent(userLang);
registerKappaViewWebComponent(userLang);
registerHandlebarsHelpers();
fixFirefoxDragDrop();
setupDomPurify();
luxon.Settings.defaultLocale = userLang

window.addEventListener("resize", () => {
	fireLegacyAppContentAreaChangedEvent();
});
