import React, { useLayoutEffect, useRef, useEffect } from "react";
import { isMobileOrTablet } from "./js/utils";

export const ElmKappaView = ({
	startUpData,
	viewDefinition,
	isOnForeground,
	isUppermostView,
	dragDropData,
	handleDragDropDataChanged,
	handleActionEventsRaised,
	incomingViewActionEvents,
	incomingPushAction,
	onPushActionHandled,
	localSettings,
	environmentId,
	branchKey,
	identityId,
	sourceViewId,
	viewParams,
	viewState,
	allViewStates
}) => {
	let viewsByIds = startUpData.views;
	const viewComponentRef = useRef();

	// Effect before aavo view first render
	useLayoutEffect(() => {
		if (viewComponentRef.current) {
			// ----------- 1. Set flags (= aavo view initial state) -----------
			viewComponentRef.current.flags = {
				viewDefinition: viewDefinition,
				allViewsByIds: viewsByIds,
				allViewStates: allViewStates, // kaikki viewStatet jotka löytyy localstoragesta, säiliönäkymä tarvii
				viewConfig: {
					localSettings: localSettings,
					isMobileDevice: isMobileOrTablet(),
					screenSize: {
						width: window.innerWidth,
						height: window.innerHeight,
					},
					isTopLevelView: !sourceViewId, // jos sourceViewId on null -> true, modaleille false
					timeZoneId:
						Intl.DateTimeFormat().resolvedOptions().timeZone,
					selectedEnvironmentAssociationId: environmentId,
					selectedBranchKey: branchKey,
					selectedIdentityId: identityId,
					defaultParameters: startUpData.defaultParameters,
					viewStaticParams: viewDefinition.view.staticParams,
					viewParams: viewParams, // actionObjektissa on allParams kun avataan modal, muille näkymille tyhjä
					viewState: viewState, // puunäkymän tila local storagessa
					viewId: viewDefinition.view.id,
					parentViewId: null, // aina null
					parentViewPermissions: null, // aina null
					sourceViewId: sourceViewId, // näkymä josta modal view avattiin, löytyy viewActionEventistä -> senderViewId
					parameterSeparators: startUpData.codeSeparators,
					frontendCommitHash: "xxx", // ei toteuteta vielä
				},
			};
			viewComponentRef.current.isOnForeground = isOnForeground;
			viewComponentRef.current.isUppermostView = isUppermostView;
			viewComponentRef.current.dragDropData = dragDropData;
			viewComponentRef.current.incomingPushAction = incomingPushAction;

			// ----------- 2. Add event handlers -----------
			viewComponentRef.current.addEventListener(
				"viewActionEventsRaised",
				handleActionEventsRaised
			);

			viewComponentRef.current.addEventListener(
				"dragDropDataChanged",
				handleDragDropDataChanged
			);

			viewComponentRef.current.addEventListener(
				"pushActionHandled",
				onPushActionHandled
			);

			viewComponentRef.current.setAttribute(
				"viewId",
				viewDefinition.view.id
			);

			// ----------- 3. Effect cleanup, all event handlers should be removed here -----------
			return () => {
				viewComponentRef.current.removeEventListener(
					"viewActionEventsRaised",
					handleActionEventsRaised
				);
				viewComponentRef.current.removeEventListener(
					"dragDropDataChanged",
					handleDragDropDataChanged
				);
				viewComponentRef.current.removeEventListener(
					"pushActionHandled",
					onPushActionHandled
				);
			};
		}
	});

	// ----------- 4. Update properties -----------

	// Update isOnForeground
	useEffect(() => {
		if (viewComponentRef.current) {
			// Set display none to all pages that are not foreground.
			if (!isOnForeground && isOnForeground !== undefined) {
				viewComponentRef.current.setAttribute("style", "display:none");
			} else if (isOnForeground !== undefined) {
				// fireLegacyAppContentAreaChangedEvent();
				viewComponentRef.current.removeAttribute("style");
			}
			viewComponentRef.current.isOnForeground = isOnForeground;
		}
	}, [isOnForeground]);

	// Update isUppermostView
	useEffect(() => {
		if (viewComponentRef.current) {
			viewComponentRef.current.isUppermostView = isUppermostView;
		}
	}, [isUppermostView]);

	// Update incomingActionEvents
	useEffect(() => {
		if (viewComponentRef.current) {
			viewComponentRef.current.incomingViewActionEvents =
				incomingViewActionEvents;
		}
	}, [incomingViewActionEvents]);


	// Update incomingPushAction
	useEffect(() => {
		if (viewComponentRef.current) {
			viewComponentRef.current.incomingPushAction = incomingPushAction;
		}
	}, [incomingPushAction]);

	// Update dragDropData
	useEffect(() => {
		if (viewComponentRef.current) {
			viewComponentRef.current.dragDropData = dragDropData;
		}
	}, [dragDropData]);

	// ----------- 5. Render aavo-view web component -----------
	return <aavo-view ref={viewComponentRef} viewId={viewDefinition.view.id} />;
};
