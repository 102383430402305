import { SurveySubmissionObjectType } from "src/api/generated/erp/db/types/enums/surveySubmissionObjectType.ts";
import { ClientSideDataGridModel } from "src/components/common/dataGrid/gridModel/ClientSideDataGridModel.tsx";
import { ObjectSurveySubmissionApi } from "src/api/generated/erp/surveys/api/objectSurveySubmissionApi.ts";
import { AavoDataGrid } from "src/components/common/dataGrid/AavoDataGrid.tsx";
import { SurveySubmissionView } from "src/api/generated/erp/db/types/tables/surveySubmissionView.ts";
import { getSurveySubmissionDataGridColumns } from "src/components/views/erp/surveys/surveyUtils.ts";
import { faPrint } from "@fortawesome/pro-regular-svg-icons";
import { AsyncButton } from "src/components/common/buttons/AsyncButton.tsx";
import { SurveySubmissionPrintingApi } from "src/api/generated/erp/surveys/api/surveySubmissionPrintingApi.ts";
import { downloadFile } from "src/utils/fileDownloading.ts";
import i18n from "i18next";

export interface SurveySubmissionsOfObjectsDataGridProps {
	objectType: SurveySubmissionObjectType;
	objectIds: number[];
	onSelectionChanged?: (selectedRow: SurveySubmissionView | undefined) => void;
}

export const SurveySubmissionsOfObjectsDataGrid = ({
	objectType,
	objectIds,
	onSelectionChanged,
}: SurveySubmissionsOfObjectsDataGridProps) => {
	return (
		<ClientSideDataGridModel
			fetchData={() =>
				ObjectSurveySubmissionApi.getSurveySubmissionsOfObjects({
					objectType,
					objectIds,
				})
			}
			initialParams={{}}
			getRows={(response) => response}
			getRowId={(row) => row.surveySubmissionId}
			gridId={"463E4CACE9AF349A"}
			onSelectionChanged={(rows) => onSelectionChanged?.(rows[0])}
			selectFirstRowOnLoad={true}
			render={({ dataGridProps, selectedRows }) => (
				<AavoDataGrid
					disableMultipleRowSelection
					columns={getSurveySubmissionDataGridColumns({})}
					actionBarComponents={
						<>
							<AsyncButton
								label={i18n.t("print")}
								icon={faPrint}
								variant={"outlined"}
								disabled={selectedRows.length === 0}
								onClick={async () => {
									const fileHandle = await SurveySubmissionPrintingApi.printSurveySubmissions({
										surveySubmissionIds: selectedRows.map((row) => row.surveySubmissionId),
									});
									downloadFile(fileHandle);
								}}
							/>
						</>
					}
					{...dataGridProps}
				/>
			)}
		/>
	);
};
