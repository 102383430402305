import i18n from "i18next";
import { MenuCheckbox } from "src/components/common/contextMenu/MenuCheckbox.tsx";
import { CustomerOrdersDataGridFilterParams } from "src/components/views/erp/sales/customerOrder/CustomerOrdersDataGrid.tsx";

export interface CustomerOrderDataGridActionBarMenuComponentsProps {
	refreshData: (params?: Partial<CustomerOrdersDataGridFilterParams>) => Promise<unknown>;
	currentParams: CustomerOrdersDataGridFilterParams;
}

export const CustomerOrdersDataGridActionBarMenuComponents = ({
	refreshData,
	currentParams,
}: CustomerOrderDataGridActionBarMenuComponentsProps) => {
	return [
		<MenuCheckbox
			key={"onlyDefaultSite"}
			label={i18n.t("only_default_site")}
			onChange={async (value) => {
				await refreshData({ onlyDefaultSiteOrders: value });
			}}
			checked={currentParams.onlyDefaultSiteOrders}
		/>,
		<MenuCheckbox
			key={"onlyUnconfirmed"}
			label={i18n.t("only_unconfirmed")}
			onChange={async (value) => {
				await refreshData({ showOnlyUnconfirmedOrders: value });
			}}
			checked={currentParams.showOnlyUnconfirmedOrders}
		/>,
		<MenuCheckbox
			key={"onlyFrozen"}
			label={i18n.t("only_frozen")}
			onChange={async (value) => {
				await refreshData({ showOnlyFrozenOrders: value });
			}}
			checked={currentParams.showOnlyFrozenOrders}
		/>,
		<MenuCheckbox
			key={"showInvoiced"}
			label={i18n.t("show_invoiced")}
			onChange={async (value) => {
				await refreshData({ showInvoicedOrders: value });
			}}
			checked={currentParams.showInvoicedOrders}
		/>,
		<MenuCheckbox
			key={"showCancelled"}
			label={i18n.t("show_cancelled")}
			onChange={async (value) => {
				await refreshData({ showCancelledOrders: value });
			}}
			checked={currentParams.showCancelledOrders}
		/>,
	];
};
