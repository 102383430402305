// This file is automatically generated. Do not edit manually.

import { t } from "i18next";

export const ConfigurationProductFamilyVersionStateValues = ["INITIAL", "PUBLISHED"] as const;

export type ConfigurationProductFamilyVersionState = (typeof ConfigurationProductFamilyVersionStateValues)[number];

export const getConfigurationProductFamilyVersionStateLabels = () => ({
	INITIAL: t("initial"),
	PUBLISHED: t("published"),
});

export const getConfigurationProductFamilyVersionStateLabel = (
	value: ConfigurationProductFamilyVersionState,
): string => {
	return getConfigurationProductFamilyVersionStateLabels()[value];
};
