// This file is automatically generated. Do not edit manually.

import { PartConfiguratorType } from "src/api/generated/erp/configurator/configuratorType/partConfiguratorType";
import { ConfigurationPropertyValues } from "src/api/generated/erp/configurator/model/configurationPropertyValues";
import { ObjectAttributeField } from "src/api/generated/erp/common/objectAttributes/objectAttributeField";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace TestConfigurationAttributesApi {
	export async function getTestConfigurationAttributes(args: {
		configurationSessionId: string;
		configuratorType: PartConfiguratorType;
		propertyValues: ConfigurationPropertyValues;
	}): Promise<Array<TestConfigurationAttributesApi_Row>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/configurator/testing/attributes/getTestConfigurationAttributes",
			method: "POST",
			data: {
				configurationSessionId: args.configurationSessionId,
				configuratorType: args.configuratorType,
				propertyValues: args.propertyValues,
			},
		});
		return response as Array<TestConfigurationAttributesApi_Row>;
	}
}

export interface TestConfigurationAttributesApi_Row {
	attributeField: ObjectAttributeField;
	attributeValue: string | null | undefined;
}
