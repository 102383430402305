// This file is automatically generated. Do not edit manually.

import { ServerSideDataModelRequest } from "src/api/generated/common/dataGrids/serverSideDataModelRequest";
import { ServerSideDataModelResult } from "src/api/generated/common/dataGrids/serverSideDataModelResult";
import { InvoiceView } from "src/api/generated/erp/db/types/tables/invoiceView";
import { InvoiceLine } from "src/api/generated/erp/db/types/tables/invoiceLine";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace InvoiceReadingApi {
	export async function getInvoices(args: {
		customerOrderId?: number | null | undefined;
		onlyInvoicesNotInLedger?: boolean;
		searchQuery?: string;
		dataModelRequest: ServerSideDataModelRequest;
	}): Promise<ServerSideDataModelResult<InvoiceView>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/invoicing/getInvoices",
			method: "POST",
			data: {
				customerOrderId: args.customerOrderId,
				onlyInvoicesNotInLedger: args.onlyInvoicesNotInLedger,
				searchQuery: args.searchQuery,
				dataModelRequest: args.dataModelRequest,
			},
		});
		return response as ServerSideDataModelResult<InvoiceView>;
	}

	export async function getInvoiceLines(args: { invoiceId: number }): Promise<Array<InvoiceLine>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/invoicing/getInvoiceLines",
			method: "POST",
			data: {
				invoiceId: args.invoiceId,
			},
		});
		return response as Array<InvoiceLine>;
	}
}
