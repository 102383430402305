import { Typography } from "@mui/material";
import React from "react";
import { SxProps } from "@mui/system";
import { Theme } from "@mui/material/styles";

export const isNullOrBlank = (value: string | null | undefined): boolean => {
	return value === null || value === undefined || value.trim() === "";
};

export const nullIfBlank = (value: string | null | undefined): string | null => {
	if (isNullOrBlank(value)) return null;
	return value ?? null;
};

export const capitalize = (value: string): string => {
	return value.charAt(0).toUpperCase() + value.slice(1);
};

export const deCapitalize = (value: string): string => {
	return value.charAt(0).toLowerCase() + value.slice(1);
};

export const booleanToString = (value: boolean): "yes" | "no" => {
	if (value) {
		return "yes";
	}
	return "no";
};

export const linebreaksToHtmlBreaks = (value: string, sx?: SxProps<Theme>): React.ReactNode[] => {
	const parts = value.split("\n");
	return parts.map((row, index) => (
		<Typography key={index} sx={sx}>
			{row}
		</Typography>
	));
};

export const camelToSnakeCase = (value: string) =>
	value.replace(/[A-Z]/g, (letter, index) => {
		return index === 0 ? letter.toLowerCase() : "_" + letter.toLowerCase();
	});

export const removeFileExtension = (fileName: string) => fileName.replace(/\.[^/.]+$/, "");

export const containsIgnoreCase = (value: string | undefined | null, search: string) => {
	if (value == undefined) return false;

	return value.toLowerCase().includes(search.toLowerCase());
};

export const ensureSuffix = (value: string, suffix: string) => {
	if (value.endsWith(suffix)) return value;
	return value + suffix;
};

export const toIntOrNull = (value: string | null | undefined): number | null => {
	const asNumber = toFloatOrNull(value);
	if (!Number.isInteger(asNumber)) return null;
	return asNumber;
};

export const toFloatOrNull = (value: string | null | undefined): number | null => {
	if (nullIfBlank(value) == null) return null;
	const parsed = Number(value);
	if (Number.isNaN(parsed)) return null;
	return parsed;
};

export const concatWithPipe = (...values: (string | number | null | undefined)[]): string => {
	// Drop empty values from end functionally
	const nonEmptyValues = values
		.reverse()
		.filter((v) => {
			if (v == null) return false;
			if (typeof v === "string") return v.trim() !== "";
			return true;
		})
		.reverse();
	return nonEmptyValues.join(" | ");
};

export function getStringFirstLine(value: string): string {
	return value.split("\n")[0] ?? "";
}
