import { CustomerOrderOfferLineView } from "src/api/generated/erp/db/types/tables/customerOrderOfferLineView";
import i18n from "i18next";
import { faCalendarAlt, faShare, faTools } from "@fortawesome/pro-regular-svg-icons";
import { AsyncMenuButton } from "src/components/common/contextMenu/AsyncMenuButton.tsx";
import { AavoDataGridRowContextMenuParams } from "src/components/common/dataGrid/AavoDataGrid.tsx";
import { CustomerOrderOfferLineConfiguratorView } from "src/components/views/erp/configurator/configuratorForm/impl/CustomerOrderOfferLineConfiguratorView.tsx";
import { useGenericDialog } from "src/components/common/dialogs/useGenericDialog.ts";
import { OpenPartConfigurationButton } from "src/components/views/erp/configurator/inspecting/OpenPartConfigurationButton.tsx";
import { CustomerOrderOfferLinesDataGrid } from "src/components/views/erp/sales/customerOrderOffer/CustomerOrderOfferLinesDataGrid.tsx";
import { OpenObjectChangeLogButton } from "src/components/views/changeLogging/OpenObjectChangeLogButton.tsx";
import dayjs from "dayjs";
import { useInputDialog } from "src/components/common/dialogs/input/useInputDialog.tsx";
import { CustomerOrderOfferActionsApi } from "src/api/generated/erp/sales/customerOrderOffer/api/customerOrderOfferActionsApi.ts";
import { dayJsToDateIsoString } from "src/utils/dayjsUtils.ts";
import { OpenConfigurationHistoryButton } from "src/components/views/erp/configurator/objectConfigurationHistory/OpenConfigurationHistoryButton.tsx";

export interface CustomerOrderOfferLinesDataGridRowContextMenuProps
	extends AavoDataGridRowContextMenuParams<CustomerOrderOfferLineView> {
	refreshData: () => Promise<unknown>;
}

export const CustomerOrderOfferLinesDataGridRowContextMenu = ({
	onlySingleRowSelected,
	row,
	refreshData,
	allSelectedRows,
}: CustomerOrderOfferLinesDataGridRowContextMenuProps) => {
	const { openDialog } = useGenericDialog();
	const showInputDialog = useInputDialog();

	return [
		onlySingleRowSelected && row.salesPartType === "PACKAGE_" && (
			<AsyncMenuButton
				key={"openPackageLinesButton"}
				label={i18n.t("package_lines")}
				icon={faShare}
				onClick={() => {
					openDialog(() => ({
						title: i18n.t("package_lines"),
						size: "xl",
						content: (
							<CustomerOrderOfferLinesDataGrid
								customerOrderOfferId={row.customerOrderOfferId}
								parentLineId={row.customerOrderOfferLineId}
							/>
						),
					}));
				}}
			/>
		),
		onlySingleRowSelected && row.partIsConfigurable && (
			<AsyncMenuButton
				key={"configureButton"}
				label={i18n.t("configure")}
				icon={faTools}
				onClick={() => {
					openDialog(({ closeDialog }) => ({
						title: i18n.t("configure"),
						content: (
							<CustomerOrderOfferLineConfiguratorView
								customerOrderOfferLineId={row.customerOrderOfferLineId}
								closeDialog={closeDialog}
								refreshSourceView={refreshData}
							/>
						),
					}));
				}}
			/>
		),
		onlySingleRowSelected && (
			<OpenPartConfigurationButton
				key={"openConfigurationButton"}
				partConfigurationId={row.partConfigurationId}
				showBom={false}
			/>
		),
		onlySingleRowSelected && row.partConfigurationId != null && (
			<OpenConfigurationHistoryButton
				key={"openConfigurationHistoryButton"}
				objectType={"CUSTOMER_ORDER_OFFER_LINE"}
				objectId={row.customerOrderOfferLineId}
				refreshSourceView={refreshData}
			/>
		),
		row.parentLineId == null && (
			<AsyncMenuButton
				key={"changePlannedDeliveryDate"}
				label={i18n.t("change_planned_delivery_date")}
				icon={faCalendarAlt}
				onClick={async () => {
					await showChangePlannedDeliveryDateDialog();
					await refreshData();
				}}
			/>
		),
		<OpenObjectChangeLogButton
			key={"changeLog"}
			objectRef={{
				objectType: "CUSTOMER_ORDER_OFFER_LINE",
				objectId: row.customerOrderOfferLineId,
			}}
		/>,
	];

	async function showChangePlannedDeliveryDateDialog() {
		const newPlannedDeliveryDate = await showInputDialog({
			defaultValue: dayjs(row.plannedDeliveryDate),
			type: "date",
			title: i18n.t("change_planned_delivery_date"),
			fieldLabel: i18n.t("new_delivery_date"),
			required: true,
		});
		if (newPlannedDeliveryDate == null) return;

		await CustomerOrderOfferActionsApi.updateOfferLinePlannedDeliveryDates({
			customerOrderOfferLineIds: allSelectedRows.map((r) => r.customerOrderOfferLineId),
			newPlannedDeliveryDate: dayJsToDateIsoString(newPlannedDeliveryDate),
		});
	}
};
