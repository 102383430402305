import i18n from "i18next";
import { getWopiAppLabel } from "src/api/generated/documents/wopi/discovery/wopiApp";
import { openWopiFilePage } from "./wopiFilePageUtils";
import { AsyncMenuButton } from "src/components/common/contextMenu/AsyncMenuButton.tsx";
import { getWopiAppForExtension } from "./wopiAppUtils";
import { AavoContextMenuClosingContext } from "src/components/common/contextMenu/AavoContextMenuClosingContext.ts";
import { useContext } from "react";

export interface EditDocumentWithWopiButtonMenuItemProps {
	fileExtension: string | undefined;
	fileUuid: string | undefined;
	visible?: boolean;
}

export const EditDocumentWithWopiButtonMenuItem = ({
	fileExtension,
	fileUuid,
	visible = true,
}: EditDocumentWithWopiButtonMenuItemProps) => {
	const closeContextMenu = useContext(AavoContextMenuClosingContext)?.closeContextMenu;
	if (fileExtension === undefined || fileUuid === undefined || visible === false) return null;

	const wopiApp = getWopiAppForExtension(fileExtension);
	if (wopiApp === undefined) return null;

	const iconUrl = `/icons/cspp/${wopiApp.toLowerCase()}_16x1.png`;
	const appFriendlyName = getWopiAppLabel(wopiApp);

	return (
		<AsyncMenuButton
			iconEl={<img src={iconUrl} alt={"icon"} />}
			onClick={async () => {
				openWopiFilePage("edit", fileUuid);
				closeContextMenu?.();
			}}
			label={i18n.t("edit_in_microsoft_online", { app: appFriendlyName })}
		/>
	);
};
