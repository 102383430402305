// This file is automatically generated. Do not edit manually.

import { AavoObjectRef } from "src/api/generated/common/sourceType/aavoObjectRef";
import { ServerSideDataModelRequest } from "src/api/generated/common/dataGrids/serverSideDataModelRequest";
import { ServerSideDataModelResult } from "src/api/generated/common/dataGrids/serverSideDataModelResult";
import { DocumentConnectionView } from "src/api/generated/postgres/db/types/documents/tables/documentConnectionView";
import { DocumentCategory } from "src/api/generated/postgres/db/types/documents/tables/documentCategory";
import { DocumentConnection } from "src/api/generated/postgres/db/types/documents/tables/documentConnection";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace DocumentsOfObjectDataGridApi {
	export async function getDocumentsOfObjects(args: {
		objectRefs: Array<AavoObjectRef>;
		categoryFilter: Array<number>;
		searchQuery: string;
		dataModelRequest: ServerSideDataModelRequest;
	}): Promise<ServerSideDataModelResult<DocumentConnectionView>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/documents/getDocumentsOfObjects",
			method: "POST",
			data: {
				objectRefs: args.objectRefs,
				categoryFilter: args.categoryFilter,
				searchQuery: args.searchQuery,
				dataModelRequest: args.dataModelRequest,
			},
		});
		return response as ServerSideDataModelResult<DocumentConnectionView>;
	}

	export async function getDocumentCategoryOptionsForObjects(args: {
		objectRefs: Array<AavoObjectRef>;
	}): Promise<Array<DocumentCategory>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/documents/getDocumentCategoryOptionsForObjects",
			method: "POST",
			data: {
				objectRefs: args.objectRefs,
			},
		});
		return response as Array<DocumentCategory>;
	}

	export async function deleteDocumentConnections(args: {
		documentConnections: Array<DocumentConnection>;
	}): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/documents/deleteDocumentConnectionId",
			method: "POST",
			data: {
				documentConnections: args.documentConnections,
			},
		});
		return response as void;
	}

	export async function setDocumentConnectionIsPinned(args: {
		documentConnectionId: number;
		isPinned: boolean;
	}): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/documents/setDocumentConnectionIsPinned",
			method: "POST",
			data: {
				documentConnectionId: args.documentConnectionId,
				isPinned: args.isPinned,
			},
		});
		return response as void;
	}

	export async function setDocumentToMainDocumentOfObject(args: {
		objectRef: AavoObjectRef;
		documentId: number;
	}): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/documents/setDocumentToMainDocumentOfObject",
			method: "POST",
			data: {
				objectRef: args.objectRef,
				documentId: args.documentId,
			},
		});
		return response as void;
	}
}
