import { FormCommonProps } from "src/components/common/forms/types.ts";
import { AsyncForm } from "src/components/common/forms/AsyncForm";
import { ObjectAttributeFieldFormComponent } from "src/components/views/erp/objectAttributes/ObjectAttributeFieldFormComponent.tsx";
import { ObjectAttributeFieldWithValue } from "src/api/generated/erp/common/objectAttributes/objectAttributeFieldWithValue.ts";

export interface ObjectAttributesFormProps {
	fetchAttrs: () => Promise<ObjectAttributeFieldWithValue[]>;
	saveAttrs: (attributes: ObjectAttributeFieldWithValue[]) => Promise<void>;
	disabled?: boolean;
	onFormEdited?: FormCommonProps<void>["onFormEdited"];
	onCompleted?: FormCommonProps<void>["onCompleted"];
}

interface FormValues {
	attributes: ObjectAttributeFieldWithValue[];
}

export const ObjectAttributesForm = ({
	fetchAttrs,
	saveAttrs,
	onFormEdited,
	onCompleted = () => {},
	disabled = false,
}: ObjectAttributesFormProps) => {
	return (
		<AsyncForm
			fetch={fetchAttrs}
			getDefaultValues={(data) => ({
				attributes: data,
			})}
			submit={async function (values: FormValues) {
				await saveAttrs(values.attributes);
			}}
			onCompleted={onCompleted}
			onFormEdited={onFormEdited}
			columns={4}
			layoutSx={{
				marginTop: 1,
			}}
			hideActions={disabled}
			render={({ data: attributes, control }) => (
				<>
					{attributes.map((attr, index) => (
						<ObjectAttributeFieldFormComponent
							key={attr.field.id}
							control={control}
							name={`attributes.${index}.attributeValue`}
							fieldDefinition={attr.field}
							disabled={disabled}
						/>
					))}
				</>
			)}
		/>
	);
};
