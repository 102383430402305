import { AavoDataGridProps } from "src/components/common/dataGrid/AavoDataGrid.tsx";
import React, { useState } from "react";
import { AsyncRender } from "../../async/AsyncRender";
import { AsyncState } from "src/utils/async/asyncState.ts";
import { removeKeyFromObject } from "src/utils/objectUtils.ts";
import {
	useServerSideDataGridModel,
	UseServerSideDataGridModelParams,
	UseServerSideDataGridModelReturn,
} from "src/components/common/dataGrid/gridModel/useServerSideDataGridModel";
import { ServerSideDataModelRequest } from "src/api/generated/common/dataGrids/serverSideDataModelRequest.ts";
import { ServerSideDataModelResult } from "src/api/generated/common/dataGrids/serverSideDataModelResult.ts";
import { logError } from "src/errorHandling/errorLogging.ts";

export interface ServerSideDataGridModelProps<
	TRemoteData,
	TRow extends object,
	TParams extends ParamsWithoutDataModelRequest<unknown>,
> extends Omit<UseServerSideDataGridModelParams<TRow, TParams>, "fetchData"> {
	fetchData: (params: ParamsWithDataModelRequest<TParams>) => Promise<TRemoteData>;
	getDataModelResult: (remoteData: TRemoteData) => ServerSideDataModelResult<TRow>;
	render: (props: ServerSideDataGridModelRenderProps<TRemoteData, TRow, TParams>) => React.ReactNode;
}

type ParamsWithoutDataModelRequest<T> = Omit<T, "dataModelRequest">;

export type ParamsWithDataModelRequest<TParams> = TParams & {
	dataModelRequest: ServerSideDataModelRequest;
};

export interface ServerSideDataGridModelRenderProps<
	TRemoteData,
	TRow extends object,
	TParams extends ParamsWithoutDataModelRequest<unknown>,
> extends Omit<UseServerSideDataGridModelReturn<TRow, TParams>, "dataGridProps"> {
	dataGridProps: DataGridProps<TRow>;
	data: TRemoteData;
}

interface DataGridProps<TRow extends object>
	extends Required<
		Pick<
			AavoDataGridProps<TRow>,
			| "rows"
			| "refreshData"
			| "rowCount"
			| "initialState"
			| "onStateChange"
			| "selectedRows"
			| "onRowSelectionChanged"
			| "getRowId"
			| "apiRef"
		>
	> {}

export const ServerSideDataGridModel = <
	TRemoteData,
	TRow extends object,
	TParams extends ParamsWithoutDataModelRequest<unknown>,
>({
	render,
	fetchData,
	getDataModelResult,
	...other
}: ServerSideDataGridModelProps<TRemoteData, TRow, TParams>) => {
	// Due to difficulties brought by the file type responses, we need to use a quite primitive approach here
	const [asyncData, setAsyncData] = useState<AsyncState<TRemoteData>>({
		loading: false,
		data: undefined,
		error: undefined,
	});

	const { dataGridProps, ...otherHookReturn } = useServerSideDataGridModel({
		...other,
		fetchData: async (params) => {
			setAsyncData((prev) => ({
				...prev,
				loading: true,
			}));
			try {
				const data = await fetchData(params);
				const dataModelResult = getDataModelResult(data);
				if (dataModelResult.type === "data") {
					setAsyncData((prev) => ({
						...prev,
						data: data,
					}));
				}
				return dataModelResult;
			} catch (error) {
				const loggedError = logError(error);
				setAsyncData((prev) => ({
					...prev,
					error: loggedError,
				}));
				throw loggedError;
			} finally {
				setAsyncData((prev) => ({
					...prev,
					loading: false,
				}));
			}
		},
	});

	return (
		<AsyncRender
			asyncData={asyncData}
			reloadOnError={() => otherHookReturn.refreshData()}
			content={(data) => {
				const dataModelResult = getDataModelResult(data);
				if (dataModelResult.type === "file") {
					throw new Error("File data not expected here");
				}
				return render({
					dataGridProps: {
						...removeKeyFromObject(dataGridProps, "rowsAsync"),
						rows: dataModelResult.rows,
						rowCount: dataModelResult.totalRowCount,
					},
					data: data,
					...otherHookReturn,
				});
			}}
		/>
	);
};
