import { SurveyFormView } from "src/api/generated/erp/db/types/tables/surveyFormView.ts";
import { CenteredTypography } from "src/components/common/CenteredTypography.tsx";
import i18n from "i18next";
import { SurveyFormRevisionsDataGrid } from "src/components/views/erp/surveys/management/revisions/SurveyFormRevisionsDataGrid.tsx";
import { SurveyFormsDataGrid } from "src/components/views/erp/surveys/management/forms/SurveyFormsDataGrid.tsx";
import { AavoMosaic } from "src/components/common/mosaic/AavoMosaic.tsx";
import { useState } from "react";
import { SurveyFormRevisionView } from "src/api/generated/erp/db/types/tables/surveyFormRevisionView.ts";
import { SurveyFormFieldsDataGrid } from "src/components/views/erp/surveys/management/fields/SurveyFormFieldsDataGrid.tsx";
import {
	SurveyFormPermissionsDataGrid
} from "src/components/views/erp/surveys/management/permissions/SurveyFormPermissionsDataGrid.tsx";

export const SurveyFormsView = () => {
	const [selectedForm, setSelectedForm] = useState<SurveyFormView | undefined>(undefined);
	const [selectedRevision, setSelectedRevision] = useState<SurveyFormRevisionView | undefined>(undefined);

	return (
		<AavoMosaic
			viewId={"A5F80917F82F3304"}
			layout={{
				type: "row",
				first: {
					type: "column",
					first: {
						type: "panel",
						title: i18n.t("survey_forms"),
						content: <SurveyFormsDataGrid onSelectionChanged={setSelectedForm} />,
					},
					second: {
						type: "tabs",
						items: [
							{
								type: "panel",
								title: i18n.t("revisions"),
								content:
									selectedForm == null ?
										<CenteredTypography>{i18n.t("select_form")}</CenteredTypography>
									:	<SurveyFormRevisionsDataGrid
											key={selectedForm.surveyFormId}
											surveyFormId={selectedForm.surveyFormId}
											onSelectionChanged={setSelectedRevision}
										/>,
							},
							{
								type: "panel",
								title: i18n.t("permissions"),
								content:
									selectedForm == null ?
										<CenteredTypography>{i18n.t("select_form")}</CenteredTypography>
									:	<SurveyFormPermissionsDataGrid
											key={selectedForm.surveyFormId}
											surveyFormId={selectedForm.surveyFormId}
										/>,
							},
						],
					},
				},
				second: {
					type: "panel",
					title: i18n.t("fields"),
					content:
						selectedRevision == null ?
							<CenteredTypography>{i18n.t("select_revision")}</CenteredTypography>
						:	<SurveyFormFieldsDataGrid
								key={selectedRevision.surveyFormRevisionId}
								surveyFormRevision={selectedRevision}
							/>,
				},
			}}
		/>
	);
};
