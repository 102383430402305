import { AavoDatePicker } from "src/components/common/inputFields/AavoDatePicker.tsx";
import { labelWithRequiredMark } from "src/components/views/erp/surveys/surveyUtils.ts";
import dayjs from "dayjs";
import { dayJsToDateIsoString } from "src/utils/dayjsUtils.ts";
import { SurveySubmissionFormFieldProps } from "src/components/views/erp/surveys/submissions/fields/SurveySubmissionFormField.tsx";

export const SurveySubmissionFormDateField = ({
	field,
	value,
	onChange,
	error,
	disabled,
}: SurveySubmissionFormFieldProps) => {
	return (
		<AavoDatePicker
			label={labelWithRequiredMark(field)}
			value={value?.type === "date" ? dayjs(value.value) : null}
			errorMessage={error}
			disabled={disabled}
			onChange={(value) => {
				if (value == null) onChange(null);
				else
					onChange({
						type: "date",
						value: dayJsToDateIsoString(value),
					});
			}}
		/>
	);
};
