// This file is automatically generated. Do not edit manually.

import { IsoDateTimeString } from "src/types/dateTime";
import { AcquisitionMethod } from "src/api/generated/erp/db/types/enums/acquisitionMethod";
import { WarehousePickingObjectType } from "src/api/generated/erp/db/types/enums/warehousePickingObjectType";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace WarehousePickingObjectLocationReservationsDataGridApi {
	export async function getData(args: {
		partId: number;
		partWarehouseLocationId: number | null | undefined;
	}): Promise<Array<WarehousePickingObjectLocationReservationsDataGridApi_Row>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/api/warehouse/pickingObjectLocationReservations/getData",
			method: "POST",
			data: {
				partId: args.partId,
				partWarehouseLocationId: args.partWarehouseLocationId,
			},
		});
		return response as Array<WarehousePickingObjectLocationReservationsDataGridApi_Row>;
	}
}

export interface WarehousePickingObjectLocationReservationsDataGridApi_Row {
	objectDescription: string;
	configurationOrPartDescription: string | null | undefined;
	lastUpdated: IsoDateTimeString;
	locationCode: string | null | undefined;
	objectAcquisitionMethod: AcquisitionMethod;
	objectId: number;
	objectLineNumber: number | null | undefined;
	objectMark: string | null | undefined;
	objectType: WarehousePickingObjectType;
	partId: number;
	partUnit: string | null | undefined;
	partWarehouseLocationId: number | null | undefined;
	pickedQuantity: number;
	reservedQuantity: number;
	siteId: number;
	warehousePickingObjectLocationId: number;
}
