import { useMemo, useState } from "react";
import { Autocomplete, AutocompleteProps, CircularProgress, TextField } from "@mui/material";
import { InputError } from "./types";
import i18n from "i18next";
import { useAsyncFetch } from "src/utils/async/asyncFetch.ts";
import { CustomPopper, CustomPopperProps } from "src/components/common/popper/CustomPopper.tsx";
import { useErrorDialog } from "src/components/common/dialogs/errorDialog/userErrorDialog.ts";
import { useDebounce } from "src/utils/useDebounce.ts";
import { getMultiSelectAutocompleteInputWidthStyle } from "src/components/common/inputFields/selectFieldUtils.ts";

export interface AsyncMultiSelectFieldProps<T, Key extends string | number>
	extends Omit<
		AutocompleteProps<T, true, false, false>,
		| "onChange"
		| "options"
		| "value"
		| "renderInput"
		| "getOptionKey"
		| "getOptionLabel"
		| "freeSolo"
		| "multiple"
		| "disableClearable"
		| "filterOptions"
		| "isOptionEqualToValue"
	> {
	fetchOptions: AsyncMultiSelectFieldFetchOptionsFunc<Key, T>;
	onChange: (v: T[]) => void;
	getOptionKey: (o: T) => Key;
	getOptionLabel: (o: T) => string;
	label: string;
	error?: InputError;
	value: Key[];
	placeholder?: string;
	popperProps?: Partial<CustomPopperProps>;
}

export type AsyncMultiSelectFieldFetchOptionsFunc<Key, T> = (params: {
	searchQuery: string;
	currentSelection: Key[] | undefined;
}) => Promise<T[]>;

export const AsyncMultiSelectField = <T, Key extends string | number>({
	label,
	value: valueKeys,
	onChange,
	fetchOptions: fetchOptionsProp,
	error,
	autoFocus,
	placeholder,
	noOptionsText,
	getOptionKey,
	getOptionLabel,
	sx,
	popperProps,
	...other
}: AsyncMultiSelectFieldProps<T, Key>) => {
	const [searchQuery, setSearchQuery] = useState<string>("");

	const [optionsAsync, fetchOptions] = useAsyncFetch<T[], string>(
		(newSearchQuery) =>
			fetchOptionsProp({
				searchQuery: newSearchQuery ?? searchQuery,
				currentSelection: valueKeys ?? [],
			}),
		{
			fetchOnMount: valueKeys != undefined,
		},
	);

	const { logErrorAndShowOnDialog } = useErrorDialog();
	const debounce = useDebounce();

	const onInputChanged = (newInput: string) => {
		setSearchQuery(newInput);
		debounce(200, async () => {
			try {
				await fetchOptions(newInput);
			} catch (e) {
				logErrorAndShowOnDialog(e);
			}
		});
	};

	const values: T[] = useMemo(() => {
		if (valueKeys.length === 0) return EMPTY_ARRAY_SINGLETON;
		if (optionsAsync.data === undefined) return EMPTY_ARRAY_SINGLETON;

		return optionsAsync.data.filter((o) => valueKeys.includes(getOptionKey(o)));

		// Autocomplete requires that value reference is stable over renders.
		// Reference to options is changing on every fetch, so we cannot use it as dependency.
		// getOptionKey is assumed to be stable
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [valueKeys]);

	return (
		<Autocomplete
			multiple
			options={optionsAsync.data || EMPTY_ARRAY_SINGLETON}
			filterOptions={(o) => o}
			value={values}
			getOptionKey={getOptionKey}
			getOptionLabel={getOptionLabel}
			disableClearable={false}
			onChange={(_, newValues) => {
				onChange(newValues);
			}}
			isOptionEqualToValue={(option, value) => {
				if (value == null) return false;
				return getOptionKey(option) === getOptionKey(value);
			}}
			noOptionsText={noOptionsText !== undefined ? noOptionsText : i18n.t("no_options")}
			onInputChange={(_, newInput) => {
				onInputChanged(newInput);
			}}
			inputValue={searchQuery}
			onOpen={() => {
				onInputChanged("");
			}}
			PopperComponent={(defaultPopperProps) => (
				<CustomPopper {...defaultPopperProps} placement={"bottom-start"} {...popperProps} />
			)}
			renderInput={(params) => {
				return (
					<TextField
						{...params}
						label={label}
						error={error !== undefined}
						helperText={error}
						autoFocus={autoFocus}
						placeholder={placeholder || i18n.t("search_options")}
						inputProps={{
							...params.inputProps,
							style: {
								...getMultiSelectAutocompleteInputWidthStyle(label, values),
								...params.inputProps.style,
							},
						}}
						InputProps={{
							...params.InputProps,
							endAdornment: (
								<>
									{optionsAsync.loading && (
										<CircularProgress size={20} color={"inherit"} />
									)}
								</>
							),
						}}
					/>
				);
			}}
			sx={{
				minWidth: 150,
				...sx,
			}}
			{...other}
		/>
	);
};

const EMPTY_ARRAY_SINGLETON: [] = [];
