// This file is automatically generated. Do not edit manually.

import { t } from "i18next";

export const PartRevisionLineAcquisitionMethodValues = [
	"WAREHOUSE",
	"NO_ACQUISITION",
	"MANUFACTURE",
	"PURCHASE_TRANSIT",
	"DELAYED",
] as const;

export type PartRevisionLineAcquisitionMethod = (typeof PartRevisionLineAcquisitionMethodValues)[number];

export const getPartRevisionLineAcquisitionMethodLabels = () => ({
	WAREHOUSE: t("warehouse"),
	NO_ACQUISITION: t("no_acquisition"),
	MANUFACTURE: t("shop_order"),
	PURCHASE_TRANSIT: t("purchase_transit"),
	DELAYED: t("delayed"),
});

export const getPartRevisionLineAcquisitionMethodLabel = (value: PartRevisionLineAcquisitionMethod): string => {
	return getPartRevisionLineAcquisitionMethodLabels()[value];
};
