// This file is automatically generated. Do not edit manually.

import { PartWarehouseLocationView } from "src/api/generated/erp/db/types/tables/partWarehouseLocationView";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace PartWarehouseLocationQueryApi {
	export async function getPartWarehouseLocations(args: {
		sitePartId: number;
		showDisabledLocations?: boolean;
	}): Promise<Array<PartWarehouseLocationView>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sitePart/partWarehouseLocations/getPartWarehouseLocations",
			method: "POST",
			data: {
				sitePartId: args.sitePartId,
				showDisabledLocations: args.showDisabledLocations,
			},
		});
		return response as Array<PartWarehouseLocationView>;
	}
}
