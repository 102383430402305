import { CostEventPriceListDataGridApi } from "src/api/generated/erp/common/costEvents/costEventPriceListDataGridApi.ts";
import { CostEventPriceList } from "src/api/generated/erp/db/types/tables/costEventPriceList.ts";
import { textColumn } from "../../../../common/dataGrid/columns.tsx";
import i18n from "i18next";
import { ControlledAsyncCrudDataGrid } from "../../../../common/dataGrid/crud/ControlledAsyncCrudDataGrid.tsx";
import { useClientSideDataGridModel } from "src/components/common/dataGrid/gridModel/useClientSideDataGridModel";

export interface CostEventPriceListDataGridProps {
	onSelectedPriceListChanged: (priceList: CostEventPriceList | undefined) => void;
}

export const CostEventPriceListDataGrid = ({
	onSelectedPriceListChanged,
}: CostEventPriceListDataGridProps) => {
	const { dataGridProps } = useClientSideDataGridModel({
		fetchData: () => CostEventPriceListDataGridApi.getAll(),
		initialParams: {},
		getRowId: (row) => row.costEventPriceListId,
		onSelectionChanged: (rows) => {
			onSelectedPriceListChanged(rows[0]);
		},
		gridId: "2174CEB184854D98",
	});
	return (
		<ControlledAsyncCrudDataGrid<CostEventPriceList>
			columns={[
				textColumn({
					headerName: i18n.t("name"),
					field: "name",
					editable: true,
					validate: "required",
					width: 400,
				}),
			]}
			add={{
				type: "enabled",
				action: async () => {
					return {};
				},
			}}
			save={{
				type: "enabled",
				action: async (params) =>
					await CostEventPriceListDataGridApi.save({ costEventPriceLists: params.items }),
			}}
			remove={{
				type: "enabled",
				action: async (params) =>
					await CostEventPriceListDataGridApi.delete_({
						costEventPriceLists: params.items,
					}),
			}}
			{...dataGridProps}
		/>
	);
};
