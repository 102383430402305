import { AavoMosaic } from "src/components/common/mosaic/AavoMosaic";
import {
	PartConfiguratorForm,
	PartConfiguratorFormProps,
} from "src/components/views/erp/configurator/configuratorForm/PartConfiguratorForm.tsx";
import i18n from "i18next";
import { ConfigurationComponentDocumentView } from "src/components/views/erp/configurator/configuratorForm/componentDocument/ConfigurationComponentDocumentView.tsx";
import { useState } from "react";
import { ConfiguratorSessionLogView } from "src/components/views/erp/configurator/configuratorTesting/logViews/ConfiguratorSessionLogView.tsx";
import { PartConfiguratorFormApi } from "src/api/generated/erp/configurator/api/form/partConfiguratorFormApi.ts";
import { AsyncFetchRender } from "src/components/common/async/AsyncFetchRender.tsx";
import { TestConfiguratorContextDataForm } from "src/components/views/erp/configurator/configuratorTesting/TestConfiguratorContextDataForm.tsx";

export interface PartConfiguratorViewProps
	extends Omit<
		PartConfiguratorFormProps,
		| "componentIdWithActiveDocument"
		| "setComponentIdWithActiveDocument"
		| "configurationSessionId"
	> {
	configurationSessionId?: string;
}

export const PartConfiguratorView = (props: PartConfiguratorViewProps) => {
	const { configuratorType, configurationSessionId: configurationSessionIdProp } = props;

	const fetchSessionId = async () => {
		return configurationSessionIdProp ?? (await PartConfiguratorFormApi.initializeSession({
			configuratorType
		}));
	};

	return (
		<AsyncFetchRender
			fetch={fetchSessionId}
			content={(configurationSessionId) => {
				if (!configuratorType.isTestConfigurator)
					return (
						<ProductionPartConfiguratorView
							{...props}
							configurationSessionId={configurationSessionId}
						/>
					);
				else
					return (
						<AavoMosaic
							viewId={"66D753A534DEFB11"}
							layout={{
								type: "tabs",
								items: [
									{
										type: "panel",
										title: i18n.t("configurator"),
										content: (
											<ProductionPartConfiguratorView
												{...props}
												configurationSessionId={configurationSessionId}
											/>
										),
									},
									{
										type: "panel",
										title: i18n.t("log"),
										hidden: !configuratorType.isTestConfigurator,
										content: (
											<ConfiguratorSessionLogView
												sessionId={configurationSessionId}
												configuratorType={configuratorType}
											/>
										),
									},
									{
										type: "panel",
										title: i18n.t("context_data"),
										hidden: !configuratorType.isTestConfigurator,
										content: (
											<TestConfiguratorContextDataForm
												configuratorType={configuratorType}
											/>
										),
									},
								],
							}}
						/>
					);
			}}
		/>
	);
};

interface CommonComponentsProps extends Omit<PartConfiguratorViewProps, "configurationSessionId"> {
	configurationSessionId: string;
}

const ProductionPartConfiguratorView = (props: CommonComponentsProps) => {
	const [componentIdWithActiveDocument, setComponentIdWithActiveDocument] = useState<number | null>(null);

	return (
		<AavoMosaic
			viewId={"63D753A534DEFB11"}
			layout={{
				type: "row",
				splitPercentage: 65,
				first: {
					type: "panel",
					content: (
						<PartConfiguratorForm
							{...props}
							componentIdWithActiveDocument={componentIdWithActiveDocument}
							setComponentIdWithActiveDocument={setComponentIdWithActiveDocument}
						/>
					),
				},
				second: {
					type: "panel",
					title: i18n.t("document"),
					content: (
						<ConfigurationComponentDocumentView componentId={componentIdWithActiveDocument} />
					),
				},
			}}
		/>
	);
};
