import { FormCommonProps } from "src/components/common/forms/types.ts";
import { AavoForm } from "src/components/common/forms/AavoForm.tsx";
import { FormNumberField } from "src/components/common/forms/fields/FormNumberField.tsx";
import i18n from "i18next";
import { requireRule } from "src/components/common/forms/validation.ts";
import { FormTextField } from "src/components/common/forms/fields/FormTextField.tsx";
import { VatCode } from "src/api/generated/erp/db/types/tables/vatCode.ts";
import { VatCodeApi } from "src/api/generated/erp/sales/basedata/api/vatCodeApi.ts";

export interface VatCodeFormProps extends FormCommonProps<number> {
	vatCode: VatCode | undefined;
}

export const VatCodeForm = ({ vatCode: existingVatCode, onCompleted, onFormEdited }: VatCodeFormProps) => {
	const isExistingRecord = existingVatCode != null;
	return (
		<AavoForm<VatCode, number>
			defaultValues={existingVatCode != null ? existingVatCode : {}}
			submit={submit}
			onCompleted={onCompleted}
			onFormEdited={onFormEdited}
			render={({ control }) => (
				<>
					<FormTextField
						control={control}
						name={"vatCodeName"}
						label={i18n.t("name")}
						rules={requireRule()}
					/>
					<FormNumberField
						control={control}
						name={"vatPercent"}
						label={i18n.t("vat_percent")}
						rules={requireRule()}
						disabled={isExistingRecord}
					/>
				</>
			)}
		/>
	);

	async function submit(values: VatCode) {
		if (existingVatCode != null) {
			await VatCodeApi.update({ vatCode: values });
			return existingVatCode.vatCodeId;
		} else {
			return await VatCodeApi.insert({ vatCode: values });
		}
	}
};
