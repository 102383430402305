import {DocumentBaseFields} from "src/components/views/documents/revisions/types.ts";
import { DocumentRevisionApi } from "src/api/generated/documents/api/documentRevisionApi.ts";
import {
	booleanColumn,
	dateTimeColumn,
	enumColumn,
	integerColumn,
	textColumn,
} from "src/components/common/dataGrid/columns.tsx";
import i18n from "i18next";
import { thumbnailColumn } from "src/components/views/documents/utils/thumbnails.tsx";
import { getDocumentRevisionStateLabels } from "src/api/generated/postgres/db/types/documents/enums/documentRevisionState";
import { AsyncButton } from "src/components/common/buttons/AsyncButton.tsx";
import { faFileArrowDown, faShare } from "@fortawesome/pro-regular-svg-icons";
import { DocumentFileApi } from "src/api/generated/documents/api/documentFileApi.ts";
import { downloadFile } from "src/utils/fileDownloading.ts";
import { DocumentRevisionForm } from "src/components/views/documents/DocumentRevisionForm.tsx";
import { AsyncMenuButton } from "src/components/common/contextMenu/AsyncMenuButton.tsx";
import { DocumentRevisionFilesDataGrid } from "src/components/views/documents/revisions/DocumentRevisionFilesDataGrid.tsx";
import { EditDocumentWithWopiButtonMenuItem } from "src/components/views/documents/wopi/EditDocumentWithWopiButtonMenuItem.tsx";
import { GenericDialogContext } from "src/components/common/dialogs/GenericDialogProvider.tsx";
import { DocumentRevisionView } from "src/api/generated/postgres/db/types/documents/tables/documentRevisionView.ts";
import { useContextOrThrow } from "src/utils/useContextOrThrow.tsx";
import { faFile } from "@fortawesome/pro-regular-svg-icons";
import { useClientSideDataGridModel } from "src/components/common/dataGrid/gridModel/useClientSideDataGridModel";
import { ControlledAsyncCrudDataGrid } from "src/components/common/dataGrid/crud/ControlledAsyncCrudDataGrid.tsx";
import {DocumentsViewEditable} from "../types.ts";

export interface DocumentRevisionsDataGridProps {
	document: DocumentBaseFields;
	editable: DocumentsViewEditable;
	onSelectionChanged: (selectedRevision: DocumentRevisionView | undefined) => void;
}

export const DocumentRevisionsDataGrid = ({
	document,
	editable,
	onSelectionChanged,
}: DocumentRevisionsDataGridProps) => {
	const { dataGridProps, onlySelectedRow, refreshData } = useClientSideDataGridModel({
		fetchData: () =>
			DocumentRevisionApi.getDocumentRevisions({ documentId: document.documentId }),
		initialParams: {},
		getRowId: (row) => row.documentRevisionId,
		gridId: "1597E30A75E9F06B",
		onSelectionChanged: (rows) => {
			onSelectionChanged(rows[0]);
		},
	});

	const { openDialog } = useContextOrThrow(GenericDialogContext);

	return (
		<ControlledAsyncCrudDataGrid<DocumentRevisionView>
			disableMultipleRowSelection
			columns={[
				integerColumn({
					field: "revisionNumber",
					headerName: i18n.t("revision"),
					width: 60,
				}),
				thumbnailColumn({
					headerName: i18n.t("image"),
					field: "thumbnail",
				}),
				textColumn({
					headerName: i18n.t("type"),
					field: "primaryFileExtension",
					width: 80,
				}),
				enumColumn({
					headerName: i18n.t("status"),
					field: "revisionState",
					enumLabels: getDocumentRevisionStateLabels(),
					width: 100,
				}),
				textColumn({
					headerName: i18n.t("note"),
					field: "note",
					width: 200,
				}),
				dateTimeColumn({
					headerName: i18n.t("created_at"),
					field: "createdDate",
					width: 150,
				}),
				textColumn({
					headerName: i18n.t("created_by"),
					field: "createdByUserName",
				}),
				booleanColumn({
					headerName: i18n.t("uploaded"),
					field: "primaryFileExists",
				}),
			]}
			form={{
				dialogSize: "lg",
				dialogTitle: i18n.t("revision"),
				component: ({ row, onCompleted, onFormEdited }) => {
					return (
						<DocumentRevisionForm
							documentId={document.documentId}
							documentRevision={row}
							onCompleted={onCompleted}
							onFormEdited={onFormEdited}
						/>
					);
				},
				addRowEnabled: editable === true,
				editEnabled: editable === true,
			}}
			actionBarComponents={
				<>
					<AsyncButton
						icon={faFileArrowDown}
						label={i18n.t("download")}
						variant={"outlined"}
						disabled={onlySelectedRow === undefined}
						onClick={async () => {
							const fileHandle = await DocumentFileApi.downloadDocumentRevisionFile({
								documentRevisionId: onlySelectedRow!.documentRevisionId,
							});
							downloadFile(fileHandle);
						}}
					/>
					{editable === true && (
						<AsyncButton
							icon={faShare}
							label={i18n.t("release")}
							variant={"outlined"}
							disabled={
								onlySelectedRow === undefined ||
								onlySelectedRow.revisionState !== "INITIAL"
							}
							onClick={async () => {
								await DocumentRevisionApi.releaseDocumentRevision({
									documentRevisionId: onlySelectedRow!.documentRevisionId,
								});
								await refreshData({});
							}}
						/>
					)}
				</>
			}
			remove={{
				type: editable === true ? "enabled" : "hidden",
				action: async ({ items }) => {
					await DocumentRevisionApi.deleteDocumentRevisions({
						documentRevisions: items,
					});
				},
			}}
			rowContextMenuComponents={({ row, multipleRowsSelected }) => [
				(editable === true || editable === "onlyOfficeForExistingDocuments") && (
					<EditDocumentWithWopiButtonMenuItem
						key={"edit_with_wopi"}
						fileExtension={row.primaryFileExtension ?? undefined}
						fileUuid={row.primaryFileUuid ?? undefined}
						visible={!multipleRowsSelected}
					/>
				),
				<AsyncMenuButton
					key={"files"}
					icon={faFile}
					label={i18n.t("files")}
					onClick={async () => {
						openDialog(() => ({
							title: i18n.t("revision_files"),
							size: "xl",
							content: (
								<DocumentRevisionFilesDataGrid
									documentRevisionId={row.documentRevisionId}
								/>
							),
						}));
					}}
				/>,
			]}
			{...dataGridProps}
		/>
	);
};
