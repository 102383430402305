import i18n from "i18next";
import { DeliveryPackageType } from "src/api/generated/erp/db/types/tables/deliveryPackageType.ts";
import { FormCommonProps } from "src/components/common/forms/types.ts";
import { AavoForm } from "src/components/common/forms/AavoForm.tsx";
import { DeepPartial } from "react-hook-form";
import { requireRule } from "src/components/common/forms/validation.ts";
import { FormTextField } from "src/components/common/forms/fields/FormTextField.tsx";
import { DeliveryPackageTypesApi } from "src/api/generated/erp/warehouse/basedata/api/deliveryPackageTypesApi.ts";

export interface DeliveryPackageTypeFormProps extends FormCommonProps<number> {
	deliveryPackageType: DeliveryPackageType | undefined;
}

interface FormValues extends DeliveryPackageType {}

export const DeliveryPackageTypeForm = (props: DeliveryPackageTypeFormProps) => {
	const { deliveryPackageType, onCompleted, onFormEdited } = props;
	return (
		<AavoForm
			defaultValues={getDefaultValues()}
			submit={submit}
			onCompleted={onCompleted}
			onFormEdited={onFormEdited}
			render={({ control }) => (
				<>
					<FormTextField control={control} name={"name"} label={i18n.t("name")} rules={requireRule()} />
				</>
			)}
		/>
	);

	function getDefaultValues(): DeepPartial<FormValues> {
		if (deliveryPackageType != null) return deliveryPackageType;
		return {};
	}

	async function submit(values: FormValues) {
		if (deliveryPackageType == null) {
			return await DeliveryPackageTypesApi.insert({
				deliveryPackageType: values,
			});
		} else {
			await DeliveryPackageTypesApi.update({
				deliveryPackageType: values,
			});
			return deliveryPackageType.deliveryPackageTypeId;
		}
	}
};
