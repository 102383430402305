// This file is automatically generated. Do not edit manually.

import { t } from "i18next";

export const AcquisitionMethodValues = [
	"WAREHOUSE",
	"MANUFACTURE",
	"PURCHASE_TRANSIT",
	"PURCHASE_DIRECT",
	"NO_ACQUISITION",
] as const;

export type AcquisitionMethod = (typeof AcquisitionMethodValues)[number];

export const getAcquisitionMethodLabels = () => ({
	WAREHOUSE: t("warehouse"),
	MANUFACTURE: t("shop_order"),
	PURCHASE_TRANSIT: t("purchase_transit"),
	PURCHASE_DIRECT: t("purchase_direct"),
	NO_ACQUISITION: t("no_acquisition"),
});

export const getAcquisitionMethodLabel = (value: AcquisitionMethod): string => {
	return getAcquisitionMethodLabels()[value];
};
