// This file is automatically generated. Do not edit manually.

import { ProductionWorkQueueView } from "src/api/generated/erp/db/types/tables/productionWorkQueueView";
import { Workcenter } from "src/api/generated/erp/db/types/tables/workcenter";
import { WorkCenterSpcData } from "src/api/generated/erp/production/jobQueues/api/workCenterSpcData";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace WorkCenterJobQueueInProgressApi {
	export async function getInProgressOperations(): Promise<WorkCenterJobQueueInProgressApi_ViewData> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/workCenterJobQueue/getInProgressOperations",
			method: "POST",
			data: {},
		});
		return response as WorkCenterJobQueueInProgressApi_ViewData;
	}

	export async function setWorkCenterHasAndon(args: { workcenterId: number; hasAndon: boolean }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/workCenterJobQueue/setWorkCenterHasAndon",
			method: "POST",
			data: {
				workcenterId: args.workcenterId,
				hasAndon: args.hasAndon,
			},
		});
		return response as void;
	}
}

export interface WorkCenterJobQueueInProgressApi_ViewData {
	shopOrderOperations: Array<ProductionWorkQueueView>;
	workCenter: Workcenter;
	workCenterSpcData: WorkCenterSpcData;
}
