import { SurveyFormField } from "src/api/generated/erp/db/types/tables/surveyFormField.ts";
import { SurveyFieldValue } from "src/api/generated/io/aavo/applications/db/erp/types/surveyFieldValue.ts";
import { SurveySubmissionFormSelectionField } from "src/components/views/erp/surveys/submissions/fields/impl/SurveySubmissionFormSelectionField.tsx";
import { SurveySubmissionFormDateTimeField } from "src/components/views/erp/surveys/submissions/fields/impl/SurveySubmissionFormDateTimeField.tsx";
import { SurveySubmissionFormDateField } from "src/components/views/erp/surveys/submissions/fields/impl/SurveySubmissionFormDateField.tsx";
import { SurveySubmissionFormCheckboxField } from "src/components/views/erp/surveys/submissions/fields/impl/SurveySubmissionFormCheckboxField.tsx";
import { SurveySubmissionFormTextField } from "src/components/views/erp/surveys/submissions/fields/impl/SurveySubmissionFormTextField.tsx";
import {
	SurveySubmissionFormDocumentField
} from "src/components/views/erp/surveys/submissions/fields/impl/SurveySubmissionFormDocumentField.tsx";
import {
	SurveySubmissionFormSectionBreak
} from "src/components/views/erp/surveys/submissions/fields/impl/SurveySubmissionFormSectionBreak.tsx";

export interface SurveySubmissionFormFieldProps {
	field: SurveyFormField;
	value: SurveyFieldValue | null;
	error: string | undefined;
	disabled?: boolean;
	onChange: (value: SurveyFieldValue | null) => void;
}

export const SurveySubmissionFormField = (props: SurveySubmissionFormFieldProps) => {
	const { field } = props;

	switch (field.fieldType) {
		case "TEXT":
		case "TEXTAREA":
		case "INTEGER":
		case "DECIMAL":
			return <SurveySubmissionFormTextField {...props} />;
		case "CHECKBOX":
			return <SurveySubmissionFormCheckboxField {...props} />;
		case "DATE":
			return <SurveySubmissionFormDateField {...props} />;
		case "DATETIME":
			return <SurveySubmissionFormDateTimeField {...props} />;
		case "SELECTION":
			return <SurveySubmissionFormSelectionField {...props} />;
		case "DOCUMENT":
			return <SurveySubmissionFormDocumentField {...props} />;
		case "SECTION_BREAK":
			return <SurveySubmissionFormSectionBreak {...props} />;
	}
};
