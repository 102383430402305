// This file is automatically generated. Do not edit manually.

import { ServerSideDataModelRequest } from "src/api/generated/common/dataGrids/serverSideDataModelRequest";
import { ServerSideDataModelResult } from "src/api/generated/common/dataGrids/serverSideDataModelResult";
import { ProjectView } from "src/api/generated/erp/db/types/tables/projectView";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace ProjectsDataGridApi {
	export async function getDataGridData(args: {
		searchQuery: string;
		onlyProjectId: number | null | undefined;
		onlyDefaultSiteProjects: boolean;
		completedProjects: boolean;
		cancelledProjects: boolean;
		dataModelRequest: ServerSideDataModelRequest;
	}): Promise<ServerSideDataModelResult<ProjectView>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/project/projectsDataGrid/getDataGridData",
			method: "POST",
			data: {
				searchQuery: args.searchQuery,
				onlyProjectId: args.onlyProjectId,
				onlyDefaultSiteProjects: args.onlyDefaultSiteProjects,
				completedProjects: args.completedProjects,
				cancelledProjects: args.cancelledProjects,
				dataModelRequest: args.dataModelRequest,
			},
		});
		return response as ServerSideDataModelResult<ProjectView>;
	}
}
