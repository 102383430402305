import { UserNotificationsOfSourceDataGridApi } from "src/api/generated/common/userNotifications/api/userNotificationsOfSourceDataGridApi.ts";
import { UserNotificationSourceType } from "src/api/generated/postgres/db/types/public_/enums/userNotificationSourceType.ts";
import { UserNotificationView } from "src/api/generated/postgres/db/types/public_/tables/userNotificationView.ts";
import { booleanColumn, textColumn } from "src/components/common/dataGrid/columns.tsx";
import i18n from "i18next";
import { getDayOfWeekLabel } from "src/api/generated/java/time/dayOfWeek.ts";
import { useClientSideDataGridModel } from "src/components/common/dataGrid/gridModel/useClientSideDataGridModel";
import { AavoButton } from "src/components/common/buttons/AavoButton.tsx";
import { useAavoToast } from "src/utils/toast.ts";
import { faPaperPlane } from "@fortawesome/pro-regular-svg-icons";
import { UserNotificationsOfSourceEditApi } from "src/api/generated/common/userNotifications/api/userNotificationsOfSourceEditApi.ts";
import { ControlledAsyncCrudDataGrid } from "src/components/common/dataGrid/crud/ControlledAsyncCrudDataGrid";
import { UserNotificationsOfSourceForm } from "src/components/views/notifications/UserNotificationsOfSourceForm.tsx";
import {  parseIsoTimeNullable } from "src/utils/dayjsUtils.ts";

export interface UserNotificationsOfSourceDataGridProps {
	sourceType: UserNotificationSourceType;
	sourceId: number;
}

export const UserNotificationsOfSourceDataGrid = ({
	sourceType,
	sourceId,
}: UserNotificationsOfSourceDataGridProps) => {
	const { dataGridProps, onlySelectedRow } = useClientSideDataGridModel({
		fetchData: () =>
			UserNotificationsOfSourceDataGridApi.getGridData({
				sourceType: sourceType,
				sourceId: sourceId,
			}),
		gridId: "14C6B499DE52ABC",
		getRowId: (row) => row.id,
		initialParams: {},
	});
	const showToast = useAavoToast();
	return (
		<ControlledAsyncCrudDataGrid<UserNotificationView>
			columns={[
				textColumn({
					field: "userName",
					headerName: i18n.t("user"),
					width: 150,
				}),
				booleanColumn({
					field: "emailNotifications",
					headerName: i18n.t("email_notifications"),
				}),
				booleanColumn({
					field: "pushNotifications",
					headerName: i18n.t("push_notifications"),
				}),
				textColumn({
					field: "enabledFrom",
					headerName: i18n.t("enabled_from"),
					width: 150,
					valueGetter: (_, row) => parseIsoTimeNullable(row.enabledFrom)?.format("H:mm") ?? "",
				}),
				textColumn({
					field: "enabledTo",
					headerName: i18n.t("enabled_to"),
					width: 150,
					valueGetter: (_, row) => parseIsoTimeNullable(row.enabledTo)?.format("H:mm") ?? "",
				}),
				textColumn({
					field: "enabledWeekdays",
					headerName: i18n.t("enabled_weekdays"),
					valueGetter: (_, row) => row.enabledWeekdays?.map(getDayOfWeekLabel).join(", "),
					width: 250,
				}),
			]}
			actionBarComponents={
				<>
					<AavoButton
						label={i18n.t("send_test_notification")}
						icon={faPaperPlane}
						variant={"outlined"}
						disabled={onlySelectedRow == null}
						onClick={async () => {
							if (onlySelectedRow == null) return;
							await UserNotificationsOfSourceDataGridApi.sendTestNotifications({
								userNotificationsId: onlySelectedRow.id,
								testNotificationTitle: i18n.t("test_notification"),
								testNotificationContent: i18n.t("test_notification_content"),
							});
							showToast(i18n.t("test_notification_sent"));
						}}
					/>
				</>
			}
			remove={{
				type: "enabled",
				action: ({ items }) =>
					UserNotificationsOfSourceEditApi.delete_({
						userNotificationIds: items.map((item) => item.id),
					}),
			}}
			form={{
				addRowEnabled: true,
				editEnabled: true,
				dialogSize: "sm",
				component: ({ row, ...other }) => {
					return (
						<UserNotificationsOfSourceForm
							userNotificationId={row?.id ?? null}
							sourceType={sourceType}
							sourceId={sourceId}
							{...other}
						/>
					);
				},
			}}
			{...dataGridProps}
		/>
	);
};
