import {AavoMosaic} from "../../../../components/common/mosaic/AavoMosaic.tsx";
import {ComponentBatchesDataGrid} from "./ComponentBatchesDataGrid.tsx";
import {
    MellanoTransactionStatusViewApi_ComponentBatch
} from "../../../../api/generated/tenants/mellano/api/mellanoTransactionStatusViewApi.ts";
import {useState} from "react";
import {CenteredTypography} from "../../../../components/common/CenteredTypography.tsx";
import {ComponentBatchContentView} from "./ComponentBatchContentView.tsx";

export interface ComponentBatchesViewProps {
	transactionId: number;
}

export const ComponentBatchesView = ({ transactionId }: ComponentBatchesViewProps) => {
	const [selectedBatch, setSelectedBatch] = useState<
		MellanoTransactionStatusViewApi_ComponentBatch | undefined
	>(undefined);
	return (
		<AavoMosaic
			viewId={"84E6E425AE15C09C"}
			layout={{
				type: "column",
				first: {
					type: "panel",
					title: "Työmääräimet",
					content: (
						<ComponentBatchesDataGrid
							transactionId={transactionId}
							setSelectedBatch={setSelectedBatch}
						/>
					),
				},
				second: {
					type: "panel",
					title: "Komponentit",
					content:
						selectedBatch == undefined ?
							<CenteredTypography>Valitse työmääräin</CenteredTypography>
						:	<ComponentBatchContentView
								key={selectedBatch.workOrderCode}
								workOrderCode={selectedBatch?.workOrderCode}
								transactionId={transactionId}
							/>,
				},
			}}
		/>
	);
};
