import i18n from "i18next";
import { faShareSquare } from "@fortawesome/pro-regular-svg-icons";
import { useTenantCustomizations } from "src/tenantCustomizations/useTenantCustomizations.tsx";
import { AsyncButton, AsyncButtonProps } from "src/components/common/buttons/AsyncButton.tsx";
import { useGenericDialog } from "src/components/common/dialogs/useGenericDialog.ts";
import { CustomersView } from "src/components/views/erp/sales/customer/CustomersView.tsx";

export interface OpenCustomerOrderButtonProps extends Omit<AsyncButtonProps, "onClick"> {
	customerId: number;
}

export const OpenCustomerOrderButton = ({ customerId, variant = "menu" }: OpenCustomerOrderButtonProps) => {
	const { openDialog } = useGenericDialog();

	const { tenantConfig } = useTenantCustomizations();
	if (!tenantConfig.erp.salesEnabled) return null;

	return (
		<AsyncButton
			label={i18n.t("show_customer")}
			icon={faShareSquare}
			variant={variant}
			onClick={() => {
				openDialog({
					title: i18n.t("customer"),
					content: <CustomersView onlyCustomerToShowId={customerId} />,
				});
			}}
		/>
	);
};
