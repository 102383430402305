// This file is automatically generated. Do not edit manually.

import { PurchaseRequest } from "src/api/generated/erp/db/types/tables/purchaseRequest";
import { PurchaseRequestView } from "src/api/generated/erp/db/types/tables/purchaseRequestView";
import { SupplierPartView } from "src/api/generated/erp/db/types/tables/supplierPartView";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace PurchaseRequestFormApi {
	export async function getInitialData(args: {
		purchaseRequestId: number;
	}): Promise<PurchaseRequestFormApi_InitialData> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/purchase/purchaseRequests/form/getInitialData",
			method: "POST",
			data: {
				purchaseRequestId: args.purchaseRequestId,
			},
		});
		return response as PurchaseRequestFormApi_InitialData;
	}

	export async function update(args: { purchaseRequest: PurchaseRequest }): Promise<PurchaseRequest> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/purchase/purchaseRequests/form/update",
			method: "POST",
			data: {
				purchaseRequest: args.purchaseRequest,
			},
		});
		return response as PurchaseRequest;
	}
}

export interface PurchaseRequestFormApi_InitialData {
	purchaseRequest: PurchaseRequestView;
	supplierPartIdOptions: Array<SupplierPartView>;
}
