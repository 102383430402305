import { Control, UseFormWatch } from "react-hook-form";

export const castControl = <TOriginal extends TTarget, TTarget extends object>(
	control: Control<TOriginal>
): Control<TTarget> => {
	return (control as unknown) as Control<TTarget>;
};

export const castWatch = <TOriginal extends TTarget, TTarget extends object>(
	watch: UseFormWatch<TOriginal>
): UseFormWatch<TTarget> => {
	return (watch as unknown) as UseFormWatch<TTarget>;
};
