// This file is automatically generated. Do not edit manually.

import { SubProjectPlannedStartDateProvider_ReferenceType } from "src/api/generated/erp/project/subProject/service/subProjectPlannedStartDateProvider";
import { IsoDateString } from "src/types/dateTime";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace SalvosProjectApi {
	export async function calculateSubProjectPlannedStartDate(args: {
		projectId: number;
		subProjectId: number;
		reference: SubProjectPlannedStartDateProvider_ReferenceType;
	}): Promise<IsoDateString | null> {
		const response = await makeAxiosRequest({
			url: "/api/ext/salvos/project/calculateSubProjectPlannedStartDate",
			method: "POST",
			data: {
				projectId: args.projectId,
				subProjectId: args.subProjectId,
				reference: args.reference,
			},
		});
		return response === "" ? null : (response as IsoDateString | null);
	}

	export async function releaseSubProjectAndScheduleActivities(args: {
		subProjectId: number;
		resourceId: number;
	}): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/salvos/project/releaseSubProjectAndScheduleActivities",
			method: "POST",
			data: {
				subProjectId: args.subProjectId,
				resourceId: args.resourceId,
			},
		});
		return response as void;
	}
}
