import Papa from "papaparse";
import { createAndDownloadFile } from "src/utils/fileDownloading.ts";
import { AsyncMenuButton } from "src/components/common/contextMenu/AsyncMenuButton.tsx";
import { faDownload } from "@fortawesome/pro-regular-svg-icons";
import i18n from "i18next";
import { expectFileResult } from "src/components/common/dataGrid/gridModel/serverSideDataModelUtils.ts";
import { ServerSideDataModelResult } from "src/api/generated/common/dataGrids/serverSideDataModelResult.ts";
import { ServerSideDataModelRequest } from "src/api/generated/common/dataGrids/serverSideDataModelRequest.ts";
import { GridColDef } from "@mui/x-data-grid/models/colDef/gridColDef";
import { GridApiPro } from "@mui/x-data-grid-pro/models/gridApiPro";
import type { GridValidRowModel } from "@mui/x-data-grid/models/gridRows";
import { GRID_REORDER_COL_DEF, GridValueFormatter, GridValueGetter } from "@mui/x-data-grid-pro";
import { useContext } from "react";
import { AavoContextMenuClosingContext } from "src/components/common/contextMenu/AavoContextMenuClosingContext.ts";

export interface DataGridCsvExportButtonProps<TRow> {
	dataModel: DataModel<TRow>;
	getApi: () => GridApiPro;
}

type DataModel<TRow> = ClientSideDataModel<TRow> | ServerSideDataModel<TRow>;

interface ClientSideDataModel<TRow> {
	type: "clientSide";
	rows: TRow[];
}

interface ServerSideDataModel<TRow> {
	type: "serverSide";
	fetchData: (dataModelRequest: Partial<ServerSideDataModelRequest>) => Promise<ServerSideDataModelResult<TRow>>;
}

export const DataGridCsvExportButton = <TRow extends GridValidRowModel>({
	dataModel,
	getApi,
}: DataGridCsvExportButtonProps<TRow>) => {
	const closeContextMenu = useContext(AavoContextMenuClosingContext)?.closeContextMenu;

	return (
		<AsyncMenuButton
			key={"dataGridDefaultExportDataButton"}
			icon={faDownload}
			label={i18n.t("download_csv")}
			onClick={async () => {
				switch (dataModel.type) {
					case "clientSide":
						await onClickForClientSideDataModel(dataModel);
						break;
					case "serverSide":
						await onClickForServerSideDataModel(dataModel);
						break;
				}
				closeContextMenu?.();
			}}
		/>
	);

	async function onClickForClientSideDataModel({ rows }: ClientSideDataModel<TRow>) {
		createAndDownloadCsvFile(rows);
	}

	async function onClickForServerSideDataModel(dataModel: ServerSideDataModel<TRow>) {
		const fetchDataResult = await dataModel.fetchData({
			resultType: "FILE",
			paginationModel: {
				page: 0,
				pageSize: 10000,
			},
		});
		const { fileUrl } = expectFileResult(fetchDataResult).fileHandle;
		// Papa.parse supports stream-based reading from a URL, which would be much better for large files
		// However, some way for line-by-line CSV-writing would be needed to utilize this
		// Currently whole file is read into memory.
		const rows = await readRemoteCsvToArray(fileUrl);

		createAndDownloadCsvFile(rows);
	}

	async function readRemoteCsvToArray(fileUrl: string): Promise<TRow[]> {
		const rawCsv = await fetch(fileUrl).then((response) => response.text());
		return Papa.parse<TRow>(rawCsv, {
			delimiter: ";",
			header: true,
			skipEmptyLines: true,
		}).data;
	}

	function mapCsvRow(row: GridValidRowModel, columnDefs: GridColDef[]): string[] {
		return columnDefs.map((columnDef) => {
			const value = row[columnDef.field];
			const afterValueGetter = executeValueGetter(columnDef, value, row);
			return executeValueFormatter(columnDef, afterValueGetter, row);
		});
	}

	function executeValueGetter(column: GridColDef, value: any, row: GridValidRowModel) {
		const valueGetter = column.valueGetter;
		if (valueGetter == null) {
			return value;
		}
		const castedValueGetter = valueGetter as GridValueGetter<GridValidRowModel, any, any, any>;
		return castedValueGetter(value, row, column, getApiRef());
	}

	function executeValueFormatter(column: GridColDef, value: any, row: GridValidRowModel) {
		const valueFormatter = column.valueFormatter;
		if (valueFormatter == null) {
			return value;
		}
		const castedValueFormatter = valueFormatter as GridValueFormatter<GridValidRowModel, any, any, any>;
		return castedValueFormatter(value, row, column, getApiRef());
	}

	function createAndDownloadCsvFile(rows: TRow[]) {
		const columnDefs = getApi()
			.getAllColumns()
			.filter((column) => column.field !== GRID_REORDER_COL_DEF.field);

		const mappedCsvRows = rows.map((row) => mapCsvRow(row, columnDefs));
		const withHeader = [columnDefs.map((columnDef) => columnDef.headerName), ...mappedCsvRows];
		const csv = Papa.unparse(withHeader, {
			delimiter: ";",
			skipEmptyLines: true,
		});
		createAndDownloadFile("export.csv", csv);
	}

	function getApiRef() {
		return { current: getApi() };
	}
};
