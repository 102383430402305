import { Tab, Tabs } from "@mui/material";
import { AavoMosaicTabItem, resolveAavoMosaicTabItemKey } from "src/components/common/mosaic/types.ts";

export interface AavoMosaicWindowToolbarProps {
	items: AavoMosaicTabItem[];
	activeItemIndex: number;
	setActiveItemIndex: (index: number) => void;
}

export const AavoMosaicWindowToolbar = ({
	items,
	activeItemIndex,
	setActiveItemIndex,
}: AavoMosaicWindowToolbarProps) => {
	return (
		<Tabs
			value={activeItemIndex}
			onChange={(_, newValue) => setActiveItemIndex(newValue)}
			variant={"scrollable"}
			scrollButtons={"auto"}
			allowScrollButtonsMobile
			sx={{
				minHeight: 0,
				alignSelf: "stretch",
				"& .MuiTabs-scroller": {
					display: "flex",
				},
			}}
		>
			{items.map((item) => {
				return (
					<Tab
						label={item.title}
						key={resolveAavoMosaicTabItemKey(item)}
						sx={{
							alignSelf: "center",
							minHeight: 0,
							padding: 0,
							paddingLeft: 1.5,
							outline: "none !important",
						}}
					/>
				);
			})}
		</Tabs>
	);
};
