import { useClientSideDataGridModel } from "src/components/common/dataGrid/gridModel/useClientSideDataGridModel.tsx";
import { AccountingDimensionsApi } from "src/api/generated/erp/sales/accounting/api/accountingDimensionsApi.ts";
import { ControlledAsyncCrudDataGrid } from "src/components/common/dataGrid/crud/ControlledAsyncCrudDataGrid";
import { AccountingDimension } from "src/api/generated/erp/db/types/tables/accountingDimension.ts";
import { integerColumn, textColumn } from "src/components/common/dataGrid/columns.tsx";
import i18n from "i18next";
import { AccountingDimensionForm } from "src/components/views/erp/sales/accounting/managing/AccountingDimensionForm.tsx";

export interface AccountingDimensionsDataGridProps {
	onSelectionChanged: (dimension: AccountingDimension | undefined) => void;
}

export const AccountingDimensionsDataGrid = ({ onSelectionChanged }: AccountingDimensionsDataGridProps) => {
	const { dataGridProps } = useClientSideDataGridModel({
		fetchData: () => AccountingDimensionsApi.getAll(),
		getRowId: (row) => row.accountingDimensionId,
		initialParams: {},
		gridId: "2D5F11DB5DC19766",
		onSelectionChanged: (rows) => onSelectionChanged(rows[0]),
	});

	return (
		<ControlledAsyncCrudDataGrid<AccountingDimension>
			disableMultipleRowSelection
			columns={[
				integerColumn({
					field: "number",
					headerName: i18n.t("number"),
					width: 50,
				}),
				textColumn({
					field: "name",
					headerName: i18n.t("name"),
					width: 200,
				}),
			]}
			form={{
				addRowEnabled: true,
				editEnabled: true,
				dialogTitle: i18n.t("dimension"),
				dialogSize: "sm",
				component: ({ row, onCompleted, onFormEdited }) => (
					<AccountingDimensionForm
						dimension={row}
						onCompleted={onCompleted}
						onFormEdited={onFormEdited}
					/>
				),
			}}
			remove={({ items }) =>
				AccountingDimensionsApi.delete_({
					dimensionIds: items.map((item) => item.accountingDimensionId),
				})
			}
			{...dataGridProps}
		/>
	);
};
