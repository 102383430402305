import { TestConfigurationResultViewCommonProps } from "../TestConfigurationResultViewCommonProps";
import i18n from "i18next";
import { ConfiguratorLogApi } from "src/api/generated/erp/configurator/api/configuratorLogApi.ts";
import { ConfiguratorLogView } from "src/components/views/erp/configurator/configuratorTesting/logViews/ConfiguratorLogView.tsx";
import { TestConfigurationDocumentResultsDataGrid } from "src/components/views/erp/configurator/configuratorTesting/documents/TestConfigurationDocumentResultsDataGrid.tsx";
import { AavoMosaic } from "src/components/common/mosaic/AavoMosaic.tsx";

export const TestConfigurationDocumentResultsWithLogView = (props: TestConfigurationResultViewCommonProps) => {
	const { configuratorType, configurationSessionId } = props;
	return (
		<AavoMosaic
			viewId={"BA20C8371ED45761"}
			layout={{
				type: "row",
				first: {
					type: "panel",
					title: i18n.t("documents"),
					content: <TestConfigurationDocumentResultsDataGrid {...props} />,
				},
				second: {
					type: "panel",
					title: i18n.t("document_log"),
					content: (
						<ConfiguratorLogView
							fetchLogFile={() =>
								ConfiguratorLogApi.getTestConfigurationPhaseResolvingLog({
									phase: "DOCUMENTS",
									sessionId: configurationSessionId,
									configuratorType: configuratorType,
								})
							}
						/>
					),
				},
			}}
		/>
	);
};
