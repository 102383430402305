import {alpha, styled} from "@mui/material/styles";
import {DataGridPro, gridClasses} from "@mui/x-data-grid-pro";
import {
    dataGridCellClassNames,
    dataGridRowClassNames,
} from "src/components/common/dataGrid/styles/dataGridClassNames.ts";

const ODD_OPACITY = 0.08;

export const StyledDataGrid = styled(DataGridPro)(({ theme }) => ({
	"--DataGrid-containerBackground": theme.palette.grey[200],
	[`& .${gridClasses.columnHeaders}`]: {
		borderBottom: `2px solid ${theme.palette.grey[600]}`,
	},
	[`& .${gridClasses.footerContainer}`]: {
		minHeight: "2.0rem",
		height: "2.0rem",
		alignItems: "stretch",
		"& .MuiTablePagination-root": {
			display: "flex",
			alignItems: "stretch",
			overflow: "hidden",
		},
		"& .MuiTablePagination-toolbar": {
			minHeight: 0,
			"& > *": {
				marginBottom: 0,
				marginTop: 0,
			},
		},
	},
	[`& .${gridClasses.row}`]: {
		"&:hover, &.Mui-hovered": {
			backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
			"@media (hover: none)": {
				backgroundColor: "transparent",
			},
		},
		"&.Mui-selected": {
			backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY + theme.palette.action.selectedOpacity),
			"&:hover, &.Mui-hovered": {
				backgroundColor: alpha(
					theme.palette.primary.main,
					ODD_OPACITY + theme.palette.action.selectedOpacity + theme.palette.action.hoverOpacity,
				),
				// Reset on touch devices, it doesn't add specificity
				"@media (hover: none)": {
					backgroundColor: alpha(
						theme.palette.primary.main,
						ODD_OPACITY + theme.palette.action.selectedOpacity,
					),
				},
			},
		},
	},
	[`& .${dataGridRowClassNames.even}`]: {
		backgroundColor: theme.palette.common.white,
	},
	[`& .${dataGridRowClassNames.odd}`]: {
		backgroundColor: theme.palette.grey[100],
	},
	[`& .${dataGridRowClassNames.edited}`]: {
		backgroundColor: "rgba(255,255,0, .15)",
	},
	[`& .${dataGridRowClassNames.unsavedNew}`]: {
		backgroundColor: "rgba(0,128,0, .15)",
	},
	[`& .${dataGridRowClassNames.green}`]: {
		backgroundColor: alpha(theme.palette.green.light, 0.5),
	},
	[`& .${dataGridRowClassNames.yellow}`]: {
		backgroundColor: alpha(theme.palette.yellow.light, 0.25),
	},
	[`& .${dataGridRowClassNames.red}`]: {
		backgroundColor: alpha(theme.palette.error.light, 0.4),
	},
	[`& .${dataGridRowClassNames.invalid}`]: {
		border: `2px solid ${theme.palette.error.main}`,
	},

	[`& .${dataGridCellClassNames.borderColorError}`]: {
		border: `2px solid ${theme.palette.error.main}`,
	},
	[`& .${dataGridCellClassNames.backgroundColorWarning}`]: {
		backgroundColor: theme.palette.warning.main,
		color: theme.palette.warning.contrastText,
	},
	[`& .${dataGridCellClassNames.textColorSuccess}`]: {
		color: theme.palette.success.main,
	},
	[`& .${dataGridCellClassNames.textColorError}`]: {
		color: theme.palette.error.main,
	},
})) as typeof DataGridPro;
