import { AavoMosaic } from "src/components/common/mosaic/AavoMosaic.tsx";
import { DeliveryForm, DeliveryFormProps } from "src/components/views/erp/delivery/delivery/DeliveryForm.tsx";
import { DeliveryPackagesDataGrid } from "../deliveryPackage/DeliveryPackagesDataGrid.tsx";
import { DeliveryView } from "src/api/generated/erp/db/types/tables/deliveryView.ts";
import i18n from "i18next";
import { createDeliverySource } from "src/components/views/erp/delivery/deliverySourceUtils.ts";

export interface DeliveryFormWithPackagesProps {
	delivery: DeliveryView;
	onFormCompleted: DeliveryFormProps["onCompleted"];
	onFormEdited?: DeliveryFormProps["onFormEdited"];
}

export const DeliveryFormWithPackages = ({
	delivery,
	onFormCompleted,
	onFormEdited,
}: DeliveryFormWithPackagesProps) => {
	return (
		<AavoMosaic
			viewId={"A01310EA80E99B3D"}
			layout={{
				type: "row",
				first: {
					type: "panel",
					title: i18n.t("delivery"),
					content: (
						<DeliveryForm
							deliverySource={createDeliverySource({
								sourceType: delivery.sourceType,
								sourceId: delivery.sourceId,
							})}
							deliveryId={delivery.deliveryId}
							onCompleted={onFormCompleted}
							onFormEdited={onFormEdited}
						/>
					),
				},
				second: {
					type: "panel",
					title: i18n.t("delivery_packages"),
					content: (
						<DeliveryPackagesDataGrid
							gridId={"215795492EC3817C"}
							deliveryId={delivery.deliveryId}
							editable={delivery.deliveryState === "INITIAL"}
							disablePagination
							showPrintPackageLabelButton
						/>
					),
				},
			}}
		/>
	);
};
