// This file is automatically generated. Do not edit manually.

import { t } from "i18next";

export const SiteCertificateTypeValues = ["FINOTROL"] as const;

export type SiteCertificateType = (typeof SiteCertificateTypeValues)[number];

export const getSiteCertificateTypeLabels = () => ({
	FINOTROL: t("finotrol"),
});

export const getSiteCertificateTypeLabel = (value: SiteCertificateType): string => {
	return getSiteCertificateTypeLabels()[value];
};
