import { Divider } from "@mui/material";
import { AavoIconButton } from "../../common/buttons/AavoIconButton";
import {
	faArrowDownLeftAndArrowUpRightToCenter as closeMenuGroupsIcon,
	faArrowLeft,
	faTimes,
} from "@fortawesome/pro-regular-svg-icons";
import { mergeSx } from "src/utils/styles";
import { toolbarSx } from "src/components/views/appBar/toolbarStyles.ts";
import { AavoTextField } from "src/components/common/inputFields/AavoTextField.tsx";
import i18n from "i18next";
import { HorizontalBox } from "src/components/common/box/HorizontalBox.tsx";
import { useState } from "react";
import { AavoButton } from "src/components/common/buttons/AavoButton.tsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDebounce } from "src/utils/useDebounce.ts";
import { AavoLoading } from "src/components/common/AavoLoading.tsx";

export interface MainDrawerToolbarProps {
	closeDrawer: () => void;
	closeAllNodes: () => void;
	filterTreeNodes: (search: string) => void;
}

export const MainDrawerToolbar = ({
	closeDrawer,
	closeAllNodes,
	filterTreeNodes,
}: MainDrawerToolbarProps) => {
	return (
		<HorizontalBox
			sx={mergeSx(toolbarSx, {
				paddingX: "0.5rem !important",
				paddingY: "0.5rem",
			})}
		>
			<FilterTextField filter={filterTreeNodes} />
			<AavoIconButton
				icon={closeMenuGroupsIcon}
				onClick={() => {
					closeAllNodes();
				}}
			/>
			<Divider flexItem orientation={"vertical"} variant={"middle"} />
			<AavoIconButton icon={faArrowLeft} sx={{}} onClick={closeDrawer} />
		</HorizontalBox>
	);
};

const FilterTextField = ({ filter }: { filter: (value: string) => void }) => {
	const [value, setValue] = useState<string>("");

	const debounceFilter = useDebounce();
	const [isDebouncing, setIsDebouncing] = useState<boolean>(false);

	const filterImmediate = (newValue: string) => {
		filter(newValue);
		setIsDebouncing(false);
	};

	const onChange = (newValue: string) => {
		setValue(newValue);
		setIsDebouncing(true);
		debounceFilter(600, () => {
			filter(newValue);
			setIsDebouncing(false);
		});
	};

	return (
		<AavoTextField
			value={value}
			label={i18n.t("search")}
			onChange={onChange}
			onSubmit={filterImmediate}
			sx={{
				flex: 1,
				maxWidth: "none !important",
			}}
			InputProps={{
				sx: {
					paddingRight: 1,
				},
				endAdornment:
					isDebouncing ? <AavoLoading />
					: value.trim() !== "" ?
						<AavoButton
							iconEl={<FontAwesomeIcon icon={faTimes} size={"sm"} />}
							color={"secondary"}
							sx={{
								paddingLeft: 0.5,
							}}
							onClick={() => {
								setValue("")
								filterImmediate("")
							}}
						/>
					:	null,
			}}
		/>
	);
};
