/* eslint-disable prefer-rest-params */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import i18n from "i18next";

export const applyDomManipulationWorkaround = () => {
    // Following code block should be rune before react application starts, to prevent application crash
    // due to undesired browser plugins manipulating react DOM.
    if (typeof Node === "function") {
        if (Node.prototype) {
            const originalRemoveChild = Node.prototype.removeChild;
            Node.prototype.removeChild = function (child) {
                if (child.parentNode !== this) {
                    if (console) {
                        window.alert(
                            i18n.t("plugin_caused_application_error_message")
                        );
                        console.warn(
                            "Cannot remove a child from a different parent. " +
                            "Some chrome plugins such as transOver may cause this error together with agGrid.",
                            child,
                            this
                        );
                    }
                    return child;
                }
                return originalRemoveChild.apply(this, arguments);
            };

            const originalInsertBefore = Node.prototype.insertBefore;
            Node.prototype.insertBefore = function (newNode, referenceNode) {
                if (referenceNode && referenceNode.parentNode !== this) {
                    if (console) {
                        console.warn(
                            "Cannot insert before a reference node from a different parent",
                            referenceNode,
                            this
                        );
                    }
                    return newNode;
                }
                return originalInsertBefore.apply(this, arguments);
            };
        }
    }
};
