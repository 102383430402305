// This file is automatically generated. Do not edit manually.

import { ConfigurationPropertyValueFilter } from "src/api/generated/erp/configurator/propertyValueFiltering/configurationPropertyValueFilter";
import { ServerSideDataModelRequest } from "src/api/generated/common/dataGrids/serverSideDataModelRequest";
import { ServerSideDataModelResult } from "src/api/generated/common/dataGrids/serverSideDataModelResult";
import { ShopOrderView } from "src/api/generated/erp/db/types/tables/shopOrderView";
import { ProductionLineView } from "src/api/generated/erp/db/types/tables/productionLineView";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace AddShopOrdersToBatchDataGridApi {
	export async function getShopOrdersToAdd(args: {
		shopOrderBatchId: number;
		searchQuery?: string;
		productionLineId?: number | null | undefined;
		propertyValueFilters?: Array<ConfigurationPropertyValueFilter>;
		dataModelRequest: ServerSideDataModelRequest;
	}): Promise<AddShopOrdersToBatchDataGridApi_ShopOrdersToAddData> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/shopOrderBatch/getShopOrdersToAdd",
			method: "POST",
			data: {
				shopOrderBatchId: args.shopOrderBatchId,
				searchQuery: args.searchQuery,
				productionLineId: args.productionLineId,
				propertyValueFilters: args.propertyValueFilters,
				dataModelRequest: args.dataModelRequest,
			},
		});
		return response as AddShopOrdersToBatchDataGridApi_ShopOrdersToAddData;
	}

	export async function addSelectedRowsToBatch(args: {
		shopOrderBatchId: number;
		selectedShopOrderIds: Array<number>;
	}): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/shopOrderBatch/addSelectedRowsToBatch",
			method: "POST",
			data: {
				shopOrderBatchId: args.shopOrderBatchId,
				selectedShopOrderIds: args.selectedShopOrderIds,
			},
		});
		return response as void;
	}
}

export interface AddShopOrdersToBatchDataGridApi_ShopOrdersToAddData {
	data: ServerSideDataModelResult<ShopOrderView>;
	productionLineOptions: Array<ProductionLineView>;
}
