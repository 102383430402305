// This file is automatically generated. Do not edit manually.

import { ProjectActivityView } from "src/api/generated/erp/db/types/tables/projectActivityView";
import { ProjectActivityType } from "src/api/generated/erp/db/types/tables/projectActivityType";
import { IsoDateString } from "src/types/dateTime";
import { FrontendDownloadFileHandle } from "src/api/generated/common/frontendDownloadFileHandle";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace ProjectActivityApi {
	export async function getSingleActivity(args: { activityId: number }): Promise<ProjectActivityView> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/project/activities/getSingleActivity",
			method: "POST",
			data: {
				activityId: args.activityId,
			},
		});
		return response as ProjectActivityView;
	}

	export async function getProjectActivityTypes(): Promise<Array<ProjectActivityType>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/project/activities/getProjectActivityTypes",
			method: "POST",
			data: {},
		});
		return response as Array<ProjectActivityType>;
	}

	export async function getTemplateActivityOptions(args: { projectId: number }): Promise<Array<ProjectActivityView>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/project/activities/getTemplateActivityOptions",
			method: "POST",
			data: {
				projectId: args.projectId,
			},
		});
		return response as Array<ProjectActivityView>;
	}

	export async function cloneActivity(args: {
		templateActivityId: number;
		targetSubProjectId: number;
		newActivityName: string | null | undefined;
		newActivityPlannedStartDate: IsoDateString;
	}): Promise<number> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/project/activities/cloneActivity",
			method: "POST",
			data: {
				templateActivityId: args.templateActivityId,
				targetSubProjectId: args.targetSubProjectId,
				newActivityName: args.newActivityName,
				newActivityPlannedStartDate: args.newActivityPlannedStartDate,
			},
		});
		return response as number;
	}

	export async function releaseProjectActivity(args: {
		projectActivityId: number;
		releaseTasks: boolean;
	}): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/project/activities/releaseProjectActivity",
			method: "POST",
			data: {
				projectActivityId: args.projectActivityId,
				releaseTasks: args.releaseTasks,
			},
		});
		return response as void;
	}

	export async function revertReleaseProjectActivity(args: { projectActivityId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/project/activities/revertReleaseProjectActivity",
			method: "POST",
			data: {
				projectActivityId: args.projectActivityId,
			},
		});
		return response as void;
	}

	export async function startProjectActivity(args: { projectActivityId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/project/activities/startProjectActivity",
			method: "POST",
			data: {
				projectActivityId: args.projectActivityId,
			},
		});
		return response as void;
	}

	export async function completeProjectActivity(args: { projectActivityId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/project/activities/completeProjectActivity",
			method: "POST",
			data: {
				projectActivityId: args.projectActivityId,
			},
		});
		return response as void;
	}

	export async function revertCompleteProjectActivity(args: { projectActivityId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/project/activities/revertCompleteProjectActivity",
			method: "POST",
			data: {
				projectActivityId: args.projectActivityId,
			},
		});
		return response as void;
	}

	export async function cancelProjectActivity(args: { projectActivityId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/project/activities/cancelProjectActivity",
			method: "POST",
			data: {
				projectActivityId: args.projectActivityId,
			},
		});
		return response as void;
	}

	export async function printProjectActivityTasks(args: { activityId: number }): Promise<FrontendDownloadFileHandle> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/project/activities/printProjectActivityTasks",
			method: "POST",
			data: {
				activityId: args.activityId,
			},
		});
		return response as FrontendDownloadFileHandle;
	}

	export async function setActivityIsSchedulable(args: {
		activityId: number;
		isSchedulable: boolean;
	}): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/project/activities/setActivityIsSchedulable",
			method: "POST",
			data: {
				activityId: args.activityId,
				isSchedulable: args.isSchedulable,
			},
		});
		return response as void;
	}
}
