export const VIEWS = "views";
export const VIEW_ID = "viewId";
export const SETTINGS = "settings";
export const LOGGED_OUT = "loggedOut";
export const INSUFFICIENT_PERMISSIONS = "insufficientPermissions";
export const OAUTH_CALLBACK = "/connection/oauth/callback";
export const WOPI_FILE_PAGE = "wopi";
export const EMAIL_VERIFIED = "emailVerified";
export const EMAIL = "email";
export const HOME = "/";

export const INDEX = "index.html"
