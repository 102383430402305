import { FieldPath } from "react-hook-form";
import { FormFieldLayoutProps } from "src/components/common/forms/styles.ts";
import { FormSelectField, FormSelectFieldProps } from "./FormSelectField.tsx";
import { SimpleSelectFieldOption } from "../../inputFields/types.ts";

export interface FormEnumSelectFieldProps<
	TFieldValues extends object,
	TFieldName extends FieldPath<TFieldValues>,
	TEnum extends string,
	DisableClearable extends boolean | undefined,
> extends Omit<
			FormSelectFieldProps<
				TFieldValues,
				TFieldName,
				TEnum,
				SimpleSelectFieldOption<TEnum>,
				DisableClearable
			>,
			"getOptionKey" | "getOptionLabel" | "options"
		>,
		FormFieldLayoutProps {
	options: Record<TEnum, string>;
}

export const FormEnumSelectField = <
	TFieldValues extends object,
	TFieldName extends FieldPath<TFieldValues>,
	TEnum extends string,
	DisableClearable extends boolean | undefined = true,
>({
	options,
	disableClearable = true,
	...other
}: FormEnumSelectFieldProps<TFieldValues, TFieldName, TEnum, DisableClearable>) => {
	return (
		<FormSelectField<
			TFieldValues,
			TFieldName,
			TEnum,
			SimpleSelectFieldOption<TEnum>,
			DisableClearable
		>
			options={Object.entries<string>(options).map(([key, label]) => ({
				key: key as TEnum,
				label: label,
			}))}
			getOptionKey={(o) => o.key as TEnum}
			getOptionLabel={(o) => o.label}
			disableClearable={disableClearable as DisableClearable}
			{...other}
		/>
	);
};
