import {AavoMosaic} from "../../../../components/common/mosaic/AavoMosaic.tsx";
import {TransactionStatusView} from "./TransactionStatusView.tsx";
import {useState} from "react";
import {MellanoTransaction} from "../../../../api/generated/tenants/mellano/models/mellanoTransaction.ts";
import {TransactionRowView} from "./TransactionRowView.tsx";
import {CenteredTypography} from "../../../../components/common/CenteredTypography.tsx";

export const TransactionStatusPage = () => {
	const [selectedTransaction, setSelectedTransaction] = useState<MellanoTransaction | undefined>(
		undefined,
	);
	return (
		<AavoMosaic
			viewId={"4FCDCA0053F73BE"}
			layout={{
				type: "column",
				splitPercentage: 30,
				first: {
					type: "panel",
					title: "Tilaustilanne",
					content: (
						<TransactionStatusView setSelectedTransaction={setSelectedTransaction} />
					),
				},
				second: {
					type: "panel",
					title: "Tilausrivit",
					content:
						selectedTransaction == undefined ?
							<CenteredTypography>Valitse tilaus</CenteredTypography>
						:	<TransactionRowView
								key={selectedTransaction.transactionId}
								transactionId={selectedTransaction.transactionId}
							/>,
				},
			}}
		/>
	);
};
