// This file is automatically generated. Do not edit manually.

import { DocumentRevisionView } from "src/api/generated/postgres/db/types/documents/tables/documentRevisionView";
import { NewDocumentFileCreationType } from "src/api/generated/documents/newDocumentFileCreationType";
import { FrontendUploadFileHandle } from "src/api/generated/common/frontendUploadFileHandle";
import { DocumentRevision } from "src/api/generated/postgres/db/types/documents/tables/documentRevision";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace DocumentRevisionApi {
	export async function getDocumentRevisions(args: { documentId: number }): Promise<Array<DocumentRevisionView>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/documents/getDocumentRevisions",
			method: "POST",
			data: {
				documentId: args.documentId,
			},
		});
		return response as Array<DocumentRevisionView>;
	}

	export async function getNextDocumentRevisionNumber(args: { documentId: number }): Promise<number> {
		const response = await makeAxiosRequest({
			url: "/api/ext/documents/getNextDocumentRevisionNumber",
			method: "POST",
			data: {
				documentId: args.documentId,
			},
		});
		return response as number;
	}

	export async function createDocumentRevision(args: {
		documentId: number;
		revisionNumber?: number | null | undefined;
		note?: string;
		release?: boolean;
		fileCreationType?: NewDocumentFileCreationType;
		file: FrontendUploadFileHandle | null | undefined;
	}): Promise<number> {
		const response = await makeAxiosRequest({
			url: "/api/ext/documents/createDocumentRevision",
			method: "POST",
			data: {
				documentId: args.documentId,
				revisionNumber: args.revisionNumber,
				note: args.note,
				release: args.release,
				fileCreationType: args.fileCreationType,
				file: args.file,
			},
		});
		return response as number;
	}

	export async function updateDocumentRevision(args: {
		documentRevisionId: number;
		revisionNumber: number;
		note: string;
		newFile: FrontendUploadFileHandle | null | undefined;
	}): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/documents/updateDocumentRevision",
			method: "POST",
			data: {
				documentRevisionId: args.documentRevisionId,
				revisionNumber: args.revisionNumber,
				note: args.note,
				newFile: args.newFile,
			},
		});
		return response as void;
	}

	export async function releaseDocumentRevision(args: { documentRevisionId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/documents/releaseDocumentRevision",
			method: "POST",
			data: {
				documentRevisionId: args.documentRevisionId,
			},
		});
		return response as void;
	}

	export async function deleteDocumentRevisions(args: { documentRevisions: Array<DocumentRevision> }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/documents/deleteDocumentRevision",
			method: "POST",
			data: {
				documentRevisions: args.documentRevisions,
			},
		});
		return response as void;
	}
}
