// This file is automatically generated. Do not edit manually.

import { ShopOrderOperationView } from "src/api/generated/erp/db/types/tables/shopOrderOperationView";
import { WorkCenterSpcData } from "src/api/generated/erp/production/jobQueues/api/workCenterSpcData";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace ProductionLineWorkCenterOperationActionViewApi {
	export async function getInitData(args: {
		shopOrderId: number;
	}): Promise<ProductionLineWorkCenterOperationActionViewApi_InitData> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/productionLineWorkCenterOperationActionView/getInitData",
			method: "POST",
			data: {
				shopOrderId: args.shopOrderId,
			},
		});
		return response as ProductionLineWorkCenterOperationActionViewApi_InitData;
	}
}

export interface ProductionLineWorkCenterOperationActionViewApi_InitData {
	shopOrderOperations: Array<ShopOrderOperationView>;
	workCenterSpcData: WorkCenterSpcData;
}
