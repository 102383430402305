import { SystemDiagram } from "src/api/generated/postgres/db/types/public_/tables/systemDiagram";
import { enumColumn, textColumn } from "src/components/common/dataGrid/columns";
import { getSystemDiagramDirectionLabels } from "src/api/generated/postgres/db/types/public_/enums/systemDiagramDirection";
import { Site } from "src/api/generated/erp/db/types/tables/site";
import i18n from "i18next";
import { SystemDiagramCrudApi } from "src/api/generated/systemDiagrams/management/systemDiagramCrudApi";
import { SystemDiagramForm } from "src/components/views/erp/common/systemDiagrams/SystemDiagramForm.tsx";
import { SystemDiagramDataGridApi } from "src/api/generated/systemDiagrams/management/systemDiagramDataGridApi.ts";
import { CrudDataGrid } from "src/components/common/dataGrid/crud/CrudDataGrid.tsx";
import { ClientSideDataGridModel } from "src/components/common/dataGrid/gridModel/ClientSideDataGridModel";

export interface SystemDiagramsDataGridProps {
	setSelectedSystemDiagram: (systemDiagram: SystemDiagram | undefined) => void;
}

export const SystemDiagramsDataGrid = ({
	setSelectedSystemDiagram,
}: SystemDiagramsDataGridProps) => {
	return (
		<ClientSideDataGridModel
			fetchData={SystemDiagramDataGridApi.getSystemDiagramsData}
			getRows={(data) => data.systemDiagrams}
			getRowId={(row) => row.systemDiagramId}
			initialParams={{}}
			gridId={"A200F7B1338FFBA2"}
			onSelectionChanged={(rows) => {
				setSelectedSystemDiagram(rows[0]);
			}}
			render={({ data: { siteOptions }, dataGridProps }) => (
				<CrudDataGrid<SystemDiagram>
					columns={[
						textColumn({
							field: "name",
							headerName: i18n.t("name"),
							width: 200,
						}),
						textColumn({
							field: "contextType",
							headerName: i18n.t("context"),
							valueGetter: (_, row) => contextColumnValueGetter(row, siteOptions),
							width: 200,
						}),
						enumColumn({
							field: "diagramDirection",
							headerName: i18n.t("diagram_direction"),
							enumLabels: getSystemDiagramDirectionLabels(),
							width: 200,
						}),
					]}
					disableMultipleRowSelection={true}
					remove={{
						type: "enabled",
						action: async (params) =>
							await SystemDiagramCrudApi.delete_({ systemDiagrams: params.items }),
					}}
					form={{
						dialogTitle: i18n.t("system_diagram"),
						dialogSize: "sm",
						component: ({ row, onCompleted, onFormEdited }) => {
							return (
								<SystemDiagramForm
									systemDiagram={row}
									onCompleted={onCompleted}
									onFormEdited={onFormEdited}
								/>
							);
						},
						addRowEnabled: true,
						editEnabled: true,
					}}
					{...dataGridProps}
				/>
			)}
		/>
	);
};

const contextColumnValueGetter = (systemDiagram: SystemDiagram, siteOptions: Site[]): string => {
	const contextType = systemDiagram.contextType;
	let site: Site | null = null;
	if (contextType === "MANUFACTURING" || contextType === "SALES") {
		const siteId = Number(systemDiagram.contextParam1);
		site = siteOptions.find((site) => site.siteId === siteId) ?? null;
	}

	switch (contextType) {
		case "MANUFACTURING":
			return `${site?.siteName} | ${i18n.t("manufacturing")}`;
		case "SALES":
			return `${site?.siteName} | ${i18n.t("sales")}`;
		case "SPC":
			return i18n.t("spc");
	}
};
