import React from "react";
import { PartCategoryApi } from "src/api/generated/erp/parts/categories/api/partCategoryApi";
import { PartCategory } from "src/api/generated/erp/db/types/tables/partCategory";
import i18n from "i18next";
import { CrudActionButtons } from "src/components/common/crud/CrudActionButtons";
import { PartCategoryFormProps } from "src/components/views/erp/parts/partCategories/PartCategoryForm.tsx";

export interface PartCategoriesCrudViewActionBarComponentsProps {
	selectedPartCategory: PartCategory | null;
	setSelectedPartCategory: React.Dispatch<React.SetStateAction<PartCategory | null>>;
	openPartCategoryFormDialog: (params: Partial<PartCategoryFormProps>) => void;
	refreshPartCategories: () => Promise<unknown>;
}

export const PartCategoriesCrudViewActionBarComponents = ({
	selectedPartCategory,
	setSelectedPartCategory,
	openPartCategoryFormDialog,
	refreshPartCategories
}: PartCategoriesCrudViewActionBarComponentsProps) => {
	return (
		<CrudActionButtons
			add={{
				type: "enabled",
				action: () => {
					openPartCategoryFormDialog({});
				},
			}}
			remove={
				selectedPartCategory === null ?
					{
						type: "disabled",
						tooltip: i18n.t("select_part_category"),
					}
				:	{
						type: "enabled",
						action: async () => {
							await PartCategoryApi.delete_({
								partCategoryId: selectedPartCategory.partCategoryId,
							});
							setSelectedPartCategory(null);
							await refreshPartCategories();
						},
					}
			}
			edit={
				selectedPartCategory === null ?
					{
						type: "disabled",
						tooltip: i18n.t("select_part_category"),
					}
				:	{
						type: "enabled",
						action: () => {
							openPartCategoryFormDialog({
								partCategoryId: selectedPartCategory?.partCategoryId,
							});
						},
					}
			}
		/>
	);
};
