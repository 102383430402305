import { AavoMosaic } from "src/components/common/mosaic/AavoMosaic.tsx";
import i18n from "i18next";
import { PartInventoryLevelEventsDataGrid } from "src/components/views/erp/warehouse/inventory/PartInventoryLevelEventsDataGrid.tsx";
import { WarehousePickingObjectLocationReservationsDataGrid } from "src/components/views/erp/warehouse/inventory/WarehousePickingObjectLocationReservationsDataGrid.tsx";

export interface PartWarehouseLocationInventoryLevelEventsAndReservationsViewProps {
	partId: number;
	partWarehouseLocationId: number | null;
}

export const PartWarehouseLocationInventoryLevelEventsAndReservationsView = ({
	partId,
	partWarehouseLocationId,
}: PartWarehouseLocationInventoryLevelEventsAndReservationsViewProps) => {
	return (
		<AavoMosaic
			viewId={"FF357E81940C8579"}
			layout={{
				type: "tabs",
				items: [
					{
						type: "panel",
						key: "inventory_events",
						title: i18n.t("inventory_events"),
						content: (
							<PartInventoryLevelEventsDataGrid
								partId={partId}
								partWarehouseLocationId={partWarehouseLocationId}
							/>
						),
					},
					{
						type: "panel",
						key: "warehouse_picking_object_location_reservations",
						title: i18n.t("reservations"),
						content: (
							<WarehousePickingObjectLocationReservationsDataGrid
								partId={partId}
								partWarehouseLocationId={partWarehouseLocationId}
							/>
						),
					},
				],
			}}
		/>
	);
};
