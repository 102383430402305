// This file is automatically generated. Do not edit manually.

import { BillingPlanModel } from "src/api/generated/erp/db/types/tables/billingPlanModel";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace BillingPlanModelEditApi {
	export async function insert(args: { billingPlanModel: BillingPlanModel }): Promise<number> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/billingPlanModel/insert",
			method: "POST",
			data: {
				billingPlanModel: args.billingPlanModel,
			},
		});
		return response as number;
	}

	export async function update(args: { billingPlanModel: BillingPlanModel }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/billingPlanModel/update",
			method: "POST",
			data: {
				billingPlanModel: args.billingPlanModel,
			},
		});
		return response as void;
	}

	export async function delete_(args: { billingPlanModelIds: Array<number> }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/billingPlanModel/delete",
			method: "POST",
			data: {
				billingPlanModelIds: args.billingPlanModelIds,
			},
		});
		return response as void;
	}
}
