// This file is automatically generated. Do not edit manually.

import { TransformedConfigurationComponent_Field } from "src/api/generated/erp/configurator/componentTransformation/model/transformedConfigurationComponent";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace ConfiguratorFieldComponentAsFilterApi {
	export async function getFilterComponents(args: {
		componentUuids: Array<string>;
	}): Promise<Array<TransformedConfigurationComponent_Field>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/configurator/configuratorFieldComponentsAsFilter/getFilterComponents",
			method: "POST",
			data: {
				componentUuids: args.componentUuids,
			},
		});
		return response as Array<TransformedConfigurationComponent_Field>;
	}
}
