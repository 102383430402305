import { AccountingDimension } from "src/api/generated/erp/db/types/tables/accountingDimension.ts";
import { AccountingDimensionsApi } from "src/api/generated/erp/sales/accounting/api/accountingDimensionsApi.ts";
import { FormNumberField } from "src/components/common/forms/fields/FormNumberField.tsx";
import i18n from "i18next";
import { AavoForm } from "src/components/common/forms/AavoForm.tsx";
import { FormTextField } from "src/components/common/forms/fields/FormTextField.tsx";
import { FormCommonProps } from "src/components/common/forms/types.ts";

export interface AccountingDimensionFormProps extends FormCommonProps<number> {
	dimension: AccountingDimension | undefined;
}

interface FormValues extends AccountingDimension {}

export const AccountingDimensionForm = (props: AccountingDimensionFormProps) => {
	const { dimension, onCompleted, onFormEdited } = props;
	return (
		<AavoForm<FormValues, number>
			defaultValues={dimension}
			submit={submit}
			onCompleted={onCompleted}
			onFormEdited={onFormEdited}
			render={({control}) => (
				<>
					<FormNumberField
						control={control}
						name={"number"}
						label={i18n.t("number")}
						disabled={dimension != null}
					/>
					<FormTextField control={control} name={"name"} label={i18n.t("name")} />
				</>
			)}
		/>
	);

	async function submit(values: FormValues) {
		if (dimension == null) {
			return await AccountingDimensionsApi.insert({
				dimension: values,
			});
		} else {
			await AccountingDimensionsApi.update({
				dimension: values,
			});
			return dimension.accountingDimensionId;
		}
	}
};
