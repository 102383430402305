// This file is automatically generated. Do not edit manually.

import { ProjectObjectGanttData } from "src/api/generated/erp/project/gantt/projectObjectGanttData";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace ProjectGanttViewApi {
	export async function getGanttDataForProjects(args: {
		projectIds: Array<number>;
	}): Promise<ProjectObjectGanttData> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/projects/gantt/getGanttDataForProjects",
			method: "POST",
			data: {
				projectIds: args.projectIds,
			},
		});
		return response as ProjectObjectGanttData;
	}

	export async function getGanttDataForSubProjects(args: {
		subProjectIds: Array<number>;
	}): Promise<ProjectObjectGanttData> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/projects/gantt/getGanttDataForSubProjects",
			method: "POST",
			data: {
				subProjectIds: args.subProjectIds,
			},
		});
		return response as ProjectObjectGanttData;
	}

	export async function getGanttDataForActivities(args: {
		activityIds: Array<number>;
	}): Promise<ProjectObjectGanttData> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/projects/gantt/getGanttDataForActivities",
			method: "POST",
			data: {
				activityIds: args.activityIds,
			},
		});
		return response as ProjectObjectGanttData;
	}
}
