import { useEffect, useState } from "react";
import {
	dataGridPersistentStateProps,
	DEFAULT_GRID_INITIAL_STATE,
} from "src/components/common/dataGrid/dataGridStateStorage.ts";
import { useForwardedRef } from "src/utils/useForwardedRef.ts";
import { useAsyncState } from "src/utils/async/asyncState.ts";
import { logError, LoggedError } from "src/errorHandling/errorLogging.ts";
import { useMaybeControlledState } from "src/utils/useMaybeControlledState.ts";
import {
	UseDataGridModelGridCommonProps,
	UseDataGridModelCommonParams,
	UseDataGridModelCommonReturn,
} from "src/components/common/dataGrid/gridModel/dataGridModelCommon.ts";
import { setRefreshRefValue } from "src/utils/useRefreshRef";
import { GridRowId } from "@mui/x-data-grid-pro";
import { DataGridCsvExportButton } from "src/components/common/dataGrid/csvDownload/DataGridCsvExportButton.tsx";
import { usePartiallyStoredState } from "src/utils/usePartiallyStoredState.ts";

export interface UseClientSideDataGridModelParams<RowData extends object, TParams extends object>
	extends UseDataGridModelCommonParams<RowData, TParams> {
	fetchData: (params: TParams) => Promise<RowData[]>;
}

export interface UseClientSideDataGridModelReturn<RowData extends object, TParams>
	extends UseDataGridModelCommonReturn<RowData, TParams> {
	dataGridProps: UseClientSideDataGridModelGridProps<RowData>;
}

export type UseClientSideDataGridModelGridProps<RowData extends object> =
	UseDataGridModelGridCommonProps<RowData>;

export const useClientSideDataGridModel = <RowData extends object, TParams extends object>({
	gridId,
	getRowId,
	fetchData,
	initialParams,
	storedParams = [],
	apiRef: apiRefProp,
	selectedRows: selectedRowsProp,
	defaultSelectedRows = [],
	onSelectionChanged: setSelectedRowsProp,
	selectFirstRowOnLoad,
	defaultGridState = DEFAULT_GRID_INITIAL_STATE,
	refreshRef,
}: UseClientSideDataGridModelParams<RowData, TParams>): UseClientSideDataGridModelReturn<
	RowData,
	TParams
> => {
	const apiRef = useForwardedRef(apiRefProp);
	const [currentParams, setCurrentParams] = usePartiallyStoredState<TParams>({
		initialState: initialParams,
		storedKeys: storedParams,
		key: "data-grid-params--" + gridId,
	});
	const [rowsAsync, setRowsAsync] = useAsyncState<RowData[]>({});
	const [error, setError] = useState<LoggedError | undefined>(undefined);

	const { initialState: initialGridState, onStateChange: onGridStateChange } = dataGridPersistentStateProps(
		gridId,
		defaultGridState,
	);

	const [selectedRows, setSelectedRows] = useMaybeControlledState({
		controlledValue: selectedRowsProp,
		onChange: setSelectedRowsProp,
		defaultValue: defaultSelectedRows,
	});
	const onlySelectedRow = selectedRows.length === 1 ? selectedRows[0] : undefined;

	const onRowSelectionChanged = (selectedRowIds: GridRowId[]) => {
		const selectedRows = rowsAsync.data?.filter((row) => selectedRowIds.includes(getRowId(row))) ?? [];
		setSelectedRows(selectedRows);
	};

	const refreshData = async (newParams: Partial<TParams> = {}) => {
		try {
			setCurrentParams((prev) => {
				return {
					...prev,
					...newParams,
				};
			});
			const newRows = await setRowsAsync(() =>
				fetchData({
					...currentParams,
					...newParams,
				}),
			);

			if (selectFirstRowOnLoad && selectedRows.length === 0) {
				setSelectedRows(newRows.slice(0, 1));
			} else {
				const newSelectedRows = newRows.filter((row) =>
					selectedRows.some((selectedRow) => getRowId(selectedRow) === getRowId(row)),
				);
				setSelectedRows(newSelectedRows);
			}

			return newRows;
		} catch (e) {
			const loggedError = logError(e);
			setError(loggedError);
			return [];
		}
	};

	if (refreshRef) setRefreshRefValue(refreshRef, refreshData);

	const getApi = () => {
		if (!apiRef.current) {
			throw new Error("DataGrid API ref is not set");
		}
		return apiRef.current;
	};

	useEffect(() => {
		refreshData().catch(logError);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return {
		dataGridProps: {
			apiRef: apiRef,
			initialState: initialGridState,
			onStateChange: onGridStateChange,
			refreshData: refreshData,
			selectedRows: selectedRows.map(getRowId),
			onRowSelectionChanged: onRowSelectionChanged,
			getRowId: getRowId,
			actionBarMenuComponents2: [
				<DataGridCsvExportButton
					key={"dataGridDefaultExportDataButton"}
					dataModel={{
						type: "clientSide",
						rows: rowsAsync.data ?? [],
					}}
					getApi={getApi}
				/>,
			],
			rowsAsync: {
				data: rowsAsync.data ?? [],
				loading: rowsAsync.loading,
				error: error,
			},
		},
		currentParams: currentParams,
		refreshData: refreshData,
		selectedRows: selectedRows,
		setSelectedRows: setSelectedRows,
		onlySelectedRow: onlySelectedRow,
		getApi: getApi,
	};
};
