import { FormMultiSelectField, FormMultiSelectFieldProps } from "./FormMultiSelectField";
import { FieldPath } from "react-hook-form";

export interface FormSerializedMultiSelectFieldProps<
	TFieldValues extends object,
	TFieldName extends FieldPath<TFieldValues>,
	Key extends string | number,
> extends FormMultiSelectFieldProps<TFieldValues, TFieldName, Key> {}

export const FormSerializedMultiSelectField = <
	TFieldValues extends object,
	TFieldName extends FieldPath<TFieldValues>,
	Key extends string | number,
>(
	props: FormSerializedMultiSelectFieldProps<TFieldValues, TFieldName, Key>,
) => {
	return (
		<FormMultiSelectField
			{...props}
			encodeValue={(keys: Key[]) => JSON.stringify(keys)}
			decodeValue={(value: string | undefined) => {
				if (value === undefined) return [];
				return JSON.parse(value) as Key[];
			}}
		/>
	);
};
