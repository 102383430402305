import { CustomerOrderOffer } from "src/api/generated/erp/db/types/tables/customerOrderOffer";
import { AsyncForm, AsyncFormContentParams } from "src/components/common/forms/AsyncForm";
import {
	CustomerOrderOfferEditApi,
	CustomerOrderOfferEditApi_FormInitData,
} from "src/api/generated/erp/sales/customerOrderOffer/api/customerOrderOfferEditApi.ts";
import { DeepPartial } from "react-hook-form";
import { FormSelectField } from "src/components/common/forms/fields/FormSelectField";
import { nonNegativeIntegerRule, requireRule } from "src/components/common/forms/validation";
import { useGlobalInitData } from "src/contexts/useGlobalInitData.ts";
import i18n from "i18next";
import { FormAsyncSelectField } from "src/components/common/forms/fields/FormAsyncSelectField";
import { CustomerApi } from "src/api/generated/erp/sales/customer/api/customerApi.ts";
import { Customer } from "src/api/generated/erp/db/types/tables/customer.ts";
import { AavoButton } from "src/components/common/buttons/AavoButton.tsx";
import { faUsers } from "@fortawesome/pro-regular-svg-icons";
import { CustomerForm } from "src/components/views/erp/sales/customer/CustomerForm.tsx";
import { FormDateField } from "src/components/common/forms/fields/FormDateField.tsx";
import { FormNumberField } from "src/components/common/forms/fields/FormNumberField.tsx";
import { FormCheckbox } from "src/components/common/forms/fields/FormCheckbox.tsx";
import { FormTextField } from "src/components/common/forms/fields/FormTextField.tsx";
import { FormAsyncUserSelectField } from "src/components/views/users/FormAsyncUserSelectField.tsx";
import { FormEnumSelectField } from "src/components/common/forms/fields/FormEnumSelectField.tsx";
import { getCurrencyLabels } from "src/api/generated/erp/db/types/enums/currency.ts";
import { DeliveryMethod } from "src/api/generated/erp/db/types/tables/deliveryMethod.ts";
import { concatWithPipe } from "src/utils/strings.tsx";
import { getVatHandlingLabels } from "src/api/generated/erp/db/types/enums/vatHandling.ts";
import { FormSection } from "src/components/common/forms/FormSection.tsx";
import { getCountryLabels } from "src/api/generated/erp/db/types/enums/country.ts";
import { CustomerAddress } from "src/api/generated/erp/db/types/tables/customerAddress.ts";
import { useState } from "react";
import { useErrorDialog } from "src/components/common/dialogs/errorDialog/userErrorDialog.ts";
import { useGenericDialog } from "src/components/common/dialogs/useGenericDialog.ts";
import { FormCommonProps } from "src/components/common/forms/types.ts";
import { useTenantCustomizations } from "src/tenantCustomizations/useTenantCustomizations.tsx";
import { dayJsToDateIsoString } from "src/utils/dayjsUtils.ts";
import dayjs from "dayjs";
import {
	getDefaultDeliveryAddress,
	getDefaultInvoiceAddress,
	getDeliveryAddresses,
	getInvoiceAddresses,
} from "src/components/views/erp/sales/customerAddressUtils.ts";
import { getDefaultVatCode } from "src/components/views/erp/sales/salesUtils.ts";

export interface CustomerOrderOfferFormProps extends FormCommonProps<number> {
	customerOrderOfferId: number | undefined;
}

interface FormValues extends CustomerOrderOffer {
	updatePlannedDeliveryDateToLines: boolean;
	updatePlannedDeliveryDateToTasks: boolean;
	deliveryAddressId: number;
}

export const CustomerOrderOfferForm = ({
	customerOrderOfferId,
	onCompleted,
	onFormEdited,
}: CustomerOrderOfferFormProps) => {
	const { defaultSiteId, appUserId } = useGlobalInitData();
	const { tenantConfig } = useTenantCustomizations();

	return (
		<AsyncForm<CustomerOrderOfferEditApi_FormInitData, FormValues, number>
			fetch={() =>
				CustomerOrderOfferEditApi.getFormInitData({
					customerOrderOfferId,
				})
			}
			getDefaultValues={getDefaultValues}
			render={(params) => <FormContent {...params} />}
			submit={submitForm}
			onCompleted={onCompleted}
			onFormEdited={onFormEdited}
			columns={3}
		/>
	);

	function getDefaultValues({
		customerOrderOffer,
		customer,
		deliveryTermOptions,
		deliveryMethodOptions,
		vatCodeOptions,
	}: CustomerOrderOfferEditApi_FormInitData): DeepPartial<FormValues> {
		if (customerOrderOffer != null)
			return {
				...customerOrderOffer,
				updatePlannedDeliveryDateToLines: true,
				updatePlannedDeliveryDateToTasks: true,
			};
		return {
			siteId: defaultSiteId,
			plannedDeliveryDate: dayJsToDateIsoString(dayjs()),
			responsiblePersonId: appUserId,
			salespersonId: undefined,
			currency: "EUR",
			vatHandling: "NORMAL_VAT",
			vatCodeId: getDefaultVatCode(vatCodeOptions).vatCodeId,
			inputPricesWithVat: tenantConfig.erp.inputPricesWithVatByDefault ?? false,
			paymentTermId: customer?.paymentTermId,
			deliveryTermsId: deliveryTermOptions[0]?.deliveryTermsId,
			deliveryMethodId: deliveryMethodOptions[0]?.deliveryMethodId,
		};
	}

	async function submitForm(values: FormValues) {
		if (customerOrderOfferId != null) {
			await CustomerOrderOfferEditApi.update({
				offer: values,
				updatePlannedDateToLines: values.updatePlannedDeliveryDateToLines,
				updatePlannedDateToTasks: values.updatePlannedDeliveryDateToTasks,
			});
			return customerOrderOfferId;
		} else {
			return await CustomerOrderOfferEditApi.insert({
				offer: values,
			});
		}
	}
};

interface FormContentProps
	extends AsyncFormContentParams<CustomerOrderOfferEditApi_FormInitData, FormValues> {}

const FormContent = ({
	control,
	setValue,
	trigger,
	watch,
	data: {
		siteOptions,
		customerOrderOffer,
		paymentTermOptions,
		contractualTermOptions,
		deliveryTermOptions,
		deliveryMethodOptions,
		offerHasAnyLines,
		customerAddresses,
		vatCodeOptions,
		salespersonOptions,
	},
	formState: { dirtyFields },
}: FormContentProps) => {
	const { logErrorAndShowOnDialog } = useErrorDialog();
	const { openDialog } = useGenericDialog();

	const isExistingRecord = customerOrderOffer != null;
	const offerIsClosed = customerOrderOffer?.isClosed === true;
	const deliveryDateChanged = dirtyFields.plannedDeliveryDate;

	const [deliveryAddressOptions, setDeliveryAddressOptions] = useState<CustomerAddress[] | undefined>(
		customerAddresses == null ? undefined : getDeliveryAddresses(customerAddresses),
	);

	const [invoiceAddressOptions, setInvoiceAddressOptions] = useState<CustomerAddress[] | undefined>(
		customerAddresses == null ? undefined : getInvoiceAddresses(customerAddresses),
	);

	return (
		<>
			{customerOrderOffer?.customerOrderOfferState === "LOST" && (
				<FormTextField
					control={control}
					name={"loseReason"}
					label={i18n.t("lose_reason")}
					spanGridColumns
					multiline
				/>
			)}
			<FormSelectField
				control={control}
				name={"siteId"}
				label={i18n.t("site")}
				options={siteOptions}
				getOptionKey={(site) => site.siteId}
				getOptionLabel={(site) => site.siteName}
				rules={requireRule()}
				disabled={isExistingRecord}
				disableClearable
			/>
			<FormAsyncSelectField
				control={control}
				name={"customerId"}
				label={i18n.t("customer")}
				getOptionKey={(o: Customer) => o.customerId}
				getOptionLabel={(o) => o.customerName}
				fetchOptions={({ searchQuery, currentSelection }) =>
					CustomerApi.getCustomerSelectionOptions({
						currentSelection: currentSelection,
						searchQuery: searchQuery,
					})
				}
				rules={requireRule()}
				disabled={isExistingRecord}
				onChange={(customer) => onCustomerChanged(customer?.customerId ?? null)}
			/>
			<AavoButton
				label={i18n.t("create_customer")}
				icon={faUsers}
				hidden={isExistingRecord}
				onClick={() => {
					openDialog(({ closeDialog }) => ({
						title: i18n.t("create_customer"),
						size: "md",
						content: (
							<CustomerForm
								onCompleted={async (result) => {
									if (result.type === "success") {
										const customerId = result.value;
										setValue("customerId", customerId);
										await onCustomerChanged(customerId);
									}
									await closeDialog();
								}}
							/>
						),
					}));
				}}
			/>
			<FormDateField
				control={control}
				name={"validUntil"}
				label={i18n.t("valid_until")}
				disabled={offerIsClosed}
				onChange={async () => {
					await trigger("validForDays");
				}}
				rules={{
					validate: (_, values) => validateValidUntilAndValidForFields(values),
				}}
			/>
			<FormNumberField
				control={control}
				name={"validForDays"}
				label={i18n.t("valid_for_days")}
				disabled={offerIsClosed}
				onChange={async () => {
					await trigger("validUntil");
				}}
				rules={{
					validate: (_, values) => validateValidUntilAndValidForFields(values),
				}}
			/>
			{isExistingRecord && (
				<FormCheckbox
					control={control}
					name={"frozen"}
					label={i18n.t("frozen")}
					disabled={offerIsClosed}
				/>
			)}
			<FormTextField
				control={control}
				name={"orderReference"}
				label={i18n.t("reference")}
				disabled={offerIsClosed}
				startNewGridRow
			/>
			<FormTextField
				control={control}
				name={"customerOfferRequestNo"}
				label={i18n.t("customer_offer_request_no")}
				disabled={offerIsClosed}
			/>
			<FormTextField
				control={control}
				name={"externalOrderId"}
				label={i18n.t("external_order_id")}
				disabled={offerIsClosed}
			/>
			<FormDateField
				control={control}
				name={"plannedDeliveryDate"}
				label={i18n.t("planned_delivery_date")}
				disabled={offerIsClosed}
				rules={requireRule()}
			/>
			{isExistingRecord && deliveryDateChanged && (
				<FormCheckbox
					control={control}
					name={"updatePlannedDeliveryDateToLines"}
					label={i18n.t("update_also_to_lines")}
					spanGridColumns
				/>
			)}
			{isExistingRecord && deliveryDateChanged && (
				<FormCheckbox
					control={control}
					name={"updatePlannedDeliveryDateToTasks"}
					label={i18n.t("update_also_to_tasks")}
					spanGridColumns
				/>
			)}
			<FormAsyncUserSelectField
				control={control}
				name={"responsiblePersonId"}
				label={i18n.t("responsible_person")}
				rules={requireRule()}
				disabled={offerIsClosed}
				startNewGridRow
			/>
			<FormSelectField
				control={control}
				name={"salespersonId"}
				label={i18n.t("salesperson")}
				options={salespersonOptions}
				getOptionKey={(o) => o.salespersonId}
				getOptionLabel={(o) => o.name}
				disabled={offerIsClosed}
			/>
			<FormEnumSelectField
				control={control}
				name={"currency"}
				label={i18n.t("currency")}
				options={getCurrencyLabels()}
				disabled={offerIsClosed}
				disableClearable
			/>
			<FormSelectField
				control={control}
				name={"deliveryTermsId"}
				label={i18n.t("delivery_term")}
				options={deliveryTermOptions}
				getOptionKey={(o) => o.deliveryTermsId}
				getOptionLabel={(o) => `${o.deliveryTermsCode} - ${o.deliveryTermsDescription}`}
				rules={requireRule()}
				disabled={offerIsClosed}
				disableClearable
			/>
			<FormTextField
				control={control}
				name={"deliveryTermsDestination"}
				label={i18n.t("delivery_terms_destination")}
				disabled={offerIsClosed}
			/>
			<FormSelectField
				control={control}
				name={"deliveryMethodId"}
				label={i18n.t("delivery_method")}
				options={deliveryMethodOptions}
				getOptionKey={(o) => o.deliveryMethodId}
				getOptionLabel={(o) => o.deliveryMethodCode}
				rules={requireRule()}
				onChange={(_, deliveryMethod: DeliveryMethod | null) => {
					setValue("transportDuration", deliveryMethod?.transportDuration ?? null);
				}}
				disabled={offerIsClosed}
				disableClearable
			/>
			<FormSelectField
				control={control}
				name={"invoiceAddressId"}
				label={i18n.t("invoice_address")}
				rules={requireRule()}
				options={invoiceAddressOptions ?? []}
				getOptionKey={(o) => o.customerAddressId}
				getOptionLabel={(o) => concatWithPipe(o.address_1, o.address_2, o.city)}
				disabled={invoiceAddressOptions == null || offerIsClosed}
			/>
			<FormSelectField
				control={control}
				name={"paymentTermId"}
				label={i18n.t("payment_term")}
				options={paymentTermOptions}
				getOptionKey={(o) => o.paymentTermId}
				getOptionLabel={(o) => o.paymentTerm ?? ""}
				rules={requireRule()}
				disabled={offerIsClosed}
				disableClearable
			/>
			<FormSelectField
				control={control}
				name={"contractualTermId"}
				label={i18n.t("contractual_term")}
				options={contractualTermOptions}
				getOptionKey={(o) => o.contractualTermId}
				getOptionLabel={(o) => o.code}
				disabled={offerIsClosed}
			/>
			<FormEnumSelectField
				control={control}
				name={"vatHandling"}
				label={i18n.t("vat_handling")}
				options={getVatHandlingLabels()}
				rules={requireRule()}
				disabled={offerIsClosed}
				disableClearable
			/>
			<FormCheckbox
				control={control}
				name={"inputPricesWithVat"}
				label={i18n.t("input_prices_with_vat")}
				disabled={offerIsClosed || offerHasAnyLines}
			/>
			<FormCheckbox
				control={control}
				name={"billingPlanEnabled"}
				label={i18n.t("billing_plan_enabled")}
				disabled={offerIsClosed}
				startNewGridRow
			/>
			{watch("billingPlanEnabled") && watch("vatHandling") !== "REVERSE_CHARGE_VAT" && (
				<FormSelectField
					control={control}
					name={"vatCodeId"}
					label={i18n.t("vat_code")}
					options={vatCodeOptions}
					getOptionKey={(o) => o.vatCodeId}
					getOptionLabel={(o) => o.vatCodeName}
					disabled={offerIsClosed}
					disableClearable
				/>
			)}
			<FormTextField
				control={control}
				name={"contactName"}
				label={i18n.t("contact_person")}
				disabled={offerIsClosed}
				startNewGridRow
			/>
			<FormTextField
				control={control}
				name={"contactEmail"}
				label={i18n.t("contact_email")}
				disabled={offerIsClosed}
			/>
			<FormTextField
				control={control}
				name={"contactPhone"}
				label={i18n.t("contact_phone")}
				disabled={offerIsClosed}
			/>
			<FormTextField
				control={control}
				name={"documentText"}
				label={i18n.t("printout_text")}
				multiline
				disabled={offerIsClosed}
				spanGridColumns
			/>
			<FormTextField
				control={control}
				name={"note"}
				label={i18n.t("note")}
				spanGridColumns
				multiline
				disabled={offerIsClosed}
			/>
			<FormSection label={i18n.t("delivery_address")}>
				{!isExistingRecord && (
					<FormSelectField
						control={control}
						name={"deliveryAddressId"}
						label={i18n.t("delivery_address")}
						rules={requireRule()}
						options={deliveryAddressOptions ?? []}
						getOptionKey={(o: CustomerAddress) => o.customerAddressId}
						getOptionLabel={(o) => concatWithPipe(o.address_1, o.city)}
						onChange={(_, address) => {
							onDeliveryAddressChanged(address);
						}}
						disabled={deliveryAddressOptions == null}
						disableClearable
					/>
				)}
				<FormTextField
					control={control}
					name={"deliveryName"}
					label={i18n.t("address_description")}
					disabled={offerIsClosed}
				/>
				<FormTextField
					control={control}
					name={"deliveryAddress_1"}
					label={i18n.t("address_1")}
					rules={requireRule()}
					disabled={offerIsClosed}
				/>
				<FormTextField
					control={control}
					name={"deliveryAddress_2"}
					label={i18n.t("address_2")}
					disabled={offerIsClosed}
				/>
				<FormTextField
					control={control}
					name={"deliveryPostalCode"}
					label={i18n.t("postal_code")}
					rules={requireRule()}
					disabled={offerIsClosed}
				/>
				<FormTextField
					control={control}
					name={"deliveryCity"}
					label={i18n.t("city")}
					rules={requireRule()}
					disabled={offerIsClosed}
				/>
				<FormEnumSelectField
					control={control}
					name={"deliveryCountry"}
					label={i18n.t("country")}
					options={getCountryLabels()}
					disabled={offerIsClosed}
					disableClearable
				/>
				<FormTextField
					control={control}
					name={"transportDuration"}
					label={i18n.t("transport_duration")}
					rules={nonNegativeIntegerRule()}
					disabled={offerIsClosed}
				/>
				<FormTextField
					control={control}
					name={"deliveryContact"}
					label={i18n.t("contact_information")}
					disabled={offerIsClosed}
				/>
			</FormSection>
		</>
	);

	async function onCustomerChanged(customerId: number | null) {
		try {
			if (customerId == null) {
				setDeliveryAddressOptions(undefined);
				setInvoiceAddressOptions(undefined);
				return;
			}
			const { customer, deliveryAddresses, invoiceAddresses } =
				await CustomerApi.getCustomerWithAddresses({
					customerId: customerId,
				});

			setDeliveryAddressOptions(deliveryAddresses);
			setInvoiceAddressOptions(invoiceAddresses);

			const defaultDeliveryAddress = getDefaultDeliveryAddress(deliveryAddresses);
			setValue("deliveryAddressId", defaultDeliveryAddress?.customerAddressId);
			onDeliveryAddressChanged(defaultDeliveryAddress);

			const defaultInvoiceAddress = getDefaultInvoiceAddress(invoiceAddresses);
			setValue("invoiceAddressId", defaultInvoiceAddress.customerAddressId);

			setValue("paymentTermId", customer.paymentTermId);
			setValue("contactName", defaultDeliveryAddress.contact ?? "");
			setValue("contactEmail", defaultDeliveryAddress.email ?? "");
			setValue("contactPhone", defaultDeliveryAddress.phone ?? "");
		} catch (e) {
			logErrorAndShowOnDialog(e);
		}
	}

	function onDeliveryAddressChanged(address: CustomerAddress | null) {
		setValue("deliveryName", address?.name ?? "");
		setValue("deliveryAddress_1", address?.address_1 ?? "");
		setValue("deliveryAddress_2", address?.address_2 ?? "");
		setValue("deliveryPostalCode", address?.postalCode ?? "");
		setValue("deliveryCity", address?.city ?? "");
		setValue("deliveryCountry", address?.country ?? "FINLAND");
		setValue("deliveryContact", address == null ? "" : `${address.contact} ${address.phone}`);
	}

	function validateValidUntilAndValidForFields(values: FormValues) {
		if (values.validUntil == null && values.validForDays == null) {
			return i18n.t("valid_until_or_valid_for_days_required");
		}
		if (values.validUntil != null && values.validForDays != null) {
			return i18n.t("valid_until_and_valid_for_days_cannot_both_be_set");
		}
	}
};
