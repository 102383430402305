import { useState } from "react";
import i18n from "i18next";
import { SystemDiagram } from "src/api/generated/postgres/db/types/public_/tables/systemDiagram";
import { SystemDiagramsDataGrid } from "./SystemDiagramsDataGrid";
import { SystemDiagramNodesDataGrid } from "src/components/views/erp/common/systemDiagrams/SystemDiagramsNodesDataGrid";
import { SystemDiagramLinksDataGrid } from "src/components/views/erp/common/systemDiagrams/SystemDiagramLinksDataGrid.tsx";
import { AavoMosaic } from "src/components/common/mosaic/AavoMosaic";

export const SystemDiagramsManagementPage = () => {
	const [selectedSystemDiagram, setSelectedSystemDiagram] = useState<SystemDiagram | undefined>(undefined);

	return (
		<AavoMosaic
			viewId={"79DE712BDEF98B20"}
			layout={{
				type: "row",
				first: {
					type: "panel",
					title: i18n.t("system_diagrams"),
					content: (
						<SystemDiagramsDataGrid
							setSelectedSystemDiagram={setSelectedSystemDiagram}
						/>
					),
				},
				second: {
					type: "column",
					first: {
						type: "panel",
						title: i18n.t("nodes"),
						content: (
							<SystemDiagramNodesDataGrid
								key={selectedSystemDiagram?.systemDiagramId}
								systemDiagramId={selectedSystemDiagram?.systemDiagramId ?? null}
							/>
						),
					},
					second: {
						type: "panel",
						title: i18n.t("links"),
						content: (
							<SystemDiagramLinksDataGrid
								key={selectedSystemDiagram?.systemDiagramId}
								systemDiagramId={selectedSystemDiagram?.systemDiagramId ?? null}
							/>
						),
					},
				},
			}}
		/>
	);
};
