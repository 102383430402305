import { VerticalBox } from "src/components/common/box/VerticalBox.tsx";
import {
	faArrowRight,
	faHorizontalRule,
	faMemoPad,
	faNotes,
	faPenField, faText
} from "@fortawesome/pro-regular-svg-icons";
import Typography from "@mui/material/Typography";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { HorizontalBox } from "src/components/common/box/HorizontalBox.tsx";
import { useDrag } from "react-dnd";
import { ConfigurationComponentType } from "src/api/generated/erp/db/types/enums/configurationComponentType.ts";
import { Tooltip } from "@mui/material";
import i18n from "i18next";
import {
	CONFIGURATOR_COMPONENT_DND_KEY,
	ConfiguratorComponentDragItem,
	NewComponentDragItemComponentProps,
} from "src/components/views/erp/configurator/managing/productFamilyVersions/components/configuratorComponentDnd.ts";
import { ConfigurationComponent } from "src/api/generated/erp/db/types/tables/configurationComponent.ts";
import { showAsyncDialog } from "src/components/common/dialogs/asyncDialog.ts";
import { SelectLibraryComponentForm } from "src/components/views/erp/configurator/managing/productFamilyVersions/components/SelectLibraryComponentForm.tsx";
import { useGenericDialog } from "src/components/common/dialogs/useGenericDialog.ts";

export interface NewComponentPaletteProps {}

export const NewComponentPalette = ({}: NewComponentPaletteProps) => {
	const { openDialog } = useGenericDialog();

	return (
		<VerticalBox
			sx={{
				padding: 1,
				gap: 1,
			}}
		>
			<NewComponentPaletteItem
				componentType={"FIELD"}
				icon={faPenField}
				label={i18n.t("input_field")}
				componentProps={{
					fieldRefreshOnChange: false
				}}
			/>
			<NewComponentPaletteItem
				componentType={"TEXT"}
				icon={faText}
				label={i18n.t("text")}
			/>
			<NewComponentPaletteItem
				componentType={"SUB_CONFIGURATOR"}
				icon={faMemoPad}
				label={i18n.t("sub_configurator")}
				componentProps={{
					spanAllColumns: true,
				}}
			/>
			<NewComponentPaletteItem
				componentType={"SUB_CONFIGURATOR_LIST"}
				icon={faNotes}
				label={i18n.t("sub_configurator_list")}
				componentProps={{
					spanAllColumns: true,
				}}
			/>
			<NewComponentPaletteItem
				componentType={"SECTION_BREAK"}
				icon={faHorizontalRule}
				label={i18n.t("section_break")}
				componentProps={{
					spanAllColumns: true,
				}}
			/>
			<NewComponentPaletteItem
				componentType={"LIBRARY_COMPONENT_REFERENCE"}
				icon={faArrowRight}
				label={i18n.t("library_component")}
				componentProps={async () => {
					const referencedComponent = await showAsyncDialog<ConfigurationComponent>(
						openDialog,
						({ onCompleted }) => ({
							title: i18n.t("select_library_component"),
							size: "sm",
							content: <SelectLibraryComponentForm onCompleted={onCompleted} />,
						}),
					);
					if (referencedComponent === undefined) return "cancelled";
					return {
						referencedLibraryComponentUuid: referencedComponent.libraryComponentSelfUuid,
						label: referencedComponent.label,
						name: referencedComponent.name,
					};
				}}
			/>
		</VerticalBox>
	);
};

interface NewComponentPaletteItemProps {
	icon: IconProp;
	label: string;
	componentType: ConfigurationComponentType;
	componentProps?: NewComponentDragItemComponentProps;
}

const NewComponentPaletteItem = ({
	icon,
	label,
	componentType,
	componentProps,
}: NewComponentPaletteItemProps) => {
	const [{ isDragging }, dragRef] = useDrag<
		ConfiguratorComponentDragItem,
		unknown,
		{ isDragging: boolean }
	>(() => ({
		type: CONFIGURATOR_COMPONENT_DND_KEY,
		item: {
			type: "newComponent",
			componentType: componentType,
			componentProps,
		},
		collect: (monitor) => ({
			isDragging: monitor.isDragging(),
		}),
	}));

	return (
		<Tooltip title={i18n.t("add_component_by_dragging_it_to_view")}>
			<HorizontalBox
				ref={dragRef}
				sx={{
					gap: 1,
					alignItems: "center",
					opacity: isDragging ? 0.5 : 1,
					border: "1px solid",
					borderRadius: 1,
					padding: 1,
				}}
			>
				<FontAwesomeIcon icon={icon} />
				<Typography
					sx={{
						overflow: "hidden",
						textOverflow: "ellipsis",
						whiteSpace: "nowrap",
					}}
				>
					{label}
				</Typography>
			</HorizontalBox>
		</Tooltip>
	);
};
