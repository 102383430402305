import { useClientSideDataGridModel } from "src/components/common/dataGrid/gridModel/useClientSideDataGridModel.tsx";
import { ControlledAsyncDataGrid } from "src/components/common/dataGrid/ControlledAsyncDataGrid.tsx";
import { enumColumn, integerColumn, textColumn } from "src/components/common/dataGrid/columns.tsx";
import i18n from "i18next";
import { getCatalogPartRevisionStateLabels } from "src/api/generated/erp/db/types/enums/catalogPartRevisionState.ts";
import { ProductFamilyVersionUnconnectedRevisionsDataGridApi } from "src/api/generated/erp/configurator/management/productFamilies/api/productFamilyVersionUnconnectedRevisionsDataGridApi.ts";
import { AavoTextField } from "src/components/common/inputFields/AavoTextField.tsx";
import { CatalogPartRevisionView } from "src/api/generated/erp/db/types/tables/catalogPartRevisionView.ts";
import { AsyncButton } from "src/components/common/buttons/AsyncButton.tsx";
import { faLink, faStethoscope } from "@fortawesome/pro-regular-svg-icons";
import { useGenericDialog } from "src/components/common/dialogs/useGenericDialog.ts";
import { CatalogPartTestConfiguratorView } from "src/components/views/erp/configurator/configuratorTesting/catalogPart/CatalogPartTestConfiguratorView.tsx";
import { ConnectRevisionsToProductFamilyVersionView } from "src/components/views/erp/configurator/managing/productFamilyVersions/revisionConnections/connectRevisionsToProductFamilyVersion/ConnectRevisionsToProductFamilyVersionView.tsx";
import { RefreshableElementRef } from "src/utils/useRefreshRef.ts";
import { concatWithPipe } from "src/utils/strings.tsx";

export interface ProductFamilyVersionUnconnectedRevisionsDataGridProps {
	productFamilyVersionId: number;
	connectedRevisionsRefreshRef: RefreshableElementRef;
}

export const ProductFamilyVersionUnconnectedRevisionsDataGrid = ({
	productFamilyVersionId,
	connectedRevisionsRefreshRef,
}: ProductFamilyVersionUnconnectedRevisionsDataGridProps) => {
	const { dataGridProps, refreshData, onlySelectedRow, selectedRows } = useClientSideDataGridModel({
		fetchData: ({ searchQuery }) =>
			ProductFamilyVersionUnconnectedRevisionsDataGridApi.getDataGridData({
				productFamilyVersionId,
				searchQuery,
			}),
		initialParams: { searchQuery: "" },
		getRowId: (row) => row.catalogPartRevisionId,
		gridId: "34797A5AB7FC11CB",
	});

	const { openDialog } = useGenericDialog();

	return (
		<ControlledAsyncDataGrid<CatalogPartRevisionView>
			columns={[
				textColumn({
					field: "part" as any,
					headerName: i18n.t("part"),
					valueGetter: (_, row) =>
						concatWithPipe(
							row.partNo,
							row.catalogPartDescription_1,
							row.catalogPartDescription_2,
						),
					width: 200,
				}),
				integerColumn({
					field: "revisionNumber",
					headerName: i18n.t("revision"),
					width: 50,
				}),
				integerColumn({
					field: "configurationProductFamilyVersionNumber",
					headerName: i18n.t("product_family_version"),
					width: 100,
				}),
				enumColumn({
					field: "state",
					headerName: i18n.t("state"),
					enumLabels: getCatalogPartRevisionStateLabels(),
				}),
				integerColumn({
					field: "note",
					headerName: i18n.t("note"),
					width: 150,
				}),
				textColumn({
					field: "categoryName",
					headerName: i18n.t("category"),
					width: 100,
				}),
			]}
			actionBarComponents={
				<>
					<AavoTextField
						label={i18n.t("search")}
						onSubmit={(searchQuery) => refreshData({ searchQuery })}
					/>
					<AsyncButton
						key={"testConfiguration"}
						label={i18n.t("test_configuration")}
						icon={faStethoscope}
						variant={"outlined"}
						disabled={!onlySelectedRow}
						onClick={() =>
							openDialog({
								title: i18n.t("test_configuration"),
								content: (
									<CatalogPartTestConfiguratorView
										productFamilyVersionId={productFamilyVersionId}
										catalogPartRevisionId={onlySelectedRow!.catalogPartRevisionId}
									/>
								),
							})
						}
					/>
					<AsyncButton
						key={"connectSelected"}
						label={i18n.t("connect")}
						icon={faLink}
						variant={"outlined"}
						disabled={selectedRows.length === 0}
						onClick={() => {
							openDialog(({ closeDialog, onDataDirtyStateChanged }) => ({
								title: i18n.t("connect_part_revisions"),
								content: (
									<ConnectRevisionsToProductFamilyVersionView
										productFamilyVersionId={productFamilyVersionId}
										catalogPartRevisions={selectedRows}
										onDataDirtyStateChanged={onDataDirtyStateChanged}
										onCancel={async () => {
											await closeDialog({ confirmIfEdited: true });
										}}
										onSubmit={async () => {
											await closeDialog();
											await Promise.all([
												refreshData(),
												connectedRevisionsRefreshRef.refresh(),
											]);
										}}
									/>
								),
							}));
						}}
					/>
				</>
			}
			{...dataGridProps}
		/>
	);
};
