// This file is automatically generated. Do not edit manually.

import { SalesContractPriceGroupDiscountView } from "src/api/generated/erp/db/types/tables/salesContractPriceGroupDiscountView";
import { SalesContractPriceGroupDiscount } from "src/api/generated/erp/db/types/tables/salesContractPriceGroupDiscount";
import { SalesPartPriceGroup } from "src/api/generated/erp/db/types/tables/salesPartPriceGroup";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace SalesContractPriceGroupDiscountsApi {
	export async function getSalesContractDiscounts(args: {
		salesContractId: number;
	}): Promise<Array<SalesContractPriceGroupDiscountView>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/pricing/salesContracts/discounts/getSalesContractDiscounts",
			method: "POST",
			data: {
				salesContractId: args.salesContractId,
			},
		});
		return response as Array<SalesContractPriceGroupDiscountView>;
	}

	export async function getFormInitData(args: {
		salesContractId: number;
		salesContractPriceGroupDiscountId: number | null | undefined;
	}): Promise<SalesContractPriceGroupDiscountsApi_FormInitData> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/pricing/salesContracts/discounts/getFormInitData",
			method: "POST",
			data: {
				salesContractId: args.salesContractId,
				salesContractPriceGroupDiscountId: args.salesContractPriceGroupDiscountId,
			},
		});
		return response as SalesContractPriceGroupDiscountsApi_FormInitData;
	}

	export async function insert(args: { discount: SalesContractPriceGroupDiscount }): Promise<number> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/pricing/salesContracts/discounts/insert",
			method: "POST",
			data: {
				discount: args.discount,
			},
		});
		return response as number;
	}

	export async function update(args: { discount: SalesContractPriceGroupDiscount }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/pricing/salesContracts/discounts/update",
			method: "POST",
			data: {
				discount: args.discount,
			},
		});
		return response as void;
	}

	export async function delete_(args: { salesContractPriceGroupDiscountIds: Array<number> }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/pricing/salesContracts/discounts/delete",
			method: "POST",
			data: {
				salesContractPriceGroupDiscountIds: args.salesContractPriceGroupDiscountIds,
			},
		});
		return response as void;
	}
}

export interface SalesContractPriceGroupDiscountsApi_FormInitData {
	discount: SalesContractPriceGroupDiscount | null | undefined;
	priceGroupOptions: Array<SalesPartPriceGroup>;
}
