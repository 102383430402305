// This file is automatically generated. Do not edit manually.

import { t } from "i18next";

export const ProjectStateValues = ["INITIAL", "RELEASED", "READY", "CANCELLED"] as const;

export type ProjectState = (typeof ProjectStateValues)[number];

export const getProjectStateLabels = () => ({
	INITIAL: t("initial"),
	RELEASED: t("released"),
	READY: t("ready"),
	CANCELLED: t("cancelled"),
});

export const getProjectStateLabel = (value: ProjectState): string => {
	return getProjectStateLabels()[value];
};
