// This file is automatically generated. Do not edit manually.

import { UserNotificationSourceType } from "src/api/generated/postgres/db/types/public_/enums/userNotificationSourceType";
import { UserNotifications } from "src/api/generated/postgres/db/types/public_/tables/userNotifications";
import { AppUser } from "src/api/generated/postgres/db/types/role_management/tables/appUser";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace UserNotificationsOfSourceEditApi {
	export async function getInitData(args: {
		userNotificationId: number | null | undefined;
		sourceType: UserNotificationSourceType;
		sourceId: number;
	}): Promise<UserNotificationsOfSourceEditApi_InitData> {
		const response = await makeAxiosRequest({
			url: "/api/ext/userNotificationsOfSource/getInitData",
			method: "POST",
			data: {
				userNotificationId: args.userNotificationId,
				sourceType: args.sourceType,
				sourceId: args.sourceId,
			},
		});
		return response as UserNotificationsOfSourceEditApi_InitData;
	}

	export async function delete_(args: { userNotificationIds: Array<number> }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/userNotificationsOfSource/delete",
			method: "POST",
			data: {
				userNotificationIds: args.userNotificationIds,
			},
		});
		return response as void;
	}

	export async function insert(args: { userNotification: UserNotifications }): Promise<number> {
		const response = await makeAxiosRequest({
			url: "/api/ext/userNotificationsOfSource/insert",
			method: "POST",
			data: {
				userNotification: args.userNotification,
			},
		});
		return response as number;
	}

	export async function update(args: { userNotification: UserNotifications }): Promise<number> {
		const response = await makeAxiosRequest({
			url: "/api/ext/userNotificationsOfSource/update",
			method: "POST",
			data: {
				userNotification: args.userNotification,
			},
		});
		return response as number;
	}
}

export interface UserNotificationsOfSourceEditApi_InitData {
	userNotifications: UserNotifications | null | undefined;
	userOptions: Array<AppUser>;
}
