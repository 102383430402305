import { SupportedLanguage } from "../types/languages";

export type Types = StorageSettings & CookieSettings;

export interface StorageSettings {
	fontSize: number;
}

export interface CookieSettings {
	userLanguage: SupportedLanguage;
}

export const isStorageLocalSettings = (
	settings: object | undefined | null
): settings is StorageSettings => {
	return settings ? "fontSize" in settings : false;
};
