// This file is automatically generated. Do not edit manually.

import { CostEventItemView } from "src/api/generated/erp/db/types/tables/costEventItemView";
import { CostEventItem } from "src/api/generated/erp/db/types/tables/costEventItem";
import { VatCode } from "src/api/generated/erp/db/types/tables/vatCode";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace CostEventItemApi {
	export async function getAll(): Promise<Array<CostEventItemView>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/costEvents/costEventItems/getAll",
			method: "POST",
			data: {},
		});
		return response as Array<CostEventItemView>;
	}

	export async function getFormInitData(args: {
		costEventItemId: number | null | undefined;
	}): Promise<CostEventItemApi_FormInitData> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/costEvents/costEventItems/getFormInitData",
			method: "POST",
			data: {
				costEventItemId: args.costEventItemId,
			},
		});
		return response as CostEventItemApi_FormInitData;
	}

	export async function insert(args: { costEventItem: CostEventItem }): Promise<number> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/costEvents/costEventItems/insert",
			method: "POST",
			data: {
				costEventItem: args.costEventItem,
			},
		});
		return response as number;
	}

	export async function update(args: { costEventItem: CostEventItem }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/costEvents/costEventItems/update",
			method: "POST",
			data: {
				costEventItem: args.costEventItem,
			},
		});
		return response as void;
	}

	export async function delete_(args: { costEventItems: Array<CostEventItem> }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/costEvents/costEventItems/delete",
			method: "POST",
			data: {
				costEventItems: args.costEventItems,
			},
		});
		return response as void;
	}
}

export interface CostEventItemApi_FormInitData {
	costEventItem: CostEventItem | null | undefined;
	vatCodeOptions: Array<VatCode>;
}
