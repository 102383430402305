import { OpenInputDialogFunc } from "src/components/common/dialogs/input/useInputDialog.tsx";
import { UserApi } from "src/api/generated/users/api/userApi.ts";

export interface UserSelectionInputDialogParams {
	title: string;
	defaultValue: number | null;
	showInputDialog: OpenInputDialogFunc;
}

export const showUserSelectionDialog = async ({
	title,
	defaultValue,
	showInputDialog,
}: UserSelectionInputDialogParams): Promise<number | undefined> => {
	return await showInputDialog({
		type: "singleNumberSelect",
		title: title,
		defaultValue: defaultValue,
		fieldProps: {
			selection: {
				options: async ({ searchQuery, currentSelection }) => {
					const users = await UserApi.getUserSelectionOptions({
						searchQuery: searchQuery,
						currentSelectionUserId: currentSelection,
					});
					return users.map((u) => ({
						value: u.id,
						label: u.name,
					}));
				},
			},
		},
	});
};
