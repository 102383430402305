import {
    ShopOrderLinePickingObjectLocationView
} from "src/api/generated/erp/db/types/tables/shopOrderLinePickingObjectLocationView.ts";
import {AavoForm, AavoFormContentParams} from "src/components/common/forms/AavoForm.tsx";
import {FormCommonProps} from "src/components/common/forms/types.ts";
import i18n from "i18next";
import {FormNumberField} from "src/components/common/forms/fields/FormNumberField.tsx";
import {Control, DeepPartial, useFieldArray} from "react-hook-form";
import Box from "@mui/material/Box";
import {Theme} from "@mui/material/styles";
import {Typography} from "@mui/material";
import {
    ShopOrderBomPickingDataGridApi
} from "src/api/generated/erp/production/api/shopOrderPicking/shopOrderBomPickingDataGridApi.ts";

export interface ShopOrderBomPickingFormProps extends FormCommonProps<void> {
	shopOrderLinePickingObjectLocations: ShopOrderLinePickingObjectLocationView[];
}

interface FormValues {
	pickingObjects: ShopOrderLinePickingObjectLocationView[];
}

export const ShopOrderBomPickingForm = ({
	shopOrderLinePickingObjectLocations,
	onCompleted,
	onFormEdited,
}: ShopOrderBomPickingFormProps) => {
	function getDefaultValues(): DeepPartial<FormValues> {
		return {
			pickingObjects: shopOrderLinePickingObjectLocations.map((location) => ({
				...location,
				locationPickedQuantity: location.locationReservedQuantity,
			})),
		};
	}

	return (
		<AavoForm<FormValues, void>
			defaultValues={getDefaultValues()}
			onCompleted={onCompleted}
			onFormEdited={onFormEdited}
			render={(params) => <FormContent {...params} />}
			submit={async (values) => {
				await ShopOrderBomPickingDataGridApi.pickShopOrderBom({
					pickingObjects: values.pickingObjects,
				});
			}}
		/>
	);
};

interface FormContentProps extends AavoFormContentParams<FormValues> {}

const FormContent = ({ control }: FormContentProps) => {
	const shopOrderLinePickingObjectLocationArray = useFieldArray<FormValues>({
		control: control,
		name: "pickingObjects",
	});

	return shopOrderLinePickingObjectLocationArray.fields.map((field, index) => (
		<SingleObjectFormSection key={field.id} control={control} index={index} pickingObject={field} />
	));
};

interface SingleObjectFormSectionProps {
	pickingObject: ShopOrderLinePickingObjectLocationView;
	control: Control<FormValues> | undefined;
	index: number;
}

const SingleObjectFormSection = ({
	pickingObject: { partLongName, locationCode, partUnit },
	control,
	index,
}: SingleObjectFormSectionProps) => {
	return (
		<Box
			sx={{
				borderBottom: "1px solid",
				borderColor: (theme: Theme) => theme.palette.divider,
				display: "flex",
				gap: 1,
				paddingBottom: 1,
				marginBottom: 1,
			}}
		>
			<Box
				key={"partDescription"}
				sx={{
					flex: 1,
				}}
			>
				<Typography key={"headTitle"} variant={"h6"}>
					{partLongName}
				</Typography>
			</Box>
			<Box
				key={"quantityInformation"}
				sx={{
					display: "flex",
					gap: 1,
					flexDirection: "column",
				}}
			>
				<Box
					sx={{
						display: "flex",
						gap: 1,
						flexDirection: "row",
					}}
				>
					<FormNumberField
						key={"quantityToReserveInWarehouseUnit"}
						control={control}
						name={`pickingObjects.${index}.locationPickedQuantity`}
						label={i18n.t("quantity_with_unit", { unit: partUnit })}
					/>
				</Box>
				<Typography
					key={"locationCode"}
				>{`${i18n.t("warehouse_location")}: ${locationCode}`}</Typography>
			</Box>
		</Box>
	);
};
