import { V10PurchaseOrderCommands } from "src/api/generated/tenants/lehto/v10/v10PurchaseOrderCommands";
import { downloadFile } from "src/utils/fileDownloading";
import { faFileDownload } from "@fortawesome/pro-regular-svg-icons";
import { useTranslation } from "react-i18next";
import { dayJsToDateIsoString } from "src/utils/dayjsUtils.ts";
import { useInputDialog } from "src/components/common/dialogs/input/useInputDialog.tsx";
import { AsyncButton } from "src/components/common/buttons/AsyncButton";
import { PurchaseRequestDataTableApi_RowDto } from "src/api/generated/erp/purchase/purchaseRequest/purchaseRequestDataTableApi.ts";

export const LehtoPurchaseRequestDataGridActionBarComponents = ({
	selectedRows,
}: {
	selectedRows: PurchaseRequestDataTableApi_RowDto[];
}) => {
	const { t } = useTranslation();
	const showInputDialog = useInputDialog();
	return (
		<>
			<AsyncButton
				disabled={selectedRows.length === 0}
				icon={faFileDownload}
				label={t("download_v10")}
				onClick={async () => {
					const deliveryDate = await showInputDialog({
						type: "date",
						title: t("download_v10"),
						defaultValue: null,
						required: true,
						fieldProps: {
							date: {
								disablePast: true,
							},
						},
					});
					if (deliveryDate == undefined) return;

					const fileHandle = await V10PurchaseOrderCommands.downloadV10PurchaseOrderXml({
						deliveryDate: dayJsToDateIsoString(deliveryDate),
						purchaseRequestIds: selectedRows.map((row) => row.purchaseRequestId),
					});
					downloadFile(fileHandle);
				}}
			/>
		</>
	);
};
