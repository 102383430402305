// This file is automatically generated. Do not edit manually.

import { SalesPartCategory } from "src/api/generated/erp/db/types/tables/salesPartCategory";
import { ObjectAccountingDimensionValue } from "src/api/generated/erp/sales/accounting/objectAccountingDimensionValue";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace SalesPartCategoryEditApi {
	export async function getFormInitData(args: {
		salesPartCategoryId: number | null | undefined;
	}): Promise<SalesPartCategoryEditApi_FormInitData> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/salesPartCategory/getFormInitData",
			method: "POST",
			data: {
				salesPartCategoryId: args.salesPartCategoryId,
			},
		});
		return response as SalesPartCategoryEditApi_FormInitData;
	}

	export async function insert(args: {
		salesPartCategory: SalesPartCategory;
		accountingCodeIds: Array<number>;
	}): Promise<number> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/salesPartCategory/insert",
			method: "POST",
			data: {
				salesPartCategory: args.salesPartCategory,
				accountingCodeIds: args.accountingCodeIds,
			},
		});
		return response as number;
	}

	export async function update(args: {
		salesPartCategory: SalesPartCategory;
		accountingCodeIds: Array<number>;
	}): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/salesPartCategory/update",
			method: "POST",
			data: {
				salesPartCategory: args.salesPartCategory,
				accountingCodeIds: args.accountingCodeIds,
			},
		});
		return response as void;
	}

	export async function delete_(args: { salesPartCategoryIds: Array<number> }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/salesPartCategory/delete",
			method: "POST",
			data: {
				salesPartCategoryIds: args.salesPartCategoryIds,
			},
		});
		return response as void;
	}
}

export interface SalesPartCategoryEditApi_FormInitData {
	accountingDimensions: Array<ObjectAccountingDimensionValue>;
	salesPartCategory: SalesPartCategory | null | undefined;
}
