import {
	ProductionLineWorkCentersEditApi,
	ProductionLineWorkCentersEditApi_FormInitData,
} from "src/api/generated/erp/production/basedata/productionLines/api/productionLineWorkCentersEditApi.ts";
import { AsyncForm, AsyncFormContentParams } from "src/components/common/forms/AsyncForm.tsx";
import { FormSelectField } from "src/components/common/forms/fields/FormSelectField.tsx";
import { requireRule } from "src/components/common/forms/validation.ts";
import { concatWithPipe } from "src/utils/strings.tsx";
import i18n from "i18next";
import { FormCommonProps } from "src/components/common/forms/types.ts";
import { ProductionLineWorkcenter } from "src/api/generated/erp/db/types/tables/productionLineWorkcenter.ts";
import { DeepPartial } from "react-hook-form";

export interface ProductionLineWorkCenterFormProps extends FormCommonProps<number> {
	productionLineId: number;
	productionLineWorkCenterId: number | undefined;
}

interface FormValues extends ProductionLineWorkcenter {}

export const ProductionLineWorkCenterForm = (props: ProductionLineWorkCenterFormProps) => {
	const { productionLineId, productionLineWorkCenterId, onCompleted, onFormEdited } = props;
	return (
		<AsyncForm
			fetch={() =>
				ProductionLineWorkCentersEditApi.getFormInitData({
					productionLineId,
					productionLineWorkCenterId,
				})
			}
			getDefaultValues={getDefaultValues}
			submit={submit}
			onCompleted={onCompleted}
			onFormEdited={onFormEdited}
			render={(contentParams) => <FormContent {...props} {...contentParams} />}
		/>
	);

	function getDefaultValues({
		productionLineWorkCenter,
	}: ProductionLineWorkCentersEditApi_FormInitData): DeepPartial<FormValues> {
		return (
			productionLineWorkCenter ?? {
				productionLineId,
			}
		);
	}

	async function submit(values: FormValues) {
		if (productionLineWorkCenterId != null) {
			await ProductionLineWorkCentersEditApi.update({
				productionLineWorkcenter: values,
			});
			return productionLineWorkCenterId;
		} else {
			return await ProductionLineWorkCentersEditApi.insert({
				productionLineWorkcenter: values,
			});
		}
	}
};

interface FormContentProps
	extends ProductionLineWorkCenterFormProps,
		AsyncFormContentParams<ProductionLineWorkCentersEditApi_FormInitData, FormValues> {}

const FormContent = ({ control, data: { controlChartOptions, workCenterOptions } }: FormContentProps) => {
	return (
		<>
			<FormSelectField
				control={control}
				name={"workcenterId"}
				label={i18n.t("work_center")}
				options={workCenterOptions}
				getOptionKey={(option) => option.workcenterId}
				getOptionLabel={(option) => concatWithPipe(option.workcenterNo, option.workcenterDescription)}
				rules={requireRule()}
			/>
			<FormSelectField
				control={control}
				name={"controlChartId"}
				label={i18n.t("control_chart")}
				options={controlChartOptions}
				getOptionKey={(option) => option.id}
				getOptionLabel={(option) =>
					concatWithPipe(option.chartSuperGroupName, option.chartGroupName, option.chartName)
				}
			/>
		</>
	);
};
