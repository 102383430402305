import {
	ManualInventoryEventFormApi,
	ManualInventoryEventFormApi_PartAndWarehouseLocation,
} from "src/api/generated/erp/warehouse/inventory/inventoryEvent/api/manualInventoryEventFormApi.ts";

export const parseWarehouseLocationBarCode = async (
	code: string,
): Promise<"invalid_code" | ManualInventoryEventFormApi_PartAndWarehouseLocation> => {
	const words = code.split("+");
	const partNo = words[1];
	const warehouseLocationCode = words[2];
	if (!partNo || !warehouseLocationCode) {
		return "invalid_code";
	}

	return await ManualInventoryEventFormApi.getPartAndWarehouseLocation({
		partNo: partNo,
		locationCode: warehouseLocationCode,
	});
};
