import {
	ProjectObjectGanttBase,
	ProjectObjectGanttBaseProps,
} from "src/components/views/erp/project/gantt/ProjectObjectGanttBase.tsx";
import { ProjectGanttViewApi } from "src/api/generated/erp/project/api/projectGanttViewApi.ts";

export interface SubProjectGanttViewProps
	extends Pick<
		ProjectObjectGanttBaseProps,
		"onProjectScheduled" | "onSubProjectScheduled" | "onActivityScheduled" | "onTaskScheduled"
	> {
	subProjectIds: number[];
}

export const SubProjectGanttView = ({ subProjectIds, ...other }: SubProjectGanttViewProps) => {
	return (
		<ProjectObjectGanttBase
			rootType={"SUB_PROJECT"}
			collapseActivities
			fetchData={() => ProjectGanttViewApi.getGanttDataForSubProjects({ subProjectIds: subProjectIds })}
			{...other}
		/>
	);
};
