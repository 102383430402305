import { ProductionLineGroup } from "src/api/generated/erp/db/types/tables/productionLineGroup.ts";
import { ProductionLineGroupApi } from "src/api/generated/erp/production/basedata/productionLines/api/productionLineGroupApi";
import { integerColumn, textColumn } from "src/components/common/dataGrid/columns";
import { ControlledAsyncCrudDataGrid } from "src/components/common/dataGrid/crud/ControlledAsyncCrudDataGrid";
import { useClientSideDataGridModel } from "src/components/common/dataGrid/gridModel/useClientSideDataGridModel.tsx";
import { useGlobalInitData } from "src/contexts/useGlobalInitData.ts";
import i18n from "i18next";
import { ProductionLineGroupView } from "src/api/generated/erp/db/types/tables/productionLineGroupView.ts";
import { concatWithPipe } from "src/utils/strings.tsx";
import { genericNullableValue } from "src/utils/genericNullableValue.ts";
import { ProductionLineGroupEditApi } from "src/api/generated/erp/production/basedata/productionLines/api/productionLineGroupEditApi.ts";
import { ProductionLineGroupForm } from "src/components/views/erp/production/baseData/productionLines/ProductionLineGroupForm.tsx";
import { SelectSiteField } from "src/components/views/erp/common/sites/SelectSiteField.tsx";

export interface ProductionLineGroupsDataGridProps {
	onSelectionChanged: (selectedRow: ProductionLineGroup | undefined) => void;
}

export const ProductionLineGroupsDataGrid = ({ onSelectionChanged }: ProductionLineGroupsDataGridProps) => {
	const { defaultSiteId } = useGlobalInitData();

	const { dataGridProps, currentParams, refreshData } = useClientSideDataGridModel({
		fetchData: (params) => ProductionLineGroupApi.getProductionLineGroups(params),
		initialParams: {
			siteId: genericNullableValue<number>(defaultSiteId),
		},
		gridId: "D2E1F081F012772A",
		getRowId: (row) => row.productionLineGroupId,
		onSelectionChanged: (rows) => onSelectionChanged(rows[0]),
	});

	return (
		<ControlledAsyncCrudDataGrid<ProductionLineGroupView>
			disableMultipleRowSelection
			columns={[
				textColumn({
					field: "name",
					headerName: i18n.t("name"),
					width: 250,
				}),
				textColumn({
					field: "siteName",
					headerName: i18n.t("site"),
					width: 120,
				}),
				textColumn({
					field: "operation" as any,
					headerName: i18n.t("operation"),
					width: 150,
					valueGetter: (_, row) => concatWithPipe(row.operationNo, row.operationDescription),
				}),
				integerColumn({
					field: "productionLineGroupId",
					headerName: i18n.t("id"),
					width: 100,
				}),
			]}
			actionBarComponents={
				<>
					<SelectSiteField value={currentParams.siteId} onChange={(siteId) => refreshData({ siteId })} />
				</>
			}
			form={{
				addRowEnabled: true,
				editEnabled: true,
				dialogTitle: i18n.t("production_line_group"),
				dialogSize: "sm",
				component: ({ row, onCompleted, onFormEdited }) => (
					<ProductionLineGroupForm
						productionLineGroupId={row?.productionLineGroupId}
						newGroupSiteId={currentParams.siteId ?? defaultSiteId}
						onCompleted={onCompleted}
						onFormEdited={onFormEdited}
					/>
				),
			}}
			remove={({ items }) =>
				ProductionLineGroupEditApi.delete_({ productionLineGroupId: items[0]!.productionLineGroupId })
			}
			{...dataGridProps}
		/>
	);
};
