import { AavoMosaic } from "src/components/common/mosaic/AavoMosaic.tsx";
import i18n from "i18next";
import { ShopOrderBatchJobQueueInitialMobileView } from "src/components/views/erp/production/shopOrderBatch/shopOrderBatchJobQueue/mobile/ShopOrderBatchJobQueueInitialMobileView.tsx";
import { ShopOrderBatchJobQueueInProgressMobileView } from "src/components/views/erp/production/shopOrderBatch/shopOrderBatchJobQueue/mobile/ShopOrderBatchJobQueueInProgressMobileView.tsx";
import { useRefreshRef } from "src/utils/useRefreshRef.ts";
import { useState } from "react";

export const ShopOrderBatchJobQueueMobileView = () => {
	const initialsRefreshRef = useRefreshRef();
	const inProgressRefreshRef = useRefreshRef();
	const [activeTabItemKey, setActiveTabItemKey] = useState<string | null>(INITIAL_BATCHES_TAB_KEY);
	return (
		<AavoMosaic
			layout={{
				type: "tabs",
				activeTabItemKey: activeTabItemKey,
				onActiveTabItemChanged: setActiveTabItemKey,
				items: [
					{
						key: INITIAL_BATCHES_TAB_KEY,
						type: "panel",
						title: i18n.t("initial"),
						content: (
							<ShopOrderBatchJobQueueInitialMobileView
								refreshRef={initialsRefreshRef}
								inProgressBatchesRefreshRef={inProgressRefreshRef}
								focusToInProgressBatches={() => setActiveTabItemKey(INCOMPLETE_BATCHES_TAB_KEY)}
							/>
						),
					},
					{
						key: INCOMPLETE_BATCHES_TAB_KEY,
						type: "panel",
						title: i18n.t("incomplete"),
						content: (
							<ShopOrderBatchJobQueueInProgressMobileView
								refreshRef={inProgressRefreshRef}
								initialsRefreshRef={initialsRefreshRef}
								focusToInitialsTab={() => setActiveTabItemKey(INITIAL_BATCHES_TAB_KEY)}
							/>
						),
					},
				],
			}}
		/>
	);
};

const INCOMPLETE_BATCHES_TAB_KEY = "incompleteShopOrderBatches";
const INITIAL_BATCHES_TAB_KEY = "initialShopOrderBatches";
