import { FormTextFieldProps } from "./FormTextField.tsx";
import { FieldPath, FieldPathValue, useController } from "react-hook-form";
import { floatRule, integerRule } from "../validation.ts";
import { ChangeEvent, useEffect, useState } from "react";
import { toFloatOrNull } from "src/utils/strings.tsx";
import { AavoTextField } from "../../inputFields/AavoTextField.tsx";
import { mergeSx } from "src/utils/styles.ts";
import { formFieldLayoutPropsToSx } from "src/components/common/forms/styles.ts";
import { FLOAT_INPUT_REGEX } from "src/utils/regexPatterns.ts";

export interface FormNumberFieldProps<TFieldValues extends object, TFieldName extends FieldPath<TFieldValues>>
	extends Omit<FormTextFieldProps<TFieldValues, TFieldName>, "onChange" | "onSubmit"> {
	type?: "integer" | "float";
	onChange?: (value: number | null, event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
	onSubmit?: (value: number | null) => void;
}

export const FormNumberField = <TFieldValues extends object, TFieldName extends FieldPath<TFieldValues>>({
	control,
	name,
	rules: rulesProp,
	type = "float",
	onChange: onChangeProp,
	onSubmit,
	spanGridColumns,
	startNewGridRow,
	disabled,
	sx,
	...other
}: FormNumberFieldProps<TFieldValues, TFieldName>) => {
	const defaultRules = type === "float" ? floatRule() : integerRule();
	const rules = {
		...defaultRules,
		...rulesProp,
	};

	const { field, fieldState } = useController({
		name,
		control,
		rules,
		defaultValue: null as FieldPathValue<TFieldValues, TFieldName>,
	});

	// Internal value has to be used to support temporary invalid values, like "1."
	const [internalValue, setInternalValue] = useState<string>(field.value ?? "");

	useEffect(() => {
		setInternalValue(field.value ?? "");
	}, [field.value]);

	return (
		<AavoTextField
			inputRef={field.ref}
			error={fieldState.error?.message}
			value={internalValue}
			type={"number"}
			onChange={(v: string, e) => {
				if (!v.match(FLOAT_INPUT_REGEX) && v !== "") {
					return;
				}

				setInternalValue(v);
				const asNumberOrNull = parseValue(v);
				field.onChange(asNumberOrNull);
				onChangeProp?.(asNumberOrNull, e);
			}}
			onSubmit={(v) => {
				onSubmit?.(parseValue(v));
			}}
			onBlur={field.onBlur}
			disabled={disabled || field.disabled}
			name={field.name}
			sx={mergeSx(
				formFieldLayoutPropsToSx({
					spanGridColumns,
					startNewGridRow,
				}),
				sx,
			)}
			{...other}
		/>
	);

	function parseValue(value: string): number | null {
		const withoutComma = value.replace(",", ".");
		const withoutLastDot = withoutComma.replace(/\.$/, "");
		return toFloatOrNull(withoutLastDot);
	}
};
