import React, { MutableRefObject, useEffect, useRef } from "react";

export const useForwardedRef = <T>(ref: React.ForwardedRef<T> | undefined): MutableRefObject<T | null> => {
	const innerRef = useRef<T>(null);

	useEffect(() => {
		if (!ref) return;
		if (typeof ref === "function") {
			ref(innerRef.current);
		} else {
			ref.current = innerRef.current;
		}
	});

	return innerRef;
};
