// This file is automatically generated. Do not edit manually.

import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace CustomerOrderLineReservationFormApi {
	export async function createReservations(args: {
		reservationRequests: Array<CustomerOrderLineReservationFormApi_ReservationRequest>;
	}): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/picking/CustomerOrderLineReservationForm/createReservations",
			method: "POST",
			data: {
				reservationRequests: args.reservationRequests,
			},
		});
		return response as void;
	}
}

export interface CustomerOrderLineReservationFormApi_ReservationRequest {
	customerOrderLineId: number;
	partWarehouseLocationId: number | null | undefined;
	quantityInWarehouseUnit: number;
}
