import {SalesCommissionApi} from "src/api/generated/erp/sales/salesCommissions/api/salesCommissionApi.ts";
import {AsyncFetchRender} from "src/components/common/async/AsyncFetchRender.tsx";
import {AavoMosaic} from "src/components/common/mosaic/AavoMosaic.tsx";
import i18n from "i18next";
import {
    SalesCommissionSourceType
} from "src/components/views/erp/sales/salesCommissions/orderAndOfferViews/SalesCommissionSourceType.ts";
import {
    CustomerOrderOrOfferSalesCommissionLinesDataGrid
} from "src/components/views/erp/sales/salesCommissions/orderAndOfferViews/CustomerOrderOrOfferSalesCommissionLinesDataGrid.tsx";
import {SalesCommissionInspectView} from "./SalesCommissionInspectView";
import {RefreshableElementRef} from "src/utils/useRefreshRef.ts";

export interface CustomerOrderOrOfferSalesCommissionViewProps {
	salesCommissionId: number;
	sourceType: SalesCommissionSourceType
	editingEnabled: boolean
	salesCommissionViewRefreshRef?: RefreshableElementRef
}

export const CustomerOrderOrOfferSalesCommissionView = ({
	salesCommissionId,
	sourceType,
	editingEnabled,
	salesCommissionViewRefreshRef
}: CustomerOrderOrOfferSalesCommissionViewProps) => {
	return (
		<AsyncFetchRender
			refreshRef={salesCommissionViewRefreshRef}
			fetch={() =>
				SalesCommissionApi.getSalesCommission({
					salesCommissionId: salesCommissionId,
				})
			}
			content={({ salesCommission, lines }, refresh) => (
				<AavoMosaic
					viewId={"44D1956ABE73C717"}
					layout={{
						type: "row",
						splitPercentage: 20,
						first: {
							type: "panel",
							content: (
								<SalesCommissionInspectView
									salesCommission={salesCommission}
									lines={lines}
									refreshData={refresh}
									editingEnabled={editingEnabled}
								/>
							),
						},
						second: {
							type: "panel",
							title: i18n.t("installments"),
							content: (
								<CustomerOrderOrOfferSalesCommissionLinesDataGrid
									salesCommissionId={salesCommissionId}
									lines={lines}
									refreshData={refresh}
									sourceType={sourceType}
									editingEnabled={editingEnabled}
								/>
							),
						},
					}}
				/>
			)}
		/>
	);
};
