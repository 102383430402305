// This file is automatically generated. Do not edit manually.

import { WarehouseTransferState } from "src/api/generated/erp/db/types/enums/warehouseTransferState";
import { ServerSideDataModelRequest } from "src/api/generated/common/dataGrids/serverSideDataModelRequest";
import { ServerSideDataModelResult } from "src/api/generated/common/dataGrids/serverSideDataModelResult";
import { WarehouseTransferView } from "src/api/generated/erp/db/types/tables/warehouseTransferView";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace WarehouseTransferJobQueueDataGridApi {
	export async function getData(args: {
		warehouseTransferState: WarehouseTransferState;
		dataModelRequest: ServerSideDataModelRequest;
	}): Promise<ServerSideDataModelResult<WarehouseTransferView>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/warehouse/warehouseTransfer/getData",
			method: "POST",
			data: {
				warehouseTransferState: args.warehouseTransferState,
				dataModelRequest: args.dataModelRequest,
			},
		});
		return response as ServerSideDataModelResult<WarehouseTransferView>;
	}
}
