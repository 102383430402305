import i18n from "i18next";
import { useConfirmDialog } from "src/components/common/dialogs/confirmDialog/useConfirmDialog.ts";
import { useMessageDialog } from "src/components/common/dialogs/messageDialog/useMessageDialog.ts";
import { AsyncButton } from "src/components/common/buttons/AsyncButton.tsx";
import { CurrentUserApi } from "src/api/currentUserApi.ts";
import { useGlobalInitData } from "src/contexts/useGlobalInitData.ts";

export const ChangePasswordButton = () => {
	const confirm = useConfirmDialog();
	const showMessage = useMessageDialog();
	const { userEmail, changingPasswordIsPossible } = useGlobalInitData();

	if (!changingPasswordIsPossible) return null;

	return (
		<AsyncButton
			label={i18n.t("change_password")}
			variant={"outlined"}
			onClick={async () => {
				const confirmed = await confirm({
					message: i18n.t(
						"you_can_order_a_link_to_change_your_password_to_e-mail_address",
					),
				});
				if (!confirmed) return;

				await CurrentUserApi.resetPassword(userEmail);

				showMessage({
					title: i18n.t("link_successfully_sent"),
					content: `${i18n.t(
						"reset_password_link_successfully_sent_to_email",
					)}: ${userEmail}`,
				});
			}}
		/>
	);
};
