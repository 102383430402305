// This file is automatically generated. Do not edit manually.

import { t } from "i18next";

export const TaskStateValues = ["INITIAL", "RELEASED", "STARTED", "COMPLETED", "CANCELLED"] as const;

export type TaskState = (typeof TaskStateValues)[number];

export const getTaskStateLabels = () => ({
	INITIAL: t("initial"),
	RELEASED: t("released"),
	STARTED: t("started"),
	COMPLETED: t("completed"),
	CANCELLED: t("cancelled"),
});

export const getTaskStateLabel = (value: TaskState): string => {
	return getTaskStateLabels()[value];
};
