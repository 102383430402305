import { html, LitElement } from "lit";
import mermaid from "mermaid";
import { onAppContentAreaChanged } from "src/legacyViews/js/appContentAreaChangedEvent";
import { AAVO_MUI_THEME } from "src/theme/theme";

class MermaidWebComponent extends LitElement {
	static get properties() {
		return {
			diagramId: { type: String },
			markdown: { type: String },
			selectedNodeId: { type: String },
		};
	}

	constructor() {
		super();
		this.diagramId = "";
		this.markdown = "";
		this.selectedNodeId = null;
	}

	createRenderRoot() {
		return this;
	}

	render() {
		return html` <div class="mermaid-container" /> `;
	}

	firstUpdated(changedProperties) {
		this._renderDiagram();
		onAppContentAreaChanged(() => {
			this._renderDiagram();
		});
	}

	updated(changedProperties) {
		if (changedProperties.has("selectedNodeId")) {
			this._setNodeIsSelected(
				changedProperties.get("selectedNodeId"),
				false
			);
			this._setNodeIsSelected(this.selectedNodeId, true);
		}
		if (changedProperties.has("markdown")) {
			this._renderDiagram();
		}
	}

	_getMermaidConfig() {
		return {
			startOnLoad: false,
			logLevel: 4,
			deterministicIds: true,
			theme: 'base',
			themeVariables:{
				primaryColor: AAVO_MUI_THEME.palette.primary.main,
				primaryBorderColor: AAVO_MUI_THEME.palette.primary.main,
				primaryTextColor: AAVO_MUI_THEME.palette.primary.contrastText,
				secondaryColor: AAVO_MUI_THEME.palette.secondary.main,
			}
		};
	}

	_renderDiagram() {
		mermaid.initialize(this._getMermaidConfig());
		const container = this._getContainerElement();

		mermaid
			.render(`mermaid__${this.diagramId}`, this.markdown)
			.then(({ svg, bindFunctions }) => {
				container.innerHTML = svg;
				this._registerNodeClickCallbacksForAllNodes(container);
			})
			.catch((error) => {
				console.error("Mermaid render failed", error);
			});
	}

	_registerNodeClickCallbacksForAllNodes(container) {
		container
			.querySelectorAll(".node, .task")
			.forEach(this._registerNodeClickCallbacksForSingleNode.bind(this));
	}

	_registerNodeClickCallbacksForSingleNode(node) {
		let nodeId = node.id;
		if (!nodeId) return;

		// ID:s in flowcharts are for some reason nowadays in format "flowchart-id_value-nn"
		const flowchartIdRegexMatch = nodeId.match(/flowchart-(.+)-\d+/);
		if (flowchartIdRegexMatch) nodeId = flowchartIdRegexMatch[1];

		const dispatchCustomEvent = (eventName, originalEvent) =>
			this.dispatchEvent(
				new CustomEvent(eventName, {
					detail: {
						nodeId: nodeId,
						event: originalEvent,
						documentRect:
							document.documentElement.getBoundingClientRect(),
					},
				})
			);

		node.classList.add("clickable");
		node.addEventListener("click", (event) => {
			dispatchCustomEvent("nodeLeftClick", event);
		});
		node.addEventListener("contextmenu", (event) => {
			dispatchCustomEvent("nodeRightClick", event);
			event.preventDefault();
			return false;
		});
	}

	_setNodeIsSelected(nodeId, isSelected) {
		const container = this._getContainerElement();
		const nodeElem = container.querySelector(
			`#${nodeId}.node, #${nodeId}.task`
		);
		if (!nodeElem) return;
		if (isSelected) nodeElem.classList.add("node-selected");
		else nodeElem.classList.remove("node-selected");
	}

	_getContainerElement() {
		return this.querySelector(".mermaid-container");
	}
}

customElements.define("mermaid-js", MermaidWebComponent);
