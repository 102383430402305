import i18n from "i18next";
import { DocumentPreviewView } from "src/components/views/documents/preview/DocumentPreviewView.tsx";
import { RefreshableElementRef, useRefreshRef } from "src/utils/useRefreshRef.ts";
import { DocumentConnectionView } from "src/api/generated/postgres/db/types/documents/tables/documentConnectionView.ts";
import { AavoObjectRef } from "src/api/generated/common/sourceType/aavoObjectRef.ts";
import { AavoMosaic } from "src/components/common/mosaic/AavoMosaic.tsx";
import { useMediaQueryBreakpointDown } from "src/utils/breakpointUtils.ts";
import { DocumentsOfObjectsDataGrid } from "src/components/views/documents/objectDocuments/DocumentsOfObjectsDataGrid.tsx";
import { DocumentsViewEditable } from "src/components/views/documents/types.ts";
import { AavoMosaicTabItem } from "src/components/common/mosaic/types.ts";
import { useMaybeControlledState } from "src/utils/useMaybeControlledState.ts";
import { filterNulls, getOnlyMember } from "src/utils/arrayUtils.ts";
import { CenteredTypography } from "src/components/common/CenteredTypography.tsx";

export interface DocumentsOfMultipleObjectsViewProps {
	objectRefs: (AavoObjectRef | false)[];
	editable?: DocumentsViewEditable;
	hidePreview?: boolean;
	viewId?: string;
	refreshRef?: RefreshableElementRef;
	extraViews?: AavoMosaicTabItem[];
	selectedDocuments?: DocumentConnectionView[];
	setSelectedDocuments?: (doc: DocumentConnectionView[]) => void;
}

export const DocumentsOfMultipleObjectsView = ({
	objectRefs,
	editable = true,
	hidePreview: hidePreviewProp = false,
	viewId = "58D9749A534DEFB8",
	refreshRef,
	selectedDocuments: selectedDocumentProp,
	setSelectedDocuments: setSelectedDocumentProp,
	extraViews = [],
}: DocumentsOfMultipleObjectsViewProps) => {
	const [selectedDocuments, setSelectedDocuments] = useMaybeControlledState<DocumentConnectionView[]>({
		controlledValue: selectedDocumentProp,
		defaultValue: [],
		onChange: setSelectedDocumentProp,
	});

	const hidePreview = useMediaQueryBreakpointDown("md") || hidePreviewProp;
	const previewViewRefreshRef = useRefreshRef();
	const onlySelectedDocument = getOnlyMember(selectedDocuments);

	const documentsOfObjectDataGrid = (
		<DocumentsOfObjectsDataGrid
			refreshRef={refreshRef}
			objectRefs={objectRefs.filter(Boolean) as AavoObjectRef[]}
			editable={editable}
			selectedDocuments={filterNulls(selectedDocuments)}
			onSelectionChanged={(docs) => setSelectedDocuments(docs)}
			onDocumentFileChanged={async () => {
				await previewViewRefreshRef.refresh();
			}}
			gridId={`${viewId}_documents_grid`}
		/>
	);

	if (hidePreview) return documentsOfObjectDataGrid;

	return (
		<AavoMosaic
			viewId={viewId}
			layout={{
				type: "row",
				first: {
					type: "panel",
					title: i18n.t("documents"),
					content: documentsOfObjectDataGrid,
				},
				second: {
					type: "tabs",
					items: [
						{
							type: "panel",
							title: i18n.t("preview"),
							content:
								onlySelectedDocument == null ?
									<CenteredTypography>{i18n.t("select_a_document")}</CenteredTypography>
								:	<DocumentPreviewView
										key={onlySelectedDocument.documentId}
										documentId={onlySelectedDocument.documentId}
										refreshRef={previewViewRefreshRef}
									/>,
						},
						...extraViews,
					],
				},
			}}
		/>
	);
};
