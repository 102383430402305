import { ConfigurationProductFamily } from "src/api/generated/erp/db/types/tables/configurationProductFamily.ts";
import i18n from "i18next";
import { useState } from "react";
import { ConfigurationProductFamiliesDataGrid } from "src/components/views/erp/configurator/managing/productFamilies/ConfigurationProductFamiliesDataGrid.tsx";
import { CenteredTypography } from "src/components/common/CenteredTypography.tsx";
import { ConfigurationProductFamilyVersionsDataGrid } from "src/components/views/erp/configurator/managing/productFamilyVersions/ConfigurationProductFamilyVersionsDataGrid.tsx";
import { AavoMosaic } from "src/components/common/mosaic/AavoMosaic.tsx";

export const ConfigurationProductFamiliesView = () => {
	const [selectedProductFamily, setSelectedProductFamily] = useState<
		ConfigurationProductFamily | undefined
	>(undefined);

	return (
		<AavoMosaic
			viewId={"914AAEE2C03CAF69"}
			layout={{
				type: "row",
				splitPercentage: 30,
				first: {
					type: "panel",
					title: i18n.t("product_families"),
					content: (
						<ConfigurationProductFamiliesDataGrid onSelectionChanged={setSelectedProductFamily} />
					),
				},
				second: {
					type: "panel",
					title: i18n.t("versions"),
					content:
						selectedProductFamily == null ?
							<CenteredTypography>{i18n.t("select_product_family")}</CenteredTypography>
						:	<ConfigurationProductFamilyVersionsDataGrid
								key={selectedProductFamily.configurationProductFamilyId}
								productFamily={selectedProductFamily}
							/>,
				},
			}}
		/>
	);
};
