import { FormCommonProps } from "src/components/common/forms/types.ts";
import { AavoForm } from "src/components/common/forms/AavoForm.tsx";
import { FormTextField } from "src/components/common/forms/fields/FormTextField.tsx";
import i18n from "i18next";
import { ConfigurableObjectType } from "src/api/generated/erp/db/types/enums/configurableObjectType.ts";
import { FormCheckbox } from "src/components/common/forms/fields/FormCheckbox.tsx";
import { ObjectConfigurationHistoryEntryInput } from "src/api/generated/erp/configurator/objectConfigurationHistory/objectConfigurationHistoryEntryInput.ts";

export interface ObjectConfigurationHistoryEntryInputFormProps
	extends FormCommonProps<ObjectConfigurationHistoryEntryInput> {
	objectType: ConfigurableObjectType;
	objectId: number;
}

export const ObjectConfigurationHistoryEntryInputForm = ({
	onCompleted,
	onFormEdited,
}: ObjectConfigurationHistoryEntryInputFormProps) => {
	return (
		<AavoForm<ObjectConfigurationHistoryEntryInput, ObjectConfigurationHistoryEntryInput>
			defaultValues={{
				comment: "",
				keepCurrentEntry: false,
			}}
			submit={(values) => values}
			onCompleted={onCompleted}
			onFormEdited={onFormEdited}
			render={({ control }) => (
				<>
					<FormCheckbox
						control={control}
						name={"keepCurrentEntry"}
						label={i18n.t("keep_previous_configuration")}
					/>
					<FormTextField
						control={control}
						name={"comment"}
						label={i18n.t("comment_for_change_log")}
						autoFocus
						multiline
					/>
				</>
			)}
		/>
	);
};
