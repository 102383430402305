// This file is automatically generated. Do not edit manually.

import { ServerSideDataModelRequest } from "src/api/generated/common/dataGrids/serverSideDataModelRequest";
import { ServerSideDataModelResult } from "src/api/generated/common/dataGrids/serverSideDataModelResult";
import { CatalogPartView } from "src/api/generated/erp/db/types/tables/catalogPartView";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace CatalogPartDataGridApi {
	export async function searchCatalogParts(args: {
		partCategoryId: number | null | undefined;
		searchQuery?: string;
		showInactiveParts?: boolean;
		showPartsWithInitialRevision?: boolean;
		responsiblePersonId?: number | null | undefined;
		catalogPartId?: number | null | undefined;
		dataModelRequest: ServerSideDataModelRequest;
	}): Promise<ServerSideDataModelResult<CatalogPartView>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/productManagement/catalogParts/search",
			method: "POST",
			data: {
				partCategoryId: args.partCategoryId,
				searchQuery: args.searchQuery,
				showInactiveParts: args.showInactiveParts,
				showPartsWithInitialRevision: args.showPartsWithInitialRevision,
				responsiblePersonId: args.responsiblePersonId,
				catalogPartId: args.catalogPartId,
				dataModelRequest: args.dataModelRequest,
			},
		});
		return response as ServerSideDataModelResult<CatalogPartView>;
	}
}
