import {
	ServerSideDataModelResult,
	ServerSideDataModelResult_DataGridData, ServerSideDataModelResult_FileData
} from "src/api/generated/common/dataGrids/serverSideDataModelResult";

export function expectNonFileResult<TRow>(
	result: ServerSideDataModelResult<TRow>,
): ServerSideDataModelResult_DataGridData<TRow> {
	if (result.type !== "data") {
		throw new Error(`Expected data result, but got file result`);
	}
	return result;
}

export function expectFileResult(
	result: ServerSideDataModelResult<unknown>,
): ServerSideDataModelResult_FileData {
	if (result.type !== "file") {
		throw new Error(`Expected file result, but got data result`);
	}
	return result;
}
