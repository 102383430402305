import { AsyncForm } from "src/components/common/forms/AsyncForm.tsx";
import { SitePartRevisionPriceConfiguratorFormApi } from "src/api/generated/erp/parts/sitePart/api/sitePartRevisionPriceConfiguratorFormApi.ts";
import { FormCommonProps } from "src/components/common/forms/types.ts";
import { FormConfiguratorLuaEditor } from "src/components/views/erp/configurator/scripting/FormConfiguratorLuaEditor.tsx";

export interface SitePartRevisionPriceConfiguratorFormProps extends FormCommonProps<void> {
	partRevisionId: number;
}

export const SitePartRevisionPriceConfiguratorForm = ({
	partRevisionId,
	onCompleted,
	onFormEdited,
}: SitePartRevisionPriceConfiguratorFormProps) => {
	return (
		<AsyncForm
			fetch={() => SitePartRevisionPriceConfiguratorFormApi.getFormInitData({ partRevisionId })}
			getDefaultValues={({ partRevision: { priceTransformationScript } }) => ({
				priceTransformationScript,
			})}
			render={({ control, data: { partRevision } }) => (
				<>
					<FormConfiguratorLuaEditor
						control={control}
						name={"priceTransformationScript"}
						productFamilyVersionId={partRevision.configurationProductFamilyVersionId}
						catalogPartRevisionId={partRevision.catalogPartRevisionId}
					/>
				</>
			)}
			submit={async ({ priceTransformationScript }) => {
				await SitePartRevisionPriceConfiguratorFormApi.submit({
					partRevisionId,
					priceTransformationScript,
				});
			}}
			onCompleted={onCompleted}
			onFormEdited={onFormEdited}
		/>
	);
};
