import { AppState, LegacyAppState } from "./types";
import { setLocalStorageItemManaged } from "src/storage/localStorageUtils.ts";

export const APP_STATE_STORAGE_KEY = "aavo_app_state";

export const setAppStateStorage = (appState: AppState): AppState => {
	setLocalStorageItemManaged(APP_STATE_STORAGE_KEY, JSON.stringify(appState));
	return appState;
};

export const getOrInitAppStateStorage = (): AppState => {
	const fromStorage = localStorage.getItem(APP_STATE_STORAGE_KEY);
	if (fromStorage) {
		return JSON.parse(fromStorage) as AppState;
	} else {
		return initAppStateStorage();
	}
};

const initAppStateStorage = (): AppState => {
	const legacyEnvAssociationId =
		getLegacyAppStateStorage()?.selectedEnvAssociationId;
	const emptyAppState = {
		selectedBranchKey: null,
		selectedEnvAssociationId: legacyEnvAssociationId ?? null,
		viewStates: {},
	};
	setLocalStorageItemManaged(APP_STATE_STORAGE_KEY, JSON.stringify(emptyAppState));
	return emptyAppState;
};

const getLegacyAppStateStorage = (): LegacyAppState | undefined => {
	const fromStorage = localStorage.getItem("app_state");
	if (fromStorage) {
		return JSON.parse(fromStorage) as LegacyAppState;
	} else {
		return undefined;
	}
};
