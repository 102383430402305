import { ConfiguratorLibraryFunctionsApi } from "src/api/generated/erp/configurator/management/libraries/api/configuratorLibraryFunctionsApi";
import { ControlledAsyncCrudDataGrid } from "src/components/common/dataGrid/crud/ControlledAsyncCrudDataGrid";
import { useClientSideDataGridModel } from "src/components/common/dataGrid/gridModel/useClientSideDataGridModel.tsx";
import { integerColumn, textColumn } from "src/components/common/dataGrid/columns.tsx";
import i18n from "i18next";
import { ConfiguratorLibraryFunctionForm } from "src/components/views/erp/configurator/managing/libraries/libraryFunctions/ConfiguratorLibraryFunctionForm.tsx";
import { ConfiguratorLibraryFunctionView } from "src/api/generated/erp/db/types/tables/configuratorLibraryFunctionView.ts";
import { ConfiguratorLibraryVersion } from "src/api/generated/erp/db/types/tables/configuratorLibraryVersion.ts";

export interface ConfiguratorLibraryFunctionsDataGridProps {
	configuratorLibraryVersion: ConfiguratorLibraryVersion;
}

export const ConfiguratorLibraryFunctionsDataGrid = ({
	configuratorLibraryVersion,
}: ConfiguratorLibraryFunctionsDataGridProps) => {
	const { dataGridProps } = useClientSideDataGridModel({
		fetchData: () =>
			ConfiguratorLibraryFunctionsApi.getLibraryVersionFunctions({
				configuratorLibraryVersionId: configuratorLibraryVersion.configuratorLibraryVersionId,
			}),
		initialParams: {},
		gridId: "88766C906A320A",
		getRowId: (row) => row.configuratorLibraryFunctionId,
	});

	return (
		<ControlledAsyncCrudDataGrid<ConfiguratorLibraryFunctionView>
			disableMultipleRowSelection
			columns={[
				textColumn({
					field: "functionName",
					headerName: i18n.t("name"),
					width: 300,
				}),
				textColumn({
					field: "description",
					headerName: i18n.t("description"),
					width: 300,
				}),
				integerColumn({
					field: "configuratorLibraryFunctionId",
					headerName: i18n.t("id"),
					width: 80,
				}),
			]}
			form={{
				addRowEnabled: true,
				editEnabled: true,
				dialogSize: "lg",
				dialogTitle: i18n.t("library_function"),
				component: ({ row, onCompleted, onFormEdited }) => (
					<ConfiguratorLibraryFunctionForm
						configuratorLibraryVersionId={configuratorLibraryVersion.configuratorLibraryVersionId}
						configuratorLibraryFunctionId={row?.configuratorLibraryFunctionId}
						onCompleted={onCompleted}
						onFormEdited={onFormEdited}
					/>
				),
			}}
			remove={
				configuratorLibraryVersion.state === "INITIAL" &&
				(({ items }) =>
					ConfiguratorLibraryFunctionsApi.delete_({
						configuratorLibraryFunctionId: items[0]!.configuratorLibraryFunctionId,
					}))
			}
			{...dataGridProps}
		/>
	);
};
