// This file is automatically generated. Do not edit manually.

import { t } from "i18next";

export const InvoiceStateValues = ["INITIAL", "PRINTED", "PAID"] as const;

export type InvoiceState = (typeof InvoiceStateValues)[number];

export const getInvoiceStateLabels = () => ({
	INITIAL: t("initial"),
	PRINTED: t("printed"),
	PAID: t("paid"),
});

export const getInvoiceStateLabel = (value: InvoiceState): string => {
	return getInvoiceStateLabels()[value];
};
