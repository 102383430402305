import {ServerSideDataGridModel} from "src/components/common/dataGrid/gridModel/ServerSideDataGridModel.tsx";
import {
    InventoryLevelProfileDataGridApi,
    InventoryLevelProfileDataGridApi_Row,
} from "src/api/generated/erp/warehouse/inventory/inventoryEvent/api/inventoryLevelProfileDataGridApi.ts";
import {genericNullableValue} from "src/utils/genericNullableValue.ts";
import {getPartTypeLabel, PartType, PartTypeValues} from "src/api/generated/erp/db/types/enums/partType.ts";
import {CrudDataGrid} from "src/components/common/dataGrid/crud/CrudDataGrid.tsx";
import {floatColumn, textColumn} from "src/components/common/dataGrid/columns.tsx";
import i18n from "i18next";
import {AavoTextField} from "src/components/common/inputFields/AavoTextField.tsx";
import {AsyncUserSelectField} from "src/components/views/users/AsyncUserSelectField.tsx";
import {OpenSitePartButton} from "src/components/views/erp/utilComponents/OpenSitePartButton.tsx";
import {MenuCheckbox} from "src/components/common/contextMenu/MenuCheckbox.tsx";
import {SelectField} from "src/components/common/inputFields/SelectField.tsx";
import {useGenericDialog} from "src/components/common/dialogs/useGenericDialog.ts";
import {AsyncMenuButton} from "src/components/common/contextMenu/AsyncMenuButton.tsx";
import {faClipboardCheck} from "@fortawesome/pro-regular-svg-icons";
import {PartWarehouseLocationInventoryLevelEventsAndReservationsView} from "src/components/views/erp/warehouse/inventory/PartWarehouseLocationInventoryLevelEventsAndReservationsView.tsx";

export interface InventoryLevelProfileDataGridProps {
	partCategoryId: number | null;
	onlyPartToShowId: number | null | undefined;
	setSelectedPartId: (partId: number | null) => void;
}

export const InventoryLevelProfileDataGrid = ({
	partCategoryId,
	onlyPartToShowId,
	setSelectedPartId,
}: InventoryLevelProfileDataGridProps) => {
	const { openDialog } = useGenericDialog();
	return (
		<ServerSideDataGridModel
			gridId={"3EC0B483FD692FF66"}
			fetchData={(params) =>
				InventoryLevelProfileDataGridApi.getGridData({
					partCategoryId: partCategoryId,
					onlyPartToShowId: onlyPartToShowId,
					...params,
				})
			}
			getDataModelResult={(data) => data}
			getRowId={(row) => row.partId}
			onSelectionChanged={(selectedRows) => {
				setSelectedPartId(selectedRows.length > 0 ? (selectedRows[0]?.partId ?? null) : null);
			}}
			initialParams={{
				partType: genericNullableValue<PartType>(null),
				responsiblePersonId: genericNullableValue<number>(null),
				searchQuery: "",
				showOnlyDefaultSiteParts: true,
			}}
			selectFirstRowOnLoad={onlyPartToShowId != null}
			render={({ currentParams, refreshData, dataGridProps }) => {
				return (
					<CrudDataGrid<InventoryLevelProfileDataGridApi_Row>
						columns={[
							textColumn({
								field: "siteName",
								headerName: i18n.t("site_short"),
								width: 60,
							}),
							textColumn({
								field: "partLongName",
								headerName: i18n.t("part"),
								width: 300,
							}),
							floatColumn({
								field: "stockQuantity",
								headerName: i18n.t("stock_quantity"),
								width: 80,
							}),
							floatColumn({
								field: "reservedQuantity",
								headerName: i18n.t("reserved"),
								width: 80,
							}),
							floatColumn({
								field: "availableQuantity",
								headerName: i18n.t("available"),
								valueGetter: (_, row) => {
									return row.stockQuantity - row.reservedQuantity;
								},
								width: 80,
							}),
							textColumn({
								field: "partUnit",
								headerName: i18n.t("unit"),
								width: 80,
							}),
							floatColumn({
								field: "standardCost",
								headerName: i18n.t("cost"),
							}),
						]}
						actionBarComponents={
							<>
								<AavoTextField
									label={i18n.t("search")}
									onSubmit={async (value) => {
										await refreshData({ searchQuery: value });
									}}
								/>
								<SelectField
									label={i18n.t("part_type")}
									options={PartTypeValues}
									getOptionKey={(option) => option}
									getOptionLabel={(option) => getPartTypeLabel(option)}
								/>
								<AsyncUserSelectField
									label={i18n.t("responsible_person")}
									onChange={async (value) => {
										await refreshData({ responsiblePersonId: value?.id ?? null });
									}}
								/>
							</>
						}
						rowContextMenuComponents={({ row }) => [
							<OpenSitePartButton key={"partButton"} partId={row.partId} />,
							<AsyncMenuButton
								key={"partInventoryLevelEventsButton"}
								label={i18n.t("inventory_events")}
								icon={faClipboardCheck}
								onClick={() => {
									openDialog({
										title: `${i18n.t("inventory_events")}: ${row.partLongName}`,
										size: "lg",
										content: (
											<PartWarehouseLocationInventoryLevelEventsAndReservationsView
												partId={row.partId}
												partWarehouseLocationId={null}
											/>
										),
									});
								}}
							/>,
						]}
						actionBarMenuComponents={[
							<MenuCheckbox
								key={"onlyDefaultSite"}
								label={i18n.t("only_default_site")}
								onChange={async (value) => {
									await refreshData({ showOnlyDefaultSiteParts: value });
								}}
								checked={currentParams.showOnlyDefaultSiteParts}
							/>,
						]}
						{...dataGridProps}
					/>
				);
			}}
		/>
	);
};
