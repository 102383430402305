import { AavoMosaic } from "src/components/common/mosaic/AavoMosaic.tsx";
import { CustomersDataGrid } from "src/components/views/erp/sales/customer/CustomersDataGrid.tsx";
import { CustomerAddressDataGrid } from "src/components/views/erp/sales/customer/CustomerAddressDataGrid.tsx";
import { useState } from "react";
import { CustomerView } from "src/api/generated/erp/db/types/tables/customerView.ts";
import { CenteredTypography } from "src/components/common/CenteredTypography.tsx";
import i18n from "i18next";
import { ContactsDataGrid } from "src/components/views/erp/contacts/ContactsDataGrid.tsx";

export interface CustomersViewProps {
	onlyCustomerToShowId?: number;
}

export const CustomersView = ({ onlyCustomerToShowId }: CustomersViewProps) => {
	const [selectedCustomer, setSelectedCustomer] = useState<CustomerView | undefined>(undefined);
	return (
		<AavoMosaic
			layout={{
				type: "column",
				first: {
					type: "panel",
					content: (
						<CustomersDataGrid
							onlyCustomerToShowId={onlyCustomerToShowId}
							onSelectionChanged={setSelectedCustomer}
						/>
					),
				},
				second: {
					type: "tabs",
					items: [
						{
							type: "panel",
							title: i18n.t("addresses"),
							content:
								selectedCustomer == undefined ?
									<CenteredTypography>{i18n.t("select_customer")}</CenteredTypography>
								:	<CustomerAddressDataGrid
										key={selectedCustomer.customerId}
										customerId={selectedCustomer.customerId}
									/>,
						},
						{
							type: "panel",
							title: i18n.t("contacts"),
							content:
								selectedCustomer == undefined ?
									<CenteredTypography>{i18n.t("select_customer")}</CenteredTypography>
								:	<ContactsDataGrid
										key={selectedCustomer.customerId}
										connectedItemType={"CUSTOMER"}
										connectedItemId={selectedCustomer.customerId}
									/>,
						},
					],
				},
			}}
		/>
	);
};
