import { TaskView } from "src/api/generated/postgres/db/types/tasks/tables/taskView.ts";
import { Theme } from "@mui/material/styles";
import {
	faArrowRotateRight,
	faCircleExclamation,
	faHourglassHalf,
	faSquareFull,
	faThumbsUp,
	faTimes,
} from "@fortawesome/pro-regular-svg-icons";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";

export function getTaskStateIcon(task: TaskView, theme: Theme): { icon: IconDefinition; color: string } {
	if (task.taskIsLate && task.taskState !== "COMPLETED")
		return {
			icon: faCircleExclamation,
			color: theme.palette.error.main,
		};

	switch (task.taskState) {
		case "COMPLETED":
			return {
				icon: faThumbsUp,
				color: theme.palette.success.main,
			};
		case "STARTED":
			return {
				icon: faArrowRotateRight,
				color: theme.palette.yellow.main,
			};
		case "RELEASED":
			return {
				icon: faHourglassHalf,
				color: theme.palette.grey[600],
			};
		case "INITIAL":
			return {
				icon: faSquareFull,
				color: theme.palette.grey[300],
			};
		case "CANCELLED":
			return {
				icon: faTimes,
				color: theme.palette.error.main,
			};
	}
}
