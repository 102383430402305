import i18n from "i18next";
import { useServerSideDataGridModel } from "src/components/common/dataGrid/gridModel/useServerSideDataGridModel";
import { CChartEventSearchApi } from "src/api/generated/spc/cChartEvents/cChartEventSearchApi.ts";
import { genericNullableValue } from "src/utils/genericNullableValue.ts";
import { IsoDateString } from "src/types/dateTime.ts";
import { ControlledAsyncDataGrid } from "src/components/common/dataGrid/ControlledAsyncDataGrid";
import { dateTimeColumn, integerColumn, textColumn } from "src/components/common/dataGrid/columns.tsx";
import { CChartEventView } from "src/api/generated/postgres/db/types/public_/tables/cChartEventView.ts";
import { isNullOrBlank } from "src/utils/strings.tsx";
import { DocumentsOfObjectButton } from "src/components/views/documents/objectDocuments/DocumentsOfObjectButton";
import { nullableAavoObjectRef } from "src/utils/aavoObjectRefUtils.ts";
import { RefreshableElementProps, setRefreshRefValue } from "src/utils/useRefreshRef.ts";
import dayjs from "dayjs";
import { dayJsToDateIsoStringNullable } from "src/utils/dayjsUtils.ts";
import { AavoDatePicker } from "src/components/common/inputFields/AavoDatePicker.tsx";

export interface CChartEventSearchViewProps extends RefreshableElementProps {
	controlChartIds: number[];
}

export const CChartEventSearchView = ({ controlChartIds, refreshRef }: CChartEventSearchViewProps) => {
	const { dataGridProps, currentParams, refreshData, onlySelectedRow } = useServerSideDataGridModel({
		gridId: "2134E3A5A1FF737",
		fetchData: (params) =>
			CChartEventSearchApi.searchCChartEvents({
				controlChartIds: controlChartIds,
				...params,
			}),
		getRowId: (row) => row.cChartEventId,
		initialParams: {
			fromDate: genericNullableValue<IsoDateString>(),
			toDate: genericNullableValue<IsoDateString>(),
		},
	});

	setRefreshRefValue(refreshRef, refreshData);

	return (
		<ControlledAsyncDataGrid<CChartEventView>
			columns={[
				textColumn({
					field: "chartName",
					headerName: i18n.t("control_chart"),
					width: 180,
					sortable: false,
				}),
				dateTimeColumn({
					field: "insertTime",
					headerName: i18n.t("time"),
					width: 150,
					sortable: false,
				}),
				{
					field: "dimensions",
					headerName: i18n.t("dimensions"),
					width: 300,
					sortable: false,
					valueGetter: (_, row) => {
						const dimensions = [
							{ label: row.dimension_1Label, value: row.dimension_1 },
							{ label: row.dimension_2Label, value: row.dimension_2 },
							{ label: row.dimension_3Label, value: row.dimension_3 },
							{ label: row.dimension_4Label, value: row.dimension_4 },
						];
						return dimensions
							.filter(({ value }) => !isNullOrBlank(value))
							.map(({ label, value }) => `${label}: ${value}`)
							.join(" | ");
					},
				},
				integerColumn({
					field: "eventCount",
					headerName: i18n.t("count"),
					sortable: false,
				}),
			]}
			actionBarComponents={
				<>
					<DocumentsOfObjectButton
						objectRef={nullableAavoObjectRef("C_CHART_EVENT", onlySelectedRow?.cChartEventId)}
						editable={false}
					/>
					<AavoDatePicker
						label={i18n.t("from")}
						value={currentParams.fromDate ? dayjs(currentParams.fromDate) : null}
						onChange={async (fromDate) => {
							await refreshData({
								fromDate: dayJsToDateIsoStringNullable(fromDate),
							});
						}}
					/>
					<AavoDatePicker
						label={i18n.t("to")}
						value={currentParams.toDate ? dayjs(currentParams.toDate) : null}
						onChange={async (toDate) => {
							await refreshData({
								toDate: dayJsToDateIsoStringNullable(toDate),
							});
						}}
					/>
				</>
			}
			{...dataGridProps}
		/>
	);
};
