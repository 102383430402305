// This file is automatically generated. Do not edit manually.

import { t } from "i18next";

export const ControlChartTimeUnitValues = ["MILLISECOND", "SECOND", "MINUTE", "HOUR", "DAY"] as const;

export type ControlChartTimeUnit = (typeof ControlChartTimeUnitValues)[number];

export const getControlChartTimeUnitLabels = () => ({
	MILLISECOND: t("millisecond"),
	SECOND: t("second"),
	MINUTE: t("minute"),
	HOUR: t("hour"),
	DAY: t("day"),
});

export const getControlChartTimeUnitLabel = (value: ControlChartTimeUnit): string => {
	return getControlChartTimeUnitLabels()[value];
};
