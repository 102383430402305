import { TaskQueryParams } from "src/components/views/tasks/types.ts";
import { TasksGanttView, TasksGanttViewProps } from "src/components/views/tasks/gantt/TasksGanttView.tsx";
import { AsyncFetchRender } from "src/components/common/async/AsyncFetchRender.tsx";
import { TaskQueryApi } from "src/api/generated/tasks/api/taskQueryApi.ts";
import { emptyDataModelRequest } from "src/utils/serverSideDataModelRequestUtils.ts";
import { RefreshableElementProps } from "src/utils/useRefreshRef.ts";
import { expectNonFileResult } from "src/components/common/dataGrid/gridModel/serverSideDataModelUtils.ts";

export interface AsyncTasksGanttViewProps
	extends Omit<TasksGanttViewProps, "tasks" | "reload">,
		RefreshableElementProps {
	taskQueryParams: Partial<TaskQueryParams>;
}

export const AsyncTasksGanttView = ({ taskQueryParams, refreshRef, ...other }: AsyncTasksGanttViewProps) => {
	return (
		<AsyncFetchRender
			refreshRef={refreshRef}
			fetch={async () => {
				return expectNonFileResult(
					await TaskQueryApi.queryTasks({
						...taskQueryParams,
						dataModelRequest: emptyDataModelRequest,
					}),
				);
			}}
			content={({ rows: tasks }, reload) => <TasksGanttView tasks={tasks} reload={reload} {...other} />}
		/>
	);
};
