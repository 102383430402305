// This file is automatically generated. Do not edit manually.

import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace ControlChartActionApi {
	export async function setControlChartGroup(args: {
		controlChartId: number;
		newControlChartGroupId: number;
	}): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/spc/controlChart/setControlChartGroup",
			method: "POST",
			data: {
				controlChartId: args.controlChartId,
				newControlChartGroupId: args.newControlChartGroupId,
			},
		});
		return response as void;
	}

	export async function clearControlChartData(args: { controlChartId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/spc/controlChart/clearControlChartData",
			method: "POST",
			data: {
				controlChartId: args.controlChartId,
			},
		});
		return response as void;
	}

	export async function cloneChartGroupControlChart(args: {
		sourceControlChartId: number;
		newChartName: string;
		targetChartGroupId: number;
	}): Promise<number> {
		const response = await makeAxiosRequest({
			url: "/api/ext/spc/controlChart/cloneChartGroupControlChart",
			method: "POST",
			data: {
				sourceControlChartId: args.sourceControlChartId,
				newChartName: args.newChartName,
				targetChartGroupId: args.targetChartGroupId,
			},
		});
		return response as number;
	}

	export async function cloneResourceItemControlChart(args: {
		sourceControlChartId: number;
		newChartName: string;
		targetItemId: number;
	}): Promise<number> {
		const response = await makeAxiosRequest({
			url: "/api/ext/spc/controlChart/cloneResourceItemControlChart",
			method: "POST",
			data: {
				sourceControlChartId: args.sourceControlChartId,
				newChartName: args.newChartName,
				targetItemId: args.targetItemId,
			},
		});
		return response as number;
	}
}
