import { PropsWithChildren } from "react";
import { Box } from "@mui/material";
import { fireLegacyAppContentAreaChangedEvent } from "src/legacyViews/js/appContentAreaChangedEvent";

const TRANSITION_PROPERTY_NAME = "margin-left";

interface MainPageContentWrapperProps {
	drawerOpen: boolean;
	drawerWidth: number;
	wideScreen: boolean;
}

export const MainPageContentWrapper = ({
	children,
	drawerOpen,
	drawerWidth,
	wideScreen,
}: MainPageContentWrapperProps & PropsWithChildren) => {
	return (
		<Box
			onTransitionEnd={(e) => {
				if (e.propertyName === TRANSITION_PROPERTY_NAME) {
					fireLegacyAppContentAreaChangedEvent(null);
				}
			}}
			sx={(theme) => ({
				flex: 1,
				minHeight: 0,
				minWidth: 0,
				display: "flex",
				flexDirection: "column",
				transition: theme.transitions.create(TRANSITION_PROPERTY_NAME, {
					easing: theme.transitions.easing.sharp,
					duration: theme.transitions.duration.leavingScreen,
				}),
				marginLeft: `${drawerWidth}px`,
				...((!drawerOpen || !wideScreen) && {
					transition: theme.transitions.create(TRANSITION_PROPERTY_NAME, {
						easing: theme.transitions.easing.easeOut,
						duration: theme.transitions.duration.enteringScreen,
					}),
					marginLeft: 0,
				}),
			})}
		>
			{children}
		</Box>
	);
};
