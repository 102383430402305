import { GridColDef } from "@mui/x-data-grid/models/colDef/gridColDef";
import i18n from "i18next";
import { AavoColumnBaseProps } from "src/components/common/dataGrid/columns.tsx";

export const thumbnailColumn = <RowData extends object>(
	props: Omit<GridColDef<RowData>, "field" | "renderCell"> & AavoColumnBaseProps<RowData, string>
): GridColDef<RowData> => ({
		sortable: false,
		...props,
		renderCell: ({ value }) => {
			if (value == null)
				return <></>;

			return <img src={`data:image/jpeg;base64,${value}`}
						alt={i18n.t("image")} />;
		}
	}
);
