import i18n from "i18next";
import { FormSelectField } from "src/components/common/forms/fields/FormSelectField";
import { FieldPath, UseControllerProps } from "react-hook-form";
import { SystemDiagramNode } from "src/api/generated/postgres/db/types/public_/tables/systemDiagramNode";
import { SystemDiagramFunctionParamsFormProps } from "src/components/views/erp/common/systemDiagrams/functionParamForms/SystemDiagramFunctionParamsForm";

export interface OperationSelectionFieldProps<TFieldName extends FieldPath<SystemDiagramNode>>
	extends SystemDiagramFunctionParamsFormProps {
	fieldName: UseControllerProps<SystemDiagramNode, TFieldName>["name"];
	operationIndex?: number;
	label?: string;
}

export const OperationSelectionField = <TFieldName extends FieldPath<SystemDiagramNode>>({
	control,
	label,
	fieldName,
	operationIndex,
	formInitialData: { operationOptions },
}: OperationSelectionFieldProps<TFieldName>) => {
	return (
		<FormSelectField
			control={control}
			name={fieldName}
			label={
				label ??
				i18n.t("operation_n", {
					operationNo: operationIndex,
				})
			}
			options={operationOptions}
			getOptionKey={(op) => op.operationId.toString()}
			getOptionLabel={(op) => `${op.operationNo} | ${op.operationDescription}`}
		/>
	);
};
