import {PartialTenantCustomizations} from "src/tenantCustomizations/TenantCustomizations";
import {
    getInventoryLevelEventQuantitySpcSamplingMethod
} from "src/components/views/erp/spcSamplingMethods/InventoryLevelEventQuantitySpcSamplingMethod.tsx";

export const hawoCustomizations: PartialTenantCustomizations = {
	tenantConfig: {
		erp: {
			enabled: true,
			configuratorEnabled: true,
		},
		tasks: {
			enabled: true,
		},
		documentsEnabled: true,
	},
	spcSamplingMethods: () => [getInventoryLevelEventQuantitySpcSamplingMethod()],
} as const;
