// This file is automatically generated. Do not edit manually.

import { ProjectActivity } from "src/api/generated/erp/db/types/tables/projectActivity";
import { ProjectActivityType } from "src/api/generated/erp/db/types/tables/projectActivityType";
import { ProjectActivityResource } from "src/api/generated/erp/db/types/tables/projectActivityResource";
import { SubProject } from "src/api/generated/erp/db/types/tables/subProject";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace ProjectActivityEditApi {
	export async function getFormInitData(args: {
		subProjectId: number;
		activityId: number | null | undefined;
	}): Promise<ProjectActivityEditApi_FormInitData> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/project/activities/getFormInitData",
			method: "POST",
			data: {
				subProjectId: args.subProjectId,
				activityId: args.activityId,
			},
		});
		return response as ProjectActivityEditApi_FormInitData;
	}

	export async function insert(args: { activity: ProjectActivity }): Promise<number> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/project/activities/insert",
			method: "POST",
			data: {
				activity: args.activity,
			},
		});
		return response as number;
	}

	export async function update(args: { activity: ProjectActivity; inheritScheduleChange: boolean }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/project/activities/update",
			method: "POST",
			data: {
				activity: args.activity,
				inheritScheduleChange: args.inheritScheduleChange,
			},
		});
		return response as void;
	}
}

export interface ProjectActivityEditApi_FormInitData {
	activity: ProjectActivity | null | undefined;
	activityTypeOptions: Array<ProjectActivityType>;
	resourceOptions: Array<ProjectActivityResource>;
	subProject: SubProject;
	subProjectOtherActivities: Array<ProjectActivity>;
}
