import {
	PurchaseOrderLinesDataGridBase,
	PurchaseOrderLinesDataGridBaseProps,
} from "src/components/views/erp/purchase/purchaseOrders/PurchaseOrderLinesDataGridBase.tsx";

export interface SinglePurchaseOrderLinesDataGridProps
	extends Pick<PurchaseOrderLinesDataGridBaseProps, "purchaseOrder" | "refreshRef"> {}

export const SinglePurchaseOrderLinesDataGrid = (props: SinglePurchaseOrderLinesDataGridProps) => {
	return <PurchaseOrderLinesDataGridBase {...props} />;
};
