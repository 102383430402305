import { DateTime } from "luxon";
import { addThousandSeparators, getFirstLine } from "../../utils";

export const textFormatter = (params) => {
	if (valueIsNullOrEmpty(params)) return "";
	return params.value.value;
};

export const firstLineFormatter = (params) => {
	if (valueIsNullOrEmpty(params)) return "";
	return getFirstLine(params.value.value);
};

export const floatFormatter = (params) => {
	if (valueIsNullOrEmpty(params) || isNaN(params.value.value)) return "";
	const strValue = params.value.value.toString();
	return addThousandSeparators(strValue.replace(".", ","));
};

export const dateTimeFormatter = (params) => {
	if (valueIsNullOrEmpty(params)) return "";
	return DateTime.fromISO(params.value.value).toFormat("dd.LL.yyyy HH:mm");
};

export const dateFormatter = (params) =>
	dateOrTimeFormatterInternal(params, DateTime.DATE_SHORT);

export const timeFormatter = (params) =>
	dateOrTimeFormatterInternal(params, DateTime.TIME_24_SIMPLE);

function dateOrTimeFormatterInternal(params, format) {
	if (valueIsNullOrEmpty(params)) return "";
	return DateTime.fromISO(params.value.value).toLocaleString(format);
}

export const selectionColumnFormatter = (fieldOptions, params) => {
	if (valueIsNullOrEmpty(params)) return "";
	return params.value.label;
};

export const valueIsNullOrEmpty = (params) => {
	return (
		params.value == null ||
		params.value.value == null ||
		params.value.value === ""
	);
};
