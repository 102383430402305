import { CustomerOrderBillingPlanLineView } from "src/api/generated/erp/db/types/tables/customerOrderBillingPlanLineView.ts";
import i18n from "i18next";
import { AavoButton } from "src/components/common/buttons/AavoButton.tsx";
import { faFileInvoice } from "@fortawesome/pro-regular-svg-icons";
import { useGenericDialog } from "src/components/common/dialogs/useGenericDialog.ts";
import { CustomerOrderInvoiceCreationForm } from "src/components/views/erp/sales/invoicing/customerOrderInvoicing/CustomerOrderInvoiceCreationForm.tsx";
import { InvoiceCreationApi } from "src/api/generated/erp/sales/invoicing/api/invoiceCreationApi.ts";
import { OpenCustomerOrderButton } from "src/components/views/erp/utilComponents/OpenCustomerOrderButton.tsx";
import { CustomerOrderBillingPlanDataGridBase } from "src/components/views/erp/sales/billingPlan/customerOrder/CustomerOrderBillingPlanDataGridBase.tsx";
import { useState } from "react";
import { useRefreshRef } from "src/utils/useRefreshRef.ts";

export interface BillingPlanInvoicingViewProps {
	customerOrderId: number;
	onInvoicesModified: () => void;
}

export const BillingPlanInvoicingView = ({
	customerOrderId,
	onInvoicesModified,
}: BillingPlanInvoicingViewProps) => {
	const { openDialog } = useGenericDialog();
	const [selectedRow, setSelectedRow] = useState<CustomerOrderBillingPlanLineView | undefined>();
	const refreshRef = useRefreshRef();

	return (
		<CustomerOrderBillingPlanDataGridBase
			refreshRef={refreshRef}
			customerOrderId={customerOrderId}
			onSelectionChanged={(rows) => setSelectedRow(rows[0])}
			actionBarComponents={
				<>
					<AavoButton
						icon={faFileInvoice}
						label={i18n.t("create_invoice")}
						disabled={() => {
							if (!selectedRow) return i18n.t("select_a_row");
							switch (selectedRow.billingPlanLineState) {
								case "INITIAL":
									return i18n.t("billing_plan_line_is_not_yet_approved");
								case "INVOICED":
									return i18n.t("billing_plan_line_is_already_invoiced");
								default:
									return false;
							}
						}}
						variant={"outlined"}
						onClick={() => showInvoiceCreationForm(selectedRow)}
					/>
					<OpenCustomerOrderButton
						key={"openCustomerOrder"}
						customerOrderId={customerOrderId}
						variant={"outlined"}
					/>
				</>
			}
		/>
	);

	function showInvoiceCreationForm(row: CustomerOrderBillingPlanLineView | undefined) {
		if (!row) return;

		openDialog(({ closeDialog }) => ({
			title: i18n.t("create_invoice"),
			size: "sm",
			content: (
				<CustomerOrderInvoiceCreationForm
					customerOrderId={customerOrderId}
					showCustomerOrderLines={false}
					closeForm={closeDialog}
					submit={async ({ invoiceDate }) => {
						await InvoiceCreationApi.createInvoiceFromCustomerOrderBillingPlanLine({
							customerOrderBillingPlanLineId: row.customerOrderBillingPlanLineId,
							invoiceDate: invoiceDate,
						});
						refreshRef.refresh();
						onInvoicesModified();
					}}
				/>
			),
		}));
	}
};
