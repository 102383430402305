import React, {Dispatch, SetStateAction, useState} from "react";
import {setLocalStorageItemManaged} from "src/storage/localStorageUtils.ts";

export type StoredStateType = string | number | boolean | object | null;

export type StoredStateTypeLiteral = "string" | "number" | "boolean" | "object";

export interface UseStoredStateParams<T extends StoredStateType> {
    defaultValue: T,
    key: string,
    type?: StoredStateTypeLiteral,
}

export const useStoredState = <T extends StoredStateType>(
    {defaultValue, key, type}: UseStoredStateParams<T>
): [state: T, setState: React.Dispatch<React.SetStateAction<T>>] => {
    const parse = (serialized: string | null): T => {
        if (serialized === null) return null as T;
        switch (type ?? typeof defaultValue) {
            case "string":
                return serialized as T;
            case "number":
                return Number(serialized) as T;
            case "boolean":
                return (serialized === "true") as T;
            default: {
                try {
                    return JSON.parse(serialized);
                } catch (error) {
                    console.error(`Error parsing stored state '${key}'`, error);
                    localStorage.removeItem(key); // Remove corrupted item from storage
                    return defaultValue;
                }
            }
        }
    };

    const currentState = parse(localStorage.getItem(key)) ?? defaultValue;

    const [state, setState] = useState<T>(currentState);

    const setStoredState: Dispatch<SetStateAction<T>> = (valueOrFunction) => {
        if (valueOrFunction instanceof Function) {
            setState((prev) => {
                const newValue = valueOrFunction(prev);
                updateStorageItem(key, newValue);
                return newValue;
            });
        } else {
            updateStorageItem(key, valueOrFunction);
            setState(valueOrFunction);
        }
    };

    return [state, setStoredState];
};

const updateStorageItem = <T extends StoredStateType>(key: string, value: T) => {
    if (value === null) {
        localStorage.removeItem(key);
    } else {
        setLocalStorageItemManaged(key, serialize(value));
    }
};

const serialize = <T extends StoredStateType>(value: Exclude<T, null>) => {
    switch (typeof value) {
        case "string":
            return value as string;
        case "number":
            return String(value);
        case "boolean":
            return value ? "true" : "false";
        case "object":
            return JSON.stringify(value);
    }
};
