// This file is automatically generated. Do not edit manually.

import { CustomerOrder } from "src/api/generated/erp/db/types/tables/customerOrder";
import { CustomerOrderLineView } from "src/api/generated/erp/db/types/tables/customerOrderLineView";
import { CustomerOrderSummary } from "src/api/generated/erp/sales/customerOrder/service/customerOrderSummary";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace CustomerOrderLineDataGridApi {
	export async function getData(args: {
		customerOrderId: number;
		parentLineId: number | null | undefined;
		searchQuery?: string;
		showCancelledLines: boolean;
	}): Promise<CustomerOrderLineDataGridApi_Data> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/customerOrderLine/dataGrid/getData",
			method: "POST",
			data: {
				customerOrderId: args.customerOrderId,
				parentLineId: args.parentLineId,
				searchQuery: args.searchQuery,
				showCancelledLines: args.showCancelledLines,
			},
		});
		return response as CustomerOrderLineDataGridApi_Data;
	}
}

export interface CustomerOrderLineDataGridApi_Data {
	customerOrder: CustomerOrder;
	customerOrderLines: Array<CustomerOrderLineView>;
	customerOrderSummary: CustomerOrderSummary;
}
