// This file is automatically generated. Do not edit manually.

import { t } from "i18next";

export const CustomerOrderBillingPlanLineStateValues = ["INITIAL", "APPROVED", "INVOICED", "CANCELLED"] as const;

export type CustomerOrderBillingPlanLineState = (typeof CustomerOrderBillingPlanLineStateValues)[number];

export const getCustomerOrderBillingPlanLineStateLabels = () => ({
	INITIAL: t("initial"),
	APPROVED: t("approved"),
	INVOICED: t("invoiced"),
	CANCELLED: t("cancelled"),
});

export const getCustomerOrderBillingPlanLineStateLabel = (value: CustomerOrderBillingPlanLineState): string => {
	return getCustomerOrderBillingPlanLineStateLabels()[value];
};
