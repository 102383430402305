import { useEffect, useState } from "react";
import { logError } from "../../errorHandling/errorLogging.ts";
import { AsyncState, useAsyncState } from "./asyncState.ts";
import { RefreshableElementRef, setRefreshRefValue } from "src/utils/useRefreshRef.ts";

export interface UseParameterizedAsyncDataParams<Result, ParamType> {
	fetchData: (params: ParamType, currentData: Result | undefined) => Promise<Result>;
	initialParams: ParamType;
	storedParams?: Array<keyof ParamType>;
	fetchOnMount?: boolean;
	refreshRef?: RefreshableElementRef;
	afterRefresh?: (data: Result, params: ParamType) => void | Promise<unknown>;
}

export interface UseParameterizedAsyncDataReturn<Result, ParamType extends object> {
	dataAsync: AsyncState<Result>;
	paramValues: ParamType;
	refresh: (newParams?: Partial<ParamType>) => Promise<Result>;
	setParamsWithoutRefresh: (newParams: Partial<ParamType>) => void;
	setLocalData: (setter: (data: Result) => Result) => void;
}

export const useParameterizedAsyncData = <Result, ParamType extends object>({
	fetchData,
	initialParams,
	afterRefresh,
	fetchOnMount = true,
	refreshRef,
}: UseParameterizedAsyncDataParams<Result, ParamType>): UseParameterizedAsyncDataReturn<
	Result,
	ParamType
> => {
	const [paramValues, setParamValues] = useState(initialParams);
	const [dataAsync, setDataAsync, setDataImmediate] = useAsyncState<Result>({});

	const setParamsWithoutRefresh = (newParams: Partial<ParamType> = {}) => {
		setParamValues((currentParams) => {
			return {
				...currentParams,
				...newParams,
			};
		});
	};

	const refresh = async (newParams: Partial<ParamType> = {}) => {
		setParamsWithoutRefresh(newParams);
		const allNewParams = {
			...paramValues,
			...newParams,
		};
		const result = await setDataAsync(() => {
			return fetchData(allNewParams, dataAsync.data);
		});
		await afterRefresh?.(result, allNewParams);
		return result;
	};

	useEffect(() => {
		if (fetchOnMount) {
			refresh({}).catch(logError);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	setRefreshRefValue(refreshRef, () => refresh());

	return {
		dataAsync: dataAsync,
		paramValues: paramValues,
		refresh: refresh,
		setParamsWithoutRefresh: setParamsWithoutRefresh,
		setLocalData: setDataImmediate,
	};
};
