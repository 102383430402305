// This file is automatically generated. Do not edit manually.

import { t } from "i18next";

export const LanguageValues = ["FINNISH", "ENGLISH", "SWEDISH"] as const;

export type Language = (typeof LanguageValues)[number];

export const getLanguageLabels = () => ({
	FINNISH: t("finnish"),
	ENGLISH: t("english"),
	SWEDISH: t("swedish"),
});

export const getLanguageLabel = (value: Language): string => {
	return getLanguageLabels()[value];
};
