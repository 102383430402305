// This file is automatically generated. Do not edit manually.

import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace ProductionLineWorkCentersDataGridApi {
	export async function getDataGridData(args: {
		productionLineId: number;
	}): Promise<Array<ProductionLineWorkCentersDataGridApi_ProductionLineWorkcenterDto>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/productionLine/workCenters/dataGrid/getDataGridData",
			method: "POST",
			data: {
				productionLineId: args.productionLineId,
			},
		});
		return response as Array<ProductionLineWorkCentersDataGridApi_ProductionLineWorkcenterDto>;
	}
}

export interface ProductionLineWorkCentersDataGridApi_ProductionLineWorkcenterDto {
	controlChart: string | null | undefined;
	controlChartId: number | null | undefined;
	position: number;
	productionLineDescription: string;
	productionLineId: number;
	productionLineNo: string | null | undefined;
	productionLineWorkcenterId: number;
	siteId: number;
	workcenterAndonActive: boolean;
	workcenterDescription: string | null | undefined;
	workcenterId: number;
	workcenterLongName: string | null | undefined;
	workcenterNo: string;
}
