// This file is automatically generated. Do not edit manually.

import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace CatalogPartActionApi {
	export async function syncCatalogPartDocumentsToSites(args: { catalogPartId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/productManagement/catalogParts/syncCatalogPartDocumentsToSites",
			method: "POST",
			data: {
				catalogPartId: args.catalogPartId,
			},
		});
		return response as void;
	}

	export async function deactivateCatalogPart(args: { catalogPartId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/productManagement/catalogParts/deactivateCatalogPart",
			method: "POST",
			data: {
				catalogPartId: args.catalogPartId,
			},
		});
		return response as void;
	}

	export async function activateCatalogPart(args: { catalogPartId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/productManagement/catalogParts/activateCatalogPart",
			method: "POST",
			data: {
				catalogPartId: args.catalogPartId,
			},
		});
		return response as void;
	}

	export async function changeCatalogPartUnit(args: { catalogPartId: number; partUnitId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/productManagement/catalogParts/changeCatalogPartUnit",
			method: "POST",
			data: {
				catalogPartId: args.catalogPartId,
				partUnitId: args.partUnitId,
			},
		});
		return response as void;
	}
}
