import { PushNotificationApi } from "src/api/generated/common/userNotifications/pushNotificationApi.ts";
import { AavoForm } from "src/components/common/forms/AavoForm";
import { FormTextField } from "src/components/common/forms/fields/FormTextField";
import { requireRule } from "src/components/common/forms/validation.ts";
import i18n from "i18next";
import { FormAsyncUserMultiSelectField } from "../users/FormAsyncUserMultiSelectField";
import { metrics } from "@sentry/react";

export interface InternalMessagingFormProps {
	closeDialog: () => void;
}

interface FormValues {
	receiverAppUserIds: number[];
	message: string;
}

export const InternalMessagingForm = ({ closeDialog }: InternalMessagingFormProps) => {
	return (
		<AavoForm
			submit={submitForm}
			columns={1}
			onCompleted={() => {
				closeDialog();
			}}
			defaultValues={{
				receiverAppUserIds: [],
				message: "",
			}}
			render={({ control }) => (
				<>
					<FormAsyncUserMultiSelectField
						control={control}
						name={"receiverAppUserIds"}
						rules={requireRule()}
						label={i18n.t("receivers")}
					/>
					<FormTextField
						control={control}
						name={"message"}
						rules={requireRule()}
						label={i18n.t("message")}
						multiline
						rows={4}
					/>
				</>
			)}
		/>
	);

	async function submitForm(values: FormValues) {
		metrics.increment("internal_messaging_form_submit");
		await PushNotificationApi.sendNotificationToUsers(values);
	}
};
