import { Primitive } from "src/types/primitive.ts";

export interface KeyValuePair {
	key: string;
	value: Primitive;
}

export const keyValuePairsToRecord = (pairs: KeyValuePair[]): Record<string, Primitive> => {
	return pairs.reduce((prev, param) => {
		prev[param.key] = param.value;
		return prev;
	}, {} as Record<string, Primitive>);
};

export const recordToKeyValuePairs = (record: Record<string, Primitive>): KeyValuePair[] => {
	return Object.entries(record).map(([key, value]) => {
		return { key, value };
	});
};