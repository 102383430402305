import { Task } from "src/api/generated/postgres/db/types/tasks/tables/task.ts";
import { FormTextField } from "src/components/common/forms/fields/FormTextField.tsx";
import { AsyncFetchRender } from "src/components/common/async/AsyncFetchRender.tsx";
import { TaskQueryApi } from "src/api/generated/tasks/api/taskQueryApi";
import { TaskSource } from "src/api/generated/tasks/taskSource/taskSource";
import { currentLocalDateAsIsoString, formatIsoString } from "src/utils/dayjsUtils.ts";
import { FormCommonProps } from "src/components/common/forms/types.ts";
import { TaskView } from "src/api/generated/postgres/db/types/tasks/tables/taskView";
import { AavoForm } from "src/components/common/forms/AavoForm.tsx";
import { requireRule } from "src/components/common/forms/validation.ts";
import i18n from "i18next";
import { Box, Typography } from "@mui/material";
import { getTaskSourceTypeLabel } from "src/api/generated/postgres/db/types/tasks/enums/taskSourceType.ts";
import { Fragment } from "react";
import { TaskUpdateApi } from "src/api/generated/tasks/api/taskUpdateApi.ts";
import { TaskCreationApi } from "src/api/generated/tasks/api/taskCreationApi";
import { FormAsyncUserSelectField } from "../../users/FormAsyncUserSelectField";
import { FormDateField } from "../../../common/forms/fields/FormDateField.tsx";
import { FormCheckbox } from "../../../common/forms/fields/FormCheckbox.tsx";
import { TaskSourceRef } from "src/api/generated/tasks/taskSource/taskSourceRef.ts";
import { useGlobalInitData } from "src/contexts/useGlobalInitData.ts";
import { FormNumberField } from "src/components/common/forms/fields/FormNumberField.tsx";

export interface TaskDesktopFormProps extends FormCommonProps<number> {
	taskId: number | undefined;
	newTaskSourceRef?: TaskSourceRef;
}

export const TASK_DESKTOP_FORM_SIZE = "lg";

export const TaskDesktopForm = (props: TaskDesktopFormProps) => {
	const { taskId, newTaskSourceRef } = props;

	const fetchTaskAndSource = async () => {
		if (taskId !== undefined) {
			return await TaskQueryApi.getTaskAndSource({ taskId });
		} else {
			const taskSource =
				newTaskSourceRef ?
					await TaskQueryApi.getTaskSource({ sourceRef: newTaskSourceRef })
				:	undefined;
			return { task: undefined, source: taskSource };
		}
	};

	return (
		<AsyncFetchRender
			fetch={fetchTaskAndSource}
			content={({ task, source }) => (
				<TaskDesktopFormContent task={task} source={source ?? undefined} {...props} />
			)}
		/>
	);
};

interface TaskDesktopFormContentProps extends TaskDesktopFormProps {
	task: TaskView | undefined;
	source: TaskSource | undefined;
}

const TaskDesktopFormContent = (props: TaskDesktopFormContentProps) => {
	const { task: existingTask, source, onFormEdited, onCompleted } = props;
	const { appUserId } = useGlobalInitData();

	const submitForm = async (values: Task) => {
		if (existingTask !== undefined) {
			await TaskUpdateApi.updateTask({
				task: values,
			});
			return existingTask.taskId;
		} else {
			return await TaskCreationApi.createTask({
				task: values,
			});
		}
	};

	return (
		<AavoForm
			defaultValues={
				existingTask ?? {
					assignedToUserId: appUserId,
					responsiblePersonUserId: source?.responsiblePersonId ?? appUserId,
					deadlineDate: currentLocalDateAsIsoString(),
					canBeStarted: false,
					sourceId: source?.sourceId,
					sourceType: source?.sourceType,
				}
			}
			submit={submitForm}
			columns={2}
			onCompleted={onCompleted}
			onFormEdited={onFormEdited}
			render={({ control }) => (
				<>
					<FormTextField
						control={control}
						name={"title"}
						label={i18n.t("title")}
						rules={requireRule()}
					/>
					<FormNumberField
						control={control}
						name={"workloadEstimateHours"}
						label={i18n.t("workload_estimate_hours")}
					/>
					<FormAsyncUserSelectField
						control={control}
						name={"assignedToUserId"}
						label={i18n.t("executor")}
					/>
					<FormAsyncUserSelectField
						control={control}
						name={"responsiblePersonUserId"}
						label={i18n.t("responsible_person")}
						rules={requireRule()}
					/>
					<FormDateField
						control={control}
						name={"earliestStartDate"}
						label={i18n.t("earliest_start_date")}
					/>
					<FormDateField
						control={control}
						name={"deadlineDate"}
						label={i18n.t("deadline_date")}
						rules={requireRule()}
					/>
					<FormTextField
						control={control}
						name={"taskDescription"}
						label={i18n.t("description")}
						multiline
						minRows={2}
						sx={{
							gridColumn: "1 / -1",
						}}
					/>
					<FormTextField
						control={control}
						name={"note"}
						label={i18n.t("note")}
						multiline
						minRows={2}
						sx={{
							gridColumn: "1 / -1",
						}}
					/>
					{source?.sourceType === "PROJECT_ACTIVITY" && (
						<FormCheckbox
							control={control}
							name={"canBeStarted"}
							label={i18n.t("can_be_started")}
						/>
					)}
					<TaskInfoComponent {...props} />
				</>
			)}
		/>
	);
};
const TaskInfoComponent = ({ source, task }: TaskDesktopFormContentProps) => {
	const fields = [
		{
			label: i18n.t("source"),
			value:
				source ? `${getTaskSourceTypeLabel(source.sourceType)} | ${source.sourceDescription}` : null,
		},
		{
			label: i18n.t("created_at"),
			value: task?.createdAt ? formatIsoString(task.createdAt) : null,
		},
		{
			label: i18n.t("released_at"),
			value: task?.releasedDate ? formatIsoString(task.releasedDate) : null,
		},
		{
			label: i18n.t("started_at"),
			value: task?.actualStartDate ? formatIsoString(task.actualStartDate) : null,
		},
		{
			label: i18n.t("started_by"),
			value: task?.startedByUserName,
		},
		{
			label: i18n.t("completed_at"),
			value: task?.completedDate ? formatIsoString(task.completedDate) : null,
		},
		{
			label: i18n.t("completed_by"),
			value: task?.completedByUserName,
		},
		{
			label: i18n.t("cancelled_at"),
			value: task?.cancelledDate ? formatIsoString(task.cancelledDate) : null,
		},
	];

	return (
		<Box
			sx={{
				gridColumn: "1 / -1",
				display: "grid",
				gridTemplateColumns: "auto 1fr",
				gap: 1,
			}}
		>
			{fields.map(({ value, label }) =>
				value != null ?
					<Fragment key={label}>
						<Typography
							sx={{
								fontWeight: "bold",
							}}
						>
							{label}:
						</Typography>
						<Typography>{value}</Typography>
					</Fragment>
				:	null,
			)}
		</Box>
	);
};
