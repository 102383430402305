import { Typography, TypographyProps } from "@mui/material";
import { VerticalBox } from "../box/VerticalBox";

export interface TypographyWithLineBreaksProps extends TypographyProps {}

export const TypographyWithLineBreaks = (props: TypographyWithLineBreaksProps) => {
	const { children, sx, ...other } = props;

	if (typeof children !== "string") {
		return <Typography {...props} />;
	}

	return (
		<VerticalBox sx={sx}>
			{children.split("\n").map((row, index) => (
				<Typography key={index} {...other}>
					{row}
				</Typography>
			))}
		</VerticalBox>
	);
};
