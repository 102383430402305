// This file is automatically generated. Do not edit manually.

import { ServerSideDataModelRequest } from "src/api/generated/common/dataGrids/serverSideDataModelRequest";
import { ServerSideDataModelResult } from "src/api/generated/common/dataGrids/serverSideDataModelResult";
import { ProjectView } from "src/api/generated/erp/db/types/tables/projectView";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace DeliverableProjectsDataGridApi {
	export async function getRows(args: {
		siteId: number | null | undefined;
		searchQuery: string;
		dataModelRequest: ServerSideDataModelRequest;
	}): Promise<ServerSideDataModelResult<ProjectView>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/project/delivery/deliverabelProjects/getRows",
			method: "POST",
			data: {
				siteId: args.siteId,
				searchQuery: args.searchQuery,
				dataModelRequest: args.dataModelRequest,
			},
		});
		return response as ServerSideDataModelResult<ProjectView>;
	}
}
