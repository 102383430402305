import { dateColumn } from "src/components/common/dataGrid/columns.tsx";
import i18n from "i18next";
import { AavoGridColDef } from "src/components/common/dataGrid/AavoDataGrid.tsx";
import { CustomerOrderDataGridApi_CustomerOrderDto } from "src/api/generated/erp/sales/customerOrder/api/customerOrderDataGridApi.ts";
import { parseIsoDateNullable } from "src/utils/dayjsUtils.ts";

export const getSalvosCustomerOrderDataGridCustomColumns =
	(): AavoGridColDef<CustomerOrderDataGridApi_CustomerOrderDto>[] => {
		return [
			dateColumn({
				field: "tenantCustomColumn1",
				headerName: i18n.t("next_contact"),
				width: 200,
				filterable: false,
				valueGetter: (_, row) => parseIsoDateNullable(row.tenantCustomColumn1),
			}),
		];
	};
