// This file is automatically generated. Do not edit manually.

import { WarehousePickerGroup } from "src/api/generated/erp/db/types/tables/warehousePickerGroup";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace WarehousePickerGroupDataCrudApi {
	export async function getAll(): Promise<Array<WarehousePickerGroup>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/warehouse/warehousePickerGroups/getAll",
			method: "POST",
			data: {},
		});
		return response as Array<WarehousePickerGroup>;
	}

	export async function save(args: {
		warehousePickerGroups: Array<WarehousePickerGroup>;
	}): Promise<Array<WarehousePickerGroup>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/warehouse/warehousePickerGroups/save",
			method: "POST",
			data: {
				warehousePickerGroups: args.warehousePickerGroups,
			},
		});
		return response as Array<WarehousePickerGroup>;
	}

	export async function delete_(args: { warehousePickerGroups: Array<WarehousePickerGroup> }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/warehouse/warehousePickerGroups/delete",
			method: "POST",
			data: {
				warehousePickerGroups: args.warehousePickerGroups,
			},
		});
		return response as void;
	}
}
