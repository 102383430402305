import "regenerator-runtime/runtime";
import { html, LitElement, unsafeCSS } from "lit";
import { fireLegacyAppContentAreaChangedEvent } from "src/legacyViews/js/appContentAreaChangedEvent";
import GoldenLayout from "./lib/goldenLayout";
import $ from "jquery";
import { convertRemToPixels, elementIsVisible } from "../utils";
import Handlebars from "handlebars/dist/handlebars.min";
import styles from "../../scss/goldenlayout/a/b/goldenlayout.scss?inline";
import deepMerge from "src/utils/deepMerge";
import { setLocalStorageItemManaged } from "src/storage/localStorageUtils";

window.jQuery = window.$ = $;

class GoldenLayoutComponent extends LitElement {
	static get properties() {
		return {
			layoutId: { type: String },
			isDynamic: { type: Boolean },
			configJson: { type: Object },
			components: { type: Array },
			explicitTitles: { type: Object },
			layoutVersion: { type: Number },
			cycleActiveItemCounter: { type: Number },
			layoutParams: { type: Object },
		};
	}

	constructor() {
		super();
		this._layout = null;
		this.layoutId = "";
		this.configJson = "{}";
		this.components = [];
		this.explicitTitles = {};
		this.layoutVersion = 0;
		this.cycleActiveItemCounter = 0;
		this.layoutParams = null;
		this.isDynamic = false;
	}

	static get styles() {
		return unsafeCSS(styles);
	}

	get stateStorageId() {
		return "golden_layout_state__" + this.layoutId;
	}

	get providedConfigStorageId() {
		return "golden_layout_provided_config__" + this.layoutId;
	}

	render() {
		return html` <div class="golden-layout-container"></div>`;
	}

	firstUpdated(changedProperties) {
		const configJson = this._getSavedOrProvidedConfigJson(
			this.providedConfigStorageId,
			this.stateStorageId,
		);
		setLocalStorageItemManaged(this.providedConfigStorageId, this.configJson);
		this._initializeLayout(configJson);
	}

	updated(changedProperties) {
		if (!this._layout) return;
		if (changedProperties.has("explicitTitles")) {
			const oldTitles = changedProperties.get("explicitTitles") || {};
			this._onExplicitTitlesUpdated(oldTitles);
		}
		if (changedProperties.has("layoutVersion") && this.layoutVersion > 0) {
			this._onLayoutVersionUpdated();
		}
		if (changedProperties.has("cycleActiveItemCounter") && this.cycleActiveItemCounter > 0) {
			this._cycleActiveItem();
		}
	}

	_onExplicitTitlesUpdated(oldTitles) {
		for (const [componentName, newTitle] of Object.entries(this.explicitTitles)) {
			if (newTitle !== oldTitles[componentName]) {
				this._setComponentTitle(componentName, newTitle);
			}
		}
	}

	_onLayoutVersionUpdated() {
		this._initializeLayout(this.configJson);
	}

	_initializeLayout(configJson) {
		if (this._layout) {
			this._removeEventListenersBeforeDestroy();
			this._layout.destroy();
		}

		const layoutContainer = this.shadowRoot.querySelector(".golden-layout-container");

		const resolvedConfigJson = Handlebars.compile(configJson)(this.layoutParams);
		let config = this._parseConfigWithFallback(resolvedConfigJson);
		config = this._resolveLayoutConfig(config);
		config = this._createCompleteConfig(config, this.components, this.explicitTitles);
		config["maximisedItemId"] = null; // Maximizing should be ephemeral.

		this._layout = new GoldenLayout.default(config, layoutContainer);

		this.components.forEach(({ componentName }) => {
			this._layout.registerComponent(componentName, (componentContainer) => {
				componentContainer.getElement().html(`<slot name="${componentName}"></slot>`);
			});
		});
		this._layout.init();
		this._addEventListeners(this.stateStorageId);
		this._applyCustomSettings(config.settings);
	}

	_applyCustomSettings(settings) {
		this.shadowRoot.querySelectorAll(".lm_splitter").forEach((el) => {
			el.classList.toggle("lm_splitter--resize-disabled", settings.resizeEnabled === false);
		});
	}

	_setComponentTitle(componentName, title) {
		const component = this._getComponentByName(componentName);
		if (!component) return;
		component.setTitle(title);
	}

	_getLayoutContainer() {
		return this.shadowRoot.querySelector(".golden-layout-container");
	}

	_getSavedOrProvidedConfigJson(providedConfigStorageId, stateStorageId) {
		// Dynamic layouts should not save states.
		if (this.isDynamic) return this.configJson;

		// Always return provided config if it has been changed
		const oldConfigJson = localStorage.getItem(providedConfigStorageId);
		if (this.configJson !== oldConfigJson) {
			return this.configJson;
		}

		// Return saved state if exists. Otherwise return provided config.
		const savedState = localStorage.getItem(stateStorageId);
		if (savedState !== null) return savedState;
		else return this.configJson;
	}

	_removeEventListenersBeforeDestroy() {
		window.removeEventListener("appContentAreaChanged", this._appContentAreaChangedListener);
	}

	_addEventListeners(stateStorageId) {
		this._layout.on("stateChanged", () => {
			fireLegacyAppContentAreaChangedEvent({
				eventSource: "golden-layout",
			});
			if (this._layout && elementIsVisible(this._getLayoutContainer())) {
				const state = this._layout.toConfig();
				localStorage.setItem(stateStorageId, JSON.stringify(state));
			}
		});

		this._appContentAreaChangedListener = ({ detail }) => {
			// Update size on appContentAreaChanged-event, but only if layout itself is not source of the event.
			// Also require that layout is currently visible.
			const isVisible = elementIsVisible(this._getLayoutContainer());
			if (detail.eventSource !== "golden-layout" && this._layout && isVisible) {
				this._layout.updateSize(this.clientWidth, this.clientHeight);
			}
		};
		window.addEventListener("appContentAreaChanged", this._appContentAreaChangedListener);
	}

	_cycleActiveItem() {
		const stackItem = this._layout.root.contentItems[0];
		if (!stackItem || stackItem.type !== "stack") {
			console.warn("Attempt to cycle layout items without stack item on root");
			return;
		}

		const activeItem = stackItem.getActiveContentItem();
		const activeItemIndex = stackItem.contentItems.indexOf(activeItem);
		const itemCount = stackItem.contentItems.length;
		let nextIndex = activeItemIndex + 1;
		if (nextIndex >= itemCount) nextIndex = 0;

		const nextItem = stackItem.contentItems[nextIndex];
		stackItem.setActiveContentItem(nextItem);
	}

	_getComponentByName(componentName) {
		return this._layout.root.getItemsByFilter((item) => {
			return item["componentName"] === componentName;
		})[0];
	}

	_parseConfigWithFallback(configJson) {
		try {
			return JSON.parse(configJson);
		} catch (e) {
			console.error("Parsing layout config failed", configJson, e);
			return {};
		}
	}

	_createCompleteConfig(rootConfig, allComponents, explicitTitles) {
		// Assign default values to root config.
		const config = deepMerge(getDefaultConfig(), rootConfig);
		// Always override dimensions.
		config.dimensions = getDefaultConfig().dimensions;

		// Find or create root content item.
		const rootComponentIsDefined = Array.isArray(config.content) && config.content.length === 1;
		let rootComponent = rootComponentIsDefined ? config.content[0] : defaultRootElement();
		const rootComponentContent = rootComponent.content || [];

		// If root component content is undefined, configure default values for all components.
		if (rootComponentContent.length === 0) {
			rootComponent.content = allComponents.map((c) => getDefaultComponentConfig(c));
		}

		// Assign default values to every component.
		rootComponent = this._applyDefaultsToComponentRecursively(
			rootComponent,
			allComponents,
			explicitTitles,
		);

		config.content = rootComponent ? [rootComponent] : [];
		return config;
	}

	_applyDefaultsToComponentRecursively(itemConfig, allComponents, explicitTitles) {
		let ret = itemConfig;
		if (itemConfig.type === "component") {
			const componentDef = allComponents.find((c) => c.componentName === itemConfig.componentName);
			if (!componentDef)
				// Drop item from configuration if corresponding component s not found.
				return null;
			ret = deepMerge(getDefaultComponentConfig(componentDef), itemConfig);
			const explicitTitle = explicitTitles[itemConfig.componentName];
			ret.title = explicitTitle ? explicitTitle : componentDef.title;
		}
		const content = ret.content || [];
		ret.content = content
			.map((c) => this._applyDefaultsToComponentRecursively(c, allComponents, explicitTitles))
			.filter((c) => c !== null);
		return ret;
	}

	/**
	 * Resolves "expression" type items from layout content.
	 */
	_resolveLayoutConfig(config) {
		const resolved = this._resolveConfigContentItemList(config.content);
		return {
			...config,
			content: resolved,
		};
	}

	_resolveConfigContentItemList(contentItemList) {
		if (!contentItemList) return [];
		return contentItemList.flatMap((contentItem) => this._resolveConfigContentItem(contentItem));
	}

	_resolveConfigContentItem(contentItem) {
		if (contentItem.type === "expression") return this._resolveConfigContentItemExpression(contentItem);
		else {
			return [
				{
					...contentItem,
					content: this._resolveConfigContentItemList(contentItem.content),
				},
			];
		}
	}

	_resolveConfigContentItemExpression(expression) {
		const paramName = expression.parameterName;
		const operand = expression.operand;
		const paramValue = getAllLayoutParams(this.layoutParams)[paramName];

		let isMatch = false;
		switch (expression.operator) {
			case "eq":
				isMatch = paramValue === operand;
				break;
			case "notEq":
				isMatch = paramValue !== operand;
				break;
		}
		if (isMatch) {
			return this._resolveConfigContentItemList(expression.result);
		} else {
			return [];
		}
	}
}

const getDefaultConfig = () => ({
	settings: {
		hasHeaders: true,
		constrainDragToContainer: true,
		reorderEnabled: false,
		resizeEnabled: true,
		selectionEnabled: false,
		popoutWholeStack: false,
		blockedPopoutsThrowError: true,
		closePopoutsOnUnload: true,
		showPopoutIcon: false,
		showMaximiseIcon: true,
		showCloseIcon: false,
		responsiveMode: "none",
	},
	dimensions: {
		borderWidth: 2,
		minItemHeight: 200,
		minItemWidth: 200,
		headerHeight: convertRemToPixels(1.7),
		dragProxyWidth: 300,
		dragProxyHeight: 200,
	},
	labels: {
		close: "sulje",
		maximise: "suurenna",
		minimise: "pienennä",
		popout: "",
	},
	content: [],
});

const defaultRootElement = () => ({
	type: "stack",
	activeItemIndex: 0,
	content: [],
});

const getDefaultComponentConfig = ({ componentName, title }) => ({
	type: "component",
	componentName,
	componentState: {},
	content: [],
	isClosable: false,
	title,
});

const getAllLayoutParams = (givenParams) => {
	return {
		...givenParams,
		__IS_MOBILE__: window.innerWidth < 576,
	};
};

customElements.define("golden-layout", GoldenLayoutComponent);
