// This file is automatically generated. Do not edit manually.

import { ServerSideDataModelRequest } from "src/api/generated/common/dataGrids/serverSideDataModelRequest";
import { ServerSideDataModelResult } from "src/api/generated/common/dataGrids/serverSideDataModelResult";
import { ProductionWorkQueueView } from "src/api/generated/erp/db/types/tables/productionWorkQueueView";
import { Workcenter } from "src/api/generated/erp/db/types/tables/workcenter";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace WorkCenterJobQueueCompletedApi {
	export async function getCompletedOperations(args: {
		includeCompletedShopOrders?: boolean;
		excludeOtherOperatorJobs?: boolean;
		dataModelRequest: ServerSideDataModelRequest;
	}): Promise<WorkCenterJobQueueCompletedApi_DataGridData> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/workCenterJobQueue/getCompletedOperations",
			method: "POST",
			data: {
				includeCompletedShopOrders: args.includeCompletedShopOrders,
				excludeOtherOperatorJobs: args.excludeOtherOperatorJobs,
				dataModelRequest: args.dataModelRequest,
			},
		});
		return response as WorkCenterJobQueueCompletedApi_DataGridData;
	}
}

export interface WorkCenterJobQueueCompletedApi_DataGridData {
	dataModelResult: ServerSideDataModelResult<ProductionWorkQueueView>;
	workCenter: Workcenter;
}
