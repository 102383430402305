import { useState } from "react";
import { PartCategory } from "src/api/generated/erp/db/types/tables/partCategory";
import { PartCategoriesCrudView } from "src/components/views/erp/parts/partCategories/PartCategoriesCrudView.tsx";
import i18n from "i18next";
import { PartCategoryAttributesDataGrid } from "./PartCategoryAttributesDataGrid";
import { AavoMosaic } from "src/components/common/mosaic/AavoMosaic.tsx";

export const PartCategoriesPage = () => {
	const [selectedPartCategory, setSelectedPartCategory] = useState<PartCategory | null>(null);

	return (
		<AavoMosaic
			viewId={"2862930FB9BD96DF"}
			layout={{
				type: "row",
				splitPercentage: 60,
				first: {
					type: "panel",
					title: i18n.t("part_categories"),
					content: (
						<PartCategoriesCrudView
							selectedPartCategory={selectedPartCategory}
							setSelectedPartCategory={setSelectedPartCategory}
						/>
					),
				},
				second: {
					type: "panel",
					title: i18n.t("attributes"),
					content: (
						<PartCategoryAttributesDataGrid
							key={selectedPartCategory?.partCategoryId}
							partCategoryId={selectedPartCategory?.partCategoryId ?? null}
						/>
					),
				},
			}}
		/>
	);
};
