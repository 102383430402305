// This file is automatically generated. Do not edit manually.

import { t } from "i18next";

export const ConfigurationPropertyDatatypeValues = [
	"STRING",
	"BOOL",
	"DECIMAL",
	"INTEGER",
	"DOCUMENT",
	"SUB_CONFIGURATION",
	"SUB_CONFIGURATION_LIST",
] as const;

export type ConfigurationPropertyDatatype = (typeof ConfigurationPropertyDatatypeValues)[number];

export const getConfigurationPropertyDatatypeLabels = () => ({
	STRING: t("string"),
	BOOL: t("bool"),
	DECIMAL: t("decimal"),
	INTEGER: t("integer"),
	DOCUMENT: t("document"),
	SUB_CONFIGURATION: t("sub_configuration"),
	SUB_CONFIGURATION_LIST: t("sub_configuration_list"),
});

export const getConfigurationPropertyDatatypeLabel = (value: ConfigurationPropertyDatatype): string => {
	return getConfigurationPropertyDatatypeLabels()[value];
};
