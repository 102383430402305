import { alpha, createTheme } from "@mui/material/styles";
import { getLanguageSetting } from "../localSettings/utils";
import * as datePickerLocales from "@mui/x-date-pickers/locales";
import * as coreLocales from "@mui/material/locale";
import * as dataGridLocales from "@mui/x-data-grid-pro/locales";
import { ButtonPropsColorOverrides, PaletteColor, PaletteColorOptions } from "@mui/material";

declare module "@mui/material/styles" {
	interface Palette {
		yellow: PaletteColor;
		green: PaletteColor;
		blue: PaletteColor;
		black: PaletteColor;
		state: {
			initial: string;
			released: string;
			inProgress: string;
			completed: string;
			cancelled: string;
		};
	}

	interface PaletteOptions {
		yellow?: PaletteColorOptions;
		green?: PaletteColorOptions;
		blue?: PaletteColorOptions;
		black?: PaletteColor;
	}
}

const disabledTextColor = alpha("#000000", 0.5);

const disabledInputStyle = {
	WebkitTextFillColor: disabledTextColor,
} as const;

const userLang = getLanguageSetting();
const themeInitial = createTheme(
	{
		palette: {
			primary: {
				main: "#00897b",
			},
			secondary: {
				main: "#f9b315",
			},
			error: {
				main: "#c73737",
			},
			warning: {
				main: "#f9b315",
			},
			success: {
				main: "#4caf50",
			},
			info: {
				main: "#2196f3",
			},
		},
		typography: {
			fontFamily: [
				"-apple-system",
				"BlinkMacSystemFont",
				'"Segoe UI"',
				"Roboto",
				'"Helvetica Neue"',
				"Arial",
				'"Noto Sans"',
				'"Liberation Sans"',
				"sans-serif",
				'"Apple Color Emoji"',
				'"Segoe UI Emoji"',
				'"Segoe UI Symbol"',
				'"Noto Color Emoji"',
			].join(","),
		},
		components: {
			MuiListItemIcon: {
				styleOverrides: {
					root: {
						minWidth: "30px !important",
					},
				},
			},
			MuiTextField: {
				styleOverrides: {
					root: {
						"& label.Mui-disabled": {
							color: disabledTextColor,
							opacity: 1,
						},
						"& input.Mui-disabled": disabledInputStyle,
					},
				},
			},
			MuiAutocomplete: {
				styleOverrides: {
					input: {
						"&.Mui-disabled": disabledInputStyle,
					},
				},
			},
		},
	},
	// Localizations
	{
		en: coreLocales.enUS,
		fi: coreLocales.fiFI,
	}[userLang],
	{
		en: dataGridLocales.enUS,
		fi: dataGridLocales.fiFI,
	}[userLang],
	{
		en: datePickerLocales.enUS,
		fi: datePickerLocales.fiFI,
	}[userLang],
);

const yellowColor = themeInitial.palette.augmentColor({
	color: {
		main: "#f5cc00",
	},
	name: "yellow",
});

export const AAVO_MUI_THEME = createTheme(themeInitial, {
	palette: {
		yellow: themeInitial.palette.augmentColor({
			color: {
				main: "#f5cc00",
			},
			name: "yellow",
		}),
		green: themeInitial.palette.success,
		blue: themeInitial.palette.info,
		black: themeInitial.palette.augmentColor({
			color: {
				main: "#000000",
			},
			name: "black",
		}),
		state: {
			initial: themeInitial.palette.grey[600],
			released: themeInitial.palette.info.main,
			inProgress: yellowColor.main,
			completed: themeInitial.palette.success.dark,
			cancelled: themeInitial.palette.error.dark,
		},
	},
});

declare module "@mui/material/Button" {
	interface ButtonPropsColorOverrides {
		yellow: true;
		black: true;
	}
}

declare module "@mui/material/IconButton" {
	interface IconButtonPropsColorOverrides extends ButtonPropsColorOverrides {}
}
