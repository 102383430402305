// This file is automatically generated. Do not edit manually.

import { Part } from "src/api/generated/erp/db/types/tables/part";
import { PartCategory } from "src/api/generated/erp/db/types/tables/partCategory";
import { ErpUnit } from "src/api/generated/erp/db/types/tables/erpUnit";
import { PartView } from "src/api/generated/erp/db/types/tables/partView";
import { ProductionLineView } from "src/api/generated/erp/db/types/tables/productionLineView";
import { Site } from "src/api/generated/erp/db/types/tables/site";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace SitePartFormApi {
	export async function getInitData(args: { partId: number }): Promise<SitePartFormApi_InitData> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sitePart/form/getInitData",
			method: "POST",
			data: {
				partId: args.partId,
			},
		});
		return response as SitePartFormApi_InitData;
	}

	export async function update(args: { part: Part }): Promise<number> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sitePart/form/update",
			method: "POST",
			data: {
				part: args.part,
			},
		});
		return response as number;
	}
}

export interface SitePartFormApi_InitData {
	categoryOptions: Array<PartCategory>;
	erpUnitOptions: Array<ErpUnit>;
	part: PartView;
	productionLineOptions: Array<ProductionLineView>;
	site: Site;
}
