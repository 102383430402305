// This file is automatically generated. Do not edit manually.

import { ConfiguratorTableRevision } from "src/api/generated/erp/db/types/tables/configuratorTableRevision";
import { IsoDateString } from "src/types/dateTime";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace ConfiguratorTableRevisionsApi {
	export async function getConfiguratorTableRevisions(args: {
		configuratorTableId: number;
	}): Promise<Array<ConfiguratorTableRevision>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/configurator/tables/revision/getConfiguratorTableRevisions",
			method: "POST",
			data: {
				configuratorTableId: args.configuratorTableId,
			},
		});
		return response as Array<ConfiguratorTableRevision>;
	}

	export async function getFormInitData(args: {
		configuratorTableId: number;
		configuratorTableRevisionId: number | null | undefined;
	}): Promise<ConfiguratorTableRevisionsApi_FormInitData> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/configurator/tables/revision/getFormInitData",
			method: "POST",
			data: {
				configuratorTableId: args.configuratorTableId,
				configuratorTableRevisionId: args.configuratorTableRevisionId,
			},
		});
		return response as ConfiguratorTableRevisionsApi_FormInitData;
	}

	export async function insert(args: {
		configuratorTableId: number;
		sourceRevisionId: number | null | undefined;
		revisionNumber: number;
		description: string;
	}): Promise<number> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/configurator/tables/revision/insert",
			method: "POST",
			data: {
				configuratorTableId: args.configuratorTableId,
				sourceRevisionId: args.sourceRevisionId,
				revisionNumber: args.revisionNumber,
				description: args.description,
			},
		});
		return response as number;
	}

	export async function update(args: { configuratorTableRevision: ConfiguratorTableRevision }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/configurator/tables/revision/update",
			method: "POST",
			data: {
				configuratorTableRevision: args.configuratorTableRevision,
			},
		});
		return response as void;
	}

	export async function delete_(args: { configuratorTableRevisionId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/configurator/tables/revision/delete",
			method: "POST",
			data: {
				configuratorTableRevisionId: args.configuratorTableRevisionId,
			},
		});
		return response as void;
	}

	export async function release(args: {
		configuratorTableRevisionId: number;
		activeFrom: IsoDateString;
	}): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/configurator/tables/revision/release",
			method: "POST",
			data: {
				configuratorTableRevisionId: args.configuratorTableRevisionId,
				activeFrom: args.activeFrom,
			},
		});
		return response as void;
	}

	export async function revertRelease(args: { configuratorTableRevisionId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/configurator/tables/revision/revertRelease",
			method: "POST",
			data: {
				configuratorTableRevisionId: args.configuratorTableRevisionId,
			},
		});
		return response as void;
	}
}

export interface ConfiguratorTableRevisionsApi_FormInitData {
	defaultRevisionNumber: number;
	revision: ConfiguratorTableRevision | null | undefined;
	sourceRevisionOptions: Array<ConfiguratorTableRevision>;
}
