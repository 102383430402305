import { FormCommonProps } from "src/components/common/forms/types.ts";
import { DeepPartial } from "react-hook-form";
import { AsyncFetchRender } from "src/components/common/async/AsyncFetchRender.tsx";
import { AavoForm } from "src/components/common/forms/AavoForm.tsx";
import { Document } from "src/api/generated/postgres/db/types/documents/tables/document";
import { Typography } from "@mui/material";
import i18n from "i18next";
import { requireRule } from "src/components/common/forms/validation.ts";
import { FormTextField } from "src/components/common/forms/fields/FormTextField.tsx";
import { FormSelectField } from "src/components/common/forms/fields/FormSelectField.tsx";
import { DocumentCategory } from "src/api/generated/postgres/db/types/documents/tables/documentCategory.ts";
import { uploadFile } from "src/utils/fileUploading.ts";
import { AavoObjectRef } from "src/api/generated/common/sourceType/aavoObjectRef";
import { DocumentQueryApi } from "src/api/generated/documents/api/documentQueryApi.ts";
import { DocumentUpdateApi } from "src/api/generated/documents/api/documentUpdateApi.ts";
import { DocumentCreationApi } from "src/api/generated/documents/api/documentCreationApi.ts";
import { openWopiFilePage } from "src/components/views/documents/wopi/wopiFilePageUtils.ts";
import { NewDocumentFileCreationType } from "src/api/generated/documents/newDocumentFileCreationType.ts";
import { isNullOrBlank, removeFileExtension } from "src/utils/strings.tsx";
import { DocumentFileCreationFormPart } from "src/components/views/documents/DocumentFileCreationFormPart.tsx";

export interface DocumentFormProps extends FormCommonProps<number> {
	documentId: number | undefined;
	objectRef: AavoObjectRef | undefined;
}

export const DocumentForm = (props: DocumentFormProps) => {
	const { objectRef, documentId } = props;

	const fetchDefaultValues = async (): Promise<DeepPartial<Document>> => {
		if (documentId !== undefined) return await DocumentQueryApi.getDocument({ documentId });
		else {
			const [defaultCategory, description2] = await Promise.all([
				DocumentCreationApi.getDefaultDocumentCategoryForNewDocument({
					objectType: objectRef?.objectType,
				}),
				DocumentCreationApi.getNewDocumentDescription2({ objectRef }),
			]);
			return {
				categoryId: defaultCategory.documentCategoryId,
				description2: description2,
			};
		}
	};

	const fetchInitData = async () => {
		const [defaultValues, categoryOptions] = await Promise.all([
			fetchDefaultValues(),
			DocumentQueryApi.getDocumentCategoryOptions(),
		]);
		return {
			defaultValues,
			categoryOptions,
		};
	};

	return (
		<AsyncFetchRender
			fetch={fetchInitData}
			content={(initData) => <DocumentFormContent initData={initData} {...props} />}
		/>
	);
};

interface FormInitData {
	defaultValues: DeepPartial<DocumentFormValues>;
	categoryOptions: DocumentCategory[];
}

interface DocumentFormValues extends Document {
	file: File;
	fileCreationType: NewDocumentFileCreationType;
}

interface DocumentFormContentProps extends DocumentFormProps {
	initData: FormInitData;
}

const DocumentFormContent = ({
	documentId,
	objectRef,
	initData: { defaultValues, categoryOptions },
	...other
}: DocumentFormContentProps) => {
	const isExistingDocument = documentId !== undefined;

	return (
		<AavoForm<DocumentFormValues, number>
			submit={(formValues) => submitForm(documentId, objectRef, formValues)}
			columns={1}
			defaultValues={{
				...defaultValues,
				fileCreationType: "UPLOAD",
			}}
			{...other}
			render={({ control, watch, setValue }) => {
				watch((value, { name }) => {
					// Set description1 to selected file name by default
					if (name === "file" && value.file !== undefined && isNullOrBlank(value.description1)) {
						setValue("description1", removeFileExtension(value.file.name), {
							shouldValidate: true,
						});
					}
				});

				return (
					<>
						<FormTextField
							control={control}
							name={"description1"}
							rules={requireRule()}
							label={i18n.t("description_1")}
							autoFocus
						/>
						<Typography marginBottom={"0.5rem"}>
							<b>{`${i18n.t("description_2")}: `}</b>
							{defaultValues.description2 ?? ""}
						</Typography>
						<FormSelectField
							control={control}
							name={"categoryId"}
							label={i18n.t("category")}
							options={categoryOptions}
							getOptionKey={(o) => o.documentCategoryId}
							getOptionLabel={(o) => o.name}
						/>
						<FormTextField
							control={control}
							name={"note"}
							label={i18n.t("note")}
							multiline={true}
							minRows={5}
						/>
						<DocumentFileCreationFormPart
							control={control}
							watch={watch}
							isExistingRecord={isExistingDocument}
						/>
					</>
				);
			}}
		/>
	);
};

const submitForm = async (
	inputDocumentId: number | undefined,
	objectRef: AavoObjectRef | undefined,
	formValues: DocumentFormValues,
): Promise<number> => {
	const fileHandle = formValues.file ? await uploadFile(formValues.file) : undefined;

	let documentId;
	if (inputDocumentId !== undefined) {
		await DocumentUpdateApi.updateDocument({
			documentId: inputDocumentId,
			categoryId: formValues.categoryId,
			description1: formValues.description1,
			note: formValues.note,
			newFile: fileHandle,
		});
		documentId = inputDocumentId;
	} else {
		documentId = await DocumentCreationApi.createDocument({
			objectRef: objectRef,
			categoryId: formValues.categoryId,
			description1: formValues.description1,
			note: formValues.note,
			fileCreationType: formValues.fileCreationType,
			uploadedFile: fileHandle,
		});

		if (formValues.fileCreationType !== "UPLOAD") {
			const fileUuid = await DocumentQueryApi.getDocumentPrimaryFileUuid({ documentId });
			openWopiFilePage("edit_new", fileUuid);
		}
	}

	return documentId;
};
