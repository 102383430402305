import { FieldPath, FieldPathValue, useController, UseControllerProps } from "react-hook-form";
import {
	AavoFileInput,
	AavoFileInputProps,
} from "src/components/common/inputFields/fileInput/AavoFileInput.tsx";

export interface FormMultiFileInputFieldProps<
	TFieldValues extends object,
	TFieldName extends FieldPath<TFieldValues>,
> extends Omit<AavoFileInputProps<true>, "onChange" | "value" | "name">,
		Pick<UseControllerProps<TFieldValues, TFieldName>, "control" | "name" | "rules"> {}

export const FormMultiFileInputField = <
	TFieldValues extends object,
	TFieldName extends FieldPath<TFieldValues>,
>({
	control,
	name,
	rules,
	...other
}: FormMultiFileInputFieldProps<TFieldValues, TFieldName>) => {
	const { field, fieldState } = useController({
		name,
		control,
		rules,
		defaultValue: [] as FieldPathValue<TFieldValues, TFieldName>,
	});

	return (
		<AavoFileInput<true>
			multiple
			error={fieldState.error?.message}
			{...other}
			value={field.value}
			onChange={(files) => field.onChange(files)}
			onBlur={field.onBlur}
			disabled={field.disabled}
			name={field.name}
		/>
	);
};
