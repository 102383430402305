// This file is automatically generated. Do not edit manually.

import { t } from "i18next";

export const DeliveryStateValues = ["INITIAL", "DELIVERED"] as const;

export type DeliveryState = (typeof DeliveryStateValues)[number];

export const getDeliveryStateLabels = () => ({
	INITIAL: t("initial"),
	DELIVERED: t("delivered"),
});

export const getDeliveryStateLabel = (value: DeliveryState): string => {
	return getDeliveryStateLabels()[value];
};
