import { RichTreeView, RichTreeViewProps, treeItemClasses } from "@mui/x-tree-view";
import React from "react";
import { AavoActionBar } from "src/components/common/actionBar/AavoActionBar.tsx";
import { AsyncButton } from "src/components/common/buttons/AsyncButton.tsx";
import { faRefresh } from "@fortawesome/pro-regular-svg-icons";
import { faCompressAlt, faExpandAlt } from "@fortawesome/pro-solid-svg-icons";
import i18n from "i18next";
import { VerticalBox } from "src/components/common/box/VerticalBox.tsx";
import { Box } from "@mui/material";
import { mergeSx } from "src/utils/styles.ts";
import { AavoMenuButton } from "src/components/common/menu/AavoMenuButton.tsx";
import { SxProps, Theme } from "@mui/material/styles";
import { flatMapTreeItems } from "src/components/common/tree/AavoTreeView.utils.ts";

export type AavoTreeViewBaseItem<R extends object> = R & {
	children?: AavoTreeViewBaseItem<R>[];
};

export type AavoTreeViewProps<R extends object, Multiple extends boolean | undefined> = Omit<
	RichTreeViewProps<R, Multiple>,
	"onExpandedItemsChange" | "items"
> & {
	items: AavoTreeViewBaseItem<R>[];
	expandOnlyOnIconClick?: boolean;
	onExpandedItemsChange: (itemsIds: string[]) => void;
	refresh?: () => Promise<unknown>;
	actionBarComponents?: React.ReactNode;
	actionBarMenuComponents?: React.ReactNode;
	containerSx?: SxProps<Theme>;
	hideActionBar?: boolean;
};

export const AavoTreeView = <R extends object, Multiple extends boolean | undefined = undefined>({
	expandOnlyOnIconClick = true,
	onExpandedItemsChange,
	onSelectedItemsChange,
	items,
	refresh,
	actionBarComponents,
	actionBarMenuComponents,
	containerSx,
	sx,
	hideActionBar = false,
	getItemId: getItemIdProp,
	...other
}: AavoTreeViewProps<R, Multiple>) => {
	const getItemId = getItemIdProp ?? ((item) => (item as any).id);

	return (
		<VerticalBox
			sx={mergeSx(
				{
					flex: 1,
					alignItems: "stretch",
				},
				containerSx,
			)}
		>
			{!hideActionBar && (
				<ActionBar
					items={items}
					getItemId={getItemId}
					setExpandedItems={(itemIds) => {
						onExpandedItemsChange?.(itemIds);
					}}
					refresh={refresh}
					extraComponents={actionBarComponents}
					menuComponents={actionBarMenuComponents}
				/>
			)}
			<RichTreeView<R, Multiple>
				items={items}
				getItemId={getItemIdProp}
				onExpandedItemsChange={(event, itemsIds) => {
					if (expandOnlyOnIconClick) {
						const iconClicked = treeItemExpandCollapseIconWasClicked(event);
						if (!iconClicked) return;
					}

					onExpandedItemsChange?.(itemsIds);
				}}
				onSelectedItemsChange={(event, itemIds) => {
					const iconClicked = treeItemExpandCollapseIconWasClicked(event);
					if (iconClicked) return;

					onSelectedItemsChange?.(event, itemIds);
				}}
				sx={mergeSx(
					{
						flex: 1,
						overflow: "auto",
					},
					!hideActionBar && {
						borderTop: "1px solid",
						borderColor: "divider",
						paddingY: 0.25,
					},
					sx,
				)}
				{...other}
			/>
		</VerticalBox>
	);
};

function treeItemExpandCollapseIconWasClicked(event: React.SyntheticEvent) {
	event.persist();
	return (event.target as any).closest(`.${treeItemClasses.iconContainer}`);
}

interface ActionBarProps<R extends object> {
	items: AavoTreeViewBaseItem<R>[];
	getItemId: (item: AavoTreeViewBaseItem<R>) => string;
	setExpandedItems: (itemIds: string[]) => void;
	refresh?: () => Promise<unknown>;
	extraComponents?: React.ReactNode;
	menuComponents?: React.ReactNode;
}

const ActionBar = <R extends object>({
	setExpandedItems,
	items,
	getItemId,
	refresh,
	extraComponents,
	menuComponents,
}: ActionBarProps<R>) => {
	return (
		<AavoActionBar
			sx={{
				alignSelf: "stretch",
				paddingRight: 1,
			}}
		>
			{refresh && (
				<AsyncButton
					icon={faRefresh}
					onClick={async () => {
						await refresh();
					}}
				/>
			)}
			<AsyncButton
				icon={faCompressAlt}
				iconProps={{
					size: "xs",
				}}
				tooltip={i18n.t("collapse_all")}
				onClick={() => {
					setExpandedItems([]);
				}}
			/>
			<AsyncButton
				icon={faExpandAlt}
				iconProps={{
					size: "xs",
				}}
				tooltip={i18n.t("expand_all")}
				onClick={() => {
					const allItems = flatMapTreeItems(items);
					setExpandedItems(allItems.map(getItemId));
				}}
			/>
			{extraComponents}
			{menuComponents && (
				<>
					<Box flex={1} />
					<AavoMenuButton menuContent={menuComponents} />
				</>
			)}
		</AavoActionBar>
	);
};
