import i18n from "i18next";
import {FieldPath, UseControllerProps} from "react-hook-form";
import {SystemDiagramNode} from "src/api/generated/postgres/db/types/public_/tables/systemDiagramNode";
import {SystemDiagramFunctionParamsFormProps} from "./SystemDiagramFunctionParamsForm";
import {FormMultiSelectField} from "src/components/common/forms/fields/FormMultiSelectField.tsx";

export interface ProductionLineMultiSelectionFieldProps<TFieldName extends FieldPath<SystemDiagramNode>>
	extends SystemDiagramFunctionParamsFormProps {
	fieldName: UseControllerProps<SystemDiagramNode, TFieldName>["name"];
	label?: string;
}

export const ProductionLinesSelectionField = <TFieldName extends FieldPath<SystemDiagramNode>>({
	control,
	fieldName,
	label,
	formInitialData: { productionLineOptions },
}: ProductionLineMultiSelectionFieldProps<TFieldName>) => {
	return (
		<FormMultiSelectField
			control={control}
			name={fieldName}
			label={
				label ??
				i18n.t("production_lines")
			}
			options={productionLineOptions.map((o) => ({
				key: o.productionLineId,
				label: o.productionLineName,
			}))}
			encodeValue={(v) => JSON.stringify(v)}
			decodeValue={(v) => (v != null ? JSON.parse(v) : [])}
		/>
	);
};
