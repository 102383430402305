import { logError } from "src/errorHandling/errorLogging.ts";
import { DeliveryPackageLineSourceType } from "src/api/generated/erp/db/types/enums/deliveryPackageLineSourceType.ts";
import { DeliveryPackageLineSource } from "src/api/generated/erp/delivery/model/deliveryPackageLineSource.ts";
import { WarehousePickingObjectType } from "src/api/generated/erp/db/types/enums/warehousePickingObjectType.ts";

export function createDeliveryPackageLineSource({
	sourceType,
	sourceId,
}: {
	sourceType: DeliveryPackageLineSourceType;
	sourceId: number | null | undefined;
}): DeliveryPackageLineSource {
	switch (sourceType) {
		case "CUSTOMER_ORDER_LINE":
			if (sourceId == null) {
				logError("Source ID is null for delivery package line source type CUSTOMER_ORDER_LINE");
			}
			return {
				type: "customerOrderLine",
				customerOrderLineId: sourceId!,
			};
		case "PROJECT_ACTIVITY_MATERIAL":
			if (sourceId == null) {
				logError("Source ID is null for delivery package line source type PROJECT_ACTIVITY_MATERIAL");
			}
			return {
				type: "projectActivityMaterial",
				projectActivityMaterialId: sourceId!,
			};
	}
}

export function warehousePickingObjectToDeliveryPackageLineSource({
	objectType,
	objectId,
}: {
	objectType: WarehousePickingObjectType;
	objectId: number;
}): DeliveryPackageLineSource {
	switch (objectType) {
		case "CUSTOMER_ORDER_LINE":
			return {
				type: "customerOrderLine",
				customerOrderLineId: objectId,
			};
		case "PROJECT_ACTIVITY_MATERIAL":
			return {
				type: "projectActivityMaterial",
				projectActivityMaterialId: objectId,
			};
		default:
			throw Error(`Cannot create delivery package line source from warehouse picking object type ${objectType}`);
	}
}
