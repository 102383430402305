import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinnerThird } from "@fortawesome/pro-regular-svg-icons";
import { SizeProp } from "@fortawesome/fontawesome-svg-core";
import React from "react";

interface AavoLoadingProps {
	size?: SizeProp;
	style?: React.CSSProperties;
}

export const AavoLoading = ({ size, style }: AavoLoadingProps) => {
	return (
		<FontAwesomeIcon
			icon={faSpinnerThird}
			spin={true}
			size={size || undefined}
			style={style}
		/>
	);
};
