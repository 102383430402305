// This file is automatically generated. Do not edit manually.

import { PartRevisionLineView } from "src/api/generated/erp/db/types/tables/partRevisionLineView";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace SitePartReferencesDataGridApi {
	export async function fetchSitePartReferences(args: { partId: number }): Promise<Array<PartRevisionLineView>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/parts/sitePartReferences/fetchSitePartReferences",
			method: "POST",
			data: {
				partId: args.partId,
			},
		});
		return response as Array<PartRevisionLineView>;
	}
}
