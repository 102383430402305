import { useEffect, useRef } from "react";
import { Box } from "@mui/system";
import { logError } from "src/errorHandling/errorLogging.ts";

export const ImageView = ({ fileUrl }: { fileUrl: string }) => {
	const containerRef = useRef<HTMLDivElement>();

	useEffect(() => {
		if (containerRef.current) {
			containerRef.current.innerHTML = "";
			initialize(fileUrl, containerRef.current).catch(logError);
		}
	}, [fileUrl]);

	return (
		<Box
			ref={containerRef}
			sx={{
				flex: 1,
			}}
		/>
	);
};

const initialize = async (sourceUrl: string, container: HTMLDivElement) => {
	const OpenSeadragon = await import("openseadragon");
	new OpenSeadragon.Viewer({
		element: container,
		visibilityRatio: 1,
		prefixUrl: `/icons/openseadragon/images/`,
		showFullPageControl: false,
		zoomPerScroll: 1.5,
		springStiffness: 10,
		animationTime: 1,
		tileSources: {
			type: "image",
			url: sourceUrl,
		},
	});
};
