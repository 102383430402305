import {ConfigurationComponentType} from "src/api/generated/erp/db/types/enums/configurationComponentType.ts";
import {ConfigurationComponent} from "src/api/generated/erp/db/types/tables/configurationComponent.ts";

export const CONFIGURATOR_COMPONENT_DND_KEY = "configuratorComponent";

export type ConfiguratorComponentDragItem =
	| {
			type: "newComponent";
			componentType: ConfigurationComponentType;
			componentProps?: NewComponentDragItemComponentProps
	  }
	| {
			type: "existingComponent";
			componentId: number;
			currentPosition: number;
	  };

export type NewComponentDragItemComponentProps =
	| Partial<ConfigurationComponent>
	| (() => Promise<Partial<ConfigurationComponent> | "cancelled">);
