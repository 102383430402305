// This file is automatically generated. Do not edit manually.

import { IsoDateTimeString } from "src/types/dateTime";
import { ChartPaginationDirection } from "src/api/generated/common/chartPaginationDirection";
import { ControlChartGraphDto } from "src/api/generated/spc/graphs/controlChartGraphDto";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace ControlChartGraphApi {
	export async function getGraphData(args: {
		controlChartIds: Array<number>;
		recordsBeforeLimit: IsoDateTimeString | null | undefined;
		paginationDirection: ChartPaginationDirection;
		currentFirstRecordTime: IsoDateTimeString | null | undefined;
		currentLastRecordTime: IsoDateTimeString | null | undefined;
		recordCount?: number | null | undefined;
	}): Promise<ControlChartGraphApi_GraphDataDto> {
		const response = await makeAxiosRequest({
			url: "/api/ext/spc/controlCharts/graph/getGraphData",
			method: "POST",
			data: {
				controlChartIds: args.controlChartIds,
				recordsBeforeLimit: args.recordsBeforeLimit,
				paginationDirection: args.paginationDirection,
				currentFirstRecordTime: args.currentFirstRecordTime,
				currentLastRecordTime: args.currentLastRecordTime,
				recordCount: args.recordCount,
			},
		});
		return response as ControlChartGraphApi_GraphDataDto;
	}
}

export interface ControlChartGraphApi_GraphDataDto {
	controlCharts: Array<ControlChartGraphDto>;
}
