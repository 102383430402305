import { Box, BoxProps } from "@mui/material";
import { forwardRef, PropsWithChildren } from "react";

export interface StackedIconProps extends BoxProps, PropsWithChildren {}

export const StackedIcon = forwardRef(({ children, ...other }: StackedIconProps, ref) => {
	return (
		<Box ref={ref} className="fa-layers fa-fw" {...other}>
			{children}
		</Box>
	);
});
