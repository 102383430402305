import {
    DeliverySourcePickingView
} from "src/components/views/erp/delivery/deliveryPackage/picking/DeliverySourcePickingView.tsx";
import i18n from "i18next";
import {ProjectView} from "src/api/generated/erp/db/types/tables/projectView.ts";
import {
    ReservableProjectActivityMaterialsDataGrid
} from "src/components/views/erp/project/delivery/picking/ReservableProjectsDataGrid.tsx";
import {
    ProjectActivityMaterialReservationFormApi
} from "src/api/generated/erp/project/delivery/api/projectActivityMaterialReservationFormApi.ts";

export interface ProjectPickingViewProps {
	project: ProjectView;
}

export const ProjectPickingView = ({ project }: ProjectPickingViewProps) => {
	return (
		<DeliverySourcePickingView
			deliverySource={{
				type: "project",
				projectId: project.projectId,
			}}
			deliverySourceHasAutomaticReservation={project.automaticMaterialReservation}
			reservationView={{
				type: "panel",
				title: i18n.t("reservable_lines"),
				hidden: project.automaticMaterialReservation,
				content: <ReservableProjectActivityMaterialsDataGrid projectId={project.projectId} />,
			}}
			revertReservation={async ({ pickingObjectLocation, quantityToRevertInBaseUnit }) => {
				await ProjectActivityMaterialReservationFormApi.createReservations({
					reservationRequests: [
						{
							projectActivityMaterialId: pickingObjectLocation.objectId,
							partWarehouseLocationId: pickingObjectLocation.partWarehouseLocationId,
							quantityInWarehouseUnit: -quantityToRevertInBaseUnit,
						},
					],
				});
			}}
		/>
	);
};
