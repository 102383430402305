import {FormCommonProps} from "src/components/common/forms/types.ts";
import {EInvoiceOperator} from "src/api/generated/erp/db/types/tables/eInvoiceOperator.ts";
import {AavoForm} from "src/components/common/forms/AavoForm.tsx";
import {EInvoiceOperatorApi} from "src/api/generated/erp/sales/basedata/api/eInvoiceOperatorApi.ts";
import {FormTextField} from "src/components/common/forms/fields/FormTextField.tsx";
import i18n from "i18next";
import {requireRule} from "src/components/common/forms/validation.ts";

export interface EInvoiceOperatorFormProps extends FormCommonProps<number> {
	eInvoiceOperator: EInvoiceOperator | undefined;
}

export const EInvoiceOperatorForm = ({
	eInvoiceOperator,
	onCompleted,
	onFormEdited,
}: EInvoiceOperatorFormProps) => {
	return (
		<AavoForm<EInvoiceOperator, number>
			onCompleted={onCompleted}
			onFormEdited={onFormEdited}
			submit={submit}
			defaultValues={eInvoiceOperator != null ? eInvoiceOperator : {}}
			render={({ control }) => (
				<>
					<FormTextField
						control={control}
						name={"eInvoiceOperatorName"}
						label={i18n.t("name")}
						rules={requireRule()}
					/>
					<FormTextField
						control={control}
						name={"eInvoiceOperatorCode"}
						label={i18n.t("e_invoice_operator_code")}
						rules={requireRule()}
					/>
				</>
			)}
		/>
	);

	async function submit(values: EInvoiceOperator) {
		return await EInvoiceOperatorApi.save({ eInvoiceOperator: values });
	}
};
