// This file is automatically generated. Do not edit manually.

import { Site } from "src/api/generated/erp/db/types/tables/site";
import { SystemDiagram } from "src/api/generated/postgres/db/types/public_/tables/systemDiagram";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace SystemDiagramDataGridApi {
	export async function getSystemDiagramsData(): Promise<SystemDiagramDataGridApi_Data> {
		const response = await makeAxiosRequest({
			url: "/api/ext/common/systemDiagrams/getSystemDiagramsData",
			method: "POST",
			data: {},
		});
		return response as SystemDiagramDataGridApi_Data;
	}
}

export interface SystemDiagramDataGridApi_Data {
	siteOptions: Array<Site>;
	systemDiagrams: Array<SystemDiagram>;
}
