// This file is automatically generated. Do not edit manually.

import { CustomerOrderLineView } from "src/api/generated/erp/db/types/tables/customerOrderLineView";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace CustomerOrderReleaseApi {
	export async function getCustomerOrderReleasePreInfo(args: {
		customerOrderId: number;
	}): Promise<CustomerOrderReleaseApi_CustomerOrderReleasePreInfo> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/customerOrderRelease/getCustomerOrderReleasePreInfo",
			method: "POST",
			data: {
				customerOrderId: args.customerOrderId,
			},
		});
		return response as CustomerOrderReleaseApi_CustomerOrderReleasePreInfo;
	}

	export async function releaseCustomerOrder(args: { customerOrderId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/customerOrderRelease/releaseCustomerOrder",
			method: "POST",
			data: {
				customerOrderId: args.customerOrderId,
			},
		});
		return response as void;
	}
}

export interface CustomerOrderReleaseApi_CustomerOrderReleasePreInfo {
	configuredLinesWithChangedProductFamilyVersion: Array<CustomerOrderLineView>;
	hasIncompleteSalesConditions: boolean;
}
