// This file is automatically generated. Do not edit manually.

import { AavoObjectRef } from "src/api/generated/common/sourceType/aavoObjectRef";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace AavoObjectTypeApi {
	export async function getAavoObjectDescription(args: { objectRef: AavoObjectRef }): Promise<string> {
		const response = await makeAxiosRequest({
			url: "/api/ext/common/objectType/getAavoObjectDescription",
			method: "POST",
			data: {
				objectRef: args.objectRef,
			},
		});
		return response as string;
	}
}
