import { AsyncForm } from "src/components/common/forms/AsyncForm.tsx";
import { FormTextField } from "src/components/common/forms/fields/FormTextField.tsx";
import i18n from "i18next";
import {
	floatRule,
	requiredNonNegativeFloatRule,
	requireRule,
} from "src/components/common/forms/validation.ts";
import { FormSelectField } from "src/components/common/forms/fields/FormSelectField.tsx";
import { FormCommonProps } from "src/components/common/forms/types.ts";
import { FormCheckbox } from "src/components/common/forms/fields/FormCheckbox.tsx";
import { FormNumberField } from "src/components/common/forms/fields/FormNumberField.tsx";
import { DefaultValues } from "react-hook-form";
import { FormEnumSelectField } from "src/components/common/forms/fields/FormEnumSelectField.tsx";
import {
	ObjectAccountingForm,
	ObjectAccountingFormValues,
} from "src/components/views/erp/sales/accounting/ObjectAccountingForm.tsx";
import {
	getObjectAccountingCodeIdsFromFormValues,
	getObjectAccountingFormDefaultValues,
} from "src/components/views/erp/sales/accounting/ObjectAccountingForm.utils.ts";
import {
	CustomerOfferBillingPlanEditApi,
	CustomerOfferBillingPlanEditApi_InitData,
} from "src/api/generated/erp/sales/billingPlan/customerOffer/api/customerOfferBillingPlanEditApi.ts";
import { BillingPlanLineSumInput } from "src/api/generated/erp/sales/billingPlan/base/service/billingPlanLineSumInput.ts";
import { CustomerOfferBillingPlanLineView } from "src/api/generated/erp/db/types/tables/customerOfferBillingPlanLineView.ts";

export interface CustomerOfferBillingPlanLineFormProps extends FormCommonProps<number> {
	customerOfferId: number;
	billingPlanLine: CustomerOfferBillingPlanLineView | undefined;
}

interface FormValues
	extends Pick<CustomerOfferBillingPlanLineView, "description" | "funded">,
		ObjectAccountingFormValues {
	sumInputType: SumInputType;
	sum: number;
	percent: number;
	vatCodeId: number;
}

type SumInputType = "sum" | "percent" | "rest";

export const CustomerOfferBillingPlanLineForm = ({
	customerOfferId,
	billingPlanLine,
	onCompleted,
	onFormEdited,
}: CustomerOfferBillingPlanLineFormProps) => {
	return (
		<AsyncForm
			fetch={() =>
				CustomerOfferBillingPlanEditApi.getFormInitData({
					customerOfferId: customerOfferId,
					billingPlanLineId: billingPlanLine?.customerOfferBillingPlanLineId,
				})
			}
			getDefaultValues={getDefaultValues}
			submit={submit}
			onCompleted={onCompleted}
			onFormEdited={onFormEdited}
			render={({ data: { customerOffer, vatCodeOptions }, control, watch }) => {
				const sumInputType = watch("sumInputType");
				return (
					<>
						<FormTextField
							control={control}
							label={i18n.t("description")}
							name={"description"}
							rules={requireRule()}
							autoFocus
						/>
						<FormEnumSelectField
							control={control}
							name={"sumInputType"}
							label={i18n.t("input_type")}
							options={{
								sum: i18n.t("sum"),
								percent: i18n.t("share_of"),
								rest: i18n.t("rest_of_order"),
							}}
						/>
						{sumInputType === "sum" && (
							<FormNumberField
								control={control}
								name={"sum"}
								rules={requiredNonNegativeFloatRule()}
								label={
									i18n.t("sum") +
									" " +
									(customerOffer.inputPricesWithVat ?
										i18n.t("with_vat")
									:	i18n.t("without_vat"))
								}
							/>
						)}
						{sumInputType === "percent" && (
							<FormNumberField
								control={control}
								label={i18n.t("share_of") + " %"}
								name={"percent"}
								rules={floatRule((value) => {
									if (value < 0 || value > 100) {
										return i18n.t("value_must_be_between_0_and_100");
									}
								})}
							/>
						)}
						<FormSelectField
							control={control}
							name={"vatCodeId"}
							label={i18n.t("vat_code")}
							options={vatCodeOptions}
							getOptionKey={(o) => o.vatCodeId}
							getOptionLabel={(o) => o.vatCodeName}
							disabled={billingPlanLine != null}
						/>
						<ObjectAccountingForm control={control} />
						<FormCheckbox label={i18n.t("funded")} control={control} name={"funded"} />
					</>
				);
			}}
		/>
	);

	function getDefaultValues({
		accountingDimensions,
		customerOffer,
	}: CustomerOfferBillingPlanEditApi_InitData): DefaultValues<FormValues> {
		if (!billingPlanLine) {
			return {
				sumInputType: "sum",
				sum: 0,
				percent: 0,
				funded: false,
				vatCodeId: customerOffer.vatCodeId,
				...getObjectAccountingFormDefaultValues(accountingDimensions),
			};
		} else {
			return {
				...billingPlanLine,
				sumInputType: "sum",
				sum:
					customerOffer.inputPricesWithVat ?
						billingPlanLine.billingPlanSumWithVat
					:	billingPlanLine.billingPlanSum,
				percent: 0,
				...getObjectAccountingFormDefaultValues(accountingDimensions),
			};
		}
	}

	async function submit(values: FormValues) {
		const sumInput: BillingPlanLineSumInput =
			values.sumInputType === "sum" ?
				{
					type: "sum",
					sum: values.sum,
				}
			: values.sumInputType === "percent" ?
				{
					type: "percent",
					percent: values.percent,
				}
			:	{
					type: "rest",
				};

		return await CustomerOfferBillingPlanEditApi.save({
			customerOfferId: customerOfferId,
			billingPlanLineId: billingPlanLine?.customerOfferBillingPlanLineId,
			description: values.description,
			funded: values.funded,
			vatCodeId: values.vatCodeId,
			sumInput: sumInput,
			accountingCodeIds: getObjectAccountingCodeIdsFromFormValues(values.accountingDimensionValues),
		});
	}
};
