// This file is automatically generated. Do not edit manually.

import { SurveyForm } from "src/api/generated/erp/db/types/tables/surveyForm";
import { SurveyCategory } from "src/api/generated/erp/db/types/tables/surveyCategory";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace SurveyFormEditApi {
	export async function getFormInitData(args: {
		surveyFormId: number | null | undefined;
	}): Promise<SurveyFormEditApi_FormInitData> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/surveys/management/surveyForm/getFormInitData",
			method: "POST",
			data: {
				surveyFormId: args.surveyFormId,
			},
		});
		return response as SurveyFormEditApi_FormInitData;
	}

	export async function insert(args: { surveyForm: SurveyForm }): Promise<number> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/surveys/management/surveyForm/insert",
			method: "POST",
			data: {
				surveyForm: args.surveyForm,
			},
		});
		return response as number;
	}

	export async function update(args: { surveyForm: SurveyForm }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/surveys/management/surveyForm/update",
			method: "POST",
			data: {
				surveyForm: args.surveyForm,
			},
		});
		return response as void;
	}

	export async function delete_(args: { surveyFormId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/surveys/management/surveyForm/delete",
			method: "POST",
			data: {
				surveyFormId: args.surveyFormId,
			},
		});
		return response as void;
	}
}

export interface SurveyFormEditApi_FormInitData {
	surveyCategories: Array<SurveyCategory>;
	surveyForm: SurveyForm | null | undefined;
}
