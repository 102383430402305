import {FormCommonProps} from "src/components/common/forms/types.ts";
import {ConfigurationComponent} from "src/api/generated/erp/db/types/tables/configurationComponent.ts";
import {
	ConfiguratorLibrariesApi
} from "src/api/generated/erp/configurator/management/libraries/api/configuratorLibrariesApi.ts";
import {FormLazySelectField} from "src/components/common/forms/fields/FormLazySelectField.tsx";
import {AavoForm} from "src/components/common/forms/AavoForm.tsx";
import i18n from "i18next";
import {requireRule} from "src/components/common/forms/validation.ts";
import {
	ConfiguratorLibraryComponentsApi
} from "src/api/generated/erp/configurator/management/libraries/api/configuratorLibraryComponentsApi.ts";

export interface SelectLibraryComponentFormProps extends FormCommonProps<ConfigurationComponent> {}

interface FormValues {
	configuratorLibraryId: number;
	libraryComponent: ConfigurationComponent;
}

export const SelectLibraryComponentForm = (props: SelectLibraryComponentFormProps) => {
	const { onCompleted, onFormEdited } = props;
	return (
		<AavoForm<FormValues, ConfigurationComponent>
			defaultValues={{}}
			submit={(values) => values.libraryComponent}
			onCompleted={onCompleted}
			onFormEdited={onFormEdited}
			render={({ control, watch }) => {
				const configuratorLibraryId = watch("configuratorLibraryId");
				return (
					<>
						<FormLazySelectField
							control={control}
							name={"configuratorLibraryId"}
							label={i18n.t("library")}
							fetchOptions={() => ConfiguratorLibrariesApi.getConfiguratorLibraries()}
							getOptionKey={(option) => option.configuratorLibraryId}
							getOptionLabel={(option) => option.name}
							rules={requireRule()}
						/>
						<FormLazySelectField
							control={control}
							name={"libraryComponent"}
							label={i18n.t("component")}
							disabled={configuratorLibraryId == null}
							fetchOptions={() =>
								ConfiguratorLibraryComponentsApi.getLatestLibraryVersionComponents({
									configuratorLibraryId,
								})
							}
							getOptionKey={(option) => option.configurationComponentId}
							getOptionLabel={(option) => option.label}
							selectionToFieldValue={(_, component) => component}
							fieldValueToKey={(component: ConfigurationComponent | undefined) =>
								component?.configurationComponentId
							}
							rules={requireRule()}
						/>
					</>
				);
			}}
		/>
	);
};
