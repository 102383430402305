// This file is automatically generated. Do not edit manually.

import { ServerSideDataModelRequest } from "src/api/generated/common/dataGrids/serverSideDataModelRequest";
import { ServerSideDataModelResult } from "src/api/generated/common/dataGrids/serverSideDataModelResult";
import { SalesPriceListView } from "src/api/generated/erp/db/types/tables/salesPriceListView";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace SalesPriceListDataGridApi {
	export async function getData(args: {
		salesPartPriceGroupFilter: number | null | undefined;
		searchQuery: string;
		includeDeactivated: boolean;
		dataModelRequest: ServerSideDataModelRequest;
	}): Promise<ServerSideDataModelResult<SalesPriceListView>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/pricing/salesPriceLists/dataGrid/getData",
			method: "POST",
			data: {
				salesPartPriceGroupFilter: args.salesPartPriceGroupFilter,
				searchQuery: args.searchQuery,
				includeDeactivated: args.includeDeactivated,
				dataModelRequest: args.dataModelRequest,
			},
		});
		return response as ServerSideDataModelResult<SalesPriceListView>;
	}
}
