// This file is automatically generated. Do not edit manually.

import { PartConfiguratorType } from "src/api/generated/erp/configurator/configuratorType/partConfiguratorType";
import { ConfiguratorInitialInputSource } from "src/api/generated/erp/configurator/misc/configuratorInitialInputSource";
import { ConfiguratorInput } from "src/api/generated/erp/configurator/model/configuratorInput";
import { ConfigurationPropertyValues } from "src/api/generated/erp/configurator/model/configurationPropertyValues";
import { ConfiguratorEvent } from "src/api/generated/erp/configurator/model/configuratorEvent";
import { TransformComponentsResult } from "src/api/generated/erp/configurator/api/form/transformComponentsResult";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace PartConfiguratorFormApi {
	export async function getConfigurationHasBeenEditedManually(args: {
		configuratorType: PartConfiguratorType;
	}): Promise<boolean> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/configurator/configuratorForm/getConfigurationHasBeenEditedManually",
			method: "POST",
			data: {
				configuratorType: args.configuratorType,
			},
		});
		return response as boolean;
	}

	export async function initializeSession(args: { configuratorType: PartConfiguratorType }): Promise<string> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/configurator/configuratorForm/initializeSession",
			method: "POST",
			data: {
				configuratorType: args.configuratorType,
			},
		});
		return response as string;
	}

	export async function getFormInitData(args: {
		configurationSessionId: string;
		configuratorType: PartConfiguratorType;
		forceInitialInputSource?: ConfiguratorInitialInputSource | null | undefined;
	}): Promise<PartConfiguratorFormApi_FormInitData> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/configurator/configuratorForm/getFormInitData",
			method: "POST",
			data: {
				configurationSessionId: args.configurationSessionId,
				configuratorType: args.configuratorType,
				forceInitialInputSource: args.forceInitialInputSource,
			},
		});
		return response as PartConfiguratorFormApi_FormInitData;
	}

	export async function saveIncompleteConfiguratorInput(args: {
		configuratorType: PartConfiguratorType;
		configuratorInput: ConfiguratorInput;
	}): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/configurator/configuratorForm/saveIncompleteConfiguratorInput",
			method: "POST",
			data: {
				configuratorType: args.configuratorType,
				configuratorInput: args.configuratorInput,
			},
		});
		return response as void;
	}

	export async function clearIncompleteConfiguratorInput(args: {
		configuratorType: PartConfiguratorType;
	}): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/configurator/configuratorForm/clearIncompleteConfiguratorInput",
			method: "POST",
			data: {
				configuratorType: args.configuratorType,
			},
		});
		return response as void;
	}

	export async function resolvePropertyValues(args: {
		configurationSessionId: string;
		configuratorType: PartConfiguratorType;
		configuratorInput: ConfiguratorInput;
	}): Promise<ConfigurationPropertyValues> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/configurator/configuratorForm/resolvePropertyValues",
			method: "POST",
			data: {
				configurationSessionId: args.configurationSessionId,
				configuratorType: args.configuratorType,
				configuratorInput: args.configuratorInput,
			},
		});
		return response as ConfigurationPropertyValues;
	}

	export async function transformComponents(args: {
		configurationSessionId: string;
		configuratorType: PartConfiguratorType;
		configuratorInput: ConfiguratorInput;
		event?: ConfiguratorEvent | null | undefined;
	}): Promise<TransformComponentsResult> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/configurator/configuratorForm/transformComponents",
			method: "POST",
			data: {
				configurationSessionId: args.configurationSessionId,
				configuratorType: args.configuratorType,
				configuratorInput: args.configuratorInput,
				event: args.event,
			},
		});
		return response as TransformComponentsResult;
	}

	export async function getComponentDocumentId(args: { configuratorComponentId: number }): Promise<number | null> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/configurator/configuratorForm/getComponentDocumentId",
			method: "POST",
			data: {
				configuratorComponentId: args.configuratorComponentId,
			},
		});
		return response === "" ? null : (response as number | null);
	}
}

export interface PartConfiguratorFormApi_FormInitData {
	initialConfiguratorInput: ConfiguratorInput;
	initialValuesFromConfigurationId: number | null | undefined;
}
