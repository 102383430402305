// This file is automatically generated. Do not edit manually.

import { ProjectActivityResource } from "src/api/generated/erp/db/types/tables/projectActivityResource";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace ProjectActivityResourcesApi {
	export async function getProjectActivityResourceOptions(): Promise<Array<ProjectActivityResource>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/project/activityResources/getProjectActivityResourceOptions",
			method: "POST",
			data: {},
		});
		return response as Array<ProjectActivityResource>;
	}
}
