import {
    DateRangePickerProps,
    DateRangePicker,
} from "@mui/x-date-pickers-pro";
import {Dayjs} from "dayjs";

export interface AavoDateRangePickerProps extends DateRangePickerProps<Dayjs> {
    errorMessage?: string;
}

export const AavoDateRangePicker = ({
                                        slotProps,
                                        errorMessage,
                                        displayWeekNumber = true,
                                        ...other
                                    }: AavoDateRangePickerProps) => {
    const slotPropsWrapped = {
        ...slotProps,
        textField: {
            ...{
                error: errorMessage !== undefined,
                helperText: errorMessage,
            },
            ...slotProps?.textField,
        },
    };

    return <DateRangePicker slotProps={slotPropsWrapped}
                            displayWeekNumber={displayWeekNumber}
                            {...other} />;
};
