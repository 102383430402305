import {
	AavoMosaicNode,
	AavoMosaicPanel,
	AavoMosaicTabContainer,
	AavoMosaicTabContainerWithKey,
} from "src/components/common/mosaic/types.ts";
import { MosaicNode } from "react-mosaic-component";

export interface AavoMosaicNodeMappingResult {
	rootNode: MosaicNode<string>;
	allTabs: AavoMosaicTabContainerWithKey[];
}

export const mapAavoMosaicNodes = (node: AavoMosaicNode): AavoMosaicNodeMappingResult =>
	mapNodesHelper(node, "root");

const mapNodesHelper = (node: AavoMosaicNode, key: string): AavoMosaicNodeMappingResult => {
	if (isMosaicPanel(node)) {
		return mapNodesHelper(
			{
				type: "tabs",
				hideToolbar: node.title == null,
				items: [
					{
						key: "0",
						...node,
					},
				],
			},
			key,
		);
	} else if (isMosaicTabContainer(node)) {
		return {
			rootNode: key,
			allTabs: [
				{
					...node,
					key: key,
				},
			],
		};
	} else {
		if (isHidden(node.first)) {
			return mapNodesHelper(node.second, key);
		}
		if (isHidden(node.second)) {
			return mapNodesHelper(node.first, key);
		}

		const firstResult = mapNodesHelper(node.first, `${key}_first`);
		const secondResult = mapNodesHelper(node.second, `${key}_second`);
		return {
			rootNode: {
				direction: node.type,
				first: firstResult.rootNode,
				second: secondResult.rootNode,
				splitPercentage: node.splitPercentage,
			},
			allTabs: [...firstResult.allTabs, ...secondResult.allTabs],
		};
	}
};

const isMosaicTabContainer = (node: AavoMosaicNode): node is AavoMosaicTabContainer => {
	return (node as AavoMosaicTabContainer).type === "tabs";
};

const isMosaicPanel = (node: AavoMosaicNode): node is AavoMosaicPanel => {
	return (node as AavoMosaicPanel).type === "panel";
};

const isHidden = (node: AavoMosaicNode): boolean => {
	if (isMosaicPanel(node)) {
		return node.hidden === true;
	}
	return false;
};
