import { AccountingDimensionTargetsApi } from "src/api/generated/erp/sales/accounting/api/accountingDimensionTargetsApi.ts";
import i18n from "i18next";
import { AccountingDimensionTarget } from "src/api/generated/erp/db/types/tables/accountingDimensionTarget.ts";
import { getAccountingObjectTypeLabels } from "src/api/generated/erp/db/types/enums/accountingObjectType.ts";
import { FormEnumSelectField } from "src/components/common/forms/fields/FormEnumSelectField.tsx";
import { requireRule } from "src/components/common/forms/validation.ts";
import { AsyncForm } from "src/components/common/forms/AsyncForm.tsx";
import { removeKeysFromRecord } from "src/utils/objectUtils.ts";
import { FormCommonProps } from "src/components/common/forms/types.ts";

export interface AccountingDimensionTargetFormProps extends FormCommonProps<number> {
	dimensionId: number;
}

interface FormValues extends AccountingDimensionTarget {}

export const AccountingDimensionTargetForm = (props: AccountingDimensionTargetFormProps) => {
	const { dimensionId, onCompleted, onFormEdited } = props;
	return (
		<AsyncForm
			fetch={() => AccountingDimensionTargetsApi.getFormInitData({ dimensionId })}
			getDefaultValues={() => ({
				accountingDimensionId: dimensionId,
			})}
			submit={submit}
			onCompleted={onCompleted}
			onFormEdited={onFormEdited}
			render={({ control, data: { connectedObjectTypes } }) => (
				<FormEnumSelectField
					control={control}
					name={"objectType"}
					label={i18n.t("target")}
					options={removeKeysFromRecord(getAccountingObjectTypeLabels(), connectedObjectTypes)}
					rules={requireRule()}
				/>
			)}
		/>
	);

	async function submit(values: FormValues) {
		return AccountingDimensionTargetsApi.insert({
			target: values,
		});
	}
};
