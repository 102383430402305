import {getLanguageSetting} from "src/localSettings/utils.ts";
import HighchartsGantt from "highcharts/highcharts-gantt";
import HighChartsDraggablePoints from "highcharts/modules/draggable-points";
import HighChartsExporting from "highcharts/modules/exporting";
import HighChartsCustomEvents from "highcharts-custom-events";
import {AAVO_MUI_THEME} from "src/theme/theme.ts";
import {alpha} from "@mui/material/styles";
import deepMerge from "src/utils/deepMerge.ts";
import React from "react";

HighChartsDraggablePoints(HighchartsGantt);
HighChartsExporting(HighchartsGantt);
HighChartsCustomEvents(HighchartsGantt);

declare module "highcharts/highcharts-gantt" {
    interface PointEventsOptionsObject {
        contextmenu?: (event: React.MouseEvent & { point: HighchartsGantt.Point }) => void;
        dblclick?: (event: React.MouseEvent & { point: HighchartsGantt.Point }) => void;
    }
}

// noinspection UnnecessaryLocalVariableJS
const theme = AAVO_MUI_THEME;

const getCommonOptions = () => {
    return {
        lang: getLang(),
        plotOptions: {
            series: {
                animation: false,
            },
        },
        xAxis: {
            dateTimeLabelFormats: dateTimeLabelFormats,
            currentDateIndicator: {
                color: theme.palette.primary.light,
                label: {
                    format: "%d.%m.%Y",
                },
            },
        },
        yAxis: {
            dateTimeLabelFormats: dateTimeLabelFormats,
        },
        tooltip: {
            dateTimeLabelFormats: dateTimeLabelFormats,
        },
        credits: {
            enabled: false,
        },
        accessibility: {
            enabled: false,
        },
    };
};

const getGanttOptions = () => {
    return deepMerge(getCommonOptions(), {
        chart: {
            scrollablePlotArea: {
                opacity: 0.5,
            },
            marginRight: 24,
        },
        navigator: {
            maskFill: alpha(theme.palette.primary.main, 0.2),
        },
        rangeSelector: {
            selected: 5,
            inputStyle: {
                color: theme.palette.text.primary,
            },
        },
    });
};

const getLang = () => {
    const userLang = getLanguageSetting();
    if (userLang === "fi")
        return {
            months: [
                "Tammikuu",
                "Helmikuu",
                "Maaliskuu",
                "Huhtikuu",
                "Toukokuu",
                "Kesäkuu",
                "Heinäkuu",
                "Elokuu",
                "Syyskuu",
                "Lokakuu",
                "Marraskuu",
                "Joulukuu",
            ],
            weekdays: [
                "Maanantai",
                "Tiistai",
                "Keskiviikko",
                "Torstai",
                "Perjantai",
                "Lauantai",
                "Sunnuntai",
            ],
            loading: "Ladataan...",
            noData: "Ei dataa",
            decimalPoint: ",",
            resetZoom: "Palauta zoomaus",
        };

    return {};
};

const dateTimeLabelFormats = {
    day: "%d.%m",
    week: "%d.%m",
    month: "%m / %Y",
};

HighchartsGantt.setOptions(getGanttOptions());
