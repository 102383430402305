// This file is automatically generated. Do not edit manually.

import { ObjectAttributeFieldWithValue } from "src/api/generated/erp/common/objectAttributes/objectAttributeFieldWithValue";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace CustomerOrderAttributesApi {
	export async function getCustomerOrderAttributes(args: {
		customerOrderId: number;
	}): Promise<Array<ObjectAttributeFieldWithValue>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/customerOrder/attributes/getCustomerOrderAttributes",
			method: "POST",
			data: {
				customerOrderId: args.customerOrderId,
			},
		});
		return response as Array<ObjectAttributeFieldWithValue>;
	}

	export async function saveCustomerOrderAttributes(args: {
		customerOrderId: number;
		attributes: Array<ObjectAttributeFieldWithValue>;
	}): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/customerOrder/attributes/saveCustomerOrderAttributes",
			method: "POST",
			data: {
				customerOrderId: args.customerOrderId,
				attributes: args.attributes,
			},
		});
		return response as void;
	}
}
