import i18n from "i18next";
import {HorizontalBox} from "src/components/common/box/HorizontalBox.tsx";
import {Fragment} from "react";
import {Typography} from "@mui/material";
import {CustomerOrderSummary} from "src/api/generated/erp/sales/customerOrder/service/customerOrderSummary.ts";
import {
	CustomerOrderOfferSummary
} from "src/api/generated/erp/sales/customerOrderOffer/service/customerOrderOfferSummary.ts";
import {formatMoney, formatNumber} from "src/utils/numberUtils.ts";

export interface CustomerOrderOrOfferSummaryTextProps {
	summary: CustomerOrderSummary | CustomerOrderOfferSummary;
}

export const CustomerOrderOrOfferSummaryText = ({ summary }: CustomerOrderOrOfferSummaryTextProps) => {
	const fields = [
		{ label: i18n.t("sum"), value: formatMoney(summary.totalPrice) },
		{
			label: i18n.t("sum_with_vat"),
			value: formatMoney(summary.totalPriceWithVat),
		},
		{ label: i18n.t("cost"), value: formatMoney(summary.totalCost) },
		{
			label: i18n.t("gross_margin"),
			value: `${formatNumber(summary.grossMargin * 100, { minDigits: 0, maxDigits: 0 })} %`,
		},
		{
			label: i18n.t("capacity_quantity"),
			value: `${summary.totalCapacityQuantity}`,
		},
	];
	return (
		<HorizontalBox
			sx={{
				gap: "0.25rem",
				alignSelf: "end",
			}}
		>
			{fields.map((field, index) => (
				<Fragment key={index}>
					<Typography
						sx={{
							fontStyle: "italic",
							marginLeft: "0.75rem",
						}}
					>{`${field.label}: `}</Typography>
					<Typography>{field.value}</Typography>
				</Fragment>
			))}
		</HorizontalBox>
	);
};
