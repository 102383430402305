import i18n from "i18next";
import { faMoneyCheckPen } from "@fortawesome/pro-regular-svg-icons";
import { AsyncButton } from "src/components/common/buttons/AsyncButton.tsx";
import { useConfirmDialog } from "src/components/common/dialogs/confirmDialog/useConfirmDialog.ts";
import { SalesCommissionLineView } from "src/api/generated/erp/db/types/tables/salesCommissionLineView.ts";
import { deCapitalize } from "src/utils/strings.tsx";
import { SalesCommissionPaymentApi } from "src/api/generated/erp/sales/salesCommissions/api/salesCommissionPaymentApi.ts";
import {
	SalesCommissionPaymentCreationDataGridApi,
	SalesCommissionPaymentCreationDataGridApi_DataGridData,
} from "src/api/generated/erp/sales/salesCommissions/api/salesCommissionPaymentCreationDataGridApi.ts";
import {
	ClientSideDataGridModel,
	ClientSideDataGridModelRenderProps,
} from "src/components/common/dataGrid/gridModel/ClientSideDataGridModel.tsx";
import { SalespersonView } from "src/api/generated/erp/db/types/tables/salespersonView.ts";
import { MultiSelectField } from "src/components/common/inputFields/MultiSelectField.tsx";
import { AavoDataGrid } from "src/components/common/dataGrid/AavoDataGrid.tsx";
import { getSalesCommissionLinesDataGridColumns } from "src/components/views/erp/sales/salesCommissions/salesCommissionUtils.ts";
import { OpenCustomerOrderButton } from "src/components/views/erp/utilComponents/OpenCustomerOrderButton.tsx";

export const SalesCommissionPaymentCreationView = () => {
	return (
		<ClientSideDataGridModel<
			SalesCommissionPaymentCreationDataGridApi_DataGridData,
			SalesCommissionLineView,
			{
				salespersonIds: Array<number>;
			}
		>
			fetchData={SalesCommissionPaymentCreationDataGridApi.getDataGridData}
			initialParams={{ salespersonIds: Array<number>() }}
			getRowId={(row) => row.salesCommissionLineId}
			getRows={(data) => data.commissionLines}
			gridId={"629EF7E9A7B2F508"}
			render={(params) => <Content {...params} />}
		/>
	);
};

interface ContentProps
	extends ClientSideDataGridModelRenderProps<
		SalesCommissionPaymentCreationDataGridApi_DataGridData,
		SalesCommissionLineView,
		{ salespersonIds: Array<number> }
	> {}

const Content = ({
	dataGridProps,
	selectedRows,
	refreshData,
	currentParams,
	data: { salespersonOptions },
}: ContentProps) => {
	const showConfirmDialog = useConfirmDialog();

	return (
		<AavoDataGrid<SalesCommissionLineView>
			checkboxSelection
			columns={[
				getSalesCommissionLinesDataGridColumns({
					showState: false,
					showEstimatedApprovalDate: false,
					showPaymentColumns: false,
				}),
			]}
			actionBarComponents={
				<>
					<MultiSelectField
						label={i18n.t("salespersons")}
						options={salespersonOptions}
						getOptionKey={(option) => option.salespersonId}
						getOptionLabel={(option) => option.name}
						value={currentParams.salespersonIds}
						onChange={(salespersonIds) => refreshData({ salespersonIds })}
					/>
					<AsyncButton
						icon={faMoneyCheckPen}
						label={i18n.t("create_payments")}
						disabled={selectedRows.length === 0}
						variant={"outlined"}
						onClick={onCreatePaymentsClick}
					/>
				</>
			}
			rowContextMenuComponents={({ row }) => [
				<OpenCustomerOrderButton key={"openCustomerOrder"} customerOrderId={row.customerOrderId} />,
			]}
			{...dataGridProps}
		/>
	);

	async function onCreatePaymentsClick() {
		const confirmed = await confirmCreatePayments();
		if (!confirmed) return;

		await SalesCommissionPaymentApi.createPayments({
			commissionLineIds: selectedRows.map((row) => row.salesCommissionLineId),
		});
		await refreshData();
	}

	async function confirmCreatePayments() {
		const salespersonsWithLines: SalespersonCommissionLines[] = salespersonOptions
			.map((salesperson) => ({
				salesperson: salesperson,
				lines: selectedRows.filter((line) => line.salespersonId === salesperson.salespersonId),
			}))
			.filter(({ lines }) => lines.length > 0);

		const salespersonsTextInConfirm = salespersonsWithLines
			.map(
				({ salesperson, lines }) =>
					`${salesperson.name}: ${lines.length} ${deCapitalize(i18n.t("installments.2"))}`,
			)
			.join("\n");

		return await showConfirmDialog({
			message: i18n.t("create_sales_commission_payments_confirm") + "\n\n" + salespersonsTextInConfirm,
		});
	}
};

interface SalespersonCommissionLines {
	salesperson: SalespersonView;
	lines: SalesCommissionLineView[];
}
