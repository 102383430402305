import { ClientSideDataGridModel } from "src/components/common/dataGrid/gridModel/ClientSideDataGridModel.tsx";
import { CrudDataGrid } from "src/components/common/dataGrid/crud/CrudDataGrid.tsx";
import {
	dateTimeColumn,
	enumColumn,
	floatColumn,
	integerColumn,
	textColumn,
} from "src/components/common/dataGrid/columns.tsx";
import i18n from "i18next";
import { getShopOrderStateLabels } from "src/api/generated/erp/db/types/enums/shopOrderState.ts";
import { ShopOrderView } from "src/api/generated/erp/db/types/tables/shopOrderView.ts";
import { AsyncButton } from "src/components/common/buttons/AsyncButton.tsx";
import { faLink, faTh } from "@fortawesome/pro-regular-svg-icons";
import { OpenShopOrderButton } from "src/components/views/erp/utilComponents/OpenShopOrderButton.tsx";
import { OpenCustomerOrderButton } from "src/components/views/erp/utilComponents/OpenCustomerOrderButton.tsx";
import { useGenericDialog } from "src/components/common/dialogs/useGenericDialog.ts";
import { AddShopOrdersToBatchDataGrid } from "src/components/views/erp/production/shopOrderBatch/AddShopOrdersToBatchDataGrid.tsx";
import { RefreshableElementRef } from "src/utils/useRefreshRef.ts";
import { AsyncMenuButton } from "src/components/common/contextMenu/AsyncMenuButton.tsx";
import { openLegacyShopOrderStateView } from "src/components/views/legacy/legacyViewAdapters.ts";
import { useOpenLegacyView } from "src/components/views/legacy/useOpenLegacyView.ts";
import { ShopOrderBatch } from "src/api/generated/erp/db/types/tables/shopOrderBatch.ts";
import { ShopOrderBatchShopOrdersApi } from "src/api/generated/erp/production/shopOrderBatch/api/shopOrderBatchShopOrdersApi.ts";

export interface ShopOrderBatchShopOrdersDataGridProps {
	shopOrderBatch: ShopOrderBatch;
	shopOrderBatchesRefreshRef?: RefreshableElementRef;
	editable?: boolean;
}

export const ShopOrderBatchShopOrdersDataGrid = ({
	shopOrderBatch,
	shopOrderBatchesRefreshRef,
	editable = true,
}: ShopOrderBatchShopOrdersDataGridProps) => {
	const { openDialog } = useGenericDialog();
	const openLegacyView = useOpenLegacyView();
	return (
		<ClientSideDataGridModel
			gridId={"EC9991492B12B3B2AB11"}
			fetchData={async () =>
				await ShopOrderBatchShopOrdersApi.getShopOrderBatchShopOrders({
					shopOrderBatchId: shopOrderBatch.shopOrderBatchId,
				})
			}
			getRows={(data) => data}
			getRowId={(row) => row.shopOrderId}
			initialParams={{}}
			render={({ dataGridProps, selectedRows, onlySelectedRow, refreshData }) => {
				return (
					<CrudDataGrid<ShopOrderView>
						columns={[
							integerColumn({
								field: "shopOrderId",
								headerName: i18n.t("number_short"),
							}),
							textColumn({
								field: "sourceRefData",
								headerName: i18n.t("reference"),
							}),
							textColumn({
								field: "configurationOrPartDescription",
								headerName: i18n.t("part"),
								width: 200,
							}),
							floatColumn({
								field: "quantity",
								headerName: i18n.t("quantity"),
							}),
							textColumn({
								field: "partUnit",
								headerName: i18n.t("unit"),
							}),
							enumColumn({
								field: "shopOrderState",
								headerName: i18n.t("state"),
								enumLabels: getShopOrderStateLabels(),
							}),
							dateTimeColumn({
								field: "demandDate",
								headerName: i18n.t("demand_date"),
								width: 150,
							}),
							floatColumn({
								field: "capacityQuantity",
								headerName: i18n.t("capacity_quantity"),
								width: 140,
							}),
							dateTimeColumn({
								field: "plannedBeginDate",
								headerName: i18n.t("planned_begin_date_short"),
								width: 130,
							}),
							dateTimeColumn({
								field: "plannedEndDate",
								headerName: i18n.t("planned_end_date_short"),
								width: 130,
							}),
							floatColumn({
								field: "totalEstimatedProductionTimeHours",
								headerName: i18n.t("estimated_production_time"),
								width: 200,
							}),
						]}
						actionBarComponents={
							<>
								{editable && (
									<AsyncButton
										label={i18n.t("connect_shop_orders")}
										icon={faLink}
										disabled={
											shopOrderBatch.batchState != "INITIAL" ?
												i18n.t("shop_order_batch_is_not_in_initial_state")
											:	undefined
										}
										variant={"outlined"}
										onClick={() => {
											openDialog(({ closeDialog }) => ({
												title: i18n.t("connect_shop_orders"),
												size: "xl",
												content: (
													<AddShopOrdersToBatchDataGrid
														shopOrderBatch={shopOrderBatch}
														onCompleted={async () => {
															await closeDialog();
															await Promise.all([
																refreshData(),
																shopOrderBatchesRefreshRef?.refresh(),
															]);
														}}
													/>
												),
											}));
										}}
									/>
								)}
							</>
						}
						rowContextMenuComponents={({ row }) => [
							<AsyncMenuButton
								key={"snapshot"}
								label={i18n.t("snapshot")}
								icon={faTh}
								disabled={onlySelectedRow == null}
								onClick={async () => {
									openLegacyShopOrderStateView({
										openLegacyView: openLegacyView,
										shopOrderId: onlySelectedRow!.shopOrderId,
									});
								}}
							/>,
							<OpenShopOrderButton key={"openShopOrder"} shopOrderId={row.shopOrderId} />,
							<OpenCustomerOrderButton key={"openCustomerOrder"} customerOrderId={row.customerOrderId} />,
						]}
						remove={
							editable && shopOrderBatch.batchState === "INITIAL" &&
							(async () => {
								await ShopOrderBatchShopOrdersApi.removeShopOrdersFromBatch({
									shopOrderBatchId: shopOrderBatch.shopOrderBatchId,
									shopOrderIds: selectedRows.map((r) => r.shopOrderId),
								});
								await Promise.all([refreshData(), shopOrderBatchesRefreshRef?.refresh()]);
							})
						}
						{...dataGridProps}
					/>
				);
			}}
		/>
	);
};
