// This file is automatically generated. Do not edit manually.

import { SubProjectView } from "src/api/generated/erp/db/types/tables/subProjectView";
import { SubProjectType } from "src/api/generated/erp/db/types/tables/subProjectType";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace SubProjectApi {
	export async function getSubProject(args: { subProjectId: number }): Promise<SubProjectView> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/project/subProject/getSubProject",
			method: "POST",
			data: {
				subProjectId: args.subProjectId,
			},
		});
		return response as SubProjectView;
	}

	export async function getSubProjectTypes(): Promise<Array<SubProjectType>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/project/subProject/getSubProjectTypes",
			method: "POST",
			data: {},
		});
		return response as Array<SubProjectType>;
	}

	export async function getProjectSubProjects(args: { projectId: number }): Promise<Array<SubProjectView>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/project/subProject/getProjectSubProjects",
			method: "POST",
			data: {
				projectId: args.projectId,
			},
		});
		return response as Array<SubProjectView>;
	}
}
