import { TaskSourceRef } from "src/api/generated/tasks/taskSource/taskSourceRef.ts";
import { TasksView } from "../TasksView.tsx";
import { RefreshableElementProps } from "src/utils/useRefreshRef.ts";

export interface SingleSourceTasksViewProps extends RefreshableElementProps {
	taskSourceRef: TaskSourceRef;
}

export const SingleSourceTasksView = ({
	refreshRef,
	taskSourceRef,
}: SingleSourceTasksViewProps) => {
	return (
		<TasksView
			refreshRef={refreshRef}
			taskQueryParams={{
				sources: [taskSourceRef],
			}}
			showCanBeStarted={false}
			showIsInSchedulePlanning={false}
			showTaskSourceInfo={false}
			showAssignedTo={true}
			showResponsiblePerson={true}
			usePagination={false}
			taskSourceRef={taskSourceRef}
		/>
	);
};
