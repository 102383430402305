import { PartConfiguratorView } from "src/components/views/erp/configurator/configuratorForm/PartConfiguratorView.tsx";
import { SubConfiguratorFormApi } from "src/api/generated/erp/configurator/api/form/subConfiguratorFormApi.ts";
import { AsyncFetchRender } from "src/components/common/async/AsyncFetchRender.tsx";
import { SubConfiguratorPartConfiguratorType } from "src/api/generated/erp/configurator/configuratorType/impl/subConfiguratorPartConfiguratorType.ts";
import { PartConfiguratorFormApi } from "src/api/generated/erp/configurator/api/form/partConfiguratorFormApi.ts";
import {
	ConfigurationPropertyValue_SubConfiguration,
	ConfigurationPropertyValue_SubConfigurationList,
} from "src/api/generated/io/aavo/applications/db/erp/types/configurationPropertyValue.ts";
import { HorizontalBox } from "src/components/common/box/HorizontalBox.tsx";
import { CenteredTypography } from "src/components/common/CenteredTypography.tsx";
import { useEffect, useState } from "react";
import { VerticalBox } from "src/components/common/box/VerticalBox.tsx";
import { hiddenElementKeepingState, positionAbsoluteFullSize } from "src/styles/sx.ts";
import { mergeSx } from "src/utils/styles.ts";
import { SubConfiguratorSelectionView } from "src/components/views/erp/configurator/configuratorForm/components/subConfiguratorList/SubConfiguratorSelectionView.tsx";
import { SubConfiguratorListItemState } from "src/components/views/erp/configurator/configuratorForm/components/subConfiguratorList/SubConfiguratorListItemState.tsx";
import { useErrorDialog } from "src/components/common/dialogs/errorDialog/userErrorDialog.ts";
import { AavoFormActionButtons } from "src/components/common/forms/AavoFormActionButtons.tsx";
import i18n from "i18next";
import type { OnSubConfiguratorInputChangedParams } from "src/components/views/erp/configurator/configuratorForm/components/subConfiguratorList/SubConfiguratorListStatefulView.tsx";

export interface SubConfiguratorListStatelessViewProps {
	subConfiguratorType: SubConfiguratorPartConfiguratorType;
	subConfiguratorStates: SubConfiguratorListItemState[];
	selectedSubConfiguratorKey: string | undefined;
	selectSubConfigurator: (key: string) => Promise<unknown>;
	addSubConfigurator: () => Promise<unknown>;
	removeSubConfigurator: (key: string) => void;
	swapSubConfigurators: (key1: string, key2: string) => void;
	onSubConfiguratorInputChanged: (
		key: string,
		params: OnSubConfiguratorInputChangedParams,
	) => Promise<unknown>;
	onSubmit: () => Promise<void>;
}

export interface SubConfiguratorListViewApi {
	getValue: () => Promise<ConfigurationPropertyValue_SubConfigurationList>;
}

export const SubConfiguratorListStatelessView = ({
	subConfiguratorType,
	subConfiguratorStates,
	selectedSubConfiguratorKey,
	selectSubConfigurator,
	addSubConfigurator,
	removeSubConfigurator,
	swapSubConfigurators,
	onSubConfiguratorInputChanged,
	onSubmit,
}: SubConfiguratorListStatelessViewProps) => {
	return (
		<VerticalBox flex={1}>
			<HorizontalBox flex={1}>
				<SubConfiguratorSelectionView
					subConfiguratorStates={subConfiguratorStates}
					selectedSubConfiguratorKey={selectedSubConfiguratorKey}
					selectSubConfigurator={selectSubConfigurator}
					addSubConfigurator={addSubConfigurator}
					removeSubConfigurator={removeSubConfigurator}
					swapSubConfigurators={swapSubConfigurators}
				/>
				<VerticalBox
					sx={{
						flex: 1,
						position: "relative",
					}}
				>
					{subConfiguratorStates.length === 0 && (
						<CenteredTypography>{i18n.t("select_or_add_sub_configuration")}</CenteredTypography>
					)}
					{subConfiguratorStates.map(({ key, subConfigurationValue }) => (
						<SingleSubConfiguratorView
							key={key}
							isActive={selectedSubConfiguratorKey === key}
							subConfiguratorType={subConfiguratorType}
							initialValue={subConfigurationValue}
							onInputChanged={(params) => onSubConfiguratorInputChanged(key, params)}
						/>
					))}
				</VerticalBox>
			</HorizontalBox>
			<AavoFormActionButtons onSubmit={onSubmit} />
		</VerticalBox>
	);
};

interface SingleSubConfiguratorViewProps {
	subConfiguratorType: SubConfiguratorPartConfiguratorType;
	initialValue: ConfigurationPropertyValue_SubConfiguration;
	isActive: boolean;
	onInputChanged: (params: OnSubConfiguratorInputChangedParams) => Promise<unknown>;
}

const SingleSubConfiguratorView = ({
	subConfiguratorType,
	initialValue,
	isActive,
	onInputChanged,
}: SingleSubConfiguratorViewProps) => {
	const { withErrorHandling } = useErrorDialog();

	const [hasBeenRendered, setHasBeenRendered] = useState(false);

	useEffect(() => {
		if (isActive) {
			setHasBeenRendered(true);
		}
	}, [isActive]);

	// Render lazily on first activation.
	if (!hasBeenRendered && !isActive) {
		return null;
	}

	return (
		<VerticalBox
			sx={mergeSx(
				{
					flex: 1,
				},
				positionAbsoluteFullSize,
				!isActive && hiddenElementKeepingState,
			)}
		>
			<AsyncFetchRender
				fetch={fetchInitData}
				content={({ configurationSessionId, initialInput }) => (
					<PartConfiguratorView
						configurationSessionId={configurationSessionId}
						configuratorType={subConfiguratorType}
						initialConfiguratorInput={initialInput}
						onConfiguratorInputChanged={(newInput, errors) => {
							withErrorHandling(async () => {
								await onInputChanged({
									input: newInput,
									errors: errors,
									configurationSessionId: configurationSessionId,
								});
							});
						}}
						hideFooter
					/>
				)}
			/>
		</VerticalBox>
	);

	async function fetchInitData() {
		const configurationSessionId = await PartConfiguratorFormApi.initializeSession({
			configuratorType: subConfiguratorType
		});

		const initialInput = await SubConfiguratorFormApi.getSubConfigurationInputFromValue({
			configuratorType: subConfiguratorType,
			subConfiguratorValue: initialValue,
			configurationSessionId: configurationSessionId,
		});

		await onInputChanged({
			input: initialInput,
			errors: [],
			configurationSessionId: configurationSessionId,
			refreshSubConfigurationValue: true,
		});

		return {
			configurationSessionId,
			initialInput,
		};
	}
};
