import { PartConfigurationAttributesApi } from "src/api/generated/erp/configurator/api/partConfigurationAttributesApi.ts";
import {
	AttributeFieldWithValue,
	createAttributeFieldWithValue,
} from "src/components/views/erp/objectAttributes/AttributeFieldWithValue.tsx";
import i18n from "i18next";
import {
	attributeValueToUserFriendlyString,
	decodeObjectAttributeValue,
} from "src/components/views/erp/objectAttributes/objectAttributeValueConverting.ts";
import { faPenToSquare } from "@fortawesome/pro-regular-svg-icons";
import { useGenericDialog } from "src/components/common/dialogs/useGenericDialog.ts";
import { AavoButton } from "src/components/common/buttons/AavoButton.tsx";
import { PartConfigurationAttributesForm } from "src/components/views/erp/configurator/inspecting/PartConfigurationAttributesForm.tsx";
import { ClientSideDataGridModel } from "src/components/common/dataGrid/gridModel/ClientSideDataGridModel.tsx";
import { AavoDataGrid } from "src/components/common/dataGrid/AavoDataGrid";

export interface PartConfigurationAttributesDataGridProps {
	partConfigurationId: number;
	editable: boolean;
}

export const PartConfigurationAttributesDataGrid = ({
	partConfigurationId,
	editable,
}: PartConfigurationAttributesDataGridProps) => {
	const { openDialog } = useGenericDialog();

	return (
		<ClientSideDataGridModel
			fetchData={async () => {
				const { attributes, attributeFields } =
					await PartConfigurationAttributesApi.getPartConfigurationAttributes({
						partConfigurationId: partConfigurationId,
					});

				return attributeFields.map((field) =>
					createAttributeFieldWithValue({
						objectType: "PART_CONFIGURATION",
						objectId: partConfigurationId,
						attributeField: field,
						attribute: attributes.find((a) => a.attributeFieldId === field.id),
					}),
				);
			}}
			getRows={(data) => data}
			getRowId={(row) => row.attributeFieldId}
			initialParams={{}}
			gridId="1DB8C278C4F4808E"
			render={({ dataGridProps, data: attributes, refreshData }) => {
				return (
					<AavoDataGrid<AttributeFieldWithValue>
						columns={[
							{
								headerName: i18n.t("attribute"),
								field: "attribute",
								width: 120,
								valueGetter: (_, row) => row.label,
							},
							{
								headerName: i18n.t("value"),
								field: "value",
								width: 200,
								valueGetter: (_, row) =>
									attributeValueToUserFriendlyString(
										decodeObjectAttributeValue(
											row.attributeValue ?? null,
											row.type,
										),
										row.type,
									),
							},
						]}
						actionBarComponents={
							<>
								{editable && (
									<AavoButton icon={faPenToSquare} onClick={openEditDialog} />
								)}
							</>
						}
						onRowDoubleClick={() => {
							openEditDialog();
						}}
						{...dataGridProps}
					/>
				);

				function openEditDialog() {
					if (!editable) return;
					openDialog(({ closeDialog, onContentEdited }) => ({
						title: i18n.t("attributes"),
						size: "lg",
						content: (
							<PartConfigurationAttributesForm
								partConfigurationId={partConfigurationId}
								attributes={attributes}
								onFormEdited={onContentEdited}
								onCompleted={async () => {
									await closeDialog();
									await refreshData();
								}}
							/>
						),
					}));
				}
			}}
		/>
	);
};
