import { ServerSideDataGridModel } from "src/components/common/dataGrid/gridModel/ServerSideDataGridModel.tsx";
import { CrudDataGrid } from "src/components/common/dataGrid/crud/CrudDataGrid.tsx";
import {
	booleanColumn,
	dateColumn,
	dateTimeColumn,
	enumColumn,
	floatColumn,
	integerColumn,
	textColumn,
} from "src/components/common/dataGrid/columns.tsx";
import i18n from "i18next";
import { getCustomerOrderLineStateLabels } from "src/api/generated/erp/db/types/enums/customerOrderLineState.ts";
import { getAcquisitionMethodLabels } from "src/api/generated/erp/db/types/enums/acquisitionMethod.ts";
import { useGlobalInitData } from "src/contexts/useGlobalInitData.ts";
import { SelectSiteField } from "src/components/views/erp/common/sites/SelectSiteField.tsx";
import { useTenantCustomizations } from "src/tenantCustomizations/useTenantCustomizations.tsx";
import { getShopOrderStateLabels } from "src/api/generated/erp/db/types/enums/shopOrderState.ts";
import { getVatHandlingLabels } from "src/api/generated/erp/db/types/enums/vatHandling.ts";
import { AllCustomerOrderLinesDataGridApi } from "src/api/generated/erp/sales/customerOrder/api/allCustomerOrderLinesDataGridApi.ts";
import { getCustomerOrderStateLabels } from "src/api/generated/erp/db/types/enums/customerOrderState.ts";
import { genericNullableValue } from "src/utils/genericNullableValue.ts";
import { CustomerOrderLineExportView } from "src/api/generated/erp/db/types/tables/customerOrderLineExportView.ts";

export const AllCustomerOrderLinesDataGrid = () => {
	const { defaultSiteId } = useGlobalInitData();
	const { tenantConfig } = useTenantCustomizations();
	return (
		<ServerSideDataGridModel
			gridId={"AE51F8EFC22A3217ECEE"}
			fetchData={AllCustomerOrderLinesDataGridApi.getAllCustomerOrderLines}
			initialParams={{
				siteId: genericNullableValue<number>(defaultSiteId),
			}}
			getDataModelResult={(data) => data}
			getRowId={(row) => row.customerOrderLineId}
			render={({ dataGridProps, currentParams, refreshData }) => {
				return (
					<CrudDataGrid<CustomerOrderLineExportView>
						columns={[
							integerColumn({
								field: "customerOrderId",
								headerName: i18n.t("customer_order_id"),
								width: 75,
							}),
							textColumn({
								field: "siteName",
								headerName: i18n.t("site_short"),
								width: 80,
							}),
							textColumn({
								field: "customerName",
								headerName: i18n.t("customer"),
								width: 150,
							}),
							textColumn({
								field: "customerOrderReference",
								headerName: i18n.t("reference"),
								width: 150,
							}),
							textColumn({
								field: "customerPoNo",
								headerName: i18n.t("customer_po_no"),
							}),
							enumColumn({
								field: "customerOrderState",
								headerName: i18n.t("customer_order_state"),
								enumLabels: getCustomerOrderStateLabels(),
							}),
							dateColumn({
								field: "customerOrderPlannedDeliveryDate",
								headerName: i18n.t("order_planned_delivery_date"),
							}),
							textColumn({
								field: "deliveryCity",
								headerName: i18n.t("delivery_city"),
							}),
							textColumn({
								field: "deliveryAddress_1",
								headerName: i18n.t("delivery_address"),
							}),
							textColumn({
								field: "responsiblePersonName",
								headerName: i18n.t("order_responsible_person"),
							}),
							textColumn({
								field: "salespersonName",
								headerName: i18n.t("salesperson"),
							}),
							tenantConfig.erp.customerOrderCheckingEnabled &&
								dateColumn({
									field: "customerOrderCheckedAt",
									headerName: i18n.t("checked_at"),
								}),
							dateColumn({
								field: "customerOrderConfirmedAt",
								headerName: i18n.t("confirmed_at"),
							}),
							tenantConfig.erp.customerOrderFreezingEnabled &&
								dateColumn({
									field: "customerOrderFrozenAt",
									headerName: i18n.t("frozen_at"),
								}),
							textColumn({
								field: "customerContactName",
								headerName: i18n.t("contact_person"),
							}),
							textColumn({
								field: "customerOrderExternalOrderId",
								headerName: i18n.t("external_order_id"),
							}),
							textColumn({
								field: "deliveryTermsCode",
								headerName: i18n.t("delivery_terms"),
							}),
							textColumn({
								field: "deliveryMethodCode",
								headerName: i18n.t("delivery_method"),
							}),
							textColumn({
								field: "deliveryTermsDestination",
								headerName: i18n.t("delivery_terms_destination"),
							}),
							booleanColumn({
								field: "customerOrderCapacityReservation",
								headerName: i18n.t("capacity_reservation"),
							}),
							integerColumn({
								field: "transportDuration",
								headerName: i18n.t("transport_duration"),
							}),
							textColumn({
								field: "transportRouteName",
								headerName: i18n.t("transport_route"),
							}),
							textColumn({
								field: "paymentTermDesc",
								headerName: i18n.t("payment_term"),
							}),
							enumColumn({
								field: "customerOrderVatHandling",
								headerName: i18n.t("vat_handling"),
								enumLabels: getVatHandlingLabels(),
							}),
							booleanColumn({
								field: "customerOrderBillingPlanEnabled",
								headerName: i18n.t("billing_plan"),
							}),
							textColumn({
								field: "customerOrderCreatedByUserName",
								headerName: i18n.t("customer_order_created_by"),
							}),
							dateTimeColumn({
								field: "customerOrderCreatedAt",
								headerName: i18n.t("customer_order_created_at"),
							}),
							textColumn({
								field: "customerOrderTypeName",
								headerName: i18n.t("customer_order_type"),
							}),

							integerColumn({
								field: "lineNumber",
								headerName: i18n.t("line_number_shortened"),
								width: 60,
							}),
							textColumn({
								field: "configurationOrPartDescription",
								headerName: i18n.t("part"),
								width: 250,
							}),
							textColumn({
								field: "mark",
								headerName: i18n.t("mark"),
								width: 200,
							}),
							floatColumn({
								field: "salesQuantityAsSalesUnits",
								headerName: i18n.t("ordered_quantity"),
							}),
							dateColumn({
								field: "customerOrderLinePlannedDeliveryDate",
								headerName: i18n.t("line_planned_delivery_date"),
							}),
							textColumn({
								field: "salesUnit",
								headerName: i18n.t("sales_unit"),
							}),
							floatColumn({
								field: "unitPrice",
								headerName: i18n.t("unit_price"),
								width: 90,
							}),
							floatColumn({
								field: "totalPrice",
								headerName: i18n.t("total_price"),
							}),
							textColumn({
								field: "salesPriceUnit",
								headerName: i18n.t("sales_price_unit"),
							}),
							enumColumn({
								field: "customerOrderLineState",
								headerName: i18n.t("state"),
								enumLabels: getCustomerOrderLineStateLabels(),
							}),
							enumColumn({
								field: "acquisitionMethod",
								headerName: i18n.t("acquisition_method"),
								enumLabels: getAcquisitionMethodLabels(),
								width: 125,
							}),
							textColumn({
								field: "customerOrderLineCapacityQuantity",
								headerName: i18n.t("capacity_quantity"),
								width: 125,
							}),
							floatColumn({
								field: "unitCost",
								headerName: i18n.t("unit_cost"),
								width: 125,
							}),
							floatColumn({
								field: "totalCost",
								headerName: i18n.t("total_cost"),
								width: 135,
							}),
							textColumn({
								field: "partUnit",
								headerName: i18n.t("warehouse_unit"),
								width: 105,
							}),

							integerColumn({
								field: "shopOrderId",
								headerName: i18n.t("shop_order_id"),
								width: 70,
							}),
							enumColumn({
								field: "shopOrderState",
								headerName: i18n.t("state"),
								enumLabels: getShopOrderStateLabels(),
							}),
							textColumn({ field: "shopOrderBatchName", headerName: i18n.t("production_batch") }),
							textColumn({ field: "shopOrderProductionLineName", headerName: i18n.t("production_line") }),
							floatColumn({
								field: "shopOrderCapacityQuantity",
								headerName: i18n.t("shop_order_capacity_quantity"),
							}),
							dateColumn({ field: "shopOrderDemandDate", headerName: i18n.t("shop_order_demand_date") }),
							dateTimeColumn({
								field: "shopOrderPlannedBeginDate",
								headerName: i18n.t("shop_order_planned_begin_date"),
								width: 150,
							}),
							dateTimeColumn({
								field: "shopOrderPlannedEndDate",
								headerName: i18n.t("shop_order_planned_end_date"),
								width: 150,
							}),
							dateTimeColumn({
								field: "shopOrderReleasedAt",
								headerName: i18n.t("shop_order_released_at"),
								width: 150,
							}),
							dateTimeColumn({
								field: "shopOrderActualBeginDate",
								headerName: i18n.t("shop_order_actual_begin_date"),
								width: 200,
							}),
							dateTimeColumn({
								field: "shopOrderActualEndDate",
								headerName: i18n.t("shop_order_actual_end_date"),
								width: 200,
							}),
							floatColumn({
								field: "shopOrderCompletedQuantity",
								headerName: i18n.t("shop_order_completed_quantity"),
								width: 150,
							}),
						]}
						actionBarComponents={
							<>
								<SelectSiteField
									value={currentParams.siteId}
									onChange={async (siteId) => {
										await refreshData({ siteId: siteId });
									}}
								/>
							</>
						}
						{...dataGridProps}
					/>
				);
			}}
		/>
	);
};
