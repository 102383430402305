// This file is automatically generated. Do not edit manually.

import { ShopOrderView } from "src/api/generated/erp/db/types/tables/shopOrderView";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace ShopOrderBatchShopOrdersApi {
	export async function getShopOrderBatchShopOrders(args: {
		shopOrderBatchId: number;
	}): Promise<Array<ShopOrderView>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/shopOrderBatch/getShopOrderBatchShopOrders",
			method: "POST",
			data: {
				shopOrderBatchId: args.shopOrderBatchId,
			},
		});
		return response as Array<ShopOrderView>;
	}

	export async function removeShopOrdersFromBatch(args: {
		shopOrderBatchId: number;
		shopOrderIds: Array<number>;
	}): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/shopOrderBatch/removeShopOrdersFromBatch",
			method: "POST",
			data: {
				shopOrderBatchId: args.shopOrderBatchId,
				shopOrderIds: args.shopOrderIds,
			},
		});
		return response as void;
	}
}
