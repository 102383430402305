import { ConfiguratorTable } from "src/api/generated/erp/db/types/tables/configuratorTable.ts";
import { AavoForm, AavoFormContentParams } from "src/components/common/forms/AavoForm";
import { FormCommonProps } from "src/components/common/forms/types";
import { DeepPartial } from "react-hook-form";
import { ConfiguratorTablesApi } from "src/api/generated/erp/configurator/tables/api/configuratorTablesApi.ts";
import { FormTextField } from "src/components/common/forms/fields/FormTextField.tsx";
import i18n from "i18next";
import { requireRule } from "src/components/common/forms/validation.ts";

export interface ConfiguratorTableFormProps extends FormCommonProps<number> {
	configuratorTable: ConfiguratorTable | undefined;
}

interface FormValues extends ConfiguratorTable {}

export const ConfiguratorTableForm = (props: ConfiguratorTableFormProps) => {
	const { configuratorTable, onCompleted, onFormEdited } = props;

	return (
		<AavoForm
			defaultValues={getDefaultValues()}
			submit={submit}
			onCompleted={onCompleted}
			onFormEdited={onFormEdited}
			render={(contentParams) => <FormContent {...props} {...contentParams} />}
		/>
	);

	function getDefaultValues(): DeepPartial<FormValues> {
		return configuratorTable ?? {};
	}

	async function submit(values: FormValues) {
		if (configuratorTable == null) {
			return await ConfiguratorTablesApi.insert({
				configuratorTable: values,
			});
		} else {
			await ConfiguratorTablesApi.update({
				configuratorTable: values,
			});
			return configuratorTable.configuratorTableId;
		}
	}
};

interface FormContentProps extends ConfiguratorTableFormProps, AavoFormContentParams<FormValues> {}

const FormContent = ({ control }: FormContentProps) => {
	return (
		<>
			<FormTextField control={control} name="name" label={i18n.t("name")} rules={requireRule()} autoFocus />
			<FormTextField control={control} name="description" label={i18n.t("description")} multiline />
		</>
	);
};
