import { ClientSideDataGridModel } from "src/components/common/dataGrid/gridModel/ClientSideDataGridModel.tsx";
import { CustomerAddressDataGridApi } from "src/api/generated/erp/sales/customer/api/customerAddressDataGridApi.ts";
import { CrudDataGrid } from "src/components/common/dataGrid/crud/CrudDataGrid.tsx";
import { booleanColumn, enumColumn, integerColumn, textColumn } from "src/components/common/dataGrid/columns.tsx";
import i18n from "i18next";
import { getCustomerAddressTypeLabels } from "src/api/generated/erp/db/types/enums/customerAddressType.ts";
import { getCountryLabels } from "src/api/generated/erp/db/types/enums/country.ts";
import { CustomerAddressView } from "src/api/generated/erp/db/types/tables/customerAddressView.ts";
import { faCheck, faCopy } from "@fortawesome/pro-regular-svg-icons";
import { CustomerAddressForm } from "src/components/views/erp/sales/customer/CustomerAddressForm.tsx";
import { useGenericDialog } from "src/components/common/dialogs/useGenericDialog.ts";
import { openFormOnDialog } from "src/components/common/dialogs/formDialog/openFormOnDialog.ts";
import { CustomerAddressEditApi } from "src/api/generated/erp/sales/customer/api/customerAddressEditApi.ts";
import { AsyncMenuButton } from "src/components/common/contextMenu/AsyncMenuButton.tsx";

export interface CustomerAddressDataGridProps {
	customerId: number;
}

export const CustomerAddressDataGrid = ({ customerId }: CustomerAddressDataGridProps) => {
	const { openDialog } = useGenericDialog();
	return (
		<ClientSideDataGridModel
			gridId={"418ADEBE6EC3F4D2"}
			fetchData={async () => await CustomerAddressDataGridApi.getCustomerAddresses({ customerId: customerId })}
			getRows={(data) => data}
			getRowId={(row) => row.customerAddressId}
			initialParams={{}}
			render={({ dataGridProps, refreshData, onlySelectedRow }) => (
				<CrudDataGrid<CustomerAddressView>
					disableMultipleRowSelection
					columns={[
						textColumn({
							field: "name",
							headerName: i18n.t("description"),
							width: 200,
						}),
						textColumn({
							field: "address_1",
							headerName: i18n.t("address_1"),
							width: 200,
						}),
						textColumn({
							field: "address_2",
							headerName: i18n.t("address_2"),
						}),
						textColumn({
							field: "postalCode",
							headerName: i18n.t("postal_code"),
						}),
						textColumn({
							field: "city",
							headerName: i18n.t("city"),
						}),
						enumColumn({
							field: "country",
							headerName: i18n.t("country"),
							enumLabels: getCountryLabels(),
						}),
						textColumn({
							field: "contact",
							headerName: i18n.t("contact"),
							width: 200,
						}),
						textColumn({
							field: "phone",
							headerName: i18n.t("phone"),
							width: 200,
						}),
						textColumn({
							field: "email",
							headerName: i18n.t("email"),
							width: 200,
						}),
						enumColumn({
							field: "addressType",
							headerName: i18n.t("type"),
							enumLabels: getCustomerAddressTypeLabels(),
						}),
						textColumn({
							field: "transportRouteName",
							headerName: i18n.t("transport_route"),
							width: 200,
						}),
						booleanColumn({
							field: "isDefault",
							headerName: i18n.t("default"),
						}),
						integerColumn({
							field: "customerAddressId",
							headerName: i18n.t("id"),
						}),
					]}
					rowContextMenuComponents={({ row }) => [
						<AsyncMenuButton
							key={"setDefault"}
							icon={faCheck}
							label={i18n.t("set_default")}
							onClick={async () => {
								await CustomerAddressEditApi.setCustomerAddressDefault({
									customerAddressId: row.customerAddressId,
								});
								await refreshData();
							}}
							disabled={row.isDefault ? i18n.t("is_already_default") : undefined}
						/>,
						<AsyncMenuButton
							key={"copy"}
							icon={faCopy}
							label={i18n.t("copy")}
							onClick={async () => {
								openFormOnDialog({
									onSubmit: refreshData,
									title: i18n.t("new_address"),
									size: "sm",
									openDialog,
									component: CustomerAddressForm,
									props: {
										customerId: customerId,
										customerAddressId: undefined,
										copyCustomerAddressSource: row,
									},
								});
							}}
						/>,
					]}
					form={{
						addRowEnabled: true,
						editEnabled: true,
						dialogSize: "sm",
						dialogTitle: i18n.t("customer_address"),
						component: ({ row, ...other }) => (
							<CustomerAddressForm
								customerId={customerId}
								customerAddressId={row?.customerAddressId}
								{...other}
							/>
						),
					}}
					remove={
						onlySelectedRow?.isDefault === true ?
							{
								type: "disabled",
								tooltip: i18n.t("default_address_cannot_be_deleted"),
							}
						:	({ row }) =>
								CustomerAddressEditApi.delete_({
									customerAddressId: row.customerAddressId,
								})
					}
					{...dataGridProps}
				/>
			)}
		/>
	);
};
