import { Box, Typography } from "@mui/material";
import { alignCenter, columnFlex, flex1, justifyCenter } from "src/styles/sx.ts";
import { mergeSx } from "src/utils/styles.ts";
import i18n from "i18next";
import { LogOutButton } from "src/components/views/login/LogOutButton.tsx";

export const InsufficientPermissionsPage = () => {
	return (
		<Box sx={mergeSx(columnFlex, flex1, justifyCenter, alignCenter)}>
			<Typography color={"primary"} variant={"h5"}>
				{i18n.t("you_have_insufficient_permissions_to_view_this_app")}
			</Typography>
			<LogOutButton showIcon={false} sx={{ mt: 1 }} variant={"contained"} />
		</Box>
	);
};