import { useParameterizedAsyncData } from "src/utils/async/parameterizedAsyncData.ts";
import { ShopOrderBomTreeViewApi } from "src/api/generated/erp/production/api/shopOrderBomTreeViewApi.ts";
import { AsyncRender } from "src/components/common/async/AsyncRender.tsx";
import {
	mapShopOrderBomTreeData,
	ShopOrderBomTreeItemModel,
} from "src/components/views/erp/production/shopOrderBom/treeItemMapping.ts";
import React, { useMemo, useState } from "react";
import { styled } from "@mui/material/styles";
import { TreeItem2, TreeItem2Props } from "@mui/x-tree-view/TreeItem2";
import { useTreeItem2 } from "@mui/x-tree-view/useTreeItem2/useTreeItem2";
import { faAngleDown, faAngleRight } from "@fortawesome/pro-regular-svg-icons";
import { HorizontalBox } from "src/components/common/box/HorizontalBox.tsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Typography } from "@mui/material";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import i18n from "i18next";
import { AavoTextField } from "src/components/common/inputFields/AavoTextField.tsx";
import { AavoTreeView } from "src/components/common/tree/AavoTreeView.tsx";
import { ShopOrderBomTreeViewResult } from "src/api/generated/erp/production/api/shopOrderBomTreeViewResult.ts";
import { flatMapTreeItems } from "src/components/common/tree/AavoTreeView.utils.ts";

export interface ShopOrderBomTreeViewProps {
	shopOrderId: number;
	onSelectionChanged: (selectedItem: ShopOrderBomTreeItemModel | null) => void;
}

export const ShopOrderBomTreeView = ({ shopOrderId, onSelectionChanged }: ShopOrderBomTreeViewProps) => {
	const { dataAsync, refresh, paramValues } = useParameterizedAsyncData<
		ShopOrderBomTreeViewResult,
		ShopOrderBomTreeViewParams
	>({
		fetchData: ({ searchQuery }) =>
			ShopOrderBomTreeViewApi.getTreeElements({
				shopOrderId: shopOrderId,
				searchQuery: searchQuery,
			}),
		initialParams: {
			searchQuery: "",
		},
	});

	return (
		<AsyncRender
			asyncData={dataAsync}
			reloadOnError={() => refresh()}
			content={(data) => (
				<ShopOrderBomTreeViewContent
					data={data}
					paramValues={paramValues}
					refresh={refresh}
					shopOrderId={shopOrderId}
					onSelectionChanged={onSelectionChanged}
				/>
			)}
		/>
	);
};

interface ShopOrderBomTreeViewParams {
	searchQuery: string;
}

interface ShopOrderBomTreeViewContentProps
	extends Pick<ShopOrderBomTreeViewProps, "shopOrderId" | "onSelectionChanged"> {
	data: ShopOrderBomTreeViewResult;
	paramValues: ShopOrderBomTreeViewParams;
	refresh: (params?: Partial<ShopOrderBomTreeViewParams>) => Promise<unknown>;
}

const ShopOrderBomTreeViewContent = (props: ShopOrderBomTreeViewContentProps) => {
	const {
		data: { treeElements },
		onSelectionChanged,
		refresh,
	} = props;

	const [selectedItem, setSelectedItemLocal] = useState<ShopOrderBomTreeItemModel | null>(null);
	const setSelectedItem = (item: ShopOrderBomTreeItemModel | null) => {
		setSelectedItemLocal(item);
		onSelectionChanged?.(item);
	};

	const bomItems = useMemo(() => mapShopOrderBomTreeData(treeElements), [treeElements]);
	const allItems = useMemo(() => flatMapTreeItems(bomItems), [bomItems]);
	const allItemsByIds: Record<string, ShopOrderBomTreeItemModel> = useMemo(
		() => allItems.reduce((acc, item) => ({ ...acc, [item.id]: item }), {}),
		[allItems],
	);

	const [expandedItems, setExpandedItems] = useState<string[]>(allItems.map((item) => item.id));

	return (
		<AavoTreeView<ShopOrderBomTreeItemModel>
			items={bomItems}
			slots={{
				item: CustomTreeItemWithStyles,
			}}
			expandedItems={expandedItems}
			onExpandedItemsChange={(itemsIds) => {
				setExpandedItems(itemsIds);
			}}
			selectedItems={selectedItem?.id ?? null}
			onSelectedItemsChange={(_, itemId) => {
				const item = itemId != null ? allItemsByIds[itemId] : null;
				setSelectedItem(item ?? null);
			}}
			sx={{
				borderBottom: "1px solid",
				borderColor: "divider",
			}}
			actionBarComponents={
				<AavoTextField
					label={i18n.t("search")}
					onSubmit={(searchQuery) => refresh({ searchQuery })}
				/>
			}
		/>
	);
};

const CustomTreeItem = React.memo((props: TreeItem2Props) => {
	const { itemId } = props;
	const { publicAPI } = useTreeItem2(props);
	const item = publicAPI.getItem(itemId) as ShopOrderBomTreeItemModel;

	return (
		<TreeItem2
			{...props}
			slots={{
				label: CustomTreeItemLabel,
				expandIcon: CustomCollapseExpandIcon,
				collapseIcon: CustomCollapseExpandIcon,
			}}
			slotProps={{
				label: {
					item: item,
				} as any,
				content: {
					item: item,
				} as any,
				expandIcon: {
					icon: faAngleRight,
				} as any,
				collapseIcon: {
					icon: faAngleDown,
				} as any,
			}}
		/>
	);
});

const CustomTreeItemWithStyles = styled(CustomTreeItem)(() => ({
	"& .MuiTreeItem-content": {
		paddingLeft: "0.25rem",
		paddingTop: "0.5rem",
		paddingBottom: "0.5rem",
	},
	"& .MuiTreeItem-iconContainer": {
		width: "2rem",
		"& svg": {
			fontSize: "1.2rem",
		},
	},
}));

const CustomTreeItemLabel = ({ item }: { item: ShopOrderBomTreeItemModel }) => {
	return (
		<HorizontalBox
			sx={{
				alignItems: "center",
				gap: 1,
			}}
		>
			<Typography>{item.label}</Typography>
		</HorizontalBox>
	);
};

const CustomCollapseExpandIcon = ({ icon }: { icon: IconDefinition }) => {
	return (
		<HorizontalBox
			sx={{
				marginTop: -1.75,
				marginBottom: -1.75,
				paddingX: 1,
				alignItems: "center",
			}}
		>
			<FontAwesomeIcon icon={icon} />
		</HorizontalBox>
	);
};
