// This file is automatically generated. Do not edit manually.

import { FrontendDownloadFileHandle } from "src/api/generated/common/frontendDownloadFileHandle";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace CustomerOrderInfoViewApi {
	export async function getCustomerOrderInfoViewData(args: {
		customerOrderId: number;
	}): Promise<CustomerOrderInfoViewApi_CustomerOrderInfoViewData> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/customerOrder/getCustomerOrderInfoViewData",
			method: "POST",
			data: {
				customerOrderId: args.customerOrderId,
			},
		});
		return response as CustomerOrderInfoViewApi_CustomerOrderInfoViewData;
	}
}

export interface CustomerOrderInfoViewApi_CustomerOrderInfoViewData {
	mainDocument: CustomerOrderInfoViewApi_CustomerOrderInfoViewData_MainDocument | null | undefined;
}

export interface CustomerOrderInfoViewApi_CustomerOrderInfoViewData_MainDocument {
	description: string;
	documentId: number;
	fileHandle: FrontendDownloadFileHandle;
}
