// This file is automatically generated. Do not edit manually.

import { ServerSideDataModelRequest } from "src/api/generated/common/dataGrids/serverSideDataModelRequest";
import { ControlChart } from "src/api/generated/postgres/db/types/public_/tables/controlChart";
import { ServerSideDataModelResult } from "src/api/generated/common/dataGrids/serverSideDataModelResult";
import { IsoDateTimeString } from "src/types/dateTime";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace ControlChartRecordsDataGridApi {
	export async function getGridData(args: {
		controlChartId: number;
		dataModelRequest: ServerSideDataModelRequest;
	}): Promise<ControlChartRecordsDataGridApi_DataGridData> {
		const response = await makeAxiosRequest({
			url: "/api/ext/spc/controlChartRecordsDataGrid/getGridData",
			method: "POST",
			data: {
				controlChartId: args.controlChartId,
				dataModelRequest: args.dataModelRequest,
			},
		});
		return response as ControlChartRecordsDataGridApi_DataGridData;
	}

	export async function update(args: {
		rows: Array<ControlChartRecordsDataGridApi_Row>;
	}): Promise<Array<ControlChartRecordsDataGridApi_Row>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/spc/controlChartRecordsDataGrid/update",
			method: "POST",
			data: {
				rows: args.rows,
			},
		});
		return response as Array<ControlChartRecordsDataGridApi_Row>;
	}
}

export interface ControlChartRecordsDataGridApi_DataGridData {
	controlChart: ControlChart;
	dataModelResult: ServerSideDataModelResult<ControlChartRecordsDataGridApi_Row>;
}

export interface ControlChartRecordsDataGridApi_Row {
	controlChartId: number;
	enabled: boolean;
	endTime: IsoDateTimeString | null | undefined;
	eventOcs: string;
	info1: string;
	info2: string;
	observationTime: IsoDateTimeString;
	primaryValue: number;
	recordId: number;
	specialCauseCode: number | null | undefined;
	startTime: IsoDateTimeString | null | undefined;
	subValues: Array<number>;
}
