// This file is automatically generated. Do not edit manually.

import { ProjectActivityView } from "src/api/generated/erp/db/types/tables/projectActivityView";
import { SubProjectView } from "src/api/generated/erp/db/types/tables/subProjectView";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace ProjectActivityDataGridApi {
	export async function getDataGridData(args: {
		subProjectId: number;
		onlyActivityId: number | null | undefined;
		cancelledActivities: boolean;
	}): Promise<ProjectActivityDataGridApi_DataGridData> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/project/activityDataGrid/getDataGridData",
			method: "POST",
			data: {
				subProjectId: args.subProjectId,
				onlyActivityId: args.onlyActivityId,
				cancelledActivities: args.cancelledActivities,
			},
		});
		return response as ProjectActivityDataGridApi_DataGridData;
	}
}

export interface ProjectActivityDataGridApi_DataGridData {
	activities: Array<ProjectActivityView>;
	subProject: SubProjectView;
}
