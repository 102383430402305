// This file is automatically generated. Do not edit manually.

import { CustomerAddress } from "src/api/generated/erp/db/types/tables/customerAddress";
import { TransportRoute } from "src/api/generated/erp/db/types/tables/transportRoute";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace CustomerAddressEditApi {
	export async function getFormInitData(args: {
		customerAddressId: number | null | undefined;
	}): Promise<CustomerAddressEditApi_InitData> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/customer/address/getFormInitData",
			method: "POST",
			data: {
				customerAddressId: args.customerAddressId,
			},
		});
		return response as CustomerAddressEditApi_InitData;
	}

	export async function insert(args: { customerAddress: CustomerAddress }): Promise<number> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/customer/address/insert",
			method: "POST",
			data: {
				customerAddress: args.customerAddress,
			},
		});
		return response as number;
	}

	export async function update(args: { customerAddress: CustomerAddress }): Promise<number> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/customer/address/update",
			method: "POST",
			data: {
				customerAddress: args.customerAddress,
			},
		});
		return response as number;
	}

	export async function setCustomerAddressDefault(args: { customerAddressId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/customer/address/setCustomerAddressDefault",
			method: "POST",
			data: {
				customerAddressId: args.customerAddressId,
			},
		});
		return response as void;
	}

	export async function delete_(args: { customerAddressId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/customer/address/delete",
			method: "POST",
			data: {
				customerAddressId: args.customerAddressId,
			},
		});
		return response as void;
	}
}

export interface CustomerAddressEditApi_InitData {
	customerAddress: CustomerAddress | null | undefined;
	transportRouteOptions: Array<TransportRoute>;
}
