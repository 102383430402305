// This file is automatically generated. Do not edit manually.

import { FrontendDownloadFileHandle } from "src/api/generated/common/frontendDownloadFileHandle";
import { IsoDateString } from "src/types/dateTime";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace InvoiceOperationApi {
	export async function printInvoice(args: { invoiceId: number }): Promise<FrontendDownloadFileHandle> {
		const response = await makeAxiosRequest({
			url: "/api/ext/invoicing/printInvoice",
			method: "POST",
			data: {
				invoiceId: args.invoiceId,
			},
		});
		return response as FrontendDownloadFileHandle;
	}

	export async function printInvoicingReport(args: {
		fromDate: IsoDateString;
		toDate: IsoDateString;
	}): Promise<FrontendDownloadFileHandle> {
		const response = await makeAxiosRequest({
			url: "/api/ext/invoicing/printInvoicingReport",
			method: "POST",
			data: {
				fromDate: args.fromDate,
				toDate: args.toDate,
			},
		});
		return response as FrontendDownloadFileHandle;
	}

	export async function copyInvoicesToLedger(args: { invoiceIds: Array<number> }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/invoicing/copyInvoicesToLedger",
			method: "POST",
			data: {
				invoiceIds: args.invoiceIds,
			},
		});
		return response as void;
	}

	export async function markInvoicesAsCopiedToLedger(args: { invoiceIds: Array<number> }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/invoicing/markInvoicesAsCopiedToLedger",
			method: "POST",
			data: {
				invoiceIds: args.invoiceIds,
			},
		});
		return response as void;
	}
}
