import {FormCommonProps} from "src/components/common/forms/types.ts";
import {ControlChartEditApi} from "src/api/generated/spc/controlChart/api/controlChartEditApi.ts";
import {FormSection} from "src/components/common/forms/FormSection.tsx";
import i18n from "i18next";
import {FormCheckbox} from "src/components/common/forms/fields/FormCheckbox.tsx";
import {ControlChart} from "src/api/generated/postgres/db/types/public_/tables/controlChart.ts";
import {AsyncForm} from "src/components/common/forms/AsyncForm.tsx";
import {ControlChartCommonApi} from "src/api/generated/spc/controlChart/api/controlChartCommonApi.ts";

export interface ControlChartSpecialCauseTestsFormProps extends FormCommonProps<number> {
	controlChartId: number;
}

export const ControlChartSpecialCauseTestsForm = ({
	controlChartId,
	onFormEdited,
	onCompleted,
}: ControlChartSpecialCauseTestsFormProps) => {
	const xTestIndexList: Array<1 | 2 | 3 | 4 | 5 | 6 | 7 | 8> = [1, 2, 3, 4, 5, 6, 7, 8];
	const rTestIndexList: Array<1 | 2 | 3 | 4> = [1, 2, 3, 4];
	return (
		<AsyncForm<ControlChart, ControlChart, number>
			fetch={async () => ControlChartCommonApi.getControlChart({ controlChartId: controlChartId })}
			getDefaultValues={(controlChart) => controlChart}
			render={({ control }) => (
				<>
					<FormSection
						label={i18n.t("x_bar_chart")}
						sx={{
							gap: "0.15rem",
						}}
					>
						{xTestIndexList.map((xTestIndex) => (
							<FormCheckbox
								control={control}
								name={`scTestX_${xTestIndex}Enabled`}
								label={i18n.t(`spc_sc_test_${xTestIndex}_description`)}
							/>
						))}
					</FormSection>
					<FormSection
						label={i18n.t("deviation_chart")}
						sx={{
							gap: "0.15rem",
						}}
					>
						{rTestIndexList.map((rTestIndex) => (
							<FormCheckbox
								control={control}
								name={`scTestR_${rTestIndex}Enabled`}
								label={i18n.t(`spc_sc_test_${rTestIndex}_description`)}
							/>
						))}
					</FormSection>
				</>
			)}
			submit={async (values) => await ControlChartEditApi.update({ controlChart: values })}
			onCompleted={onCompleted}
			onFormEdited={onFormEdited}
		/>
	);
};
