// This file is automatically generated. Do not edit manually.

import { ConfigurableObjectType } from "src/api/generated/erp/db/types/enums/configurableObjectType";
import { ObjectConfigurationHistoryView } from "src/api/generated/erp/db/types/tables/objectConfigurationHistoryView";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace ObjectConfigurationHistoryApi {
	export async function getObjectConfigurationHistory(args: {
		objectType: ConfigurableObjectType;
		objectId: number;
	}): Promise<Array<ObjectConfigurationHistoryView>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/configurator/objectConfigurationHistory/getObjectConfigurationHistory",
			method: "POST",
			data: {
				objectType: args.objectType,
				objectId: args.objectId,
			},
		});
		return response as Array<ObjectConfigurationHistoryView>;
	}

	export async function getObjectConfigurationHistoryHasAnyEntry(args: {
		objectType: ConfigurableObjectType;
		objectId: number;
	}): Promise<boolean> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/configurator/objectConfigurationHistory/getObjectConfigurationHistoryHasAnyEntry",
			method: "POST",
			data: {
				objectType: args.objectType,
				objectId: args.objectId,
			},
		});
		return response as boolean;
	}

	export async function restoreObjectConfigurationFromHistory(args: {
		objectConfigurationHistoryEntryId: number;
	}): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/configurator/objectConfigurationHistory/restoreObjectConfigurationFromHistory",
			method: "POST",
			data: {
				objectConfigurationHistoryEntryId: args.objectConfigurationHistoryEntryId,
			},
		});
		return response as void;
	}
}
