import { VerticalBox } from "src/components/common/box/VerticalBox";
import { ObjectAttributesForm } from "src/components/views/erp/objectAttributes/ObjectAttributesForm.tsx";
import { CatalogPartAttributesApi } from "src/api/generated/erp/parts/catalogPart/api/catalogPartAttributesApi.ts";

export interface CatalogPartAttributesFormProps {
	catalogPartId: number;
}

export const CatalogPartAttributesForm = ({ catalogPartId }: CatalogPartAttributesFormProps) => {
	return (
		<VerticalBox flex={1}>
			<ObjectAttributesForm
				fetchAttrs={() =>
					CatalogPartAttributesApi.getCatalogPartAttributes({
						catalogPartId: catalogPartId,
					})
				}
				saveAttrs={(attrs) =>
					CatalogPartAttributesApi.saveCatalogPartAttributes({
						catalogPartId,
						attributes: attrs,
					})
				}
			/>
		</VerticalBox>
	);
};
