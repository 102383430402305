// This file is automatically generated. Do not edit manually.

import { t } from "i18next";

export const SalespersonPaymentMethodValues = ["SALARY", "INVOICE"] as const;

export type SalespersonPaymentMethod = (typeof SalespersonPaymentMethodValues)[number];

export const getSalespersonPaymentMethodLabels = () => ({
	SALARY: t("salary"),
	INVOICE: t("invoice"),
});

export const getSalespersonPaymentMethodLabel = (value: SalespersonPaymentMethod): string => {
	return getSalespersonPaymentMethodLabels()[value];
};
