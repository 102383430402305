import { useRef } from "react";
import { useFileInputDnd } from "src/components/common/inputFields/fileInput/useFileInputDnd.tsx";
import { GridRow, GridRowModel, GridSlotProps } from "@mui/x-data-grid-pro";

export type GridRowWithFileDroppingProps = GridSlotProps["row"] & {
	canDrop: boolean;
	onFilesDropped: (row: GridRowModel, files: File[]) => void;
};

export const GridRowWithFileDropping = ({
	onFilesDropped,
	canDrop,
	style,
	row,
	...other
}: GridRowWithFileDroppingProps) => {
	const ref = useRef(null);

	const { isDraggingOver } = useFileInputDnd({
		ref,
		canDrop: canDrop,
		onDrop: (files) => onFilesDropped(row, files),
	});

	return (
		<GridRow
			ref={ref}
			style={{
				...style,
				backgroundColor: isDraggingOver && canDrop ? "lightgray" : undefined,
			}}
			row={row}
			{...other}
		/>
	);
};
