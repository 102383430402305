import {StackedIcon, StackedIconProps} from "src/components/common/icons/StackedIcon.tsx";
import {faArrowDown, faMapPin} from "@fortawesome/pro-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export interface MapPinWithArrowDownIconProps extends StackedIconProps {}

export const MapPinWithArrowDownIcon = ({ ...other }: MapPinWithArrowDownIconProps) => {
	return (
		<StackedIcon {...other}>
			<FontAwesomeIcon
				icon={faMapPin}
				transform={{
					size: 14,
					x: -2,
					y: 1,
				}}
			/>
			<FontAwesomeIcon
				icon={faArrowDown}
				transform={{
					size: 11,
					x: +6,
					y: 3,
				}}
			/>
		</StackedIcon>
	);
};
