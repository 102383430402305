import { Primitive } from "src/types/primitive";
import { SelectField, SelectFieldProps } from "src/components/common/inputFields/SelectField";
import { ConditionalNullable } from "../../inputFields/types";
import { FieldPath, FieldPathValue, useController, UseControllerProps } from "react-hook-form";
import { FormFieldLayoutProps, formFieldLayoutPropsToSx } from "src/components/common/forms/styles.ts";
import { mergeSx } from "src/utils/styles.ts";

export interface FormSelectFieldProps<
	TFieldValues extends object,
	TFieldName extends FieldPath<TFieldValues>,
	Key extends Primitive,
	TOption,
	DisableClearable extends boolean | undefined,
> extends Omit<SelectFieldProps<TOption, Key, DisableClearable>, "value" | "name">,
		Pick<UseControllerProps<TFieldValues, TFieldName>, "control" | "name" | "rules">,
		FormFieldLayoutProps {
	formValueType?: "key" | "option";
}

export const FormSelectField = <
	TFieldValues extends object,
	TFieldName extends FieldPath<TFieldValues>,
	Key extends Primitive,
	TOption,
	DisableClearable extends boolean | undefined,
>({
	control,
	name,
	rules,
	onChange,
	spanGridColumns,
	startNewGridRow,
	sx,
	formValueType = "key",
	disabled,
	onBlur,
	getOptionKey,
	...other
}: FormSelectFieldProps<TFieldValues, TFieldName, Key, TOption, DisableClearable>) => {
	const { field, fieldState } = useController({
		name,
		control,
		rules,
		defaultValue: null as FieldPathValue<TFieldValues, TFieldName>,
	});

	return (
		<SelectField<TOption, Key, DisableClearable>
			error={fieldState.error?.message}
			sx={mergeSx(
				formFieldLayoutPropsToSx({
					spanGridColumns,
					startNewGridRow,
				}),
				sx,
			)}
			value={
				field.value == null ? null
				: formValueType === "key" ?
					(field.value as ConditionalNullable<Key, DisableClearable>)
				:	(getOptionKey(field.value) as ConditionalNullable<Key, DisableClearable>)
			}
			onChange={(key: Key | null, option: TOption | null) => {
				field.onChange(formValueType === "key" ? key : option);
				onChange?.(
					key as ConditionalNullable<Key, DisableClearable>,
					option as ConditionalNullable<TOption, DisableClearable>,
				);
			}}
			onBlur={(e) => {
				field.onBlur();
				onBlur?.(e);
			}}
			disabled={disabled || field.disabled}
			getOptionKey={(option) => getOptionKey(option)}
			{...other}
		/>
	);
};
