import React, { useEffect } from "react";

export const useResizeObserver = (
	elementRef: React.RefObject<Element>,
	onResize: () => void,
	deps: any[] = [],
) => {
	useEffect(() => {
		if (!elementRef.current) return;

		const resizeObserver = new ResizeObserver(() => {
			onResize();
		});
		resizeObserver.observe(elementRef.current);

		return () => resizeObserver.disconnect();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, deps);
};
