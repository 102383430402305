// This file is automatically generated. Do not edit manually.

import { t } from "i18next";

export const ResourceStateValues = ["SETUP", "RUNNING", "PAUSED", "MAINTENANCE", "INTERRUPTED"] as const;

export type ResourceState = (typeof ResourceStateValues)[number];

export const getResourceStateLabels = () => ({
	SETUP: t("setup"),
	RUNNING: t("running"),
	PAUSED: t("paused"),
	MAINTENANCE: t("maintenance"),
	INTERRUPTED: t("interrupted"),
});

export const getResourceStateLabel = (value: ResourceState): string => {
	return getResourceStateLabels()[value];
};
