import { CustomerOrderView } from "src/api/generated/erp/db/types/tables/customerOrderView.ts";
import { DeliverySourcePickingView } from "src/components/views/erp/delivery/deliveryPackage/picking/DeliverySourcePickingView.tsx";
import { ReservableCustomerOrderLinesDataGrid } from "src/components/views/erp/sales/delivery/picking/ReservableCustomerOrderLinesDataGrid.tsx";
import i18n from "i18next";
import { CustomerOrderLineReservationFormApi } from "src/api/generated/erp/sales/delivery/api/customerOrderLineReservationFormApi.ts";

export interface CustomerOrderPickingViewProps {
	customerOrder: CustomerOrderView;
}

export const CustomerOrderPickingView = ({ customerOrder }: CustomerOrderPickingViewProps) => {
	return (
		<DeliverySourcePickingView
			deliverySource={{
				type: "customerOrder",
				customerOrderId: customerOrder.customerOrderId,
			}}
			deliverySourceHasAutomaticReservation={customerOrder.automaticReservation}
			reservationView={{
				type: "panel",
				title: i18n.t("reservable_lines"),
				hidden: customerOrder.automaticReservation,
				content: <ReservableCustomerOrderLinesDataGrid customerOrderId={customerOrder.customerOrderId} />,
			}}
			revertReservation={async ({ pickingObjectLocation, quantityToRevertInBaseUnit }) => {
				await CustomerOrderLineReservationFormApi.createReservations({
					reservationRequests: [
						{
							customerOrderLineId: pickingObjectLocation.objectId,
							partWarehouseLocationId: pickingObjectLocation.partWarehouseLocationId,
							quantityInWarehouseUnit: -quantityToRevertInBaseUnit,
						},
					],
				});
			}}
		/>
	);
};
