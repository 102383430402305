// This file is automatically generated. Do not edit manually.

import { ConfiguratorTableTab } from "src/api/generated/erp/db/types/tables/configuratorTableTab";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace ConfiguratorTableTabsApi {
	export async function getRevisionTabs(args: {
		configuratorTableRevisionId: number;
	}): Promise<Array<ConfiguratorTableTab>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/configurator/tables/tabs/getRevisionTabs",
			method: "POST",
			data: {
				configuratorTableRevisionId: args.configuratorTableRevisionId,
			},
		});
		return response as Array<ConfiguratorTableTab>;
	}

	export async function insert(args: { configuratorTableRevisionId: number; tabName: string }): Promise<number> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/configurator/tables/tabs/insert",
			method: "POST",
			data: {
				configuratorTableRevisionId: args.configuratorTableRevisionId,
				tabName: args.tabName,
			},
		});
		return response as number;
	}

	export async function update(args: { configuratorTableTab: ConfiguratorTableTab }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/configurator/tables/tabs/update",
			method: "POST",
			data: {
				configuratorTableTab: args.configuratorTableTab,
			},
		});
		return response as void;
	}

	export async function delete_(args: { configuratorTableTabId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/configurator/tables/tabs/delete",
			method: "POST",
			data: {
				configuratorTableTabId: args.configuratorTableTabId,
			},
		});
		return response as void;
	}
}
