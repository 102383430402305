import { makeAxiosRequest } from "./utils/apiClient";

export interface UserData {
	email: string;
	tenant: string;
	principalId: string;
	isAssociatedToTenant: boolean;
	mfaIsEnabled: boolean;
	useNfc: boolean;
	changingPasswordIsPossible: boolean;
}

export namespace CurrentUserApi {
	export const logOut = async (): Promise<void> => {
		return await makeAxiosRequest({
			url: "/api/user/logout",
			method: "POST",
		});
	};

	export const resetPassword = async (email: string): Promise<void> => {
		return await makeAxiosRequest({
			url: "/api/user/resetPasswordTicket",
			method: "POST",
			params: {
				email: email,
			},
		});
	};

	export const resetMfa = async (): Promise<void> => {
		return await makeAxiosRequest({
			url: "/api/user/resetMfa",
			method: "POST",
		});
	};

	export const getUserData = async (): Promise<UserData> => {
		return await makeAxiosRequest<UserData>({
			url: "/api/user/userData",
			method: "GET",
		});
	};
}
