import {
	SurveyFormPermissionApi,
	SurveyFormPermissionApi_FormInitData,
} from "src/api/generated/erp/surveys/api/surveyFormPermissionApi.ts";
import { AsyncForm, AsyncFormContentParams } from "src/components/common/forms/AsyncForm.tsx";
import { FormCommonProps } from "src/components/common/forms/types.ts";
import { ObjectPermission } from "src/api/generated/iam/model/objectPermission.ts";
import { getObjectMemberTypeLabels, ObjectMemberType } from "src/api/generated/iam/model/objectMemberType.ts";
import { getObjectPermissionId } from "src/components/views/erp/surveys/management/permissions/ObjectPermissionId.ts";
import { FormEnumSelectField } from "src/components/common/forms/fields/FormEnumSelectField.tsx";
import { FormCheckbox } from "src/components/common/forms/fields/FormCheckbox.tsx";
import { FormSelectField } from "src/components/common/forms/fields/FormSelectField.tsx";
import { requireRule } from "src/components/common/forms/validation.ts";
import i18n from "i18next";
import { DeepPartial } from "react-hook-form";

export interface SurveyFormPermissionFormProps extends FormCommonProps<string> {
	surveyFormId: number;
	objectPermission: ObjectPermission | undefined;
}

interface FormValues {
	memberType: ObjectMemberType;
	roleId: number;
	userId: number;
	allowChecking: boolean;
	allowApproving: boolean;
}

export const SurveyFormPermissionForm = (props: SurveyFormPermissionFormProps) => {
	const { surveyFormId, objectPermission, onCompleted, onFormEdited } = props;
	return (
		<AsyncForm
			fetch={() => SurveyFormPermissionApi.getFormInitData()}
			getDefaultValues={getDefaultValues}
			submit={submit}
			onCompleted={onCompleted}
			onFormEdited={onFormEdited}
			render={(contentParams) => <FormContent {...props} {...contentParams} />}
		/>
	);

	function getDefaultValues({}: SurveyFormPermissionApi_FormInitData): DeepPartial<FormValues> {
		if (objectPermission != null) {
			return {
				memberType: objectPermission.memberType,
				roleId: objectPermission.memberType === "ROLE" ? objectPermission.memberId : 0,
				userId: objectPermission.memberType === "USER" ? objectPermission.memberId : 0,
				allowChecking: objectPermission.actions.includes("SURVEY_CHECK"),
				allowApproving: objectPermission.actions.includes("SURVEY_APPROVE"),
			};
		} else {
			return {
				memberType: "ROLE",
				allowChecking: true,
				allowApproving: true,
			};
		}
	}

	async function submit(values: FormValues) {
		const memberId = values.memberType === "ROLE" ? values.roleId : values.userId;
		await SurveyFormPermissionApi.savePermission({
			surveyFormId: surveyFormId,
			memberType: values.memberType,
			memberId: memberId,
			allowChecking: values.allowChecking,
			allowApproving: values.allowApproving,
		});
		return getObjectPermissionId({ memberType: values.memberType, memberId: memberId });
	}
};

interface FormContentProps
	extends SurveyFormPermissionFormProps,
		AsyncFormContentParams<SurveyFormPermissionApi_FormInitData, FormValues> {}

const FormContent = ({ control, watch, data: { userOptions, roleOptions } }: FormContentProps) => {
	const memberType = watch("memberType");
	return (
		<>
			<FormEnumSelectField
				control={control}
				name={"memberType"}
				label={i18n.t("type")}
				rules={requireRule()}
				options={getObjectMemberTypeLabels()}
			/>
			{memberType === "ROLE" && (
				<FormSelectField
					control={control}
					name={"roleId"}
					label={i18n.t("role")}
					options={roleOptions}
					getOptionKey={(option) => option.id}
					getOptionLabel={(option) => option.name}
					rules={requireRule()}
				/>
			)}
			{memberType === "USER" && (
				<FormSelectField
					control={control}
					name={"userId"}
					label={i18n.t("user")}
					options={userOptions}
					getOptionKey={(option) => option.id}
					getOptionLabel={(option) => option.name}
					rules={requireRule()}
				/>
			)}
			<FormCheckbox control={control} name={"allowChecking"} label={i18n.t("allow_checking")} />
			<FormCheckbox control={control} name={"allowApproving"} label={i18n.t("allow_approving")} />
		</>
	);
};
