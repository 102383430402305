import {
    ProjectActivityJobQueueBaseDataGrid
} from "src/components/views/erp/project/activityJobQueue/ProjectActivityJobQueueBaseDataGrid.tsx";
import {
    ProjectActivityJobQueueApi
} from "src/api/generated/erp/project/projectActivity/api/projectActivityJobQueueApi.ts";
import {AsyncButton} from "src/components/common/buttons/AsyncButton.tsx";
import i18n from "i18next";
import {faCheck, faKey, faUndo} from "@fortawesome/pro-regular-svg-icons";
import {
    ProjectActivityJobQueueActionsApi
} from "src/api/generated/erp/project/projectActivity/api/projectActivityJobQueueActionsApi.ts";
import {useContextOrThrow} from "src/utils/useContextOrThrow.tsx";
import {
    ProjectActivityJobQueueViewContext
} from "src/components/views/erp/project/activityJobQueue/ProjectActivityJobQueueViewContext.ts";
import {useConfirmDialog} from "src/components/common/dialogs/confirmDialog/useConfirmDialog.ts";
import {
    ProjectActivityCustomerOrderView
} from "src/api/generated/erp/db/types/tables/projectActivityCustomerOrderView.ts";
import {useInputDialog} from "src/components/common/dialogs/input/useInputDialog.tsx";
import {useAavoToast} from "src/utils/toast.ts";
import {ProjectJobQueueActions} from "src/components/views/erp/project/activityJobQueue/projectJobQueueActions.ts";
import {useUserPermissions} from "src/components/views/erp/common/userPermissions.ts";

export interface ProjectActivityJobQueueInProgressDataGridProps {
	onSelectionChanged: (rows: ProjectActivityCustomerOrderView[]) => void;
}

export const ProjectActivityJobQueueInProgressDataGrid = ({
	onSelectionChanged,
}: ProjectActivityJobQueueInProgressDataGridProps) => {
	const { initialActivitiesRefreshRef, inProgressActivitiesRefreshRef, completedActivitiesRefreshRef } =
		useContextOrThrow(ProjectActivityJobQueueViewContext);
	const showConfirmDialog = useConfirmDialog();
	const showInputDialog = useInputDialog();
	const showToast = useAavoToast();
	const userPermissions = useUserPermissions();

	return (
		<ProjectActivityJobQueueBaseDataGrid
			refreshRef={inProgressActivitiesRefreshRef}
			fetchData={ProjectActivityJobQueueApi.getActivitiesInProgress}
			subProjectTypeFiltering
			resourceFiltering
			onlyActivitiesStartedByMeFiltering
			showResponsiblePersonColumn
			gridId={"FE46DD346B0B58A0"}
			onSelectionChanged={onSelectionChanged}
			showActualStartDate={true}
			actionBarComponents={({ selectedRows, onlySelectedRow }) => (
				<>
					<AsyncButton
						label={i18n.t("revert_start")}
						icon={faUndo}
						disabled={selectedRows.length === 0}
						variant={"outlined"}
						onClick={async () => {
							await ProjectJobQueueActions.revertStartActivities(
								selectedRows,
								showConfirmDialog,
							);
							await Promise.all([
								inProgressActivitiesRefreshRef.refresh(),
								initialActivitiesRefreshRef.refresh(),
							]);
						}}
					/>
					<AsyncButton
						label={i18n.t("completed")}
						icon={faCheck}
						disabled={selectedRows.length === 0}
						variant={"outlined"}
						onClick={async () => {
							await ProjectJobQueueActions.completeActivities(selectedRows, showConfirmDialog);
							await Promise.all([
								inProgressActivitiesRefreshRef.refresh(),
								completedActivitiesRefreshRef.refresh(),
							]);
						}}
					/>
					{!userPermissions.project.isExternalProjectUser && (
						<AsyncButton
							label={i18n.t("start_sub_project")}
							icon={faKey}
							disabled={!onlySelectedRow}
							variant={"outlined"}
							onClick={() => startSubProject(onlySelectedRow!)}
						/>
					)}
				</>
			)}
		/>
	);

	async function startSubProject(activity: ProjectActivityCustomerOrderView) {
		const subProjectOptions = await ProjectActivityJobQueueActionsApi.getProjectInitialSubProjects({
			projectId: activity.projectId,
		});
		const subProjectId = await showInputDialog({
			type: "singleNumberSelect",
			title: i18n.t("start_sub_project"),
			fieldLabel: i18n.t("select_a_sub_project"),
			defaultValue: null,
			required: true,
			fieldProps: {
				selection: {
					options: subProjectOptions.map((subProject) => ({
						value: subProject.subProjectId,
						label: subProject.subProjectDescription,
					})),
				},
			},
		});
		if (subProjectId === undefined) return;

		await ProjectActivityJobQueueActionsApi.startSubProject({
			subProjectId,
		});

		showToast("", i18n.t("sub_project_started"));
	}
};
