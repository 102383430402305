// This file is automatically generated. Do not edit manually.

import { t } from "i18next";

export const SalesCommissionPaymentStateValues = ["INITIAL", "APPROVED"] as const;

export type SalesCommissionPaymentState = (typeof SalesCommissionPaymentStateValues)[number];

export const getSalesCommissionPaymentStateLabels = () => ({
	INITIAL: t("initial"),
	APPROVED: t("approved"),
});

export const getSalesCommissionPaymentStateLabel = (value: SalesCommissionPaymentState): string => {
	return getSalesCommissionPaymentStateLabels()[value];
};
