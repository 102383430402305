import { ErrorText } from "src/components/common/errors/ErrorText";
import { AavoDataGrid, AavoDataGridProps } from "./AavoDataGrid";
import { AsyncState } from "src/utils/async/asyncState";

export interface ControlledAsyncDataGridProps<RowData extends object>
	extends Omit<AavoDataGridProps<RowData>, "rows" | "loading" | "refreshData"> {
	refreshData: () => Promise<unknown>;
	rowsAsync: AsyncState<RowData[]>;
	hideRefreshButton?: boolean;
}

export const ControlledAsyncDataGrid = <RowData extends object>({
	rowsAsync,
	refreshData,
	hideRefreshButton = false,
	...other
}: ControlledAsyncDataGridProps<RowData>) => {
	if (rowsAsync.error) return <ErrorText error={rowsAsync.error} reload={refreshData} />;

	return (
		<AavoDataGrid
			rows={rowsAsync.data ?? []}
			loading={rowsAsync.loading}
			refreshData={hideRefreshButton ? undefined : refreshData}
			{...other}
		/>
	);
};
