import { ClientSideDataGridModel } from "src/components/common/dataGrid/gridModel/ClientSideDataGridModel";
import { ConfiguratorTable } from "src/api/generated/erp/db/types/tables/configuratorTable.ts";
import { ConfiguratorTablesApi } from "src/api/generated/erp/configurator/tables/api/configuratorTablesApi.ts";
import { CrudDataGrid } from "src/components/common/dataGrid/crud/CrudDataGrid.tsx";
import { integerColumn, textColumn } from "src/components/common/dataGrid/columns.tsx";
import i18n from "i18next";
import { AavoTextField } from "src/components/common/inputFields/AavoTextField.tsx";
import { ConfiguratorTableForm } from "src/components/views/erp/configurator/tables/ConfiguratorTableForm.tsx";

export interface ConfiguratorTablesDataGridProps {
	onSelectionChanged?: (selectedRow: ConfiguratorTable | undefined) => void;
}

export const ConfiguratorTablesDataGrid = ({ onSelectionChanged }: ConfiguratorTablesDataGridProps) => {
	return (
		<ClientSideDataGridModel
			fetchData={(params) => ConfiguratorTablesApi.getConfiguratorTables(params)}
			initialParams={{
				searchQuery: "",
			}}
			getRows={(response) => response}
			getRowId={(row) => row.configuratorTableId}
			gridId={"4632DC40B4FF7312"}
			onSelectionChanged={(rows) => onSelectionChanged?.(rows[0])}
			render={({ dataGridProps, refreshData }) => (
				<CrudDataGrid<ConfiguratorTable>
					columns={[
						textColumn({
							field: "name",
							headerName: i18n.t("name"),
							width: 200,
						}),
						textColumn({
							field: "description",
							headerName: i18n.t("description"),
							width: 300,
						}),
						integerColumn({
							field: "configuratorTableId",
							headerName: i18n.t("id"),
						}),
					]}
					actionBarComponents={
						<>
							<AavoTextField
								label={i18n.t("search")}
								onSubmit={(searchQuery) => refreshData({ searchQuery })}
							/>
						</>
					}
					form={{
						addRowEnabled: true,
						editEnabled: true,
						dialogTitle: i18n.t("table"),
						dialogSize: "sm",
						component: ({ row, onCompleted, onFormEdited }) => (
							<ConfiguratorTableForm
								configuratorTable={row}
								onCompleted={onCompleted}
								onFormEdited={onFormEdited}
							/>
						),
					}}
					remove={({ row }) => ConfiguratorTablesApi.delete_({
						configuratorTableId: row.configuratorTableId,
					})}
					{...dataGridProps}
				/>
			)}
		/>
	);
};
