export const SUPPORTED_LANGUAGES = ["en", "fi"] as const;

type SupportedLanguageTuple = typeof SUPPORTED_LANGUAGES;
export type SupportedLanguage = SupportedLanguageTuple[number];

export const isSupportedLanguage = (
	lang: string | undefined
): lang is SupportedLanguage => {
	return lang !== undefined
		? SUPPORTED_LANGUAGES.includes(lang as any)
		: false;
};
