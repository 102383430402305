// This file is automatically generated. Do not edit manually.

import { BillingPlanLineSumInput } from "src/api/generated/erp/sales/billingPlan/base/service/billingPlanLineSumInput";
import { ObjectAccountingDimensionValue } from "src/api/generated/erp/sales/accounting/objectAccountingDimensionValue";
import { CustomerOrderOffer } from "src/api/generated/erp/db/types/tables/customerOrderOffer";
import { VatCode } from "src/api/generated/erp/db/types/tables/vatCode";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace CustomerOfferBillingPlanEditApi {
	export async function getFormInitData(args: {
		customerOfferId: number;
		billingPlanLineId: number | null | undefined;
	}): Promise<CustomerOfferBillingPlanEditApi_InitData> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/customerOffer/billingPlan/getFormInitData",
			method: "POST",
			data: {
				customerOfferId: args.customerOfferId,
				billingPlanLineId: args.billingPlanLineId,
			},
		});
		return response as CustomerOfferBillingPlanEditApi_InitData;
	}

	export async function save(args: {
		customerOfferId: number;
		billingPlanLineId: number | null | undefined;
		description: string;
		funded: boolean;
		vatCodeId: number;
		sumInput: BillingPlanLineSumInput;
		accountingCodeIds: Array<number>;
	}): Promise<number> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/customerOffer/billingPlan/save",
			method: "POST",
			data: {
				customerOfferId: args.customerOfferId,
				billingPlanLineId: args.billingPlanLineId,
				description: args.description,
				funded: args.funded,
				vatCodeId: args.vatCodeId,
				sumInput: args.sumInput,
				accountingCodeIds: args.accountingCodeIds,
			},
		});
		return response as number;
	}

	export async function delete_(args: { billingPlanLineIds: Array<number> }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/customerOffer/billingPlan/delete",
			method: "POST",
			data: {
				billingPlanLineIds: args.billingPlanLineIds,
			},
		});
		return response as void;
	}
}

export interface CustomerOfferBillingPlanEditApi_InitData {
	accountingDimensions: Array<ObjectAccountingDimensionValue>;
	customerOffer: CustomerOrderOffer;
	vatCodeOptions: Array<VatCode>;
}
