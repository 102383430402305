// This file is automatically generated. Do not edit manually.

import { CustomerOrderType } from "src/api/generated/erp/db/types/tables/customerOrderType";
import { ObjectAccountingDimensionValue } from "src/api/generated/erp/sales/accounting/objectAccountingDimensionValue";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace CustomerOrderTypeEditApi {
	export async function getFormInitData(args: {
		customerOrderTypeId: number | null | undefined;
	}): Promise<CustomerOrderTypeEditApi_FormInitData> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/basedata/customerOrderTypes/getFormInitData",
			method: "POST",
			data: {
				customerOrderTypeId: args.customerOrderTypeId,
			},
		});
		return response as CustomerOrderTypeEditApi_FormInitData;
	}

	export async function insert(args: {
		customerOrderType: CustomerOrderType;
		accountingCodeIds: Array<number>;
	}): Promise<number> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/basedata/customerOrderTypes/insert",
			method: "POST",
			data: {
				customerOrderType: args.customerOrderType,
				accountingCodeIds: args.accountingCodeIds,
			},
		});
		return response as number;
	}

	export async function update(args: {
		customerOrderType: CustomerOrderType;
		accountingCodeIds: Array<number>;
	}): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/basedata/customerOrderTypes/update",
			method: "POST",
			data: {
				customerOrderType: args.customerOrderType,
				accountingCodeIds: args.accountingCodeIds,
			},
		});
		return response as void;
	}

	export async function delete_(args: { customerOrderTypeIds: Array<number> }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/basedata/customerOrderTypes/delete",
			method: "POST",
			data: {
				customerOrderTypeIds: args.customerOrderTypeIds,
			},
		});
		return response as void;
	}
}

export interface CustomerOrderTypeEditApi_FormInitData {
	accountingDimensions: Array<ObjectAccountingDimensionValue>;
	customerOrderType: CustomerOrderType | null | undefined;
}
