// This file is automatically generated. Do not edit manually.

import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace SitePartActionApi {
	export async function activatePart(args: { partId: number }): Promise<number> {
		const response = await makeAxiosRequest({
			url: "/api/ext/sitePart/activatePart",
			method: "POST",
			data: {
				partId: args.partId,
			},
		});
		return response as number;
	}

	export async function deactivatePart(args: { partId: number }): Promise<number> {
		const response = await makeAxiosRequest({
			url: "/api/ext/sitePart/deactivatePart",
			method: "POST",
			data: {
				partId: args.partId,
			},
		});
		return response as number;
	}

	export async function setPartDemandControlChartId(args: { partId: number; controlChartId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/sitePart/setPartDemandControlChartId",
			method: "POST",
			data: {
				partId: args.partId,
				controlChartId: args.controlChartId,
			},
		});
		return response as void;
	}
}
