import i18n from "i18next";
import { logError } from "src/errorHandling/errorLogging.ts";

export function getSpecialCauseDescriptionForCode(code: number | null | undefined) {
	if(code == null) return ""

	const codeWithoutPrefix = code % 100;
	if (codeWithoutPrefix < 1 || codeWithoutPrefix > 8) {
		logError("Invalid special cause code: " + code);
		return "-";
	}
	return i18n.t(`spc_sc_test_${codeWithoutPrefix}_description` as any);
}
