// This file is automatically generated. Do not edit manually.

import { FrontendUploadFileHandle } from "src/api/generated/common/frontendUploadFileHandle";
import { FrontendDownloadFileHandle } from "src/api/generated/common/frontendDownloadFileHandle";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace FuturaPlanHolzmaFileModifierApi {
	export async function modifyFuturaPlanFile(args: {
		inputFileHandle: FrontendUploadFileHandle;
	}): Promise<FrontendDownloadFileHandle> {
		const response = await makeAxiosRequest({
			url: "/api/ext/holzma/modifyFuturaPlanFile",
			method: "POST",
			data: {
				inputFileHandle: args.inputFileHandle,
			},
		});
		return response as FrontendDownloadFileHandle;
	}
}
