import { basicSetup } from "codemirror";
import { EditorView, keymap } from "@codemirror/view";
import { indentWithTab } from "@codemirror/commands";
import { StreamLanguage } from "@codemirror/language";
import { lua } from "@codemirror/legacy-modes/mode/lua";
import { html, LitElement } from "lit";

class CodeMirrorWebComponent extends LitElement {
	static get properties() {
		return {
			editorContent: { type: String },
		};
	}

	createRenderRoot() {
		return this;
	}

	constructor() {
		super();
		this.editorContent = "";
	}

	render() {
		return html` <div class="codemirror-wrapper" /> `;
	}

	firstUpdated(changedProperties) {
		super.firstUpdated(changedProperties);
		const editorElement = this.querySelector(".codemirror-wrapper");
		this._codeMirror = new EditorView({
			parent: editorElement,
			extensions: [
				basicSetup,
				keymap.of([indentWithTab]),
				StreamLanguage.define(lua),
				EditorView.updateListener.of((viewUpdate) => {
					if (viewUpdate.docChanged) {
						this._dispatchContentChangeEvent(
							viewUpdate.state.doc.toString()
						);
					}
				}),
			],
		});
		this._setEditorContent(this.editorContent);
	}

	update(changedProperties) {
		super.update(changedProperties);
		if (changedProperties.has("editorContent")) {
			this._setEditorContent(this.editorContent);
		}
	}

	_setEditorContent(content) {
		if (!this._codeMirror || content == null) return;
		const currentContent = this._codeMirror.state.doc.toString();
		if (content === currentContent) return;

		this._codeMirror.dispatch({
			changes: {
				from: 0,
				to: this._codeMirror.state.doc.length,
				insert: content,
			},
		});
	}

	_dispatchContentChangeEvent(editorContent) {
		this.dispatchEvent(
			new CustomEvent("codeChange", {
				detail: {
					editorContent,
				},
			})
		);
	}
}

customElements.define("code-mirror-component", CodeMirrorWebComponent);
