import {ClientSideDataGridModel} from "src/components/common/dataGrid/gridModel/ClientSideDataGridModel";
import {CrudDataGrid} from "src/components/common/dataGrid/crud/CrudDataGrid";
import {enumColumn, integerColumn, textColumn} from "src/components/common/dataGrid/columns.tsx";
import i18n from "i18next";
import {
	ConfiguratorLibraryVersionsApi
} from "src/api/generated/erp/configurator/management/libraries/api/configuratorLibraryVersionsApi.ts";
import {ConfiguratorLibraryVersion} from "src/api/generated/erp/db/types/tables/configuratorLibraryVersion.ts";
import {
	getConfiguratorLibraryVersionStateLabels
} from "src/api/generated/erp/db/types/enums/configuratorLibraryVersionState.ts";
import {
	ConfiguratorLibraryVersionForm
} from "src/components/views/erp/configurator/managing/libraries/ConfiguratorLibraryVersionForm.tsx";
import {useConfirmDialog} from "src/components/common/dialogs/confirmDialog/useConfirmDialog.ts";
import {AsyncMenuButton} from "src/components/common/contextMenu/AsyncMenuButton.tsx";
import {faShare, faUndo} from "@fortawesome/pro-regular-svg-icons";

export interface ConfiguratorLibraryVersionsDataGridProps {
	configuratorLibraryId: number;
	onSelectionChanged: (selectedLibrary: ConfiguratorLibraryVersion | undefined) => void;
}

export const ConfiguratorLibraryVersionsDataGrid = ({
	configuratorLibraryId,
	onSelectionChanged,
}: ConfiguratorLibraryVersionsDataGridProps) => {
	const showConfirmDialog = useConfirmDialog();

	return (
		<ClientSideDataGridModel
			fetchData={() =>
				ConfiguratorLibraryVersionsApi.getConfiguratorLibraryVersions({
					libraryId: configuratorLibraryId,
				})
			}
			initialParams={{}}
			getRows={(data) => data}
			getRowId={(row) => row.configuratorLibraryVersionId}
			gridId={"72A57A104F052CDAD"}
			onSelectionChanged={(rows) => onSelectionChanged(rows[0])}
			render={({ dataGridProps, onlySelectedRow, refreshData }) => (
				<CrudDataGrid<ConfiguratorLibraryVersion>
					disableMultipleRowSelection
					columns={[
						integerColumn({
							field: "versionNumber",
							headerName: i18n.t("version"),
						}),
						enumColumn({
							field: "state",
							headerName: i18n.t("state"),
							enumLabels: getConfiguratorLibraryVersionStateLabels(),
						}),
						textColumn({
							field: "note",
							headerName: i18n.t("note"),
						}),
					]}
					{...dataGridProps}
					form={{
						addRowEnabled: true,
						editEnabled: true,
						dialogSize: "sm",
						dialogTitle: i18n.t("library_version"),
						component: ({ row, onCompleted, onFormEdited }) => (
							<ConfiguratorLibraryVersionForm
								configuratorLibraryId={configuratorLibraryId}
								configuratorLibraryVersionId={row?.configuratorLibraryVersionId}
								onCompleted={onCompleted}
								onFormEdited={onFormEdited}
							/>
						),
					}}
					remove={
						onlySelectedRow?.state === "RELEASED" ?
							{ type: "disabled" }
						:	({ items }) =>
								ConfiguratorLibraryVersionsApi.delete_({
									libraryVersionId: items[0]!.configuratorLibraryVersionId,
								})
					}
					rowContextMenuComponents={({ row }) => [
						row.state === "INITIAL" && (
							<AsyncMenuButton
								key={"release"}
								label={i18n.t("release")}
								icon={faShare}
								onClick={async () => {
									const confirmed = await showConfirmDialog();
									if (!confirmed) return;

									await ConfiguratorLibraryVersionsApi.releaseLibraryVersion({
										libraryVersionId: row.configuratorLibraryVersionId,
									});

									await refreshData();
								}}
							/>
						),
						row.state === "RELEASED" && (
							<AsyncMenuButton
								key={"revertRelease"}
								label={i18n.t("revert_release")}
								icon={faUndo}
								onClick={async () => {
									const confirmed = await showConfirmDialog();
									if (!confirmed) return;

									await ConfiguratorLibraryVersionsApi.revertReleaseLibraryVersion({
										libraryVersionId: row.configuratorLibraryVersionId,
									});

									await refreshData();
								}}
							/>
						),
					]}
				/>
			)}
		/>
	);
};
