import { FormCommonProps } from "src/components/common/forms/types.ts";
import {
	WarehousePickingObjectDto,
	WarehousePickingObjectReservationFormBase,
} from "src/components/views/erp/warehouse/inventory/WarehousePickingObjectReservationFormBase.tsx";
import { CustomerOrderLineView } from "src/api/generated/erp/db/types/tables/customerOrderLineView.ts";
import i18n from "i18next";
import { formatIsoString } from "src/utils/dayjsUtils.ts";
import {
	CustomerOrderLineReservationFormApi
} from "src/api/generated/erp/sales/delivery/api/customerOrderLineReservationFormApi.ts";

export interface CustomerOrderLineReservationFormProps extends FormCommonProps<void> {
	customerOrderLines: CustomerOrderLineView[];
}

export const CustomerOrderLineReservationForm = (props: CustomerOrderLineReservationFormProps) => {
	const { customerOrderLines, onCompleted, onFormEdited } = props;

	const customerOrderLinePickingObjects = customerOrderLines.reduce<WarehousePickingObjectDto[]>(
		(acc, line) => {
			if (line.partId == null) return acc;
			else
				return [
					...acc,
					{
						pickingObjectId: line.customerOrderLineId,
						partId: line.partId,
						objectTotalValueInWarehouseUnit: line.salesQuantityAsWarehouseUnits,
						objectReservedQuantityInWarehouseUnit: line.reservedQuantity,
						partDescription: line.configurationOrPartDescription,
						subTitle: `${i18n.t("delivery_date")}: ${formatIsoString(line.plannedDeliveryDate)}`,
					},
				];
		},
		Array<WarehousePickingObjectDto>(),
	);
	return (
		<WarehousePickingObjectReservationFormBase
			warehousePickingObjects={customerOrderLinePickingObjects}
			onFormEdited={onFormEdited}
			submit={async (values) =>
				await CustomerOrderLineReservationFormApi.createReservations({
					reservationRequests: values.pickingObjects.map((request) => ({
						customerOrderLineId: request.pickingObjectId,
						partWarehouseLocationId: request.partWarehouseLocationId,
						quantityInWarehouseUnit: request.quantityToReserveInWarehouseUnit,
					})),
				})
			}
			onCompleted={onCompleted}
		/>
	);
};
