import { ControlChartEventsDataGridApi } from "src/api/generated/spc/controlChart/api/controlChartEventsDataGridApi.ts";
import { ControlledAsyncDataGrid } from "src/components/common/dataGrid/ControlledAsyncDataGrid.tsx";
import { dateTimeColumn, integerColumn, textColumn } from "src/components/common/dataGrid/columns.tsx";
import i18n from "i18next";
import { DocumentsOfObjectButton } from "src/components/views/documents/objectDocuments/DocumentsOfObjectButton.tsx";
import { nullableAavoObjectRef } from "src/utils/aavoObjectRefUtils.ts";
import { useContext } from "react";
import { ControlChartEventView } from "src/api/generated/postgres/db/types/public_/tables/controlChartEventView.ts";
import { useServerSideDataGridModel } from "src/components/common/dataGrid/gridModel/useServerSideDataGridModel";
import { useTenantCustomizations } from "src/tenantCustomizations/useTenantCustomizations.tsx";
import {
	FocusControlChartGraphViewToRecordButton,
	UploadDocumentsToSpcRecordButton,
} from "src/components/views/spc/controlChart/controlChartInspectingView/rawRecordDataGridUtils.tsx";
import { parseDayJsNullable } from "src/utils/dayjsUtils.ts";
import { RefreshableElementProps } from "src/utils/useRefreshRef.ts";
import { ControlChartInspectingViewContext } from "src/components/views/spc/controlChart/controlChartInspectingView/ControlChartInspectingViewContext.ts";
import { AsyncMenuButton } from "src/components/common/contextMenu/AsyncMenuButton.tsx";
import { faLink } from "@fortawesome/pro-regular-svg-icons";
import {
	openLegacyConnectSpcRecordToImprovementView,
	openLegacyProcessImprovementView,
} from "src/components/views/legacy/legacyViewAdapters.ts";
import { useOpenLegacyView } from "src/components/views/legacy/useOpenLegacyView.ts";

export interface ControlChartEventsDataGridProps extends RefreshableElementProps {
	controlChartId: number;
}

export const ControlChartEventsDataGrid = ({ controlChartId }: ControlChartEventsDataGridProps) => {
	const { tenantConfig } = useTenantCustomizations();
	const openLegacyView = useOpenLegacyView();

	const { graphViewApiRef, eventsDataGridRefreshRef } = useContext(ControlChartInspectingViewContext) ?? {};

	const { dataGridProps, onlySelectedRow, refreshData } = useServerSideDataGridModel({
		gridId: "FD6EC55B3BC14A9BD",
		fetchData: (params) =>
			ControlChartEventsDataGridApi.getGridData({
				controlChartId,
				...params,
			}),
		initialParams: {},
		getRowId: (row) => row.recordId,
		refreshRef: eventsDataGridRefreshRef,
	});

	return (
		<ControlledAsyncDataGrid<ControlChartEventView>
			disableColumnFilter
			columns={[
				dateTimeColumn({
					field: "recordObservationTime",
					headerName: i18n.t("recorded_at"),
					sortable: false,
					width: 150,
				}),
				textColumn({
					field: "ocs",
					headerName: i18n.t("reason"),
					sortable: false,
					width: 100,
				}),
				textColumn({
					field: "description",
					headerName: i18n.t("description"),
					sortable: false,
					width: 150,
				}),
				dateTimeColumn({
					field: "createdAt",
					headerName: i18n.t("created_at"),
					sortable: false,
					width: 120,
				}),
				textColumn({
					field: "createdBy",
					headerName: i18n.t("created_by"),
					sortable: false,
					width: 120,
				}),
				integerColumn({
					field: "recordId",
					headerName: i18n.t("id"),
					sortable: false,
				}),
			]}
			actionBarComponents={
				<>
					{tenantConfig.documentsEnabled && (
						<>
							<DocumentsOfObjectButton
								objectRef={nullableAavoObjectRef("SPC_RECORD", onlySelectedRow?.recordId)}
							/>
							<UploadDocumentsToSpcRecordButton
								disabled={!onlySelectedRow}
								recordId={onlySelectedRow?.recordId}
							/>
						</>
					)}
					<FocusControlChartGraphViewToRecordButton
						disabled={!onlySelectedRow}
						recordObservationTime={parseDayJsNullable(onlySelectedRow?.recordObservationTime)}
						graphViewApiRef={graphViewApiRef}
					/>
				</>
			}
			rowContextMenuComponents={({ row }) => [
				tenantConfig.spc.processImprovementsEnabled && [
					row.processImprovementId == null && (
						<AsyncMenuButton
							key={"connectToProcessImprovement"}
							label={i18n.t("connect_to_an_improvement")}
							icon={faLink}
							onClick={() => {
								openLegacyConnectSpcRecordToImprovementView({
									openLegacyView,
									recordId: row.recordId,
									refreshSource: () => refreshData(),
								});
							}}
						/>
					),
					row.processImprovementId != null && (
						<AsyncMenuButton
							key={"openImprovement"}
							label={i18n.t("open_improvement")}
							icon={faLink}
							onClick={() => {
								openLegacyProcessImprovementView({
									openLegacyView,
									processImprovementId: row.processImprovementId!,
								});
							}}
						/>
					),
				],
			]}
			{...dataGridProps}
		/>
	);
};
