// This file is automatically generated. Do not edit manually.

import { SalesCommission } from "src/api/generated/erp/db/types/tables/salesCommission";
import { SalesCommissionLineView } from "src/api/generated/erp/db/types/tables/salesCommissionLineView";
import { SalesCommissionView } from "src/api/generated/erp/db/types/tables/salesCommissionView";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace SalesCommissionApi {
	export async function getSalesCommission(args: {
		salesCommissionId: number;
	}): Promise<SalesCommissionApi_SalesCommissionWithLines> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/commissions/getSalesCommission",
			method: "POST",
			data: {
				salesCommissionId: args.salesCommissionId,
			},
		});
		return response as SalesCommissionApi_SalesCommissionWithLines;
	}

	export async function updateSalesCommission(args: { salesCommission: SalesCommission }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/commissions/updateSalesCommission",
			method: "POST",
			data: {
				salesCommission: args.salesCommission,
			},
		});
		return response as void;
	}
}

export interface SalesCommissionApi_SalesCommissionWithLines {
	lines: Array<SalesCommissionLineView>;
	salesCommission: SalesCommissionView;
}
