import { PartConfiguration } from "src/api/generated/erp/db/types/tables/partConfiguration";
import { PartConfigurationOperation } from "src/api/generated/erp/db/types/tables/partConfigurationOperation.ts";
import {
	PartConfigurationOperationApi,
	PartConfigurationOperationApi_FormInitData,
} from "src/api/generated/erp/configurator/api/partConfigurationOperationApi.ts";
import { FormCommonProps } from "src/components/common/forms/types.ts";
import { FormNumberField } from "src/components/common/forms/fields/FormNumberField";
import i18n from "i18next";
import { requireRule } from "src/components/common/forms/validation.ts";
import { FormSelectField } from "src/components/common/forms/fields/FormSelectField.tsx";
import { PartConfigurationOperationView } from "src/api/generated/erp/db/types/tables/partConfigurationOperationView.ts";
import { AsyncForm, AsyncFormContentParams } from "src/components/common/forms/AsyncForm.tsx";

export interface PartConfigurationOperationFormProps extends FormCommonProps<number> {
	partConfiguration: PartConfiguration;
	partConfigurationOperation: PartConfigurationOperation | undefined;
}

export const PartConfigurationOperationForm = (props: PartConfigurationOperationFormProps) => {
	const { partConfiguration, partConfigurationOperation, onFormEdited, onCompleted } = props;
	return (
		<AsyncForm
			fetch={() =>
				PartConfigurationOperationApi.getFormInitData({
					partConfigurationId: partConfiguration.partConfigurationId,
					partConfigurationOperationId: partConfigurationOperation?.partConfigurationOperationId,
				})
			}
			getDefaultValues={({ nextPosition }) =>
				partConfigurationOperation ?? {
					partConfigurationId: partConfiguration.partConfigurationId,
					position: nextPosition,
					materialOperationId: -1,
				}
			}
			submit={submitForm}
			columns={1}
			onCompleted={onCompleted}
			onFormEdited={onFormEdited}
			render={(params) => <FormContent {...props} {...params} />}
		/>
	);

	async function submitForm(values: PartConfigurationOperation) {
		return await PartConfigurationOperationApi.saveOperation({
			partConfigurationOperation: values,
		});
	}
};

interface FormContentProps
	extends PartConfigurationOperationFormProps,
		AsyncFormContentParams<PartConfigurationOperationApi_FormInitData, PartConfigurationOperation> {}

const FormContent = ({
	partConfigurationOperation,
	control,
	watch,
	data: { operationOptions, configurationOperations },
}: FormContentProps) => {
	const isNewRecord = partConfigurationOperation == null;
	const selectedOperationId = watch("operationId");

	return (
		<>
			<FormNumberField
				control={control}
				name={"position"}
				label={i18n.t("number_shortened")}
				rules={requireRule()}
			/>
			<FormSelectField
				control={control}
				name={"operationId"}
				label={i18n.t("operation")}
				rules={requireRule()}
				options={operationOptions}
				getOptionKey={(op) => op.operationId}
				getOptionLabel={(op) => op.operationLongName}
			/>
			<FormSelectField
				control={control}
				name={"materialOperationId"}
				label={i18n.t("material_operation")}
				disableClearable
				rules={requireRule()}
				options={getMaterialOperationOptions()}
				getOptionKey={(op) => op.partConfigurationOperationId}
				getOptionLabel={(op) => op.operationLongName ?? ""}
			/>
			<FormNumberField control={control} name={"setUpTime"} label={i18n.t("set_up_time_hours")} />
			<FormNumberField control={control} name={"workingTime"} label={i18n.t("working_time_hours")} />
		</>
	);

	function getMaterialOperationOptions(): Pick<
		PartConfigurationOperationView,
		"partConfigurationOperationId" | "operationLongName"
	>[] {
		const optionsFromSavedOperations = configurationOperations;

		if (!isNewRecord) {
			return optionsFromSavedOperations;
		}

		const selectedOperation = operationOptions.find(
			(op) => op.operationId === selectedOperationId,
		);
		// Option -1 is used to point material operation to self when creating a new part configuration operation.
		const selfOption = {
			partConfigurationOperationId: -1,
			operationLongName: selectedOperation?.operationLongName ?? "",
		};

		return [selfOption, ...optionsFromSavedOperations];
	}
};
