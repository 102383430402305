// This file is automatically generated. Do not edit manually.

import { SupplierPartView } from "src/api/generated/erp/db/types/tables/supplierPartView";
import { PurchaseOrderLine } from "src/api/generated/erp/db/types/tables/purchaseOrderLine";
import { PurchaseOrder } from "src/api/generated/erp/db/types/tables/purchaseOrder";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace PurchaseOrderLineEditApi {
	export async function getFormInitData(args: {
		purchaseOrderId: number;
		purchaseOrderLineId: number | null | undefined;
	}): Promise<PurchaseOrderLineEditApi_FormInitData> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/purchase/purchaseOrderLine/getFormInitData",
			method: "POST",
			data: {
				purchaseOrderId: args.purchaseOrderId,
				purchaseOrderLineId: args.purchaseOrderLineId,
			},
		});
		return response as PurchaseOrderLineEditApi_FormInitData;
	}

	export async function getSupplierPartOptions(args: {
		purchaseOrderId: number;
		searchQuery: string;
		currentSelection: number | null | undefined;
	}): Promise<Array<SupplierPartView>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/purchase/purchaseOrderLine/getSupplierPartOptions",
			method: "POST",
			data: {
				purchaseOrderId: args.purchaseOrderId,
				searchQuery: args.searchQuery,
				currentSelection: args.currentSelection,
			},
		});
		return response as Array<SupplierPartView>;
	}

	export async function getSupplierPartDefaultPrice(args: { supplierPartId: number }): Promise<number> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/purchase/purchaseOrderLine/getSupplierPartDefaultPrice",
			method: "POST",
			data: {
				supplierPartId: args.supplierPartId,
			},
		});
		return response as number;
	}

	export async function insert(args: { purchaseOrderLine: PurchaseOrderLine }): Promise<number> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/purchase/purchaseOrderLine/insert",
			method: "POST",
			data: {
				purchaseOrderLine: args.purchaseOrderLine,
			},
		});
		return response as number;
	}

	export async function update(args: { purchaseOrderLine: PurchaseOrderLine }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/purchase/purchaseOrderLine/update",
			method: "POST",
			data: {
				purchaseOrderLine: args.purchaseOrderLine,
			},
		});
		return response as void;
	}
}

export interface PurchaseOrderLineEditApi_FormInitData {
	defaultLineNumber: number;
	purchaseOrder: PurchaseOrder;
	purchaseOrderLine: PurchaseOrderLine | null | undefined;
	supplierPart: SupplierPartView | null | undefined;
}
