// This file is automatically generated. Do not edit manually.

import { SystemDiagramNode } from "src/api/generated/postgres/db/types/public_/tables/systemDiagramNode";
import { SystemDiagramFunction } from "src/api/generated/systemDiagrams/functions/systemDiagramFunction";
import { CustomerOrderTenantState } from "src/api/generated/erp/sales/customerOrder/tenantState/customerOrderTenantState";
import { CustomerOrderType } from "src/api/generated/erp/db/types/tables/customerOrderType";
import { Operation } from "src/api/generated/erp/db/types/tables/operation";
import { ProductionLineView } from "src/api/generated/erp/db/types/tables/productionLineView";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace SystemDiagramNodeCrudApi {
	export async function getForSystemDiagram(args: {
		systemDiagramId: number;
	}): Promise<SystemDiagramNodeCrudApi_SystemDiagramNodeData> {
		const response = await makeAxiosRequest({
			url: "/api/ext/common/systemDiagrams/manage/nodes/getForSystemDiagram",
			method: "POST",
			data: {
				systemDiagramId: args.systemDiagramId,
			},
		});
		return response as SystemDiagramNodeCrudApi_SystemDiagramNodeData;
	}

	export async function save(args: { systemDiagramNode: SystemDiagramNode }): Promise<SystemDiagramNode> {
		const response = await makeAxiosRequest({
			url: "/api/ext/common/systemDiagrams/manage/nodes/save",
			method: "POST",
			data: {
				systemDiagramNode: args.systemDiagramNode,
			},
		});
		return response as SystemDiagramNode;
	}

	export async function delete_(args: { systemDiagramNodes: Array<SystemDiagramNode> }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/common/systemDiagrams/manage/nodes/delete",
			method: "POST",
			data: {
				systemDiagramNodes: args.systemDiagramNodes,
			},
		});
		return response as void;
	}

	export async function getFormInitialData(args: {
		systemDiagramId: number;
		systemDiagramNodeId: number | null | undefined;
	}): Promise<SystemDiagramNodeCrudApi_FormInitialData> {
		const response = await makeAxiosRequest({
			url: "/api/ext/common/systemDiagrams/manage/nodes/getFormInitialData",
			method: "POST",
			data: {
				systemDiagramId: args.systemDiagramId,
				systemDiagramNodeId: args.systemDiagramNodeId,
			},
		});
		return response as SystemDiagramNodeCrudApi_FormInitialData;
	}
}

export interface SystemDiagramNodeCrudApi_SystemDiagramNodeData {
	functionOptions: Array<SystemDiagramFunction>;
	nodes: Array<SystemDiagramNode>;
}

export interface SystemDiagramNodeCrudApi_FormInitialData {
	customerOrderTenantStateOptions: Array<CustomerOrderTenantState>;
	customerOrderTypeOptions: Array<CustomerOrderType>;
	functionOptions: Array<SystemDiagramFunction>;
	operationOptions: Array<Operation>;
	productionLineOptions: Array<ProductionLineView>;
	systemDiagramNode: SystemDiagramNode | null | undefined;
}
