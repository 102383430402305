import { AsyncForm } from "src/components/common/forms/AsyncForm.tsx";
import {
	SalesPartEditApi,
	SalesPartEditApi_InitData,
} from "src/api/generated/erp/sales/basedata/api/salesPartEditApi.ts";
import { SalesPart } from "src/api/generated/erp/db/types/tables/salesPart.ts";
import { FormCommonProps } from "src/components/common/forms/types.ts";
import { FormTextField } from "src/components/common/forms/fields/FormTextField.tsx";
import { FormSelectField } from "src/components/common/forms/fields/FormSelectField.tsx";
import i18n from "i18next";
import { requiredNonNegativeFloatRule, requireRule } from "src/components/common/forms/validation.ts";
import {
	ConfigurationPriceCalculationMethodValues,
	getConfigurationPriceCalculationMethodLabel,
} from "src/api/generated/erp/db/types/enums/configurationPriceCalculationMethod.ts";
import { FormCheckbox } from "src/components/common/forms/fields/FormCheckbox.tsx";
import {
	ObjectAccountingForm,
	ObjectAccountingFormValues,
} from "src/components/views/erp/sales/accounting/ObjectAccountingForm.tsx";
import {
	getObjectAccountingCodeIdsFromFormValues,
	getObjectAccountingFormDefaultValues,
} from "src/components/views/erp/sales/accounting/ObjectAccountingForm.utils.ts";
import { getDefaultVatCode } from "src/components/views/erp/sales/salesUtils.ts";

export interface SalesPartFormProps extends FormCommonProps<number> {
	salesPartId: number | null;
	partId: number;
}

interface FormValues extends SalesPart, ObjectAccountingFormValues {}

export const SalesPartForm = ({ salesPartId, partId, onCompleted, onFormEdited }: SalesPartFormProps) => {
	return (
		<AsyncForm<SalesPartEditApi_InitData, FormValues, number>
			columns={2}
			fetch={() =>
				SalesPartEditApi.getInitData({
					salesPartId: salesPartId,
					partId: partId,
				})
			}
			getDefaultValues={({ part, salesPart, accountingDimensions, vatCodeOptions }) => {
				if (salesPart != null)
					return {
						...salesPart,
						...getObjectAccountingFormDefaultValues(accountingDimensions),
					};
				else
					return {
						partId: partId,
						salesPartNo: part.partNo,
						salesPartDescription: `${part.partDescription_1} ${part.partDescription_2}`,
						salesPriceUnitId: part.partUnitId,
						salesUnitId: part.partUnitId,
						priceFactor: 1,
						salesFactor: 1,
						configurationPriceCalculationMethod: part.isConfigurable ? "RULE" : "BOM",
						isDefault: true,
						active: true,
						canBeUsedOnCustomerOrderLine: true,
						siteId: part.siteId,
						vatCodeId: getDefaultVatCode(vatCodeOptions).vatCodeId,
						...getObjectAccountingFormDefaultValues(accountingDimensions),
					};
			}}
			onCompleted={onCompleted}
			onFormEdited={onFormEdited}
			render={({ data, control }) => {
				const {
					part,
					salesPart,
					salesPartCategoryOptions,
					salesPriceGroupOptions,
					erpUnitOptions,
					vatCodeOptions,
				} = data;
				return (
					<>
						<FormTextField
							control={control}
							name="salesPartNo"
							label={i18n.t("sales_part_no")}
							rules={requireRule()}
						/>
						<FormTextField
							control={control}
							name="salesPartDescription"
							label={i18n.t("description")}
							rules={requireRule()}
						/>
						<FormSelectField
							control={control}
							name={"salesPartCategoryId"}
							label={i18n.t("sales_part_category")}
							options={salesPartCategoryOptions}
							getOptionKey={(o) => o.salesPartCategoryId}
							getOptionLabel={(o) => o.name ?? ""}
						/>
						<FormSelectField
							control={control}
							name={"salesPartPriceGroupId"}
							label={i18n.t("sales_price_group")}
							options={salesPriceGroupOptions}
							getOptionKey={(o) => o.salesPartPriceGroupId}
							getOptionLabel={(o) => o.name ?? ""}
						/>
						<FormTextField
							control={control}
							name="salesPrice"
							label={i18n.t("sales_price")}
							rules={requiredNonNegativeFloatRule()}
						/>
						<FormSelectField
							control={control}
							name={"salesPriceUnitId"}
							label={i18n.t("sales_price_unit")}
							options={erpUnitOptions}
							getOptionKey={(o) => o.unitId}
							getOptionLabel={(o) => o.unitName}
							rules={requireRule()}
						/>
						<FormSelectField
							control={control}
							name={"salesUnitId"}
							label={i18n.t("sales_unit")}
							options={erpUnitOptions}
							getOptionKey={(o) => o.unitId}
							getOptionLabel={(o) => o.unitName}
							rules={requireRule()}
						/>
						<FormTextField
							control={control}
							name="priceFactor"
							label={i18n.t("price_factor")}
							rules={requiredNonNegativeFloatRule()}
						/>
						<FormTextField
							control={control}
							name="salesFactor"
							label={i18n.t("sales_factor")}
							rules={requiredNonNegativeFloatRule()}
						/>
						<FormSelectField
							control={control}
							name={"vatCodeId"}
							label={i18n.t("vat_code")}
							options={vatCodeOptions}
							getOptionKey={(o) => o.vatCodeId}
							getOptionLabel={(o) => o.vatCodeName}
							rules={requireRule()}
						/>
						<ObjectAccountingForm control={control} />
						{part.isConfigurable && (
							<FormSelectField
								control={control}
								name={"configurationPriceCalculationMethod"}
								label={i18n.t("configuration_price_calculation_method")}
								options={ConfigurationPriceCalculationMethodValues}
								getOptionKey={(o) => o}
								getOptionLabel={(o) => getConfigurationPriceCalculationMethodLabel(o)}
							/>
						)}
						<FormCheckbox
							control={control}
							name={"isDefault"}
							label={i18n.t("part_default_sales_part")}
							disabled={salesPart != null}
							sx={{ gridColumnStart: 1 }}
						/>
						<FormCheckbox
							control={control}
							name={"active"}
							label={i18n.t("active")}
							sx={{ gridColumnStart: 1 }}
						/>
						<FormCheckbox
							control={control}
							name={"canBeUsedOnCustomerOrderLine"}
							label={i18n.t("can_be_used_on_customer_order_line")}
							sx={{ gridColumnStart: 1 }}
						/>
					</>
				);
			}}
			submit={(values: FormValues) => {
				const { accountingDimensionValues, ...salesPart } = values;
				const accountingCodeIds = getObjectAccountingCodeIdsFromFormValues(accountingDimensionValues);

				if (values.salesPartId == null) {
					return SalesPartEditApi.insert({
						salesPart,
						accountingCodeIds,
					});
				} else {
					return SalesPartEditApi.update({
						salesPart,
						accountingCodeIds,
					});
				}
			}}
		/>
	);
};
