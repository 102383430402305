import { AsyncDataGrid } from "src/components/common/dataGrid/AsyncDataGrid";
import { TestConfigurationResultViewCommonProps } from "src/components/views/erp/configurator/configuratorTesting/TestConfigurationResultViewCommonProps.ts";
import {
	SitePartTestConfigurationResultOperationsApi,
	SitePartTestConfigurationResultOperationsApi_Row,
} from "src/api/generated/erp/configurator/api/configuratorTesting/sitePartTestConfigurationResultOperationsApi.ts";
import { floatColumn } from "src/components/common/dataGrid/columns.tsx";
import i18n from "i18next";
import { dataGridPersistentStateProps } from "src/components/common/dataGrid/dataGridStateStorage";

export const SitePartTestConfigurationOperationsDataGrid = ({
	configuratorType,
	propertyValues,
	configurationSessionId,
}: TestConfigurationResultViewCommonProps) => {
	return (
		<AsyncDataGrid<SitePartTestConfigurationResultOperationsApi_Row>
			fetchRows={() =>
				SitePartTestConfigurationResultOperationsApi.getTestConfigurationResultOperations({
					configurationSessionId,
					configuratorType,
					propertyValues,
				})
			}
			getRowId={(row) => row.operationId}
			columns={[
				{
					field: "operation",
					headerName: i18n.t("operation"),
					valueGetter: (_, row) => row.operationNo + " | " + row.operationDescription,
					width: 250,
				},
				{
					field: "materialOperation",
					headerName: i18n.t("material_operation"),
					valueGetter: (_, row) =>
						row.materialOperationNo + " | " + row.materialOperationDescription,
					width: 250,
				},
				floatColumn({
					field: "setUpTime",
					headerName: i18n.t("set_up_time_hours"),
					width: 100,
				}),
				floatColumn({
					field: "workingTime",
					headerName: i18n.t("working_time_hours"),
					width: 100,
				}),
			]}
			{...dataGridPersistentStateProps("D49F3F0FC5CD8BC1")}
		/>
	);
};
