// This file is automatically generated. Do not edit manually.

import { t } from "i18next";

export const PurchaseOrderTypeValues = ["TO_SITE", "DIRECT"] as const;

export type PurchaseOrderType = (typeof PurchaseOrderTypeValues)[number];

export const getPurchaseOrderTypeLabels = () => ({
	TO_SITE: t("delivery_to_site"),
	DIRECT: t("direct_delivery"),
});

export const getPurchaseOrderTypeLabel = (value: PurchaseOrderType): string => {
	return getPurchaseOrderTypeLabels()[value];
};
