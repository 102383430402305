import { SxProps, Theme } from "@mui/material/styles";
import { mergeSx } from "src/utils/styles.ts";

export interface FormFieldLayoutProps {
	spanGridColumns?: boolean;
	startNewGridRow?: boolean;
}

export const startNewGridRow: SxProps<Theme> = {
	gridColumnStart: 1,
};

export const spanGridColumns: SxProps<Theme> = {
	gridColumn: "1 / -1",
};

export function formFieldLayoutPropsToSx(props: FormFieldLayoutProps): SxProps<Theme> {
	let ret: SxProps<Theme> = {};
	if (props.spanGridColumns) {
		ret = mergeSx(ret, spanGridColumns);
	}
	if (props.startNewGridRow) {
		ret = mergeSx(ret, startNewGridRow);
	}
	return ret;
}
