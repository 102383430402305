import { SpcSamplingMethod } from "src/components/views/spc/basedata/controlCharts/form/samplingMethod/SpcSamplingMethod.tsx";
import i18n from "i18next";
import { FormTextField } from "src/components/common/forms/fields/FormTextField.tsx";

export const getOperationThroughputSampling = (): SpcSamplingMethod => {
	return {
		key: "operation_throughput",
		label: i18n.t("operation_throughput_quantity"),
		renderParameterFields: ({ control }) => {
			return (
				<>
					<FormTextField
						control={control}
						name={"samplingParams.param1"}
						label={i18n.t("equipments")}
					/>
				</>
			);
		},
	};
};
