import {isStorageLocalSettings, StorageSettings} from "../localSettings/types";
import { setLocalStorageItemManaged } from "src/storage/localStorageUtils.ts";

export const LOCAL_SETTINGS_STORAGE_KEY = "local_settings";

export const DEFAULT_FONT_SIZE: number = 16;
export const DEFAULT_LANGUAGE = "fi" as const;

export const getLocalSettingsStorage = (): StorageSettings => {
    const settings = localStorage.getItem(LOCAL_SETTINGS_STORAGE_KEY);
    if (settings) {
        const parsed = JSON.parse(settings);
        if (isStorageLocalSettings(parsed)) {
            return parsed;
        }
    }
    return {
        fontSize: DEFAULT_FONT_SIZE,
    };
};

export const setLocalSettingsStorage = (settings: StorageSettings): StorageSettings => {
    setLocalStorageItemManaged(LOCAL_SETTINGS_STORAGE_KEY, JSON.stringify(settings));
    return settings;
};

export const getFontSizeStorage = (): number => {
    return getLocalSettingsStorage().fontSize;
};
