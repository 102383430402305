// This file is automatically generated. Do not edit manually.

import { CustomerOrderSalesConditionView } from "src/api/generated/erp/db/types/tables/customerOrderSalesConditionView";
import { CustomerOrderSalesCondition } from "src/api/generated/erp/db/types/tables/customerOrderSalesCondition";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace CustomerOrderSalesConditionApi {
	export async function getCustomerOrderSalesConditions(args: {
		customerOrderId: number | null | undefined;
		responsiblePersonId: number | null | undefined;
		onlyIncomplete: boolean;
	}): Promise<Array<CustomerOrderSalesConditionView>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/customerOrderSalesCondition/getCustomerOrderSalesConditions",
			method: "POST",
			data: {
				customerOrderId: args.customerOrderId,
				responsiblePersonId: args.responsiblePersonId,
				onlyIncomplete: args.onlyIncomplete,
			},
		});
		return response as Array<CustomerOrderSalesConditionView>;
	}

	export async function completeCustomerOrderSalesCondition(args: { conditionId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/customerOrderSalesCondition/completeCustomerOrderSalesCondition",
			method: "POST",
			data: {
				conditionId: args.conditionId,
			},
		});
		return response as void;
	}

	export async function revertCompleteCustomerOrderSalesCondition(args: { conditionId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/customerOrderSalesCondition/revertCompleteCustomerOrderSalesCondition",
			method: "POST",
			data: {
				conditionId: args.conditionId,
			},
		});
		return response as void;
	}

	export async function delete_(args: {
		customerOrderSalesConditions: Array<CustomerOrderSalesCondition>;
	}): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/customerOrderSalesCondition/delete",
			method: "POST",
			data: {
				customerOrderSalesConditions: args.customerOrderSalesConditions,
			},
		});
		return response as void;
	}
}
