import { DocumentConnectionView } from "src/api/generated/postgres/db/types/documents/tables/documentConnectionView.ts";
import { CatalogPartRevisionView } from "src/api/generated/erp/db/types/tables/catalogPartRevisionView.ts";
import { CatalogPartRevisionDocumentConfigurationRuleApi } from "src/api/generated/erp/configurator/management/catalogPart/api/catalogPartRevisionDocumentConfigurationRuleApi.ts";
import { FormConfiguratorLuaEditor } from "src/components/views/erp/configurator/scripting/FormConfiguratorLuaEditor.tsx";
import { AsyncForm } from "src/components/common/forms/AsyncForm.tsx";
import { CatalogPartRevisionDocumentConfigurationRule } from "src/api/generated/erp/db/types/tables/catalogPartRevisionDocumentConfigurationRule.ts";
import { DeepPartial } from "react-hook-form";

export interface CatalogPartRevisionDocumentConfigurationRulesViewProps {
	catalogPartRevision: CatalogPartRevisionView;
	document: DocumentConnectionView;
	onDirtyStateChanged?: (isDirty: boolean) => void;
}

interface FormValues {
	ruleScript: string;
}

export const CatalogPartRevisionDocumentConfigurationRulesView = (
	props: CatalogPartRevisionDocumentConfigurationRulesViewProps,
) => {
	const { catalogPartRevision, document, onDirtyStateChanged } = props;
	return (
		<AsyncForm
			fetch={() =>
				CatalogPartRevisionDocumentConfigurationRuleApi.getRevisionDocumentConfigurationRules({
					catalogPartRevisionId: catalogPartRevision.catalogPartRevisionId,
					documentId: document.documentId,
				})
			}
			getDefaultValues={getDefaultValues}
			submit={submit}
			onCompleted={() => onDirtyStateChanged?.(false)}
			onFormEdited={() => onDirtyStateChanged?.(true)}
			hideCancel
			render={({ control }) => (
				<>
					<FormConfiguratorLuaEditor
						control={control}
						name={"ruleScript"}
						catalogPartRevisionId={catalogPartRevision.catalogPartRevisionId}
						productFamilyVersionId={catalogPartRevision.configurationProductFamilyVersionId}
					/>
				</>
			)}
		/>
	);

	function getDefaultValues(
		initData: CatalogPartRevisionDocumentConfigurationRule | null,
	): DeepPartial<FormValues> {
		return {
			ruleScript: initData?.ruleScript ?? "",
		};
	}

	async function submit({ ruleScript }: FormValues) {
		return await CatalogPartRevisionDocumentConfigurationRuleApi.setRevisionDocumentConfigurationRules({
			catalogPartRevisionId: catalogPartRevision.catalogPartRevisionId,
			documentId: document.documentId,
			ruleScript: ruleScript,
		});
	}
};
