import { html, LitElement } from "lit";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import { onAppContentAreaChanged } from "src/legacyViews/js/appContentAreaChangedEvent";

class ThreeJsWebComponent extends LitElement {
	static get properties() {
		return {
			sourceUrl: { type: String }
		};
	}

	createRenderRoot() {
		return this;
	}

	constructor() {
		super();
		this.sourceUrl = null;
	}

	render() {
		return html`
			<div class="three-container">
				<canvas class="three-canvas" />
			</div>
		`;
	}

	firstUpdated(_changedProperties) {
		super.firstUpdated(_changedProperties);
		const threeCanvas = this.querySelector(".three-canvas");
		const size = this.getContainerSize();

		this._scene = this.createScene();
		this._camera = this.createCamera(size);
		this.createLights();
		this._renderer = this.createRenderer(threeCanvas, size);
		this.createControls(threeCanvas);
		this.renderCanvas();
		this.loadModel().catch((error) => {
			console.error(error);
		})

		onAppContentAreaChanged(() => {
			this.onAreaChanged();
		});
	}

	createScene() {
		const scene = new THREE.Scene();
		scene.background = new THREE.Color(0xbfe3dd);
		return scene;
	}

	createCamera(canvasSize) {
		const camera = new THREE.PerspectiveCamera(
			75,
			canvasSize.width / canvasSize.height
		);
		camera.position.z = 15;
		camera.position.y = 13;
		camera.position.x = 8;
		return camera;
	}

	createLights() {
		const lightColor = 0xffffff;

		const ambientLight = new THREE.AmbientLight(lightColor, 0.5);
		this._scene.add(ambientLight);

		const directionalLight = new THREE.DirectionalLight(lightColor, 0.8);
		directionalLight.position.set(1, 1, 1);
		this._scene.add(directionalLight);
	}

	createRenderer(threeCanvas, size) {
		const renderer = new THREE.WebGLRenderer({
			canvas: threeCanvas,
			alpha: true
		});

		renderer.setSize(size.width, size.height);
		renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
		return renderer;
	}

	createControls(canvas) {
		const controls = new OrbitControls(this._camera, canvas);
		controls.enableDamping = true;
		controls.addEventListener("change", () => {
			this.renderCanvas();
		});
	}

	async loadModel() {
		const { IFCLoader } = await import("web-ifc-three");
		const ifcLoader = new IFCLoader();
		await ifcLoader.ifcManager.setWasmPath("/static/ifc/wasm/");
		ifcLoader.load(this.sourceUrl, (ifcModel) => {
			this._scene.add(ifcModel);
			this.renderCanvas();
		});
	}

	onAreaChanged() {
		const newSize = this.getContainerSize();
		this._camera.aspect = newSize.width / newSize.height;
		this._camera.updateProjectionMatrix();
		this._renderer.setSize(newSize.width, newSize.height);
		this.renderCanvas();
	}

	getContainerSize() {
		const container = this.querySelector(".three-container");
		return {
			width: container.offsetWidth,
			height: container.offsetHeight
		};
	}

	renderCanvas() {
		this._renderer.render(this._scene, this._camera);
	}
}

customElements.define("three-js-component", ThreeJsWebComponent);
