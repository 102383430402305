import { useClientSideDataGridModel } from "src/components/common/dataGrid/gridModel/useClientSideDataGridModel";
import { VatCodeApi } from "src/api/generated/erp/sales/basedata/api/vatCodeApi.ts";
import { ControlledAsyncCrudDataGrid } from "src/components/common/dataGrid/crud/ControlledAsyncCrudDataGrid";
import { booleanColumn, floatColumn, textColumn } from "src/components/common/dataGrid/columns.tsx";
import i18n from "i18next";
import { VatCodeForm } from "src/components/views/erp/sales/basedata/vatCodes/VatCodeForm.tsx";
import { AsyncMenuButton } from "src/components/common/contextMenu/AsyncMenuButton.tsx";
import { VatCode } from "src/api/generated/erp/db/types/tables/vatCode.ts";
import { faBan, faCheck, faUndo } from "@fortawesome/pro-regular-svg-icons";

export const VatCodesDataGrid = () => {
	const { dataGridProps, selectedRows, refreshData } = useClientSideDataGridModel({
		fetchData: () => VatCodeApi.getVatCodes(),
		initialParams: {},
		getRowId: (row) => row.vatCodeId,
		gridId: "9B02BA3E9FE43E98",
	});

	return (
		<ControlledAsyncCrudDataGrid<VatCode>
			disableMultipleRowSelection
			columns={[
				textColumn({
					field: "vatCodeName",
					headerName: i18n.t("name"),
					width: 200,
				}),
				floatColumn({
					field: "vatPercent",
					headerName: i18n.t("vat_percent"),
				}),
				booleanColumn({
					field: "isDefault",
					headerName: i18n.t("default"),
				}),
				booleanColumn({
					field: "isActive",
					headerName: i18n.t("active"),
				}),
			]}
			form={{
				addRowEnabled: true,
				editEnabled: true,
				dialogTitle: i18n.t("vat_code"),
				dialogSize: "sm",
				component: ({ row, ...other }) => <VatCodeForm vatCode={row} {...other} />,
			}}
			remove={() =>
				VatCodeApi.delete_({
					vatCodeIds: selectedRows.map((row) => row.vatCodeId),
				})
			}
			rowContextMenuComponents={({ row }) => [
				!row.isDefault && (
					<AsyncMenuButton
						key={"setToDefault"}
						icon={faCheck}
						label={i18n.t("set_to_default")}
						onClick={async () => {
							await VatCodeApi.setToDefaultVatCode({ vatCodeId: row.vatCodeId });
							await refreshData();
						}}
					/>
				),
				<AsyncMenuButton
					key={"setIsActive"}
					icon={row.isActive ? faBan : faUndo}
					label={row.isActive ? i18n.t("deactivate") : i18n.t("activate")}
					onClick={async () => {
						await VatCodeApi.setVatCodeIsActive({
							vatCodeId: row.vatCodeId,
							isActive: !row.isActive,
						});
						await refreshData();
					}}
				/>,
			]}
			{...dataGridProps}
		/>
	);
};
