import { FormCommonProps } from "src/components/common/forms/types.ts";
import { WorkspaceCategory } from "src/api/generated/postgres/db/types/workspaces/tables/workspaceCategory.ts";
import { AavoForm } from "src/components/common/forms/AavoForm.tsx";
import { FormTextField } from "src/components/common/forms/fields/FormTextField.tsx";
import { requireRule } from "src/components/common/forms/validation.ts";
import { WorkspaceCategoryApi } from "src/api/generated/workspaces/api/workspaceCategoryApi.ts";
import i18n from "i18next";

export interface WorkspaceCategoryFormProps extends FormCommonProps<number> {
	workspaceCategory: WorkspaceCategory | undefined;
}

export const WorkspaceCategoryForm = ({ workspaceCategory, onFormEdited, onCompleted }: WorkspaceCategoryFormProps) => {
	return (
		<AavoForm
			defaultValues={workspaceCategory ?? {}}
			submit={submit}
			onCompleted={onCompleted}
			onFormEdited={onFormEdited}
			render={({ control }) => (
				<>
					<FormTextField control={control} name={"categoryName"} label={i18n.t("name")} rules={requireRule()} />
					<FormTextField
						control={control}
						name={"categoryDescription"}
						label={i18n.t("description")}
						rules={requireRule()}
					/>
				</>
			)}
		/>
	);

	async function submit(values: WorkspaceCategory) {
		if (values.id != null) {
			return await WorkspaceCategoryApi.update({
				workspaceCategory: values,
			});
		} else {
			return await WorkspaceCategoryApi.insert({
				workspaceCategory: values,
			});
		}
	}
};
