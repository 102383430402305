import { faTable } from "@fortawesome/pro-regular-svg-icons";
import { AsyncMenuButton } from "src/components/common/contextMenu/AsyncMenuButton.tsx";
import { createAndDownloadFile } from "src/utils/fileDownloading.ts";
import { SitePartActionBarMenuComponentsProps } from "src/tenantCustomizations/TenantCustomizations.ts";
import { SitePartDataGridApi } from "src/api/generated/erp/parts/sitePart/api/sitePartDataGridApi.ts";
import { expectNonFileResult } from "src/components/common/dataGrid/gridModel/serverSideDataModelUtils.ts";
import Papa from "papaparse";
import dayjs from "dayjs";

export const FeeliaPartsDemandAvgDownloadCsvButton = ({
	useServerSideDataGridModelReturn,
}: SitePartActionBarMenuComponentsProps) => {
	const { currentParams, currentDataModelRequest } = useServerSideDataGridModelReturn;
	return (
		<AsyncMenuButton
			key={"feeliaPartsDemandAvgDownloadCsvButton"}
			icon={faTable}
			label={"Lataa keskiarvoennuste"}
			onClick={async () => {
				const data = await SitePartDataGridApi.searchSiteParts({
					...currentParams,
					dataModelRequest: {
						...currentDataModelRequest,
						resultType: "DATA",
						paginationModel: {
							page: 0,
							pageSize: 10000,
						},
					},
				});
				const { rows } = expectNonFileResult(data);
				const exportRows = rows.map((row) => {
					const demandPerDayAvg = row.reorderPointCalculationParams?.demandPerDayAvg ?? 0;
					const demandPerWeek = demandPerDayAvg * 5;
					const averages = Array(24).fill(demandPerWeek.toLocaleString("fi-FI"));
					const partInfo = ["FO00002", 1000, row.partNo, dayjs().local().format("D.M.YYYY")];
					return partInfo.concat(averages);
				});

				const csv = Papa.unparse(exportRows, {
					delimiter: ";",
					skipEmptyLines: true,
				});

				createAndDownloadFile("partsDemandAverages.csv", csv);
			}}
		/>
	);
};
