import { Divider, DividerProps } from "@mui/material";
import { mergeSx } from "src/utils/styles.ts";
import Typography from "@mui/material/Typography";

export interface AavoMenuDividerProps extends DividerProps {
	label?: string;
}

export const AavoMenuDivider = ({
	sx,
	label,
	textAlign = "right",
	children,
	...other
}: AavoMenuDividerProps) => {
	const content =
		children != null ? children
		: label != null ? <Typography variant="body2">{label}</Typography>
		: null;

	return (
		<Divider
			sx={mergeSx(
				{
					gridColumn: "1 / -1",
					color: "primary.main",
				},
				sx,
			)}
			textAlign={textAlign}
			children={content}
			{...other}
		/>
	);
};
