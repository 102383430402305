// This file is automatically generated. Do not edit manually.

import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace SpcDataCollectionApi {
	export async function finishTimeBasedRecord(args: { controlChartId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/spc/dataCollection/finishTimeBasedRecord",
			method: "POST",
			data: {
				controlChartId: args.controlChartId,
			},
		});
		return response as void;
	}

	export async function revertLastRecordToIncomplete(args: { controlChartId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/spc/dataCollection/revertLastRecordToIncomplete",
			method: "POST",
			data: {
				controlChartId: args.controlChartId,
			},
		});
		return response as void;
	}
}
