// This file is automatically generated. Do not edit manually.

import { IsoDateString } from "src/types/dateTime";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace InvoiceUpdatingApi {
	export async function updateInvoice(args: {
		invoiceId: number;
		invoicePrintoutText: string;
		invoiceDate: IsoDateString;
		externalId: string | null | undefined;
	}): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/invoicing/updateInvoice",
			method: "POST",
			data: {
				invoiceId: args.invoiceId,
				invoicePrintoutText: args.invoicePrintoutText,
				invoiceDate: args.invoiceDate,
				externalId: args.externalId,
			},
		});
		return response as void;
	}
}
