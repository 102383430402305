import { floatColumn, integerColumn, textColumn } from "src/components/common/dataGrid/columns.tsx";
import i18n from "i18next";
import { DeliveryPackageLineView } from "src/api/generated/erp/db/types/tables/deliveryPackageLineView.ts";
import { AsyncButton } from "src/components/common/buttons/AsyncButton.tsx";
import { faMinus } from "@fortawesome/pro-regular-svg-icons";
import { ControlledAsyncCrudDataGrid } from "src/components/common/dataGrid/crud/ControlledAsyncCrudDataGrid.tsx";
import { RefreshableElementProps, RefreshableElementRef } from "src/utils/useRefreshRef.ts";
import { useGenericDialog } from "src/components/common/dialogs/useGenericDialog.ts";
import { DeliveryPackageView } from "src/api/generated/erp/db/types/tables/deliveryPackageView.ts";
import { useClientSideDataGridModel } from "src/components/common/dataGrid/gridModel/useClientSideDataGridModel.tsx";
import { DeliveryPackageApi } from "src/api/generated/erp/delivery/api/deliveryPackageApi.ts";
import { DecreaseDeliveryPackageLineQuantityForm } from "src/components/views/erp/delivery/deliveryPackage/picking/DecreaseDeliveryPackageLineQuantityForm.tsx";

export interface DeliveryPackageLinesDataGridProps extends RefreshableElementProps {
	deliveryPackage: DeliveryPackageView;
	editable?: boolean;
	refreshRef?: RefreshableElementRef;
	pickableLinesViewRefreshRef?: RefreshableElementRef;
}

export const DeliveryPackageLinesDataGrid = ({
	deliveryPackage,
	pickableLinesViewRefreshRef,
	refreshRef,
	editable = true,
}: DeliveryPackageLinesDataGridProps) => {
	const { dataGridProps, onlySelectedRow, refreshData } = useClientSideDataGridModel({
		fetchData: () =>
			DeliveryPackageApi.getPackageLines({
				deliveryPackageId: deliveryPackage.deliveryPackageId,
			}),
		initialParams: {},
		getRowId: (row) => row.deliveryPackageLineId,
		gridId: "DF172DA1E15F6901",
		refreshRef: refreshRef,
	});

	const { openDialog } = useGenericDialog();

	return (
		<ControlledAsyncCrudDataGrid<DeliveryPackageLineView>
			columns={[
				integerColumn({
					field: "sourceLineNumber",
					headerName: i18n.t("line_number"),
				}),
				textColumn({
					field: "partLongName",
					headerName: i18n.t("part"),
					width: 300,
				}),
				floatColumn({
					field: "quantityInBaseUnit",
					headerName: i18n.t("picked_quantity"),
					width: 120,
				}),
				textColumn({
					field: "partUnit",
					headerName: i18n.t("unit"),
				}),
				textColumn({
					field: "sourceMark",
					headerName: i18n.t("mark"),
				}),
			]}
			actionBarComponents={
				<>
					{editable && (
						<AsyncButton
							icon={faMinus}
							label={i18n.t("delete")}
							disabled={() => {
								if (!onlySelectedRow) return i18n.t("select_line");
								if (deliveryPackage.deliveryState === "DELIVERED")
									return i18n.t("package_is_delivered");
								if (deliveryPackage.state === "COMPLETED") return i18n.t("package_is_picked");
								return false;
							}}
							variant={"outlined"}
							onClick={async () => {
								if (onlySelectedRow) {
									await openDecreaseQuantityDialog(onlySelectedRow);
								}
							}}
						/>
					)}
				</>
			}
			{...dataGridProps}
		/>
	);

	async function openDecreaseQuantityDialog(row: DeliveryPackageLineView) {
		openDialog(({ closeDialog }) => ({
			title: i18n.t("delete"),
			size: "sm",
			content: (
				<DecreaseDeliveryPackageLineQuantityForm
					deliveryPackageLine={row}
					onCompleted={async () => {
						await closeDialog();
						await Promise.all([pickableLinesViewRefreshRef?.refresh(), refreshData()]);
					}}
				/>
			),
		}));
	}
};
