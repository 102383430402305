import { AsyncForm } from "src/components/common/forms/AsyncForm.tsx";
import {
	UserNotificationsOfSourceEditApi,
	UserNotificationsOfSourceEditApi_InitData,
} from "src/api/generated/common/userNotifications/api/userNotificationsOfSourceEditApi.ts";
import { FormCheckbox } from "src/components/common/forms/fields/FormCheckbox.tsx";
import i18n from "i18next";
import { getDayOfWeekLabels } from "src/api/generated/java/time/dayOfWeek.ts";
import { requireRule } from "src/components/common/forms/validation.ts";
import { FormSelectField } from "src/components/common/forms/fields/FormSelectField.tsx";
import { UserNotificationSourceType } from "src/api/generated/postgres/db/types/public_/enums/userNotificationSourceType.ts";
import { FormCommonProps } from "src/components/common/forms/types.ts";
import { UserNotifications } from "src/api/generated/postgres/db/types/public_/tables/userNotifications.ts";
import { FormMultiSelectField } from "src/components/common/forms/fields/FormMultiSelectField.tsx";
import { enumOptions } from "src/components/common/forms/fields/selectionFieldUtils.ts";
import { FormTimeField } from "src/components/common/forms/FormTimeField.tsx";

export interface UserNotificationsOfSourceFormProps extends FormCommonProps<number> {
	userNotificationId: number | null;
	sourceType: UserNotificationSourceType;
	sourceId: number;
}

export const UserNotificationsOfSourceForm = ({
	userNotificationId,
	sourceType,
	sourceId,
	onCompleted,
	onFormEdited,
}: UserNotificationsOfSourceFormProps) => {
	return (
		<AsyncForm<UserNotificationsOfSourceEditApi_InitData, UserNotifications, number>
			fetch={() =>
				UserNotificationsOfSourceEditApi.getInitData({
					userNotificationId: userNotificationId,
					sourceType: sourceType,
					sourceId: sourceId,
				})
			}
			getDefaultValues={({ userNotifications }) =>
				userNotifications != null ? userNotifications : (
					{
						emailNotifications: true,
						pushNotifications: true,
						enabledWeekdays: ["MONDAY", "TUESDAY", "WEDNESDAY", "THURSDAY", "FRIDAY"],
						enabledTo: "16:00:00",
						enabledFrom: "08:00:00",
						sourceType: sourceType,
						sourceId: sourceId,
					}
				)
			}
			submit={(values) =>
				values.id == null ?
					UserNotificationsOfSourceEditApi.insert({ userNotification: values })
				:	UserNotificationsOfSourceEditApi.update({
						userNotification: values,
					})
			}
			render={({ data: { userOptions }, control }) => (
				<>
					<FormSelectField
						control={control}
						name={"appUserId"}
						label={i18n.t("user")}
						options={userOptions}
						getOptionKey={(option) => option.id}
						getOptionLabel={(option) => option.name}
						rules={requireRule()}
					/>
					<FormCheckbox
						control={control}
						name={"emailNotifications"}
						label={i18n.t("email_notifications")}
					/>
					<FormCheckbox
						control={control}
						name={"pushNotifications"}
						label={i18n.t("push_notifications")}
					/>
					<FormTimeField
						control={control}
						name={"enabledFrom"}
						label={i18n.t("enabled_from")}
						rules={requireRule()}
					/>
					<FormTimeField
						control={control}
						name={"enabledTo"}
						label={i18n.t("enabled_to")}
						rules={requireRule()}
					/>
					<FormMultiSelectField
						options={enumOptions(getDayOfWeekLabels())}
						control={control}
						name={"enabledWeekdays"}
						label={i18n.t("enabled_weekdays")}
						rules={requireRule()}
					/>
				</>
			)}
			onCompleted={onCompleted}
			onFormEdited={onFormEdited}
		/>
	);
};
