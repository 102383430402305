// This file is automatically generated. Do not edit manually.

import { FutureInventoryEvent } from "src/api/generated/erp/warehouse/inventory/inventoryEvent/futureInventoryEvent";
import { PartCumulativeInventoryLevel } from "src/api/generated/erp/warehouse/inventory/inventoryEvent/partCumulativeInventoryLevel";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace FutureInventoryEventsApi {
	export async function getFutureInventoryEvents(args: { partId: number }): Promise<Array<FutureInventoryEvent>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/api/warehouse/inventory/futureInventoryEvents/getFutureInventoryEvents",
			method: "POST",
			data: {
				partId: args.partId,
			},
		});
		return response as Array<FutureInventoryEvent>;
	}

	export async function getFutureCumulativeInventoryEventData(args: {
		partId: number;
	}): Promise<Array<PartCumulativeInventoryLevel>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/api/warehouse/inventory/futureInventoryEvents/getFutureCumulativeInventoryEventData",
			method: "POST",
			data: {
				partId: args.partId,
			},
		});
		return response as Array<PartCumulativeInventoryLevel>;
	}
}
