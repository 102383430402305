import { AavoFileInput } from "src/components/common/inputFields/fileInput/AavoFileInput.tsx";
import { uploadFile } from "src/utils/fileUploading.ts";
import { UnmanagedFilesApi } from "src/api/generated/documents/unmanagedFiles/unmanagedFilesApi.ts";
import { useErrorDialog } from "src/components/common/dialogs/errorDialog/userErrorDialog.ts";
import { useGenericDialog } from "src/components/common/dialogs/useGenericDialog.ts";
import { useState } from "react";
import { HorizontalBox } from "src/components/common/box/HorizontalBox.tsx";
import i18n from "i18next";
import { AavoLoading } from "src/components/common/AavoLoading.tsx";
import { getFileExtension } from "src/utils/fileUtils.ts";
import { AsyncButton } from "src/components/common/buttons/AsyncButton.tsx";
import { faExternalLinkAlt } from "@fortawesome/pro-regular-svg-icons";
import { SurveyFieldValue_DocumentsValue_Document } from "src/api/generated/io/aavo/applications/db/erp/types/surveyFieldValue.ts";
import { useStaticDataGridModel } from "src/components/common/dataGrid/gridModel/useStaticDataGridModel.tsx";
import { textColumn } from "src/components/common/dataGrid/columns.tsx";
import { labelWithRequiredMark } from "src/components/views/erp/surveys/surveyUtils.ts";
import { UnmanagedFilePreviewView } from "src/components/views/documents/preview/UnmanagedFilePreviewView.tsx";
import { AavoDataGrid } from "src/components/common/dataGrid/AavoDataGrid.tsx";
import { AavoMosaic } from "src/components/common/mosaic/AavoMosaic.tsx";
import { CenteredTypography } from "src/components/common/CenteredTypography.tsx";
import { SurveySubmissionFormFieldProps } from "src/components/views/erp/surveys/submissions/fields/SurveySubmissionFormField.tsx";

export const SurveySubmissionFormDocumentField = ({
	field,
	value: valueProp,
	onChange: onChangeProp,
	error,
	disabled,
}: SurveySubmissionFormFieldProps) => {
	const { logErrorAndShowOnDialog } = useErrorDialog();
	const { openDialog } = useGenericDialog();

	const valueDocuments: SurveyFieldValue_DocumentsValue_Document[] =
		valueProp?.type === "documents" ? valueProp.documents : [];

	const [files, setFiles] = useState<File[]>(
		valueDocuments.map((document) => new File([], document.fileName)), // Create dummy Files
	);

	const [valueHasChanged, setValueHasChanged] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	return (
		<HorizontalBox gap={1}>
			<AavoFileInput<true>
				multiple={field.documentFieldAllowMultiple as any}
				value={files}
				label={labelWithRequiredMark(field)}
				error={error}
				placeholder={i18n.t("select_a_file")}
				onChange={onChange}
				disabled={disabled}
				hideSizeText={!valueHasChanged} // Hide the size text when "fake File" created from current value
				InputProps={{
					endAdornment: isLoading ? <AavoLoading size={"xl"} /> : undefined,
				}}
				sx={{
					flex: 1,
				}}
			/>
			{valueDocuments.length > 0 && (
				<AsyncButton icon={faExternalLinkAlt} tooltip={i18n.t("open")} onClick={openSelectedFile} />
			)}
		</HorizontalBox>
	);

	async function onChange(newValue: File[] | File | null) {
		try {
			const newFiles =
				newValue == null ? []
				: Array.isArray(newValue) ? newValue
				: [newValue];

			setFiles(newFiles);
			setValueHasChanged(true);
			if (newFiles.length === 0) {
				await onChangeProp(null);
				return;
			}
			setIsLoading(true);
			const documents = await Promise.all(
				newFiles.map(async (file) => {
					const fileHandle = await uploadFile(file);
					const fileUuid = await UnmanagedFilesApi.createUnmanagedFile({ fileHandle });
					return {
						unmanagedFileUuid: fileUuid,
						fileName: file.name,
					};
				}),
			);
			onChangeProp({
				type: "documents",
				documents: documents,
			});
		} catch (e) {
			logErrorAndShowOnDialog(e);
		} finally {
			setIsLoading(false);
		}
	}

	async function openSelectedFile() {
		openDialog({
			size: "fullscreen",
			title: i18n.t("files"),
			content: <SelectedDocumentsPreviewView documents={valueDocuments} />,
		});
	}
};

interface SelectedDocumentsPreviewViewProps {
	documents: SurveyFieldValue_DocumentsValue_Document[];
}

const SelectedDocumentsPreviewView = ({ documents }: SelectedDocumentsPreviewViewProps) => {
	const [selectedDocument, setSelectedDocument] = useState<SurveyFieldValue_DocumentsValue_Document | undefined>(
		documents[0],
	);

	const documentPreviewView =
		selectedDocument == null ?
			<CenteredTypography>{i18n.t("select_document")}</CenteredTypography>
		:	<UnmanagedFilePreviewView
				key={selectedDocument.unmanagedFileUuid}
				fileUuid={selectedDocument.unmanagedFileUuid}
				extension={getFileExtension(selectedDocument.fileName)!}
			/>;

	if (documents.length === 1) {
		return documentPreviewView;
	}

	return (
		<AavoMosaic
			viewId={"5335CACBFF439AF7"}
			layout={{
				type: "row",
				splitPercentage: 20,
				first: {
					type: "panel",
					title: i18n.t("files"),
					content: (
						<SelectedDocumentsDataGrid
							documents={documents}
							selectedDocument={selectedDocument}
							onSelectionChanged={setSelectedDocument}
						/>
					),
				},
				second: {
					type: "panel",
					title: i18n.t("preview"),
					content: documentPreviewView,
				},
			}}
		/>
	);
};

interface SelectedDocumentsDataGridProps {
	documents: SurveyFieldValue_DocumentsValue_Document[];
	selectedDocument: SurveyFieldValue_DocumentsValue_Document | undefined;
	onSelectionChanged: (document: SurveyFieldValue_DocumentsValue_Document | undefined) => void;
}

const SelectedDocumentsDataGrid = ({
	documents,
	selectedDocument,
	onSelectionChanged,
}: SelectedDocumentsDataGridProps) => {
	const { dataGridProps } = useStaticDataGridModel({
		rows: documents,
		gridId: "C01BE7D2B971DAF9",
		getRowId: (document) => document.unmanagedFileUuid,
		selectedRows: selectedDocument ? [selectedDocument] : [],
		onSelectionChanged: (rows) => onSelectionChanged(rows[0]),
	});

	return (
		<AavoDataGrid<SurveyFieldValue_DocumentsValue_Document>
			columns={[
				textColumn({
					field: "fileName",
					headerName: i18n.t("file"),
					width: 200,
				}),
			]}
			disableMultipleRowSelection
			{...dataGridProps}
		/>
	);
};
