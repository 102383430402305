import { mergeSx } from "src/utils/styles.ts";
import { Box, BoxProps } from "@mui/material";

export const InheritLayoutBox = ({ sx, ...other }: BoxProps) => {
	return (
		<Box
			sx={mergeSx(
				{
					gridColumn: "1 / -1",
					display: "inherit",
					gridTemplateColumns: "inherit",
					gap: "inherit",
					flexDirection: "inherit",
				},
				sx,
			)}
			{...other}
		/>
	);
};
