// This file is automatically generated. Do not edit manually.

import { CatalogPartRevisionView } from "src/api/generated/erp/db/types/tables/catalogPartRevisionView";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace ProductFamilyVersionConnectedRevisionsDataGridApi {
	export async function getDataGridData(args: {
		productFamilyVersionId: number;
		searchQuery: string;
	}): Promise<Array<CatalogPartRevisionView>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/configurator/managing/productFamily/version/connectedRevisionsDataGrid/getDataGridData",
			method: "POST",
			data: {
				productFamilyVersionId: args.productFamilyVersionId,
				searchQuery: args.searchQuery,
			},
		});
		return response as Array<CatalogPartRevisionView>;
	}
}
