import { ProductionLineGroupsDataGrid } from "src/components/views/erp/production/baseData/productionLines/ProductionLineGroupsDataGrid.tsx";
import { AavoMosaic } from "src/components/common/mosaic/AavoMosaic.tsx";
import { ProductionLinesDataGrid } from "src/components/views/erp/production/baseData/productionLines/ProductionLinesDataGrid.tsx";
import { CenteredTypography } from "src/components/common/CenteredTypography.tsx";
import i18n from "i18next";
import { useState } from "react";
import { ProductionLineGroup } from "src/api/generated/erp/db/types/tables/productionLineGroup.ts";
import { ProductionLine } from "src/api/generated/erp/db/types/tables/productionLine.ts";
import { ProductionLineCapacityDataGrid } from "src/components/views/erp/production/baseData/productionLines/capacity/ProductionLineCapacityDataGrid.tsx";
import { ProductionLineWorkCentersDataGrid } from "src/components/views/erp/production/baseData/productionLines/productionLineWorkCenters/ProductionLineWorkCentersDataGrid.tsx";

export const ProductionLinesView = () => {
	const [selectedProductionLineGroup, setSelectedProductionLineGroup] = useState<ProductionLineGroup | undefined>(
		undefined,
	);
	const [selectedProductionLine, setSelectedProductionLine] = useState<ProductionLine | undefined>(undefined);

	return (
		<AavoMosaic
			viewId={"758797332987FE50"}
			layout={{
				type: "row",
				first: {
					type: "column",
					first: {
						type: "panel",
						title: i18n.t("production_line_groups"),
						content: (
							<ProductionLineGroupsDataGrid
								onSelectionChanged={(newSelection) => {
									setSelectedProductionLineGroup(newSelection);
									setSelectedProductionLine(undefined);
								}}
							/>
						),
					},
					second: {
						type: "panel",
						title: i18n.t("production_lines"),
						content:
							selectedProductionLineGroup === undefined ?
								<CenteredTypography>{i18n.t("select_group")}</CenteredTypography>
							:	<ProductionLinesDataGrid
									key={selectedProductionLineGroup.productionLineGroupId}
									productionLineGroupId={selectedProductionLineGroup.productionLineGroupId}
									onSelectionChanged={setSelectedProductionLine}
								/>,
					},
				},
				second: {
					type: "tabs",
					items: [
						{
							type: "panel",
							title: i18n.t("capacity"),
							content:
								selectedProductionLine === undefined ?
									<CenteredTypography>{i18n.t("select_production_line")}</CenteredTypography>
								:	<ProductionLineCapacityDataGrid
										key={selectedProductionLine.productionLineId}
										productionLineId={selectedProductionLine.productionLineId}
									/>,
						},
						{
							type: "panel",
							title: i18n.t("work_centers"),
							content:
								selectedProductionLine === undefined ?
									<CenteredTypography>{i18n.t("select_production_line")}</CenteredTypography>
								:	<ProductionLineWorkCentersDataGrid
										key={selectedProductionLine.productionLineId}
										productionLineId={selectedProductionLine.productionLineId}
									/>,
						},
					],
				},
			}}
		/>
	);
};
