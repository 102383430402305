import { AavoFileInput } from "src/components/common/inputFields/fileInput/AavoFileInput";
import { useState } from "react";
import { VerticalBox } from "src/components/common/box/VerticalBox";
import { AsyncButton } from "src/components/common/buttons/AsyncButton.tsx";
import { uploadFile } from "src/utils/fileUploading.ts";
import { FuturaPlanHolzmaFileModifierApi } from "../../../../api/generated/tenants/mellano/holzma/futuraPlanHolzmaFileModifierApi.ts";
import { downloadFile } from "../../../../utils/fileDownloading.ts";

export const FuturaPlanHolzmaFileModifierView = () => {
	const [file, setFile] = useState<File | null>(null);

	return (
		<VerticalBox
			sx={{
				flex: 1,
				padding: 1,
				gap: 1,
			}}
		>
			<AavoFileInput
				value={file}
				multiple={false}
				onChange={setFile}
				placeholder={"Valitse tiedosto"}
				accept={"application/vnd.ms-excel"}
				sx={{}}
			/>
			<AsyncButton
				label={"Muunna"}
				disabled={file === null}
				variant={"contained"}
				color={"primary"}
				sx={{
					alignSelf: "flex-start",
				}}
				onClick={async () => {
					if (file === null) return;
					const inputFileHandle = await uploadFile(file);
					const outputFileHandle = await FuturaPlanHolzmaFileModifierApi.modifyFuturaPlanFile({
						inputFileHandle: inputFileHandle,
					});
					downloadFile(outputFileHandle);
				}}
			/>
		</VerticalBox>
	);
};
