// This file is automatically generated. Do not edit manually.

import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace ProductionPlanningActionsApi {
	export async function setProductionLineForShopOrders(args: {
		productionLineId: number;
		shopOrderIds: Array<number>;
	}): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/productionPlanning/setProductionLineForShopOrders",
			method: "POST",
			data: {
				productionLineId: args.productionLineId,
				shopOrderIds: args.shopOrderIds,
			},
		});
		return response as void;
	}
}
