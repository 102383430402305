// This file is automatically generated. Do not edit manually.

import { ShopOrderState } from "src/api/generated/erp/db/types/enums/shopOrderState";
import { ServerSideDataModelRequest } from "src/api/generated/common/dataGrids/serverSideDataModelRequest";
import { ServerSideDataModelResult } from "src/api/generated/common/dataGrids/serverSideDataModelResult";
import { ShopOrderView } from "src/api/generated/erp/db/types/tables/shopOrderView";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace ShopOrderDataGridApi {
	export async function getData(args: {
		searchQuery: string;
		shopOrderStateFilter?: ShopOrderState | null | undefined;
		onlyDefaultSite?: boolean;
		onlyShopOrderId?: number | null | undefined;
		includeCompleted?: boolean;
		includeCancelled?: boolean;
		dataModelRequest: ServerSideDataModelRequest;
	}): Promise<ServerSideDataModelResult<ShopOrderView>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/shopOrders/getData",
			method: "POST",
			data: {
				searchQuery: args.searchQuery,
				shopOrderStateFilter: args.shopOrderStateFilter,
				onlyDefaultSite: args.onlyDefaultSite,
				onlyShopOrderId: args.onlyShopOrderId,
				includeCompleted: args.includeCompleted,
				includeCancelled: args.includeCancelled,
				dataModelRequest: args.dataModelRequest,
			},
		});
		return response as ServerSideDataModelResult<ShopOrderView>;
	}
}
