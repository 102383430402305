import axios from "axios";

export interface ParsedError {
	error: Error;
	message: string;
	backendLogId: string | undefined;
	treatAsError: boolean;
	latestFrontendVersion: string;
}

export const parseError = (error: unknown): ParsedError => {
	if (axios.isAxiosError(error)) {
		if (error.response?.data) {
			return parseError(error.response.data);
		}
	}

	return {
		error: castToError(error),
		message: getErrorMessage(error),
		backendLogId: getErrorBackendLogId(error),
		treatAsError: getTreatAsError(error),
		latestFrontendVersion: getLatestFrontendVersion(error),
	};
};

const castToError = (error: unknown): Error => {
	if (error instanceof Error) return error;
	return Error(getErrorMessage(error));
};

const getErrorMessage = (error: unknown): string => {
	if (isObjectWithMessage(error)) return error.message;

	try {
		return JSON.stringify(error);
	} catch {
		// fallback in case there's an error stringifying the error
		// like with circular references for example.
		return String(error);
	}
};

const isObjectWithMessage = (error: unknown): error is { message: string } =>
	typeof error === "object" &&
	error !== null &&
	"message" in error &&
	typeof (error as Record<string, unknown>).message === "string";

const getErrorBackendLogId = (error: unknown): string | undefined => {
	if (typeof error === "object" && error != null && "logId" in error)
		return String((error as Record<string, unknown>).logId);

	return undefined;
};

const getTreatAsError = (error: unknown): boolean => {
	if (typeof error === "object" && error != null && "logAsError" in error)
		return (error as Record<string, unknown>).logAsError === true;

	return true;
};

const getLatestFrontendVersion = (error: unknown): string => {
	if (typeof error === "object" && error != null && "latestFrontendVersion" in error)
		return String((error as Record<string, unknown>).latestFrontendVersion);

	return "";
};
