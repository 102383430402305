// This file is automatically generated. Do not edit manually.

import { ProductionLineGroup } from "src/api/generated/erp/db/types/tables/productionLineGroup";
import { Operation } from "src/api/generated/erp/db/types/tables/operation";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace ProductionLineGroupEditApi {
	export async function getFormInitData(args: {
		productionLineGroupId: number | null | undefined;
	}): Promise<ProductionLineGroupEditApi_FormInitData> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/productionLine/groups/getFormInitData",
			method: "POST",
			data: {
				productionLineGroupId: args.productionLineGroupId,
			},
		});
		return response as ProductionLineGroupEditApi_FormInitData;
	}

	export async function insert(args: { productionLineGroup: ProductionLineGroup }): Promise<number> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/productionLine/groups/insert",
			method: "POST",
			data: {
				productionLineGroup: args.productionLineGroup,
			},
		});
		return response as number;
	}

	export async function update(args: { productionLineGroup: ProductionLineGroup }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/productionLine/groups/update",
			method: "POST",
			data: {
				productionLineGroup: args.productionLineGroup,
			},
		});
		return response as void;
	}

	export async function delete_(args: { productionLineGroupId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/productionLine/groups/delete",
			method: "POST",
			data: {
				productionLineGroupId: args.productionLineGroupId,
			},
		});
		return response as void;
	}
}

export interface ProductionLineGroupEditApi_FormInitData {
	operationOptions: Array<Operation>;
	productionLineGroup: ProductionLineGroup | null | undefined;
}
