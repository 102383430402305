// This file is automatically generated. Do not edit manually.

import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace PartWarehouseLocationActionsApi {
	export async function deactivatePartWarehouseLocation(args: { partWarehouseLocationId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sitePart/partWarehouseLocations/deactivatePartWarehouseLocation",
			method: "POST",
			data: {
				partWarehouseLocationId: args.partWarehouseLocationId,
			},
		});
		return response as void;
	}

	export async function activatePartWarehouseLocation(args: { partWarehouseLocationId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sitePart/partWarehouseLocations/activatePartWarehouseLocation",
			method: "POST",
			data: {
				partWarehouseLocationId: args.partWarehouseLocationId,
			},
		});
		return response as void;
	}

	export async function setPartWarehouseLocationToDefault(args: { partWarehouseLocationId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sitePart/partWarehouseLocations/setPartWarehouseLocationToDefault",
			method: "POST",
			data: {
				partWarehouseLocationId: args.partWarehouseLocationId,
			},
		});
		return response as void;
	}
}
