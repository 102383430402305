// This file is automatically generated. Do not edit manually.

import { ReclamationCause } from "src/api/generated/erp/db/types/tables/reclamationCause";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace ReclamationCauseDataGridApi {
	export async function getAll(): Promise<Array<ReclamationCause>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/reclamations/reclamationCause/getAll",
			method: "POST",
			data: {},
		});
		return response as Array<ReclamationCause>;
	}

	export async function save(args: { reclamationCauses: Array<ReclamationCause> }): Promise<Array<ReclamationCause>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/reclamations/reclamationCause/save",
			method: "POST",
			data: {
				reclamationCauses: args.reclamationCauses,
			},
		});
		return response as Array<ReclamationCause>;
	}

	export async function delete_(args: { reclamationCauses: Array<ReclamationCause> }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/reclamations/reclamationCause/delete",
			method: "POST",
			data: {
				reclamationCauses: args.reclamationCauses,
			},
		});
		return response as void;
	}

	export async function setIsActive(args: { reclamationCauseId: number; isActive: boolean }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/reclamations/reclamationCause/setIsActive",
			method: "POST",
			data: {
				reclamationCauseId: args.reclamationCauseId,
				isActive: args.isActive,
			},
		});
		return response as void;
	}
}
