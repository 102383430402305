// This file is automatically generated. Do not edit manually.

import { IsoDateString } from "src/types/dateTime";
import { ServerSideDataModelRequest } from "src/api/generated/common/dataGrids/serverSideDataModelRequest";
import { ServerSideDataModelResult } from "src/api/generated/common/dataGrids/serverSideDataModelResult";
import { PurchaseOrderLineView } from "src/api/generated/erp/db/types/tables/purchaseOrderLineView";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace PurchaseOrderLinesDataGridApi {
	export async function getData(args: {
		purchaseOrderId: number | null | undefined;
		searchQuery: string;
		showCancelledLines: boolean;
		showReceivedLines: boolean;
		fromDate: IsoDateString | null | undefined;
		toDate: IsoDateString | null | undefined;
		dataModelRequest: ServerSideDataModelRequest;
	}): Promise<PurchaseOrderLinesDataGridApi_DataGridData> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/purchase/purchaseOrder/purchaseOrderLinesDataGrid/getData",
			method: "POST",
			data: {
				purchaseOrderId: args.purchaseOrderId,
				searchQuery: args.searchQuery,
				showCancelledLines: args.showCancelledLines,
				showReceivedLines: args.showReceivedLines,
				fromDate: args.fromDate,
				toDate: args.toDate,
				dataModelRequest: args.dataModelRequest,
			},
		});
		return response as PurchaseOrderLinesDataGridApi_DataGridData;
	}
}

export interface PurchaseOrderLinesDataGridApi_DataGridData {
	dataModelResult: ServerSideDataModelResult<PurchaseOrderLineView>;
	purchaseOrderTotalPrice: number | null | undefined;
}
