import { FormCommonProps } from "src/components/common/forms/types.ts";
import { ConfiguratorTableRevision } from "src/api/generated/erp/db/types/tables/configuratorTableRevision.ts";
import {
	ConfiguratorTableRevisionsApi,
	ConfiguratorTableRevisionsApi_FormInitData,
} from "src/api/generated/erp/configurator/tables/api/configuratorTableRevisionsApi.ts";
import { AsyncForm, AsyncFormContentParams } from "src/components/common/forms/AsyncForm.tsx";
import { FormTextField } from "src/components/common/forms/fields/FormTextField.tsx";
import { FormSelectField } from "src/components/common/forms/fields/FormSelectField.tsx";
import { concatWithPipe } from "src/utils/strings.tsx";
import i18n from "i18next";
import { getConfiguratorTableRevisionStateLabel } from "src/api/generated/erp/db/types/enums/configuratorTableRevisionState.ts";
import { requireRule } from "src/components/common/forms/validation.ts";
import { DeepPartial } from "react-hook-form";

export interface ConfiguratorTableRevisionFormProps extends FormCommonProps<number> {
	configuratorTableId: number;
	configuratorTableRevisionId: number | undefined;
}

interface FormValues extends ConfiguratorTableRevision {
	sourceRevisionId: number | undefined;
}

export const ConfiguratorTableRevisionForm = (props: ConfiguratorTableRevisionFormProps) => {
	const { configuratorTableId, configuratorTableRevisionId, onCompleted, onFormEdited } = props;

	return (
		<AsyncForm
			fetch={() =>
				ConfiguratorTableRevisionsApi.getFormInitData({
					configuratorTableId: configuratorTableId,
					configuratorTableRevisionId: configuratorTableRevisionId,
				})
			}
			getDefaultValues={getDefaultValues}
			submit={submit}
			onCompleted={onCompleted}
			onFormEdited={onFormEdited}
			render={(contentParams) => <FormContent {...props} {...contentParams} />}
		/>
	);

	function getDefaultValues({
		revision,
		defaultRevisionNumber,
		sourceRevisionOptions,
	}: ConfiguratorTableRevisionsApi_FormInitData): DeepPartial<FormValues> {
		if (revision) {
			return revision;
		} else {
			return {
				configuratorTableId: configuratorTableId,
				revisionNumber: defaultRevisionNumber,
				sourceRevisionId: sourceRevisionOptions[0]?.configuratorTableRevisionId,
			};
		}
	}

	async function submit(values: FormValues) {
		if (configuratorTableRevisionId) {
			await ConfiguratorTableRevisionsApi.update({
				configuratorTableRevision: values,
			});
			return configuratorTableRevisionId;
		} else {
			return await ConfiguratorTableRevisionsApi.insert({
				configuratorTableId: configuratorTableId,
				sourceRevisionId: values.sourceRevisionId,
				revisionNumber: values.revisionNumber,
				description: values.description,
			});
		}
	}
};

interface FormContentProps
	extends ConfiguratorTableRevisionFormProps,
		AsyncFormContentParams<ConfiguratorTableRevisionsApi_FormInitData, FormValues> {}

const FormContent = ({ configuratorTableRevisionId, control, data: { sourceRevisionOptions } }: FormContentProps) => {
	const isNewRecord = configuratorTableRevisionId == null;

	return (
		<>
			<FormTextField
				control={control}
				name="revisionNumber"
				label={i18n.t("revision_number")}
				rules={requireRule()}
				disabled={!isNewRecord}
			/>
			<FormTextField control={control} name="description" label={i18n.t("description")} autoFocus multiline />
			{isNewRecord && (
				<FormSelectField
					control={control}
					name="sourceRevisionId"
					label={i18n.t("source_revision")}
					options={sourceRevisionOptions}
					getOptionKey={(option) => option.configuratorTableRevisionId}
					getOptionLabel={(option) =>
						concatWithPipe(
							option.revisionNumber,
							getConfiguratorTableRevisionStateLabel(option.state),
							option.description,
						)
					}
				/>
			)}
		</>
	);
};
