import { useState } from "react";
import { DocumentActiveRevisionView } from "src/api/generated/postgres/db/types/documents/tables/documentActiveRevisionView.ts";
import i18n from "i18next";
import { DocumentSearchDataGrid } from "src/components/views/documents/search/DocumentSearchDataGrid.tsx";
import { DocumentPreviewView } from "src/components/views/documents/preview/DocumentPreviewView.tsx";
import { AavoMosaic } from "src/components/common/mosaic/AavoMosaic.tsx";
import { CenteredTypography } from "src/components/common/CenteredTypography.tsx";

export const DocumentSearchPage = () => {
	const [selectedDocument, setSelectedDocument] = useState<DocumentActiveRevisionView | undefined>(
		undefined,
	);

	return (
		<AavoMosaic
			viewId={"65D749A534DEFB11"}
			layout={{
				type: "row",
				splitPercentage: 65,
				first: {
					type: "panel",
					title: i18n.t("documents"),
					content: <DocumentSearchDataGrid onSelectedDocumentChanged={setSelectedDocument} />,
				},
				second: {
					type: "panel",
					title: i18n.t("preview"),
					content:
						selectedDocument == null ?
							<CenteredTypography>{i18n.t("select_a_document")}</CenteredTypography>
						:	<DocumentPreviewView
								key={selectedDocument.documentId}
								documentId={selectedDocument.documentId}
							/>,
				},
			}}
		/>
	);
};
