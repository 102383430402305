// This file is automatically generated. Do not edit manually.

import { SubProject } from "src/api/generated/erp/db/types/tables/subProject";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace ProjectActivityJobQueueActionsApi {
	export async function startActivities(args: { activityIds: Array<number> }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/project/activityJobQueue/startActivities",
			method: "POST",
			data: {
				activityIds: args.activityIds,
			},
		});
		return response as void;
	}

	export async function getActivitiesHaveStartedTasks(args: { activityIds: Array<number> }): Promise<boolean> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/project/activityJobQueue/getActivitiesHaveStartedTasks",
			method: "POST",
			data: {
				activityIds: args.activityIds,
			},
		});
		return response as boolean;
	}

	export async function revertStartActivities(args: { activityIds: Array<number> }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/project/activityJobQueue/revertStartActivities",
			method: "POST",
			data: {
				activityIds: args.activityIds,
			},
		});
		return response as void;
	}

	export async function completeActivities(args: { activityIds: Array<number> }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/project/activityJobQueue/completeActivities",
			method: "POST",
			data: {
				activityIds: args.activityIds,
			},
		});
		return response as void;
	}

	export async function revertCompleteActivities(args: { activityIds: Array<number> }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/project/activityJobQueue/revertCompleteActivities",
			method: "POST",
			data: {
				activityIds: args.activityIds,
			},
		});
		return response as void;
	}

	export async function getProjectInitialSubProjects(args: { projectId: number }): Promise<Array<SubProject>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/project/activityJobQueue/getProjectInitialSubProjects",
			method: "POST",
			data: {
				projectId: args.projectId,
			},
		});
		return response as Array<SubProject>;
	}

	export async function startSubProject(args: { subProjectId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/project/activityJobQueue/startSubProject",
			method: "POST",
			data: {
				subProjectId: args.subProjectId,
			},
		});
		return response as void;
	}
}
