import { Box, Tooltip, TooltipProps } from "@mui/material";
import { mergeSx } from "src/utils/styles.ts";

export const TooltipWithWrapper = ({ children, sx, ...other }: TooltipProps) => {
	return (
		<Tooltip {...other}>
			<Box
				sx={mergeSx(
					{
						display: "flex",
					},
					sx,
				)}
			>
				{children}
			</Box>
		</Tooltip>
	);
};
