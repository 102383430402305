import {Control} from "react-hook-form";
import {requireRule} from "src/components/common/forms/validation.ts";
import i18n from "i18next";
import {SystemDiagram} from "src/api/generated/postgres/db/types/public_/tables/systemDiagram.ts";
import {FormTextField} from "src/components/common/forms/fields/FormTextField.tsx";
import {FormCommonProps} from "src/components/common/forms/types.ts";
import {FormSelectField} from "src/components/common/forms/fields/FormSelectField.tsx";
import {FormEnumSelectField} from "src/components/common/forms/fields/FormEnumSelectField.tsx";
import {
    getSystemDiagramDirectionLabels
} from "src/api/generated/postgres/db/types/public_/enums/systemDiagramDirection.ts";
import {
    getSystemDiagramContextTypeLabels,
    SystemDiagramContextType,
} from "src/api/generated/postgres/db/types/public_/enums/systemDiagramContextType.ts";
import {SitesApi} from "src/api/generated/erp/common/sites/sitesApi.ts";
import {Site} from "src/api/generated/erp/db/types/tables/site.ts";
import {SystemDiagramCrudApi} from "src/api/generated/systemDiagrams/management/systemDiagramCrudApi.ts";
import {AsyncForm} from "src/components/common/forms/AsyncForm.tsx";

export interface SystemDiagramFormProps extends FormCommonProps<number> {
	systemDiagram: SystemDiagram | undefined;
}

export const SystemDiagramForm = ({ systemDiagram, onFormEdited, onCompleted }: SystemDiagramFormProps) => {
	const isNewRecord = systemDiagram === undefined;

	const submitForm = async (values: SystemDiagram) => {
		const saved = await SystemDiagramCrudApi.save({ systemDiagram: values });
		return saved.systemDiagramId;
	};

	return (
		<AsyncForm<Site[], SystemDiagram, number>
			fetch={() => SitesApi.getAll()}
			submit={submitForm}
			columns={1}
			fitContent={true}
			onCompleted={onCompleted}
			onFormEdited={onFormEdited}
			getDefaultValues={() =>
				systemDiagram ?? {
					name: "",
					diagramDirection: "LEFT_TO_RIGHT",
				}
			}
			render={({ control, watch, data: siteOptions }) => (
				<>
					<FormTextField
						control={control}
						name={"name"}
						rules={requireRule()}
						label={i18n.t("name")}
						autoFocus
					/>
					<FormEnumSelectField
						control={control}
						name={"diagramDirection"}
						rules={requireRule()}
						label={i18n.t("diagram_direction")}
						options={getSystemDiagramDirectionLabels()}
					/>
					<FormEnumSelectField
						control={control}
						name={"contextType"}
						rules={requireRule()}
						label={i18n.t("context")}
						disabled={!isNewRecord}
						options={getSystemDiagramContextTypeLabels()}
					/>
					<ContextParamFields
						control={control}
						contextType={watch("contextType")}
						siteOptions={siteOptions}
						isNewRecord={isNewRecord}
					/>
				</>
			)}
		/>
	);
};

const ContextParamFields = ({
	isNewRecord,
	control,
	contextType,
	siteOptions,
}: {
	isNewRecord: boolean;
	control: Control<SystemDiagram>;
	contextType: SystemDiagramContextType;
	siteOptions: Site[];
}) => {
	if (contextType === "MANUFACTURING" || contextType === "SALES") {
		return (
			<FormSelectField
				control={control}
				name={"contextParam1"}
				label={i18n.t("site")}
				rules={requireRule()}
				disabled={!isNewRecord}
				options={siteOptions}
				getOptionKey={(site) => site.siteId.toString()}
				getOptionLabel={(site) => site.siteName}
			/>
		);
	}

	return null;
};
