// This file is automatically generated. Do not edit manually.

import { CustomerAddressView } from "src/api/generated/erp/db/types/tables/customerAddressView";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace CustomerAddressDataGridApi {
	export async function getCustomerAddresses(args: { customerId: number }): Promise<Array<CustomerAddressView>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/customer/address/getCustomerAddresses",
			method: "POST",
			data: {
				customerId: args.customerId,
			},
		});
		return response as Array<CustomerAddressView>;
	}
}
