import { ConfiguratorLogApi } from "src/api/generated/erp/configurator/api/configuratorLogApi.ts";
import { PartConfiguratorType } from "src/api/generated/erp/configurator/configuratorType/partConfiguratorType.ts";
import { ConfiguratorLogView } from "src/components/views/erp/configurator/configuratorTesting/logViews/ConfiguratorLogView.tsx";

export interface ConfiguratorSessionLogViewProps {
	sessionId: string;
	configuratorType: PartConfiguratorType;
}

export const ConfiguratorSessionLogView = ({
	sessionId,
	configuratorType,
}: ConfiguratorSessionLogViewProps) => {
	return (
		<ConfiguratorLogView
			fetchLogFile={() =>
				ConfiguratorLogApi.getConfiguratorSessionLogFile({
					sessionId,
					configuratorType,
				})
			}
		/>
	);
};
