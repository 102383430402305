import { AavoAppIcon } from "../common/AavoAppIcon";

export const HomePage = () => {
	return (
		<AavoAppIcon
			sx={{
				alignSelf: "center",
				alignItems: "stretch",
				height: "60vmax",
				width: "60vmax",
			}}
		/>
	);
};
