// This file is automatically generated. Do not edit manually.

import { IsoDateString } from "src/types/dateTime";
import { BillingPlanLineSumInput } from "src/api/generated/erp/sales/billingPlan/base/service/billingPlanLineSumInput";
import { CustomerOrderBillingPlanLine } from "src/api/generated/erp/db/types/tables/customerOrderBillingPlanLine";
import { ObjectAccountingDimensionValue } from "src/api/generated/erp/sales/accounting/objectAccountingDimensionValue";
import { VatCode } from "src/api/generated/erp/db/types/tables/vatCode";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace CustomerOrderBillingPlanLineEditApi {
	export async function getFormInitData(args: {
		customerOrderId: number;
		billingPlanLineId: number | null | undefined;
	}): Promise<CustomerOrderBillingPlanLineEditApi_InitData> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/customerOrder/billingPlan/getFormInitData",
			method: "POST",
			data: {
				customerOrderId: args.customerOrderId,
				billingPlanLineId: args.billingPlanLineId,
			},
		});
		return response as CustomerOrderBillingPlanLineEditApi_InitData;
	}

	export async function save(args: {
		customerOrderId: number;
		billingPlanLineId: number | null | undefined;
		description: string;
		estimatedApprovalDate: IsoDateString;
		funded: boolean;
		sumInput: BillingPlanLineSumInput;
		vatCodeId: number;
		accountingCodeIds: Array<number>;
	}): Promise<CustomerOrderBillingPlanLine> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/customerOrder/billingPlan/save",
			method: "POST",
			data: {
				customerOrderId: args.customerOrderId,
				billingPlanLineId: args.billingPlanLineId,
				description: args.description,
				estimatedApprovalDate: args.estimatedApprovalDate,
				funded: args.funded,
				sumInput: args.sumInput,
				vatCodeId: args.vatCodeId,
				accountingCodeIds: args.accountingCodeIds,
			},
		});
		return response as CustomerOrderBillingPlanLine;
	}
}

export interface CustomerOrderBillingPlanLineEditApi_InitData {
	accountingDimensions: Array<ObjectAccountingDimensionValue>;
	customerOrderVatCodeId: number;
	inputPricesWithVat: boolean;
	vatCodeOptions: Array<VatCode>;
}
