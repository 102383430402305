// This file is automatically generated. Do not edit manually.

import { t } from "i18next";

export const SystemDiagramContextTypeValues = ["SPC", "MANUFACTURING", "SALES"] as const;

export type SystemDiagramContextType = (typeof SystemDiagramContextTypeValues)[number];

export const getSystemDiagramContextTypeLabels = () => ({
	SPC: t("spc"),
	MANUFACTURING: t("manufacturing"),
	SALES: t("sales"),
});

export const getSystemDiagramContextTypeLabel = (value: SystemDiagramContextType): string => {
	return getSystemDiagramContextTypeLabels()[value];
};
