// This file is automatically generated. Do not edit manually.

import { t } from "i18next";

export const PartWarehouseLocationReplenishmentTriggerValues = [
	"MANUAL",
	"AFTER_TRANSFER_OUT",
	"BEFORE_TRANSFER_OUT",
] as const;

export type PartWarehouseLocationReplenishmentTrigger =
	(typeof PartWarehouseLocationReplenishmentTriggerValues)[number];

export const getPartWarehouseLocationReplenishmentTriggerLabels = () => ({
	MANUAL: t("manually"),
	AFTER_TRANSFER_OUT: t("after_transfer_out"),
	BEFORE_TRANSFER_OUT: t("before_transfer_out"),
});

export const getPartWarehouseLocationReplenishmentTriggerLabel = (
	value: PartWarehouseLocationReplenishmentTrigger,
): string => {
	return getPartWarehouseLocationReplenishmentTriggerLabels()[value];
};
