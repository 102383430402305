import { AsyncButton } from "src/components/common/buttons/AsyncButton.tsx";
import i18n from "i18next";
import { AavoFormFooter } from "src/components/common/forms/AavoFormFooter.tsx";
import { faTimes, faUndo } from "@fortawesome/pro-regular-svg-icons";
import { VerticalBox } from "src/components/common/box/VerticalBox.tsx";
import { useGenericDialog } from "src/components/common/dialogs/useGenericDialog.ts";
import Typography from "@mui/material/Typography";
import { StackedIcon } from "src/components/common/icons/StackedIcon.tsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFloppyDisk } from "@fortawesome/pro-solid-svg-icons";
import React from "react";

export interface ConfiguratorFormFooterProps {
	onCancel?: () => void;
	resetToExistingConfiguration?: () => Promise<unknown>;
	resetToDefaultValues?: () => Promise<unknown>;
	submitForm?: () => Promise<unknown>;
	extraComponents?: React.ReactNode;
}

export const ConfiguratorFormFooter = (props: ConfiguratorFormFooterProps) => {
	const { onCancel, submitForm, resetToExistingConfiguration, resetToDefaultValues, extraComponents } =
		props;
	const { openDialog } = useGenericDialog();

	return (
		<AavoFormFooter>
			{extraComponents}
			{(resetToDefaultValues || resetToExistingConfiguration) && (
				<AsyncButton
					color={"secondary"}
					label={i18n.t("reset")}
					icon={faUndo}
					onClick={async () => {
						openDialog(({ closeDialog }) => ({
							title: i18n.t("reset_configurator_form"),
							size: "sm",
							content: (
								<ResetFormDialogContent
									resetToDefaultValues={resetToDefaultValues}
									resetToExistingConfiguration={resetToExistingConfiguration}
									closeDialog={closeDialog}
								/>
							),
						}));
					}}
				/>
			)}
			{onCancel && (
				<AsyncButton
					color={"secondary"}
					label={i18n.t("close")}
					icon={faTimes}
					onClick={async () => {
						await onCancel();
					}}
				/>
			)}
			{submitForm && (
				<AsyncButton
					label={i18n.t("ok")}
					onClick={async () => {
						await submitForm();
					}}
				/>
			)}
		</AavoFormFooter>
	);
};

interface ResetFormDialogContentProps
	extends Pick<ConfiguratorFormFooterProps, "resetToDefaultValues" | "resetToExistingConfiguration"> {
	closeDialog: () => void;
}

const ResetFormDialogContent = ({
	resetToDefaultValues,
	resetToExistingConfiguration,
	closeDialog,
}: ResetFormDialogContentProps) => {
	return (
		<VerticalBox gap={1.5} margin={1.5}>
			<Typography>{i18n.t("what_would_you_like_to_do")}</Typography>
			<AsyncButton
				disabled={resetToDefaultValues == null}
				label={i18n.t("reset_to_default_values")}
				variant={"outlined"}
				icon={faUndo}
				onClick={async () => {
					await resetToDefaultValues?.();
					closeDialog();
				}}
			/>
			<AsyncButton
				disabled={resetToExistingConfiguration == null}
				label={i18n.t("reset_to_existing_configuration")}
				variant={"outlined"}
				icon={faUndo}
				onClick={async () => {
					await resetToExistingConfiguration?.();
					closeDialog();
				}}
				iconEl={
					<StackedIcon>
						<FontAwesomeIcon icon={faUndo} />
						<FontAwesomeIcon
							icon={faFloppyDisk}
							transform={{
								size: 8,
								x: -11,
								y: 4,
							}}
						/>
					</StackedIcon>
				}
			/>
		</VerticalBox>
	);
};
