import {ProjectActivityMaterialsDataGridBase} from "src/components/views/erp/project/projectActivity/material/ProjectActivityMaterialsDataGridBase.tsx";
import {
	ProjectActivityMaterialsDataGridApi
} from "src/api/generated/erp/project/projectActivityMaterial/api/projectActivityMaterialsDataGridApi.ts";

export interface ProjectAllActivityMaterialsDataGridProps {
	projectId: number;
}

export const ProjectAllActivityMaterialsDataGrid = ({
	projectId,
}: ProjectAllActivityMaterialsDataGridProps) => {
	return (
		<ProjectActivityMaterialsDataGridBase
			gridId={"D362AAECDED2C493"}
			fetchData={({ showCancelledLines, dataModelRequest }) =>
				ProjectActivityMaterialsDataGridApi.getDataGridDataForProject({
					projectId: projectId,
					cancelledLines: showCancelledLines,
					dataModelRequest: dataModelRequest,
				})
			}
			showActivityColumn
		/>
	);
};
