export class CheckboxCellRenderer {
	init(params) {
		const initialValue = params.value ? params.value.value : false;

		this.eGui = document.createElement("span");
		this.eGui.classList.add("ag-grid_checkbox_rendered_container");

		const input = document.createElement("input");
		input.type = "checkbox";
		input.checked = initialValue;
		input.addEventListener("click", () => {
			if (params.isEditableCheckBox) {
				params.markRowToUnsaved(params.node.id);
				params.node.setDataValue(params.column, {
					type: "simpleValue",
					value: input.checked,
				});
			} else {
				input.checked = initialValue;
				return false;
			}
		});

		this.eGui.innerHTML = "";
		this.eGui.appendChild(input);
	}

	getGui() {
		return this.eGui;
	}
}
