import {AsyncMenuButton} from "src/components/common/contextMenu/AsyncMenuButton.tsx";
import i18n from "i18next";
import {faArchive, faCopy, faPlus, faSync, faTrash, faUndo} from "@fortawesome/pro-regular-svg-icons";
import {WorkspaceEditApi} from "src/api/generated/workspaces/api/workspaceEditApi.ts";
import {useGenericDialog} from "src/components/common/dialogs/useGenericDialog.ts";
import {openFormOnDialog} from "src/components/common/dialogs/formDialog/openFormOnDialog.ts";
import {WorkspaceForm} from "src/components/views/workspaces/WorkspaceForm.tsx";
import {useInputDialog} from "src/components/common/dialogs/input/useInputDialog.tsx";
import {WorkspacesTreeViewContext} from "src/components/views/workspaces/treeView/WorkspaceTreeViewContext.tsx";
import {useContextOrThrow} from "src/utils/useContextOrThrow.tsx";
import {WorkspaceTreeViewItemModel} from "src/components/views/workspaces/treeView/WorkspacesTreeViewState.ts";
import {flatMapTreeItems} from "src/components/common/tree/AavoTreeView.utils.ts";

export interface WorkspaceTreeViewContextMenuProps {
	workspace: WorkspaceTreeViewItemModel;
}

export const WorkspaceTreeViewContextMenu = ({ workspace }: WorkspaceTreeViewContextMenuProps) => {
	const { openDialog } = useGenericDialog();
	const openInputDialog = useInputDialog();
	const { refresh, dispatch } = useContextOrThrow(WorkspacesTreeViewContext);
	return [
		//TODO() info
		//TODO() jäsenet
		<AsyncMenuButton
			key={"createWorkspace"}
			label={i18n.t("create_sub_workspace")}
			icon={faPlus}
			onClick={async () => {
				openFormOnDialog({
					openDialog: openDialog,
					component: WorkspaceForm,
					title: i18n.t("create_workspace"),
					props: {
						workspaceId: null,
						parentId: workspace.workspaceId,
					},
					onSubmit: async () => {
						await refresh();
						dispatch({
							type: "setItemIsExpanded",
							itemId: workspace.workspaceId.toString(),
							isExpanded: true,
						});
					},
					size: "md",
				});
			}}
		/>,
		<AsyncMenuButton
			key={"copy"}
			label={i18n.t("copy")}
			icon={faCopy}
			onClick={async () => {
				const newWorkspaceName = await openInputDialog({
					type: "string",
					title: i18n.t("new_name"),
					fieldLabel: i18n.t("name"),
					defaultValue: i18n.t("copy_postfix", { name: workspace.workspaceName }),
					required: true,
				});
				if (!newWorkspaceName) return;
				await WorkspaceEditApi.copyWorkspace({
					sourceWorkspaceId: workspace.workspaceId,
					targetWorkspaceName: newWorkspaceName,
				});
				await refresh();
			}}
		/>,
		<AsyncMenuButton
			key={"delete"}
			label={i18n.t("delete")}
			icon={faTrash}
			onClick={async () => {
				await WorkspaceEditApi.delete_({ workspaceId: workspace.workspaceId });
				await refresh();
			}}
			hidden={!workspace.isArchived}
		/>,
		<AsyncMenuButton
			key={"archive"}
			label={i18n.t("archive")}
			icon={faArchive}
			onClick={async () => {
				await WorkspaceEditApi.archiveWorkspace({ workspaceId: workspace.workspaceId });
				await refresh();
			}}
			hidden={workspace.isArchived == true}
		/>,
		<AsyncMenuButton
			key={"revertArchive"}
			label={i18n.t("cancel")}
			icon={faUndo}
			onClick={async () => {
				await WorkspaceEditApi.revertArchiveWorkspace({ workspaceId: workspace.workspaceId });
				await refresh();
			}}
			hidden={!workspace.isArchived}		
		/>,
		<AsyncMenuButton
			key={"syncDocumentDescription2"}
			label={i18n.t("sync_document_description_2")}
			icon={faSync}
			onClick={async () => {
				await WorkspaceEditApi.syncWorkspacesDocumentSourceDescriptions({
					workspaceIds: flatMapTreeItems([workspace]).map((item) => item.workspaceId),
				});
				await refresh();
			}}
		/>,
	];
};
