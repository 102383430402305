import { DeepPartial } from "react-hook-form";
import { AavoForm } from "src/components/common/forms/AavoForm";
import { FormResult } from "src/components/common/forms/types.ts";
import { TaskUpdateApi } from "src/api/generated/tasks/api/taskUpdateApi";
import { FormDateField } from "src/components/common/forms/fields/FormDateField.tsx";
import i18n from "i18next";
import { requireRule } from "src/components/common/forms/validation.ts";
import { IsoDateString } from "src/types/dateTime.ts";

export interface RescheduleTasksFormProps {
	taskIds: number[];
	defaultValues: DeepPartial<FormValues>;
	onCompleted: (result: FormResult<void>) => void;
}

export interface FormValues {
	deadlineDate: IsoDateString;
	earliestStartDate: IsoDateString | null;
}

export const RescheduleTasksForm = ({ taskIds, defaultValues, onCompleted }: RescheduleTasksFormProps) => {
	return (
		<AavoForm<FormValues, void>
			defaultValues={defaultValues}
			submit={async (values) => {
				await TaskUpdateApi.scheduleTasks({
					taskIds: taskIds,
					deadlineDate: values.deadlineDate,
					earliestStartDate: values.earliestStartDate,
				});
			}}
			columns={1}
			fitContent
			onCompleted={onCompleted}
			render={({ control }) => (
				<>
					<FormDateField
						control={control}
						name={"deadlineDate"}
						label={i18n.t("deadline_date")}
						rules={requireRule()}
					/>
					<FormDateField
						control={control}
						name={"earliestStartDate"}
						label={i18n.t("earliest_start_date")}
					/>
				</>
			)}
		/>
	);
};
