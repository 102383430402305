// This file is automatically generated. Do not edit manually.

import { t } from "i18next";

export const PurchaseOrderLineSourceTypeValues = [
	"MANUAL",
	"PURCHASE_REQUEST",
	"CUSTOMER_ORDER_LINE",
	"ACTIVITY_MATERIAL_LINE",
	"SHOP_ORDER_LINE",
] as const;

export type PurchaseOrderLineSourceType = (typeof PurchaseOrderLineSourceTypeValues)[number];

export const getPurchaseOrderLineSourceTypeLabels = () => ({
	MANUAL: t("manual"),
	PURCHASE_REQUEST: t("purchase_request"),
	CUSTOMER_ORDER_LINE: t("customer_order_line"),
	ACTIVITY_MATERIAL_LINE: t("activity_material_line"),
	SHOP_ORDER_LINE: t("shop_order_line"),
});

export const getPurchaseOrderLineSourceTypeLabel = (value: PurchaseOrderLineSourceType): string => {
	return getPurchaseOrderLineSourceTypeLabels()[value];
};
