// This file is automatically generated. Do not edit manually.

import { FrontendDownloadFileHandle } from "src/api/generated/common/frontendDownloadFileHandle";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace PartWarehouseLocationPrintApi {
	export async function printLabels(args: {
		partWarehouseLocationIds: Array<number>;
	}): Promise<FrontendDownloadFileHandle> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sitePart/partWarehouseLocations/printLabels",
			method: "POST",
			data: {
				partWarehouseLocationIds: args.partWarehouseLocationIds,
			},
		});
		return response as FrontendDownloadFileHandle;
	}

	export async function printSmallLabels(args: {
		partWarehouseLocationIds: Array<number>;
	}): Promise<FrontendDownloadFileHandle> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sitePart/partWarehouseLocations/printSmallLabels",
			method: "POST",
			data: {
				partWarehouseLocationIds: args.partWarehouseLocationIds,
			},
		});
		return response as FrontendDownloadFileHandle;
	}
}
