import mapboxgl, { LngLatLike } from "mapbox-gl";

export const fitMapToCoords = (
	map: mapboxgl.Map,
	coords: LngLatLike[],
	options: mapboxgl.FitBoundsOptions = {},
) => {
	const bounds = new mapboxgl.LngLatBounds();
	coords.forEach((lngLat) => {
		bounds.extend(lngLat);
	});

	map.fitBounds(bounds, {
		...{ padding: 100 },
		...options,
	});
};

export const defaultProximity = {
	lng: 24.945831,
	lat: 60.192059,
}; // Helsinki, Finland
