import i18n from "i18next";
import { DeliveryPackageType } from "src/api/generated/erp/db/types/tables/deliveryPackageType.ts";
import { DeliveryPackageTypeForm } from "src/components/views/erp/warehouse/basedata/packageTypes/DeliveryPackageTypeForm.tsx";
import { useClientSideDataGridModel } from "src/components/common/dataGrid/gridModel/useClientSideDataGridModel.tsx";
import { faBan, faUndo } from "@fortawesome/pro-regular-svg-icons";
import { useConfirmDialog } from "src/components/common/dialogs/confirmDialog/useConfirmDialog.ts";
import { MenuCheckbox } from "src/components/common/contextMenu/MenuCheckbox.tsx";
import { ControlledAsyncCrudDataGrid } from "src/components/common/dataGrid/crud/ControlledAsyncCrudDataGrid.tsx";
import { AsyncMenuButton } from "src/components/common/contextMenu/AsyncMenuButton.tsx";
import { DeliveryPackageTypesApi } from "src/api/generated/erp/warehouse/basedata/api/deliveryPackageTypesApi.ts";
import { booleanColumn, textColumn } from "src/components/common/dataGrid/columns.tsx";

export const DeliveryPackageTypesDataGrid = () => {
	const { dataGridProps, refreshData, currentParams } = useClientSideDataGridModel({
		fetchData: ({ showInactive }) =>
			DeliveryPackageTypesApi.getDeliveryPackageTypes({
				includeInactive: showInactive,
			}),
		initialParams: {
			showInactive: false,
		},
		getRowId: (row) => row.deliveryPackageTypeId,
		gridId: "25E3274ECFE803AC",
	});

	const showConfirmDialog = useConfirmDialog();

	return (
		<ControlledAsyncCrudDataGrid<DeliveryPackageType>
			disableMultipleRowSelection
			columns={[
				textColumn({
					field: "name",
					headerName: i18n.t("name"),
				}),
				booleanColumn({
					field: "isActive",
					headerName: i18n.t("active"),
				}),
			]}
			form={{
				addRowEnabled: true,
				editEnabled: true,
				dialogTitle: i18n.t("package_type"),
				dialogSize: "sm",
				component: ({ row, onCompleted, onFormEdited }) => (
					<DeliveryPackageTypeForm
						deliveryPackageType={row}
						onCompleted={onCompleted}
						onFormEdited={onFormEdited}
					/>
				),
			}}
			remove={{
				type: "enabled",
				action: ({ items }) =>
					DeliveryPackageTypesApi.delete_({
						deliveryPackageTypeId: items[0]!.deliveryPackageTypeId,
					}),
			}}
			actionBarMenuComponents={[
				<MenuCheckbox
					key={"show_inactive"}
					label={i18n.t("show_inactive")}
					checked={currentParams.showInactive}
					onChange={(showInactive) => refreshData({ showInactive })}
				/>,
			]}
			rowContextMenuComponents={({ row }) => [
				row.isActive && (
					<AsyncMenuButton
						key={"deactivate"}
						label={i18n.t("deactivate")}
						icon={faBan}
						onClick={async () => {
							const confirmed = await showConfirmDialog();
							if (!confirmed) return;

							await DeliveryPackageTypesApi.setPackageTypeIsActive({
								packageTypeId: row.deliveryPackageTypeId,
								isActive: false,
							});
							await refreshData();
						}}
					/>
				),
				!row.isActive && (
					<AsyncMenuButton
						key={"activate"}
						label={i18n.t("activate")}
						icon={faUndo}
						onClick={async () => {
							const confirmed = await showConfirmDialog();
							if (!confirmed) return;

							await DeliveryPackageTypesApi.setPackageTypeIsActive({
								packageTypeId: row.deliveryPackageTypeId,
								isActive: true,
							});
							await refreshData();
						}}
					/>
				),
			]}
			{...dataGridProps}
		/>
	);
};
