// This file is automatically generated. Do not edit manually.

import { ServerSideDataModelRequest } from "src/api/generated/common/dataGrids/serverSideDataModelRequest";
import { ServerSideDataModelResult } from "src/api/generated/common/dataGrids/serverSideDataModelResult";
import { ControlChartView } from "src/api/generated/postgres/db/types/public_/tables/controlChartView";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace ControlChartDataGridApi {
	export async function getGroupOrParentGroupControlCharts(args: {
		chartGroupId: number | null | undefined;
		parentGroupId: number | null | undefined;
		searchQuery: string;
		showDisabledCharts: boolean;
		dataModelRequest: ServerSideDataModelRequest;
	}): Promise<ServerSideDataModelResult<ControlChartView>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/spc/controlChart/getGroupOrParentGroupControlCharts",
			method: "POST",
			data: {
				chartGroupId: args.chartGroupId,
				parentGroupId: args.parentGroupId,
				searchQuery: args.searchQuery,
				showDisabledCharts: args.showDisabledCharts,
				dataModelRequest: args.dataModelRequest,
			},
		});
		return response as ServerSideDataModelResult<ControlChartView>;
	}

	export async function getControlChartsForResourceItem(args: {
		resourceItemId: number;
		searchQuery: string;
		showDisabledCharts: boolean;
		dataModelRequest: ServerSideDataModelRequest;
	}): Promise<ServerSideDataModelResult<ControlChartView>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/spc/controlChart/getResrouceItemControlCharts",
			method: "POST",
			data: {
				resourceItemId: args.resourceItemId,
				searchQuery: args.searchQuery,
				showDisabledCharts: args.showDisabledCharts,
				dataModelRequest: args.dataModelRequest,
			},
		});
		return response as ServerSideDataModelResult<ControlChartView>;
	}
}
