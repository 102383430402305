import { ClientSideDataGridModel } from "src/components/common/dataGrid/gridModel/ClientSideDataGridModel";
import { CrudDataGrid } from "src/components/common/dataGrid/crud/CrudDataGrid.tsx";
import { dateColumn, enumColumn, integerColumn, textColumn } from "src/components/common/dataGrid/columns.tsx";
import i18n from "i18next";
import { ConfiguratorTableRevisionsApi } from "src/api/generated/erp/configurator/tables/api/configuratorTableRevisionsApi.ts";
import { ConfiguratorTableRevision } from "src/api/generated/erp/db/types/tables/configuratorTableRevision.ts";
import { getConfiguratorTableRevisionStateLabels } from "src/api/generated/erp/db/types/enums/configuratorTableRevisionState.ts";
import { ConfiguratorTableRevisionForm } from "src/components/views/erp/configurator/tables/ConfiguratorTableRevisionForm.tsx";
import { AsyncMenuButton } from "src/components/common/contextMenu/AsyncMenuButton.tsx";
import { faShare, faUndo } from "@fortawesome/pro-regular-svg-icons";
import { useConfirmDialog } from "src/components/common/dialogs/confirmDialog/useConfirmDialog.ts";
import { useInputDialog } from "src/components/common/dialogs/input/useInputDialog.tsx";
import dayjs from "dayjs";
import { dayJsToDateIsoString } from "src/utils/dayjsUtils.ts";
import { useConfirmWithInputDialog } from "src/components/common/dialogs/confirmDialog/useConfirmWithInputDialog.tsx";
import { deCapitalize } from "src/utils/strings.tsx";

export interface ConfiguratorTableRevisionsDataGridProps {
	configuratorTableId: number;
	onSelectionChanged?: (selectedRow: ConfiguratorTableRevision | undefined) => void;
}

export const ConfiguratorTableRevisionsDataGrid = ({
	configuratorTableId,
	onSelectionChanged,
}: ConfiguratorTableRevisionsDataGridProps) => {
	const showConfirmDialog = useConfirmDialog();
	const showConfirmWithInputDialog = useConfirmWithInputDialog();
	const showInputDialog = useInputDialog();

	return (
		<ClientSideDataGridModel
			fetchData={() =>
				ConfiguratorTableRevisionsApi.getConfiguratorTableRevisions({
					configuratorTableId,
				})
			}
			initialParams={{}}
			getRows={(response) => response}
			getRowId={(row) => row.configuratorTableRevisionId}
			gridId={"4637DC40B4FF7312"}
			onSelectionChanged={(rows) => onSelectionChanged?.(rows[0])}
			selectFirstRowOnLoad
			render={({ dataGridProps, refreshData }) => (
				<CrudDataGrid<ConfiguratorTableRevision>
					disableMultipleRowSelection
					columns={[
						integerColumn({
							field: "revisionNumber",
							headerName: i18n.t("revision"),
						}),
						textColumn({
							field: "description",
							headerName: i18n.t("description"),
							width: 200,
						}),
						enumColumn({
							field: "state",
							headerName: i18n.t("state"),
							enumLabels: getConfiguratorTableRevisionStateLabels(),
						}),
						dateColumn({
							field: "activeFromDate",
							headerName: i18n.t("active_from"),
							width: 180,
						}),
						integerColumn({
							field: "configuratorTableRevisionId",
							headerName: i18n.t("id"),
						}),
					]}
					form={{
						addRowEnabled: true,
						editEnabled: true,
						dialogTitle: i18n.t("revision"),
						dialogSize: "sm",
						component: ({ row, onCompleted, onFormEdited }) => (
							<ConfiguratorTableRevisionForm
								configuratorTableId={configuratorTableId}
								configuratorTableRevisionId={row?.configuratorTableRevisionId}
								onCompleted={onCompleted}
								onFormEdited={onFormEdited}
							/>
						),
					}}
					remove={{
						type: "enabled",
						confirm: false,
						action: async ({ row }) => {
							const confirmInput = deCapitalize(i18n.t("delete"));
							const confirmed = await showConfirmWithInputDialog({
								title: i18n.t("are_you_sure"),
								message: i18n.t("delete_configurator_table_revision_confirmation", {
									required_input: confirmInput,
								}),
								inputToMatch: confirmInput,
								submitLabel: i18n.t("delete"),
							});
							if (!confirmed) return;

							await ConfiguratorTableRevisionsApi.delete_({
								configuratorTableRevisionId: row.configuratorTableRevisionId,
							});
						},
					}}
					rowContextMenuComponents={({ row }) => [
						row.state === "INITIAL" && (
							<AsyncMenuButton
								key={"tabs"}
								label={i18n.t("release")}
								icon={faShare}
								onClick={async () => {
									const activeFrom = await showInputDialog({
										type: "date",
										title: i18n.t("release"),
										fieldLabel: i18n.t("active_from"),
										defaultValue: dayjs().local(),
										required: true,
									});
									if (activeFrom == null) return;

									await ConfiguratorTableRevisionsApi.release({
										configuratorTableRevisionId: row.configuratorTableRevisionId,
										activeFrom: dayJsToDateIsoString(activeFrom),
									});
									await refreshData();
								}}
							/>
						),
						row.state === "RELEASED" && (
							<AsyncMenuButton
								key={"tabs"}
								label={i18n.t("revert_release")}
								icon={faUndo}
								onClick={async () => {
									const confirmed = await showConfirmDialog();
									if (!confirmed) return;

									await ConfiguratorTableRevisionsApi.revertRelease({
										configuratorTableRevisionId: row.configuratorTableRevisionId,
									});
									await refreshData();
								}}
							/>
						),
					]}
					{...dataGridProps}
				/>
			)}
		/>
	);
};
