// This file is automatically generated. Do not edit manually.

import { t } from "i18next";

export const DeliveryPackageLabelSizeValues = ["_10X10", "A4"] as const;

export type DeliveryPackageLabelSize = (typeof DeliveryPackageLabelSizeValues)[number];

export const getDeliveryPackageLabelSizeLabels = () => ({
	_10X10: t("_10x10"),
	A4: t("a4"),
});

export const getDeliveryPackageLabelSizeLabel = (value: DeliveryPackageLabelSize): string => {
	return getDeliveryPackageLabelSizeLabels()[value];
};
