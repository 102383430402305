import i18n from "i18next";
import {ShopOrderApi} from "src/api/generated/erp/production/api/shopOrder/shopOrderApi.ts";
import {ShopOrderActionApi} from "src/api/generated/erp/production/api/shopOrder/shopOrderActionApi.ts";
import {ShowConfirmDialogFunc} from "src/components/common/dialogs/confirmDialog/ConfirmDialogProvider.tsx";
import {ShopOrderBomApi} from "src/api/generated/erp/production/api/shopOrderBom/shopOrderBomApi.ts";

export async function cancelShopOrder({
	shopOrderId,
	showConfirmDialog,
}: {
	shopOrderId: number;
	showConfirmDialog: ShowConfirmDialogFunc;
}) {
	const confirmed = await showConfirmDialog({
		title: i18n.t("cancel"),
		message: i18n.t("confirm_cancel_shop_order"),
	});
	if (!confirmed) return;

	const linesWithReceivedPurchaseOrders = await ShopOrderApi.getShopOrderLinesWithReceivedPurchaseOrderLines({
		shopOrderId: shopOrderId,
	});

	if (linesWithReceivedPurchaseOrders.length > 0) {
		const confirmed = await showConfirmDialog({
			title: i18n.t("are_you_sure"),
			message: i18n.t("shop_order_has_lines_with_received_purchase_orders"),
		});
		if (!confirmed) return;
	}

	const shopOrderLines = await ShopOrderBomApi.getShopOrderBom({ shopOrderId: shopOrderId });
	const shopOrderWarehouseAcquisitionLines = shopOrderLines.filter((line) => line.acquisitionMethod === "WAREHOUSE");
	if (shopOrderWarehouseAcquisitionLines.some((line) => line.reservedQuantity > line.pickedQuantity)) {
		await showConfirmDialog({
			title: i18n.t("can_not_cancel"),
			message: i18n.t("shop_order_warehouse_lines_has_reservations.revert_reservations_first"),
		});
		return;
	}
	if (
		shopOrderWarehouseAcquisitionLines.some(
			(line) => line.pickedQuantity > 0 && line.pickedQuantity === line.reservedQuantity,
		)
	) {
		const confirmed = await showConfirmDialog({
			title: i18n.t("are_you_sure"),
			message: i18n.t("shop_order_has_picked_lines"),
		});
		if (!confirmed) return;
	}

	await ShopOrderActionApi.cancelShopOrder({
		shopOrderId: shopOrderId,
	});
}
