import { Salesperson } from "src/api/generated/erp/db/types/tables/salesperson.ts";
import { SalespersonEditApi } from "src/api/generated/erp/sales/basedata/api/salespersonEditApi.ts";
import { FormCommonProps } from "src/components/common/forms/types.ts";
import { AavoForm, AavoFormContentParams } from "src/components/common/forms/AavoForm.tsx";
import { DeepPartial } from "react-hook-form";
import { FormAsyncUserSelectField } from "src/components/views/users/FormAsyncUserSelectField.tsx";
import i18n from "i18next";
import { requireRule } from "src/components/common/forms/validation.ts";
import { FormEnumSelectField } from "src/components/common/forms/fields/FormEnumSelectField.tsx";
import { getSalespersonPaymentMethodLabels } from "src/api/generated/erp/db/types/enums/salespersonPaymentMethod.ts";

export interface SalespersonFormProps extends FormCommonProps<number> {
	salesperson: Salesperson | undefined;
}

interface FormValues extends Salesperson {}

export const SalespersonForm = (props: SalespersonFormProps) => {
	const { onCompleted, onFormEdited, salesperson } = props;
	return (
		<AavoForm
			defaultValues={getDefaultValues()}
			submit={submit}
			onCompleted={onCompleted}
			onFormEdited={onFormEdited}
			render={(contentParams) => <FormContent {...props} {...contentParams} />}
		/>
	);

	function getDefaultValues(): DeepPartial<FormValues> {
		if (salesperson != null) return salesperson;
		else
			return {
				paymentMethod: "SALARY",
			};
	}

	async function submit(values: FormValues) {
		if (salesperson == null)
			return await SalespersonEditApi.insert({
				salesperson: values,
			});
		else {
			await SalespersonEditApi.update({
				salesperson: values,
			});
			return salesperson.salespersonId;
		}
	}
};

interface FormContentProps extends SalespersonFormProps, AavoFormContentParams<FormValues> {}

const FormContent = ({ control, salesperson }: FormContentProps) => {
	return (
		<>
			<FormAsyncUserSelectField
				control={control}
				name={"appUserId"}
				label={i18n.t("user")}
				rules={requireRule()}
				disabled={salesperson != null}
				fetchOptions={({ searchQuery, currentSelection }) =>
					SalespersonEditApi.getSalespersonAppUserSelectionOptions({
						searchQuery: searchQuery,
						currentSelectionUserId: currentSelection,
					})
				}
				getOptionKey={(option) => option.id}
				getOptionLabel={(option) => option.name}
			/>
			<FormEnumSelectField
				control={control}
				options={getSalespersonPaymentMethodLabels()}
				name={"paymentMethod"}
				label={i18n.t("payment_method")}
				rules={requireRule()}
				disableClearable
			/>
		</>
	);
};
