import { UnmanagedFilesApi } from "src/api/generated/documents/unmanagedFiles/unmanagedFilesApi.ts";
import { AsyncFetchRender } from "src/components/common/async/AsyncFetchRender.tsx";
import { FilePreviewView } from "src/components/views/documents/preview/FilePreviewView.tsx";

export interface UnmanagedFilePreviewViewProps {
	fileUuid: string;
	extension: string;
}

export const UnmanagedFilePreviewView = ({ fileUuid, extension }: UnmanagedFilePreviewViewProps) => {
	return (
		<AsyncFetchRender
			fetch={() =>
				UnmanagedFilesApi.getUnmanagedFilePresignedDownloadUrl({
					uuid: fileUuid,
				})
			}
			content={(fileUrl) => <FilePreviewView fileUrl={fileUrl} extension={extension} />}
		/>
	);
};
