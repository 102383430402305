import { FormCommonProps } from "src/components/common/forms/types.ts";
import {
	WarehousePickingObjectDto,
	WarehousePickingObjectReservationFormBase,
} from "src/components/views/erp/warehouse/inventory/WarehousePickingObjectReservationFormBase.tsx";
import { ProjectActivityMaterialView } from "src/api/generated/erp/db/types/tables/projectActivityMaterialView.ts";
import { ProjectActivityMaterialReservationFormApi } from "src/api/generated/erp/project/delivery/api/projectActivityMaterialReservationFormApi.ts";

export interface ProjectActivityMaterialReservationFormProps extends FormCommonProps<void> {
	activityMaterials: ProjectActivityMaterialView[];
}

export const ProjectActivityMaterialReservationForm = (props: ProjectActivityMaterialReservationFormProps) => {
	const { activityMaterials, onCompleted, onFormEdited } = props;

	const pickingObjects = activityMaterials.map<WarehousePickingObjectDto>((line) => ({
		pickingObjectId: line.projectActivityMaterialId,
		partId: line.partId,
		objectTotalValueInWarehouseUnit: line.quantityInBaseUnit,
		objectReservedQuantityInWarehouseUnit: line.reservedQuantityInBaseUnit,
		partDescription: line.configurationOrPartDescription,
		subTitle: "",
	}));

	return (
		<WarehousePickingObjectReservationFormBase
			warehousePickingObjects={pickingObjects}
			onFormEdited={onFormEdited}
			submit={async (values) =>
				await ProjectActivityMaterialReservationFormApi.createReservations({
					reservationRequests: values.pickingObjects.map((request) => ({
						projectActivityMaterialId: request.pickingObjectId,
						partWarehouseLocationId: request.partWarehouseLocationId,
						quantityInWarehouseUnit: request.quantityToReserveInWarehouseUnit,
					})),
				})
			}
			onCompleted={onCompleted}
		/>
	);
};
