import { useAsyncFetch } from "src/utils/async/asyncFetch.ts";
import { ControlledAsyncDataGrid, ControlledAsyncDataGridProps } from "./ControlledAsyncDataGrid";

export interface AsyncDataGridProps<RowData extends object>
	extends Omit<ControlledAsyncDataGridProps<RowData>, "rowsAsync" | "refreshData"> {
	fetchRows: () => Promise<RowData[]>;
}

export const AsyncDataGrid = <RowData extends object>({
	fetchRows,
	...other
}: AsyncDataGridProps<RowData>) => {
	const [rowsAsync, refreshData] = useAsyncFetch(fetchRows);
	return <ControlledAsyncDataGrid refreshData={refreshData} rowsAsync={rowsAsync} {...other} />;
};
