// This file is automatically generated. Do not edit manually.

import { t } from "i18next";

export const ConfigurationPriceCalculationMethodValues = ["BOM", "RULE"] as const;

export type ConfigurationPriceCalculationMethod = (typeof ConfigurationPriceCalculationMethodValues)[number];

export const getConfigurationPriceCalculationMethodLabels = () => ({
	BOM: t("bom"),
	RULE: t("rule"),
});

export const getConfigurationPriceCalculationMethodLabel = (value: ConfigurationPriceCalculationMethod): string => {
	return getConfigurationPriceCalculationMethodLabels()[value];
};
