// This file is automatically generated. Do not edit manually.

import { ObjectAttribute } from "src/api/generated/erp/db/types/tables/objectAttribute";
import { ObjectAttributeField } from "src/api/generated/erp/common/objectAttributes/objectAttributeField";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace PartConfigurationAttributesApi {
	export async function getPartConfigurationAttributes(args: {
		partConfigurationId: number;
	}): Promise<PartConfigurationAttributesApi_PartConfigurationAttributes> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/configurator/configuration/attributes/getPartConfigurationAttributes",
			method: "POST",
			data: {
				partConfigurationId: args.partConfigurationId,
			},
		});
		return response as PartConfigurationAttributesApi_PartConfigurationAttributes;
	}

	export async function saveAttributes(args: {
		partConfigurationId: number;
		attributes: Array<ObjectAttribute>;
	}): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/configurator/configuration/attributes/saveAttributes",
			method: "POST",
			data: {
				partConfigurationId: args.partConfigurationId,
				attributes: args.attributes,
			},
		});
		return response as void;
	}
}

export interface PartConfigurationAttributesApi_PartConfigurationAttributes {
	attributeFields: Array<ObjectAttributeField>;
	attributes: Array<ObjectAttribute>;
}
