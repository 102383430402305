import type { EChartsReactProps } from "echarts-for-react";
import { VerticalBox } from "../box/VerticalBox";
import { SxProps, Theme } from "@mui/material/styles";
import { PageLoading } from "src/components/common/PageLoading.tsx";
import React, { Suspense } from "react";

const ReactECharts = React.lazy(() => import("echarts-for-react"));

export interface AavoEchartsProps extends EChartsReactProps {
	sx?: SxProps<Theme>;
}

export const AavoECharts = ({ sx, style, opts, ...other }: AavoEchartsProps) => {
	return (
		<VerticalBox sx={sx}>
			<Suspense fallback={<PageLoading />}>
				<ReactECharts
					style={{ width: "100%", height: "100%", ...style }}
					opts={{
						renderer: "svg",
						...opts,
					}}
					{...other}
				/>
			</Suspense>
		</VerticalBox>
	);
};
