import { MaybeAsyncFunction } from "src/types/functions";
import { ConfirmDialogOptions } from "src/components/common/dialogs/confirmDialog/ConfirmDialogProvider.tsx";

export type CrudActionButtonDefinition<Params = void, Result = void> =
	| EnabledActionButtonDefinition<Params, Result>
	| { type: "disabled"; tooltip?: string }
	| { type: "hidden" };

export type CrudActionButtonDefinitionWithShortcuts<Params, Result> =
	| CrudActionButtonDefinition<Params, Result>
	| MaybeAsyncFunction<Params, EnabledActionResult<Result>> // shorthand for enabled
	| false; // shorthand for hidden action

export type EnabledActionButtonDefinition<Params, Result> = {
	type: "enabled";
	action: MaybeAsyncFunction<Params, EnabledActionResult<Result>>;
	confirm?: boolean | ConfirmDialogOptions;
};

export type EnabledActionResult<Result> = Result | "interrupt";

export function removeActionShortcuts<Params, Result>(
	prop: CrudActionButtonDefinitionWithShortcuts<Params, Result> | undefined,
): CrudActionButtonDefinition<Params, Result> {
	return (
		prop === undefined ? { type: "hidden" }
		: prop === false ? { type: "hidden" }
		: typeof prop === "function" ? { type: "enabled", action: prop }
		: prop
	);
}
