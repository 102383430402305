// This file is automatically generated. Do not edit manually.

import { CostEventObjectRef } from "src/api/generated/erp/common/costEvents/costEventObjectRef";
import { ServerSideDataModelRequest } from "src/api/generated/common/dataGrids/serverSideDataModelRequest";
import { CostEvent } from "src/api/generated/erp/db/types/tables/costEvent";
import { ServerSideDataModelResult } from "src/api/generated/common/dataGrids/serverSideDataModelResult";
import { CostEventView } from "src/api/generated/erp/db/types/tables/costEventView";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace CostEventsOfObjectDataGridApi {
	export async function getDataGridData(args: {
		objectRef: CostEventObjectRef;
		dataModelRequest: ServerSideDataModelRequest;
	}): Promise<CostEventsOfObjectDataGridApi_GridData> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/costEvents/costEventsOfObject/getDataGridData",
			method: "POST",
			data: {
				objectRef: args.objectRef,
				dataModelRequest: args.dataModelRequest,
			},
		});
		return response as CostEventsOfObjectDataGridApi_GridData;
	}

	export async function delete_(args: { costEvents: Array<CostEvent> }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/costEvents/costEventsOfObject/delete",
			method: "POST",
			data: {
				costEvents: args.costEvents,
			},
		});
		return response as void;
	}
}

export interface CostEventsOfObjectDataGridApi_GridData {
	costEventsData: ServerSideDataModelResult<CostEventView>;
	totalCosts: number;
	totalPrice: number;
}
