import {SizeProp} from "@fortawesome/fontawesome-svg-core";
import {AavoLoading} from "./AavoLoading";
import {Box, BoxProps} from "@mui/material";
import {mergeSx} from "src/utils/styles";
import {overlaySx} from "src/styles/sx";

export interface PageLoadingProps extends BoxProps {
    size?: SizeProp;
    overlay?: boolean;
}

export const PageLoading = ({size, overlay, sx, ...other}: PageLoadingProps) => {
    return (
        <Box
            sx={mergeSx(
                {
                    display: "flex",
                    flex: 1,
                    justifyContent: "center",
                    alignItems: "center",
                },
                overlay && {
                    ...overlaySx,
                    zIndex: 100,
                },
                sx
            )}
            {...other}
        >
            <AavoLoading size={size || "3x"}/>
        </Box>
    );
};
