import React from "react";
import { AsyncFetchRender } from "src/components/common/async/AsyncFetchRender";
import { CurrentUserApi } from "src/api/currentUserApi.ts";
import * as Sentry from "@sentry/react";
import { GlobalInitDataApi } from "src/api/generated/common/api/globalInitDataApi.ts";
import { logError } from "src/errorHandling/errorLogging.ts";
import { absolute } from "src/utils/paths.ts";
import { INSUFFICIENT_PERMISSIONS } from "src/urls.ts";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { UserEnvironmentType } from "src/api/generated/config/userEnvironmentType.ts";
import { Site } from "src/api/generated/erp/db/types/tables/site.ts";

export interface GlobalInitDataContextValue {
	principalId: string;
	appUserId: number;
	userName: string;
	userEmail: string;
	userPermissions: string[];
	mfaIsEnabled: boolean;
	defaultSiteId: number;
	userSites: Site[];
	changingPasswordIsPossible: boolean;
	environmentType: UserEnvironmentType;
}

export const GlobalInitDataContext = React.createContext<GlobalInitDataContextValue | undefined>(undefined);

export const GlobalInitDataProvider = ({ children }: React.PropsWithChildren) => {
	const navigate = useNavigate();
	return (
		<AsyncFetchRender
			fetch={() => fetchContextValue(navigate)}
			content={(contextValue) => (
				<GlobalInitDataContext.Provider value={contextValue}>{children}</GlobalInitDataContext.Provider>
			)}
		/>
	);
};

const fetchContextValue = async (navigate: NavigateFunction) => {
	const userData = await CurrentUserApi.getUserData();
	Sentry.setTag("tenant", userData.tenant);

	if (!userData.isAssociatedToTenant) {
		navigate(absolute(INSUFFICIENT_PERMISSIONS));
	}

	let result: GlobalInitDataContextValue = {
		principalId: userData.principalId,
		appUserId: -1,
		userEmail: userData.email,
		userName: "",
		userPermissions: [],
		mfaIsEnabled: userData.mfaIsEnabled,
		changingPasswordIsPossible: userData.changingPasswordIsPossible,
		defaultSiteId: -1,
		userSites: [],
		environmentType: "PROD",
	};

	try {
		const initData = await GlobalInitDataApi.getInitData();
		result = {
			...result,
			appUserId: initData.appUserId,
			userName: initData.userName,
			userPermissions: initData.userPermissions ?? [],
			userSites: initData.erp.userSites,
			defaultSiteId: initData.erp.defaultSiteId,
			environmentType: initData.environmentType,
		};
		Sentry.setUser({
			id: userData.principalId,
			email: userData.email,
			username: initData.userName,
			appUserId: initData.appUserId.toString(),
		});
	} catch (e) {
		Sentry.setUser({
			id: userData.principalId,
			email: userData.email,
		});

		logError(`Failed to get global init data ${e}`);
	}

	return result;
};

export default GlobalInitDataProvider;
