import { CostEventsDataGridBase } from "src/components/views/erp/common/costEvents/CostEventsDataGridBase.tsx";
import {
	ProjectCostEventsDataGridsApi,
	ProjectCostEventsDataGridsApi_GridData,
} from "src/api/generated/erp/project/project/api/projectCostEventsDataGridsApi.ts";
import { textColumn } from "src/components/common/dataGrid/columns.tsx";
import i18n from "i18next";
import { ProjectActivityCostEventView } from "src/api/generated/erp/db/types/tables/projectActivityCostEventView.ts";

export interface SubProjectCostEventsDataGridProps {
	subProjectId: number;
	costEventPriceListId: number;
}

export const SubProjectCostEventsDataGrid = ({
	subProjectId,
	costEventPriceListId,
}: SubProjectCostEventsDataGridProps) => {
	return (
		<CostEventsDataGridBase<ProjectActivityCostEventView, object, ProjectCostEventsDataGridsApi_GridData>
			fetchData={({ dataModelRequest }) =>
				ProjectCostEventsDataGridsApi.getGridDataForSubProject({
					subProjectId,
					dataModelRequest,
				})
			}
			initialParams={{}}
			getRowCostEventPriceListId={() => costEventPriceListId}
			insert={{ type: "disabled" }}
			gridId={"A8FA2F0C5AE6EA16"}
			extraColumns={[
				textColumn({
					field: "activityName",
					headerName: i18n.t("activity"),
					width: 150,
					position: 0,
				}),
			]}
		/>
	);
};
