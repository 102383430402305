// This file is automatically generated. Do not edit manually.

import { ObjectAccountingDimensionValue } from "src/api/generated/erp/sales/accounting/objectAccountingDimensionValue";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace CustomerOrderAccountingApi {
	export async function getFormInitData(args: {
		customerOrderId: number;
	}): Promise<CustomerOrderAccountingApi_FormInitData> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/customerOrder/accounting/getInitData",
			method: "POST",
			data: {
				customerOrderId: args.customerOrderId,
			},
		});
		return response as CustomerOrderAccountingApi_FormInitData;
	}

	export async function setCustomerOrderAccountingDimensions(args: {
		customerOrderId: number;
		accountingCodeIds?: Array<number>;
	}): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/customerOrder/accounting/setCustomerOrderAccountingDimensions",
			method: "POST",
			data: {
				customerOrderId: args.customerOrderId,
				accountingCodeIds: args.accountingCodeIds,
			},
		});
		return response as void;
	}
}

export interface CustomerOrderAccountingApi_FormInitData {
	accountingDimensions: Array<ObjectAccountingDimensionValue>;
}
