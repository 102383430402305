import { ResourceStateChartsApi } from "src/api/generated/spc/resources/api/resourceStateChartsApi.ts";
import { ClientSideDataGridModel } from "src/components/common/dataGrid/gridModel/ClientSideDataGridModel.tsx";
import { CrudDataGrid } from "src/components/common/dataGrid/crud/CrudDataGrid.tsx";
import { enumColumn, textColumn } from "src/components/common/dataGrid/columns.tsx";
import i18n from "i18next";
import { ResourceStateChartView } from "src/api/generated/postgres/db/types/ext_resourcing/tables/resourceStateChartView.ts";
import { getResourceStateLabels } from "src/api/generated/io/aavo/applications/db/postgres/enums/resourceState.ts";
import { ResourceStateChartEditApi } from "src/api/generated/spc/resources/api/resourceStateChartEditApi.ts";
import { ResourceStateChartForm } from "src/components/views/spc/basedata/resources/ResourceStateChartForm.tsx";

export interface ResourceStateChartsDataGridProps {
	resourceId: number;
}

export const ResourceStateChartsDataGrid = ({ resourceId }: ResourceStateChartsDataGridProps) => {
	return (
		<ClientSideDataGridModel
			gridId={"50932022EC98D38B"}
			fetchData={() =>
				ResourceStateChartsApi.getGridData({
					resourceId: resourceId,
				})
			}
			initialParams={{}}
			getRows={(data) => data}
			getRowId={(row) => row.id}
			render={({ dataGridProps }) => (
				<CrudDataGrid<ResourceStateChartView>
					columns={[
						enumColumn({
							field: "resourceState",
							headerName: i18n.t("state"),
							editable: true,
							enumLabels: getResourceStateLabels(),
							width: 120,
						}),
						textColumn({
							field: "durationChartName",
							headerName: i18n.t("state_duration_chart"),
							width: 150,
						}),
						textColumn({
							field: "frequencyChartName",
							headerName: i18n.t("state_frequency_chart"),
							width: 150,
						}),
					]}
					remove={async ({ items }) => {
						await ResourceStateChartEditApi.delete_({
							resourceStateChartIds: items.map((chart) => chart.id),
						});
					}}
					form={{
						editEnabled: true,
						addRowEnabled: true,
						dialogSize: "sm",
						dialogTitle: i18n.t("state_chart"),
						component: ({ row, ...other }) => (
							<ResourceStateChartForm
								resourceId={resourceId}
								resourceStateChartId={row?.id ?? null}
								{...other}
							/>
						),
					}}
					{...dataGridProps}
				/>
			)}
		/>
	);
};
