// This file is automatically generated. Do not edit manually.

import { PartType } from "src/api/generated/erp/db/types/enums/partType";
import { ServerSideDataModelRequest } from "src/api/generated/common/dataGrids/serverSideDataModelRequest";
import { ServerSideDataModelResult } from "src/api/generated/common/dataGrids/serverSideDataModelResult";
import { PartView } from "src/api/generated/erp/db/types/tables/partView";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace SitePartDataGridApi {
	export async function searchSiteParts(args: {
		onlySitePartId: number | null | undefined;
		partCategoryId: number | null | undefined;
		searchQuery: string;
		showInactiveParts: boolean;
		onlyDefaultSite: boolean;
		partType: PartType | null | undefined;
		showPartsWithDesignedRevision: boolean;
		responsiblePersonId: number | null | undefined;
		dataModelRequest: ServerSideDataModelRequest;
	}): Promise<ServerSideDataModelResult<PartView>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sitePart/searchSiteParts",
			method: "POST",
			data: {
				onlySitePartId: args.onlySitePartId,
				partCategoryId: args.partCategoryId,
				searchQuery: args.searchQuery,
				showInactiveParts: args.showInactiveParts,
				onlyDefaultSite: args.onlyDefaultSite,
				partType: args.partType,
				showPartsWithDesignedRevision: args.showPartsWithDesignedRevision,
				responsiblePersonId: args.responsiblePersonId,
				dataModelRequest: args.dataModelRequest,
			},
		});
		return response as ServerSideDataModelResult<PartView>;
	}
}
