// This file is automatically generated. Do not edit manually.

import { SalesCommissionLineView } from "src/api/generated/erp/db/types/tables/salesCommissionLineView";
import { SalespersonView } from "src/api/generated/erp/db/types/tables/salespersonView";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace SalesCommissionPaymentCreationDataGridApi {
	export async function getDataGridData(args: {
		salespersonIds: Array<number>;
	}): Promise<SalesCommissionPaymentCreationDataGridApi_DataGridData> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/commissions/paymentCreation/dataGrid/getDataGridData",
			method: "POST",
			data: {
				salespersonIds: args.salespersonIds,
			},
		});
		return response as SalesCommissionPaymentCreationDataGridApi_DataGridData;
	}
}

export interface SalesCommissionPaymentCreationDataGridApi_DataGridData {
	commissionLines: Array<SalesCommissionLineView>;
	salespersonOptions: Array<SalespersonView>;
}
