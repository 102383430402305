// This file is automatically generated. Do not edit manually.

import { t } from "i18next";

export const ConfiguratorTableRevisionStateValues = ["INITIAL", "RELEASED"] as const;

export type ConfiguratorTableRevisionState = (typeof ConfiguratorTableRevisionStateValues)[number];

export const getConfiguratorTableRevisionStateLabels = () => ({
	INITIAL: t("initial"),
	RELEASED: t("released"),
});

export const getConfiguratorTableRevisionStateLabel = (value: ConfiguratorTableRevisionState): string => {
	return getConfiguratorTableRevisionStateLabels()[value];
};
