import { RefreshableElementRef } from "src/utils/useRefreshRef.ts";
import { WarehouseTransferJobQueueDataGridBase } from "src/components/views/erp/warehouse/transfer/WarehouseTransferJobQueueDataGridBase.tsx";
import { AsyncButton } from "src/components/common/buttons/AsyncButton.tsx";
import i18n from "i18next";
import { WarehouseTransferActionApi } from "src/api/generated/erp/warehouse/warehouseTransfer/api/warehouseTransferActionApi.ts";
import { faDolly, faPrint, faSignInAlt, faUndo } from "@fortawesome/pro-regular-svg-icons";
import { downloadFile } from "src/utils/fileDownloading.ts";

export interface WarehouseTransferJobQueueReservedDataGridProps {
	refreshRef: RefreshableElementRef;
	pickedRefreshRef: RefreshableElementRef;
	notStartedRefreshRef: RefreshableElementRef;
	deliveredRefreshRef: RefreshableElementRef;
}

export const WarehouseTransferJobQueueReservedDataGrid = ({
	refreshRef,
	notStartedRefreshRef,
	pickedRefreshRef,
	deliveredRefreshRef,
}: WarehouseTransferJobQueueReservedDataGridProps) => {
	return (
		<WarehouseTransferJobQueueDataGridBase
			warehouseTransferState={"RESERVED"}
			gridId={"6B8D33A1C61308FB"}
			refreshRef={refreshRef}
			sortModel={[
				{ field: "givingSiteName", sort: "asc" },
				{ field: "givingWarehouseLocationCode", sort: "asc" },
			]}
			columnOrder={{
				givingWarehouseLocationCode: 0,
				requestingWarehouseLocationCode: 5,
			}}
			actionBarComponents={(selectedRows) => (
				<>
					<AsyncButton
						label={i18n.t("picking_list")}
						icon={faPrint}
						onClick={async () => {
							const fileHandle = await WarehouseTransferActionApi.printWarehouseTransferPickingList();
							downloadFile(fileHandle);
						}}
						variant={"outlined"}
					/>
					<AsyncButton
						label={i18n.t("pick")}
						icon={faDolly}
						onClick={async () => {
							await WarehouseTransferActionApi.pickSelectedTransfers({
								warehouseTransferIds: selectedRows.map((row) => row.warehouseTransferId),
							});
							await pickedRefreshRef.refresh();
							await refreshRef.refresh();
						}}
						disabled={selectedRows.length === 0}
						variant={"outlined"}
					/>
					<AsyncButton
						label={i18n.t("deliver")}
						icon={faSignInAlt}
						onClick={async () => {
							await WarehouseTransferActionApi.pickAndTransferSelectedTransfers({
								warehouseTransferIds: selectedRows.map((row) => row.warehouseTransferId),
							});
							await deliveredRefreshRef.refresh();
							await refreshRef.refresh();
						}}
						disabled={selectedRows.length === 0}
						variant={"outlined"}
					/>
					<AsyncButton
						label={i18n.t("deliver_without_purchasing")}
						icon={faSignInAlt}
						onClick={async () => {
							await WarehouseTransferActionApi.pickAndTransferWithoutPurchasingSelectedTransfers({
								warehouseTransferIds: selectedRows.map((row) => row.warehouseTransferId),
							});
							await deliveredRefreshRef.refresh();
							await refreshRef.refresh();
						}}
						disabled={selectedRows.length === 0}
						variant={"outlined"}
					/>
					<AsyncButton
						label={i18n.t("revert_reserve")}
						icon={faUndo}
						onClick={async () => {
							await WarehouseTransferActionApi.revertReserveSelectedTransfers({
								warehouseTransferIds: selectedRows.map((row) => row.warehouseTransferId),
							});
							await notStartedRefreshRef.refresh();
							await refreshRef.refresh();
						}}
						disabled={selectedRows.length === 0}
						variant={"outlined"}
					/>
				</>
			)}
		/>
	);
};
