import {
	faArrowUpRight,
} from "@fortawesome/pro-solid-svg-icons";
import { faPaperclip } from "@fortawesome/pro-regular-svg-icons";
import { AsyncButtonProps } from "../../../common/buttons/AsyncButton.tsx";
import { AavoObjectRef } from "src/api/generated/common/sourceType/aavoObjectRef.ts";
import { DocumentsOfObjectButton } from "./DocumentsOfObjectButton.tsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import i18n from "i18next";
import { StackedIcon } from "src/components/common/icons/StackedIcon.tsx";

export interface DocumentsOfObjectSourceButtonProps extends Omit<AsyncButtonProps, "onClick"> {
	sourceRef: AavoObjectRef | undefined;
	showLabel?: boolean;
}

export const DocumentsOfObjectSourceButton = ({
	sourceRef,
	showLabel = false,
	...other
}: DocumentsOfObjectSourceButtonProps) => {
	return (
		<DocumentsOfObjectButton
			objectRef={sourceRef}
			label={showLabel ? i18n.t("source") : undefined}
			tooltip={i18n.t("source_documents")}
			dialogTitle={(description) => `${i18n.t("source_documents")} (${description})`}
			editable={false}
			iconEl={
				<StackedIcon>
					<FontAwesomeIcon icon={faPaperclip} />
					<FontAwesomeIcon
						icon={faArrowUpRight}
						transform={{
							size: 10,
							x: -7,
							y: -6,
						}}
					/>
				</StackedIcon>
			}
			{...other}
		/>
	);
};
