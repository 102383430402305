import { TaskSourceRef } from "src/api/generated/tasks/taskSource/taskSourceRef.ts";
import { TaskSourceType } from "src/api/generated/postgres/db/types/tasks/enums/taskSourceType.ts";

export const encodeTaskSource = (taskSource: TaskSourceRef): string => {
	return JSON.stringify({
		sourceType: taskSource.sourceType,
		sourceId: taskSource.sourceId,
	});
};

export const encodeTaskSourceNullable = (
	taskSource: TaskSourceRef | null | undefined,
): string | null => {
	if (taskSource == null) return null;

	return encodeTaskSource(taskSource);
};

export const decodeTaskSource = (encoded: string): TaskSourceRef => {
	return JSON.parse(encoded);
};

export const decodeTaskSourceNullable = (
	encoded: string | null | undefined,
): TaskSourceRef | null => {
	if (encoded == null) return null;
	return decodeTaskSource(encoded);
};

export const nullableTaskSourceRef = (
	sourceType: TaskSourceType | undefined | null,
	sourceId: number | undefined | null,
): TaskSourceRef | null => {
	if (sourceType == null || sourceId == null) return null;

	return {
		sourceType,
		sourceId,
	};
};
