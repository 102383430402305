import {
	getPurchaseRequestStateLabels,
	PurchaseRequestState,
} from "src/api/generated/erp/db/types/enums/purchaseRequestState.ts";
import {
	PurchaseRequestDataTableApi,
	PurchaseRequestDataTableApi_RowDto,
} from "src/api/generated/erp/purchase/purchaseRequest/purchaseRequestDataTableApi.ts";
import {
	booleanColumn,
	dateColumn,
	dateTimeColumn,
	enumColumn,
	floatColumn,
	integerColumn,
	textColumn,
} from "src/components/common/dataGrid/columns.tsx";
import i18n from "i18next";
import { PurchaseRequestForm } from "src/components/views/erp/purchase/purchaseRequests/PurchaseRequestForm.tsx";
import { useServerSideDataGridModel } from "src/components/common/dataGrid/gridModel/useServerSideDataGridModel";
import { getPurchaseRequestSourceTypeLabels } from "src/api/generated/erp/db/types/enums/purchaseRequestSourceType.ts";
import { ControlledAsyncCrudDataGrid } from "src/components/common/dataGrid/crud/ControlledAsyncCrudDataGrid.tsx";
import { IsoDateString } from "src/types/dateTime.ts";
import { genericNullableValue } from "src/utils/genericNullableValue.ts";
import { AavoDatePicker } from "src/components/common/inputFields/AavoDatePicker.tsx";
import { dayJsToDateIsoStringNullable } from "src/utils/dayjsUtils.ts";
import { LazySelectField } from "src/components/common/inputFields/LazySelectField.tsx";
import { AsyncButton } from "src/components/common/buttons/AsyncButton.tsx";
import { PurchaseRequestApi } from "src/api/generated/erp/purchase/purchaseRequest/purchaseRequestApi.ts";
import { PurchaseOrderFromPurchaseRequestForm } from "src/components/views/erp/purchase/purchaseRequests/PurchaseOrderFromPurchaseRequestForm.tsx";
import { downloadFile } from "src/utils/fileDownloading.ts";
import { AavoTextField } from "src/components/common/inputFields/AavoTextField.tsx";
import { faBan, faFileInvoice, faShare, faSquareCheck } from "@fortawesome/pro-regular-svg-icons";
import { useTenantCustomizations } from "src/tenantCustomizations/useTenantCustomizations.tsx";
import { useGenericDialog } from "src/components/common/dialogs/useGenericDialog.ts";
import { useConfirmDialog } from "src/components/common/dialogs/confirmDialog/useConfirmDialog.ts";
import { SelectField } from "src/components/common/inputFields/SelectField.tsx";
import { enumOptions } from "src/components/common/forms/fields/selectionFieldUtils.ts";
import { MenuCheckbox } from "src/components/common/contextMenu/MenuCheckbox.tsx";

interface PurchaseRequestsDataGridProps {
	onlyPurchaseRequestId?: number;
}

export const PurchaseRequestsDataGrid = ({ onlyPurchaseRequestId }: PurchaseRequestsDataGridProps) => {
	const confirm = useConfirmDialog();
	const tenantCustomizations = useTenantCustomizations();
	const { openDialog } = useGenericDialog();

	const { dataGridProps, refreshData, currentParams, selectedRows } = useServerSideDataGridModel({
		gridId: "7ACD1E9E2F587563",
		fetchData: (params) =>
			PurchaseRequestDataTableApi.getPurchaseRequests({
				onlyPurchaseRequestId: onlyPurchaseRequestId,
				...params,
			}),
		initialParams: {
			demandDateBefore: genericNullableValue<IsoDateString>(),
			stateFilter: genericNullableValue<PurchaseRequestState>("INITIAL"),
			supplierId: genericNullableValue<number>(),
			showOnlyDefaultSite: true,
			searchQuery: "",
		},
		getRowId: (purchaseRequest: PurchaseRequestDataTableApi_RowDto) => purchaseRequest.purchaseRequestId,
		selectFirstRowOnLoad: onlyPurchaseRequestId != null,
	});

	const selectedPurchaseRequestIds = selectedRows.map((r) => r.purchaseRequestId);

	return (
		<ControlledAsyncCrudDataGrid<PurchaseRequestDataTableApi_RowDto>
			columns={[
				integerColumn({
					field: "purchaseRequestId",
					headerName: i18n.t("number_shortened"),
					sortable: true,
					width: 70,
				}),
				textColumn({
					field: "siteName",
					headerName: i18n.t("site"),
					sortable: true,
					width: 90,
					editable: true,
				}),
				dateColumn({
					field: "purchaseRequestDate",
					headerName: i18n.t("request_date"),
					sortable: true,
				}),
				dateColumn({
					field: "demandDate",
					headerName: i18n.t("demand_date"),
					sortable: true,
				}),
				textColumn({
					field: "supplierName",
					headerName: i18n.t("supplier"),
					sortable: true,
				}),
				textColumn({
					field: "partNo",
					headerName: i18n.t("part_no"),
					sortable: true,
					width: 90,
				}),
				textColumn({
					field: "partDescription_1",
					headerName: i18n.t("part"),
					sortable: true,
					width: 175,
				}),
				floatColumn({
					field: "quantity",
					headerName: i18n.t("quantity"),
					sortable: true,
					width: 70,
				}),
				textColumn({
					field: "purchaseUnit",
					headerName: i18n.t("purchase_unit"),
					sortable: true,
				}),
				textColumn({
					field: "currentPurchasePrice",
					headerName: i18n.t("purchase_price"),
					sortable: true,
				}),
				textColumn({
					field: "purchasePriceUnit",
					headerName: i18n.t("price_unit"),
					sortable: true,
				}),
				textColumn({
					field: "supplierPartDescription",
					headerName: i18n.t("supplier_part_description"),
					sortable: true,
					width: 130,
				}),
				enumColumn({
					field: "source",
					headerName: i18n.t("source"),
					sortable: true,
					enumLabels: getPurchaseRequestSourceTypeLabels(),
					width: 120,
				}),
				enumColumn({
					field: "purchaseRequestState",
					headerName: i18n.t("state"),
					sortable: true,
					enumLabels: getPurchaseRequestStateLabels(),
				}),
				booleanColumn({
					field: "isDefault",
					headerName: i18n.t("default_supplier"),
					sortable: true,
				}),
				textColumn({
					field: "handler",
					headerName: i18n.t("handler"),
					sortable: true,
					width: 100,
				}),
				textColumn({
					field: "createdBy",
					headerName: i18n.t("created_by"),
					sortable: true,
					width: 150,
				}),
				dateTimeColumn({
					field: "orderedDate",
					headerName: i18n.t("ordered_date"),
					sortable: true,
				}),
				dateTimeColumn({
					field: "cancelledDate",
					headerName: i18n.t("cancelled_date"),
					sortable: true,
				}),
			]}
			form={{
				editEnabled: true,
				addRowEnabled: false,
				dialogSize: "sm",
				dialogTitle: i18n.t("purchase_request"),
				component: ({ row, onCompleted, onFormEdited }) => (
					<PurchaseRequestForm
						purchaseRequestId={row!.purchaseRequestId}
						onCompleted={onCompleted}
						onFormEdited={onFormEdited}
					/>
				),
			}}
			actionBarComponents={
				<>
					<SelectField
						value={currentParams.stateFilter}
						options={enumOptions(getPurchaseRequestStateLabels())}
						label={""}
						getOptionLabel={(o) => o.label}
						getOptionKey={(o) => o.key}
						onChange={async (v) => {
							await refreshData({ stateFilter: v, supplierId: null });
						}}
						disableClearable={true}
					/>
					<AavoDatePicker
						sx={{
							width: 180,
						}}
						label={i18n.t("demand_date_before")}
						onChange={async (value) => {
							await refreshData({
								demandDateBefore: dayJsToDateIsoStringNullable(value),
							});
						}}
					/>
					<LazySelectField
						fetchOptions={() =>
							PurchaseRequestDataTableApi.getSupplierOptions({
								purchaseRequestStateFilter: currentParams.stateFilter,
							})
						}
						label={i18n.t("supplier")}
						onChange={async (newValue: number | null) => {
							await refreshData({ supplierId: newValue });
						}}
						getOptionLabel={(o) => o.supplierName}
						getOptionKey={(o) => o.supplierId}
					/>
					<AavoTextField
						label={i18n.t("search")}
						sx={{
							width: 180,
						}}
						onSubmit={async (input) => {
							await refreshData({ searchQuery: input });
						}}
					/>
					<AsyncButton
						label={i18n.t("confirm_as_ordered")}
						icon={faSquareCheck}
						disabled={selectedRows.length < 1}
						variant={"outlined"}
						onClick={async () => {
							const confirmed = await confirm({
								message: i18n.t("confirm_purchase_requests_as_ordered_message"),
							});
							if (!confirmed) return;

							await PurchaseRequestApi.markSelectedPurchaseRequestsOrdered({
								purchaseRequestIds: selectedPurchaseRequestIds,
							});
							await refreshData({});
						}}
					/>
					<AsyncButton
						label={i18n.t("create_purchase_order")}
						icon={faShare}
						disabled={selectedRows.length < 1}
						variant={"outlined"}
						onClick={async () => {
							openDialog(({ closeDialog, onContentEdited }) => ({
								size: "sm",
								title: i18n.t("create_purchase_order"),
								content: (
									<PurchaseOrderFromPurchaseRequestForm
										purchaseRequestIds={selectedPurchaseRequestIds}
										onCompleted={async () => {
											await closeDialog();
											await refreshData({});
										}}
										onFormEdited={onContentEdited}
									/>
								),
							}));
						}}
					/>
					<AsyncButton
						label={i18n.t("cancel")}
						icon={faBan}
						disabled={selectedRows.length < 1}
						variant={"outlined"}
						onClick={async () => {
							const confirmed = await confirm({
								message: i18n.t("cancel_purchase_requests_message"),
								cancelButtonText: i18n.t("no"),
							});
							if (!confirmed) return;

							await PurchaseRequestApi.cancelSelectedPurchaseRequests({
								purchaseRequestIds: selectedPurchaseRequestIds,
							});
							await refreshData({});
						}}
					/>
					<AsyncButton
						label={i18n.t("print")}
						icon={faFileInvoice}
						disabled={selectedRows.length < 1}
						variant={"outlined"}
						onClick={async () => {
							const fileHandle = await PurchaseRequestApi.printPurchaseRequests({
								purchaseRequestIds: selectedRows.map((r) => r.purchaseRequestId),
							});
							downloadFile(fileHandle);
						}}
					/>
					{tenantCustomizations.purchaseRequestsDataGridActionBarComponents?.({
						selectedRows: selectedRows,
						refreshData: refreshData,
					})}
				</>
			}
			actionBarMenuComponents={[
				<MenuCheckbox
					key={"onlyDefaultSite"}
					label={i18n.t("only_default_site")}
					checked={currentParams.showOnlyDefaultSite}
					onChange={async (checked) => {
						await refreshData({ showOnlyDefaultSite: checked });
					}}
				/>,
			]}
			{...dataGridProps}
		/>
	);
};
