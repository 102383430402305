import {FormCommonProps} from "src/components/common/forms/types.ts";
import {ShopOrder} from "src/api/generated/erp/db/types/tables/shopOrder.ts";
import {
    ShopOrderFormApi,
    ShopOrderFormApi_InitData,
} from "src/api/generated/erp/production/api/shopOrder/shopOrderFormApi.ts";
import {FormSelectField} from "src/components/common/forms/fields/FormSelectField.tsx";
import i18n from "i18next";
import {useGlobalInitData} from "src/contexts/useGlobalInitData.ts";
import {AsyncForm, AsyncFormContentParams} from "src/components/common/forms/AsyncForm.tsx";
import {requireRule} from "src/components/common/forms/validation.ts";
import {FetchAsyncOptionParams} from "src/components/common/inputFields/AsyncSelectField.tsx";
import {FormAsyncSelectField} from "src/components/common/forms/fields/FormAsyncSelectField.tsx";
import {FormNumberField} from "src/components/common/forms/fields/FormNumberField.tsx";
import {FormDateField} from "src/components/common/forms/fields/FormDateField.tsx";
import {LabelValueView, LabelValueViewItem} from "src/components/common/misc/LabelValueView.tsx";
import {FormTextField} from "src/components/common/forms/fields/FormTextField.tsx";
import {spanGridColumns} from "src/components/common/forms/styles.ts";
import {formatIsoString} from "src/utils/dayjsUtils.ts";
import {FormDateTimeField} from "src/components/common/forms/fields/FormDateTimeField.tsx";
import {PartView} from "src/api/generated/erp/db/types/tables/partView.ts";
import {useConfirmDialog} from "src/components/common/dialogs/confirmDialog/useConfirmDialog.ts";
import {
    confirmMaybeRescheduleShopOrders
} from "src/components/views/erp/production/shopOrder/utils/confirmRescheduleShopOrders.tsx";
import dayjs from "dayjs";
import {useMemo} from "react";

export interface ShopOrderFormProps extends FormCommonProps<number> {
	shopOrderId: number | null;
}

interface FormValues extends ShopOrder {
	part: PartView;
}

export const ShopOrderForm = ({ shopOrderId, onFormEdited, onCompleted }: ShopOrderFormProps) => {
	const { defaultSiteId } = useGlobalInitData();
	const showConfirmDialog = useConfirmDialog();
	return (
		<AsyncForm<ShopOrderFormApi_InitData, FormValues, number>
			fetch={async () => await ShopOrderFormApi.getInitData({ shopOrderId: shopOrderId })}
			getDefaultValues={(data) => {
				return data.shopOrder != null && data.part != null ?
						{
							part: data.part,
							...data.shopOrder,
						}
					:	{
							siteId: defaultSiteId,
							quantity: 1,
							source: "MANUAL",
							sourceId: null,
						};
			}}
			onCompleted={onCompleted}
			onFormEdited={onFormEdited}
			submit={submitForm}
			columns={2}
			render={(params) => <FormContent onCompleted={onCompleted} shopOrderId={shopOrderId} {...params} />}
		/>
	);

	async function submitForm(
		values: FormValues,
		{ data }: AsyncFormContentParams<ShopOrderFormApi_InitData, FormValues>,
	) {
		if (shopOrderId != null) {
			if (values.plannedBeginDate != null) {
				const confirmed = confirmMaybeRescheduleShopOrders({
					shopOrderIds: [values.shopOrderId],
					oldPlannedBeginDate:
						data.shopOrder?.plannedBeginDate != null ? dayjs(data.shopOrder?.plannedBeginDate) : undefined,
					newPlannedBeginDate: dayjs(values.plannedBeginDate),
					showConfirmDialog: showConfirmDialog,
				});
				if (!confirmed) {
					return "interrupted";
				}
			}
			await ShopOrderFormApi.update({ shopOrder: values });
			return shopOrderId;
		} else {
			return await ShopOrderFormApi.insert({ shopOrder: values, partId: values.part.partId });
		}
	}
};

interface FormContentProps extends ShopOrderFormProps, AsyncFormContentParams<ShopOrderFormApi_InitData, FormValues> {}

const FormContent = ({
	data: { allProductionLines, siteOptions ,shopOrder},
	control,
	watch,
	getValues,
	setValue,
	shopOrderId,
}: FormContentProps) => {
	const selectedSiteId = watch("siteId");
	const selectedSiteProductionLineOptions = useMemo(
		() =>
			allProductionLines.filter((line) => {
				if (line.siteId !== selectedSiteId) return false;
				const isCurrentProductionLine = line.productionLineId === shopOrder?.productionLineId;
				return line.isActive || isCurrentProductionLine;
			}),
		[allProductionLines, selectedSiteId, shopOrder],
	);
	return (
		<>
			<FormSelectField
				control={control}
				name={"siteId"}
				label={i18n.t("site")}
				options={siteOptions}
				getOptionKey={(o) => o.siteId}
				getOptionLabel={(o) => o.siteName}
				rules={requireRule()}
				disabled={shopOrderId != null}
				disableClearable
			/>
			<FormSelectField
				control={control}
				name={"productionLineId"}
				label={i18n.t("production_line")}
				options={selectedSiteProductionLineOptions}
				getOptionKey={(o) => o.productionLineId}
				getOptionLabel={(o) => o.productionLineName}
				rules={requireRule()}
				disabled={shopOrder?.shopOrderState !== "INITIAL" && shopOrder != null}
				disableClearable
			/>

			<FormAsyncSelectField
				label={i18n.t("part")}
				control={control}
				name={"part"}
				fetchOptions={({ searchQuery, currentSelection }: FetchAsyncOptionParams<number>) =>
					ShopOrderFormApi.getPartSelectionOptions({
						currentSelection: currentSelection,
						searchQuery: searchQuery,
						siteId: getValues("siteId"),
					})
				}
				formValueType={"option"}
				getOptionKey={(option) => option.partId}
				getOptionLabel={(option) =>
					`${option.partNo} | ${option.partDescription_1} | ${option.partDescription_2}`
				}
				rules={requireRule()}
				disabled={shopOrderId != null}
				onChange={(value) => setDefaultCapacityQuantity(value, getValues("quantity"))}
			/>
			<FormNumberField
				control={control}
				name={"quantity"}
				label={i18n.t("quantity")}
				rules={requireRule()}
				disabled={
					shopOrder?.source === "CUSTOMER_ORDER_LINE" ||
					(shopOrder?.shopOrderState !== "INITIAL" && shopOrder != null)
				}
				onChange={(value) => setDefaultCapacityQuantity(getValues("part"), value)}
			/>
			<FormNumberField
				control={control}
				name={"capacityQuantity"}
				label={i18n.t("capacity_quantity")}
				disabled={shopOrder?.releasedDate != null}
			/>
			<FormDateField
				control={control}
				name={"demandDate"}
				label={i18n.t("demand_date")}
				disabled={
					(shopOrder?.source != null && shopOrder?.source !== "MANUAL") || shopOrder?.releasedDate != null
				}
				rules={requireRule()}
			/>
			{shopOrder != null && (
				<FormDateTimeField
					control={control}
					name={"plannedBeginDate"}
					label={i18n.t("planned_begin_date")}
					disabled={shopOrder.shopOrderState !== "INITIAL" && shopOrder.shopOrderState !== "PLANNED"}
					rules={shopOrder?.plannedBeginDate != null ? requireRule() : undefined}
				/>
			)}
			{shopOrder != null && (
				<FormDateTimeField
					control={control}
					name={"plannedEndDate"}
					label={i18n.t("planned_end_date")}
					disabled={shopOrder?.shopOrderState !== "INITIAL" && shopOrder?.shopOrderState !== "PLANNED"}
					rules={shopOrder?.plannedEndDate != null ? requireRule() : undefined}
				/>
			)}
			{shopOrder != null && (
				<LabelValueView
					sx={{
						flex: 1,
						alignSelf: "flex-start",
					}}
					items={getLabelValueViewItems(shopOrder)}
				/>
			)}
			<FormTextField
				control={control}
				name={"packageInfo"}
				label={i18n.t("package_info")}
				startNewGridRow={true}
				spanGridColumns={true}
			/>
			<FormTextField control={control} name={"note"} label={i18n.t("note")} multiline sx={spanGridColumns} />
		</>
	);

	function setDefaultCapacityQuantity(part: PartView | null, quantity: number | null) {
		if (part == null || part.capacityQuantity == null || quantity == null) {
			setValue("capacityQuantity", 0);
		} else {
			setValue("capacityQuantity", part.capacityQuantity * quantity);
		}
	}
};

function getLabelValueViewItems(shopOrder: ShopOrder): LabelValueViewItem[] {
	return [
		{
			label: i18n.t("work_instruction_printed"),
			value: formatIsoString(shopOrder.workInstructionPrinted, "L LT"),
		},
		{
			label: i18n.t("released_at"),
			value: formatIsoString(shopOrder.releasedDate, "L LT"),
		},
		{
			label: i18n.t("actual_begin_date"),
			value: formatIsoString(shopOrder.actualBeginDate, "L LT"),
		},
		{
			label: i18n.t("actual_end_date"),
			value: formatIsoString(shopOrder.actualEndDate, "L LT"),
		},
	];
}
