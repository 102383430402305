// This file is automatically generated. Do not edit manually.

import { SalesPriceList } from "src/api/generated/erp/db/types/tables/salesPriceList";
import { SalesPartPriceGroup } from "src/api/generated/erp/db/types/tables/salesPartPriceGroup";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace SalesPriceListEditApi {
	export async function getFormInitData(args: {
		salesPriceListId: number | null | undefined;
	}): Promise<SalesPriceListEditApi_FormInitData> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/pricing/salesPriceLists/getFormInitData",
			method: "POST",
			data: {
				salesPriceListId: args.salesPriceListId,
			},
		});
		return response as SalesPriceListEditApi_FormInitData;
	}

	export async function insert(args: { salesPriceList: SalesPriceList }): Promise<number> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/pricing/salesPriceLists/insert",
			method: "POST",
			data: {
				salesPriceList: args.salesPriceList,
			},
		});
		return response as number;
	}

	export async function update(args: { salesPriceList: SalesPriceList }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/pricing/salesPriceLists/update",
			method: "POST",
			data: {
				salesPriceList: args.salesPriceList,
			},
		});
		return response as void;
	}

	export async function delete_(args: { salesPriceListIds: Array<number> }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/pricing/salesPriceLists/delete",
			method: "POST",
			data: {
				salesPriceListIds: args.salesPriceListIds,
			},
		});
		return response as void;
	}
}

export interface SalesPriceListEditApi_FormInitData {
	salesPartPriceGroupOptions: Array<SalesPartPriceGroup>;
	salesPriceList: SalesPriceList | null | undefined;
}
