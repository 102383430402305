import { TasksDesktopView } from "src/components/views/tasks/desktop/TasksDesktopView.tsx";
import { TaskQueryParams } from "src/components/views/tasks/types.ts";
import { TaskSourceRef } from "src/api/generated/tasks/taskSource/taskSourceRef.ts";
import { RefreshableElementRef } from "src/utils/useRefreshRef.ts";
import { useMediaQueryBreakpointDown } from "src/utils/breakpointUtils.ts";
import { TasksMobileView } from "src/components/views/tasks/mobile/TasksMobileView.tsx";
import { Task } from "src/api/generated/postgres/db/types/tasks/tables/task.ts";

export interface TasksViewProps {
	refreshRef?: RefreshableElementRef;
	onTaskCompleted?: (task: Task) => void;
	onTaskCompletionReverted?: (task: Task) => void;
	taskQueryParams: TaskQueryParams;
	taskSourceRef?: TaskSourceRef;
	showCanBeStarted?: boolean;
	showIsInSchedulePlanning?: boolean;
	showTaskSourceInfo?: boolean;
	showAssignedTo?: boolean;
	showResponsiblePerson?: boolean;
	showGanttButton?: boolean;
	usePagination?: boolean;
	showResponsiblePersonFilter?: boolean;
	showStateFilter?: boolean;
}

export const TasksView = (props: TasksViewProps) => {
	const isMobile = useMediaQueryBreakpointDown("md");
	if (isMobile) return <TasksMobileView {...props} />;
	else return <TasksDesktopView {...props} />;
};
