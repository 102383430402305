import { FormCommonProps } from "src/components/common/forms/types.ts";
import {
	ObjectAccountingForm,
	ObjectAccountingFormValues,
} from "src/components/views/erp/sales/accounting/ObjectAccountingForm.tsx";
import {
	CustomerOrderTypeEditApi,
	CustomerOrderTypeEditApi_FormInitData,
} from "src/api/generated/erp/sales/basedata/api/customerOrderTypeEditApi.ts";
import { requireRule } from "src/components/common/forms/validation.ts";
import i18n from "i18next";
import { FormTextField } from "src/components/common/forms/fields/FormTextField.tsx";
import { DeepPartial } from "react-hook-form";
import {
	getObjectAccountingCodeIdsFromFormValues,
	getObjectAccountingFormDefaultValues,
} from "src/components/views/erp/sales/accounting/ObjectAccountingForm.utils.ts";
import { CustomerOrderType } from "src/api/generated/erp/db/types/tables/customerOrderType.ts";
import { AsyncForm } from "src/components/common/forms/AsyncForm.tsx";
import { FormCheckbox } from "src/components/common/forms/fields/FormCheckbox.tsx";

export interface CustomerOrderTypeFormProps extends FormCommonProps<number> {
	customerOrderTypeId: number | undefined;
}

interface FormValues extends CustomerOrderType, ObjectAccountingFormValues {}

export const CustomerOrderTypeForm = (props: CustomerOrderTypeFormProps) => {
	const { customerOrderTypeId, onCompleted, onFormEdited } = props;
	return (
		<AsyncForm
			fetch={() => CustomerOrderTypeEditApi.getFormInitData({ customerOrderTypeId })}
			getDefaultValues={getDefaultValues}
			submit={submit}
			onCompleted={onCompleted}
			onFormEdited={onFormEdited}
			render={({ control }) => (
				<>
					<FormTextField
						control={control}
						name={"name"}
						label={i18n.t("name")}
						rules={requireRule()}
					/>
					<FormTextField control={control} name={"description"} label={i18n.t("description")} />
					<FormCheckbox
						control={control}
						name={"automaticReservation"}
						label={i18n.t("automatic_reservation")}
					/>
					<ObjectAccountingForm control={control} />
				</>
			)}
		/>
	);

	function getDefaultValues({
		customerOrderType,
		accountingDimensions,
	}: CustomerOrderTypeEditApi_FormInitData): DeepPartial<FormValues> {
		const accountingValues = getObjectAccountingFormDefaultValues(accountingDimensions);
		if (customerOrderType != null) {
			return {
				...customerOrderType,
				...accountingValues,
			};
		} else {
			return {
				automaticReservation: true,
				...accountingValues,
			};
		}
	}

	async function submit(values: FormValues) {
		const { accountingDimensionValues, ...customerOrderType } = values;
		const accountingCodeIds = getObjectAccountingCodeIdsFromFormValues(accountingDimensionValues);
		if (customerOrderTypeId != null) {
			await CustomerOrderTypeEditApi.update({
				customerOrderType,
				accountingCodeIds,
			});
			return customerOrderTypeId;
		} else {
			return await CustomerOrderTypeEditApi.insert({
				customerOrderType,
				accountingCodeIds,
			});
		}
	}
};
