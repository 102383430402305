import { FieldPath } from "react-hook-form";
import {
	FormCodeMirrorField,
	FormCodeMirrorFieldProps,
} from "src/components/common/forms/fields/FormCodeMirrorField.tsx";
import { useConfiguratorLuaEditorExtensions } from "src/components/views/erp/configurator/scripting/useConfiguratorLuaEditorExtensions.ts";

export interface FormConfiguratorLuaEditorProps<
	TFieldValues extends object,
	TFieldName extends FieldPath<TFieldValues>,
> extends FormCodeMirrorFieldProps<TFieldValues, TFieldName> {
	productFamilyVersionId: number | undefined | null;
	catalogPartRevisionId: number | null | undefined;
}

export const FormConfiguratorLuaEditor = <
	TFieldValues extends object,
	TFieldName extends FieldPath<TFieldValues>,
>({
	productFamilyVersionId,
	catalogPartRevisionId,
	...other
}: FormConfiguratorLuaEditorProps<TFieldValues, TFieldName>) => {
	const extensions = useConfiguratorLuaEditorExtensions({ productFamilyVersionId, catalogPartRevisionId });

	return <FormCodeMirrorField extensions={extensions} {...other} />;
};
