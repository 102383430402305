// This file is automatically generated. Do not edit manually.

import { AppUser } from "src/api/generated/postgres/db/types/role_management/tables/appUser";
import { Salesperson } from "src/api/generated/erp/db/types/tables/salesperson";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace SalespersonEditApi {
	export async function getSalespersonAppUserSelectionOptions(args: {
		currentSelectionUserId: number | null | undefined;
		searchQuery: string;
	}): Promise<Array<AppUser>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/salespersons/getSalespersonAppUserSelectionOptions",
			method: "POST",
			data: {
				currentSelectionUserId: args.currentSelectionUserId,
				searchQuery: args.searchQuery,
			},
		});
		return response as Array<AppUser>;
	}

	export async function insert(args: { salesperson: Salesperson }): Promise<number> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/salespersons/insert",
			method: "POST",
			data: {
				salesperson: args.salesperson,
			},
		});
		return response as number;
	}

	export async function update(args: { salesperson: Salesperson }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/salespersons/update",
			method: "POST",
			data: {
				salesperson: args.salesperson,
			},
		});
		return response as void;
	}

	export async function delete_(args: { salespersonIds: Array<number> }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/salespersons/delete",
			method: "POST",
			data: {
				salespersonIds: args.salespersonIds,
			},
		});
		return response as void;
	}

	export async function setSalespersonIsActive(args: { salespersonId: number; isActive: boolean }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/salespersons/setSalespersonIsActive",
			method: "POST",
			data: {
				salespersonId: args.salespersonId,
				isActive: args.isActive,
			},
		});
		return response as void;
	}
}
