// This file is automatically generated. Do not edit manually.

import { t } from "i18next";

export const SurveyFormRevisionStateValues = ["INITIAL", "RELEASED"] as const;

export type SurveyFormRevisionState = (typeof SurveyFormRevisionStateValues)[number];

export const getSurveyFormRevisionStateLabels = () => ({
	INITIAL: t("initial"),
	RELEASED: t("released"),
});

export const getSurveyFormRevisionStateLabel = (value: SurveyFormRevisionState): string => {
	return getSurveyFormRevisionStateLabels()[value];
};
