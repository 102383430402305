// This file is automatically generated. Do not edit manually.

import { SurveyCategory } from "src/api/generated/erp/db/types/tables/surveyCategory";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace SurveyCategoryApi {
	export async function getSurveyCategories(): Promise<Array<SurveyCategory>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/surveys/management/categories/getSurveyCategories",
			method: "POST",
			data: {},
		});
		return response as Array<SurveyCategory>;
	}

	export async function insert(args: { surveyCategory: SurveyCategory }): Promise<number> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/surveys/management/categories/insert",
			method: "POST",
			data: {
				surveyCategory: args.surveyCategory,
			},
		});
		return response as number;
	}

	export async function update(args: { surveyCategory: SurveyCategory }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/surveys/management/categories/update",
			method: "POST",
			data: {
				surveyCategory: args.surveyCategory,
			},
		});
		return response as void;
	}

	export async function delete_(args: { surveyCategoryId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/surveys/management/categories/delete",
			method: "POST",
			data: {
				surveyCategoryId: args.surveyCategoryId,
			},
		});
		return response as void;
	}
}
