// This file is automatically generated. Do not edit manually.

import { NumberSequence } from "src/api/generated/erp/db/types/tables/numberSequence";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace NumberSequencesApi {
	export async function getAll(): Promise<Array<NumberSequence>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/common/numberSequences/getAll",
			method: "POST",
			data: {},
		});
		return response as Array<NumberSequence>;
	}

	export async function save(args: { numberSequences: Array<NumberSequence> }): Promise<Array<NumberSequence>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/common/numberSequences/save",
			method: "POST",
			data: {
				numberSequences: args.numberSequences,
			},
		});
		return response as Array<NumberSequence>;
	}

	export async function delete_(args: { numberSequences: Array<NumberSequence> }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/common/numberSequences/delete",
			method: "POST",
			data: {
				numberSequences: args.numberSequences,
			},
		});
		return response as void;
	}
}
