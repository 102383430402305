// This file is automatically generated. Do not edit manually.

import { ResourceStateChart } from "src/api/generated/postgres/db/types/ext_resourcing/tables/resourceStateChart";
import { ResourceControlChartsView } from "src/api/generated/postgres/db/types/ext_resourcing/tables/resourceControlChartsView";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace ResourceStateChartEditApi {
	export async function getInitData(args: {
		resourceId: number;
		resourceStateChartId: number | null | undefined;
	}): Promise<ResourceStateChartEditApi_InitData> {
		const response = await makeAxiosRequest({
			url: "/api/ext/spc/resourceStateChart/getInitData",
			method: "POST",
			data: {
				resourceId: args.resourceId,
				resourceStateChartId: args.resourceStateChartId,
			},
		});
		return response as ResourceStateChartEditApi_InitData;
	}

	export async function save(args: { resourceStateChart: ResourceStateChart }): Promise<number> {
		const response = await makeAxiosRequest({
			url: "/api/ext/spc/resourceStateChart/save",
			method: "POST",
			data: {
				resourceStateChart: args.resourceStateChart,
			},
		});
		return response as number;
	}

	export async function delete_(args: { resourceStateChartIds: Array<number> }): Promise<Array<void>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/spc/resourceStateChart/delete",
			method: "POST",
			data: {
				resourceStateChartIds: args.resourceStateChartIds,
			},
		});
		return response as Array<void>;
	}
}

export interface ResourceStateChartEditApi_InitData {
	otherResourceStates: Array<ResourceStateChart>;
	resourceControlChartOptions: Array<ResourceControlChartsView>;
	resourceStateChart: ResourceStateChart | null | undefined;
}
