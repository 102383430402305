import { CostEventObjectRef } from "src/api/generated/erp/common/costEvents/costEventObjectRef.ts";
import { FormCommonProps } from "../../../../common/forms/types.ts";
import { AsyncFetchRender } from "../../../../common/async/AsyncFetchRender.tsx";
import { CostEvent } from "src/api/generated/erp/db/types/tables/costEvent.ts";
import { currentLocalDateAsIsoString } from "src/utils/dayjsUtils.ts";
import {
	CostEventFormApi,
	CostEventFormApi_InitData,
} from "src/api/generated/erp/common/costEvents/costEventFormApi.ts";
import { AavoForm } from "../../../../common/forms/AavoForm.tsx";
import { FormSelectField } from "../../../../common/forms/fields/FormSelectField.tsx";
import i18n from "i18next";
import { FormTextField } from "../../../../common/forms/fields/FormTextField.tsx";
import { requireRule } from "../../../../common/forms/validation.ts";
import { FormDateField } from "../../../../common/forms/fields/FormDateField.tsx";
import { FormNumberField } from "src/components/common/forms/fields/FormNumberField.tsx";

export interface CostEventFormProps extends FormCommonProps<number> {
	costEventId: number | undefined;
	costEventPriceListId: number;
	newCostEventObjectRef: CostEventObjectRef | undefined;
}

export const CostEventForm = (props: CostEventFormProps) => {
	const { costEventId, costEventPriceListId } = props;
	return (
		<AsyncFetchRender
			fetch={() => CostEventFormApi.getInitData({ costEventId, costEventPriceListId })}
			content={(initData) => <CostEventFormContent initData={initData} {...props} />}
		/>
	);
};

interface CostEventFormContentProps extends CostEventFormProps {
	initData: CostEventFormApi_InitData;
}

const CostEventFormContent = ({
	initData,
	onCompleted,
	onFormEdited,
	newCostEventObjectRef,
	costEventPriceListId,
}: CostEventFormContentProps) => {
	return (
		<AavoForm
			defaultValues={
				initData.costEvent ?? {
					eventDate: currentLocalDateAsIsoString(),
				}
			}
			submit={(formValues) => submitForm(formValues, costEventPriceListId, newCostEventObjectRef)}
			columns={1}
			onCompleted={onCompleted}
			onFormEdited={onFormEdited}
			render={({ control }) => (
				<>
					<FormSelectField
						control={control}
						name={"itemId"}
						label={i18n.t("item")}
						options={initData.availableCostEventItems}
						rules={requireRule()}
						getOptionKey={(o) => o.costEventItemId}
						getOptionLabel={(o) => o.itemName}
					/>
					<FormNumberField
						control={control}
						name={"quantity"}
						label={i18n.t("quantity")}
						rules={requireRule()}
					/>
					<FormDateField
						control={control}
						name={"eventDate"}
						label={i18n.t("date")}
						rules={requireRule()}
					/>
					<FormTextField
						control={control}
						name={"description"}
						label={i18n.t("description")}
						minRows={2}
					/>
				</>
			)}
		/>
	);
};
const submitForm = async (
	costEvent: CostEvent,
	priceListId: number,
	objectRef: CostEventObjectRef | undefined,
): Promise<number> => {
	if (costEvent.costEventId == null) {
		if (objectRef === undefined) {
			throw new Error("objectRef for new cost event is undefined");
		}
		return await CostEventFormApi.createNew({
			costEventItemId: costEvent.itemId,
			costEventPriceListId: priceListId,
			description: costEvent.description,
			quantity: costEvent.quantity,
			eventDate: costEvent.eventDate,
			objectRef: objectRef,
		});
	}
	return await CostEventFormApi.update({
		costEvent: costEvent,
		costEventPriceListId: priceListId,
	});
};
