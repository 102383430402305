import { CatalogPartTestConfigurationResultBomApi } from "src/api/generated/erp/configurator/api/configuratorTesting/catalogPartTestConfigurationResultBomApi.ts";
import { TestConfigurationResultViewCommonProps } from "src/components/views/erp/configurator/configuratorTesting/TestConfigurationResultViewCommonProps.ts";
import { CatalogPartTestConfiguratorResultsView } from "src/components/views/erp/configurator/configuratorTesting/catalogPart/CatalogPartTestConfiguratorResultsView.tsx";
import { TestConfigurationBomDataGridBase } from "src/components/views/erp/configurator/configuratorTesting/TestConfigurationBomDataGridBase.tsx";

export const CatalogPartTestConfigurationBomDataGrid = (
	props: TestConfigurationResultViewCommonProps,
) => {
	const { configurationSessionId, configuratorType, propertyValues } = props;

	return (
		<TestConfigurationBomDataGridBase
			{...props}
			fetchRows={() =>
				CatalogPartTestConfigurationResultBomApi.getTestConfigurationResultBom({
					configurationSessionId,
					configuratorType,
					propertyValues,
				})
			}
			subConfigurationResultsView={CatalogPartTestConfiguratorResultsView}
		/>
	);
};
