import {StackedIcon, StackedIconProps} from "src/components/common/icons/StackedIcon.tsx";
import {faArrowDown, faChartLine} from "@fortawesome/pro-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export interface ChartWithArrowDownIconProps extends StackedIconProps {}

export const ChartWithArrowDownIcon = ({ ...other }: ChartWithArrowDownIconProps) => {
	return (
		<StackedIcon {...other}>
			<FontAwesomeIcon
				icon={faChartLine}
				transform={{
					size: 14,
					x: -2,
					y: 1,
				}}
			/>
			<FontAwesomeIcon
				icon={faArrowDown}
				transform={{
					size: 11,
					x: +9,
					y: 3,
				}}
			/>
		</StackedIcon>
	);
};
