import {
	ReceivePurchaseOrderLinesFormApi,
	ReceivePurchaseOrderLinesFormApi_FormInitData,
} from "src/api/generated/erp/purchase/purchaseOrder/api/receivePurchaseOrderLinesFormApi.ts";
import { AsyncForm, AsyncFormContentParams } from "src/components/common/forms/AsyncForm.tsx";
import { PurchaseOrderLineView } from "src/api/generated/erp/db/types/tables/purchaseOrderLineView.ts";
import { FormCommonProps } from "src/components/common/forms/types.ts";
import { Box, Divider } from "@mui/material";
import { VerticalBox } from "src/components/common/box/VerticalBox.tsx";
import { FormNumberField } from "src/components/common/forms/fields/FormNumberField.tsx";
import i18n from "i18next";
import { requireRule } from "src/components/common/forms/validation.ts";
import { FormCheckbox } from "src/components/common/forms/fields/FormCheckbox.tsx";
import { FormSelectField } from "src/components/common/forms/fields/FormSelectField.tsx";
import { useFieldArray } from "react-hook-form";
import { ReceivePurchaseOrderLineDto } from "src/api/generated/erp/purchase/purchaseOrder/api/receivePurchaseOrderLineDto.ts";

export interface ReceivePurchaseOrderLinesFormProps extends FormCommonProps<void> {
	targetReceiptId: number;
	purchaseOrderLines: PurchaseOrderLineView[];
}

interface FormValues {
	purchaseOrderLines: PurchaseOrderLineFormValues[];
}

interface PurchaseOrderLineFormValues extends ReceivePurchaseOrderLineDto, PurchaseOrderLineView {
	enableOverReceipt: boolean;
}

export const ReceivePurchaseOrderLinesForm = (props: ReceivePurchaseOrderLinesFormProps) => {
	const { purchaseOrderLines, targetReceiptId, onFormEdited, onCompleted } = props;
	return (
		<AsyncForm
			fetch={() =>
				ReceivePurchaseOrderLinesFormApi.getFormInitData({
					purchaseOrderLineIds: purchaseOrderLines.map((line) => line.purchaseOrderLineId),
				})
			}
			getDefaultValues={getDefaultValues}
			onFormEdited={onFormEdited}
			onCompleted={onCompleted}
			submit={submit}
			render={(params) => <FormContent {...params} {...props} />}
		/>
	);

	function getDefaultValues({
		partWarehouseLocationOptionsByPartId,
	}: ReceivePurchaseOrderLinesFormApi_FormInitData): FormValues {
		return {
			purchaseOrderLines: purchaseOrderLines.map((line) => {
				const partWarehouseLocations = partWarehouseLocationOptionsByPartId[line.partId] ?? [];
				const defaultLocation =
					partWarehouseLocations.find((loc) => loc.isDefault) ?? partWarehouseLocations[0];
				return {
					...line,
					purchaseOrderReceiptId: targetReceiptId,
					quantityToReceiveAsPurchaseUnits: line.purchaseQuantity - line.receivedQuantity,
					fullyReceived: true,
					partWarehouseLocationId: defaultLocation?.partWarehouseLocationId,
					allowOverReceipt: false,
					enableOverReceipt: false,
				};
			}),
		};
	}

	async function submit(values: FormValues) {
		await ReceivePurchaseOrderLinesFormApi.submit({
			lines: values.purchaseOrderLines,
		});
	}
};

interface FormContentProps
	extends AsyncFormContentParams<ReceivePurchaseOrderLinesFormApi_FormInitData, FormValues>,
		ReceivePurchaseOrderLinesFormProps {}

const FormContent = ({ control, ...other }: FormContentProps) => {
	const purchaseOrderLineFieldArray = useFieldArray<FormValues>({
		control: control,
		name: "purchaseOrderLines",
	});
	return (
		<>
			{purchaseOrderLineFieldArray.fields.map((line, index) => {
				return (
					<SinglePurchaseOrderLineContent
						key={line.id}
						lineValues={line}
						index={index}
						control={control}
						{...other}
					/>
				);
			})}
		</>
	);
};

interface SinglePurchaseOrderLineContentProps extends FormContentProps {
	lineValues: PurchaseOrderLineFormValues;
	index: number;
}

const SinglePurchaseOrderLineContent = ({
	lineValues,
	control,
	watch,
	trigger,
	index,
	data: { partWarehouseLocationOptionsByPartId },
}: SinglePurchaseOrderLineContentProps) => {
	const demandedQuantity = lineValues.purchaseQuantity - lineValues.receivedQuantity;
	const rowHasOverReceipt = watch(`purchaseOrderLines.${index}.quantityToReceiveAsPurchaseUnits`) > demandedQuantity;
	return (
		<VerticalBox key={lineValues.purchaseOrderLineId} gap={2}>
			<Divider textAlign={"left"} children={lineValues.partLongName} />
			<Box
				sx={{
					display: "grid",
					gridTemplateColumns: "1fr 1fr 1fr",
					gap: 1,
				}}
			>
				<FormNumberField
					control={control}
					name={`purchaseOrderLines.${index}.quantityToReceiveAsPurchaseUnits`}
					label={i18n.t("received_quantity")}
					onChange={async () => {
						await trigger(`purchaseOrderLines.${index}.enableOverReceipt`);
					}}
					rules={{
						required: i18n.t("required"),
						validate: (value) => {
							if (value > demandedQuantity && !watch(`purchaseOrderLines.${index}.enableOverReceipt`))
								return `${i18n.t("row_has_over_receipt")} (${value - demandedQuantity} ${lineValues.purchaseUnit})`;
						},
					}}
				/>
				<Box>
					<FormCheckbox
						control={control}
						name={`purchaseOrderLines.${index}.fullyReceived`}
						label={i18n.t("fully_received")}
					/>
					{rowHasOverReceipt && (
						<FormCheckbox
							control={control}
							name={`purchaseOrderLines.${index}.enableOverReceipt`}
							label={i18n.t("enable_over_receiving_to_warehouse")}
							rules={requireRule()}
							onChange={async () => {
								await trigger(`purchaseOrderLines.${index}.quantityToReceiveAsPurchaseUnits`);
							}}
						/>
					)}
				</Box>
				{lineValues.purchaseOrderType === "TO_SITE" && (
					<FormSelectField
						control={control}
						name={`purchaseOrderLines.${index}.partWarehouseLocationId`}
						label={i18n.t("warehouse_location")}
						options={getPartWarehouseLocationOptions(lineValues)}
						getOptionKey={(option) => option.partWarehouseLocationId}
						getOptionLabel={(option) => option.locationCode}
						rules={requireRule()}
					/>
				)}
			</Box>
		</VerticalBox>
	);

	function getPartWarehouseLocationOptions(line: PurchaseOrderLineFormValues) {
		return partWarehouseLocationOptionsByPartId[line.partId] ?? [];
	}
};
