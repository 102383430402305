import { AavoForm } from "src/components/common/forms/AavoForm.tsx";
import { ControlChartExportApi } from "src/api/generated/spc/controlChart/api/controlChartExportApi.ts";
import { FormCommonProps } from "src/components/common/forms/types.ts";
import { FormDateField } from "src/components/common/forms/fields/FormDateField.tsx";
import { downloadFile } from "src/utils/fileDownloading.ts";
import { currentLocalDateAsIsoString } from "src/utils/dayjsUtils.ts";
import { IsoDateString } from "src/types/dateTime.ts";
import i18n from "i18next";

export interface ControlChartsDataExportFormProps extends FormCommonProps<void> {
	controlChartIds: number[];
}

interface FormValues {
	startDate: IsoDateString;
	endDate: IsoDateString;
}

export const ControlChartsDataExportForm = ({
	controlChartIds,
	onCompleted,
}: ControlChartsDataExportFormProps) => {
	return (
		<AavoForm
			defaultValues={{
				startDate: currentLocalDateAsIsoString(),
				endDate: currentLocalDateAsIsoString(),
			}}
			submit={submitForm}
			onCompleted={onCompleted}
			render={({ control }) => (
				<>
					<FormDateField control={control} name={"startDate"} label={i18n.t("from_date")} />
					<FormDateField control={control} name={"endDate"} label={i18n.t("to_date")} />
				</>
			)}
		/>
	);

	async function submitForm({ startDate, endDate }: FormValues) {
		const fileHandle = await ControlChartExportApi.exportControlChartsData({
			controlChartIds: controlChartIds,
			startDate: startDate,
			endDate: endDate,
		});
		downloadFile(fileHandle);
	}
};
