import {AsyncMenuButton} from "../../../../common/contextMenu/AsyncMenuButton.tsx";
import i18n from "i18next";
import {
    faBan,
    faBriefcase,
    faCheckSquare,
    faClone,
    faCommentDollar,
    faCopy,
    faEnvelope,
    faEye,
    faEyeSlash,
    faFileImport,
    faFileWord,
    faIcicles,
    faLink,
    faPercent,
    faPrint,
    faProjectDiagram,
    faShare,
    faSwatchbook,
    faUndo,
    faUniversity,
} from "@fortawesome/pro-regular-svg-icons";
import {CustomerOrderActionApi} from "src/api/generated/erp/sales/customerOrder/api/customerOrderActionApi.ts";
import {CustomerOrderQueryApi} from "src/api/generated/erp/sales/customerOrder/api/customerOrderQueryApi.ts";
import {downloadFile} from "src/utils/fileDownloading.ts";
import {
    openLegacyCustomerOrderPreLineImportView,
    openLegacyDocumentSendingView,
    openLegacyWorkspaceView,
} from "../../../legacy/legacyViewAdapters.ts";
import {OpenObjectChangeLogButton} from "src/components/views/changeLogging/OpenObjectChangeLogButton.tsx";
import {
    CustomerOrderDataGridApi,
    CustomerOrderDataGridApi_CustomerOrderDto,
} from "src/api/generated/erp/sales/customerOrder/api/customerOrderDataGridApi.ts";
import {ServerSideDataGridModelRefreshFunc} from "../../../../common/dataGrid/gridModel/useServerSideDataGridModel";
import {useConfirmDialog} from "../../../../common/dialogs/confirmDialog/useConfirmDialog.ts";
import {useOpenLegacyView} from "../../../legacy/useOpenLegacyView.ts";
import {useTenantCustomizations} from "src/tenantCustomizations/useTenantCustomizations.tsx";
import {useInputDialog} from "src/components/common/dialogs/input/useInputDialog.tsx";
import {
    connectCustomerOrderToProject,
    copyCustomerOrderTasks,
    createProjectForCustomerOrder,
} from "src/components/views/erp/sales/customerOrder/customerOrderActions.tsx";
import {AavoDataGridRowContextMenuParams} from "src/components/common/dataGrid/AavoDataGrid.tsx";
import {CustomerOrderReleaseApi} from "src/api/generated/erp/sales/customerOrder/api/customerOrderReleaseApi.ts";
import {CustomerOrdersPageContext} from "src/components/views/erp/sales/customerOrder/CustomerOrdersPageContext.tsx";
import {useContext} from "react";
import {CustomerOrderOffersPage} from "src/components/views/erp/sales/customerOrderOffer/CustomerOrderOffersPage.tsx";
import {useGenericDialog} from "src/components/common/dialogs/useGenericDialog.ts";
import {ProjectTreeViewContainer} from "src/components/views/erp/project/treeView/ProjectTreeViewContainer.tsx";
import {
    ProjectAllActivityMaterialsDataGrid
} from "src/components/views/erp/project/projectActivity/material/ProjectAllActivityMaterialsDataGrid.tsx";
import {
    CustomerOrderAccountingForm
} from "src/components/views/erp/sales/customerOrder/CustomerOrderAccountingForm.tsx";
import {openFormOnDialog} from "src/components/common/dialogs/formDialog/openFormOnDialog.ts";
import {cancelCustomerOrder} from "src/components/views/erp/sales/customerOrder/actions/cancelCustomerOrder.ts";
import {OpenCustomerOrderButton} from "src/components/views/erp/utilComponents/OpenCustomerButton.tsx";

export interface CustomerOrdersDataGridContextMenuComponentsProps
	extends AavoDataGridRowContextMenuParams<CustomerOrderDataGridApi_CustomerOrderDto> {
	refreshData: ServerSideDataGridModelRefreshFunc<typeof CustomerOrderDataGridApi.searchCustomerOrders>;
}

export const CustomerOrdersDataGridContextMenuComponents = (
	props: CustomerOrdersDataGridContextMenuComponentsProps,
) => {
	const { refreshData, row, onlySingleRowSelected } = props;

	const confirm = useConfirmDialog();
	const { openDialog } = useGenericDialog();
	const openLegacyView = useOpenLegacyView();

	const tenantCustomizations = useTenantCustomizations();
	const tenantConfig = tenantCustomizations.tenantConfig;
	const salesCustomizations = tenantCustomizations.erp?.sales;

	const showInputDialog = useInputDialog();

	const customerOrdersPageContext = useContext(CustomerOrdersPageContext);
	const customerOrderLinesRefreshRef = customerOrdersPageContext?.customerOrderLinesRefreshRef;

	return [
		row.releasedAt == null && onlySingleRowSelected && (
			<AsyncMenuButton key={"release"} label={i18n.t("release")} icon={faShare} onClick={releaseCustomerOrder} />
		),
		row.confirmedAt == null && onlySingleRowSelected && (
			<AsyncMenuButton
				key={"confirmCustomerOrder"}
				label={i18n.t("confirm")}
				icon={faCheckSquare}
				onClick={async () => {
					await CustomerOrderActionApi.confirmCustomerOrder({
						customerOrderId: row.customerOrderId,
					});
					await refreshData();
				}}
			/>
		),
		row.customerOrderState === "INITIAL" && onlySingleRowSelected && (
			<AsyncMenuButton
				key={"cancelCustomerOrder"}
				label={i18n.t("cancel")}
				icon={faBan}
				onClick={async () => {
					const cancelled = await cancelCustomerOrder({
						customerOrderId: row.customerOrderId,
						showConfirmDialog: confirm,
					});
					if (cancelled) await refreshData();
				}}
			/>
		),
		onlySingleRowSelected && (
			<AsyncMenuButton
				key={"printCustomerOrderConfirmation"}
				label={i18n.t("order_confirmation")}
				icon={faPrint}
				onClick={async () => {
					const fileHandle = await CustomerOrderActionApi.printCustomerOrderConfirmation({
						customerOrderId: row.customerOrderId,
					});
					downloadFile(fileHandle);
				}}
			/>
		),
		onlySingleRowSelected && <OpenCustomerOrderButton key={"showCustomer"} customerId={row.customerId} />,
		onlySingleRowSelected && (
			<AsyncMenuButton
				key={"printCustomerOrderDegreeOfReadinessReport"}
				label={i18n.t("degree_of_readiness_report")}
				icon={faPercent}
				onClick={async () => {
					const apiFunc =
						salesCustomizations?.printCustomerOrderDegreeOfReadinessReport ??
						CustomerOrderActionApi.printCustomerOrderDegreeOfReadinessReport;

					const fileHandle = await apiFunc({ customerOrderId: row.customerOrderId });
					downloadFile(fileHandle);
				}}
			/>
		),
		onlySingleRowSelected && (
			<AsyncMenuButton
				key={"sendDocuments"}
				label={i18n.t("send_documents")}
				icon={faEnvelope}
				onClick={async () => {
					if (row.customerOrderId == null) return;
					await openLegacyDocumentSendingView({
						openLegacyView: openLegacyView,
						objectRef: {
							objectType: "CUSTOMER_ORDER",
							objectId: row.customerOrderId,
						},
					});
				}}
			/>
		),
		onlySingleRowSelected && tenantConfig.erp.projectEnabled && row.projectId == null && (
			<AsyncMenuButton
				key={"createProjectButton"}
				label={i18n.t("create_project")}
				icon={faProjectDiagram}
				onClick={async () => {
					await createProjectForCustomerOrder(row, showInputDialog);
					await refreshData();
				}}
			/>
		),
		onlySingleRowSelected && tenantConfig.erp.projectEnabled && row.projectId == null && (
			<AsyncMenuButton
				key={"connectToProject"}
				label={i18n.t("connect_to_project")}
				icon={faLink}
				onClick={async () => {
					await connectCustomerOrderToProject(row, showInputDialog);
					await refreshData();
				}}
			/>
		),
		onlySingleRowSelected && tenantConfig.erp.projectEnabled && row.projectId != null && (
			<AsyncMenuButton
				key={"showProject"}
				label={i18n.t("show_project")}
				icon={faProjectDiagram}
				onClick={async () => {
					if (row.projectId == null) return;
					openDialog({
						title: i18n.t("project"),
						content: <ProjectTreeViewContainer onlyProjectId={row.projectId} />,
					});
				}}
			/>
		),
		onlySingleRowSelected && tenantConfig.erp.projectEnabled && row.projectId != null && (
			<AsyncMenuButton
				key={"showProjectMaterials"}
				label={i18n.t("project_materials")}
				icon={faSwatchbook}
				onClick={async () => {
					if (row.projectId == null) return;
					openDialog({
						title: i18n.t("project_materials"),
						content: <ProjectAllActivityMaterialsDataGrid projectId={row.projectId} />,
					});
				}}
			/>
		),
		onlySingleRowSelected && (
			<AsyncMenuButton
				key={"showDocuments"}
				label={i18n.t("documents_texts")}
				icon={faFileWord}
				onClick={async () => {
					if (row.customerOrderId == null) return;
					openLegacyView("E1BC1901A2CDAF5", {
						customerOrderId: row.customerOrderId,
					});
				}}
			/>
		),
		onlySingleRowSelected && (
			<AsyncMenuButton
				key={"copyTasks"}
				label={i18n.t("copy_tasks")}
				icon={faClone}
				onClick={async () => {
					await copyCustomerOrderTasks(row, showInputDialog);
					await refreshData();
				}}
			/>
		),
		onlySingleRowSelected && (
			<AsyncMenuButton
				key={"workspaces"}
				label={i18n.t("workspaces")}
				icon={faBriefcase}
				onClick={async () => {
					const workspaces = await CustomerOrderQueryApi.getCustomerOrderWorkspaces({
						customerOrderId: row.customerOrderId,
					});
					openLegacyWorkspaceView({
						openLegacyView: openLegacyView,
						workspaceIds: workspaces.map((workspace) => workspace.workspaceId),
					});
				}}
			/>
		),
		onlySingleRowSelected && row.customerOrderOfferId != null && (
			<AsyncMenuButton
				key={"showOffer"}
				label={i18n.t("show_offer")}
				icon={faCommentDollar}
				onClick={async () => {
					openDialog({
						title: i18n.t("offer"),
						content: (
							<CustomerOrderOffersPage
								onlyCustomerOrderOfferToShowId={row.customerOrderOfferId ?? undefined}
							/>
						),
					});
				}}
			/>
		),
		onlySingleRowSelected && row.sourceType == "MANUAL" && (
			<AsyncMenuButton
				key={"copyCustomerOrder"}
				label={i18n.t("copy_customer_order")}
				icon={faCopy}
				onClick={async () => {
					openLegacyView("2787533CCC85A0FF", {
						customerOrderId: row.customerOrderId,
						_targetCustomerId: row.customerId,
			});
					await refreshData();
				}}
			/>
		),
		onlySingleRowSelected && row.confirmedAt != null && (
			<AsyncMenuButton
				key={"revertConfirmation"}
				label={i18n.t("revert_confirmation")}
				icon={faUndo}
				onClick={async () => {
					const confirmed = await confirm({
						title: i18n.t("revert_confirmation"),
						message: i18n.t("are_you_sure"),
					});
					if (!confirmed) return;

					await CustomerOrderActionApi.revertConfirmCustomerOrder({
						customerOrderId: row.customerOrderId,
					});
					await refreshData();
				}}
			/>
		),
		onlySingleRowSelected && tenantConfig.erp.customerOrderCheckingEnabled && row.checkedAt == null && (
			<AsyncMenuButton
				key={"checkCustomerOrder"}
				label={i18n.t("check")}
				icon={faEye}
				onClick={async () => {
					const confirmed = await confirm({
						title: i18n.t("check"),
						message: i18n.t("are_you_sure"),
					});
					if (!confirmed) return;
					await CustomerOrderActionApi.checkCustomerOrder({
						customerOrderId: row.customerOrderId,
					});
					await refreshData();
				}}
			/>
		),
		onlySingleRowSelected && tenantConfig.erp.customerOrderCheckingEnabled && row.checkedAt != null && (
			<AsyncMenuButton
				key={"revertChecking"}
				label={i18n.t("revert_checking")}
				icon={faEyeSlash}
				onClick={async () => {
					const confirmed = await confirm({
						title: i18n.t("revert_checking"),
						message: i18n.t("are_you_sure"),
					});
					if (!confirmed) return;
					await CustomerOrderActionApi.revertCheckCustomerOrder({
						customerOrderId: row.customerOrderId,
					});
					await refreshData();
				}}
			/>
		),
		onlySingleRowSelected && tenantConfig.erp.customerOrderFreezingEnabled && row.frozenAt == null && (
			<AsyncMenuButton
				key={"freezeCustomerOrder"}
				label={i18n.t("freeze")}
				icon={faIcicles}
				onClick={async () => {
					const confirmed = await confirm({
						title: i18n.t("freeze"),
						message: i18n.t("are_you_sure"),
					});
					if (!confirmed) return;
					await CustomerOrderActionApi.freezeCustomerOrder({
						customerOrderId: row.customerOrderId,
					});
					await refreshData();
				}}
			/>
		),
		onlySingleRowSelected && tenantConfig.erp.customerOrderFreezingEnabled && row.frozenAt != null && (
			<AsyncMenuButton
				key={"revertFreeze"}
				label={i18n.t("revert_freeze")}
				icon={faUndo}
				onClick={async () => {
					const confirmed = await confirm({
						title: i18n.t("revert_freeze"),
						message: i18n.t("are_you_sure"),
					});
					if (!confirmed) return;
					await CustomerOrderActionApi.unfreezeCustomerOrder({
						customerOrderId: row.customerOrderId,
					});
					await refreshData();
				}}
			/>
		),
		onlySingleRowSelected && row.customerOrderId != null && (
			<OpenObjectChangeLogButton
				key={"changeLog"}
				objectRef={{ objectType: "CUSTOMER_ORDER", objectId: row.customerOrderId }}
			/>
		),
		<AsyncMenuButton
			key={"importLines"}
			label={i18n.t("import_lines")}
			icon={faFileImport}
			onClick={() => {
				openLegacyCustomerOrderPreLineImportView({
					openLegacyView: openLegacyView,
					targetType: "CUSTOMER_ORDER",
					targetId: row.customerOrderId,
					targetPackageLineId: null,
				});
			}}
			disabled={row.customerOrderState === "INVOICED" || row.customerOrderState === "CANCELLED"}
		/>,
		onlySingleRowSelected && (
			<AsyncMenuButton
				key={"accounting"}
				label={i18n.t("accounting")}
				icon={faUniversity}
				onClick={() => {
					openFormOnDialog({
						openDialog,
						title: i18n.t("accounting"),
						size: "sm",
						component: CustomerOrderAccountingForm,
						props: {
							customerOrderId: row.customerOrderId,
						},
					});
				}}
			/>
		),
		salesCustomizations?.customerOrderContextMenuComponents?.(props),
	];

	async function releaseCustomerOrder() {
		const confirmed = await confirm({
			message: i18n.t("release_customer_order_confirmation"),
		});
		if (!confirmed) return;

		const { configuredLinesWithChangedProductFamilyVersion, hasIncompleteSalesConditions } =
			await CustomerOrderReleaseApi.getCustomerOrderReleasePreInfo({
				customerOrderId: row.customerOrderId,
			});

		if (hasIncompleteSalesConditions) {
			const confirmed = await confirm({
				message: i18n.t("customer_order_has_incomplete_sales_conditions_still_release"),
			});
			if (!confirmed) return;
		}
		if (configuredLinesWithChangedProductFamilyVersion.length > 0) {
			const confirmed = await confirm({
				title: i18n.t("changed_configurator_version"),
				message: i18n.t("order_lines_has_changed_configurator_version_after_configuring", {
					lineNumbers: configuredLinesWithChangedProductFamilyVersion
						.map((line) => line.lineNumber)
						.join(", "),
				}),
			});
			if (!confirmed) return;
		}
		await CustomerOrderReleaseApi.releaseCustomerOrder({
			customerOrderId: row.customerOrderId,
		});
		await refreshData();
		customerOrderLinesRefreshRef?.refresh();
	}
};
