import i18n from "i18next";
import { CatalogPartRevisionBomDataGrid } from "src/components/views/erp/parts/catalogPart/CatalogPartRevisionBomDataGrid.tsx";
import { AavoMosaic } from "src/components/common/mosaic/AavoMosaic.tsx";
import { CatalogPartRevisionLineView } from "src/api/generated/erp/db/types/tables/catalogPartRevisionLineView.ts";
import { useRef, useState } from "react";
import { CrudDataGridApi } from "src/components/common/dataGrid/crud/CrudDataGrid.tsx";
import { AavoDataGridApi } from "src/components/common/dataGrid/AavoDataGridApi.ts";
import { DataDirtyStateChangeHandler } from "src/utils/dataDirtyStateChangeHandler.ts";
import { useDebounce } from "src/utils/useDebounce.ts";
import { AsyncFetchRender } from "src/components/common/async/AsyncFetchRender.tsx";
import { CatalogPartRevisionApi } from "src/api/generated/erp/parts/catalogPart/api/catalogPartRevisionApi";
import { ConfiguratorLuaEditor } from "../../configurator/scripting/ConfiguratorLuaEditor";

export interface CatalogPartRevisionBomViewProps {
	catalogPartRevisionId: number;
	dataDirtyStateChanged?: DataDirtyStateChangeHandler;
}

export const CatalogPartRevisionBomView = ({
	catalogPartRevisionId,
	dataDirtyStateChanged,
}: CatalogPartRevisionBomViewProps) => {
	const [selectedBomLine, setSelectedBomLine] = useState<CatalogPartRevisionLineView | null>(null);
	const crudDataGridApiRef = useRef<CrudDataGridApi | null>(null);
	const gridApiRef = useRef<AavoDataGridApi<CatalogPartRevisionLineView> | null>(null);

	const debounceTransformationScriptEdit = useDebounce();

	return (
		<AsyncFetchRender
			fetch={() => CatalogPartRevisionApi.getCatalogPartRevision({ catalogPartRevisionId })}
			content={(catalogPartRevision) => (
				<AavoMosaic
					viewId={"5FDD9CD476371CA9"}
					layout={{
						type: "row",
						first: {
							type: "panel",
							title: i18n.t("product_structure"),
							content: (
								<CatalogPartRevisionBomDataGrid
									catalogPartRevision={catalogPartRevision}
									onRowSelectionChanged={(selectedRows) => {
										if (selectedRows[0] != null)
											return setSelectedBomLine(selectedRows[0]);
										else return null;
									}}
									selectedRow={selectedBomLine}
									crudDataGridApiRef={crudDataGridApiRef}
									gridApiRef={gridApiRef}
									dataDirtyStateChanged={dataDirtyStateChanged}
								/>
							),
						},
						second: {
							type: "panel",
							hidden: !catalogPartRevision.isConfigurable,
							title: i18n.t("configurator_rules"),
							content:
								selectedBomLine == null ?
									<></>
								:	<ConfiguratorLuaEditor
										key={selectedBomLine.catalogPartRevisionLineId}
										productFamilyVersionId={
											catalogPartRevision.configurationProductFamilyVersionId
										}
										catalogPartRevisionId={catalogPartRevision.catalogPartRevisionId}
										disabled={catalogPartRevision.state !== "INITIAL"}
										defaultValue={
											gridApiRef.current?.getRow(
												selectedBomLine.catalogPartRevisionLineId,
											)?.configuratorTransformationScript
										}
										onChange={onTransformationScriptChange}
									/>,
						},
					}}
				/>
			)}
		/>
	);

	function onTransformationScriptChange(value: string) {
		debounceTransformationScriptEdit(300, () => {
			if (crudDataGridApiRef.current == null) throw Error("crudDataGridApiRef is null");

			crudDataGridApiRef.current.updateRows([
				{
					...selectedBomLine,
					configuratorTransformationScript: value,
				},
			]);
			dataDirtyStateChanged?.({ isDirty: true });
		});
	}
};
