import { CChartEventSearchApi } from "src/api/generated/spc/cChartEvents/cChartEventSearchApi.ts";
import { ControlledAsyncDataGrid } from "src/components/common/dataGrid/ControlledAsyncDataGrid";
import i18n from "i18next";
import { LazyMultiSelectField } from "src/components/common/inputFields/LazyMultiSelectField.tsx";
import { ChartGroupView } from "src/api/generated/postgres/db/types/public_/tables/chartGroupView.ts";
import { useClientSideDataGridModel } from "src/components/common/dataGrid/gridModel/useClientSideDataGridModel";
import { ControlChartCommonApi } from "src/api/generated/spc/controlChart/api/controlChartCommonApi.ts";

export interface ChartGroupSelectionViewProps {
	onChartGroupSelected: (chartGroup: ChartGroupView | undefined) => void;
}

export const ChartGroupSelectionView = ({ onChartGroupSelected }: ChartGroupSelectionViewProps) => {
	const { dataGridProps, currentParams, refreshData } = useClientSideDataGridModel({
		gridId: "6E790D02CA4CA696",
		fetchData: CChartEventSearchApi.getChartGroupOptions,
		initialParams: {
			chartParentGroupIds: Array<number>(),
		},
		getRowId: (row) => row.chartGroupId,
		onSelectionChanged: (rows) => onChartGroupSelected(rows[0]),
	});

	return (
		<ControlledAsyncDataGrid<ChartGroupView>
			disableMultipleRowSelection
			columns={[
				{
					field: "parentGroupName",
					headerName: i18n.t("parent_group"),
					width: 150,
				},
				{
					field: "name",
					headerName: i18n.t("group"),
					width: 200,
				},
			]}
			actionBarComponents={
				<>
					<LazyMultiSelectField
						label={i18n.t("parent_group")}
						fetchOptions={ControlChartCommonApi.getAllChartParentGroups}
						getOptionKey={(option) => option.id}
						getOptionLabel={(option) => option.name}
						value={currentParams.chartParentGroupIds}
						onChange={async (value) => {
							await refreshData({ chartParentGroupIds: value });
						}}
					/>
				</>
			}
			{...dataGridProps}
		/>
	);
};
