// This file is automatically generated. Do not edit manually.

import { t } from "i18next";

export const PurchaseOrderLineAppendModeValues = [
	"APPEND_ALWAYS",
	"APPEND_IF_SAME_SOURCE_TYPE",
	"APPEND_IF_EXACTLY_SAME_SOURCE",
] as const;

export type PurchaseOrderLineAppendMode = (typeof PurchaseOrderLineAppendModeValues)[number];

export const getPurchaseOrderLineAppendModeLabels = () => ({
	APPEND_ALWAYS: t("append_always"),
	APPEND_IF_SAME_SOURCE_TYPE: t("append_if_same_source_type"),
	APPEND_IF_EXACTLY_SAME_SOURCE: t("append_if_exactly_same_source"),
});

export const getPurchaseOrderLineAppendModeLabel = (value: PurchaseOrderLineAppendMode): string => {
	return getPurchaseOrderLineAppendModeLabels()[value];
};
