import { FieldPath, FieldPathValue, useController, UseControllerProps } from "react-hook-form";
import { FormFieldLayoutProps, formFieldLayoutPropsToSx } from "src/components/common/forms/styles.ts";
import { mergeSx } from "src/utils/styles.ts";
import { AavoCodeMirror, AavoCodeMirrorProps } from "src/components/common/codeMirror/AavoCodeMirror.tsx";

export interface FormCodeMirrorFieldProps<TFieldValues extends object, TFieldName extends FieldPath<TFieldValues>>
	extends Omit<AavoCodeMirrorProps, "value" | "defaultValue">,
		Pick<UseControllerProps<TFieldValues, TFieldName>, "control" | "name" | "rules">,
		FormFieldLayoutProps {}

export const FormCodeMirrorField = <TFieldValues extends object, TFieldName extends FieldPath<TFieldValues>>({
	control,
	name,
	rules,
	disabled,
	onChange: onChangeProp,
	spanGridColumns,
	startNewGridRow,
	sx,
	...other
}: FormCodeMirrorFieldProps<TFieldValues, TFieldName>) => {
	const { field } = useController({
		name,
		control,
		rules,
		defaultValue: "" as FieldPathValue<TFieldValues, TFieldName>,
	});

	return (
		<AavoCodeMirror
			value={field.value !== null ? field.value : ""}
			onChange={(v: string) => {
				field.onChange(v);
				onChangeProp?.(v);
			}}
			disabled={disabled || field.disabled}
			sx={mergeSx(
				formFieldLayoutPropsToSx({
					spanGridColumns,
					startNewGridRow,
				}),
				sx,
			)}
			{...other}
		/>
	);
};
