import { FormCommonProps } from "src/components/common/forms/types.ts";
import { AsyncForm, AsyncFormContentParams } from "src/components/common/forms/AsyncForm.tsx";
import {
	SubProjectEditApi,
	SubProjectEditApi_FormInitData,
} from "src/api/generated/erp/project/subProject/api/subProjectEditApi.ts";
import { DeepPartial } from "react-hook-form";
import { SubProject } from "src/api/generated/erp/db/types/tables/subProject.ts";
import { FormTextField } from "src/components/common/forms/fields/FormTextField.tsx";
import { FormAsyncUserSelectField } from "src/components/views/users/FormAsyncUserSelectField.tsx";
import { requireRule } from "src/components/common/forms/validation.ts";
import i18n from "i18next";
import { FormSelectField } from "src/components/common/forms/fields/FormSelectField.tsx";
import { FormDateField } from "src/components/common/forms/fields/FormDateField.tsx";
import { FormCheckbox } from "src/components/common/forms/fields/FormCheckbox.tsx";

export interface SubProjectFormProps extends FormCommonProps<number> {
	projectId: number;
	subProjectId: number | undefined;
	disabled?: boolean;
}

interface FormValues extends SubProject {
	inheritScheduleChange: boolean;
}

export const SubProjectForm = (props: SubProjectFormProps) => {
	const { projectId, subProjectId, onCompleted, onFormEdited, disabled } = props;
	return (
		<AsyncForm
			fetch={() => SubProjectEditApi.getFormInitData({ projectId, subProjectId })}
			getDefaultValues={getDefaultValues}
			submit={submit}
			onCompleted={onCompleted}
			onFormEdited={onFormEdited}
			columns={2}
			getUseFormProps={() => ({ disabled: disabled })}
			render={(contentParams) => <FormContent {...props} {...contentParams} />}
		/>
	);

	function getDefaultValues({
		project,
		subProject,
	}: SubProjectEditApi_FormInitData): DeepPartial<FormValues> {
		if (subProject != null)
			return {
				...subProject,
				inheritScheduleChange: true,
			};
		else
			return {
				projectId: projectId,
				plannedStartDate: project.plannedStartDate,
				plannedEndDate: project.plannedEndDate,
			};
	}

	async function submit({ inheritScheduleChange, ...subProject }: FormValues) {
		if (subProjectId != null) {
			await SubProjectEditApi.update({ subProject, inheritScheduleChange });
			return subProjectId;
		} else {
			return await SubProjectEditApi.insert({ subProject });
		}
	}
};

interface FormContentProps
	extends SubProjectFormProps,
		AsyncFormContentParams<SubProjectEditApi_FormInitData, FormValues> {}

const FormContent = ({
	subProjectId,
	control,
	data: { subProjectTypeOptions, projectOtherSubProjects },
	formState: { dirtyFields },
}: FormContentProps) => {
	const isExistingRecord = subProjectId != null;

	return (
		<>
			<FormTextField
				control={control}
				name={"subProjectDescription"}
				label={i18n.t("name")}
				rules={requireRule()}
			/>
			<FormSelectField
				control={control}
				name={"subProjectTypeId"}
				label={i18n.t("type")}
				options={subProjectTypeOptions}
				getOptionKey={(o) => o.subProjectTypeId}
				getOptionLabel={(o) => o.name}
			/>
			<FormAsyncUserSelectField
				control={control}
				name={"responsiblePersonId"}
				label={i18n.t("responsible_person")}
				rules={requireRule()}
			/>
			<FormDateField
				control={control}
				name={"plannedStartDate"}
				label={i18n.t("planned_start")}
				rules={requireRule()}
				startNewGridRow
			/>
			<FormDateField
				control={control}
				name={"plannedEndDate"}
				label={i18n.t("planned_end")}
				rules={requireRule()}
			/>
			{isExistingRecord && dirtyFields.plannedStartDate && (
				<FormCheckbox
					control={control}
					name={"inheritScheduleChange"}
					label={i18n.t("inherit_schedule_change_to_lower_level_items")}
					spanGridColumns
				/>
			)}
			<FormTextField control={control} name={"note"} label={i18n.t("note")} multiline spanGridColumns />
			<FormSelectField
				control={control}
				name={"startAfterSubProjectCompletedId"}
				label={i18n.t("start_after_another_sub_project_completed")}
				options={projectOtherSubProjects}
				getOptionKey={(o) => o.subProjectId}
				getOptionLabel={(o) => o.subProjectDescription}
				startNewGridRow
			/>
		</>
	);
};
