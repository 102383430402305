import { FieldPath, FieldPathValue, useController, UseControllerProps } from "react-hook-form";
import {
	AavoFileInput,
	AavoFileInputProps,
} from "src/components/common/inputFields/fileInput/AavoFileInput.tsx";

export interface FormSingleFileInputFieldProps<
	TFieldValues extends object,
	TFieldName extends FieldPath<TFieldValues>,
> extends Omit<AavoFileInputProps<false>, "onChange" | "value" | "name">,
		Pick<UseControllerProps<TFieldValues, TFieldName>, "control" | "name" | "rules"> {}

export const FormSingleFileInputField = <
	TFieldValues extends object,
	TFieldName extends FieldPath<TFieldValues>,
>({
	control,
	name,
	rules,
	...other
}: FormSingleFileInputFieldProps<TFieldValues, TFieldName>) => {
	const { field, fieldState } = useController({
		name,
		control,
		rules,
		defaultValue: null as FieldPathValue<TFieldValues, TFieldName>,
	});

	const transformedField = {
		...field,
		ref: undefined,
	};

	return (
		<AavoFileInput<false>
			multiple={false}
			error={fieldState.error?.message}
			{...other}
			{...transformedField}
		/>
	);
};
