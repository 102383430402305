import dayjs, { Dayjs } from "dayjs";
import { FieldPathValue, UseControllerProps } from "react-hook-form";
import { FieldPath, useController } from "react-hook-form";
import { AavoDatePicker, AavoDatePickerProps } from "../../inputFields/AavoDatePicker";
import { dayJsToDateIsoString } from "src/utils/dayjsUtils.ts";
import { mergeSx } from "src/utils/styles.ts";
import { FormFieldLayoutProps, formFieldLayoutPropsToSx } from "src/components/common/forms/styles.ts";
import { ConditionalNullable } from "src/components/common/inputFields/types.ts";

export interface FormDateFieldProps<
	TFieldValues extends object,
	TFieldName extends FieldPath<TFieldValues>,
	DisableClearable extends boolean | undefined,
> extends Omit<AavoDatePickerProps<DisableClearable>, "value">,
		FormFieldLayoutProps {
	control: UseControllerProps<TFieldValues, TFieldName>["control"];
	name: UseControllerProps<TFieldValues, TFieldName>["name"];
	rules?: UseControllerProps<TFieldValues, TFieldName>["rules"];
}

export const FormDateField = <
	TFieldValues extends object,
	TFieldName extends FieldPath<TFieldValues>,
	DisableClearable extends boolean | undefined,
>({
	control,
	name,
	rules,
	spanGridColumns,
	startNewGridRow,
	sx,
	onChange,
	...other
}: FormDateFieldProps<TFieldValues, TFieldName, DisableClearable>) => {
	const { field, fieldState } = useController({
		name,
		control,
		rules,
		defaultValue: null as FieldPathValue<TFieldValues, TFieldName>,
	});

	const dayJsValue = field.value === null ? null : dayjs(field.value);

	const transformedField = {
		...field,
		value: dayJsValue,
		onChange: (date: ConditionalNullable<Dayjs, DisableClearable>) => {
			field.onChange(date === null ? null : dayJsToDateIsoString(date));
			onChange?.(date as ConditionalNullable<Dayjs, DisableClearable>);
		},
		ref: undefined,
	};

	return (
		<AavoDatePicker
			errorMessage={fieldState.error?.message}
			sx={mergeSx(
				formFieldLayoutPropsToSx({
					spanGridColumns,
					startNewGridRow,
				}),
				sx,
			)}
			{...transformedField}
			{...other}
		/>
	);
};
