// This file is automatically generated. Do not edit manually.

import { IsoDateString } from "src/types/dateTime";
import { PurchaseRequestState } from "src/api/generated/erp/db/types/enums/purchaseRequestState";
import { ServerSideDataModelRequest } from "src/api/generated/common/dataGrids/serverSideDataModelRequest";
import { ServerSideDataModelResult } from "src/api/generated/common/dataGrids/serverSideDataModelResult";
import { Supplier } from "src/api/generated/erp/db/types/tables/supplier";
import { IsoDateTimeString } from "src/types/dateTime";
import { PurchaseRequestViewCurrency } from "src/api/generated/erp/db/types/enums/purchaseRequestViewCurrency";
import { PurchaseRequestSourceType } from "src/api/generated/erp/db/types/enums/purchaseRequestSourceType";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace PurchaseRequestDataTableApi {
	export async function getPurchaseRequests(args: {
		onlyPurchaseRequestId?: number | null | undefined;
		demandDateBefore?: IsoDateString | null | undefined;
		stateFilter?: PurchaseRequestState | null | undefined;
		supplierId?: number | null | undefined;
		showOnlyDefaultSite?: boolean;
		searchQuery?: string;
		dataModelRequest: ServerSideDataModelRequest;
	}): Promise<ServerSideDataModelResult<PurchaseRequestDataTableApi_RowDto>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/purchase/purchaseRequests/dataTable/getPurchaseRequests",
			method: "POST",
			data: {
				onlyPurchaseRequestId: args.onlyPurchaseRequestId,
				demandDateBefore: args.demandDateBefore,
				stateFilter: args.stateFilter,
				supplierId: args.supplierId,
				showOnlyDefaultSite: args.showOnlyDefaultSite,
				searchQuery: args.searchQuery,
				dataModelRequest: args.dataModelRequest,
			},
		});
		return response as ServerSideDataModelResult<PurchaseRequestDataTableApi_RowDto>;
	}

	export async function getSupplierOptions(args: {
		purchaseRequestStateFilter: PurchaseRequestState | null | undefined;
	}): Promise<Array<Supplier>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/purchase/purchaseRequests/dataTable/getSupplierOptions",
			method: "POST",
			data: {
				purchaseRequestStateFilter: args.purchaseRequestStateFilter,
			},
		});
		return response as Array<Supplier>;
	}
}

export interface PurchaseRequestDataTableApi_RowDto {
	currentPurchasePrice: number;
	cancelledDate: IsoDateTimeString | null | undefined;
	createdBy: string;
	currency: PurchaseRequestViewCurrency;
	demandDate: IsoDateString;
	handler: string | null | undefined;
	isDefault: boolean;
	lastUpdated: IsoDateTimeString;
	orderedDate: IsoDateTimeString | null | undefined;
	packSize: number;
	partCategory: string | null | undefined;
	partDescription_1: string;
	partDescription_2: string;
	partId: number;
	partNo: string;
	partUnit: string | null | undefined;
	purchaseFactor: number;
	purchasePriceFactor: number;
	purchasePriceUnit: string;
	purchaseRequestDate: IsoDateTimeString;
	purchaseRequestId: number;
	purchaseRequestState: PurchaseRequestState;
	purchaseUnit: string;
	quantity: number;
	quantityAsWarehouseUnits: number;
	siteId: number;
	siteName: string;
	source: PurchaseRequestSourceType;
	sourceId: number | null | undefined;
	supplierExternalId: string;
	supplierId: number;
	supplierIsActive: boolean;
	supplierName: string;
	supplierPartDescription: string;
	supplierPartId: number;
	supplierPartNo: string;
}
