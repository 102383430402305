import { AsyncMenuButton } from "src/components/common/contextMenu/AsyncMenuButton.tsx";
import { faTools } from "@fortawesome/pro-regular-svg-icons";
import { useGenericDialog } from "src/components/common/dialogs/useGenericDialog.ts";
import { OmegakeittiotMachineToolInstructionGenerationDataGrid } from "src/tenantCustomizations/tenants/omegakeittiot/OmegakeittiotMachineToolInstructionGenerationDataGrid.tsx";
import { ShopOrderBatchContextMenuCustomComponentsProps } from "src/tenantCustomizations/TenantCustomizations.ts";

export const OmegakeittiotShopOrderBatchContextMenuComponents = ({
	shopOrderBatch,
}: ShopOrderBatchContextMenuCustomComponentsProps) => {
	const { openDialog } = useGenericDialog();
	return [
		<AsyncMenuButton
			key={"rover"}
			icon={faTools}
			label={"Työstökoneohjelma (Rover)"}
			onClick={async () => {
				openDialog({
					title: "Työstökoneohjelma (Rover)",
					content: (
						<OmegakeittiotMachineToolInstructionGenerationDataGrid
							shopOrderBatchId={shopOrderBatch.shopOrderBatchId}
							machineType={"ROVER"}
						/>
					),
				});
			}}
		/>,
		<AsyncMenuButton
			key={"selco"}
			icon={faTools}
			label={"Työstökoneohjelma (Selco)"}
			onClick={async () => {
				openDialog({
					title: "Työstökoneohjelma (Selco)",
					content: (
						<OmegakeittiotMachineToolInstructionGenerationDataGrid
							shopOrderBatchId={shopOrderBatch.shopOrderBatchId}
							machineType={"SELCO"}
						/>
					),
				});
			}}
		/>,
	];
};
