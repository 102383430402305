import { mergeSx } from "src/utils/styles";
import { centerContent, columnFlex, flex1, gap1 } from "src/styles/sx";
import { Box, Typography } from "@mui/material";
import i18n from "i18next";
import { useNavigate } from "react-router-dom";
import { capitalize } from "../../utils/strings";
import { AavoButton } from "./buttons/AavoButton";
import { HOME } from "../../urls";

export const PageNotFound = () => {
	const navigate = useNavigate();

	const goBackToMainPage = () => {
		navigate(HOME);
	};

	return (
		<Box sx={mergeSx(columnFlex, flex1, centerContent, gap1)}>
			<Typography>{i18n.t("page_not_found")}</Typography>
			<AavoButton color={"primary"} onClick={goBackToMainPage}>
				{capitalize(i18n.t("go_back_to_main_page"))}
			</AavoButton>
		</Box>
	);
};
