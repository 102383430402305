import { Popper, PopperProps } from "@mui/material";

export interface CustomPopperProps extends PopperProps {
	width?: number | string;
}

export const CustomPopper = ({
	width,
	style,
	placement = "bottom-start",
	...other
}: CustomPopperProps) => {
	return (
		<Popper
			placement={placement}
			{...other}
			style={{
				...style,
				width: width,
				maxWidth: "100vw",
			}}
		/>
	);
};
