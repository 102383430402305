import { PartialTenantCustomizations } from "src/tenantCustomizations/TenantCustomizations";
import { LehtoPurchaseRequestDataGridActionBarComponents } from "src/tenantCustomizations/tenants/lehto/LehtoPurchaseRequestDataGridActionBarComponents";
import { LehtoDocumentsOfObjectDataGridContextMenuComponents } from "src/tenantCustomizations/tenants/lehto/LehtoDocumentsOfObjectDataGridContextMenuComponents.tsx";

export const lehtoCustomizations: PartialTenantCustomizations = {
	tenantConfig: {
		erp: {
			enabled: true,
			customerOrderPreLineImportEnabled: true,
			configuratorEnabled: true,
		},
		spc: {
			processImprovementsEnabled: true
		},
		tasks: {
			enabled: true,
		},
		documentsEnabled: true,
	},
	purchaseRequestsDataGridActionBarComponents: LehtoPurchaseRequestDataGridActionBarComponents,
	documentsOfObjectDataGridContextMenuComponents:
		LehtoDocumentsOfObjectDataGridContextMenuComponents,
} as const;
