// This file is automatically generated. Do not edit manually.

import { CatalogPartRevisionView } from "src/api/generated/erp/db/types/tables/catalogPartRevisionView";
import { PartView } from "src/api/generated/erp/db/types/tables/partView";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace CatalogPartRevisionApi {
	export async function getCatalogPartRevision(args: {
		catalogPartRevisionId: number;
	}): Promise<CatalogPartRevisionView> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/productManagement/catalogPartRevision/getCatalogPartRevision",
			method: "POST",
			data: {
				catalogPartRevisionId: args.catalogPartRevisionId,
			},
		});
		return response as CatalogPartRevisionView;
	}

	export async function getCatalogPartRevisions(args: {
		catalogPartId: number;
	}): Promise<Array<CatalogPartRevisionView>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/productManagement/catalogPartRevision/getCatalogPartRevisions",
			method: "POST",
			data: {
				catalogPartId: args.catalogPartId,
			},
		});
		return response as Array<CatalogPartRevisionView>;
	}

	export async function getRevisionsForCatalogParts(args: {
		catalogPartIds: Array<number>;
	}): Promise<Array<CatalogPartRevisionView>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/productManagement/catalogPartRevision/getRevisionsForCatalogParts",
			method: "POST",
			data: {
				catalogPartIds: args.catalogPartIds,
			},
		});
		return response as Array<CatalogPartRevisionView>;
	}

	export async function getCatalogPartHasAnyRevisionLines(args: { catalogPartRevisionId: number }): Promise<boolean> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/productManagement/catalogPartRevision/getCatalogPartHasAnyRevisionLines",
			method: "POST",
			data: {
				catalogPartRevisionId: args.catalogPartRevisionId,
			},
		});
		return response as boolean;
	}

	export async function getCatalogPartRevisionSiteParts(args: {
		catalogPartRevisionId: number;
	}): Promise<Array<PartView>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/productManagement/catalogPartRevision/getCatalogPartRevisionSiteParts",
			method: "POST",
			data: {
				catalogPartRevisionId: args.catalogPartRevisionId,
			},
		});
		return response as Array<PartView>;
	}
}
